import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { setLocalStorage } from "../../../../../utils/localStorage";
import { InputField } from "./FormFields/InputField";

interface IFormStandFor
{
	setStepCourse: any;
	closeForm(): void;
	componentInputs: any;
	addInput: any;
	handleChangeArrInputs: any;
}

export default function FormStandFor({ setStepCourse, closeForm, componentInputs, addInput, handleChangeArrInputs }: IFormStandFor)
{
	function handleStep(step: number)
	{
		return setStepCourse(step);
	}

	let count: number = 0;

	componentInputs[ 3 ].items.forEach((element: any) =>
	{
		if (element.value) count += 1;
	});
	function handleSubmit(step: number)
	{
		if ((count >= 5))
		{
			setStepCourse(step);
			setLocalStorage("mission-nine-inputs", componentInputs);
		}
	}

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "100%", maxWidth: "980px", minWidth: { lg: "785px", xl: "906px" } }}>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
				<Box
					sx={{ display: "flex", flexDirection: "column", height: "100%", maxHeight: "520px", justifyContent: "space-between" }}
					component="form"
					autoComplete="off"
				>
					<Box sx={{
						display: "flex",
						width: "100%",
						height: "auto",
						m: "0 auto",
						marginTop: "15px",
						marginLeft: "37px",
						border: "1px solid rgba(255, 255, 255, .6)",
						borderRadius: "24px",
						wordBreak: "break-all",
						flexDirection: "column",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						boxShadow: "0px 5px 10px 0px #A7C7E199",
						padding: "24px 32px"
					}}>
						<Box
							sx={{ position: "absolute", top: "22px", right: "22px" }}
							onClick={closeForm}
						>
							<CloseIcon sx={{ fill: "#fff", fontSize: "14px", width: "24px", backgroundColor: "#3E809D", borderRadius: "40px", height: "24px", p: "6px", cursor: "pointer" }} />
						</Box>
						<Box sx={{ margin: "0px" }}>
							<Typography sx={{ font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, marginLeft: "10px", marginBottom: "17px", color: "#215C75", textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
								{/* Quotes I live by...  */}
								{componentInputs[ 3 ].title}<br />
							</Typography>
							<Box
								className="customScroll"
								sx={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "450px" }}>
								{componentInputs[ 3 ].items.map((element: any, index: any) => (
									<>
										<Box key={index} sx={{ display: "flex", flexDirection: "row", marginBottom: "4px", marginRight: "16px" }}>
											<Typography sx={{
												whiteSpace: "nowrap",
												marginRight: "16px",
												color: "#215C75",
												fontFamily: "Lora-Bold",
												fontSize: { lg: "18px", xl: "22px" },
												fontWeight: "700",
												position: "relative",
												top: "14px",
												left: "8px"
											}}>
												{index + 1}.
											</Typography>
											<InputField
												value={element.value}
												fullWidth
												autoFocus={index === 0}
												rows={2}
												onChange={(e: any) => handleChangeArrInputs(e.target.value, 3, index)}
												inputProps={{ maxLength: 70 }}
												placeholder={element.placepolder}
												helperText={`${ element.value.length }/70 characters`}
											/>
										</Box>
										{
											(componentInputs[ 3 ].items.length === (index + 1)) && (componentInputs[ 3 ].items.length <= 9) &&
											(
												<Box
													sx={{ display: "flex", flexDirection: "row", "&:hover": { cursor: "pointer" } }}
													onClick={() => addInput(3)}
												>
													<AddIcon sx={{ fill: "#215C75", fontSize: { lg: "25px", xl: "35px" }, ml: { lg: "30px", xl: "-4px" } }} />
												</Box>
											)
										}
									</>
								))
								}
							</Box>
						</Box>
					</Box>
					<Box sx={{ marginLeft: "37px", display: "flex", width: "100%", flexDirection: "row", padding: "15px 0px", justifyContent: "space-between" }}>
						<Box
							sx={{
								position: "relative",
								zIndex: "2000",
								color: "#fff",
								backgroundColor: "#3E809D",
								borderRadius: "30px",
								width: "40px",
								height: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": { cursor: "pointer" }
							}}
							onClick={() => handleStep(3)}>
							<ArrowBackIosNewIcon />
						</Box>
						<Box
							sx={{
								position: "relative",
								zIndex: "2000",
								color: "#fff",
								backgroundColor: (!(count >= 5) ? "#3E809D80" : "#3E809D"),
								borderRadius: "30px",
								width: "40px",
								height: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": {
									cursor: (!(count >= 5) ? "normal" : "pointer")
								}
							}}
							onClick={() => handleSubmit(5)}>
							<ArrowForwardIosIcon />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
