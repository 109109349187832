import { Box } from "@mui/material";
import { useMemo } from "react";
import { useLocalStorage } from "utils/localStorage";
import IMGBackground from "./assets/images/background.jpg";
import BiggestChallenges from "./components/BiggestChallenges";
import Greetings from "./components/Greetings";
import MainGoals from "./components/MainGoals";
import Name from "./components/Name";
import Password from "./components/Password";
import PersonalReport from "./components/PersonalReport";
import ResultCelebration from "./components/ResultCelebration";
import SignUp from "./components/SignUp";
import { RegistrationCtx } from "./contexts/RegistrationCtx";

const components = [
	Greetings,
	Name,
	MainGoals,
	BiggestChallenges,
	PersonalReport,
	SignUp,
	Password,
	ResultCelebration
	// Result
];

function Registration()
{
	const [ name, setName ] = useLocalStorage<string>("registration-name", "");
	const [ email, setEmail ] = useLocalStorage<string>("registration-email", "");
	const [ step, setStep ] = useLocalStorage<number>("registration-step", 1);

	const context = useMemo(() =>
	{
		return {
			setStep,
			name,
			setName,
			email,
			setEmail
		};
	}, [ name, email ]);

	const Component = useMemo(() =>
	{
		if ((step - 1) === components.length)
		{
			return () => (<h1 style={{ margin: "auto" }}>Step not found? (Step: {step})</h1>);
		}

		return components?.[ step - 1 ];
	}, [ step ]);

	// Render
	return (
		<RegistrationCtx.Provider value={context}>
			<Box sx={{
				minHeight: "100vh",
				background: `url(${ IMGBackground }) center center/cover`,
				display: "flex",
				flexDirection: "column",

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				}
			}}>
				<Component />
			</Box>
		</RegistrationCtx.Provider>
	);
}

export default Registration;
