import { Box } from "@mui/material";
import MissionsDialog from "components/missions/Dialog";
import RadialButton from "components/RadialButton";
import { forwardRef, Ref, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";

export type IKeepAnEyeOnYourBeliefsProps = Record<string, any>;

function KeepAnEyeOnYourBeliefs({ ...props }: IKeepAnEyeOnYourBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ isShowContent, setIsShowContent ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ isViewContent, setIsViewContent ] = useState<[ boolean, boolean ]>([ false, false ]);

	// Handles
	function onToggleContentView(index: number)
	{
		setIsViewContent((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: true });
		});

		setIsShowContent((prevState) =>
		{
			if (index === 0)
			{
				return [ !prevState[ index ], false ];
			}

			return [ false, !prevState[ index ] ];

		});
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_title" defaultValue="Let’s uncover the deepest sitting beliefs!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_subTitle" defaultValue="You’ve identified many hidden beliefs already. Let’s squeeze out those that might still be hiding." position="left" isDescription />}
					sx={{
						marginBottom: "40px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { left: "-35px" }
					}}
				/>

				<Box sx={{
					width: "911px",
					height: "397px",
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "space-between",

					"& .RadialButtonTips-container":
					{
						maxWidth: "640px",
						width: "640px",
						padding: "13.5px 32px",
						font: "400 22px/30px 'Open Sans'"
					},
					"& .RadialButtonTips-title":
					{
						textShadow: "unset",
						textAlign: "center"
					},
					"& .RadialButtonTips--direction-left .RadialButtonTips-title":
					{
						paddingRight: "47px",
						paddingLeft: "32px"
					},
					"& .RadialButtonTips--direction-right .RadialButtonTips-title":
					{
						paddingRight: "32px",
						paddingLeft: "47px"
					},
					"& .RadialButtonTips-content":
					{
						textAlign: "center"
					}
				}}>
					<RadialButton
						value="Find evidence to support your new beliefs"
						direction="left"
						offBlipOnClick={false}
						blip={isViewContent[ 0 ] === false}
						absoluteValuePosition
						onClick={() => onToggleContentView(0)}
						sx={{
							alignSelf: "flex-end",

							"&:not(:hover) .RadialButton-value":
							{
								display: "none"
							}
						}}
					/>

					<RadialButton
						value="Challenge remaining limiting beliefs"
						offBlipOnClick={false}
						blip={isViewContent[ 1 ] === false}
						absoluteValuePosition
						onClick={() => onToggleContentView(1)}
						sx={{
							"&:not(:hover) .RadialButton-value":
							{
								display: "none"
							}
						}}
					/>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					onClick={() => { missionContext.nextStep(); }}
					disabled={isViewContent.some((value) => value === false)}
				/>
			</StepperNavigationWrapper>

			<MissionsDialog
				title={<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_right_title" defaultValue="Find evidence to support your new beliefs" position="left" />}
				open={isShowContent[ 0 ]}
				onClose={() => onToggleContentView(0)}
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						maxWidth: "804px",
						padding: "56px 42px 28px 56px"
					}
				}}
			>
				<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_right_content" defaultValue="To make your new empowering beliefs part of your system, it’s helpful to find evidence to support your new beliefs. For example, if you are rewriting an old belief that earning a lot of money is hard, you can start seeking out examples of people making big money with ease." position="left" isDescription />
			</MissionsDialog>

			<MissionsDialog
				title={<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_left_title" defaultValue="Challenge remaining limiting beliefs" position="right" />}
				open={isShowContent[ 1 ]}
				onClose={() => onToggleContentView(1)}
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						maxWidth: "804px",
						padding: "56px 42px 28px 56px"
					}
				}}
			>
				<ContentAdmin keyContent="contentMissionSeven" keyText="KeepAnEyeOnYourBeliefs_left_content" defaultValue="Now that you know how to challenge limiting beliefs, you can work on the remaining beliefs you identified. You can also keep an eye on your beliefs in the future, and once you spot one that is holding you back, you know what to do!" position="right" isDescription />
			</MissionsDialog>
		</Box>
	);
}

export default forwardRef(KeepAnEyeOnYourBeliefs);
