import { Box, alpha } from "@mui/material";
import { ReactComponent as SVGIconExit } from "assets/images/icons/size-40/exit.svg";
import { ReactComponent as SVGIconFilters } from "assets/images/icons/size-40/filters.svg";
import { ReactComponent as SVGIconMoney } from "assets/images/icons/size-40/money.svg";
import { ReactComponent as SVGIconSupport } from "assets/images/icons/size-40/question.svg";
import { ReactComponent as SVGIconSettings } from "assets/images/icons/size-40/settings.svg";
import IMGOcta from "assets/images/octa.png";
import TabPanel from "components/TabPanel";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import AsidePanel, { ITabsItem } from "../aside";
import Help from "./help";
import RequestFeature from "./request-feature";
import Settings from "./settings";
import Transactions from "./transactions";
// import { ReactComponent as SVGIconWhatsApp } from "assets/images/icons/size-40/whats-app.svg";

interface IAccountProps
{
	selectedTab: string | undefined;
}

const TABS: ITabsItem[] = [
	{
		to: "/dashboard/account/",
		value: "settings",
		label: "Setting",
		icon: <SVGIconFilters />
	},
	{
		to: "/dashboard/account/help",
		value: "help",
		label: "Help",
		icon: <SVGIconSupport />
	},
	{
		to: "/dashboard/account/request-feature",
		value: "request-feature",
		label: "Request a feature",
		icon: <SVGIconSettings />
	},
	{
		to: "/dashboard/account/transactions",
		value: "transactions",
		label: "Transactions",
		icon: <SVGIconMoney />
	}
];

type ISubTitles = Record<string, string>;

const subTitles: ISubTitles =
{
	settings: "Settings",
	help: "Help",
	"request-feature": "Request a Feature",
	transactions: "Transactions"
};

function Account({ selectedTab = "settings" }: IAccountProps)
{
	const navigate = useNavigate();
	const userContext = useUser();

	if (!(selectedTab in subTitles))
	{
		navigate("/dashboard/account");
	}

	// Handles
	function onClickSignOut()
	{
		userContext.dispatch({
			type: UserActionType.LOGOUT
		});
		navigate("/login");
	}

	// Render
	return (
		<Box sx={{
			height: { xl: "860px", lg: "676px", xs: "676px" },
			display: "flex",
			gap: "40px",
			maxWidth: "1792px"
		}}>
			<AsidePanel
				header={
					(<Box
						component="img"
						src={IMGOcta}
						alt=""
						sx={{
							display: "block",
							position: "absolute",
							top: { xl: "18px", lg: "6px", xs: "6px" },
							right: { xl: "45px", lg: "51px", xs: "51px" },
							width: { xl: "259px", lg: "212px", xs: "212px" },
							height: { xl: "432px", lg: "354px", xs: "354px" }
						}}
					/>)
				}
				title="Account"
				tabs={TABS}
				selectedTab={selectedTab}
				navigationProps={{ "data-tut": "reactour__myAccount_aside" }}
			>
				<Box sx={{
					margin: "auto 32px 32px 32px",
					display: "flex",
					flexDirection: "column",
					gap: "24px"
				}}>
					{/* <CustomButton.Base
						// onClick={onClickSignOut}
						sx={{
							minWidth: "unset",
							width: "100%",
							minHeight: "unset",
							height: "56px",
							gap: "16px",

							"& svg":
							{
								width: { xl: "40px", lg: "24px", xs: "24px" },
								height: { xl: "40px", lg: "24px", xs: "24px" },

								"& *":
								{
									transition: "linear 300ms fill, linear 0ms stroke",
									stroke: "unset"
								}
							},
							"&:hover svg *":
							{
								stroke: "unset"
							}
						}}
					>
						<SVGIconWhatsApp />
						Talk to founder
					</CustomButton.Base> */}

					<CustomButton.Outline
						onClick={onClickSignOut}
						sx={{
							minWidth: "unset",
							width: "100%",
							minHeight: "unset",
							height: "56px",
							gap: "16px",

							"& svg":
							{
								width: { xl: "40px", lg: "24px", xs: "24px" },
								height: { xl: "40px", lg: "24px", xs: "24px" },

								"& *":
								{
									transition: "linear 300ms fill, linear 0ms stroke",
									stroke: "unset"
								}
							},
							"&:hover svg *":
							{
								fill: "#215c75",
								stroke: "unset"
							}
						}}
					>
						<SVGIconExit />
						Sign Out
					</CustomButton.Outline>
				</Box>
			</AsidePanel>

			<Box sx={{
				padding: { xl: "72px", lg: "40px", xs: "40px" },
				width: "100%",
				height: "100%",
				overflow: "auto",

				backgroundColor: alpha("#FFFFFF", 0.5),
				backdropFilter: "blur(30px)",
				boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "24px",

				"& > [role='tabpanel']":
				{
					height: "100%",
					overflowY: "auto",
					marginRight: "-16px",
					paddingRight: "16px"
				}
			}}>
				<TabPanel className="customScroll" value={selectedTab} active="settings" index={0}>
					<Settings />
				</TabPanel>
				<TabPanel className="customScroll" value={selectedTab} active="help" index={1}>
					<Help />
				</TabPanel>
				<TabPanel className="customScroll" value={selectedTab} active="request-feature" index={2}>
					<RequestFeature />
				</TabPanel>
				<TabPanel className="customScroll" value={selectedTab} active="transactions" index={3}>
					<Transactions />
				</TabPanel>
			</Box>
		</Box>
	);
}

export default Account;
