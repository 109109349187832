import { Box } from "@mui/material";
import Api from "api";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import useMission from "pages/missionFour/hooks/useMission";
import IMGCelebrationOcta from "pages/registration/assets/images/celebration-octa.png";
import { useState } from "react";
import Confetti from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import clearLocalStorage from "utils/clearlocalStorage";
import { getLocalStorage } from "utils/localStorage";
import trackEvent from "utils/mixpanelUtil";
import type { AttractiveInfoType } from "../JobToLove";
import type { IPointInfo } from "../LifeHighsLows";
import type { JobInformation } from "../PersonalJobAd/LearnMoreAboutEachJob";

function Complete()
{
	const clientWidth = document.documentElement.clientWidth;
	const missionContext = useMission();
	const navigate = useNavigate();

	const { state: userState, dispatch: userDispatch } = useUser();

	const [ isLoading, setIsLoading ] = useState<boolean>(false);

	// Handles
	function nextStep()
	{
		if (isLoading === true)
		{
			return;
		}

		const lifeHighsLowsGraphImage = getLocalStorage<string>(missionContext.missionPrefix + "-lifeHighsLows-graphImage");
		if (lifeHighsLowsGraphImage === null)
		{
			return missionContext.setStep(4);
		}

		const lifeHighsLowsPointsInfo = getLocalStorage<IPointInfo[]>(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo");
		if (lifeHighsLowsPointsInfo === null)
		{
			return missionContext.setStep(4);
		}

		const highsLowsReflects = getLocalStorage<[ string, string, string, string, [ string, string, string, string ] ]>(missionContext.missionPrefix + "-highsLows-reflects");
		if (highsLowsReflects === null)
		{
			return missionContext.setStep(7);
		}

		const jobsThatAttract = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsThatAttract");
		if (jobsThatAttract === null)
		{
			return missionContext.setStep(10);
		}

		const attractiveInfo = getLocalStorage<AttractiveInfoType[]>(missionContext.missionPrefix + "-attractiveInfo");
		if (attractiveInfo === null)
		{
			return missionContext.setStep(11);
		}

		const personalJobAdText = getLocalStorage<string>(missionContext.missionPrefix + "-PersonalJobAd-text");
		if (personalJobAdText === null)
		{
			return missionContext.setStep(13);
		}

		const jobList = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsList");
		if (jobList === null)
		{
			return missionContext.setStep(14);
		}

		const selectedJobs = getLocalStorage<string[]>(missionContext.missionPrefix + "-selectedJobs");
		if (selectedJobs === null)
		{
			return missionContext.setStep(15);
		}

		const jobsInformation = getLocalStorage<Record<string, JobInformation>>(missionContext.missionPrefix + "-jobsInformation");
		if (jobsInformation === null)
		{
			return missionContext.setStep(16);
		}

		setIsLoading(true);

		Api.missions
			.setDataMissionFour(
				{
					lifeHighsLowsGraphImage,
					lifeHighsLowsPointsInfo,
					highsLowsReflects,
					jobsThatAttract,
					attractiveInfo,
					personalJobAdText,
					jobList,
					selectedJobs,
					jobsInformation
				}
			)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(4) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 4 }
					}
				);

				if (userState.user?.completedMission === 3)
				{
					setTimeout(() => missionContext.setStep(21), 0);
				}
				else
				{
					clearLocalStorage("mission-four");
					setTimeout(() => navigate("/dashboard", { replace: true }), 0);
				}

			})
			.catch((error: any) =>
			{
				console.error(error);
			})
			.finally(() =>
			{
				setIsLoading(false);
			});
	}

	return (
		<Box sx={{
			padding: { xl: "60px 32px 32px 32px", sm: "48px 32px 32px 32px", xs: "54px 32px 0 32px" },
			flexGrow: 1,
			backdropFilter: "blur(40px)",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%"
		}}>
			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main",
				textAlign: "center"
			}}>
				Congratulations!<br />
				Another mission is complete.
			</Box>

			<Confetti
				particleCount={250}
				particleSize={clientWidth <= 700 ? 10 : 15}
				force={0.8}
				duration={5000}
				width={clientWidth}
				colors={[
					"#C1D2EF",
					"#CAEAB1",
					"#E39BBE",
					"#7D84DA",
					"#E2E49D",
					"#9DDE8A",
					"#B5A7E7",
					"#96B8E2",
					"#DF8EA7",
					"#F6E0DB"
				]}
			/>

			<Box
				component="img"
				src={IMGCelebrationOcta}
				alt=""
				sx={{
					marginTop: { xl: "40px", sm: 0, xs: "80px" },
					maxWidth: { xl: "716px", sm: "602px", xs: "390px" },
					width: "100%"
				}}
			/>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "40px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>

				<CustomButton.Contained
					size="large"
					onClick={nextStep}
					loading={isLoading}
					sx={{
						maxWidth: "390px",
						width: { sm: "auto", xs: "100%" },
						boxShadow: "unset"
					}}
				>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default Complete;
