import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import { alpha, Box, FormHelperText } from "@mui/material";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import { ReactComponent as SVGZoomIn } from "assets/images/icons/zoom-in.svg";
import { ReactComponent as SVGZoomOut } from "assets/images/icons/zoom-out.svg";
import ArrowButton from "components/ArrowButton";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomInput from "components/CustomInput";
import MissionsDialog from "components/missions/Dialog";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, DragEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import useMission from "../../hooks/useMission";
import IMGCursorGrab from "../TimeToPriorities/cursor-grab.png";

export const COLORS =
{
	green: "#1FAD77",
	blue: "#278EE3",
	red: "#F22E6C",
	none: "#FFFFFF"
};

export interface IBoardItem
{
	id: number;
	title: string;
	color: keyof typeof COLORS;
}

export interface IBoard
{
	id: "__values__" | string;
	title: string;
	items: IBoardItem[];
}

function ClusterYourYearnings()
{
	const missionContext = useMission();
	const addClusterInputRef = useRef<HTMLInputElement>(null);
	const [ isMove, setIsMove ] = useState<boolean>(false);
	const [ currentBoardIndex, setCurrentBoardIndex ] = useState<number | null>(null);
	const [ currentBoardItem, setCurrentBoardItem ] = useState<IBoardItem | null>(null);
	const [ showModalAddCluster, setShowModalAddCluster ] = useState<boolean>(true);
	const [ clusterTitle, setClusterTitle ] = useState<string>("");
	const [ errorAddCluster, setErrorAddCluster ] = useState<string>("");
	const [ boards, setBoards ] = useState<IBoard[]>(() =>
	{
		const clusters = getLocalStorage<IBoard[]>("mission-three-cluster");
		if (clusters !== null)
		{
			return clusters;
		}

		let values = getLocalStorage<string[]>("mission-three-wantInLine");
		if (values === null)
		{
			values = [];
		}

		const boards: IBoard[] = [
			{
				id: "__values__",
				title: "firstColumn",
				items: values.map((item, index): IBoardItem => ({ id: index, title: item, color: "none" }))
			}
		];

		return boards;
	});
	const [ clustersTitles, setClustersTitles ] = useState<Record<string, string>>(() =>
	{
		const clustersTitle: Record<string, string> = {};

		boards.forEach((board) =>
		{
			clustersTitle[ board.id ] = board.title;
		});

		return clustersTitle;
	});

	// Effects
	useEffect(() =>
	{
		setLocalStorage("mission-three-cluster", boards);
	}, [ boards ]);

	// Utils
	function searchBoardIndex(boardId: IBoard[ "id" ]): number
	{
		return boards.findIndex((board) => board.id === boardId);
	}

	function highlightDrop(event: DragEvent<HTMLDivElement>, show: boolean)
	{
		if (event.currentTarget.classList.contains("cluster__column"))
		{
			event.currentTarget.classList[ show ? "add" : "remove" ]("cluster__column--highlight-drop");
		}
	}

	function generateID(): string
	{
		return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
	}

	function changeClusterTitle(boardId: IBoard[ "id" ], value: string)
	{
		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);
			const boardIndex = boards.findIndex((board) => board.id === boardId);

			boards[ boardIndex ].title = value;

			return boards;
		});
	}

	// Handles
	// #region Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function onEntering()
	{
		if (addClusterInputRef.current !== null)
		{
			addClusterInputRef.current.focus();
		}
	}

	function deletedValues(title: string)
	{
		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);

			boards[ 0 ].items = boards[ 0 ].items.filter((item) => item.title !== title);

			return boards;
		});

		const store = getLocalStorage<string[]>("mission-three-wantInLine");
		if (store !== null)
		{
			setLocalStorage("mission-three-wantInLine", store.filter((item) => item !== title));
		}
	}

	function addCluster(): void
	{
		const title: string = clusterTitle.trim();

		if (title.length < 3)
		{
			return setErrorAddCluster("No shorter than three characters");
		}
		else if (title.length > 15)
		{
			return setErrorAddCluster("No longer than fifteen characters");
		}

		const isExist: boolean = boards.some((board) => board.title === title);

		if (isExist === true)
		{
			return setErrorAddCluster("This name is already in use");
		}

		const id = generateID();

		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);

			boards.push({ id, title, items: [] });

			return boards;
		});

		setClustersTitles((prevState) =>
		{
			return { ...prevState, [ id ]: title };
		});

		setClusterTitle("");
		setErrorAddCluster("");
		setShowModalAddCluster(false);
	}

	function onBlurClusterTitle(boardId: IBoard[ "id" ])
	{
		return (_event: FocusEvent<HTMLInputElement>) =>
		{
			if (clustersTitles[ boardId ].trim().length >= 3)
			{
				return changeClusterTitle(boardId, clustersTitles[ boardId ].trim().slice(0, 15).trim());
			}

			setClustersTitles((prevState) =>
			{
				return {
					...prevState,
					[ boardId ]: boards.find((board) => board.id === boardId)!.title
				};
			});
		};
	}

	function onKeyDownClusterTitle(boardId: IBoard[ "id" ])
	{
		return (event: KeyboardEvent<HTMLInputElement>) =>
		{
			if (event.key !== "Enter")
			{
				return;
			}

			if (clustersTitles[ boardId ].trim().length >= 3)
			{
				return changeClusterTitle(boardId, clustersTitles[ boardId ].trim().slice(0, 15).trim());
			}

			setClustersTitles((prevState) =>
			{
				return {
					...prevState,
					[ boardId ]: boards.find((board) => board.id === boardId)!.title
				};
			});
		};
	}

	function onChangeClusterTitle(boardId: IBoard[ "id" ])
	{
		return ({ target }: ChangeEvent<HTMLInputElement>) =>
		{
			setClustersTitles((prevState) =>
			{
				return { ...prevState, [ boardId ]: target.value.trim().slice(0, 15).trim() };
			});
		};
	}

	function onDeleteCluster(boardId: IBoard[ "id" ])
	{
		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);
			const boardIndex = boards.findIndex((board) => board.id === boardId);

			if (boards[ boardIndex ].items.length > 0)
			{
				const boardsValuesIndex = boards.findIndex((board) => board.id === "__values__");

				boards[ boardsValuesIndex ].items.push(...boards[ boardIndex ].items);
			}

			boards.splice(boardIndex, 1);

			return boards;
		});
	}
	// #endregion

	// #region Drag n Drop
	function onDragStart(_event: DragEvent<HTMLDivElement>, boardIndex: number, boardItem: IBoardItem)
	{
		setCurrentBoardIndex(boardIndex);
		setCurrentBoardItem(boardItem);
	}

	function onDragEnter(event: DragEvent<HTMLDivElement>)
	{
		highlightDrop(event, true);
	}

	function onDragOver(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function onDragLeave(event: DragEvent<HTMLDivElement>)
	{
		highlightDrop(event, false);
	}

	function onDrop(event: DragEvent<HTMLDivElement>, boardIndex: number, boardItem: IBoardItem | null = null)
	{
		event.stopPropagation();

		if (currentBoardIndex === null || currentBoardItem === null)
		{
			return;
		}

		highlightDrop(event, false);

		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);

			let itemNewIndex: number = boards[ boardIndex ].items.length;
			if (boardItem !== null)
			{
				itemNewIndex = boards[ boardIndex ].items.findIndex((item) => item.id === boardItem.id);
			}

			const currentItemIndex = boards[ currentBoardIndex ].items.findIndex((item) => item.id === currentBoardItem.id);

			boards[ currentBoardIndex ].items.splice(currentItemIndex, 1);

			boards[ boardIndex ].items.splice(itemNewIndex, 0, currentBoardItem);

			return boards;
		});
	}
	// #endregion

	// Render
	return (
		<>
			<Box className="main__content">
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						keyContent="contentMissionThree"
						keyText="create_some_order_title"
						defaultValue="Let’s create some order"
						position="right"
					/>}
					subTitle={<ContentAdmin
						keyContent="contentMissionThree"
						keyText="create_some_order_subTitle"
						defaultValue="Now that you have lots of ideas for your dream life, let’s create some structure. Place similar ideas into groups and give each group a name."
						position="right"
						isDescription />}
				/>
				<Box sx={{
					flexGrow: 1,
					display: "flex",
					gap: "10px",
					backdropFilter: "blur(15px)",
					borderRadius: "20px",
					background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
					border: "1px solid " + alpha("#FFFFFF", 0.6),
					padding: "30px 10px 10px 30px",
					maxHeight: { xl: "635px", lg: "478px", xs: "635px" },
					boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5)
				}}>
					{/* Left menu */}
					<Box
						className="scrollCustom"
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							width: { xl: "315px", lg: "250px", xs: "300px" },
							alignItems: "flex-start",
							overflow: "auto",
							maxHeight: "100%",
							marginBottom: "20px",
							paddingBottom: "6px",
							flexShrink: 0
						}}
						onDrop={(event) => onDrop(event, 0)}
						onDragEnter={onDragEnter}
						onDragOver={onDragOver}
						onDragLeave={onDragLeave}
					>
						{boards[ 0 ].items.map((boardItem) => (
							<ChipStyled
								key={boardItem.title}
								label={boardItem.title}
								draggable
								onDragStart={(event) => onDragStart(event, 0, boardItem)}
								onDragOver={(event) => event.preventDefault()}
								onDrop={(event) => onDrop(event, 0, boardItem)}
								onDelete={() => { deletedValues(boardItem.title); }}
							/>
						))}
					</Box>

					{/* Right menu */}
					<Box sx={{
						position: "relative",
						zIndex: 2,
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",

						"& .cluster__column":
						{
							padding: "5px",
							borderRadius: "10px",
							width: "300px",
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							border: "1px solid " + alpha("#FFFFFF", 0.7),
							backdropFilter: "blur(10px)",
							boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),

							"&.cluster__column--highlight-drop .cluster__items:empty::after":
							{
								borderColor: alpha("#215C75", 1),
								color: "#4D4D4D"
							}
						},
						"& .cluster__title":
						{
							display: "flex",
							gap: "5px",
							padding: "0 10px",

							"& .cluster__inputTitle":
							{
								flexGrow: 1,
								font: "700 16px/20px Lora",
								color: "primary.main",
								textAlign: "center",
								background: "none",
								border: "none",
								outline: "none"
							},
							"& .cluster__delete":
							{
								border: "none",
								background: "none",
								cursor: "pointer",
								padding: 0,
								opacity: 1,
								transition: "linear 200ms opacity",

								"&:hover":
								{
									opacity: 0.7
								}
							}
						},
						"& .cluster__items":
						{
							display: "flex",
							flexWrap: "wrap",
							gap: "10px",
							alignItems: "center",
							overflow: "auto",
							maxHeight: "380px",
							padding: "0 10px 10px 10px",

							"&:empty":
							{
								"&::after":
								{
									content: "'Drop to here'",
									padding: "10px",
									display: "block",
									width: "100%",
									textAlign: "center",
									color: alpha("#4D4D4D", 0.6),
									font: "500 16px 'Open Sans'",
									border: "2px dashed " + alpha("#215C75", 0.6),
									borderRadius: "5px",
									transition: "linear 200ms border-color, linear 200ms color"
								}
							},
							"& .MuiChip-root":
							{
								flexGrow: 1,
								"& .MuiChip-label":
								{
									textAlign: "center",
									flexGrow: 1
								}
							}
						},
						"& .react-transform-wrapper":
						{
							flexGrow: 1,
							width: "100%",
							maxWidth: "628px",
							height: "100%",
							cursor: (isMove === true ? "grabbing" : "grab"),
							borderRadius: "10px"
						},
						"& .react-transform-component":
						{
							gap: "20px",
							justifyContent: "center",
							alignItems: "flex-start",
							width: "100%",
							maxWidth: "628px",
							borderRadius: "10px"
						}
					}}>
						<TransformWrapper
							minScale={0.5}
							maxScale={1}
							centerZoomedOut
							centerOnInit={false}
							panning={{ excluded: [ "MuiChip-root", "MuiChip-label", "cluster__inputTitle", "cluster__delete", "cluster__delete--icon" ] }}
							wheel={{ excluded: [ "cluster__items", "MuiChip-root", "MuiChip-label" ] }}
							onPinchingStart={() => setIsMove(true)}
							onPanningStart={() => setIsMove(true)}
							onPinchingStop={() => setIsMove(false)}
							onPanningStop={() => setIsMove(false)}
						>
							{({ zoomIn, zoomOut }) => (
								<>
									<TransformComponent>
										{boards.filter((board) => board.id !== "__values__").map(
											(board) => (
												<Box
													key={board.id}
													className="cluster__column"
													onDrop={(event) => onDrop(event, searchBoardIndex(board.id))}
													onDragEnter={onDragEnter}
													onDragOver={onDragOver}
													onDragLeave={onDragLeave}
												>
													<Box className="cluster__title">
														<Box
															className="cluster__inputTitle"
															component="input"
															value={clustersTitles[ board.id ]}
															onChange={onChangeClusterTitle(board.id)}
															onBlur={onBlurClusterTitle(board.id)}
															onKeyDown={onKeyDownClusterTitle(board.id)}
														/>

														<Box component="button" className="cluster__delete" onClick={() => onDeleteCluster(board.id)}>
															<TooltipStyled title="Delete cluster" placement="top" arrow>
																<ClearIcon className="cluster__delete--icon" color="primary" />
															</TooltipStyled>
														</Box>
													</Box>

													<Box className="cluster__items scrollCustom">
														{board.items.map(
															(boardItem) => (
																<ChipStyled
																	key={boardItem.id}
																	label={boardItem.title}
																	draggable
																	onDragStart={(event) => onDragStart(event, searchBoardIndex(board.id), boardItem)}
																	onDragOver={(event) => event.preventDefault()}
																	onDrop={(event) => onDrop(event, searchBoardIndex(board.id), boardItem)}
																	sx={{ flexGrow: 1 }}
																/>
															)
														)}
													</Box>
												</Box>
											)
										)}
									</TransformComponent>

									{/* Buttons */}
									<Box sx={{
										width: "100%",
										display: "flex",
										gap: "20px",
										justifyContent: "flex-end",
										alignItems: "center",
										marginTop: "10px",
										"& .navigation-button":
										{
											background: "none",
											border: "none",
											cursor: "pointer",
											padding: "5px",
											borderRadius: "10px",
											width: "49px",
											height: "49px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											transition: "linear 200ms background-color",
											"&:hover": { backgroundColor: alpha("#215C75", 0.2) }
										}
									}}>
										<TooltipStyled placement="top" arrow title="Zoom In">
											<Box component="button" className="navigation-button" onClick={() => zoomIn(0.25)}>
												<SVGZoomIn />
											</Box>
										</TooltipStyled>

										<TooltipStyled placement="top" arrow title="Zoom Out">
											<Box component="button" className="navigation-button" onClick={() => zoomOut(0.25)}>
												<SVGZoomOut />
											</Box>
										</TooltipStyled>

										<TooltipStyled placement="top" arrow title="Hand">
											<Box
												component="button"
												className="navigation-button"
											// className={`navigation-button ${ (isCursorHand ? "navigation-button--active" : "") }`}
											// onClick={() => setIsCursorHand(true)}
											>
												<Box component="img" src={IMGCursorGrab} />
											</Box>
										</TooltipStyled>

										<TooltipStyled placement="top" arrow title="Add cluster">
											<Box component="button" className="navigation-button" onClick={() => setShowModalAddCluster(true)}>
												<AddIcon fontSize="large" color="primary" />
											</Box>
										</TooltipStyled>
									</Box>
								</>
							)}
						</TransformWrapper>
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					onClick={onClickNextStep}
					disabled={!boards.some((board) => board.id !== "__values__" && board.items.length > 0)}
				/>
			</StepperNavigationWrapper>

			<MissionsDialog
				open={showModalAddCluster}
				TransitionProps={{ onEntering }}
				onClose={() =>
				{
					setShowModalAddCluster(false);
					setClusterTitle("");
					setErrorAddCluster("");
				}}
			>
				<>
					<Box
						component="form"
						sx={{ display: "flex", alignItems: "center", position: "relative" }}
						onSubmit={(event) => { event.preventDefault(); addCluster(); }}
					>
						<CustomInput.Base
							required
							autoFocus
							inputRef={addClusterInputRef}
							placeholder="Cluster title"
							value={clusterTitle}
							onChange={({ target }: ChangeEvent<HTMLInputElement>) => { setClusterTitle(target.value); setErrorAddCluster(""); }}
							sx={{
								width: "320px",
								paddingRight: "45px"
							}}
						/>

						<TooltipStyled title="Create" placement="right" arrow>
							<Box
								component="button"
								type="submit"
								sx={{
									border: "none",
									background: "none",
									position: "absolute",
									cursor: "pointer",
									right: "10px",
									top: "10px"
								}}
							>
								<SendIcon fontSize="large" color="primary" />
							</Box>
						</TooltipStyled>
					</Box>

					{errorAddCluster !== "" &&
						(
							<FormHelperText sx={{ color: "#E41616", fontWeight: "500", fontSize: "1.1rem", fontFamily: "Open Sans", marginLeft: "10px" }}>
								{errorAddCluster}
							</FormHelperText>
						)
					}
				</>
			</MissionsDialog>
		</>
	);
}

export default ClusterYourYearnings;
