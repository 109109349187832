import { Box, FormControl, Snackbar } from "@mui/material";
import Api from "api";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { Formik } from "formik";
import { SyntheticEvent, useState } from "react";
import * as yup from "yup";

function RequestFeature()
{
	const [ loader, setLoader ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ success, setSuccess ] = useState<boolean>(false);

	// Handles
	function handleClose(_event: SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	function onSubmit({ subject, message }: { subject: string; message: string; })
	{
		if (loader === true)
		{
			return;
		}

		setLoader(true);

		Api.messages
			.requestAFeature(subject, message)
			.then((res: any) =>
			{
				console.info({ res });
				setOpen(true);
				setSuccess(true);
			})
			.catch((error: any) =>
			{
				console.error(error);
			})
			.finally(() => setLoader(false));
	}

	// Render
	if (success === true)
	{
		return (
			<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark" }}>
				Your message has been sent.

				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={open}
					autoHideDuration={3000}
					onClose={handleClose}
					TransitionComponent={TransitionSlideLeft}
				>

					<AlertStyled onClose={handleClose} severity="success">
						Done
					</AlertStyled>
				</Snackbar>
			</Box>
		);
	}

	return (
		<Box>
			<Box sx={{ font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" } }}>
				We welcome ideas and suggestions. If you have a feature in mind, let us know and our product team will review it.
			</Box>

			<Formik
				validateOnBlur
				validateOnChange
				initialValues={{ subject: "", message: "" }}
				onSubmit={onSubmit}
				validationSchema={
					yup.object({
						subject: yup.string().trim().required("The field must not be empty"),
						message: yup.string().trim().required("The field must not be empty")
					})
				}
			>
				{({ values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty }) =>
				{
					return (
						<Box
							component="form"
							onSubmit={handleSubmit}
							sx={{
								marginTop: { xl: "80px", lg: "62px", xs: "62px" },
								display: "flex",
								flexDirection: "column",
								gap: "40px"
							}}
						>
							{/* Subject */}
							<FormControl>
								<CustomInput.Base
									type="text"
									placeholder="Subject"
									name="subject"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.subject}
								/>

								<Box sx={{
									display: "flex",
									justifyContent: "space-between",
									position: "absolute",
									right: "5px",
									left: "5px",
									bottom: "-19px"
								}}>
									<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
										{touched.subject ? errors.subject : null}
									</Box>

									{/* <Box sx={{ opacity: 0.5, font: "400 14px/19px 'Open Sans'", color: "primary.dark" }}>
										{values.subject.length}/{INPUT_MAX_LEN} characters
									</Box> */}
								</Box>
							</FormControl>

							{/* Message */}
							<FormControl>
								<CustomInput.Base
									type="text"
									placeholder="Message"
									name="message"
									multiline
									rows={8}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.message}
								/>

								<Box sx={{
									display: "flex",
									justifyContent: "space-between",
									position: "absolute",
									right: "5px",
									left: "5px",
									bottom: "-19px"
								}}>
									<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
										{touched.message ? errors.message : null}
									</Box>

									{/* <Box sx={{ opacity: 0.5, font: "400 14px/19px 'Open Sans'", color: "primary.dark" }}>
										{values.message.length}/{INPUT_MAX_LEN} characters
									</Box> */}
								</Box>
							</FormControl>

							{/* Submit */}
							<CustomButton.Base
								loading={loader}
								type="submit"
								disabled={!(isValid && dirty)}
								sx={{
									margin: { xl: "72px auto 0 auto", lg: "88px auto 0 auto", xs: "88px auto 0 auto" },
									minHeight: "unset",
									height: { xl: "56px", lg: "40px", xs: "40px" }
								}}
							>
								Submit
							</CustomButton.Base>
						</Box>
					);
				}}
			</Formik>
		</Box>
	);
}

export default RequestFeature;
