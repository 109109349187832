import { Box, Button, styled, SxProps, Theme, Typography } from "@mui/material";
import { MouseEvent, useLayoutEffect, useState } from "react";
import IMGBall from "../../assets/images/icons/ball-list.svg";
import { mergeDeep } from "../../utils/mergeDeep";

const ButtonStyled = styled(Button)(() => (
	{
		position: "relative",
		padding: "0",
		minHeight: "55px",
		"&:hover":
		{
			backgroundColor: "unset"
		}
	}
)) as typeof Button;

interface IRadialButtonProps
{
	value?: string;
	imgRadialButton?: string;
	whiteSpace?: string;
	valueMaxWidth?: string;
	showValue?: boolean;
	showIcon?: boolean;
	absoluteValuePosition?: boolean;
	direction?: "right" | "left";
	alignText?: "center";
	blip?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	color?: "white" | "blue" | "#3E809D80";
	hoverColor?: string;
	borderStyle?: string;
	backgroundStyle?: string;
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	[ key: string ]: any;
}

function RadialButton(
	{
		value,
		imgRadialButton = IMGBall,
		whiteSpace,
		valueMaxWidth,
		showValue = true,
		showIcon = true,
		absoluteValuePosition = false,
		direction = "right",
		alignText,
		blip = false,
		blipDurationMs = 2000,
		offBlipOnClick = true,
		color = "white",
		hoverColor,
		borderStyle = "none",
		backgroundStyle = "none",
		onClick,
		className,
		disabled = false,
		sx,
		...props
	}: IRadialButtonProps
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (isBlip === true)
	{
		classList.push("animation--blip-radialButton");
	}

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<ButtonStyled
			disableRipple
			disabled={disabled}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={
				mergeDeep({
					margin: (showValue === false ? "11.5px 0" : direction === "right" ? "11.5px 0 11.5px 40px" : "11.5px 40px 11.5px 0")
				}, sx)
			}
			{...props}
		>
			<Box
				sx={{
					position: "absolute",
					top: "-12.5px",
					display: (showIcon === false ? "none" : "flex"),
					justifyContent: "center",
					alignItems: "center",
					width: "80px",
					height: "80px",
					borderRadius: "50%",
					zIndex: 2,
					left: (direction === "right" ? "-40px" : "unset"),
					right: (direction === "left" ? "-40px" : "unset"),
					"--duration": blipDurationMs + "ms",
					// backgroundColor: "rgba(225,225,225,0.95)",
					border: (borderStyle || "none"),
					background: (backgroundStyle || "none"),
					// backdropFilter: (imgRadialButton === IMGBall ? 'none' : 'blur(30px)'),
					backdropFilter: "blur(30px)",
					...sx
				}}
				className={classList.join(" ")}
			>
				{/* <img src={IMGBall} alt='' /> */}
				{/* <img src={(imgRadialButton ? imgRadialButton : IMGBall)} alt='' /> */}
				<img src={imgRadialButton} alt="" />
			</Box>

			<Typography sx={{
				position: (absoluteValuePosition === true ? "absolute" : "relative"),
				font: "700 24px/31px Lora",
				textTransform: "initial",
				color: (disabled === true || color === "blue" ? "#215C75" : "#3E809D80"),
				display: (showValue === true ? "flex" : "none"),
				justifyContent: "center",
				alignItems: "center",
				// padding: ((borderStyle !== 'none') ? (direction === "right" ? "0 55px 0 95px" :  "0 95px 0 55px") : "0 55px"),
				padding: ((borderStyle !== "none") ? (direction === "right" ? (alignText === "center" ? "0 80px" : "0 55px 0 95px") : (alignText === "center" ? "0 80px" : "0 95px 0 55px")) : "0 55px"),
				height: "55px",
				border: "1px solid rgba(255, 255, 255, .6)",
				background: "linear-gradient(to right, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .2))",
				borderRadius: "40px",
				backdropFilter: "blur(100px)",
				textShadow: (disabled === false && color === "white" ? "1px 1px 5px rgba(0, 0, 0, .6)" : "none"),
				// width: "max-content",
				width: valueMaxWidth || "max-content",
				minWidth: "290px",
				whiteSpace: "nowrap",
				right: (absoluteValuePosition === true ? (direction === "left" ? 0 : "unset") : "unset"),
				left: (absoluteValuePosition === true ? (direction === "right" ? 0 : "unset") : "unset"),
				top: (absoluteValuePosition === true ? "50%" : "unset"),
				transform: (absoluteValuePosition === true ? "translateY(-50%)" : "unset"),
				"&:hover": {
					color: (hoverColor || "#215C75"),
					textShadow: (hoverColor ? "1px 1px 5px rgba(0, 0, 0, .6)" : "none")
				}

			}}>
				{value}
			</Typography>
		</ButtonStyled>
	);
}

export default RadialButton;
