import { Box } from "@mui/material";
import Api from "api";
import clockEvening from "assets/images/clockEvening.png";
import clockMorning from "assets/images/clockMorning.png";
import clockNight from "assets/images/clockNight.png";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import missionFiveDefualt from "assets/images/missionFiveDefualt.png";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLocalStorage } from "utils/localStorage";
import { IMissionContextFiveMission, MissionCtx } from "./MissionCtx";
import ChatPageMissionFive from "./feature/ChatPageMissionFive";
import Clock from "./feature/Clock";
import Complete from "./feature/Complete";
import FinishNight from "./feature/FinishNight";
import RadialChart from "./feature/RadialChart";
import RadialChartAfternoon from "./feature/RadialChartAfternoon";
import RadialChartEvening from "./feature/RadialChartEvening";
import RadialChartMorning from "./feature/RadialChartMorning";
import RadialChartNight from "./feature/RadialChartNight";
import ReviewDay from "./feature/ReviewDay";
import ClockAfternoon from "./feature/clockAfternoon";
import ClockEvening from "./feature/clockEvening";
import ClockMorning from "./feature/clockMorning/ClockMorning";
import ClockNight from "./feature/clockNight";
import Feedback from "./feature/feedback";

export type BackgroundType = Record<"default" | "clockMorning" | "clockEvening" | "clockNight", string>;

export const Background: BackgroundType =
{
	default: `url(${ missionFiveDefualt }) center center/cover`,
	clockMorning: `url(${ clockMorning }) center center/cover`,
	clockEvening: `url(${ clockEvening }) center center/cover`,
	clockNight: `url(${ clockNight }) center center/cover`
};
const backgrounds = [
	missionFiveDefualt,
	clockMorning,
	clockEvening,
	clockNight
];
const Components = [
	ChatPageMissionFive,
	RadialChart,
	Clock,
	RadialChartMorning,
	ClockMorning,
	RadialChartAfternoon,
	ClockAfternoon,
	RadialChartEvening,
	ClockEvening,
	RadialChartNight,
	ClockNight,
	FinishNight,
	ReviewDay,
	// FinishMission,
	Complete,
	Feedback
];

const STEPS = 5;

export const PROGRESS_STEP = 100 / STEPS;
function MissionFiveContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ step, setStep ] = useState<number>(() =>
	{
		const storageStep = localStorage.getItem("mission-five-step");
		if (storageStep !== null)
		{
			return Number(storageStep);
		}

		return 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");
	const [ name, setName ] = useState<string>("");
	const [ blurBg, setBlurBg ] = useState<boolean>(true);
	const [ isDuration, setIsDuration ] = useState<number>(300);

	const MissionTwoContext: IMissionContextFiveMission = useMemo(() =>
	{
		return {
			step,
			setStep,
			progress,
			setProgress,
			background,
			setBackground,
			name,
			setName,
			blurBg,
			setBlurBg,
			isDuration,
			setIsDuration
		};
	}, [ background, blurBg, isDuration, name, progress, step ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		if (userContext.state.user === null)
		{
			return;
		}

		Api.users
			.getUser(userContext.state.user.id)
			.then((user) =>
			{
				setName(user.name);
			});
	}, [ userContext.state.user, userContext.state.user?.id ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(5, step).catch((error) => console.error(error));

		setLocalStorage("mission-five-step", step);

		setProgress((step - 1) * (100 / (Components.length - 1)));
		switch (step)
		{
			case 4: {
				setBackground("clockMorning");
				break;
			}

			case 5: {
				setBackground("clockMorning");
				break;
			}

			case 8: {
				setBackground("clockEvening");
				break;
			}

			case 9: {
				setBackground("clockEvening");
				break;
			}

			case 10: {
				setBackground("clockNight");
				break;
			}

			case 11: {
				setBackground("clockNight");
				break;
			}

			case 12: {
				setBackground("clockNight");
				break;
			}

			default:
				setBackground("default");
		}
	}, [ step ]);

	useEffect(() =>
	{
		localStorage.setItem("mission-five-progress", String(progress));
	}, [ progress ]);

	useLayoutEffect(() =>
	{
		const userId = localStorage.getItem("userId");

		if (userId === null)
		{
			return;
		}

		Api.users
			.getUser(userId)
			.then((user) =>
			{
				setName(user.name);
			});
	}, []);

	// Handles
	return (
		<>
			{backgrounds.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<Box
				sx={{
					width: "100%",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					zIndex: "10",
					transition: `linear ${ isDuration }ms background`,
					overflow: "hidden"
				}}
			>
				<Box sx={{
					minHeight: "100vh",
					width: "100%",
					padding: { xl: step === 15 || step === 14 ? 0 : "24px", lg: step === 15 || step === 14 ? 0 : "16px", xs: step === 15 || step === 14 ? 0 : "16px" },
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: "25px",
					backdropFilter: blurBg ? "blur(30px)" : ""
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 14 || step === 15 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							display: step === 15 || step === 14 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "10px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px"
						}}>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={Components.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {Components.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
							{step !== 1 && step !== 12 && step !== 13 && step !== 14 &&
								<TooltipStyled title="Tips" placement="bottom" arrow>
									<TipsButton
										isActive={isBlipTips}
										blip={false}
										onClick={() => { setIsShowTips(true); setIsBlipTips(false); }}

									/>
								</TooltipStyled>
							}
							<MissionProgressBar value={progress} />
						</Box>
					</Box>

					<MissionCtx.Provider value={MissionTwoContext}>
						<Box
							className="mission-root"
							sx={{
								width: "100%",

								"& .TransitionGroup":
								{
									flexGrow: 1,
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									width: "100%"
								},
								"& .mission-main":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								},
								"& .main__content":
								{
									maxWidth: "980px",
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									gap: "20px"
								}
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								{Components.map((Component, index) =>
								{
									if (step !== index + 1)
									{
										return false;
									}

									return (
										// <Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
										<Component key={index} />
										// </Grow>
									);
								})}
							</Box>
						</Box>
						<MissionsDialog
							title="Instructions"
							open={isShowTips}
							onClose={() => setIsShowTips(false)}
							position="center"
						>
							<ContentAdmin keyContent="contentMissionFive" keyText="modal_helpers" defaultValue="**Why:** Creating a detailed vision of your desired lifestyle and environment helps you achieve this vision faster. This exercise will help you think about the details of your desired lifestyle and have more clarity of your dream life.\n\n**Do:** Describe your perfect average day filling in the details about your life. Click on the time of the day to fill out the details. Be as specific as possible in the answers.\n\n**Think:** There are no limitations (financial, geographical, health, etc.), do not list anything that would be illegal or break the law (as this wouldn’t last long), and make sure to describe an average day, stuff that you would do daily and never get tired of doing." position="right" isDescription />
						</MissionsDialog>
					</MissionCtx.Provider>
				</Box>
			</Box>
			<MobileContent />
		</>
	);
}

export default MissionFiveContainer;
