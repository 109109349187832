import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Career from "../../../../assets/images/Career.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function CareerDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Career} alt="Career" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Career
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="{NAME}, I am sorry to hear you aren’t so happy with your career. It is a fundamental aspect of life that impacts your overall sense of wellbeing. But don’t despair, no matter how far you feel from your perfect career, there are ways to fix that!" keyContent="contentMissionOne" keyText="careerScreen1" />
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="Maybe you only need to address a couple of specific issues to feel good about your career again. Take on a new exciting project, talk to your boss about stepping up or create healthy boundaries for a better work life balance.\\n\\nIt could also be you need a career change, within your field, or even outside of it. If you feel you’ve got untapped potential, it’s time to take a step back and strategise your future." keyContent="contentMissionOne" keyText="careerDescStart" />
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="Maybe you only need to address a couple of specific issues to feel good about your career again. Take on a new exciting project, talk to your boss about stepping up or create healthy boundaries for a better work life balance.\\n\\nIt could also be you need a career change, within your field, or even outside of it. If you feel you’ve got untapped potential, it’s time to take a step back and strategise your future." keyContent="contentMissionOne" keyText="careerDescStart" />
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Let me guess, {NAME}, you are probably overwhelmed with all the options out there. You could pursue so many paths. How do you even know which one to choose? The answer is simple — experimentation.\\nFirst, learn about yourself: understand your values, your yearnings, what fulfills you. You can then try lots of different things and see works for you best.\\nWe designed Life Strategy Quest to help you build a clear picture of who you are, what you want, and guide you through all the options to a purposeful life and a fulfilling career." keyContent="contentMissionOne" keyText="careerScreen2" />
				{/*
				Let me guess, {localStorage.getItem('name')}, you are probably overwhelmed with all the options out there. You could pursue so many paths. How do you even know which one to choose? The answer is simple — experimentation.<br></br><br></br>
				First, learn about yourself: understand your values, your yearnings, what fulfills you. You can then try lots of different things and see works for you best.<br></br><br></br>
				We designed Life Strategy Quest to help you build a clear picture of who you are, what you want, and guide you through all the options to a purposeful life and a fulfilling career.
				*/}
			</Typography>
		</Box>
	);
}

export default CareerDetails;
