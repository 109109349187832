import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Dialog, Slide, SlideProps, styled, SxProps, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, ReactNode } from "react";

const StyledDialog = styled(Dialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			backgroundColor: "#E9EDF0",
			padding: "50px 50px 30px 30px",
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "20px",
			maxWidth: "785px",
			boxShadow: "0px 5px 30px rgba(36, 92, 116, .15)",
			font: "400 22px/30px 'Open Sans'",
			color: "#4D4D4D",
			display: "block",

			backdropFilter: "blur(25px)"
		}
	}
);

const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionSlideDown = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionSlideRight = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="right" ref={ref} {...props} />;
});

const TransitionSlideLeft = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="left" ref={ref} {...props} />;
});

const TRANSITION =
{
	up: TransitionSlideUp,
	down: TransitionSlideDown,
	right: TransitionSlideRight,
	left: TransitionSlideLeft
};

export interface IDialogProps
{
	open: boolean;
	children: ReactNode;
	/**
	 * @default up
	 */
	directionSlide?: SlideProps[ "direction" ];
	title?: string | JSX.Element;
	position?: "bottom" | "center" | "bottomPx";
	sx?: SxProps<Theme>;
	onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton") => void) | null;
	noIcon?: boolean;
	[ prop: string ]: any;
}

export default function MissionsDialog({
	title,
	directionSlide = "up",
	position = "center",
	onClose = null,
	open = false,
	sx,
	children,
	noIcon = false,
	...props
}: IDialogProps)
{
	return (
		<StyledDialog
			className="MissionDialog-root"
			open={open}
			TransitionComponent={TRANSITION[ directionSlide ] || undefined}
			sx={[
				{
					"& .MuiDialog-container":
					{
						alignItems: (position === "center" ? "center" : "flex-end"),
						position: "relative"
					},
					"& .MuiDialog-paper":
					{
						marginBottom: (position === "bottom" ? "200px" : (position === "bottomPx" ? "10px" : "unset"))
					},
					"& .MuiPaper-root":
					{
						width: "auto"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			onClose={onClose || undefined}
			{...props}
		>
			{onClose !== null && noIcon === false &&
				<CancelIcon
					className="MissionDialog-cancel"
					onClick={() => onClose({}, "closeButton")}
					sx={{
						position: "absolute",
						top: "20px",
						right: "20px",
						fill: "#215C75",
						opacity: 1,
						"&:hover": { opacity: 0.3, cursor: "pointer" }
					}}
				/>
			}

			{title !== undefined &&
				// (typeof title === "string" || title?.type?.name === "ContentAdmin" ?
				// 	(
				<Box
					className="MissionDialog-title"
					sx={{
						color: "#215C75",
						font: "700 24px/31px Lora-Bold",
						marginBottom: "20px"
					}}
				>
					{title}
				</Box>
				// ) :
				// (
				// 	title
				// )
				// )
			}

			{children}
		</StyledDialog>
	);
}
