import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CelebrationScreen from "../../../../components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

export type ICelebrationScreen1Props = Record<string, any>;

function CelebrationScreen1({ ...props }: ICelebrationScreen1Props, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<CelebrationScreen
					content={<ContentAdmin keyContent={missionContext.keyContent} keyText="CelebrationScreen1_text" defaultValue="You are a legend, {NAME}. Are you ready to see what kind of person you are?" position="left" isDescription />}
					buttonText="Show me!"
					onClickButton={() => missionContext.nextStep()}
					sx={{ "& .CelebrationScreen-content": { color: "primary.main", textShadow: "unset" } }}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(CelebrationScreen1);
