import { Box, SxProps, Theme } from "@mui/material";
import { ForwardRefExoticComponent, Ref, forwardRef } from "react";

export interface IImageButtonProps
{
	value: JSX.Element | string;
	image: string;
	/**
	 * @deprecated
	 * @default 200
	 */
	size?: number;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

const DEFAULT_SIZE: number = 200;

function ImageButton({ value, image, size = DEFAULT_SIZE, sx = [], ...props }: IImageButtonProps, ref: Ref<HTMLDivElement>)
{
	return (
		<Box
			component="button"
			className="ImageButton-root"
			sx={[
				{
					"--size": size + "px",
					"--percent": "calc(var(--size) / " + DEFAULT_SIZE + ")",
					alignSelf: "flex-start",
					width: "var(--size)",
					padding: "unset",
					background: "unset",
					border: "unset",
					position: "relative",
					cursor: "pointer",

					"& .ImageButton-image":
					{
						position: "relative",
						zIndex: 2,
						backgroundColor: "#E9EDF0",
						backgroundImage: `url(${ image })`,
						backgroundSize: "cover",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						width: "var(--size)",
						height: "var(--size)",
						border: "calc(10 * var(--percent)) solid #E9EDF0",
						borderRadius: "50%",
						boxShadow: "-3px -3px 7px 0px #FFFFFFCC, 3px 3px 7px 0px #283F493D"
					},
					"& .ImageButton-value":
					{
						paddingTop: "0",
						paddingBottom: "0",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 300ms",
						opacity: 0,

						position: "absolute",
						zIndex: 1,
						top: "calc(90 * var(--percent))",
						left: "0",
						right: "0",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						padding: "calc(126 * var(--percent)) calc(10 * var(--percent)) calc(24 * var(--percent)) calc(10 * var(--percent))",
						color: "primary.main",
						font: "700 calc(24 * var(--percent))/calc(31 * var(--percent)) Lora-Bold",
						textAlign: "center",
						border: "1px solid #FFFFFF80",
						borderRadius: "calc(24 * var(--percent))",
						boxShadow: "0px 5px 10px 0px #A7C7E199",
						backdropFilter: "blur(25px)"
					},
					"&:hover .ImageButton-value":
					{
						opacity: 1,
						paddingTop: "calc(126 * var(--percent))",
						paddingBottom: "calc(24 * var(--percent))",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 0ms"
					}
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			ref={ref}
			{...props}
		>
			<div className="ImageButton-image" />

			<div className="ImageButton-value">
				{value}
			</div>
		</Box>
	);
}

export default forwardRef(ImageButton) as ForwardRefExoticComponent<IImageButtonProps>;
