import { Box, alpha } from "@mui/material";
import { ReactComponent as SVGIconCommunity } from "assets/images/icons/size-40/community.svg";
import { ReactComponent as SVGIconRefer } from "assets/images/icons/size-40/refer.svg";
import { ReactComponent as SVGIconSupport } from "assets/images/icons/size-40/support.svg";
import ReferFriendsModal from "components/Dialog/ReferFriends.modal";
import TabPanel from "components/TabPanel";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { useState } from "react";
import AsidePanel, { ITabsItem } from "../aside";
import Community from "./community";
import IMGOcta from "./octa.png";
import Personalized from "./personalized";

interface ISupportsProps
{
	selectedTab: string | undefined;
}

const TABS: ITabsItem[] = [
	{
		to: "/dashboard/supports/",
		value: "personalized",
		// label: "Personalized support",
		label: <ContentAdmin keyContent="contentDashboard" keyText="tab_personalized" defaultValue="Personalized support" position="left" />,
		icon: <SVGIconSupport />
	},
	{
		to: "/dashboard/supports/community",
		value: "community",
		label: <ContentAdmin keyContent="contentDashboard" keyText="tab_community" defaultValue="Community" position="left" />,
		icon: <SVGIconCommunity />
	}
];

function Supports({ selectedTab = "personalized" }: ISupportsProps)
{
	const [ isOpenShare, setIsOpenShare ] = useState<boolean>(false);

	// Render
	return (
		<>
			<Box sx={{
				height: { xl: "860px", lg: "676px", xs: "676px" },
				display: "flex",
				gap: { xl: "40px", lg: "24px", xs: "24px" },
				maxWidth: "1792px"
			}}>
				<AsidePanel
					header={
						(<Box
							component="img"
							src={IMGOcta}
							alt=""
							sx={{
								display: "block",
								margin: "0 auto",
								width: { xl: "333px", lg: "239px", xs: "239px" },
								height: { xl: "186px", lg: "133px", xs: "133px" }
							}}
						/>)
					}
					title={<ContentAdmin position="right" defaultValue="Community & Support" keyContent="contentDashboard" keyText="communitySupport" />}
					tabs={TABS}
					selectedTab={selectedTab}
					navigationProps={{ "data-tut": "reactour__support_aside" }}
				>
					<Box sx={{ margin: { xl: "auto 32px 32px 32px", lg: "auto 20px 24px 16px", xs: "auto 20px 24px 16px" } }}>
						<CustomButton.Base
							onClick={() => setIsOpenShare(true)}
							sx={{
								minWidth: "unset",
								width: "100%",
								gap: "16px",

								"& svg":
								{
									width: "unset",
									height: "unset",

									"& *":
									{
										transition: "linear 300ms fill, linear 0ms stroke",
										stroke: "unset"
									}
								},
								"&:hover svg *":
								{
									fill: "#215c75",
									stroke: "unset"
								}
							}}
						>
							<Box
								component={SVGIconRefer}
								sx={{
									width: { xl: "40px !important", lg: "24px !important", xs: "24px !important" },
									height: { xl: "40px !important", lg: "24px !important", xs: "24px !important" }
								}}
							/>
							Refer a friend
						</CustomButton.Base>
					</Box>
				</AsidePanel>

				<Box sx={{
					padding: { xl: "72px", lg: "40px", xs: "40px" },
					width: "100%",
					height: "100%",

					backgroundColor: alpha("#FFFFFF", 0.5),
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px",

					"& > [role='tabpanel']":
					{
						height: "100%",
						overflowY: "auto",
						marginRight: "-16px",
						paddingRight: "16px"
					}
				}}>
					<TabPanel className="customScroll" value={selectedTab} index="personalized" active="personalized">
						<Personalized />
					</TabPanel>

					<TabPanel className="customScroll" value={selectedTab} index="community" active="community">
						<Community />
					</TabPanel>
				</Box>
			</Box>

			<ReferFriendsModal
				open={isOpenShare}
				onClose={() => setIsOpenShare(false)}
			/>
		</>
	);
}

export { Community, Personalized, Supports as default };
