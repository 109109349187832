import { Box, alpha, keyframes } from "@mui/material";
import Api from "api";
import Shares from "assets/images/Shares.svg";
import bgOpenMissionEight from "assets/images/bgOpenMissionEight.png";
import bgOpenMissionFive from "assets/images/bgOpenMissionFive.png";
import bgOpenMissionFour from "assets/images/bgOpenMissionFour.png";
import bgOpenMissionNine from "assets/images/bgOpenMissionNine.png";
import bgOpenMissionSeven from "assets/images/bgOpenMissionSeven.png";
import bgOpenMissionSix from "assets/images/bgOpenMissionSix.png";
import bgOpenMissionTen from "assets/images/bgOpenMissionTen.png";
import bgOpenMissionThree from "assets/images/bgOpenMissionThree.png";
import bgOpenMissionTwo from "assets/images/bgOpenMissionTwo.png";
import bgOpenOneMission from "assets/images/bgOpenOneMission.png";
import iconMissionEight from "assets/images/iconMissionEight.svg";
import iconMissionEleven from "assets/images/iconMissionEleven.svg";
import iconMissionFive from "assets/images/iconMissionFive.svg";
import iconMissionFour from "assets/images/iconMissionFour.svg";
import iconMissionNine from "assets/images/iconMissionNine.svg";
import iconMissionSeven from "assets/images/iconMissionSeven.svg";
import iconMissionSix from "assets/images/iconMissionSix.svg";
import iconMissionTen from "assets/images/iconMissionTen.svg";
import iconMissionThree from "assets/images/iconMissionThree.svg";
import iconMissionTwo from "assets/images/iconMissionTwo.svg";
import ContentAdmin from "components/contentAdmin";
import useUser from "hooks/useUser";
import { Navigate, useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import Belief from "./Belief";
import MyValues from "./MyValues";
import SVGNorthStar from "./assets/icons/north-star.svg";
import IMGBackground from "./assets/images/background.png";
import IMGLineLg from "./assets/images/line-lg.svg";
import IMGLineMd from "./assets/images/line-md.svg";
import IMGLine from "./assets/images/line.svg";
import Career from "./career";
import ImageButton from "./components/ImageButton";
import Experiments from "./experiments";
import Fears from "./fears";
import LetterYourself from "./letter-yourself";
import MyLifePlan from "./myLifePlan";
import NorthStar from "./northStar";
import PerfectDay from "./perfectDay";
import VisionBoard from "./vision-board";
import WheelOfLife from "./wheel-of-life";
import Yearning from "./yearning";

interface IResourceProps
{
	selectedResource: string | undefined;
}

interface IButtonData
{
	position: [ top: Record<"xl" | "lg" | "xs", string>, left: Record<"xl" | "lg" | "xs", string> ];
	width: number;
	image: string;
	value: string;
	color: string;
	hideImage: boolean;
	page: string;
}

const arrBg = [
	bgOpenOneMission,
	bgOpenMissionTwo,
	bgOpenMissionThree,
	bgOpenMissionFour,
	bgOpenMissionFive,
	bgOpenMissionSix,
	bgOpenMissionSeven,
	bgOpenMissionEight,
	bgOpenMissionNine,
	bgOpenMissionTen
];

const pulseAnimation = keyframes`
	0%, 100% { transform: scale(1); }
	50% { transform: scale(1.1); }
`;

function Resources({ selectedResource }: IResourceProps)
{
	const userContext = useUser();
	const navigate = useNavigate();

	const completedMission: number = userContext.state.user?.completedMission || 0;

	switch (selectedResource)
	{
		case "wheel-of-life": return <WheelOfLife />;
		case "my-values": return <MyValues />;
		case "yearning": return <Yearning />;
		case "career": return <Career />;
		case "perfect-day": return <PerfectDay />;
		case "vision-board": return <VisionBoard />;
		case "belief": return <Belief />;
		case "fears": return <Fears />;
		case "north-star": return <NorthStar />;
		case "my-life-plan": return <MyLifePlan />;
		case "experiments": return <Experiments />;
		case "letter-yourself": return <LetterYourself />;
		case undefined: break;
		default: return <Navigate to="/dashboard/artifacts" />;
	}

	const buttonsData: IButtonData[] = [
		{
			position: [
				{ xl: "364px", lg: "285px", xs: "274px" },
				{ xl: "53px", lg: "85px", xs: "66px" }
			],
			width: 73,
			image: Shares,
			value: "Wheel of life",
			color: "#C49AC1",
			hideImage: false,
			page: "/dashboard/artifacts/wheel-of-life"
		},
		{
			position: [
				{ xl: "107px", lg: "85px", xs: "65px" },
				{ xl: "192px", lg: "185px", xs: "136px" }
			],
			width: 70,
			image: iconMissionTwo,
			value: "Values",
			color: "#B3D3C6",
			hideImage: (completedMission <= 1),
			page: "/dashboard/artifacts/my-values"
		},
		{
			position: [
				{ xl: "370px", lg: "289px", xs: "276px" },
				{ xl: "347.21px", lg: "284.24px", xs: "227px" }
			],
			width: 84,
			image: iconMissionThree,
			value: "Yearnings",
			color: "#76C0CF",
			hideImage: (completedMission <= 2),
			page: "/dashboard/artifacts/yearning"
		},
		{
			position: [
				{ xl: "628px", lg: "482px", xs: "443px" },
				{ xl: "465px", lg: "350px", xs: "273px" }
			],
			width: 63,
			image: iconMissionFour,
			value: "Career",
			color: "#7AB1B8",
			hideImage: (completedMission <= 3),
			page: "/dashboard/artifacts/career"
		},
		{
			position: [
				{ xl: "550px", lg: "407px", xs: "358px" },
				{ xl: "702px", lg: "505px", xs: "408px" }
			],
			width: 70,
			image: iconMissionFive,
			value: "Perfect day",
			color: "#9EC2BE",
			hideImage: (completedMission <= 4),
			page: "/dashboard/artifacts/perfect-day"
		},
		{
			position: [
				{ xl: "294px", lg: "206px", xs: "192px" },
				{ xl: "748px", lg: "530px", xs: "425px" }
			],
			width: 59,
			image: iconMissionSix,
			value: "Vision Board",
			color: "#BC9FA2",
			hideImage: (completedMission <= 5),
			page: "/dashboard/artifacts/vision-board"
		},
		{
			position: [
				{ xl: "61px", lg: "31px", xs: "38px" },
				{ xl: "844px", lg: "592px", xs: "444px" }
			],
			width: 58,
			image: iconMissionSeven,
			value: "Beliefs",
			color: "#949CB1",
			hideImage: (completedMission <= 6),
			page: "/dashboard/artifacts/belief"
		},
		{
			position: [
				{ xl: "69px", lg: "44px", xs: "14px" },
				{ xl: "1114px", lg: "779px", xs: "584px" }
			],
			width: 50,
			image: iconMissionEight,
			value: "Fears",
			color: "#BFC3CE",
			hideImage: (completedMission <= 7),
			page: "/dashboard/artifacts/fears"
		},
		{
			position: [
				{ xl: "289px", lg: "210px", xs: "157px" },
				{ xl: "1187px", lg: "796px", xs: "638px" }
			],
			width: 63,
			image: SVGNorthStar,
			value: "North Star",
			color: "#6E9AC2",
			hideImage: (completedMission <= 8),
			page: "north-star"
		},
		{
			position: [
				{ xl: "509px", lg: "377px", xs: "309px" },
				{ xl: "1192px", lg: "793px", xs: "619px" }
			],
			width: 63,
			image: iconMissionNine,
			value: "Life Plan",
			color: "#D0B2A7",
			hideImage: (completedMission <= 8),
			page: "my-life-plan"
		},
		{
			position: [
				{ xl: "602px", lg: "453px", xs: "412px" },
				{ xl: "1420px", lg: "920px", xs: "721px" }
			],
			width: 48,
			image: iconMissionTen,
			value: "Experiments",
			color: "#AF91A5",
			hideImage: (completedMission <= 9),
			page: "/dashboard/artifacts/experiments"
		},
		{
			position: [
				{ xl: "316px", lg: "254px", xs: "251px" },
				{ xl: "1538px", lg: "1007px", xs: "797px" }
			],
			width: 67,
			image: iconMissionEleven,
			value: "Letter to yourself",
			color: "#937AB1",
			hideImage: (completedMission <= 10),
			page: "/dashboard/artifacts/letter-yourself"
		}
	];

	// Handles
	function seenArtifact(index: number)
	{
		Api.missions
			.handleNotificationArtifact(index)
			.then((unseenList) =>
			{
				userContext.dispatch(
					{
						type: UserActionType.UPDATE_STATE,
						payload:
						{
							user:
							{
								notificationArtifact: unseenList
							}
						}
					}
				);
			})
			.catch((error) =>
			{
				seenArtifact(index);
				console.error(error);
			});
	}

	// Render
	return (
		<Box
			className="ArtifactsContainer-root"
			sx={[
				{
					marginTop: { xl: "-24px", lg: "-8px", xs: "-8px" },
					marginLeft: { xl: "32px", lg: "24px", xs: "24px" },
					maxWidth: { xl: "1760px", lg: "1165px", xs: "914px" },

					"& .ArtifactsContainer-header":
					{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "24px",
						width: "100%"
					},
					"& .ArtifactsContainer-header__title":
					{
						color: "#215C75",
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 32px/41px Lora-Bold", xs: "700 32px/41px Lora-Bold" }
					},
					"& .ArtifactsContainer-header__buttons":
					{
						display: "flex",
						gap: "24px",
						marginBottom: "-5px"
					},
					"& .ArtifactsContainer-content":
					{
						position: "relative",
						marginTop: { xl: "24px", lg: "16px", xs: "16px" },
						width: "100%",
						height: { xl: "809px", lg: "627px", xs: "570px" },
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						background: `url(${ IMGBackground }) center center/cover`,
						backgroundColor: alpha("#FFFFFF", 0.5),
						backdropFilter: "blur(30px)",
						border: "2px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px"
					}
				}
			]}
		>
			<Box className="ArtifactsContainer-header__title">
				<ContentAdmin position="left" defaultValue="My artifacts" keyContent="contentDashboard" keyText="my_resources" />
			</Box>

			<Box className="ArtifactsContainer-content">
				<Box sx={{
					height: "100%",
					width: "100%",
					background: `url(${ arrBg[ completedMission - 1 ] })`,
					backgroundSize: completedMission === 10 ? "auto" : "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: completedMission === 9 || completedMission === 10 ? "right" : "10% 50%",
					borderRadius: "24px"
				}}>
					<Box
						sx={{
							backgroundImage:
							{
								xl: `url(${ IMGLine })`,
								lg: `url(${ IMGLineLg })`,
								xs: `url(${ IMGLineMd })`
							},
							backgroundSize: "cover",
							position: "absolute",
							top:
							{
								xl: "103px",
								lg: "55.41px",
								xs: "55px"
							},
							left:
							{
								xl: "119px",
								lg: "129.33px",
								xs: "109px"
							},
							width:
							{
								xl: "1486px",
								lg: "925.38px",
								xs: "740px"
							},
							height:
							{
								xl: "640px",
								lg: "495px",
								xs: "455px"
							}
						}}
					/>

					{buttonsData.map((button, index) =>
					{
						const isUnseen: boolean = userContext.state.user?.notificationArtifact?.includes(index + 1) || false;

						return (
							<Box
								key={button.value}
								data-tut={"artifact-" + index}
								sx={{
									position: "absolute",
									top: button.position[ 0 ],
									left: button.position[ 1 ],
									zIndex: 2
								}}
							>
								<ImageButton
									disabled={button.hideImage === true}
									image={button.image}
									size={129}
									value={button.value}
									showImage={button.hideImage}
									width={button.width}
									onClick={() =>
									{
										if (isUnseen === true)
										{
											seenArtifact(index + 1);
										}

										navigate(button.page);
									}}
									sx={[
										{
											"--size":
											{
												xl: 129, lg: 88, xs: 88
											},
											"& .ImageButton-image":
											{
												backgroundColor: button.hideImage === true ? "#B6CEC9" : button.color
											}
										},
										(button.hideImage === true) &&
										{
											"& .ImageButton-value":
											{
												display: "none"
											}
										},
										(isUnseen === true) &&
										{
											"& .ImageButton-image":
											{
												boxShadow: "0px 0px 4.95924px #FFFFFF, 0px 0.727132px 12.6736px #FFD25E"
											}
										},
										(button.hideImage === false && index === 1 && userContext.state.user?.onboarded === false) &&
										{
											animation: pulseAnimation + " linear 2s infinite"
										}
									]}
								/>
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}

export { Resources as default };
