import { Box } from "@mui/material";
import OсtaStripe from "assets/images/OсtaStripe.png";
import Dialog from "components/Dialog";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import IMGOctaModal from "../assets/images/octa-modal.png";

interface DialogResultPaymentProps
{
	stripeStatus: boolean;
	setStripeStatus(val: boolean): void;
}

function DialogResultPayment({ stripeStatus, setStripeStatus }: Readonly<DialogResultPaymentProps>)
{
	const { state: { user } } = useUser();
	const [ URLSearchParams ] = useSearchParams();
	const navigate = useNavigate();

	const isSuccessPayment = URLSearchParams.get("status") === "success";
	// const isSubscription = user?.subscriptionIsActive ?? false;
	const isFullSubscription = user?.fullSubscribe ?? false;

	// MARK: Handles
	function closeDialogStripeStatus()
	{
		if (isSuccessPayment)
		{
			setStripeStatus(false);
			navigate("/dashboard");
		}
		else
		{
			setStripeStatus(false);
		}
	}

	// MARK: Render
	return (
		<Dialog
			title={
				<ContentAdmin
					limit={300}
					keyContent="contentDashboard"
					keyText={
						isSuccessPayment
							? (isFullSubscription ? "dialogSuccessStripeFullSubscription" : "dialogSuccessStripeSubscription")
							: "dialogSuccessStripeCancel"
					}
					defaultValue={
						isSuccessPayment
							? (isFullSubscription ? "You have unlocked the full quest" : "Your subscription is activated")
							: "Your payment was declined"
					}
					position="left"
					isDescription
				/>
			}
			open={stripeStatus}
			onClose={() => setStripeStatus(false)}
			sx={{
				"& .MuiDialog-paper":
				{
					minHeight: { sm: "425px", xs: "330px" },

					"& *:not(img)":
					{
						zIndex: 1
					}
				}
			}}
		>
			<Box sx={{
				marginTop: "24px",
				width: { sm: "568px", xs: "224px" },
				font: { sm: "400 22px/30px 'Open Sans'", xs: "400 16px/20px 'Open Sans'" },
				color: "primary.dark"
			}}>
				{isSuccessPayment
					? (
						<ContentAdmin
							limit={300}
							keyContent="contentDashboard"
							keyText="dialogSuccessStripeDesc"
							defaultValue="Congratulations, {NAME}! Your payment has been successful and you now have access to the full quest and all the tools."
							position="left"
							isDescription
						/>
					)
					: (
						<ContentAdmin
							limit={300}
							keyContent="contentDashboard"
							keyText="dialogSuccessStripeCancelDesc"
							defaultValue="{NAME}, unfortunately, your payment didn't go through. Please try again using a different payment method."
							position="left"
							isDescription
						/>
					)
				}
			</Box>

			<Box sx={{
				flexGrow: 1,
				display: "flex",
				alignItems: "flex-end",
				marginTop: "24px"
			}}>
				<CustomButton.Contained
					onClick={closeDialogStripeStatus}
					sx={{
						width: { sm: "auto", xs: "100%" }
					}}
				>
					{isSuccessPayment ? "Go to Quest" : "View packages"}
				</CustomButton.Contained>
			</Box>

			<Box
				component="img"
				src={OсtaStripe}
				alt=""
				sx={{
					width: "230px",
					position: "absolute",
					top: "18px",
					right: "116px",

					display: { md: "block", xs: "none" }
				}}
			/>

			<Box
				component="img"
				src={IMGOctaModal}
				alt=""
				sx={{
					width: "124px",
					position: "absolute",
					bottom: "0px",
					right: "0px",
					display: { sm: "none" }
				}}
			/>
		</Dialog>
	);
}

export default DialogResultPayment;
