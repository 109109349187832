import Api, { getAccessToken } from "api";

export interface DataCreateCoupon
{
	id: string;
	percentOff: number;
	redeemBy: string;
	name: string;
}

export interface ResGetCoupons
{
	id: string;
	percentOff: number;
	redeemBy: string;
	status: boolean;
	name: string;
}
export class Coupons
{
	async createCoupon(data: DataCreateCoupon)
	{
		return (
			await Api.instance.post<string>(
				"/coupons/create",
				{ data },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async deleteCoupon(id: string)
	{
		return (
			await Api.instance.post<string>(
				"/coupons/delete",
				{ id },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getCoupons()
	{
		return (
			await Api.instance.get<ResGetCoupons[]>(
				"/coupons/getCoupons",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async checkCoupon(id: string)
	{
		return (
			await Api.instance.post<ResGetCoupons>(
				"/coupons/checkCoupon",
				{ id },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
