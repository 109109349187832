import { Box, Button, styled, SxProps, Theme } from "@mui/material";
import { MouseEvent, useLayoutEffect, useState } from "react";
import { ReactComponent as SVGBall } from "../../assets/images/icons/ball.svg";
import { ReactComponent as SVGselectedBallExperiments } from "../../assets/images/missions/mission-eight/svgSelected.svg";
import { mergeDeep } from "../../utils/mergeDeep";

const ButtonStyled = styled(Button)(() => (
	{
		position: "relative",
		padding: "0",
		minHeight: "55px",
		"&:hover":
		{
			backgroundColor: "unset"
		}
	}
)) as typeof Button;

interface IRadialButtonProps
{
	srcIcon?: string;
	value?: string;
	showValue?: boolean;
	absoluteValuePosition?: boolean;
	direction?: "right" | "left";
	blip?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	color?: "white" | "blue";
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	disabled?: boolean;
	cursorBallButton?: string;
	topBallButtonPosition?: string;
	leftBallButtonPosition?: string;
	sx?: SxProps<Theme>;
	selected?: boolean;
	[ key: string ]: any;
}

function BallButton(
	{
		srcIcon,
		value,
		showValue = true,
		absoluteValuePosition = false,
		direction = "right",
		blip = false,
		blipDurationMs = 2000,
		offBlipOnClick = true,
		color = "white",
		onClick,
		className,
		disabled = false,
		cursorBallButton,
		topBallButtonPosition,
		leftBallButtonPosition,
		selected,
		sx,
		...props
	}: IRadialButtonProps
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);
	const [ isSelected, setIsSelected ] = useState<boolean>(false);

	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (isBlip === true)
	{
		classList.push("animation--blip-radialButton");
	}

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<ButtonStyled
			disableRipple
			disabled={disabled}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
					setIsSelected(true);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={
				mergeDeep({
					margin: (showValue === false ? "11.5px 0" : direction === "right" ? "11.5px 0 11.5px 40px" : "11.5px 40px 11.5px 0")
				}, sx)
			}
			{...props}
		>
			<Box
				sx={{
					position: "absolute",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "121px",
					height: "121px",
					borderRadius: "50%",
					opacity: (showValue === false && disabled === true ? ".5" : "1"),
					zIndex: 2,
					// left: (direction === "right" ? "-67px" : "unset"),
					right: (direction === "left" ? "-67px" : "unset"),
					"--duration": blipDurationMs + "ms",
					cursor: cursorBallButton,
					top: topBallButtonPosition || "-37.5px",
					left: leftBallButtonPosition || (direction === "right" ? "-67px" : "unset")
				}}
				className={classList.join(" ")}
			>
				{isSelected ?
					<Box sx={{
						width: "130px",
						height: "130px"
					}}>
						<SVGselectedBallExperiments />
					</Box>

					:
					<SVGBall />
				}
			</Box>
		</ButtonStyled>
	);
}

export default BallButton;
