import { Box } from "@mui/material";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import { DragEvent, useState } from "react";
import { useLocalStorage } from "utils/localStorage";
import useMission from "../../hooks/useMission";
import { items } from "../AnythingMissing";
import { ListOfValuesData } from "../ListOfValues";

const MIN_ITEMS: number = 5;

function PrioritiesYourValues()
{
	const missionContext = useMission();

	const [ , setItemsList ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-itemsList", items);
	const [ listOfValues, setListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ currentItem, setCurrentItem ] = useState<null | number>(null);

	// Handles
	function onDeleteValue(index: number)
	{
		return () =>
		{
			setListOfValues((prevState) =>
			{
				const values = [ ...prevState ];
				const value = values[ index ];

				setItemsList((prevState) => Array.from(new Set([ ...prevState, value ])));
				values.splice(index, 1);

				return values;
			});
		};
	}

	function onDragStart(index: number)
	{
		return () =>
		{
			setCurrentItem(index);
		};
	}

	function onDragOver(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function onDrop(index: number)
	{
		if (currentItem === null)
		{
			return;
		}

		setListOfValues((prevState) =>
		{
			const values = [ ...prevState ];
			const value = values[ currentItem ];

			values.splice(currentItem, 1);
			values.splice(index, 0, value);

			return values;
		});

		setCurrentItem(null);
	}

	function onDragEnd()
	{
		setCurrentItem(null);
	}

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<PanelTitle
				positionOcta
				imageVariation="closeEye"
				title={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="PrioritiesYourValues_title"
					defaultValue="Let’s prioritise your values"
					position="right"
				/>}
				subTitle={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="PrioritiesYourValues_subTitle"
					defaultValue="Phew, {NAME}, you made it! One last step before you go, let’s place your values in order of priority. Simply drop each value in the right spot."
					isDescription
					position="right"
				/>}
				sx={{
					marginBottom: "41px",

					"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
					"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
				}}
			/>

			<Panel sx={{
				height: { xl: "512px", lg2: "434px", xs: "356px" },
				width: { xl: "740px", lg: "100%", xs: "100%" },
				margin: "0 auto",
				display: "grid",
				gridTemplateRows: "auto 1fr",
				gap: { xl: "24px", lg: "16px", xs: "16px" },
				padding: { xl: "24px 24px 24px 32px", lg: "16px", xs: "16px" }
			}}>
				<Box sx={{
					color: "primary.main",
					font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
					textAlign: "center"
				}}>
					My values
				</Box>

				<Box
					className="customScroll"
					component="ol"
					sx={{
						counterReset: "list",
						margin: "unset",
						padding: "unset",
						paddingRight: "8px",
						paddingBottom: "5px",
						height: "100%",
						overflowY: "auto",
						display: "grid",
						gridTemplateColumns: "repeat(3, calc(33% - 9px))",
						alignContent: "flex-start",
						gap: "16px",

						"& li":
						{
							counterIncrement: "list",
							listStyle: "none",
							display: "flex",
							alignItems: "center",
							gap: "3px",

							"&::before":
							{
								display: "block",
								content: "counter(list) '.'",
								width: "30px",
								flexShrink: 0,
								font: "400 22px/30px 'Open Sans'",
								// font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
								textAlign: "right"
							}
						}
					}}
				>
					{listOfValues.map((value, index) =>
					{
						return (
							<li key={index}>
								<ChipStyled
									label={value}
									onDelete={listOfValues.length > MIN_ITEMS ? onDeleteValue(index) : undefined}
									draggable
									onDragStart={onDragStart(index)}
									onDragOver={onDragOver}
									onDrop={() => onDrop(index)}
									onDragEnd={onDragEnd}
									sx={{
										maxWidth: "calc(100% - 33px)",
										cursor: "grab",
										textTransform: "capitalize"
									}}
								/>
							</li>
						);
					})}
				</Box>
			</Panel>

			<CustomButton.Contained
				size="large"
				onClick={() => missionContext.nextStep()}
				sx={{
					display: "flex",
					margin: "24px auto 0 auto"
				}}
			>
				Submit
			</CustomButton.Contained>
		</Box>
	);
}

export default PrioritiesYourValues;
