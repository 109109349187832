import { Box } from "@mui/material";
import CelebrationScreen from "components/missions/CelebrationScreen";
import { useNavigate } from "react-router-dom";

function ResultCelebration()
{
	const navigate = useNavigate();

	return (
		<Box sx={{
			padding: "0 24px",
			display: "flex",
			flexGrow: 1
		}}>
			<CelebrationScreen
				content={<>Woo-hoo, your report is here.<br />Are you ready to see the results?</>}
				buttonText="I am ready!"
				onClickButton={() => navigate("/mission-one/results")}
				sx={(theme) =>
				{
					return {
						"& .CelebrationScreen-content":
						{
							color: "primary.main",
							textShadow: "unset"
						},
						[ theme.breakpoints.down("lg") ]:
						{
							marginTop: { xs: "unset !important" }
						}
					};
				}}
			/>
		</Box>
	);
}

export default ResultCelebration;
