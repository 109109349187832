import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { ReactComponent as SVGArrow } from "assets/images/icons/chatAccordion-arrow.svg";
import ContentAdmin from "components/contentAdmin";
import useMission from "pages/missionTwo/hooks/useMission";
import { useState } from "react";

function ChatAccordion()
{
	const missionContext = useMission();
	const [ expanded, setExpanded ] = useState([ false, false ]);

	function handleChange(key: number)
	{
		setExpanded(expanded.map((item, index) =>
		{
			return index === key ? !item : item;
		}));
	}

	return (
		<Box sx={{
			"& .MuiAccordion-root":
			{
				background: "none",
				boxShadow: "none",
				"&::before": { display: "none" },
				"&.Mui-expanded": { margin: 0 }
			},
			"& .MuiAccordionSummary-root":
			{
				minHeight: 0,
				padding: 0,

				"&.Mui-expanded":
				{
					minHeight: "unset"
				}
			},
			"& .MuiAccordionSummary-content":
			{
				margin: 0,
				padding: 0,
				"&.Mui-expanded": { margin: 0 }
			},
			"& .MuiAccordionDetails-root":
			{
				padding: "20px 0"
			},
			"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
			{
				margin: 0,
				transform: "rotate(-90deg)"
			}
		}}>
			<Accordion expanded={expanded[ 0 ]} onChange={() => handleChange(0)}>
				<AccordionSummary expandIcon={<SVGArrow />}>
					<Box sx={{
						color: "#4D4D4D",
						font: { xl: "700 22px/30px 'Open Sans'", xs: "700 18px/30px 'Open Sans'" }
					}}>
						<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_9_accordion_title_1" defaultValue="Constructive vs Destructive" />
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{
						color: "#4D4D4D",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }
					}}>
						<ContentAdmin
							keyContent={missionContext.keyContent}
							keyText="screen_9_accordion-details-1"
							defaultValue="You don’t want to value things that might harm you or others. It’s healthier to value things that enhance us and other people.\n\nFor example, pleasure can be an important part of life, but if you make feeling good all the time your priority, you can run into problems."
							isDescription
						/>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Box component="hr" sx={{ border: "none", borderBottom: "1px solid #707070", margin: "20px 0" }} />

			<Accordion expanded={expanded[ 1 ]} onChange={() => handleChange(1)}>
				<AccordionSummary expandIcon={<SVGArrow />}>
					<Box sx={{
						color: "#4D4D4D",
						font: { xl: "700 22px/30px 'Open Sans'", xs: "700 18px/30px 'Open Sans'" }
					}}>
						<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_9_accordion_title_2" defaultValue="Controllable vs Uncontrollable" />
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{
						color: "#4D4D4D",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }
					}}>
						<ContentAdmin
							keyContent={missionContext.keyContent}
							keyText="screen_9_accordion-details-2"
							defaultValue="If you value what’s out of your control, you give up on your life. Money is a great example of uncontrollable value. If economy collapses, you will use a lot more than just money, but your perceived purpose of living too. You should pick values you can control, otherwise your values will control you."
							isDescription
						/>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}

export default ChatAccordion;
