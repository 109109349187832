import { Box, Fade, Snackbar, TextField, Typography, type AlertColor } from "@mui/material";
import Api from "api";
import type { ResGetCoupons } from "api/Coupons";
import AlertStyled from "components/AlertStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { useState, type Dispatch, type ReactNode, type SetStateAction } from "react";

interface CheckCouponProps
{
	setIsCheckCoupon: Dispatch<SetStateAction<boolean>>;
	setCoupon: Dispatch<SetStateAction<ResGetCoupons | undefined>>;
}
function CheckCoupon({ setIsCheckCoupon, setCoupon }: CheckCouponProps)
{
	const [ code, setCode ] = useState<string>("");
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<boolean>(false);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);

	function checkCoupon()
	{
		if (code.length === 0)
		{
			setErrorMessage(true);

			return;
		}

		Api.coupons
			.checkCoupon(code)
			.then((data) =>
			{
				setSnackbarContent({ severity: "success", content: "success" });
				setCoupon(data);
				setIsCheckCoupon(false);
			})
			.catch((err) =>
			{
				console.error(err);
				setSnackbarContent({ severity: "error", content: "There is no such coupon" });
			})
			.finally(() => setIsLoading(false));
	}

	// Render
	return (
		<Box>
			<Typography sx={{ font: "400 16px/32px 'Open Sans'", mt: 3 }}>
				Enter coupon
			</Typography>
			<TextField
				placeholder="Code"
				value={code}
				fullWidth
				onChange={({ target }) =>
				{
					setCode(target.value.trim());
					setErrorMessage(false);
				}}

			/>

			<Fade in={errorMessage}>
				<Box sx={{
					paddingLeft: "5px",
					color: "#DC4242",
					font: "400 14px/19px 'Open Sans'"
				}}>
					Enter the code
				</Box>
			</Fade>
			<Box sx={{
				display: "flex",
				justifyContent: "space-between",
				marginTop: 4,
				width: "100%",
				gap: 2
			}}>
				<CustomButton.Outline
					onClick={() => setIsCheckCoupon(false)
					}
					sx={{ flexGrow: 1 }}
				>
					Cancel
				</CustomButton.Outline>
				<CustomButton.Contained
					sx={{ flexGrow: 1 }}
					loading={isLoading}
					onClick={checkCoupon}
				>
					Apply
				</CustomButton.Contained>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={30000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}

			>
				<AlertStyled sx={{ maxHeight: "70px" }} onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

export default CheckCoupon;
