import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CelebrationScreen from "../../../../components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

export type IYouTopLimitingBeliefsCelebrationProps = Record<string, any>;

function YouTopLimitingBeliefsCelebration({ ...props }: IYouTopLimitingBeliefsCelebrationProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative" }}>
				<CelebrationScreen
					content={<ContentAdmin keyContent="contentMissionSeven" keyText="YouTopLimitingBeliefsCelebration_text" defaultValue="Those are some good beliefs to work on!" position="left" />}
					buttonText="Let’s do it"
					onClickButton={() => { missionContext.nextStep(); }}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(YouTopLimitingBeliefsCelebration);
