import { Navigate, Outlet, useLocation } from "react-router-dom";
import useUser from "./hooks/useUser";

export interface IUnauthorizedRouteProps
{
	readonly redirectPath?: string;
	readonly children?: JSX.Element;
}

function UnauthorizedRoute({ redirectPath = "/dashboard", children }: IUnauthorizedRouteProps)
{
	const userContext = useUser();
	const location = useLocation();

	if (userContext.state.isAuthenticated === true)
	{
		return (<Navigate to={redirectPath} replace state={{ from: location }} />);
	}

	return children ?? <Outlet />;
}

export default UnauthorizedRoute;
