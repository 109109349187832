import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import IMGCast from "../../../../../assets/images/missions/mission-eight/cast-con.png";
import smile from "../../../../../assets/images/missions/mission-eight/smile.svg";
import smileBad from "../../../../../assets/images/missions/mission-eight/smileBad.svg";
import smileGood from "../../../../../assets/images/missions/mission-eight/smileGood.svg";

interface SliderBlockPorps
{
	onClose(): void;
	value: number;
	handleChangeGhostValues: any;
	title: any;
}

function SliderBlock({ onClose, value, handleChangeGhostValues, title }: SliderBlockPorps)
{
	return (
		<Box sx={{ display: "flex", height: "calc(100vh - 75px) ", flexDirection: "column", justifyContent: "center", ml: "120px" }}>
			<Box sx={{
				display: "flex",
				width: "calc(100% - 75px)",
				maxWidth: "665px",
				minHeight: "121px",
				margin: "0 auto",
				border: "1px solid rgba(255, 255, 255, .6)",
				borderRadius: "24px",
				flexDirection: "column",
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
				backdropFilter: "blur(25px)",
				boxShadow: "0px 5px 10px 0px #A7C7E199",
				position: "relative"
			}}>
				<Box
					component="img"
					src={IMGCast}
					alt=""
					sx={{
						position: "absolute",
						left: "-54px",
						top: "-48px",
						width: "100px",
						height: "100px",
						zIndex: 1
					}}
				/>
				<Box sx={{ padding: "14px 24px 0px 24px", position: "relative" }}>
					<Box
						sx={{
							position: "absolute",
							top: "15px",
							right: "15px",
							zIndex: "10"
						}}
						onClick={onClose}>
						<CloseIcon
							sx={{
								fill: "#fff",
								fontSize: "14px",
								backgroundColor: "#3E809D",
								borderRadius: "40px",
								width: "24px",
								height: "24px",
								p: "3px",
								cursor: "pointer"
							}} />
					</Box>
					<Typography sx={{ marginLeft: "16px", marginBottom: "10px", color: "#215C75", fontFamily: "Open Sans", fontSize: "22px", fontWeight: "700", lineHeight: "30px", textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
						{title}
					</Typography>
					<Box sx={{
						width: "calc(100% - 32px)",
						marginLeft: "16px"
					}}>
						<Slider
							max={100}
							min={1}
							value={value}
							onChange={handleChangeGhostValues}
							step={1}
							sx={{
								height: "10px",
								".MuiSlider-track": {
									backgroundColor: "transparent",
									border: "none"
								},
								".MuiSlider-rail": {
									backgroundColor: "rgba(255, 255, 255, 0.8)"
								},
								".MuiSlider-thumb": {
									background: value < 33 ? `url(${ smileGood })` : (33 <= value && value <= 66) ? `url(${ smile })` : `url(${ smileBad })`,
									width: "40px",
									height: "40px"
								}
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default SliderBlock;
