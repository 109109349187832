import CancelIcon from "@mui/icons-material/Cancel";
import { alpha, Box, CardMedia, Dialog, Slide, styled, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import IMGMissionLarge4 from "../../../../assets/images/missions/mission-nine/card-fon-img.png";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

export interface IMission2Props
{
	isOpen: boolean;
	onClose?: any;
}
const StyledDialog = styled(Dialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			backgroundColor: alpha("#C0D3DF", 0.7),
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "20px",
			maxWidth: "980px",
			width: "100%",
			boxShadow: "0px 5px 10px 0px rgba(167, 199, 225, 0.6)",
			backdropFilter: "blur(25px)",
			display: "flex",
			flexDirection: "row",
			marginTop: "140px"
		}
	}
);
const Transition = forwardRef((
	props: TransitionProps & { children: React.ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

function ModalMission9Data({ isOpen = false, onClose }: IMission2Props)
{
	const missionContext = useMission();
	const data = JSON.parse(`${ localStorage.getItem("mission-nine-inputs") }`);

	// Handles
	function closeDialog()
	{
		if (onClose)
		{
			onClose();
		}
		else
		{
			missionContext.setShowMissionInfo(null);
		}
	}

	// Render
	return (
		<StyledDialog
			open={isOpen}
			TransitionComponent={Transition}
			onClose={closeDialog}
			sx={{
				"& .MuiPaper-root": {
					maxWidth: { lg: "814px", xl: "980px" }
				}
			}}
		>
			<Box sx={{ height: { lg: "540px", xl: "740px" } }}>
				<CardMedia
					component="img"
					width="100%"
					height="740px"
					image={IMGMissionLarge4}
					alt=""
				/>
			</Box>
			<Box sx={{ p: "33px 36px 32px 72px", width: "100%" }}>
				<Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
					<CancelIcon
						onClick={closeDialog}
						sx={{
							position: "absolute",
							top: "32px",
							right: "32px",
							fill: "#3E809D",
							opacity: ".8",
							"&:hover": { opacity: "1", cursor: "pointer" }
						}}
					/>
				</Box>
				<Typography sx={{ font: { lg: "700 30px/51px 'Lora-Bold'", xl: "700 40px/51px 'Lora-Bold'" }, color: "#215C75", mt: 2 }}>
					<ContentAdmin
						keyContent="contentMissionNine"
						keyText="names_north_star"
						defaultValue="{NAME}’s North Star"
						position="left" />
				</Typography>
				{data?.length > 0 &&
					<Box
						className="customScroll"
						sx={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "550px", mt: 5, pr: "16px" }}>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#215C75"
						}}>
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="my_purpose_is"
								defaultValue="My purpose is"
								position="left" />
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#4D4D4D",
							overflow: "hidden",
							overflowWrap: "anywhere"
						}}>
							{data[ 0 ].value}
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#215C75",
							mt: 5
						}}>
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="my_tribe_is"
								defaultValue="My tribe is"
								position="left"
							/>
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#4D4D4D",
							overflow: "hidden",
							overflowWrap: "anywhere"
						}}>
							{data[ 2 ].value}
						</Typography>

						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#215C75",
							mt: 5
						}}>
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="i_am_motivated"
								defaultValue="This is why I am motivated every day to fulfill my purpose"
								position="left"
							/>
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#4D4D4D",
							overflow: "hidden",
							overflowWrap: "anywhere"
						}}>
							{data[ 4 ].value}
						</Typography>

						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#215C75",
							mt: 5
						}}>
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="what_i_stand_for"
								defaultValue="This is what I stand for"
								position="left" />
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#4D4D4D",
							overflow: "hidden",
							overflowWrap: "anywhere"
						}}>
							{data[ 3 ].items.map((item: any) => (
								`${ item.value } `
							))}
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#215C75",
							mt: 5
						}}>
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="mission_9_this_is_why"
								defaultValue="This is why I want to fulfill my purpose ..."
								position="left"
							/>
						</Typography>
						<Typography sx={{
							font: { lg: "700 18px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" },
							color: "#4D4D4D",
							overflow: "hidden",
							overflowWrap: "anywhere"
						}}>
							{data[ 1 ].items.map((item: any) => (
								`${ item.value } `
							))}
						</Typography>
					</Box>}
			</Box>
		</StyledDialog>
	);
}

export default ModalMission9Data;
