import { Box, alpha } from "@mui/material";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import MissionsDialog from "components/missions/Dialog";
import Panel from "components/missions/Panel";
import { EXAMPLES, INPUT_MAX_LEN } from "pages/missionFour/components/PersonalJobAd";
import * as SVGPersonIcons from "pages/missionSeven/components/WhatAreYourFamilyBeliefs/assets/icons";
import ImageButton from "pages/missionSeven/components/WhatAreYourFamilyBeliefs/components/ImageButton";
import { useLayoutEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { LOCAL_STORAGE_PREFIX } from "..";

export interface IPersonalJobAdProps
{
	nextStep(): void;
}

function PersonalJobAd({ nextStep }: IPersonalJobAdProps)
{
	const [ showModalName, setShowModalName ] = useState<keyof typeof EXAMPLES | null>(null);
	const [ modalVisited, setModalVisited ] = useState<Set<keyof typeof EXAMPLES>>(new Set());
	const [ inputValue, setInputValue ] = useState<string>(() =>
	{
		return getLocalStorage<string>(LOCAL_STORAGE_PREFIX + "-create-jobAdText") ?? "";
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(LOCAL_STORAGE_PREFIX + "-create-jobAdText", inputValue);
	}, [ inputValue ]);

	// Handles
	function onOpenModal(name: Exclude<typeof showModalName, null>)
	{
		setShowModalName(name);
		setModalVisited((prevState) => prevState.add(name));
	}

	// Render
	return (
		<>
			<Panel sx={{
				padding: { xl: "32px 32px 26px 32px", lg: "24px", xs: "24px" },
				maxWidth: { xl: "980px", lg: "928px", xs: "928px" },
				height: { xl: "623px", lg: "520px", xs: "520px" },
				justifySelf: "center"
			}}>
				<Box sx={{
					color: "primary.main",
					font: { xl: "700 24px/33px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 24px/33px 'Open Sans'" }
				}}>
					<ContentAdmin keyContent="contentMissionFour" keyText="PersonalJobAd_panelTitle" defaultValue="{NAME}’s personal job ad" position="left" />
				</Box>

				<Box sx={{ position: "relative", marginTop: { xl: "24px", lg: "16px", xs: "16px" } }}>
					<Box
						className="customScroll"
						sx={{
							borderRadius: "8px",
							backgroundColor: alpha("#FFFFFF", 0.5),
							padding: "12px 16px",
							overflowY: "auto",
							height: { xl: "340px", lg: "311px", xs: "340px" }
						}}
					>
						<CustomInput.Base
							placeholder="Write your personal job ad here. What talents, passions and skills would you like to use? What are your requirements to you schedule, colleagues, environment? Consult the examples if you get stuck."
							multiline
							inputProps={{ maxLength: INPUT_MAX_LEN }}
							value={inputValue}
							onChange={({ target }) =>
							{
								setInputValue(target.value.replaceAll(/\n{3,}/g, "\n\n"));
							}}
							sx={{
								alignItems: "flex-start",
								backgroundColor: "unset",
								color: "primary.dark",
								width: "100%",
								"& textarea":
								{
									font:
									{
										xl: "400 22px/30px 'Open Sans' !important",
										lg: "400 16px/22px 'Open Sans' !important",
										xs: "400 16px/22px 'Open Sans' !important"
									},
									padding: "0 9px 0 0 !important"
								}
							}}
						/>
					</Box>
					<Box sx={{ opacity: 0.5, font: "400 14px/19px 'Open Sans'", color: "primary.dark", position: "absolute", right: "16px", bottom: "-19px" }}>
						{inputValue.length}/{INPUT_MAX_LEN} characters
					</Box>
				</Box>

				<Box sx={{
					color: "primary.main",
					font: { xl: "700 24px/33px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 16px/22px 'Open Sans'" },
					marginTop: { xl: "32px", lg: "20px", xs: "20px" }
				}}>
					Examples
				</Box>
				<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: { xl: "18px", lg: "8px", xs: "18px" } }}>
					<Box sx={{ display: "flex", gap: "16px" }}>
						{[
							[ "Grandma", "Eve" ],
							[ "Dad", "Robert" ],
							[ "Mom", "Natalya" ],
							[ "Brother", "George" ],
							[ "Sister", "Mimi" ]
						].map(([ icon, name ]) =>
						{
							return (
								<ImageButton
									key={icon}
									SVGImage={SVGPersonIcons[ icon as keyof typeof SVGPersonIcons ]}
									text=""
									isSelected={modalVisited.has(name as keyof typeof EXAMPLES) === true}
									sx={{
										"--size": { xl: "77px", lg: "72px", xs: "72px" },
										"& .ImageButton-text": { display: "none" }
									}}
									onClick={() => onOpenModal(name as Exclude<typeof showModalName, null>)}
								/>
							);
						})}
					</Box>
					<CustomButton.Contained
						disabled={inputValue.trim().length < 2}
						onClick={nextStep}
					>
						Recommend me jobs
					</CustomButton.Contained>
				</Box>
			</Panel>

			<MissionsDialog
				open={showModalName !== null}
				title={showModalName as string}
				onClose={() => setShowModalName(null)}
				position="center"
				sx={{
					"& .MuiDialog-container":
					{
						alignItems: { xl: "unset", lg: "center", xs: "center" }
					},
					"& .MuiPaper-root":
					{
						margin: "32px",
						marginTop: { xl: "260px", lg: "32px", xs: "32px" },
						maxWidth: "980px",
						height: "fit-content",
						maxHeight: "600px",
						padding: "32px 32px 32px 40px",
						display: "grid",
						gridTemplateRows: "auto 1fr",
						gap: "24px"
					},
					"& .MissionDialog-cancel":
					{
						top: "32px",
						right: "32px"
					},
					"& .MissionDialog-title":
					{
						font: "700 40px/51px Lora-Bold",
						marginBottom: "unset",
						paddingRight: "32px"
					}
				}}
			>
				<Box
					className="customScroll"
					sx={{
						overflowY: "auto",
						height: "100%",
						paddingRight: "28px",
						font: "400 22px/30px 'Open Sans'",
						color: "primary.dark"
					}}
				>
					{showModalName !== null &&
						(<ContentAdmin keyContent="contentMissionFour" keyText={"PersonalJobAd_example_" + showModalName} defaultValue={EXAMPLES[ showModalName ]} position="left" isDescription />)
					}
				</Box>
			</MissionsDialog>
		</>
	);
}

export default PersonalJobAd;
