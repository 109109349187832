import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

const Welcome = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_message_1" defaultValue="Woop-woop, {NAME}, did you know I’m proud of you?" isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [

				<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_answer_1" defaultValue="Why is that?" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_message_2" defaultValue="Well, look how far you’ve come! You’ve learnt so much about yourself and I really loved getting to know you along the way, {NAME}." isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_answer_2" defaultValue="Thanks, Octa!" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_message_3" defaultValue="You gathered so many clues to help you design your best life." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_answer_3" defaultValue="Clues?" position="top" />
					]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_message_4" defaultValue="Yes, and today you will use everything you’ve collected so far to create a vision board of your dream life." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_answer_4" defaultValue="Let’s do it!" position="top" />
					]
				});
				break;
			}

			case 5: { setChatStep((prevStep) => prevStep + 1); missionContext.nextStep(); }
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Chat
					title={<ContentAdmin keyContent="contentMissionSix" keyText="welcome_chat_title" defaultValue="You are a superstar, {NAME}!" />}
					onResponseAnswer={onResponseAnswer}
					messages={messages}
					answers={answers}
				/>
			</Box>
		</Box>
	);
});

export default Welcome;
