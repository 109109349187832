import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, alpha, useMediaQuery, useTheme } from "@mui/material";
import Api from "api";
import IMGJellyfish from "assets/images/jellyfish.png";
import Wheel, { Captions, WheelData, captions } from "components/Wheel";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import { ComponentType, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, useReadLocalStorage } from "utils/localStorage";
import trackEvent from "utils/mixpanelUtil";
import IMGBackground from "./assets/images/background.jpg";
import IMGStars from "./assets/images/stars.png";
import Tab from "./components/Tab";
import Career from "./contents/Career";
import Friend from "./contents/Friend";
import Fun from "./contents/Fun";
import Growth from "./contents/Growth";
import Health from "./contents/Health";
import Home from "./contents/Home";
import Love from "./contents/Love";
import Money from "./contents/Money";

const CONTENTS: Record<Captions, ComponentType<any>> = {
	home: Home,
	health: Health,
	friend: Friend,
	love: Love,
	career: Career,
	money: Money,
	fun: Fun,
	growth: Growth
};

function MissionOneResult()
{
	const theme = useTheme();
	const navigate = useNavigate();
	const tabsBoxRef = useRef<HTMLDivElement>(null);

	const { state: userState } = useUser();
	const [ activeTab, setActiveTab ] = useState<null | Captions>(null);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	const [ isTabClicked, setIsTabClicked ] = useState<Record<Captions, boolean>>(
		{
			home: false,
			health: false,
			friend: false,
			love: false,
			career: false,
			money: false,
			fun: false,
			growth: false
		}
	);

	const data = useReadLocalStorage<WheelData>(
		"mission-one-graph",
		{
			home: 0,
			health: 0,
			friend: 0,
			love: 0,
			career: 0,
			money: 0,
			fun: 0,
			growth: 0
		}
	);

	const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

	const dataSorted = useMemo(() =>
	{
		const sorted = Object.entries(data).sort(([ , valueA ], [ , valueB ]) => valueA - valueB);

		if (isMobile === true)
		{
			return sorted.filter(([ , value ]) => value < 0.7);
		}

		return sorted;
	}, [ data, isMobile ]);

	const dataHighScore = useMemo(() =>
	{
		return dataSorted.filter(([ , value ]) => value >= 0.7);
	}, [ dataSorted ]);

	const Content = useMemo(() =>
	{
		if (activeTab === null)
		{
			return null;
		}

		const Component = CONTENTS[ activeTab ];

		return <Component />;
	}, [ activeTab ]);

	// Effects
	useEffect(() =>
	{
		if (activeTab === null)
		{
			tabsBoxRef.current?.scrollIntoView({ behavior: "smooth" });

			return;
		}

		setIsTabClicked((prevState) => ({ ...prevState, [ activeTab ]: true }));

		if (isMobile === true)
		{
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}

		tabsBoxRef.current?.scrollIntoView({ behavior: "smooth" });

	}, [ activeTab, isMobile ]);

	// Handles
	function onFinish()
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.missions
			.saveMission(data)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(1) }
				});

				setTimeout(() =>
				{
					navigate(getLocalStorage<string>("registration-name") !== null ? "/registration/complete" : "/dashboard");
				}, 0);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => setIsFetch(false));
	}

	// Render
	if (isMobile === true && activeTab !== null)
	{
		return (
			<ContentModal
				name={activeTab}
				onClose={() => setActiveTab(null)}
			/>
		);
	}

	return (
		<Box sx={[
			{
				padding: { xl: "56px 56px 32px 56px", lg: "40px 44px 26px 44px", xs: "26px 0 32px 0" },
				minHeight: "100vh",
				background: `url(${ IMGBackground }) center center/cover`,

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				}
			},
			activeTab === null && { paddingBottom: { lg: "unset !important" } }
		]}>
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,

					height: { xl: "320px", lg: "213px" },

					background: `url(${ IMGStars }) top center/cover`
				}}
			/>

			<Box sx={{
				display: "flex",
				flexDirection: { lg: "row", xs: "column" },
				alignItems: "center",
				justifyContent: "space-between",
				gap: "32px"
			}}>
				<Wheel
					active={8}
					data={data}
					sx={{
						margin: "0 auto"
					}}
				/>

				<Panel sx={[
					{
						position: "relative",
						padding: { xl: "48px 64px", xs: "24px 32px" },
						marginRight: { lg: "16px" },

						width: { xl: "862px !important", lg: "600px !important" },
						minHeight: { xl: "601px", lg: "394px" }

					},
					(theme) =>
					{
						return {
							[ theme.breakpoints.down("lg") ]:
							{
								border: "unset",
								borderRadius: "unset"
							}
						};
					}
				]}>
					<Box sx={{
						font: { lg: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						textAlign: { lg: "left", xs: "center" },
						color: "primary.main"
					}}>
						<ContentAdmin
							position="left"
							keyContent="contentMissionOne"
							keyText="result-title"
							defaultValue="{NAME}’s Wheel of Life"
						/>
					</Box>

					<Box sx={{
						marginTop: { xl: "48px", lg: "24px", xs: "16px" },
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 14px/16px 'Open Sans'" },
						textAlign: "left",
						color: "primary.dark"
					}}>
						{dataHighScore.length < 5
							? (
								<ContentAdmin
									isDescription
									position="left"
									defaultValue="{NAME}, it sounds like you aren’t so happy with most areas of your life right now. Life is a rollercoaster and sometimes things go better than other times. But acknowledging that things aren’t working is the first step to a more fulfilling life.\n\nMaybe it’s just a phase you are going through, and slight adjustments across all areas will help you get back on track. But maybe you need a bigger change in life. Often, when we are unhappy with how things are going, it’s a sign you might be on the wrong path in life and need a course correction.\n\nReflect on each individual area and ask yourself if it’s time for a new chapter. Recognising you need a change can be scary, but we’ve got your back. With Life Strategy Quest, we will guide you through all the steps to a more fulfilling life."
									keyContent="contentMissionOne"
									keyText="mediumStatusDesc"
								/>
							)
							: (
								<ContentAdmin
									isDescription
									position="left"
									keyContent="contentMissionOne"
									keyText="result-text"
									defaultValue="{NAME}, let’s start by acknowledging what’s going right in your life. It sounds like **{SCORES}** have scored quite high. You should give yourself credit.\n\nMost people struggle to maintain their life in balance at all times, so it’s completely normal that some areas of your life scored a little lower.\n\nThe good news is that you now have clarity of what needs your attention. Sometimes we feel down or not quite fulfilled without even understanding what needs fixing.\n\nWhen you know what to focus on, it is a lot easier to fix it."
									replacer={{
										"{SCORES}": dataHighScore.map(([ name ]) => captions[ name as Captions ]).join(", ")
									}}
								/>
							)
						}
					</Box>

					<Box sx={{
						position: "absolute",
						bottom: { xl: "-65px", lg: "-80px" },
						right: { xl: "-56px", lg: "-32px" },

						width: { xl: "296px", lg: "247px" },

						display: { lg: "block", xs: "none" }
					}}>
						<img src={IMGJellyfish} alt="" />
					</Box>
				</Panel>
			</Box>

			<Box ref={tabsBoxRef} sx={{ marginTop: { xl: "36px", lg: "24px" } }}>
				<Box
					sx={{
						padding: { xl: "40px 40px 24px 40px", lg: "16px 16px 8px 16px", xs: "24px 20px" },

						display: { lg: "flex", xs: "grid" },
						gridTemplateColumns: "repeat(auto-fill, 100px)", // 100px - a tab width
						gap: { xl: "64px", lg: "19.5px", xs: "8px" }
					}}
				>
					{dataSorted.map(([ name, value ]) =>
					{
						return (
							<Tab
								value={value * 10}
								icon={name as Captions}
								active={activeTab === name}
								onClick={() => setActiveTab(name as Captions)}
								waveAnimation={isTabClicked[ name as Captions ] === false}
							/>
						);
					})}
				</Box>

				{activeTab !== null &&
					(
						<Box sx={{
							padding: { xl: "40px", lg: "16px" },
							minHeight: { xl: "613px", lg: "421px" },
							width: { xl: "1808px", lg: "1191px" },

							background: alpha("#FFFFFF", 0.2),
							borderRadius: (
								activeTab === dataSorted[ 0 ][ 0 ]
									? { xl: "0 32px 32px 32px", lg: "0 22px 22px 22px" }
									: activeTab === dataSorted[ dataSorted.length - 1 ][ 0 ]
										? { xl: "32px 0 32px 32px", lg: "22px 0 22px 22px" }
										: { xl: "32px", lg: "22px" }
							),

							display: { lg: "grid", xs: "none" },
							gridTemplateColumns: "1fr 1fr 1fr",
							gap: { xl: "24px", lg: "16px" }
						}}>
							{Content}
						</Box>
					)
				}

				<CustomButton.Base
					size="large"
					loading={isFetch}
					onClick={onFinish}
					sx={{
						margin: "24px auto 0 auto",
						display: { lg: (activeTab !== null ? "flex" : "none"), xs: "flex" },
						boxShadow: "unset"
					}}
				>
					Embark on a quest
				</CustomButton.Base>
			</Box>
		</Box>
	);
}

interface IContentModalProps
{
	name: Captions;
	onClose(): void;
}

function ContentModal({ name, onClose }: IContentModalProps)
{
	const { state: userState } = useUser();

	const Content = useMemo(() =>
	{
		const Component = CONTENTS[ name ];

		return <Component />;
	}, [ name ]);

	// Render
	return (
		<Box
			sx={{
				zIndex: 555,
				position: "absolute",
				top: "0",
				left: "0",
				right: "0",

				padding: "32px 32px 16px 32px",

				minHeight: "100vh",

				background: `url(${ IMGBackground }) center center/cover`,

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				}
			}}
		>
			<Box
				component="button"
				onClick={onClose}
				sx={{
					"--size": "24px",

					position: "absolute",
					top: "16px",
					right: "24px",

					padding: "unset",
					width: "var(--size)",
					height: "var(--size)",

					background: "#FFFFFF",
					border: "unset",
					borderRadius: "50%",
					transition: "linear 300ms opacity",

					display: "flex",
					justifyContent: "center",
					alignItems: "center",

					cursor: "pointer",

					"&:hover":
					{
						opacity: 0.8
					}
				}}
			>
				<CloseRoundedIcon color="primary" fontSize="small" />
			</Box>

			<Box sx={{
				font: "700 24px/31px Lora-Bold",
				color: "#FFFFFF",
				textAlign: "center"
			}}>
				{userState.user?.name || "Name"}’s {captions[ name ]}
			</Box>

			<Box
				sx={{
					marginTop: "24px",

					display: "flex",
					flexDirection: "column",
					gap: "16px"
				}}
			>
				{Content}
			</Box>
		</Box>
	);
}

export default MissionOneResult;
