import { Box } from "@mui/material";
import guideChara from "assets/images/guideChara-shrink.png";
import ArrowButton from "components/ArrowButton";
import CustomInput from "components/CustomInput";
import config from "config";
import { KeyboardEvent, useState } from "react";
import { setLocalStorage } from "utils/localStorage";
import { ValidationError } from "yup";

function Name({ handleStep }: { handleStep(): void; })
{
	const [ name, setName ] = useState<string>("");
	const [ error, setError ] = useState<string | null>(null);

	// Handles
	function submitName()
	{
		let error: string | null = null;

		try
		{
			config.name.yup().validateSync(name.trim());
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		if (error !== null)
		{
			return setError(error);
		}

		setLocalStorage("name", name.trim());
		handleStep();
	}

	function onKeyDownHandler(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key === "Enter")
		{
			submitName();
		}
	}

	//	Render
	return (
		<Box sx={{
			minHeight: "100vh",
			padding: "24px 24px 16px 24px",
			textAlign: "center",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",

			"@supports (min-height: 100dvh)":
			{
				minHeight: "100dvh"
			}
		}}>
			<Box sx={{
				marginTop: { xl: "20px", sm: "54px" },
				width: { xl: "211px", sm: "177px", xs: "139px" }
			}}>
				<img width="100%" src={guideChara} alt="" />
			</Box>

			<Box sx={{
				maxWidth: { xl: "844px", sm: "530px" },
				width: "100%",
				marginTop: { xl: "40px", sm: "24px", xs: "30px" },
				padding: { sm: "unset", xs: "0 8px" },
				font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main"
			}}>
				Hey, good looking,<br />
				how should I call you?
			</Box>

			<CustomInput.Base
				placeholder="Name"
				value={name}
				onChange={({ target }) => setName(target.value.trim())}
				onKeyDown={onKeyDownHandler}
				sx={{
					marginTop: { xl: "40px", sm: "60px", xs: "30px" },
					maxWidth: { xl: "478px", sm: "410px" },
					width: "100%"
				}}
			/>

			<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
				{error}
			</Box>

			<ArrowButton
				onClick={submitName}
				sx={{
					position: "absolute",
					bottom: "24px",
					right: { xl: "40px", sm: "16px", xs: "unset" }
				}}
			/>
		</Box>
	);
}

export default Name;
