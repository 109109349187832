import CancelIcon from "@mui/icons-material/Cancel";
import { Box, CardMedia, Dialog, Slide, styled, SxProps, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";

const StyledDialog = styled(Dialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			background: "linear-gradient(to right bottom, rgba(239, 239, 239, .8), rgba(239, 239, 239, .1))",
			border: "1px solid rgba(255, 255, 255, .6)",
			maxWidth: "620px",
			borderRadius: "24px",
			boxShadow: "0px 5px 30px rgba(36, 92, 116, .15)",
			font: "400 20px/25px 'Open Sans'",
			color: "rgba(77, 77, 77, .7)",
			display: "block",
			backdropFilter: "blur(40px) brightness(.9)",
			marginTop: "80px"
		}
	}
);

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
)
{
	return <Slide direction="up" ref={ref} {...props} />;
});

interface IImageDialogProps
{
	open: boolean;
	direction: "column" | "row";
	position: "bottom" | "center";
	onClose(): void;
	image: string;
	title: string | JSX.Element;
	body?: string | JSX.Element;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function ImageDialog({ open, image, title, body, direction, position, onClose, sx = [], ...props }: IImageDialogProps)
{
	// Render
	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
			sx={[
				{
					"& .MuiDialog-container":
					{
						alignItems: (position === "center" ? "center" : "flex-end")
					},
					"& .MuiPaper-root":
					{
						width: (direction === "row" ? "100%" : "320px"),
						padding: (
							direction === "row"
								? { xl: "56px 48px 32px 36px", lg: "40px 32px 40px 32px", xs: "50px 30px 32px 50px" }
								: "63px 24px 30px 24px"
						),
						marginBottom: (position === "bottom" ? "120px" : "unset")
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<CancelIcon
				onClick={onClose}
				sx={[
					{
						position: "absolute",
						top: { xl: "32px", lg: "24px", xs: "24px" },
						right: { xl: "32px", lg: "24px", xs: "24px" },
						fontSize: "24px",
						fill: "#215C75",
						opacity: 1,
						"&:hover": { opacity: 0.3, cursor: "pointer" }
					},
					(direction === "column") &&
					{
						top: { xl: "17px", lg: "13px", xs: "13px" },
						right: { xl: "17px", lg: "13px", xs: "13px" },
						fontSize: { xl: "36px", lg: "19px", xs: "16px" }
					}
				]}
			/>

			<Box sx={{ width: "100%", display: "flex", flexDirection: direction, gap: "24px" }}>
				<CardMedia
					image={image}
					sx={[
						{
							width: (direction === "column" ? { xl: "272px", lg: "208px", xs: "208px" } : "179px"),
							height: (direction === "column" ? { xl: "171px", lg: "131px", xs: "91px" } : "224px"),
							backgroundPosition: "center",
							backgroundSize: "contain",
							flexShrink: 0,
							marginBottom: (direction === "column" ? { xl: "6px", lg: "0", xs: "24px" } : "0")
						},
						(direction === "column") &&
						{
							marginLeft: "auto",
							marginRight: "auto"
						}
					]}
				/>

				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: (body === undefined ? "center" : "flex-start"),
					gap: (direction === "column" ? { xl: "10px", lg: "8px", xs: "8px" } : "34px")
				}}>
					<Box sx={{
						font: "700 24px/31px Lora",
						color: "primary.main",
						textAlign: (direction === "column" ? "center" : "left")
					}}>
						{title}
					</Box>

					<Box sx={{
						font: (direction === "row" ? "400 22px/30px 'Open Sans'" : "400 18px/24px 'Open Sans'"),
						color: "#4D4D4D",
						paddingRight: (direction === "row" ? "25px" : "unset"),
						textAlign: (direction === "column" ? "center" : "left"),
						width: "100%"
					}}>
						{body}
					</Box>
				</Box>
			</Box>
		</StyledDialog>
	);
}

export default ImageDialog;
