import { Box, ImageList, ImageListItem } from "@mui/material";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IVisionBoard } from ".";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";

interface IDetailsProps
{
	boards: IVisionBoard[];
}

function Details({ boards }: IDetailsProps)
{
	const navigate = useNavigate();
	const { data: boardId } = useParams();

	const [ board, setBoard ] = useState<IVisionBoard | null>(() =>
	{
		return boards.find((board) => board.id === boardId) || null;
	});

	const [ isShowCarousel, setIsShowCarousel ] = useState<boolean>(false);
	const [ currentImageIndex, setCurrentImageIndex ] = useState<number>(0);

	// Effects
	useLayoutEffect(() =>
	{
		setBoard(boards.find((board) => board.id === boardId) || null);
	}, [ boards, boardId ]);

	// Render
	return (
		<ArtifactsContainer
			title={board === null ? "Loading..." : board.title || board.date}
			headerRight={
				<>
					<TooltipStyled title="Download" placement="left" arrow>
						<Box
							component="button"
							sx={{
								display: "none",
								padding: "unset",
								background: "unset",
								border: "unset",
								cursor: "pointer",
								color: "inherit"
							}}
						>
							<SVGIconDownload />
						</Box>
					</TooltipStyled>

					<CustomButton.Outline
						disabled={board === null}
						onClick={() => navigate("/dashboard/artifacts/vision-board/edit/" + board!.id)}
						sx={{ width: "207px", marginTop: "-10px" }}
					>
						Edit
					</CustomButton.Outline>
				</>
			}
			onClose={() => navigate("/dashboard/artifacts/vision-board/")}
			sx={{
				"& .ArtifactsContainer-main":
				{
					gap: "16px"
				}
			}}
		>
			<ImageList
				variant="masonry"
				cols={6}
				gap={32}
				className="customScroll"
				sx={{
					columnCount: { xl: "6 !important", lg: "5 !important", xs: "5 !important" },

					overflowY: "auto",
					margin: "unset",
					marginRight: "-24px",
					paddingRight: "16px",

					"& img":
					{
						borderRadius: "26px",
						cursor: "zoom-in"
					}
				}}
			>
				{/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
				{board !== null && board.images.map((image, index) =>
				{
					return (
						<ImageListItem key={image}>
							<img
								src={image}
								alt=""
								onClick={() =>
								{
									setCurrentImageIndex(index);
									setIsShowCarousel(true);
								}}
							/>
						</ImageListItem>
					);
				})}
			</ImageList>

			<Dialog
				open={isShowCarousel}
				onClose={() => setIsShowCarousel(false)}
				sx={{
					"& .MuiDialog-paper":
					{
						width: { xl: "1430px", lg: "1096px", xs: "1096px" },
						height: { xl: "812px", lg: "622px", xs: "622px" },
						padding: "30px"
					},
					"& .Dialog-closeButton":
					{
						right: { xl: "24px", lg: "18px", xs: "18px" },
						top: { xl: "24px", lg: "18px", xs: "18px" }
					},
					"& .Dialog-title":
					{
						marginBottom: "unset",
						position: "absolute",
						top: "24px",
						left: { xl: "45px", lg: "24px", xs: "24px" },
						maxWidth: { xl: "360px", lg: "320px", xs: "320px" },
						overflow: "hidden",
						textOverflow: "ellipsis"
					}
				}}
				title={board === null ? "Loading..." : board.title || board.date}
			>
				{board !== null && board.images.length > 0 &&
					(
						<>
							<Box
								component="button"
								sx={[
									{
										"--size": { xl: "40px", lg: "32px", xs: "40px" },
										display: "flex",
										position: "absolute",
										top: "50%",
										left: { xl: "50px", lg: "24px", xs: "24px" },
										transform: "translateY(-50%) rotate(180deg)",
										color: "primary.main",
										padding: "unset",
										background: "unset",
										border: "unset",
										cursor: "pointer",

										"& svg":
										{
											width: "var(--size)",
											height: "var(--size)"
										}
									},
									(currentImageIndex === 0) &&
									{
										display: "none"
									}
								]}
								onClick={() =>
								{
									setCurrentImageIndex((prevState) => prevState - 1);
								}}
							>
								<SVGChevronArrow />
							</Box>

							<Box
								component="button"
								sx={[
									{
										"--size": { xl: "40px", lg: "32px", xs: "40px" },
										display: "flex",
										position: "absolute",
										top: "50%",
										right: { xl: "50px", lg: "24px", xs: "24px" },
										transform: "translateY(-50%)",
										color: "primary.main",
										padding: "unset",
										background: "unset",
										border: "unset",
										cursor: "pointer",

										"& svg":
										{
											width: "var(--size)",
											height: "var(--size)"
										}
									},
									(currentImageIndex === board!.images!.length - 1) &&
									{
										display: "none"
									}
								]}
								onClick={() =>
								{
									setCurrentImageIndex((prevState) => prevState + 1);
								}}
							>
								<SVGChevronArrow />
							</Box>

							<Box sx={[
								{
									display: "flex",
									alignItems: "center",
									gap: "24px",

									"& div":
									{
										borderRadius: "26px",
										backgroundRepeat: "no-repeat",
										backgroundSize: "cover",
										backgroundPosition: "center center",
										objectFit: "cover",
										// transition: "linear 300ms background",

										"&:nth-of-type(1)":
										{
											backgroundImage: `url(${ board!.images[ currentImageIndex - 1 ] })`,
											width: { xl: "289px", lg: "222px", xs: "222px" },
											height: { xl: "428px", lg: "328px", xs: "328px" }
										},
										"&:nth-of-type(2)":
										{
											backgroundImage: `url(${ board!.images[ currentImageIndex ] })`,
											width: { xl: "505px", lg: "388px", xs: "388px" },
											height: { xl: "749px", lg: "574px", xs: "574px" }
										},
										"&:nth-of-type(3)":
										{
											backgroundImage: `url(${ board!.images[ currentImageIndex + 1 ] })`,
											width: { xl: "289px", lg: "222px", xs: "222px" },
											height: { xl: "428px", lg: "328px", xs: "328px" }
										}
									}
								},
								(currentImageIndex === 0) &&
								{
									"& div:nth-of-type(1)":
									{
										visibility: "hidden"
									}
								},
								(currentImageIndex === board!.images!.length - 1) &&
								{
									"& div:nth-of-type(3)":
									{
										visibility: "hidden"
									}
								}
							]}>
								<Box />
								<Box />
								<Box />
							</Box>
						</>
					)
				}
			</Dialog>
		</ArtifactsContainer>
	);
}

export default Details;
