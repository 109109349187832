import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import IMGGuideChara from "../../../../assets/images/octa2.png";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import useMission from "../../hooks/useMission";

const Celebration = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important" }}>
				<Box sx={{ background: `url(${ IMGGuideChara }) center center/cover`, width: "229px", height: "289px", marginTop: "95px" }} />

				<Box sx={{ font: "700 42px/54px Lora", textAlign: "center", color: "primary.main" }}>
					<ContentAdmin keyContent="contentMissionSix" keyText="celebration_text" defaultValue="Amazing job!" position="left" isDescription />
				</Box>

				<CustomButton.Contained onClick={() => { missionContext.nextStep(); }}>Woo-hoo!</CustomButton.Contained>
			</Box>
		</Box>
	);
});

export default Celebration;
