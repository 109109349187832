import { Box, Skeleton, alpha } from "@mui/material";
import { IGetDataMissionTwo } from "api/Missions";
import IMGSideImage from "assets/images/mission-two-sideImage.png";
import Panel from "components/missions/Panel";
import { useNavigate } from "react-router-dom";
import randomInt from "utils/randomInt";
import ArtifactsContainer from "../components/ArtifactsContainer";
import IMGBackground from "./assets/images/background.png";

export interface IRecommendationProps
{
	data: IGetDataMissionTwo | null;
}

function Recommendation({ data }: IRecommendationProps)
{
	const navigate = useNavigate();

	// Render
	return (
		<ArtifactsContainer
			title="Practical Recommendations"
			backgroundImage={IMGBackground}
			onClose={() => navigate("/dashboard/artifacts/my-values")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					gap: { xl: "24px", lg: "48px", xs: "48px" }
				}
			}}
		>
			<Panel sx={{
				justifySelf: "center",
				maxWidth: { xl: "1469px", lg: "1034px", xs: "1034px" },
				height: { xl: "705px", lg: "494px", xs: "524px" },
				display: "grid",
				gridTemplateColumns: { xl: "200px 1fr", lg: "164px 1fr", xs: "164px 1fr" },
				gridTemplateRows: "40px 1fr 40px",
				gap: { xl: "0 48px", lg: "0 32px", xs: "0 24px" }
			}}>
				<Box
					sx={{
						height: "100%",
						width: "100%",
						gridRow: "3 span",
						background: `url(${ IMGSideImage }) center center/cover`,
						borderTopLeftRadius: "inherit",
						borderBottomLeftRadius: "inherit"
					}}
				/>

				<Box
					className="customScroll"
					component="ol"
					sx={{
						counterReset: "list",
						gridColumn: "2",
						gridRow: "2",
						height: "100%",
						overflowY: "auto",
						margin: "0 32px 0 0",
						padding: "unset",
						paddingRight: "24px",
						listStyle: "none",

						"& li":
						{
							color: "primary.dark",

							"&:not(:last-child)":
							{
								marginBottom: "30px"
							},
							"& h2":
							{
								margin: "unset",
								marginLeft: { xl: "8px", lg: "4px", xs: "4px" },
								display: "flex",
								gap: "8px",
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								color: "primary.main",

								"&::before":
								{
									counterIncrement: "list",
									content: "counter(list) '.'",
									font: "inherit",
									color: "inherit"
								}
							},
							"& p":
							{
								margin: "unset",
								font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }
							}
						}
					}}
				>
					{data === null
						? (
							(new Array(5).fill(0)).map((_, index) =>
							{
								return (
									<li key={index}>
										<h2>
											<Skeleton
												animation="wave"
												variant="text"
												sx={{
													backgroundColor: alpha("#3E809D", 0.3),
													height: "30px",
													width: randomInt(30, 85) + "%"
												}}
											/>
										</h2>
										<p>
											<Skeleton
												animation="wave"
												variant="rounded"
												sx={{
													backgroundColor: alpha("#3E809D", 0.3),
													height: randomInt(1, 3) * 30 + "px"
													// width: randomInt(30, 85) + "%"
												}}
											/>
										</p>
									</li>
								);
							})
						)
						: data.recommendation.map(({ title, action }) =>
						{
							return (
								<li key={title}>
									<h2>{title}</h2>
									<p>{action}</p>
								</li>
							);
						})
					}

				</Box>
			</Panel>
		</ArtifactsContainer>
	);
}

export default Recommendation;
