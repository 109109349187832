import { Box, Fade, alpha } from "@mui/material";
import IMGOcta2 from "assets/images/octa2.png";
import ArrowButton from "components/ArrowButton";
import ChipStyled from "components/ChipStyled";
import LightBulb from "components/LightBulb";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import MissionsDialog from "components/missions/Dialog";
import PanelTitle from "components/missions/PanelTitle";
import useUser from "hooks/useUser";
import { DragEvent, useState } from "react";
import Tour from "reactour";
import useMission from "../../hooks/useMission";

enum EBoardId
{
	First,
	Good,
	Bad
}

interface IBlipBulbState
{
	good: boolean;
	bad: boolean;
}

const BLIP_MS = 1000;

function LetsPlayGame()
{
	interface IBoard
	{
		id: EBoardId;
		title: string;
		items: IBoardItem[];
	}

	interface IBoardItem
	{
		id: number;
		goodBoard: EBoardId;
		title: keyof typeof promptContent;
	}

	// States
	const { user } = useUser().state;
	const missionContext = useMission();
	const [ promptValue, setPromptValue ] = useState<keyof typeof promptContent | null>(null);
	const [ blipBulb, setBlipBulb ] = useState<IBlipBulbState>({ good: false, bad: false });
	const [ currentBoard, setCurrentBoard ] = useState<IBoard | null>(null);
	const [ currentItem, setCurrentItem ] = useState<IBoardItem | null>(null);
	const [ boards, setBoards ] = useState<IBoard[]>(
		[
			{
				id: EBoardId.First,
				title: "firstColumn",
				items: [
					{ id: 1, goodBoard: EBoardId.Bad, title: "Being right" },
					{ id: 2, goodBoard: EBoardId.Good, title: "Contentment" },
					{ id: 3, goodBoard: EBoardId.Bad, title: "Not being alone" },
					{ id: 4, goodBoard: EBoardId.Good, title: "Giving to others" },
					{ id: 5, goodBoard: EBoardId.Good, title: "Self-Sufficiency" },
					{ id: 6, goodBoard: EBoardId.Bad, title: "Material success" },
					{ id: 7, goodBoard: EBoardId.Good, title: "Conscientiousness" }
				]
			},
			{
				id: EBoardId.Good,
				title: "Good values",
				items: []
			},
			{
				id: EBoardId.Bad,
				title: "Bad values",
				items: []
			}
		]
	);

	const promptContent =
	{
		"Being right": (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_beingRight" defaultValue="It is impossible to know if you are 100% right, and you cannot force other people to admit you are right, even when you are. Which makes this value uncontrollable, and hence not the best one to add to your list." isDescription />
		),
		Contentment: (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_contentment" defaultValue="Being content with what you have is within your control and is conducive to your overall sense of wellbeing, which makes it a great value." isDescription />
		),
		"Not being alone": (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_notBeingAlone" defaultValue="While there is nothing wrong with wanting to be with people, it is not always within your control. It is better if you choose value you can control, for example, ’friendships’ or ‘relationships’." isDescription />
		),
		"Giving to others": (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_givingToOther" defaultValue="Giving to others is both within your control and it enhances you and others, making it an excellent value." isDescription />
		),
		"Self-Sufficiency": (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_selfSufficiency" defaultValue="Good value, both controllable and constructive!" isDescription />
		),
		"Material success": (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_materialSuccess" defaultValue="It’s a tricky one! Having material wealth is a great thing, but making it your value can bring a lot of trouble.\n\nWhile you can work towards achieving your material success, it’s not just on you, so it is not fully controllable.\n\nIt is best if you choose contentment as a value. You can still work hard and achieve great things, but will always be content no matter what!" isDescription />
		),
		Conscientiousness: (
			<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_prompt_conscientiousness" defaultValue="You can control it and it’s good for yourself and others, a great value!" isDescription />
		)
	};

	// Utils
	function timerBlipBulb(bulbType: keyof IBlipBulbState)
	{
		setBlipBulb((prevState) => ({ ...prevState, [ bulbType ]: true }));

		setTimeout(() =>
		{
			setBlipBulb((prevState) => ({ ...prevState, [ bulbType ]: false }));
		}, BLIP_MS);
	}

	// Handles
	function dragOverHandler(event: DragEvent<HTMLDivElement>)
	{
		if (currentBoard === null || currentItem === null)
		{
			return;
		}

		event.preventDefault();
	}

	function onDragStart(_event: DragEvent<HTMLDivElement>, board: IBoard, item: IBoardItem)
	{
		setCurrentBoard(board);
		setCurrentItem(item);
	}

	function onDragEnd()
	{
		setCurrentBoard(null);
		setCurrentItem(null);
	}

	function dropCardHandler(_event: DragEvent<HTMLDivElement>, board: IBoard)
	{
		if (currentItem === null || currentBoard === null)
		{
			return;
		}

		if (currentItem.goodBoard !== board.id)
		{
			return setPromptValue(currentItem.title);
		}

		timerBlipBulb(board.id === EBoardId.Good ? "good" : "bad");

		board.items.push(currentItem);
		const currentIndex = currentBoard.items.indexOf(currentItem);

		currentBoard.items.splice(currentIndex, 1);

		setBoards(boards.map(b =>
		{
			if (b.id === board.id)
			{
				return board;
			}

			if (b.id === currentBoard.id)
			{
				return currentBoard;
			}

			return b;
		}));
		setCurrentBoard(null);
		setCurrentItem(null);
	}

	function nextStep()
	{
		missionContext.nextStep();
	}

	return (
		<>
			<Box sx={{
				maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
				width: "100%",
				flexGrow: 1,
				display: "flex",
				flexDirection: "column",
				gap: "17px"
			}}>
				<PanelTitle
					positionOcta
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_title" defaultValue="Let's play a game" position="right" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="LetsPlayGame_subtitle" defaultValue="It’s a very simple game, go through the values below and decide which ones are good and which ones are bad." isDescription position="right" />}
					imageVariation="closeEye"
					sx={{ "& .component-missions-panelTitle-imageContainer": { zIndex: 2 } }}
				/>

				<Fade in={missionContext.showContent}>
					<Box sx={{
						height: { xl: "630px", lg: "478px", xs: "478px" },
						width: "100%",
						display: "grid",
						gridTemplateAreas: '"first god" "first bad"',
						gridTemplateColumns:
						{
							xl: "450px 474px",
							lg: "339px 403px",
							xs: "1fr 1fr"
						},
						gridTemplateRows: "1fr 1fr",
						gap: { xl: "22px 56px", lg: "33px 43px", xs: "23px 43px" },

						"& .board":
						{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							alignItems: "center",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							border: "1px solid rgba(255, 255, 255, 0.5)",
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
							borderRadius: "24px",
							backdropFilter: "blur(30px)",
							gap: "20px",
							padding: "24px",
							overflowY: "auto",
							alignContent: "flex-start",

							"& .dnd-block":
							{
								font: "400 16px/22px 'Open Sans'"
							},
							"&--mini":
							{
								// height: "100%",
								// maxHeight: "227px",

								// "& .dnd-block":
								// {
								// 	cursor: "auto"
								// }
							}
						}
					}}>
						<Box className="board area-first" sx={{ gridArea: "first" }}>
							{boards[ 0 ].items.map((item) => (
								<ChipStyled
									key={item.title}
									className="dnd-block"
									draggable
									// onDragOver={(e) => dragOverHandler(e)}
									onDragStart={(e) => onDragStart(e, boards[ 0 ], item)}
									onDragEnd={onDragEnd}
									// onDrop={(e) => dropHandler(e, boards[ 0 ], item)}
									label={item.title}
									sx={{ cursor: "pointer" }}
								/>
							))}
						</Box>

						<Box className="area-god" sx={{ gridArea: "god", display: "flex", flexDirection: "column", gap: "16px 0", width: "475px" }}>
							<Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
								<Box sx={{
									color: "primary.main",
									font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" }
								}}>
									Good values
								</Box>

								<LightBulb color="green" blip={blipBulb.good} sx={{ "--size": { xl: "56px", lg: "48px", xs: "48px" } }} />
							</Box>

							<Box
								className="board board--mini"
								onDragOver={(e) => dragOverHandler(e)}
								onDrop={(e) => dropCardHandler(e, boards[ 1 ])}
							>
								{boards[ 1 ].items.map((item) => (
									<ChipStyled key={item.title} label={item.title} className="dnd-block" />
								))}
							</Box>
						</Box>

						<Box
							className="area-bad"
							sx={{
								gridArea: "bad", display: "flex", flexDirection: "column", gap: "16px 0", width: "475px"
							}}
						>
							<Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
								<Box sx={{
									color: "primary.main",
									font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" }
								}}>
									Bad values
								</Box>

								<LightBulb color="red" blip={blipBulb.bad} sx={{ "--size": { xl: "56px", lg: "48px", xs: "48px" } }} />
							</Box>

							<Box
								className="board board--mini"
								onDragOver={(e) => dragOverHandler(e)}
								onDrop={(e) => dropCardHandler(e, boards[ 2 ])}
							>
								{boards[ 2 ].items.map((item) => (
									<ChipStyled key={item.title} label={item.title} className="dnd-block" />
								))}
							</Box>
						</Box>
					</Box>
				</Fade>
			</Box>

			<Box sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={boards[ 0 ].items.length !== 0}
					onClick={nextStep}
				/>
			</Box>

			<MissionsDialog title={promptValue as string} open={promptValue !== null} onClose={() => setPromptValue(null)}>
				<p>
					{promptContent[ promptValue as keyof typeof promptContent ]}
				</p>
			</MissionsDialog>

			<Tour
				isOpen={missionContext.isShowTour === true}
				rounded={20}
				maskSpace={5}
				showCloseButton={false}
				showNavigation={false}
				showNumber={false}
				disableInteraction
				closeWithMask={false}
				lastStepNextButton={<CustomButton.Outline>Got it!</CustomButton.Outline>}
				maskClassName="reactour__mask"
				steps={[
					{
						selector: ".TipsButton-root",
						position: "bottom",
						style: { top: "10px" }, // left: "300px",
						content()
						{
							return (
								<Box sx={{
									position: "relative",
									transform: { xl: "translateX(300px)", lg: "translateX(250px)", xs: "translateX(-75px)" },

									"& + div":
									{
										transform: { xl: "translateX(300px)", lg: "translateX(250px)", xs: "translateX(-75px)" }
									}
								}}>
									<Box
										component="img"
										src={IMGOcta2}
										sx={{
											position: "absolute",
											left: 0,
											top: { xl: "-110px", lg: "-90px", xs: "-90px" },
											transform: "rotate(-9.11deg) translateX(-80%)",
											width: { xl: "148px", lg: "117px", xs: "117px" }
										}}
									/>

									<Box sx={{
										width: { xl: "375px", lg: "286px", xs: "286px" },
										background: "#E6F4F6",
										boxShadow: "0px 3px 6px " + alpha("#000000", 0.5),
										border: "1px solid " + alpha("#FFFFFF", 0.5),
										borderRadius: "16px",
										padding: "24px",
										font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										color: "primary.dark"
									}}>
										{user?.name || "Name"}, here you will find additional information about exercises if you get stuck.
									</Box>
								</Box>
							);
						}
					}
				]}
				onRequestClose={() => missionContext.setIsShowTour(false)}
			/>
		</>
	);
}

export default LetsPlayGame;
