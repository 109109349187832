import { createContext, Dispatch } from "react";
import { ActionType, IUserState } from "../reducers/userReducer";

export interface IUserContext
{
	state: IUserState;
	dispatch: Dispatch<ActionType>;
}

export const UserCtx = createContext<IUserContext>({} as IUserContext);
