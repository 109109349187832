import { Box, Grid, Typography } from "@mui/material";
import Growth from "../../../../assets/images/Growth.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function GrowthDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Growth} alt="Growth" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Growth
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="Personal growth keeps us engaged throughout life. It is completely normal to go through cycles with personal development. Sometimes other things in life come to a fore. But if you’ve felt stagnant for a while, it’s a good idea to bring your personal growth agenda back into the spotlight." keyContent="contentMissionOne" keyText="growthScreen1" />
				{/* Personal growth keeps us engaged throughout life. It is completely normal to go through cycles with personal development. Sometimes other things in life come to a fore. But if you’ve felt stagnant for a while, it’s a good idea to bring your personal growth agenda back into the spotlight. */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box sx={{
						fontSize: "18px",
						fontFamily: "Open Sans",
						fontWeight: "medium",
						color: "#4D4D4D",
						marginTop: "10px",
						"& ul":
						{
							listStyle: "none",
							"& li": { position: "relative" },
							"& li::before":
							{
								content: "''",
								display: "block",
								position: "absolute",
								left: "-24.833px",
								top: "50%",
								transform: "translateY(-50%)",
								width: "5px",
								height: "5px",
								borderRadius: "50%",
								backgroundColor: "#4D4D4D"
							}
						}
					}}
					>
						<ContentAdmin isDescription position="left" defaultValue="- Are you learning new things?\n- Does your job allow you to develop new skills?\n- Is your environment conducive to your growth?\n- What would help you grow as a person?\n\nStarting small is the key to keeping your desired habits. If you want to read or journal regularly, don’t commit to an hour daily. Start with 5 minutes twice a week and build it from there." keyContent="contentMissionOne" keyText="growthDescStart" />
						{/* Starting small is the key to keeping your desired habits. If you want to read or journal regularly, don’t commit to an hour daily. Start with 5 minutes twice a week and build it from there. */}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Does your career support a trajectory in which you want to develop?\n- Are you pursuing hobbies that don’t suit you anymore?\n- Have you lost the spark in life and don’t feel like doing anything?" keyContent="contentMissionOne" keyText="growthDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "7px",
						height: "7px",
						borderRadius: "50%",
						backgroundColor: "#215C75"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Feeling stuck and unmotivated might be a sign you are on a wrong trajectory in life. It could be you are not driven in your career, feel stressed and overworked or have no headspace or energy to focus on yourself.\\n\\nThe good news is, that you’ve made your first step and realised you need a change. Life Strategy Quest is exactly what you need to help you make that change." keyContent="contentMissionOne" keyText="growthScreen2" />
				{/*
				Feeling stuck and unmotivated might be a sign you are on a wrong trajectory in life. It could be you are not driven in your career, feel stressed and overworked or have no headspace or energy to focus on yourself.<br></br><br></br>
				The good news is, that you’ve made your first step and realised you need a change. Life Strategy Quest is exactly what you need to help you make that change.
				*/}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default GrowthDetails;
