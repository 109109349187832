import { Box } from "@mui/material";
import CelebrationScreen from "components/missions/CelebrationScreen";
import useRegistration from "pages/registration/hooks/useRegistration";

function Greetings()
{
	const { setStep } = useRegistration();

	return (
		<Box sx={{ padding: "0 24px", display: "flex", flexGrow: 1 }}>
			<CelebrationScreen
				content={<>Hi, I am Octa, I’ll help you<br />design your best life!</>}
				buttonText="Continue"
				onClickButton={() => setStep((prevStep) => prevStep + 1)}
				sx={(theme) =>
				{
					return {
						"& .CelebrationScreen-content":
						{
							color: "primary.main",
							textShadow: "unset"
						},
						[ theme.breakpoints.down("lg") ]:
						{
							marginTop: { xs: "unset !important" }
						}
					};
				}}
			/>
		</Box>
	);
}

export default Greetings;
