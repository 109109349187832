import { Box, Button, styled, SxProps, Theme } from "@mui/material";
import { MouseEvent, useLayoutEffect, useState } from "react";
import IMGnewStarOne from "../../assets/images/missions/mission-eleven/constellation-1.png";
import IMGnewStarTwo from "../../assets/images/missions/mission-eleven/constellation-2.png";
import IMGnewStarThree from "../../assets/images/missions/mission-eleven/constellation-3.png";
import IMGnewStarFour from "../../assets/images/missions/mission-eleven/constellation-4.png";
import IMGnewStarFive from "../../assets/images/missions/mission-eleven/constellation-5.png";
import IMGnewStarSix from "../../assets/images/missions/mission-eleven/constellation-6.png";
import IMGnewStarSeven from "../../assets/images/missions/mission-eleven/constellation-7.png";
import IMGnewStarEight from "../../assets/images/missions/mission-eleven/constellation-8.png";
import { ReactComponent as SVGstar } from "../../assets/images/missions/mission-eleven/star.svg";
import { mergeDeep } from "../../utils/mergeDeep";

const ButtonStyled = styled(Button)(() => (
	{
		position: "relative",
		padding: "0",
		minHeight: "55px",
		"&:hover":
		{
			backgroundColor: "unset"
		}
	}
)) as typeof Button;

interface IRadialButtonProps
{
	id?: number;
	value?: string;
	showValue?: boolean;
	absoluteValuePosition?: boolean;
	direction?: "right" | "left";
	blip?: boolean;
	selected?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	color?: "white" | "blue";
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	[ key: string ]: any;
	selectedBtn?: boolean;
}

function StarButton(
	{
		id,
		value,
		showValue = true,
		absoluteValuePosition = false,
		direction = "right",
		blip = false,
		selected = false,
		blipDurationMs = 2000,
		offBlipOnClick = true,
		color = "white",
		onClick,
		className,
		disabled = false,
		sx,
		selectedBtn,
		...props
	}: IRadialButtonProps
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<ButtonStyled
			disableRipple
			disabled={disabled}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={
				mergeDeep({
					margin: "11.5px 0"
					// border: (selectedBtn ? '1px solid green' : '1px solid red')
				}, sx)
			}
			{...props}
		>
			<Box sx={{ position: "relative" }}>
				{selectedBtn
					? id === 0 // id === 0 ? <Box sx={{ position: 'absolute', top: '-29px', left: '-24px' }}><SVGnewStarOne /></Box>
						? <Box sx={{ position: "absolute", top: "-29px", left: "-24px" }}><img src={IMGnewStarOne} alt="" /></Box>
						: id === 1
							? <Box sx={{ position: "absolute", top: "-49px", left: "-59px" }}><img src={IMGnewStarTwo} alt="" /></Box>
							: id === 2
								? <Box sx={{ position: "absolute", top: "-70px", left: "-28px" }}><img src={IMGnewStarThree} alt="" /></Box>
								: id === 3
									? <Box sx={{ position: "absolute", top: "-71px", left: "-80px" }}><img src={IMGnewStarFour} alt="" /></Box>
									: id === 4
										? <Box sx={{ position: "absolute", top: "-66px", left: "-156px" }}><img src={IMGnewStarFive} alt="" /></Box>
										: id === 5
											? <Box sx={{ position: "absolute", top: "-170px", left: "-61px" }}><img src={IMGnewStarSix} alt="" /></Box>
											: id === 6
												? <Box sx={{ position: "absolute", top: "-168px", left: "-203px" }}><img src={IMGnewStarSeven} alt="" /></Box>
												: id === 7 ? <Box sx={{ position: "absolute", top: "-67px", left: "-47px" }}><img src={IMGnewStarEight} alt="" /></Box> : <SVGstar />
					: <SVGstar />
				}
			</Box>
		</ButtonStyled>
	);
}

export default StarButton;
