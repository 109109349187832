import { COLORS, IBoard, IBoardItem } from "../components/ClusterYourYearnings";

function getClusterItemsByColor(clusters: IBoard[], color: keyof typeof COLORS): IBoardItem[]
{
	return clusters.reduce<IBoardItem[]>((items, cluster) =>
	{
		if (cluster.id === "__values__")
		{
			return items;
		}

		const filteredItems = cluster.items.filter((boardItem) => boardItem.color === color);

		return [ ...items, ...filteredItems ];
	}, []);
}

export default getClusterItemsByColor;
