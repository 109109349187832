import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Growth from "../../../../assets/images/Growth.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function GrowthDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Growth} alt="Growth" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Growth
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Personal growth keeps us engaged throughout life. It is completely normal to go through cycles with personal development. Sometimes other things in life come to a fore. But if you’ve felt stagnant for a while, it’s a good idea to bring your personal growth agenda back into the spotlight." keyContent="contentMissionOne" keyText="growthScreen1" />
				{/* Personal growth keeps us engaged throughout life. It is completely normal to go through cycles with personal development. Sometimes other things in life come to a fore. But if you’ve felt stagnant for a while, it’s a good idea to bring your personal growth agenda back into the spotlight. */}
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="Starting small is the key to keeping your desired habits. If you want to read or journal regularly, don’t commit to an hour daily. Start with 5 minutes twice a week and build it from there." keyContent="contentMissionOne" keyText="growthDescStart" />
							{/* Starting small is the key to keeping your desired habits. If you want to read or journal regularly, don’t commit to an hour daily. Start with 5 minutes twice a week and build it from there. */}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Does your career support a trajectory in which you want to develop?\n- Are you pursuing hobbies that don’t suit you anymore?\n- Have you lost the spark in life and don’t feel like doing anything?" keyContent="contentMissionOne" keyText="growthDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Feeling stuck and unmotivated might be a sign you are on a wrong trajectory in life. It could be you are not driven in your career, feel stressed and overworked or have no headspace or energy to focus on yourself.\\n\\nThe good news is, that you’ve made your first step and realised you need a change. Life Strategy Quest is exactly what you need to help you make that change." keyContent="contentMissionOne" keyText="growthScreen2" />
				{/*
				 Feeling stuck and unmotivated might be a sign you are on a wrong trajectory in life. It could be you are not driven in your career, feel stressed and overworked or have no headspace or energy to focus on yourself.<br></br><br></br>
				The good news is, that you’ve made your first step and realised you need a change. Life Strategy Quest is exactly what you need to help you make that change.
				*/}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default GrowthDetails;
