import { Box } from "@mui/material";
import { forwardRef, Ref, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage } from "../../../../utils/localStorage";
import IMGBubble from "../../assets/images/bubble.png";
import useMission from "../../hooks/useMission";

export type IMyNewEmpoweringBeliefsProps = Record<string, any>;

function MyNewEmpoweringBeliefs({ ...props }: IMyNewEmpoweringBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ items ] = useState<string[]>(() =>
	{
		const items = getLocalStorage<string[]>("mission-seven-top-rewrite");

		if (items === null)
		{
			missionContext.setStep(14);

			return [];
		}

		return items;
	});

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="MyNewEmpoweringBeliefs_title" defaultValue="My New Empowering Beliefs" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="MyNewEmpoweringBeliefs_subTitle" defaultValue="" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { left: "-35px" }
					}}
				/>

				<Panel sx={{
					marginTop: "50px",
					padding: { xl: "32px 17px", lg: "16px", xs: "16px" },
					width: { xl: "100%", lg: "524px", xs: "524px" }
				}}>
					<Box
						component="ul"
						className="customScroll"
						sx={{
							paddingLeft: "0px",
							margin: "0px",
							maxHeight: "500px",
							overflowY: "auto",

							"& li":
							{
								display: "flex",
								alignItems: "center",
								gap: "12px",
								listStyle: "none",
								font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
								color: "primary.dark"
							},
							"& li:not(:last-child)": { marginBottom: "16px" },
							"& li::before":
							{
								content: "''",
								display: "inline-block",
								height: "16px",
								width: "16px",
								background: `url(${ IMGBubble }) center center/cover`
							}
						}}
					>
						{items.map((item, index) =>
						{
							return <li key={index + item}>{item}</li>;
						})}
					</Box>
				</Panel>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={() => { missionContext.nextStep(); }} />
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(MyNewEmpoweringBeliefs);
