import { Box, SxProps, Theme, alpha, keyframes } from "@mui/material";
import SVGCareer from "assets/images/Career.svg";
import SVGFriend from "assets/images/Friend.svg";
import SVGFun from "assets/images/Fun.svg";
import SVGGrowth from "assets/images/Growth.svg";
import SVGHealth from "assets/images/Health.svg";
import SVGLove from "assets/images/Love.svg";
import SVGMoney from "assets/images/Money.svg";
import SVGHome from "assets/images/home.svg";
import { Captions, captions } from "components/Wheel";

interface ITabProps
{
	readonly value: number;
	readonly icon: Captions;
	/**
	 * @default false
	 */
	readonly waveAnimation?: boolean;
	/**
	 * @default false
	 */
	readonly active?: boolean;
	onClick?(): void;
	readonly sx?: SxProps<Theme>;
}

const waveAnimationKeyframe = keyframes`
	0%
	{
		left: -10%;
		top: -10px;
	}
	50%
	{
		left: 110%;
	}
	80%
	{
		left: 200%;
		top: -10px;
		bottom: 0px;
	}
	100%
	{
		bottom: 200%;
		top: -200%;
	}
`;

function Tab({ value, icon, onClick, active = false, waveAnimation, sx = [] }: ITabProps)
{
	const icons: Record<ITabProps[ "icon" ], string> =
	{
		home: SVGHome,
		love: SVGLove,
		career: SVGCareer,
		friend: SVGFriend,
		fun: SVGFun,
		growth: SVGGrowth,
		money: SVGMoney,
		health: SVGHealth
	};

	return (
		<Box
			component="button"
			onClick={onClick}
			sx={[
				{
					position: "relative",

					padding: "unset",
					width: { xl: "160px", lg: "128px", xs: "100px" },
					height: { xl: "120px", xs: "96px" },

					background: "linear-gradient(104deg, rgba(229, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					backdropFilter: "blur(30px)",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: { xl: "16px", xs: "12px" },

					// opacity: active === true ? 1 : { lg: 0.4, xs: 0.5 },

					cursor: "pointer",

					transition: "linear 200ms opacity",

					"&:hover":
					{
						opacity: active === true ? 1 : 0.7
					},
					"&::before":
					{
						opacity: 0,
						transition: "linear 200ms opacity",

						content: "''",
						position: "absolute",
						top: { xl: "-40px", lg: "-16px" },
						left: { xl: "-41px", lg: "-17px" },
						bottom: { xl: "-25px", lg: "-9px" },
						zIndex: -1,

						width: { xl: "240px", lg: "160px" },
						background: alpha("#FFFFFF", 0.2),
						borderRadius: "32px 32px 0 0"
					}
				},
				active === true &&
				{
					"&::before":
					{
						opacity: { lg: 1 }
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Box sx={{
				position: "relative",

				height: "100%",
				width: "100%",

				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				gap: "5px",
				overflow: "hidden",

				"&::after":
				{
					opacity: waveAnimation === true ? 1 : 0,
					animation: waveAnimationKeyframe + " 3s infinite ease-in-out",
					content: "''",
					position: "absolute",
					top: "-20px",
					bottom: "-20px",
					left: "0",
					width: "25px",
					background: alpha("#FFFFFF", 0.5),
					transform: "rotate(20deg)",

					transition: "linear 300ms opacity"
				}
			}}>
				<Box sx={{ position: "relative" }}>
					<Box
						sx={{
							"--size": { xl: "32px", xs: "26px" },
							width: "var(--size)",
							height: "var(--size)",
							backgroundColor: alpha("#FFFFFF", 0.5),
							boxShadow: "0px 5px 20px 0px " + alpha("#7ec4ff", 0.4),
							backdropFilter: "blur(30px)",
							borderRadius: "50%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							font: { xl: "700 18px/25px 'Open Sans'", xs: "700 14px/20px 'Open Sans'" },
							color: (value < 7 ? "#BF5858" : "#1fad77"),
							position: "absolute",
							top: "0",
							left: "0",
							transform: "translate(-30%, -30%)"
						}}
					>
						{value}
					</Box>
					<Box
						sx={[
							{
								"--size": { xl: "60px", xs: "48px" },
								width: "var(--size)",
								height: "var(--size)",
								background: `url(${ icons[ icon ] }) center center/cover`
							}
						]}
					/>
				</Box>
				<Box
					sx={{
						font: { xl: "700 18px/23px Lora-Bold", xs: "700 14px/18px Lora-Bold" },
						textAlign: "center",
						color: "primary.main"
					}}
				>
					{captions[ icon ]}
				</Box>
			</Box>
		</Box>
	);
}

export default Tab;
