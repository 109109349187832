import { Box, Button, Slider, Typography, alpha } from "@mui/material";
import Career from "assets/images/Career.svg";
import Friend from "assets/images/Friend.svg";
import Fun from "assets/images/Fun.svg";
import Growth from "assets/images/Growth.svg";
import Health from "assets/images/Health.svg";
import Love from "assets/images/Love.svg";
import Money from "assets/images/Money.svg";
import home from "assets/images/home.svg";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import loveOpacity from "assets/images/loveOpacity.svg";
import meditate from "assets/images/meditate.png";
import moneyOpacity from "assets/images/moneyOpacity.svg";
import step3 from "assets/images/step3.svg";
import step4 from "assets/images/step4.svg";
import step5 from "assets/images/step5.svg";
import step6 from "assets/images/step6.svg";
import step7 from "assets/images/step7.svg";
import ReferFriendsModal from "components/Dialog/ReferFriends.modal";
import TooltipStyled from "components/TooltipStyled";
import { WheelData } from "components/Wheel";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import bgSteper from "pages/registration/assets/images/complete-background.jpg";
import SteperHeader from "pages/startPage/fields/SteperHeader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import { setLocalStorage } from "utils/localStorage";

const items = [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10" ];

const captions =
{
	home: "home",
	health: "health",
	friend: "friend",
	love: "love",
	career: "Speed",
	money: "Weight",
	fun: "Weight2",
	growth: "Weight3"
};

function MissionOneContainer()
{
	const location = useLocation();
	const userContext = useUser();
	const navigate = useNavigate();

	const [ isOpenShare, setIsOpenShare ] = useState<boolean>(false);
	const [ step, setStep ] = useState(0);
	const [ valSlider, setValSlider ] = useState(5);
	const [ valGraph, setValGraph ] = useState<WheelData>(
		{
			home: 0,
			health: 0,
			friend: 0,
			love: 0,
			career: 0,
			money: 0,
			fun: 0,
			growth: 0
		}
	);

	// Hooks
	useEffect(() =>
	{
		if (step !== 8)
		{
			return;
		}

		setLocalStorage("mission-one-graph", valGraph);

		if (userContext.state.isAuthenticated)
		{
			return navigate("/mission-one/results");
		}

		navigate("/registration");

	}, [ step, valGraph, navigate, userContext.state.isAuthenticated ]);

	// Handles
	function handleValSlider(value: number)
	{
		setValSlider(value);
	}

	function handleStepGraph(value: string)
	{
		const stepKeys = [ "health", "friend", "love", "career", "money", "fun", "growth", "home" ];

		if (stepKeys[ step ] !== undefined)
		{
			setValGraph(
				{
					...valGraph,
					[ stepKeys[ step ] ]: +value / 10
				}
			);

			setValSlider(5);
		}

		setStep((prev) => prev + 1);
	}

	function renderStep(step: number)
	{
		switch (step)
		{
			case 0:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Health} alt="Health" />}
					title="Health"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How happy are you with your health, wellness, and physical body?"
						keyContent="contentMissionOne"
						keyText="healthDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 1:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Friend} alt="Health" />}
					title="Friends & Family"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How satisfied are you with your social life?"
						keyContent="contentMissionOne"
						keyText="relationshipsDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 2:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Love} alt="Love" />}
					title="Love"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How’s your romantic life?"
						keyContent="contentMissionOne"
						keyText="loveDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 3:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Career} alt="Career" />}
					title="Career"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How happy and fulfilled are you professionally?"
						keyContent="contentMissionOne"
						keyText="careerDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 4:
				return (
					<SteperHeader
						// @ts-ignore
						handleStep={handleStepGraph}
						img={<img width="100%" src={Money} alt="Money" />}
						title="Money"
						description={<ContentAdmin
							isDescription
							position="left"
							defaultValue="How comfortable are you with your financial situation?"
							keyContent="contentMissionOne"
							keyText="moneyDesc"
						/>}
						sx={{
							paddingTop: "24px !important"
						}}
					/>
				);
			case 5:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Fun} alt="Fun" />}
					title="Fun"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="Are you having fun?"
						keyContent="contentMissionOne"
						keyText="funDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 6:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={Growth} alt="Growth" />}
					title="Growth"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How satisfied are you with your personal growth?"
						keyContent="contentMissionOne"
						keyText="growthDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			case 7:
				return (<SteperHeader
					// @ts-ignore
					handleStep={handleStepGraph}
					img={<img width="100%" src={home} alt="home" />}
					title="Physical environment"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How comfortable is your physical environment?"
						keyContent="contentMissionOne"
						keyText="physicalEnvironmentDesc"
					/>}
					sx={{
						paddingTop: "24px !important"
					}}
				/>);
			default:
				return "err";
		}
	}

	const data = [ { data: valGraph } ];

	function valuetext(value: any)
	{
		return value + "";
	}

	return (
		<Box
			className="heighSteper pb0"
			sx={{
				position: "relative",
				width: "100%",
				background: `url(${ bgSteper }) center center/cover`,
				flexGrow: 1,
				display: "flex",
				flexDirection: "column",

				minHeight: "100vh",

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				}
			}}
		>
			{userContext.state.isAuthenticated
				? (
					<TooltipStyled title="Back to dashboard" placement="right" arrow>
						<Box
							component="button"
							onClick={() => navigate("/dashboard")}
							sx={{
								position: "fixed",
								top: { xl: "24px", xs: "16px" },
								left: { xl: "32px", xs: "24px" },
								cursor: "pointer",
								display: "flex",
								padding: "unset",
								border: "unset",
								background: "unset",

								"& path":
								{
									transition: "linear 300ms fill-opacity"
								},
								"&:hover path":
								{
									fillOpacity: 1
								}
							}}
						>
							<SVGArrowBack />
						</Box>
					</TooltipStyled>
				)
				: null
			}

			<Box
				component="img"
				src={meditate}
				alt="meditate"
				sx={{
					position: "absolute",
					bottom: "20px",
					left: "20px",
					display: { xs: "none", sm: "block" }
				}}
			/>

			{/* {location.pathname.split("/")[ 1 ] !== "artifact" && (
				<CustomButton.Contained
					onClick={() => setIsOpenShare(true)}
					sx={{
						height: { lg2: "56px", xs: "40px" },

						display: { xs: "none", md: "flex" },
						position: "absolute",
						bottom: "30px",
						right: "30px",

						paddingLeft: { lg2: "80px", xs: "20px" },
						paddingRight: { lg2: "80px", xs: "20px" },

						"& svg":
						{
							"--size": { lg2: "40px", xs: "24px" },
							width: "var(--size)",
							height: "var(--size)"
						},
						"& path":
						{
							fill: "currentColor",
							stroke: "unset"
						}
					}}
				>
					<SVGRefer />
					Share the tool with a friend 123
				</CustomButton.Contained>
			)} */}

			{/* <Box sx={{ background: { xs: '', sm: `url(${meditate}) no-repeat` }, backgroundPosition: { xs: '', sm: '10px 98%' }, height: '100vh' }}> */}
			<Box sx={{
				marginTop: { xs: "52px", sm: "unset" },
				backgroundPosition: { xs: "", sm: "10px 98%" }
			}}>
				{renderStep(step)}

				<Box
					sx={{
						margin: location.pathname.split("/")[ 1 ] === "artifact"
							? "24px auto 0"
							: { xl: "40px auto 0 auto", xs: "24px auto 0 auto" },
						display: { xs: "none", sm: "flex" },
						justifyContent: "center",
						gap: { xl: "16px", xs: "12px" }
					}}
				>
					{items.map((item) => (
						<Button
							key={item}
							onClick={() => handleStepGraph(item)}
							sx={{
								"--size": { xl: "80px", xs: "64px" },
								width: "var(--size)",
								height: "var(--size)",

								background: "rgba(255,255,255,0.6)",
								fontSize: "24px",
								color: "#215C75",
								fontWeight: "bold",
								borderRadius: "10px",
								// '&:hover': { background: '#9db6cf' }
								"&:hover": { background: "#718EC0" }
							}}
						>
							{item}
						</Button>
					))}
				</Box>

				<Box sx={{
					display: { xs: "none", sm: "flex" },
					justifyContent: "space-between",
					width: { xs: "90%", md: "836px", xl: "1026px" },
					m: { xs: "8px auto 0 auto", sm: "16px auto 0" },
					height: "70px"
				}}>
					<Typography sx={{
						fontSize: { xs: "14px", sm: "16px" },
						fontFamily: "Open Sans",
						textAlign: { xs: "left", sm: "center" },
						fontWeight: "medium",
						color: "#fff",
						width: "144px",
						lineHeight: { xs: "19px", sm: "23px" }
					}}>
						{[
							<ContentAdmin isDescription position="right" defaultValue="I am feeling like a dying horse" keyContent="contentMissionOne" keyText="healthBad" />,
							<ContentAdmin isDescription position="right" defaultValue="Does my cat count?" keyContent="contentMissionOne" keyText="relationshipBad" />,
							<ContentAdmin isDescription position="right" defaultValue="I’m applying for cat adoption" keyContent="contentMissionOne" keyText="loveBad" />,
							<ContentAdmin isDescription position="right" defaultValue="Please cancel Mondays" keyContent="contentMissionOne" keyText="careerBad" />,
							<ContentAdmin isDescription position="right" defaultValue="Instant noodles are the staple food of my diet" keyContent="contentMissionOne" keyText="moneyBad" />,
							<ContentAdmin isDescription position="right" defaultValue="Does binge watching Netflix count?" keyContent="contentMissionOne" keyText="funBad" />,
							<ContentAdmin isDescription position="right" defaultValue="I forgot what a book looks like 😳" keyContent="contentMissionOne" keyText="growthBad" />,
							<ContentAdmin isDescription position="right" defaultValue="Living under a bridge would be more comfortable" keyContent="contentMissionOne" keyText="physicalEnvironmentBad" />
						][ step ]}
					</Typography>
					<Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontFamily: "Open Sans", textAlign: { xs: "right", sm: "center" }, fontWeight: "medium", color: "#fff", width: "144px", lineHeight: { xs: "19px", sm: "23px" } }}>
						{[
							<ContentAdmin isDescription position="left" defaultValue="Very, I am applying to join the Olympics!" keyContent="contentMissionOne" keyText="healthGood" />,
							<ContentAdmin isDescription position="left" defaultValue="Great Gatsby would be jealous" keyContent="contentMissionOne" keyText="relationshipGood" />,
							<ContentAdmin isDescription position="left" defaultValue="Like Romeo & Juliette but with a happy ending" keyContent="contentMissionOne" keyText="loveGood" />,
							<ContentAdmin isDescription position="left" defaultValue="I wouldn’t quit my job for a billion dollars!" keyContent="contentMissionOne" keyText="careerGood" />,
							<ContentAdmin isDescription position="left" defaultValue="I really need to find a bigger bay for all my yachts!" keyContent="contentMissionOne" keyText="moneyGood" />,
							<ContentAdmin isDescription position="left" defaultValue="I’m having a blast! Can Red Bull sponsor me?" keyContent="contentMissionOne" keyText="funGood" />,
							<ContentAdmin isDescription position="left" defaultValue="Dalai Lama would quote me" keyContent="contentMissionOne" keyText="growthGood" />,
							<ContentAdmin isDescription position="left" defaultValue="My Persian rugs are too hard on my feet" keyContent="contentMissionOne" keyText="physicalEnvironmentGood" />
						][ step ]}
					</Typography>
				</Box>

				{/* Graph */}
				<Box
					sx={{
						width: { xs: "310px", sm: "450px" },
						m: {
							xs: "32px auto 0 auto",
							sm: "-100px auto 0 auto",
							lg2: "-70px auto 0 auto",
							xl: "-30px auto 0 auto"
						},
						position: "relative",
						transform:
						{
							lg: "scale(0.8)",
							xl: location.pathname.split("/")[ 1 ] === "artifact" ? "scale(0.7)" : "scale(1)"
						}
					}}
				>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						<RadarChart
							captions={captions}
							// @ts-ignore
							data={data}
							size={450}
							options={{
								scales: 10
							}}
						/>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "none" } }}>
						<RadarChart
							captions={captions}
							// @ts-ignore
							data={data}
							size={310}
							options={{
								scales: 10
							}}
						/>
					</Box>

					<Box sx={{ position: "absolute", top: { xs: "87px", sm: "131px" }, left: { xs: "94px", sm: "131px" }, width: { xs: "123px", sm: "188px" }, height: "3px", background: "#fff", transform: "rotate(270deg)", opacity: .4 }} />
					<Box sx={{ position: "absolute", top: { xs: "107px", sm: "157px" }, left: { xs: "141px", sm: "198px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
					<Box sx={{ position: "absolute", top: { xs: "154px", sm: "223px" }, left: { xs: "160px", sm: "227px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", opacity: step > 0 ? 1 : .4 }} />
					<Box sx={{ position: "absolute", top: { xs: "200px", sm: "289px" }, left: { xs: "140px", sm: "198px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(225deg)", opacity: step > 1 ? 1 : .4 }} />
					<Box sx={{ position: "absolute", top: { xs: "219px", sm: "318px" }, left: { xs: "93px", sm: "132px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(270deg)", opacity: step > 2 ? 1 : .4 }} />
					<Box sx={{ position: "absolute", top: { xs: "199px", sm: "290px" }, left: { xs: "47px", sm: "65px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(315deg)", opacity: step > 3 ? 1 : .4 }} />
					<Box sx={{ position: "absolute", top: { xs: "154px", sm: "223px" }, left: { xs: "28px", sm: "36px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", opacity: step > 4 ? 1 : .6 }} />
					<Box sx={{ position: "absolute", top: { xs: "108px", sm: "156px" }, left: { xs: "48px", sm: "65px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(45deg)", opacity: step > 5 ? 1 : .4 }} />

					<Box sx={{ position: "absolute", top: { xs: "30px", sm: "40px" }, right: { xs: "32px", sm: "40px" }, width: { xs: "40px", sm: "62px" } }}>
						<img width="100%" src={Health} alt="Health" />
					</Box>
					<Box sx={{ position: "absolute", top: { xs: "137px", sm: "194px" }, right: { xs: "-13px", sm: "-25px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 0
							? <img width="100%" src={Friend} alt="Friend" /> :
							<img width="100%" src={step4} alt="step4" />}
					</Box>
					{step > 1
						? (
							<Box sx={{ position: "absolute", top: { xs: "240px", sm: "345px" }, right: "32px", width: { xs: "40px", sm: "62px" } }}>
								<img width="100%" src={Love} alt="Love" />
							</Box>
						)
						: (
							<Box sx={{ position: "absolute", top: { xs: "231px", sm: "330px" }, right: { xs: "21px", sm: "22px" }, width: { xs: "60px", sm: "92px" } }}>
								<img width="100%" src={loveOpacity} alt="loveOpacity" />
							</Box>
						)}
					<Box sx={{ position: "absolute", top: { xs: "282px", sm: "412px" }, right: { xs: "135px", sm: "194px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 2
							? <img width="100%x" src={Career} alt="Friend" /> :
							<img width="100%" src={step7} alt="step7" />}
					</Box>

					<Box sx={{ position: "absolute", top: { xs: "239px", sm: "348px" }, right: { xs: "238px", sm: "348px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 3
							? <img width="100%" src={Money} alt="step6" /> :
							<img width="100%" src={moneyOpacity} alt="step6" />}
					</Box>
					<Box sx={{ position: "absolute", top: { xs: "135px", sm: "193px" }, right: { xs: "282px", sm: "414px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 4
							? <img width="100%" src={Fun} alt="Fun" /> :
							<img width="100%" src={step5} alt="step5" />}
					</Box>
					<Box sx={{ position: "absolute", top: { xs: "32px", sm: "37px" }, right: { xs: "238px", sm: "346px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 5
							? <img width="100%" src={Growth} alt="Growth" /> :
							<img width="100%" src={step3} alt="step3" />}
					</Box>
					<Box sx={{ position: "absolute", top: { xs: "-13px", sm: "-22px" }, left: { xs: "136px", sm: "194px" }, width: { xs: "40px", sm: "62px" } }}>
						{step > 6
							? <img width="100%" src={home} alt="moneyOpacity" /> :
							<img width="100%" src={step6} alt="moneyOpacity" />}
					</Box>
					<Box sx={{ position: "absolute", top: { xs: "142px", sm: "207px" }, right: { xs: "142px", sm: "207px" }, width: { xs: "25px", sm: "36px" }, height: { xs: "25px", sm: "36px" }, borderRadius: "100px", background: "#fff", opacity: .4 }} />
				</Box>

				{/* Slider (mobile) */}
				<Box sx={{ display: { xs: "block", sm: "none" }, width: "90%", m: "32px auto 0" }}>
					<Box sx={{
						display: "flex",
						justifyContent: "space-between",
						font: "700 17px/25px 'Open Sans'",
						color: "#fff"
					}}>
						<span>1</span>
						<span>10</span>
					</Box>

					<Slider
						aria-label="Always visible"
						getAriaValueText={valuetext}
						value={valSlider}
						step={1}
						onChange={(e) => handleValSlider(Number((e.target as HTMLInputElement)!.value))}
						max={10}
						min={1}
						valueLabelDisplay="on"
						sx={{
							color: "#215C75",
							p: "8px 0 0 0 !important",

							".MuiSlider-rail":
							{
								background: "#215C75", opacity: 1
							},
							".MuiSlider-thumb":
							{
								color: "transparent",

								"&::before":
								{
									boxShadow: "none !important"
								}
							},
							".MuiSlider-track":
							{
								height: "2px"
							},
							".MuiSlider-valueLabel":
							{
								padding: "1px",
								width: "32px",
								transform: "translateY(-51%) scale(1) !important",
								backgroundColor: alpha("#FFFFFF", 0.5),
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.40)",
								borderRadius: "8px",
								clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%)",

								font: "700 18px/25px 'Open Sans'",
								color: "primary.main"
							},
							"& .MuiSlider-valueLabelCircle":
							{
								display: "block",
								width: "100%",
								padding: "8px 0 12px 0",
								background: "linear-gradient(104deg, #D2D4DB 9.99%, #A3B2C6 91.61%)",
								borderRadius: "7px",
								textAlign: "center",

								clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%)"
							}
						}}
					/>

					<Box sx={{
						display: "flex",
						justifyContent: "space-between",
						width: { xs: "90%", md: "950px" },
						m: { xs: "8px auto 0 auto", sm: "20px auto 0" },
						height: "70px"
					}}>
						<Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontFamily: "Open Sans", textAlign: { xs: "left", sm: "center" }, fontWeight: "medium", color: "#fff", width: "144px", lineHeight: { xs: "19px", sm: "23px" } }}>
							{
								[
									<ContentAdmin isDescription position="right" defaultValue="I am feeling like a dying horse" keyContent="contentMissionOne" keyText="healthBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Does my cat count?" keyContent="contentMissionOne" keyText="relationshipBad" />,
									<ContentAdmin isDescription position="right" defaultValue="I’m applying for cat adoption" keyContent="contentMissionOne" keyText="loveBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Please cancel Mondays" keyContent="contentMissionOne" keyText="careerBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Instant noodles are the staple food of my diet" keyContent="contentMissionOne" keyText="moneyBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Does binge watching Netflix count?" keyContent="contentMissionOne" keyText="funBad" />,
									<ContentAdmin isDescription position="right" defaultValue="I forgot what a book looks like 😳" keyContent="contentMissionOne" keyText="growthBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Living under a bridge would be more comfortable" keyContent="contentMissionOne" keyText="physicalEnvironmentBad" />
								][ step ]
							}
						</Typography>
						<Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontFamily: "Open Sans", textAlign: { xs: "right", sm: "center" }, fontWeight: "medium", color: "#fff", width: "144px", lineHeight: { xs: "19px", sm: "23px" } }}>
							{
								[
									<ContentAdmin isDescription position="left" defaultValue="Very, I am applying to join the Olympics!" keyContent="contentMissionOne" keyText="healthGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Great Gatsby would be jealous" keyContent="contentMissionOne" keyText="relationshipGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Like Romeo & Juliette but with a happy ending" keyContent="contentMissionOne" keyText="loveGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I wouldn’t quit my job for a billion dollars!" keyContent="contentMissionOne" keyText="careerGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I really need to find a bigger bay for all my yachts!" keyContent="contentMissionOne" keyText="moneyGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I’m having a blast! Can Red Bull sponsor me?" keyContent="contentMissionOne" keyText="funGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Dalai Lama would quote me" keyContent="contentMissionOne" keyText="growthGood" />,
									<ContentAdmin isDescription position="left" defaultValue="My Persian rugs are too hard on my feet" keyContent="contentMissionOne" keyText="physicalEnvironmentGood" />
								][ step ]
							}
						</Typography>
					</Box>
				</Box>
			</Box>

			{/* Submit button and share (mobile) */}
			<Box sx={{
				display: { sm: "none" },
				padding: "0 16px",
				marginTop: { xl: "62px", lg: "80px", xs: "auto" },
				width: "100%",
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Base
					size="large"
					onClick={() => handleStepGraph(valSlider + "")}
					sx={(theme) => ({
						display: "flex",
						margin: "0 auto",
						boxShadow: "unset",

						[ theme.breakpoints.down("sm") ]:
						{
							maxWidth: "434px",
							width: "100%"
						}
					})}
				>
					Submit
				</CustomButton.Base>

				{/* TODO: Change it into a button */}
				{/* <Box
					onClick={() => setIsOpenShare(true)}
					ml={3}
					sx={{
						background: "#3E809D",
						borderRadius: "100px",
						p: "12px 12px 12px 16px",
						position: "fixed",
						bottom: "10px",
						right: "15px",
						display: { xs: "block", sm: "none" }
					}}
				>
					<img width="31px" src={share} alt="share" />
				</Box> */}
			</Box>

			<ReferFriendsModal
				open={isOpenShare}
				onClose={() => setIsOpenShare(false)}
			/>
		</Box>
	);
}

export default MissionOneContainer;
