import { Box, Grow, Zoom } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import IMGBgBlur from "assets/images/missions/mission-six/bg-blur.png";
import IMGBgDefault from "assets/images/missions/mission-six/bg.png";
import IMGMission5 from "assets/images/missions/mission-six/mission-five.png";
import IMGMission4 from "assets/images/missions/mission-six/mission-four.png";
import IMGMission3 from "assets/images/missions/mission-six/mission-three.png";
import IMGMission2 from "assets/images/missions/mission-six/mission-two.png";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { IMissionContext, MissionCtx } from "./MissionCtx";
import Celebration from "./components/Celebration";
import Complete from "./components/Complete";
import Finish from "./components/Finish";
import ImageButton from "./components/ImageButton";
import ModalMission2Data from "./components/ModalMissionData/Mission2";
import ModalMission3Data from "./components/ModalMissionData/Mission3";
import ModalMission4Data from "./components/ModalMissionData/Mission4";
import ModalMission5Data from "./components/ModalMissionData/Mission5";
import TimeToReview from "./components/TimeToReview";
import Welcome from "./components/Welcome";
import YourVisionBoard from "./components/YourVisionBoard/index-new";
import Feedback from "./components/feedback";

export type BackgroundType = Record<"default" | "blur", string>;

// Background
const Background: BackgroundType =
{
	default: `url(${ IMGBgDefault }) bottom center/cover`,
	blur: `url(${ IMGBgBlur }) bottom center/cover`
};

const BackgroundPreload = [
	IMGBgDefault,
	IMGBgBlur
];

const STEPS = [
	Welcome,
	TimeToReview,
	YourVisionBoard,
	Celebration,
	Finish,
	Complete,
	Feedback
];

const PERCENTAGE_PER_STEP: number = 100 / (STEPS.length - 1);

function MissionSixContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ step, setStep ] = useState<number>(() =>
	{
		const step = getLocalStorage<number>("mission-six-step");

		return step ?? 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: JSX.Element; position?: "bottom" | "center"; } | null>(null);
	const [ showMissionInfo, setShowMissionInfo ] = useState<null | 2 | 3 | 4 | 5>(null);

	// Context
	const MissionContext: IMissionContext = useMemo(() =>
	{
		return {
			step,
			setStep,
			nextStep,
			prevStep,
			progress,
			setProgress,

			setShowMissionInfo
		};
	}, [ progress, step ]);

	// Utils
	function changeBackground(step: number)
	{
		switch (step)
		{
			case 3: { return setBackground("blur"); }

			default: { setBackground("default"); }
		}
	}

	function prevStep()
	{
		setStep((prevStep) => prevStep - 1);
	}

	function nextStep()
	{
		setStep((prevStep) => prevStep + 1);
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(6, step).catch((error) => console.error(error));

		setLocalStorage("mission-six-step", step);

		changeBackground(step);
		setProgress((step - 1) * PERCENTAGE_PER_STEP);

		// Close modal
		setIsShowTips(false);

		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 2: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent="contentMissionSix" keyText="timeToReview_tips" defaultValue="**Why:** You have thought about your life from various angles. Now it’s time to put all pieces of the puzzle together. The output of this step will serve you as a foundation for developing your dream life mood board in the next step.\n\n**Do:** Go back to where you’ve started and step by step review everything that you’ve discovered: values, yearnings, fulfilling career and your perfect average day. Don’t worry, you don’t need to remember everything, you will still be able to access it in the next step.\n\n**Think:** You are on a journey and might discover that things have changed since you did previous missions. Don’t stress about it, nothing is set in stone and it’s meant to be an iterative process. Just reflect your latest dreams & wants in the next exercise." position="right" isDescription />)
					}
				);
				break;
			}

			case 3: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent="contentMissionSix" keyText="yourVisionBoard_tips" defaultValue="**Why:** Creating a moodboard is a great way to visualise the ideas that are shaping in your head. When you visualise something, you help your brain to work towards your dream life, as it knows exactly what it must look like.\n\n**Do:** Using the clues from previous missions, try to visualise everything that you see there. Find the images that reflect your dream life the most. You can include images of who you want to be, your lifestyle, your environment, your activities or anything else.\n\n**Think:** This is the time to dream big, your dream muscle should be strong by now! What do you see? Maybe you see yourself wearing an expensive suit as you give an important presentation in front of the crowd? Or spending a lazy morning in your pajamas enjoying a cup of a freshly brewed coffee in your bright and modern bedroom? Make it yours!" position="right" isDescription />)
					}
				);
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}
	}, [ step ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("mission-six-progress", progress);
	}, [ progress ]);

	// Render
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<MissionCtx.Provider value={MissionContext}>
				<Box
					className="mission-root"
					sx={{
						width: "100%",
						display: { sm: "flex", xs: "none" },
						flexDirection: "column",
						alignItems: "center",
						gap: "25px",
						background: Background[ background ],
						minHeight: "100vh",
						backgroundAttachment: "fixed",
						padding: { xl: step === 6 || step === 7 ? 0 : "24px", lg: step === 6 || step === 7 ? 0 : "16px", xs: step === 6 || step === 7 ? 0 : "16px" },
						transition: "linear 300ms background",
						overflowX: "hidden",

						"& .TransitionGroup":
						{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							width: "100%"
						},
						"& .mission-main":
						{
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "25px"
						},
						"& .main__content":
						{
							maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}
					}}
				>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 7 || step === 6 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						className="mission-header"
						sx={{
							display: step === 7 || step === 6 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "15px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px",
							position: "relative",
							zIndex: "10"
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={STEPS.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {STEPS.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: { xl: "40px", lg: "16px", xs: "16px" } }}>
							<Box sx={{
								display: "flex",
								alignItems: "center",
								gap: { xl: "24px", lg: "16px", xs: "16px" },

								"& .ImageButton-root":
								{
									"--size": { xl: "80px", lg: "56px", xs: "56px" }
								}
							}}>
								{step === 3 &&
									(
										<>
											<Zoom in={step === 3} style={{ transitionDelay: "800ms" }} timeout={1000}>
												<ImageButton onClick={() => setShowMissionInfo(2)} image={IMGMission2} value="Values" />
											</Zoom>
											<Zoom in={step === 3} style={{ transitionDelay: "700ms" }} timeout={1000}>
												<ImageButton onClick={() => setShowMissionInfo(3)} image={IMGMission3} value="Yearnings" />
											</Zoom>
											<Zoom in={step === 3} style={{ transitionDelay: "600ms" }} timeout={1000}>
												<ImageButton onClick={() => setShowMissionInfo(4)} image={IMGMission4} value="A Job to Love" />
											</Zoom>
											<Zoom in={step === 3} style={{ transitionDelay: "500ms" }} timeout={1000}>
												<ImageButton onClick={() => setShowMissionInfo(5)} image={IMGMission5} value="Perfect Day" />
											</Zoom>
										</>
									)
								}

								{tipsData !== null &&
									(
										<TooltipStyled title="Tips" placement="bottom" arrow>
											<TipsButton
												isActive={isBlipTips}
												blip={false}
												onClick={() =>
												{
													setIsShowTips(true);
													setIsBlipTips(false);
												}}
											/>
										</TooltipStyled>
									)
								}
							</Box>

							<MissionProgressBar value={progress} />
						</Box>
					</Box>

					{/* <TransitionGroup className="TransitionGroup"> */}
					{STEPS.map((Component, index) =>
					{
						if (step !== index + 1)
						{
							return false;
						}

						return (<Component key={index} />);
						// return (
						// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
						// 		<Component />
						// 	</Grow>
						// );
					})}

					{step > STEPS.length && <Grow timeout={{ enter: 1000, exit: 0 }} unmountOnExit><h1>Step {step}</h1></Grow>}
					{/* </TransitionGroup> */}

					{/* Tips content */}
					{tipsData !== null &&
						(
							<MissionsDialog
								title={tipsData.title}
								open={isShowTips}
								directionSlide="down"
								onClose={() => setIsShowTips(false)}
								position={tipsData.position ?? "center"}
								sx={{ "& .MuiPaper-root": { maxWidth: "980px" } }}
							>
								{tipsData.content}
							</MissionsDialog>
						)
					}

					{/* Image Side Modal Content */}
					<ModalMission2Data isOpen={showMissionInfo === 2} />
					<ModalMission3Data isOpen={showMissionInfo === 3} />
					<ModalMission4Data isOpen={showMissionInfo === 4} />
					<ModalMission5Data isOpen={showMissionInfo === 5} />
				</Box>
			</MissionCtx.Provider>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default MissionSixContainer;
