import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TrackMiddleware from "TrackMiddleware";
import TrackingWrapper from "TrackingWrapper";
import UnauthorizedRoute from "UnauthorizedRoute";
import { useEffect, useLayoutEffect, useMemo, useReducer, useRef } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Api, { getAccessToken } from "./api";
import { UserCtx } from "./contexts/UserCtx";
import { userInitialState } from "./reducers/userInitState";
import { UserActionType, userReducer } from "./reducers/userReducer";
import { privateRoutes, publicRoutes, unauthorizedRoutes } from "./routes";
import theme from "./theme";

function App()
{
	const isLoadRef = useRef([ false, false ]);
	const [ userState, userDispatch ] = useReducer(userReducer, userInitialState);
	const accessToken = getAccessToken();

	const context = useMemo(() =>
	{
		return { state: userState, dispatch: userDispatch };
	}, [ userState ]);

	// Utils
	async function updateUserData(accessToken: string)
	{
		isLoadRef.current[ 0 ] = true;

		try
		{
			const user = await Api.users.getMe();

			userDispatch({
				type: UserActionType.UPDATE_STATE,
				payload: { accessToken, user, isAuthenticated: true }
			});
		}
		catch (error)
		{
			console.error(error);

			userDispatch({ type: UserActionType.LOGOUT });
		}

		isLoadRef.current[ 0 ] = false;
	}

	async function updateContent()
	{
		isLoadRef.current[ 1 ] = true;

		const data = await Api.admin.getContent();

		userDispatch({
			type: UserActionType.SET_CONTENT,
			payload: { content: data[ 0 ] }
		});

		isLoadRef.current[ 1 ] = false;
	}

	// Effects
	useEffect(() =>
	{
		if (window.location.host !== process.env.REACT_APP_PROD_HOST)
		{
			return;
		}

		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
	}, []);

	useLayoutEffect(() =>
	{
		if (accessToken === null)
		{
			return;
		}

		if (isLoadRef.current.some((status) => status === true))
		{
			return;
		}

		updateUserData(accessToken);
		updateContent();
	}, [ accessToken ]);

	useLayoutEffect(() =>
	{
		if (isLoadRef.current[ 0 ] === true || accessToken === null)
		{
			return;
		}

		updateUserData(accessToken);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ userState.user?.completedMission ]);

	// Render
	return (
		<div className="App">
			<TrackingWrapper>
				<UserCtx.Provider value={context}>
					<GoogleOAuthProvider clientId="667333075170-uajmof1k092tn58oucfaiubmoihbkk5q.apps.googleusercontent.com">
						<BrowserRouter>
							<ThemeProvider theme={theme}>
								<Routes>
									{publicRoutes.map((route) => (
										<Route
											key={route.path}
											path={route.path}
											element={<TrackMiddleware>{route.element}</TrackMiddleware>}
										/>
									))}
									{unauthorizedRoutes.map((route) => (
										<Route
											key={route.path}
											path={route.path}
											element={
												<UnauthorizedRoute>
													<TrackMiddleware>
														{route.element}
													</TrackMiddleware>
												</UnauthorizedRoute>
											}
										/>
									))}
									{privateRoutes.map((route) => (
										<Route
											key={route.path}
											path={route.path}
											element={
												<ProtectedRoute redirectPath={route.redirectPath}>
													<TrackMiddleware>
														{route.element}
													</TrackMiddleware>
												</ProtectedRoute>
											}
										/>
									))}
								</Routes>
							</ThemeProvider>
						</BrowserRouter>
					</GoogleOAuthProvider>
				</UserCtx.Provider>
			</TrackingWrapper>
		</div>
	);
}

export default App;
