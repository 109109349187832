import { Box, Fade } from "@mui/material";
import afternoonBg from "assets/images/afternoonBg.png";
import eveningBg from "assets/images/eveningBg.png";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import morningBg from "assets/images/morningBg.png";
import nightBg from "assets/images/nightBg.png";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import CardContent from "./CardContent";

function Details({ items }: any)
{
	const navigate = useNavigate();
	const isFetchingRef = useRef<boolean>(false);
	const { data: missionId } = useParams();

	const [ currentItemIndex, setCurrentItemIndex ] = useState<number>(() =>
	{
		return getMissionIndexById(missionId as string);
	});

	function getMissionIndexById(id: string): number
	{
		return items.findIndex((item: any) => item.id === id);
	}

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}
	// Utils

	// Effects
	useLayoutEffect(() =>
	{
		if (missionId === undefined)
		{
			navigate("/dashboard/artifacts/perfect-day");
		}
	}, [ missionId, navigate ]);
	useLayoutEffect(() =>
	{
		if (items.length === 0 || currentItemIndex === -1)
		{
			return;
		}

		navigate("/dashboard/artifacts/perfect-day/details/" + items[ currentItemIndex ].id, { replace: true });

		if (currentItemIndex === -1 || isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;
	}, [ items, currentItemIndex, navigate ]);

	// Render
	return (
		<ArtifactsContainer
			title={formatDate(items[ currentItemIndex ]?.created_at || "Perfect day details not found")}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts/perfect-day")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			{currentItemIndex > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setCurrentItemIndex((prevState) => prevState - 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: { xs: "54%", xl: "50%" },
								transform: "translateY(-50%)",
								left: { xl: "20px", lg: "8px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{currentItemIndex < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setCurrentItemIndex((prevState) => prevState + 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: { xs: "54%", xl: "50%" },
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "10px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}
			{items.length > 0 && items.map((item: any, index: number) => (
				index === currentItemIndex &&
					<Box key={index} sx={{ display: "flex", gap: { lg: "21px", xl: 4 }, flexWrap: "wrap", justifyContent: "center" }}>
						<CardContent
							title="morning"
							content={item.mission_data.morning}
							bgImage={morningBg}
							editMode
							idItem={item.id}
							sx={{
								width: { lg: "520px", xl: "752px" },
								height: { lg: "230px", xl: "333px" },
								"& .contentStyle": {
									font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
								}
							}}
						/>
						<CardContent
							idItem={item.id}
							title="afternoon"
							content={item.mission_data.afternoon}
							bgImage={afternoonBg}
							editMode
							sx={{
								width: { lg: "520px", xl: "752px" },
								height: { lg: "230px", xl: "333px" },
								"& .contentStyle": {
									font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
								}
							}}
						/>
						<CardContent
							idItem={item.id}
							title="evening"
							content={item.mission_data.evening}
							bgImage={eveningBg}
							editMode
							sx={{
								width: { lg: "520px", xl: "752px" },
								height: { lg: "230px", xl: "333px" },
								"& .contentStyle": {
									font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
								}
							}}
						/>
						<CardContent
							idItem={item.id}
							title="night"
							content={item.mission_data.night}
							bgImage={nightBg}
							editMode
							sx={{
								color: "primary.light",
								width: { lg: "520px", xl: "752px" },
								height: { lg: "230px", xl: "333px" },
								"& .contentStyle": {
									font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
								}
							}}
						/>
					</Box>
			))}
		</ArtifactsContainer>
	);
}

export default Details;
