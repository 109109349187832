import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import bgImgInner from "assets/images/missions/mission-ten/bgImgInner.png";
import ContentAdmin from "components/contentAdmin";
import { useState } from "react";

export interface IMission2Props
{
	isOpen?: boolean;
	closeModal?(): void;
	imgUrl?: string;
	title?: string;
}

function ModalMissionFour({ isOpen = false, closeModal, imgUrl, title }: IMission2Props)
{

	const [ data, setData ] = useState<string[]>([]);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);
	const [ showBoard, setShowBoard ] = useState<1 | null>(null);
	const columns: number = (showBoard === null ? 3 : 6);

	// Handles
	// useEffect(() =>
	// {
	// 	Api.missions
	// 		.getDataMissionSix()
	// 		.then(({ data }) =>
	// 		{
	// 			setIsErrorLoading(false);
	// 			setData(data.mission_data);
	// 		})
	// 		.catch((error) =>
	// 		{
	// 			setIsErrorLoading(true);
	// 			console.error(error);
	// 		});
	// }, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			maxHeight: "392px",
			position: "relative",
			backgroundImage: `url(${ bgImgInner })`,
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
			backgroundRepeat: "repeat-x",
			borderRadius: "24px",
			borderTopLeftRadius: "75px",
			borderBottomLeftRadius: "75px"
		}}>
			<CancelIcon
				onClick={closeModal}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					fill: "#3E809D",
					"&:hover": { cursor: "pointer" }
				}}
			/>
			<Box
				component="img"
				src={imgUrl}
				alt="side image"
				className="ImageSideModal-image"
				sx={[
					showBoard === null &&
						{
							gridColumn: "2 span",
							width: "140px",
							height: "392px",
							borderRadius: "100px",
							filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))"
						},
					showBoard === 1 &&
						{ display: "none" }
				]}
			/>
			<Box
				className="ImageSideModal-content"
				sx={{ width: "100%", padding: "24px 24px 30px 40px" }}
			>
				<Typography sx={{ font: { lg: "700 24px/40px Lora", xl: "700 40px/51px Lora" }, color: "#215C75", mb: "12px" }}>
					{title}
				</Typography>
				<Box className="customScroll" sx={{ overflowY: "auto", height: "270px", paddingRight: "8px" }}>
					<Typography sx={{ font: { lg: '400 18px/27px "Open Sans"', xl: '400 24px/33px "Open Sans"' }, color: "#4D4D4D", mb: "12px" }}>
						<ContentAdmin
							limit={300}
							keyContent="contentMissionTen"
							keyText="modalMissionFour_txt"
							defaultValue="My advice to you, {NAME}, is to look at it regularly. Print it out, download it as a screensaver — the more often you look at it, the more likely your dreams are to come true.\nUse it to check in on your progress too. Are you living most of what you see? Has anything lost its appeal to you? Do you want to add new dreams?"
							position="right"
							isDescription
						/>
					</Typography>
					{/* <Typography sx={{ font: '400 24px/33px "Open Sans"', color: '#4D4D4D', mb: '12px' }}>
							Use it to check in on your progress too. Are you living most of what you see? Has anything lost its appeal to you? Do you want to add new dreams?
						</Typography> */}
					{/* {(data === null || data === undefined) && isErrorLoading === false &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Loading data <CircularProgress size={24} />
								</Box>
							)
						}
						{(data === null || data === undefined) && isErrorLoading === true &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Failed to retrieve data, please try again
								</Box>
							)
						}
						{data !== null && data !== undefined && isErrorLoading === false &&
							(<>
								<Box sx={{
									padding: '22px',
									// marginBottom: '30px',
									position: 'relative',
									borderRadius: '24px',
									background: 'linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)',
								}}
								>
									<Masonry spacing={2} columns={3}>
										{data.map((image, index) =>
										(
											<Box
												sx={{ borderRadius: '24px' }}
												key={index + image}
												component="img"
												src={image}
												alt=""
											/>
										))}
									</Masonry>
								</Box>
								<Box
									component={(showBoard === null ? SVGExpandArrows : CancelIcon)}
									sx={{
										position: "fixed",
										width: '30px !important',
										bottom: "12px",
										right: "40px",
										color: "rgb(33, 92, 117)",
										cursor: "pointer",
										opacity: 0.8,
										"&:hover": { opacity: 1 }
									}}
									onClick={() => { setShowBoard((prevState) => prevState === 1 ? null : 1); }}
								/>

							</>
							)
						} */}
				</Box>
			</Box>
		</Box>
	);
}

export default ModalMissionFour;
