import { Dispatch, SetStateAction } from "react";

function setFieldValue<T>(setState: Dispatch<SetStateAction<T>>, fieldName: keyof T, value: string)
{
	setState((prevState) => (
		{
			...prevState,
			[ fieldName ]:
			{
				...prevState[ fieldName ],
				value: value
			}
		}
	));
}

export default setFieldValue;
