import { Alert, styled } from "@mui/material";

export default styled(Alert)(() =>
{
	return ([
		{
			padding: "24px",
			gap: "8px",
			backgroundColor: "#FFFFFF",
			borderRadius: "16px",

			"&.MuiAlert":
			{
				// INFO: -variationSeverity
				"&-standardSuccess":
				{
					color: "#1FAD77"
				},
				"&-standardWarning":
				{
					color: "#BF5858"
				},
				"&-standardError":
				{
					color: "#FF0000"
				}
				// TODO: Added new severity?
			},
			"& .MuiAlert-icon":
			{
				color: "inherit",
				margin: "unset",
				padding: "unset"
			},
			"& .MuiAlert-message":
			{
				padding: "unset",
				font: "400 14px/19px 'Open Sans'"
			},
			"& .MuiAlert-action":
			{
				margin: "unset",
				padding: "unset",

				"& button":
				{
					color: "primary.dark",
					padding: "unset"
				}
			}
		}
	]);
}) as typeof Alert;
