import { Box, Divider, SxProps, Theme } from "@mui/material";
import IMGBgGradient from "assets/images/bg-gradient.png";
import { ReactComponent as SVGIconSignIn } from "assets/images/dashboards/account/signIn.svg";
import ArrowButton from "components/ArrowButton";
import BouncingDotsLoader from "components/BouncingDotsLoader";
import CharButton from "components/CharButton";
import CheckboxStyled from "components/CheckboxStyled";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import ChipStyled from "components/ChipStyled";
import CustomInput from "components/CustomInput";
import InstructionButton from "components/InstructionButton";
import LightBulb from "components/LightBulb";
import MissionProgressBar from "components/MissionProgressBar";
import RadialButton from "components/RadialButton";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import SolarSystemButton from "components/missions/SolarSystemButton";
import { useState } from "react";
import { Link } from "react-router-dom";
import "reset.css";

function Section({ title, children, sx }: { title: string; children: JSX.Element | JSX.Element[]; sx?: SxProps<Theme>; })
{
	return (
		<Box
			component="section"
			sx={[
				{ marginTop: "30px", minWidth: "300px" },
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Divider sx={{ color: "#215C75", fontWeight: "bold", marginBottom: "20px", "&::before, &::after": { borderColor: "#215C75" } }}>{title}</Divider>
			<Box className="section-items" sx={{ display: "flex", gap: "20px 15px", flexWrap: "wrap", alignItems: "flex-start" }}>{children}</Box>
		</Box>
	);
}

function ComponentTesting()
{
	const [ isLoading, setIsLoading ] = useState<boolean>(false);

	function onClickLoading()
	{
		setIsLoading(true);

		setTimeout(() => setIsLoading(false), 1000);
	}

	return (
		<Box sx={{ backgroundImage: `url(${ IMGBgGradient })`, backgroundAttachment: "fixed", padding: "40px 100px 200px", minHeight: "100vh" }}>
			<Section title="<CustomButton.Base>">
				<CustomButton.Base>Button</CustomButton.Base>
				<CustomButton.Base disabled>Disabled</CustomButton.Base>
				<CustomButton.Base component={Link} to="/dashboard">Link Button</CustomButton.Base>
				<CustomButton.Base startIcon={<SVGIconSignIn />}>Start Icon</CustomButton.Base>
				<CustomButton.Base endIcon={<SVGIconSignIn />}>End Icon</CustomButton.Base>
				<CustomButton.Base fullWidth>Full Width</CustomButton.Base>
			</Section>

			<Section title={`<CustomButton.Base loading={${ isLoading }}>`}>
				<CustomButton.Base loading={isLoading} onClick={onClickLoading}>Loading Position End</CustomButton.Base>
				<CustomButton.Base loading={isLoading} loadingPosition="start" onClick={onClickLoading}>Loading Position Start</CustomButton.Base>
				<CustomButton.Base loading={isLoading} onClick={onClickLoading}>Loading</CustomButton.Base>
				<CustomButton.Base loading={isLoading} onClick={onClickLoading} startIcon={<SVGIconSignIn />}>Start Icon</CustomButton.Base>
				<CustomButton.Base loading={isLoading} onClick={onClickLoading} endIcon={<SVGIconSignIn />}>End Icon</CustomButton.Base>
			</Section>

			<Section title="<CustomButton.Outline>">
				<CustomButton.Outline>Button</CustomButton.Outline>
				<CustomButton.Outline disabled>Disabled</CustomButton.Outline>
				<CustomButton.Outline component={Link} to="/dashboard">Link Button</CustomButton.Outline>
				<CustomButton.Outline startIcon={<SVGIconSignIn />}>Start Icon</CustomButton.Outline>
				<CustomButton.Outline endIcon={<SVGIconSignIn />}>End Icon</CustomButton.Outline>
				<CustomButton.Outline fullWidth>Full Width</CustomButton.Outline>
			</Section>

			<Section title={`<CustomButton.Outline loading={${ isLoading }}>`}>
				<CustomButton.Outline loading={isLoading} onClick={onClickLoading}>Loading Position End</CustomButton.Outline>
				<CustomButton.Outline loading={isLoading} loadingPosition="start" onClick={onClickLoading}>Loading Position Start</CustomButton.Outline>
				<CustomButton.Outline loading={isLoading} onClick={onClickLoading}>Loading</CustomButton.Outline>
				<CustomButton.Outline loading={isLoading} onClick={onClickLoading} startIcon={<SVGIconSignIn />}>
					Start Icon
				</CustomButton.Outline>
				<CustomButton.Outline loading={isLoading} onClick={onClickLoading} endIcon={<SVGIconSignIn />}>End Icon</CustomButton.Outline>
			</Section>

			<Section title="<CustomButton.Contained>">
				<CustomButton.Contained>Button</CustomButton.Contained>
				<CustomButton.Contained disabled>Disabled</CustomButton.Contained>
				<CustomButton.Contained component={Link} to="/dashboard">Link Button</CustomButton.Contained>
				<CustomButton.Contained startIcon={<SVGIconSignIn />}>Start Icon</CustomButton.Contained>
				<CustomButton.Contained endIcon={<SVGIconSignIn />}>End Icon</CustomButton.Contained>
				<CustomButton.Contained fullWidth>Full Width</CustomButton.Contained>
			</Section>

			<Section title={`<CustomButton.Contained loading={${ isLoading }}>`}>
				<CustomButton.Contained loading={isLoading} onClick={onClickLoading}>Loading Position End</CustomButton.Contained>
				<CustomButton.Contained loading={isLoading} loadingPosition="start" onClick={onClickLoading}>Loading Position Start</CustomButton.Contained>
				<CustomButton.Contained loading={isLoading} onClick={onClickLoading}>Loading</CustomButton.Contained>
				<CustomButton.Contained loading={isLoading} onClick={onClickLoading} startIcon={<SVGIconSignIn />}>
					Start Icon
				</CustomButton.Contained>
				<CustomButton.Contained loading={isLoading} onClick={onClickLoading} endIcon={<SVGIconSignIn />}>
					End Icon
				</CustomButton.Contained>
			</Section>

			<Section title="<CheckboxStyled>">
				<CheckboxStyled />
				<CheckboxStyled checked />
			</Section>

			<Section title="<CheckboxStyledWithLabel>">
				<CheckboxStyledWithLabel label="contained" />
				<CheckboxStyledWithLabel label="contained" checkboxProps={{ checked: true }} />
				<CheckboxStyledWithLabel variant="outlined" label="outlined" />
				<CheckboxStyledWithLabel variant="outlined" label="outlined" checkboxProps={{ checked: true }} />
			</Section>

			<Section title="<CustomInput.Base>">
				<CustomInput.Base />
				<CustomInput.Base placeholder="placeholder" />
				<CustomInput.Base value="test" />
				<CustomInput.Base value="disabled" disabled />
				<CustomInput.Base placeholder="multiline" multiline rows={2} />
			</Section>

			<Section title="<CustomInput.Password>">
				<CustomInput.Password />
				<CustomInput.Password placeholder="placeholder" />
				<CustomInput.Password value="test" />
				<CustomInput.Password value="disabled" disabled />
			</Section>

			<Section title="<ArrowButton>">
				<ArrowButton direction="left" />
				<ArrowButton />
				<ArrowButton direction="left" disabled />
				<ArrowButton disabled />
			</Section>

			<Section title="<SolarSystemButton>">
				<SolarSystemButton />
				<SolarSystemButton disabled />
				<SolarSystemButton blip />
				<SolarSystemButton blip disabled />
			</Section>

			<Section title="<TipsButton>">
				<TipsButton />
				<TipsButton disabled />
			</Section>

			<Section title="<InstructionButton>">
				<InstructionButton />
				<InstructionButton disabled />
			</Section>

			<Section title="<RadialButton>" sx={{ "& .section-items": { gap: "50px" } }}>
				<RadialButton value="right" />
				<RadialButton value="right" disabled />
				<RadialButton value="Peak Moments" showValue={false} />
				<RadialButton value="Peak Moments" showValue={false} disabled />
			</Section>

			<Section title={'<RadialButton direction="left">'}>
				<RadialButton value="left" direction="left" />
				<RadialButton value="left" direction="left" disabled />
			</Section>

			<Section title="<RadialButton blip>" sx={{ "& .section-items": { gap: "50px" } }}>
				<RadialButton value="left" direction="left" blip />
				<RadialButton value="right" blip />
				<RadialButton value="left" direction="left" blip disabled />
				<RadialButton value="right" blip disabled />
				<RadialButton value="Peak Moments" showValue={false} blip />
				<RadialButton value="Peak Moments" showValue={false} disabled blip />
			</Section>

			<Section title="<RadialButton absoluteValuePosition>" sx={{ "& .section-items": { gap: "50px" } }}>
				<RadialButton value="left" direction="left" absoluteValuePosition />
				<RadialButton value="right" direction="right" absoluteValuePosition />
			</Section>

			<Section title="<MissionProgressBar>">
				<MissionProgressBar />
				<MissionProgressBar value={10} />
				<MissionProgressBar value={33} />
				<MissionProgressBar value={66} />
				<MissionProgressBar value={100} />
			</Section>

			<Section title="<LightBulb>">
				<LightBulb color="green" />
				<LightBulb color="green" blip />
				<LightBulb color="red" />
				<LightBulb color="red" blip />
			</Section>

			<Section title="<BouncingDotsLoader>">
				<BouncingDotsLoader />
			</Section>

			<Section title="<CharButton>">
				<CharButton char="A" value="default" />
				<CharButton char="B" value="selected" selected />
				<CharButton char="C" value="active" active />
			</Section>

			<Section title="<ChipStyled>">
				<ChipStyled label="default" />
				<ChipStyled label="default clickable" clickable />
				<ChipStyled label="default onDelete" onDelete={() => { return; }} />
				<ChipStyled label="default disabled" disabled />
				<ChipStyled variant="filled" label="filled" />
				<ChipStyled variant="filled" label="filled clickable" clickable />
				<ChipStyled variant="filled" label="filled onDelete" onDelete={() => { return; }} />
				<ChipStyled variant="filled" label="filled disabled" disabled />
			</Section>

			<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: "50px" }}>
				<Section
					title="<TooltipStyled>"
					sx={{
						maxWidth: "500px",
						"& .section-items":
						{
							display: "grid",
							gridTemplateColumns: "repeat(5, 1fr)",
							justifyContent: "center",
							textAlign: "center"
						}
					}}
				>
					<div />
					<TooltipStyled title="Test long text" placement="top-start">
						<div>top-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top">
						<div>top</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top-end">
						<div>top-end</div>
					</TooltipStyled>
					<div />
					<TooltipStyled title="Test long text" placement="left-start">
						<div>left-start</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-start">
						<div>right-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="left">
						<div>left</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right">
						<div>right</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="left-end">
						<div>left-end</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-end">
						<div>right-end</div>
					</TooltipStyled>
					<div />
					<TooltipStyled title="Test long text" placement="bottom-start">
						<div>bottom-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom">
						<div>bottom</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom-end">
						<div>bottom-end</div>
					</TooltipStyled>
					<div />
				</Section>

				<Section
					title="<TooltipStyled arrow>"
					sx={{
						maxWidth: "500px",
						"& .section-items":
						{
							display: "grid",
							gridTemplateColumns: "repeat(5, 1fr)",
							justifyContent: "center",
							textAlign: "center"
						}
					}}
				>
					<div />
					<TooltipStyled title="Test long text" placement="top-start" arrow>
						<div>top-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top" arrow>
						<div>top</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top-end" arrow>
						<div>top-end</div>
					</TooltipStyled>
					<div />

					<TooltipStyled title="Test long text" placement="left-start" arrow>
						<div>left-start</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-start" arrow>
						<div>right-start</div>
					</TooltipStyled>

					<TooltipStyled title="Test long text" placement="left" arrow>
						<div>left</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right" arrow>
						<div>right</div>
					</TooltipStyled>

					<TooltipStyled title="Test long text" placement="left-end" arrow>
						<div>left-end</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-end" arrow>
						<div>right-end</div>
					</TooltipStyled>

					<div />
					<TooltipStyled title="Test long text" placement="bottom-start" arrow>
						<div>bottom-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom" arrow>
						<div>bottom</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom-end" arrow>
						<div>bottom-end</div>
					</TooltipStyled>
					<div />
				</Section>

				<Section
					title="<TooltipStyled followCursor>"
					sx={{
						maxWidth: "500px",
						"& .section-items":
						{
							display: "grid",
							gridTemplateColumns: "repeat(5, 1fr)",
							justifyContent: "center",
							textAlign: "center"
						}
					}}
				>
					<div />
					<TooltipStyled title="Test long text" placement="top-start" followCursor>
						<div>top-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top" followCursor>
						<div>top</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="top-end" followCursor>
						<div>top-end</div>
					</TooltipStyled>
					<div />
					<TooltipStyled title="Test long text" placement="left-start" followCursor>
						<div>left-start</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-start" followCursor>
						<div>right-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="left" followCursor>
						<div>left</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right" followCursor>
						<div>right</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="left-end" followCursor>
						<div>left-end</div>
					</TooltipStyled>
					<div /><div /><div />
					<TooltipStyled title="Test long text" placement="right-end" followCursor>
						<div>right-end</div>
					</TooltipStyled>
					<div />
					<TooltipStyled title="Test long text" placement="bottom-start" followCursor>
						<div>bottom-start</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom" followCursor>
						<div>bottom</div>
					</TooltipStyled>
					<TooltipStyled title="Test long text" placement="bottom-end" followCursor>
						<div>bottom-end</div>
					</TooltipStyled>
					<div />
				</Section>
			</Box>
		</Box>
	);
}

export default ComponentTesting;
