import { Box } from "@mui/material";
import { ReactComponent as SVGEagle } from "../../../../../assets/images/missions/mission-ten/eagle.svg";
import { ReactComponent as SVGEagle2 } from "../../../../../assets/images/missions/mission-ten/eagle2.svg";
import { ReactComponent as SVGEagle3 } from "../../../../../assets/images/missions/mission-ten/eagle3.svg";

interface EagleBtnsProps
{
	selectedTitle: number;
	handleSelectedTitle(val: number): void;
}

function EagleBtns({ selectedTitle, handleSelectedTitle }: EagleBtnsProps)
{
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "center",
			gap: "100px",
			width: "100%",
			m: "40px 0 0 40px",
			border: "1px solid transparent",
			transform: { lg: "scale(0.7)", xl: "scale(1)" }
		}}>
			<Box
				onClick={() => handleSelectedTitle(0)}
				sx={{
					transform: "rotate(0.75deg)",
					cursor: "pointer",
					width: "226px",
					position: "relative",
					top: "-44px",
					left: "-76px"
				}}>
				{selectedTitle !== 0 && <SVGEagle />}
			</Box>
			<Box
				onClick={() => handleSelectedTitle(1)}
				sx={{
					ml: 2,
					cursor: "pointer",
					width: "158px",
					position: "relative",
					top: "-16px",
					left: "12px"
				}}>
				{selectedTitle !== 1 && <SVGEagle2 />}
			</Box>
			<Box
				onClick={() => handleSelectedTitle(2)}
				sx={{
					cursor: "pointer",
					width: "202px",
					position: "relative",
					top: "-75px",
					left: "12px"
				}}>
				{selectedTitle !== 2 && <SVGEagle3 />}
			</Box>
		</Box>
	);
}

export default EagleBtns;
