import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Box } from "@mui/material";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import IMGBackground from "pages/missionTwo/assets/images/background-blur.jpg";
import { useNavigate } from "react-router-dom";

const LIST = [
	"Maximize your potential",
	"Define your purpose",
	"Harmonize all aspects of your life",
	"Find clarity on your next steps",
	"Design a life in sync with your true self"
];

function QuestPower()
{
	const navigate = useNavigate();

	return (
		<Box sx={{
			padding: "52px",
			height: "100vh",

			background: `url(${ IMGBackground }) center center/cover`,

			"@supports (min-height: 100dvh)":
			{
				minHeight: "100dvh"
			}
		}}>
			<TooltipStyled title="Back to dashboard" placement="right" arrow>
				<Box
					component="button"
					onClick={() => navigate("/dashboard")}
					sx={{
						position: "fixed",
						top: { xl: "24px", xs: "16px" },
						left: { xl: "32px", xs: "24px" },
						cursor: "pointer",
						display: "flex",
						padding: "unset",
						border: "unset",
						background: "unset",

						"& path":
						{
							transition: "linear 300ms fill-opacity"
						},
						"&:hover path":
						{
							fillOpacity: 1
						}
					}}
				>
					<SVGArrowBack />
				</Box>
			</TooltipStyled>

			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "#FFFFFF",
				textAlign: "center"
			}}>
				Unlock the power of the quest!
			</Box>

			<Box sx={{
				marginTop: "14px",
				font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: "#FFFFFF",
				textAlign: "center"
			}}>
				Understand what’s important to you to stay on track in life and focus on things that truly matter.
			</Box>

			<Box sx={{
				padding: "0 56px",
				marginTop: { lg2: "104px", xs: "64px" },
				width: "100%",

				display: "flex",
				gap: "60px"
			}}>
				<Box
					sx={{
						height: { xl: "606px", lg2: "507px", xs: "412px" },
						width: { xl: "1077px", lg2: "900px", xs: "731px" },
						overflow: "hidden",
						borderRadius: { xl: "24px", xs: "16px" }
					}}
				>
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/FcHyyxZJobQ?si=5Co9EcrYt85wpzfy"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</Box>

				<Box sx={{
					width: { xl: "536px", xs: "371px" },
					display: "flex",
					flexDirection: "column"
				}}>
					<Box sx={{
						font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						color: "#FFFFFF",
						textAlign: "center"
					}}>
						Transform your life
					</Box>

					<Box
						component="ul"
						sx={{
							margin: "0 auto",
							marginTop: { lg2: "77px", xs: "52px" },
							marginLeft: { xl: "24px" },
							padding: "unset",
							listStyle: "none",

							display: "flex",
							flexDirection: "column",
							gap: { xl: "32px", xs: "24px" },

							font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
							color: "#FFFFFF",

							"& li":
							{
								display: "flex",
								gap: "8px",
								alignItems: "center"
							},
							"& svg":
							{
								fontSize: { xl: "23px", lg: "18px" }
							}
						}}
					>
						{LIST.map((listItem) =>
						{
							return (
								<li key={listItem}>
									<DoneRoundedIcon />
									{listItem}
								</li>
							);
						})}
					</Box>

					<CustomButton.Base
						onClick={() => navigate("/packages")}
						sx={{
							margin: "0 auto",
							marginTop: "auto",
							alignSelf: "flex-end",
							display: "flex",
							boxShadow: "unset"
						}}
					>
						View Packages
					</CustomButton.Base>
				</Box>
			</Box>
		</Box>
	);
}

export default QuestPower;
