import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ArrowButton from "../../../components/ArrowButton";
import RadialButton from "../../../components/RadialButton";
import ContentAdmin from "../../../components/contentAdmin";
import MissionsDialog from "../../../components/missions/Dialog";
import PanelTitle from "../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../components/missions/StepperNavigationWrapper";
import useMission from "../hooks";

function GuidingLightMorning()
{
	// States
	const missionContext = useMission();
	const [ isOpenButton, setIsOpenButton ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ openOneDialog, setOpenOneDialog ] = useState(false);
	const [ openTwoDialog, setOpenTwoDialog ] = useState(false);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean ]>([ true, true ]);
	const [ open, setOpen ] = useState(
		{
			one: false,
			two: false
		}
	);

	function handleOpenOneDialog()
	{
		setOpen({
			...open,
			one: true
		});
		setOpenOneDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 0: false })
		));
	}

	function handleOpenTwoDialog()
	{
		setOpen({
			...open,
			two: true
		});
		setOpenTwoDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 1: false })
		));
	}
	// Handles

	function onClickNextStep()
	{

		missionContext.setStep((prevStep) => prevStep + 1);
	}

	return (
		<>
			<Box sx={{
				height: "76.5vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "1000px" },
				m: "auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				marginTop: "0px",
				position: "relative",
				top: "17px",
				left: "-20px"
			}}>
				<PanelTitle
					imageVariation="closeEye"
					positionOcta
					title={<ContentAdmin
						limit={100}
						keyContent="contentMissionNine"
						keyText="guiding_light_morning_title"
						defaultValue="Let the North Star be your guiding light"
						position="top" />}
				/>
				<Box sx={{
					display: "flex",
					justifyContent: "center",
					position: "relative",
					height: "100%"
				}}>
					{(openOneDialog || openTwoDialog)
						? <Box />
						: (
							<Box>
								<Box sx={{ position: "absolute", top: "76px", right: isOpenButton[ 0 ] === false ? "44px" : "4px" }}>
									<RadialButton
										value="How can you use your North Star?"
										color="blue"
										blip={disabledButtons[ 0 ] === true}
										offBlipOnClick
										direction="left"
										onClick={handleOpenOneDialog}
										showValue={isOpenButton[ 0 ]}
										onMouseEnter={() => setIsOpenButton([ true, false ])}
										onMouseLeave={() => setIsOpenButton([ false, false ])}

									/>
								</Box>
								<Box sx={{ position: "absolute", top: "329px", left: isOpenButton[ 1 ] === false ? "145px" : "105px" }}>
									<RadialButton
										value="Can North Star change?"
										color="blue"
										onClick={handleOpenTwoDialog}
										blip={disabledButtons[ 1 ] === true}
										offBlipOnClick
										showValue={isOpenButton[ 1 ]}
										onMouseEnter={() => setIsOpenButton([ false, true ])}
										onMouseLeave={() => setIsOpenButton([ false, false ])}
									/>
								</Box>
							</Box>
						)
					}
				</Box>
			</Box>
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={!(open.one && open.two)}
					onClick={onClickNextStep} />
			</StepperNavigationWrapper>
			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						backgroundColor: "transparent",
						maxWidth: "804px",
						maxHeight: "429px",
						padding: "56px 60px 56px 56px",
						top: "67px"
					}
				}}
				title={<ContentAdmin
					limit={111}
					keyContent="contentMissionNine"
					keyText="how_can_use_NorthStar_title_1"
					defaultValue="How can you use your North Star?"
					position="left"
				/>}
				open={openOneDialog}
				onClose={() => setOpenOneDialog(false)}
			>
				<Typography sx={{ color: "#4D4D4D", font: '400 22px/30px "Open Sans"' }}>
					<ContentAdmin
						limit={450}
						keyContent="contentMissionNine"
						keyText="how_can_use_NorthStar_body_1"
						defaultValue="Achieving your North Star is not the goal. It is there to help you make informed decisions to ensure you live your life in sync with who you really are. \n\n Ask yourself this question every day: does this action, experiment, project gets me closer to realising my North Star? If the answer is no, then don’t waste time, energy, and money on it. A North Star is a compass that ensures you work on those things that really matter to you."
						isDescription
						position="left"
					/>
				</Typography>
			</MissionsDialog>
			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						backgroundColor: "transparent",
						maxWidth: "804px",
						maxHeight: "280px",
						padding: "56px 60px 56px 56px",
						top: "80px"
					}
				}}
				title={<ContentAdmin
					limit={111}
					keyContent="contentMissionNine"
					keyText="canNorthStar_change_title_2"
					defaultValue="Can North Star change?"
					position="left"
				/>}
				open={openTwoDialog}
				onClose={() => setOpenTwoDialog(false)}
			>
				<Typography sx={{ color: "#4D4D4D", font: '400 22px/30px "Open Sans"' }}>
					<ContentAdmin
						limit={450}
						keyContent="contentMissionNine"
						keyText="canNorthStar_change_body_2"
						defaultValue="Similarly to values, your North Star may evolve as you do. You may also reach your goal, which means it’s time to set a new North Star. This could mean an extension of your current one or a completely new one."
						isDescription
						position="left" />
				</Typography>
			</MissionsDialog>
		</>
	);
}

export default GuidingLightMorning;
