import { Box, CircularProgress, Typography } from "@mui/material";
import CustomButton from "components/customButton";
import { useEffect, useState } from "react";
import IMGButtonImage from "../../../dashboard/artifacts/experiments/assets/images/button-image.png";
import { ValuesFlies } from "../ExperimentsTracker";

interface ProgressStepProps
{
	valuesFlies: ValuesFlies[];
	handleNextStep(title: string): void;
	setStep: any;
}
function ProgressStep({ valuesFlies, handleNextStep, setStep }: ProgressStepProps)
{
	const [ activeItems, setActiveItems ] = useState(0);
	const [ filteredState, serFilteredState ] = useState<any>([]);

	function handleActiveItems(number: number)
	{
		setActiveItems(number);
	}

	useEffect(() =>
	{
		let newState: any = [ [], [], [] ];

		valuesFlies.forEach(item =>
		{
			if (item.countChecked === 0)
			{
				newState[ 0 ].push(item);
			}
			else if (item.countChecked > 0 && item.run.length !== item.countChecked)
			{
				newState[ 1 ].push(item);
			}
			else if (item.run.length / item.countChecked * 100 === 100)
			{
				newState[ 2 ].push(item);
			}
		});
		serFilteredState(newState);
	}, [ valuesFlies ]);

	return (
		<Box sx={{
			width: { lg: "832px", xl: "980px" },
			height: { lg: "450px", xl: "654px" },
			background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
			boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
			border: "1px solid rgba(255, 255, 255, 0.5)",
			borderRadius: "24px",
			ml: 4,
			backdropFilter: "blur(30px)",
			p: { lg: "16px 24px", xl: "32px 27px" },
			mt: { lg: 1, xl: 0 }
		}}>
			<Box sx={{ display: "flex", justifyContent: "center", gap: "10px", color: "primary.main" }}>
				<Typography
					onClick={() => handleActiveItems(0)}
					sx={{
						font: { lg: "700 14px/20px Lora-Bold", xl: "700 18px/24px Lora-Bold" },
						color: activeItems === 0 ? "primary.main" : "rgba(62, 128, 157, 0.50)",
						textTransform: "uppercase",
						p: { lg: "8px 13px", xl: "14px 24px" },
						cursor: "pointer",
						background:
							"rgba(255, 255, 255, 0.5)",
						borderRadius: "40px",
						"&:hover": {
							boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.16)"
						}
					}}
				>
					to be tested
				</Typography>
				<Typography
					onClick={() => handleActiveItems(1)}
					sx={{
						font: { lg: "700 14px/20px Lora-Bold", xl: "700 18px/24px Lora-Bold" },
						color: activeItems === 1 ? "primary.main" : "rgba(62, 128, 157, 0.50)",
						textTransform: "uppercase",
						p: { lg: "8px 13px", xl: "14px 24px" },
						cursor: "pointer",
						background:
							"rgba(255, 255, 255, 0.5)",
						borderRadius: "40px",
						"&:hover": {
							boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.16)"
						}
					}}>
					in progress
				</Typography>
				<Typography
					onClick={() => handleActiveItems(2)}
					sx={{
						font: { lg: "700 14px/20px Lora-Bold", xl: "700 18px/24px Lora-Bold" },
						color: activeItems === 2 ? "primary.main" : "rgba(62, 128, 157, 0.50)",
						textTransform: "uppercase",
						p: { lg: "8px 13px", xl: "14px 24px" },
						cursor: "pointer",
						background:
							"rgba(255, 255, 255, 0.5)",
						borderRadius: "40px",
						"&:hover": {
							boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.16)"
						}
					}}>
					Completed
				</Typography>
			</Box>
			<Box className="customScroll" sx={{ display: "flex", gap: { lg: "20px 50px", xl: "13px" }, overflowY: "auto", flexWrap: "wrap", height: { lg: "320px", xl: "438px" }, ml: { lg: "20px", xl: 0 } }}>
				{filteredState.length > 0 && filteredState[ activeItems ].map((item: any, index: number) => (
					<Box
						onClick={() => handleNextStep(item.title)}
						sx={{ minWidth: { lg: "140px", xl: "214px" }, mt: "20px", cursor: "pointer" }}>
						<Box
							key={index}
							sx={{
								position: "relative",
								width: { lg: "130px", xl: "160px" },
								height: { lg: "130px", xl: "160px" },
								border: "10px solid #FFFFFF",
								borderRadius: "50%",
								margin: "0 auto"
							}}>
							<CircularProgress
								size={160}
								thickness={3}
								variant="determinate"
								value={item.countChecked === 0 ? 0 : item.run.length / item.countChecked * 100}
								sx={{
									position: "relative",
									top: "-10px",
									left: "-10px",
									color: "primary.main",
									display: { lg: "none", xl: "block" }
								}}
							/>
							<CircularProgress
								size={130}
								thickness={4}
								variant="determinate"
								value={item.countChecked === 0 ? 0 : item.run.length / item.countChecked * 100}
								sx={{
									position: "relative",
									top: "-10px",
									left: "-10px",
									color: "primary.main",
									display: { lg: "block", xl: "none" }
								}}
							/>
							<Box sx={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								background: `url(${ IMGButtonImage }) center center/cover`,
								boxShadow: "0px 0px 10px #FFE8B9",
								borderRadius: "50%"
							}} />
						</Box>
						<Box sx={{
							width: "100%",
							textAlign: "center",
							color: "primary.main",
							font: "700 24px/31px Lora-Bold",
							mt: 2,
							display: { lg: "none", xl: "block" }
						}}>
							{item.title}
						</Box>
					</Box>
				))}
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
				<CustomButton.Outline
					onClick={() => setStep(2)}
					sx={{
						fontSize: "24px",
						lineHeight: "33px",
						background: "#3E809D",
						border: "#3E809D",
						color: "#fff",
						width: "320px"

					}}
				>
					Add new
				</CustomButton.Outline>
			</Box>
		</Box>
	);
}

export default ProgressStep;
