import CloseIcon from "@mui/icons-material/Close";
import { Box, CardMedia, Dialog, Slide, Typography, alpha, styled } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import useUser from "hooks/useUser";
import { Ref, forwardRef, useEffect, useState } from "react";
import { default as CardFonImg, default as IMGMissionLarge4 } from "../../../assets/images/missions/mission-nine/card-fon-img.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import Rezult from "../feature/SetCourseRezult/feature/rezult";
import useMission from "../hooks/useMission";
import Results from "./actionPlan/Results";

const StyledDialog = styled(Dialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			backgroundColor: alpha("#C0D3DF", 0.7),
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "20px",
			maxWidth: "980px",
			height: "740px",
			width: "100%",
			boxShadow: "0px 5px 10px 0px rgba(167, 199, 225, 0.6)",
			backdropFilter: "blur(25px)",
			display: "flex",
			flexDirection: "row",
			marginTop: "180px",
			padding: "0px"
		},
		"& .MuiPaper-root .customScroll":
		{
			maxHeight: "620px"
		}
	}
);
const Transition = forwardRef((
	props: TransitionProps & { children: React.ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

const YourPlanFinish = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const userContext = useUser();
	const [ open, setOpen ] = useState([ false, false ]);
	const [ boards, setBoards ] = useState([]);

	function nextStep()
	{
		if (userContext.state.user?.completedMission === 8)
		{
			missionContext.setFirstPlaythrough(true);
		}

		setTimeout(() => missionContext.setStep(19), 0);
	}

	useEffect(() =>
	{
		const localData = JSON.parse(`${ localStorage.getItem("mission-nine-graph-data-plan") }`);
		if (localData !== null) setBoards(localData);
	}, []);

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				width: "100%",
				maxWidth: "980px",
				m: "0 auto",
				display: "flex",
				flexDirection: "column",
				marginTop: { xl: "145px", lg: "75px", xs: "75px" },
				position: "relative"
			}}>
			{!open[ 1 ] &&
				(
					<Box>
						<Box sx={{
							display: "flex",
							width: "100%",
							height: "100%",
							minHeight: "448px",
							margin: "0 auto",
							border: "1px solid rgba(255, 255, 255, .6)",
							borderRadius: "24px",
							wordBreak: "break-word",
							background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
							backdropFilter: "blur(25px)",
							boxShadow: "0px 5px 10px 0px #A7C7E199",
							position: "relative",
							maxHeight: "444px",
							overflow: "hidden"
						}}>
							<Box sx={{ display: "flex", width: "261px", backgroundImage: `url(${ CardFonImg })`, backgroundRepeat: "no-repeat" }} />
							<Box sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								width: "100%",
								position: "relative",
								padding: {
									xs: "15px 30px 44px 30px",
									md: "52px 56px 44px 72px"
								},
								wordBreak: "break-word"
							}}>
								<Typography sx={{
									color: "#215C75",
									font: {
										xs: "700 18px/28px Lora-Bold",
										md: "700 24px/38px Lora-Bold"
									},
									textAlign: "left"
								}}>
									<ContentAdmin
										limit={111}
										keyContent="contentMissionNine"
										keyText="finish_step_congratulations"
										defaultValue="Congratulations on completing this very challenging step, {NAME}! Make yourself a nice cup of tea or coffee and relax, you worked really hard. In your next final mission you will work through your unknowns and design your life experiments, keep it up!"
										position="left"
										isDescription
									/>
								</Typography>

								<Box sx={{ display: "flex", justifyContent: "space-between" }}>
									<CustomButton.Outline
										onClick={() => setOpen([ true, false ])}
										// component={Link}
										// to="/dashboard"
										sx={{
											background: "#E9EDF0",
											color: "#215C75",
											borderColor: "transparent",
											boxShadow: "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)",
											padding: "12px 50px"
										}}
									>
										Your North Star
									</CustomButton.Outline>
									<CustomButton.Outline
										onClick={() => setOpen([ false, true ])}
										sx={{
											background: "#E9EDF0",
											color: "#215C75",
											borderColor: "transparent",
											boxShadow: "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)",
											padding: "12px 50px"
										}}
									>
										Your Action plan
									</CustomButton.Outline>
								</Box>
							</Box>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "center", mt: "73px" }}>
							<CustomButton.Outline
								onClick={nextStep}
								sx={{
									background: "#3E809D",
									border: "#3E809D",
									color: "#fff"
								}}
							>
								Finish
							</CustomButton.Outline>
						</Box>
					</Box>
				)
			}
			<StyledDialog
				open={open[ 0 ]}
				onClose={() => setOpen([ false, false ])}
				TransitionComponent={Transition}
			>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ height: "740px" }}>
						<CardMedia
							component="img"
							width="100%"
							height="740px"
							image={IMGMissionLarge4}
							alt=""
						/>
					</Box>

					<Box sx={{ p: "33px 36px 32px 72px", width: "100%" }}>
						<Box
							onClick={() => setOpen([ false, false ])}
							sx={{
								position: "absolute",
								top: "30px",
								right: "30px",
								zIndex: "10"
							}}
						>
							<CloseIcon
								sx={{
									fill: "#fff",
									fontSize: "14px",
									backgroundColor: "#3E809D",
									borderRadius: "40px",
									width: "24px",
									height: "24px",
									p: "3px",
									cursor: "pointer"
								}} />
						</Box>
						<Rezult />
					</Box>
				</Box>
			</StyledDialog>
			<Dialog
				open={open[ 1 ]}
				TransitionComponent={Transition}
				onClose={() => setOpen([ false, false ])}
				sx={{
					"& .MuiBackdrop-root": { backgroundColor: "transparent" },
					"& .MuiPaper-root":
					{
						maxWidth: "982px",
						width: "100%",
						display: "flex",
						marginTop: "180px",
						padding: "0px",
						background: "transparent",
						boxShadow: "none",
						borderRadius: "0px"
					}
				}}
			>
				<CloseIcon
					onClick={() => setOpen([ false, false ])}
					sx={{
						fill: "#fff",
						fontSize: "14px",
						backgroundColor: "#3E809D",
						borderRadius: "40px",
						width: "24px",
						height: "24px",
						p: "3px",
						cursor: "pointer",
						position: "absolute",
						top: "15px",
						right: "25px",
						zIndex: "10"
					}}
				/>

				{boards.length > 0 && <Results boards={boards} sx={{ ml: "24px", mr: "24px", maxWidth: "982px", width: "100%" }} />}
			</Dialog>
		</Box>
	);
});

export default YourPlanFinish;
