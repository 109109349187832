import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { Grow, Table, TableBody, TableCell, TableHead, TableRow, alpha } from "@mui/material";
import Api from "api";
import { IResponseGetStripeData } from "api/Users";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Transactions()
{
	const isFetchRef = useRef<boolean>(false);
	const [ isFetching, setIsFetching ] = useState<boolean>(false);
	const [ transactions, setTransactions ] = useState<IResponseGetStripeData[]>([]);

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchRef.current === true || isFetching === true)
		{
			return;
		}

		setIsFetching(true);
		isFetchRef.current = true;

		Api.users
			.getStripeData()
			.then((res) =>
			{
				setTransactions(res);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() =>
			{
				setIsFetching(false);
				isFetchRef.current = false;
			});
	}, []); // eslint-disable-line

	// Render
	return (
		<Table sx={{
			"& .MuiTableCell-head":
			{
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "#215C75",
				borderBottom: "1px solid " + alpha("#3E809D", 0.5),
				padding: "0 0 16px 0"
			},
			"& .MuiTableCell-body":
			{
				font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: "#4D4D4D",
				borderBottom: "unset",
				padding: "24px 0 0 0"
			},
			"& .MuiTableCell-root:not(:first-of-type)":
			{
				paddingLeft: "16px"
			}
		}}>
			<TableHead>
				<TableRow>
					<TableCell>Payment</TableCell>
					<TableCell>Amount</TableCell>
					<TableCell>Currency</TableCell>
					<TableCell sx={{ width: "200px" }}>Transaction_id</TableCell>
					<TableCell>Description</TableCell>
					<TableCell sx={{ width: "90px" }}>Status</TableCell>
					<TableCell sx={{ width: "135px" }}>Date</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{isFetching === true &&
					(
						<TableRow>
							<TableCell colSpan={6} sx={{ textAlign: "center" }}>Loading...</TableCell>
						</TableRow>
					)
				}
				{isFetching === false && transactions.length === 0 &&
					(
						<TableRow>
							<TableCell colSpan={9} sx={{ textAlign: "center" }}>Your transaction list is empty</TableCell>
						</TableRow>
					)
				}
				{isFetching === false && transactions.length > 0 &&
					transactions.map((item) =>
					{
						return (
							<TableRow key={item.id}>
								<TableCell>{item.information}</TableCell>
								{/* <TableCell>
									{new Intl.NumberFormat(undefined, { style: "currency", currency: item.currency }).format(item.value)}
								</TableCell> */}
								<TableCell>{item.value}</TableCell>
								<TableCell>{item.currency}</TableCell>
								<TableCell sx={{
									"&, & span":
									{
										display: "block",
										maxWidth: "200px",
										overflow: "hidden",
										textOverflow: "ellipsis",
										cursor: "pointer"
									}
								}}>
									<CopyToClipboard text={item.transaction_id}>
										<TooltipStyled title="Copy to clipboard" placeholder="bottom" TransitionComponent={Grow} arrow>
											<span>{item.transaction_id}</span>
										</TooltipStyled>
									</CopyToClipboard>
								</TableCell>
								<TableCell>{item.description}</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{item.status === "succeeded"
										? <DoneIcon sx={{ color: "#1FAD77" }} />
										: <ClearIcon sx={{ color: "#F22E6C" }} />
									}
								</TableCell>
								<TableCell sx={{ whiteSpace: "nowrap" }}>
									{item.created_at}
								</TableCell>
							</TableRow>
						);
					})
				}
			</TableBody>
		</Table>
	);
}

export default Transactions;
