import { alpha, Box, capitalize, Chip } from "@mui/material";
import { forwardRef, Ref, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import deepClone from "../../../../utils/deepClone";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";
import { IInitialValues } from "../LetsUncoverDeepestSittingBeliefs";

export type IYouTopLimitingBeliefsProps = Record<string, any>;

export interface IItems
{
	text: string;
	selected: boolean;
}

const NEED_SELECTED: number = 5;

function YouTopLimitingBeliefs({ ...props }: IYouTopLimitingBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ items, setItems ] = useState<IItems[]>(() =>
	{
		const items: string[] = [];

		const store1 = getLocalStorage<IInitialValues>("mission-seven-i-should");
		const store2 = getLocalStorage<IInitialValues>("mission-seven-i-am");

		items.push(...Object.values(store1 || {}).map((value) => "I should " + value.trim()));
		items.push(...Object.values(store2 || {}).map((value) => "I am " + value.trim()));

		// ---------
		// const store3 = getLocalStorage<IBoards>("mission-seven-WhatBeliefsWouldYouRatherHave-boards");

		// if (store3?.bubble)
		// {
		// 	items.push(...store3.bubble);
		// }

		// ---------
		const store4 = getLocalStorage<string[]>("mission-seven-family-said-store");

		if (store4 !== null)
		{
			items.push(...store4);
		}

		// ---------
		const store5 = getLocalStorage<Record<string, string>>("mission-seven-blitz-answers");

		if (store5 !== null)
		{
			items.push(
				...Object.entries(store5)
					.filter(([ , value ]) => value !== "__TIMEOUT__")
					.map(([ key, value ]) =>
					{
						return key.replace("…", "") + " " + value;
					})
			);
		}

		return Array.from(new Set(items.map((value) => capitalize(value)))).map(
			(value: string): IItems => ({ text: value, selected: false })
		);
	});

	const needSelect = Math.min(NEED_SELECTED, items.length);

	// Utils
	function getSelectedItem(): IItems[]
	{
		return items.filter(({ selected }) => selected === true);
	}

	function getSelectedItemCount(): number
	{
		return getSelectedItem().length;
	}

	// Handles
	function onToggleSelectedItem(itemText: string)
	{
		setItems((prevState) =>
		{
			const items = deepClone(prevState);

			const itemIndex = items.findIndex(({ text }) => text === itemText);
			if (itemIndex === -1)
			{
				return prevState;
			}

			items[ itemIndex ].selected = !items[ itemIndex ].selected;

			return items;
		});
	}

	function onNextStep()
	{
		if (getSelectedItemCount() < needSelect)
		{
			return;
		}

		setLocalStorage("mission-seven-top5", getSelectedItem().map(({ text }) => text));

		setTimeout(() => missionContext.nextStep(), 0);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="YouTopLimitingBeliefs_title" defaultValue={`Your top ${ NEED_SELECTED } limiting beliefs`} position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="YouTopLimitingBeliefs_subTitle" defaultValue={`Woah, look at that list. I want you to select top ${ NEED_SELECTED } that are 1) Important to your sense of identity and 2) Stopping you from realising your full potential.`} position="left" isDescription />}
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { left: "-35px" }
					}}
				/>

				<Panel sx={{
					padding: { xl: "32px", lg: "24px", xs: "24px" },
					height: { xl: "556px", lg: "406px", xs: "406px" },
					display: "grid",
					gridAutoRows: "auto 1fr",
					gap: { xl: "24px", lg: "16px", xs: "16px" }
				}}>
					<Box sx={{ color: "primary.main", font: "700 24px/31px Lora-Bold" }}>
						<ContentAdmin keyContent="contentMissionSeven" keyText="YouTopLimitingBeliefs_panelTitle" defaultValue="{NAME}’s beliefs" position="left" />
					</Box>

					<Box
						className="customScroll"
						sx={{
							overflowY: "auto",
							display: "flex",
							alignContent: "flex-start",
							flexWrap: "wrap",
							gap: "24px",
							padding: "0 16px 5px 0",

							"& .MuiChip-root":
							{
								padding: "9px 16px",
								backgroundColor: alpha("#FFFFFF", 0.5),
								boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
								borderRadius: "24px",
								font: "400 16px/22px 'Open Sans'",
								color: "primary.dark",
								cursor: "pointer",

								"&.MuiChip-root--selected":
								{
									backgroundColor: alpha("#3E809D", 0.5),
									color: "#FFFFFF"
								}
							}
						}}
					>
						{items.map(({ text, selected }) =>
						{
							return (
								<Chip
									key={text}
									label={text}
									className={selected === true ? "MuiChip-root--selected" : ""}
									onClick={() => onToggleSelectedItem(text)}
								/>
							);
						})}
					</Box>
				</Panel>

				<CustomButton.Contained
					sx={{
						marginTop: "-24px",
						paddingLeft: "80px !important",
						paddingRight: "80px !important"
					}}
					disabled={getSelectedItemCount() < needSelect}
					onClick={onNextStep}
				>
					Submit!
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default forwardRef(YouTopLimitingBeliefs);
