import { Box, SxProps, Theme } from "@mui/material";
import { ForwardRefExoticComponent, ReactNode, Ref, forwardRef } from "react";

export interface IStepperNavigationWrapperProps
{
	children: ReactNode;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}
function StepperNavigationWrapper(
	{
		children,
		sx = [],
		...props
	}: IStepperNavigationWrapperProps,
	ref: Ref<HTMLElement>
)
{
	return (
		<Box
			ref={ref}
			component="nav"
			className="stepper"
			sx={[
				{
					display: "flex",
					justifyContent: "space-between",
					zIndex: 1301,
					position: "absolute",
					bottom: "16px",
					right: "40px",
					left: "40px",
					pointerEvents: "none",

					"& *":
					{
						pointerEvents: "auto"
					}
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			{...props}
		>
			{children}
		</Box>
	);
}

export default forwardRef(StepperNavigationWrapper) as ForwardRefExoticComponent<IStepperNavigationWrapperProps>;
