import { alpha, Dialog as MUIDialog, Slide, SlideProps, styled, SxProps, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ReactComponent as SVGClose } from "assets/images/icons/size-24/close.svg";
import { forwardRef, ReactElement, ReactNode } from "react";

const StyledDialog = styled(MUIDialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			backgroundColor: "#E9EDF0",
			padding: "50px 50px 30px 30px",
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "20px",
			maxWidth: "785px",
			boxShadow: "0px 5px 30px rgba(36, 92, 116, .15)",
			font: "400 22px/30px 'Open Sans'",
			color: "#4D4D4D",
			display: "block",

			backdropFilter: "blur(25px)"
		}
	}
);

const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionSlideDown = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionSlideRight = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="right" ref={ref} {...props} />;
});

const TransitionSlideLeft = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="left" ref={ref} {...props} />;
});

const TRANSITION =
{
	up: TransitionSlideUp,
	down: TransitionSlideDown,
	right: TransitionSlideRight,
	left: TransitionSlideLeft
};

export interface IDialogProps
{
	open: boolean;
	children: ReactNode;
	/**
	 * @default up
	 */
	directionSlide?: SlideProps[ "direction" ];
	title?: string | JSX.Element;
	position?: "bottom" | "center" | "bottomPx";
	sx?: SxProps<Theme>;
	onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton") => void) | null;
	noIcon?: boolean;
	[ prop: string ]: any;
}

export default function Dialog({
	title,
	directionSlide = "up",
	position = "center",
	onClose = null,
	open = false,
	sx,
	children,
	noIcon = false,
	...props
}: IDialogProps)
{
	return (
		<StyledDialog
			className="Dialog-root"
			open={open}
			TransitionComponent={TRANSITION[ directionSlide ] || undefined}
			sx={[
				{
					"& .MuiDialog-paper":
					{
						maxWidth: "unset",
						width: "824px",
						minHeight: "355px",
						padding: "56px 40px 40px 40px",
						backgroundColor: "#FFFFFF",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: "40px",

						"& form":
						{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "40px"
						}
					},
					"& .MuiBackdrop-root":
					{
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
						backdropFilter: "blur(25px)"
					},
					"& .Dialog-closeButton":
					{
						cursor: "pointer",
						position: "absolute",
						top: "32px",
						right: "32px",
						color: "primary.main",
						opacity: 1,
						transition: "linear 300ms opacity",

						"&:hover": { opacity: 0.7 }
					},
					"& .Dialog-title":
					{
						color: "primary.main",
						font: "700 24px/31px Lora-Bold",
						marginBottom: "20px",
						textAlign: "center"
					},
					"& .MuiInputBase-root":
					{
						width: "568px",
						backgroundColor: alpha("#FFFFFF", 0.5),
						border: "1px solid #E9EDF0",
						borderRadius: "8px",

						"& .MuiInputBase-input":
						{
							color: "primary.dark"
						}
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			onClose={onClose || undefined}
			{...props}
		>
			{onClose !== null && noIcon === false &&
				<SVGClose
					className="Dialog-closeButton"
					onClick={() => onClose({}, "closeButton")}
				/>
			}

			{title !== undefined &&
				<div className="Dialog-title">
					{title}
				</div>
			}

			{children}
		</StyledDialog>
	);
}
