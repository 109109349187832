import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Box, Dialog, IconButton, Snackbar, alpha, type AlertColor } from "@mui/material";
import Api from "api";
import type { ResGetCoupons } from "api/Coupons";
import AlertStyled from "components/AlertStyled";
import TooltipStyled from "components/TooltipStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { useState, type Dispatch, type ReactNode, type SetStateAction } from "react";

export interface DeleteCouponProps
{
	id: string;
	setCoupons: Dispatch<SetStateAction<ResGetCoupons[]>>;
}

function DeleteCoupon({ id, setCoupons }: DeleteCouponProps)
{
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);

	// Handles
	function deleteCoupon(id: string)
	{
		setIsFetch(true);
		Api.coupons
			.deleteCoupon(id)
			.then(() =>
			{
				setCoupons(pre => pre.filter((item) => item.id !== id));
				setSnackbarContent({ severity: "success", content: "Success" });
				setOpen(false);
			})
			.catch((err) =>
			{
				setSnackbarContent({ severity: "error", content: "An error occurred" });
				console.error(err);
			})
			.finally(() => setIsFetch(false));
	}

	// Render
	return (
		<>
			<TooltipStyled title="Delete coupon" placement="bottom" arrow>
				<IconButton
					onClick={() => setOpen(true)}
				>
					<DeleteOutlineRoundedIcon />
				</IconButton>
			</TooltipStyled>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					"& .MuiDialog-paper":
					{
						maxWidth: "unset",
						width: "680px",
						height: "300px",
						padding: "48px 64px 64px 64px",
						backgroundColor: "#FFFFFF",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px"
					},
					"& .MuiBackdrop-root":
					{
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
						backdropFilter: "blur(25px)"
					}
				}}
			>

				<Box sx={{ font: "700 40px/51px Lora-Bold", color: "primary.main" }}>
					Delete coupon
				</Box>

				<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark", marginTop: "24px" }}>
					Are you sure you want to remove the coupon?
				</Box>

				<Box sx={{ marginTop: "auto", display: "flex", justifyContent: "space-between" }}>
					<CustomButton.Contained
						onClick={() => setOpen(false)}
					>
						Cancel
					</CustomButton.Contained>

					<CustomButton.Outline
						loading={isFetch}
						onClick={() => deleteCoupon(id)}
						disabled={isFetch}
					>
						Delete
					</CustomButton.Outline>
				</Box>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={3000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
		</>
	);
}

export default DeleteCoupon;
