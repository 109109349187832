export default function clearLocalStorage(key: string)
{
	let arrKey: any = [];

	for (let i = 0; i < localStorage.length; i++)
	{
		const myKey = localStorage.key(i);

		if (myKey?.indexOf(key) === 0)
		{
			arrKey.push(myKey);
		}
	}

	arrKey.forEach((element: string) =>
	{
		localStorage.removeItem(element);
	});
}
