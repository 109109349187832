import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function Rezult()
{
	const [ dataMission, setDataMission ] = useState<any>([]);

	useEffect(() =>
	{
		const data = JSON.parse(`${ localStorage.getItem("mission-nine-inputs") }`);

		setDataMission(data);
	}, []);

	return (
		<Box sx={{ margin: "0px" }}>
			<Typography sx={{
				font: { lg: "700 30px/31px Lora-Bold", xl: "700 40px/51px Lora-Bold" },
				marginLeft: "16px",
				marginBottom: "5px",
				color: "#215C75",
				textAlign: "left"
			}}>
				My North Star
			</Typography>
			<Box
				className="customScroll"
				sx={{
					overflowY: "scroll",
					overflowX: "hidden",
					paddingRight: "45px",
					maxHeight: "480px"
				}}>
				{dataMission.length
					? (
						<Grid container spacing={2}>
							<Grid
								item
								xs={6}
								sx={{
									m: "33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere"
								}}>
								<Typography sx={{ font: { lg: "700 20px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
									My purpose is
								</Typography>
								<Typography sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
									{dataMission[ 0 ].value}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{ m: "33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
								<Typography sx={{ font: { lg: "700 20px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
									My tribe is...
								</Typography>
								<Typography sx={{ font: { lg: "400 18px/31px Open Sans", xl: "400 24px/31px Open Sans" }, m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
									{dataMission[ 2 ].value}
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{ m: "8px 0 15px 0", overflow: "hidden", maxWidth: "100% !important", overflowWrap: "anywhere" }}>
								<Typography sx={{ font: { lg: "700 20px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
									This is why I am motivated every day to fulfill my purpose
								</Typography>
								<Typography sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
									{dataMission[ 4 ].value}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{ m: "33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
								<Typography sx={{ font: { lg: "700 20px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
									This is what I stand for
								</Typography>
								{dataMission[ 3 ].items
									? (
										<Typography sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
											{dataMission[ 3 ].items.map((item: any, index: number) => (
												<Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
													<FiberManualRecordIcon sx={{ fill: "#4D4D4D", mt: "4px", fontSize: "10px" }} />
													<Typography key={index} sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, color: "#4D4D4D", textAlign: "left" }}>
														{item.value}
													</Typography>
												</Box>
											))}
										</Typography>
									)
									: null
								}
							</Grid>
							<Grid
								item
								xs={6}
								sx={{ m: "33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
								<Typography sx={{ font: { lg: "700 20px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
									Quotes I live by
								</Typography>
								{dataMission[ 1 ].items
									? (
										<Typography sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
											{dataMission[ 1 ].items.map((item: any, index: number) => (
												<Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
													<FiberManualRecordIcon sx={{ fill: "#4D4D4D", mt: "4px", fontSize: "10px" }} />
													<Typography key={index} sx={{ font: { lg: "400 18px/31pxOpen Sans", xl: "400 24px/31px Open Sans" }, color: "#4D4D4D", textAlign: "left" }}>
														{item.value}
													</Typography>
												</Box>
											))}
										</Typography>
									)
									: null
								}
							</Grid>
						</Grid>
					)
					: null
				}
			</Box>
		</Box>
	);
}
