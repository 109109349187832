import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import { IResponseGetMissionEleven } from "api/Missions";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import CustomButton from "components/customButton";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import { formatDate } from "../yearning";
import Create from "./Create";
import IMGBackgroundMini from "./assets/images/background-mini.png";
import IMGBackground from "./assets/images/background.png";
import IMGMailClose from "./assets/images/mail-close.png";
import IMGMailOpen from "./assets/images/mail-open.png";

function LetterYourself()
{
	const navigate = useNavigate();
	const params = useParams();

	const isFetchingRef = useRef<boolean>(false);
	const [ items, setItems ] = useState<IResponseGetMissionEleven[ "data" ]>([]);
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ miniBackground, setMiniBackground ] = useState<boolean>(false);

	useEffect(() =>
	{
		if (window.matchMedia("(min-width: 1700px)").matches)
		{
			setMiniBackground(false);
		}
		else
		{
			setMiniBackground(true);
		}
	}, []);

	// Utils
	function updateItems()
	{
		if (isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getDataMissionEleven()
			.then(({ data }) =>
			{
				setItems(data);
			})
			.finally(() => (isFetchingRef.current = false));
	}

	// Effects
	useLayoutEffect(() =>
	{
		updateItems();
	}, []);

	// Render
	switch (params.action)
	{
		case "create": return <Create updateItems={updateItems} />;
		case undefined: break;
		default: return <Navigate to="/dashboard/artifacts/letter-yourself" />;
	}

	return (
		<ArtifactsContainer
			title="Letter to Yourself"
			backgroundImage={miniBackground ? IMGBackgroundMini : IMGBackground}
			maxWidth="unset"
			headerRight={(items[ selectedItemIndex ] !== undefined) && (
				<Box sx={{ font: "700 24px/31px Lora-Bold" }}>
					{formatDate(items[ selectedItemIndex ].created_at)}
				</Box>
			)}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "48px 32px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "0px 4px 42px " + alpha("#68AEC5", 0.4)
							}
						}}
					>
						{items.length === 0
							? (<Box>Loading...</Box>)
							: (
								items.map((item, index) =>
								{
									return (
										<Box key={item._id} sx={{ position: "relative" }}>
											{item.letterSend === false &&
												(
													<Box sx={{
														position: "absolute",
														top: "24px",
														left: "50%",
														transform: "translateX(-50%)",
														padding: { xs: "8px 16px", xl: "8px 20px" },

														backgroundColor: "#3E809D",
														boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
														borderRadius: "40px",
														color: "#FFFFFF",
														font: { xs: "600 8px/10px 'Open Sans'", xl: "600 12px/16px 'Open Sans'" },
														textTransform: "uppercase",
														whiteSpace: "nowrap",
														pointerEvents: "none"
													}}>
														{item.remainingDays}
													</Box>
												)
											}

											<AsideCircleButton
												image={(item.letterSend === true ? IMGMailOpen : IMGMailClose)}
												text={formatDate(item.created_at)}
												isSelected={selectedItemIndex === index}
												onClick={() => setSelectedItemIndex(index)}
												sx={{
													"& .AsideCircleButton-text":
													{
														font: { xl: "700 24px/41px Lora-Bold", xs: "700 18px/24px Lora-Bold" },
														color: "#FFFFFF",

														"&--selected":
														{
															font: { xl: "700 32px/41px Lora-Bold", xs: "700 22px/28px Lora-Bold" },
															opacity: 0.5
														}
													}
												}}
											/>
										</Box>
									);
								})
							)
						}

					</Box>

					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: { xl: "355px", lg: "355px", xs: "355px" }
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/letter-yourself/create");
						}}
					>
						Write new letter
					</CustomButton.Contained>
				</Box>
			}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-main":
				{
					position: "relative",
					padding: { xl: "40px 125px 40px 48px", xs: "32px 40px" }
				},
				"& .ArtifactsContainer-content":
				{
					height: { xl: "860px", lg: "764px", md: "680px" }
				}
			}}
		>
			{selectedItemIndex > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItemIndex((prevState) => prevState - 1)}
							sx={{
								width: "40px",
								height: "40px",
								position: "absolute",
								top: { xl: "calc(50% + 46px)", xs: "calc(50% + 39px)" },
								transform: "translateY(-50%)",
								left: "20px",
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "#FFFFFF",
								cursor: "pointer",

								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{selectedItemIndex < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItemIndex((prevState) => prevState + 1)}
							sx={{
								width: "40px",
								height: "40px",
								position: "absolute",
								top: { xl: "calc(50% + 46px)", xs: "calc(50% + 39px)" },
								transform: "translateY(-50%)",
								right: "20px",
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "#FFFFFF",
								cursor: "pointer",

								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			{items[ selectedItemIndex ] !== undefined &&
				(
					<Box sx={{
						position: "relative",
						padding: "24px 40px",

						width: { xl: "838px", lg: "542px", md: "350px", xs: "100%" },
						margin: { xl: 0, xs: "0 auto" },
						minHeight: { xl: "354px", xs: "265px" },
						maxHeight: { xl: "100%", xs: "265px" },

						backgroundColor: alpha("#FFFFFF", 0.8),
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						backdropFilter: "blur(30px)",
						borderRadius: "24px",

						alignSelf: "center",
						justifySelf: "flex-end",
						display: "grid",
						gridTemplateRows: "1fr"
					}}>
						<Box
							className="customScroll"
							sx={{
								overflowY: "auto",
								marginRight: "-8px"
								// paddingRight: "30px"
							}}
						>
							<Box sx={[
								{
									height: "100%",
									font: "400 22px/50px 'Satisfy'",
									color: "#4D4D4D",
									background: "linear-gradient(rgba(255, 255, 255, 0.5) 2px, transparent 0px)",
									backgroundSize: "100% 50px",
									backgroundPositionY: "34px",
									padding: "0 10px"
								},
								(items[ selectedItemIndex ].letterSend === false) &&
								{
									filter: "blur(4px)"
								}
							]}>
								{items[ selectedItemIndex ].letterSend === true
									? items[ selectedItemIndex ].mission_data
									: "I work from home and go out for a coffee in a neighborhood cafe. I am wearing comfy yoga pants and a white designer shirt. It's always sunny in Lisbon and the sky is blue! I do some work, take my dog out for a lunch walk, and some days I go to the gym. For lunch I have a salad, or a poke bowl."
								}
							</Box>
						</Box>

						{items[ selectedItemIndex ].letterSend === false &&
							(
								<Box sx={{
									position: "absolute",
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									backgroundColor: alpha("#FFFFFF", 0.1),
									boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
									backdropFilter: "blur(10px)",
									borderRadius: "inherit",

									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "16px"
								}}>
									<Box sx={{
										color: "primary.main", font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/24px Lora-Bold" }
									}}>
										You can open the letter after
									</Box>

									<Box sx={{
										padding: { xl: "13px 32px", xs: "9px 46.5px" },
										textTransform: "uppercase",
										color: "#FFFFFF",
										font: { xl: "700 22px/30px 'Open Sans'", xs: "700 16px/22px 'Open Sans'" },
										backgroundColor: "#3E809D",
										borderRadius: "40px"
									}}>
										{items[ selectedItemIndex ].remainingDays}
									</Box>
								</Box>
							)
						}
					</Box>
				)
			}
		</ArtifactsContainer>
	);
}

export default LetterYourself;
