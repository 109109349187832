import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CelebrationScreen from "../../../../components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

export type IFinishProps = Record<string, any>;

function WhatBeliefsWouldYouRatherHaveFinish({ ...props }: IFinishProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important" }}>
				<CelebrationScreen
					content={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatBeliefsWouldYouRatherHaveFinish_text" defaultValue="Good job, {NAME}!" position="left" isDescription />}
					buttonText="Thanks, Octa!"
					onClickButton={() => { missionContext.nextStep(); }}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(WhatBeliefsWouldYouRatherHaveFinish);
