import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogProps, Dialog as MuiDialog, Slide, SlideProps, alpha } from "@mui/material";
import { ReactElement, ReactNode, forwardRef } from "react";
import { TransitionProps } from "react-transition-group/Transition";

const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionSlideDown = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionSlideRight = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="right" ref={ref} {...props} />;
});

const TransitionSlideLeft = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="left" ref={ref} {...props} />;
});

const TRANSITION =
{
	up: TransitionSlideUp,
	down: TransitionSlideDown,
	right: TransitionSlideRight,
	left: TransitionSlideLeft
};

export interface IDialogProps extends Omit<DialogProps, "open" | "title">
{
	title?: JSX.Element | ReactNode;
	/**
	 * @default false
	 */
	open?: boolean;
	/**
	 * @default up
	 */
	directionSlide?: SlideProps[ "direction" ];
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton"): void;
}

function Dialog({ title, open = false, onClose, children, directionSlide = "up", sx = [], ...props }: IDialogProps)
{
	return (
		<MuiDialog
			open={open}
			onClose={onClose}
			TransitionComponent={TRANSITION[ directionSlide ] || undefined}
			sx={[
				{
					"& .MuiDialog-container":
					{
						alignItems: { sm: "center", xs: "flex-end" }
					},
					"& .MuiDialog-paper":
					{
						margin: { sm: "32px", xs: "unset" },
						maxWidth: { xl: "980px", sm: "625px" },
						width: "100%",
						padding: { sm: "56px 64px", xs: "24px 32px 40px 32px" },
						backgroundColor: "#FFFFFF",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: { sm: "24px", xs: "24px 24px 0 0" }
					},
					"& .MuiBackdrop-root":
					{
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
						backdropFilter: "blur(25px)"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<CloseIcon
				onClick={() => onClose({}, "closeButton")}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					cursor: "pointer",
					color: "primary.main"
				}}
			/>

			<Box sx={{
				font: { sm: "700 40px/45px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main",
				marginRight: "24px"
			}}>
				{title}
			</Box>

			{children}
		</MuiDialog>
	);
}

export default Dialog;
