import CloseIcon from "@mui/icons-material/Close";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import fearsBg from "assets/images/fearsBg.png";
import { default as IMGLISTITEM, default as IMGSRC } from "assets/images/icons/ball-list.svg";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import IMGBg from "assets/images/missions/mission-eight/bigBall.png";
import RadialButtonBall from "components/RadialButtonBall";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArtifactsContainer from "../../components/ArtifactsContainer";

const items = [
	{
		id: 1,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="emergency_plan"
			defaultValue="Emergency plan"
			position="left"
		/>
	},
	{
		id: 2,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="immediate_emergency_plan"
			defaultValue="Immediate action Plan"
			position="left"
		/>
	}
];

function Details({ item }: any)
{
	const navigate = useNavigate();
	const [ selectedButton, setSelectedButton ] = useState(1);
	const [ open, setOpen ] = useState(false);

	// const [ isButtonState, setIsButtonState ] = useState(
	// 	{
	// 		oneButton: false,
	// 		twoButton: false
	// 	}
	// );
	function onOpenButtonTwo()
	{
		setOpen(true);
		setSelectedButton(2);
		// setIsButtonState({
		// 	oneButton: false,
		// 	twoButton: true
		// });;
	}

	function onOpenButtonOne()
	{
		setOpen(true);
		setSelectedButton(1);
		// setIsButtonState({
		// 	oneButton: true,
		// 	twoButton: false
		// });;
	}

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}

	return (
		<ArtifactsContainer
			title={formatDate(item?.created_at || "Fears details not found")}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			backgroundImage={fearsBg}
			onClose={() => navigate("/dashboard/artifacts/fears")}
		>
			<Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "relative" }}>
				<Box>
					<Box sx={{
						position: "relative",
						width: "615px",
						height: "615px",
						backgroundImage: `url(${ IMGBg })`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						boxShadow: "0px 0 35px 7px rgb(255 255 255 / 66%)",
						borderRadius: "50%",
						transform: { lg: "scale(0.8)", xl: "scale(1)" },
						mt: { lg: "-50px", xl: 0 }
					}}>
						<>
							<Box sx={{
								position: "absolute", top: "354px", right: "160px"

							}}>
								<RadialButtonBall
									sx={{ backdropFilter: "none" }}
									imgRadialButton={IMGSRC}
									direction="left"
									value="Emergency plan"
									color="blue"
									absoluteValuePosition
									onClick={onOpenButtonOne}
								/>
							</Box>
							<Box sx={{ position: "absolute", top: "214px", right: "399px" }}>
								<RadialButtonBall
									sx={{ backdropFilter: "none" }}
									imgRadialButton={IMGSRC}
									value="Immediate action plan"
									color="blue"
									absoluteValuePosition
									onClick={onOpenButtonTwo}
								/>
							</Box>

						</>
						{open
							? (
								<Box sx={{
									position: "absolute",
									zIndex: "10",
									top: "0px",
									width: "709px",
									right: selectedButton === 1 ? "300px" : "-400px"
								}}>
									<Box sx={{
										display: "flex",
										width: "709px",
										maxWidth: "709px",
										height: "616px",
										border: "1px solid rgba(255, 255, 255, .6)",
										flexDirection: "column",
										background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
										backdropFilter: "blur(25px)",
										boxShadow: "0px 5px 10px 0px #A7C7E199",
										position: "relative",
										borderTopRightRadius: (selectedButton === 1 ? "0px" : "24px"),
										borderBottomRightRadius: (selectedButton === 1 ? "0px" : "24px"),
										borderTopLeftRadius: (selectedButton === 1 ? "24px" : "0px"),
										borderBottomLeftRadius: (selectedButton === 1 ? "24px" : "0px")
									}}>
										<Box
											sx={{
												padding: "31px 24px 0px 17px",
												position: "relative"
											}}>
											<Box
												sx={{
													position: "absolute",
													top: "30px",
													right: "40px",
													zIndex: "10"
												}}
												onClick={() => setOpen(false)}>
												<CloseIcon
													sx={{
														fill: "#fff",
														fontSize: "14px",
														backgroundColor: "#3E809D",
														borderRadius: "40px",
														width: "24px",
														height: "24px",
														p: "3px",
														cursor: "pointer"
													}} />
											</Box>
											<Typography sx={{
												marginBottom: "25px",
												color: "#215C75",
												font: {
													xs: '700 20px/21px "Lora"',
													md: '700 40px/51px "Lora"'
												},
												textAlign: "left",
												marginLeft: "30px",
												wordBreak: "break-word"

											}}>
												{items[ selectedButton - 1 ].title}
											</Typography>
											<Box
												className="scrollCustom"
												sx={{
													overflowY: "scroll",
													overflowX: "hidden",
													paddingLeft: "25px",
													maxHeight: "450px",
													width: "calc(100% - 26px)"
												}}>
												<List sx={{ padding: "0px" }}>
													{item.mission_data?.map((element: any, index: number) => (
														<ListItem key={index} sx={{ alignItems: "flex-start", marginBottom: "8px", marginLeft: "-28px", padding: "0px" }}>
															<ListItemIcon>
																<Box sx={{
																	width: "80px",
																	height: "80px",
																	backgroundImage: `url(${ IMGLISTITEM })`,
																	backgroundPosition: "center",
																	backgroundRepeat: "no-repeat"
																}} />
															</ListItemIcon>
															{selectedButton === 2 &&
																<ListItemText
																	primary={
																		<Typography
																			sx={{
																				color: "#215C75",
																				font: {
																					xs: '700 18px/18px "Lora"',
																					md: '700 24px/31px "Lora"'
																				},
																				marginBottom: "8px",
																				wordBreak: "break-word"
																			}}
																			component="div"
																		>
																			{element.title}
																		</Typography>

																	}
																	secondary={
																		<Typography
																			sx={{
																				color: "#4D4D4D",
																				font: {
																					xs: '400 18px/18px "Lora"',
																					md: '400 22px/30px "Lora"'
																				}
																			}}
																			component="div"
																		>{element.repair}
																		</Typography>
																	}
																/>}

															{(selectedButton === 1) && <ListItemText
																primary={
																	<Typography
																		sx={{ color: "#215C75", font: '700 24px/31px "Lora"', marginBottom: "8px" }}
																		component="div"
																	>{element.title}
																	</Typography>

																}
																secondary={
																	<Typography
																		sx={{ color: "#4D4D4D", font: '400 22px/30px "Open Sans"' }}
																		component="div"
																	>{element.prevent}
																	</Typography>

																}
															/>}
														</ListItem>
													))}

												</List>
											</Box>
										</Box>
									</Box>
								</Box>
							)
							: null}
					</Box>

				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default Details;
