import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import Api from "api";
import Career from "assets/images/Career.svg";
import Friend from "assets/images/Friend.svg";
import Fun from "assets/images/Fun.svg";
import Growth from "assets/images/Growth.svg";
import Health from "assets/images/Health.svg";
import Love from "assets/images/Love.svg";
import Money from "assets/images/Money.svg";
import bgArtefactFirst from "assets/images/bgArtefactFirst.png";
import home from "assets/images/home.svg";
import loveOpacity from "assets/images/loveOpacity.svg";
import meditate from "assets/images/meditate.png";
import moneyOpacity from "assets/images/moneyOpacity.svg";
import share from "assets/images/shareNew.svg";
import step3 from "assets/images/step3.svg";
import step4 from "assets/images/step4.svg";
import step5 from "assets/images/step5.svg";
import step6 from "assets/images/step6.svg";
import step7 from "assets/images/step7.svg";
import ReferFriendsModal from "components/Dialog/ReferFriends.modal";
import ContentAdmin from "components/contentAdmin";
import SteperHeader from "pages/startPage/fields/SteperHeader";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RadarChart from "react-svg-radar-chart";
import { setLocalStorage } from "utils/localStorage";
import ArtifactsContainer from "../../components/ArtifactsContainer";

const items = [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10" ];

function Create()
{
	const navigate = useNavigate();
	const [ step, setStep ] = useState(0);
	const [ valSlider, setValSlider ] = useState(5);
	const [ idMissionPre, setIdMissionPre ] = useState(0);
	const [ valGraph, setValGraph ] = useState(
		{
			home: 0.1,
			health: 0.1,
			friend: 0.1,
			love: 0.1,
			career: 0.1,
			money: 0.1,
			fun: 0.1,
			growth: 0.1
		}
	);
	const [ isOpenShare, setIsOpenShare ] = useState(false);

	// Hooks
	useLayoutEffect(() =>
	{
		Api.missions.getMissions()
			.then(({ missions }) =>
			{
				const lastMissionId = Math.max(...missions.filter((mission) => mission.mission_id).map((mission) => +mission.mission_id));

				console.info({ missions, lastMissionId });
				setIdMissionPre(lastMissionId + 1);
			}).catch((error) =>
			{
				console.error(error);
			});

	}, []);

	useEffect(() =>
	{
		if (step !== 8)
		{
			return;
		}

		Api.missions.saveMission(valGraph, idMissionPre)
			.then((res) =>
			{
				setLocalStorage("artifactGraph", valGraph);

				return navigate(`/artifact/wheel-of-life/details/:${ res }`);
			})
			.catch((error) =>
			{
				console.error(error);
			});
	}, [ step, valGraph ]);

	// Handles
	function handleValSlider(event: Event, newValue: number | number[])
	{
		setValSlider(newValue as number);
	}

	function handleStepGraph(value: number)
	{
		const stepKeys = [ "health", "friend", "love", "career", "money", "fun", "growth", "home" ];

		if (stepKeys[ step ] !== undefined)
		{
			setValGraph(
				{
					...valGraph,
					[ stepKeys[ step ] ]: +value / 10
				}
			);

			setValSlider(5);
		}

		setStep((prev) => prev + 1);
	}

	function renderStep(step: number)
	{
		switch (step)
		{
			case 0:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Health} alt="Health" />}
					title="Health"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How happy are you with your health, wellness, and physical body?"
						keyContent="contentMissionOne"
						keyText="healthDesc"
					/>}
				/>);
			case 1:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Friend} alt="Health" />}
					title="Friends & Family"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How satisfied are you with your social life?"
						keyContent="contentMissionOne"
						keyText="relationshipsDesc"
					/>}
				/>);
			case 2:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Love} alt="Love" />}
					title="Love"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How’s your romantic life?"
						keyContent="contentMissionOne"
						keyText="loveDesc"
					/>}
				/>);
			case 3:
				return (<SteperHeader
					sx={{ pt: 0 }}
					// handleStep={handleStepGraph}
					img={<img width="100%" src={Career} alt="Career" />}
					title="Career"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How happy and fulfilled are you professionally?"
						keyContent="contentMissionOne"
						keyText="careerDesc"
					/>}
				/>);
			case 4:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Money} alt="Money" />}
					title="Money"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How comfortable are you with your financial situation?"
						keyContent="contentMissionOne"
						keyText="moneyDesc"
					/>}
				/>);
			case 5:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Fun} alt="Fun" />}
					title="Fun"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="Are you having fun?"
						keyContent="contentMissionOne"
						keyText="funDesc"
					/>}
				/>);
			case 6:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={Growth} alt="Growth" />}
					title="Growth"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How satisfied are you with your personal growth?"
						keyContent="contentMissionOne"
						keyText="growthDesc"
					/>}
				/>);
			case 7:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={home} alt="home" />}
					title="Physical environment"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How comfortable is your physical environment?"
						keyContent="contentMissionOne"
						keyText="physicalEnvironmentDesc"
					/>}
				/>);
			default:
				return (<SteperHeader
					sx={{ pt: 0 }}
					img={<img width="100%" src={home} alt="home" />}
					title="Physical environment"
					description={<ContentAdmin
						isDescription
						position="left"
						defaultValue="How comfortable is your physical environment?"
						keyContent="contentMissionOne"
						keyText="physicalEnvironmentDesc"
					/>}
				/>);
		}
	}

	const data = [
		{
			data: valGraph,
			meta: { color: "transparent" }
		}
	];

	const captions =
	{
		home: "home",
		health: "health",
		friend: "friend",
		love: "love",
		career: "Speed",
		money: "Weight",
		fun: "Weight2",
		growth: "Weight3"
	};
	const date = new Date();
	const mm = String(date.getMonth() + 1).padStart(2, "0");

	function valuetext(value: number)
	{
		return `${ value }`;
	}

	return (
		<ArtifactsContainer
			title={`${ date.getDate() }.${ mm }.${ date.getFullYear() }`}
			backgroundImage={bgArtefactFirst}
			sx={{
				"& .ArtifactsContainer-main": {
					background: "rgba(113, 140, 235, 0.2)",
					backdropFilter: "blur(50px)",
					borderRadius: "24px"
				},
				"& .ArtifactsContainer-content": {
					border: "none"
				}
			}}
			onClose={() => navigate("/dashboard/artifacts/wheel-of-life")}
		>
			<Box
				className="heighSteper"
				sx={{
					position: "relative",
					mt: "-20px"
				}}
			>
				<Box sx={{ position: "absolute", bottom: { lg: "60px", xl: "40px" }, left: "-10px", display: { xs: "none", sm: "block" }, width: { lg: "379px", xl: "465px" } }}>
					<img src={meditate} alt="meditate" width="100%" />
				</Box>

				{/* <Box sx={{ background: { xs: '', sm: `url(${meditate}) no-repeat` }, backgroundPosition: { xs: '', sm: '10px 98%' }, height: '100vh' }}> */}
				<Box sx={{ backgroundPosition: { xs: "", sm: "10px 98%" }, height: { xs: "100vh", sm: "100%" } }}>
					{renderStep(step)}
					<Grid
						container
						sx={{
							width: { xs: "90%", md: "928px" },
							m: "24px auto 0",
							display: { xs: "none", sm: "flex" },
							justifyContent: "center"
						}}>
						{items.map((item, index) => (
							<Grid
								key={index + 1}
								item
								sx={{
									width: "82px",
									mr: {
										lg: "0", xl: (index + 1 === items.length ? "0" : "12px")
									}
								}}>
								<Button
									sx={{
										width: { lg: "65px", xl: "100%" },
										background: "rgba(255,255,255,0.6)",
										height: { lg: "65px", xl: "78px" },
										fontSize: "24px",
										color: "#215C75",
										fontWeight: "bold",
										borderRadius: "10px",
										// '&:hover': { background: '#9db6cf' }
										"&:hover": { background: "#718EC0" }
									}}
									onClick={() => handleStepGraph(+item)}
								>
									{item}
								</Button>
							</Grid>
						))}
					</Grid>
					<Box sx={{ display: { xs: "block", sm: "none" }, width: "90%", m: "20px auto 0" }}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography sx={{ fontSize: "18px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff", lineHeight: "23px" }}>
								1
							</Typography>
							<Typography sx={{ fontSize: "18px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff", lineHeight: "23px" }}>
								10
							</Typography>
						</Box>
						<Slider
							aria-label="Always visible"
							getAriaValueText={valuetext}
							value={valSlider}
							step={1}
							onChange={handleValSlider}
							max={10}
							min={1}
							valueLabelDisplay="on"
							sx={{
								".MuiSlider-rail": { background: "#215C75", opacity: 1 },
								".MuiSlider-thumb": { color: "rgba(255,255,255, 0.8)" },
								color: "#215C75",
								".MuiSlider-track": { height: "2px" },
								p: " 10px 0 !important",
								".MuiSlider-valueLabel": { background: "rgba(255,255,255, 0.6)", p: "3px 6px", color: "#3E809D", fontSize: "16px", fontWeight: 700, transform: "translateY(-70%) scale(1) !important" }
							}}
						/>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between", width: { xs: "90%", lg: "85%", xl: "950px" }, m: { xs: "5px auto 0", xl: "20px auto 0" }, height: "70px" }}>
						<Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontFamily: "Open Sans", textAlign: { xs: "left", sm: "center" }, fontWeight: "medium", color: "#fff", width: "144px", lineHeight: { xs: "19px", sm: "23px" } }}>
							{
								[
									<ContentAdmin isDescription position="right" defaultValue="I am feeling like a dying horse" keyContent="contentMissionOne" keyText="healthBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Does my cat count?" keyContent="contentMissionOne" keyText="relationshipBad" />,
									<ContentAdmin isDescription position="right" defaultValue="I’m applying for cat adoption" keyContent="contentMissionOne" keyText="loveBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Please cancel Mondays" keyContent="contentMissionOne" keyText="careerBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Instant noodles are the staple food of my diet" keyContent="contentMissionOne" keyText="moneyBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Does binge watching Netflix count?" keyContent="contentMissionOne" keyText="funBad" />,
									<ContentAdmin isDescription position="right" defaultValue="I forgot what a book looks like 😳" keyContent="contentMissionOne" keyText="growthBad" />,
									<ContentAdmin isDescription position="right" defaultValue="Living under a bridge would be more comfortable" keyContent="contentMissionOne" keyText="physicalEnvironmentBad" />
								][ step ]
							}
						</Typography>
						<Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, fontFamily: "Open Sans", textAlign: { xs: "right", sm: "center" }, fontWeight: "medium", color: "#fff", width: "144px", lineHeight: { xs: "19px", sm: "23px" } }}>
							{
								[
									<ContentAdmin isDescription position="left" defaultValue="Very, I am applying to join the Olympics!" keyContent="contentMissionOne" keyText="healthGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Great Gatsby would be jealous" keyContent="contentMissionOne" keyText="relationshipGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Like Romeo & Juliette but with a happy ending" keyContent="contentMissionOne" keyText="loveGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I wouldn’t quit my job for a billion dollars!" keyContent="contentMissionOne" keyText="careerGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I really need to find a bigger bay for all my yachts!" keyContent="contentMissionOne" keyText="moneyGood" />,
									<ContentAdmin isDescription position="left" defaultValue="I’m having a blast! Can Red Bull sponsor me?" keyContent="contentMissionOne" keyText="funGood" />,
									<ContentAdmin isDescription position="left" defaultValue="Dalai Lama would quote me" keyContent="contentMissionOne" keyText="growthGood" />,
									<ContentAdmin isDescription position="left" defaultValue="My Persian rugs are too hard on my feet" keyContent="contentMissionOne" keyText="physicalEnvironmentGood" />
								][ step ]
							}
						</Typography>
					</Box>
					<Box
						sx={{
							width: { xs: "310px", sm: "450px" },
							m: { xs: "-20px auto", lg: "-150px auto 0", xl: "-100px auto 0" },
							position: "relative",
							transform: { lg: "scale(0.556)", xl: "scale(0.7)" }
						}}>
						<Box sx={{ display: { xs: "none", sm: "block" } }}>
							<RadarChart
								captions={captions}
								data={data}
								size={450}
								options={{
									scales: 10
								}}
							/>
						</Box>
						<Box sx={{ display: { xs: "block", sm: "none" } }}>
							<RadarChart
								captions={captions}
								data={data}
								size={310}
								options={{
									scales: 10
								}}
							/>
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "87px", sm: "131px" }, left: { xs: "94px", sm: "131px" }, width: { xs: "123px", sm: "188px" }, height: "3px", background: "#fff", transform: "rotate(270deg)", opacity: .4 }} />
						<Box sx={{ position: "absolute", top: { xs: "107px", sm: "157px" }, left: { xs: "141px", sm: "198px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
						<Box sx={{ position: "absolute", top: { xs: "154px", sm: "223px" }, left: { xs: "160px", sm: "227px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", opacity: step > 0 ? 1 : .4 }} />
						<Box sx={{ position: "absolute", top: { xs: "200px", sm: "289px" }, left: { xs: "140px", sm: "198px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(225deg)", opacity: step > 1 ? 1 : .4 }} />
						<Box sx={{ position: "absolute", top: { xs: "219px", sm: "318px" }, left: { xs: "93px", sm: "132px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(270deg)", opacity: step > 2 ? 1 : .4 }} />
						<Box sx={{ position: "absolute", top: { xs: "199px", sm: "290px" }, left: { xs: "47px", sm: "65px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(315deg)", opacity: step > 3 ? 1 : .4 }} />
						<Box sx={{ position: "absolute", top: { xs: "154px", sm: "223px" }, left: { xs: "28px", sm: "36px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", opacity: step > 4 ? 1 : .6 }} />
						<Box sx={{ position: "absolute", top: { xs: "108px", sm: "156px" }, left: { xs: "48px", sm: "65px" }, width: { xs: "123px", sm: "186px" }, height: "3px", background: "#fff", transform: "rotate(45deg)", opacity: step > 5 ? 1 : .4 }} />

						<Box sx={{ position: "absolute", top: { xs: "30px", sm: "40px" }, right: { xs: "32px", sm: "40px" }, width: { xs: "40px", sm: "62px" } }}>
							<img width="100%" src={Health} alt="Health" />
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "137px", sm: "194px" }, right: { xs: "-13px", sm: "-25px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 0
								? <img width="100%" src={Friend} alt="Friend" /> :
								<img width="100%" src={step4} alt="step4" />}
						</Box>
						{step > 1
							? (
								<Box sx={{ position: "absolute", top: { xs: "240px", sm: "345px" }, right: "32px", width: { xs: "40px", sm: "62px" } }}>
									<img width="100%" src={Love} alt="Love" />
								</Box>
							)
							: (
								<Box sx={{ position: "absolute", top: { xs: "231px", sm: "330px" }, right: { xs: "21px", sm: "22px" }, width: { xs: "60px", sm: "92px" } }}>
									<img width="100%" src={loveOpacity} alt="loveOpacity" />
								</Box>
							)
						}
						<Box sx={{ position: "absolute", top: { xs: "282px", sm: "412px" }, right: { xs: "135px", sm: "194px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 2
								? <img width="100%x" src={Career} alt="Friend" /> :
								<img width="100%" src={step7} alt="step7" />}
						</Box>

						<Box sx={{ position: "absolute", top: { xs: "239px", sm: "348px" }, right: { xs: "238px", sm: "348px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 3
								? <img width="100%" src={Money} alt="step6" /> :
								<img width="100%" src={moneyOpacity} alt="step6" />}
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "135px", sm: "193px" }, right: { xs: "282px", sm: "414px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 4
								? <img width="100%" src={Fun} alt="Fun" /> :
								<img width="100%" src={step5} alt="step5" />}
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "32px", sm: "37px" }, right: { xs: "238px", sm: "346px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 5
								? <img width="100%" src={Growth} alt="Growth" /> :
								<img width="100%" src={step3} alt="step3" />}
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "-13px", sm: "-22px" }, left: { xs: "136px", sm: "194px" }, width: { xs: "40px", sm: "62px" } }}>
							{step > 6
								? <img width="100%" src={home} alt="moneyOpacity" /> :
								<img width="100%" src={step6} alt="moneyOpacity" />}
						</Box>
						<Box sx={{ position: "absolute", top: { xs: "142px", sm: "207px" }, right: { xs: "142px", sm: "207px" }, width: { xs: "25px", sm: "36px" }, height: { xs: "25px", sm: "36px" }, borderRadius: "100px", background: "#fff", opacity: .4 }} />
					</Box>
				</Box>
				<Box sx={{
					position: "fixed",
					bottom: "10px",
					display: { xs: "block", sm: "none" },
					width: "100%"
				}}>
					<Box onClick={() => handleStepGraph(valSlider)} ml={3} sx={{ background: "#3E809D", borderRadius: "100px", p: "15px 12px 15px 16px", width: "170px", m: "0 auto" }}>
						<Typography sx={{ fontSize: "18px", fontFamily: "Open Sans", fontWeight: 700, color: "#fff", textAlign: "center" }}>Submit</Typography>
					</Box>
				</Box>
				<Box
					onClick={() => setIsOpenShare(true)}
					ml={3}
					sx={{
						background: "#3E809D",
						borderRadius: "100px",
						p: "12px 12px 12px 16px",
						position: "fixed",
						bottom: "10px",
						right: "15px",
						display: { xs: "block", sm: "none" }
					}}
				>
					<img width="31px" src={share} alt="share" />
				</Box>

				<ReferFriendsModal
					open={isOpenShare}
					onClose={() => setIsOpenShare(false)}
				/>
			</Box>
		</ArtifactsContainer>
	);
}

export default Create;
