import { Box, BoxProps } from "@mui/material";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionFour/hooks/useMission";
import { ForwardRefExoticComponent, Fragment, Ref, forwardRef, useLayoutEffect, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { JobInformation } from "./LearnMoreAboutEachJob";

export interface IYourActionPlanProps extends BoxProps { }

function YourActionPlan({ ...props }: IYourActionPlanProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ jobsInformation, setJobsInformation ] = useState<Record<string, JobInformation>>(() =>
	{
		const store = getLocalStorage<Record<string, JobInformation>>(
			missionContext.missionPrefix + "-jobsInformation"
		);

		return store ?? {};
	});

	if (Object.keys(jobsInformation).length === 0)
	{
		missionContext.setStep(16);
	}

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(missionContext.missionPrefix + "-jobsInformation", jobsInformation);
	}, [ jobsInformation ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="YourActionPlan_title" defaultValue="Your action plan" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="YourActionPlan_subTitle" defaultValue="Excellent job, let’s review your action plan and prioritise steps you want to take to explore each job further. Feel free to add your own to the list!" position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Panel sx={{
					height: { xl: "618px", lg: "463px", xs: "463px" },
					padding: { xl: "40px 32px 32px 40px", lg: "24px", xs: "24px" },
					display: "grid",
					gridTemplateRows: "1fr auto",
					justifyItems: "center",
					gap: "20px",

					"& .customScroll":
					{
						overflowY: "auto",
						paddingRight: "24px",
						height: "100%",

						display: "flex",
						flexDirection: "column",
						gap: "16px",

						"& > span":
						{
							marginLeft: { xl: "26px", lg: "24px", xs: "24px" },
							color: "primary.main",
							font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" }
						}
					}
				}}>
					<div className="customScroll">
						{Object.entries(jobsInformation).map(([ jobName, jobInformation ], index) =>
						{
							return (
								<Fragment key={index + jobName}>
									<span>{jobName}</span>
									{jobInformation.list.map(({ checked, text }, listIndex) =>
									{
										const [ titleRaw, ...descriptionRaw ] = text.split(":");

										const description = descriptionRaw.join(":");
										const title = <b>{titleRaw}:</b>;

										return (
											<CheckboxStyledWithLabel
												key={jobName + "-list-" + listIndex}
												variant="outlined"
												label={descriptionRaw.length === 0 ? titleRaw : <>{title}{description}</>}
												checkboxProps={{
													checked,
													onChange(_event, checked)
													{
														setJobsInformation((prevState) =>
														{
															const state = deepClone(prevState);

															state[ jobName ].list[ listIndex ].checked = checked;

															return state;
														});
													}
												}}
												sx={{
													"--line-height": { xl: "30px", xs: "22px" },
													gap: { xl: "10px", lg: "8px", xs: "8px" },
													font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
													color: "primary.dark",

													"& .MuiCheckbox-root":
													{
														position: "sticky",
														top: "0px",
														left: 0,
														zIndex: 2
													}
												}}
											/>
										);
									})}
								</Fragment>
							);
						})}
					</div>

					<CustomButton.Contained
						onClick={() => missionContext.nextStep()}
						sx={{
							paddingRight: "80px !important",
							paddingLeft: "80px !important"
						}}
					>
						Submit
					</CustomButton.Contained>
				</Panel>
			</Box>
		</Box>
	);
}

export default forwardRef(YourActionPlan) as ForwardRefExoticComponent<IYourActionPlanProps>;
