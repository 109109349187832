import { Box, Fade, Skeleton, alpha } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import Dialog from "pages/missionFour/components/LifeHighsLows/Dialog";
import { JobInformation } from "pages/missionFour/components/PersonalJobAd/LearnMoreAboutEachJob";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import reactStringReplace from "utils/reactStringReplace";
import { LOCAL_STORAGE_PREFIX } from "..";

export interface ILearnMoreAboutJobProps
{
	setStep: Dispatch<SetStateAction<number>>;
	updateItems(callback?: () => void, force?: boolean): void;
}

function LearnMoreAboutJob({ setStep, updateItems }: ILearnMoreAboutJobProps)
{
	const navigate = useNavigate();

	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	const [ loadedJobsList, setLoadedJobsList ] = useState<string[]>([]);
	const [ selectedJobName, setSelectedJobName ] = useState<string | null>(null);
	const [ jobsInformation, setJobsInformation ] = useState<Record<string, JobInformation>>(() =>
	{
		return getLocalStorage<Record<string, JobInformation>>(LOCAL_STORAGE_PREFIX + "-create-jobsInformation") ?? {};
	});
	const [ selectedJobs ] = useState<string[]>(() =>
	{
		return getLocalStorage<string[]>(LOCAL_STORAGE_PREFIX + "-create-jobsList-selected") ?? [];
	});

	if (selectedJobs.length === 0)
	{
		setStep(3);
	}

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(LOCAL_STORAGE_PREFIX + "-create-jobsInformation", jobsInformation);
	}, [ jobsInformation ]);

	// Utils
	function getJobInformation(jobName: string, force = false)
	{
		if (jobsInformation[ jobName ] !== undefined || (force === false && loadedJobsList.includes(jobName) === true))
		{
			return;
		}

		setLoadedJobsList((prevState) => [ ...prevState, jobName ]);

		Api.bot
			.getResponseBot(
				`Tell me in detail about the ${ jobName } and suggest 3 steps I can take to further explore this job`,
				"description"
			)
			.then(({ descJob: result }) =>
			{
				let [ text1, text2, ...list ] = result.map((item) => item.trim());

				list = list.map((listItem) => listItem.replaceAll(/^\d+\.?\)?\s*/g, ""));

				setJobsInformation((prevState) =>
				{
					return {
						...prevState,
						[ jobName ]:
						{
							isSaved: false,
							text: `${ text1 }\n\n${ text2 }`,
							list: list.map((listItem) => ({ text: listItem, checked: false }))
						}
					};
				});
			})
			.catch((error) =>
			{
				getJobInformation(jobName, true);
				console.error(error);
			})
			.finally(() =>
			{
				setLoadedJobsList((prevState) =>
				{
					return prevState.filter((listJobName) => listJobName !== jobName);
				});
			});
	}

	function isJobsSaved()
	{
		for (const jobName of selectedJobs)
		{
			if (jobsInformation[ jobName ] === undefined)
			{
				return false;
			}

			if (jobsInformation[ jobName ].isSaved === false)
			{
				return false;
			}
		}

		return true;
	}

	// Handles
	function onSelectJob(jobName: string)
	{
		setSelectedJobName(jobName);
		getJobInformation(jobName);
	}

	function onSelectCheckbox(index: number)
	{
		if (selectedJobName === null)
		{
			return;
		}

		setJobsInformation((prevState) =>
		{
			const state = deepClone(prevState);

			state[ selectedJobName ].list[ index ].checked = !state[ selectedJobName ].list[ index ].checked;

			return state;
		});
	}

	function onClickSave()
	{
		if (selectedJobName === null)
		{
			return;
		}

		setJobsInformation((prevState) =>
		{
			const state = deepClone(prevState);

			state[ selectedJobName ].isSaved = true;

			return state;
		});

		setSelectedJobName(null);
	}

	function onClickNextStep()
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		const jobAdText = getLocalStorage<string>(LOCAL_STORAGE_PREFIX + "-create-jobAdText");
		if (jobAdText === null)
		{
			return setStep(1);
		}

		const jobList = getLocalStorage<string[]>(LOCAL_STORAGE_PREFIX + "-create-jobsList");
		if (jobList === null)
		{
			return setStep(2);
		}

		Api.missions
			.setDataMissionFour(
				{
					jobsInformation,
					jobList,
					selectedJobs,
					personalJobAdText: jobAdText
				},
				true
			)
			.then(() =>
			{
				updateItems(() =>
				{
					localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-jobAdText");
					localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-jobsList");
					localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-jobsList-selected");
					localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-jobsInformation");
					localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-step");

					navigate("/dashboard/artifacts/career");
				}, true);
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	// Render
	return (
		<>
			{isJobsSaved() === true &&
				(
					<Fade in>
						<Box
							component="button"
							disabled={isFetch === true}
							onClick={onClickNextStep}
							sx={{
								width: "40px",
								height: "40px",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: "20px",
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			<Fade in={selectedJobName === null} timeout={500}>
				<Box sx={{
					width: "980px",
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					justifySelf: "center",
					gap: "16px"
				}}>
					{selectedJobs.map((jobName) =>
					{
						return (
							<Box
								component="button"
								key={jobName}
								sx={[
									{
										padding: "10px",
										width: { xl: "233px", lg: "185px", xs: "185px" },
										height: { xl: "178px", lg: "145px", xs: "145px" },
										background: "linear-gradient(135deg, rgba(239, 239, 239, .8), rgba(239, 239, 239, .1))",
										boxShadow: "0px 5px 20px rgba(126, 196, 255, .4)",
										backdropFilter: "blur(15px)",
										border: "1px solid rgba(255, 255, 255, .6)",
										borderRadius: "24px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										cursor: "pointer",
										textAlign: "center",
										font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
										color: "primary.main",
										wordBreak: "break-word"
									},
									jobsInformation[ jobName ]?.isSaved === true &&
									{
										background: alpha("#3E809D", 0.5),
										color: "#FFFFFF"
									}
								]}
								onClick={() => onSelectJob(jobName)}
							>
								{jobName}
							</Box>
						);
					})}
				</Box>
			</Fade>

			<Dialog
				open={selectedJobName !== null}
				sx={{
					"& .MuiDialog-container":
					{
						alignItems: { xl: "unset", lg: "center", xs: "center" }
					},
					"& .MuiPaper-root":
					{
						margin: "32px",
						padding: { xl: "32px", lg: "24px", xs: "24px" },
						marginTop: { xl: "260px", lg: "32px", xs: "32px" },
						maxWidth: { xl: "980px", lg: "784px", xs: "784px" },
						maxHeight: { xl: "600px", lg: "463px", xs: "463px" },
						height: "fit-content",
						display: "grid",
						gridTemplateRows: "auto 1fr auto",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }
					}
				}}
				onClose={() => setSelectedJobName(null)}
			>
				{selectedJobName !== null && (
					<>
						<Box sx={{
							font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
							color: "primary.main"
						}}>
							{selectedJobName}
						</Box>

						<Box
							className="customScroll"
							sx={{
								overflowY: "auto",
								paddingRight: "16px",

								"& .MuiSkeleton-root":
								{
									backgroundColor: alpha("#3E809D", 0.3),
									height: "1em"
								},
								"& .MuiSkeleton-wave::after":
								{
									background: `linear-gradient( 90deg, transparent, ${ alpha("#3E809D", 0.4) }, transparent )`
								}
							}}
						>
							{jobsInformation[ selectedJobName ] === undefined &&
								<>
									<Skeleton sx={{ width: "100%" }} animation="wave" />
									<Skeleton sx={{ width: "100%" }} animation="wave" />
									<Skeleton sx={{ width: "100%" }} animation="wave" />

									<Skeleton sx={{ width: "40%", marginTop: "1em" }} animation="wave" />

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>
								</>
							}
							{jobsInformation[ selectedJobName ] !== undefined &&
								<>
									{reactStringReplace(
										jobsInformation[ selectedJobName ].text,
										/(\n)/g,
										(_math, index) => (<br key={index} />)
									)}

									<Box
										component="ul"
										sx={{
											listStyle: "none",
											margin: "unset",
											padding: "unset",
											marginTop: "16px",

											display: "flex",
											flexDirection: "column",
											gap: "16px"
										}}
									>
										{jobsInformation[ selectedJobName ].list.map(({ text, checked }, index) =>
										{
											const [ titleRaw, ...descriptionRaw ] = text.split(":");

											const description = descriptionRaw.join(":");
											const title = <b>{titleRaw}:</b>;

											return (
												<li key={index + text}>
													<CheckboxStyledWithLabel
														variant="outlined"
														label={descriptionRaw.length === 0 ? titleRaw : <>{title}{description}</>}
														checkboxProps={{
															checked,
															onChange()
															{
																onSelectCheckbox(index);
															}
														}}
														sx={{
															"--line-height": { xl: "30px", xs: "22px" },
															gap: "10px",
															font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },

															"& .MuiCheckbox-root":
															{
																position: "sticky",
																top: 0,
																left: 0,
																zIndex: 2
															}
														}}
													/>
												</li>
											);
										})}
									</Box>
								</>
							}
						</Box>

						<Box sx={{ margin: { xl: "24px auto 0 auto", lg: "8px auto 0 auto", xs: "8px auto 0 auto" } }}>
							<TooltipStyled title="Need chose minimum one checkbox" placement="bottom" arrow>
								<div>
									<CustomButton.Contained
										disabled={
											jobsInformation[ selectedJobName ] === undefined
											|| jobsInformation[ selectedJobName ].list.every(({ checked }) => checked === false)
										}
										onClick={onClickSave}
										sx={{
											paddingRight: "80px !important",
											paddingLeft: "80px !important"
										}}
									>
										I’m done!
									</CustomButton.Contained>
								</div>
							</TooltipStyled>
						</Box>
					</>
				)}
			</Dialog>
		</>
	);
}

export default LearnMoreAboutJob;
