import { Box } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import missionTenDefualt from "assets/images/missions/mission-ten/missionTenDefualt.png";
import missionTenMorning from "assets/images/missions/mission-ten/missionTenMorning.png";
import missionTenSurpriseBg from "assets/images/missions/mission-ten/missionTenSurpriseBg.png";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { ReactNode, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLocalStorage } from "utils/localStorage";
import { IMissionContextTenMission, MissionCtx } from "./MissionCtx";
import ActNow from "./feature/ActNow";
import AwwThanks from "./feature/AwwThanks";
import ChatPageCorrectCourse from "./feature/ChatPageCorrectCourse";
import ChatPageMissionTen from "./feature/ChatPageMissionTen";
import Complete from "./feature/Complete";
import ExperimentsTracker from "./feature/ExperimentsTracker";
import LifeExperiments from "./feature/LifeExperiments";
import LifeExperimentsEagle from "./feature/LifeExperimentsEagle";
import PathToDreamLife from "./feature/PathToDreamLife";
import Feedback from "./feature/feedback";
import LifeStrategyUpToDate from "./feature/lifeStrategyUpToDate";

export type BackgroundType = Record<"chatPageMissionTen" | "morningStar" | "surpriseBg", string>;

export const Background: BackgroundType =
{
	chatPageMissionTen: `url(${ missionTenDefualt }) center center/cover`,
	morningStar: `url(${ missionTenMorning }) center center/cover`,
	surpriseBg: `url(${ missionTenSurpriseBg }) center center/cover`
};

const Components = [
	ChatPageMissionTen,
	PathToDreamLife,
	// HowYouCanExperiment,
	LifeExperimentsEagle,
	LifeExperiments,
	AwwThanks,
	ExperimentsTracker,
	ChatPageCorrectCourse,
	// LifeStrategy,
	LifeStrategyUpToDate,
	ActNow,
	// Finish,
	Complete,
	Feedback
];

const STEPS = 5;

export const PROGRESS_STEP = 100 / STEPS;
function MissionTenContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ showContent, setShowContent ] = useState<boolean>(true);
	const [ step, setStep ] = useState<number>(() =>
	{
		const storageStep = localStorage.getItem("mission-ten-step");
		if (storageStep !== null)
		{
			return Number(storageStep);
		}

		return 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("chatPageMissionTen");
	const [ name, setName ] = useState<string>("");
	const [ firstPlaythrough, setFirstPlaythrough ] = useState<boolean>(false);
	const [ showMissionInfo, setShowMissionInfo ] = useState<null | 2 | 3 | 4 | 5 | 6 | 7>(null);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: ReactNode; position?: "bottom" | "center"; } | null>(null);

	const MissionTenContext: IMissionContextTenMission = useMemo(() =>
	{
		return {
			step,
			setStep,
			progress,
			setProgress,
			background,
			setBackground,
			name,
			setName,
			setShowMissionInfo,
			showContent,
			setShowContent,
			firstPlaythrough,
			setFirstPlaythrough
		};
	}, [ background, name, progress, showContent, step ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		if (userContext.state.user === null)
		{
			return;
		}

		Api.users
			.getUser(userContext.state.user.id)
			.then((user) =>
			{
				setName(user.name);
			});
	}, [ userContext.state.user, userContext.state.user?.id ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(10, step).catch((error) => console.error(error));
		setLocalStorage("mission-ten-step", step);
		setProgress((step - 1) * (100 / (Components.length - 1)));
	}, [ step ]);

	useLayoutEffect(() =>
	{
		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 3: { // screen 3 ( 4. Let’s design your life experiments! )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionTen"
									keyText="tips_step_4"
									defaultValue="**Why:** In this exercise you will think of different ways for how you can run experiments on your unknowns so that you can reduce uncertainty and gather insights about each one of your ideas.\n\n**Do:** Define your unknowns and brainstorm up to 3 possible ways for each experiment for how you can act now and reflect later.\n\n**Think:** Keep in mind the amount of risk you are willing to take for each experiment. It might be different for different areas of your life. For example, you might be a lot more open to taking risks when it comes to your career and be very risk-averse around your relationships."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 4: { // screen 4 ( 4. Let’s design your life experiments! - eagles )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionTen"
									keyText="tips_step_4"
									defaultValue="**Why:** In this exercise you will think of different ways for how you can run experiments on your unknowns so that you can reduce uncertainty and gather insights about each one of your ideas.\n\n**Do:** Define your unknowns and brainstorm up to 3 possible ways for each experiment for how you can act now and reflect later.\n\n**Think:** Keep in mind the amount of risk you are willing to take for each experiment. It might be different for different areas of your life. For example, you might be a lot more open to taking risks when it comes to your career and be very risk-averse around your relationships."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 6: { // screen 6 ( 6. Here is your experiments tracker )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionTen"
									keyText="tips_step_4"
									defaultValue="**Why:** In this exercise you will think of different ways for how you can run experiments on your unknowns so that you can reduce uncertainty and gather insights about each one of your ideas.\n\n**Do:** Define your unknowns and brainstorm up to 3 possible ways for each experiment for how you can act now and reflect later.\n\n**Think:** Keep in mind the amount of risk you are willing to take for each experiment. It might be different for different areas of your life. For example, you might be a lot more open to taking risks when it comes to your career and be very risk-averse around your relationships."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 10: { // screen 8 ( 8. Keep you life strategy up to date)
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionTen"
									keyText="tips_step_4"
									defaultValue="**Why:** In this exercise you will think of different ways for how you can run experiments on your unknowns so that you can reduce uncertainty and gather insights about each one of your ideas.\n\n**Do:** Define your unknowns and brainstorm up to 3 possible ways for each experiment for how you can act now and reflect later.\n\n**Think:** Keep in mind the amount of risk you are willing to take for each experiment. It might be different for different areas of your life. For example, you might be a lot more open to taking risks when it comes to your career and be very risk-averse around your relationships."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}
	}, [ step ]);
	useEffect(() =>
	{
		localStorage.setItem("mission-ten-progress", String(progress));
	}, [ progress ]);

	useLayoutEffect(() =>
	{
		const userId = localStorage.getItem("userId");

		if (userId === null)
		{
			return;
		}

		Api.users
			.getUser(userId)
			.then((user) =>
			{
				setName(user.name);
			});
	}, []);

	// Handles
	return (
		<>
			<Box
				sx={{
					width: "100%",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					zIndex: "10",
					transition: "linear 300ms background",
					overflow: "hidden"
				}}
			>
				<Box sx={{
					minHeight: "100vh",
					width: "100%",
					padding: { xl: step === 11 || step === 10 ? 0 : "24px", lg: step === 11 || step === 10 ? 0 : "16px", xs: step === 11 || step === 10 ? 0 : "16px" },
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: ((step === 1 || step === 2) ? "20px" : "0px")
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 11 || step === 10 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							position: "relative",
							display: step === 11 || step === 10 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "10px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px",
							zIndex: 10
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={Components.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {Components.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
							<Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
								{(step === 4 || step === 8) &&
									<TooltipStyled title="Tips" placement="bottom" arrow>
										<TipsButton
											isActive={isBlipTips}
											blip={false}
											onClick={() =>
											{
												setIsShowTips(true);
												setIsBlipTips(false);
												setShowContent(false);
											}}
										/>
									</TooltipStyled>
								}
								<MissionProgressBar value={progress} />
							</Box>
						</Box>
					</Box>

					<MissionCtx.Provider value={MissionTenContext}>
						<Box
							className="mission-root"
							sx={{
								flexGrow: 1,
								width: "100%",
								position: "relative",
								top: step === 11 ? 0 : "-10px",
								left: step === 11 ? 0 : "-9px",

								"& .TransitionGroup":
								{
									flexGrow: 1,
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									width: "100%"
								},
								"& .mission-main":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								},
								"& .main__content":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									gap: "20px"
								}
							}}
						>
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column"
							}}>
								{Components.map((Component, index) =>
								{
									if (step !== index + 1)
									{
										return false;
									}

									return <Component key={index} />;
								})}
							</Box>
						</Box>

						{/* Tips content */}
						{tipsData !== null &&
							(
								<MissionsDialog
									sx={{
										marginTop: "100px",
										"& .MuiPaper-root": {
											color: "#4D4D4D",
											background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
											backdropFilter: "blur(25px)",
											backgroundColor: "transparent",
											maxWidth: "980px",
											maxHeight: "500px",
											padding: "56px",
											top: "80px"
										},
										"& .MuiDialog-container": {
											position: "relative",
											top: (step === 3 ? "-62px" : "0px"),
											left: "0px",
											right: "auto"
										}
									}}
									sxCancelIcon={{
										fill: "#3E809D",
										opacity: "1",
										top: "32px",
										right: "32px"
									}}
									title={tipsData.title}
									open={isShowTips}
									onClose={() => { setIsShowTips(false); setShowContent(true); }}
									position={tipsData.position ?? "center"}

								>
									{tipsData.content}
								</MissionsDialog>
							)
						}

					</MissionCtx.Provider>
				</Box>
			</Box>
			<MobileContent />
		</>
	);
}

export default MissionTenContainer;
