// import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CardContent, CardMedia } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiPaper-root": {
		maxWidth: "980px",
		maxHeight: "595px",
		borderRadius: 24,
		background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
		backdropFilter: "blur(45px)",
		boxShadow: "0px 5px 10px 0px #7EC4FF66",
		position: "relative",
		top: "45px",
		marginLeft: "34px",
		border: "1px solid rgba(255, 255, 255, 0.5)"
	},

	"& .MuiDialogContent-root": {
		padding: theme.spacing(0),
		display: "flex",
		flexDirection: "row",
		position: "relative"
	},
	"& .MuiCardContent-root": {
		padding: "32px 56px 32px 65px",
		display: "flex",
		flexDirection: "column"
	},
	"& .MuiDialogTitle-root": {
		marginBottom: "11px",
		color: "#215C75",
		fontFamily: "Lora-Bold",
		fontSize: "40px",
		fontWeight: "700",
		lineHeight: "51px",
		textAlign: "left",
		padding: "0px 40px 0 0"
	},
	"& .MuiIconButton-root": {
		width: "25px",
		height: "25px",
		position: "absolute",
		top: "30px",
		right: "30px",
		backgroundColor: "#3E809D",
		"&:hover": {
			backgroundColor: "#3E809D"
		}
	},
	"& .MuiIconButton-root svg": {
		fill: "#fff",
		width: "15px",
		height: "15px"
	},
	"& .MuiDialog-container": {
		alignItems: "center",
		paddingBottom: "15px",
		marginTop: "100px"
	},
	"& .MuiModal-backdrop": {
		backgroundColor: "rgba(0, 0, 0, 0.1)"
	}
}));

function BootstrapDialogTitle(props: any)
{
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle {...other} sx={{ minHeight: { lg: "0px", xl: "102px" } }}>
			{children}
			{onClose
				? (
					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				)
				: null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
};

export default function ModalWindow({ open, handleClose, project, handleChangeBallsValues }: any)
{
	const nodeRef = useRef(null);

	function handleCloseCart()
	{
		handleClose();
		handleChangeBallsValues();
	}

	return (
		<CSSTransition in={open} nodeRef={nodeRef} timeout={300}>
			<BootstrapDialog
				ref={nodeRef}
				onClose={handleClose}
				open={open}
				sx={{
					"& .MuiPaper-root": {
						maxWidth: { lg: "789px", xl: "980px" },
						maxHeight: { lg: "520px", xl: "595px" },
						top: { lg: "-50px", xl: "40px" }
					},
					"& .MuiDialogTitle-root": {
						fontSize: { lg: "24px", xl: "40px" }
					},
					"& .MuiCardContent-root": {
						p: { lg: "24px 30px", xl: "32px 56px 32px 65px" }
					}
				}}>
				<DialogContent dividers>
					<CardMedia
						component="img"
						image={project.src}
						alt="image"
						sx={{ width: { lg: "154px", xl: "220px" }, height: "100%" }}
					/>
					<CardContent>
						<BootstrapDialogTitle onClose={handleCloseCart}>
							{project.title}
						</BootstrapDialogTitle>
						<Typography sx={{
							marginBottom: "30px",
							color: "#4D4D4D",
							fontFamily: "Open Sans",
							fontSize: { lg: "16px", xl: "22px" },
							fontWeight: "400",
							lineHeight: { lg: "20px", xl: "30px" },
							textAlign: "left",
							overflowWrap: "anywhere"
						}}>
							{project.description}
						</Typography>
						{project.description1
							? (
								<Typography
									sx={{
										marginBottom: "30px",
										color: "#4D4D4D",
										fontFamily: "Open Sans",
										fontSize: { lg: "16px", xl: "22px" },
										fontWeight: "400",
										lineHeight: { lg: "20px", xl: "30px" },
										textAlign: "left",
										overflowWrap: "anywhere"
									}}>
									{project.description1}
								</Typography>
							)
							: null
						}
						<Typography sx={{
							display: "flex",
							marginTop: "auto",
							color: "#4D4D4D",
							fontFamily: "Open Sans",
							fontSize: { lg: "16px", xl: "22px" },
							fontWeight: "400",
							fontStyle: "italic",
							lineHeight: { lg: "20px", xl: "30px" },
							paddingRight: "20px",
							overflowWrap: "anywhere"
						}}>
							{project.quote}
						</Typography>
					</CardContent>
				</DialogContent>
			</BootstrapDialog>
		</CSSTransition>
	);
}
