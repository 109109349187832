import { Box } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import useMission from "pages/missionTwo/hooks/useMission";
import { ChangeEvent, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import { LifePeaks, filledForm } from ".";
import type { ListOfValuesData } from "../ListOfValues";

export interface ILifePeaksFormProps
{
	readonly index: 0 | 1 | 2;
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

const MAX_LENGTH_INPUT: number = 30;
const MAX_LENGTH_TEXTAREA: number = 300;

function LifePeaksForm({ index, preview = false }: ILifePeaksFormProps)
{
	const missionContext = useMission();
	const [ lifePeaks, setLifePeaks ] = useLocalStorage<LifePeaks[]>(missionContext.missionPrefix + "-lifePeaks", []);
	const [ _newListOfValues, setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Handles
	function onChange(field: 0 | 1)
	{
		return ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		{
			setLifePeaks((prevState) =>
			{
				const lifePeaks = [ ...prevState ];

				lifePeaks[ index ] ??= [ "", "" ];
				lifePeaks[ index ]![ field ] = target.value.replaceAll(/\s{2,}/g, " ");

				return lifePeaks;
			});
		};
	}

	function getBot()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					lifePeaks
						.filter((item) => item !== undefined)
						.map((item) =>
						{
							return "Define in max 5 nouns, with no additional text, the personal values of this person based on their description of their peak life moment " + item![ 1 ];
						}),
					"personBased"
				)
				.then((values) =>
				{
					console.log("values", values);
					const newValues =
						Array.from(
							new Set(
								values
									.map(({ personBased }) => personBased)
									.flat()
									.map((value) => value.toLowerCase())
							)
						).filter((value) => listOfValues.includes(value) === false);

					console.log("newValues", newValues);
					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 6) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
					setTimeout(() => missionContext.setExtraStep(null), 0);

				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	function nextStep()
	{
		if (preview === true)
		{
			missionContext.setExtraStep(null);
		}
		else
			if (lifePeaks.length === 3)
			{
				getBot();
			}
			else
				missionContext.setExtraStep(null);
	}

	// Render
	return (
		<Panel sx={{
			marginTop: "70px",
			padding: "24px 24px 24px 32px"
		}}>
			<Box sx={{
				paddingLeft: "16px",
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "primary.main"
			}}>
				<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="lifePeaks_fieldTitle1"
					defaultValue="What are your peak?"
					position="right"
				/>
			</Box>

			<CustomInput.Base
				readOnly={preview}
				placeholder="e.g. backpacking in Asia during my gap year"
				value={lifePeaks[ index ]?.[ 0 ] ?? ""}
				onChange={onChange(0)}
				inputProps={{ maxLength: MAX_LENGTH_INPUT }}
				sx={{
					marginTop: "16px",
					width: "100%"
				}}
			/>

			<Box sx={{
				padding: "0 16px",
				width: "100%",

				font: "400 14px/16px 'Open Sans'",
				color: "primary.dark",
				textAlign: "right",
				opacity: 0.5
			}}>
				{lifePeaks[ index ]?.[ 0 ]?.trim()?.length ?? 0}/{MAX_LENGTH_INPUT} characters
			</Box>

			<Box sx={{
				marginTop: "8px",
				paddingLeft: "16px",
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "primary.main"
			}}>
				<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="lifePeaks_fieldTitle2"
					defaultValue="Why was it special?"
					position="right"
				/>
			</Box>

			<CustomInput.Base
				readOnly={preview}
				multiline
				rows={4}
				value={lifePeaks[ index ]?.[ 1 ] ?? ""}
				onChange={onChange(1)}
				inputProps={{ maxLength: MAX_LENGTH_TEXTAREA }}
				sx={{
					marginTop: "16px",
					width: "100%"
				}}
			/>

			<Box sx={{
				padding: "0 16px",
				width: "100%",

				font: "400 14px/16px 'Open Sans'",
				color: "primary.dark",
				textAlign: "right",
				opacity: 0.5
			}}>
				{lifePeaks[ index ]?.[ 1 ]?.trim()?.length ?? 0}/{MAX_LENGTH_TEXTAREA} characters
			</Box>

			<CustomButton.Contained
				disabled={preview === false && filledForm(lifePeaks, index) === false}
				onClick={nextStep}
				loading={loading}
				sx={{
					margin: "8px auto 0 auto",
					display: "flex"
				}}
			>
				{preview === true ? "Back" : "Submit"}
			</CustomButton.Contained>
		</Panel>
	);
}

export default LifePeaksForm;
