import { Box } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGLogo } from "assets/images/logo.svg";
import IMGOcta from "assets/images/meditate.png";
import { useState } from "react";
import CustomButton from "../customButton";

function MobileContent()
{
	const [ sendEmail, setSendEmail ] = useState<boolean>(false);
	const [ loadingSendEmail, setLoadingSendEmail ] = useState<boolean>(false);

	// Handles
	function sendLinkToEmail()
	{
		if (loadingSendEmail === true)
		{
			return;
		}

		setLoadingSendEmail(true);

		Api.users.sendLinkToUser()
			.then(() =>
			{
				setSendEmail(true);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => setLoadingSendEmail(false));
	}

	// Render
	return (
		<Box sx={{ display: { xs: "block", sm: "none" } }}>
			<Box sx={{
				padding: "24px 32px 32px 32px",
				minHeight: "100vh",
				background: "linear-gradient(35deg, #7889B0  0%, #7FA9D0 75%, #CACFCC 100%)",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "24px",
				textAlign: "center",

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				},
				"& svg":
				{
					fill: "#FFFFFF",
					width: "58px",
					height: "40px"
				}
			}}>
				<SVGLogo />

				{sendEmail === true
					? (
						<Box sx={{ margin: "auto 0", font: "400 18px/25px 'Open Sans'", color: "#FFFFFF" }}>
							We have emailed you the link. Go to your desktop and click the
							link in the email to continue your journey.
						</Box>
					)
					: (
						<>
							<Box
								component="h1"
								sx={{
									margin: "unset",
									font: "700 24px/31px Lora-Bold",
									color: "#FFFFFF"
								}}
							>
								Welcome to My Life Quest
							</Box>

							<Box sx={{ marginTop: "auto", font: "400 18px/25px 'Open Sans'", color: "#FFFFFF" }}>
								We want you to take your time to think about your life deeply. That's why we made this app
								available on desktop only. To continue, click below to get desktop login link in your inbox
							</Box>

							<img src={IMGOcta} alt="" style={{ display: "block", width: "208px", margin: "auto" }} />

							<CustomButton.Base
								onClick={() => sendLinkToEmail()}
								loading={loadingSendEmail}
								sx={{
									marginBottom: "auto",
									width: "100%"
								}}
							>
								Get the Link
							</CustomButton.Base>
						</>
					)
				}
			</Box>
		</Box>
	);
}

export default MobileContent;
