import { Box, Button, ButtonProps, styled } from "@mui/material";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useState } from "react";
import { ReactComponent as SVGTipsOff } from "./tips-off.svg";
import { ReactComponent as SVGTipsOn } from "./tips-on.svg";

const ButtonStyled = styled(Button)(({ disabled }) => (
	{
		minWidth: "unset",
		border: "2px solid #FFFFFF",
		borderRadius: "50%",
		background: "linear-gradient(to top, rgba(239, 239, 239, .6) 5%, rgba(239, 239, 239, .1))",
		boxShadow: "3px 3px 10px rgba(28, 130, 170, .66)",
		backdropFilter: "blur(15px)",
		opacity: (disabled === true ? ".5" : "1")
	}
)) as typeof Button;

interface IArrowButtonProps extends ButtonProps
{
	isActive?: boolean;
	blip?: boolean;
	offBlipOnClick?: boolean;
}

function TipsButton(
	{
		isActive = true,
		blip = true,
		offBlipOnClick = false,
		onClick,
		className = "",
		sx = [],
		...props
	}: IArrowButtonProps,
	ref: Ref<HTMLButtonElement>
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (isBlip === true)
	{
		classList.push("animation--blip-contrast");
	}

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<ButtonStyled
			className={"TipsButton-root " + classList.join(" ")}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={[
				{
					"--size": { xl: "80px", lg: "56px", xs: "80px" },
					width: "var(--size)",
					height: "var(--size)"
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			ref={ref}
			{...props}
		>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<Box component={isActive === true ? SVGTipsOn : SVGTipsOff} sx={{ width: "calc(var(--size) / 1.25)" }} />
			</Box>
		</ButtonStyled>
	);
}

export default forwardRef(TipsButton) as ForwardRefExoticComponent<IArrowButtonProps>;
