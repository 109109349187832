import { alpha, Box, SxProps, Theme } from "@mui/material";
import { createRef, forwardRef, KeyboardEvent, Ref, useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import CustomInput from "../../../../components/CustomInput";
import PanelTitle from "../../../../components/missions/PanelTitle";
import deepClone from "../../../../utils/deepClone";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import randomInt from "../../../../utils/randomInt";
import IMGBubble from "../../assets/images/bubble.png";
import useMission from "../../hooks/useMission";
import { ReactComponent as SVGBoxCenter } from "./assets/box-center.svg";
import { ReactComponent as SVGBoxSide } from "./assets/box-side.svg";
import * as SVGIcons from "./assets/icons";
import ImageButton from "./components/ImageButton";
import { FamilySelectType } from "./Select";

export type IInputsProps = Record<string, any>;

type BubbleType = [ size: number, position: SxProps<Theme> ];

const UPDATE_POSITION_TIMER: number = 4000;
const INPUT_MAX_LEN: number = 30;

function WhatAreYourFamilyBeliefsInputs({ ...props }: IInputsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const bubblesBoxRef = useRef<HTMLDivElement>(null);
	const boxRef = useRef<HTMLDivElement>(null);

	const [ inputText, setInputText ] = useState<string>("");
	const [ selectedFamily, setSelectedFamily ] = useState<number | null>(null);
	const [ ballonPosition, setBallonPosition ] = useState({ x: 0, direction: "center" });
	const [ saidStore, setSaidStore ] = useState<string[]>(getLocalStorage<string[]>("mission-seven-family-said-store") || []);
	const [ bubbles, setBubbles ] = useState<BubbleType[]>([]);
	const [ familyList ] = useState<string[]>(() =>
	{
		const family =
			Object
				.entries(getLocalStorage<FamilySelectType>("mission-seven-family-select") || {})
				.filter(([ , selected ]: [ string, boolean ]) => selected === true)
				.map(([ family ]) => family);

		if (family.length === 0)
		{
			missionContext.setStep(7);

			return [];
		}

		return family;
	});

	const familyButtonRef =
		familyList.map(() => createRef<HTMLButtonElement>());

	// Utils
	function familyItemCount()
	{
		return Object.keys(familyList).length;
	}

	function createBubble(): BubbleType
	{
		const size = randomInt(75, 90);

		const { width, height } = bubblesBoxRef.current!.getBoundingClientRect();

		return [
			size,
			{
				left: randomInt(0, width - size) + "px",
				top: randomInt(0, height - size) + "px"
			}
		];
	}

	// Effects
	// Animate bubble
	useLayoutEffect(() =>
	{
		if (saidStore.length <= bubbles.length)
		{
			return;
		}

		setBubbles((prevState) =>
		{
			let bubbles: BubbleType[] = deepClone(prevState);

			for (let i = bubbles.length; bubbles.length < saidStore.length; i++)
			{
				bubbles.push(createBubble());
			}

			return bubbles;
		});

	}, [ saidStore.length, bubbles.length ]);

	useEffect(() =>
	{
		const timerId = setInterval(animateBubbles, UPDATE_POSITION_TIMER - 100);

		animateBubbles();

		function animateBubbles()
		{
			if (bubblesBoxRef.current === null)
			{
				return;
			}

			const { width, height } = bubblesBoxRef.current.getBoundingClientRect();

			setBubbles((prevState) =>
			{
				let bubbles: BubbleType[] = deepClone(prevState);

				bubbles = bubbles.map((item) =>
				{
					item[ 1 ] =
					{
						left: randomInt(0, width - item[ 0 ]) + "px",
						top: randomInt(0, height - item[ 0 ]) + "px"
					};

					return item;
				});

				return bubbles;
			});
		}

		return () => clearInterval(timerId);

	}, [ bubbles.length ]);

	// Save said store
	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-family-said-store", saidStore);
	}, [ saidStore ]);

	// Set ballon position
	useLayoutEffect(() =>
	{
		if (selectedFamily === null)
		{
			return;
		}

		const element = familyButtonRef[ selectedFamily ].current;

		if (element === null || element === undefined || boxRef.current === null)
		{
			return;
		}

		const { left: boxLeft, width: boxWidth } = boxRef.current.getBoundingClientRect();
		const { left: itemLeft, right: itemRight, width: itemWidth } = element.getBoundingClientRect();

		const itemLeftOffset = itemLeft - boxLeft;
		const itemRightOffset = (boxLeft + boxWidth) - itemRight;
		const boxCenter = boxWidth / 2;

		let itemDirection: "left" | "right" | "center" = "center";
		let x: number = 0;

		const gapOffset = familyItemCount() <= 5 ? 50 : 20;

		if (itemLeftOffset > boxCenter - itemWidth - gapOffset && itemRightOffset > boxCenter - itemWidth - gapOffset)
		{
			itemDirection = "center";

			x = boxCenter - Math.min(itemLeftOffset, itemRightOffset);

			x -= itemWidth / 2;

			if (itemLeftOffset < itemRightOffset)
			{
				x *= -1;
			}
		}
		else if (itemLeftOffset < boxCenter)
		{
			itemDirection = "left";
			x = itemLeftOffset + (itemWidth / 2 - 30);
		}
		else if (itemLeftOffset > boxCenter)
		{
			itemDirection = "right";
			x = itemRightOffset + (itemWidth / 2 - 30);
		}

		setBallonPosition((prevState) =>
		{
			return Object.assign(
				{},
				prevState,
				{ x, direction: itemDirection }
			);
		});

	}, [ selectedFamily ]); // eslint-disable-line

	// Close ballon
	useLayoutEffect(() =>
	{
		function onClick(event: MouseEvent)
		{
			const target = event.target as HTMLElement | null;

			if (selectedFamily === null || target === null)
			{
				return;
			}

			if (target.closest(".ballon-root") || target.closest(".ImageButton-root"))
			{
				return;
			}

			setInputText("");
			setSelectedFamily(null);
		}

		document.addEventListener("click", onClick);

		return () => document.removeEventListener("click", onClick);

	}, [ selectedFamily ]);

	// Handles
	function onKeyDownInput({ key }: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		if (key !== "Enter")
		{
			return;
		}

		setSaidStore((prevState) => [ ...prevState, inputText ]);

		setInputText("");
		setSelectedFamily(null);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefs_title" defaultValue="What are your family beliefs?" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefs_subTitle" defaultValue="Write down what your hear from your family a lot.\nEach family member can have more than one belief." position="left" isDescription />}
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				{/* Bubbles */}
				<Box sx={{ position: "relative", width: "100%" }}>
					<Box
						ref={bubblesBoxRef}
						sx={{
							width: "80%",
							height: "450px",
							position: "absolute",
							pointerEvents: "none",
							zIndex: 2,
							"& > img":
							{
								transition: `linear ${ UPDATE_POSITION_TIMER }ms left, linear ${ UPDATE_POSITION_TIMER }ms top, linear 300ms opacity`,
								zIndex: 2
							}
						}}
					>
						{bubbles.map(([ size, position ], index) =>
						{
							return (
								<Box
									key={index}
									component="img"
									src={IMGBubble}
									alt={"Bubble " + index}
									sx={{
										"--size": { xl: size + "px", lg: size * 0.9 + "px", xs: size * 0.9 + "px" },
										position: "absolute",
										maxWidth: "var(--size)",
										maxHeight: "var(--size)",
										...position
									}}
								/>
							);
						})}
					</Box>
				</Box>

				{/* Ballon */}
				{selectedFamily !== null &&
					(
						<Box sx={{ position: "relative", width: "100%", margin: "auto 0 94px 0", zIndex: "10" }}>
							<Box
								className="ballon-root"
								sx={[
									{
										width: { xl: "768px", lg: "694px", xs: "694px" },
										padding: "24px",
										display: "flex",
										alignItems: "center",
										gap: "15px",
										borderRadius: { xl: "24px", lg: "20px", xs: "20px" },
										position: "absolute",
										// transition: "linear 300ms left",
										zIndex: "2"
									},
									ballonPosition.direction === "left" &&
									{
										left: ballonPosition.x + "px"
									},
									ballonPosition.direction === "right" &&
									{
										left: `calc(100% - ${ ballonPosition.x }px)`,
										transform: "translateX(-100%)"
									},
									ballonPosition.direction === "center" && {
										left: `calc(50% + ${ ballonPosition.x }px)`,
										transform: "translateX(-50%)"
									}
								]}
							>
								<Box
									component={ballonPosition.direction === "center" ? SVGBoxCenter : SVGBoxSide}
									sx={[
										{
											position: "absolute",
											top: "-15px",
											left: "-15px",
											width: "104%",
											zIndex: "-1"
										},
										ballonPosition.direction === "right" &&
										{
											transform: "scaleX(-1)"
										}
									]}
								/>

								<Box sx={{
									font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
									color: "primary.main"
								}}>
									{familyList[ selectedFamily ]} often said
								</Box>

								<Box component="label" sx={{ flexGrow: 1, position: "relative" }}>
									<CustomInput.Base
										fullWidth
										placeholder="e.g. You should have a proper job"
										inputProps={{ maxLength: INPUT_MAX_LEN }}
										value={inputText}
										onChange={({ target }) => setInputText(target.value)}
										onKeyDown={onKeyDownInput}
										sx={{
											width: "100%",
											height: { xl: "56px", lg: "48px", xs: "48px" },
											backgroundColor: alpha("#FFFFFF", 0.5)
										}}
									/>
									<Box sx={{
										position: "absolute",
										right: "16px",
										bottom: "-18px",
										font: "400 14px/19px 'Open Sans'",
										color: "primary.dark"
									}}>
										<div style={{ opacity: 0.5 }}>{inputText.length}/{INPUT_MAX_LEN} characters</div>
									</Box>
								</Box>
							</Box>
						</Box>
					)
				}

				<Box
					ref={boxRef}
					sx={{
						marginTop: "auto",
						marginBottom: (familyItemCount() <= 5 ? "55px" : "100px"),
						width: "100%",
						height: (familyItemCount() <= 5 ? "220px" : "198.67px"),
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: (familyItemCount() <= 5 ? "50px" : "10px"),
						position: "relative",
						zIndex: 10,

						"& > :nth-of-type(even)":
						{
							alignSelf: "flex-end"
						}
					}}
				>
					{familyList.map((text, index) => (
						<ImageButton
							ref={familyButtonRef[ index ]}
							key={text}
							SVGImage={SVGIcons[ text as keyof FamilySelectType ]}
							text={text}
							isSelected
							size={familyItemCount() <= 5 ? "normal" : "small"}
							onClick={() => { setSelectedFamily(index); setInputText(""); }}
							sx={{
								"--size": "98px"
							}}
						/>
					))}
				</Box>

				<CustomButton.Contained
					sx={{
						marginBottom: "20px",
						paddingLeft: "80px !important",
						paddingRight: "80px !important"
					}}
					onClick={() => { missionContext.nextStep(); }}
					disabled={new Set(saidStore).size < 1}
				>
					Submit
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default forwardRef(WhatAreYourFamilyBeliefsInputs);
