import { Box, alpha } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import CustomInput from "components/CustomInput";
import FlippingCard from "components/FlippingCard";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { useFormik } from "formik";
import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import deepClone from "utils/deepClone";
import * as yup from "yup";
import ArtifactsContainer from "../components/ArtifactsContainer";
import IMGBackground from "./assets/images/background.png";

function Belief()
{
	const navigate = useNavigate();

	const isFetchRef = useRef<boolean>(false);
	const [ isSubmitFetch, setIsSubmitFetch ] = useState<boolean>(false);

	const [ missionData, setMissionData ] = useState<Record<"top" | "rewriteTop", string[]> | null>(null);
	const [ missionId, setMissionId ] = useState<string | null>(null);

	const formik = useFormik({
		initialValues: { rewriteValue: "", topValue: "" },
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: yup.object(
			{
				// eslint-disable-next-line no-template-curly-in-string
				rewriteValue: yup.string().trim().required()
					.max(70, "Maximum ${max}"),
				// eslint-disable-next-line no-template-curly-in-string
				topValue: yup.string().trim().required()
					.max(70, "Maximum ${max}")
			}
		),
		onSubmit({ rewriteValue, topValue }, { resetForm })
		{
			if (isSubmitFetch === true || missionId === null)
			{
				return;
			}

			setIsSubmitFetch(true);

			const data = deepClone(missionData as Record<"top" | "rewriteTop", string[]>);

			data.rewriteTop.push(rewriteValue.trim());
			data.top.push(topValue.trim());

			Api.missions
				.editDataMissionSeven(missionId, data)
				.then(() =>
				{
					setMissionData(data);
					resetForm();
				})
				.catch((error) => console.error(error))
				.finally(() => setIsSubmitFetch(false));
		}
	});

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchRef.current === true)
		{
			return;
		}

		isFetchRef.current = true;

		Api.missions
			.getDataMissionSeven()
			.then(({ data: { id, mission_data } }) =>
			{
				setMissionId(id);

				if (Array.isArray(mission_data) === true || mission_data.top === undefined || mission_data.rewriteTop === undefined)
				{
					mission_data = { top: [], rewriteTop: [] };
				}

				setMissionData(mission_data);
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetchRef.current = false));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Render
	return (
		<ArtifactsContainer
			title="My Empowering Beliefs"
			backgroundImage={IMGBackground}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-main":
				{
					gridTemplateRows: "auto 1fr auto"
				}
			}}
		>
			<Box
				className="customScroll"
				sx={{
					"&::-webkit-scrollbar":
					{
						backgroundColor: alpha("#FFFFFF", 0.5)
					},
					"&::-webkit-scrollbar-thumb":
					{
						backgroundColor: "#FFFFFF"
					},

					height: "100%",
					overflowY: "auto",
					paddingRight: "40px",
					marginRight: "8px",

					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr 1fr",
					alignContent: "flex-start",
					gap: { xl: "24px 40px", lg: "20px 28px", xs: "20px 28px" },

					"& .FlippingCard-root":
					{
						height: { xl: "143px", lg: "116px", xs: "116px" }
					},
					"& .FlippingCard-card > *":
					{
						backgroundColor: "#E9EDF0",
						boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
						border: "1px solid #E9EDF0",
						borderRadius: "24px",

						padding: { xl: "5px 38px", lg: "5px 16px", xs: "5px 16px" },

						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignContent: "center",
						textAlign: "center",
						verticalAlign: "center",

						font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						color: "primary.main",
						wordBreak: "break-word"
					},
					"& .FlippingCard-card :nth-of-type(2)":
					{
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" },
						fontStyle: "italic",
						color: alpha("#4D4D4D", 0.5)
					}
				}}
			>
				{missionData?.rewriteTop.map((rewriteItem, index) =>
				{
					return (
						<FlippingCard key={index}>
							<div>
								{rewriteItem}
							</div>
							<div>
								{missionData.top[ index ]}
							</div>
						</FlippingCard>
					);
				})}
			</Box>

			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				sx={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: { xl: "32px 40px", lg: "24px", xs: "24px" },
					justifyContent: "center",
					marginRight: "56px",

					"& .MuiInputBase-root":
					{
						width: { xl: "478px", lg: "100%", xs: "100%" },
						backgroundColor: alpha("#FFFFFF", 0.9)
					},
					"& .MuiInputBase-input":
					{
						padding: "12px 16px",
						height: { xl: "72px !important", lg: "48px !important", xs: "48px !important" }
					}
				}}
			>
				{/* Top Value */}
				<CustomInput.Base
					placeholder="Limiting belief"
					multiline
					rows={2}
					inputProps={{ maxLength: 70 }}
					name="topValue"
					value={formik.values.topValue}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					sx={{
						justifySelf: "flex-end",
						display: { xl: "block", lg: "none", xs: "none" }
					}}
				/>

				<CustomInput.Base
					placeholder="Limiting belief"
					inputProps={{ maxLength: 70 }}
					name="topValue"
					value={formik.values.topValue}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					sx={{
						justifySelf: "flex-end",
						display: { xl: "none", lg: "block", xs: "block" }
					}}
				/>

				{/* Rewrite value */}
				<CustomInput.Base
					className="customScroll"
					placeholder="New belief"
					multiline
					rows={2}
					inputProps={{ maxLength: 70 }}
					name="rewriteValue"
					value={formik.values.rewriteValue}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					sx={{
						display: { xl: "block", lg: "none", xs: "none" }
					}}
				/>

				<CustomInput.Base
					className="customScroll"
					placeholder="New belief"
					inputProps={{ maxLength: 70 }}
					name="rewriteValue"
					value={formik.values.rewriteValue}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					sx={{
						display: { xl: "none", lg: "block", xs: "block" }
					}}
				/>

				<CustomButton.Contained
					type="submit"
					loading={isSubmitFetch === true}
					disabled={!(formik.dirty && formik.isValid)}
					sx={{
						justifySelf: "center",
						gridColumn: "2 span",
						width: "216px",
						borderRadius: "40px",

						"&:disabled":
						{
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
							backdropFilter: "blur(30px)",
							border: "unset",
							color: "#FFFFFF"
						}
					}}
				>
					Save
				</CustomButton.Contained>
			</Box>
		</ArtifactsContainer>
	);
}

export default Belief;
