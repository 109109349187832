import { Box, Fade } from "@mui/material";
import IMGGuideOcta from "assets/images/guideChara-shrink.png";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import useRegistration from "pages/registration/hooks/useRegistration";
import { KeyboardEvent, useState } from "react";
import trackEvent from "utils/mixpanelUtil";
import { ValidationError } from "yup";

function Name()
{
	const { setStep, name, setName } = useRegistration();
	const [ error, setError ] = useState<string | null>(null);

	// Handles
	function submitName()
	{
		let error: string | null = null;

		try
		{
			config.name.yup().validateSync(name.trim());
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		if (error !== null)
		{
			return setError(error);
		}

		trackEvent({
			eventName: "Name Submitted",
			properties: { Page: "Registration", "User Name": name }
		});

		setStep((prevState) => prevState + 1);
	}

	function onKeyDownHandler(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key === "Enter")
		{
			submitName();
		}
	}

	//	Render
	return (
		<Box sx={{
			flexGrow: 1,
			padding: "36px 32px 0px 32px",
			textAlign: "center",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			gap: "54px"
		}}>
			<div style={{ width: "100%" }}>
				<Box
					component="img"
					src={IMGGuideOcta}
					alt=""
					sx={{
						width: { xl: "211px", sm: "177px", xs: "201px" }
					}}
				/>

				<Box sx={{
					maxWidth: { xl: "844px", sm: "530px" },
					width: "100%",
					margin: "0 auto",
					marginTop: { xl: "40px", sm: "24px", xs: "30px" },
					padding: { sm: "unset", xs: "0 8px" },
					font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
					color: "primary.main"
				}}>
					How should I call you?
				</Box>

				<CustomInput.Base
					placeholder="Name"
					value={name}
					onChange={({ target }) => setName(target.value.trim())}
					onKeyDown={onKeyDownHandler}
					sx={{
						marginTop: { xl: "40px", xs: "32px" },
						maxWidth: { xl: "478px", sm: "410px" },
						width: "100%",
						borderRadius: "8px !important",

						"& .MuiInputBase-input":
						{
							padding: "16px 13px !important"
						}
					}}
				/>

				<Fade in={error !== null}>
					<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
						{error}
					</Box>
				</Fade>
			</div>

			<Box sx={{
				marginTop: { xl: "166px", lg: "80px", xs: "auto" },
				width: "100%",
				minHeight: "120px"
			}}>
				<CustomButton.Contained onClick={submitName} sx={{ width: { sm: "unset", xs: "100%" } }}>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default Name;
