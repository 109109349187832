import { Box, Typography, keyframes } from "@mui/material";
import IMGOne from "assets/images/missions/mission-three/MapContainer/1.png";
import IMGTwo from "assets/images/missions/mission-three/MapContainer/2.png";
import IMGThree from "assets/images/missions/mission-three/MapContainer/3.png";
import IMGFour from "assets/images/missions/mission-three/MapContainer/4.png";
import ArrowButton from "components/ArrowButton";
import ChipStyled from "components/ChipStyled";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import ImageDialog from "components/missions/ImageDialog";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import capitalizeFirstLetter from "utils/capitalizerFirstLetter";
import { pixelToVw } from "utils/pixelToVw";
import { ReactComponent as SVGBunny1Carrot } from "../../assets/images/bunny-1-carrot.svg";
import { ReactComponent as SVGBunny1 } from "../../assets/images/bunny-1.svg";
import { ReactComponent as SVGBunny2Carrot } from "../../assets/images/bunny-2-carrot.svg";
import { ReactComponent as SVGBunny2 } from "../../assets/images/bunny-2.svg";
import { ReactComponent as SVGBunny3Carrot } from "../../assets/images/bunny-3-carrot.svg";
import { ReactComponent as SVGBunny3 } from "../../assets/images/bunny-3.svg";
import { ReactComponent as SVGBunny4Carrot } from "../../assets/images/bunny-4-carrot.svg";
import { ReactComponent as SVGBunny4 } from "../../assets/images/bunny-4.svg";
import useMission from "../../hooks/useMission";

const vibrateKeyframe = keyframes`
	0%, 100%
	{
		transform: rotateZ(0deg) translate(var(--carrotX), var(--carrotY));
	}
	25%
	{
		transform: rotateZ(15deg) translate(var(--carrotX), var(--carrotY));
	}
	75%
	{
		transform: rotateZ(-15deg) translate(var(--carrotX), var(--carrotY));
	}
`;

function MapContainer()
{
	const missionContext = useMission();
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ error, setError ] = useState<string>("");
	const [ openHint, setOpenHint ] = useState<null | 1 | 2 | 3 | 4>(null);
	const [ animationStatus, setAnimationStatus ] = useState<[ boolean, boolean, boolean, boolean ]>([ true, true, true, true ]);
	const [ arrValues, setArrValues ] = useState<Set<string>>(() =>
	{
		const store = localStorage.getItem("mission-three-wantInLine");

		if (store === null)
		{
			return new Set<string>();
		}

		return new Set<string>(JSON.parse(store));
	});

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 40)
		{
			return setError("The text should not exceed 40 characters");
		}

		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);
			const stateSize = state.size;

			state.add(capitalizeFirstLetter(valueInput.trim()));

			if (state.size === stateSize)
			{
				return prevState;
			}

			localStorage.setItem("mission-three-wantInLine", JSON.stringify(Array.from(state)));

			return state;
		});

		setValueInput("");
	}

	function deleteItemArrValues(item: string)
	{
		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);

			if (state.delete(item) === false)
			{
				return prevState;
			}

			localStorage.setItem("mission-three-wantInLine", JSON.stringify(Array.from(state)));

			return state;
		});
	}

	function handlerOpenHint(index: Exclude<typeof openHint, null>)
	{
		setOpenHint(index);
		setAnimationStatus((prevState) => (
			Object.assign([], prevState, { [ index - 1 ]: false })
		));
	}

	// Render
	return (
		<>
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_title" defaultValue="Let’s map everything that you want!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_subTitle" defaultValue="{NAME}, now that you have some idea of what you want in life, let’s get it all out of your head.\n\nAsk the bunnies for a hint if you get stuck!" position="left" isDescription />}
				/>

				<Box sx={{ width: "420px", marginTop: "19px", display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
						<Typography sx={{ color: "#707070", font: "400 14px/31px 'Open Sans'" }}>
							Added {arrValues.size}
						</Typography>
					</Box>

					<TooltipStyled title="Press 'Enter' to add" placement="right" arrow>
						<CustomInput.Base
							value={valueInput}
							fullWidth
							autoFocus
							onChange={(e: ChangeEvent<HTMLInputElement>) => { setValueInput(e.target.value); setError(""); }}
							onKeyDown={handleAddArrValues}
							disabled={arrValues.size >= 50}
							sx={{ textTransform: "capitalize" }}
						/>
					</TooltipStyled>

					<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "25px", gap: "10px" }}>
						<Box sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
							{error || (arrValues.size >= 50 && "Limit of 50 has been reached")}
						</Box>

						<Box sx={{ color: "#707070", font: "400 14px/31px 'Open Sans'" }}>
							Between 10 and 50
						</Box>
					</Box>

					{/* Item list */}
					<Box
						className="scrollCustom"
						sx={{
							display: "flex",
							flexWrap: "wrap",
							alignItems: "flex-start",
							alignContent: "flex-start",
							gap: "10px",
							overflow: "auto",
							height: "243px",
							paddingRight: "5px",
							paddingBottom: "3px"
						}}
					>
						{Array.from(arrValues).map((item) => (
							<ChipStyled
								key={item}
								label={item}
								onDelete={() => deleteItemArrValues(item)}
								sx={{
									flexGrow: 1,
									"& .MuiChip-label":
									{
										flexGrow: 1,
										textAlign: "center"
									}
								}}
							/>
						))}
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={arrValues.size < 10 || arrValues.size > 50}
					onClick={onClickNextStep} />
			</StepperNavigationWrapper>

			{/* Bunny */}
			<>
				<Box
					onClick={() => handlerOpenHint(1)}
					sx={[
						{
							"--carrotX": "-35px",
							"--carrotY": "-20px",

							position: "fixed",
							zIndex: 10,
							bottom: { xl: "170px", xs: "130px" },
							left: { xl: pixelToVw(460), xs: pixelToVw(300) },

							"& svg:last-child":
							{
								transformOrigin: "left bottom",
								transform: "translate(var(--carrotX), var(--carrotY))"
							}
						},
						animationStatus[ 0 ] === true &&
						{
							cursor: "pointer",

							"& svg:last-child":
							{
								animation: "linear 1700ms " + vibrateKeyframe + " infinite"
							}
						}
					]}
				>
					<SVGBunny1 />
					<SVGBunny1Carrot />
				</Box>

				<Box
					onClick={() => handlerOpenHint(2)}
					sx={[
						{
							"--carrotX": "-150px",
							"--carrotY": "-20px",

							position: "fixed",
							zIndex: 10,
							bottom: "20px",
							left: { xl: pixelToVw(600), xs: pixelToVw(500) },

							"& svg:last-child":
							{
								transformOrigin: "left center",
								transform: "translate(var(--carrotX), var(--carrotY))"
							}
						},
						animationStatus[ 1 ] === true &&
						{
							cursor: "pointer",

							"& svg:last-child":
							{
								animation: "linear 1700ms " + vibrateKeyframe + " infinite"
							}
						}
					]}
				>
					<SVGBunny2 />
					<SVGBunny2Carrot />
				</Box>

				<Box
					onClick={() => handlerOpenHint(3)}
					sx={[
						{
							"--carrotX": "-60px",
							"--carrotY": "-15px",

							position: "fixed",
							zIndex: 10,
							bottom: { xl: "170px", xs: "110px" },
							right: { xl: pixelToVw(580), xs: pixelToVw(490) },

							"& svg:last-child":
							{
								transformOrigin: "center top",
								transform: "translate(var(--carrotX), var(--carrotY))"
							}
						},
						animationStatus[ 2 ] === true &&
						{
							cursor: "pointer",

							"& svg:last-child":
							{
								animation: "linear 1700ms " + vibrateKeyframe + " infinite"
							}
						}
					]}
				>
					<SVGBunny3 />
					<SVGBunny3Carrot />
				</Box>

				<Box
					onClick={() => handlerOpenHint(4)}
					sx={[
						{
							"--carrotX": "-100px",
							"--carrotY": "-25px",

							position: "fixed",
							zIndex: 10,
							bottom: { xl: "95px", xs: "70px" },
							right: { xl: pixelToVw(280), xs: pixelToVw(200) },

							"& svg:last-child":
							{
								transformOrigin: "left top",
								transform: "translate(var(--carrotX), var(--carrotY))"
							}
						},
						animationStatus[ 3 ] === true &&
						{
							cursor: "pointer",

							"& svg:last-child":
							{
								animation: "linear 1700ms " + vibrateKeyframe + " infinite"
							}
						}
					]}
				>
					<SVGBunny4 />
					<SVGBunny4Carrot />
				</Box>
			</>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_1_title" defaultValue="Big and small" position="left" />}
				body={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_1_body" defaultValue="Like getting married or\nhaving a good coffee" position="left" isDescription />}
				open={openHint === 1}
				image={IMGOne}
				direction="column"
				position="bottom"
				onClose={() => setOpenHint(null)}
			/>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_2_title" defaultValue="Rational and irrational" position="left" />}
				body={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_2_body" defaultValue="Like having a high-paying job or buying a sports car" position="left" isDescription />}
				open={openHint === 2}
				image={IMGTwo}
				direction="column"
				position="bottom"
				onClose={() => setOpenHint(null)}
			/>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_3_title" defaultValue="Ongoing and one-off" position="left" />}
				body={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_3_body" defaultValue="like playing piano or\ngetting a tattoo" position="left" isDescription />}
				open={openHint === 3}
				image={IMGThree}
				direction="column"
				position="bottom"
				onClose={() => setOpenHint(null)}
			/>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_4_title" defaultValue="Dream or Plan" position="left" />}
				body={<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_bunny_4_body" defaultValue="Like going to Antarctica or buying a house" position="left" isDescription />}
				open={openHint === 4}
				image={IMGFour}
				direction="column"
				position="bottom"
				onClose={() => setOpenHint(null)}
			/>
		</>
	);
}

export default MapContainer;
