import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, CircularProgress, Dialog, Grid, Typography, alpha } from "@mui/material";
import LinkMI from "@mui/material/Link";
import Api from "api";
import Artboard from "assets/images/Artboard.svg";
import OсtaStripe from "assets/images/OсtaStripe.png";
import YearningBack3a from "assets/images/YearningBack3a.svg";
import bgPrice from "assets/images/bgPrice.png";
import meditate from "assets/images/meditate.svg";
import octaPriceOne from "assets/images/octaPriceOne.svg";
import octsSubscribe from "assets/images/octsSubscribe.png";
import UnsubscribeModal from "components/Dialog/Unsubscribe.modal";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useEffect, useLayoutEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Element } from "react-scroll";
import { getLocalStorage } from "utils/localStorage";
import MobileWelcom from "./MobileWelcom";

function WelcomPage()
{
	const navigate = useNavigate();
	const userContext = useUser();
	const [ URLSearchParams ] = useSearchParams();

	const [ packages, setPackages ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ stripeStatus, setStripeStatus ] = useState(false);
	const [ activeItem, setActiveItem ] = useState();
	const [ openDialogDetails, setOpenDialogDetails ] = useState(false);
	const [ activeSubscripotion, setActiveSubscripotion ] = useState("");
	const [ isShowModalUnsubscribe, setIsShowModalUnsubscribe ] = useState(false);

	function openDetails(item)
	{
		setActiveItem(item);
		setOpenDialogDetails(true);
	}

	useEffect(() =>
	{
		Api.admin
			.getPackages()
			.then((res) =>
			{
				setPackages([
					{
						id: 1,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_1"
							defaultValue="Free\nTrial"
							position="top"
						/>,
						subTitle: [
							{
								title: "Just 2 first missions",
								active: true
							},
							{
								title: "Saved results",
								active: false
							},
							{
								title: "Access to trackers ",
								active: false
							},
							{
								title: "Ability to repeat exercises ",
								active: false
							}
							// {
							// 	title: '1 month of individual guidance with coach',
							// 	active: false
							// },
						],
						price: res[ 0 ].decideLater,
						idProduct: "",
						details: {
							title: "Free Trial",
							description: "Start Your Quest: Try 2 Missions for Free!",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_1"
									defaultValue="Enjoy the first 2 missions with no strings attached"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_2"
									defaultValue="Save & view unlimited number of Wheel of Life results"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_3"
									defaultValue="Save, view, update your personal values"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_4"
									defaultValue="Unlimited repeats of the Wheel of Life exercise"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_5"
									defaultValue="Check out our other packages for access to the full quest"
									position="top"
									isDescription
								/>
							]
						}
					},
					// {
					// 	id: 2,
					// 	title: < ContentAdmin limit={111}
					// 		keyContent="contentDashboard"
					// 		keyText="subscription_title_2"
					// 		defaultValue="Monthly\nSubscription"
					// 		position="top"
					// 	/>,
					// 	description: '',
					// 	subTitle: [
					// 		{
					// 			title: 'Full 10 missions',
					// 			active: true
					// 		},
					// 		{
					// 			title: 'Saved results',
					// 			active: true
					// 		},
					// 		{
					// 			title: 'Access to trackers ',
					// 			active: false
					// 		},
					// 		{
					// 			title: 'Ability to repeat exercises ',
					// 			active: false
					// 		},
					// 		{
					// 			title: '10 sessions with life design coach',
					// 			active: false
					// 		},
					// 	],
					// 	price: res[ 0 ].monthly,
					// 	idProduct: process.env.REACT_APP_STRIPE_SUBSCRIPTION_KEY,
					// 	details: {
					// 		title: 'Monthly Subscription',
					// 		description: 'Flexible and affordable access to the full quest',
					// 		subTitle: [
					// 			< ContentAdmin limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_2_1"
					// 				defaultValue="Enjoy the quest for as long as you want &\ncancel any time"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			< ContentAdmin limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_2_2"
					// 				defaultValue="Have unlimited access to all 10 missions"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			< ContentAdmin limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_2_3"
					// 				defaultValue="Save and view your results"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			< ContentAdmin limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_2_4"
					// 				defaultValue="Track and update your progress"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			< ContentAdmin limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_2_5"
					// 				defaultValue="Repeat your favourite exercises as much as you want"
					// 				position="top"
					// 				isDescription
					// 			/>
					// 		]
					// 	}
					// },
					{
						id: 3,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_3"
							defaultValue="Lifetime\nAccess"
							position="top"
						/>,
						subTitle: [
							{
								title: "Full 10 missions",
								active: true
							},
							{
								title: "Saved results",
								active: true
							},
							{
								title: "Access to trackers ",
								active: true
							},
							{
								title: "Ability to repeat exercises ",
								active: true
							},
							{
								title: "10 sessions with life design coach",
								active: false
							}
						],
						price: res[ 0 ].yearly,
						idProduct: "full",
						details: {
							title: "Lifetime Access",
							description: "Unlock the quest forever with a one-time investment",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_1"
									defaultValue="Unlimited lifetime access to the quest & all the tools"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_2"
									defaultValue="Make the quest your lifelong strategic life planning\n tool"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_3"
									defaultValue="Repeat your favourite exercises, view progress and\n update as you go"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_4"
									defaultValue="Never miss out on future updates and additions to\n the quest"
									position="top"
									isDescription
								/>
							]
						}
					},
					{
						id: 4,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_4"
							defaultValue="Personalised\nGuidance"
							position="top"
						/>,
						subTitle: [
							{
								title: "Full 10 missions",
								active: true
							},
							{
								title: "Saved results",
								active: true
							},
							{
								title: "Access to trackers ",
								active: true
							},
							{
								title: "Ability to repeat exercises ",
								active: true
							},
							{
								title: "10 sessions with life design coach",
								active: true
							}
						],
						price: res[ 0 ].lifetime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
						idProduct: "",
						details: {
							title: "Personalised Guidance",
							description: "Apply now for a chance to work directly with Natalya",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_4_1"
									defaultValue="Lifetime access to the quest and all the tools"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_4_2"
									defaultValue="10 individual coaching sessions with Natalya,\n founder & author of My Life Quest"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_4_3"
									defaultValue="Personalised guidance, additional exercises &\n homework recommended just for you"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_4_4"
									defaultValue="Natalya only works with individuals on a case-by-case\n basis, serving a maximum of 12 clients per year to\n maintain a 100% success rate"
									position="top"
									isDescription
								/>
							]
						}
					}
				]);
				setIsLoading(false);
			});

		if (URLSearchParams.get("status") !== "success")
		{
			return;
		}

		setStripeStatus(true);

		Api.users
			.getStripeData()
			.then((data) =>
			{
				if (data.length === 0)
				{
					return;
				}

				ReactGA.ga(
					"send",
					"event",
					"conversion",
					{
						send_to: process.env.REACT_APP_GA_TRACKING_CONVERSATION_ID + "/cIOtCNPX9K8YEJu-iOsp",
						transaction_id: data[ data.length - 1 ].transaction_id
					}
				);
			});
	}, [ URLSearchParams ]);
	useLayoutEffect(() =>
	{
		if (!getLocalStorage("graph"))
		{
			navigate("/");
		}
	}, [ navigate ]);
	function handleClick(e, item)
	{
		e.stopPropagation();

		if (item.id === 1)
		{
			navigate("/");

			return;
		}

		if (userContext.state?.user?.subscriptionIsActive)
		{
			setIsShowModalUnsubscribe(true);
			// handleSubscribe();
		}

		else
		{
			setActiveSubscripotion(item.idProduct);
			// setOpen(true);
			Api.transactions
				.byAllMissions()
				.then((data) =>
				{
					window.location = data;
				})
				.catch((error) => console.error(error));
			setOpenDialogDetails(false);
		}

	}

	function closeDialogStripeStatus()
	{
		if (window.location.search.split("=")[ 1 ] === "success")
		{
			setStripeStatus(false);
			navigate("/dashboard");
		}

		else
		{
			setStripeStatus(false);
		}
	}

	return (
		<Box sx={{ background: `url(${ bgPrice }) no-repeat` }}>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Box id="section" sx={{ maxWidth: "1248px", m: "0 auto" }}>
					<Element name="/welcome" className="element">
						<Typography pt={10} sx={{ fontSize: { xs: "24px", sm: "60px" }, fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff" }}>
							Welcome to Life Strategy Quest
						</Typography>
					</Element>
					<Typography pt={2} sx={{ fontSize: { xs: "16px", sm: "22px" }, fontFamily: "Open Sans", fontWeight: "medium", color: "#fff" }}>
						{getLocalStorage("name")}, thank you for trusting us. We hope the assessment brought some clarity for you.<br /><br />

						We would be happy to guide you to even more clarity with Life Strategy Quest. It’s a fun, self-paced journey to help
						you uncover your true self, understand your options, and turn your dream life into reality.
					</Typography>
				</Box>
				<Box mt={10} sx={{ display: "flex", justifyContent: "flex-end", position: "relative" }}>
					<Box className="imageWelcom" sx={{ maxWidth: "1207px" }}>
						<img width="100%" src={Artboard} alt="Artboard" />
					</Box>
					<Box
						className="paddingDescWelcom"
						sx={{
							background: "linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))",
							borderRadius: "0 20px 20px 0",
							border: "2px solid #fff",
							borderLeft: "none",
							p: "80px 100px 80px 150px",
							position: "absolute",
							left: 0,
							bottom: "-60px",
							maxWidth: "873px",
							backdropFilter: "blur(60px)"
						}}
					>
						<Box sx={{ position: "relative" }}>
							<Typography sx={{ fontSize: "42px", fontFamily: "Lora", fontWeight: "bold", color: "#fff" }}>
								You don’t have to decide just yet
							</Typography>
							<Typography mt={3} sx={{ fontSize: "22px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff" }}>
								Enjoy the first steps of the quest for free. Even if you decide to do just that, you will already feel a lot
								clearer about your life. We hope you will have fun!
							</Typography>
							<Box className="octaWelcomFirst" sx={{ position: "absolute", top: "-372px", left: "170px" }}>
								<img width="248px" src={octaPriceOne} alt="octaPriceOne" />
							</Box>
						</Box>
					</Box>
				</Box>
				<Box mt={32} sx={{ display: "flex", justifyContent: "flex-start", position: "relative" }}>
					<Box className="imageWelcom" sx={{ maxWidth: "1207px" }}>
						<img width="100%" src={YearningBack3a} alt="YearningBack3a" />
					</Box>
					<Box
						className="paddingDescWelcom"
						sx={{
							background: "linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))",
							borderRadius: "20px 0 0 20px",
							border: "2px solid #fff",
							borderRight: "none",
							p: "80px 100px 80px 150px",
							position: "absolute",
							right: 0,
							top: "-140px",
							maxWidth: "873px",
							backdropFilter: "blur(60px)"
						}}
					>
						<Box sx={{ position: "relative" }}>
							<Typography sx={{ fontSize: "42px", fontFamily: "Lora", fontWeight: "bold", color: "#fff" }}>
								A quick word about our pricing
							</Typography>
							<Typography mt={3} sx={{ fontSize: "22px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff" }}>
								We are a small, self-funded team. We don’t believe in ads or selling your data.<br /><br />
								This is why we are offering a full quest as a premium subscription, so that you can enjoy your personal growth
								journey in a peaceful and serene way.
							</Typography>
							<Box className="octaWelcomSecond" sx={{ position: "absolute", bottom: "-452px", right: "0px" }}>
								<img width="450px" src={meditate} alt="octaPriceOne" />
							</Box>
						</Box>
					</Box>
				</Box>
				<Typography pt={10} sx={{ fontSize: "60px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff" }}>
					Our packages
				</Typography>
				<Typography pt={2} sx={{ fontSize: "22px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff", textAlign: "center" }}>
					Get access to the full quest and all life management tools with a payment plan that works best for you:
				</Typography>
				{/* <Box sx={{ width: '96%', m: '50px auto 0' }}>
					<Grid pb={20} container spacing={5} >
						<Grid item xs={12} sm={3}>
							<Box sx={{
								background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								backdropFilter: 'blur(60px)',
								border: '2px solid #fff',
								borderRadius: '20px',
								p: '30px',
								height: '352px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px' }}>
										Monthly<br></br>
										subscription
									</Typography>
								</Box>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px', textAlign: 'right' }}>
										€12.99
									</Typography>
									<Typography sx={{ fontSize: '18px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
										cancel any time
									</Typography>
								</Box>
							</Box>
							<Box sx={{ width: '95%', m: '20px auto 0' }}>
								<Link className="textecotationLinks" to="/temporary-page"><ButtonStyled fullWidth={true} title="Subscribe Monthly" /></Link>
							</Box>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Box sx={{
								background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								backdropFilter: 'blur(60px)',
								border: '2px solid #fff',
								borderRadius: '20px',
								p: '30px',
								height: '352px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px' }}>
										Yearly<br></br>
										subscription
									</Typography>
								</Box>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px', textAlign: 'right' }}>
										€129.99
									</Typography>
									<Typography sx={{ fontSize: '18px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
										cancel any time
									</Typography>
								</Box>
							</Box>
							<Box sx={{ width: '95%', m: '20px auto 0' }}>
								<Link className="textecotationLinks" to="/temporary-page"><ButtonStyled fullWidth={true} title="Subscribe Annually" /></Link>
							</Box>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Box sx={{
								background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								backdropFilter: 'blur(60px)',
								border: '2px solid #fff',
								borderRadius: '20px',
								p: '30px',
								height: '352px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px' }}>
										Lifetime access
									</Typography>
									<Typography sx={{ fontSize: '24px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px', mt: '-10px' }}>
										to the quest and all the tools
									</Typography>
								</Box>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px', textAlign: 'right' }}>
										€399.99
									</Typography>
									<Typography sx={{ fontSize: '18px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
										cancel any time
									</Typography>
								</Box>
							</Box>
							<Box sx={{ width: '95%', m: '20px auto 0' }}>
								<Link className="textecotationLinks" to="/temporary-page"><ButtonStyled fullWidth={true} title="Get Lifetime Access" /></Link>
							</Box>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Box sx={{
								background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								backdropFilter: 'blur(60px)',
								border: '2px solid #fff',
								borderRadius: '20px',
								p: '30px',
								height: '352px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px' }}>
										Decide later
									</Typography>
								</Box>
								<Box>
									<Typography sx={{ fontSize: '42px', fontFamily: 'Lora', fontWeight: 'bold', color: '#fff', lineHeight: '56px', textAlign: 'right' }}>
										€0
									</Typography>
								</Box>
							</Box>
							<Box sx={{ width: '95%', m: '20px auto 0' }}>
								<Link className="textecotationLinks" to="/temporary-page"><Button sx={{
									background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
									backdropFilter: 'blur(60px)',
									color: '#fff',
									fontFamily: 'Open Sans',
									fontSize: '24px',
									width: '100%',
									borderRadius: '160px',
									height: '60px',
									textTransform: 'none',
									border: '3px solid #fff',
									"&:hover": {
										background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
									}
								}}>Try for Free</Button></Link>
							</Box>
						</Grid>
					</Grid>
				</Box> */}
				{isLoading ?
					<Box sx={{ display: "flex", justifyContent: "center", mt: "145px" }}><CircularProgress /></Box> :
					<Grid container spacing={4} justifyContent="center" sx={{ mt: "55px", pb: "55px" }}>
						{packages.map((item, index) => (
							<Grid item key={index} xs={12} sm={3}>
								<Box
									onClick={() => openDetails(item)}
									sx={{
										border: "2px solid rgba(255, 255, 255, 0.5)",
										borderRadius: "24px",
										background: (
											item.id === 3
												? "#FFFFFF"
												: "linear-gradient(259.97deg, rgba(207, 225, 255, 0.55) 7.03%, rgba(223, 223, 223, 0.132) 102.08%)"
										),
										p: { lg: "24px", xl: "24px 36px 32px" },
										height: { lg: "357px", xl: "501px" },
										display: "flex",
										justifyContent: "space-between",
										flexDirection: "column",
										color: item.id === 3 ? "primary.main" : "primary.light",
										position: "relative",
										cursor: "pointer"
									}}
								>
									{item.id === 3 &&
										<Box sx={{
											"--size": "72px",

											position: "absolute",
											top: "-44px",
											left: "50%",
											transform: "translateX(-50%)",

											width: "var(--size)",
											height: "var(--size)",

											backgroundColor: "#FFFFFF",
											borderRadius: "50%",

											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
										>
											<FavoriteIcon sx={{ fontSize: "45px", fill: alpha("#F22E6C", 0.5) }} />
										</Box>
									}

									<Typography sx={{ font: { lg: '700 24px/31px "lora-Bold"', xl: '700 40px/51px "lora-Bold"' }, textAlign: "center", mb: { lg: "15px", xl: 4 } }}>
										{item.title}
									</Typography>

									{item.subTitle.map((itemSubtitle, indexSubtitle) => (
										<Box
											key={indexSubtitle}
											sx={{
												display: "flex",
												gap: { lg: "10px", xl: "14px" },
												mb: { lg: "10px", xl: 2 }
											}}
										>
											<CheckIcon sx={{
												fontSize: { lg: "14px", xl: "20px" },
												mt: { lg: "2px", xl: "5px" },
												opacity: itemSubtitle.active ? 1 : .5
											}}
											/>

											<Typography sx={{
												font: { lg: '400 16px/20px "Open Sans"', xl: '400 22px/26px "Open Sans"' },
												opacity: itemSubtitle.active ? 1 : .5
											}}
											>
												{itemSubtitle.title}
											</Typography>
										</Box>
									))}

									{index === 1 && userContext.state?.user?.subscriptionIsActive ?
										<TooltipStyled title="You have an active subscription" placement="top" arrow>
											<Box>
												<CustomButton.Contained
													disabled={(userContext.state?.user?.subscriptionIsActive)}
													onClick={(e) =>
													{
														handleClick(e, item);
													}}
													sx={{ width: "100%", fontFamily: "lora-Bold", fontSize: { lg: "24px", xl: "32px" }, minHeight: { lg: "40px", xl: "56px" } }}
												>
													{`€${ item.price }`}
												</CustomButton.Contained>
											</Box>
										</TooltipStyled>
										:
										<Box>
											{index === 2 ?
												<LinkMI
													onClick={(e) => e.stopPropagation()}
													href="https://www.yourlifestartup.com/change-your-life-step-by-step"
													sx={{
														textDecoration: "none",
														width: "100%"
													}}
												>
													<CustomButton.Contained
														sx={{ width: "100%", fontFamily: "lora-Bold", fontSize: { lg: "24px", xl: "32px" }, minHeight: { lg: "40px", xl: "56px" } }}
													>
														{`€${ item.price }`}
													</CustomButton.Contained>
												</LinkMI> :
												<CustomButton.Contained
													disabled={
														(index === 2 && userContext.state?.user?.subscriptionIsActive)
														|| (index === 2 && userContext.state?.user?.fullSubscribe)
														|| (index === 1 && userContext.state?.user?.fullSubscribe)
													}
													onClick={(e) => handleClick(e, item)}
													sx={{
														width: "100%",
														fontFamily: "lora-Bold",
														fontSize: { lg: "24px", xl: "32px" },
														minHeight: { lg: "40px", xl: "56px" }
													}}
												>
													{(index === 1 && userContext.state?.user?.subscriptionIsActive) ? "Unsubscribe" : `€${ item.price }`}
												</CustomButton.Contained>}
										</Box>
									}

									{/* {index === 2 && <Box sx={{ position: 'absolute', top: { lg: '-32px', xl: '-46px' }, left: { lg: 'calc(50% - 28px)', xl: 'calc(50% - 36px)' } }}>
											<Box sx={{ width: { lg: '56px', xl: '72px' }, height: { lg: '56px', xl: '72px' }, position: 'relative', p: '13px', background: '#fff', borderRadius: '50%' }}>
												<Box
													component='img'
													src={heart}
													sx={{ width: { lg: '35px', xl: '45px' }, top: { lg: '13px', xl: '20px' }, position: 'absolute', left: { lg: '10px', xl: '13px' } }}
												/>
											</Box>
										</Box>} */}
								</Box>
							</Grid>
						))}
					</Grid>}
				<Dialog
					open={openDialogDetails}
					onClose={() => setOpenDialogDetails(false)}
					sx={{
						"& .MuiBackdrop-root": { backgroundColor: "transparent", backdropFilter: "blur(20px)" },
						".MuiPaper-root": {
							p: "56px 64px",
							borderRadius: " 24px",
							boxShadow: "none",
							position: "relative",
							minWidth: "980px",
							m: 0,
							height: "703px"
						}
					}}
				>
					{activeItem
						? (
							<>
								<CloseIcon
									onClick={() => setOpenDialogDetails(false)}
									sx={{ position: "absolute", top: "37px", right: "32px", fill: "#215C75", cursor: "pointer" }}
								/>
								<Typography sx={{ font: '700 40px/45px "Lora-Bold"', color: "primary.main" }}>
									{activeItem.details?.title}
								</Typography>
								<Typography sx={{ font: '400 22px/20px "Open Sans"', color: "primary.dark", mt: 2, opacity: .5, mb: "40px" }}>
									{activeItem.details?.description}
								</Typography>
								<Box sx={{ position: "relative" }}>
									{activeItem.details?.subTitle.map((itemSubtitle, indexSubtitle) => (
										<Box key={indexSubtitle} sx={{ display: "flex", gap: "18px", mb: "36px" }}>
											<CheckIcon sx={{ fill: "#215C75", fontSize: "20px", mt: "5px" }} />
											<Typography sx={{ font: '400 22px/30px "Open Sans"' }}>
												{itemSubtitle}
											</Typography>
										</Box>
									))}
									<Box sx={{ position: "absolute", top: "-47px", right: "0px", width: "305px" }} component="img" src={octsSubscribe} alt="" />
								</Box>
								<Box sx={{ marginTop: "auto", width: "100%", display: "flex", gap: "40px" }}>
									<CustomButton.Outline
										onClick={() =>
										{
											if (activeItem.details?.title === "Free Trial")
											{
												navigate("/dashboard");
											}
											else
												setOpenDialogDetails(false);
										}}
										sx={{
											fontFamily: "Open Sans",
											fontSize: "24px",
											minHeight: "56px",
											background: "rgba(255, 255, 255, 0.50)",
											flexGrow: 1
										}}
									>
										{activeItem.details?.title === "Free Trial"
											? "Continue with free trial"
											: "Choose another plan"
										}
									</CustomButton.Outline>
									{activeItem.details?.title === "Personalised Guidance"
										? (
											<CustomButton.Contained
												component={LinkMI}
												href="https://www.yourlifestartup.com/change-your-life-step-by-step"
												sx={{
													fontFamily: "Open Sans",
													fontSize: "24px",
													minHeight: "56px",
													flexGrow: 1
												}}
											>
												Apply
											</CustomButton.Contained>
										)
										: (
											<CustomButton.Contained
												disabled={(activeItem.details?.title === "Lifetime Access" && userContext.state?.user?.fullSubscribe) || (activeItem.details?.title === "Monthly Subscription" && userContext.state?.user?.fullSubscribe)}
												onClick={(e) =>
												{
													if (activeItem.details?.title === "Free Trial")
													{
														setOpenDialogDetails(false);
													}
													else
														handleClick(e, activeItem);
												}}
												sx={{ fontFamily: "Open Sans", fontSize: "24px", minHeight: "56px", flexGrow: 1 }}
											>
												{(activeItem.details?.title === "Monthly Subscription" && userContext.state?.user?.subscriptionIsActive)
													? "Unsubscribe"
													: activeItem.details?.title === "Free Trial"
														? "Get the Full Quest"
														: `Pay €${ activeItem.price }`
												}
											</CustomButton.Contained>
										)
									}
								</Box>
							</>
						)
						: null
					}
				</Dialog>
				<UnsubscribeModal
					open={isShowModalUnsubscribe}
					onClose={() => setIsShowModalUnsubscribe(false)}
				/>
				<Dialog
					open={stripeStatus}
					onClose={() => setStripeStatus(false)}
					sx={{
						"& .MuiDialog-paper":
						{
							maxWidth: "unset",
							width: "980px",
							height: "425px",
							padding: "56px 64px",
							backgroundColor: "#FFFFFF",
							border: "1px solid " + alpha("#FFFFFF", 0.5),
							borderRadius: "24px",
							position: "relative"
						},
						"& .MuiBackdrop-root":
						{
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
							backdropFilter: "blur(25px)"
						}
					}}
				>
					<CloseIcon
						onClick={() => setStripeStatus(false)}
						sx={{
							position: "absolute",
							top: "32px",
							right: "32px",
							cursor: "pointer",
							color: "primary.main"
						}}
					/>

					<Box sx={{ font: "700 40px/51px Lora-Bold", color: "#215C75" }}>
						{window.location.search.split("=")[ 1 ] === "success"
							? (
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="dialogSuccessStripe"
									defaultValue="Your subscription is activated"
									position="left"
									isDescription
								/>
							)
							: (
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="dialogSuccessStripeCancel"
									defaultValue="Your payment was declined"
									position="left"
									isDescription
								/>
							)
						}
					</Box>

					<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", marginTop: "24px", width: "568px" }}>
						{window.location.search.split("=")[ 1 ] === "success"
							? (
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="dialogSuccessStripeDesc"
									defaultValue="Congratulations, {NAME}! Your payment has been successful and you now have access to the full quest and all the tools."
									position="left"
									isDescription
								/>
							)
							: (
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="dialogSuccessStripeCancelDesc"
									defaultValue="{NAME}, unfortunately, your payment didn't go through. Please try again using a different payment method."
									position="left"
									isDescription
								/>
							)
						}
					</Box>
					<CustomButton.Contained
						// onClick={(event: MouseEvent<HTMLButtonElement>) => onClose(event, "reject")}
						sx={{ width: "309px", position: "absolute", left: "64px", bottom: "56px" }}
						onClick={closeDialogStripeStatus}
					>
						{window.location.search.split("=")[ 1 ] === "success" ? "Go to Quest" : "View packages"}
					</CustomButton.Contained>
					<Box
						component="img"
						src={OсtaStripe}
						alt=""
						sx={{ width: "230px", position: "absolute", top: "18px", right: "116px" }}
					/>
				</Dialog>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "none" } }}>
				<MobileWelcom packages={packages} />
			</Box>
		</Box>

	);
}

export default WelcomPage;
