import { Checkbox, CheckboxProps, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as SVGCheck } from "assets/images/icons/size-8/check.svg";

const BpIcon = styled("span")(
	{
		position: "relative",
		borderRadius: "4px",
		width: "24px",
		height: "24px",
		marginTop: "3px",
		backgroundColor: alpha("#FFFFFF", 0.5),

		"& svg":
		{
			position: "absolute",
			top: "6px",
			left: "4px"
		}
	}
);

const BpCheckedIcon = styled(({ ...props }: Record<string, any>) =>
{
	return (
		<BpIcon {...props}>
			<SVGCheck />
		</BpIcon>
	);
})({});

function CheckboxStyled({ sx = [], ...props }: CheckboxProps)
{
	return (
		<Checkbox
			className="CheckboxStyled-root"
			disableRipple
			checkedIcon={<BpCheckedIcon className="CheckboxStyled-checkbox CheckboxStyled-checkbox--checked" />}
			icon={<BpIcon className="CheckboxStyled-checkbox" />}
			sx={[
				{
					"&.MuiCheckbox-root":
					{
						padding: "unset"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		/>
	);
}

export default CheckboxStyled;
