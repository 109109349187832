import { Box } from "@mui/material";
import { forwardRef, Ref, useState } from "react";
import IMGMission5 from "../../../../assets/images/missions/mission-six/mission-five.png";
import IMGMission4 from "../../../../assets/images/missions/mission-six/mission-four.png";
import IMGMission3 from "../../../../assets/images/missions/mission-six/mission-three.png";
import IMGMission2 from "../../../../assets/images/missions/mission-six/mission-two.png";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";
import ImageButton from "../ImageButton";

const TimeToReview = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ missionViews, setMissionViews ] = useState<[ boolean, boolean, boolean, boolean ]>([ false, false, false, false ]);

	// Handles
	function setIsView(index: 0 | 1 | 2 | 3)
	{
		setMissionViews((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: true });
		});
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSix" keyText="timeToReview_title" defaultValue="Time to review your clues" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="timeToReview_subTitle" defaultValue="First, take a moment to review the artifacts you’ve collected.\nJust look how much you already know about yourself, {NAME}." position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{
					display: "flex",
					justifyContent: "space-between",
					gap: "20px",
					height: "340px",
					width: "100%",
					marginTop: { xl: "126px", lg: "93px", xs: "93px" },

					"& .ImageButton-root":
					{
						"--size": { xl: "200px", lg: "163px", xs: "80px" }
					},
					"& .ImageButton-root:nth-of-type(even)":
					{
						alignSelf: "flex-end"
					}
				}}>
					<ImageButton
						onClick={() => { setIsView(0); missionContext.setShowMissionInfo(2); }}
						image={IMGMission2}
						value="Values"
					/>
					<ImageButton
						onClick={() => { setIsView(1); missionContext.setShowMissionInfo(3); }}
						image={IMGMission3}
						value="Yearnings"
					/>
					<ImageButton
						onClick={() => { setIsView(2); missionContext.setShowMissionInfo(4); }}
						image={IMGMission4}
						value="A Job to Love"
					/>
					<ImageButton
						onClick={() => { setIsView(3); missionContext.setShowMissionInfo(5); }}
						image={IMGMission5}
						value="Perfect Day"
					/>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={() => { missionContext.nextStep(); }} disabled={!missionViews.every((isView) => isView === true)} />
			</StepperNavigationWrapper>
		</Box>
	);
});

export default TimeToReview;
