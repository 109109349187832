import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const ChatPageMissionEight = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionEight"
					keyText="screen_1_chat_message"
					defaultValue="Boo, {NAME}!"
					isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionEight"
				keyText="screen_1_chat_answer"
				defaultValue="Scared me!"
				position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="screen_2_chat_message"
						defaultValue="I am trying to be funny! But on a more serious note, did you know that a brave person is not someone who is not afraid. A brave person is someone who is afraid but does it anyway."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionEight"
							keyText="screen_2_chat_message_answer"
							defaultValue="Sounds empowering…"
							position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="sounds_empowering_answer"
						defaultValue="It surely does. The thing is, we all have fears, especially when we are paving our own path in life. But we shouldn’t let fears stop us from becoming our best selves!"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionEight"
							keyText="sounds_empowering_so_true"
							defaultValue="So true"
							position="top"
						/>,
						<ContentAdmin
							limit={64}
							keyContent="contentMissionEight"
							keyText="sounds_empowering_i_agree"
							defaultValue="I agree"
							position="top"
						/>
					]
				});
				break;
			}

			case 4: {
				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);
	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Box
			ref={ref}
			{...props}
			className="mission-main main"
			component="main"
			sx={{ minHeight: "calc(100vh - 48px - 36px - 35px)" }}
		>
			<Box className="main__content">
				<Chat
					titleColor="#E9EDF0"
					titleMarginTop="10px"
					titleMarginLeft="5px"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEight"
						keyText="hello_again_title"
						defaultValue="Hello again, my friend!"
					/>}
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatPageMissionEight;
