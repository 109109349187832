import { Box } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import useMission from "pages/missionTwo/hooks/useMission";
import { ChangeEvent, useLayoutEffect, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import { ToughDecisionsFields, filledFormField } from ".";
import type { ListOfValuesData } from "../ListOfValues";

export interface IToughDecisionsFormProps
{
	readonly index: number;
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

const MAX_LENGTH_TEXTAREA: number = 300;

function ToughDecisionsForm({ index, preview = false }: IToughDecisionsFormProps)
{
	const missionContext = useMission();
	const [ toughDecisionsFields, setToughDecisionsFields ] = useLocalStorage<ToughDecisionsFields>(missionContext.missionPrefix + "-ToughDecisionsFields", []);
	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ , setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Effects
	useLayoutEffect(() =>
	{
		if (toughDecisionsFields.length === 0 || toughDecisionsFields[ index ] === undefined)
		{
			missionContext.setExtraStep(null);
		}
	}, [ toughDecisionsFields ]);

	// Handles
	function onChange({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setToughDecisionsFields((prevState) =>
		{
			const toughDecisionsFields = [ ...prevState ];

			toughDecisionsFields[ index ][ 1 ] = target.value.replaceAll(/\s{2,}/g, " ");

			return toughDecisionsFields;
		});
	}

	function getBot()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					toughDecisionsFields
						.map(([ , value ]) =>
						{
							return "Define in max 5 nouns, with no additional text, the personal values of this person based on their life’s tough decisions " + value.trim();
						}),
					"personBased"
				)
				.then((values) =>
				{
					const newValues =
						Array.from(
							new Set(
								values
									.map(({ personBased }) => personBased)
									.flat()
									.map((value) => value.toLowerCase())
							)
						).filter((value) => listOfValues.includes(value) === false);

					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 2) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
					setTimeout(() => missionContext.setExtraStep(null), 0);
				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	function nextStep()
	{
		if (preview === true)
		{
			missionContext.setExtraStep(null);
		}
		else
		{
			let emptyValArr = [];

			toughDecisionsFields.forEach(item =>
			{
				item.forEach(i =>
				{
					if (i.length === 0) emptyValArr.push(i);
				});

			});
			if (emptyValArr.length === 0)
			{
				getBot();
			}
			else
				missionContext.setExtraStep(null);
		}
	}

	// Render
	return (
		<Panel sx={{
			marginTop: "70px",
			padding: "24px 24px 24px 32px"
		}}>
			<Box sx={{
				paddingLeft: "16px",
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "primary.main"
			}}>
				{toughDecisionsFields[ index ][ 0 ]}
			</Box>

			<CustomInput.Base
				readOnly={preview}
				multiline
				rows={4}
				value={toughDecisionsFields[ index ][ 1 ]}
				onChange={onChange}
				inputProps={{ maxLength: MAX_LENGTH_TEXTAREA }}
				sx={{
					marginTop: "16px",
					width: "100%"
				}}
			/>

			<Box sx={{
				padding: "0 16px",
				width: "100%",

				font: "400 14px/16px 'Open Sans'",
				color: "primary.dark",
				textAlign: "right",
				opacity: 0.5
			}}>
				{toughDecisionsFields[ index ][ 1 ].trim().length ?? 0}/{MAX_LENGTH_TEXTAREA} characters
			</Box>

			<CustomButton.Contained
				disabled={preview === false && filledFormField(toughDecisionsFields, index) === false}
				onClick={nextStep}
				loading={loading}
				sx={{
					margin: "8px auto 0 auto",
					display: "flex"
				}}
			>
				{preview === true ? "Back" : "Submit"}
			</CustomButton.Contained>
		</Panel>
	);
}

export default ToughDecisionsForm;
