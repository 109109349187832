import { Box, BoxProps, alpha, useMediaQuery, useTheme } from "@mui/material";
import SVGCareer from "assets/images/Career.svg";
import SVGFriend from "assets/images/Friend.svg";
import SVGFun from "assets/images/Fun.svg";
import SVGGrowth from "assets/images/Growth.svg";
import SVGHealth from "assets/images/Health.svg";
import SVGLove from "assets/images/Love.svg";
import SVGMoney from "assets/images/Money.svg";
import SVGHome from "assets/images/home.svg";
import RadarChart from "react-svg-radar-chart";

export type Captions = "home" | "health" | "friend" | "love" | "career" | "money" | "fun" | "growth";
export type WheelData = Record<Captions, number>;

export interface IWheelProps extends BoxProps
{
	readonly data: WheelData;
	readonly active: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
	/**
	 * @default false
	 */
	readonly activeOnce?: boolean;
}

export const captions: Record<Captions, string> =
{
	money: "Money",
	health: "Health",
	love: "Love",
	growth: "Growth",
	friend: "Friends",
	fun: "Fun",
	career: "Career",
	home: "Environment"
};

function Wheel({ data, active, activeOnce = false, sx = [] }: IWheelProps)
{
	const theme = useTheme();
	const isXl = useMediaQuery(theme.breakpoints.up("xl"));
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));

	return (
		<Box
			sx={[
				{
					"--size": { xl: "637px", lg: "526px", xs: "320px" },
					position: "relative",

					width: "var(--size)",
					height: "var(--size)",

					display: "flex",
					justifyContent: "center",
					alignItems: "center",

					"& .Wheel-scale":
					{
						stroke: alpha("#FFFFFF", 0.5),
						strokeWidth: 1
					},
					"& .Wheel-axis":
					{
						stroke: "#FFFFFF",
						strokeWidth: 2,

						"&:nth-of-type(1)":
						{
							strokeOpacity: (activeOnce === true && active === 1) || (activeOnce === false && active >= 1) ? 1 : 0.6
						},
						"&:nth-of-type(2)":
						{
							strokeOpacity: (activeOnce === true && active === 2) || (activeOnce === false && active >= 2) ? 1 : 0.6
						},
						"&:nth-of-type(3)":
						{
							strokeOpacity: (activeOnce === true && active === 3) || (activeOnce === false && active >= 3) ? 1 : 0.6
						},
						"&:nth-of-type(4)":
						{
							strokeOpacity: (activeOnce === true && active === 4) || (activeOnce === false && active >= 4) ? 1 : 0.6
						},
						"&:nth-of-type(5)":
						{
							strokeOpacity: (activeOnce === true && active === 5) || (activeOnce === false && active >= 5) ? 1 : 0.6
						},
						"&:nth-of-type(6)":
						{
							strokeOpacity: (activeOnce === true && active === 6) || (activeOnce === false && active >= 6) ? 1 : 0.6
						},
						"&:nth-of-type(7)":
						{
							strokeOpacity: (activeOnce === true && active === 7) || (activeOnce === false && active >= 7) ? 1 : 0.6
						},
						"&:nth-of-type(8)":
						{
							strokeOpacity: (activeOnce === true && active === 8) || (activeOnce === false && active >= 8) ? 1 : 0.6
						}
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<RadarChart
				captions={captions}
				data={[ { data, meta: { color: "" } } ]}
				size={(isXl ? 569 : isLg ? 472 : 300)}
				options={{
					scales: 10,
					scaleProps()
					{
						return { className: "Wheel-scale", fill: "none" };
					},
					axisProps()
					{
						return { className: "Wheel-axis" };
					}
					// captionProps()
					// {
					// 	return { className: "Wheel-caption", fontFamily: "", fontSize: 0, textAnchor: "" };
					// }
				}}
			/>

			<CaptionImage icon="money" position="top" active={(activeOnce === true && active === 1) || (activeOnce === false && active >= 1)} />
			<CaptionImage icon="health" position="top-end" active={(activeOnce === true && active === 2) || (activeOnce === false && active >= 2)} />

			<CaptionImage icon="love" position="right" active={(activeOnce === true && active === 3) || (activeOnce === false && active >= 3)} />

			<CaptionImage icon="fun" position="bottom-start" active={(activeOnce === true && active === 4) || (activeOnce === false && active >= 4)} />
			<CaptionImage icon="friend" position="bottom" active={(activeOnce === true && active === 5) || (activeOnce === false && active >= 5)} />
			<CaptionImage icon="growth" position="bottom-end" active={(activeOnce === true && active === 6) || (activeOnce === false && active >= 6)} />

			<CaptionImage icon="home" position="left" active={(activeOnce === true && active === 7) || (activeOnce === false && active >= 7)} />
			<CaptionImage icon="career" position="top-start" active={(activeOnce === true && active === 8) || (activeOnce === false && active >= 8)} />
		</Box>
	);
}

export interface ICaptionLineProps
{
	readonly position: "top" | "top-start" | "top-end" | "left" | "right" | "bottom" | "bottom-start" | "bottom-end";
	/**
	 * @default false;
	 */
	readonly active?: boolean;
}
export function CaptionLine({ position, active = false }: ICaptionLineProps)
{
	const positions: Record<ICaptionImageProps[ "position" ], { top: string | number; left: string | number; transform: string; }> =
	{
		top: { top: "15%", left: "calc(50% - 1px)", transform: "translateX(-50%)" },
		"top-start": { top: "12.5%", left: "20%", transform: "translateX(-50%)" },
		"top-end": { top: "12.5%", left: "80%", transform: "translateX(-50%)" },

		left: { top: "50%", left: "7.5%", transform: "translate(-50%, -50%)" },
		right: { top: "50%", left: "92.5%", transform: "translate(-50%, -50%)" },

		bottom: { top: "85.5%", left: "50%", transform: "translateX(-50%)" },
		"bottom-start": { top: "73%", left: "20%", transform: "translateX(-50%)" },
		"bottom-end": { top: "73%", left: "80%", transform: "translateX(-50%)" }
	};

	return (
		<Box
			sx={[
				{
					width: "2px",
					height: "3px",

					backgroundColor: alpha("#FFFFFF", active ? 1 : 0.6),
					opacity: active === true ? 1 : 0.6,

					position: "absolute",
					...positions[ position ]
				}
			]}
		/>
	);
}

export interface ICaptionImageProps
{
	readonly icon: Captions;
	readonly position: "top" | "top-start" | "top-end" | "left" | "right" | "bottom" | "bottom-start" | "bottom-end";
	/**
	 * @default false;
	 */
	readonly active?: boolean;
}
export function CaptionImage({ icon, position, active = false }: ICaptionImageProps)
{
	const positions: Record<ICaptionImageProps[ "position" ], { top: string | number; left: string | number; transform: string; }> =
	{
		top: { top: "1.5%", left: "50%", transform: "translateX(-50%)" },
		"top-start": { top: "14%", left: "20%", transform: "translateX(-50%)" },
		"top-end": { top: "14%", left: "80%", transform: "translateX(-50%)" },

		left: { top: "50%", left: "7.5%", transform: "translate(-50%, -50%)" },
		right: { top: "50%", left: "92.5%", transform: "translate(-50%, -50%)" },

		bottom: { top: "86%", left: "50%", transform: "translateX(-50%)" },
		"bottom-start": { top: "74%", left: "20%", transform: "translateX(-50%)" },
		"bottom-end": { top: "74%", left: "80%", transform: "translateX(-50%)" }
	};

	const icons: Record<ICaptionImageProps[ "icon" ], string> =
	{
		home: SVGHome,
		love: SVGLove,
		career: SVGCareer,
		friend: SVGFriend,
		fun: SVGFun,
		growth: SVGGrowth,
		money: SVGMoney,
		health: SVGHealth
	};

	return (
		<Box
			sx={[
				{
					"--size": { xl: "78px", lg: "65px", xs: "38px" },

					width: "var(--size)",
					height: "var(--size)",

					background: `url(${ icons[ icon ] }) center center/cover`,
					opacity: active === true ? 1 : 0.6,

					position: "absolute",
					...positions[ position ]
				}
			]}
		/>
	);
}

export default Wheel;
