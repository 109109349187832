import { Box } from "@mui/material";
import { DEFAULT_STORE_FORM, IStoreForm } from "../..";
import ArrowButton from "../../../../components/ArrowButton";
import PanelTitle from "../../../../components/missions/PanelTitle";
import SolarSystemButton from "../../../../components/missions/SolarSystemButton";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

const TYPE_TO_EXTRASTEP = { pink: 1, purple: 2, yellow: 3 };

function DreamUpYourUniverses()
{
	const store = getLocalStorage<IStoreForm>("mission-three-form") || DEFAULT_STORE_FORM;
	const missionContext = useMission();

	// Utils
	function isFillForm(type: keyof typeof TYPE_TO_EXTRASTEP)
	{
		return store.DreamUpYourUniversesForm[ type ].title.length >= 2 && store.DreamUpYourUniversesForm[ type ].fields.length === 6;
	}

	// Handles
	function onButtonClick(type: keyof typeof TYPE_TO_EXTRASTEP)
	{
		return () =>
		{
			missionContext.setExtraStep(TYPE_TO_EXTRASTEP[ type ]);
		};
	}

	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	// Render
	return (
		<>
			<Box className="main__content" sx={{ position: "relative" }}>
				<PanelTitle positionOcta imageVariation="closeEye" title="Dream up your universes!" />

				<Box sx={{
					position: "absolute",
					bottom: { xl: "460px", lg: "360px", xs: "320px" },
					left: { xl: "100px", lg: "80px", xs: "100px" }
				}}>
					<SolarSystemButton blip={!isFillForm("pink")} onClick={onButtonClick("pink")} />
				</Box>
				<Box sx={{
					position: "absolute",
					bottom: { xl: "370px", lg: "290px", xs: "250px" },
					left: { xl: "400px", lg: "310px", xs: "400px" }
				}}>
					<SolarSystemButton blip={!isFillForm("purple")} onClick={onButtonClick("purple")} />
				</Box>
				<Box sx={{
					position: "absolute",
					bottom: { xl: "450px", lg: "320px", xs: "320px" },
					right: { xl: "120px", lg: "120px", xs: "100px" }
				}}>
					<SolarSystemButton blip={!isFillForm("yellow")} onClick={onButtonClick("yellow")} />
				</Box>
			</Box>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton direction="right" onClick={onClickNextStep} disabled={!isFillForm("pink") || !isFillForm("purple") || !isFillForm("yellow")} />
			</StepperNavigationWrapper>
		</>
	);
}

export default DreamUpYourUniverses;
