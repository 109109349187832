import { Box, Fade, alpha } from "@mui/material";
import { IGetDataMissionFour } from "api/Missions";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import TooltipStyled from "components/TooltipStyled";
import { useNavigate, useParams } from "react-router-dom";
import { ETab, formatData } from ".";
import ArtifactsContainer from "../components/ArtifactsContainer";
import { formatDate } from "../yearning";
import IMGActionPlanBackground from "./assets/images/actionPlan-background.png";

export interface IDetailsProps
{
	items: IGetDataMissionFour[ "data" ] | null;
}

function Details({ items }: IDetailsProps)
{
	const navigate = useNavigate();
	const { data: missionId } = useParams();

	const selectedItem = getMissionIndexFromId(missionId || "");

	// Utils
	function getMissionIndexFromId(id: string)
	{
		if (items === null)
		{
			return -1;
		}

		return items.findIndex((item) => item.id === id);
	}

	// Render
	return (
		<ArtifactsContainer
			title={(
				items === null
					? "Loading..."
					: selectedItem === -1
						? "Information not found"
						: formatDate(items[ selectedItem ].created_at)
			)}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts/career")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					gap: "19px"
				}
			}}
		>
			{items !== null && selectedItem > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => navigate("/dashboard/artifacts/career/details/" + items[ selectedItem - 1 ].id)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "32px" },
								display: "inline-flex",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								left: { xl: "20px", lg: "8px", xs: "8px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{items !== null && selectedItem < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => navigate("/dashboard/artifacts/career/details/" + items[ selectedItem + 1 ].id)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "32px" },
								display: "inline-flex",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "8px", xs: "8px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			{items !== null && selectedItem !== -1 &&
				(
					<Box sx={{
						width: { xl: "1558px", lg: "1052px", xs: "1558px" },
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						justifyContent: "center",
						justifySelf: "center",
						gap: { xl: "40px", lg: "22px", xs: "40px" },

						"& > div":
						{
							padding: { xl: "24px 32px 32px 32px", lg: "16px 24px 24px 24px", xs: "24px 32px 32px 32px" },
							height: { xl: "680px", lg: "552px", xs: "680px" },
							display: "grid",
							gridTemplateRows: "auto 1fr",
							gap: { xl: "16px", lg: "8px", xs: "16px" },
							backgroundColor: alpha("#FFFFFF", 0.6),
							borderRadius: "24px",
							color: "primary.dark",
							font: "400 22px/30px 'Open Sans'",

							"& .customScroll":
							{
								overflowY: "auto",
								paddingRight: "8px"
							}
						}
					}}>
						<Box>
							<Box sx={{
								color: "primary.main",
								font: { xl: "700 39px/50px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 39px/50px Lora-Bold" }
							}}>
								My perfect job ad
							</Box>

							<div className="customScroll">
								{formatData(items[ selectedItem ].mission_data, ETab.PERFECT_JOB_AD)}
							</div>
						</Box>

						<Box>
							<Box sx={{
								color: "primary.main",
								font: { xl: "700 39px/50px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 39px/50px Lora-Bold" }
							}}>
								Recommended jobs
							</Box>

							<div className="customScroll">
								{formatData(items[ selectedItem ].mission_data, ETab.RECOMMENDED_JOBS)}
							</div>
						</Box>

						<Box sx={{ background: `url(${ IMGActionPlanBackground }) center center/cover` }}>
							<Box sx={{
								color: "primary.main",
								font: { xl: "700 39px/50px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 39px/50px Lora-Bold" }
							}}>
								My action plan
							</Box>

							<Box
								className="customScroll"
								sx={{
									color: "primary.main",

									"& li":
									{
										gap: "11px !important"
									},
									"& li::before":
									{
										backgroundColor: alpha("#FFFFFF", 0.5) + " !important"
									}
								}}
							>
								{formatData(items[ selectedItem ].mission_data, ETab.ACTION_PLAN)}
							</Box>
						</Box>
					</Box>
				)
			}
		</ArtifactsContainer>
	);
}

export default Details;
