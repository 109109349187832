import { useState } from "react";
import WithEmail from "./WithEmail";
import WithGAuth from "./WithGAuth";

export type signUpWith = "email" | "GAuth";

function SignUp()
{
	const [ signUpWith, setSignUpWith ] = useState<signUpWith>("GAuth");

	return signUpWith === "GAuth" ? <WithGAuth setSignUpWith={setSignUpWith} /> : <WithEmail />;
}

export default SignUp;
