import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import RadialButton from "../../../../../components/RadialButton";

const Buttons = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const { setStepClockMorning, handleBlip } = props;

	function handleStep(step: number, key: number)
	{
		setStepClockMorning(step);
		handleBlip(key);
	}

	return (
		<Box ref={ref} {...props} sx={{ position: "relative", width: "700px", m: "140px auto 0px" }}>
			<Box sx={{ position: "absolute", top: "160px" }}>
				<RadialButton
					onClick={() => handleStep(2, 0)}
					value="Peak Moments"
					showValue={false}
					blip={
						!(localStorage.getItem("mission-five-data-morning-environment") !== null ||
							localStorage.getItem("mission-five-data-afternoon-environment") !== null ||
							localStorage.getItem("mission-five-data-evening-environment") !== null ||
							localStorage.getItem("mission-five-data-night-environment") !== null)}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "50px", right: "40%" }}>
				<RadialButton
					onClick={() => handleStep(3, 1)}
					value="Peak Moments"
					showValue={false}
					blip={!(localStorage.getItem("mission-five-data-morning-activities") !== null ||
						localStorage.getItem("mission-five-data-afternoon-activities") !== null ||
						localStorage.getItem("mission-five-data-evening-activities") !== null ||
						localStorage.getItem("mission-five-data-night-activities") !== null)} />
			</Box>
			<Box sx={{ position: "absolute", top: "170px", right: 0 }}>
				<RadialButton
					onClick={() => handleStep(4, 2)}
					value="Peak Moments"
					showValue={false}
					blip={!(localStorage.getItem("mission-five-data-night-active-people") !== null ||
						localStorage.getItem("mission-five-data-morning-active-people") !== null ||
						localStorage.getItem("mission-five-data-afternoon-active-people") !== null ||
						localStorage.getItem("mission-five-data-evening-active-people") !== null)} />
			</Box>
		</Box>
	);
});

export default Buttons;
