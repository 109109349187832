import { Box, Grow } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import IMGBgDefault from "assets/images/missions/mission-three/bg-default.png";
import IMGBgNoTree from "assets/images/missions/mission-three/bg-no-tree.png";
import IMGBgPink from "assets/images/missions/mission-three/bg-pink.png";
import IMGBgPurple from "assets/images/missions/mission-three/bg-purple.png";
import IMGBgYellow from "assets/images/missions/mission-three/bg-yellow.png";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { StepsIndex } from "pages/missionFour";
import { ReactNode, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { IMissionContext, MissionCtx } from "./MissionCtx";
import ClusterYourYearnings from "./components/ClusterYourYearnings";
import ClusterYourYearningsFinal from "./components/ClusterYourYearnings/Final";
import Complete from "./components/Complete";
import IMGClusters from "./components/CreateSomeStructure/image.png";
import DreamUpYourUniverses from "./components/DreamUpYourUniverses";
import DreamUpYourUniversesForm from "./components/DreamUpYourUniverses/Form";
import ItsTimeToDream from "./components/ItsTimeToDream";
import LetsCreateClarityForYou from "./components/LetsCreateClarityForYou";
import MapContainer from "./components/MapContainer";
import MapContainerFinish from "./components/MapContainer/Finish";
import MustHave from "./components/MustHave";
import MustHaveFinish from "./components/MustHave/Finish";
import ReflectParallelUniverses from "./components/ReflectParallelUniverses";
import ReflectParallelUniversesFinish from "./components/ReflectParallelUniverses/finish";
import TimeToPriorities from "./components/TimeToPriorities";
import WhatNext from "./components/WhatNext";
import Feedback from "./components/feedback";

interface IDreamUpYourUniversesForm
{
	title: string;
	fields: string[];
}

export interface IStoreForm
{
	DreamUpYourUniversesForm: { pink: IDreamUpYourUniversesForm; purple: IDreamUpYourUniversesForm; yellow: IDreamUpYourUniversesForm; };
	ReflectParallelUniverses: string;
	LetsReflectOnYourMapForm: [ string, string, string ];
}

export const DEFAULT_STORE_FORM: IStoreForm =
{
	DreamUpYourUniversesForm: { pink: { title: "", fields: [] }, purple: { title: "", fields: [] }, yellow: { title: "", fields: [] } },
	ReflectParallelUniverses: "",
	LetsReflectOnYourMapForm: [ "", "", "" ]
};

export type BackgroundType = Record<"default" | "noTree" | "pink" | "purple" | "yellow", string>;

export const Background: BackgroundType =
{
	default: `url(${ IMGBgDefault }) bottom center/cover`,
	noTree: `url(${ IMGBgNoTree }) bottom center/cover`,
	pink: `url(${ IMGBgPink }) bottom center/cover`,
	purple: `url(${ IMGBgPurple }) bottom center/cover`,
	yellow: `url(${ IMGBgYellow }) bottom center/cover`
};

const BackgroundPreload = [
	IMGBgDefault,
	IMGBgNoTree,
	IMGBgPink,
	IMGBgPurple,
	IMGBgYellow
];

const STEPS = [
	/* Step:  1 */ LetsCreateClarityForYou,
	/* Step:  2 */ ItsTimeToDream,
	/* Step:  3 */ DreamUpYourUniverses,
	/* Step:  4 */ ReflectParallelUniverses,
	/* Step:  5 */ ReflectParallelUniversesFinish,
	/* Step:  6 */ MapContainer,
	/* Step:  7 */ MapContainerFinish,
	/* Step:  8 */ ClusterYourYearnings,
	/* Step:  9 */ ClusterYourYearningsFinal,
	/* Step: 10 */ TimeToPriorities,
	/* Step: 11 */ MustHave,
	/* StepL 12 */ MustHaveFinish,
	/* Step: 13 */ WhatNext,
	// /* Step: 14 */ Finish,
	Complete,
	Feedback
] as const;

const EXTRA_STEPS = [
	[ DreamUpYourUniversesForm, { type: "pink" } ],
	[ DreamUpYourUniversesForm, { type: "purple" } ],
	[ DreamUpYourUniversesForm, { type: "yellow" } ]
] as const;

const PERCENTAGE_PER_STEP: number = 100 / (STEPS.length - 1);

function MissionThreeContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();

	const [ step, setStep ] = useState<number>(() =>
	{
		let step = getLocalStorage<number | string>("mission-three-step");

		if (typeof step === "string")
		{
			step = Number(step);
		}

		return step ?? 1;
	});
	const [ extraStep, setExtraStep ] = useState<number | null>(() =>
	{
		let extraStep = getLocalStorage<number | string>("mission-three-extraStep");

		if (typeof extraStep === "string")
		{
			extraStep = Number(extraStep);
		}

		return extraStep;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: ReactNode; position?: "bottom" | "center"; } | null>(null);

	// Context
	const MissionContext: IMissionContext = useMemo(() =>
	{
		return {
			step,
			setStep,
			extraStep,
			setExtraStep,
			progress,
			setProgress,
			name: userContext.state.user?.name as string
		};
	}, [ extraStep, progress, step, userContext.state.user?.name ]);

	// Utils
	function changeBackground(step: number, extraStep: number | null)
	{
		if (extraStep !== null)
		{
			switch (extraStep)
			{
				case 1: {
					setBackground("pink");

					return;
				}

				case 2: {
					setBackground("purple");

					return;
				}

				case 3: {
					setBackground("yellow");

					return;
				}
			}
		}

		switch (step)
		{
			case 2: {
				setBackground("noTree");
				break;
			}

			default: {
				setBackground("default");
			}
		}
	}

	// Effect
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		// Save step, extraStep to local storage
		setLocalStorage("mission-three-extraStep", extraStep);
		setLocalStorage("mission-three-step", step);

		changeBackground(step, extraStep);

		// Change progress
		setProgress((step - 1) * PERCENTAGE_PER_STEP);

		// Close modal
		setIsShowTips(false);
	}, [ step, extraStep ]);

	useLayoutEffect(() =>
	{
		// Save progress to local storage
		setLocalStorage("mission-three-progress", progress);
	}, [ progress ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(3, step).catch((error) => console.error(error));

		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 2: { // screen 2
				setTipsData(
					{
						title: "Pro Tip",
						content: (
							<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_tips" defaultValue="Because we all are so different, some people find this exercise super easy, and some struggle a bit. If you find it hard, it’s normal — just do your best and don’t overthink it too much!" position="right" isDescription />
						)
					}
				);
				break;
			}

			case 3: { // screen 7-10
				setTipsData(
					{
						title: "Parallel Universes",
						content: (
							<ContentAdmin keyContent="contentMissionThree" keyText="screen_7_tips" defaultValue="In this exercise I want you to use your imagination and fill in the blanks just like in the examples.\n\n**Example 1 - Architect in New York**\nIn this universe, I am an architect. I live in a penthouse in New York with my cat. I make money designing beautiful buildings. In my free time, I travel and eat out. The best thing about my life is that I can leave a legacy behind through my work.\n\n**Example 2 - Surfing Instructor**\nIn this universe, I am a surfing instructor. I live in a shared house by the beach with fellow surfers. I make money teaching surfing and taking part in competitions. In my free time, I compose music and go on hikes. The best thing about my life is that I can spend lots of times outdoors." position="right" isDescription />
						)
					}
				);
				break;
			}

			case 6: { // screen 14-16

				setTipsData(
					{
						title: "Pro Tip",
						content: (
							<ContentAdmin keyContent="contentMissionThree" keyText="screen_14_tips" defaultValue="Without thinking too much, write everything that you want or ever wanted in life. Forget about logic or structure at this point. Just get it all out of your head. Think about things you want to have, do, experience, achieve, become." position="right" isDescription />
						)
					}
				);
				break;
			}

			case 8: { // screen 18a

				setTipsData(
					{
						content: (
							<>
								<ContentAdmin keyContent="contentMissionThree" keyText="screen_18a_tips" defaultValue="Clustering means dragging close to each other those yearnings that are similar. For instance, Yoga, Breathing Exercises & Tai-Chi can all Form a ‘Mind and Body’ cluster.\n\nThere is no right or wrong way to do it, it should make sense to you. I recommend having between 5 and 7 clusters. How you name your clusters is all up to you." position="right" isDescription />

								<Box component="img" src={IMGClusters} sx={{ margin: "auto auto 0 auto", height: "325px", width: "653px" }} />
							</>
						)
					}
				);
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}
	}, [ step ]);

	// Render
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<MissionCtx.Provider value={MissionContext}>
				<Box sx={{
					width: "100%",
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: "24px",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					padding: { xl: step === 15 || step === 14 ? 0 : "24px", lg: step === 15 || step === 14 ? 0 : "16px", xs: step === 15 || step === 14 ? 0 : "16px" },
					zIndex: "10",
					transition: "linear 300ms background"
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 14 || step === 15 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							maxHeight: { xl: "48px", lg: "32px", xs: "32px" },
							display: step === 15 || step === 14 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "15px",
							alignItems: "center",
							width: "100%"
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={STEPS.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber as StepsIndex)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {STEPS.length}
								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
							{tipsData !== null &&
								(
									<TooltipStyled title="Tips" placement="bottom" arrow>
										<TipsButton
											// size={80}
											blip={isBlipTips}
											offBlipOnClick={false}
											onClick={() => { setIsShowTips(true); setIsBlipTips(false); }}
										/>
									</TooltipStyled>
								)
							}

							<MissionProgressBar value={progress} />
						</Box>
					</Box>

					{/* Main */}
					<Box
						component="main"
						className="main"
						sx={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
							gap: "25px",
							"& .main__content":
							{
								maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
								width: "100%",
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								gap: "20px"
							}
						}}
					>
						{/* <TransitionGroup className="TransitionGroup"> */}
						{extraStep !== null &&
							EXTRA_STEPS.map(([ Component, props ], index) =>
							{
								if (extraStep !== index + 1)
								{
									return false;
								}

								// @ts-ignore
								return (<Component key={index} {...props} />);

								// return (
								// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
								// 		<Component />
								// 	</Grow>
								// );
							})
						}

						{extraStep === null &&
							STEPS.map((Component, index) =>
							{
								if (step !== index + 1)
								{
									return false;
								}

								// @ts-ignore
								return (<Component key={index} />);
								// return (
								// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
								// 		<Component />
								// 	</Grow>
								// );
							})
						}
						{extraStep === null && step > STEPS.length &&
							(
								<Grow timeout={{ enter: 1000, exit: 0 }} in unmountOnExit><h1>Step: {step} / {STEPS.length}</h1></Grow>
							)
						}
						{/* </TransitionGroup> */}
					</Box>

					{/* Tips content */}
					{tipsData !== null &&
						(
							<MissionsDialog
								title={tipsData.title}
								open={isShowTips}
								onClose={() => setIsShowTips(false)}
								position={tipsData.position ?? "center"}
							>
								{tipsData.content}
							</MissionsDialog>
						)
					}
				</Box>
			</MissionCtx.Provider>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default MissionThreeContainer;
