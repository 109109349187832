import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import homeBg from "../../../../assets/images/homeBg.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function PhisycalhDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={homeBg} alt="Physical Environment" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Physical Environment
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Your daily comfort makes an enormous difference to your overall sense of happiness and wellbeing. Lacking comfort and joy in your surroundings can significantly hinder your life's fulfilment. What can be done?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescScreen1" />
			</Typography>
			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{/* <Box sx={{ p: '0px 10px 15px' }}>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Is your home comfortable?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Does it bring you joy?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Do you like the area in which you live?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									What can you remove from your life to create more comfort?
								</Typography>
							</Box>
							<Box sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									What can you add to your life to fill it with more joy?
								</Typography>
							</Box>
						</Box> */}
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="Start with your immediate environment — your home, office, car. Small tweaks can be powerful: make your home more cosy, buy comfortable furniture, clean up and organise. Have you done it all and something is still missing?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescStart" />
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0, mt: "-5px" },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Does your environment allow you to do what you really like?\n- Can you attain your dream lifestyle in the area where you live?\n- Does the climate suit you?\n- Does your body feel good where you are?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Your daily happiness matters. It is important your environment supports what you like doing and your desired lifestyle. If you live in a busy city, sitting at your desk all day, no matter how comfortable the desk is, you won’t be happy if you love outdoors and moving your body.\\n\\nFind out what matters to you in life and in your immediate surroundings, and wear your radical honesty hat to answer a question — do you need a change? Life Strategy Quest is a perfect place to start if you want to figure it out." keyContent="contentMissionOne" keyText="physicalEnvironmentDescScreen2" />
				{/*
				Your daily happiness matters. It is important your environment supports what you like doing and your desired lifestyle. If you live in a busy city, sitting at your desk all day, no matter how comfortable the desk is, you won’t be happy if you love outdoors and moving your body.<br></br><br></br>
				Find out what matters to you in life and in your immediate surroundings, and wear your radical honesty hat to answer a question — do you need a change? Life Strategy Quest is a perfect place to start if you want to figure it out.
				*/}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default PhisycalhDetails;
