import { Box, SxProps, Theme } from "@mui/material";
import { minMax } from "@syncfusion/ej2-react-charts";

interface IMissionProgressBarProps
{
	/**
	 * @default 0
	 */
	value?: number;
	sx?: SxProps<Theme>;
}

function MissionProgressBar({ value = 0, sx = [] }: IMissionProgressBarProps)
{
	value = minMax(value, 0, 100);

	return (
		<Box
			className="MissionProgressBar-root"
			sx={[
				{
					display: "flex",
					alignItems: "center",

					"& .MissionProgressBar-bar":
					{
						position: "relative",
						right: "-2px",
						paddingRight: "1px",
						paddingLeft: { xl: "3px", xs: "2px" },
						width: { xs: "151px", xl: "246px" },
						height: { xs: "15px", xl: "20px" },
						border: "2px solid #FFFFFF",
						borderRight: "none",
						borderRadius: "50px 0 0 50px",
						display: "flex",
						alignItems: "center"
					},
					"& .MissionProgressBar-bar__progress":
					{
						position: "relative",
						background: "rgba(255, 255, 255, 1)",
						// background: "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
						borderRadius: (value === 100 ? "50px 0 0 50px" : "50px"),
						height: { xs: "7px", xl: "10px" },
						width: value + "%"
					},
					"& .MissionProgressBar-value":
					{
						"--size": { xs: "32px", xl: "48px" },
						position: "relative",
						zIndex: 10,
						width: "var(--size)",
						height: "var(--size)",
						border: "2px solid #FFFFFF",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						color: "#FFFFFF",
						font: { xs: "400 11px/12px 'Helvetica Neue'", xl: "400 16px/17px 'Helvetica Neue'" }
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Box className="MissionProgressBar-bar">
				<Box className="MissionProgressBar-bar__progress" />
			</Box>

			<Box className="MissionProgressBar-value">
				{Math.round(value)}%
			</Box>
		</Box>
	);
}

export default MissionProgressBar;
