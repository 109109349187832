import { Box } from "@mui/material";
import CustomButton from "components/customButton";
import { useNavigate } from "react-router-dom";
import clearLocalStorage from "utils/clearlocalStorage";
import IMGArtifactsGraph from "../../assets/images/artifacts-graph.png";

function ViewArtifact()
{
	const navigate = useNavigate();

	return (
		<>
			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "#FFFFFF",
				textAlign: "center",
				marginTop: { xl: "unset", lg: "36px" }
			}}>
				You have already completed 2 missions<br />
				of My Life Quest!
			</Box>

			<Box sx={{
				padding: "0 56px",
				marginTop: "64px",
				width: "100%",

				display: "flex",
				gap: "60px"
			}}>
				<Box
					sx={{
						height: { xl: "606px", lg2: "507px", xs: "412px" },
						width: { xl: "1077px", lg2: "900px", xs: "731px" },
						overflow: "hidden",
						borderRadius: { xl: "24px", xs: "16px" }
					}}
				>
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/FcHyyxZJobQ?si=1J3zSo0ycsMduBR0"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</Box>

				<Box sx={{
					width: { xl: "536px", xs: "371px" },
					display: "flex",
					flexDirection: "column"
				}}>
					<Box sx={{
						marginTop: { xl: "-230px", lg2: "-130px", lg: "-130px" },
						marginLeft: { xl: "115px", lg2: "13px", lg: "60px" },
						position: "relative",
						display: "flex",
						alignItems: "center",
						pointerEvents: "none !important",
						userSelect: "none !important",

						"& img":
						{
							width: { xl: "auto", lg2: "430px", lg: "383px" }
						}
					}}>
						<img src={IMGArtifactsGraph} alt="artifacts graph" />
					</Box>

					<Box sx={{
						font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						color: "#FFFFFF",
						textAlign: "center"
					}}>
						You already have 2 artifacts!
					</Box>

					<Box sx={{
						marginTop: "14px",

						font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "#FFFFFF",
						textAlign: "center"
					}}>
						This means you can watch, edit and redo your Wheel of Life and Values any time.
					</Box>

					<CustomButton.Base
						onClick={() =>
						{
							if (process.env.NODE_ENV !== "development")
							{
								clearLocalStorage("mission-two");
							}

							navigate("/dashboard/artifacts");
						}}
						sx={{
							margin: "0 auto",
							marginTop: { xl: "52px", lg2: "72px", xs: "24px" },
							alignSelf: "flex-end",
							display: "flex",
							boxShadow: "unset"
						}}
					>
						View my artifacts
					</CustomButton.Base>
				</Box>
			</Box>
		</>
	);
}

export default ViewArtifact;
