import { Dispatch, SetStateAction } from "react";

function setFieldError<T, D = string>(setState: Dispatch<SetStateAction<T>>, fieldName: keyof T, value: D)
{
	setState((prevState) => (
		{
			...prevState,
			[ fieldName ]:
			{
				...prevState[ fieldName ],
				error: value
			}
		}
	));
}

export default setFieldError;
