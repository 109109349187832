import Api, { getAccessToken } from "api";

export type ByAllMissions = {
	id: string | undefined;
	type: "sub" | "full";
};
export class Transactions
{
	async stripeSubscription(idProduct: string)
	{
		return (
			await Api.instance.post<string>(
				"/transactions/stripeSubscription",
				{ idProduct },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async unsubscribe()
	{
		return (
			await Api.instance.get(
				"/transactions/unsubscribe",
				{
					headers: {
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
	async byAllMissions(data: ByAllMissions)
	{
		return (
			await Api.instance.post(
				"/transactions/byAllMissions",
				{ data },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
