import CancelIcon from "@mui/icons-material/Cancel";
import { Box, FormControl, InputAdornment } from "@mui/material";
import { Formik } from "formik";
import { Ref, forwardRef } from "react";
import * as yup from "yup";
import { IInitialValues } from ".";
import CustomInput from "../../../../components/CustomInput";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

export type ILetsUncoverDeepestSittingBeliefsIAmProps = Record<string, any>;

const INPUT_MAX_LEN: number = 30;

function LetsUncoverDeepestSittingBeliefsIAm({ ...props }: ILetsUncoverDeepestSittingBeliefsIAmProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const initialValues: IInitialValues = getLocalStorage<IInitialValues>("mission-seven-i-am") || { field1: "", field2: "", field3: "" };

	// Handles
	function onSubmit(values: IInitialValues)
	{
		setLocalStorage("mission-seven-i-am", values);
		missionContext.setExtraStep(null);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverDeepestSittingBeliefs_title" defaultValue="Let’s uncover the deepest sitting beliefs!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverDeepestSittingBeliefs_subTitle" defaultValue="You’ve identified many hidden beliefs already.\nLet’s squeeze out those that might still be hiding." position="left" isDescription />}
					sx={{
						marginBottom: "50px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Panel sx={{
					padding: { xl: "40px 60px 40px 30px", lg: "16px 24px 24px 24px", xs: "16px 24px 24px 24px" },
					maxWidth: { xl: "837px", lg: "715px", xs: "715px" }
				}}>
					<CancelIcon
						onClick={() => missionContext.setExtraStep(null)}
						sx={{
							position: "absolute",
							top: "20px",
							right: "20px",
							fill: "#215C75",
							opacity: 1,
							"&:hover": { opacity: 0.3, cursor: "pointer" }
						}}
					/>

					<Box sx={{
						color: "primary.main",
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						margin: "0 16px 8px 16px"
					}}>
						I am…
					</Box>

					<Box sx={{
						color: "primary.dark",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						marginBottom: { xl: "24px", lg: "8px", xs: "8px" },
						marginLeft: "16px"
					}}>
						<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverDeepestSittingBeliefsIAm_description" defaultValue="Write down all ‘I am’ statements you use regularly." position="left" isDescription />
					</Box>

					<Formik
						initialValues={initialValues}
						onSubmit={onSubmit}
						validationSchema={
							yup.object({
								field1: yup.string().min(2, "Minimum 2 characters").required("Field is required"),
								field2: yup.string().min(2, "Minimum 2 characters").required("Field is required"),
								field3: yup.string().min(2, "Minimum 2 characters").required("Field is required")
							})
						}
					>
						{({ values, handleChange, handleSubmit, errors, touched }) => (
							<Box component="form" sx={{ display: "flex", flexDirection: "column", gap: "5px" }} onSubmit={handleSubmit}>
								{
									(
										[
											[ "field1", "e.g. not skilled enough" ],
											[ "field2", "e.g. unlovable" ],
											[ "field3", "e.g. bad with money" ]
										] as [ fieldName: keyof IInitialValues, placeholder: string ][]
									).map(([ fieldName, placeholder ]) =>
									{
										return (
											<label>
												<FormControl fullWidth>
													<CustomInput.Base
														inputProps={{ maxLength: INPUT_MAX_LEN }}
														onChange={handleChange}
														value={values[ fieldName ]}
														name={fieldName}
														placeholder={placeholder}
														startAdornment={<InputAdornment position="start">I am</InputAdornment>}
														sx={{
															boxShadow: "1px 1px 2px rgba(0, 0, 0, .15)",
															height: { xl: "56px", lg: "48px", xs: "48px" },

															"& .MuiInputBase-input":
															{
																paddingLeft: "7px"
															},
															"& .MuiInputAdornment-root p":
															{
																padding: "18px 0 17px 0",
																color: "primary.dark"
															}
														}}
													/>
												</FormControl>

												<Box sx={{
													display: "flex",
													justifyContent: "space-between",
													margin: "0 16px",
													font: "400 14px/19px 'Open Sans'",
													color: "primary.dark"
												}}>
													<Box sx={{ color: "#DC4242" }}>{touched[ fieldName ] ? errors[ fieldName ] : null}</Box>
													<div style={{ opacity: 0.5 }}>{values[ fieldName ].length}/{INPUT_MAX_LEN} characters</div>
												</Box>
											</label>
										);
									})
								}

								<CustomButton.Contained
									type="submit"
									sx={{
										display: "flex",
										margin: "15px auto 0 auto",
										paddingLeft: "80px !important",
										paddingRight: "80px !important"
									}}
								>
									Submit
								</CustomButton.Contained>
							</Box>
						)}
					</Formik>
				</Panel>
			</Box>
		</Box>
	);
}

export default forwardRef(LetsUncoverDeepestSittingBeliefsIAm);
