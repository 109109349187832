import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, TextField, Tooltip, TooltipProps, Zoom } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrow } from "assets/images/icons/chatAccordion-arrow.svg";
import TooltipStyled from "components/TooltipStyled";
import useUser from "hooks/useUser";
import { KeyboardEvent, MouseEvent, createRef, useState } from "react";
import { UserActionType } from "reducers/userReducer";
import convertMarkdown from "utils/convertMarkdown";
import CustomButton from "../customButton";
import MissionsDialog from "../missions/Dialog";

export interface IContentAdminProps
{
	keyContent: string;
	keyText: string;
	defaultValue: string;
	isDescription?: boolean;
	limit?: number;
	position?: TooltipProps[ "placement" ];
	/**
	 * @default 1300
	 */
	zIndex?: number;
	replacer?:
	Record<string, string>;
}

function ContentAdmin({ keyContent, keyText, defaultValue, isDescription, limit, position = "right", zIndex = 1300, replacer = {} }: IContentAdminProps)
{
	const { state } = useUser();
	const textFieldRef = createRef<HTMLTextAreaElement | HTMLInputElement>();
	const [ open, setOpen ] = useState(false);
	const { dispatch: userDispatch } = useUser();
	const [ isHelpShown, setIsHelpShown ] = useState(false);
	const [ helpExpanded, setHelpExpanded ] = useState<"panel1" | "panel2" | "panel3" | "panel4" | "panel5" | "panel6" | false>("panel1");

	const alwaysIsShow: boolean = false;

	// Utils
	function outputStringReplacer(string: string)
	{
		Object.entries(replacer).forEach(([ searchValue, replaceValue ]) =>
		{
			string = string.replaceAll(searchValue, replaceValue);
		});

		string = string.replaceAll("{NAME}", state.user?.name || "Name");
		let html = convertMarkdown(string);

		return html;
	}

	function editorWrapText(prefix: string, suffix: string)
	{
		if (textFieldRef.current === null)
		{
			return;
		}

		const startPos = textFieldRef.current.selectionStart;
		const endPos = textFieldRef.current.selectionEnd;

		if (startPos === null || endPos === null)
		{
			return;
		}

		const selectedText = textFieldRef.current.value.substring(startPos, endPos);

		if (selectedText.trim() === "")
		{
			return;
		}

		let value = textFieldRef.current.value.substring(0, startPos);

		value += prefix + selectedText + suffix;
		const cursorPosition: number = value.length;

		value += textFieldRef.current.value.substring(endPos);

		handleValue(keyContent, keyText, value);
		textFieldRef.current.setSelectionRange(cursorPosition, cursorPosition);
	}

	function isShowTooltipStyledArrow()
	{
		switch (position)
		{
			case "top":
			case "right":
			case "bottom":
			case "left":
				return true;
			default:
				return false;
		}
	}

	// Handles
	function handleValue(keyOpj: string, keyValue: string, value: string)
	{
		const arrData = { ...state.content };

		arrData[ keyOpj ][ keyValue ] = value;

		userDispatch({
			type: UserActionType.SET_CONTENT,
			payload: { content: arrData }
		});
	}

	function handleContent(keyOpj: string)
	{
		const dataValue =
		{
			data: state.content[ keyOpj ],
			key: keyOpj
		};

		Api.admin
			.addContent(dataValue)
			.then(() =>
			{
				setOpen(false);
			})
			.catch((error: any) =>
			{
				console.error(error);
			});
	}

	function handleChange(panel: typeof helpExpanded)
	{
		return (_event: React.SyntheticEvent, isExpanded: boolean) =>
		{
			setHelpExpanded(isExpanded ? panel : false);
		};
	}

	function onTextFieldKeyDown(event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>)
	{
		if (event.ctrlKey === false)
		{
			return;
		}

		switch (event.code)
		{
			// Bold
			case "KeyB": { event.preventDefault(); editorWrapText("**", "**"); break; }

			// Italic
			case "KeyI": { event.preventDefault(); editorWrapText("*", "*"); break; }
		}
	}

	// Render
	return (
		<>
			<Tooltip
				// @ts-ignore
				{...alwaysIsShow === true && { open: true }}
				{...state.user?.userRole !== "admin" && {
					disableFocusListener: true,
					disableHoverListener: true,
					disableInteractive: true,
					disableTouchListener: true,
					open: false
				}}
				arrow={isShowTooltipStyledArrow()}
				componentsProps={{ tooltip: { sx: { backgroundColor: "unset" } }, popper: { sx: { zIndex } } }}
				placement={position}
				TransitionComponent={Zoom}
				title={
					<Box
						component="button"
						onClick={(event) => { event.stopPropagation(); setOpen(true); }}
						sx={[
							{
								backgroundColor: "#3E809D",
								cursor: "pointer",
								border: "unset",
								padding: "10px",
								minWidth: "10px",
								borderRadius: "50%",
								zIndex: 999,
								opacity: 0.7,
								transition: "linear 200ms opacity, linear 200ms background-color",
								"&:hover": { backgroundColor: "#3E809D", opacity: 1 }
							}
						]}
					>
						<ModeEditIcon sx={{ fill: "#fff" }} />
					</Box>
				}
			>
				<Box sx={{ position: "relative", minHeight: "1em", minWidth: "1em" }}>
					{outputStringReplacer(state.content[ keyContent ]?.[ keyText ] || defaultValue)}
				</Box>
			</Tooltip>

			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				onClick={(event: MouseEvent<HTMLDivElement>) =>
				{
					event.stopPropagation();

					if ((event.target as HTMLDivElement).classList.contains("MuiDialog-container"))
					{
						setOpen(false);

						return;
					}
				}}
				sx={{ "& .MuiPaper-root": { maxWidth: "unset" } }}
			>
				<Box sx={{ background: "#E9EDF0", padding: "45px 20px 20px 20px", borderRadius: "20px", width: "600px" }}>
					<Box sx={{ position: "absolute", right: "10px", top: "10px", display: "flex", gap: "5px" }}>
						<TooltipStyled title="Help" placement="bottom" arrow>
							<HelpIcon
								onClick={() => { setIsHelpShown(true); }}
								sx={{
									fill: "#215C75",
									opacity: ".3",
									transition: "linear 200ms opacity",
									"&:hover": { opacity: "1", cursor: "pointer" }
								}}
							/>
						</TooltipStyled>

						<TooltipStyled title="Close" placement="bottom" arrow>
							<CancelIcon
								onClick={() => setOpen(false)}
								sx={{
									fill: "#215C75",
									opacity: ".3",
									transition: "linear 200ms opacity",
									"&:hover": { opacity: "1", cursor: "pointer" }
								}}
							/>
						</TooltipStyled>
					</Box>

					{state.content[ keyContent ]
						? (
							<div>
								<Box sx={{
									display: "flex",
									gap: "5px",
									flexWrap: "wrap",
									marginBottom: "10px",
									"& .MuiButton-root":
									{
										font: "600 13px/16px 'Open Sans'",
										padding: "5px 10px",
										minWidth: "unset",
										minHeight: "unset"
									}
								}}>
									<TooltipStyled
										title="CTRL + B"
										placement="top"
										TransitionComponent={Zoom}
										PopperProps={{ sx: { "& .MuiTooltipStyled-tooltipstyledPlacementTop": { marginBottom: "8px !important" } } }}
										arrow
									>
										<CustomButton.Outline
											sx={{ fontWeight: "bold !important" }}
											onClick={() => editorWrapText("**", "**")}
										>
											Bold
										</CustomButton.Outline>
									</TooltipStyled>

									<TooltipStyled
										title="CTRL + I"
										placement="top"
										TransitionComponent={Zoom}
										PopperProps={{ sx: { "& .MuiTooltipStyled-tooltipstyledPlacementTop": { marginBottom: "8px !important" } } }}
										arrow
									>
										<CustomButton.Outline
											sx={{ fontStyle: "italic !important" }}
											onClick={() => editorWrapText("*", "*")}
										>
											Italic
										</CustomButton.Outline>
									</TooltipStyled>

									<CustomButton.Outline
										sx={{ fontStyle: "italic !important", fontWeight: "bold !important" }}
										onClick={() => editorWrapText("***", "***")}
									>
										Bold and Italic
									</CustomButton.Outline>
								</Box>

								<TextField
									fullWidth
									{...isDescription === true && { multiline: true, rows: 6 }}
									variant="outlined"
									inputRef={textFieldRef}
									inputProps={{
										maxLength: limit,
										autoFocus: true,
										onKeyDown: onTextFieldKeyDown,
										...isDescription === true && { className: "scrollCustom", sx: { paddingRight: "8px" } }
									}}
									defaultValue={defaultValue.replaceAll("\\n", "\n")}
									value={state.content[ keyContent ][ keyText ]}
									onChange={(e) => handleValue(keyContent, keyText, e.target.value)}
									helperText={limit && state.content[ keyContent ][ keyText ] ? `${ state.content[ keyContent ][ keyText ].length }/${ limit }` : null}
								/>
							</div>
						)
						: null
					}

					<Box sx={{ display: "flex", marginTop: "20px", justifyContent: "center" }}>
						<Button onClick={() => handleContent(keyContent)} sx={{ boxShadow: "-5px -5px 10px rgb(255 255 255 / 73%), 5px 5px 10px rgb(0 0 0 / 16%)" }}>Save</Button>
					</Box>
				</Box>
			</Dialog>

			<MissionsDialog
				open={isHelpShown}
				onClose={() => setIsHelpShown(false)}
				onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
			>
				<Box sx={{
					width: "600px",
					"& kbd": {
						backgroundColor: "#215C75",
						color: "#FFFFFF",
						padding: "2px 5px",
						borderRadius: "10px",
						fontSize: "17px"
					},
					"& .MuiAccordion-root":
					{
						background: "none",
						boxShadow: "none",
						padding: "5px",
						borderRadius: 0,
						border: "2px solid #707070",
						"&:not(:last-child)": { borderBottom: "none" },
						"&::before": { display: "none" },
						"&.Mui-expanded": { margin: 0 }
					},
					"& .MuiAccordionSummary-root":
					{
						minHeight: 0,
						padding: 0
					},
					"& .MuiAccordionSummary-root.Mui-expanded":
					{
						padding: 0,
						paddingBottom: "5px",
						borderBottom: "1px dashed #707070",
						minHeight: "auto"
					},
					"& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded":
					{
						margin: 0,
						padding: 0
					},
					"& .MuiAccordionDetails-root":
					{
						padding: "10px 10px",
						"& table":
						{
							width: "100%",
							border: "1px solid #606060",
							borderCollapse: "collapse",
							"& th, td": { padding: "5px", borderBottom: "1px solid #606060", width: "50%" },
							"& td": { borderBottomStyle: "dashed", whiteSpace: "pre-line" }
						}
					},
					"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
					{
						margin: 0,
						transform: "rotate(-90deg)"
					}
				}}>
					<Accordion expanded={helpExpanded === "panel1"} onChange={handleChange("panel1")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Bold <span style={{ marginLeft: "20px" }}><kbd>CTRL</kbd> + <kbd>B</kbd></span>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								"I just love **bold text**.",
								"I just love __bold text__.",
								"Love**is**bold"
							])}
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={helpExpanded === "panel2"} onChange={handleChange("panel2")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Italic <span style={{ marginLeft: "15px" }}><kbd>CTRL</kbd> + <kbd>I</kbd></span>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								"Italicized text is the *cat's meow*.",
								"Italicized text is the _cat's meow_.",
								"A*cat*meow"
							])}
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={helpExpanded === "panel3"} onChange={handleChange("panel3")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Bold and Italic
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								"This text is ***really important***.",
								"This text is ___really important___.",
								"This text is __*really important*__.",
								"This text is **_really important_**.",
								"This is really***very***important text."
							])}
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={helpExpanded === "panel4"} onChange={handleChange("panel4")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Creating a ordered list
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								`1. *First* item
								2. **Second** item
								3. ***Third*** item
								4. Fourth item`,
								`1. *First* item
								1. **Second** item
								1. ***Third*** item
								1. Fourth item`,
								`1. *First* item
								8. **Second** item
								3. ***Third*** item
								5. Fourth item`
							])}
						</AccordionDetails>
					</Accordion>

					<Accordion expanded={helpExpanded === "panel5"} onChange={handleChange("panel5")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Creating a list
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								`- *First* item
								- **Second** item
								- ***Third*** item
								- Fourth item`
							])}
						</AccordionDetails>
					</Accordion>

					{/* <Accordion expanded={helpExpanded === "panel6"} onChange={handleChange("panel6")}>
						<AccordionSummary expandIcon={<SVGArrow />}>
							<Box sx={{ color: "#4D4D4D", font: "700 22px/30px 'Open Sans'" }}>
								Link
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							{HelpTableOutput([
								`[link](https://google.com)`
							])}
						</AccordionDetails>
					</Accordion> */}
				</Box>
			</MissionsDialog>
		</>
	);
}

function HelpTableOutput(values: string[])
{
	return (
		<Box component="table">
			<tr>
				<th>Input</th>
				<th>Output</th>
			</tr>

			{/* eslint-disable-next-line react/destructuring-assignment */}
			{values.map((item) => (
				<tr key={item}>
					<td>{item}</td>
					<td>{convertMarkdown(item)}</td>
				</tr>
			))}
		</Box>
	);
}

export default ContentAdmin;
