import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography } from "@mui/material";

function TermsConditions()
{
	return (
		<Box sx={{ background: "#236d8e" }}>
			<Box sx={{ width: { xs: "95%", sm: "70%" }, m: "0 auto", pb: 16 }}>
				<Typography
					sx={{
						color: "#fff",
						fontSize: { xs: "32px", sm: "45px", xl: "60px" },
						fontFamily: "Lora-Bold",
						textAlign: "center",
						mb: 6,
						pt: 16
					}}
				>
					Terms & Conditions
				</Typography>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					General Notice:
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Terminology applicable to these specific Terms and Conditions, as
						well as any and all Agreements based thereupon, is as follows: “The
						Customer”, “Member”, “Your” and “You” refers directly to you, as the
						individual making use of this website, and the individual who
						accepts these Terms and Conditions.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						MyLifeQuest.io shall henceforth be referred to as “Company”,
						“Website”, “Us” and “We”. We are a platform that guides our
						customers to a life purpose and helps them reach their full
						potential.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						The purpose of this document is to outline and govern the
						relationship between you and us. Your use of and access to this
						website, as well as the services it offers (known collectively as
						the “Services”) is subject to the notices, terms and conditions
						detailed below (known collectively as the “Terms of Service”). Your
						use of the Services constitutes acceptance of these Terms of
						Service, which may from time to time be updated by us as needs
						dictate.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						We provide access to this website on a temporary basis. Our right to
						amend or withdraw the Services at any time without notice is
						reserved. We cannot be held liable for the unavailability of this
						website for any period, at any time. Links to external websites not
						operated by MyLifeQuest.io may appear on this website (known as
						“Linked Sites”). We have no control over the content or function of
						Linked Sites, and as such cannot accept responsibility for any
						damage or losses incurred by your usage of them. Any usage you make
						of Linked Sites is subject to the terms of service provided by each
						individual Linked Site.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Prohibitions
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						You agree not to misuse or conduct malicious activity on this
						Website. Without limitation, you are prohibited from committing or
						encouraging any criminal offense; distributing or transmitting
						worms, viruses, Trojan horses, logic bombs or other malicious or
						harmful computer programs or coding; distributing or transmitting
						material which is deemed obscene or offensive; distributing or
						transmitting confidential data; hacking or interrupting the Service
						in any manner; deliberately corrupting data; causing annoyance or
						disruption to other users; committing any manner of intellectual
						property theft; transmitting or distributing unsolicited
						advertisements or other promotional materials that would constitute
						‘spam’; or attempting to affect the functionality or performance of
						any computer hardware or software belonging to or accessed via this
						website.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Breaching these provisions is an offense, and it is the right and
						obligation of MyLifeQuest.io to report breaches to law enforcement
						agencies. If we deem such reporting necessary, we have the right to
						divulge your identity as part of the report.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Eligibility
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Your presence on and use of this website is your statement and
						confirmation that you have never previously been blocked, banned or
						suspended from the Site. You additionally state and confirm that:
						(1) the information submitted by you while contacting us is 100%
						accurate; (2) you will maintain the accuracy of your data and will
						notify us as soon as you change the information; or (3) you will not
						violate the policies and regulations listed in the agreement. You
						confirm that if your country’s laws prohibit your use of this site
						under these Terms, you will not use it.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Furthermore, you state that you will not, under any circumstances,
						make use of this website for illegal activities, listed as without
						limitation finance of terrorism, sponsorship of violent activities,
						extortion, data ransoming, blackmail, fraud, money laundering or
						illegal gambling.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Services
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						We offer an online digital journey to help you find purpose in life
						and design a fulfilling life. Next to the 10-step quest, we offer
						additional exercises to help you reflect on your progress and plan
						the next steps.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Termination
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						MyLifeQuest.io reserves the right to terminate or withdraw your
						access to or usage of this Website at any time, or no or any reason.
						In the event of termination or withdrawal, you agree that you shall
						pay immediately all sums due to MyLifeQuest.io in relation to any
						Services ordered. All accrued obligations, remedies, liabilities or
						rights will remain unaffected including without limitation our right
						to claim damages in relation to these Terms being breached, or our
						right to indemnification by you in accordance with these Terms.
						Additionally, provisions made by these Terms that have effect after
						termination either by implication or expressly will continue to
						remain in effect and full force.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Liabilities and Disclaimers
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						All material displayed by this website is not covered by any
						warranty, conditions or guarantee regarding its accuracy. Unless
						express statement is provided to the contrary, and to the maximum
						degree that the law permits, MyLifeQuest.io and all associated
						content providers, advertisers and suppliers hereby expressly and
						completely exclude without limitation all warranties, conditions
						and/or other terms that might otherwise be implied by the
						legislation, or the regulations of equity, and disclaim liabilities
						for damages, including but not limited to indirect, direct,
						consequential, special, incidental or punitive damages, damages for
						loss of profit, loss of data, loss of use or other intangibles,
						damages to reputation or goodwill, procurement cost for alternative
						services or goods necessitated by or related to the inability to
						use, use of, failures or other performance of this website or any
						Linked Sites and material posted thereupon, regardless of whether
						any such damages were foreseeable or arose in equity, tort,
						contract, restitution, at common law, by statute or otherwise.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Intellectual Property Rights
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						All intellectual property rights pertaining to content (including
						images) and software available through the use of this Website
						remain with MyLifeQuest.io or licensors thereof, and are protected
						by global copyright treaties and laws. We reserve these rights.
						Permission is granted to you for storage, printing and display of
						the content for personal use, by you, only. You are prohibited from
						publishing, distributing, manipulating or otherwise reproducing the
						content of this website in any format for any purpose. You are
						prohibited from using the content for business or professional
						purposes.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Miscellaneous
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						In the event that the provisions of these Terms are found to be
						unenforceable or invalid, such provisions are to be held to reflect,
						as closely as possible, the original intent of the Terms in
						accordance with applicable laws, and the remainder of any
						enforceable aspect of the Terms is to remain in effect and full
						force.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default TermsConditions;
