import { Box } from "@mui/material";
// import IMGStar from 'assets/images/missions/mission-eight/star.png';
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionNine/hooks";
import Rezult from "./feature/rezult";

export default function SetCourseRezult()
{
	const missionContext = useMission();

	function onClickNextStep()
	{

		missionContext.setStep((prevStep) => prevStep + 1);
		missionContext.setBackground("morningStar");
	}

	return (
		<>
			<Box
				sx={{
					height: "85vh",
					width: "100%",
					maxWidth: "980px",
					m: "0 auto",
					position: "relative",
					top: "-12px",
					left: "-25px"
				}}
			>
				<Box
					sx={{
						maxWidth: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column"
					}}>
					<PanelTitle
						color="#ffffff"
						positionOcta
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionNine"
							keyText="you_set_course_rezult_title"
							defaultValue="{NAME}’s North Star"
							position="right"
						/>
						}
						subTitle={<ContentAdmin
							limit={300}
							keyContent="contentMissionNine"
							keyText="you_set_course_rezult_sub_title"
							defaultValue="Having a dream goal can help us to stay focused and motivated. It provides us with a clear direction and a sense of purpose."
							position="right"
							isDescription
						/>
						}
					/>
					{missionContext.showContent
						? (
							<Box sx={{
								display: "flex",
								width: "100%",
								height: "auto",
								m: "0 auto",
								marginTop: "71px",
								marginLeft: "37px",
								border: "1px solid rgba(255, 255, 255, .6)",
								borderRadius: "24px",
								wordBreak: "break-all",
								flexDirection: "column",
								background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
								backdropFilter: "blur(25px)",
								boxShadow: "0px 5px 10px 0px #A7C7E199",
								padding: "24px 26px 35px 24px"
							}}>
								{/* <Box component="img"
								src={IMGStar}
								sx={{
									position: 'absolute',
									left: 'calc(50% - 278px)',
									top: '-183px',
								}}
							/> */}

								<Rezult />
							</Box>
						)
						: null
					}

				</Box>
			</Box>
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					sx={{ width: "56px", height: "56px", minWidth: "56px", background: "#E9EDF0" }}
					direction="right"
					onClick={onClickNextStep}
					sxIcon={{ fill: "#215C75" }}
				/>
			</StepperNavigationWrapper>
		</>
	);
}
