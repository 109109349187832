import { Button } from "@mui/material";
import arrow from "../../assets/images/arrow.svg";

function ButtonStyled({ fullWidth, title })
{

	return (
		<Button sx={{
			background: "#3E809D",
			color: "#fff",
			fontFamily: "Open Sans",
			fontSize: { xs: "22px", sm: "24px" },
			width: { xs: fullWidth ? "100%" : "60px", sm: fullWidth ? "100%" : "80px" },
			borderRadius: fullWidth ? "160px" : "50%",
			height: { xs: fullWidth ? "60px" : "60px", sm: fullWidth ? "60px" : "80px" },
			textTransform: "none",
			fontWeight: "bold",
			"&:hover": {
				background: "#3E809D"
			}
		}}
		>
			{fullWidth ? title : <img src={arrow} alt="arrow" />}
		</Button>
	);
}

export default ButtonStyled;
