import { Box, CircularProgress } from "@mui/material";
import Api from "api";
import { useCallback, useState } from "react";
import IMGMissionLarge4 from "../../../../assets/images/missions/mission-six/mission-four-large.png";
import ContentAdmin from "../../../../components/contentAdmin";
import ImageSideModal from "../../../missionSix/components/ImageSideModal";
import useMission from "../../hooks/useMission";

export interface IMission2Props
{
	isOpen: boolean;
}

function ModalMission4Data({ isOpen = false }: IMission2Props)
{
	const missionContext = useMission();
	const [ data, setData ] = useState<string | null>(null);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	const onOpen = useCallback(() =>
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.missions
			.getDataMissionFour()
			.then(({ data }) =>
			{

				const dataIndex = data.findIndex(({ mission_data }) =>
				{
					return mission_data?.personalJobAdText !== undefined;
				});

				if (dataIndex !== -1)
				{
					return setData(data[ dataIndex ].mission_data!.personalJobAdText);
				}

				setIsErrorLoading(true);
			})
			.catch((error) =>
			{
				setIsErrorLoading(true);
				console.error(error);
			})
			.finally(() => setIsFetch(false));
	}, []);

	// Render
	return (
		<ImageSideModal
			open={isOpen}
			onClose={() => missionContext.setShowMissionInfo(null)}
			image={IMGMissionLarge4}
			title={<ContentAdmin keyContent="contentMissionSix" keyText="modal_jobLove_title" defaultValue="A Job to Love" position="left" />}
			subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="modal_jobLove_subTitle" defaultValue="You reflected on what jobs might be a great fit for you and outlined your requirements in a Personal Job Ad." position="left" isDescription />}
			onOpen={onOpen}
		>
			<Box sx={{ color: "primary.main", font: "700 24px/38px Lora-Bold" }}>
				{isFetch === true &&
					(
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
							Loading data <CircularProgress size={24} />
						</Box>
					)
				}
				{isFetch === false && isErrorLoading === true &&
					(
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
							Failed to retrieve data, please try again
						</Box>
					)
				}
				{data !== null && data}
			</Box>
		</ImageSideModal>
	);
}

export default ModalMission4Data;
