import { alpha, Box, BoxProps, capitalize, Grow, Slide, SlideProps, Snackbar } from "@mui/material";
import AlertStyled from "components/AlertStyled";
import { ChangeEvent, forwardRef, ForwardRefExoticComponent, Ref, useState } from "react";
import { IPointInfo } from ".";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomInput from "../../../../components/CustomInput";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import deepClone from "../../../../utils/deepClone";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import { ReactComponent as SVGArrowHorizontal } from "../../assets/images/arrow-horizontal.svg";
import { ReactComponent as SVGArrowVertical } from "../../assets/images/arrow-vertical.svg";
import { ReactComponent as SVGIconEmotionPeaks } from "../../assets/images/emotion-peaks.svg";
import { ReactComponent as SVGIconEmotionValleys } from "../../assets/images/emotion-valleys.svg";
import useMission from "../../hooks/useMission";

export interface INowNameYourHighsLowsProps extends BoxProps { }

function NowNameYourHighsLows({ ...props }: INowNameYourHighsLowsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);
	const [ pointsInfo, setPointsInfo ] = useState<IPointInfo[]>(() =>
	{
		const store = getLocalStorage<IPointInfo[]>(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo");

		if (store === null)
		{
			missionContext.setStep(4);

			return [];
		}

		return store;
	});

	// Utils
	function isPointsFilled()
	{
		return pointsInfo.every(({ data }) => data.label.length >= 2);
	}

	// Handles
	function nextStep()
	{
		if (isPointsFilled() === false)
		{
			return setIsShowAlert(true);
		}

		setLocalStorage(
			missionContext.missionPrefix + "-lifeHighsLows-pointsInfo",
			pointsInfo.map((pointInfo) =>
			{
				pointInfo.data.label = pointInfo.data.label.trim();

				return pointInfo;
			})
		);

		missionContext.nextStep();
	}

	function onChange(index: number)
	{
		return ({ target }: ChangeEvent<HTMLInputElement>) =>
		{
			setPointsInfo((prevState) =>
			{
				const points = deepClone(prevState);

				points[ index ].data.label = capitalize(target.value);

				return points;
			});
		};
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", gap: "44px !important" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="NowNameYourHighsLows_title" defaultValue="Now name your Highs & Lows" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="NowNameYourHighsLows_subTitle" defaultValue="Now let’s name your Highs & Lows" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Panel sx={{ padding: { xl: "32px 24px 32px 24px", xs: "24px" } }}>
					<Box sx={{
						font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						color: "primary.main",
						marginLeft: "63px",
						marginBottom: "22px"
					}}>
						<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_panelTitle" defaultValue="{NAME}'s life journey to date" position="left" />
					</Box>

					<Box sx={{
						marginRight: { xl: "40px", xs: "24px" },
						display: "grid",
						gridTemplateColumns: "auto 1fr",
						gridTemplateRows: "1fr auto",
						gap: "14px"
					}}>
						{/* Left arrow */}
						<Box className="leftArrow" sx={{ height: "100%", width: "49px", display: "flex" }}>
							<Box sx={{
								writingMode: "vertical-rl",
								transform: "rotate(-180deg)",
								color: "primary.main",
								font: "700 18px/25px 'Open Sans'",
								textTransform: "uppercase",
								textAlign: "center"
							}}>
								Life Satisfaction
							</Box>

							<Box sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "space-between",
								position: "relative",
								left: "-10px"
							}}>
								<SVGIconEmotionPeaks />
								<Box component={SVGArrowVertical} sx={{ height: { xl: "320px", xs: "230px" } }} />
								<SVGIconEmotionValleys />
							</Box>
						</Box>

						{/* Graph */}
						<Box
							className="graph"
							sx={{
								position: "relative",
								width: "100%",
								height: { xl: "476px", xs: "380px" },
								backgroundColor: alpha("#FFFFFF", 0.5),
								borderRadius: "16px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<img
								src={getLocalStorage<string>(missionContext.missionPrefix + "-lifeHighsLows-graphImage") || ""}
								alt="Graph"
							/>

							{pointsInfo.map(({ x: left, y: top, type, data }, index) =>
							{
								return (
									<Box
										key={index}
										sx={{
											position: "absolute",
											left,
											top,
											transform: "translateX(-50%) translateY(-75%) !important",

											"&:hover": { zIndex: 2 }
										}}
									>
										<Grow in timeout={{ enter: 500 + (index * 500) }}>
											<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
												<CustomInput.Base
													value={data.label}
													placeholder={capitalize(type.slice(0, -1) + " " + (Math.floor(index / 2) + 1))}
													onChange={onChange(index)}
													sx={{
														width: "197px",
														height: "40px",
														backgroundColor: "#FFFFFF",
														boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
														borderRadius: "40px",

														"& .MuiInputBase-input":
														{
															padding: "9px 22px",
															font: "400 16px/22px 'Open Sans'",
															textAlign: "center"
														}
													}}
												/>
												<Box
													sx={{
														width: "16px",
														height: "16px",
														backgroundColor: "#3E809D",
														border: "2px solid #FFFFFF",
														borderRadius: "50%"
													}}
												/>
											</Box>
										</Grow>
									</Box>
								);
							})}
						</Box>

						{/* Bottom arrow */}
						<Box className="bottomArrow" sx={{ gridColumn: "2", height: "44px", display: "flex", flexDirection: "column" }}>
							<Box sx={{
								color: "primary.main",
								font: "700 18px/25px 'Open Sans'",
								textTransform: "uppercase",
								height: "100%",
								textAlign: "center",
								position: "relative",
								top: "-5px",
								order: 2
							}}>
								Time
							</Box>

							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<Box sx={{
									color: "primary.main",
									font: "700 18px/25px 'Open Sans'",
									textTransform: "uppercase",
									textAlign: "center"
								}}>
									Birth
								</Box>

								<SVGArrowHorizontal width="100%" />

								<Box sx={{
									color: "primary.main",
									font: "700 18px/25px 'Open Sans'",
									textTransform: "uppercase",
									textAlign: "center"
								}}>
									Now
								</Box>
							</Box>
						</Box>
					</Box>
				</Panel>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" onClick={() => missionContext.prevStep()} />
				<ArrowButton
					direction="right"
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>

			<Snackbar
				open={isShowAlert}
				autoHideDuration={5000}
				onClose={() => setIsShowAlert(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "140px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
					You have {pointsInfo.filter(({ data }) => data.label.length < 2).length} unfilled fields
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default forwardRef(NowNameYourHighsLows) as ForwardRefExoticComponent<INowNameYourHighsLowsProps>;
