import { Box, Fade } from "@mui/material";
import Api from "api";
import bgSteper from "assets/images/bgSteper.png";
import ArrowButton from "components/ArrowButton";
import CustomInput from "components/CustomInput";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginAdmin()
{
	const navigate = useNavigate();

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
	const [ password, setPassword ] = useState<string>("");

	// Handles
	function submitPost(event: FormEvent)
	{
		event.preventDefault();

		if (isFetch === true)
		{
			return;
		}

		if (password.length < 8)
		{
			return setErrorMessage("Password must be contain minimum 8 char");
		}

		setIsFetch(true);
		setErrorMessage(null);

		Api.admin
			.login(password)
			.then(() =>
			{
				sessionStorage.setItem("admin-password", password);
				navigate("/admin/users");
			})
			.catch((error) =>
			{
				console.error(error);
				setErrorMessage("Incorrect Password");
			})
			.finally(() => setIsFetch(false));
	}

	return (
		<Box sx={{
			background: `url(${ bgSteper }) no-repeat`,
			height: "100vh",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<Box sx={{ font: { sm: "700 42px/56px Lora-Bold", xs: "700 24px/31px Lora-Bold" }, color: "#FFFFFF" }}>
				Login
			</Box>

			<Box component="form" onSubmit={submitPost} sx={{ marginTop: "40px" }}>
				<CustomInput.Password
					autoComplete="current-password"
					placeholder="Password"
					value={password}
					onChange={({ target }) =>
					{
						setPassword(target.value.trim());
						setErrorMessage(null);
					}}
					sx={{ width: "350px" }}
				/>

				<Fade in={errorMessage !== null}>
					<Box sx={{
						paddingLeft: "5px",
						color: "#DC4242",
						font: "400 14px/19px 'Open Sans'"
					}}>
						{errorMessage}
					</Box>
				</Fade>

				<ArrowButton type="submit" sx={{ display: "flex", margin: "40px auto 0 auto" }} />
			</Box>
		</Box>
	);
}

export default LoginAdmin;
