import { Box, Chip, Grid, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import IMGTree1 from "../../../../assets/images/missions/mission-nine/MapContainer/tree-1.png";
import IMGTree2 from "../../../../assets/images/missions/mission-nine/MapContainer/tree-2.png";
import IMGTree4 from "../../../../assets/images/missions/mission-nine/MapContainer/tree-4.png";
import IMGDeer from "../../../../assets/images/missions/mission-nine/deer.png";
import IMGDog from "../../../../assets/images/missions/mission-nine/dog.png";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import MissionsDialog from "../../../../components/missions/Dialog";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import capitalizeFirstLetter from "../../../../utils/capitalizerFirstLetter";
import { pixelToVw } from "../../../../utils/pixelToVw";
import useMission from "../../hooks/useMission";
import AnimalTooltip from "../AnimalTooltip/index";
import styles from "./MapContainer.module.css";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
		backdropFilter: "blur(25px)",
		padding: "32px 37px",
		maxWidth: "355px",
		border: "1px solid rgba(255, 255, 255, 0.5)",
		boxShadow: "0px 5px 10px rgba(167, 199, 225, 0.6)",
		borderRadius: "24px",
		color: "#215C75",
		font: '700 24px/31px "Lora-Bold"'
	}
}));

function MapContainer()
{
	const missionContext = useMission();
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ error, setError ] = useState<string>("");
	const [ showContent, setShowContent ] = useState(false);
	const [ chipIndex, setChipIndex ] = useState(0);
	const [ openHint, setOpenHint ] = useState<null | 1 | 2 | 3 | 4 | 5 | 6 | 7>(null);
	const [ animationStatus, setAnimationStatus ] = useState<[ boolean, boolean, boolean, boolean, boolean ]>(
		[ true, true, true, true, true ]
	);
	const [ arrValues, setArrValues ] = useState<Set<string>>(() =>
	{
		const store = localStorage.getItem("mission-nine-wantInLine");
		if (store === null)
		{
			return new Set();
		}

		return new Set(JSON.parse(store));
	});
	const [ showAnimalTooltip, setShowAnimalTooltip ] = useState<[ boolean, boolean, boolean ]>([ false, false, false ]);

	const defaultChips = [
		{
			id: 0,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_0" defaultValue="Travel South America" position="top" />,
			top: "0px",
			left: "calc(50% - 100px)",
			right: "auto"
		},
		{
			id: 1,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_1" defaultValue="Spend time with friends" position="top" />,
			top: "58px",
			left: "40px",
			right: "auto"
		},
		{
			id: 2,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_2" defaultValue="Learn Spanish" position="top" />,
			top: "30px",
			left: "auto",
			right: "51px"
		},
		{
			id: 3,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_3" defaultValue="Swim with whales in Tonga" position="top" />,
			top: "118px",
			left: "auto",
			right: "288px"
		},
		{
			id: 4,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_4" defaultValue="Avoid stress" position="top" />,
			top: "120px",
			left: "auto",
			right: "17px"
		},
		{
			id: 5,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_5" defaultValue="Paint, dance, write" position="top" />,
			top: "156px",
			left: "0px",
			right: "auto"
		},
		{
			id: 6,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_6" defaultValue="Take a year off work" position="top" />,
			top: "210px",
			left: "auto",
			right: "288px"
		},
		{
			id: 7,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_7" defaultValue="Read all books on my list" position="top" />,
			top: "238px",
			left: "auto",
			right: "0px"
		},
		{
			id: 8,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_8" defaultValue="House in the nature" position="top" />,
			top: "271px",
			left: "38px",
			right: "auto"
		},
		{
			id: 9,
			label: <ContentAdmin limit={30} keyContent="contentMissionNine" keyText="lots_ideas_example_9" defaultValue="Get motorcycle license" position="top" />,
			top: "324px",
			left: "auto",
			right: "206px"
		}
	];

	// Handles
	function onShowAnimalTooltip(index: number)
	{
		if (index > showAnimalTooltip.length - 1)
		{
			return;
		}

		setShowAnimalTooltip((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: true });
		});
	}

	function onHideAnimalTooltip(index: number)
	{
		if (index > showAnimalTooltip.length - 1)
		{
			return;
		}

		setShowAnimalTooltip((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: false });
		});
	}

	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 50)
		{
			return setError("The text should not exceed 50 characters");
		}

		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);
			const stateSize = state.size;

			state.add(capitalizeFirstLetter(valueInput.trim()));
			if (state.size === stateSize)
			{
				return prevState;
			}

			localStorage.setItem("mission-nine-wantInLine", JSON.stringify(Array.from(state)));

			return state;
		});
		setValueInput("");
	}

	function deleteItemArrValues(item: string)
	{
		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);

			if (state.delete(item) === false)
			{
				return prevState;
			}

			localStorage.setItem("mission-nine-wantInLine", JSON.stringify(Array.from(state)));

			return state;
		});
	}

	function handlerOpenHint(index: Exclude<typeof openHint, null>)
	{
		setOpenHint(index);

		setAnimationStatus((prevState) => (
			Object.assign([], prevState, { [ index - 1 ]: false })
		));
	}

	function handleChip()
	{
		onHideAnimalTooltip(0);
		setShowContent(true);
		setChipIndex(pre => pre === 9 ? 0 : pre + 1);
	}

	// Render
	return (
		<>
			<Box onClick={() => setShowContent(false)} sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
				<Box className="main__content" sx={{ alignItems: "center", position: "relative" }}>
					<Box sx={{ maxWidth: { lg: "814px", xl: "980px" }, display: "flex", flexDirection: "column" }}>
						<PanelTitle
							positionOcta
							imageVariation="closeEye"
							title={<ContentAdmin limit={111} keyContent="contentMissionNine" keyText="lots_ideas_title" defaultValue="Let’s come up with LOTS of ideas" position="right" />}
							subTitle={<ContentAdmin
								limit={300}
								keyContent="contentMissionNine"
								keyText="lots_ideas_sub_title"
								defaultValue="The purpose of this step is to come up with as many ideas as you can for what you can do to live a more fulfilling life."
								position="right"
								isDescription
							/>}
							sx={{ marginTop: "16px" }}
						/>
						{missionContext.showContent
							? (
								<>
									<Box sx={{
										// p: '16px 24px 8px',
										borderRadius: "24px",
										boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
										backgroundImage: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
										// backgroundColor: '#D9D9D9',
										backdropFilter: "blur(30px)",
										position: "relative",
										width: "100%",
										m: "67px 0",
										wordWrap: "break-word",
										left: "38px",
										top: "-10px"
									}}>
										<Box sx={{
											p: "16px 24px 2px",
											borderRadius: "24px",
											background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)"

										}}>
											<TooltipStyled title="Press 'Enter' to add" placement="right" arrow>
												<>
													<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
														<Typography sx={{ color: "#215C75", font: { lg: "700 18px/31px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" }, marginLeft: "18px", mb: 1 }}>
															<ContentAdmin
																limit={200}
																keyContent="contentMissionNine"
																keyText="dream_life_title"
																defaultValue="To live a dream life I want to ..."
																position="left"
															/>
														</Typography>
														<Typography
															// sx={{ color: Array.from(arrValues).length < 10 ? "#EE3E3E" : "#215C75", font: "400 16px/22px 'Open Sans'", mr: '18px' }}
															sx={{
																color: Array.from(arrValues).length < 10 ? "red" : "#4D4D4D", font: "400 14px/19px 'Open Sans'", opacity: "0.5", marginRight: "14px"
															}}
														>
															{Array.from(arrValues).length < 10 ? `added ${ Array.from(arrValues).length }/10` : `add between ${ Array.from(arrValues).length } and 50`}
														</Typography>
													</Box>
													<TextField
														value={valueInput}
														fullWidth
														autoFocus
														inputProps={{ maxLength: 30 }}
														onChange={(e: ChangeEvent<HTMLInputElement>) => { setValueInput(e.target.value); setError(""); }}
														onKeyDown={handleAddArrValues}
														disabled={arrValues.size >= 50}
														helperText={`${ valueInput.length }/30 characters`}
														placeholder="Type your dream life ideas here"
														sx={{
															"& .MuiInputBase-root fieldset": {
																border: "none"
															},
															"& .MuiOutlinedInput-root": {
																font: { lg: '400 16px/20px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
																color: "#4D4D4D !important",
																backgroundColor: "rgba(255, 255, 255, 0.6)",
																borderRadius: "8px",
																height: "59px",
																lineHeight: "59px"
															},
															"& .MuiFormHelperText-contained": {
																textAlign: "right",
																font: '400 14px "Open Sans"',
																color: "#4D4D4D",
																opacity: "0.5"
															}
														}}
													/>
													<Typography sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
														{error || (arrValues.size >= 50 && "Limit of 50 has been reached")}
													</Typography>
												</>
											</TooltipStyled>
										</Box>

									</Box>

									{/* Item list */}
									<Box
										className="scrollCustom"
										sx={{
											display: "flex",
											flexWrap: "wrap",
											// justifyContent: "center",
											alignItems: "flex-start",
											alignContent: "flex-start",
											gap: "10px",
											overflow: "auto",
											height: "240px",
											paddingRight: "5px",
											marginTop: "-46px",
											marginLeft: "38px",
											width: "100%"

										}}
									>
										<Grid container spacing={2}>
											{Array.from(arrValues).map((item) => (
												<Grid item>
													<Box sx={{ display: "flex" }}>
														<Chip
															key={item}
															label={item}
															onDelete={() => deleteItemArrValues(item)}
															sx={{
																padding: "10px 14px",
																height: "unset",
																justifyContent: "space-between",
																gap: "10px",
																background: "rgba(255, 255, 255, 0.5)",
																display: "flex",
																boxShadow: " 0px 3px 6px rgba(0, 0, 0, 0.161)",
																borderRadius: "40px",
																"& .MuiChip-label":
																{
																	color: "#4D4D4D",
																	font: "400 16px 'Open Sans'",
																	padding: "0",
																	whiteSpace: "normal",
																	textAlign: "center"
																},
																"& .MuiChip-deleteIcon":
																{
																	margin: "0",
																	fill: "#215C75",
																	opacity: ".3"
																},
																"& .MuiChip-deleteIcon:hover":
																{
																	opacity: "1"
																}
															}}
														/>
													</Box>
												</Grid>
											))}
										</Grid>

									</Box>
								</>
							)
							: null
						}
					</Box>
				</Box>
			</Box>
			{missionContext.showContent
				? (
					<>
						{/* animals */}
						<Box onClick={() => setShowContent(false)} sx={{ zIndex: "100", position: "relative", top: "24px", width: "545px", m: "auto", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
							{showContent
								? (
									<Chip
										label={defaultChips[ chipIndex ]?.label}
										// TransitionComponent={TransitionSlideUp}
										sx={{
											position: "absolute",
											top: "-74px",
											left: "155px",
											width: "auto",
											padding: "17px",
											height: "unset",
											justifyContent: "space-between",
											gap: "10px",
											background: "#ffffff",
											display: "flex",
											boxShadow: " 0px 3px 6px rgba(0, 0, 0, 0.161)",
											borderRadius: "40px",
											"& .MuiChip-label":
											{
												color: "#4D4D4D",
												font: "400 22px/30px 'Open Sans'",
												padding: "0",
												whiteSpace: "normal",
												textAlign: "center"
											},
											"& .MuiChip-deleteIcon":
											{
												margin: "0",
												fill: "#215C75",
												opacity: ".3"
											},
											"& .MuiChip-deleteIcon:hover":
											{
												opacity: "1"
											}
										}}
									/>
								)
								: null
							}
							<Box
								sx={{ position: "absolute", top: { lg: "-20px", xl: "0px" }, left: "0px" }}
								onMouseEnter={() => { !showContent && onShowAnimalTooltip(0); }}
								onMouseLeave={() => { onHideAnimalTooltip(0); }}
							>
								<AnimalTooltip
									bgTooltip="linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)"
									bgBlur
									isShow={showAnimalTooltip[ 0 ]}
									title={<>Click and I will give examples</>}
									leftPos="30px"
								/>
								<Box onClick={(event) => { event.stopPropagation(); handleChip(); }} component="img" src={IMGDeer} alt="deer" sx={{ width: { lg: "168px", xl: "212px" }, height: "auto", cursor: "pointer" }} />
							</Box>

							<Box
								sx={{ position: "absolute", top: { lg: "58px", xl: "103px" }, right: "-40px" }}
								onMouseEnter={() => onShowAnimalTooltip(1)}
								onMouseLeave={() => onHideAnimalTooltip(1)}
							>
								<AnimalTooltip
									bgTooltip="linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)"
									bgBlur
									isShow={showAnimalTooltip[ 1 ]}
									title={<>Click and I'll give you a hint</>}
									leftPos="-70px"
								/>
								<Box onClick={() => handlerOpenHint(6)} component="img" src={IMGDog} alt="dog" sx={{ width: { lg: "112px", xl: "141px" }, cursor: "pointer" }} />
							</Box>

						</Box>
						<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
							<ArrowButton direction="left" disabled hidden />
							<ArrowButton
								direction="right"
								disabled={arrValues.size < 10}
								onClick={onClickNextStep}
							/>
						</StepperNavigationWrapper>
						{/* Tree */}
						<>

							<LightTooltip
								title={<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_1_title" defaultValue="Your next chapter \n (6–12 months horizon)" position="left" />}
								placement="top"
							>
								<Box
									className={animationStatus[ 0 ] ? styles.animationVibrate : ""}
									component="img"
									src={IMGTree1}
									sx={{ "--delay": "100ms", cursor: "pointer", position: "fixed", bottom: { lg: "195px", xl: "210px" }, left: { lg: pixelToVw(320), xl: pixelToVw(530) } }}
									// onClick={() => handlerOpenHint(1)
									onMouseEnter={() => handlerOpenHint(1)}
								/>
							</LightTooltip>

							<LightTooltip
								title={<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_2_title" defaultValue="Major course correction in career, lifestyle, relationship" position="left" />}
								placement="top"
							>
								<Box
									className={animationStatus[ 1 ] ? styles.animationVibrate : ""}
									component="img"
									src={IMGTree2}
									sx={{ "--delay": "0ms", cursor: "pointer", position: "fixed", bottom: "140px", left: { lg: pixelToVw(480), xl: pixelToVw(610) } }}
									onMouseEnter={() => handlerOpenHint(2)}
								/>
							</LightTooltip>

							<LightTooltip
								title={<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_3_title" defaultValue="Daily happiness — your environment, where you live, how you live, your habits, etc." position="left" />}
								placement="top"
							>
								<Box
									className={animationStatus[ 2 ] ? styles.animationVibrate : ""}
									component="img"
									src={IMGTree2}
									sx={{ "--delay": "300ms", cursor: "pointer", position: "fixed", bottom: "75px", right: { lg: pixelToVw(390), xl: pixelToVw(530) } }}
									onMouseEnter={() => handlerOpenHint(3)}
								/>
							</LightTooltip>

							<LightTooltip
								title={<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_4_title" defaultValue="Personal growth — everything you want to do, accomplish, experience" position="left" />}
								placement="top"
							>
								<Box
									className={animationStatus[ 3 ] ? styles.animationVibrate : ""}
									component="img"
									src={IMGTree4}
									sx={{ "--delay": "150ms", cursor: "pointer", position: "fixed", bottom: "-20px", right: { lg: pixelToVw(180), xl: pixelToVw(380) } }}
									onMouseEnter={() => handlerOpenHint(4)}
								/>
							</LightTooltip>

							<LightTooltip
								title={<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_5_title" defaultValue="Dreams — your bucket list" position="left" />}
								placement="top"
							>
								<Box
									className={animationStatus[ 4 ] ? styles.animationVibrate : ""}
									component="img"
									src={IMGTree4}
									sx={{ "--delay": "150ms", cursor: "pointer", position: "fixed", bottom: "45px", left: { lg: pixelToVw(230), xl: pixelToVw(465) } }}
									onMouseEnter={() => handlerOpenHint(5)}
								/>
							</LightTooltip>
						</>
					</>
				)
				: null
			}

			{/* <MissionsDialog
				noIcon={true}
				sx={{
					"& .MuiPaper-root": {
						background: 'linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)',
						backdropFilter: 'blur(25px)',
						padding: '32px 37px',
						maxWidth: '355px',
						zIndex: 9999
					},
					"& .MuiDialog-container": {
						position: 'relative',
						top: '116px',
						left: '-400px',
						right: 'auto',
						zIndex: 9999
					},
				}}
				open={openHint === 1}
				// position="bottom"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ color: "#215C75", font: "700 24px/31px Lora-Bold", marginRight: "20px" }}>
					<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_1_title" defaultValue="Your next chapter \n (6–12 months horizon)" position="left" />
				</Box>
			</MissionsDialog> */}

			{/* <MissionsDialog
				noIcon={true}
				sx={{
					"& .MuiPaper-root": {
						background: 'linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)',
						backdropFilter: 'blur(25px)',
						padding: '32px 37px',
						maxWidth: '490px',
						zIndex: 9999
					},
					"& .MuiDialog-container": {
						position: 'relative',
						top: '-26px',
						left: '-270px',
						right: 'auto',
						zIndex: 9999
					},
				}}
				open={openHint === 2}
				position="bottom"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ color: "#215C75", font: "700 24px/31px Lora-Bold", marginRight: "20px" }}>
					<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_2_title" defaultValue="Major course correction in career, lifestyle, relationship" position="left" />
				</Box>
			</MissionsDialog>
			<MissionsDialog
				noIcon={true}
				sx={{
					"& .MuiPaper-root": {
						background: 'linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)',
						backdropFilter: 'blur(25px)',
						padding: '32px 37px',
						maxWidth: '490px',
						zIndex: 9999
					},
					"& .MuiDialog-container": {
						position: 'relative',
						top: '40px',
						left: 'auto',
						right: '-385px',
						zIndex: 9999
					},
				}}
				open={openHint === 3}
				position="bottom"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ color: "#215C75", font: "700 24px/31px Lora-Bold", marginRight: "20px" }}>
					<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_3_title" defaultValue="Daily happiness — your environment, where you live, how you live, your habits, etc." position="left" />
				</Box>
			</MissionsDialog>
			<MissionsDialog
				noIcon={true}
				sx={{
					"& .MuiPaper-root": {
						background: 'linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)',
						backdropFilter: 'blur(25px)',
						padding: '32px 37px',
						maxWidth: '490px',
						zIndex: 9999
					},
					"& .MuiDialog-container": {
						position: 'relative',
						top: '100px',
						left: 'auto',
						right: '-520px',
						zIndex: 9999
					},
				}}
				open={openHint === 4}
				position="bottom"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ color: "#215C75", font: "700 24px/31px Lora-Bold", marginRight: "20px" }}>
					<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_4_title" defaultValue="Personal growth — everything you want to do, accomplish, experience" position="left" />
				</Box>
			</MissionsDialog>
			<MissionsDialog
				noIcon={true}
				sx={{
					"& .MuiPaper-root": {
						background: 'linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)',
						backdropFilter: 'blur(25px)',
						padding: '32px 37px',
						maxWidth: '398px',
						zIndex: 9999
					},
					"& .MuiDialog-container": {
						position: 'relative',
						top: '33px',
						left: '-430px',
						right: 'auto',
						zIndex: 9999
					},
				}}
				open={openHint === 5}
				position="bottom"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ color: "#215C75", font: "700 24px/31px Lora-Bold", marginRight: "20px" }}>
					<ContentAdmin isDescription keyContent="contentMissionNine" keyText="tree_5_title" defaultValue="Dreams — your bucket list" position="left" />
				</Box>
			</MissionsDialog> */}
			<MissionsDialog
				sxCancelIcon={{
					fill: "#3E809D",
					opacity: "1"
				}}
				title={<ContentAdmin keyContent="contentMissionNine" keyText="dialog_6_title" defaultValue="Ideation Rules" position="left" />}
				open={openHint === 6}
				position="bottom"
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backgroundColor: "transparent",
						backdropFilter: "blur(25px)",
						padding: "32px 37px",
						maxWidth: "980px",
						color: "#4D4D4D"
					},
					"& .MuiDialog-container": {
						position: "relative",
						top: "0px"
					}
				}}
				onClose={() => setOpenHint(null)}
			>
				<Box className="missions-dialog-list" sx={{ font: "400 22px/40px 'Open Sans'" }}>
					<ContentAdmin
						limit={1000}
						keyContent="contentMissionNine"
						keyText="dialog_6_content"
						defaultValue="1. **Quantity over quality:** — to come up with great ideas, you need to have A LOT of ideas first. Go for quantity — come up with as many ideas as you can.\n2. **Use values as design principles** — refer to your list of your values in front of you and refer to them as you ideate.\n3. **Invite wild ideas** — think about what we really want without any constraints.\n4. **No idea is a bad idea** — you will get to narrowing down your ideas and prioritising. Don’t worry about it too much at this stage, any idea is great — no matter how big, small, silly or irrational it is.\n5. **Got stuck? Use your clues** — take a look at your vision board, your your dream job ad and your perfect average day. You may squeeze a few more ideas doing that."
						position="right"
						isDescription
					/>
				</Box>
			</MissionsDialog>
			<MissionsDialog
				noIcon
				sx={{
					margin: "0px auto",
					padding: "0px",
					boxShadow: "none",
					"& .MuiPaper-root": {
						backgroundColor: "transparent",
						backdropFilter: "blur(25px)",
						padding: "32px 37px",
						color: "#4D4D4D"
					},
					"& .MuiDialog-container": {
						position: "relative",
						top: "40px"
					}

				}}
				open={openHint === 7}
				position="bottomPx"
				onClose={() => setOpenHint(null)}
			>
				<Box sx={{ position: "relative", height: "64px", mb: 26, ml: 22 }}>
					<Box
						sx={{ display: "flex" }}
					// sx={{ position: 'absolute', top: `${ defaultChips[ chipIndex ]?.top }`, left: `${ defaultChips[ chipIndex ]?.left }`, right: `${ defaultChips[ chipIndex ]?.right }`, width: 'auto', }}
					>
						<Chip
							label={defaultChips[ chipIndex ]?.label}
							sx={{
								width: "auto",
								padding: "17px",
								height: "unset",
								justifyContent: "space-between",
								gap: "10px",
								background: "#ffffff",
								display: "flex",
								boxShadow: " 0px 3px 6px rgba(0, 0, 0, 0.161)",
								borderRadius: "40px",
								"& .MuiChip-label":
								{
									color: "#4D4D4D",
									font: "400 22px/30px 'Open Sans'",
									padding: "0",
									whiteSpace: "normal",
									textAlign: "center"
								},
								"& .MuiChip-deleteIcon":
								{
									margin: "0",
									fill: "#215C75",
									opacity: ".3"
								},
								"& .MuiChip-deleteIcon:hover":
								{
									opacity: "1"
								}
							}}
						/>
					</Box>
				</Box>
			</MissionsDialog>
		</>
	);
}

export default MapContainer;
