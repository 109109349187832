import { Box, IconButton, alpha, keyframes } from "@mui/material";
import useMission from "pages/missionTwo/hooks/useMission";
import { useReadLocalStorage } from "utils/localStorage";
import { ToughDecisionsFields, filledFormField } from ".";
import { ReactComponent as SVGCloudBottomLeft } from "../../assets/images/cloud-bottom-left.svg";
import { ReactComponent as SVGCloudBottomRight } from "../../assets/images/cloud-bottom-right.svg";
import { ReactComponent as SVGCloudCenter } from "../../assets/images/cloud-center.svg";
import { ReactComponent as SVGCloudTopLeft } from "../../assets/images/cloud-top-left.svg";
import { ReactComponent as SVGCloudTopRight } from "../../assets/images/cloud-top-right.svg";

export interface IToughDecisionsCloudsProps
{
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

const PREVIEW_OFFSET: number = 5;

const CLOUDS = [
	SVGCloudTopRight,
	SVGCloudCenter,
	SVGCloudTopLeft,
	SVGCloudBottomLeft,
	SVGCloudBottomRight
];

const cloudAnimation = keyframes`
	0%, 100%
	{
		transform: scaleY(1) scaleX(1) translateY(0);
	}
	50%
	{
		transform: scaleY(1.3) scaleX(0.7) translateY(10px);
	}
`;

function ToughDecisionsClouds({ preview = false }: IToughDecisionsCloudsProps)
{
	const missionContext = useMission();
	const toughDecisionsFields = useReadLocalStorage<ToughDecisionsFields>(missionContext.missionPrefix + "-ToughDecisionsFields", []);

	// Handles
	function onClickButton(index: number)
	{
		return () =>
		{
			missionContext.setExtraStep(9 + index + (preview ? PREVIEW_OFFSET : 0));
		};
	}

	// Render
	return (
		<>
			{(toughDecisionsFields.map(([ fieldName ], index) =>
			{
				const positions: [
					top: Partial<Record<"xl" | "lg2" | "lg" | "xs", string>>,
					left: Partial<Record<"xl" | "lg2" | "lg" | "xs", string>>,
					width: Partial<Record<"xl" | "lg2" | "lg" | "xs", string>>
				][] =
					[
						[
							{ xl: "30px", lg2: "30px", xs: "20px" },
							{ xl: "95%", lg2: "90%", xs: "87%" },
							{ xl: "256px", lg2: "214px", xs: "172px" }
						],
						[
							{ xl: "0px", lg2: "15px", xs: "10px" },
							{ xl: "40%", lg2: "40%", xs: "42%" },
							{ xl: "162px", lg2: "135px", xs: "108px" }
						],
						[
							{ xl: "120px", lg2: "120px", xs: "90px" },
							{ xl: "-15%", lg2: "-9%", xs: "-5%" },
							{ xl: "220px", lg2: "184px", xs: "147px" }
						],

						[
							{ xl: "290px", lg2: "270px", xs: "220px" },
							{ xl: "2%", lg2: "2%", xs: "12%" },
							{ xl: "143px", lg2: "119px", xs: "95px" }
						],
						[
							{ xl: "240px", lg2: "220px", xs: "180px" },
							{ xl: "105%", lg2: "107%", xs: "102%" },
							{ xl: "173px", lg2: "145px", xs: "116px" }
						]
					];

				const SVGCloud = CLOUDS[ index ];

				return (
					<Box
						key={"cloud-" + fieldName}
						sx={[
							{
								position: "absolute",
								left: positions[ index ][ 1 ],
								top: positions[ index ][ 0 ],
								transformOrigin: "bottom",

								"& svg":
								{
									width: positions[ index ][ 2 ]
								}
							},
							filledFormField(toughDecisionsFields, index) === false &&
							{
								animation: cloudAnimation + " linear 2500ms " + index * 75 + "ms infinite"
							},
							filledFormField(toughDecisionsFields, index) === true &&
							{
								"& path":
								{
									fill: alpha("#FFFFFF", 0.5)
								}
							}
						]}
					>
						<IconButton
							disableFocusRipple
							disableRipple
							disableTouchRipple
							onClick={onClickButton(index)}
							sx={{
								backgroundColor: "unset !important"
							}}
						>
							<SVGCloud />
						</IconButton>
					</Box>
				);
			}))}
		</>
	);
}

export default ToughDecisionsClouds;
