import { Box, CircularProgress } from "@mui/material";
import Api from "api";
import { useCallback, useState } from "react";
import IMGMissionLarge2 from "../../../../assets/images/missions/mission-six/mission-two-large.png";
import ContentAdmin from "../../../../components/contentAdmin";
import ImageSideModal from "../../../missionSix/components/ImageSideModal";
import useMission from "../../hooks/useMission";

export interface IMission2Props
{
	isOpen: boolean;
}

function ModalMission2Data({ isOpen = false }: IMission2Props)
{
	const missionContext = useMission();
	const [ data, setData ] = useState<string[] | null>(null);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	// Handles
	const onOpen = useCallback(() =>
	{
		Api.missions
			.getDataMissionTwo()
			.then(({ values }) =>
			{
				setIsErrorLoading(false);
				setData(values.slice(0, 10));
			})
			.catch((error) =>
			{
				setIsErrorLoading(true);
				console.error(error);
			});
	}, []);

	// Render
	return (
		<ImageSideModal
			open={isOpen}
			onClose={() => missionContext.setShowMissionInfo(null)}
			image={IMGMissionLarge2}
			title={<ContentAdmin keyContent="contentMissionSix" keyText="modal_values_title" defaultValue="{NAME}’s top 10 Values" position="left" />}
			subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="modal_values_subTitle" defaultValue="You’ve started your journey by understanding the core of who you are — your Values." position="left" isDescription />}
			onOpen={onOpen}
		>
			{(data === null || data === undefined) && isErrorLoading === false &&
				(
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
						Loading data <CircularProgress size={24} />
					</Box>
				)
			}
			{(data === null || data === undefined) && isErrorLoading === true &&
				(
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
						Failed to retrieve data, please try again
					</Box>
				)
			}
			{data !== null && data !== undefined && isErrorLoading === false &&
				(
					<Box
						component="ol"
						sx={{
							display: "grid",
							gridTemplateRows: "repeat(5, 1fr)",
							gridTemplateColumns: "1fr 1fr",
							gridAutoFlow: "column",
							color: "#215C75",
							font: "700 24px/31px Lora",
							textTransform: "capitalize",
							gap: "24px"
						}}
					>
						{data.map((value, index) => (<li key={value + index}>{value}</li>))}
					</Box>
				)
			}
		</ImageSideModal>
	);
}

export default ModalMission2Data;
