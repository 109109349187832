import { Box, Typography } from "@mui/material";
import useUser from "hooks/useUser";
import { Ref, forwardRef } from "react";
import IMGMeditate from "../../../assets/images/meditate.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const YourPlanStart = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const { state: userState } = useUser();

	function nextStep()
	{

		if (userState.user?.completedMission === 8)
		{
			missionContext.setFirstPlaythrough(true);
		}

		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				width: "100%",
				maxWidth: { lg: "805px", xl: "881px" },
				m: "0 auto",
				display: "flex",
				flexDirection: "column",
				marginTop: "115px",
				paddingLeft: "113px",
				position: "relative",
				top: "10px",
				left: "15px"
			}}>
			<Box sx={{
				display: "flex",
				width: "100%",
				height: "100%",
				minHeight: { lg: "314px", xl: "448px" },
				margin: "0 auto",
				border: "1px solid rgba(255, 255, 255, .6)",
				borderRadius: "24px",
				wordBreak: "break-word",
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
				backdropFilter: "blur(25px)",
				boxShadow: "0px 5px 10px 0px #A7C7E199",
				position: "relative",
				maxHeight: { lg: "314px", xl: "444px" }
			}}>
				<Box sx={{
					position: "absolute",
					zIndex: "100",
					top: "-134px",
					left: "-108px",
					width: { lg: "302px", xl: "417px" },
					height: { lg: "176px", xl: "243px" },
					backgroundImage: `url(${ IMGMeditate })`,
					rotate: "-2deg",
					backgroundSize: "cover"
				}} />

				<Box sx={{
					width: "100%",
					position: "relative",
					padding: {
						lg: "57px 72px",
						xl: "102px 77px 56px 72px"
					},
					wordBreak: "break-word"
				}}>
					<Typography sx={{
						color: "#ffffff",
						font: {
							lg: "700 24px/28px Lora-Bold",
							xl: "700 40px/51px Lora-Bold"
						},
						textAlign: "left"
					}}>
						<ContentAdmin
							limit={111}
							keyContent="contentMissionNine"
							keyText="start_step_title"
							defaultValue="{NAME}, you’re almost there!"
							position="left"
							isDescription
						/>
					</Typography>
					<Typography sx={{
						color: "#215C75",
						mt: { lg: "24px", xl: "45px" },
						font: {
							lg: "700 18px/28px Lora-Bold",
							xl: "700 24px/38px Lora-Bold"
						},
						textAlign: "left"
					}}>
						<ContentAdmin
							limit={111}
							keyContent="contentMissionNine"
							keyText="start_step_congratulations"
							defaultValue="You’ve done all the hard work — learned a lot about yourself, worked through your fears and limitations. This only means one thing — you are ready to set the vision for your dream life and turn it into an action plan."
							position="left"
							isDescription
						/>
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mt: "46px", ml: "-116px" }}>
				<CustomButton.Outline
					sx={{ fontSize: "24px", lineHeight: "33px", background: "#3E809D", border: "#3E809D", color: "#fff", minWidth: "275px" }}
					onClick={nextStep}
				>Let’s do it
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default YourPlanStart;
