import { Box, Button, ButtonProps, CircularProgress, styled, SxProps, Theme } from "@mui/material";
import { ReactComponent as SVGArrow } from "assets/images/icons/size-40/Arrow.svg";
import { ExoticComponent, forwardRef, Ref } from "react";

const ButtonStyled = styled(Button)(({ disabled, hidden = false }) => (
	{
		width: "56px",
		height: "56px",
		minHeight: "unset",
		minWidth: "unset",
		borderRadius: "50%",
		backgroundColor: (disabled === true ? "rgba(239, 239, 239, .6)" : "#3E809D"),
		boxShadow: "0px 3px 6px rgba(0, 0, 0, .16)",
		backdropFilter: "blur(15px) brightness(.9)",
		visibility: (hidden === true ? "hidden" : "visible"),
		pointerEvents: "auto"
	}
)) as typeof Button;

interface IArrowButtonProps extends ButtonProps
{
	loading?: boolean;
	/**
	 * @default "right"
	 */
	direction?: "left" | "right";
	sxIcon?: SxProps<Theme>;
}

function ArrowButton(
	{
		loading = false,
		disabled, direction = "right", hidden = false, sxIcon, ...props
	}: IArrowButtonProps,
	ref: Ref<HTMLButtonElement>
)
{
	return (
		<ButtonStyled
			hidden={hidden}
			disabled={loading === true || disabled}
			ref={ref}
			{...props}
		>
			<Box sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				transform: (direction === "left" ? "rotate(180deg)" : "")
			}}>
				{loading === true
					? <CircularProgress size={38} sx={{ color: "#FFF" }} />
					: <SVGArrow />
				}
			</Box>
		</ButtonStyled>
	);
}

export default forwardRef(ArrowButton) as ExoticComponent<IArrowButtonProps>;
