import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { DragEvent, useEffect, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";

interface ItemsBoard
{
	id: number;
	title: string;
	like: boolean;
}
interface Board
{
	id: string;
	title: string;
	items: ItemsBoard[];
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
		font: '400 14px/17px "Open Sans"',
		color: "#4D4D4D",
		boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
		padding: "24px",
		borderRadius: "16px",
		backdropFilter: "blur(15px)",
		border: "1px solid  rgba(239, 239, 239, 0.0816)",
		minWidth: "383px"
	}
}));

function AreasOfFocuse()
{
	const missionContext = useMission();
	const [ currentBoard, setCurrentBoard ] = useState<any>(null);
	const [ currentItem, setCurrentItem ] = useState<any>(null);
	const [ boards, setBoards ] = useState<any>([]);

	function onClickNextStep()
	{
		localStorage.setItem("mission-nine-graph-data", JSON.stringify(boards?.filter((item: any) => item.id !== 1)));

		missionContext.setStep((prevStep) => prevStep + 1);
	}

	useEffect(() =>
	{
		let arrValues: ItemsBoard[] = [];

		// JSON.parse(`${ localStorage.getItem('mission-nine-wantInLine') }`).forEach((element: Board) =>
		// {
		// 	element.items.forEach((item: ItemsBoard) =>
		// 	{
		// 		arrValues.push(item);
		// 	});
		// });
		setBoards([
			{
				id: 1,
				itemsBoard: JSON.parse(`${ localStorage.getItem("mission-nine-wantInLine") }`)
			},
			{
				id: 2,
				itemsBoard: []
			},
			{
				id: 3,
				itemsBoard: []
			},
			{
				id: 4,
				itemsBoard: []
			},
			{
				id: 5,
				itemsBoard: []
			}
		]);
	}, []);
	function dragOverHandler(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function dragStartHandler(e: any, board: any, item: any)
	{
		setCurrentBoard(board);
		setCurrentItem(item);
	}

	function dropCardHandlerGood(_event: DragEvent<HTMLDivElement>, board: any)
	{
		if (currentItem === null || currentBoard === null)
		{
			return;
		}

		board.itemsBoard.push(currentItem);
		const currentIndex = currentBoard.itemsBoard.indexOf(currentItem);

		currentBoard.itemsBoard.splice(currentIndex, 1);
		setBoards(boards.map((b: any) =>
		{
			if (b.id === board.id)
			{
				return board;
			}

			if (b.id === currentBoard.id)
			{
				return currentBoard;
			}

			return b;
		}));
	}

	function dropCardHandler(_event: DragEvent<HTMLDivElement>, board: any, item: number)
	{
		if (currentItem === null || currentBoard === null)
		{
			return;
		}

		_event.preventDefault();
		const currentIndex = currentBoard.itemsBoard.indexOf(currentItem);

		currentBoard.itemsBoard.splice(currentIndex, 1);
		const dropIndex = board.itemsBoard.indexOf(item);

		board.itemsBoard.splice(dropIndex + 1, 0, currentItem);
		setBoards(boards.map((b: any) =>
		{
			if (b.id === board.id)
			{
				return board;
			}

			if (b.id === currentBoard.id)
			{
				return currentBoard;
			}

			return b;
		}));
	}

	// Render
	return (
		<Box
			className="main__content"
			sx={{
				height: "84vh",
				width: "100%",
				m: "0 auto",
				position: "relative",
				maxWidth: { lg: "814px", xl: "1036px" },
				top: "-15px",
				left: "0px"
			}}>
			<PanelTitle
				positionOcta
				zIndexOcta="10"
				title={<ContentAdmin
					keyContent="contentMissionNine"
					keyText="areas_of_focuse_title"
					defaultValue="Prioritise your areas of focus"
					position="left"
				/>}
				subTitle={<ContentAdmin
					keyContent="contentMissionNine"
					keyText="areas_of_focuse_text"
					defaultValue="We are getting there, {NAME}! Just hang in with me for a couple of more steps. The task here is simple, place your idea groups on a graph."
					position="left"
					isDescription />}
				imageVariation="closeEye"
				sx={{ marginBottom: "10px" }}
			/>
			{missionContext.showContent
				? (
					<Box sx={{ display: "flex", gap: 4, position: "relative", top: "-15px", left: "35px", zIndex: 999 }}>
						<Box sx={{ borderRadius: "24px" }}>
							<Box sx={{
								width: { lg: "554px", xl: "690px" },
								height: { lg: "456px", xl: "632px" },
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								border: "1px solid rgba(255, 255, 255, 0.5)",
								backdropFilter: "blur(30px)",
								borderRadius: "24px",
								p: { lg: "8px 15px", xl: "22px 24px 8px 8px" },
								position: "relative"
							}}>

								<Box sx={{
									borderBottom: "3px solid rgba(62, 128, 157, 0.3)",
									width: { lg: "381px", xl: "528px" },
									transform: "rotate(-90deg)",
									position: "absolute",
									top: { lg: "206px", xl: "292px" },
									left: { lg: "-172px", xl: "-238px" }
								}}>
									<Box sx={{ position: "relative" }}>
										<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
											<Box sx={{ color: "#215C75", font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' }, textAlign: "center", textTransform: "uppercase", mb: "6px" }}>
												impact
											</Box>
											<LightTooltip
												placement="top"
												title={
													<Box sx={{
														color: "#4D4D4D", font: '400 14px/19px "Open Sans"'
													}}>
														<ContentAdmin
															keyContent="contentMissionNine"
															keyText="areas_of_focuse_impact_ideas"
															defaultValue="Impact of ideas in this group on your life in terms of improved joy, happiness, wellbeing, satisfaction, and fulfillment."
															position="left"
															isDescription />
													</Box>
												}
											>
												<Box sx={{
													position: "relative",
													cursor: "pointer"
												}}>
													<InfoOutlinedIcon sx={{ fill: "#215C75" }} />
													{/* <Box sx={{ width: '15px', position: 'absolute', bottom: '10px', right: '13px', transform: 'rotate(90deg)' }}>
															<CardMedia
																component="img"
																width="100%"
																image={CursorPointer}
																alt="Paella dish"
															/>
														</Box> */}
												</Box>
											</LightTooltip>
										</Box>
										<ArrowForwardIosIcon sx={{ position: "absolute", right: "-9px", top: { lg: "18px", xl: "21px" }, fill: "rgba(62, 128, 157, 0.3)" }} />
									</Box>
								</Box>
								<Box sx={{
									color: "#215C75",
									font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' },
									textTransform: "uppercase",
									position: "absolute",
									top: { lg: "8px", xl: "22px" },
									left: { lg: "15px", xl: "24px" }
								}}>
									high
								</Box>
								<Box sx={{
									color: "#215C75",
									font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' },
									textTransform: "uppercase",
									position: "absolute",
									bottom: { lg: "19px", xl: "28px" },
									left: { lg: "13px", xl: "24px" }
								}}>
									low
								</Box>
								<Box sx={{
									borderTop: "3px solid rgba(62, 128, 157, 0.3)",
									width: { lg: "477px", xl: "592px" },
									position: "absolute",
									bottom: { lg: "0px", xl: "6px" },
									left: { lg: "59px", xl: "71px" }
								}}>
									<Box sx={{ position: "relative" }}>
										<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
											<Box sx={{ color: "#215C75", font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' }, textAlign: "center", textTransform: "uppercase", mt: "6px" }}>
												Difficulty
											</Box>
											<LightTooltip
												placement="top"
												title={
													<Box sx={{
														color: "#4D4D4D", font: '400 14px/19px "Open Sans"'
													}}>
														<ContentAdmin
															keyContent="contentMissionNine"
															keyText="areas_of_focuse_difficulty"
															defaultValue="Difficulty of making it happen in terms of effort, time, money and any other constraints"
															position="left"
															isDescription />
													</Box>}>
												<Box sx={{
													position: "relative",
													cursor: "pointer"
												}}>
													<InfoOutlinedIcon sx={{
														fill: "#215C75", mt: "6px"
													}} />
													{/* <Box sx={{ width: '15px', position: 'absolute', bottom: '0px', right: '0px' }}>
															<CardMedia
																component="img"
																width="100%"
																image={CursorPointer}
																alt="Paella dish"
															/>
														</Box> */}
												</Box>
											</LightTooltip>
										</Box>
										<ArrowForwardIosIcon sx={{
											position: "absolute", right: "-10px", top: "-15px", fill: "rgba(62, 128, 157, 0.3)", fontSize: "27px"
										}} />
									</Box>
								</Box>
								<Box sx={{
									color: "#215C75",
									font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' },
									textTransform: "uppercase",
									position: "absolute",
									bottom: "8px",
									left: "74px"
								}}>
									easy
								</Box>
								<Box sx={{
									color: "#215C75",
									font: { lg: '700 16px/22px "Open Sans"', xl: '700 18px/25px "Open Sans"' },
									textTransform: "uppercase",
									position: "absolute",
									bottom: "8px",
									right: "32px"
								}}>
									hard
								</Box>
								<Box sx={{ position: "relative", display: "flex", flexWrap: "wrap", gap: { lg: "11px", xl: "16px" }, top: { lg: "25px", xl: "30px" }, left: { lg: "32px", xl: "50px" } }}
								>
									{boards?.filter((item: any) => item.id !== 1).map((board: any, index: number) => (
										<Box
											key={index}
											className="scrollCustom"
											sx={{
												height: { lg: "182px", xl: "252px" },
												minWidth: { lg: "236px", xl: "294px" },
												maxWidth: { lg: "236px", xl: "294px" },
												background: "rgba(255, 255, 255, 0.5)",
												borderRadius: "16px",
												display: "flex",
												alignContent: "flex-start",
												gap: 2,
												flexWrap: "wrap",
												p: 2,
												overflowY: "auto"
											}}
											onDragOver={(e) => dragOverHandler(e)}
											onDrop={(e) => dropCardHandlerGood(e, board)}
										>
											{board.itemsBoard.map((item: any) =>
											{
												return (
													<Box
														key={item}
														className="dnd-block"
														draggable
														onDragOver={(e) => dragOverHandler(e)}
														onDragStart={(e) => dragStartHandler(e, board, item)}
														onDrop={(e) => dropCardHandler(e, board, item)}
														sx={{
															background: "rgba(255, 255, 255, 0.5)",
															boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
															borderRadius: "40px",
															p: "9px 16px",
															cursor: "grab",
															display: "flex"
														}}
													>
														{item}
													</Box>
												);
											})}
										</Box>
									))}
								</Box>
							</Box>
						</Box>
						<Box
							className="scrollCustom"
							sx={{
								display: "flex",
								flexDirection: "column",
								alignContent: "flex-start",
								maxHeight: { lg: "456px", xl: "632px" },
								overflowY: "auto"
							}}
						>
							{boards[ 0 ]?.itemsBoard?.length > 0 && boards[ 0 ].itemsBoard.map((claster: any, index: number) => (
								<Box
									key={index}
									sx={{
										width: "max-content",
										background: "rgba(255, 255, 255, 0.5)",
										borderRadius: { lg: "40px", xl: "8px" },
										p: "9px 16px",
										mb: 1,
										cursor: "grab"
									}}
									className="dnd-block"
									draggable
									onDragOver={(e) => dragOverHandler(e)}
									onDragStart={(e) => dragStartHandler(e, boards[ 0 ], claster)}
								>
									<Box sx={{
										color: { lg: "primary.dark", xl: "primary.main" },
										font: { lg: '16px/23px "Lora-Bold"', xl: '18px/23px "Lora-Bold"' },
										width: "max-content"
									}}>
										{claster}
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				)
				: null}
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={boards[ 0 ]?.itemsBoard?.length !== 0}
					onClick={onClickNextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default AreasOfFocuse;
