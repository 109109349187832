import { Box, Grid, Typography } from "@mui/material";
import Health from "../../../../assets/images/Health.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function HealthDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>

				<Box component="img" src={Health} alt="Health" className="imgDetails" sx={{ marginRight: "25px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Health
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "400 22px/30px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="Your physical health is the foundation for all other spheres of your life. You can’t have much fun or advance your career if your health is suffering, that’s why it’s so important to look after your health well." keyContent="contentMissionOne" keyText="healthDescScreen1" />
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "400 22px/30px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Are there any health issues you need to attend to?\n- Do you eat healthily?\n- Do you exercise enough?\n- How is your sleep?\n- Are you emotionally stable?\n\nSmall tweaks to your diet and exercise routine might make an enormous difference." keyContent="contentMissionOne" keyText="healthDescStart" />
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="If you eat well, exercise and don’t have any chronic illnesses, but are still not satisfied with your health, you might need to take an honest look at your life.\n\n- Are you burnt out?\n- Are you under too much stress?\n- Are you fulfilled in your career?\n- Do you feel lonely?\n- Do you live life in alignment with your essence?" keyContent="contentMissionOne" keyText="healthDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "400 22px/30px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "5px",
						height: "5px",
						borderRadius: "50%",
						backgroundColor: "#4D4D4D"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Often, health-related symptoms occur when we ignore our inner voice. Sometimes we need time to realise that something isn’t serving us anymore, find courage to make the change or simply don’t know where to start.\\n\\nAll of that is ok, because that’s exactly why we have created Life Strategy Quest — to help you find clarity and realign with your essence." keyContent="contentMissionOne" keyText="healthDescScreen2" />
				{/*
				Often, health-related symptoms occur when we ignore our inner voice. Sometimes we need time to realise that something isn’t serving us anymore, find courage to make the change or simply don’t know where to start.<br></br><br></br>

				All of that is ok, because that’s exactly why we have created Life Strategy Quest — to help you find clarity and realign with your essence.
				*/}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default HealthDetails;
