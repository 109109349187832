import { Box, Fade, Slide, SlideProps, Snackbar, alpha } from "@mui/material";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import ContentAdmin from "components/contentAdmin";
import Panel from "components/missions/Panel";
import { Dispatch, SetStateAction, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { LOCAL_STORAGE_PREFIX } from "..";
import AlertStyled from "components/AlertStyled";

export interface ITopJobsForYouSelectProps
{
	nextStep(): void;
	setStep: Dispatch<SetStateAction<number>>;
}

function TopJobsForYouSelect({ nextStep, setStep }: ITopJobsForYouSelectProps)
{
	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);

	const [ jobsList ] = useState<string[]>(() =>
	{
		return getLocalStorage<string[]>(LOCAL_STORAGE_PREFIX + "-create-jobsList") ?? [];
	});
	const [ selectedJobs, setSelectedJobs ] = useState<string[]>(() =>
	{
		return getLocalStorage<string[]>(LOCAL_STORAGE_PREFIX + "-create-jobsList-selected") ?? [];
	});

	if (jobsList.length === 0)
	{
		setStep(2);
	}

	// Handles
	function onSelectJob(jobName: string)
	{
		setSelectedJobs((prevState) =>
		{
			const selected = [ ...prevState ];
			const foundJobIndex = selected.indexOf(jobName);

			if (foundJobIndex !== -1)
			{
				selected.splice(foundJobIndex, 1);
			}
			else
			{
				selected.push(jobName);
			}

			return selected;
		});
	}

	// Render
	return (
		<>
			<Fade in>
				<Box
					component="button"
					onClick={() =>
					{
						if (selectedJobs.length < 3 || selectedJobs.length > 12)
						{
							return setIsShowAlert(true);
						}

						setLocalStorage(LOCAL_STORAGE_PREFIX + "-create-jobsList-selected", selectedJobs);
						localStorage.removeItem(LOCAL_STORAGE_PREFIX + "-create-jobsInformation");

						nextStep();
					}}
					sx={{
						"--size": { xl: "40px", lg: "32px", xs: "32px" },
						display: "inline-flex",
						position: "absolute",
						top: "50%",
						transform: "translateY(-50%)",
						right: { xl: "20px", lg: "8px", xs: "8px" },
						padding: "unset",
						background: "unset",
						border: "unset",
						color: "primary.main",
						cursor: "pointer",

						"& svg":
						{
							width: "var(--size)",
							height: "var(--size)"
						},
						"&:hover":
						{
							opacity: "0.7 !important"
						}
					}}
				>
					<SVGChevronArrow />
				</Box>
			</Fade>

			<Panel sx={{
				padding: { xl: "32px 32px 26px 32px", lg: "24px", xs: "24px" },
				maxWidth: { xl: "980px", lg: "609px", xs: "928px" },
				height: { xl: "613px", lg: "520px", xs: "520px" },
				justifySelf: "center",
				display: "grid",
				gridTemplateRows: "auto 1fr"
			}}>
				<Box sx={{
					color: "primary.main",
					font: { xl: "700 24px/33px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 24px/33px 'Open Sans'" }
				}}>
					<ContentAdmin keyContent="contentMissionFour" keyText="TopJobsForYou_panelTitle" defaultValue="Here are the jobs that might be suitable for you" position="left" />
				</Box>

				<Box
					className="customScroll"
					component="ul"
					sx={{
						listStyle: "none",
						height: "100%",
						margin: "unset",
						marginTop: { xl: "24px", lg: "16px", xs: "16px" },
						padding: "unset",
						paddingRight: "16px",
						overflowY: "auto",
						display: "grid",
						gridTemplateColumns: { xl: "repeat(3, 1fr)", lg: "repeat(2, 1fr)", xs: "repeat(2, 1fr)" },
						gap: { xl: "26px 50px", lg: "26px 45px", xs: "26px 45px" },
						justifyContent: "space-between",
						alignItems: "center",
						alignContent: "flex-start",
						color: "primary.dark",
						font: "400 16px/22px 'Open Sans'",

						"& .MuiSkeleton-wave::after":
						{
							background: `linear-gradient( 90deg, transparent, ${ alpha("#3E809D", 0.4) }, transparent )`
						}
					}}
				>
					{jobsList.map((jobName) =>
					{
						return (
							<li key={jobName}>
								<CheckboxStyledWithLabel
									label={jobName}
									checkboxProps={{
										checked: selectedJobs.includes(jobName),
										onChange()
										{
											onSelectJob(jobName);
										}
									}}
									sx={{
										"--line-height": "22px",
										gap: "16px",
										font: "400 16px/22px 'Open Sans' !important",

										"& .Mui-checked + .CheckboxStyledWithLabel-content":
										{
											fontWeight: "bold"
										}
									}}
								/>
							</li>
						);
					})}
				</Box>
			</Panel>

			<Snackbar
				open={isShowAlert}
				autoHideDuration={5000}
				onClose={() => setIsShowAlert(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "35px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
					You must choose from 3 to 12 jobs
				</AlertStyled>
			</Snackbar>
		</>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default TopJobsForYouSelect;
