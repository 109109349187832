import { Box, SxProps, Theme, alpha } from "@mui/material";
import { ButtonHTMLAttributes, ForwardRefExoticComponent, Ref, forwardRef } from "react";

export interface IAsideCircleButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children">
{
	image: string;
	text: string;
	/**
	 * @default false;
	 */
	isSelected?: boolean;
	sx?: SxProps<Theme>;
}

function AsideCircleButton(
	{
		image,
		text,
		isSelected = false,
		sx = [],
		className = "",
		...props
	}: IAsideCircleButtonProps,
	ref: Ref<HTMLButtonElement>
)
{
	return (
		<Box
			component="button"
			className={"AsideCircleButton-root " + className}
			ref={ref}
			sx={[
				{
					"--imageSize": { xl: "218px", lg: "160px", xs: "160px" },
					background: "unset",
					padding: "unset",
					border: "unset",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "16px",
					cursor: "pointer",

					"& .AsideCircleButton-image":
					{
						width: "var(--imageSize)",
						height: "var(--imageSize)",
						background: `url(${ image }) center center/cover`,
						borderRadius: "50%"
					},
					"& .AsideCircleButton-text":
					{
						color: "primary.main",
						font: { xl: "700 32px/51px Lora-Bold", xs: "700 22px/28px Lora-Bold" },
						transition: "linear 200ms color",

						"&--selected":
						{
							color: alpha("#3E809D", 0.4)
						}
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<div className="AsideCircleButton-image" />
			<div className={"AsideCircleButton-text " + (isSelected === true ? "AsideCircleButton-text--selected" : "")}>
				{text}
			</div>
		</Box>
	);
}

export default forwardRef(AsideCircleButton) as ForwardRefExoticComponent<IAsideCircleButtonProps>;
