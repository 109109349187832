import { Box, FormControl, Slide, SlideProps, Snackbar } from "@mui/material";
import Api from "api";
import ImageJellyfish from "assets/images/jellyfish.png";
import { ReactComponent as SVGLogo } from "assets/images/logo.svg";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

function ResetPasswordStepTwo()
{
	const navigate = useNavigate();
	const [ isFetching, setIsFetching ] = useState<boolean>(false);
	const [ isPasswordChanged, setIsPasswordChanged ] = useState<boolean>(false);

	const token = useSearchParams()[ 0 ].get("token");

	if (token === null)
	{
		navigate("/login");

		return null;
	}

	// Handles
	function onFormSubmit(values: { "new-password": string; "confirm-password": string; })
	{
		if (token === null || isFetching === true)
		{
			return;
		}

		setIsFetching(true);

		Api.auth
			.resetPassword(token, values[ "new-password" ], values[ "confirm-password" ])
			.then(() =>
			{
				setIsPasswordChanged(true);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => setIsFetching(false));
	}

	function onSnackbarClose()
	{
		navigate("/login");
	}

	// Render
	return (
		<Box sx={{
			background: "linear-gradient(35deg, #7889B0  0%, #7FA9D0 75%, #CACFCC 100%)",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			minHeight: "100vh",
			padding: "20px"
		}}>
			<SVGLogo style={{ fill: "#FFFFFF", position: "absolute", left: "20px", top: "20px", width: "58px", height: "40px" }} />

			<Box sx={{ maxWidth: "680px", width: "100%" }}>
				<Box sx={{ font: "700 42px/56px Lora-Bold", textAlign: "center", color: "#FFFFFF" }}>
					Password reset
				</Box>

				<Box sx={{
					marginTop: "20px",
					padding: "60px 100px 60px 100px",
					border: "2px solid #DFE7F1",
					borderRadius: "20px",
					backgroundColor: "rgba(245, 242, 242, .4)"
				}}>
					<Formik
						validateOnBlur
						validateOnChange
						initialValues={{ "new-password": "", "confirm-password": "" }}
						onSubmit={onFormSubmit}
						validationSchema={
							yup.object({
								"new-password": config.password.yup(),
								"confirm-password": config.password.yup()
									.oneOf([ yup.ref("new-password") ], "Different passwords")
							})
						}
					>
						{({ values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty }) =>
						{
							return (
								<Box
									component="form"
									onSubmit={handleSubmit}
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										gap: "40px"
									}}
								>
									<label>
										<FormControl sx={{ width: "100%" }}>
											<Box sx={{ font: "700 22px/28px Lora-Bold", marginLeft: "10px", color: "#215C75" }}>
												New password
											</Box>

											<CustomInput.Password
												name="new-password"
												placeholder="new password"
												autoComplete="new-password"
												value={values[ "new-password" ]}
												onChange={handleChange}
												onBlur={handleBlur}
												sx={{ marginTop: "10px" }}
											/>

											<Box sx={{
												position: "absolute",
												left: "5px",
												bottom: "-19px",
												color: "#DC4242",
												font: "400 14px/19px 'Open Sans'"
											}}>
												{touched[ "new-password" ] ? errors[ "new-password" ] : null}
											</Box>
										</FormControl>
									</label>

									<label>
										<FormControl sx={{ width: "100%" }}>
											<Box sx={{ font: "700 22px/28px Lora-Bold", marginLeft: "10px", color: "#215C75" }}>
												Confirm new password
											</Box>

											<CustomInput.Password
												name="confirm-password"
												placeholder="confirm password"
												autoComplete="confirm-password"
												value={values[ "confirm-password" ]}
												onChange={handleChange}
												onBlur={handleBlur}
												sx={{ marginTop: "10px" }}
											/>

											<Box sx={{
												position: "absolute",
												left: "5px",
												bottom: "-19px",
												color: "#DC4242",
												font: "400 14px/19px 'Open Sans'"
											}}>
												{touched[ "confirm-password" ] ? errors[ "confirm-password" ] : null}
											</Box>
										</FormControl>
									</label>

									<CustomButton.Base
										type="submit"
										loading={isFetching === true}
										disabled={!(isValid && dirty) || isPasswordChanged === true}
										sx={{ display: "flex", margin: "0 auto" }}
									>
										Change
									</CustomButton.Base>
								</Box>
							);
						}}
					</Formik>
				</Box>

				<img src={ImageJellyfish} alt="" style={{ display: "block", margin: "20px auto 0 auto", width: "246px", height: "143px" }} />
			</Box>

			<Snackbar
				open={isPasswordChanged === true}
				autoHideDuration={5000}
				onClose={onSnackbarClose}
				anchorOrigin={{ horizontal: "center", vertical: "top" }}
				TransitionComponent={TransitionDown}
			>
				<AlertStyled onClose={onSnackbarClose} severity="success">
					The new password has been accepted and can be used now.<br />
					You will be redirected to the login page in 5 seconds.
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

function TransitionDown(props: Omit<SlideProps, "direction">)
{
	return <Slide {...props} direction="down" />;
}

export default ResetPasswordStepTwo;
