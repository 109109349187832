import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useUser from "../../../hooks/useUser";
import useMission from "../hooks";

const ChatPageMissionEleven = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();
	const { content } = useUser().state;

	const [ chatStep, setChatStep ] = useState<number>(1);
	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: (
					<ContentAdmin
						limit={300}
						keyContent="contentMissionEleven"
						keyText="screen_1_chat_message"
						defaultValue="Me again! It’s really hard for me to let you go, {NAME}! I prepared this last secret mission for you as a gift."
						isDescription
					/>
				)
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [
				<ContentAdmin
					limit={64}
					keyContent="contentMissionEleven"
					keyText="screen_1_chat_answer"
					defaultValue="A gift?"
					position="top"
				/>
			]
		}
	);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					(
						<ContentAdmin
							limit={300}
							keyContent="contentMissionEleven"
							keyText="screen_2_chat_message"
							defaultValue="Yes, you deserved it! You worked so hard and now is time to relax. You can sit back while I share with you something special."
							isDescription
						/>
					)
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionEleven"
							keyText="screen_2_chat_message_answer"
							defaultValue="Show me!"
							position="top"
						/>
					]
				});
				break;
			}

			case 3: {
				setChatStep((prevStep) => prevStep + 1);

				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content">
				<Chat
					titleColor="#E9EDF0"
					titleMarginTop="10px"
					titleMarginLeft="5px"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEleven"
						keyText="chat_page_title"
						defaultValue="It’s hard for me to let you go, {NAME}"
					/>}
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatPageMissionEleven;
