import { Box, Fade } from "@mui/material";
import Api from "api";
import ArrowButton from "components/ArrowButton";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionTwo/hooks/useMission";
import { useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import { ListOfValuesData } from "../ListOfValues";
import LifePeaksButtons from "./Buttons";
import LifePeaksTemplate from "./Template";

export type LifePeaks = [ peak: string, special: string ] | undefined;

export function filledForm(data: LifePeaks[], index: 0 | 1 | 2)
{
	if (data[ index ] === undefined)
	{
		return false;
	}

	return data[ index ]!.every((value) => value.trim().length > 0);
}

function LifePeaks()
{
	const missionContext = useMission();
	const lifePeaks = useReadLocalStorage<LifePeaks[]>(missionContext.missionPrefix + "-lifePeaks", []);

	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ _newListOfValues, setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Handles
	function nextStep()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					lifePeaks
						.filter((item) => item !== undefined)
						.map((item) =>
						{
							return "Define in max 5 nouns, with no additional text, the personal values of this person based on their description of their peak life moment " + item![ 1 ];
						}),
					"personBased"
				)
				.then((values) =>
				{
					const newValues =
						Array.from(
							new Set(
								values
									.map(({ personBased }) => personBased)
									.flat()
									.map((value) => value.toLowerCase())
							)
						).filter((value) => listOfValues.includes(value) === false);

					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 6) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	// Render
	return (
		<>
			<LifePeaksTemplate>
				<Fade in={missionContext.showContent}>
					<Box sx={{ position: "relative", width: "100%" }}>
						<LifePeaksButtons />
					</Box>
				</Fade>
			</LifePeaksTemplate>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					loading={loading}
					disabled={
						filledForm(lifePeaks, 0) === false ||
						filledForm(lifePeaks, 1) === false ||
						filledForm(lifePeaks, 2) === false
					}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default LifePeaks;
