import { Box, alpha } from "@mui/material";
import ArrowButton from "components/ArrowButton";
import RadialButton from "components/RadialButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { useLayoutEffect, useState } from "react";
import useMission from "../../hooks/useMission";

function GettingStart()
{
	const missionContext = useMission();
	const [ isShowValues, setIsShowValues ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ openOneDialog, setOpenOneDialog ] = useState(false);
	const [ openTwoDialog, setOpenTwoDialog ] = useState(false);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean ]>([ true, true ]);
	const [ isShowTooltip, setIsShowTooltip ] = useState<boolean>(false);
	const [ open, setOpen ] = useState(
		{
			one: false,
			two: false
		}
	);

	// Effects
	useLayoutEffect(() =>
	{
		if (open.one === false || open.two === false)
		{
			return;
		}

		const timerId = setTimeout(() => setIsShowTooltip(true), 4000);

		return () => clearTimeout(timerId);
	}, [ open ]);

	// Handles
	function handleOpenOneDialog()
	{
		setOpen({
			...open,
			one: true
		});
		setOpenOneDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 0: false })
		));

	}

	function onClickNextStep()
	{
		missionContext.setStep((prevStep) => prevStep + 1);
	}

	function handleOpenTwoDialog()
	{
		setOpen({
			...open,
			two: true
		});
		setOpenTwoDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 1: false })
		));
	}

	return (
		<>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
				width: "100%"
			}}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_2_left_desc_title" defaultValue="What are values?" position="right" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_2_title" defaultValue="But first, let’s explore! Hover over the glowing icons and see what happens." isDescription position="right" />}
					sx={{
						marginBottom: "100px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>
				{(openOneDialog || openTwoDialog)
					? <Box />
					: (
						<Box sx={[ {
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: "100px",
							flexGrow: 1,
							width: "90%",
							m: "0 auto"
						},
						...[ isShowValues[ 0 ] === false && { paddingRight: "40px" } ],
						...[ isShowValues[ 1 ] === false && { paddingLeft: "40px" } ] ]}>
							<Box sx={{ alignSelf: "flex-end" }}>
								<RadialButton
									value="What are personal values?"
									onClick={handleOpenOneDialog}
									direction="left"
									blip={disabledButtons[ 0 ] === true}
									showValue={isShowValues[ 0 ]}
									onMouseEnter={() => setIsShowValues([ true, false ])}
									onMouseLeave={() => setIsShowValues([ false, false ])}
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 0 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
								/>
							</Box>
							<Box>
								<RadialButton
									value="Why should I know my values?"
									onClick={handleOpenTwoDialog}
									blip={disabledButtons[ 1 ] === true}
									showValue={isShowValues[ 1 ]}
									onMouseEnter={() => setIsShowValues([ false, true ])}
									onMouseLeave={() => setIsShowValues([ false, false ])}
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 1 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
								/>
							</Box>
						</Box>
					)
				}
			</Box>
			<MissionsDialog
				sxCancelIcon={{
					fill: "#3E809D",
					opacity: "1",
					top: "32px",
					right: "32px"

				}}
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						position: "relative",
						width: "100%",
						maxWidth: "804px",
						top: "22px",
						padding: "56px 42px 28px 57px"
					}
				}}
				title={<ContentAdmin keyContent={missionContext.keyContent} keyText="what_personal_values" defaultValue="What are personal values?" position="left" />}
				open={openOneDialog}
				onClose={() => setOpenOneDialog(false)}
			>
				<p>
					<ContentAdmin keyContent={missionContext.keyContent} keyText="what_personal_values_txt" defaultValue="Values are your fundamental beliefs about what really matters to you in life. They guide your actions and define how you feel about different things. We all have different values!\n\nFor example, some might value freedom, while others stability. And you might value something completely different!" isDescription position="left" />
				</p>
			</MissionsDialog>

			<MissionsDialog
				sxCancelIcon={{
					fill: "#3E809D",
					opacity: "1",
					top: "32px",
					right: "32px"
				}}
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						position: "relative",
						width: "100%",
						maxWidth: "804px",
						top: "42px",
						padding: "56px 42px 28px 57px"
					},
					"& .MuiPaper-root ul li::marker": {
						fontSize: "15px",
						position: "relative",
						top: "10px"
					}
				}}
				title={<ContentAdmin keyContent={missionContext.keyContent} keyText="should_i_know_values" defaultValue="Why should I know my values?" position="left" />}
				open={openTwoDialog}
				onClose={() => setOpenTwoDialog(false)}
			>
				<ContentAdmin keyContent={missionContext.keyContent} keyText="should_i_know_values_txt" defaultValue="Knowing your values can help you to: \n- Make important life decisions \n- Stay on track in life \n- Design your life in sync with your essence" isDescription position="left" />
			</MissionsDialog>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" hidden />
				<TooltipStyled
					open={isShowTooltip}
					title="Click here to continue"
					placement="top-end"
					sx={{
						"& .MuiTooltip-tooltip":
						{
							padding: "24px",
							border: "1px solid " + alpha("#FFFFFF", 0.5)
						}
					}}
				>
					<ArrowButton
						direction="right"
						disabled={open.one === false || open.two === false}
						onClick={onClickNextStep}
					/>
				</TooltipStyled>
			</StepperNavigationWrapper>
		</>
	);
}

export default GettingStart;
