import { Box } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionTwo/hooks/useMission";
import { ReactNode } from "react";

function WhatMakesProudTemplate({ children }: { readonly children?: ReactNode; })
{
	const missionContext = useMission();

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<PanelTitle
				positionOcta
				imageVariation="closeEye"
				title={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="WhatMakesProud_title"
					defaultValue="What makes me proud"
					isDescription
					position="right"
				/>}
				subTitle={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="WhatMakesProud_text"
					defaultValue="{NAME}, I am sure you have a lot to be proud of. Reflecting on the qualities you are most proud of will help identifying a few more of your personal values."
					isDescription
					position="right"
				/>}
				sx={{
					marginBottom: "40px",

					"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
					"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
				}}
			/>

			{children}
		</Box>
	);
}

export default WhatMakesProudTemplate;
