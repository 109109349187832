import { alpha, Box, Chip, Menu, MenuItem, Slide, SlideProps, Snackbar, Typography, Zoom } from "@mui/material";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import { ReactComponent as SVGMagicalWand } from "assets/images/icons/magical-wand.svg";
import { ReactComponent as SVGZoomIn } from "assets/images/icons/zoom-in.svg";
import { ReactComponent as SVGZoomOut } from "assets/images/icons/zoom-out.svg";
import AlertStyled from "components/AlertStyled";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import TooltipStyled from "components/TooltipStyled";
import { MouseEvent, SyntheticEvent, useEffect, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import useMission from "../../hooks/useMission";
import { COLORS, IBoard, IBoardItem } from "../ClusterYourYearnings";
import IMGCursorBlue from "./cursor-blue.png";
import IMGCursorGrab from "./cursor-grab.png";
import IMGCursorGreen from "./cursor-green.png";
import IMGCursorNone from "./cursor-none.png";
import IMGCursorRed from "./cursor-red.png";

export interface IMenuItem
{
	color: "green" | "blue" | "red" | "none";
	title: string;
	desc?: string;
}

export const MENU_ITEMS: IMenuItem[] = [
	{ color: "red", title: "1. Must Have", desc: "really matters to you and you are ready to sacrifice a lot to achieve that." },
	{ color: "green", title: "2. Very Important", desc: "ready to put in a lot of effort, but maybe not as urgent as Must Have." },
	{ color: "blue", title: "3. Nice to Have", desc: "would totally add to your level of happiness if happens, but if it doesn’t — you wouldn’t feel you have failed in life." },
	{ color: "none", title: "None" }
];

export const CURSORS =
{
	green: IMGCursorGreen,
	blue: IMGCursorBlue,
	red: IMGCursorRed,
	none: IMGCursorNone,
	grab: IMGCursorGrab
};

function TimeToPriorities()
{
	const missionContext = useMission();
	const [ isMove, setIsMove ] = useState<boolean>(false);
	const [ color, setColor ] = useState<keyof typeof COLORS>("red");
	const [ anchorElement, setAnchorElement ] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorElement);
	const [ isOpenSnackbar, setIsOpenSnackBar ] = useState<boolean>(false);
	const [ isCursorHand, setIsCursorHand ] = useState<boolean>(false);
	const [ boards, setBoards ] = useState<IBoard[]>(() =>
	{
		const clusters = getLocalStorage<IBoard[]>("mission-three-cluster");
		if (clusters !== null)
		{
			return clusters;
		}

		let values = getLocalStorage<string[]>("mission-three-wantInLine");
		if (values === null)
		{
			values = [];
		}

		const boards: IBoard[] = [
			{
				id: "__values__",
				title: "firstColumn",
				items: values.map((item, index): IBoardItem => ({ id: index, title: item, color: "none" }))
			}
		];

		return boards;
	});

	const cursor = (isMove === true
		? "grabbing"
		: isCursorHand === true
			? `url(${ CURSORS.grab }), grab`
			: `url(${ CURSORS[ color ] }), pointer`
	);

	// Effects
	useEffect(() =>
	{
		setLocalStorage("mission-three-cluster", boards);
	}, [ boards ]);

	// Utils
	function searchBoardIndex(boardId: IBoard[ "id" ]): number
	{
		return boards.findIndex((board) => board.id === boardId);
	}

	// Handles
	function onClickNextStep()
	{
		if (!boards.some((board) => board.items.filter((boardItem) => boardItem.color === "red").length > 0))
		{
			return setIsOpenSnackBar(true);
		}

		if (getLocalStorage("mission-three-doItAgain") === true)
		{
			return missionContext.setStep(15); // Finish step
		}

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function onClickPrevStep()
	{
		missionContext.setStep((currentStep) => currentStep - 2);
	}

	function onCloseSnackbar(_event: SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setIsOpenSnackBar(false);
	}

	// Menu
	function openMenuHandler({ currentTarget }: MouseEvent<HTMLElement>)
	{
		setAnchorElement(currentTarget);
	}

	function closeMenuHandler()
	{
		setAnchorElement(null);
	}

	function onSelectMenuItem(color: keyof typeof COLORS)
	{
		closeMenuHandler();

		setIsCursorHand(false);
		setColor(color);
	}

	function itemChangeColor(boardIndex: number, boardItem: IBoardItem): void
	{
		if (isCursorHand)
		{
			return;
		}

		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);

			const boardItemIndex = boards[ boardIndex ].items.findIndex((item) => item.id === boardItem.id);

			boards[ boardIndex ].items[ boardItemIndex ].color = color;

			return boards;
		});
	}

	// Render
	return (
		<>
			<Box className="main__content">
				<PanelTitle
					positionOcta
					title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_20_title" defaultValue="Time to prioritize!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionThree" keyText="screen_20_subTitle" defaultValue="Now, go through all of your yearnings one by one and color them by level of priority." position="left" isDescription />}
					imageVariation="closeEye"
					sx={{ marginBottom: "10px" }}
				/>
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						gap: "10px",
						backdropFilter: "blur(15px)",
						borderRadius: "20px",
						background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
						border: "1px solid " + alpha("#FFFFFF", 0.6),
						padding: "30px 30px 10px 30px",
						maxHeight: { xl: "635px", lg: "478px", xs: "635px" },
						boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
						cursor: cursor,
						// Chips
						"& .MuiChip-root":
						{
							borderRadius: "60px",
							padding: "10px 14px",
							height: "unset",
							justifyContent: "space-between",
							gap: "10px",
							display: "flex",
							boxShadow: "0px 3px 6px " + alpha("#000", 0.16),
							cursor: cursor,
							"& .MuiChip-label":
							{
								color: "#FFFFFF",
								font: "500 16px 'Open Sans'",
								padding: "0",
								whiteSpace: "normal"
							},
							"& .MuiChip-deleteIcon":
							{
								margin: "0px",
								fill: "#215C75",
								opacity: 1,

								"&:hover":
								{
									opacity: 0.3
								}
							},
							"&.MuiChip-root--color-red":
							{
								background: alpha(COLORS.red, 0.5)
							},
							"&.MuiChip-root--color-green":
							{
								background: alpha(COLORS.green, 0.5)
							},
							"&.MuiChip-root--color-blue":
							{
								background: alpha(COLORS.blue, 0.5)
							},
							"&.MuiChip-root--color-none":
							{
								background: alpha(COLORS.none, 0.5),
								"& .MuiChip-label": { color: "#4D4D4D" }
							}
						}
					}}
				>
					<Box
						sx={{
							position: "relative",
							zIndex: 2,
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							"& .cluster__column":
							{
								padding: "5px",
								borderRadius: "10px",
								width: "300px",
								display: "flex",
								flexDirection: "column",
								gap: "10px",
								border: "1px solid " + alpha("#FFFFFF", 0.7),
								backdropFilter: "blur(10px)",
								boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
								"&.cluster__column--highlight-drop .cluster__items:empty::after":
								{
									borderColor: alpha("#215C75", 1),
									color: "#4D4D4D"
								}
							},
							"& .cluster__title": { font: "700 16px/20px Lora", textAlign: "center", color: "primary.main" },
							"& .cluster__items":
							{
								display: "flex",
								flexWrap: "wrap",
								gap: "10px",
								alignItems: "center",
								overflow: "auto",
								maxHeight: "380px",
								padding: "0 10px 10px 10px",
								"&:empty":
								{
									"&::after":
									{
										content: "'Drop to here'",
										padding: "10px",
										display: "block",
										width: "100%",
										textAlign: "center",
										color: alpha("#4D4D4D", 0.6),
										font: "500 16px 'Open Sans'",
										border: "2px dashed " + alpha("#215C75", 0.6),
										borderRadius: "5px",
										transition: "linear 200ms border-color, linear 200ms color"
									}
								},
								"& .MuiChip-root":
								{
									flexGrow: 1,
									"& .MuiChip-label":
									{
										textAlign: "center",
										flexGrow: 1
									}
								}
							},
							"& .react-transform-wrapper":
							{
								flexGrow: 1,
								width: "100%",
								height: "100%",
								cursor: cursor,
								borderRadius: "10px"
							},
							"& .react-transform-component":
							{
								gap: "20px",
								justifyContent: "center",
								alignItems: "flex-start",
								width: "100%",
								maxWidth: "628px",
								borderRadius: "10px"
							}
						}}
					>
						<TransformWrapper
							minScale={0.5}
							maxScale={1}
							centerZoomedOut
							centerOnInit={false}
							panning={{ excluded: (isCursorHand ? [] : [ "MuiChip-root", "MuiChip-label" ]) }}
							wheel={{ excluded: [ "cluster__items", "MuiChip-root", "MuiChip-label" ] }}
							onPinchingStart={() => setIsMove(true)}
							onPanningStart={() => setIsMove(true)}
							onPinchingStop={() => setIsMove(false)}
							onPanningStop={() => setIsMove(false)}
						>
							{({ zoomIn, zoomOut }) => (
								<>
									<TransformComponent>
										{boards.filter((board) => board.id !== "__values__").map(
											(board) => (
												<Box
													key={board.id}
													className="cluster__column"
												>
													<Box className="cluster__title">{board.title}</Box>
													<Box className="cluster__items scrollCustom">
														{board.items.map(
															(boardItem) => (
																<Chip
																	key={boardItem.id}
																	label={boardItem.title}
																	className={"MuiChip-root--color-" + boardItem.color}
																	onClick={() => itemChangeColor(searchBoardIndex(board.id), boardItem)}
																/>
															)
														)}
													</Box>
												</Box>
											)
										)}
									</TransformComponent>

									{/* Buttons */}
									<Box
										sx={{
											width: "100%",
											display: "flex",
											gap: "20px",
											justifyContent: "flex-end",
											alignItems: "center",
											marginTop: "10px",
											"& .navigation-button":
											{
												background: "none",
												border: "none",
												cursor: "pointer",
												padding: "5px",
												borderRadius: "10px",
												width: "49px",
												height: "49px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												transition: "linear 200ms background-color",
												"&:hover, &.navigation-button--active": { backgroundColor: alpha("#215C75", 0.2) },
												"& #Rectangle_92": // Color square
												{
													fill: COLORS[ color ]
												}
											}
										}}
									>
										<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Zoom In">
											<Box component="button" className="navigation-button" onClick={() => zoomIn(0.25)}>
												<SVGZoomIn />
											</Box>
										</TooltipStyled>

										<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Zoom Out">
											<Box component="button" className="navigation-button" onClick={() => zoomOut(0.25)}>
												<SVGZoomOut />
											</Box>
										</TooltipStyled>

										<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Hand">
											<Box
												component="button"
												className={`navigation-button ${ (isCursorHand ? "navigation-button--active" : "") }`}
												onClick={() => setIsCursorHand(true)}
											>
												<Box component="img" src={IMGCursorGrab} />
											</Box>
										</TooltipStyled>

										<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Tips">
											<Box component="button" className={`navigation-button ${ (anchorElement !== null ? "navigation-button--active" : "") }`} onClick={openMenuHandler}>
												<SVGMagicalWand />
											</Box>
										</TooltipStyled>

										<Menu
											open={isMenuOpen}
											anchorEl={anchorElement}
											anchorOrigin={{ vertical: "top", horizontal: "right" }}
											transformOrigin={{ vertical: "bottom", horizontal: "right" }}
											onClose={closeMenuHandler}
											PaperProps={{
												style:
												{
													background: "none",
													boxShadow: "none"
												}
											}}
											MenuListProps={{
												style: {
													padding: 0
												}
											}}
											sx={{
												"& .MuiMenuItem-root":
												{
													padding: "15px",
													background: `linear-gradient(to bottom right, ${ alpha("#F7F7F7", 0.8) }, ${ alpha("#F7F7F7", 0) })`,
													borderRadius: "5px",
													border: "1px solid " + alpha("#FFFFFF", 0.6),
													flexDirection: "column",
													alignItems: "flex-start",
													width: "255px",
													boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
													whiteSpace: "normal",
													backdropFilter: "blur(40px) brightness(.95)",
													"&:not(:first-of-type)": { borderTop: "none" },
													"& .menu__title": { display: "flex", gap: "10px", alignItems: "center", "& .MuiTypography-root": { font: "700 14px/19px 'Open Sans'" } },
													"& .menu__body": { font: "500 14px/19px 'Open Sans'", opacity: 0.6, transition: "linear 200ms opacity" },
													"&:hover .menu__body": { opacity: 0.8 }
												}
											}}
										>
											{MENU_ITEMS.map((menuItem, index) => (
												<MenuItem key={index} onClick={() => onSelectMenuItem(menuItem.color)}>
													<Box className="menu__title">
														<Typography>{menuItem.title}</Typography>
														<ColorSquare color={menuItem.color} />
													</Box>

													{menuItem.desc !== undefined &&
														(
															<Typography className="menu__body">
																{menuItem.desc}
															</Typography>
														)
													}
												</MenuItem>
											))}
										</Menu>
									</Box>
								</>
							)}
						</TransformWrapper>
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton onClick={onClickPrevStep} direction="left" hidden />
				<ArrowButton
					direction="right"
					onClick={onClickNextStep} />
			</StepperNavigationWrapper>

			<Snackbar
				open={isOpenSnackbar}
				autoHideDuration={5000}
				onClose={onCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "100px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={onCloseSnackbar} severity="warning">
					You have not marked any of your yearnings as a Must Have.
				</AlertStyled>
			</Snackbar>
		</>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export function ColorSquare({ color }: { color: keyof typeof COLORS; })
{
	return <Box sx={{ width: "15px", height: "15px", borderRadius: "3px", border: "1px solid " + alpha("#FFFFFF", 0.6), backgroundColor: COLORS[ color ] }} />;
}

export default TimeToPriorities;
