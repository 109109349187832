import { IResponseAuthenticate } from "api/Auth";
import { DeepPartial } from "../types";
import deepClone from "../utils/deepClone";
import { setLocalStorage } from "../utils/localStorage";

export interface IUserState
{
	isAuthenticated: boolean;
	user: IResponseAuthenticate | null;
	accessToken: string | null;
	content: any;
}

export enum UserActionType
{
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	GET_USER = "GET_USER",
	REFRESH_TOKEN = "REFRESH_TOKEN",
	SET_CONTENT = "SET_CONTENT",
	COMPLETED_MISSION = "COMPLETED_MISSION",
	SUBSCRIPTION_IS_ACTIVE = "SUBSCRIPTION_IS_ACTIVE",
	UPDATE_STATE = "UPDATE_STATE",
	FULL_SUBSCRIBE = "FULL_SUBSCRIBE"
}

// export interface IAction
// {
// 	type: UserActionType;
// 	payload?:
// 	{
// 		accessToken?: string;
// 		user?: any;
// 		content?: any;
// 		completedMission?: number;
// 	};
// }

export type ActionType =
	{
		type: UserActionType.LOGIN;
		payload: { user?: IResponseAuthenticate; accessToken?: IUserState[ "accessToken" ]; };
	} | {
		type: UserActionType.LOGOUT;
	} | {
		type: UserActionType.GET_USER;
		payload: { user?: Partial<IResponseAuthenticate>; };
	} | {
		type: UserActionType.REFRESH_TOKEN;
		payload: { accessToken: IUserState[ "accessToken" ]; };
	} | {
		type: UserActionType.SET_CONTENT;
		payload: { content?: any; };
	} | {
		type: UserActionType.COMPLETED_MISSION;
		payload: { completedMission: IResponseAuthenticate[ "completedMission" ]; };
	} | {
		type: UserActionType.SUBSCRIPTION_IS_ACTIVE;
		payload: { subscriptionIsActive: boolean; };
	} | {
		type: UserActionType.UPDATE_STATE;
		payload: DeepPartial<IUserState>;
	} | {
		type: UserActionType.FULL_SUBSCRIBE;
		payload: { fullSubscribe: boolean; };
	};

export function userReducer(state: IUserState, action: ActionType): IUserState
{
	switch (action.type)
	{
		case UserActionType.LOGIN: {
			action.payload?.user && setLocalStorage("user", action.payload.user);
			action.payload?.accessToken && setLocalStorage("accessToken", action.payload.accessToken);

			return {
				...state,
				isAuthenticated: true,
				user: action.payload?.user || null,
				accessToken: action.payload?.accessToken || null
			};
		}

		case UserActionType.LOGOUT: {
			localStorage.clear();

			return {
				...state,
				isAuthenticated: false,
				user: null,
				accessToken: null
			};
		}

		case UserActionType.GET_USER: {
			if (action.payload.user)
			{
				const user: IUserState = Object.assign({}, state, { user: action.payload.user });

				setLocalStorage("user", user);

				return user;
			}

			return state;
		}

		case UserActionType.REFRESH_TOKEN: {
			if (action.payload?.accessToken)
			{
				setLocalStorage("accessToken", action.payload.accessToken);

				return {
					...state,
					accessToken: action.payload.accessToken
				};
			}

			return state;
		}

		case UserActionType.SET_CONTENT: {
			if (action.payload?.content)
			{
				return {
					...state,
					content: action.payload.content
				};
			}

			return state;
		}

		case UserActionType.COMPLETED_MISSION: {
			if (state.user === null)
			{
				console.warn(`[${ action.type }] user === null`, state);

				return state;
			}

			if (state.user.completedMission < action.payload.completedMission)
			{
				const cloneState = deepClone(state);

				if (cloneState.user !== null)
				{
					cloneState.user.completedMission = action.payload.completedMission;
				}

				setLocalStorage("completedMission", action.payload.completedMission);
				setLocalStorage("user", cloneState);

				return cloneState;
			}

			return state;
		}

		case UserActionType.SUBSCRIPTION_IS_ACTIVE: {
			if (state.user === null)
			{
				console.warn(`[${ action.type }] user === null`, state);

				return state;
			}

			const cloneState = deepClone(state);

			if (cloneState.user !== null)
			{
				cloneState.user.subscriptionIsActive = action.payload.subscriptionIsActive;
			}

			setLocalStorage("user", cloneState);

			return cloneState;
		}

		case UserActionType.FULL_SUBSCRIBE: {
			if (state.user === null)
			{
				console.warn(`[${ action.type }] user === null`, state);

				return state;
			}

			const cloneState = deepClone(state);

			if (cloneState.user !== null)
			{
				cloneState.user.fullSubscribe = action.payload.fullSubscribe;
			}

			setLocalStorage("user", cloneState);

			return cloneState;
		}

		case UserActionType.UPDATE_STATE: {
			const cloneState = deepClone(state);

			if (action.payload.accessToken)
			{
				setLocalStorage("accessToken", action.payload.accessToken);
				cloneState.accessToken = action.payload.accessToken;
			}

			if (action.payload.user)
			{
				cloneState.user = Object.assign({}, cloneState.user, action.payload.user);

				setLocalStorage("user", cloneState.user);
			}

			if (action.payload.content)
			{
				cloneState.content = action.payload.content;
			}

			if (action.payload.isAuthenticated)
			{
				cloneState.isAuthenticated = action.payload.isAuthenticated;
			}

			return cloneState;
		}

		default: {
			return state;
		}
	}
}
