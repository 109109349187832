import { Box, BoxProps } from "@mui/material";
import IMGLightBulbRed from "assets/images/badValues.svg";
import IMGLightBulbGreen from "assets/images/goodValues.svg";

export interface ILightBulbProps extends BoxProps
{
	color?: "red" | "green";
	blip?: boolean;
}

function LightBulb({ color = "green", blip = false, className = "", sx = [], ...props }: ILightBulbProps)
{
	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (blip === true)
	{
		classList.push("animation--blip-bulb");
	}

	return (
		<Box
			sx={[
				{
					"--size": "54px",
					width: "var(--size)",
					height: "var(--size)",
					background: `url(${ (color === "red" ? IMGLightBulbRed : IMGLightBulbGreen) })`,
					backgroundSize: "calc(var(--size) + 20px)",
					backgroundPosition: "center"
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			className={classList.join(" ")}
			{...props}
		/>
	);
}

export default LightBulb;
