import { Box, CircularProgress, alpha } from "@mui/material";
import Api from "api";
import { IDataMissionTen, IGetDataMissionsTen } from "api/Missions";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import { FormEvent, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import * as yup from "yup";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";
import { formatDate } from "../yearning";
import Create, { MAX_FILED_LENGTH } from "./Create";
import Edit from "./Edit";
import IMGBackground from "./assets/images/background.png";
import IMGButtonImageComplete from "./assets/images/button-image-complete.png";
import IMGButtonImage from "./assets/images/button-image.png";
import IMGDislike from "./assets/images/dislike.png";
import IMGLike from "./assets/images/like.png";

enum ETab
{
	TO_BE_TESTED = 1,
	IN_PROGRESS = 2,
	COMPLETED = 3
}

const DATA_NOT_FOUND =
{
	[ ETab.TO_BE_TESTED ]: "You have no experiments to be tested, try creating a new experiment",
	[ ETab.IN_PROGRESS ]: "You have no experiments in progress",
	[ ETab.COMPLETED ]: "You have no completed experiments, add your insights & verdicts to your experiments to complete them"
};

export const RULES =
{
	title:
	{
		minLength: 2,
		maxLength: 30,
		yupScheme()
		{
			return yup
				.string()
				// eslint-disable-next-line no-template-curly-in-string
				.min(this.minLength, "At least ${min} characters are required")
				// eslint-disable-next-line no-template-curly-in-string
				.max(this.maxLength, "You need a maximum of ${max} characters")
				.test(
					"title-test",
					"Only allow: A-z, А-я, 0-9, '.', '-', '_' and space.",
					(value) => this.regTest(value || "")
				);
		},
		regTest(value: string): boolean
		{
			return /^[A-Za-zА-Яа-яЁё0-9-_. ]+$/.test(value);
		}
	}
};

function Experiments()
{
	const navigate = useNavigate();
	const params = useParams();

	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	const [ selectedTab, setSelectedTab ] = useState<ETab>(ETab.TO_BE_TESTED);

	const [ isShowCreateModal, setIsShowCreateModal ] = useState<boolean>(false);
	const [ newExperimentTitle, setNewExperimentTitle ] = useState<string>("");
	const [ newExperimentTitleError, setNewExperimentTitleError ] = useState<string | null>(null);

	const [ loadItems, setLoadItems ] = useState<IGetDataMissionsTen[ "data" ] | null>(null);
	const [ selectedItem, setSelectedItem ] = useState<number>(-1);

	const items: IGetDataMissionsTen[ "data" ] = useMemo(() =>
	{
		return (loadItems ?? []).filter(({ mission_data }) => correctedForTab(mission_data, selectedTab));
	}, [ selectedTab, loadItems ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Utils
	function updateItems(callback?: () => void, force: boolean = false)
	{
		if (force === false && (isFetch === true || isFetchRef.current === true))
		{
			return;
		}

		setIsFetch(true);
		isFetchRef.current = true;

		Api.missions
			.getDataMissionTen()
			.then(({ data }) =>
			{
				setLoadItems(data);

				callback && callback();
			})
			.catch((error) => console.info(error))
			.finally(() =>
			{
				setIsFetch(false);
				isFetchRef.current = false;
			});
	}

	function calculateProgress(item: IDataMissionTen)
	{
		const checkedCount = item.experiments.filter(({ checked }) => checked === true).length;

		if (checkedCount === 0)
		{
			return 0;
		}

		return (checkedCount * 100) / item.experiments.length;
	}

	function correctedForTab(item: IDataMissionTen, tab: ETab)
	{
		const progress = calculateProgress(item);

		if ((item.like === null && progress === 0 && tab === ETab.TO_BE_TESTED)
			|| (item.like === null && progress > 0 && tab === ETab.IN_PROGRESS)
			|| (typeof item.like === "boolean" && tab === ETab.COMPLETED)
		)
		{
			return true;
		}

		return false;
	}

	// Effects
	useLayoutEffect(() => updateItems(), []); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => setSelectedItem(0), [ selectedTab, items ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Handles
	function onCreate(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			RULES.title.yupScheme().validateSync(newExperimentTitle);
		}
		catch (e)
		{
			error = (e as yup.ValidationError).message;
		}

		setNewExperimentTitleError(error);

		if (error !== null)
		{
			return;
		}

		setLocalStorage("dashboard-experiments-create-title", newExperimentTitle);
		navigate("/dashboard/artifacts/experiments/create");
		setIsShowCreateModal(false);
	}

	// Render
	switch (params.action)
	{
		case "create": return <Create updateItems={updateItems} />;
		case "edit": return <Edit updateItems={updateItems} items={loadItems} />;
		case undefined: break;
		default: return <Navigate to="/dashboard/artifacts/experiments" />;
	}

	return (
		<ArtifactsContainer
			backgroundImage={IMGBackground}
			title="My Experiments"
			aside={
				<>
					<Box sx={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
						{
							[
								[ "to be tested", ETab.TO_BE_TESTED ],
								[ "in progress", ETab.IN_PROGRESS ],
								[ "completed", ETab.COMPLETED ]
							].map(([ text, tabValue ]) =>
							{
								return (
									<CustomButton.Base
										key={tabValue}
										onClick={() => setSelectedTab(tabValue as ETab)}
										sx={[
											{
												textTransform: "uppercase",
												padding: { lg: "7px 13px !important", xl: "14px 24px !important" },
												font: { lg: "700 14px/21px 'Open Sans' !important", xl: "700 18px/25px 'Open Sans' !important" },
												minWidth: "unset !important",
												minHeight: "unset !important",
												opacity: 0.5,
												border: "unset"
											},
											(selectedTab === tabValue) &&
											{
												opacity: 1
											}
										]}
									>
										{text}
									</CustomButton.Base>
								);
							})
						}
					</Box>

					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							overflowX: "hidden",
							marginRight: "-24px",
							paddingRight: "24px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { lg: "48px 32px", xl: "16px 65px" }
						}}
					>
						{loadItems === null
							? (
								<Box sx={{ gridColumn: "2 span", justifySelf: "center" }}>
									Loading...
								</Box>
							)
							: items.length === 0
								? (
									<Box sx={{ gridColumn: "2 span", justifySelf: "center" }}>
										{DATA_NOT_FOUND[ selectedTab ]}
									</Box>
								)
								: items.map(({ id, mission_data }, index) =>
								{
									return (
										<Box
											key={id}
											component="button"
											sx={{
												padding: "unset",
												background: "unset",
												border: "unset",
												cursor: "pointer",
												display: "flex",
												flexDirection: "column",
												gap: "16px",
												ml: { lg: 1, xl: 0 }
											}}
											onClick={() => setSelectedItem(index)}
										>
											<Box sx={{
												position: "relative",
												width: { lg: "160px", xl: "218px" },
												height: { lg: "160px", xl: "218px" },
												border: "14px solid #FFFFFF",
												borderRadius: "50%"
											}}>
												<CircularProgress
													size={218}
													thickness={3}
													variant="determinate"
													value={(selectedTab === ETab.COMPLETED ? 100 : calculateProgress(mission_data))}
													sx={{
														position: "relative",
														top: "-13px",
														left: "-14px",
														color: "primary.main",
														display: { lg: "none", xl: "block" }
													}}
												/>
												<CircularProgress
													size={160}
													thickness={4}
													variant="determinate"
													value={(selectedTab === ETab.COMPLETED ? 100 : calculateProgress(mission_data))}
													sx={{
														position: "relative",
														top: "-13px",
														left: "-14px",
														color: "primary.main",
														display: { lg: "block", xl: "none" }
													}}
												/>
												<Box sx={{
													position: "absolute",
													top: 0,
													left: 0,
													width: "100%",
													height: "100%",
													background: `url(${ (selectedTab === ETab.COMPLETED ? IMGButtonImageComplete : IMGButtonImage) }) center center/cover`,
													boxShadow: (selectedTab === ETab.COMPLETED ? "unset" : "0px 0px 10px #FFE8B9"),
													borderRadius: "50%"
												}} />

												{mission_data.like !== null &&
													<Box sx={{
														position: "absolute",
														top: "-12px",
														right: "5px",
														width: "50px",
														height: "50px",
														backgroundColor: "#3E809D",
														boxShadow: "0 2px 10px " + alpha("#FFFFFF", 0.7),
														borderRadius: "50%",

														"& img":
														{
															height: "100%",
															width: "100%"
														}
													}}>
														<img
															src={mission_data.like === true ? IMGLike : IMGDislike}
															alt={mission_data.like === true ? "Like" : "Dislike"}
														/>
													</Box>
												}
											</Box>

											<Box sx={{
												width: "100%",
												textAlign: "center",
												color: (selectedItem === index ? "primary.main" : alpha("#3E809D", 0.4)),
												font: "700 24px/31px Lora-Bold",
												transition: "linear 200ms color",
												wordBreak: "break-word"
											}}>
												{mission_data.title}
											</Box>
										</Box>
									);
								})
						}
					</Box>

					<CustomButton.Contained
						onClick={() =>
						{
							if (getLocalStorage<string>("dashboard-experiments-create-title") !== null)
							{
								return navigate("/dashboard/artifacts/experiments/create");
							}

							setIsShowCreateModal(true);
						}}
						sx={{ justifySelf: "center" }}
					>
						Add new experiment
					</CustomButton.Contained>
				</>
			}
			headerRight={items[ selectedItem ] !== undefined && formatDate(items[ selectedItem ].created_at)}
			onExpand={
				items[ selectedItem ] === undefined || items[ selectedItem ].mission_data.like !== null
					? undefined
					: () => navigate("/dashboard/artifacts/experiments/edit/" + items[ selectedItem ].id)
			}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					gap: { lg: "32px", xl: "56px" }
				},
				"& .ArtifactsContainer-aside":
				{
					display: "grid",
					gridTemplateRows: "auto 1fr auto",
					gap: "40px",
					padding: { lg: "37px 22px 32px", xl: "32px 60px 40px 60px" }
				}
			}}
		>
			{loadItems !== null && items[ selectedItem ] !== undefined &&
				<Box sx={{
					display: "grid",
					gridTemplateRows: "auto 1fr",
					justifySelf: "center",
					alignSelf: "flex-start",
					gap: "24px",
					maxHeight: { lg: "544px", xl: "654px" },
					width: { lg: "663px", xl: "980px" },
					padding: { lg: "8px 20px 30px 24px", xl: "32px 32px 48px 48px" },
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					backdropFilter: "blur(30px)",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				}}>
					<Box sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: "8px",
						color: "primary.main",
						font: { lg: "700 24px/35px Lora-Bold", xl: "700 40px/51px Lora-Bold" }
					}}>
						<Box
							component="button"
							disabled={selectedItem === 0}
							onClick={() =>
							{
								setSelectedItem((prevState) =>
								{
									return prevState - 1;
								});
							}}
							sx={{
								color: "inherit",
								width: "40px",
								height: "40px",
								padding: "unset",
								background: "unset",
								border: "unset",
								cursor: "pointer",
								visibility: (selectedItem > 0 ? "visible" : "hidden"),

								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>

						<div>
							{items[ selectedItem ].mission_data.title}
						</div>

						<Box
							component="button"
							disabled={selectedItem >= items.length - 1}
							onClick={() =>
							{
								setSelectedItem((prevState) =>
								{
									return prevState + 1;
								});
							}}
							sx={{
								color: "inherit",
								width: "40px",
								height: "40px",
								padding: "unset",
								background: "unset",
								border: "unset",
								cursor: "pointer",
								visibility: (selectedItem < items.length - 1 ? "visible" : "hidden"),

								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Box>

					<Box
						className="customScroll"
						sx={{
							overflowY: "auto",
							paddingRight: "24px",
							height: "100%",
							display: "grid",
							gridTemplateColumns: "260px 1fr",
							alignContent: "flex-start",
							gap: "32px 24px",

							"& > :nth-of-type(2n+1)":
							{
								color: "primary.main",
								font: { lg: "700 18px/25px Lora-Bold", xl: "700 24px/31px Lora-Bold" }
							},
							"& > :nth-of-type(2n)":
							{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
								color: "primary.dark",
								font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
							}
						}}
					>
						<div>What I am testing</div>
						<div>{items[ selectedItem ].mission_data.whatTesting}</div>

						<div>Experiments</div>
						<div>
							{items[ selectedItem ].mission_data.experiments.map(({ value, checked }, index) =>
							{
								return (
									<CheckboxStyledWithLabel
										key={index + value}
										label={value}
										checkboxProps={{ checked, readOnly: true }}
										sx={{ "*": { cursor: "auto" } }}
									/>
								);
							})}
						</div>

						<div>Definition of success</div>
						<div>{items[ selectedItem ].mission_data.definitionSuccess}</div>

						<div>Key insights</div>
						<div>
							<Box sx={{
								padding: "12px 16px",
								minHeight: "84px",
								backgroundColor: alpha("#FFFFFF", 0.5),
								borderRadius: "8px"
							}}>
								{items[ selectedItem ].mission_data.keyInsights}
							</Box>
							<Box sx={{
								margin: "-16px 10px 0 10px",
								display: "flex",
								justifyContent: "flex-end",
								gap: "16px"
							}}>
								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{items[ selectedItem ].mission_data.keyInsights.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</div>

						<div style={{ marginTop: "-19px" }}>Decision</div>
						<div style={{ marginTop: "-19px" }}>
							<Box sx={{
								padding: "12px 16px",
								minHeight: "84px",
								backgroundColor: alpha("#FFFFFF", 0.5),
								borderRadius: "8px"
							}}>
								{items[ selectedItem ].mission_data.decision}
							</Box>
							<Box sx={{
								margin: "-16px 10px 0 10px",
								display: "flex",
								justifyContent: "flex-end",
								gap: "16px"
							}}>
								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{items[ selectedItem ].mission_data.keyInsights.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</div>

						{selectedTab === ETab.COMPLETED &&
							(
								<>
									<div style={{ marginTop: "-19px" }}>Verdict</div>
									<Box sx={{
										marginTop: "-19px",
										flexDirection: "row !important",
										gap: "40px !important",

										"& img":
										{
											"--size": "80px",
											width: "var(--size)",
											height: "var(--size)"
										}
									}}>
										<img
											src={IMGLike}
											alt="Like"
											style={{ opacity: (items[ selectedItem ].mission_data.like === true ? 1 : 0.5) }}
										/>

										<img
											src={IMGDislike}
											alt="Dislike"
											style={{ opacity: (items[ selectedItem ].mission_data.like === false ? 1 : 0.5) }}
										/>
									</Box>
								</>
							)
						}
					</Box>
				</Box>
			}

			<Dialog
				open={isShowCreateModal}
				onClose={() => setIsShowCreateModal(false)}
				title="Name new experiment"
			>
				<form onSubmit={onCreate}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="Name"
							value={newExperimentTitle}
							inputProps={{ maxLength: RULES.title.maxLength }}
							onChange={({ target }) => setNewExperimentTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{newExperimentTitleError}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{newExperimentTitle.length}/{RULES.title.maxLength} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

export default Experiments;
