import { Box } from "@mui/material";
import RadialButton from "components/RadialButton";
import MissionsDialog from "components/missions/Dialog";
import { useState } from "react";
import ArrowButton from "../../../components/ArrowButton";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../components/missions/StepperNavigationWrapper";
import useMission from "../hooks";

function PathToDreamLife()
{
	// States
	const missionContext = useMission();
	const [ isShowValues, setIsShowValues ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ openOneDialog, setOpenOneDialog ] = useState(false);
	const [ openTwoDialog, setOpenTwoDialog ] = useState(false);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean ]>([ true, true ]);
	const [ open, setOpen ] = useState(
		{
			one: false,
			two: false
		}
	);

	// Handles
	function handleOpenOneDialog()
	{
		setOpen({
			...open,
			one: true
		});
		setOpenOneDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 0: false })
		));

	}

	function handleOpenTwoDialog()
	{
		setOpen({
			...open,
			two: true
		});
		setOpenTwoDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 1: false })
		));
	}

	// Handles
	function onClickNextStep()
	{
		missionContext.setStep((prevStep) => prevStep + 1);
	}

	return (
		<>
			<Box sx={{
				height: "76.5vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "970px" },
				m: "0 auto",
				position: "relative",
				top: "2px",
				left: "-30px"

			}}>
				<PanelTitle
					imageVariation="closeEye"
					positionOcta
					title={<ContentAdmin
						limit={100}
						keyContent="contentMissionTen"
						keyText="PathToDreamLife_title"
						defaultValue="Your path to dream life"
						position="top" />}
				/>

				{(openOneDialog || openTwoDialog)
					? <Box /> :
					<Box sx={{
						display: "flex",
						justifyContent: "center",
						position: "relative",
						height: "100%"
					}}>
						<Box sx={{ position: "absolute", top: "203px", right: "16px", alignSelf: "flex-end" }}>
							<RadialButton
								value="How to choose experiments"
								onClick={handleOpenOneDialog}
								direction="left"
								blip={disabledButtons[ 0 ] === true}
								showValue={isShowValues[ 0 ]}
								onMouseEnter={() => setIsShowValues([ true, false ])}
								onMouseLeave={() => setIsShowValues([ false, false ])}
								sx={{
									margin: "0px",
									"& .RadialButton-value": {
										color: disabledButtons[ 0 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
										textShadow: "none"
									}
								}}
							/>
						</Box>
						<Box sx={{ position: "absolute", top: "442px", left: "143px" }}>
							<RadialButton
								value="What are life experiments"
								onClick={handleOpenTwoDialog}
								blip={disabledButtons[ 1 ] === true}
								showValue={isShowValues[ 1 ]}
								onMouseEnter={() => setIsShowValues([ false, true ])}
								onMouseLeave={() => setIsShowValues([ false, false ])}
								sx={{
									margin: "0px",
									"& .RadialButton-value": {
										color: disabledButtons[ 1 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
										textShadow: "none"
									}
								}}
							/>
						</Box>
					</Box>
				}
			</Box>
			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						position: "relative",
						width: "100%",
						maxWidth: "804px",
						top: "22px",
						padding: "56px 42px 28px 57px"
					}
				}}
				title={<ContentAdmin
					limit={50}
					keyContent="contentMissionTen"
					keyText="path_to_dreamLife_title"
					defaultValue="What are life experiments"
					position="left"
				/>}
				open={openOneDialog}
				onClose={() => setOpenOneDialog(false)}
			>
				<p>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="path_to_dreamLife_body"
						defaultValue="Experiments are a great tool to help you get unstuck when attempting to do something big or uncertain. They allow you to ‘date’ your idea and learn more about it before deciding whether you want to continue on that path."
						isDescription
						position="left"
					/>
				</p>
			</MissionsDialog>
			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						position: "relative",
						width: "100%",
						maxWidth: "804px",
						top: "42px",
						padding: "56px 42px 28px 57px"
					},
					"& .MuiPaper-root ul li::marker": {
						fontSize: "15px",
						position: "relative",
						top: "10px"
					}
				}}
				title={<ContentAdmin
					limit={50}
					keyContent="contentMissionTen"
					keyText="path_to_dreamLife_title_1"
					defaultValue="How to choose experiments"
					position="right"
				/>}
				open={openTwoDialog}
				onClose={() => setOpenTwoDialog(false)}
			>
				<p>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="path_to_dreamLife_body_1"
						defaultValue="You can design different types of experiments depending on your risk profile. Some experiments are low risk, such as conversational research, and some are more risky — like taking a sabbatical. And keep in mind that you are testing the direction, not the destination."
						isDescription
						position="left"
					/>
				</p>
			</MissionsDialog>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={open.one === false || open.two === false}
					onClick={onClickNextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default PathToDreamLife;
