import { Box, SxProps, Theme } from "@mui/material";

interface ITextInputProps
{
	isError?: boolean;
	fullWidth?: boolean;
	multiline?: boolean;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

type TextInputFullWidthProps =
	{
		multiline: true;
		rows: number;
		size?: never;
	} |
	{
		size: number;
		multiline?: false;
		rows?: number;
	};

function TextInput({
	size,
	isError = false,
	fullWidth = false,
	multiline = false,
	rows = 3,
	sx = [],
	...props
}: ITextInputProps & TextInputFullWidthProps)
{
	return (
		<>
			{multiline === true ?
				(<Box
					component="textarea"
					rows={rows}
					sx={[
						{
							width: (fullWidth === true ? "100%" : size + "px"),
							border: "none",
							font: "inherit",
							color: "inherit",
							padding: "0",
							outline: "none",
							transitionDuration: "200ms",
							transitionTimingFunction: "linear",
							transitionProperty: "background, color",
							background: `repeating-linear-gradient(to bottom, ${ isError ? "#E41616" : "#4D4D4D" } 0px 1px, transparent 1px calc(var(--line-height) - 1px)) 1px var(--line-height) / 100% content-box no-repeat`,
							overflow: "hidden",
							resize: "none",
							"& + span":
							{
								borderBottom: "1px solid #E8EBE9"
							},
							"&:focus":
							{
								color: "rgba(36, 92, 116, .8)",
								background: "repeating-linear-gradient(to bottom, #245C74 0px 2px, transparent 1px calc(var(--line-height) - 1px)) 1px var(--line-height) / 100% content-box no-repeat",
								"& + span":
								{
									borderBottom: "2px solid #E8EBE9"
								}
							}
						},
						...(Array.isArray(sx) ? sx : [ sx ])
					]}
					{...props}
				/>) :
				(<Box
					component="input"
					sx={[
						{
							width: (fullWidth === true ? "100%" : size + "px"),
							background: "none",
							border: "none",
							borderBottom: "1px solid " + (isError ? "#E41616" : "#4D4D4D"),
							font: "inherit",
							color: "inherit",
							padding: "0",
							textAlign: "center",
							outline: "none",
							transitionDuration: "200ms",
							transitionTimingFunction: "linear",
							transitionProperty: "border-bottom-color, color",
							marginBottom: "1px",
							"&:focus":
							{
								borderBottom: "2px solid #245C74",
								color: "rgba(36, 92, 116, .8)",
								marginBottom: "0"
							}
						},
						...(Array.isArray(sx) ? sx : [ sx ])
					]}
					{...props}
				/>)
			}
		</>
	);
}

export default TextInput;
