import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import stars from "../../../assets/images/Stars.svg";

function SteperHeader({ img, title, description, sx })
{
	const location = useLocation();

	return (
		<Box>
			<Box sx={{
				background: location.pathname.split("/")[ 1 ] === "artifact" ? "transparent" : `url(${ stars }) no-repeat`,
				backgroundSize: "cover",
				pt: { xs: "30px", sm: "48px" },
				width: { xs: "90%", sm: "100%" },
				m: "0 auto",
				display: { xs: "none", sm: "block" },
				...sx
			}}>
				<Box sx={{
					width: { xs: "60px", lg: "78px", xl: "94px" },
					height: { xs: "60px", lg: "78px", xl: "94px" },
					m: "0 auto"
				}}>
					{img}
				</Box>

				<Typography
					mt={2}
					sx={{
						fontSize: { xs: "24px", lg: "40px", xl: "60px" },
						fontFamily: "Lora",
						textAlign: "center",
						fontWeight: "bold",
						color: "#fff",
						lineHeight: { xs: "32px", lg: "45px", xl: "65px" }
					}}
				>
					{title}
				</Typography>

				<Box sx={{ display: "flex", justifyContent: "center", mt: "7px" }}>
					<Typography sx={{
						fontSize: { xs: "16px", xl: "24px" },
						fontFamily: "Open Sans",
						textAlign: "center",
						fontWeight: "medium",
						color: "#fff"
					}}>
						{description}
					</Typography>
				</Box>
			</Box>
			<Box sx={{
				background: location.pathname.split("/")[ 1 ] === "artifact" ? "transparent" : `url(${ stars }) no-repeat`,
				backgroundSize: "cover",
				width: { xs: "90%", sm: "100%" },
				m: "0 auto",
				display: { xs: "block", sm: "none" }
			}}>
				<Typography sx={{
					fontSize: { xs: "24px", sm: "60px" },
					fontFamily: "Lora",
					textAlign: "center",
					fontWeight: "bold",
					color: "#fff",
					lineHeight: { xs: "32px", sm: "65px" }
				}}>
					{title}
				</Typography>
				<Typography sx={{
					height: "32px", // line-height * 2
					textAlign: "center",
					font: "400 14px/16px 'Open Sans'",
					color: "#FFFFFF"
				}}>
					{description}
				</Typography>
				{/* <Box sx={{display: 'flex', alignContent: 'center'}}>
                    <Box sx={{ width: { xs: '60px', sm: '94px' }, height: { xs: '40px', sm: '94px' } }}>
                        {img}
                    </Box>
                    <Typography sx={{ fontSize: { xs: '16px', Fsm: '24px' }, fontFamily: 'Open Sans', textAlign: 'center', fontWeight: 'medium', color: '#fff' }}>
                        {description}
                    </Typography>
                </Box> */}
			</Box>
		</Box>

	);
}

export default SteperHeader;
