import { Tooltip, TooltipProps, Zoom, alpha, styled } from "@mui/material";
import { forwardRef } from "react";

const Component = forwardRef(({ className, ...props }: TooltipProps, ref) =>
{
	return (<Tooltip {...props} TransitionComponent={Zoom} classes={{ popper: className }} ref={ref} />);
});

const TooltipStyled = styled(Component)(
	{
		"& .MuiTooltip-tooltip":
		{
			font: "400 14px/19px 'Open Sans'",
			color: "#4D4D4D",
			borderRadius: "16px",
			backgroundColor: alpha("#FFFFFF", 0.5),
			backdropFilter: "blur(30px)",
			boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
			padding: "12px 16px"
		},
		"& .MuiTooltip-arrow::before":
		{
			backgroundColor: alpha("#FFFFFF", 0.5)
		},
		"&[data-popper-placement='top-start'] .MuiTooltip-arrow, &[data-popper-placement='bottom-start'] .MuiTooltip-arrow": { left: "-6px !important" },
		"&[data-popper-placement='top-end'] .MuiTooltip-arrow, &[data-popper-placement='bottom-end'] .MuiTooltip-arrow": { left: "6px !important" },
		"&[data-popper-placement='left-start'] .MuiTooltip-arrow, &[data-popper-placement='right-start'] .MuiTooltip-arrow": { top: "10px !important" },
		"&[data-popper-placement='left-end'] .MuiTooltip-arrow, &[data-popper-placement='right-end'] .MuiTooltip-arrow": { top: "-10px !important" },
		"&[data-popper-placement='left-end'], &[data-popper-placement='right-end']":
		{
			"& .MuiTooltip-tooltip": { top: "11px !important" }
		},
		"&[data-popper-placement='left-start'], &[data-popper-placement='right-start']":
		{
			"& .MuiTooltip-tooltip": { top: "-11px !important" }
		}
	}
);

export default TooltipStyled;
