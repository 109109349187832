import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, Typography, alpha } from "@mui/material";
import Api from "api";
import octa from "assets/images/octaUnsubscribe.png";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import getDateSubscription from "utils/date";

export interface IUnsubscribeModalProps
{
	/**
	 * @default false
	 */
	open?: boolean;
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton" | "reject" | "resolve"): void;
}

function UnsubscribeModal({ open = false, onClose, ...props }: IUnsubscribeModalProps & DialogProps)
{
	const { state: userState, dispatch: userDispatch } = useUser();
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ successUnsubscribe, setSuccessUnsubscribe ] = useState<boolean>(false);

	// Handles
	function onUnsubscribe()
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.transactions
			.unsubscribe()
			.then(() =>
			{
				userDispatch(
					{
						type: UserActionType.SUBSCRIPTION_IS_ACTIVE,
						payload: { subscriptionIsActive: false }
					}
				);
				setSuccessUnsubscribe(true);
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	// <ContentAdmin
	// 	limit={300}
	// 	keyContent="contentDashboard"
	// 	keyText="unsubscribe_success_desc"
	// 	defaultValue="Upgrade now to access the quest, view and update\n your artifacts and enjoy designing life on your terms."
	// 	position="left"
	// 	isDescription
	// />

	// <ContentAdmin
	// 	limit={300}
	// 	keyContent="contentDashboard"
	// 	keyText="unsubscribe_title_desc"
	// 	defaultValue="If you press this button, you will immediately lose\n access to artifacts and missions"
	// 	position="left"
	// 	isDescription
	// />
	// Render
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper":
				{
					maxWidth: "unset",
					width: "980px",
					height: "425px",
					padding: "56px 64px",
					backgroundColor: "#FFFFFF",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				},
				"& .MuiBackdrop-root":
				{
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
					backdropFilter: "blur(25px)"
				}
			}}
			{...props}
		>
			{onClose !== undefined &&
				<CloseIcon
					onClick={() => onClose({}, "closeButton")}
					sx={{
						position: "absolute",
						top: "32px",
						right: "32px",
						cursor: "pointer",
						color: "primary.main"
					}}
				/>
			}

			<Box sx={{ font: "700 40px/51px Lora-Bold", color: "#215C75" }}>
				{successUnsubscribe
					? (
						<ContentAdmin
							limit={300}
							keyContent="contentDashboard"
							keyText="unsubscribe_success_title"
							defaultValue="Your subscription was cancelled"
							position="left"
							isDescription
						/>
					)
					: (
						<ContentAdmin
							limit={300}
							keyContent="contentDashboard"
							keyText="unsubscribe_title_modal"
							defaultValue="Do you really want\nto unsubscribe?"
							position="left"
							isDescription
						/>
					)
				}
			</Box>

			<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", marginTop: successUnsubscribe ? "75px" : "24px" }}>
				{successUnsubscribe
					? (

						userState.user?.dateSubscriptionStart &&
						(
							<Typography sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", maxWidth: "650px" }}>
								{`You can access your missions and artifacts until ${ getDateSubscription(userState.user.dateSubscriptionStart, 30) }. Upgrade now to keep access to the quest.`}
							</Typography>
						)
					)
					: (

						userState.user?.dateSubscriptionStart &&
						(
							<Typography sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", maxWidth: "550px" }}>
								{`You will lose access to missions and your artifacts on ${ getDateSubscription(userState.user.dateSubscriptionStart, 30) }.`}
							</Typography>
						)

					)
				}
			</Box>
			{successUnsubscribe ?
				<CustomButton.Contained
					component={Link}
					to="/packages"
					onClick={(event: MouseEvent<HTMLButtonElement>) => onClose(event, "reject")}
					sx={{ marginTop: "auto", width: "349px", fontSize: "24px !important", height: "56px !important" }}
				>
					View packages
				</CustomButton.Contained> :
				<Box sx={{ marginTop: "auto", display: "flex", gap: "19px" }}>
					<CustomButton.Contained
						disabled={isFetch === true}
						onClick={(event: MouseEvent<HTMLButtonElement>) => onClose(event, "reject")}
						sx={{ width: "319px", fontSize: "24px !important", height: "56px !important" }}
					>
						Keep access
					</CustomButton.Contained>
					<CustomButton.Outline
						loading={isFetch === true}
						onClick={() => onUnsubscribe()}
						sx={{ background: "transparent", width: "319px", fontSize: "24px !important", height: "56px !important" }}
					>
						Unsubscribe
					</CustomButton.Outline>
				</Box>}
			<Box
				component="img"
				src={octa}
				alt=""
				sx={{ width: "230px", position: "absolute", top: successUnsubscribe ? "74px" : "38px", right: successUnsubscribe ? "-105px" : "36px", transform: successUnsubscribe ? "rotate(-20deg)" : "none" }}
			/>
		</Dialog>
	);
}

export default UnsubscribeModal;
