import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const ChatPageMissionFive = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionFive"
					keyText="mission_5_screen_1_chat_message"
					defaultValue="Happiness is not a station you arrive at, but a manner of traveling"
					isDescription
				/>
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionFive"
				keyText="mission_5_screen_1_chat_answer"
				defaultValue="Great quote!"
				position="top"
			/> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionFive"
						keyText="mission_5_screen_2_chat_message"
						defaultValue="Yeah, and you know why?"
						isDescription
					/>
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionFive"
							keyText="mission_5_screen_2_chat_answer"
							defaultValue="Why is that?"
							position="top"
						/>
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionFive"
						keyText="why_that_answer"
						defaultValue="Because this mission is all about your daily happiness. And that is more important than some of the big things, like your career or big achievements."
						isDescription
					/>
				);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin
						limit={64}
						keyContent="contentMissionFive"
						keyText="chat_answer_interesting"
						defaultValue="Interesting"
						position="top"
					/> ]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionFive"
						keyText="interesting_answer"
						defaultValue="Well, you may experience a strong feeling of happiness after a big promotion or a wedding, but that will fade after some time. What is left is how you design your everyday life. Every day happiness is a much surer recipe for living a dream life that constantly focusing only on big things."
						isDescription
					/>
				);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin
						limit={64}
						keyContent="contentMissionFive"
						keyText="chat_answer_i_am_intrigued"
						defaultValue="I am intrigued"
						position="top" /> ]
				});
				break;
			}

			case 5: {

				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);
	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);

	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content">
				<Chat
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionFive"
						keyText="screen_5_chat_title"
						defaultValue="{NAME}, I was waiting for you"
					/>}
					messages={messages}
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatPageMissionFive;
