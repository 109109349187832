import { Box, Typography } from "@mui/material";
import RadialButton from "components/RadialButton";
import MissionsDialog from "components/missions/Dialog";
import { useState } from "react";
import ArrowButton from "../../../components/ArrowButton";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../components/missions/StepperNavigationWrapper";
import useMission from "../hooks";

function GuidingLight()
{
	// States
	const missionContext = useMission();
	const [ isOpenButton, setIsOpenButton ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ openOneDialog, setOpenOneDialog ] = useState(false);
	const [ openTwoDialog, setOpenTwoDialog ] = useState(false);
	const [ open, setOpen ] = useState(
		{
			one: false,
			two: false
		}
	);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean ]>([ true, true ]);

	function handleOpenOneDialog()
	{
		setOpen({
			...open,
			one: true
		});
		setOpenOneDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 0: false })
		));
	}

	function handleOpenTwoDialog()
	{
		setOpen({
			...open,
			two: true
		});
		setOpenTwoDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 1: false })
		));
	}

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((prevStep) => prevStep + 1);
		missionContext.setBackground("withStar");
	}

	return (
		<>
			<Box sx={{
				height: "76.5vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "970px" },
				m: "0 auto",
				position: "relative",
				top: "19px",
				left: "-30px"

			}}>
				<PanelTitle
					imageVariation="closeEye"
					color="#ffffff"
					positionOcta
					title={<ContentAdmin
						limit={100}
						keyContent="contentMissionNine"
						keyText="guidingLight_title"
						defaultValue="Let the North Star be your guiding light"
						position="top" />}
				/>
				{(openOneDialog || openTwoDialog)
					? <Box />
					: (
						<Box sx={{
							display: "flex",
							justifyContent: "center",
							position: "relative",
							height: "100%"
						}}>
							<Box sx={{ position: "absolute", top: "237px", left: isOpenButton[ 0 ] === false ? { lg: "436px", xl: "496px" } : { lg: "396px", xl: "456px" } }}>
								<RadialButton
									blip={disabledButtons[ 0 ] === true}
									// blip
									color="blue"
									direction="right"
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 0 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
									showValue={isOpenButton[ 0 ]}
									value="What is a North Star?"
									onClick={handleOpenOneDialog}
									onMouseEnter={() => setIsOpenButton([ true, false ])}
									onMouseLeave={() => setIsOpenButton([ false, false ])}
								/>
							</Box>
							<Box sx={{ position: "absolute", top: "438px", right: isOpenButton[ 1 ] === false ? "268px" : "228px" }}>
								<RadialButton
									blip={disabledButtons[ 1 ] === true}
									// blip
									color="blue"
									direction="left"
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 1 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
									onClick={handleOpenTwoDialog}
									showValue={isOpenButton[ 1 ]}
									value="Why do you need a North Star?"
									onMouseEnter={() => setIsOpenButton([ false, true ])}
									onMouseLeave={() => setIsOpenButton([ false, false ])}
								/>
							</Box>
						</Box>
					)
				}
			</Box>
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					sx={{ width: "56px", height: "56px", minWidth: "56px", background: open.one === false || open.two === false ? "rgba(255, 255, 255, 0.5)" : "#E9EDF0" }}
					direction="right"
					disabled={open.one === false || open.two === false}
					sxIcon={{ fill: open.one === false || open.two === false ? "#FFFFFF" : "#215C75" }}
					onClick={onClickNextStep} />
			</StepperNavigationWrapper>
			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						padding: "56px 60px 56px 56px"
					}
				}}
				title={<ContentAdmin keyContent="contentMissionThree" keyText="asdasdsadad" defaultValue="What is a North Star?" position="left" />}
				open={openOneDialog}
				onClose={() => setOpenOneDialog(false)}
			>
				<Typography sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D" }}>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionNine"
						keyText="what_north_star_body"
						defaultValue="The North Star is famous for holding nearly still in our sky while the entire northern sky moves around it. When it comes to your life, your North Star is your personal mission statement. It is a short description of your aspirations and the impact you are aiming to create."
						isDescription
						position="right"
					/>
				</Typography>
			</MissionsDialog>

			<MissionsDialog
				sx={{
					"& .MuiPaper-root": {
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						padding: "56px 60px 56px 56px"
					}
				}}
				title={<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_modal_2_title" defaultValue="More cues in the next mission!" position="left" />}
				open={openTwoDialog}
				onClose={() => setOpenTwoDialog(false)}
			>
				<Typography sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D" }}>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionNine"
						keyText="why_need_north_star_text"
						defaultValue="Your North Star will be your guiding beacon on your life journey. Similarly, to how values serve as your design principles in life, your North Star will show you the direction in which to design your life."
						isDescription
						position="left"
					/>
				</Typography>
			</MissionsDialog>
		</>
	);
}

export default GuidingLight;
