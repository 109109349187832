import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const ChatPageMissionTen = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionTen"
					keyText="screen_1_chat_message"
					defaultValue="{NAME}, congratulations, you’ve reached the last step on your journey!"
					isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionTen"
				keyText="screen_1_chat_answer"
				defaultValue="I am a superstar"
				position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="screen_2_chat_message"
						defaultValue="In your last mission you created an action plan, but we left out your unknowns. In this mission you will create more certainty!"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="screen_2_chat_message_answer"
							defaultValue="Certainty?"
							position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="screen_3_chat_message"
						defaultValue="Yeah, you see, {NAME}, life happens to be uncertain a lot, and you can stay stuck forever not knowing what is the right move. Or you can take a small step and see where it takes you."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="screen_3_chat_answer"
							defaultValue="Interesting"
							position="top" />
					]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="screen_4_chat_message"
						defaultValue="Yeah, I call it life experiments, you can run as many as you want. From each one you can learn something and correct the course. This way over time you will design the most fulfilling life!"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="screen_4_chat_answer"
							defaultValue="Let’s do it!"
							position="top" />
					]
				});
				break;
			}

			case 5: {
				// redirect

				setChatStep((prevStep) => prevStep + 1);
				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);

	// Render
	return (
		<Box
			ref={ref}
			{...props}
			className="mission-main main"
			component="main"
			sx={{
				minHeight: "calc(100vh - 48px - 36px - 35px - 20px)",
				position: "relative",
				top: "-30px",
				left: "10px"
			}}
		>
			<Box className="main__content" sx={{ marginTop: "15px", alignItems: "center" }}>
				<Chat
					titleMarginTop="10px"
					titleMarginLeft="5px"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionTen"
						keyText="last_mission_title"
						defaultValue="{NAME}, it’s your last mission!"
					/>}
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatPageMissionTen;
