import { Box, BoxProps } from "@mui/material";
import { Children, useState } from "react";
import { CSSTransition } from "react-transition-group";

export interface IFlippingCardProps { }

function FlippingCard(
	{
		children,
		className = "",
		onClick,
		sx = [],
		...props
	}: IFlippingCardProps & BoxProps
)
{
	const [ isFlip, setIsFlip ] = useState<boolean>(false);

	if (Children.count(children) !== 2)
	{
		console.error("[FlippingCard] Require only 2 children");
	}

	// Render
	return (
		<Box
			className={"FlippingCard-root " + className}
			onClick={(event) =>
			{
				onClick?.(event);
				setIsFlip((prevState) => !prevState);
			}}
			sx={[
				{
					perspective: "2000px",
					cursor: "pointer",

					"& .FlippingCard-card":
					{
						height: "100%",
						transformStyle: "preserve-3d",

						"&.FlippingCard-card--flip-exit":
						{
							transform: "rotateY(0deg)"
						},
						"&.FlippingCard-card--flip-exit-active":
						{
							transform: "rotateY(180deg)",
							transition: "cubic-bezier(.57, .21, .69, 1.25) 300ms transform"
						},
						"&.FlippingCard-card--flip-exit-done":
						{
							transform: "rotateY(180deg)"
						},
						"&.FlippingCard-card--flip-enter":
						{
							transform: "rotateY(180deg)"
						},
						"&.FlippingCard-card--flip-enter-active":
						{
							transform: "rotateY(0deg)",
							transition: "cubic-bezier(.57, .21, .69, 1.25) 300ms transform"
						},
						"&.FlippingCard-card--flip-enter-done":
						{
							transform: "rotateY(0deg)"
						}
					},
					"& .FlippingCard-card > *":
					{
						position: "absolute",
						top: 0,
						width: "100%",
						height: "100%",
						backfaceVisibility: "hidden"
					},
					"& .FlippingCard-card > :nth-of-type(2)":
					{
						transform: "rotateY(180deg)"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<CSSTransition
				in={isFlip === false}
				timeout={300}
				classNames="FlippingCard-card--flip"
			>
				<div className="FlippingCard-card">
					{children}
				</div>
			</CSSTransition>
		</Box>
	);
}

export default FlippingCard;
