import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography } from "@mui/material";

function PrivacyPolicy()
{
	return (
		<Box sx={{ background: "#236d8e" }}>
			<Box sx={{ width: { xs: "95%", sm: "70%" }, m: "0 auto", pb: 16 }}>
				<Typography
					sx={{
						color: "#fff",
						fontSize: { xs: "32px", sm: "45px", xl: "60px" },
						fontFamily: "Lora-Bold",
						textAlign: "center",
						mb: 6,
						pt: 16
					}}
				>
					Privacy Policy
				</Typography>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "20px",
						fontFamily: "Lora"
					}}
				>
					We take our commitment to data security seriously. We do not misuse
					your personal data, nor pass it to anybody else. All data held by us
					is stored safely and securely.
				</Typography>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Full Statement
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						This privacy policy details the way in which we utilize and protect
						the information that you provide us with. Whenever you submit any
						information that could be used to identify you as an individual, it
						will only ever be used as described in this statement.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Data Security
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Our data security responsibilities are of paramount importance to
						us, and we are dedicated to ensuring the security of all the
						information that we receive from you. We use physical, electronic,
						and business management protocols in order to safeguard our data and
						protect it from unauthorized disclosure or access.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					The information we collect.
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						The amount of data we collect from you come from your contact with
						us, which may include the use of our website, signing up for the
						updates, your use of our website, your subscription to our mailing
						lists, and any offline interaction such as telephone or in-person
						conversations. Typical examples of data we will record include your
						name, email address and other contact information, such as telephone
						numbers. We may also collect demographic information about you such
						as your age, your preferences, and your location.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						We never store information that would be deemed as financially
						sensitive, such as debit or credit card numbers, security numbers or
						bank details. If you provide payment details to us online, we do not
						have sight of any financial information that you enter.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					What we do with your information
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						The information that we take from you helps us better understand and
						serve your needs. It is retained for internal record keeping,
						regulation and audit trail purposes. The information may also be
						used to assist us with the improvement of our services and products.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Occasionally, we may send out emails containing promotional material
						for special offers, new products, or other information we believe
						could be interesting to you. These will be sent to the email address
						you provided us with. All promotional emails we send out contain an
						opportunity to unsubscribe, should you not wish to receive any more.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Taking control of your information
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						We never pass personal information about you to any third party
						unless you grant us permission, or the law requires it of us. You
						could restrict the usage or collection of your personal data. You
						can exercise this by unsubscribing from email using the links
						provided within the emails or emailing us directly to request your
						removal from marketing mailing lists.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						You can request to see all information we hold about you at any time
						by contacting our Data Protection Officer, using the same email
						address. You can also request that the data we hold for you us
						amended or updated at any time, also by contacting the Data
						Protection Officer.
					</Typography>
				</Box>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						We may use Google Analytics, which is a trusted solution to analyse
						and understand the behaviour of visitors on our website. It may
						track things like the timeframe you spend on our website etc.
						Similarly, we will be using Hotjar to collect analysis.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Third party websites
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						You may find links on our website which lead to other websites which
						you may find interesting. We do not have control of any of these
						external websites, and as such cannot be held liable for their own
						data protection processes. No third-party sites are governed by this
						statement. We advise that you use caution, and always check the
						privacy statements of third-party websites before entering any data
						onto them.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Cookies
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						You can set your browser to refuse all or some browser cookies or to
						alert you when websites set or access cookies. If you disable or
						refuse cookies, please note that some parts of this website may
						become inaccessible or not function properly. For more information
						about the cookies we use, please see our cookie policy.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Consent
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						Your use of this website constitutes your acceptance of this privacy
						policy. Your provision, to us, of your personal information
						constitutes your agreement to our usage, storage, processing,
						transferal and disclosure of your data and personal information
						referred to within this privacy policy statement, in accordance with
						the purposes detailed in the same statement.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Copyrights
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						The material content of this website is owned by and/or licensed to
						us. This includes, without limitation, the graphics, design, layout,
						and appearance of the website. Reproduction is strictly prohibited
						unless specific exclusion is granted.
					</Typography>
				</Box>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "30px",
						fontFamily: "Lora-Bold",
						mt: 3
					}}
				>
					Contact us
				</Typography>
				<Box sx={{ display: "flex", mt: 1 }}>
					<FiberManualRecordIcon
						sx={{ fill: "#fff", mr: 2, fontSize: "10px", mt: "10px" }}
					/>
					<Typography
						sx={{
							color: "#fff",
							fontSize: "20px",
							fontFamily: "Lora"
						}}
					>
						If there is any question about our policies, please get in touch
						with us and we will try to explain our services/ policies to meet
						all your needs.
						<a className="link" href="https://www.yourlifestartup.com/">
							hello@yourlifestartup.com
						</a>

					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default PrivacyPolicy;
