import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import bgImgInner from "assets/images/missions/mission-ten/bgImgInner.png";
import ContentAdmin from "components/contentAdmin";
import { useState } from "react";

export interface IMission2Props
{
	isOpen?: boolean;
	closeModal?(): void;
	imgUrl?: string;
	title?: string;
}

function ModalMissionTwo({ isOpen = false, closeModal, imgUrl, title }: IMission2Props)
{
	const [ data, setData ] = useState<string[] | null>(null);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	// Handles
	// useEffect(() =>
	// {
	// 	Api.users
	// 		.topValuesUser()
	// 		.then((values) =>
	// 		{
	// 			console.log('Api: values = ', values);
	// 			setIsErrorLoading(false);
	// 			setData(values.slice(0, 10));
	// 			console.log('Api: Data = ', data);
	// 		})
	// 		.catch((error) =>
	// 		{
	// 			setIsErrorLoading(true);
	// 			console.error(error);
	// 		});
	// }, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			maxHeight: "392px",
			position: "relative",
			// background: 'linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)',
			// border: '1px solid rgba(255, 255, 255, 0.5)',
			backgroundImage: `url(${ bgImgInner })`,
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
			boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
			backdropFilter: "blur(30px)",
			borderRadius: "24px",
			borderTopLeftRadius: "75px",
			borderBottomLeftRadius: "75px"
		}}>
			<CancelIcon
				onClick={closeModal}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					fill: "#3E809D",
					"&:hover": { cursor: "pointer" }
				}}
			/>

			<Box
				component="img"
				src={imgUrl}
				alt="side image"
				className="ImageSideModal-image"
				sx={{
					width: "140px",
					height: "392px",
					borderRadius: "100px",
					filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))"
				}}
			/>

			<Box sx={{ width: "100%", padding: "24px 24px 30px 40px" }}>

				<Typography sx={{ font: { lg: "700 24px/40px Lora", xl: "700 40px/51px Lora" }, color: "#215C75", mb: "12px" }}>
					{title}
				</Typography>
				<Box className="customScroll" sx={{ overflowY: "auto", height: "270px", paddingRight: "8px" }}>
					{/* <Typography sx={{ font: '400 24px/33px "Open Sans"', color: '#4D4D4D', mb: '12px' }}>
							<ContentAdmin keyContent="contentMissionSix" keyText="modal_values_subTitle" defaultValue="You’ve started your journey by understanding the core of who you are — your Values." position="left" isDescription />
						</Typography> */}
					{/* {(data === null || data === undefined) && isErrorLoading === false &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Loading data <CircularProgress size={24} />
								</Box>
							)
						}
						{(data === null || data === undefined) && isErrorLoading === true &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Failed to retrieve data, please try again
								</Box>
							)
						} */}
					{/* {data !== null && data !== undefined && isErrorLoading === false &&
							( */}
					<Box>
						<Typography sx={{ font: { lg: '400 18px/27px "Open Sans"', xl: '400 24px/33px "Open Sans"' }, color: "#4D4D4D", mb: "12px" }}>
							<ContentAdmin
								limit={300}
								keyContent="contentMissionTen"
								keyText="modalMissionTwo_txt"
								defaultValue="Your values won’t change much, only the order might shift slightly. Values are there for you to do a quick check-in with yourself. Are you living your values? Are any ignored? Is your life in sync with your values?\nYou can also use your values as design principles when entering new chapters of your life, such as parenthood or a relationship. Values can also help you make big life decisions, such as whether to move countries or get a new job."
								position="top"
								isDescription
							/>
						</Typography>
						{/* <Box
								component="ol"
								sx={{
									display: "grid",
									gridTemplateRows: "repeat(5, 1fr)",
									gridTemplateColumns: "1fr 1fr",
									gridAutoFlow: "column",
									color: "#215C75",
									font: "700 24px/31px Lora",
									textTransform: "capitalize",
									gap: "24px",
								}}
							>
								{data && data.map((value, index) => (<li key={value + index}>{value}</li>))}
							</Box> */}
					</Box>
					{/* // 	)
						// } */}
				</Box>
			</Box>
		</Box>
	);
}

export default ModalMissionTwo;
