import { AlertColor, Box, Divider, Fade, Snackbar, SxProps, Theme, alpha } from "@mui/material";
import Api from "api";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import config from "config";
import { FormEvent, ReactNode, useState } from "react";
import { ValidationError } from "yup";

function MergeContent()
{
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ email, setEmail ] = useState<string>("");
	const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);

	// Handles
	function handleContentProd()
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.admin
			.setContentProd()
			.then(() =>
			{
				setSnackbarContent({ severity: "success", content: "Success" });
			})
			.catch((err) =>
			{
				setSnackbarContent({ severity: "error", content: "An error occurred" });
				console.error(err);
			})
			.finally(() => setIsFetch(false));
	}

	function sendEmails(event: FormEvent)
	{
		event.preventDefault();

		if (isFetch === true)
		{
			return;
		}

		try
		{
			config.email.yup().validateSync(email);
		}
		catch (e)
		{
			return setErrorMessage((e as ValidationError).message);
		}

		setIsFetch(true);

		Api.users.fullEmailsSend(email)
			.then(() =>
			{
				setSnackbarContent({ severity: "success", content: "Success" });
			})
			.catch((err) =>
			{
				setSnackbarContent({ severity: "error", content: "An error occurred" });
				console.error(err);
			})
			.finally(() => setIsFetch(false));
	}

	// Render
	return (
		<Box sx={{ display: "flex", flexWrap: "wrap", gap: "48px" }}>
			<Section title="Content">
				<CustomButton.Contained
					onClick={handleContentProd}
					loading={isFetch === true}
				>
					Merge content
				</CustomButton.Contained>
			</Section>

			<Section title="Send emails">
				<form onSubmit={sendEmails}>
					<CustomInput.Base
						placeholder="email"
						value={email}
						onChange={({ target }) =>
						{
							setEmail(target.value.trim());
							setErrorMessage(null);
						}}
						sx={{ width: "350px" }}
					/>

					<Fade in={errorMessage !== null}>
						<Box sx={{
							paddingLeft: "5px",
							color: "#DC4242",
							font: "400 14px/19px 'Open Sans'"
						}}>
							{errorMessage}
						</Box>
					</Fade>

					<CustomButton.Base
						type="submit"
						loading={isFetch === true}
						sx={{ width: "100%", marginTop: "24px" }}
					>
						Send emails
					</CustomButton.Base>
				</form>
			</Section>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={3000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

export function Section({ title, children, sx = [] }: { title: ReactNode; children: ReactNode; sx?: SxProps<Theme>; })
{
	return (
		<Box
			component="section"
			className="section-root"
			sx={[
				{
					width: "fit-content",
					padding: "24px",
					backgroundColor: alpha("#FFFFFF", 0.2),
					borderRadius: "24px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",

					"& .section-title":
					{
						font: "700 24px/31px Lora-Bold", textAlign: "center"
					},
					"& .section-divider":
					{
						width: "100%",
						margin: "4px 0 20px 0",
						borderBottomWidth: "2px"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>

			<div className="section-title">
				{title}
			</div>

			<Divider className="section-divider" />

			{children}
		</Box>
	);
}

export default MergeContent;
