import Chat, { IResponseAnswer } from "components/Chat";
import { AnswersType } from "components/Chat/Answer";
import { IMessage } from "components/Chat/Message";
import ContentAdmin from "components/contentAdmin";
import { useEffect, useState } from "react";
import useMission from "../../hooks/useMission";
import ChatAccordion from "./ChatAccordion";

function ServiceChat()
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin keyContent={missionContext.keyContent} keyText="screen_5_chat_message_1" defaultValue="{NAME}, before you define your values, I have a question for you." isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin keyContent={missionContext.keyContent} keyText="screen_5_chat_answer_1" defaultValue="Ask Away!" position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_6_chat_message_1" defaultValue="Do you think some values might be better than others?" isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_7_chat_answer_1" defaultValue="I think so!" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_7_chat_answer_2" defaultValue="Not sure..." position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_8_chat_message_1" defaultValue="Well, yeah, some values might be healthier and more conducive to your wellbeing." isDescription />
				);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin keyContent={missionContext.keyContent} keyText="screen_8_chat_answer_1" defaultValue="How do I choose the good one?" position="top" /> ]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_9_chat_message_1" defaultValue="Glad you asked! You can use these criteria to land on the most healthy values for you." isDescription />,
					() => setChatStep((prevStep) => prevStep + 1)
				);
				break;
			}

			case 5: {
				addMessage("from", <ChatAccordion />);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin keyContent={missionContext.keyContent} keyText="screen_10_chat_answer_1" defaultValue="Interesting!" position="top" /> ]
				});
				break;
			}

			case 6: {
				setChatStep((prevStep) => prevStep + 1);
				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Chat
			sx={{ position: "relative", top: "-16px", left: "-9px" }}
			title={<ContentAdmin
				keyContent={missionContext.keyContent}
				keyText="screen_5_chat_title"
				defaultValue="Are some values better than others?"
			/>}
			messages={messages}
			answers={answers}
			onResponseAnswer={onResponseAnswer}
		/>
	);
}

export default ServiceChat;
