import { Box, Fade, Skeleton, alpha } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import ContentAdmin from "components/contentAdmin";
import Panel from "components/missions/Panel";
import { EMPTY_LIST, MIN_JOBS_COUNT } from "pages/missionFour/components/PersonalJobAd/TopJobsForYouList";
import { Dispatch, SetStateAction, useLayoutEffect, useRef, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import randomInt from "utils/randomInt";
import { LOCAL_STORAGE_PREFIX } from "..";

export interface ITopJobsForYouListProps
{
	nextStep(): void;
	setStep: Dispatch<SetStateAction<number>>;
}

function TopJobsForYouList({ nextStep, setStep }: ITopJobsForYouListProps)
{
	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	const [ jobsList, setJobsList ] = useState<string[]>(() =>
	{
		return getLocalStorage<string[]>(LOCAL_STORAGE_PREFIX + "-create-jobsList") ?? [];
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(LOCAL_STORAGE_PREFIX + "-create-jobsList", jobsList);
	}, [ jobsList ]);

	useLayoutEffect(() =>
	{
		if (isFetch === true || isFetchRef.current === true || jobsList.length !== 0)
		{
			return;
		}

		const store = getLocalStorage<string>(LOCAL_STORAGE_PREFIX + "-create-jobAdText");

		if (store === null)
		{
			return setStep(1);
		}

		setIsFetch(true);
		isFetchRef.current = true;

		Api.bot
			.getResponseBot(
				`Recommend ${ MIN_JOBS_COUNT } most suitable jobs for a person with the following description: ${ store }, and output these works point by point in the array`,
				"returnProfessions"
			)
			.then(({ professions }) =>
			{
				setJobsList(professions);
			})
			.finally(() =>
			{
				setIsFetch(false);
				isFetchRef.current = false;
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Render
	return (
		<>
			{isFetch === false && isFetchRef.current === false &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => nextStep()}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "32px" },
								display: "inline-flex",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "8px", xs: "8px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			<Panel sx={{
				padding: { xl: "32px 32px 26px 32px", lg: "24px", xs: "24px" },
				maxWidth: { xl: "980px", lg: "928px", xs: "928px" },
				height: { xl: "613px", lg: "520px", xs: "520px" },
				justifySelf: "center"
			}}>
				<Box sx={{
					color: "primary.main",
					font: { xl: "700 24px/33px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 24px/33px 'Open Sans'" }
				}}>
					<ContentAdmin keyContent="contentMissionFour" keyText="TopJobsForYou_panelTitle" defaultValue="Here are the jobs that might be suitable for you" position="left" />
				</Box>

				<Box sx={{ position: "relative", marginTop: { xl: "24px", lg: "16px", xs: "16px" } }}>
					<Box
						className="customScroll"
						component="ul"
						sx={{
							listStyle: "none",
							height: "100%",
							margin: "unset",
							padding: "unset",
							paddingRight: "16px",
							overflowY: "auto",
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr",
							gap: "26px 50px",
							justifyContent: "space-between",
							alignItems: "center",
							color: "primary.dark",
							font: "400 16px/22px 'Open Sans'",

							"& .MuiSkeleton-wave::after":
							{
								background: `linear-gradient( 90deg, transparent, ${ alpha("#3E809D", 0.4) }, transparent )`
							}
						}}
					>
						{(isFetch === true || isFetchRef.current === true) &&
							EMPTY_LIST.map((_value, index) =>
							{
								return (
									<li key={index}>
										<Skeleton
											animation="wave"
											sx={{
												backgroundColor: alpha("#3E809D", 0.3),
												height: "1em",
												width: randomInt(30, 85) + "%"
											}}
										/>
									</li>
								);
							})
						}
						{(isFetch === false && isFetchRef.current === false) && jobsList.map((jobName) =>
						{
							return (
								<li key={jobName}>
									{jobName}
								</li>
							);
						})}
					</Box>
				</Box>
			</Panel>
		</>
	);
}

export default TopJobsForYouList;
