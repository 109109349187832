import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import bgRadialMain from "../../../assets/images/bgRadialMain.png";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import RadialButton from "../../../components/RadialButton";
import useMission from "../hooks";

const RadialChart = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box ref={ref} {...props} sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { xl: "980px", xs: "802px" }, m: "10px auto 0px" }}>
			<Box sx={{ mt: "-50px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionFive"
						keyText="radialchart_welcome"
						defaultValue="Welcome to your day!"
						position="right"
					/>}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionFive"
						keyText="radialchart_description"
						defaultValue="{NAME}, you are closer to your dream life than you think. It is much easier to achieve things, when you know what you are working towards. So let’s dream up your perfect average day."
						position="left"
						isDescription
					/>}
				/>
				<Box
					onClick={nextStep}
					sx={{
						width: { xl: "412px", xs: "444px" },
						height: { xl: "412px", xs: "444px" },
						borderRadius: "400px",
						border: "1px solid rgba(255, 255, 255, 0.5)",
						margin: "80px auto 0px",
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
						background: `url(${ bgRadialMain }) center center/ 110%`,
						boxShadow: "0px 4px 6px rgba(73, 140, 168, 0.3)"
					}}>
					<Box
						className="gradientBg"
						sx={{
							width: { xl: "412px", xs: "444px" },
							height: { xl: "412px", xs: "444px" },
							borderRadius: "400px",
							border: "1px solid rgba(255, 255, 255, 0.5)",
							position: "relative",
							overflow: "hidden",
							cursor: "pointer",
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							backdropFilter: "blur(35px)"
						}}>
						<Box sx={{ ml: "60px" }}>
							<RadialButton value="Peak Moments" showValue={false} blip />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
});

export default RadialChart;
