import { Box, BoxProps, alpha } from "@mui/material";
import { ReactNode } from "react";

interface ICardProps extends Omit<BoxProps, "title">
{
	title: ReactNode;
}

function Card({ title, children }: ICardProps)
{
	return (
		<Box sx={{
			padding: { xl: "32px", lg: "22px", xs: "20px" },

			background: "linear-gradient(104deg, rgba(229, 229, 229, 0.553) 9.99%, rgba(239, 239, 239, 0.154) 91.61%)",
			backdropFilter: "blur(30px)",
			border: "1px solid " + alpha("#FFFFFF", 0.5),
			borderRadius: "24px",

			display: "flex",
			flexDirection: "column",
			gap: { xl: "24px", xs: "16px" }
		}}>
			<Box sx={{
				font: { lg: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "primary.main"
			}}>
				{title}
			</Box>

			<Box sx={{
				font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 14px/16px 'Open Sans'" },
				color: "primary.dark",

				flexGrow: 1,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				gap: { xl: "24px", xs: "16px" },

				"& ol": {
					paddingLeft: "24px",
					margin: "unset",

					"& li::marker": {
						fontWeight: "700"
					},
					"& li:not(:first-child)": {
						marginTop: { xl: "24px", xs: "16px" }
					}
				},
				"& ul": {
					paddingLeft: "24px",
					margin: "unset",

					"& li::marker": {
						fontSize: "14px"
					},
					"& li:not(:first-child)": {
						marginTop: { xl: "24px", xs: "16px" }
					}
				},
				"& .video-container":
				{
					width: "100%",
					maxWidth: { xl: "496px", lg: "334px", xs: "286px" },
					height: { xl: "279px", lg: "188px", xs: "161px" },
					overflow: "hidden",
					borderRadius: { xl: "24px", xs: "16px" }
				}
			}}>
				{children}
			</Box>
		</Box>
	);
}

export default Card;
