import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

export type ILetsTalkAboutBeliefsProps = Record<string, any>;

function LetsTalkAboutBeliefs({ ...props }: ILetsTalkAboutBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_message_1" defaultValue="That was fun! What you caught there were a bunch of beliefs." isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [
				<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_answer_1" defaultValue="Beliefs?" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_message_2" defaultValue="Yes, everyone has their own perspective on how things are, and that’s called a belief system. Each belief system works, but sometimes our beliefs can hold us back from becoming our best selves." isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_answer_2" defaultValue="Interesting" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_message_3" defaultValue="Well, some beliefs can be limiting, but some can really empower you. Depending on what you believe, you will have a very different perception of life. The good news is that you can change your belief system! It is not set in stone." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_answer_3" defaultValue="That’s good news" position="top" />,
						<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_answer_4" defaultValue="Phew" position="top" />
					]
				});
				break;
			}

			case 4: { setChatStep((prevStep) => prevStep + 1); setTimeout(() => missionContext.nextStep(), 1000); }
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Chat
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsTalkAboutBeliefs_chat_title" defaultValue="Let’s talk about beliefs" />}
					onResponseAnswer={onResponseAnswer}
					messages={messages}
					answers={answers}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(LetsTalkAboutBeliefs);
