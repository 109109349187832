import { Masonry } from "@mui/lab";
import { Box } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { FormEvent, useLayoutEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import * as yup from "yup";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";
import Create from "./Create";
import Details from "./Details";
import Edit from "./Edit";
import IMGCreateNewBoardBackground from "./assets/images/create-new-board-background.png";

export interface IVisionBoard
{
	id: string;
	title: string;
	images: string[];
	date: string;
}

export const BOARD_RULES =
{
	MINIMUM_SELECT_IMAGES: 1,
	MINIMUM_LENGTH_TITLE: 2,
	MAXIMUM_LENGTH_TITLE: 20,

	TEST_TITLE_REGEX(value: string)
	{
		return /^[A-Za-zА-Яа-яЁё0-9-_. ]+$/.test(value);
	},
	TITLE_YUP()
	{
		return yup
			.string()
			// eslint-disable-next-line no-template-curly-in-string
			.min(this.MINIMUM_LENGTH_TITLE, "At least ${min} characters are required")
			// eslint-disable-next-line no-template-curly-in-string
			.max(this.MAXIMUM_LENGTH_TITLE, "You need a maximum of ${max} characters")
			.test(
				"title-test",
				"Only allow: A-z, А-я, 0-9, '.', '-', '_' and space.",
				(value) => this.TEST_TITLE_REGEX(value || "")
			);
	}
};

function VisionBoard()
{
	const navigate = useNavigate();
	const params = useParams();

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ boards, setBoards ] = useState<IVisionBoard[]>([]);
	const [ newBoardTitle, setNewBoardTitle ] = useState<string>(() => getCurrentDate());
	const [ isShowModalCreateBoard, setIsShowCreateBoard ] = useState<boolean>(false);
	const [ editTitleError, setEditTitleError ] = useState<string | null>(null);

	// Utils
	function updateItems()
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.missions
			.getDataMissionSix()
			.then(({ data }) =>
			{
				setBoards(data.map(({ id, title, mission_data: images, created_at }): IVisionBoard =>
				{
					return { id, title, images, date: created_at.replaceAll("/", ".") };
				}));
			})
			.finally(() => setIsFetch(false));
	}

	function getCurrentDate()
	{
		const currentDate = new Date();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const year = String(currentDate.getFullYear());

		return `${ month }.${ day }.${ year }`;
	}

	// Effects
	useLayoutEffect(() =>
	{
		updateItems();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Handles
	function onCreateNewBoard(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			BOARD_RULES.TITLE_YUP().validateSync(newBoardTitle);
		}
		catch (e)
		{
			error = (e as yup.ValidationError).message;
		}

		setEditTitleError(error);

		if (error !== null)
		{
			return;
		}

		setLocalStorage("dashboard-visionBoard-create-title", newBoardTitle);
		navigate("/dashboard/artifacts/vision-board/create");
		setIsShowCreateBoard(false);
	}

	// Render
	switch (params.action)
	{
		case "create": return (<Create updateItems={updateItems} />);
		case "details": return (<Details boards={boards} />);
		case "edit": return (<Edit boards={boards} updateItems={updateItems} />);
		case undefined: break;
		default: return (<Navigate to="/dashboard/artifacts/vision-board" />);
	}

	return (
		<ArtifactsContainer
			title={<ContentAdmin position="top-start" defaultValue="My Vision Boards" keyContent="contentDashboard" keyText="artifacts_mission6_title" />}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					position: "relative",
					gap: "40px"
				}
			}}
		>
			<Box sx={{ display: "flex", gap: "32px", maxHeight: { xl: "685px", lg: "536px", xs: "536px" } }}>
				<Box
					className="customScroll"
					sx={{
						width: "100%",
						maxWidth: { xl: "1266px", lg: "792px", xs: "792px" },
						overflowY: "auto"
					}}
				>
					<Masonry
						columns={{ xl: 4, lg: 3, xs: 3 }}
						spacing="32px"
						sx={{
							maxWidth: "1266px",
							width: "100%",
							"& > div":
							{
								width: { xl: "285px", lg: "236px", xs: "252px" }
							}
						}}
					>
						{boards.map(({ id, title, images, date }) =>
						{
							return (
								<div
									key={id}
									onClick={() => navigate("/dashboard/artifacts/vision-board/details/" + id)}
									style={{ cursor: "pointer" }}
								>
									<Box sx={{
										color: "primary.main",
										font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
										wordBreak: "break-word"
									}}>
										{title || date}
									</Box>

									<Box
										component="img"
										src={images[ 0 ]}
										alt=""
										sx={{
											marginTop: "8px",
											width: "100%",
											borderRadius: "16px"
										}}
									/>
								</div>
							);
						})}
					</Masonry>
				</Box>
				<Box
					component="button"
					sx={{
						width: "359px",
						padding: "unset",
						background: `url(${ IMGCreateNewBoardBackground }) center center/cover`,
						border: "unset",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",

						"& div":
						{
							transition: "linear 200ms opacity"
						},
						"&:hover div":
						{
							opacity: 0.7
						}
					}}
					onClick={() =>
					{
						if (getLocalStorage<string>("dashboard-visionBoard-create-title") !== null)
						{
							return navigate("/dashboard/artifacts/vision-board/create");
						}

						setIsShowCreateBoard(true);
					}}
				>
					<Box sx={{ font: "700 24px/31px Lora-Bold", color: "primary.main" }}>
						Create new board
						<Box sx={{ font: "600 120px/154px Lora", position: "relative", top: "-19px" }}>+</Box>
					</Box>
				</Box>
			</Box>

			<Dialog
				open={isShowModalCreateBoard}
				onClose={() => setIsShowCreateBoard(false)}
				title="Name your new board"
			>
				<form onSubmit={onCreateNewBoard}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="name"
							value={newBoardTitle}
							inputProps={{ maxLength: BOARD_RULES.MAXIMUM_LENGTH_TITLE }}
							onChange={({ target }) => setNewBoardTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{
								color: "#DC4242", font: "400 14px/19px 'Open Sans'"
							}}>
								{editTitleError}
							</Box>

							<Box sx={{
								color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'"
							}}>
								{newBoardTitle.length}/{BOARD_RULES.MAXIMUM_LENGTH_TITLE} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

export default VisionBoard;
