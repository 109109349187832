import { Box, Typography } from "@mui/material";
import { Ref, forwardRef } from "react";
import octa2 from "../../../assets/images/missions/mission-five/octa.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const FinishNight = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setBlurBg(false);

		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box ref={ref} {...props} sx={{ height: "81vh", width: "100%", maxWidth: "980px", m: "0 auto" }}>
			<Box sx={{ width: { xl: "205px", xs: "199px" }, m: "40px auto 30px" }}>
				<img width="100%" src={octa2} alt="guide-chara" />
			</Box>
			<Typography
				className="component-missions-panel-title"
				sx={{
					font: "700 40px/51px Lora",
					color: "#E9EDF0",
					textAlign: "center",
					wordBreak: "break-all",
					maxHeight: "102px",
					overflow: "hidden"
				}}
			>
				<ContentAdmin limit={111} keyContent="contentMissionFive" keyText="finish_night" defaultValue="You are a superstar, {NAME}!" position="top" />
			</Typography>
			<Box sx={{
				display: "flex",
				justifyContent: "center",
				mt: 5
			}}>
				<CustomButton.Base
					sx={{
						padding: { xl: "7px 43px", xs: "7.5px 80px" },
						fontSize: "24px",
						lineHeight: "33px"
					}}
					onClick={nextStep}>Thanks, Octa!
				</CustomButton.Base>
			</Box>
		</Box>
	);
});

export default FinishNight;
