import { Box, SxProps, TextField, Theme, Typography } from "@mui/material";

import { ArrValues } from "pages/missionTen/feature/LifeExperiments";
import CustomButton from "../customButton";

interface IForm
{
	componentInputs: Omit<ArrValues, "title">;
	addInput: any;
	title?: string;
	handleChangeArrInputs: any;
	onSubmit(): any;
	closeForm(): void;
	sx?: SxProps<Theme>;
	valueName?: string;
	setValueName?: any;

}

// const Form = (open: any) =>
function Form({ componentInputs, addInput, title, handleChangeArrInputs, onSubmit, closeForm, sx = [], valueName, setValueName }: IForm)
{
	const conditionDisabled = isDisabled();

	// Utils
	function isDisabled()
	{
		if (componentInputs.test.trim() === "")
		{
			return true;
		}

		if (componentInputs.run.filter((item) => item.value.trim().length > 0).length === 0)
		{
			return true;
		}

		if (componentInputs.success.trim() === "")
		{
			return true;
		}

		return false;
	}

	// Render
	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={onSubmit}
			sx={[
				{
					position: "relative",
					zIndex: "100",
					width: { lg: "786px", xl: "906px" },
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
					borderRadius: "24px"
					// border: '1px solid green'
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Box sx={{
				background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
				boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
				display: "flex",
				flexDirection: "column",
				gap: "0px",
				border: "1px solid rgba(255, 255, 255, 0.5)",
				borderRadius: "24px",
				backdropFilter: "blur(30px)",
				p: { lg: "25px", xl: "23px 0px 0px 24px" },
				mt: 0
			}}>
				<Box sx={{ pr: { lg: 0, xl: "10px" } }}>
					<Typography sx={{
						m: { lg: "0 0 16px 14px", xl: "0 0 16px" },
						color: "#215C75",
						font: { lg: "700 18px/16px 'Lora-Bold'", xl: "700 22px/30px 'Lora-Bold'" }
					}}>
						{title}
					</Typography>

					<Box
						className="customScroll"
						sx={{
							height: { lg: "324px", xl: "510px" },
							overflowY: "auto",
							paddingRight: { lg: "19px", xl: "10px" }
						}}>

						{valueName !== undefined &&
							(
								<Box>
									<Typography sx={{
										m: { lg: "0 0 8px 14px", xl: "0 0 16px 12px" },
										color: "#4D4D4D",
										font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }
									}}>
										What name your experiment?
									</Typography>

									<TextField
										inputProps={{ maxLength: 120 }}
										value={valueName}
										onChange={(e: any) => setValueName(e.target.value)}
										fullWidth
										multiline
										rows={2}
										autoFocus
										placeholder="e.g. I like being an artist & it brings income."
										helperText={componentInputs.test.length + "/120 characters"}
										sx={{
											"& .MuiInputBase-root fieldset": {
												border: "none"
											},
											"& .MuiOutlinedInput-root": {
												font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
												color: "#4D4D4D !important",
												backgroundColor: "rgba(255, 255, 255, 0.6)",
												borderRadius: "8px",
												maxHeight: { lg: "64px", xl: "86px" }
											},
											"& .MuiFormHelperText-contained": {
												textAlign: "right",
												font: '400 14px/19px "Open Sans"',
												color: "#4D4D4D",
												opacity: "0.5"
											}
										}}
									/>
								</Box>
							)
						}

						<Typography sx={{
							m: { lg: "0 0 8px 14px", xl: "0 0 16px 12px" },
							color: "#4D4D4D",
							font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }
						}}>
							What do you want to test?
						</Typography>

						<TextField
							inputProps={{ maxLength: 150 }}
							value={componentInputs.test}
							onChange={(e: any) => handleChangeArrInputs(e.target.value, 0, "test")}
							fullWidth
							multiline
							rows={2}
							autoFocus
							placeholder="e.g. I like being an artist & it brings income."
							helperText={`${ componentInputs.test.length }/150 characters`}
							sx={{
								"& .MuiInputBase-root fieldset": {
									border: "none"
								},
								"& .MuiOutlinedInput-root": {
									font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
									color: "#4D4D4D !important",
									backgroundColor: "rgba(255, 255, 255, 0.6)",
									borderRadius: "8px",
									maxHeight: { lg: "64px", xl: "86px" }
								},
								"& .MuiFormHelperText-contained": {
									textAlign: "right",
									font: '400 14px/19px "Open Sans"',
									color: "#4D4D4D",
									opacity: "0.5"
								}
							}}
						/>

						<Typography sx={{
							m: { lg: "0 0 8px 14px", xl: "0 0 16px 12px" },
							color: "#4D4D4D",
							font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }
						}}>
							What experiments can you run?
						</Typography>

						{componentInputs.run.map((element: any, index: number) => (
							<TextField
								key={index}
								inputProps={{ maxLength: 150 }}
								value={element.value}
								onChange={(e: any) => { handleChangeArrInputs(e.target.value, index, "run"); }}
								fullWidth
								multiline
								rows={2}
								autoFocus
								helperText={`${ element.value.length }/150 characters`}
								placeholder="e.g. Create a website displaying my art with prices"
								sx={{
									"& .MuiInputBase-root fieldset": {
										border: "none"
									},
									"& .MuiOutlinedInput-root": {
										font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
										color: "#4D4D4D !important",
										backgroundColor: "rgba(255, 255, 255, 0.6)",
										borderRadius: "8px",
										maxHeight: { lg: "64px", xl: "86px" }
									},
									"& .MuiFormHelperText-contained": {
										textAlign: "right",
										font: '400 14px/19px "Open Sans"',
										color: "#4D4D4D",
										opacity: "0.5",
										mt: "1px"
									}
								}}
							/>
						))}

						<Box
							onClick={addInput}
							sx={{
								display: "flex",
								flexDirection: "row",
								marginBottom: "15px",
								"&:hover": { cursor: "pointer" }
							}}
						>
							<Typography sx={{
								color: "#215C75",
								fontFamily: "Open Sans",
								fontSize: "45px",
								fontWeight: "400",
								lineHeight: "30px",
								textAlign: "left",
								maxHeight: "60px",
								position: "relative",
								top: "-8px",
								left: "10px"
							}}>
								+
							</Typography>
						</Box>

						<Typography sx={{
							m: { lg: "0 0 8px 14px", xl: "0 0 16px 12px" },
							color: "#4D4D4D",
							font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }
						}}>
							How will you know the experiment was a success?
						</Typography>

						<TextField
							inputProps={{ maxLength: 150 }}
							value={componentInputs.success}
							onChange={(e: any) => handleChangeArrInputs(e.target.value, 0, "success")}
							fullWidth
							multiline
							rows={2}
							autoFocus
							placeholder="e.g. I like being an artist & it brings income."
							helperText={`${ componentInputs.success.length }/150 characters`}
							sx={{
								"& .MuiInputBase-root fieldset": {
									border: "none"
								},
								"& .MuiOutlinedInput-root": {
									font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
									color: "#4D4D4D !important",
									backgroundColor: "rgba(255, 255, 255, 0.6)",
									borderRadius: "8px",
									maxHeight: { lg: "64px", xl: "86px" }
								},
								"& .MuiFormHelperText-contained": {
									textAlign: "right",
									font: '400 14px/19px "Open Sans"',
									color: "#4D4D4D",
									opacity: "0.5"
								}
							}}
						/>
					</Box>

					<Box sx={{
						display: "flex",
						justifyContent: "center",
						mt: 0,
						mb: { lg: 0, xl: 2 }
					}}>
						<CustomButton.Outline
							disabled={conditionDisabled}
							sx={{
								fontSize: "24px",
								lineHeight: "33px",
								color: "#fff !important",
								marginTop: "-3px",
								backgroundColor: (conditionDisabled ? "#3E809D80" : "#3E809D"),
								border: "none",
								// borderColor: (conditionDisabled ? '#3E809D80' : '#3E809D'),
								"&:hover": {
									cursor: (conditionDisabled ? "normal" : "pointer")
								}
							}}
							onClick={onSubmit}
						>
							Done
						</CustomButton.Outline>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default Form;
