import { Box, SxProps, TextField, Theme, Typography } from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import ContentAdmin from "../contentAdmin";
import CustomButton from "../customButton";

const Accordion = styled((props: any) => (
	<MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
	border: "1px solid transparent",
	"&:not(:last-child)": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	}
}));

export interface IFormAccordionProps
{
	title: any;
	setExpanded: any;
	formik: any;
	expanded: any;
	sx?: SxProps<Theme>;
}

function FormAccordion({ title, expanded, setExpanded, formik, sx }: IFormAccordionProps)
{
	function closeForm()
	{
		setExpanded([ false, false, false ]);
		formik.handleSubmit();
	}

	function handleChange(key: number)
	{
		setExpanded(expanded.map((item: boolean, index: number) =>
		{
			return (index === key ? !item : item);
		}));
	}

	return (

		<Box
			component="form"
			autoComplete="off"
			onSubmit={formik.handleSubmit}
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "absolute",
				top: { lg: "-170px", xl: "-270px" },
				width: "100%",
				zIndex: 1,
				overflow: "hidden",
				borderRadius: "24px",
				...sx
			}}>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				border: "1px solid rgba(255, 255, 255, 0.5)",
				p: "32px 16px 0px 32px",
				borderRadius: "24px",
				boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
				background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
				backdropFilter: "blur(30px)",
				mt: 0,
				gap: "0px"
			}}>
				<Box>
					<Box sx={{ position: "relative" }}>
						<Typography sx={{ marginLeft: "16px", marginBottom: "18px", color: "#215C75", fontFamily: "Lora-Bold", fontSize: "22px", fontWeight: "700", lineHeight: "30px", textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
							{title}
						</Typography>
					</Box>
					<Box
						className="customScroll"
						sx={{
							height: { lg: "370px", xl: "470px" },
							overflowY: "auto",
							paddingRight: "16px"
						}}>
						<>
							<Accordion
								expanded={expanded[ 0 ]}
								onChange={() => handleChange(0)}
								sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "transparent", boxShadow: "none" }}
							>
								<MuiAccordionSummary
									sx={{
										"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
											transform: "rotate(180deg)",
											padding: "0px",
											margin: "0px"
										},
										"& .MuiAccordionSummary-content": {
											marginLeft: "10px",
											flexGrow: "0",
											padding: "0px",
											margin: "0px !important"
										},
										"& .MuiAccordionSummary-root": {
											padding: "0px",
											margin: "0px !important"
										}
									}}
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1.2rem",
												color: "rgba(0, 0, 0, 0.87)",
												transform: "rotate(90deg)",
												position: "relative",
												top: "2px"
											}}
										/>
									}
									aria-controls="panel1d-content"
									id="panel1d-header">
									<Typography
										sx={{
											color: "#4D4D4D",
											font: { lg: '700 16px/24px "Open Sans"', xl: '700 22px/30px "Open Sans"' },
											marginRight: "8px"

										}}>
										<ContentAdmin
											limit={60}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_define"
											defaultValue="Define"
											position="left"
										/>
									</Typography>
								</MuiAccordionSummary>
								<MuiAccordionDetails>
									<Typography sx={{ color: "#4D4D4D", font: { lg: '400 16px/24px "Open Sans"', xl: '400 22px/30px "Open Sans"' } }}>
										<ContentAdmin
											limit={250}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_define_txt"
											defaultValue="What is the worst thing that can happen? Ask yourself ‘So what?’ until you get to the root cause of your fear, then write it down."
											position="left"
											isDescription
										/>

									</Typography>
								</MuiAccordionDetails>
							</Accordion>
							<TextField
								inputProps={{ maxLength: 150 }}
								value={formik.values.willRun}
								onChange={formik.handleChange}
								id="willRun"
								fullWidth
								name="willRun"
								helperText={`${ formik.values.willRun.length }/150 characters`}
								// placeholder='What is the worst thing that can happen? Ask yourself ‘So what?’ until you get to the root cause of your fear, then write it down.'
								sx={{
									"& .MuiInputBase-root fieldset": {
										border: "none"
									},
									"& .MuiOutlinedInput-root": {
										font: '400 22px/30px "Open Sans"',
										color: "#4D4D4D !important",
										backgroundColor: "rgba(255, 255, 255, 0.6)",
										borderRadius: "8px"
									},
									"& .MuiFormHelperText-contained": {
										textAlign: "right",
										font: '400 14px "Open Sans"',
										color: "#fff",
										opacity: "0.5"
									}
								}}
							/>
							<Typography sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
								{/* {formik.errors.willRun || (arrValues.size >= 70 && "Limit of 70 has been reached")} */}
								{formik.touched.willRun ? formik.errors.willRun : null}
							</Typography>
						</>
						<>
							<Accordion
								expanded={expanded[ 1 ]}
								onChange={() => handleChange(1)}
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									backgroundColor: "transparent",
									boxShadow: "none"
								}}
							>
								<MuiAccordionSummary
									sx={{
										"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
											transform: "rotate(180deg)",
											padding: "0px",
											margin: "0px"
										},
										"& .MuiAccordionSummary-content": {
											marginLeft: "10px",
											flexGrow: "0",
											padding: "0px",
											margin: "0px !important"
										},
										"& .MuiAccordionSummary-root": {
											padding: "0px",
											margin: "0px !important"
										},
										"& .MuiPaper-root-MuiAccordion-root:before": {
											backgroundColor: "transparent",
											border: "1px solid red"
										}

									}}
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1.2rem",
												color: "rgba(0, 0, 0, 0.87)",
												transform: "rotate(90deg)",
												position: "relative",
												top: "2px"
											}}
										/>
									}
									aria-controls="panel1d-content"
									id="panel1d-header">
									<Typography
										sx={{
											color: "#4D4D4D",
											font: { lg: '700 16px/24px "Open Sans"', xl: '700 22px/30px "Open Sans"' },
											marginRight: "8px"

										}}>
										<ContentAdmin
											limit={60}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_prevent"
											defaultValue="Prevent"
											position="left"
										/>
									</Typography>
								</MuiAccordionSummary>
								<MuiAccordionDetails>
									<Typography sx={{ color: "#4D4D4D", font: { lg: '400 16px/24px "Open Sans"', xl: '400 22px/30px "Open Sans"' } }}>
										<ContentAdmin
											limit={250}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_txt"
											defaultValue="What could you do or ask someone to help you to prevent this from happening. Even if it only decreases the likelihood by 1%? .If it DID happen, what could you do to either repair the damage or get back on track?"
											position="left"
											isDescription
										/>

									</Typography>
								</MuiAccordionDetails>
							</Accordion>
							<TextField
								inputProps={{ maxLength: 200 }}
								value={formik.values.canCreate}
								onChange={formik.handleChange}
								id="canCreate"
								fullWidth
								multiline
								rows={2}
								name="canCreate"
								helperText={`${ formik.values.canCreate.length }/200 characters`}
								// placeholder='What could you do or ask someone to help you to prevent this from happening. Even if it only decreases the likelihood by 1%? .If it DID happen, what could you do to either repair the damage or get back on track?'
								sx={{
									"& .MuiInputBase-root fieldset": {
										border: "none"
									},
									"& .MuiOutlinedInput-root": {
										font: '400 22px/30px "Open Sans"',
										color: "#4D4D4D !important",
										backgroundColor: "rgba(255, 255, 255, 0.6)",
										borderRadius: "8px"
									},
									"& .MuiFormHelperText-contained": {
										textAlign: "right",
										font: '400 14px "Open Sans"',
										color: "#fff",
										opacity: "0.5"
									}
								}}
							/>
							<Typography sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
								{/* {formik.errors.canCreate || (arrValues.size >= 70 && "Limit of 70 has been reached")} */}
								{formik.touched.canCreate ? formik.errors.canCreate : null}
							</Typography>
						</>
						<>
							<Accordion
								expanded={expanded[ 2 ]}
								onChange={() => handleChange(2)}
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									backgroundColor: "transparent",
									boxShadow: "none"
								}}
							>
								<MuiAccordionSummary
									sx={{
										"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
											transform: "rotate(180deg)",
											padding: "0px",
											margin: "0px"
										},
										"& .MuiAccordionSummary-content": {
											marginLeft: "10px",
											flexGrow: "0",
											padding: "0px",
											margin: "0px !important"
										},
										"& .MuiAccordionSummary-root": {
											padding: "0px",
											margin: "0px !important"
										},
										"& .MuiPaper-root-MuiAccordion-root:before": {
											backgroundColor: "transparent",
											border: "1px solid red"
										}

									}}
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1.2rem",
												color: "rgba(0, 0, 0, 0.87)",
												transform: "rotate(90deg)",
												position: "relative",
												top: "2px"
											}}
										/>
									}
									aria-controls="panel1d-content"
									id="panel1d-header">
									<Typography
										sx={{
											color: "#4D4D4D",
											font: { lg: '700 16px/24px "Open Sans"', xl: '700 22px/30px "Open Sans"' },
											marginRight: "8px"

										}}>

										<ContentAdmin
											limit={60}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_repair"
											defaultValue="Repair"
											position="left"
										/>
									</Typography>
								</MuiAccordionSummary>
								<MuiAccordionDetails>
									<Typography sx={{ color: "#4D4D4D", font: { lg: '400 16px/24px "Open Sans"', xl: '400 22px/30px "Open Sans"' } }}>
										<ContentAdmin
											limit={250}
											keyContent="contentMissionEight"
											keyText="form_faceYourFears_repair_txt"
											defaultValue="What is the worst thing that can happen? Ask yourself ‘So what?’ until you get to the root cause of your fear, then write it down."
											position="left"
											isDescription
										/>

									</Typography>
								</MuiAccordionDetails>
							</Accordion>
							<TextField
								inputProps={{ maxLength: 200 }}
								value={formik.values.canFind}
								onChange={formik.handleChange}
								id="canFind"
								fullWidth
								multiline
								rows={2}
								name="canFind"
								helperText={`${ formik.values.canFind.length }/200 characters`}
								// placeholder='What is the worst thing that can happen? Ask yourself ‘So what?’ until you get to the root cause of your fear, then write it down.'
								sx={{
									"& .MuiInputBase-root fieldset": {
										border: "none"
									},
									"& .MuiOutlinedInput-root": {
										font: '400 22px/30px "Open Sans"',
										color: "#4D4D4D !important",
										backgroundColor: "rgba(255, 255, 255, 0.6)",
										borderRadius: "8px"
									},
									"& .MuiFormHelperText-contained": {
										textAlign: "right",
										font: '400 14px "Open Sans"',
										color: "#fff",
										opacity: "0.5"
									}
								}}
							/>
							<Typography sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
								{/* {formik.errors.willRun || (arrValues.size >= 70 && "Limit of 70 has been reached")} */}
								{formik.touched.canFind ? formik.errors.canFind : null}
							</Typography>
						</>
					</Box>
					<Box sx={{
						display: "flex",
						justifyContent: "center",
						mt: 0,
						mb: 2
					}}>
						<CustomButton.Contained
							disabled={!formik.values.willRun || !formik.values.canCreate || !formik.values.canFind}
							onClick={closeForm}
							sx={{ marginTop: "15px" }}
						>
							Submit
						</CustomButton.Contained>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default FormAccordion;
