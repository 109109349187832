import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Health()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-health-reflection"
					defaultValue="- How is your sleep?\n- Do you eat well and exercise enough? \n- Are you burnt out?\n- What is your level of stress?\n- Do you feel lonely?\n- Do you live in alignment with your values?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-health-actionPlan"
					defaultValue="1. **Attend to your health.** Schedule a general check up or a doctor’s appointment for a known issues.\n1. **Fix the foundation.** Small tweaks to your sleep schedule, diet and exercise routine can make an enormous difference.\n1. **Re-design your lifestyle.** Stress at work, sedentary lifestyle, lack of social connections can all lead to poor health. Think of what kind of lifestyle could help you promote better health."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-health-howCanHelp"
					defaultValue="My Life Quest will help you create a lifestyle that supports your health goals. You will get clarity on what needs fixing and walk away with a strategic action plan. "
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/v4ejgNJpIz8?si=zbbn1fGl8MnYrHdF"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Health;
