import { Box, BoxProps } from "@mui/material";
import ArrowButton from "components/ArrowButton";
import RadialButton from "components/RadialButton";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { ForwardRefExoticComponent, Ref, forwardRef, useState } from "react";
import useMission from "../../hooks/useMission";

export interface ITimeToActProps extends BoxProps { }

function TimeToAct({ ...props }: ITimeToActProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ isOpenButton, setIsOpenButton ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ showTips, setShowTips ] = useState<null | 1 | 2>(null);

	// Handles
	function nextStep()
	{
		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="TimeToAct_title" defaultValue="Time to act" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="TimeToAct_subTitle" defaultValue="{NAME}, don’t just dream about a fulfilling career, take action towards it!" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{
					marginTop: "115px",
					height: "255px",
					width: "100%",

					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between"
				}}>
					<Box sx={{ alignSelf: "flex-end" }}>
						<RadialButton
							textTransformValue="none"
							blip={isOpenButton[ 0 ] === false}
							color="blue"
							value="Adopt ‘bias towards action’ approach"
							direction="left"
							onClick={() =>
							{
								setIsOpenButton((prevState) =>
								{
									return Object.assign([], prevState, { 0: true });
								});
								setShowTips(1);
							}}
							sx={{
								"&:not(:hover) .RadialButton-value":
								{
									display: "none"
								},
								"& .RadialButton-value":
								{
									boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)"
								}
							}}
						/>
					</Box>

					<Box>
						<RadialButton
							textTransformValue="none"
							blip={isOpenButton[ 1 ] === false}
							color="blue"
							value="Show up every day"
							direction="right"
							onClick={() =>
							{
								setIsOpenButton((prevState) =>
								{
									return Object.assign([], prevState, { 1: true });
								});
								setShowTips(2);
							}}
							sx={{
								"&:not(:hover) .RadialButton-value":
								{
									display: "none"
								},
								"& .RadialButton-value":
								{
									boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)"
								}
							}}
						/>
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={nextStep} disabled={isOpenButton.some((value) => value === false)} />
			</StepperNavigationWrapper>

			<MissionsDialog
				title={<ContentAdmin keyContent={missionContext.keyContent} keyText="timeToAct_modal_1_title" defaultValue="Adopt ‘bias towards action’ approach" position="left" />}
				open={showTips === 1}
				onClose={() => setShowTips(null)}
			>
				<ContentAdmin keyContent={missionContext.keyContent} keyText="timeToAct_modal_1_body" defaultValue="Get out of your head and take action towards exploring your options! Don’t overthink it, even small steps count. Research from your couch, talk to a friend, or sign up for a free course. The key is to take action, no matter how small it may seem. Just do it!" position="left" isDescription />
			</MissionsDialog>

			<MissionsDialog
				title={<ContentAdmin keyContent={missionContext.keyContent} keyText="timeToAct_modal_2_title" defaultValue="Show up every day" position="left" />}
				open={showTips === 2}
				onClose={() => setShowTips(null)}
			>
				<ContentAdmin keyContent={missionContext.keyContent} keyText="timeToAct_modal_2_body" defaultValue="Committing to small daily actions is key to achieving your life change goals. Even if it’s just 3 minutes of reading about a career you’re interested in, showing up consistently makes all the difference. Don’t let daily distractions get in the way, make showing up a daily habit and watch your progress soar." position="left" isDescription />
			</MissionsDialog>
		</Box>
	);
}

export default forwardRef(TimeToAct) as ForwardRefExoticComponent<ITimeToActProps>;
