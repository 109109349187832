import { alpha, Box, SxProps, Theme } from "@mui/material";
import { forwardRef, Ref } from "react";

export interface IGoatTooltipProps
{
	title: string | JSX.Element;
	/**
	 * @default false
	 */
	isShow: boolean;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function GoatTooltip({ title, isShow = false, sx = [], ...props }: IGoatTooltipProps, ref: Ref<HTMLDivElement>)
{
	return (
		<Box
			ref={ref}
			sx={[
				{
					position: "absolute",
					top: { xl: "-88px", lg: "-80px", xs: "-80px" },
					left: { xl: "-113px", lg: "-90px", xs: "-90px" },
					opacity: 1,
					transition: "linear 300ms opacity"
				},
				...[ isShow === false && { opacity: 0 } ],
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Box
				ref={ref}
				sx={{
					font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
					color: "primary.dark",
					backgroundColor: alpha("#FFFFFF", 0.5),
					borderRadius: "40px",
					padding: "8px 16px",
					width: "max-content"
				}}
				{...props}
			>
				{title}
			</Box>

			<Box sx={{
				"--size": { xl: "32px", lg: "26px", xs: "25px" },
				position: "absolute",
				top: { xl: "64px", lg: "49px", xs: "49px" },
				left: { xl: "27px", lg: "22px", xs: "22px" },
				backgroundColor: alpha("#FFFFFF", 0.5),
				width: "var(--size)",
				height: "var(--size)",
				borderRadius: "50%"
			}} />

			<Box sx={{
				"--size": "24px",
				position: "absolute",
				top: { xl: "96px", lg: "77px", xs: "77px" },
				left: { xl: "70px", lg: "56px", xs: "56px" },
				backgroundColor: alpha("#FFFFFF", 0.5),
				width: "var(--size)",
				height: "var(--size)",
				borderRadius: "50%"
			}} />
		</Box>
	);
}

export default forwardRef(GoatTooltip);
