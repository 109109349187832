import { styled, Typography } from "@mui/material";
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)({
	"& .MuiInputBase-root fieldset": {
		border: "none !important"
	},
	"& .MuiOutlinedInput-root": {
		font: '400 22px/30px "Open Sans"',
		color: "#4D4D4D !important",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		borderRadius: "10px !important",
		minHeight: "56px",
		maxHeight: "109px",
		lineHeight: "56px"
	},
	"& .MuiOutlinedInput-root input": {
		padding: "12.5px 14px"

	},
	"& .MuiFormHelperText-contained": {
		textAlign: "right",
		font: '400 14px/19px "Open Sans" !important',
		color: "#4D4D4D !important",
		opacity: "0.5",
		marginTop: "-1px"
	},
	"& .MuiInputAdornment-positionStart": {
		font: "inherit",
		letterSpacing: "inherit",
		color: "currentColor",
		background: "none",
		height: "1.4375em",
		margin: "0px 5px 0px 0px"
	},
	"& .MuiInputAdornment-positionStart p": {
		font: '400 22px/30px "Open Sans" !important',
		color: "#4D4D4D !important",
		paddingLeft: "5px !important",

		border: "1px solid  !important"
	}
});

export function InputField({ error = undefined, ...rest })
{
	function _renderHelperText()
	{
		if (error)
		{
			return error;
		}
		// return error;
	}

	return (
		<>
			<TextField
				inputProps={{ maxLength: 70 }}
				type="text"
				error="error"
				helperText="120 characters"
				placeholder="defalt placeholder"
				sx={{
					"& .MuiOutlinedInput-root": {
						font: { lg: '400 16px/20px "Open Sans"', xl: '400 22px/30px "Open Sans"' }
					}
				}}
				{...rest}
			/>
			<Typography sx={{ color: "red" }}>
				{_renderHelperText()}
			</Typography>
		</>
	);
}
