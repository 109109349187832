import { createContext, Dispatch, SetStateAction } from "react";
import { BackgroundType } from ".";

export interface IMissionContext
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	prevStep(): void;
	nextStep(): void;

	extraStep: number | null;
	setExtraStep: Dispatch<SetStateAction<number | null>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof BackgroundType;
	setBackground: Dispatch<SetStateAction<keyof BackgroundType>>;

	isShowTour: boolean;
	setIsShowTour: Dispatch<SetStateAction<boolean>>;

	showContent: boolean;
	setShowContent: Dispatch<SetStateAction<boolean>>;

	keyContent: "contentMissionTwo";
	missionPrefix: "mission-two";
}

export const MissionCtx = createContext<IMissionContext>({} as IMissionContext);
