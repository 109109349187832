// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, SxProps, Theme, Typography } from "@mui/material";
import Api from "api";
import checkedIcon from "assets/images/checkedIcon.png";
import checkboxNoActive from "assets/images/missions/mission-ten/checkboxNoActive.svg";
import { useEffect, useLayoutEffect, useState } from "react";
import { Board } from "./index";

interface ResultsProps
{
	boards: Board[];
	sx?: SxProps<Theme>;
}
// interface Boards
// {
// 	id: string;
// 	_id: string;
// 	user_id: string;
// 	mission_data: any;
// 	created_at: string;
// }
function Results({ boards, sx }: ResultsProps)
{
	const [ boardsResults, setBoardsResults ] = useState<any>([]);
	const [ idMission, setIdMission ] = useState("");

	function handleChange(event: React.ChangeEvent<HTMLInputElement>, id: number, idTask: number, title: string)
	{
		const newBoardsResults = [ ...boardsResults ];

		newBoardsResults.forEach((board: any) =>
		{
			if (board.id === id)
				board.items.forEach((element: any) =>
				{
					if (element.id === idTask)
						element.values.forEach((value: any) =>
						{
							if (value.title === title) value.checked = event.target.checked;
						});
				});
		});
		setBoardsResults(newBoardsResults);
	}

	useLayoutEffect(() =>
	{
		Api.missions
			.getDataMissionNine()
			.then(({ data }) =>
			{
				setBoardsResults(data[ 0 ].mission_data[ 0 ]);
				setIdMission(data[ 0 ].id);
			})
			.catch((error) => console.error(error));
	}, []);
	useEffect(() =>
	{
		if (idMission && boardsResults)
		{
			const localData = JSON.parse(`${ localStorage.getItem("mission-nine-graph-data-good") }`);
			let arrCheckedItems: string[] = [];

			localData.forEach((element: any) =>
			{
				element.items.forEach((item: any) =>
				{
					if (item.checked === true) arrCheckedItems.push(item.title);
				});
			});
			const dataInputs = JSON.parse(`${ localStorage.getItem("mission-nine-inputs") }`);
			const dataMission = [ boardsResults, arrCheckedItems, dataInputs ];

			Api.missions
				.editDataMissionNine(idMission, dataMission)
				.catch((error: any) =>
				{
					console.error(error);
				});
		}
	}, [ boardsResults ]);

	return (
		<Box
			className="customScroll"
			sx={{
				overflowY: "scroll",
				overflowX: "hidden",
				height: "594px",
				pb: "6px",
				pr: "35px"
			}}>
			{boardsResults.map((board: any, boardIndex: number) =>
			{
				return (
					<Box
						key={board.id}
						className="test"
						sx={{
							border: "1px solid rgba(255, 255, 255, 0.5)",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							p: "27px 34px 23px",
							boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
							borderRadius: "24px",
							ml: 3,
							backdropFilter: "blur(30px)",
							maxWidth: { lg: "759px", xl: "980px" },
							width: "100%",
							"&:not(:last-child)":
							{
								mb: 3
							},
							...sx
						}}>
						<Typography
							sx={{ color: "#215C75", font: { lg: "400 18px/31px 'lora-Bold'", xl: "400 24px/31px 'lora-Bold'" } }}
						>
							{board.title}
						</Typography>
						{board.items.map((item: any, index: number) =>
						{
							// if (state === 0 && board.items.length === index + 1)
							// {
							// 	state = board.items.length;
							// } else if (state !== 0 && board.items.length === index + 1)
							// {
							// 	state += board.items.length;
							// }
							// if (item.checked === false)
							return (
								<Grid key={item.id} container mt={2} sx={{ width: "100%" }}>
									<Grid item xs={5}>
										<Typography
											sx={{ color: "#4D4D4D", font: { lg: "700 16px/30px 'Open Sans'", xl: "700 22px/30px 'Open Sans'" } }}
										>
											{item.title}
											{/* {`${ state !== 0 && board.items.length === index + 1 ? state - board.items.length + index + 1 : state !== 0 && board.items.length !== index + 1 ? state + index + 1 : index + 1 }. ${ item.title }`} */}
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<FormGroup>
											{item.values.map((value: any, index: number) => (
												// <Box
												// 	key={index}
												// 	sx={{
												// 		display: 'flex', alignItems: 'center', gap: '15px', mb: 1
												// 	}}>
												// 	<FiberManualRecordIcon sx={{ fill: '#4D4D4D', fontSize: '7px' }} />
												// 	<Typography
												// 		sx={{ color: "#4D4D4D", font: "400 22px/30px 'Open Sans'" }}
												// 	>
												// 		{value.title}
												// 	</Typography>
												// </Box>
												<FormControlLabel
													control={
														<Checkbox
															icon={
																<Box
																	component="img"
																	width="100%"
																	src={checkboxNoActive}
																	alt=""
																/>
															}
															checked={value.checked}
															onChange={(e) => handleChange(e, board.id, item.id, value.title)}
															checkedIcon={
																<Box
																	component="img"
																	width="100%"
																	src={checkedIcon}
																	alt=""
																/>
															}
														/>
													}
													label={value.title}
													sx={{
														".MuiTypography-root": {
															font: { lg: "400 16px/30px Open Sans", xl: "400 22px/30px Open Sans" },
															ml: 1
														},
														mb: "5px"
													}}
												/>
											))}
										</FormGroup>
										{(board.items.length > 1 && item.values.length > 0) && <Divider sx={{ background: "rgba(62, 128, 157, 0.5)", mt: "9px", mb: "12px" }} />}
									</Grid>
								</Grid>
							);
						})}
					</Box>
				);
			})
			}
		</Box>
	);
}

export default Results;
