import { Box, Grid, Typography } from "@mui/material";
import home from "../../../../assets/images/home.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function PhisycalhDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>

				<Box component="img" src={home} alt="Physical Environment" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Physical Environment
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="Your daily comfort makes an enormous difference to your overall sense of happiness and wellbeing. Lacking comfort and joy in your surroundings can significantly hinder your life's fulfilment. What can be done?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescScreen1" />
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Is your home comfortable?\n- Does it bring you joy?\n- Do you like the area in which you live?\n- What can you remove from your life to create more comfort?\n- What can you add to your life to fill it with more joy?\n\nStart with your immediate environment — your home, office, car. Small tweaks can be powerful: make your home more cosy, buy comfortable furniture, clean up and organise. Have you done it all and something is still missing?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescStart" />
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Does your environment allow you to do what you really like?\n- Can you attain your dream lifestyle in the area where you live?\n- Does the climate suit you?\n- Does your body feel good where you are?" keyContent="contentMissionOne" keyText="physicalEnvironmentDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "5px",
						height: "5px",
						borderRadius: "50%",
						backgroundColor: "#4D4D4D"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Your daily happiness matters. It is important your environment supports what you like doing and your desired lifestyle. If you live in a busy city, sitting at your desk all day, no matter how comfortable the desk is, you won’t be happy if you love outdoors and moving your body.\\n\\nFind out what matters to you in life and in your immediate surroundings, and wear your radical honesty hat to answer a question — do you need a change? Life Strategy Quest is a perfect place to start if you want to figure it out." keyContent="contentMissionOne" keyText="physicalEnvironmentDescScreen2" />
				{/*
				Your daily happiness matters. It is important your environment supports what you like doing and your desired lifestyle. If you live in a busy city, sitting at your desk all day, no matter how comfortable the desk is, you won’t be happy if you love outdoors and moving your body.<br></br><br></br>
				Find out what matters to you in life and in your immediate surroundings, and wear your radical honesty hat to answer a question — do you need a change? Life Strategy Quest is a perfect place to start if you want to figure it out.
				*/}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default PhisycalhDetails;
