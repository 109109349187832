import { Navigate, Outlet, useLocation } from "react-router-dom";
import useUser from "./hooks/useUser";

export interface IProtectedRouteProps
{
	/**
	 * @default "/login"
	 */
	readonly redirectPath?: string;
	readonly children?: JSX.Element;
}

function ProtectedRoute({ redirectPath = "/login", children }: IProtectedRouteProps)
{
	const userContext = useUser();
	const location = useLocation();

	if (userContext.state.isAuthenticated === false)
	{
		return (<Navigate to={redirectPath} replace state={{ from: location }} />);
	}

	return children ?? <Outlet />;
}

export default ProtectedRoute;
