import { IResponseAuthenticate } from "api/Auth";
import { getAccessToken } from "../api";
import { getLocalStorage } from "../utils/localStorage";
import { IUserState } from "./userReducer";

const accessToken = getAccessToken();

const userStore = getLocalStorage<IResponseAuthenticate>("user");
let user: null | IResponseAuthenticate = null;

if (userStore !== null)
{
	user = userStore;
}

export const userInitialState: IUserState =
{
	isAuthenticated: (user !== null && accessToken !== null),
	user,
	accessToken,
	content: []
};
