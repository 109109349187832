import Api from "api";
import { ReactNode, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

function TrackMiddleware({ children }: { children: ReactNode; })
{
	const isFirstInitRef = useRef<boolean>(true);
	const [ searchParams, setSearchParams ] = useSearchParams();

	// Effects
	useEffect(() =>
	{
		if (isFirstInitRef.current === false)
		{
			return;
		}

		isFirstInitRef.current = false;

		const updatedParams = new URLSearchParams(searchParams);
		const trackId = updatedParams.get("trackId");
		const time = updatedParams.get("time");

		if (trackId === null || time === null)
		{
			return;
		}

		updatedParams.delete("trackId");
		updatedParams.delete("time");
		setSearchParams(updatedParams, { replace: true });

		Api.admin.followingLink(Number(trackId), Number(time));

	}, [ searchParams, setSearchParams ]);

	// Render
	return <>{children}</>;
}

export default TrackMiddleware;
