import { Box, SxProps, Theme } from "@mui/material";
import IMGGuideChara from "../../assets/images/guideChara.png";

export interface IGuideCharaProps
{
	sx?: SxProps<Theme>;
	[ key: string ]: any;
}

function GuideChara({ sx = [], ...props }: IGuideCharaProps)
{
	return (
		<Box
			className="guide-chara"
			sx={[
				{
					"--size": { xl: "104px", lg: "80px", xs: "104px" },
					width: "var(--size)",
					height: "var(--size)",
					border: "2px solid #FFFFFF",
					borderRadius: "50%",
					overflow: "hidden",
					position: "relative",
					boxShadow: "0px 3px 10px #FFFFFF"
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			{...props}
		>
			<Box
				component="img"
				src={IMGGuideChara}
				sx={{
					position: "absolute",
					top: { xl: "-12px", lg: "-13px", xs: "-12px" },
					left: { xl: "-46px", lg: "-35px", xs: "-46px" },
					width: { xl: "180px", lg: "135px", xs: "180px" }
				}}
			/>
		</Box>
	);
}

export default GuideChara;
