import { Box, alpha } from "@mui/material";
import IMGOcta from "assets/images/octa2-shrink.png";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionTwo/hooks/useMission";
import { DragEvent, useState } from "react";
import Tour from "reactour";
import { useLocalStorage } from "utils/localStorage";
import { ListOfValuesData } from "../ListOfValues";

export const items = [
	"Acceptance",
	"Accomplishment",
	"Accountability",
	"Accuracy",
	"Achievement",
	"Adaptability",
	"Alertness",
	"Altruism",
	"Ambition",
	"Amusement",
	"Assertiveness",
	"Awareness",
	"Balance",
	"Beauty",
	"Boldness",
	"Bravery",
	"Brilliance",
	"Calm",
	"Certainty",
	"Challenge",
	"Charity",
	"Comfort",
	"Common sense",
	"Communication",
	"Community",
	"Compassion",
	"Competence",
	"Concentration",
	"Confidence",
	"Connection",
	"Consciousness",
	"Consistency",
	"Contentment",
	"Contribution",
	"Control",
	"Cooperation",
	"Courage",
	"Courtesy",
	"Creation",
	"Creativity",
	"Credibility",
	"Curiosity",
	"Decisiveness",
	"Dedication",
	"Dependability",
	"Determination",
	"Development ",
	"Devotion",
	"Dignity",
	"Discipline",
	"Discovery",
	"Drive",
	"Effectiveness",
	"Efficiency",
	"Empathy",
	"Endurance",
	"Enjoyment",
	"Enthusiasm",
	"Equality",
	"Excellence",
	"Exploration",
	"Expressiveness",
	"Fairness",
	"Focus",
	"Foresight",
	"Fortitude",
	"Freedom",
	"Friendship",
	"Fun",
	"Generosity",
	"Giving",
	"Goodness",
	"Grace",
	"Gratitude",
	"Greatness",
	"Growth",
	"Happiness",
	"Hard work ",
	"Harmony",
	"Honesty",
	"Honor",
	"Hopefulness",
	"Humility",
	"Humor",
	"Improvement",
	"Independence",
	"Individuality",
	"Innovation",
	"Inquisitive",
	"Insightful",
	"Inspiration",
	"Integrity",
	"Intelligence",
	"Intensity",
	"Intuition",
	"Joy",
	"Justice",
	"Kindness",
	"Knowledge",
	"Leadership",
	"Learning",
	"Liberty",
	"Logic",
	"Love",
	"Loyalty",
	"Mastery",
	"Maturity",
	"Meaning",
	"Moderation",
	"Motivation",
	"Openness",
	"Optimism",
	"Order",
	"Originality",
	"Passion",
	"Patience",
	"Peace",
	"Performance",
	"Persistence",
	"Playfulness",
	"Power",
	"Productivity",
	"Professionalism",
	"Prosperity",
	"Purpose",
	"Quality",
	"Recognition",
	"Respect",
	"Responsibility",
	"Restraint",
	"Rigor",
	"Risk",
	"Satisfaction",
	"Security",
	"Self-reliance",
	"Selflessness",
	"Sensitivity",
	"Serenity",
	"Service",
	"Sharing",
	"Significance",
	"Silence",
	"Simplicity",
	"Sincerity",
	"Skillfulness",
	"Solitude",
	"Spirituality",
	"Spontaneity",
	"Stability",
	"Status",
	"Stewardship",
	"Strength",
	"Structure",
	"Success",
	"Support",
	"Surprise",
	"Sustainability",
	"Teamwork",
	"Temperance",
	"Timeliness",
	"Tolerance",
	"Toughness",
	"Tradition",
	"Tranquility",
	"Transparency",
	"Trust",
	"Trustworthiness",
	"Truth",
	"Understanding",
	"Uniqueness",
	"Unity",
	"Valor",
	"Victory",
	"Vigor",
	"Vision",
	"Vitality",
	"Wealth",
	"Winning",
	"Wisdom",
	"Wonder"
];

type Boards = "items" | "values";

function AnythingMissing()
{
	const missionContext = useMission();

	const [ isOpenTour, setIsOpenTour ] = useState<boolean>(true);

	const [ itemsList, setItemsList ] = useLocalStorage<ListOfValuesData[]>(
		missionContext.missionPrefix + "-itemsList",
		items.map((value) => value.toLowerCase())
	);
	const [ listOfValues, setListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ currentBoard, setCurrentBoard ] = useState<Boards | null>(null);
	const [ currentItem, setCurrentItem ] = useState<number | null>(null);

	// Handles
	function onDeleteValue(index: number)
	{
		return () =>
		{
			setListOfValues((prevState) =>
			{
				const values = [ ...prevState ];
				const value = values[ index ];

				setItemsList((prevState) => Array.from(new Set([ ...prevState, value ])));
				values.splice(index, 1);

				return values;
			});
		};
	}

	function onDragOver(board: Boards)
	{
		return (event: DragEvent<HTMLDivElement>) =>
		{
			if (currentBoard === null || currentItem === null || currentBoard === board)
			{
				return;
			}

			if (listOfValues.length >= 20)
			{
				return;
			}

			event.preventDefault();
		};
	}

	function onDragStart(board: Boards, index: number)
	{
		return () =>
		{
			setCurrentBoard(board);
			setCurrentItem(index);
		};
	}

	function onDrop()
	{
		if (currentBoard === null || currentItem === null)
		{
			return;
		}

		setItemsList((prevState) =>
		{
			const items = [ ...prevState ];
			const item = items[ currentItem ];

			setListOfValues((prevState) => Array.from(new Set([ ...prevState, item ])));
			items.splice(currentItem, 1);

			return items;
		});

		setCurrentItem(null);
		setCurrentBoard(null);
	}

	function onDragEnd()
	{
		setCurrentItem(null);
		setCurrentBoard(null);
	}

	// Render
	return (
		<>
			<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						keyContent={missionContext.keyContent}
						keyText="AnythingMissing_title"
						defaultValue="Anything is missing?"
						position="left"
					/>}
					subTitle={<ContentAdmin
						keyContent={missionContext.keyContent}
						keyText="AnythingMissing_subTitle"
						defaultValue="{NAME}, that’s an impressive list of values! Now see if you’d like to add anything else. Just grab the values that resonate with you the most and add them to your list, but keep it manageable, under 20 values."
						position="left"
						isDescription
					/>}
				/>

				<Box sx={{
					"--height": { xl: "512px", lg2: "434px", xs: "356px" },

					marginTop: "40px",
					display: "grid",
					gridTemplateColumns: { xl: "332px 1fr", lg: "248px 1fr", xs: "248px 1fr" },
					gap: { xl: "36px", lg: "32px", xs: "32px" }
				}}>
					<Panel sx={{
						height: "var(--height)",
						padding: { xl: "24px 24px 24px 32px", lg: "16px", xs: "16px" }
					}}>
						<Box
							className="customScroll"
							sx={{
								paddingRight: "8px",
								paddingBottom: "5px",
								height: "100%",
								overflowY: "auto",
								display: "flex",
								flexWrap: "wrap",
								gap: "16px"
							}}
						>
							{itemsList.map((value, index) =>
							{
								return (
									<ChipStyled
										key={index}
										label={value}
										draggable
										onDragStart={onDragStart("items", index)}
										onDragEnd={onDragEnd}
										sx={{ cursor: "grab", textTransform: "capitalize" }}
									/>
								);
							})}
						</Box>
					</Panel>

					<Panel
						onDragOver={onDragOver("values")}
						onDrop={onDrop}
						sx={{
							height: "var(--height)",
							padding: { xl: "24px 24px 24px 32px", lg: "16px", xs: "16px" },
							display: "grid",
							gridTemplateRows: "auto auto 1fr",
							gap: { xl: "24px", lg: "16px", xs: "16px" }
						}}
					>
						<div>
							<Box sx={{
								color: "primary.main",
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								textAlign: "center"
							}}>
								My values
							</Box>
							<Box sx={{
								color: "primary.dark",
								font: { xl: "400 14px/16px 'Open Sans'", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								textAlign: "center",
								opacity: 0.5
							}}>
								Between 10 and 20
							</Box>
						</div>

						<Box
							className="customScroll"
							sx={{
								paddingRight: "8px",
								paddingBottom: "5px",
								height: "100%",
								overflowY: "auto",
								display: "flex",
								flexWrap: "wrap",
								alignContent: "flex-start",
								gap: "16px"
							}}
						>
							{listOfValues.map((value, index) =>
							{
								return (
									<ChipStyled
										key={index}
										label={value}
										onDelete={onDeleteValue(index)}
										sx={{ textTransform: "capitalize" }}
									/>
								);
							})}
						</Box>
					</Panel>
				</Box>

				<CustomButton.Contained
					size="large"
					disabled={listOfValues.length < 10 || listOfValues.length > 20}
					onClick={() => missionContext.nextStep()}
					sx={{
						display: "flex",
						margin: "24px auto 0 auto"
					}}
				>
					Submit
				</CustomButton.Contained>
			</Box>

			<Tour
				isOpen={isOpenTour}
				rounded={30}
				maskSpace={5}
				showCloseButton={false}
				showNavigation={false}
				showNumber={false}
				disableInteraction
				closeWithMask={false}
				lastStepNextButton={<CustomButton.Outline>Got it!</CustomButton.Outline>}
				maskClassName="reactour__mask"
				steps={[
					{
						selector: ".GetHelpButton-root",
						position: "bottom",
						style: { top: "-120px" },
						content()
						{
							return (
								<Box sx={{
									position: "relative",
									transform: { xl: "translateX(-250px)", lg: "translateX(-250px)", xs: "translateX(-75px)" },

									"& + div":
									{
										transform: { xl: "translateX(-250px)", lg: "translateX(-250px)", xs: "translateX(-75px)" }
									}
								}}>
									<Box
										component="img"
										src={IMGOcta}
										sx={{
											position: "absolute",
											left: 0,
											top: { xl: "-60px", lg: "-60px", xs: "-60px" },
											transform: "rotate(-9.11deg) translateX(-110%)",
											width: { xl: "148px", lg: "117px", xs: "117px" }
										}}
									/>

									<Box sx={{
										width: { xl: "375px", lg: "286px", xs: "286px" },
										background: "#E6F4F6",
										boxShadow: "0px 3px 6px " + alpha("#000000", 0.5),
										border: "1px solid " + alpha("#FFFFFF", 0.5),
										borderRadius: "16px",
										padding: "24px",
										font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										color: "primary.dark"
									}}>
										If you get stuck, have feedback or a question, you can contact our team and we will help you.
									</Box>
								</Box>
							);
						}
					}
				]}
				onRequestClose={() => setIsOpenTour(false)}
			/>
		</>
	);
}

export default AnythingMissing;
