import { Box, Fade } from "@mui/material";
import useMission from "pages/missionTwo/hooks/useMission";
import WhatMakesProudForm from "./Form";
import WhatMakesProudTemplate from "./Template";

export interface IWhatMakesProudFormViewProps
{
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

function WhatMakesProudFormView({ preview = false }: IWhatMakesProudFormViewProps)
{
	const missionContext = useMission();

	return (
		<WhatMakesProudTemplate>
			<Fade in={missionContext.showContent}>
				<Box sx={{ position: "relative", width: "100%" }}>
					<WhatMakesProudForm preview={preview} />
				</Box>
			</Fade>
		</WhatMakesProudTemplate>
	);
}

export default WhatMakesProudFormView;
