import { Box, FormControl, Snackbar } from "@mui/material";
import Api from "api";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import DeleteAccountModal from "components/Dialog/DeleteAccount.modal";
import UnsubscribeModal from "components/Dialog/Unsubscribe.modal";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import config from "config";
import { useFormik } from "formik";
import useUser from "hooks/useUser";
import { useState } from "react";
import { UserActionType } from "reducers/userReducer";
import * as yup from "yup";
import ModalChangePassword from "./changePassword.modal";

function ChangeAccountData()
{
	// States
	const userContext = useUser();
	const [ isFetching, setIsFetching ] = useState<boolean>(false);
	const [ isEditedData, setIsEditedData ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ isShowModalEditPassword, setIsShowModalEditPassword ] = useState<boolean>(false);
	const [ isShowModalDeleteAccount, setIsShowModalDeleteAccount ] = useState<boolean>(false);
	const [ isShowModalUnsubscribe, setIsShowModalUnsubscribe ] = useState<boolean>(false);

	const formik = useFormik(
		{
			initialValues:
			{
				name: userContext.state.user?.name ?? "",
				email: userContext.state.user?.email ?? ""
			},
			onSubmit: onFormSubmit,
			enableReinitialize: true,
			validateOnBlur: true,
			validateOnChange: true,
			validationSchema: yup.object({
				name: config.name.yup(),
				email: config.email.yup()
			})
		}
	);

	// Handles
	function handleClose(_event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	function onEditMode()
	{
		if (isEditedData === false)
		{
			return setIsEditedData(true);
		}

		if (!(formik.isValid && formik.dirty))
		{
			formik.resetForm();

			return setIsEditedData(false);
		}

		formik.submitForm();
	}

	function onFormSubmit({ name, email }: { name: string; email: string; })
	{
		setIsFetching(true);

		Api.users
			.updateUser({ name, email })
			.then(() =>
			{
				userContext.dispatch(
					{
						type: UserActionType.UPDATE_STATE,
						payload: { user: { name, email } }
					}
				);

				setIsEditedData(false);
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetching(false));
	}

	// Render
	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity="success">
					Done
				</AlertStyled>
			</Snackbar>

			<ModalChangePassword isOpen={isShowModalEditPassword} setIsOpen={setIsShowModalEditPassword} />

			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: { xl: "32px", lg: "24px", xs: "24px" }
				}}
			>
				<label>
					<Box sx={{
						font: { xl: "700 22px/28px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						marginBottom: { xl: "10px", lg: "8px", xs: "8px" },
						marginLeft: "10px",
						color: "#215C75"
					}}>
						Name
					</Box>
					<FormControl sx={{ width: "100%" }}>
						<CustomInput.Base
							placeholder="Name"
							name="name"
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={isEditedData === false}
						/>

						<Box sx={{
							position: "absolute",
							left: "5px",
							bottom: "-19px",
							color: "#DC4242",
							font: "400 14px/19px 'Open Sans'"
						}}>
							{formik.touched.name ? formik.errors.name : null}
						</Box>
					</FormControl>
				</label>

				<label>
					<Box sx={{
						font: { xl: "700 22px/28px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						marginBottom: { xl: "10px", lg: "8px", xs: "8px" },
						marginLeft: "10px",
						color: "#215C75"
					}}>
						Email
					</Box>
					<FormControl sx={{ width: "100%" }}>
						<CustomInput.Base
							placeholder="Email"
							name="email"
							autoComplete="username"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={isEditedData === false}
						/>

						<Box sx={{
							position: "absolute",
							left: "5px",
							bottom: "-19px",
							color: "#DC4242",
							font: "400 14px/19px 'Open Sans'"
						}}>
							{formik.touched.email ? formik.errors.email : null}
						</Box>
					</FormControl>
				</label>

				<Box sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-between",
					gap: "20px",
					marginTop: { xl: "80px", lg: "56px", xs: "56px" },

					"& .MuiButton-root":
					{
						minHeight: "unset",
						width: { xl: "auto", lg: "auto", xs: "100%" },
						height: { xl: "56px", lg: "40px", xs: "40px" },
						font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/24.51px 'Open Sans'", xs: "700 18px/24.51px 'Open Sans'" }
					}
				}}>
					<CustomButton.Base
						loading={isFetching === true}
						disabled={isFetching === true}
						onClick={onEditMode}
					>
						{isEditedData === false
							? "Edit Account"
							: (!(formik.isValid && formik.dirty))
								? "Cancel"
								: "Save"
						}
					</CustomButton.Base>

					<CustomButton.Base
						onClick={() => setIsShowModalEditPassword(true)}
						disabled={isEditedData === true}
					>
						Edit Password
					</CustomButton.Base>

					<CustomButton.Base
						onClick={() => setIsShowModalDeleteAccount(true)}
						disabled={isEditedData === true}
					>
						Delete Account
					</CustomButton.Base>
					{userContext.state.user?.subscriptionIsActive && !userContext.state.user?.suspendedSubscription
						? (
							<CustomButton.Base
								onClick={() => setIsShowModalUnsubscribe(true)}
								disabled={isEditedData === true}
							>
								Unsubscribe
							</CustomButton.Base>
						)
						: null
					}
				</Box>

				<DeleteAccountModal
					open={isShowModalDeleteAccount}
					onClose={() => setIsShowModalDeleteAccount(false)}
				/>

				<UnsubscribeModal
					open={isShowModalUnsubscribe}
					onClose={() => setIsShowModalUnsubscribe(false)}
				/>
			</Box>
		</>
	);
}

export default ChangeAccountData;
