import { alpha, Box, SxProps, Theme } from "@mui/material";
import { ElementType, ExoticComponent, forwardRef, Ref } from "react";

export interface IImageButtonProps
{
	SVGImage: ElementType;
	text: string;
	/**
	 * @default false
	 */
	isSelected?: boolean;
	/**
	 * @default "normal"
	 */
	size?: "normal" | "small";
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

export enum Colors
{
	SELECTED = "#FFF8EA",
	NORMAL = "#3E809D"
}

function ImageButton({ SVGImage, text, isSelected = false, size = "normal", sx = [], ...props }: IImageButtonProps, ref: Ref<HTMLButtonElement>)
{
	return (
		<Box
			className="ImageButton-root"
			component="button"
			sx={[
				{
					"--size": (size === "normal" ? 116 : 93.6) + "px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "8px",
					padding: "unset",
					background: "unset",
					border: "unset",
					cursor: "pointer"
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			ref={ref}
			{...props}
		>
			<Box
				className="ImageButton-imageContainer"
				sx={{
					width: "var(--size)",
					height: "var(--size)",
					background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.144) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
					backdropFilter: "blur(30px)",
					border: "2px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "50%",
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "center"
				}}
			>
				<Box
					className="ImageButton-image"
					component={SVGImage}
					sx={{

						filter: (isSelected === true ? "drop-shadow(0px 0px 10px #FFE8B9)" : "unset"),
						width: "76.7%",
						height: "85%",
						transition: "linear 300ms filter",

						"& path":
						{
							transition: "linear 300ms fill",
							fill: (isSelected === true ? Colors.SELECTED : Colors.NORMAL)
						}
					}}
				/>
			</Box>

			<Box
				className="ImageButton-text"
				sx={{
					padding: (size === "normal" ? "8px 32px" : "4px 18px"),
					backgroundColor: alpha("#FFFFFF", 0.5),
					borderRadius: "40px",
					color: "primary.main",
					font: "700 18px/23px Lora-Bold"
				}}
			>
				{text}
			</Box>
		</Box>
	);
}

export default forwardRef(ImageButton) as ExoticComponent<IImageButtonProps>;
