import Chat from "components/Chat";
import ContentAdmin from "components/contentAdmin";
import useMission from "../../hooks/useMission";

function WelcomeBack()
{
	const missionContext = useMission();

	// Handles
	function onResponseAnswer()
	{
		missionContext.setStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Chat
			sx={{ position: "relative", top: "-32px", left: "-8px" }}
			title={<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_1_chat_title" defaultValue="My favourite {NAME} is back!" />}
			messages={[
				{
					type: "from",
					message: <ContentAdmin keyContent={missionContext.keyContent} keyText="screen_1_chat_message_1" defaultValue="Hey, {NAME}, good to see you again! In this mission, you will define your personal values that will help you simplify so many things in life!" isDescription />
				}
			]}
			answers={{
				type: "select",
				items: [
					<ContentAdmin keyContent={missionContext.keyContent} keyText="screen_1_chat_answer_1" defaultValue="Sounds fun! Let’s do it!" position="top" />
				]
			}}
			onResponseAnswer={onResponseAnswer}
		/>
	);
}

export default WelcomeBack;
