import Api, { getAccessToken } from "api";
import { ICluster } from "pages/dashboard/artifacts/yearning/Details";
import { AttractiveInfoType } from "pages/missionFour/components/JobToLove";
import { IPointInfo } from "pages/missionFour/components/LifeHighsLows";
import { JobInformation } from "pages/missionFour/components/PersonalJobAd/LearnMoreAboutEachJob";

export interface IGetMissionResponse
{
	data: [ id: string, date: string ][];
	missions:
	{
		created_at: string;
		id: string;
		mission_data:
		{
			home: number;
			health: number;
			friend: number;
			love: number;
			career: number;
			money: number;
			fun: number;
			growth: number;
		};
		mission_id: string;
		user: string;
		_id: string;
	}[];
}

export interface IGetSharedResponse
{
	data:
	{
		home: number;
		health: number;
		friend: number;
		love: number;
		career: number;
		money: number;
		fun: number;
		growth: number;
	};
	user:
	{
		name: string;
	};
}

export interface IGetDataMission
{
	data:
	{
		_id: string;
		user: string;
		mission_data:
		{
			home: number;
			health: number;
			friend: number;
			love: number;
			career: number;
			money: number;
			fun: number;
			growth: number;
		};
		mission_id: number;
		created_at: string;
		id: string;
	};
}

export interface IGetDataMissionTwo
{
	recommendation: { title: string; text?: string; action?: string; }[];
	values: string[];
}

export interface IGetDataMissionsThree
{
	data:
	{
		_id: string;
		id: string;
		/**
		 * Example: 24/11/2022
		 */
		created_at: string;
	}[];
}

export interface IGetDataMissionThree
{
	data:
	{
		/**
		 * @example "63e642a7d60ede80bb12cace"
		 */
		_id: string;
		/**
		 * @example "63e642a7d60ede80bb12cace"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example: "24/11/2022"
		 */
		create_at: string;
		mission_data:
		{
			clusters: ICluster[];
		};
	};
}

export interface IMissionThereUniverseFields
{
	title: string;
	fields: [ string, string, string, string, string, string ];
}

export interface IGetMissionsThreeUniverseData
{
	data:
	{
		DreamUpYourUniversesForm: Record<"pink" | "purple" | "yellow", IMissionThereUniverseFields>;
	};
}

export interface ISaveMissionFourData
{
	lifeHighsLowsGraphImage?: string;
	lifeHighsLowsPointsInfo?: IPointInfo[];
	highsLowsReflects?: [ string, string, string, string, [ string, string, string, string ] ];
	jobsThatAttract?: string[];
	attractiveInfo?: AttractiveInfoType[];
	personalJobAdText: string;
	jobList: string[];
	selectedJobs: string[];
	jobsInformation: Record<string, JobInformation>;
}

export interface IGetDataMissionFour
{
	data:
	{
		/**
		 * @example "63e642a7d60ede80bb12cace"
		 */
		_id: string;
		/**
		 * @example "63e642a7d60ede80bb12cace"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example: "24/11/2022"
		 */
		created_at: string;
		mission_data?: ISaveMissionFourData;
	}[];
}

export interface IGetDataMissionsFive
{
	data:
	{
		/**
		 * Example: 06/02/2023
		 */
		created_at: string;
		_id: string;
		id: string;
		user_id: string;
		mission_data:
		{
			afternoon: string;
			evening: string;
			morning: string;
			night: string;
		};
	};
}

export interface IResponseGetMissionSix
{
	data:
	{
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		_id: string;
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * images url
		 */
		mission_data: string[];
		/**
		 * @example "10/02/2023"
		 */
		created_at: string;
		title: string;
	}[];
}

export interface IResponseGetMissionSeven
{
	data:
	{
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		_id: string;
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example "10/02/2023"
		 */
		created_at: string;
		mission_data: Record<"top" | "rewriteTop", string[]>;
	};
}

export interface IDataMissionTen
{
	title: string;
	whatTesting: string;
	/**
	 * Task list
	 */
	experiments: { value: string; checked: boolean; }[];
	definitionSuccess: string;
	keyInsights: string;
	decision: string;
	/**
	 * @default null
	 */
	like: null | boolean;
}

export interface IGetDataMissionTen
{
	data:
	{
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		_id: string;
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example "10/02/2023"
		 */
		created_at: string;
		mission_data: IDataMissionTen;
	};
}

export interface IGetDataMissionsTen
{
	data:
	{
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		_id: string;
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example "10/02/2023"
		 */
		created_at: string;
		mission_data: IDataMissionTen;
	}[];
}

export interface IResponseGetMissionEleven
{
	data:
	{
		/**
		 * @example "63e620f7d60ede80bb12c774"
		 */
		_id: string;
		/**
		 * @example "63e4d627ea1d98d6148a1bfb"
		 */
		user_id: string;
		/**
		 * @example "2023-06-14"
		 */
		created_at: string;
		mission_data: "do not show" | string;
		letterSend: boolean;
		/**
		 * @example "Wed Jun 14 2023 00:00:00 GMT+0000"
		 */
		letterSendTime: string;
		/**
		 * @example "4 years, 11 months, 30 days"
		 */
		remainingDays: string;
		/**
		 * ¯\_(ツ)_/¯
		 */
		__v: number;
	}[];
}

export default class Missions
{
	async saveMission(data: any, mission_id?: number, missionNumber?: number)
	{
		return (
			await Api.instance.post<string>(
				"/missions/saveMission",
				{ data, mission_id, missionNumber },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getShared(id: string)
	{
		return (
			await Api.instance.get<IGetSharedResponse>("/missions/getShared?id=" + id, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					Cookie: "refreshToken=" + Api.refreshToken
				}
			})
		).data;
	}

	async getMissions()
	{
		return (
			await Api.instance.get<IGetMissionResponse>(
				"/missions/getMissions",
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMission(id: string)
	{
		return (
			await Api.instance.get<IGetDataMission>(
				"/missions/getDataMission?id=" + id,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async setDataMissionTwo(data: IGetDataMissionTwo, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionTwo",
				{ missionNumber: 2, dataMissionTwo: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionTwo()
	{
		return (
			await Api.instance.get<IGetDataMissionTwo>(
				"/missions/getDataMissionTwo",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission three
	async getDataMissionThree(id: string)
	{
		return (
			await Api.instance.get<IGetDataMissionThree>(
				"/missions/getDataMissionThree?id=" + id,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionsThree()
	{
		return (
			await Api.instance.get<IGetDataMissionsThree>(
				"/missions/getDataMissionsThree",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getMissionsThreeUniverseData()
	{
		return (
			await Api.instance.get<IGetMissionsThreeUniverseData>(
				"/missions/getMissionsThreeUniverseData",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async setDataMissionThree(data: any, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionThree",
				{ missionNumber: 3, dataMissionThree: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission four
	async setDataMissionFour(data: ISaveMissionFourData, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionFour",
				{ missionNumber: 4, dataMissionFour: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionFour()
	{
		return (
			await Api.instance.get<IGetDataMissionFour>(
				"/missions/getDataMissionFour",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission five
	async getDataMissionFive(allData: boolean)
	{
		return (
			await Api.instance.get(
				`/missions/getDataMissionFive?allData=${ allData }`,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
	async editDataMissionFive(data: any)
	{
		return (
			await Api.instance.post(
				"/missions/editDataMissionFive",
				{ data },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
	async setDataMissionFive(data: any, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionFive",
				{ missionNumber: 5, dataMissionFive: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission six
	async getDataMissionSix()
	{
		return (
			await Api.instance.get<IResponseGetMissionSix>(
				"/missions/getDataMissionSix",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async setDataMissionSix(images: string[], title?: string, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionSix",
				{
					artifact,
					missionNumber: 6,
					dataMissionSix: images,
					...(title !== undefined && { title })
				},
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async editMissionSix(id: string, data: { images?: string[]; title?: string; })
	{
		return (
			await Api.instance.post<"success">(
				"/missions/editMissionSix",
				{
					id,
					...(data.title !== undefined && { title: data.title }),
					...(data.images !== undefined && { images: data.images })
				},
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission seven
	async setDataMissionSeven(data: Record<"top" | "rewriteTop", string[]>, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionSeven",
				{ missionNumber: 7, dataMissionSeven: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async editDataMissionSeven(id: string, data: Record<"top" | "rewriteTop", string[]>)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/editDataMissionSeven",
				{ id, data },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionSeven()
	{
		return (
			await Api.instance.get<IResponseGetMissionSeven>(
				"/missions/getDataMissionSeven",
				{
					headers:
					{
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission eight
	async setDataMissionEight(data: any, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionEight",
				{ missionNumber: 8, dataMissionEight: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionEight(allData?: boolean)
	{
		return (
			await Api.instance.get(
				`/missions/getDataMissionEight?allData=${ allData }`,
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission nine
	async setDataMissionNine(data: any, title?: string, artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionNine",
				{ missionNumber: 9, dataMissionNine: data, title, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async editDataMissionNine(id: string, data: any)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/editMissionNine",
				{ id, data },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async editMissionNineTitle(id: string, title: string)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/editMissionNineTitle",
				{ id, title },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionNine(): Promise<any>;
	async getDataMissionNine(id: string | undefined): Promise<any>;
	async getDataMissionNine(id?: string): Promise<any>
	{
		return (
			await Api.instance.get(
				"/missions/getDataMissionNine" + (id !== undefined ? "?id=" + id : ""),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission ten
	async getDataMissionTen(): Promise<IGetDataMissionsTen>;
	async getDataMissionTen(id: string): Promise<IGetDataMissionTen>;
	async getDataMissionTen(id?: string): Promise<IGetDataMissionTen | IGetDataMissionsTen>
	{
		return (
			await Api.instance.get<IGetDataMissionTen | IGetDataMissionsTen>(
				"/missions/getDataMissionTen" + (id !== undefined ? "?id=" + id : ""),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async setDataMissionTen(data: IDataMissionTen | IDataMissionTen[], artifact: boolean = false)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionTen",
				{ missionNumber: 10, dataMissionTen: data, artifact },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async editDataMissionTen(id: string, data: IDataMissionTen)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/editMissionTen",
				{ id, data },
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// Mission eleven
	async setDataMissionEleven(
		data: string,
		options?: { monthOrYear: "months" | "years"; number: number; artifact: boolean; },
		artifact: boolean = false
	)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionEleven",
				{
					artifact,
					missionNumber: 11,
					dataMissionEleven: data,
					...(options !== undefined && options)
				},
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getDataMissionEleven()
	{
		return (
			await Api.instance.get<IResponseGetMissionEleven>(
				"/missions/getDataMissionEleven",
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	// ----
	async saveMissionStep(mission: number, step: number)
	{
		return (
			await Api.instance.post<"success">(
				"/missions/saveMissionStep",
				{ step, mission },
				{
					headers:
					{
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async handleNotificationArtifact(number: number)
	{
		return (
			await Api.instance.post<number[]>(
				"/missions/handleNotificationArtifact",
				{ number },
				{
					headers:
					{
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
