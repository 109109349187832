import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import Career from "assets/images/Career.svg";
import Friend from "assets/images/Friend.svg";
import Fun from "assets/images/Fun.svg";
import Growth from "assets/images/Growth.svg";
import Health from "assets/images/Health.svg";
import Love from "assets/images/Love.svg";
import Money from "assets/images/Money.svg";
import bgArtefactFirst from "assets/images/bgArtefactFirst.png";
import bgArtefactOne from "assets/images/bgArtefactOne.png";
import home from "assets/images/home.svg";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RadarChart from "react-svg-radar-chart";
import CustomButton from "../../../../components/customButton";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import Create from "./components/Create";

function WheelOfLife()
{
	const params = useParams();
	const navigate = useNavigate();
	const isFetchingRef = useRef<boolean>(false);
	const [ items, setItems ] = useState<any>([]);
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ dataGraphGet, setDataGraphGet ] = useState<any>();

	const captions =
	{
		home: "home",
		health: "health",
		friend: "friend",
		love: "love",
		career: "Speed",
		money: "Weight",
		fun: "Weight2",
		growth: "Weight3"
	};

	const data = [
		{
			data: dataGraphGet,
			meta: { color: "transparent" }
		}
	];

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getMissions()
			.then(({ data }) =>
			{
				setItems(data);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => (isFetchingRef.current = false));
	}, []);
	useEffect(() =>
	{
		if (items.length > 0)
			Api.missions.getDataMission(items[ selectedItemIndex ][ 0 ])
				.then(({ data }) =>
				{
					const { mission_data } = data;

					setDataGraphGet(mission_data);
				}).catch((error) =>
				{
					console.error(error);
				});
	}, [ items, selectedItemIndex ]);
	switch (params.action)
	{
		case "create": return (<Create />);
		// case "details": return (<DetailsContainerShared />);
	}

	// Render
	return (
		<ArtifactsContainer
			data-tut="wheel-of-life"
			title="Wheel of Life"
			backgroundImage={bgArtefactFirst}
			headerRight={
				items.length > 0 && items[ selectedItemIndex ] ? formatDate(items[ selectedItemIndex ][ 1 ]) : null
			}
			sx={{
				"& .ArtifactsContainer-main": {
					background: "rgba(113, 140, 235, 0.2)",
					backdropFilter: "blur(50px)",
					borderRadius: "24px 0 0 24px"
				}
			}}
			onClose={() => navigate("/dashboard/artifacts")}
			onExpand={() => navigate("/artifact/wheel-of-life/details/:" + items[ selectedItemIndex ][ 0 ])}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "0px 4px 42px " + alpha("#68AEC5", 0.4)
							}
						}}
					>
						{items.length === 0
							? (
								<Box>Loading...</Box>
							)
							: (
								items.map((item: any, index: number) =>
								{
									return (
										<AsideCircleButton
											key={index}
											image={bgArtefactOne}
											text={formatDate(item[ 1 ])}
											isSelected={selectedItemIndex === index}
											onClick={() => setSelectedItemIndex(index)}
											sx={{
												".AsideCircleButton-image": {
													borderRadius: 0,
													boxShadow: "none"
												}
											}}
										/>
									);
								})
							)
						}
					</Box>
					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: "355px"
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/wheel-of-life/create");
						}}
					>
						Create new wheel
					</CustomButton.Contained>
				</Box>}
		>
			<Box sx={{ height: "100%", position: "relative", pl: { lg: 0, xl: "40px" }, maxWidth: { lg: "669px", xl: "1008px" } }}>
				{selectedItemIndex > 0 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState - 1)}
								sx={{
									width: "40px",
									height: "40px",
									position: "absolute",
									top: { lg: "40%", xl: "50%" },
									transform: "translateY(-50%)",
									left: "0px",
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.light",
									cursor: "pointer",

									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
							</Box>
						</Fade>
					)
				}

				{selectedItemIndex < items.length - 1 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState + 1)}
								sx={{
									width: "40px",
									height: "40px",
									position: "absolute",
									top: { lg: "40%", xl: "50%" },
									transform: "translateY(-50%)",
									right: "-20px",
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.light",
									cursor: "pointer",

									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow />
							</Box>
						</Fade>
					)
				}
				{dataGraphGet
					? (
						<Box sx={{
							width: { xs: "310px", sm: "670px" },
							m: "70px auto 50px",
							position: "relative",
							transform: { lg: "scale(0.637)", xl: "scale(0.9)" },
							mt: { lg: "-80px", xl: "-10px" }
						}}>
							<Box sx={{ display: { xs: "none", sm: "block" } }}>
								<RadarChart
									captions={captions}
									data={data}
									size={670}
									options={{
										scales: 10
									}}
								/>
							</Box>
							<Box sx={{ display: { xs: "block", sm: "none" } }}>
								<RadarChart
									captions={captions}
									data={data}
									size={310}
									options={{
										scales: 10
									}}
								/>
							</Box>

							<Box sx={{ position: "absolute", top: { xs: "87px", sm: "178px" }, left: { xs: "94px", sm: "207px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "107px", sm: "228px" }, left: { xs: "141px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "160px", sm: "362px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
							<Box sx={{ position: "absolute", top: { xs: "200px", sm: "440px" }, left: { xs: "140px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(225deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "219px", sm: "488px" }, left: { xs: "93px", sm: "206px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "199px", sm: "439px" }, left: { xs: "47px", sm: "99px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "28px", sm: "51px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
							<Box sx={{ position: "absolute", top: { xs: "108px", sm: "226px" }, left: { xs: "48px", sm: "98px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(45deg)" }} />
							<Box sx={{ position: "absolute", top: { xs: "-11px", sm: "-37px" }, left: { xs: "136px", sm: "287px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={home} alt="moneyOpacity" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "30px", sm: "60px" }, right: { xs: "32px", sm: "57px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Health} alt="Health" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "137px", sm: "289px" }, right: { xs: "-13px", sm: "-41px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Friend} alt="Friend" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "240px", sm: "514px" }, right: { xs: "32px", sm: "54px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Love} alt="Love" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "282px", sm: "618px" }, right: { xs: "135px", sm: "288px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Career} alt="Friend" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "239px", sm: "518px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Money} alt="step6" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "135px", sm: "285px" }, right: { xs: "282px", sm: "619px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Fun} alt="Fun" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "32px", sm: "55px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
								<img width="100%" src={Growth} alt="Growth" />
							</Box>
							<Box sx={{ position: "absolute", top: { xs: "142px", sm: "308px" }, right: { xs: "142px", sm: "308px" }, width: { xs: "25px", sm: "55px" }, height: { xs: "25px", sm: "55px" }, borderRadius: "100px", background: "#fff", opacity: .4 }} />
						</Box>
					)
					: null
				}
			</Box>
		</ArtifactsContainer>
	);
}

export default WheelOfLife;
