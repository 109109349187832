import { Box, CardMedia } from "@mui/material";
import LifePlanBack from "assets/images/missions/mission-ten/LifePlanBack.png";
import Lifeexperiment from "assets/images/missions/mission-ten/Lifeexperiment.png";
import YearningBack from "assets/images/missions/mission-ten/YearningBack.png";
import bgImg from "assets/images/missions/mission-ten/bgImg.png";
import circuit1 from "assets/images/missions/mission-ten/circuit1.png";
import circuit2 from "assets/images/missions/mission-ten/circuit2.png";
import circuit3 from "assets/images/missions/mission-ten/circuit3.png";
import circuit4 from "assets/images/missions/mission-ten/circuit4.png";
import imgMissionTwo from "assets/images/missions/mission-ten/imgMissionTwo.png";
import lgarrow1 from "assets/images/missions/mission-ten/lgarrow1.png";
import lgarrow2 from "assets/images/missions/mission-ten/lgarrow2.png";
import lgarrow3 from "assets/images/missions/mission-ten/lgarrow3.png";
import lgarrow4 from "assets/images/missions/mission-ten/lgarrow4.png";
import mission4Img from "assets/images/missions/mission-ten/mission4Img.png";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionTen/hooks";
import { useState } from "react";
import ImageBlock from "./feature/ImageBlock";
import ModalMissionFive from "./feature/modalMissionFive";
import ModalMissionFour from "./feature/modalMissionFour";
import ModalMissionSix from "./feature/modalMissionSix";
import ModalMissionThree from "./feature/modalMissionThree";
import ModalMissionTwo from "./feature/modalMissionTwo";

function LifeStrategyUpToDate()
{
	const missionContext = useMission();
	const [ missionViews, setMissionViews ] = useState<[ boolean, boolean, boolean, boolean, boolean ]>(
		[ false, false, false, false, false ]
	);

	// Handles
	function setIsView(index: 0 | 1 | 2 | 3 | 4)
	{
		setMissionViews((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: true });
		});
	}

	function nextStep()
	{

		missionContext.setStep((prevState) => prevState + 1);

	}

	const [ openModal, setOpenModal ] = useState(0);

	function closeModal()
	{
		setOpenModal(0);
	}

	// Render
	return (
		<>
			<Box sx={{
				flexDirection: "column",
				display: "flex",
				justifyContent: { lg: "flex-start", xl: "space-between" },
				height: "81vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "980px" },
				margin: "0 auto",
				position: "relative",
				top: "-10px",
				left: "-20px"
			}}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionTen"
						keyText="lifeStrategy_title"
						defaultValue="Keep you life strategy up to date"
						position="right"
					/>
					}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="lifeStrategy_txt"
						defaultValue="Hey, {NAME}, dedicate time to regularly check your progress against your life strategy. You can come back to your artifacts at any point and make updates."
						position="right"
						isDescription
					/>
					}
				/>
				{missionContext.showContent
					? (
						<Box
							sx={{
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
								borderRadius: "24px",
								backdropFilter: "blur(5px)",
								border: "1px solid rgba(255, 255, 255, 0.5)",
								p: { lg: "18px 30px 31px", xl: "36px 40px 40px" },
								width: { lg: "713px", xl: "906px" },
								height: { lg: "451px", xl: "619px" },
								m: { lg: "35px auto 0", xl: "50px auto 0" },
								display: "flex",
								gap: { lg: "21px", xl: "31px" },
								position: "relative",
								left: "30px",
								top: "-5px"
							}}>
							<ImageBlock
								url={imgMissionTwo}
								sx={{ mt: { lg: "61px", xl: "77px" } }}
								title="Values"
								onClick={() => { setOpenModal(1); setIsView(0); !missionViews[ 0 ] && missionContext.setShowMissionInfo(2); }}
							/>
							<ImageBlock
								url={YearningBack}
								title="Map of yearnings"
								onClick={() => { setOpenModal(2); setIsView(1); !missionViews[ 1 ] && missionContext.setShowMissionInfo(3); }}
							/>
							<ImageBlock
								url={mission4Img}
								sx={{ mt: { lg: "61px", xl: "77px" } }}
								title="Vision&nbsp;board"
								onClick={() => { setOpenModal(3); setIsView(2); !missionViews[ 2 ] && missionContext.setShowMissionInfo(4); }}
							/>
							<ImageBlock
								url={LifePlanBack}
								sx={{ backgroundPosition: "-7px" }}
								circleBottom="38px"
								title="My&nbsp;life&nbsp;plan"
								onClick={() => { setOpenModal(4); setIsView(3); !missionViews[ 3 ] && missionContext.setShowMissionInfo(6); }}
							/>
							<ImageBlock
								url={Lifeexperiment}
								sx={{ mt: { lg: "61px", xl: "77px" } }}
								title="Experiments tracker"
								onClick={() => { setOpenModal(5); setIsView(4); !missionViews[ 4 ] && missionContext.setShowMissionInfo(7); }}
							/>
							<Box sx={{
								width: "150px",
								position: "absolute",
								zIndex: 1,
								bottom: "190px",
								left: "113px",
								transform: "rotate(0deg)",
								display: { lg: "none", xl: "block" }
							}}>
								<CardMedia
									component="img"
									width="100%"
									image={circuit1}
									alt=""
								/>
							</Box>
							<Box
								component="img"
								src={lgarrow1}
								alt=""
								sx={{
									position: "absolute",
									zIndex: 1,
									bottom: "123px",
									left: "85px",
									transform: "rotate(0deg)",
									display: { lg: "block", xl: "none" }
								}} />
							<Box
								component="img"
								src={lgarrow2}
								alt=""
								sx={{
									position: "absolute",
									zIndex: 1,
									bottom: "103px",
									left: "231px",
									transform: "rotate(0deg)",
									display: { lg: "block", xl: "none" }
								}} />
							<Box
								component="img"
								src={lgarrow3}
								alt=""
								sx={{
									width: "111px",
									position: "absolute",
									zIndex: 1,
									bottom: "109px",
									left: "366px",
									transform: "rotate(0deg)",
									display: { lg: "block", xl: "none" }
								}} />
							<Box
								component="img"
								src={lgarrow4}
								alt=""
								sx={{
									width: "111px",
									position: "absolute",
									zIndex: 1,
									bottom: "117px",
									left: "504px",
									transform: "rotate(0deg)",
									display: { lg: "block", xl: "none" }
								}} />
							<Box sx={{
								width: "134px",
								position: "absolute",
								zIndex: 1,
								bottom: "165px",
								left: "298px",
								display: { lg: "none", xl: "block" }
							}}>
								<CardMedia
									component="img"
									width="100%"
									image={circuit2}
									alt=""
								/>
							</Box>
							<Box sx={{
								width: "134px",
								position: "absolute",
								zIndex: 1,
								bottom: "171px",
								right: "299px",
								display: { lg: "none", xl: "block" }
							}}>
								<CardMedia
									component="img"
									width="100%"
									image={circuit3}
									alt=""
								/>
							</Box>
							<Box sx={{
								width: "141px",
								position: "absolute",
								zIndex: 1,
								bottom: "182px",
								right: "120px",
								transform: "rotate(-3deg)",
								display: { lg: "none", xl: "block" }
							}}>
								<CardMedia
									component="img"
									width="100%"
									image={circuit4}
									alt=""
								/>
							</Box>
							{openModal !== 0 &&
								(
									<Box sx={{
										backgroundImage: `url(${ bgImg })`,
										boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
										borderRadius: "24px",
										backdropFilter: "blur(30px)",
										position: "absolute",
										top: 0,
										left: 0,
										width: { lg: "713px", xl: "906px" },
										height: { lg: "451px", xl: "619px" },
										zIndex: 2,
										p: { lg: "20px", xl: "114px 40px" }
									}}>
										{openModal === 1 && <ModalMissionTwo imgUrl={imgMissionTwo} closeModal={closeModal} title="Values" />}
										{openModal === 2 && <ModalMissionThree imgUrl={YearningBack} closeModal={closeModal} title="Map of yearnings" />}
										{openModal === 3 && <ModalMissionFour imgUrl={mission4Img} closeModal={closeModal} title="Vision&nbsp;board" />}
										{openModal === 4 && <ModalMissionFive imgUrl={LifePlanBack} closeModal={closeModal} title="My&nbsp;life&nbsp;plan" />}
										{openModal === 5 && <ModalMissionSix imgUrl={Lifeexperiment} closeModal={closeModal} title="Experiments tracker" />}
									</Box>
								)
							}
						</Box>
					)
					: null
				}
			</Box>

			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					onClick={nextStep}
					disabled={!missionViews.every((isView) => isView === true)}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default LifeStrategyUpToDate;
