import axios, { AxiosInstance } from "axios";
import { getLocalStorage } from "../utils/localStorage";
import Admin from "./Admin";
import Auth from "./Auth";
import Bot from "./Bot";
import { Coupons } from "./Coupons";
import { Feedback } from "./Feedback";
import Messages from "./Messages";
import Missions from "./Missions";
import { Transactions } from "./Transactions";
import Users from "./Users";

export function getAccessToken(): string | null
{
	return getLocalStorage<string>("accessToken");
}

export default class Api
{
	static instance: AxiosInstance = axios.create(
		{
			baseURL: process.env.REACT_APP_API_URL,
			validateStatus: (status) => status === 200
		}
	);

	static refreshToken: string = "0378606cbe38ff4d6b4c059490c574b8bd884e713329fe6b465dc465b6b401386d5689cdf6bac464";

	static auth = new Auth();
	static admin = new Admin();
	static users = new Users();
	static missions = new Missions();
	static messages = new Messages();
	static bot = new Bot();
	static transactions = new Transactions();
	static feedback = new Feedback();
	static coupons = new Coupons();
}
