import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Friend from "../../../../assets/images/Friend.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function FriendsDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Friend} alt="Friend" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Friends & Family
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Social connections are the foundation of your emotional and physical wellbeing. When you feel connected to others, you are less prone to anxiety and depression. Loneliness is on the rise in many countries, so you are definitely not alone in feeling somewhat disconnected, {NAME}" keyContent="contentMissionOne" keyText="friendsScreen1" />
				{/* Social connections are the foundation of your emotional and physical wellbeing. When you feel connected to others, you are less prone to anxiety and depression. Loneliness is on the rise in many countries, so you are definitely not alone in feeling somewhat disconnected, {localStorage.getItem('name')}! */}
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="It might be a good idea to do an audit of your friendships, restore meaningful connections that were lost, cease empty ones, fix any outstanding family issues. Focus on giving, sharing, and doing acts of services or kindness for others." keyContent="contentMissionOne" keyText="friendsDescStart" />
							{/* It might be a good idea to do an audit of your friendships, restore meaningful connections that were lost, cease empty ones, fix any outstanding family issues. Focus on giving, sharing, and doing acts of services or kindness for others. */}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Are you surrounded by like-minded people?\n- Do you feel you belong in your workplace, friends circle, community, country?\n- Does your life design allow enough time for human connection?" keyContent="contentMissionOne" keyText="friendsDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Feeling disconnected can sometimes mean that you need a change in your environment. We aren’t all born into a community that fits us, and need to find (or create!) our tribe.\\n\\nYou can do that by running life experiments, trying different lifestyle, connecting with diverse people until you find your tribe.\\n\\nWe can help you on that path with Life Strategy Quest, where you will learn what you value the most in life and people. Once you know that, you can use those insights as cues to connect with the right people meaningfully." keyContent="contentMissionOne" keyText="friendsScreen2" />
				{/*
				Feeling disconnected can sometimes mean that you need a change in your environment. We aren’t all born into a community that fits us, and need to find (or create!) our tribe.<br></br><br></br>
				You can do that by running life experiments, trying different lifestyle, connecting with diverse people until you find your tribe.<br></br><br></br>
				We can help you on that path with Life Strategy Quest, where you will learn what you value the most in life and people. Once you know that, you can use those insights as cues to connect with the right people meaningfully.
				*/}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default FriendsDetails;
