import { Box, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import Api from "api";
import checkedTenMission from "assets/images/missions/mission-ten/checkedTenMission.svg";
import flyi from "assets/images/missions/mission-ten/flyi.png";
import IMGTree1 from "assets/images/missions/mission-ten/tree1.png";
import IMGTree2 from "assets/images/missions/mission-ten/tree2.png";
import IMGTree3 from "assets/images/missions/mission-ten/tree3.png";
import ArrowButton from "components/ArrowButton";
import Form from "components/Form";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { useEffect, useState } from "react";
// import { getLocalStorage } from "utils/localStorage";
import { setLocalStorage } from "utils/localStorage";
import styles from "../../../missionNine/feature/MapContainer/MapContainer.module.css";
import useMission from "../../hooks";

export type Run = { id: number; value: string; checked: boolean; };

export interface ArrValues
{
	title: string;
	test: string;
	run: Run[];
	success: string;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
		backdropFilter: "blur(25px)",
		padding: "32px 37px",
		maxWidth: "500px",
		border: "1px solid rgba(255, 255, 255, 0.5)",
		// boxShadow: '0px 5px 10px rgba(167, 199, 225, 0.6)',
		borderRadius: "24px",
		color: "#215C75",
		font: '700 24px/31px "Lora-Bold"'
	},
	[ `& .${ tooltipClasses.tooltip } ul` ]: {
		padding: "0 0 0 20px"
	},
	[ `& .${ tooltipClasses.tooltip } ul li::marker` ]: {
		fontSize: "15px",
		lineHeight: "15px"
	}
}));

function LifeExperiments()
{
	const [ componentInputs, setComponentInputs ] = useState<Omit<ArrValues, "title">>(
		{
			test: "",
			run: [
				{
					id: 1,
					value: "",
					checked: false
				}
			],
			success: ""
		}

	);
	const [ animationStatus, setAnimationStatus ] = useState<[ boolean, boolean, boolean ]>([ true, true, true ]);
	const [ openHint, setOpenHint ] = useState<null | 1 | 2 | 3>(null);
	const missionContext = useMission();
	const [ title, setTitle ] = useState("");
	const [ flies, setFlies ] = useState<ArrValues[]>([]);
	const [ open, setOpen ] = useState(false);
	const [ disabledBtn, setDisabledBtn ] = useState(true);

	function positionFlies(index: number)
	{
		return {
			position: "absolute",
			left: [ "531px", "142px", "527px", "80px", "322px", "289px", "734px", "610px", "10px", "630px", "740px" ][ index ],
			top: [ "-61px", "-10px", "172px", "199px", "83px", "301px", "220px", "330px", "330px", "90px", "-50px" ][ index ],
			transform: "rotation(" + [ -13, 24, 24, 0, 0, 40, -5, -15, 15, 5, -25 ][ index ] + "deg)"
		};
	}

	function clearEmptyRun(run: Run[])
	{
		return run
			.map((item) =>
			{
				item.value = item.value.trim();

				return item;
			})
			.filter((item) => item.value.length > 0);
	}

	function onClick(title: string)
	{
		setOpen(true);
		setTitle(title);
		flies.forEach((item) =>
		{
			if (item.title !== title)
			{
				return;
			}

			if (item.test.trim() === "" && clearEmptyRun(item.run).length === 0 && item.success.trim() === "")
			{
				setComponentInputs({
					test: "",
					run: [
						{
							id: 1,
							value: "",
							checked: false
						}
					],
					success: ""
				});
			}

			else
			{
				setComponentInputs({
					test: item.test,
					success: item.success,
					run: clearEmptyRun(item.run)
				});
			}
		});
	}

	function addInput()
	{
		const newcomponentInputs = { ...componentInputs };
		let newItem = {
			id: componentInputs.run.length + 1,
			value: "",
			checked: false
		};

		newcomponentInputs.run.push(newItem);
		setComponentInputs(newcomponentInputs);
	}

	function handleChangeArrInputs(value: string, indexArr: number, indexItem: string)
	{
		const newcomponentInputs = { ...componentInputs };
		if (indexItem === "test") { newcomponentInputs.test = value; }

		if (indexItem === "success") { newcomponentInputs.success = value; }

		if (indexItem === "run") { newcomponentInputs.run[ indexArr ].value = value; }

		setComponentInputs(newcomponentInputs);
	}

	function onClickNextStep()
	{
		setLocalStorage("fly-values", flies.map((item) =>
		{
			item.run = clearEmptyRun(item.run);

			return item;
		}));

		missionContext.setStep((prevState) => prevState + 1);
	}

	function closeForm() { setOpen(false); }

	function onSubmit()
	{
		const newComponentInputs = [ ...flies ];

		newComponentInputs.forEach((item: ArrValues) =>
		{
			if (item.title !== title)
			{
				return;
			}

			item.test = componentInputs.test;
			item.success = componentInputs.success;
			item.run = clearEmptyRun(componentInputs.run);
		});

		setFlies(newComponentInputs);
		setLocalStorage("fly-values", newComponentInputs);
		closeForm();
	}

	function handlerOpenHint(index: Exclude<typeof openHint, null>)
	{
		setOpenHint(index);

		setAnimationStatus((prevState) => (
			Object.assign([], prevState, { [ index - 1 ]: false })
		));
	}

	useEffect(() =>
	{
		Api.missions
			.getDataMissionNine()
			.then(({ data }) =>
			{
				const arrValues: ArrValues[] = [];

				data[ 0 ].mission_data[ 1 ]?.forEach((element: string) =>
				{
					arrValues.push({
						title: element,
						test: "",
						run: [],
						success: ""
					});
				});

				setFlies(arrValues);
			})
			.catch((error) =>
			{
				console.error(error);
			});
	}, []);
	useEffect(() =>
	{
		let count = 0;

		flies.forEach((item: any) =>
		{
			if (item.test) count += 1;
		});
		if (flies.length !== 0 && count === flies.length) setDisabledBtn(false);
	}, [ flies ]);

	return (
		<>
			<Box sx={{
				flexDirection: "column",
				display: "flex",
				justifyContent: "space-between",
				height: "81vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "980px" },
				margin: "0 auto",
				position: "relative",
				top: "44px",
				left: "10px"
			}}>
				<Box sx={{ maxWidth: "100%", mt: "-20px" }}>
					<Box sx={{ position: "relative", top: "-36px", left: "-36px" }}>
						<PanelTitle
							positionOcta
							imageVariation="closeEye"
							title={<ContentAdmin
								limit={111}
								keyContent="contentMissionTen"
								keyText="life_experiments_title"
								defaultValue="Let’s design your life experiments!"
								position="right"
							/>
							}
							subTitle={<ContentAdmin
								limit={300}
								keyContent="contentMissionTen"
								keyText="life_experiments_sub_title"
								defaultValue="Alright,{NAME}, here are all of your unknowns, time to brainstorm experiments you can run on each one. Go through your unknows and think of ways you can create more certaint"
								position="right"
								isDescription
							/>
							}
						/>
					</Box>

					{missionContext.showContent
						? (
							<Box sx={{
								position: "relative",
								height: "430px",
								top: "129px",
								left: "35px"

							}}>
								{/* flies */}
								{flies.length > 0 &&
									flies.map((item: any, index: number) => (
										<Box sx={{ transform: { lg: "scale(0.8)", xl: "scale(1)" }, mt: { lg: "-70px", xl: 0 }, ml: { lg: "-70px", xl: 0 } }}>
											<Box key={index} sx={positionFlies(index)}>
												<Box
													component="img"
													src={item.test ? checkedTenMission : flyi}
													alt="flyi"
													onClick={() => onClick(item.title)}
													sx={{
														position: "relative",
														cursor: "pointer",
														zIndex: 1,
														top: "0px"
													}}>
													{/* <BallButton
													// srcIcon={FlySrc}
													srcIcon={flyi}
													showValue={false}
													cursorBallButton='pointer'
													topBallButtonPosition='0px'
													leftBallButtonPosition='0px'
												/> */}
												</Box>
											</Box>
										</Box>
									))
								}
								{open
									? (
										<Box sx={{ position: "relative", top: { lg: "-90px", xl: "-150px" }, ml: { lg: "-10px", xl: 0 } }}>
											<Form
												componentInputs={componentInputs}
												handleChangeArrInputs={handleChangeArrInputs}
												onSubmit={onSubmit}
												closeForm={closeForm}
												addInput={addInput}
												title={title}
											/>
										</Box>
									)
									: null
								}
								{/* Tree */}
								<LightTooltip
									sx={{
										".MuiTooltip-tooltip": {
											font: { lg: '700 18px/31px "Lora-Bold"', xl: '700 24px/31px "Lora-Bold"' },
											p: { lg: "15px 20px", xl: "32px 37px" },
											maxWidth: { lg: "348px", xl: "500px" }
										}
									}}
									title={
										<>
											<ContentAdmin
												limit={1000}
												keyContent="contentMissionTen"
												keyText="dialog_3_title"
												defaultValue="Lifestyle"
												position="right"
											/>

											<Box sx={{ color: "#4D4D4D", font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }, mt: { lg: 1, xl: "16px" } }}>
												<ContentAdmin
													limit={1000}
													keyContent="contentMissionTen"
													keyText="dialog_3_content"
													defaultValue="- Thinking of moving to the countryside? Book a farm on Airbnb and test the countryside lifestyle for a couple of weeks.\n- Wondering if you want to have a dog? Sign-up as a dog sitter and explore various breeds you are considering."
													position="right"
													isDescription
												/>
											</Box>
										</>
									}
									placement="top"
								>
									<Box
										className={animationStatus[ 2 ] ? styles.animationVibrate : ""}
										component="img"
										src={IMGTree1}
										sx={{
											"--delay": "300ms",
											cursor: "pointer",
											zIndex: "20",
											position: "absolute",
											bottom: { xl: "210px", lg: "260px", xs: "260px" },
											right: { xl: "100px", lg: "115px", xs: "115px" },
											width: { xl: "104px", lg: "88px", xs: "88px" }
										}}
										onMouseEnter={() => handlerOpenHint(3)}
									/>
								</LightTooltip>

								<LightTooltip
									sx={{
										".MuiTooltip-tooltip": {
											font: { lg: '700 18px/31px "Lora-Bold"', xl: '700 24px/31px "Lora-Bold"' },
											p: { lg: "15px 20px", xl: "30px 37px" },
											maxWidth: { lg: "348px", xl: "500px" }
										}
									}}
									title={
										<>
											<ContentAdmin
												limit={1000}
												keyContent="contentMissionTen"
												keyText="dialog_1_title"
												defaultValue="Career"
												position="right"
											/>

											<Box sx={{ color: "#4D4D4D", font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }, mt: { lg: 1, xl: "16px" } }}>
												<ContentAdmin
													limit={1000}
													keyContent="contentMissionTen"
													keyText="dialog_1_content"
													defaultValue="Looking to try out a potential career direction or want to test a business idea? Here are a few things you can try: \n- Create a landing page or a website for your new business idea \n- Create a ‘Coming soon’ page to gauge the demand \n- Volunteer or shadow someone who does what you want to do "
													position="right"
													isDescription
												/>
											</Box>
										</>
									}
									placement="top"
								>
									<Box
										className={animationStatus[ 0 ] ? styles.animationVibrate : ""}
										component="img"
										src={IMGTree2}
										sx={{
											"--delay": "100ms",
											cursor: "pointer",
											zIndex: "20",
											position: "absolute",
											bottom: { xl: "275px", lg: "315px", xs: "325px" },
											right: { xl: "-27px", lg: "50px", xs: "27px" },
											height: { xl: "83px", lg: "70px", xs: "70px" }
										}}
										onMouseEnter={() => handlerOpenHint(1)}
									/>
								</LightTooltip>

								<LightTooltip
									sx={{
										".MuiTooltip-tooltip": {
											font: { lg: '700 18px/31px "Lora-Bold"', xl: '700 24px/31px "Lora-Bold"' },
											p: { lg: "15px 20px", xl: "32px 37px" },
											maxWidth: { lg: "348px", xl: "500px" }
										}
									}}
									title={
										<>
											<ContentAdmin
												limit={1000}
												keyContent="contentMissionTen"
												keyText="dialog_2_title"
												defaultValue="Family & Relationships"
												position="right"
											/>

											<Box sx={{ color: "#4D4D4D", font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' }, mt: { lg: 1, xl: "16px" } }}>
												<ContentAdmin
													limit={1000}
													keyContent="contentMissionTen"
													keyText="dialog_2_content"
													defaultValue="- Wondering if polyamorous or open relationships might be for you? Agree with your partner to try an alternative relationship model for a month.\n- Not sure if you want to have a child? Spend a week with your friends who have children."
													position="right"
													isDescription
												/>
											</Box>
										</>
									}
									placement="top"
								>
									<Box
										className={animationStatus[ 1 ] ? styles.animationVibrate : ""}
										component="img"
										src={IMGTree3}
										sx={{
											"--delay": "0ms",
											cursor: "pointer",
											zIndex: "20",
											position: "absolute",
											bottom: { xl: "130px", lg: "210px", xs: "210px" },
											right: { xl: "-60px", lg: "-30px", xs: "-30px" },
											width: { xl: "104px", lg: "88px", xs: "88px" }
										}}
										onMouseEnter={() => handlerOpenHint(2)}
									/>
								</LightTooltip>
							</Box>
						)
						: null
					}
				</Box>
			</Box>

			<StepperNavigationWrapper sx={{ bottom: 0, right: "25px" }} absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					disabled={disabledBtn}
					onClick={onClickNextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default LifeExperiments;
