import { CloseRounded } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Fade, Slide, SlideProps, Snackbar, alpha } from "@mui/material";
import Api from "api";
import { IDataMissionTen, IGetDataMissionsTen } from "api/Missions";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import AlertStyled from "components/AlertStyled";
import CheckboxStyled from "components/CheckboxStyled";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { useFormik } from "formik";
import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import deepClone from "utils/deepClone";
import * as yup from "yup";
import { RULES } from ".";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";
import { MAX_FILED_LENGTH } from "./Create";
import IMGBackground from "./assets/images/background.png";
import IMGDislike from "./assets/images/dislike.png";
import IMGLike from "./assets/images/like.png";

export interface IEditProps
{
	updateItems(callback?: () => void, force?: boolean): void;
	items: IGetDataMissionsTen[ "data" ] | null;
}

const fieldYupScheme = yup
	.string()
	.trim()
	// eslint-disable-next-line no-template-curly-in-string
	.max(MAX_FILED_LENGTH, "You need a maximum of ${max} characters");

function Edit({ items, updateItems }: IEditProps)
{
	const navigate = useNavigate();
	const { data: missionId } = useParams();

	const formik = useFormik({
		initialValues:
		{
			whatTesting: "",
			definitionSuccess: "",
			keyInsights: "",
			decision: ""
		},
		onSubmit()
		{
			if (item === null)
			{
				return;
			}

			if (item.experiments.length === 0)
			{
				return setIsShowAlert(true);
			}

			if (isFetch === true || isFetchRef.current === true)
			{
				return;
			}

			setIsFetch(true);
			isFetchRef.current = true;

			Api.missions
				.editDataMissionTen(missionId as string, item)
				.then(() =>
				{
					updateItems(() =>
					{
						navigate("/dashboard/artifacts/experiments", { replace: true });
					}, true);
				})
				.catch((error) => console.error(error))
				.finally(() =>
				{
					setIsFetch(false);
					isFetchRef.current = false;
				});
		},
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: yup.object(
			{
				whatTesting: fieldYupScheme.required("This field is required"),
				definitionSuccess: fieldYupScheme.required("This field is required"),
				keyInsights: fieldYupScheme,
				decision: fieldYupScheme
			}
		)
	});

	const isFetchRef = useRef<boolean>(false);
	const isFirstInitRef = useRef<boolean>(true);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);

	const [ newTaskValue, setNewTaskValue ] = useState<string>("");
	const [ newTaskIsChecked, setNewTaskIsChecked ] = useState<boolean>(false);

	const [ item, setItem ] = useState<IDataMissionTen | null>(null);

	const [ isShowChangeTitleModal, setIsShowChangeTitleModal ] = useState<boolean>(false);
	const [ newTitle, setNewTitle ] = useState<string>("");
	const [ newTitleError, setNewTitleError ] = useState<string | null>(null);

	// Effects
	useLayoutEffect(() =>
	{
		setItem(() =>
		{
			return items?.find(({ id }) => id === missionId)?.mission_data || null;
		});
	}, [ items, missionId ]);

	useLayoutEffect(() =>
	{
		if (isFirstInitRef.current === true)
		{
			return;
		}

		setItem((prevState) =>
		{
			return Object.assign({}, deepClone(prevState), formik.values);
		});
	}, [ formik.values ]);

	useLayoutEffect(() =>
	{
		if (item === null || isFirstInitRef.current === false)
		{
			return;
		}

		isFirstInitRef.current = false;

		setNewTitle(item.title);
		formik.setFieldValue("whatTesting", item.whatTesting);
		formik.setFieldValue("definitionSuccess", item.definitionSuccess);
		formik.setFieldValue("keyInsights", item.keyInsights);
		formik.setFieldValue("decision", item.decision);

		window.requestAnimationFrame(() =>
		{
			formik.validateField("whatTesting");
			formik.validateField("definitionSuccess");
			formik.validateField("keyInsights");
			formik.validateField("decision");
		});
	}, [ item ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Handles
	function onKeyDownNewTask(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		try
		{
			fieldYupScheme.required("This field is required").validateSync(newTaskValue.trim());
		}
		catch (e)
		{
			return;
		}

		setItem((prevState) =>
		{
			const state = deepClone(prevState);

			state!.experiments.push({ checked: newTaskIsChecked, value: newTaskValue.trim() });

			return state;
		});

		setNewTaskIsChecked(false);
		setNewTaskValue("");
	}

	function onDeleteTask(index: number)
	{
		return (event: MouseEvent<HTMLButtonElement>) =>
		{
			event.preventDefault();
			event.stopPropagation();

			setItem((prevState) =>
			{
				const state = deepClone(prevState);

				state!.experiments.splice(index, 1);

				return state;
			});
		};
	}

	function onChangeTaskIsChecked(index: number)
	{
		return (_event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
		{
			setItem((prevState) =>
			{
				const state = deepClone(prevState);

				state!.experiments[ index ].checked = checked;

				return state;
			});
		};

	}

	function onChangeTitle(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			RULES.title.yupScheme().validateSync(newTitle.trim());
		}
		catch (e)
		{
			error = (e as yup.ValidationError).message;
		}

		setNewTitleError(error);

		if (error !== null || item === null)
		{
			return;
		}

		setItem((prevState) =>
		{
			if (prevState === null)
			{
				return null;
			}

			return { ...prevState, title: newTitle.trim() };
		});
		setIsShowChangeTitleModal(false);
	}

	function onChangeLike(isLike: boolean)
	{
		return (event: MouseEvent<HTMLButtonElement>) =>
		{
			event.preventDefault();
			event.stopPropagation();

			setItem((prevState) =>
			{
				if (prevState === null)
				{
					return null;
				}

				const state = deepClone(prevState);

				state.like = (state.like === isLike ? null : isLike);

				return state;
			});
		};

	}

	// Render
	return (
		<ArtifactsContainer
			backgroundImage={IMGBackground}
			title={(
				items === null
					? "Loading..."
					: item === null
						? "Information not found"
						: item.title
			)}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts/experiments")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			{item !== null &&
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{
						display: "grid",
						gridTemplateRows: "auto 1fr auto",
						alignContent: "flex-start",
						alignItems: "flex-start",
						justifySelf: "center",
						alignSelf: "center",
						gap: { lg: "16px", xl: "24px" },

						maxHeight: { lg: "539px", xl: "670px" },
						width: { lg: "977px", xl: "1234px" },
						padding: { lg: "24px 32px", xl: "32px 32px 24px 32px" },

						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						boxShadow: "0px 5px 20px" + alpha("#7EC4FF", 0.4),
						backdropFilter: "blur(30px)",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px"
					}}
				>
					<Box sx={{
						display: "flex",
						alignItems: "center",
						gap: "16px",
						color: "primary.main",
						font: { lg: "700 18px/24px Lora-Bold", xl: "700 24px/31px Lora-Bold" }
					}}>
						{item.title}

						<Box
							component="button"
							sx={{
								width: "24px",
								height: "24px",
								backgroundColor: "#3E809D",
								border: "unset",
								borderRadius: "50%",
								transition: "linear 300ms opacity",
								cursor: "pointer",

								"&:hover":
								{
									opacity: 0.7
								}
							}}
							onClick={(event) =>
							{
								event.preventDefault();
								event.stopPropagation();

								setIsShowChangeTitleModal(true);
							}}
						>
							<ModeEditIcon sx={{ color: "#FFFFFF", fontSize: "12px" }} />
						</Box>
					</Box>

					<Box
						className="customScroll"
						sx={{
							overflowY: "auto",
							paddingRight: "16px",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							gap: { lg: "16px", xl: "24px" },

							"& > label":
							{
								position: "relative",
								display: "flex",
								flexDirection: "column",
								gap: "8px"
							},
							"& .CheckboxStyledWithLabel-content":
							{
								color: "primary.dark",
								font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
							},
							"& > * > span":
							{
								display: "block",
								marginLeft: "16px",
								color: "primary.dark",
								font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
							}
						}}
					>
						<label>
							<span>What do you want to test?</span>

							<CustomInput.Base
								name="whatTesting"
								placeholder="e.g. I like being an artist & it brings income."
								inputProps={{ maxLength: MAX_FILED_LENGTH }}
								value={formik.values.whatTesting}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<Box sx={{
								margin: "-8px 10px 0 10px",
								display: "flex",
								justifyContent: "space-between",
								gap: "16px"
							}}>
								<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
									{formik.errors.whatTesting}
								</Box>

								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{formik.values.whatTesting.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</label>

						<div style={{ marginTop: "-19px" }}>
							<span style={{ marginBottom: "16px" }}>What experiments can you run?</span>

							<Box
								component={TransitionGroup}
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "16px",

									"&:not(:empty):not(:last-child)":
									{
										marginBottom: "16px"
									}
								}}
							>
								{item.experiments.map(({ value, checked }, index) =>
								{
									return (
										<Fade key={index + value} timeout={{ appear: 300, enter: 300, exit: 0 }}>
											<Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
												<CheckboxStyledWithLabel
													label={value}
													checkboxProps={{
														checked,
														onChange: onChangeTaskIsChecked(index)
													}}
												/>

												<TooltipStyled title="Delete" placement="bottom" arrow>
													<Box
														component="button"
														onClick={onDeleteTask(index)}
														sx={{
															background: "unset",
															padding: "unset",
															border: "unset",
															cursor: "pointer",

															"&:hover":
															{
																opacity: 0.7
															}
														}}
													>
														<CloseRounded color="primary" sx={{ fontSize: "22px", marginTop: "4px" }} />
													</Box>
												</TooltipStyled>
											</Box>
										</Fade>
									);
								})}
							</Box>

							{item.experiments.length < 7 &&
								(
									<Fade in={item.experiments.length < 7} timeout={300}>
										<Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
											<CheckboxStyled
												checked={newTaskIsChecked}
												onChange={(_event, checked) => setNewTaskIsChecked(checked)}
											/>

											<CustomInput.Base
												placeholder="add new task"
												value={newTaskValue}
												onChange={({ target }) => setNewTaskValue(target.value)}
												onKeyDown={onKeyDownNewTask}
												inputProps={{ maxLength: MAX_FILED_LENGTH }}
												sx={{
													background: "unset",
													flexGrow: 1,

													"& .MuiInputBase-input":
													{
														padding: "unset",
														color: "primary.dark",
														font: { lg: "400 16px/24px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" },
														"&::placeholder":
														{
															opacity: "0.5 !important"
														}
													}
												}}
											/>
										</Box>
									</Fade>
								)
							}
						</div>

						<label>
							<span>How will you know the experiment was a success?</span>

							<CustomInput.Base
								name="definitionSuccess"
								placeholder="e.g. Create a website displaying my art with prices"
								inputProps={{ maxLength: MAX_FILED_LENGTH }}
								value={formik.values.definitionSuccess}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<Box sx={{
								margin: "-8px 10px 0 10px",
								display: "flex",
								justifyContent: "space-between",
								gap: "16px"
							}}>
								<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
									{formik.errors.definitionSuccess}
								</Box>

								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{formik.values.definitionSuccess.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</label>

						<label style={{ marginTop: "-19px" }}>
							<span>Key insights</span>

							<CustomInput.Base
								name="keyInsights"
								inputProps={{ maxLength: MAX_FILED_LENGTH }}
								value={formik.values.keyInsights}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<Box sx={{
								margin: "-8px 10px 0 10px",
								display: "flex",
								justifyContent: "space-between",
								gap: "16px"
							}}>
								<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
									{formik.errors.keyInsights}
								</Box>

								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{formik.values.keyInsights.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</label>

						<label style={{ marginTop: "-19px" }}>
							<span>Key Decision</span>

							<CustomInput.Base
								name="decision"
								inputProps={{ maxLength: MAX_FILED_LENGTH }}
								value={formik.values.decision}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>

							<Box sx={{
								margin: "-8px 10px 0 10px",
								display: "flex",
								justifyContent: "space-between",
								gap: "16px"
							}}>
								<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
									{formik.errors.decision}
								</Box>

								<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
									{formik.values.decision.length}/{MAX_FILED_LENGTH} characters
								</Box>
							</Box>
						</label>

						<Box sx={{ display: "flex", gap: "45px" }}>
							<span style={{ marginLeft: "unset" }}>Verdict</span>

							<Box sx={{
								display: "flex",
								gap: "40px",

								"& button":
								{
									"--size": "80px",
									width: "var(--size)",
									height: "var(--size)",
									padding: "unset",
									background: "unset",
									boxShadow: "0 2px 10px " + alpha("#FFFFFF", 0.7),
									border: "unset",
									borderRadius: "50%",
									cursor: "pointer",

									"& img":
									{
										height: "100%",
										width: "100%"
									}
								}
							}}>
								<TooltipStyled title="Like" placement="top" arrow>
									<button onClick={onChangeLike(true)} style={{ opacity: item.like === true ? 1 : 0.5 }}>
										<img src={IMGLike} alt="Like" />
									</button>
								</TooltipStyled>

								<TooltipStyled title="Dislike" placement="top" arrow>
									<button onClick={onChangeLike(false)} style={{ opacity: item.like === false ? 1 : 0.5 }}>
										<img src={IMGDislike} alt="Dislike" />
									</button>
								</TooltipStyled>
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						disabled={!(formik.isValid && formik.dirty)}
						sx={{ justifySelf: "center", marginTop: "20px" }}
					>
						Save
					</CustomButton.Contained>
				</Box>
			}

			<Snackbar
				open={isShowAlert}
				autoHideDuration={5000}
				onClose={() => setIsShowAlert(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "35px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
					You must add at least one task
				</AlertStyled>
			</Snackbar>

			<Dialog
				open={isShowChangeTitleModal}
				onClose={() => setIsShowChangeTitleModal(false)}
				title="Change experiment name"
			>
				<form onSubmit={onChangeTitle}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="Name"
							value={newTitle}
							inputProps={{ maxLength: RULES.title.maxLength }}
							onChange={({ target }) => setNewTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{newTitleError}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{newTitle.length}/{RULES.title.maxLength} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default Edit;
