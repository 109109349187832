import { alpha } from "@mui/material";
import { MouseEvent } from "react";
import CustomButton from "../customButton";

export type AnswerType = string | JSX.Element;
export type AnswersType =
	{
		type: "select";
		items: AnswerType[];
	} | {
		type: "input";
	};

interface IAnswerProps
{
	text: AnswerType;
	onClick(event: MouseEvent<HTMLButtonElement>): void;
}

function Answer({ text, onClick }: IAnswerProps)
{
	return (
		<CustomButton.Outline
			onClick={onClick}
			sx={{
				backgroundColor: alpha("#FFFFFF", 0.5),
				minWidth: "unset",
				minHeight: "unset",
				height: { xl: "56px", lg: "40px", xs: "40px" },
				padding: { xl: "12px 80px", lg: "12px 32px", xs: "12px 80px" },
				font: { xl: "700 24px/32px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" }
			}}
		>
			{text}
		</CustomButton.Outline>
	);
}

export default Answer;
