import { Box } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import CelebrationScreen from "components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

function ClusterYourYearningsFinal()
{
	const missionContext = useMission();

	// Handles
	function onClickNextStep()
	{
		missionContext.setStep((currentStep) => currentStep + 1);
	}

	// Render
	return (
		<Box className="main__content" sx={{ alignItems: "center" }}>
			<CelebrationScreen
				content={<ContentAdmin keyContent="contentMissionThree" keyText="screen_19b_text" defaultValue="Excellent, {NAME}!\nOne last step for crystal clarity." position="left" isDescription />}
				buttonText="I am ready!"
				onClickButton={() => onClickNextStep()}
				sx={{
					"& .CelebrationScreen-content":
					{
						color: "primary.main",
						textShadow: "unset"
					}
				}}
			/>
		</Box>
	);
}

export default ClusterYourYearningsFinal;
