import { Box } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import Api from "api";
import { IResponseAuthenticate } from "api/Auth";
import IMGGuideOcta from "assets/images/guideChara-shrink.png";
import { ReactComponent as SVGGoogle } from "assets/images/icons/google.svg";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import useRegistration from "pages/registration/hooks/useRegistration";
import { Dispatch, SetStateAction } from "react";
import { UserActionType } from "reducers/userReducer";
import { setLocalStorage } from "utils/localStorage";
import trackEvent from "utils/mixpanelUtil";
import { signUpWith } from ".";

export interface IWithGAuthProps
{
	readonly setSignUpWith: Dispatch<SetStateAction<signUpWith>>;
}

function WithGAuth({ setSignUpWith }: IWithGAuthProps)
{
	const { setStep, name } = useRegistration();
	const userContext = useUser();

	// Handles
	const login = useGoogleLogin(
		{
			onSuccess(tokenResponse)
			{
				Api.auth
					.authenticateByGoogle(tokenResponse.code, name)
					.then((res: IResponseAuthenticate) =>
					{
						setLocalStorage("accessToken", res.accessToken);
						setLocalStorage("email", res.email);
						setLocalStorage("name", res.name);
						setLocalStorage("userId", res.id);
						setLocalStorage("onboarded", res.onboarded);
						setLocalStorage("completedMission", res.completedMission);

						trackEvent({
							eventName: "Registration Success",
							userId: res.id,
							properties: { "Auth method": "GAuth" }
						});

						userContext.dispatch(
							{
								type: UserActionType.LOGIN,
								payload: { user: res, accessToken: res.accessToken }
							}
						);

						// Bypass password entry
						setTimeout(() => setStep((prevState) => prevState + 2), 0);
					})
					.catch((error) => console.error(error));
			},
			flow: "auth-code"
		}
	);

	// Render
	return (
		<Box sx={{
			padding: { xl: "48px 24px 24px 24px", xs: "36px 32px 0px 32px" },
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			flexGrow: 1
		}}>
			<Box
				component="img"
				src={IMGGuideOcta}
				alt=""
				sx={{
					width: { xl: "211px", xs: "197px" }
				}}
			/>

			<Box sx={{
				font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main"
			}}>
				Get Your Free Report
			</Box>

			<Box sx={{
				maxWidth: "461px",
				marginTop: { md: "4px", xs: "16px" },
				font: "400 16px/22px 'Open Sans'",
				color: "primary.main",
				textAlign: "center"
			}}>
				Please confirm your account details to view your custom plan and access the free mission of the quest.
			</Box>

			<Box sx={{
				padding: "0 16px",
				marginTop: { xl: "62px", lg: "80px", xs: "auto" },
				width: "100%",
				minHeight: { sm: "unset", xs: "120px" },

				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: { xl: "16px", xs: "12px" }
			}}>
				<CustomButton.Outline
					onClick={() => login()}
					sx={{
						width: { sm: "unset", xs: "100%" },
						textWrap: "unset",

						"& svg":
						{
							flexShrink: 0,
							width: "24px",
							height: "24px"
						},
						"& svg *":
						{
							stroke: "unset"
						}
					}}
				>
					<SVGGoogle />
					<Box sx={{
						fontSize: { sm: "24px", xs: "17px" },
						whiteSpace: "nowrap"
					}}>
						Sign up with Google
					</Box>
				</CustomButton.Outline>

				<Box
					component="button"
					onClick={() => setSignUpWith("email")}
					sx={{
						padding: "unset",

						display: "block",
						background: "unset",
						border: "unset",

						font: "400 18px/25px 'Open Sans'",
						color: "primary.main",
						textDecoration: "underline",

						cursor: "pointer"
					}}
				>
					or sign up with email
				</Box>
			</Box>
		</Box>
	);
}

export default WithGAuth;
