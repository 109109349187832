import { Masonry } from "@mui/lab";
import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import ChipStyled from "components/ChipStyled";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MissionData, formatDate } from ".";
import { COLORS } from "../../../missionThree/components/ClusterYourYearnings";
import ArtifactsContainer from "../components/ArtifactsContainer";
import IMGBackground from "./assets/images/background.png";

export interface ICluster
{
	title: string;
	items: IClusterItem[];
}

export interface IClusterItem
{
	title: string;
	color: keyof typeof COLORS;
}

export interface IDetailsProps
{
	items: MissionData[];
}

function Details({ items }: IDetailsProps)
{
	const navigate = useNavigate();
	const { data: missionId } = useParams();

	const isFetchingRef = useRef<boolean>(false);
	const [ clusters, setClusters ] = useState<ICluster[]>([]);
	const [ currentItemIndex, setCurrentItemIndex ] = useState<number>(() =>
	{
		return getMissionIndexById(missionId as string);
	});

	// Utils
	function getMissionIndexById(id: string): number
	{
		return items.findIndex(([ itemId ]) => itemId === id);
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (missionId === undefined)
		{
			navigate("/dashboard/artifacts/yearning");
		}
	}, [ missionId, navigate ]);

	useLayoutEffect(() =>
	{
		setCurrentItemIndex(getMissionIndexById(missionId as string));

	}, [ items, missionId ]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() =>
	{
		if (items.length === 0 || currentItemIndex === -1)
		{
			return;
		}

		navigate("/dashboard/artifacts/yearning/details/" + items[ currentItemIndex ][ 0 ], { replace: true });

		if (currentItemIndex === -1 || isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getDataMissionThree(items[ currentItemIndex ][ 0 ])
			.then(({ data }) =>
			{
				setClusters(
					data.mission_data.clusters.filter(({ items }) => items.length > 0)
				);
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetchingRef.current = false));
	}, [ items, currentItemIndex, navigate ]);

	// Render
	return (
		<ArtifactsContainer
			backgroundImage={IMGBackground}
			title={formatDate(items[ currentItemIndex ]?.[ 1 ] || "Yearning details not found")}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts/yearning")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			{currentItemIndex > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setCurrentItemIndex((prevState) => prevState - 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								left: { xl: "20px", lg: "10px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{currentItemIndex < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setCurrentItemIndex((prevState) => prevState + 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "10px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Box
					className="customScroll"
					sx={{
						"--width": { xl: "1389px", lg: "1053px", xs: "1389px" },
						overflowY: "auto",
						maxHeight: "664px",
						marginRight: "-24px",
						paddingRight: "16px",
						maxWidth: "var(--width)",
						width: "100%"
					}}
				>
					<Masonry
						columns={3}
						spacing={{ xl: "48px", lg: "24px", xs: "48px" }}
						sx={{
							width: "var(--width)",

							// Chips
							"& .MuiChip-root":
							{
								height: "unset",
								whiteSpace: "normal"
							},
							"& .MuiChip-label":
							{
								color: "#FFFFFF"
							},
							"& .MuiChip-root--color-red":
							{
								background: alpha(COLORS.red, 0.5)
							},
							"& .MuiChip-root--color-green":
							{
								background: alpha(COLORS.green, 0.5)
							},
							"& .MuiChip-root--color-blue":
							{
								background: alpha(COLORS.blue, 0.5)
							},
							"& .MuiChip-root--color-none":
							{
								background: alpha(COLORS.none, 0.5),

								"& .MuiChip-label":
								{
									color: "#4D4D4D"
								}
							},
							// Cluster
							"& .cluster-root":
							{
								padding: { xl: "24px 32px 32px 32px", lg: "24px", xs: "24px 32px 32px 32px" },
								borderRadius: "24px",
								width: { xl: "423px", lg: "320px", xs: "423px" },
								display: "flex",
								flexDirection: "column",
								gap: "16px",
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								backdropFilter: "blur(10px)",
								border: "1px solid " + alpha("#FFFFFF", 0.5),
								boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5)
							},
							"& .cluster__title":
							{
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
								color: "primary.main"
							},
							"& .cluster__items":
							{
								display: "flex",
								flexWrap: "wrap",
								gap: "16px",
								alignItems: "center",
								overflow: "auto",
								maxHeight: "380px",
								paddingBottom: "5px",

								"&:empty":
								{
									"&::after":
									{
										content: "'Drop to here'",
										padding: "10px",
										display: "block",
										width: "100%",
										textAlign: "center",
										color: alpha("#4D4D4D", 0.6),
										font: "500 16px 'Open Sans'",
										border: "2px dashed " + alpha("#215C75", 0.6),
										borderRadius: "5px",
										transition: "linear 200ms border-color, linear 200ms color"
									}
								},
								"& .MuiChip-root":
								{
									flexGrow: 1,
									cursor: "inherit",
									"& .MuiChip-label":
									{
										textAlign: "center",
										flexGrow: 1
									}
								}
							}
						}}
					>
						{clusters.map(
							({ title, items }, clusterIndex) => (
								<Box key={title + "-" + clusterIndex} className="cluster-root">
									<Box className="cluster__title">{title}</Box>
									<Box className="cluster__items customScroll">
										{items.map(
											(clusterItem, clusterItemIndex) => (
												<ChipStyled
													key={clusterItem.title + "-" + clusterItemIndex}
													label={clusterItem.title}
													className={"MuiChip-root--color-" + clusterItem.color}
												/>
											)
										)}
									</Box>
								</Box>
							)
						)}
					</Masonry>
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default Details;
