import { alpha, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { DragEvent, useEffect, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import PanelTitle from "../../../../components/missions/PanelTitle";
import useMission from "../../hooks/useMission";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
		font: '400 14px/17px "Open Sans"',
		color: "#4D4D4D",
		boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
		padding: "24px",
		borderRadius: "16px",
		backdropFilter: "blur(15px)",
		border: "1px solid  rgba(239, 239, 239, 0.0816)",
		minWidth: "471px"
	}
}));

function LifeRoadMap()
{
	const missionContext = useMission();
	const [ boards, setBoards ] = useState<any>([]);
	const [ currentBoard, setCurrentBoard ] = useState<any>(null);
	const [ currentItem, setCurrentItem ] = useState<any>(null);

	function onClickNextStep()
	{
		localStorage.setItem("mission-nine-graph-data-checked", JSON.stringify(boards));

		missionContext.setStep((prevStep) => prevStep + 1);
	}

	useEffect(() =>
	{

		const localData = JSON.parse(`${ localStorage.getItem("mission-nine-graph-data") }`);
		const data: any = [
			{
				id: 2,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_focus"
					defaultValue="Do right away"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_focus_tooltip"
					defaultValue="Exciting things you will do right away to quickly improve your life."
					position="left"
					isDescription
				/>,
				items: []
			},
			{
				id: 3,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_research"
					defaultValue="Do in the next 6 months"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_research_tooltip"
					defaultValue="Impactful things that are a little more complex, but you really want them"
					position="left"
					isDescription
				/>,
				items: []
			},
			{
				id: 4,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_fillIns"
					defaultValue="Do in the next 1-2 years"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_fillIns_tooltip"
					defaultValue="Anything else that is still important to you but you are less excited about"
					position="left"
					isDescription
				/>,
				items: []
			},
			{
				id: 5,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_luxuries"
					defaultValue="Do in the next 3-5 years"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_luxuries_tooltip"
					defaultValue="Everything else that you want to do but might not be your biggest priority right now."
					position="left"
					isDescription
				/>,
				items: []
			}
		];

		localData.forEach((item: any) =>
		{
			if (item.id === 2)
			{
				item.itemsBoard.length > 0 && item.itemsBoard.forEach((element: any) =>
				{
					data[ 0 ].items.push(element);
				});
			}

			if (item.id === 3)
			{
				item.itemsBoard.length > 0 && item.itemsBoard.forEach((element: any) =>
				{
					data[ 1 ].items.push(element);
				});
			}

			if (item.id === 4)
			{
				item.itemsBoard.length > 0 && item.itemsBoard.forEach((element: any) =>
				{
					data[ 2 ].items.push(element);
				});
			}

			if (item.id === 5)
			{
				item.itemsBoard.length > 0 && item.itemsBoard.forEach((element: any) =>
				{
					data[ 3 ].items.push(element);
				});
			}
		});
		setBoards(data);

	}, []);

	function dragOverHandler(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function dragStartHandler(e: any, board: any, item: any)
	{
		setCurrentBoard(board);
		setCurrentItem(item);
	}

	function dropCardHandlerGood(_event: DragEvent<HTMLDivElement>, board: any)
	{
		if (currentItem === null || currentBoard === null)
		{
			return;
		}

		board.items.push(currentItem);
		const currentIndex = currentBoard.items.indexOf(currentItem);

		currentBoard.items.splice(currentIndex, 1);
		setBoards(boards.map((b: any) =>
		{
			if (b.id === board.id)
			{
				return board;
			}

			if (b.id === currentBoard.id)
			{
				return currentBoard;
			}

			return b;
		}));
	}

	function dropCardHandler(_event: DragEvent<HTMLDivElement>, board: any, item: number)
	{
		if (currentItem === null || currentBoard === null)
		{
			return;
		}

		_event.preventDefault();
		const currentIndex = currentBoard.items.indexOf(currentItem);

		currentBoard.items.splice(currentIndex, 1);
		const dropIndex = board.items.indexOf(item);

		board.items.splice(dropIndex + 1, 0, currentItem);
		setBoards(boards.map((b: any) =>
		{
			if (b.id === board.id)
			{
				return board;
			}

			if (b.id === currentBoard.id)
			{
				return currentBoard;
			}

			return b;
		}));
	}

	return (
		<Box
			className="main__content"
			sx={{
				height: "84vh",
				width: "100%",
				m: "0 auto",
				position: "relative",
				maxWidth: { lg: "814px", xl: "1036px" },
				top: "-15px",
				left: "0px"
			}}>
			<PanelTitle
				positionOcta
				zIndexOcta="10"
				title={<ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_title"
					defaultValue="Your dream life roadmap"
					position="left"
				/>}
				subTitle={<ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_subTitle"
					defaultValue="Yee-haa, here is your prioritised roadmap. Don’t worry, we’ll get to the details in a bit. For now, see if everything is in the right place and rearrange if needed."
					position="left"
					isDescription />}
				imageVariation="closeEye"
			/>
			{missionContext.showContent
				? (
					<>
						<Box
							sx={{
								display: "flex",
								gap: "16px",
								marginLeft: "30px"
							}}
						>
							<Box
								className="scrollCustom"
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "start",
									flexWrap: "wrap",
									width: "100%",
									"& .cluster__column":
									{
										padding: "17px 26px 12px 26px",
										borderRadius: "24px",
										width: "calc(50% - 16px)",
										margin: "8px",
										display: "flex",
										flexDirection: "column",
										gap: "10px",
										border: "1px solid " + alpha("#FFFFFF", 0.5),
										background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
										backdropFilter: "blur(30px)",
										boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
										height: { lg: "194px", xl: "271px" },
										overflowY: "scroll",
										overflowX: "hidden"
									},
									"& .cluster__title": {
										font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" },
										textTransform: "capitalize",
										textAlign: "left",
										color: "#215C75"
									},
									"& .cluster__items":
									{
										display: "flex",
										flexWrap: "wrap",
										gap: "7px",
										alignItems: "center",
										overflow: "auto",
										padding: "7px 0 10px 0"
									}
								}}
							>
								{boards.length > 0 && boards.map(
									(board: any, index: number) => (
										<Box
											onDragOver={(e) => dragOverHandler(e)}
											onDrop={(e) => dropCardHandlerGood(e, board)}
											key={board.id}
											className="cluster__column"
										>
											<Box sx={{ display: "flex", alignItems: "center" }}>
												<Box className="cluster__title">{index + 1}. &nbsp;</Box>
												<Box className="cluster__title">{board.title}</Box>
												{/* <LightTooltip placement="top" title={<Typography sx={{
													color: '#4D4D4D', font: '400 14px/19px "Open Sans"',
												}}>
													{board.tooltip}
												</Typography>}>
													<InfoOutlinedIcon sx={{
														fill: '#215C75', ml: 1,
														cursor: `url(${ CursorPointer }), auto`,
													}} />
												</LightTooltip> */}
											</Box>
											<Box className="cluster__items scrollCustom">
												{board.items.map(
													(boardItem: any, index: number) => (
														<Box
															key={index}
															className="dnd-block"
															draggable
															onDragOver={(e) => dragOverHandler(e)}
															onDragStart={(e) => dragStartHandler(e, board, boardItem)}
															onDrop={(e) => dropCardHandler(e, board, boardItem)}
															sx={{
																display: "flex",
																alignItems: "center",
																backgroundColor: "rgba(255, 255, 255, 0.5);",
																boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
																borderRadius: "60px",
																padding: "9px 16px"
															}}>
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	"&:hover": {
																		cursor: "pointer"
																	}
																}}
															>
																{boardItem}
															</Box>
														</Box>
													)
												)}
											</Box>
										</Box>
									)
								)}
							</Box>
						</Box>
						<CustomButton.Contained onClick={onClickNextStep} sx={{ display: "flex", margin: "0 auto", background: "#3E809D", borderColor: "#3E809D" }}>
							Submit
						</CustomButton.Contained>
					</>
				)
				: null
			}
		</Box>
	);
}

export default LifeRoadMap;
