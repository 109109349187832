import { Box, BoxProps, alpha } from "@mui/material";
import { ISaveMissionFourData } from "api/Missions";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { ETab, TABS, formatData } from "pages/dashboard/artifacts/career";
import { ForwardRefExoticComponent, Ref, forwardRef, useState } from "react";
import { getLocalStorage } from "utils/localStorage";
import useMission from "../../hooks/useMission";
import { JobInformation } from "../PersonalJobAd/LearnMoreAboutEachJob";

export interface IPerfectJobProps extends BoxProps { }

function PerfectJob({ ...props }: IPerfectJobProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ selectedTab, setSelectedTab ] = useState<ETab>(ETab.PERFECT_JOB_AD);

	const [ personalJobAdText ] = useState<null | string>(() =>
	{
		return getLocalStorage<string>(missionContext.missionPrefix + "-PersonalJobAd-text");
	});
	const [ jobsList ] = useState<null | string[]>(() =>
	{
		return getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsList");
	});
	const [ selectedJobs ] = useState<null | string[]>(() =>
	{
		return getLocalStorage<string[]>(missionContext.missionPrefix + "-selectedJobs");
	});
	const [ jobsInformation ] = useState<null | Record<string, JobInformation>>(() =>
	{
		return getLocalStorage<Record<string, JobInformation>>(missionContext.missionPrefix + "-jobsInformation");
	});

	// Render
	if (personalJobAdText === null)
	{
		missionContext.setStep(13);
	}
	else if (jobsList === null)
	{
		missionContext.setStep(14);
	}
	else if (selectedJobs === null)
	{
		missionContext.setStep(15);
	}
	else if (jobsInformation === null)
	{
		missionContext.setStep(16);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="PerfectJob_title" defaultValue="{NAME}’s perfect job" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="PerfectJob_subTitle" defaultValue="" position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: "50px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{
					maxWidth: "836px",
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					gap: "8px",
					padding: "0 16px"
				}}>
					{TABS.map(([ text, tabValue ]) =>
					{
						return (
							<CustomButton.Base
								key={tabValue}
								onClick={() => setSelectedTab(tabValue)}
								sx={{
									textTransform: "uppercase",
									padding: { xl: "14px 32px 15px 32px", lg: "13px 32px", xs: "13px 32px" },
									font: { xl: "700 18px/25px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 16px/22px 'Open Sans'" },
									minWidth: "unset",
									minHeight: "unset",
									opacity: (selectedTab === tabValue ? 1 : 0.5),
									border: "unset"
								}}
							>
								{text}
							</CustomButton.Base>
						);
					})}
				</Box>

				<Box sx={{
					marginTop: { xl: "28px", lg: "24px", xs: "24px" },
					maxWidth: { xl: "836px", lg: "759px", xs: "759px" },
					padding: { xl: "32px", lg: "24px", xs: "24px" },
					width: "100%",
					height: { xl: "520px", lg: "358px", xs: "358px" },
					backgroundColor: alpha("#FFFFFF", 0.8),
					borderRadius: "32px",

					"& .customScroll":
					{
						overflowY: "auto",
						paddingRight: "12px",
						height: "100%",

						"& li":
						{
							gap: "11px !important"
						}
					}
				}}>
					<div className="customScroll">
						{formatData(
							{
								jobList: jobsList,
								selectedJobs,
								jobsInformation,
								personalJobAdText
							} as ISaveMissionFourData,
							selectedTab
						)}
					</div>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={() => missionContext.nextStep()} />
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(PerfectJob) as ForwardRefExoticComponent<IPerfectJobProps>;
