import { Box, keyframes } from "@mui/material";
import { Ref, forwardRef, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import RadialButton from "../../../../components/RadialButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

export type ILetsUncoverDeepestSittingBeliefsProps = Record<string, any>;

export interface IInitialValues
{
	field1: string;
	field2: string;
	field3: string;
}

const animation = keyframes`
	0%, 100% { transform: scale(1); }
	50% { transform: scale(1.15); }
`;

function LetsUncoverDeepestSittingBeliefs({ ...props }: ILetsUncoverDeepestSittingBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ isShowValues, setIsShowValues ] = useState<[ boolean, boolean ]>([ false, false ]);

	const isFilled1 = !!getLocalStorage<IInitialValues>("mission-seven-i-should");
	const isFilled2 = !!getLocalStorage<IInitialValues>("mission-seven-i-am");

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverDeepestSittingBeliefs_title" defaultValue="Let’s uncover the deepest sitting beliefs!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverDeepestSittingBeliefs_subTitle" defaultValue="You’ve identified many hidden beliefs already.\nLet’s squeeze out those that might still be hiding." position="left" isDescription />}
					sx={{
						marginBottom: "100px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={[
					{
						height: "160px",
						width: "704px",
						display: "flex",
						flexDirection: "column"
					},
					...[ isShowValues[ 0 ] === false && { paddingRight: "40px" } ],
					...[ isShowValues[ 1 ] === false && { paddingLeft: "40px" } ]
				]}>
					<Box sx={{ alignSelf: "flex-end" }}>
						<RadialButton
							value="I should…"
							direction="left"
							blip={isFilled1 === false}
							showValue={isShowValues[ 0 ]}
							onMouseEnter={() => setIsShowValues([ true, false ])}
							onMouseLeave={() => setIsShowValues([ false, false ])}
							onClick={() => missionContext.setExtraStep(1)}
							sx={{
								"& .animation--blip-radialButton":
								{
									animation: `ease-in-out ${ animation } var(--duration) infinite`
								}
							}}
						/>
					</Box>

					<Box>
						<RadialButton
							value="I am…"
							blip={isFilled2 === false}
							showValue={isShowValues[ 1 ]}
							onMouseEnter={() => setIsShowValues([ false, true ])}
							onMouseLeave={() => setIsShowValues([ false, false ])}
							onClick={() => missionContext.setExtraStep(2)}
							sx={{
								"& .animation--blip-radialButton":
								{
									animation: `ease-in-out ${ animation } var(--duration) infinite`
								}
							}}
						/>
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={() => { missionContext.nextStep(); }} disabled={isFilled1 === false || isFilled2 === false} />
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(LetsUncoverDeepestSittingBeliefs);
