import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertColor, Box, Dialog, Snackbar, Stack, Typography } from "@mui/material";
import Api from "api";
import type { DataCreateCoupon, ResGetCoupons } from "api/Coupons";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { ReactNode, useState, type Dispatch, type SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { getCreateCoupon, useSchema } from "validation/yupValidation";

export type CreateCoupon = {
	id: string;
	percentOff: number;
	redeemBy: string;
	name: string;
};

interface CreateCouponsProps
{
	coupons: ResGetCoupons[];
	setCoupons: Dispatch<SetStateAction<ResGetCoupons[]>>;
}

function CreateCoupons({ coupons, setCoupons }: CreateCouponsProps)
{
	const [ isLoadingCreate, setIsLoadingCreate ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ uniqueIdErr, setUniqueIdErr ] = useState<boolean>(false);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);
	const { control, handleSubmit, setValue } = useForm({
		resolver: useSchema(getCreateCoupon),
		defaultValues: {
			id: "",
			name: "",
			percentOff: 1,
			redeemBy: "once",
			status: true
		}
	});

	const onSubmit = handleSubmit(async (values) =>
	{
		const data = values as DataCreateCoupon;
		if (coupons.filter((coupon) => coupon.id === data.id).length === 1)
		{
			setUniqueIdErr(true);

			return;
		}

		setIsLoadingCreate(true);
		Api.coupons
			.createCoupon(data)
			.then(() =>
			{
				setSnackbarContent({ severity: "success", content: "Success" });
				const newCoupons = [ ...coupons ];

				newCoupons.push(data as ResGetCoupons);
				setUniqueIdErr(false);
				setCoupons(newCoupons);
				setOpen(false);
			})
			.catch((err) =>
			{
				setSnackbarContent({ severity: "error", content: "An error occurred" });
				console.error(err);
			})
			.finally(() => setIsLoadingCreate(false));
	});

	// Render
	return (
		<Box>
			<CustomButton.Base
				onClick={() => setOpen(true)}
				sx={{ width: "100%", marginTop: "24px" }}
			>
				Create new coupon
			</CustomButton.Base>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={3000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					"& .MuiDialog-paper":
					{
						minHeight: { sm: "425px", xs: "330px" },

						"& *:not(img)":
						{
							zIndex: 1
						},
						background: "linear-gradient(to right bottom, rgba(152, 176, 181,1 ), rgba(148, 230, 247,0.80196078431372549 ))",
						p: "0 24px 24px"
					}
				}}
			>
				<Box sx={{
					marginTop: "24px",
					width: { sm: "568px", xs: "224px" },
					font: { sm: "400 22px/30px 'Open Sans'", xs: "400 16px/20px 'Open Sans'" },
					color: "primary.dark"
				}}>
					<Typography sx={{
						textAlign: "center",
						color: "#215C75",
						font: { lg: "700 18px/16px 'Lora-Bold'", xl: "700 22px/30px 'Lora-Bold'" },
						mt: 0
					}}>
						Create Coupon
					</Typography>
					<Stack gap={1} mt={2}>
						<Stack direction="row" gap={1} alignItems="center">
							<Typography sx={{
								color: "#000",
								font: { lg: "400 18px/16px 'Lora'", xl: "400 22px/18px 'Lora'" },
								mt: 0
							}}>
								Name
							</Typography>
							<TooltipStyled title="Coupon name" placement="top" arrow>
								<InfoOutlinedIcon sx={{ color: "#000" }} />
							</TooltipStyled>
						</Stack>
						<Controller
							control={control}
							name="name"
							render={({ field, fieldState }) => (
								<>
									<CustomInput.Base
										fullWidth
										required
										{...field}
										error={Boolean(fieldState.error)}
									/>

									<Box sx={{
										paddingLeft: "5px",
										color: "#DC4242",
										font: "400 14px/19px 'Open Sans'"
									}}>
										{fieldState.error?.message}
									</Box>

								</>
							)}
						/>
					</Stack>
					<Stack gap={1} mt={2}>
						<Stack direction="row" gap={1} textAlign="center">
							<Typography sx={{
								color: "#000",
								font: { lg: "400 18px/16px 'Lora'", xl: "400 22px/18px 'Lora'" },
								mt: 0
							}}>
								Code
							</Typography>
							<TooltipStyled title="Coupon code that users enter" placement="top" arrow>
								<InfoOutlinedIcon sx={{ color: "#000" }} />
							</TooltipStyled>
						</Stack>
						<Box>
							<Controller
								control={control}
								name="id"
								render={({ field, fieldState }) => (
									<>
										<CustomInput.Base
											fullWidth
											required
											{...field}
											error={Boolean(fieldState.error)}
										/>

										<Box sx={{
											paddingLeft: "5px",
											color: "#DC4242",
											font: "400 14px/19px 'Open Sans'"
										}}>
											{fieldState.error?.message}
										</Box>

									</>
								)}
							/>
							{uniqueIdErr
								?
								(
									<Typography sx={{
										color: "red",
										mt: 1
									}}>
										This code already exists
									</Typography>
								)
								:
								null}
						</Box>
					</Stack>
					<Stack gap={1} mt={2}>
						<Stack direction="row" gap={1} alignItems="center">
							<Typography sx={{
								color: "#000",
								font: { lg: "400 18px/16px 'Lora'", xl: "400 22px/18px 'Lora'" },
								mt: 0
							}}>
								Discount
							</Typography>
							<TooltipStyled title="Amount of percentage discount. Minimum value 1, maximum 100" placement="top" arrow>
								<InfoOutlinedIcon sx={{ color: "#000" }} />
							</TooltipStyled>
						</Stack>
						<Controller
							control={control}
							name="percentOff"
							render={({ field, fieldState }) => (
								<>
									<CustomInput.Base
										fullWidth
										type="number"
										required
										{...field}
										inputProps={{ min: 1, max: 100 }}
										error={Boolean(fieldState.error)}
									/>

									<Box sx={{
										paddingLeft: "5px",
										color: "#DC4242",
										font: "400 14px/19px 'Open Sans'"
									}}>
										{fieldState.error?.message}
									</Box>

								</>
							)}
						/>
					</Stack>
				</Box>

				<Box sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "24px",
					width: "100%"
				}}>
					<CustomButton.Base
						onClick={onSubmit}
						sx={{
							width: { sm: "auto", xs: "100%" }
						}}
						loading={isLoadingCreate}
					>
						Save
					</CustomButton.Base>
				</Box>
			</Dialog>
		</Box>
	);
}

export default CreateCoupons;
