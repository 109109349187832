import { IconButton, Zoom } from "@mui/material";
import useMission from "pages/missionTwo/hooks/useMission";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import { ReactComponent as SVGAirBallon } from "../../assets/images/air-ballon.svg";

export interface IWhatMakesProudButtonsProps
{
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

export type WhatMakesProudData = string;

const PREVIEW_OFFSET: number = 1;

export function filledForm(data: string)
{
	return data.trim().length > 0;
}

function WhatMakesProudButtons({ preview = false }: IWhatMakesProudButtonsProps)
{
	const missionContext = useMission();
	const whatMakesProud = useReadLocalStorage<WhatMakesProudData>(missionContext.missionPrefix + "-whatMakesProud", "");
	const [ isSeenAirBallonAnimation, setIsSeenAirBallonAnimation ] = useLocalStorage<boolean>(
		missionContext.missionPrefix + "-isSeenAirBallonAnimation",
		false
	);

	// Render
	// if (filledForm(whatMakesProud) === false)
	// {
	// 	return (
	// 		<Box
	// 			sx={{
	// 				position: "absolute",
	// 				left: { xl: "20%" },
	// 				top: { xl: "80px" }
	// 			}}
	// 		>
	// 			<RadialButton
	// 				blip
	// 				offBlipOnClick={false}
	// 				value=""
	// 				showValue={false}
	// 				onClick={() => missionContext.setExtraStep(7 + (preview ? PREVIEW_OFFSET : 0))}
	// 			/>
	// 		</Box>
	// 	);
	// }

	return (
		<>
			{missionContext.step === 11 ?
				<IconButton
					disableFocusRipple
					disableRipple
					disableTouchRipple
					onClick={() => missionContext.setExtraStep(7 + (preview ? PREVIEW_OFFSET : 0))}
					sx={{
						position: "absolute",
						left: { xl: "20%", lg2: "20%", xs: "24%" },
						top: { xl: "20px", lg2: "30px", xs: "10px" },

						width: { xl: "171px", lg2: "142px", xs: "114px" },
						backgroundColor: "unset !important"
					}}
				>
					<SVGAirBallon />
				</IconButton> :
				(
					<Zoom
						in
						timeout={isSeenAirBallonAnimation ? 0 : 4000}
						onTransitionEnd={() => setIsSeenAirBallonAnimation(true)}
					>
						<IconButton
							disableFocusRipple
							disableRipple
							disableTouchRipple
							onClick={() => missionContext.setExtraStep(7 + (preview ? PREVIEW_OFFSET : 0))}
							sx={{
								position: "absolute",
								left: { xl: "20%", lg2: "20%", xs: "24%" },
								top: { xl: "20px", lg2: "30px", xs: "10px" },

								width: { xl: "171px", lg2: "142px", xs: "114px" },
								backgroundColor: "unset !important"
							}}
						>
							<SVGAirBallon />
						</IconButton>
					</Zoom>
				)
			}
		</>
	);
}

export default WhatMakesProudButtons;
