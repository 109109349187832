import { Box } from "@mui/material";
import { Ref, forwardRef } from "react";
import calmSuccess from "../../../assets/images/calmSuccess.svg";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const ToFaceFears = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
		missionContext.setBackground("yourPlan");
	}

	return (
		<Box ref={ref} {...props} sx={{ height: "81vh", width: "100%", maxWidth: { lg: "802px", xl: "980px" }, m: "0 auto" }}>
			<Box sx={{ width: { lg: "150px", xl: "199px" }, height: { lg: "300px", xl: "359px" }, m: { lg: "50px auto 30px", xl: "100px auto 30px" } }}>
				<img width="100%" src={calmSuccess} alt="guide-chara" />
			</Box>
			<Box sx={{
				font: { lg: "700 30px/41px Lora", xl: "700 40px/51px Lora" },
				color: "#E9EDF0",
				textAlign: "center",
				wordBreak: "break-word",
				maxHeight: "102px",
				overflow: "hidden",
				maxWidth: "785px",
				margin: "0 auto"
			}}>
				<ContentAdmin
					limit={111}
					keyContent="contentMissionEight"
					keyText="to_face_fears"
					defaultValue="{NAME}, it is important to face your fears"
					position="top"
				/>
			</Box>
			<Box sx={{
				font: { lg: "400 18px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" },
				color: "#E9EDF0",
				textAlign: "center",
				marginTop: "37px",
				maxWidth: "844px",
				marginLeft: "auto",
				marginRight: "auto"
			}}>
				<ContentAdmin
					limit={300}
					keyContent="contentMissionEight"
					keyText="to_face_fears_text"
					defaultValue="Don’t be afraid to fail, embrace it. And if you fail, you can proudly say — you’ve done everything you could."
					position="left"
					isDescription
				/>
			</Box>

			<Box sx={{
				display: "flex",
				justifyContent: "center",
				mt: 5
			}}>
				<CustomButton.Outline
					onClick={nextStep}
					sx={{
						background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)",
						backdropFilter: "blur(30px)",
						color: "#fff",
						borderColor: "#fff",
						minWidth: "325px"
					}}
				>
					Thanks, Octa!
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default ToFaceFears;
