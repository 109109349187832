import { Box, Fade } from "@mui/material";
import useMission from "pages/missionTwo/hooks/useMission";
import ToughDecisionsForm from "./Form";
import ToughDecisionsTemplate from "./Template";

export interface IToughDecisionsFormViewProps
{
	readonly index: number;
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

function ToughDecisionsFormView({ index, preview = false }: IToughDecisionsFormViewProps)
{
	const missionContext = useMission();

	return (
		<ToughDecisionsTemplate>
			<Fade in={missionContext.showContent}>
				<Box sx={{ position: "relative", width: "100%" }}>
					<ToughDecisionsForm index={index} preview={preview} />
				</Box>
			</Fade>
		</ToughDecisionsTemplate>
	);
}

export default ToughDecisionsFormView;
