import CustomButton from "components/customButton";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tour from "reactour";
import { tourConfig } from "./config";
import "./index.css";

interface IMylifequestProductTourProps
{
	isTourOpen: boolean;
	setTourOpen: Dispatch<SetStateAction<boolean>>;

	onClose(): void;
}

export function MylifequestProductTour({ isTourOpen, setTourOpen, onClose }: IMylifequestProductTourProps)
{
	const ACCENT_COLOR = "#5cb7b7";

	const navigate = useNavigate();
	const [ buttonContent, setButtonContent ] = useState<string>("Got it!");
	const [ step, setStep ] = useState<number>(0);
	const [ isRender, setIsRender ] = useState<boolean>(false);

	// Effects
	useLayoutEffect(() =>
	{
		const timerId = setTimeout(() => setIsRender(true), 1000);

		if (isTourOpen === false)
		{
			return;
		}

		switch (step)
		{
			case 0: {
				setButtonContent("View artifact");
				navigate("/dashboard/artifacts");
				break;
			}

			case 1: {
				setButtonContent("Cool!");
				navigate("/dashboard/artifacts/wheel-of-life");
				break;
			}

			case 2: {
				setButtonContent("Hooray!");
				navigate("/dashboard/");
				break;
			}
		}

		return () => clearTimeout(timerId);
	}, [ step, navigate, isTourOpen ]);

	// Handles
	function closeTour()
	{
		setTourOpen(false);
		onClose();
	}

	// Render
	if (isRender === false)
	{
		return null;
	}

	return (
		<Tour
			getCurrentStep={(currentStep) => setStep(currentStep)}
			onRequestClose={closeTour}
			disableInteraction
			steps={tourConfig}
			isOpen={isTourOpen}
			maskClassName="reactour__mask"
			rounded={20}
			accentColor={ACCENT_COLOR}
			lastStepNextButton={<CustomButton.Outline>{buttonContent}</CustomButton.Outline>}
			nextButton={<CustomButton.Outline>{buttonContent}</CustomButton.Outline>}
			showNavigation={false}
			showNumber={false}
			maskSpace={0}
			closeWithMask={false}
		/>
	);
}
