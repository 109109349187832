import { Masonry } from "@mui/lab";
import { Box } from "@mui/material";
import IMGGuideChara from "assets/images/octa2.png";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import MissionsDialog from "components/missions/Dialog";
import useMission from "pages/missionSix/hooks/useMission";
import { forwardRef, Ref, useState } from "react";
import { useReadLocalStorage } from "utils/localStorage";

export type IFinishProps = Record<string, any>;

const Finish = forwardRef((props: IFinishProps, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ isShowModal, setIsShowModal ] = useState<boolean>(false);

	const images = useReadLocalStorage<string[]>("mission-six-selectedImages", []);

	// Handles
	function onFinish()
	{
		setTimeout(() => missionContext.setStep(6), 0);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main" sx={{ maxWidth: "844px" }}>
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Box sx={{ position: "relative" }}>
					<Box
						component="img"
						src={IMGGuideChara}
						alt=""
						sx={{
							width: { xl: "284px", lg: "188px", xs: "239px" },
							transform: { xl: "rotate(22.35deg)", lg: "rotate(19.36deg)", xs: "rotate(22.35deg)" },
							position: "absolute",
							top: { xl: "49px", lg: "22px", xs: "74px" },
							right: { xl: "-40%", lg: "-29%", xs: "-29%" }
						}}
					/>

					<Box sx={{
						position: "relative",
						zIndex: "2",
						backgroundColor: "rgba(87, 171, 217, .5)",
						borderRadius: "24px",
						padding: { xl: "24px 32px 32px 32px", lg: "16px 24px 24px 24px", xs: "16px 24px 24px 24px" },
						boxShadow: "0px 5px 10px rgba(126, 196, 255, 0.5)"
					}}>
						<Box sx={{
							font: { xl: "700 40px/51px Lora", lg: "700 24px/31px Lora", xs: "700 24px/31px Lora" },
							color: "#FFFFFF",
							textAlign: "center",
							marginBottom: { xl: "70px", lg: "16px", xs: "16px" }
						}}>
							<ContentAdmin keyContent="contentMissionSix" keyText="finish_box_title" defaultValue="{NAME}’s\nVision Board" />
						</Box>

						<Box
							sx={{
								"--gutter": "7px",
								margin: "0 auto",
								maxWidth: "314px",
								width: "max-content",
								maxHeight: " 216px",
								overflow: "hidden",
								borderRadius: "24px",
								border: "1px solid #4D4D4D",
								backgroundColor: "#FFFFFF",
								cursor: "pointer",

								"& .MuiMasonry-root":
								{
									alignContent: "center",
									// justifyContent: "center",
									margin: "calc(0px - (var(--gutter) / 2))",
									// marginLeft: "-50px",
									// marginRight: "-50px",
									width: "auto"
								},
								"& .MuiMasonry-root > *":
								{
									borderRadius: "15px",
									margin: "calc(var(--gutter) / 2)"
								}
							}}
							onClick={() => setIsShowModal(true)}
						>
							<Masonry spacing="7px" columns={3}>
								{images.map((image, index) => (
									<Box key={index + image} component="img" src={image} alt="" />
								))}
							</Masonry>
						</Box>
					</Box>
				</Box>

				<Box sx={{
					width: { xl: "844px", lg: "598px", xs: "844px" },
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "24px"
				}}>
					<Box sx={{
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 40px/51px Lora-Bold" },
						textAlign: "center",
						color: "primary.main"
					}}>
						<ContentAdmin keyContent="contentMissionSix" keyText="finish_title" defaultValue="What a great life, {NAME}!" position="left" isDescription />
					</Box>

					<Box sx={{
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" },
						textAlign: "center",
						color: "primary.main",
						textShadow: "0px 0px 15px #FFFFFF"
					}}>
						<ContentAdmin keyContent="contentMissionSix" keyText="finish_text" defaultValue="Congratulations on reaching an important milestone on your journey to your dream life. It takes courage to admit who you really are and discover what you really want. You have laid an important foundation for living a fulfilling life." position="left" isDescription />
					</Box>

					<CustomButton.Contained onClick={onFinish}>Finish</CustomButton.Contained>
				</Box>

			</Box>

			<MissionsDialog
				title="My Vision Board"
				open={isShowModal}
				onClose={() => setIsShowModal(false)}
				sx={{
					"& .MuiPaper-root":
					{
						padding: "30px",
						paddingRight: "28px"
					}
				}}
			>
				<Box
					className="customScroll"
					sx={{
						"--gutter": "7px",
						margin: "0 auto",
						maxWidth: "600x",
						width: "max-content",
						maxHeight: "500px",
						overflowY: "auto",
						borderRadius: "15px",
						padding: "0 calc(var(--gutter) * 3) 0 var(--gutter)",

						"& .MuiMasonry-root":
						{
							alignContent: "center",
							width: "auto",
							marginBottom: "0px"
						},
						"& .MuiMasonry-root > *":
						{
							borderRadius: "15px",
							margin: "calc(var(--gutter) / 2)"
						}
					}}
				>
					<Masonry spacing="7px" columns={3}>
						{[ ...images ].map((image, index) => (
							<Box key={index + image} component="img" src={image} alt="" />
						))}
					</Masonry>
				</Box>
			</MissionsDialog>
		</Box>
	);
});

export default Finish;
