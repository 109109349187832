import { Box } from "@mui/material";
import CustomButton from "components/customButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { getLocalStorage } from "utils/localStorage";
import screen from "../../../assets/images/Screen.png";
import CareerDetails from "./itemsDetails/Career";
import FriendsDetails from "./itemsDetails/Friends";
import FunDetails from "./itemsDetails/Fun";
import GrowthDetails from "./itemsDetails/Growth";
import HealthDetails from "./itemsDetails/Health";
import LoveDetails from "./itemsDetails/Love";
import MoneyDetails from "./itemsDetails/Money";
import PhisycalhDetails from "./itemsDetails/Phisycal";

function DetailsItem({ details })
{
	const graph = getLocalStorage("graph");
	const location = useLocation();
	const navigate = useNavigate();

	function renderComponent(details)
	{
		switch (details)
		{
			case "Health":
				return <HealthDetails arr={graph} />;
			case "Friends":
				return <FriendsDetails arr={graph} />;
			case "Love":
				return <LoveDetails arr={graph} />;
			case "Career":
				return <CareerDetails arr={graph} />;
			case "Money":
				return <MoneyDetails arr={graph} />;
			case "Physical environment":
				return <PhisycalhDetails arr={graph} />;
			case "Fun":
				return <FunDetails arr={graph} />;
			case "Growth":
				return <GrowthDetails arr={graph} />;
			default:
				return null;
		}
	}

	function onClickContinue()
	{
		if (getLocalStorage("registration-name"))
		{
			navigate("/registration/complete");
		}
		else
		{
			navigate("/dashboard");
		}
	}

	return (
		<Box pb={6}>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Box sx={{ width: { md: "99%", xl: "96%" }, display: "flex", margin: { xs: 0, lg: "0 auto", xl: 0 } }}>
					<Box className="displayImageDetails">
						<img src={screen} alt="" />
					</Box>
					<Box
						id="details"
						className="paddingDetails heightetails"
						sx={{
							// background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							width: "100%",
							p: { lg: "0 50px 50px 50px", xl: "0 50px  0 50px" },
							borderRadius: "0 0 60px 0",
							height: "1003px",
							position: "relative"
							// boxShadow: '1px 4px 100px 100px rgba(126, 296, 255, 0.1) inset'
						}}
					>
						<Box sx={{ position: "absolute", top: "65px", right: "65px", zIndex: 9999 }}>
							<Link to="topAncor" spy smooth duration={500}>
								<Box sx={{ width: "218px", border: "2px solid #215C75", color: "#215C75", font: "700 24px/33px 'Open Sans'", p: "12px 80px", background: "rgba(255, 255, 255, 0.5)", borderRadius: "40px", cursor: "pointer" }}>Back</Box>
							</Link>
						</Box>
						{renderComponent(details)}
						{location.pathname.split("/")[ 1 ] !== "artifact" &&
							(
								<Box mt={2} pb={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
									<Box sx={{ width: "382px" }}>
										<CustomButton.Contained onClick={() => onClickContinue()} sx={{ width: "100%" }}>
											Continue
										</CustomButton.Contained>
									</Box>
								</Box>
							)
						}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default DetailsItem;
