import { Box } from "@mui/material";
import Api from "api";
import { IDataMissionTen } from "api/Missions";
import Form from "components/Form";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import { useEffect, useState } from "react";
import { UserActionType } from "reducers/userReducer";
import trackEvent from "utils/mixpanelUtil";
import ArrowButton from "../../../components/ArrowButton";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../components/missions/StepperNavigationWrapper";
import useMission from "../hooks";
import Details from "./experimentsTracker/Details";
import ProgressStep from "./experimentsTracker/progressStep";

export interface ValuesFlies
{
	id: number;
	success: string;
	title: string;
	test: string;
	run: ValuesRun[];
	active: boolean;
	keyInsights: string;
	like: boolean | null;
	decision: string;
	countChecked: number;
}
export interface ValuesRun
{
	id: number;
	value: string;
	checked: boolean;
}
interface LocalRun
{
	id: number;
	value: string;
}

interface LocalFlies
{
	success: string;
	title: string;
	test: string;
	run: LocalRun[];
}
function ExperimentsTracker()
{
	// States
	const missionContext = useMission();
	const [ valuesFlies, setValuesFlies ] = useState<ValuesFlies[]>([]);
	const [ step, setStep ] = useState<number>(0);
	const [ valueName, setValueName ] = useState("");
	const { state: userState, dispatch: userDispatch } = useUser();
	const [ componentInputs, setComponentInputs ] = useState<any>(
		{
			test: "",
			run: [
				{
					id: 1,
					value: "",
					checked: false
				}
			],
			success: ""
		}

	);

	function handleNextStep(title: string)
	{
		const newValuesFlies = [ ...valuesFlies ];

		newValuesFlies.forEach(item =>
		{
			if (item.title === title)
			{
				item.active = true;
			}

			else
			{
				item.active = false;
			}
		});
		setStep(pre => pre + 1);
	}

	// Handles
	function onClickNextStep()
	{
		const data: IDataMissionTen[] = valuesFlies.map((
			{
				decision, success: definitionSuccess, run, keyInsights, like, test: whatTesting, title
			}
		) =>
		{
			return {
				title,
				decision,
				definitionSuccess,
				experiments: run.map(({ value, checked }) =>
				{
					return { value, checked };
				}),
				keyInsights,
				like,
				whatTesting
			};
		});

		Api.missions
			.setDataMissionTen(data)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(10) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 10 }
					}
				);
				if (userState.user?.completedMission === 9)
				{
					missionContext.setFirstPlaythrough(true);
				}

				localStorage.setItem("mission-ten-experiments-tracker", JSON.stringify(valuesFlies));
				missionContext.setStep((prevStep) => prevStep + 1);
			})
			.catch((error) => console.error(error));

	}

	useEffect(() =>
	{
		if (localStorage.getItem("fly-values") !== null)
		{
			const arrValues: ValuesFlies[] = [];

			JSON.parse(`${ localStorage.getItem("fly-values") }`).forEach((element: LocalFlies, index: number) =>
			{
				const arrRun: ValuesRun[] = [];

				element.run.forEach((runItem: LocalRun, indexRun: number) =>
				{
					arrRun.push({
						id: indexRun,
						value: runItem.value,
						checked: false
					});
				});
				arrValues.push(
					{
						id: index,
						success: element.success,
						title: element.title,
						test: element.test,
						run: arrRun,
						active: index === 0,
						keyInsights: "",
						like: null,
						decision: "",
						countChecked: 0
					}
				);
			});
			setValuesFlies(arrValues);
		}
	}, []);

	function handleActiveValue(id: number)
	{
		setValuesFlies(valuesFlies.map((item: ValuesFlies) =>
		{
			if (item.id === id)
			{
				return { ...item, active: true };
			}

			return { ...item, active: false };

		}));
	}

	function handleChecked(idValuesItem: number, idRun: number)
	{
		const newValuesFlies = [ ...valuesFlies ];

		newValuesFlies.forEach(item =>
		{
			if (item.id === idValuesItem)
			{
				item.run.forEach(itemRun =>
				{
					if (itemRun.id === idRun)
					{
						if (itemRun.checked)
						{
							item.countChecked -= 1;
							itemRun.checked = false;
						}

						else
						{
							item.countChecked += 1;
							itemRun.checked = true;
						}
					}

				});
			}
		});

		setValuesFlies(newValuesFlies);
	}

	function handleKeyInsights(value: string, idValuesItem: number)
	{
		setValuesFlies(valuesFlies.map((item: ValuesFlies) =>
		{
			if (item.id === idValuesItem)
			{
				return { ...item, keyInsights: value };
			}

			return item;

		}));
	}

	function handleKeyDecision(value: string, idValuesItem: number)
	{
		setValuesFlies(valuesFlies.map((item: ValuesFlies) =>
		{
			if (item.id === idValuesItem)
			{
				return { ...item, decision: value };
			}

			return item;

		}));
	}

	function handleLikeTrue(idValuesItem: number)
	{
		setValuesFlies(valuesFlies.map((item: ValuesFlies) =>
		{
			if (item.id === idValuesItem)
			{
				return { ...item, like: true };
			}

			return item;

		}));
	}

	function handleLikeFalse(idValuesItem: number)
	{
		setValuesFlies(valuesFlies.map((item: ValuesFlies) =>
		{
			if (item.id === idValuesItem)
			{
				return { ...item, like: false };
			}

			return item;

		}));
	}

	function handleChangeArrInputs(value: string, indexArr: number, indexItem: string)
	{
		const newcomponentInputs = { ...componentInputs };
		if (indexItem === "test") { newcomponentInputs.test = value; }

		if (indexItem === "success") { newcomponentInputs.success = value; }

		if (indexItem === "run") { newcomponentInputs.run[ indexArr ].value = value; }

		setComponentInputs(newcomponentInputs);
	}

	function onSubmit()
	{
		// debugger;
		const newflies = [ ...valuesFlies ];

		newflies.push({
			id: newflies.length + 1,
			success: componentInputs.success,
			title: valueName,
			test: componentInputs.test,
			run: componentInputs.run,
			active: false,
			keyInsights: "",
			like: null,
			decision: "",
			countChecked: 0
		});
		setValuesFlies(newflies);
		setComponentInputs({
			test: "",
			run: [
				{
					id: 1,
					value: "",
					checked: false
				}
			],
			success: ""
		});
		setValueName("");
		setStep(0);
	}

	function addInput()
	{
		const newcomponentInputs = { ...componentInputs };
		let newItem = {
			id: componentInputs.run.length + 1,
			value: "",
			checked: false
		};

		newcomponentInputs.run.push(newItem);
		setComponentInputs(newcomponentInputs);
	}

	function closeForm()
	{
		return setStep(0);
	}

	const renderStep = [
		<ProgressStep valuesFlies={valuesFlies} handleNextStep={handleNextStep} setStep={setStep} />,
		<Details
			valuesFlies={valuesFlies}
			handleActiveValue={handleActiveValue}
			handleLikeFalse={handleLikeFalse}
			handleLikeTrue={handleLikeTrue}
			handleChecked={handleChecked}
			handleKeyInsights={handleKeyInsights}
			handleKeyDecision={handleKeyDecision}
			setStep={setStep}
		/>,
		<Form
			sx={{ m: { lg: "0 0 0 50px", xl: "0 auto" } }}
			componentInputs={componentInputs}
			handleChangeArrInputs={handleChangeArrInputs}
			onSubmit={onSubmit}
			closeForm={closeForm}
			addInput={addInput}
			valueName={valueName}
			setValueName={setValueName} />
	];

	return (
		<>
			<Box sx={{
				height: "76.5vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "970px" },
				m: "0 auto",
				position: "relative",
				top: "-12px",
				left: "-30px"

			}}>
				<PanelTitle
					imageVariation="closeEye"
					positionOcta
					title={<ContentAdmin
						limit={100}
						keyContent="contentMissionTen"
						keyText="experimentsTracker_title"
						defaultValue="Here is your experiments tracker"
						position="right" />}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="experimentsTracker_txt"
						defaultValue="{NAME}, this is where you can keep track of all your experiments, add insights, make decisions and add new experiments to run. You can use it in the future too when you feel stuck again."
						position="right"
						isDescription
					/>}
				/>
				{renderStep[ step ]}
			</Box>
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					onClick={onClickNextStep} />
			</StepperNavigationWrapper>

		</>
	);
}

export default ExperimentsTracker;
