import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Api from "api";
import { default as IMGLISTITEM, default as IMGSRC } from "assets/images/icons/ball-list.svg";
import IMGBg from "assets/images/missions/mission-eight/bigBall.png";
import RadialButtonBall from "components/RadialButtonBall";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFears } from "..";

const items = [
	{
		id: 1,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="emergency_plan"
			defaultValue="Emergency plan"
			position="left"
		/>
	},
	{
		id: 2,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="immediate_emergency_plan"
			defaultValue="Immediate action Plan"
			position="left"
		/>
	}
];

interface FinishCreateProps
{
	fears: IFears[];
}
function FinishCreate({ fears }: FinishCreateProps)
{

	const navigate = useNavigate();
	const [ selectedButton, setSelectedButton ] = useState(1);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ open, setOpen ] = useState(false);

	function onOpenButtonTwo()
	{
		setOpen(true);
		setSelectedButton(2);
	}

	function onOpenButtonOne()
	{
		setOpen(true);
		setSelectedButton(1);
	}

	function saveArtefact()
	{
		setIsLoading(true);
		Api.missions
			.setDataMissionEight(fears, true)
			.then(() =>
			{
				setIsLoading(false);
				setTimeout(() => navigate("/dashboard/artifacts/fears"), 0);
			})
			.catch((error: any) =>
			{
				console.error(error);
			});
	}

	return (
		<Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "relative", mt: "-30px" }}>
			<Box>
				<Box sx={{
					position: "relative",
					width: "615px",
					height: "615px",
					backgroundImage: `url(${ IMGBg })`,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					boxShadow: "0px 0 35px 7px rgb(255 255 255 / 66%)",
					borderRadius: "50%",
					transform: { lg: "scale(0.8)", xl: "scale(1)" },
					mt: { lg: "-20px", xl: 0 }
				}}>
					<>
						<Box sx={{
							position: "absolute", top: "354px", right: "160px"
						}}>
							<RadialButtonBall
								sx={{ backdropFilter: "none" }}
								imgRadialButton={IMGSRC}
								direction="left"
								value="Emergency plan"
								color="blue"
								absoluteValuePosition
								onClick={onOpenButtonOne}
							/>
						</Box>
						<Box sx={{ position: "absolute", top: "214px", right: "399px" }}>
							<RadialButtonBall
								sx={{ backdropFilter: "none" }}
								imgRadialButton={IMGSRC}
								value="Immediate action plan"
								color="blue"
								absoluteValuePosition
								onClick={onOpenButtonTwo}
							/>
						</Box>
					</>
					{open
						? (
							<Box sx={{
								position: "absolute",
								zIndex: "10",
								top: "0px",
								width: "709px",
								right: selectedButton === 1 ? "300px" : "-400px"
							}}>
								<Box sx={{
									display: "flex",
									width: "709px",
									maxWidth: "709px",
									height: "616px",
									border: "1px solid rgba(255, 255, 255, .6)",
									flexDirection: "column",
									background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
									backdropFilter: "blur(25px)",
									boxShadow: "0px 5px 10px 0px #A7C7E199",
									position: "relative",
									borderTopRightRadius: (selectedButton === 1 ? "0px" : "24px"),
									borderBottomRightRadius: (selectedButton === 1 ? "0px" : "24px"),
									borderTopLeftRadius: (selectedButton === 1 ? "24px" : "0px"),
									borderBottomLeftRadius: (selectedButton === 1 ? "24px" : "0px")
								}}>
									<Box
										sx={{
											padding: "31px 24px 0px 17px",
											position: "relative"
										}}>
										<Box
											sx={{
												position: "absolute",
												top: "30px",
												right: "40px",
												zIndex: "10"
											}}
											onClick={() => setOpen(false)}>
											<CloseIcon
												sx={{
													fill: "#fff",
													fontSize: "14px",
													backgroundColor: "#3E809D",
													borderRadius: "40px",
													width: "24px",
													height: "24px",
													p: "3px",
													cursor: "pointer"
												}} />
										</Box>
										<Typography sx={{
											marginBottom: "25px",
											color: "#215C75",
											font: {
												xs: '700 20px/21px "Lora"',
												md: '700 40px/51px "Lora"'
											},
											textAlign: "left",
											marginLeft: "30px",
											wordBreak: "break-word"

										}}>
											{items[ selectedButton - 1 ].title}
										</Typography>
										<Box
											className="scrollCustom"
											sx={{
												overflowY: "scroll",
												overflowX: "hidden",
												paddingLeft: "25px",
												maxHeight: "450px",
												width: "calc(100% - 26px)"
											}}>
											<List sx={{ padding: "0px" }}>
												{fears
													? fears.map((element: any, index: number) => (
														<ListItem key={index} sx={{ alignItems: "flex-start", marginBottom: "8px", marginLeft: "-28px", padding: "0px" }}>
															<ListItemIcon>
																<Box sx={{
																	width: "80px",
																	height: "80px",
																	backgroundImage: `url(${ IMGLISTITEM })`,
																	backgroundPosition: "center",
																	backgroundRepeat: "no-repeat"
																}} />
															</ListItemIcon>
															{selectedButton === 2 &&
																<ListItemText
																	primary={
																		<Typography
																			sx={{
																				color: "#215C75",
																				font: {
																					xs: '700 18px/18px "Lora"',
																					md: '700 24px/31px "Lora"'
																				},
																				marginBottom: "8px",
																				wordBreak: "break-word"
																			}}
																			component="div"
																		>
																			{element.title}
																		</Typography>

																	}
																	secondary={
																		<Typography
																			sx={{
																				color: "#4D4D4D",
																				font: {
																					xs: '400 18px/18px "Lora"',
																					md: '400 22px/30px "Lora"'
																				}
																			}}
																			component="div"
																		>{element.prevent}
																		</Typography>
																	}
																/>}
															{(selectedButton === 1) && <ListItemText
																primary={
																	<Typography
																		sx={{ color: "#215C75", font: '700 24px/31px "Lora"', marginBottom: "8px" }}
																		component="div"
																	>{element.title}
																	</Typography>

																}
																secondary={
																	<Typography
																		sx={{ color: "#4D4D4D", font: '400 22px/30px "Open Sans"' }}
																		component="div"
																	>{element.repair}
																	</Typography>

																}
															/>}
														</ListItem>
													))
													: null}
											</List>
										</Box>
									</Box>
								</Box>
							</Box>
						)
						: null
					}
				</Box>
				<Box sx={{
					position: "absolute",
					bottom: { lg: "-10px", xl: "-40px" },
					left: { lg: "475px", xl: "716px" }
				}}>
					<CustomButton.Outline
						onClick={saveArtefact}
						sx={{
							fontSize: "24px",
							lineHeight: "33px",
							background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)",
							backdropFilter: "blur(25px)",
							color: "#fff !important",
							borderColor: "#fff",
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
							"&:hover": {
								cursor: "pointer",
								background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)"
							}
						}}
					>{isLoading ? <CircularProgress /> : "Save"}
					</CustomButton.Outline>
				</Box>
			</Box>
		</Box>
	);
}

export default FinishCreate;
