import { Box, alpha } from "@mui/material";
import IMGOcta from "assets/images/octa2-shrink.png";
import CustomButton from "components/customButton";
import useMission from "pages/missionTwo/hooks/useMission";
import Tour from "reactour";

function PauseAndResume()
{
	const missionContext = useMission();

	// Render
	return (
		<Tour
			isOpen
			rounded={20}
			maskSpace={5}
			showCloseButton={false}
			showNavigation={false}
			showNumber={false}
			disableInteraction
			closeWithMask={false}
			lastStepNextButton={<CustomButton.Outline>Got it!</CustomButton.Outline>}
			maskClassName="reactour__mask"
			steps={[
				{
					selector: "",
					position: "bottom",
					style: { top: "-100px" }, // left: "300px",
					content()
					{
						return (
							<Box sx={{
								position: "relative",
								transform: { xl: "translateX(0)", lg: "translateX(250px)", xs: "translateX(-75px)" },

								"& + div":
								{
									transform: { xl: "translateX(0)", lg: "translateX(250px)", xs: "translateX(-75px)" }
								}
							}}>
								<Box
									component="img"
									src={IMGOcta}
									sx={{
										position: "absolute",
										left: 0,
										top: { xl: "-60px", lg: "-90px", xs: "-90px" },
										transform: "rotate(-9.11deg) translateX(-110%)",
										width: { xl: "148px", lg: "117px", xs: "117px" }
									}}
								/>

								<Box sx={{
									width: { xl: "375px", lg: "286px", xs: "286px" },
									background: "#E6F4F6",
									boxShadow: "0px 3px 6px " + alpha("#000000", 0.5),
									border: "1px solid " + alpha("#FFFFFF", 0.5),
									borderRadius: "16px",
									padding: "24px",
									font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
									color: "primary.dark"
								}}>
									You can always pause and resume the quest whenever you want. We will save your progress.
								</Box>
							</Box>
						);
					}
				}
			]}
			onRequestClose={() => missionContext.nextStep()}
		/>
	);
}

export default PauseAndResume;
