import CheckIcon from "@mui/icons-material/Check";
import { Box, CircularProgress, Typography } from "@mui/material";
import LinkMI from "@mui/material/Link";
import Api from "api";
import type { ResGetCoupons } from "api/Coupons";
import bgPackages from "assets/images/bgPackages.png";
import heart from "assets/images/heart.svg";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import octa from "assets/images/octaDetails.svg";
import UnsubscribeModal from "components/Dialog/Unsubscribe.modal";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import DialogStripe from "pages/dashboard/missions/DialogStripe";
import { MouseEvent, ReactNode, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link, useSearchParams } from "react-router-dom";
import trackEvent from "utils/mixpanelUtil";
import DialogDetailsPackage from "./components/DialogDetailsPackage";
import DialogResultPayment from "./components/DialogResultPayment";

export interface PackagesObj
{
	title: any;
	description?: string;
	subTitle: SubTitle[];
	price: number;
	idProduct?: string;
	details?: Details;
	id: number;
}
interface Details
{
	title: string;
	description?: string;
	subTitle: ReactNode[];
}
interface SubTitle
{
	title: string;
	active: boolean;
}

export interface Prices
{
	sub: number;
	full: number;
}
function Packages()
{
	const userContext = useUser();
	const [ URLSearchParams ] = useSearchParams();

	const [ packages, setPackages ] = useState<PackagesObj[]>([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ activeItem, setActiveItem ] = useState<PackagesObj>();
	const [ open, setOpen ] = useState(false);
	const [ stripeStatus, setStripeStatus ] = useState(false);
	const [ openDialogDetails, setOpenDialogDetails ] = useState(false);
	const [ isShowModalUnsubscribe, setIsShowModalUnsubscribe ] = useState(false);
	const [ coupon, setCoupon ] = useState<ResGetCoupons>();
	const [ prices, setPrices ] = useState<Prices>();

	function percentPrice(value: number): number
	{
		if (coupon)
		{
			const percentPrice = value - value * (coupon.percentOff / 100);

			return +percentPrice.toFixed(2);
		}

		return value;

	}

	function formatPrice(value: number): string
	{
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	useEffect(() =>
	{
		if (coupon && prices)
		{
			setPackages(packages.map((item) =>
			{
				if (item.idProduct === "")
				{
					return item;
				}
				else if (
					item.idProduct === "subscription"
				)
				{
					return { ...item, price: percentPrice(prices.sub) };
				}

				return { ...item, price: percentPrice(prices.full) };

			}));
			activeItem &&
				setActiveItem({
					...activeItem,
					price: activeItem.idProduct === "" ? activeItem.price : activeItem.idProduct === "subscription" ? percentPrice(prices.sub) : percentPrice(prices.full)
				});
		}
	}, [ coupon ]);

	// Effects
	useEffect(() =>
	{
		Api.admin
			.getPackages()
			.then((res) =>
			{
				setPrices({
					sub: res[ 0 ].monthly,
					full: res[ 0 ].yearly
				});
				setPackages([
					{
						id: 1,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_1"
							defaultValue="Free\nTrial"
							position="top"
						/>,
						subTitle: [
							{
								title: "Full access to the first 2 missions",
								active: true
							},
							{
								title: "Assess your life & design your perfect day",
								active: true
							},
							{
								title: "Get personalised recommendations",
								active: true
							}
						],
						price: res[ 0 ].decideLater,
						idProduct: "",
						details: {
							title: "Free Trial",
							description: "Start Your Quest: Try 2 Missions for Free!",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_1"
									defaultValue="Enjoy the first 2 missions with no strings attached"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_2"
									defaultValue="Save & view unlimited number of Wheel of Life results"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_3"
									defaultValue="Save, view, update your personal values"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_4"
									defaultValue="Unlimited repeats of the Wheel of Life exercise"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_1_5"
									defaultValue="Check out our other packages for access to the full quest"
									position="top"
									isDescription
								/>
							]
						}
					},
					{
						id: 2,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_2"
							defaultValue="Monthly Subscription"
							position="top"
						/>,
						description: "",
						subTitle: [
							{
								title: "Access the full quest for as long as you want",
								active: true
							},
							{
								title: "Discover who you are and  design your best life",
								active: true
							},
							{
								title: "Cancel any time",
								active: true
							}
						],
						price: res[ 0 ].monthly,
						idProduct: "subscription",
						details: {
							title: "Monthly Subscription",
							description: "Flexible and affordable access to the full quest",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_2_1"
									defaultValue="Enjoy the quest for as long as you want &\ncancel any time"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_2_2"
									defaultValue="Have unlimited access to all 10 missions"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_2_3"
									defaultValue="Save and view your results"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_2_4"
									defaultValue="Track and update your progress"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_2_5"
									defaultValue="Repeat your favourite exercises as much as you want"
									position="top"
									isDescription
								/>
							]
						}
					},
					{
						id: 3,
						title: <ContentAdmin
							limit={111}
							keyContent="contentDashboard"
							keyText="subscription_title_3"
							defaultValue="Lifetime Access"
							position="top"
						/>,
						subTitle: [
							{
								title: "Unlock the quest forever",
								active: true
							},
							{
								title: "Lifelong strategic  life planning companion",
								active: true
							},
							{
								title: "Money back guarantee",
								active: true
							}
						],
						price: res[ 0 ].yearly,
						idProduct: "full",
						details: {
							title: "Lifetime Access",
							description: "Unlock the quest forever with a one-time investment",
							subTitle: [
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_1"
									defaultValue="Unlimited lifetime access to the quest & all the tools"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_2"
									defaultValue="Make the quest your lifelong strategic life planning\n tool"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_3"
									defaultValue="Repeat your favourite exercises, view progress and\n update as you go"
									position="top"
									isDescription
								/>,
								<ContentAdmin
									limit={300}
									keyContent="contentDashboard"
									keyText="subscription_details_3_4"
									defaultValue="Never miss out on future updates and additions to\n the quest"
									position="top"
									isDescription
								/>
							]
						}
					}
					// {
					// 	id: 4,
					// 	title: <ContentAdmin
					// 		limit={111}
					// 		keyContent="contentDashboard"
					// 		keyText="subscription_title_4"
					// 		defaultValue="Personalised Guidance"
					// 		position="top"
					// 	/>,
					// 	subTitle: [
					// 		{
					// 			title: "Full 10 missions",
					// 			active: true
					// 		},
					// 		{
					// 			title: "Saved results",
					// 			active: true
					// 		},
					// 		{
					// 			title: "Access to trackers ",
					// 			active: true
					// 		},
					// 		{
					// 			title: "Ability to repeat exercises ",
					// 			active: true
					// 		},
					// 		{
					// 			title: "10 sessions with life design coach",
					// 			active: true
					// 		}
					// 	],
					// 	price: res[ 0 ].lifetime,
					// 	idProduct: "",
					// 	details: {
					// 		title: "Personalised Guidance",
					// 		description: "Apply now for a chance to work directly with Natalya",
					// 		subTitle: [
					// 			<ContentAdmin
					// 				limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_4_1"
					// 				defaultValue="Lifetime access to the quest and all the tools"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			<ContentAdmin
					// 				limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_4_2"
					// 				defaultValue="10 individual coaching sessions with Natalya,\n founder & author of My Life Quest"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			<ContentAdmin
					// 				limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_4_3"
					// 				defaultValue="Personalised guidance, additional exercises &\n homework recommended just for you"
					// 				position="top"
					// 				isDescription
					// 			/>,
					// 			<ContentAdmin
					// 				limit={300}
					// 				keyContent="contentDashboard"
					// 				keyText="subscription_details_4_4"
					// 				defaultValue="Natalya only works with individuals on a case-by-case\n basis, serving a maximum of 12 clients per year to\n maintain a 100% success rate"
					// 				position="top"
					// 				isDescription
					// 			/>
					// 		]
					// 	}
					// }
				]);
				setIsLoading(false);
			});

		if (URLSearchParams.get("status") !== "success")
		{
			return;
		}

		setStripeStatus(true);

		Api.users
			.getStripeData()
			.then((data) =>
			{
				if (data.length === 0)
				{
					return;
				}

				trackEvent({
					eventName: "Purchase Success",
					userId: userContext.state.user?.id,
					properties: { transactionId: data[ data.length - 1 ].transaction_id }
				});
				console.log("ReactGA start");

				ReactGA.ga(
					"event",
					"conversion",
					{
						send_to: process.env.REACT_APP_GA_TRACKING_CONVERSATION_ID + "/cIOtCNPX9K8YEJu-iOsp",
						transaction_id: data[ data.length - 1 ].transaction_id
					}
				);

				console.log("ReactGA end");
			});
	}, [ URLSearchParams ]);

	useEffect(() =>
	{
		trackEvent({
			eventName: "Paywall Viewed",
			userId: userContext.state.user?.id
		});
	}, []);

	// Utils

	// Handles
	function handleClick(item: PackagesObj)
	{
		return (event: MouseEvent<HTMLButtonElement>) =>
		{
			event.stopPropagation();

			if (item.id === 1)
			{
				return;
			}

			if (userContext.state.user?.subscriptionIsActive)
			{
				return setIsShowModalUnsubscribe(true);
			}

			setOpenDialogDetails(false);
			if (activeItem?.idProduct)
			{
				Api.transactions
					.byAllMissions({
						id: coupon?.id,
						type: activeItem.idProduct !== "full" ? "sub" : "full"
					})
					.then((data) =>
					{
						trackEvent(
							{
								eventName: "Stripe Checkout Viewed",
								userId: userContext.state.user?.id,
								properties: { Package: item.title }
							},
							() =>
							{
								window.location = data;
							}
						);
					})
					.catch((error) => console.error(error));
			}

		};
	}

	function openDetails(item: PackagesObj)
	{
		setActiveItem(item);
		setOpenDialogDetails(true);

		trackEvent({
			eventName: "Paywall Modal Viewed",
			userId: userContext.state.user?.id,
			properties:
			{
				Package: userContext.state.content[ item.title.props.keyContent ][ item.title.props.keyText ].replaceAll("\\n", " ")
			}
		});
	}

	// Render
	return (
		<>
			<Box sx={{ background: `url(${ bgPackages })`, width: "100%", minHeight: "100vh" }}>
				<Box sx={{
					background: "rgba(64, 90, 183, 0.4)",
					width: "100%",
					minHeight: "100vh",
					backdropFilter: "blur(50px)",
					p: { lg: "47px 76px 0px", xl: "64px 120px 0px", xs: "32px" }
				}}>
					<Box
						component={Link}
						to="/dashboard"
						sx={{
							position: "absolute",
							top: "24px",
							left: "24px",

							display: "block",
							width: { xl: "60px", sm: "48px", xs: "36px" },
							height: { xl: "40px", sm: "32px", xs: "24px" },
							"& svg": { width: "100%", height: "100%" },
							"&:hover svg path": { fillOpacity: 1 }
						}}
					>
						<TooltipStyled title="Back to dashboard" placement="bottom" arrow>
							<SVGArrowBack />
						</TooltipStyled>
					</Box>

					<Box sx={{ maxWidth: { md: "465px", xl: "650px" }, margin: "0 auto", position: "relative" }}>
						<Box sx={{
							font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
							textAlign: "center",
							color: "#fff"
						}}>
							Our packages
						</Box>

						<Box sx={{
							font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
							textAlign: "center",
							color: "#fff",
							marginTop: { sm: "20px", xs: "8px" }
						}}>
							Get access to the full quest and all life management tools with a payment plan that works best for you:
						</Box>

						<Box
							component="img"
							src={octa}
							alt=""
							sx={{
								width: { xl: "382px", md: "312px" },
								position: "absolute",
								top: { xl: "-20px", md: "20px" },
								right: { xl: "-422px", lg: "-322px", md: "-280px" },
								transform: { xl: "rotate(10deg)" },
								display: { md: "unset", xs: "none" }
							}}
						/>
					</Box>

					{isLoading
						? (
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								mt: "145px"
							}}>
								<CircularProgress />
							</Box>
						)
						: (
							<Box sx={{
								marginTop: { sm: "105px", xs: "32px" },
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
								gap: { xl: "80px", xs: "44px 16px" }
							}}>
								{packages.map((item, index) => (
									<Box
										key={index}
										onClick={() => openDetails(item)}
										sx={{
											padding: { xl: "24px 36px 32px", sm: "24px", xs: "16px 24px 24px 16px" },
											minHeight: { xl: "501px", sm: "357px" },
											width: { xl: "384px", md: "274px", xs: "100%" },
											background: index === 1 ? "#fff" : "linear-gradient(259.97deg, rgba(207, 225, 255, 0.55) 7.03%, rgba(223, 223, 223, 0.132) 102.08%)",
											border: "2px solid rgba(255, 255, 255, 0.5)",
											borderRadius: "24px",
											display: "flex",
											justifyContent: "space-between",
											flexDirection: "column",
											color: index === 1 ? "primary.main" : "primary.light",
											cursor: "pointer",
											position: "relative"
										}}
									>
										<div>
											<Box sx={{
												font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
												textAlign: "center"
											}}>
												{item.title}
											</Box>

											<Box
												component="ul"
												sx={{
													margin: "unset",
													marginTop: { xl: "22px", xs: "16px" },
													padding: "unset",

													"& li":
													{
														display: "flex",
														gap: { xl: "10px", xs: "8px" },
														mb: 2
													}
												}}
											>
												{item.subTitle.map((itemSubtitle, indexSubtitle) => (
													<li key={indexSubtitle}>
														<CheckIcon sx={{
															fill: (index === 2 ? "primary.main" : "primary.light"),
															fontSize: { xl: "20px", xs: "14px" },
															mt: { xl: "9px", xs: "8px" },
															opacity: (itemSubtitle.active === true ? 1 : .5)
														}} />

														<Typography sx={{
															font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/32px 'Open Sans'" },
															opacity: (itemSubtitle.active === true ? 1 : .5)
														}}>
															{itemSubtitle.title}
														</Typography>
													</li>
												))}
											</Box>
										</div>

										{index === 1 && userContext.state?.user?.subscriptionIsActive
											? (
												<TooltipStyled title="You have an active subscription" placement="top" arrow>
													<CustomButton.Contained
														disabled={(userContext.state?.user?.subscriptionIsActive)}
														// onClick={handleClick(item)}
														sx={{
															width: "100%",
															marginTop: "24px",
															fontFamily: "Lora-Bold"
														}}
													>
														€{formatPrice(item.price)}
													</CustomButton.Contained>
												</TooltipStyled>
											)
											: (index === 2)
												? (
													<LinkMI
														// onClick={(e) => e.stopPropagation()}
														// href="https://www.yourlifestartup.com/change-your-life-step-by-step"
														sx={{
															textDecoration: "none",
															width: "100%",
															marginTop: "24px"
														}}
													>
														<CustomButton.Contained sx={{
															width: "100%",
															fontFamily: "Lora-Bold",
															fontSize: "32px"
														}}>
															€{formatPrice(item.price)}
														</CustomButton.Contained>
													</LinkMI>
												)
												: (
													<CustomButton.Contained
														disabled={
															(index === 2 && userContext.state.user?.subscriptionIsActive)
															|| ((index === 1 || index === 2) && userContext.state.user?.fullSubscribe)
														}
														// onClick={handleClick(item)}
														sx={{
															width: "100%",
															marginTop: "24px",
															fontFamily: "Lora-Bold",
															fontSize: "32px"
														}}
													>
														{(index === 1 && userContext.state.user?.subscriptionIsActive)
															? "Unsubscribe"
															: "€" + formatPrice(item.price)
														}
													</CustomButton.Contained>
												)
										}

										{index === 1 &&
											<Box sx={{ position: "absolute", top: { xs: "-32px", xl: "-46px" }, left: { xs: "calc(50% - 28px)", xl: "calc(50% - 36px)" } }}>
												<Box sx={{ width: { xs: "56px", xl: "72px" }, height: { xs: "56px", xl: "72px" }, position: "relative", p: "13px", background: "#fff", borderRadius: "50%" }}>
													<Box
														component="img"
														src={heart}
														sx={{ width: { xs: "35px", xl: "45px" }, top: { xs: "13px", xl: "20px" }, position: "absolute", left: { xs: "10px", xl: "13px" } }}
													/>
												</Box>
											</Box>
										}
									</Box>
								))}
							</Box>
						)}
				</Box>
			</Box>

			<DialogStripe
				open={open}
				onClose={() => setOpen(false)}
				handleClick={handleClick}
				amount={10000}
				idSecret={activeItem?.idProduct}
				description={10}
			/>

			<UnsubscribeModal
				open={isShowModalUnsubscribe}
				onClose={() => setIsShowModalUnsubscribe(false)}
			/>

			<DialogDetailsPackage
				setCoupon={setCoupon}
				activeItem={activeItem}
				openDialogDetails={openDialogDetails}
				setOpenDialogDetails={setOpenDialogDetails}
				handleClick={handleClick}
			/>

			<DialogResultPayment
				stripeStatus={stripeStatus}
				setStripeStatus={setStripeStatus}
			/>
		</>
	);
}

export default Packages;
