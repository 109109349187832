import { Box } from "@mui/material";
import { useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import RadialButton from "../../../../components/RadialButton";
import ContentAdmin from "../../../../components/contentAdmin";
import MissionsDialog from "../../../../components/missions/Dialog";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";

function WhatNext()
{
	const missionContext = useMission();
	const [ isOpenButton, setIsOpenButton ] = useState<[ boolean, boolean ]>([ false, false ]);
	const [ openOneDialog, setOpenOneDialog ] = useState(false);
	const [ openTwoDialog, setOpenTwoDialog ] = useState(false);
	const [ open, setOpen ] = useState(
		{
			one: false,
			two: false
		}
	);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean ]>([ true, true ]);

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function handleOpenOneDialog()
	{
		setOpen({
			...open,
			one: true
		});
		setOpenOneDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 0: false })
		));
	}

	function handleOpenTwoDialog()
	{
		setOpen({
			...open,
			two: true
		});
		setOpenTwoDialog(true);
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { 1: false })
		));
	}

	// Render
	return (
		<>
			<Box className="main__content">
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_title" defaultValue="What’s next?" position="right" />}
					subTitle={<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_subTitle" defaultValue="In the next mission, you will get even more clarity and will define what you actually want in life." position="right" isDescription />}
				/>
				{(openOneDialog || openTwoDialog)
					? <Box />
					: (
						<Box sx={[ {
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: "100px",
							flexGrow: 1,
							width: "90%",
							m: "0 auto"
						},
						...[ isOpenButton[ 0 ] === false && { paddingRight: "40px" } ],
						...[ isOpenButton[ 1 ] === false && { paddingLeft: "40px" } ] ]}>
							<Box sx={{ alignSelf: "flex-end" }}>
								<RadialButton
									value="Keep your map up to date"
									onClick={handleOpenOneDialog}
									direction="left"
									blip={disabledButtons[ 0 ] === true}
									showValue={isOpenButton[ 0 ]}
									onMouseEnter={() => setIsOpenButton([ true, false ])}
									onMouseLeave={() => setIsOpenButton([ false, false ])}
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 0 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
								/>
							</Box>
							<Box>
								<RadialButton
									value="More cues in the next mission"
									onClick={handleOpenTwoDialog}
									blip={disabledButtons[ 1 ] === true}
									showValue={isOpenButton[ 1 ]}
									onMouseEnter={() => setIsOpenButton([ false, true ])}
									onMouseLeave={() => setIsOpenButton([ false, false ])}
									sx={{
										"& .RadialButton-value": {
											color: disabledButtons[ 1 ] === false ? "rgba(62, 128, 157, 0.5)" : "#215C75",
											textShadow: "none"
										}
									}}
								/>
							</Box>
						</Box>
					)
				}
			</Box>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton direction="right" onClick={onClickNextStep} disabled={open.one === false || open.two === false} />
			</StepperNavigationWrapper>

			<MissionsDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_modal_1_title" defaultValue="Keep your map up to date" position="left" />}
				open={openOneDialog}
				onClose={() => setOpenOneDialog(false)}
			>
				<p>
					<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_modal_1_body" defaultValue="Mapping your yearnings can be a great tool to structure your thoughts and align with where you are in life at each moment in time.\n\nYour priorities and yearnings will change over time, you will accomplish things, so come back to your map every now and again to update it.\n\nYou will find this exercise in Tools on the Dashboard!" position="left" isDescription />
				</p>
			</MissionsDialog>

			<MissionsDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_modal_2_title" defaultValue="More cues in the next mission!" position="left" />}
				open={openTwoDialog}
				onClose={() => setOpenTwoDialog(false)}
			>
				<p>
					<ContentAdmin keyContent="contentMissionThree" keyText="whatNext_modal_2_body" defaultValue="Your values and your yearnings already gave you an idea of who you are and what matters to you in life.\n\nIn the next mission, you will discover what career would make you the most happy and fulfilled. It will unlock new heights for you!" position="left" isDescription />

				</p>
			</MissionsDialog>
		</>
	);
}

export default WhatNext;
