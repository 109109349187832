import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, FormControl, Slide, alpha } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import { ReactElement, forwardRef, useState } from "react";
import { TransitionProps } from "react-transition-group/Transition";
import { ValidationError } from "yup";

export interface IResetPasswordModalProps
{
	/**
	 * @default false
	 */
	open?: boolean;
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton"): void;
}

function ResetPasswordModal({ open = false, onClose, ...props }: IResetPasswordModalProps & DialogProps)
{
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isMailSent, setIsMailSent ] = useState<boolean>(false);
	const [ email, setEmail ] = useState<string>("");
	const [ emailError, setEmailError ] = useState<string | null>(null);

	// Handles
	function onClickSendMail()
	{
		if (isFetch === true)
		{
			return;
		}

		let error = null;

		try
		{
			config.email.yup().validateSync(email);
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		setEmailError(error);

		if (error !== null)
		{
			return;
		}

		setIsFetch(true);

		Api.auth
			.forgotPassword(email)
			.then(() =>
			{
				setIsMailSent(true);
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	// Render
	return (
		<Dialog
			open={open}
			onClose={(event, reason) =>
			{
				setEmailError(null);
				setEmail("");
				setIsMailSent(false);
				onClose(event, reason);
			}}
			sx={{
				"& .MuiDialog-container":
				{
					alignItems: "flex-end"
				},
				"& .MuiDialog-paper":
				{
					margin: "unset",
					maxWidth: "unset",
					width: "100%",
					height: "276px",
					padding: "24px 32px 40px 32px",
					backgroundColor: "#FFFFFF",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px 24px 0 0"
				},
				"& .MuiBackdrop-root":
				{
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
					backdropFilter: "blur(25px)"
				}
			}}
			TransitionComponent={TransitionSlideUp}
			{...props}
		>
			<CloseIcon
				onClick={() =>
				{
					setEmailError(null);
					setEmail("");
					setIsMailSent(false);
					onClose({}, "closeButton");
				}}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					cursor: "pointer",
					color: "primary.main"
				}}
			/>

			<Box sx={{ font: "700 24px/31px Lora-Bold", color: "primary.main" }}>
				Forgot Password
			</Box>

			{isMailSent === true
				? (
					<Box sx={{ margin: "auto 0", font: "400 16px/20px 'Open Sans'", color: "primary.dark", textAlign: "center" }}>
						If a user with such an email address exists, then an email was sent to this email address. You can close this page.
					</Box>
				)
				: (
					<>
						<FormControl sx={{ margin: "auto 0" }}>
							<CustomInput.Base
								placeholder="Email"
								name="email"
								autoComplete="email"
								onChange={({ target }) =>
								{
									setEmail(target.value.trim());
									setEmailError(null);
								}}
								value={email}
								sx={{
									border: "1px solid #E9EDF0",
									borderRadius: { sm: "10px", xs: "30px" }
								}}
							/>

							<Box sx={{
								position: "absolute",
								left: "15px",
								right: "15px",
								bottom: "-19px",
								textAlign: "center",
								color: "#DC4242",
								font: "400 14px/19px 'Open Sans'"
							}}>
								{emailError}
							</Box>
						</FormControl>

						<CustomButton.Contained
							onClick={onClickSendMail}
							loading={isFetch === true}
							sx={{ marginTop: "auto", width: "100%" }}
						>
							Send Email
						</CustomButton.Contained>
					</>
				)
			}
		</Dialog>
	);
}

const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

export default ResetPasswordModal;
