import { Box, Checkbox, CheckboxProps, SxProps, Theme, alpha, styled } from "@mui/material";
import { ReactComponent as SVGCheck } from "assets/images/icons/size-8/check.svg";
import { ReactNode } from "react";

const BpIcon = styled("span")(
	{
		position: "relative",
		width: "var(--size)",
		height: "var(--size)",
		backgroundColor: alpha("#FFFFFF", 0.5),
		borderRadius: "4px",

		"&.CheckboxStyledWithLabel-checkbox--variant-outlined":
		{
			border: "2px solid #215C75"
		},
		"& svg":
		{
			position: "absolute",
			width: "11px",
			height: "8px",
			top: "4px",
			left: "3px"
		},
		"&.CheckboxStyledWithLabel-checkbox--variant-outlined svg":
		{
			top: "2px",
			left: "1px"
		}
	}
);

const BpCheckedIcon = styled(({ ...props }: Record<string, any>) =>
{
	return (
		<BpIcon {...props}>
			<SVGCheck />
		</BpIcon>
	);
})({});

interface ICheckboxStyled
{
	label: ReactNode;
	/**
	 * @default "contained"
	 */
	variant?: "contained" | "outlined";
	sx?: SxProps<Theme>;
	checkboxProps?: CheckboxProps;
}

function CheckboxStyledWithLabel({ label, variant = "contained", sx = [], checkboxProps = {}, ...props }: ICheckboxStyled)
{
	const { sx: checkboxSx, ...checkBoxProps } = checkboxProps;

	return (
		<Box
			className={"CheckboxStyledWithLabel-label CheckboxStyledWithLabel-label--variant-" + variant}
			component="label"
			sx={[
				{
					"--line-height": "19px",

					display: "flex",
					alignItems: "flex-start",
					gap: "8px",
					font: { sm: "400 16px/19px 'Open Sans'", xs: "400 14px/19px 'Open Sans'" },
					color: "primary.dark",
					cursor: "pointer",

					"& .CheckboxStyledWithLabel-content":
					{
						font: "inherit"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Checkbox
				className="CheckboxStyledWithLabel-root"
				disableRipple
				checkedIcon={
					<BpCheckedIcon
						className={"CheckboxStyledWithLabel-checkbox CheckboxStyledWithLabel-checkbox--checked CheckboxStyledWithLabel-checkbox--variant-" + variant}
					/>
				}
				icon={
					<BpIcon
						className={"CheckboxStyledWithLabel-checkbox CheckboxStyledWithLabel-checkbox--variant-" + variant}
					/>
				}
				sx={[
					{
						"--size": "16px",
						padding: "unset",
						display: "flex",
						marginTop: "calc((var(--line-height) - var(--size)) / 2)",
						width: "var(--size)",
						height: "var(--size)",

						"& .PrivateSwitchBase-input":
						{
							width: "var(--size)",
							height: "var(--size)"
						}
					},
					...Array.isArray(checkboxSx) ? checkboxSx : [ checkboxSx ]
				]}
				{...checkBoxProps}
			/>

			<Box className="CheckboxStyledWithLabel-content">{label}</Box>
		</Box>
	);
}

export default CheckboxStyledWithLabel;
