import { Box } from "@mui/material";
import BallButton from "../../../../components/BallButton";

interface IButtonsPorps
{
	handleSelectedBall(id: number): void;
}

function Buttons({ handleSelectedBall }: IButtonsPorps)
{
	return (
		<Box sx={{ position: "relative", width: "700px", m: "140px auto 0px", transform: { lg: "scale(0.8)", xl: "scale(1)" } }}>
			<Box sx={{ position: "absolute", top: "70px", left: "100px" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(0)} blip />
			</Box>
			<Box sx={{ position: "absolute", top: "-46px", right: "22%" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(1)} blip />
			</Box>
			<Box sx={{ position: "absolute", top: "134px", right: "40%" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(2)} blip />
			</Box>
			<Box sx={{ position: "absolute", top: "370px", left: "255px" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(3)} blip />
			</Box>
			<Box sx={{ position: "absolute", top: "135px", right: "-100px" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(4)} blip />
			</Box>
			<Box sx={{ position: "absolute", top: "335px", right: "65px" }}>
				<BallButton showValue={false} onClick={() => handleSelectedBall(5)} blip />
			</Box>
		</Box>
	);
}

export default Buttons;
