import { Box, CardMedia, Zoom } from "@mui/material";
import Api from "api";
import TooltipStyled from "components/TooltipStyled";
import GetHelpButton from "components/missions/GetHelpButton";
import { Fragment, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowBack from "../../assets/images/missions/mission-eight/arrowBack.svg";
import imgmission9 from "../../assets/images/missions/mission-nine/imgmission9.svg";
import missionNineDefualt from "../../assets/images/missions/mission-nine/missionNineDefualt.svg";
import missionNineLifePlanDay from "../../assets/images/missions/mission-nine/missionNineLifePlanDay.svg";
import missionNineMorningStar from "../../assets/images/missions/mission-nine/missionNineMorningStar.svg";
import missionNineStar from "../../assets/images/missions/mission-nine/missionNineStar.svg";
import IMGMission5 from "../../assets/images/missions/mission-six/mission-five.png";
import IMGMission4 from "../../assets/images/missions/mission-six/mission-four.png";
import IMGMission3 from "../../assets/images/missions/mission-six/mission-three.png";
import IMGMission2 from "../../assets/images/missions/mission-six/mission-two.png";
import MissionProgressBar from "../../components/MissionProgressBar";
import MobileContent from "../../components/MobileContent";
import TipsButton from "../../components/TipsButton";
import ContentAdmin from "../../components/contentAdmin";
import MissionsDialog from "../../components/missions/Dialog";
import useUser from "../../hooks/useUser";
import { setLocalStorage } from "../../utils/localStorage";
import ImageButton from "../missionSix/components/ImageButton";
import { IMissionContextNineMission, MissionCtx } from "./MissionCtx";
import Amazing from "./feature/Amazing";
import AmazingJob from "./feature/AmazingJob";
import AwwThanks from "./feature/AwwThanks";
import ChatAfterUncertaintyCheck from "./feature/ChatAfterUncertaintyCheck";
import ChatPageMissionNine from "./feature/ChatPageMissionNine";
import Complete from "./feature/Complete";
import GuidingLight from "./feature/GuidingLight";
import GuidingLightMorning from "./feature/GuidingLightMorning";
import HeHe from "./feature/HeHe";
import MapContainer from "./feature/MapContainer";
import SetCourseRezult from "./feature/SetCourseRezult/SetCourseRezult";
import TimeToReview from "./feature/TimeToReview";
import YourPlanFinish from "./feature/YourPlanFinish";
import YourPlanStart from "./feature/YourPlanStart";
import ActionPlan from "./feature/actionPlan";
import AreasOfFocuse from "./feature/areasOfFocuse";
import Feedback from "./feature/feedback";
import LifeRoadMap from "./feature/lifeRoadMap";
import ModalMission5Data from "./feature/modalMissionData/MissionFive";
import ModalMission4Data from "./feature/modalMissionData/MissionFour";
import ModalMission9Data from "./feature/modalMissionData/MissionNine";
import ModalMission6Data from "./feature/modalMissionData/MissionSix";
import ModalMission3Data from "./feature/modalMissionData/MissionThree";
import ModalMission2Data from "./feature/modalMissionData/MissionTwo";
import SetCourse from "./feature/setCourse/SetCourse";
import UncertaintyCheck from "./feature/uncertaintyCheck";

export type BackgroundType = Record<"reasonsAvoid" | "withStar" | "morningStar" | "lifePlanDay", string>;

export const Background: BackgroundType =
{
	reasonsAvoid: `url(${ missionNineDefualt }) center center/cover`,
	withStar: `url(${ missionNineStar }) center center/cover`,
	morningStar: `url(${ missionNineMorningStar }) center center/cover`,
	lifePlanDay: `url(${ missionNineLifePlanDay }) center center/cover`
};
const Components = [
	YourPlanStart,
	TimeToReview,
	Amazing,
	GuidingLight,
	SetCourse,
	SetCourseRezult,
	AwwThanks,
	GuidingLightMorning,
	ChatPageMissionNine,
	MapContainer,
	HeHe,
	// CreateSomeOrder,
	// IdeaChecks,
	AreasOfFocuse,
	LifeRoadMap,
	UncertaintyCheck,
	ChatAfterUncertaintyCheck,
	ActionPlan,
	AmazingJob,
	YourPlanFinish,
	Complete,
	Feedback
];

const STEPS = 5;

export const PROGRESS_STEP = 100 / STEPS;
function MissionNineContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ firstPlaythrough, setFirstPlaythrough ] = useState<boolean>(false);
	const [ showContent, setShowContent ] = useState<boolean>(true);
	const [ step, setStep ] = useState<number>(() =>
	{
		const storageStep = localStorage.getItem("mission-nine-step");
		if (storageStep !== null)
		{
			return Number(storageStep);
		}

		return 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("reasonsAvoid");
	const [ name, setName ] = useState<string>("");
	const [ showMissionInfo, setShowMissionInfo ] = useState<null | 2 | 3 | 4 | 5 | 6 | 7>(null);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: ReactNode; position?: "bottom" | "center"; } | null>(null);

	const MissionNineContext: IMissionContextNineMission = useMemo(() =>
	{
		return {
			step,
			setStep,
			progress,
			setProgress,
			background,
			setBackground,
			name,
			setName,
			setShowMissionInfo,
			showContent,
			setShowContent,
			firstPlaythrough,
			setFirstPlaythrough
		};
	}, [ background, name, progress, showContent, step ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		if (userContext.state.user === null)
		{
			return;
		}

		Api.users
			.getUser(userContext.state.user.id)
			.then((user) =>
			{
				setName(user.name);
			});
	}, [ userContext.state.user, userContext.state.user?.id ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(9, step).catch((error) => console.error(error));

		setLocalStorage("mission-nine-step", step);
		setProgress((step - 1) * (100 / (Components.length - 1)));
	}, [ step ]);

	useLayoutEffect(() =>
	{
		// Change background
		if (step > 11)
		{
			setBackground("lifePlanDay");
		}
		else if (step > 6)
		{
			setBackground("morningStar");
		}
		else if (step > 3)
		{
			setBackground("withStar");
		}
		else
		{
			setBackground("reasonsAvoid");
		}

		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 5: { // screen 5 ( 5. The North Star guides the way, but you set the course )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_5"
									defaultValue="**Why:** Creating a personal North Star is a great way to consolidate your self-knowledge in one place. North Star is your go-to artifact for checking in with yourself regularly and staying on track in life.\n\n**Do:** Go through the North Star fields one by one and fill them in to the best of your knowledge. Try not to overthink at this point and refer to the artifacts collected in previous missions if you get stuck.\n\n**Think:** It’s time to think big and set a bold vision for life. Your North Star is not your goal, but an ambition to aspire to. It should be inspiring and empowering. Don’t think too much when filling in the statements and write your answers down on your first inclination. You can always come back to your North Star at a later stage and re-write it if necessary."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 6: { // screen 6 ( 6. My North Star )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_5"
									defaultValue="**Why:** Creating a personal North Star is a great way to consolidate your self-knowledge in one place. North Star is your go-to artifact for checking in with yourself regularly and staying on track in life.\n\n**Do:** Go through the North Star fields one by one and fill them in to the best of your knowledge. Try not to overthink at this point and refer to the artifacts collected in previous missions if you get stuck.\n\n**Think:** It’s time to think big and set a bold vision for life. Your North Star is not your goal, but an ambition to aspire to. It should be inspiring and empowering. Don’t think too much when filling in the statements and write your answers down on your first inclination. You can always come back to your North Star at a later stage and re-write it if necessary."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 10: { // screen 9 ( 9. Let’s come up with LOTS of ideas )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_9"
									defaultValue="**Why:** The purpose of this step is to translate your North Star into real life. This exercise is based on design thinking ideation technique. Coming up with lots of ideas helps you expand your thinking (diverge) before you prioritise the next step (converge).\n\n**Do:** Come up with as many ideas as you can for what you can do in life that will get you closer to your North Star.\n\n**Think:** Follow the rules for ideation. If you get stuck — review your clues, they might help spark imagination."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 12: { // screen 11 ( 11. Let’s create some order )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_11"
									defaultValue="**Why:**  The purpose of this exercise is to narrow down your focus by identifying several common themes that unify all the ideas you came up with in the previous step.\n\n**Do:** Create structure by clustering your ideas. There is no right or wrong way to do it, so do it in a way that makes sense to you. Try not to have too broad or too narrow clusters. You can refer to an example for inspiration.\n\n**Think:** Think of it as a game of mix and match. You’ll notice that many ideas resemble each other — it is a good thing. Try combining them — keep the best parts of some, get rid of the ones that aren’t working, and consolidate your thinking into a few concepts."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 14: { // screen 13 ( 13. Prioritise your areas of focus )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_13"
									defaultValue="**Why:**  This exercise is designed to help you identify your areas of focus based on the impact on each idea on your life and difficulty of implementing it. It’s a technique used in design thinking to plough through a multitude of ideas and narrow down the focus.\n\n**Do:** Place each one of your clusters one by one (idea groups) on the matrix assessing its Impact and difficulty.\n\n**Think:** Remember — this matrix helps you assess relative impact and relative difficulty of each cluster compared to your other clusters"
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 15: { // screen 13 ( 13. Prioritise your areas of focus )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_13"
									defaultValue="**Why:**  This exercise is designed to help you identify your areas of focus based on the impact on each idea on your life and difficulty of implementing it. It’s a technique used in design thinking to plough through a multitude of ideas and narrow down the focus.\n\n**Do:** Place each one of your clusters one by one (idea groups) on the matrix assessing its Impact and difficulty.\n\n**Think:** Remember — this matrix helps you assess relative impact and relative difficulty of each cluster compared to your other clusters"
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 16: { // screen 15 ( 15. Uncertainty check )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionNine"
									keyText="tips_step_15"
									defaultValue="**Why:**  This exercise will help you identify what can be turned into an immediate action plan — your knowns, and what requires further experimentation — your unknowns.\n\n**Do:** Go through all your ideas one by one. Mark your biggest unknowns — ideas that you are not sure how to execute or whether to pursue them.\n\n**Think:** If you know how to execute on an idea or turn it into reality, then it’s a known. But if you have an idea you are not sure how to execute on it or whether it even makes sense in your life, then mark it as unknown."
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			case 18: {
				setBackground("lifePlanDay");
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}
	}, [ step ]);

	useEffect(() =>
	{
		localStorage.setItem("mission-nine-progress", String(progress));
	}, [ progress ]);

	useLayoutEffect(() =>
	{
		const userId = localStorage.getItem("userId");

		if (userId === null)
		{
			return;
		}

		Api.users
			.getUser(userId)
			.then((user) =>
			{
				setName(user.name);
			});
	}, []);

	// Handles
	return (
		<>
			<Box
				sx={{
					width: "100%",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					zIndex: "10",
					transition: "linear 300ms background",
					overflow: "hidden"
				}}
			>
				<Box sx={{
					minHeight: "100vh",
					width: "100%",
					padding: { xl: step === 19 || step === 20 ? 0 : "24px", lg: step === 19 || step === 20 ? 0 : "16px", xs: step === 19 || step === 20 ? 0 : "16px" },
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: ((step === 1 || step === 2) ? "20px" : "0px")
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 19 || step === 20 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							position: "relative",
							display: step === 19 || step === 20 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "10px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px",
							zIndex: 10
							// top: ((step === 5 || step === 6 || step === 10 || step === 12 || step === 14) ? '-27px' : '-11px'),
						}}>
						<Box component={Link} to="/dashboard" sx={{ width: { lg: "48px", xl: "73px" } }}>
							<CardMedia
								component="img"
								width="100%"
								image={arrowBack}
								alt="Paella dish"
							/>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={Components.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {Components.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
							<Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
								{step === 10 &&
									<Box sx={{ display: "flex", width: "100%", gap: "24px", transform: { lg: "scale(0.8)", xl: "scale(1)" }, justifyContent: "flex-end", mr: { lg: "-55px", xl: 0 } }}>
										<Zoom in={step === 10} style={{ transitionDelay: "800ms" }} timeout={1000}>
											<ImageButton onClick={() => setShowMissionInfo(2)} image={IMGMission2} value="Values" size={80} />
										</Zoom>
										<Zoom in={step === 10} style={{ transitionDelay: "700ms" }} timeout={1000}>
											<ImageButton onClick={() => setShowMissionInfo(3)} image={IMGMission3} value="Yearnings" size={80} />
										</Zoom>
										<Zoom in={step === 10} style={{ transitionDelay: "600ms" }} timeout={1000}>
											<ImageButton onClick={() => setShowMissionInfo(4)} image={IMGMission4} value="A Job to Love" size={80} />
										</Zoom>
										<Zoom in={step === 10} style={{ transitionDelay: "600ms" }} timeout={1000}>
											<ImageButton onClick={() => setShowMissionInfo(6)} image={IMGMission5} value="Perfect Day" size={80} />
										</Zoom>
										<Zoom in={step === 10} style={{ transitionDelay: "500ms" }} timeout={1000}>
											<ImageButton onClick={() => setShowMissionInfo(5)} image={imgmission9} value="My North Star" size={80} />
										</Zoom>
									</Box>
								}
								{(step === 5 || step === 6 || step === 10 || step === 12 || step === 14) &&
									<TooltipStyled title="Tips" placement="bottom" arrow>
										<TipsButton
											isActive={isBlipTips}
											blip={false}
											onClick={() => { setIsShowTips(true); setIsBlipTips(false); setShowContent(false); }}
										/>
									</TooltipStyled>
								}
								<MissionProgressBar value={progress} />
							</Box>
						</Box>
					</Box>

					<MissionCtx.Provider value={MissionNineContext}>
						<Box
							className="mission-root"
							sx={{
								width: "100%",
								position: "relative",
								flexGrow: 1,

								"& .TransitionGroup":
								{
									flexGrow: 1,
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									width: "100%"
								},
								"& .mission-main":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								},
								"& .main__content":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									gap: "20px"
								}
							}}
						>
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column"
							}}>
								{Components.map((Component, index) =>
								{
									if (step === index + 1)
									{
										return (
											// <Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
											<Component />
											// </Grow>
										);
									}

									return <Fragment key={index} />;
								})}
							</Box>
						</Box>

						{/* Tips content */}
						{tipsData !== null &&
							(
								<MissionsDialog
									sx={{
										"& .MuiPaper-root": {
											color: "#4D4D4D",
											maxWidth: ((step === 12 || step === 13 || step === 10) ? "980px" : "985px"),
											maxHeight: ((step === 5 || step === 6) ? "550px" : (step === 16 ? "460px" : (step === 12 ? "520px" : "490px"))),
											padding: ((step === 10 || step === 14 || step === 15) ? "53px 56px 24px 56px" : ((step === 12 || step === 16) ? "56px 57px 56px 56px" : ((step === 5 || step === 6) ? "56px 57px 56px 58px" : "50px 50px 30px 30px"))),
											"& p": {
												m: "0px 0px 25px 0px"
											}
										},
										"& .MuiDialog-container": {
											position: "relative",
											top: (step === 10 ? "-21px" : (step === 6 ? "95px" : ((step === 14 || step === 15) ? "-35px" : ((step === 13) ? "45px" : ((step === 16) ? "-10px" : ((step === 12) ? "-5px" : ((step === 5) ? "75px" : "90px"))))))),
											left: (step === 11 ? "20px" : ""),
											right: "auto"
										}
									}}
									sxCancelIcon={{
										fill: "#3E809D",
										opacity: "1",
										top: "30px",
										right: "30px"
									}}
									title={tipsData.title}
									open={isShowTips}
									onClose={() => { setIsShowTips(false); setShowContent(true); }}
									position={tipsData.position ?? "center"}

								>
									{tipsData.content}
								</MissionsDialog>
							)
						}
						<ModalMission2Data isOpen={showMissionInfo === 2} />
						<ModalMission3Data isOpen={showMissionInfo === 3} />
						<ModalMission4Data isOpen={showMissionInfo === 4} />
						<ModalMission9Data isOpen={showMissionInfo === 5} />
						<ModalMission5Data isOpen={showMissionInfo === 6} />
						<ModalMission6Data isOpen={showMissionInfo === 7} />
					</MissionCtx.Provider>
				</Box>
			</Box>
			<MobileContent />
		</>
	);
}

export default MissionNineContainer;
