import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Button, CardMedia, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import checkboxNoActive from "assets/images/missions/mission-ten/checkboxNoActive.svg";
import dislike from "assets/images/missions/mission-ten/dislike.png";
import likeTemMission from "assets/images/missions/mission-ten/likeTemMission.png";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { ChangeEvent } from "react";
import { ValuesFlies, ValuesRun } from "../ExperimentsTracker";

interface DetailsProps
{
	valuesFlies: ValuesFlies[];
	handleActiveValue(id: number): void;
	handleLikeFalse(id: number): void;
	handleLikeTrue(id: number): void;
	handleChecked(id: number, idRun: number): void;
	handleKeyInsights(value: string, id: number): void;
	handleKeyDecision(value: string, id: number): void;
	setStep: any;
}
function Details({
	valuesFlies,
	setStep,
	handleActiveValue,
	handleChecked,
	handleKeyInsights,
	handleKeyDecision,
	handleLikeFalse,
	handleLikeTrue
}: DetailsProps)
{
	// States

	return (
		<Box sx={{
			width: { lg: "663px", xl: "980px" },
			height: { lg: "451px", xl: "654px" },
			background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
			boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
			border: "1px solid rgba(255, 255, 255, 0.5)",
			borderRadius: "24px",
			ml: { lg: 14, xl: 4 },
			backdropFilter: "blur(30px)",
			p: { lg: "8px 24px 24px", xl: "32px 27px" }
		}}>
			{valuesFlies
				.filter((item) => item.active === true)
				.map((item) =>
				{
					return (
						<Box key={item.id}>
							<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
								<Button disabled={item.id === 0} sx={{ minWidth: "10px", p: 0 }}>
									<ArrowBackIosNewOutlinedIcon
										onClick={() => handleActiveValue(item.id - 1)}
										sx={{ fill: "#215C75", fontSize: "26px", cursor: "pointer" }} />
								</Button>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Typography sx={{ color: "#215C75", font: { lg: "700 24px/30px 'Lora-Bold'", xl: "700 40px/51px 'Lora-Bold'" } }}>
										{item.title}
									</Typography>
									{/* <Box sx={{ width: '40px', height: '40px', background: '#fff', borderRadius: '50%', ml: 3, position: 'relative' }}>
											<CircularProgress variant="determinate" value={20} sx={{ color: "#215C75", position: 'absolute', top: 0, left: 0, zIndex: 2 }} />
											<Box sx={{ position: 'absolute', top: 0, left: 0, width: '40px', height: '40px', borderRadius: '50%', border: '4px solid #E9EDF0', zIndex: 1 }}></Box>
										</Box> */}
								</Box>
								<Button disabled={item.id + 1 === valuesFlies.length} sx={{ minWidth: "10px", p: 0 }}>
									<ArrowForwardIosOutlinedIcon
										onClick={() => handleActiveValue(item.id + 1)}
										sx={{ fill: "#215C75", fontSize: "26px", cursor: "pointer" }} />
								</Button>
							</Box>
							<Box sx={{ height: { lg: "315px", xl: "460px" }, width: "100%", mt: 2, pl: { lg: 0, xl: "29px" }, pr: 2, overflowY: "auto" }} className="customScroll">
								<Box sx={{ display: "flex", gap: "10px" }}>
									<Box sx={{ width: "49%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											<ContentAdmin
												limit={100}
												keyContent="contentMissionTen"
												keyText="experimentsTracker_form_title_1"
												defaultValue="What I am testing"
												position="left" />
										</Typography>
									</Box>
									<Box sx={{ width: "578px" }}>
										<Typography sx={{ color: "#4D4D4D", font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" } }}>
											{item.test}
										</Typography>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "10px", mt: "21px" }}>
									<Box sx={{ width: "40%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											<ContentAdmin
												limit={100}
												keyContent="contentMissionTen"
												keyText="experimentsTracker_form_title_2"
												defaultValue="Experiments"
												position="left" />
										</Typography>
									</Box>
									<Box sx={{ width: "578px" }}>
										<FormGroup>
											{item.run.map((itemRun: ValuesRun) => (
												<FormControlLabel
													key={itemRun.id}
													sx={{ ".MuiTypography-root": { font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }, color: "#4D4D4D", ml: 1 } }}
													control={<Checkbox
														icon={<CardMedia
															component="img"
															width="100%"
															image={checkboxNoActive}
															alt=""
														/>}
														onChange={() => handleChecked(item.id, itemRun.id)}
														checked={itemRun.checked} />}
													label={itemRun.value} />
											))}
										</FormGroup>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "10px", mt: "21px" }}>
									<Box sx={{ width: "40%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											<ContentAdmin
												limit={100}
												keyContent="contentMissionTen"
												keyText="experimentsTracker_form_title_3"
												defaultValue="Definition of success"
												position="left" />
										</Typography>
									</Box>
									<Box sx={{ width: "578px" }}>
										<Typography sx={{ color: "#4D4D4D", font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" } }}>
											{item.success}
										</Typography>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "10px", mt: "21px" }}>
									<Box sx={{ width: "40%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											<ContentAdmin
												limit={100}
												keyContent="contentMissionTen"
												keyText="experimentsTracker_form_title_4"
												defaultValue="Key insights"
												position="left" />
										</Typography>
									</Box>
									<Box sx={{ width: "578px" }}>
										<TextField
											value={item.keyInsights}
											fullWidth
											multiline
											minRows={2}
											maxRows={2}
											inputProps={{ maxLength: 200 }}
											onChange={(e: ChangeEvent<HTMLInputElement>) => { handleKeyInsights(e.target.value, item.id); }}
											helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ item.keyInsights.length }/200 characters`}</Typography>}
											sx={{
												"& .MuiInputBase-root fieldset": {
													border: "none"
												},
												"& .MuiOutlinedInput-root": {
													font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" },
													color: "#4D4D4D !important",
													backgroundColor: "rgba(255, 255, 255, 0.5)",
													borderRadius: "8px",
													height: "84px"
												},
												"& .MuiFormHelperText-contained": {
													textAlign: "right",
													mt: 0
												}
											}}
										/>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "10px", mt: "21px" }}>
									<Box sx={{ width: "40%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											Decision
										</Typography>
									</Box>
									<Box sx={{ width: "578px" }}>
										<TextField
											value={item.decision}
											fullWidth
											multiline
											minRows={2}
											maxRows={2}
											inputProps={{ maxLength: 200 }}
											onChange={(e: ChangeEvent<HTMLInputElement>) => { handleKeyDecision(e.target.value, item.id); }}
											helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ item.decision.length }/200 characters`}</Typography>}
											sx={{
												"& .MuiInputBase-root fieldset": {
													border: "none"
												},
												"& .MuiOutlinedInput-root": {
													font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" },
													color: "#4D4D4D !important",
													backgroundColor: "rgba(255, 255, 255, 0.5)",
													borderRadius: "8px",
													height: "84px"
												},
												"& .MuiFormHelperText-contained": {
													textAlign: "right",
													mt: 0
												}
											}}
										/>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "10px", mt: "21px", pb: 4 }}>
									<Box sx={{ width: "40%" }}>
										<Typography sx={{ color: "#215C75", font: { lg: "700 18px/27px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" } }}>
											Verdict
										</Typography>
									</Box>
									<Box sx={{ display: "flex", gap: "20px" }}>
										<Box
											sx={{ border: item.like ? "3px solid #FFFFFF" : "3px solid #FFFFFF80", width: "80px", height: "80px", borderRadius: "50%", p: "10px", cursor: "pointer" }}
											onClick={() => handleLikeTrue(item.id)}
										>
											<Box sx={{ width: "55px" }} component="img" src={likeTemMission} />
										</Box>
										<Box
											onClick={() => handleLikeFalse(item.id)}
											sx={{ border: item.like === false ? "3px solid #FFFFFF" : "3px solid #FFFFFF80", width: "80px", height: "80px", borderRadius: "50%", p: "18px", cursor: "pointer" }}>
											<Box sx={{ width: "38px", height: "38px" }} component="img" src={dislike} />
										</Box>
									</Box>
								</Box>
							</Box>
							<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
								<CustomButton.Outline
									onClick={() => setStep(0)}
									sx={{
										fontSize: "24px",
										lineHeight: "33px",
										background: "#3E809D",
										border: "#3E809D",
										color: "#fff"
									}}
								>
									Back to board
								</CustomButton.Outline>
							</Box>
						</Box>
					);
				})
			}
		</Box>
	);
}

export default Details;
