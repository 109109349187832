import { Box, Button, Snackbar, SxProps, Theme, Typography, styled } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import Api from "api";
import editIcon from "assets/images/editIcon.svg";
import AlertStyled from "components/AlertStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { useState } from "react";

const TextField = styled(MuiTextField)(() => ({
	"& .MuiInputBase-root fieldset": {
		border: "none !important"
	},
	"& .MuiOutlinedInput-input": {
		background: "rgba(255, 255, 255, 0.5)",
		borderRadius: "8px",
		font: '400 22px/30px "Open Sans"',
		color: "#4D4D4D",
		padding: "12px 16px"
	},
	"& .MuiOutlinedInput-root": {
		padding: 0
	},
	"& .MuiFormHelperText-contained": {
		textAlign: "right",
		font: '400 14px/19px "Open Sans"',
		color: "#4D4D4D",
		opacity: "0.5"
	}
}));

interface CardContentProps
{
	title: string;
	bgImage?: string;
	content?: string;
	editMode?: boolean;
	createMode?: boolean;
	createModeValue?: string;
	handleChangeValueCreateMode?: any;
	sx?: SxProps<Theme>;
	idItem?: string;
}
function CardContent({
	title,
	bgImage,
	content,
	editMode,
	idItem,
	createMode,
	createModeValue,
	handleChangeValueCreateMode,
	sx
}: CardContentProps)
{
	const [ editModeActive, setEditModeActive ] = useState(false);
	const [ valueInput, setValueInput ] = useState<string | undefined>(content);
	const [ open, setOpen ] = useState<boolean>(false);

	function handleClose(_event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	function handleChangeInput(value: string)
	{
		if (createModeValue !== undefined)
		{
			handleChangeValueCreateMode(value, title);
		}
		else setValueInput(value);
	}

	function saveMission()
	{

		Api.missions
			.editDataMissionFive({
				key: title,
				data: valueInput,
				id: idItem
			})
			.then((res: any) =>
			{
				console.info({ res });
				setEditModeActive(false);
				setOpen(true);
			})
			.catch((error: any) =>
			{
				console.error(error);
			});
	}

	return (
		<Box sx={{
			height: { lg: "271px", xl: "333px" },
			width: { lg: "320px", xl: "453px" },
			background: `url(${ bgImage })`,
			borderRadius: "24px",
			p: "24px",
			backgroundSize: "cover",
			color: "primary.main",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			"& .contentStyle": {
				font: { lg: "400 14px/20px Open Sans", xl: "400 16px/22px Open Sans" }, mb: 2
			},
			...sx
		}}>
			<Box>
				<Typography sx={{ font: { lg: "700 18px/24px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, mb: { lg: 1, xl: 2 }, textTransform: "capitalize" }}>
					{title}
				</Typography>
				{editModeActive || createMode ?
					<TextField
						value={createModeValue ? createModeValue : valueInput}
						onChange={(e) => handleChangeInput(e.target.value)}
						fullWidth
						multiline
						minRows={5}
						maxRows={5}
						sx={{
							".MuiOutlinedInput-input": {
								font: { lg: '400 14px/18px "Open Sans"', xl: '400 22px/24px "Open Sans"' }
							}
							// '.MuiInputBase-input': {
							// 	height: { lg: '120px', xl: '137px' }
							// }
						}}
						inputProps={{ maxLength: 300 }}
						helperText={`${ createModeValue !== undefined ? createModeValue.length : valueInput?.length }/300 characters`}
					/> :
					<Typography className="contentStyle">
						{valueInput}
					</Typography>
				}
			</Box>
			{editMode
				? (
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						{editModeActive
							? (
								<CustomButton.Contained
									onClick={saveMission}
									sx={{
										minHeight: { xs: "27px", xl: "40px" },
										fontSize: "18px",
										minWidth: { xs: "167px", xl: "202px" },
										mt: { xs: "1px", xl: "4px" },
										p: { xs: 0, xl: "7px 43px" }
									}}
								>
									Save
								</CustomButton.Contained>
							)
							: (
								<Button
									onClick={() => setEditModeActive(true)}
									sx={{
										width: { xs: "28px", xl: "40px" },
										height: { xs: "28px", xl: "40px" },
										borderRadius: "50%",
										backgroundColor: "#3E809D",
										boxShadow: "0px 3px 6px rgba(0, 0, 0, .16)",
										backdropFilter: "blur(15px) brightness(.9)",
										pointerEvents: "auto",
										minWidth: { xs: "28px", xl: "40px" },
										"&:hover": {
											backgroundColor: "#3E809D"
										}
									}}>
									<Box component="img" src={editIcon} alt="" sx={{ width: { xs: "15px", xl: "20px" } }} />
								</Button>
							)
						}
					</Box>
				)
				: null
			}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity="success">
					Success
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

export default CardContent;
