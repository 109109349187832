import { Box } from "@mui/material";
import { useState } from "react";
import IMGOne from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/1.png";
import IMGTwo from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/2.png";
import IMGThree from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/3.png";
import IMGTree1Highlight from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-1-highlight.png";
import IMGTree1 from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-1.png";
import IMGTree2Highlight from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-2-highlight.png";
import IMGTree2 from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-2.png";
import IMGTree3Highlight from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-3-highlight.png";
import IMGTree3 from "../../../../assets/images/missions/mission-three/DreamUpYourUniverses/tree-3.png";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import ImageDialog from "../../../../components/missions/ImageDialog";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { pixelToVw } from "../../../../utils/pixelToVw";
import useMission from "../../hooks/useMission";

function ItsTimeToDream()
{
	const missionContext = useMission();
	const [ openHint, setOpenHint ] = useState<null | 1 | 2 | 3>(null);
	const [ highlightStatus, setHighlightStatus ] = useState<[ boolean, boolean, boolean ]>([ true, true, true ]);

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	function handlerOpenHint(index: 1 | 2 | 3)
	{
		setOpenHint(index);
		setHighlightStatus((prevState) => (
			Object.assign([], prevState, { [ index - 1 ]: false })
		));
	}

	// Render
	return (
		<>
			<Box className="main__content">
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_title" defaultValue="{NAME}, it’s time to dream!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_subTitle" defaultValue="I want you to imagine from now on you can live in 3 parallel universes where you can do absolutely anything. See what the magic trees are hiding behind to get inspired!" position="left" isDescription />}
				/>
			</Box>

			<StepperNavigationWrapper sx={{ position: "absolute", bottom: "16px", right: "40px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					onClick={onClickNextStep}
				/>
			</StepperNavigationWrapper>

			{/* Tree */}
			<>
				<Box
					component="img"
					src={highlightStatus[ 0 ] ? IMGTree1Highlight : IMGTree1}
					sx={{
						width: { xl: "122px", lg: "90px", xs: "101px" },
						cursor: "pointer",
						zIndex: "20",
						position: "fixed",
						bottom: { xl: "61px", lg: "45px", xs: "61px" },
						left: pixelToVw(855)
					}}
					onClick={() => handlerOpenHint(1)}
				/>

				<Box
					component="img"
					src={highlightStatus[ 1 ] ? IMGTree2Highlight : IMGTree2}
					sx={{
						width: { xl: "164px", lg: "121px", xs: "101px" },
						cursor: "pointer",
						zIndex: "20",
						position: "fixed",
						bottom: { xl: "-31px", lg: "-20px", xs: "-31px" },
						left: { xl: pixelToVw(1155), lg: pixelToVw(1180), xs: pixelToVw(1155) }
					}}
					onClick={() => handlerOpenHint(2)}
				/>

				<Box
					component="img"
					src={highlightStatus[ 2 ] ? IMGTree3Highlight : IMGTree3}
					sx={{
						width: { xl: "101px", lg: "76px", xs: "101px" },
						cursor: "pointer",
						zIndex: "20",
						position: "fixed",
						bottom: { xl: "165px", lg: "130px", xs: "145px" },
						right: { xl: pixelToVw(475), lg: pixelToVw(420), xs: pixelToVw(475) }
					}}
					onClick={() => handlerOpenHint(3)}
				/>
			</>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_dialog_1" defaultValue="You could live a secluded life growing organic vegetables in rural Vietnam" position="right" isDescription />}
				open={openHint === 1}
				image={IMGOne}
				direction="row"
				position="center"
				onClose={() => setOpenHint(null)}
			/>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_dialog_2" defaultValue="Become a K-pop star in Korea living a celebrity lifestyle" position="right" isDescription />}
				open={openHint === 2}
				image={IMGTwo}
				direction="row"
				position="center"
				onClose={() => setOpenHint(null)}
			/>

			<ImageDialog
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_2_dialog_3" defaultValue="Be a monk or a nun and withdraw from all worldly pleasures" position="right" isDescription />}
				open={openHint === 3}
				image={IMGThree}
				direction="row"
				position="center"
				onClose={() => setOpenHint(null)}
			/>
		</>
	);
}

export default ItsTimeToDream;
