import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, InputProps, OutlinedInput, styled } from "@mui/material";
import { Ref, forwardRef, useState } from "react";

const InputBaseStyled = styled(OutlinedInput)(({ theme }) => ({
	"--font": "400 18px/24px 'Open Sans'",

	backgroundColor: "rgba(255, 255, 255, .6)",
	borderRadius: "10px",

	[ theme.breakpoints.down("xl") ]:
	{
		font: "400 16px/22px 'Open Sans'"
	},
	[ theme.breakpoints.down("sm") ]:
	{
		borderRadius: "30px"
	},
	"&.MuiOutlinedInput-root:is(.MuiInputBase-multiline)":
	{
		padding: "0px"
	},
	"& .MuiInputBase-inputMultiline, & .MuiInputBase-input":
	{
		boxSizing: "border-box",
		height: "56px",
		padding: "18px 20px 17px 20px",
		color: theme.palette.primary.dark,
		font: "var(--font)",

		[ theme.breakpoints.down("xl") ]:
		{
			height: "48px",
			padding: "13px 16px"
		},
		[ theme.breakpoints.down("sm") ]:
		{
			paddingLeft: "24px",
			paddingRight: "24px"
		},
		"&:placeholder":
		{
			opacity: 0.5,
			font: "inherit",
			color: "inherit"
		}
	},
	"& .MuiInputAdornment-root":
	{
		margin: "unset",
		"& p":
		{
			font: "var(--font)"
		}
	},
	"& fieldset": { border: "none" }
}));

const InputBase = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) =>
{
	return (
		<InputBaseStyled size="small" ref={ref} {...props} />
	);
});

const InputPassword = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) =>
{
	const [ isShowPassword, setIsShowPassword ] = useState<boolean>(false);

	function onClickTogglePassword()
	{
		setIsShowPassword((prevState) => !prevState);
	}

	return (
		<InputBaseStyled
			size="small"
			ref={ref}
			{...props}
			type={isShowPassword ? "text" : "password"}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={onClickTogglePassword}
						edge="end"
					>
						{isShowPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
});

const CustomInput =
{
	Base: InputBase,
	Password: InputPassword
};

export default CustomInput;
