import { Box, styled, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MuiTextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { forwardRef, Ref, useEffect } from "react";
import * as Yup from "yup";
import { ReactComponent as SVGRadial } from "../../../../../assets/images/icons/radial.svg";
import ContentAdmin from "../../../../../components/contentAdmin";
import CustomButton from "../../../../../components/customButton";
import useMission from "../../../hooks";

const TextField = styled(MuiTextField)(({ theme }) => ({
	"& .MuiInputBase-root fieldset": {
		border: "none !important"
	},
	"& .MuiOutlinedInput-root": {
		[ theme.breakpoints.down("xl") ]: {
			font: '400 16px/22px "Open Sans" !important',
			height: "48px",
			lineHeight: "48px"
		},
		[ theme.breakpoints.up("xl") ]: {
			font: '400 22px/30px "Open Sans" !important',
			height: "59px",
			lineHeight: "59px"
		},
		color: "#4D4D4D !important",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		borderRadius: "10px !important"
	},
	"& .MuiInputBase-input": {
		[ theme.breakpoints.down("xl") ]: {
			padding: "13px 16px 13px 0"
		},
		[ theme.breakpoints.up("xl") ]: {
			padding: "16.5px 14px 16.5px 0"
		}
	},
	"& .MuiFormHelperText-contained": {
		textAlign: "right",
		font: '400 14px "Open Sans" !important'
	},
	"& .MuiInputAdornment-positionStart": {
		font: "inherit",
		letterSpacing: "inherit",
		color: "currentColor",
		background: "none",
		height: "1.4375em",
		margin: "0px 5px 0px 0px"
	},
	"& .MuiInputAdornment-positionStart p": {
		[ theme.breakpoints.down("xl") ]: {
			font: '400 16px/22px "Open Sans" !important',
			paddingLeft: "2px !important"
		},
		[ theme.breakpoints.up("xl") ]: {
			font: '400 22px/30px "Open Sans" !important',
			paddingLeft: "5px !important"
		},
		color: "#4D4D4D !important"
	}
}));

const Environment = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const { setStepClockMorning } = props;
	const missionContext = useMission();
	const formik = useFormik({
		initialValues: {
			spendAfternoon: "",
			wearing: "",
			theWeatherToday: ""
		},
		validationSchema: Yup.object({
			spendAfternoon: Yup.string().required("required"),
			wearing: Yup.string().required("required"),
			theWeatherToday: Yup.string().required("required")
		}),
		onSubmit: (values) =>
		{
			const dataAfternoon = {
				spendAfternoon: values.spendAfternoon,
				wearing: values.wearing,
				theWeatherToday: values.theWeatherToday
			};
			if (localStorage.getItem("mission-five-data-afternoon-activities") && localStorage.getItem("mission-five-data-afternoon-people"))
			{
				const dataAfternoonAll = {
					environment: dataAfternoon,
					activities: JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon-activities") }`),
					people: localStorage.getItem("mission-five-data-afternoon-people")
				};

				localStorage.setItem("mission-five-data-afternoon", JSON.stringify(dataAfternoonAll));
				localStorage.removeItem("mission-five-data-afternoon-environment");
				localStorage.removeItem("mission-five-data-afternoon-activities");
				localStorage.removeItem("mission-five-data-afternoon-people");
				localStorage.removeItem("mission-five-data-afternoon-active-people");
				missionContext.setBlurBg(true);

				missionContext.setStep((prevState) => prevState + 1);
			}
			else
			{
				localStorage.setItem("mission-five-data-afternoon-environment", JSON.stringify(dataAfternoon));
				setStepClockMorning(1);
			}

		}
	});

	useEffect(() =>
	{
		const dataMorning = JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon-environment") }`);
		if (dataMorning)
		{
			formik.setFieldValue("spendAfternoon", dataMorning.spendAfternoon);
			formik.setFieldValue("wearing", dataMorning.wearing);
			formik.setFieldValue("theWeatherToday", dataMorning.theWeatherToday);
		}
	}, []);

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				flexDirection: "column",
				display: "flex",
				width: "100%",
				maxWidth: { xl: "980px", xs: "784px" },
				minWidth: { xl: "906px", xs: "784px" },
				height: "calc(100vh - 303px)",
				margin: { xl: "auto", xs: "0 auto" },
				marginTop: { xl: "160px", xs: "97px" }
			}}>
			<Box sx={{
				display: "flex",
				alignItems: "center"
			}}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: { xl: "80px", xs: "64px" },
						height: { xl: "80px", xs: "64px" },
						border: "2px solid #FFFFFF",
						borderRadius: "50%",
						background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
						backdropFilter: "blur(200px)",
						marginRight: "30px",
						boxShadow: "3px 3px 10px rgb(28 130 170 / 66%)"
					}}
				>
					<SVGRadial />
				</Box>
				<Typography sx={{ color: "#215C75", fontFamily: "Lora", fontSize: { xl: "40px", xs: "24px" }, fontWeight: "700", lineHeight: { xl: "51px", xs: "31px" }, textAlign: "left", wordBreak: "break-all", maxHeight: "102px" }}>
					{/* Environment */}
					<ContentAdmin limit={111} keyContent="contentMissionFive" keyText="mission_5_environment" defaultValue="Environment" position="right" />
				</Typography>
			</Box>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "space-between"
			}}>
				<Box
					component="form"
					autoComplete="off"
					onSubmit={formik.handleSubmit}
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						maxHeight: { xl: "520px", xs: "395px" },
						justifyContent: "space-between"
					}}>
					<Box sx={{
						display: "flex",
						width: "calc(100% - 75px)",
						height: "auto",
						m: "0 auto",
						marginLeft: { xl: "75px", xs: "68px" },
						border: "1px solid rgba(255, 255, 255, .6)",
						borderRadius: "24px",
						overflow: "hidden",
						wordBreak: "break-all",
						flexDirection: "column",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						boxShadow: "0px 5px 10px 0px #A7C7E199"
					}}>
						<Box sx={{ padding: { xl: "24px 24px 0px 24px", xs: "16px 16px 0px 16px" } }}>
							<Box>
								<Typography sx={{ marginLeft: "16px", marginBottom: "10px", color: "#215C75", fontFamily: "Open Sans", fontSize: { xl: "22px", xs: "18px" }, fontWeight: "700", lineHeight: { xl: "30px", xs: "24px" }, textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
									{/* Where do you spend your afternoon? */}
									<ContentAdmin limit={175} keyContent="contentMissionFive" keyText="clock_afternoon_spend_afternoon" defaultValue="Where do you spend your afternoon?" position="left" />
								</Typography>
								<TextField
									inputProps={{ maxLength: 80 }}
									value={formik.values.spendAfternoon}
									onChange={formik.handleChange}
									id="spendAfternoon"
									fullWidth
									name="spendAfternoon"
									InputProps={{
										startAdornment: <InputAdornment position="start">I </InputAdornment>
									}}
									placeholder="work from home and go out for a coffee in a neighborhood café"
									helperText={`${ formik.values.spendAfternoon.length }/80`}
								/>
								{/* <Typography sx={{ color: "red", }}>
										{formik.touched.spendAfternoon && formik.errors.spendAfternoon}
									</Typography> */}
							</Box>
							<Box>
								<Typography sx={{ marginLeft: "16px", marginBottom: "10px", color: "#215C75", fontFamily: "Open Sans", fontSize: { xl: "22px", xs: "18px" }, fontWeight: "700", lineHeight: { xl: "30px", xs: "24px" }, textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
									{/* What are you wearing? */}
									<ContentAdmin limit={175} keyContent="contentMissionFive" keyText="clock_afternoon_wearing" defaultValue="What are you wearing?" position="left" />
								</Typography>
								<TextField
									inputProps={{ maxLength: 80 }}
									value={formik.values.wearing}
									onChange={formik.handleChange}
									id="wearing"
									fullWidth
									name="wearing"
									InputProps={{
										startAdornment: <InputAdornment position="start">I am wearing </InputAdornment>
									}}
									placeholder="comfy yoga pants and a white designer shirt"
									helperText={`${ formik.values.wearing.length }/80`}
								/>
								{/* <Typography sx={{ color: "red", }}>
										{formik.touched.wearing && formik.errors.wearing}
									</Typography> */}
							</Box>
							<Box>
								<Typography sx={{ marginLeft: "16px", marginBottom: "10px", color: "#215C75", fontFamily: "Open Sans", fontSize: { xl: "22px", xs: "18px" }, fontWeight: "700", lineHeight: { xl: "30px", xs: "24px" }, textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
									{/* What is the weather today? */}
									<ContentAdmin limit={175} keyContent="contentMissionFive" keyText="clock_afternoon_the_weather" defaultValue="What is the weather today?" position="left" />
								</Typography>
								<TextField
									inputProps={{ maxLength: 80 }}
									value={formik.values.theWeatherToday}
									onChange={formik.handleChange}
									id="theWeatherToday"
									fullWidth
									name="theWeatherToday"
									InputProps={{
										startAdornment: <InputAdornment position="start">It’s </InputAdornment>
									}}
									placeholder="always sunny in Lisbon and the sky is blue!"
									helperText={`${ formik.values.theWeatherToday.length }/80`}
								/>
								{/* <Typography sx={{ color: "red", }}>
										{formik.touched.theWeatherToday && formik.errors.theWeatherToday}
									</Typography> */}
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						disabled={!formik.values.spendAfternoon || !formik.values.wearing || !formik.values.theWeatherToday}
						sx={{
							margin: { xl: "30px auto 30px auto", xs: "auto auto 0" },
							padding: { xl: "7px 43px", xs: "7.5px 80px" },
							display: "flex",
							fontSize: "24px",
							lineHeight: "33px",
							background: "#3E809D",
							borderColor: "#3E809D"
						}}
						onClick={() =>
						{

							formik.handleSubmit();
						}}>
						Done
					</CustomButton.Contained>
				</Box>
			</Box>
		</Box>
	);
});

export default Environment;
