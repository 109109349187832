import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CelebrationScreen from "../../../../components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

export type ICelebrationProps = Record<string, any>;

function WhatAreYourFamilyBeliefsCelebration({ ...props }: ICelebrationProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative" }}>
				<CelebrationScreen
					content={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefsCelebration_text" defaultValue="Excellent job!" position="left" isDescription />}
					buttonText="Thanks!"
					onClickButton={() => { missionContext.nextStep(); }}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(WhatAreYourFamilyBeliefsCelebration);
