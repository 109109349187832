import { Box, alpha } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import useMission from "pages/missionTwo/hooks/useMission";
import { useEffect } from "react";
import trackEvent from "utils/mixpanelUtil";
import IMGProgress75 from "../../assets/images/progress-75.png";

function ProgressMessage()
{
	const missionContext = useMission();
	const { state: userState } = useUser();

	// Effects
	useEffect(() =>
	{
		trackEvent({
			eventName: "Three Peaks Exercise Completed",
			userId: userState.user?.id
		});
	}, []);

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<Box sx={{
				padding: { xl: "36px 28px 36px 54px", md: "32px", xs: "32px 36px 40px 36px" },
				margin: "88px auto 0 auto",
				width: { xl: "816px", md: "474px", xs: "240px" },

				backgroundColor: alpha("#FFFFFF", 0.3),
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "24px",

				display: "flex",
				alignItems: "center",
				flexDirection: { md: "row", xs: "column" },
				gap: "16px 28px"
			}}>
				<Box sx={{
					position: "relative",

					"& img":
					{
						"--size": { xl: "243px", xs: "166px" },
						transform: "rotate(12.47deg)",
						width: "var(--size)",
						height: "var(--size)"
					}
				}}>
					<img src={IMGProgress75} alt="" />

					<Box sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -55%)",

						font: { xl: "700 80px 'Open Sans'", xs: "700 48px 'Open Sans'" },
						color: "primary.main",

						"& span":
						{
							fontSize: { xl: "48px", xs: "32px" }
						}
					}}>
						75<span>%</span>
					</Box>
				</Box>

				<Box sx={{
					font: { xl: "400 24px/33px 'Open sans'", xs: "400 16px/22px 'Open sans'" },
					color: "primary.main",
					textAlign: { md: "left", xs: "center" }
				}}>
					<ContentAdmin
						isDescription
						keyContent={missionContext.keyContent}
						keyText="ProgressMessage_cardText"
						defaultValue="People who know their values make better decisions. 75% report an increase in positive outcomes in various aspects of life, including career and relationships."
						position="right"
					/>
				</Box>
			</Box>

			<Box sx={{
				marginTop: "72px",

				font: { xl: "700 40px/51px Lora-Bold", sm: "700 24px/31px Lora-Bold" },
				color: "primary.main",
				textAlign: "center",

				"& div": { display: "inline" }
			}}>
				<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="ProgressMessage_text"
					defaultValue="Keep going, {NAME}!"
					position="left"
				/>
			</Box>

			<CustomButton.Contained
				onClick={() => missionContext.nextStep()}
				sx={{
					margin: { xl: "40px auto 0 auto", sm: "24px auto 0 auto" },
					display: "flex"
				}}
			>
				Continue
			</CustomButton.Contained>
		</Box>
	);
}

export default ProgressMessage;
