import Api, { getAccessToken } from "api";

export default class Messages
{
	async needHelpEmail(subject: string, message: string)
	{
		return (
			await Api.instance.post<"done">(
				"/users/needHelpEmail",
				{ subject, message },
				{
					headers:
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async requestAFeature(subject: string, message: string)
	{
		return (
			await Api.instance.post<"done">(
				"/users/requestAFeature",
				{ subject, message },
				{
					headers:
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async feedbackEmail(subject: string, message: string)
	{
		return (
			await Api.instance.post<"done">(
				"/users/feedbackEmail",
				{ subject, message },
				{
					headers:
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
