import { Box, Button, CardMedia, styled, Typography } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { forwardRef, Ref, useEffect, useState } from "react";
import editIcon from "../../../assets/images/editIcon.svg";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import PanelTitle from "../../../components/missions/PanelTitle";
import FinishAfternoon from "./FinishAfternoon";

const TextField = styled(MuiTextField)(() => ({
	"& .MuiInputBase-root fieldset": {
		border: "none !important"
	},
	"& .MuiOutlinedInput-input": {
		background: "rgba(255, 255, 255, 0.5)",
		borderRadius: "8px",
		font: '400 22px/30px "Open Sans"',
		color: "#4D4D4D",
		padding: "12px 16px"
	},
	"& .MuiOutlinedInput-root": {
		padding: 0
	},
	marginBottom: "32px"
}));
const ReviewDay = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const [ isDisabled, setIsDisabled ] = useState(true);
	const [ content, setContent ] = useState(false);
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ dataMissionString, setDataMissionString ] = useState({
		morning: "",
		afternoon: "",
		evening: "",
		night: ""
	});

	function nextStep()
	{
		setContent(true);
	}

	useEffect(() =>
	{
		const dataMission = {
			time: JSON.parse(`${ localStorage.getItem("mission-five-time") }`),
			morning: JSON.parse(`${ localStorage.getItem("mission-five-data-morning") }`),
			afternoon: JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon") }`),
			evening: JSON.parse(`${ localStorage.getItem("mission-five-data-evening") }`),
			night: JSON.parse(`${ localStorage.getItem("mission-five-data-night") }`)
		};

		setDataMissionString({
			morning: `I wake up at ${ dataMission.time[ 0 ].hours }:${ dataMission.time[ 0 ].minutes } ${ dataMission.morning?.environment?.wakeUp }. My home is ${ dataMission.morning?.environment?.lookLike }. It smells like ${ dataMission.morning?.environment?.howSmell }. I ${ dataMission.morning?.activities?.wakeUp }. I have breakfast at ${ dataMission.time[ 1 ].hours }:${ dataMission.time[ 1 ].minutes } For breakfast I eat ${ dataMission.morning?.activities?.howSmell }. I eat breakfast with ${ dataMission.morning?.people }.`,
			afternoon: `I ${ dataMission.afternoon?.environment?.spendAfternoon }. I am wearing ${ dataMission.afternoon?.environment?.wearing }. It’s ${ dataMission.afternoon?.environment?.theWeatherToday }. I ${ dataMission.afternoon?.activities?.dayActivities }. I have lunch at ${ dataMission.time[ 2 ].hours }:${ dataMission.time[ 2 ].minutes }. For lunch I have ${ dataMission.afternoon?.activities?.eatForLunch }. I  ${ dataMission.afternoon?.activities?.dayActivities }. I   ${ dataMission.afternoon?.activities?.atWork }`,
			evening: `In the evening, I ${ dataMission.evening?.environment?.spendEvening }. I get there by ${ dataMission.evening?.environment?.getThere }. When I am back, I ${ dataMission.evening?.activities?.spendEvening }. I ${ dataMission.evening?.activities?.personalFulfillment }. Dinner is at ${ dataMission.time[ 3 ].hours }:${ dataMission.time[ 3 ].minutes } I eat with ${ dataMission.evening?.people }.`,
			night: `My bedroom ${ dataMission.night?.environment }. I ${ dataMission.night?.activities }. I fall asleep at ${ dataMission.time[ 4 ].hours }:${ dataMission.time[ 4 ].minutes } with ${ dataMission.night?.people }.`
		});
	}, []);
	function handleScroll(e: any)
	{
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom)
		{
			setIsDisabled(false);
		}
	}

	useEffect(() =>
	{
		const totalLength = [ dataMissionString?.morning, dataMissionString?.afternoon, dataMissionString?.evening, dataMissionString?.night ]
			.filter(Boolean)
			.reduce((acc, arr) => acc + arr.length, 0);

		if (totalLength < 504)
		{
			setIsDisabled(false);
		}

	}, [ dataMissionString ]);

	function handleValue(e: any)
	{
		setDataMissionString({
			...dataMissionString,
			[ e.name ]: e.value
		});
	}

	return (
		<Box ref={ref} {...props}>
			{content
				? <FinishAfternoon />
				: (
					<Box sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { xl: "980px", xs: "784px" }, m: "10px auto 0px" }}>
						<Box sx={{ maxWidth: "100%", m: "-20px auto 0" }}>
							<PanelTitle
								positionOcta
								sx={{
									paddingRight: "90px",
									font: "700 40px/51px Lora",
									"& .component-missions-panelTitle-imageContainer": {
										top: { xl: "-37px", xs: "-15px" },
										left: { xl: 0, xs: "-67px" }
									}
								}}
								imageVariation="closeEye"
								title={<ContentAdmin limit={111} keyContent="contentMissionFive" keyText="review_day_title" defaultValue="Let’s review your day" position="right" />}
								subTitle={<ContentAdmin
									limit={200}
									keyContent="contentMissionFive"
									keyText="review_day_text"
									defaultValue="What an amazing day you created, {NAME}. Is there anything you want to add or tweak?"
									position="left"
									isDescription />}
							/>
							{dataMissionString.morning
								? (
									<Box sx={{ marginTop: { xl: "55px", xs: "20px" } }}>
										<Box
											sx={{
												padding: { xl: "32px 40px", xs: "24px" },
												width: "100%",
												display: "flex",
												flexDirection: "column",
												background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
												backdropFilter: "blur(25px)",
												boxShadow: "0px 5px 10px rgba(167, 199, 225, 0.6)",
												borderRadius: "24px",
												height: { xl: "calc(100vh - 300px)", xs: "463px" },
												overflowY: "scroll",
												overflowX: "hidden",
												minWidth: { xl: "980px", xs: "784px" }
											}}>
											<Box sx={{ display: "flex", justifyContent: "space-between" }}>
												<Typography sx={{
													marginBottom: "15px",
													color: "#215C75",
													font: { xl: "700 40px/51px Lora", xs: "700 24px/30px Lora" },
													textAlign: "left"
												}}>
													<ContentAdmin
														limit={127}
														keyContent="contentMissionFive"
														keyText="review_day_perfect_day"
														defaultValue="{NAME}’s Perfect Day"
														position="left" />
												</Typography>
												<Button
													onClick={() => setIsEditMode(!isEditMode)}
													sx={{
														width: { xl: "40px", xs: "32px" },
														height: { xl: "40px", xs: "32px" },
														borderRadius: "50%",
														backgroundColor: "#3E809D",
														boxShadow: "0px 3px 6px rgba(0, 0, 0, .16)",
														backdropFilter: "blur(15px) brightness(.9)",
														pointerEvents: "auto",
														minWidth: { xl: "40px", xs: "32px" },
														"&:hover": {
															backgroundColor: "#3E809D"
														}
													}}
												>
													<Box sx={{ width: "20px" }}>
														<CardMedia
															component="img"
															width="100%"
															image={editIcon}
															alt=""
														/>
													</Box>
												</Button>
											</Box>
											<Box
												onScroll={handleScroll}
												className="customScroll"
												sx={{
													overflowY: "scroll",
													overflowX: "hidden",
													paddingRight: "35px"
												}}>
												{isEditMode ?
													<TextField
														value={dataMissionString.morning}
														onChange={(e) => handleValue(e.target)}
														name="morning"
														fullWidth
														multiline
													/> :
													<Box sx={{ paddingRight: "25px" }}>
														<Typography sx={{
															marginBottom: "30px",
															fontSize: { xl: "22px", xs: "18px" },
															lineHeight: { xl: "30px", xs: "23px" },
															fontFamily: "Open Sans",
															color: "#4D4D4D"
														}}>
															{dataMissionString.morning}
														</Typography>
													</Box>}
												<Typography sx={{
													marginBottom: "8px",
													color: "#215C75",
													font: { xl: "700 24px/31px Lora", xs: "700 18px/23px Lora" },
													textAlign: "left"
												}}>
													{/* Afternoon */}
													<ContentAdmin limit={127} keyContent="contentMissionFive" keyText="review_day_afternoon" defaultValue="Afternoon" position="left" />
												</Typography>
												{isEditMode ?
													<TextField
														value={dataMissionString.afternoon}
														onChange={(e) => handleValue(e.target)}
														name="afternoon"
														fullWidth
														multiline
													/> :
													<Typography sx={{
														marginBottom: "30px",
														fontSize: { xl: "22px", xs: "18px" },
														lineHeight: { xl: "30px", xs: "23px" },
														fontFamily: "Open Sans",
														color: "#4D4D4D"
													}}>
														{dataMissionString.afternoon}
													</Typography>}
												<Typography sx={{
													marginBottom: "8px",
													color: "#215C75",
													font: { xl: "700 24px/31px Lora", xs: "700 18px/23px Lora" },
													textAlign: "left"
												}}>
													{/* Evening */}
													<ContentAdmin limit={127} keyContent="contentMissionFive" keyText="review_day_evening" defaultValue="Evening" position="left" />
												</Typography>
												{isEditMode
													? (
														<TextField
															value={dataMissionString.evening}
															onChange={(e) => handleValue(e.target)}
															name="evening"
															fullWidth
															multiline
														/>
													)
													: (
														<Typography sx={{
															marginBottom: "30px",
															fontSize: { xl: "22px", xs: "18px" },
															lineHeight: { xl: "30px", xs: "23px" },
															fontFamily: "Open Sans",
															color: "#4D4D4D"
														}}>
															{dataMissionString.evening}
														</Typography>
													)
												}
												<Typography sx={{
													marginBottom: "8px",
													color: "#215C75",
													font: { xl: "700 24px/31px Lora", xs: "700 18px/23px Lora" },
													textAlign: "left"
												}}>
													{/* Night */}
													<ContentAdmin limit={127} keyContent="contentMissionFive" keyText="review_day_nigh" defaultValue="Night" position="left" />
												</Typography>

												{isEditMode
													? (
														<TextField
															value={dataMissionString.night}
															onChange={(e) => handleValue(e.target)}
															name="night"
															fullWidth
															multiline
														/>
													)
													: (
														<Typography sx={{
															marginBottom: "30px",
															fontSize: { xl: "22px", xs: "18px" },
															lineHeight: { xl: "30px", xs: "23px" },
															fontFamily: "Open Sans",
															color: "#4D4D4D"
														}}>
															{dataMissionString.night}
														</Typography>
													)
												}
											</Box>

											<Box>
												{isEditMode
													? (
														<CustomButton.Contained
															sx={{
																margin: { xl: "30px auto 30px auto", xs: "16px auto 0" },
																padding: { xl: "7px 43px", xs: "7.5px 80px" },
																display: "flex",
																fontSize: "24px",
																lineHeight: "33px"
															}}
															onClick={() => setIsEditMode(!isEditMode)}
														>
															Save
														</CustomButton.Contained>
													)
													: (
														<CustomButton.Contained
															disabled={isDisabled}
															sx={{
																margin: { xl: "30px auto 30px auto", xs: "16px auto 0" },
																padding: { xl: "7px 43px", xs: "7.5px 80px" },
																display: "flex",
																fontSize: "24px",
																lineHeight: "33px",
																background: "#3E809D",
																borderColor: "#3E809D"
															}}
															onClick={nextStep}>
															Submit
														</CustomButton.Contained>
													)
												}
											</Box>
										</Box>
									</Box>
								)
								: null
							}
						</Box>
					</Box>
				)
			}
		</Box>
	);
});

export default ReviewDay;
