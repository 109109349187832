import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

export type IIntroToTopicProps = Record<string, any>;

function IntroToTopic({ ...props }: IIntroToTopicProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_message_1" defaultValue="{NAME}, your career is not just a way of making money. We all need something to do even if we have enough money for the rest of our lives!" isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [
				<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_answer_1" defaultValue="Doing something meaningful is important!" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_message_2" defaultValue="Exactly, so let’s find out what would make you fulfilled." isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_answer_2" defaultValue="I am ready!" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_answer_3" defaultValue="Let’s do it!" position="top" />
					]
				});
				break;
			}

			case 3: { setChatStep((prevStep) => prevStep + 1); missionContext.nextStep(); }
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Chat
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroToTopic_chat_title" defaultValue="" />}
					onResponseAnswer={onResponseAnswer}
					messages={messages}
					answers={answers}
					isAlignItems
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(IntroToTopic);
