import { Box } from "@mui/material";
import { useState } from "react";
import { getLocalStorage } from "utils/localStorage";
import bgModal from "../../assets/images/bgmodal.png";
import cta from "../../assets/images/cta.svg";
import CareerDetails from "./fields/itemsDetailsModal/Career";
import FriendsDetails from "./fields/itemsDetailsModal/Friends";
import FunDetails from "./fields/itemsDetailsModal/Fun";
import GrowthDetails from "./fields/itemsDetailsModal/Growth";
import HealthDetails from "./fields/itemsDetailsModal/Health";
import LoveDetails from "./fields/itemsDetailsModal/Love";
import MoneyDetails from "./fields/itemsDetailsModal/Money";
import PhisycalhDetails from "./fields/itemsDetailsModal/Phisycal";

function Dialogs({ details, handleClose })
{
	const graph = useState(getLocalStorage("graph"));

	function renderComponent(details)
	{
		switch (details)
		{
			case "Health":
				return <HealthDetails arr={graph} />;
			case "Friends":
				return <FriendsDetails arr={graph} />;
			case "Love":
				return <LoveDetails arr={graph} />;
			case "Career":
				return <CareerDetails arr={graph} />;
			case "Money":
				return <MoneyDetails arr={graph} />;
			case "Physical environment":
				return <PhisycalhDetails arr={graph} />;
			case "Fun":
				return <FunDetails arr={graph} />;
			case "Growth":
				return <GrowthDetails arr={graph} />;
			default:
				return "";
		}
	}

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<img width="100%" src={bgModal} alt="cta" />
				<Box onClick={handleClose} sx={{ width: "56px", position: "fixed", top: "10px", right: "10px", cursor: "pointer", zIndex: 9999 }}>
					<img width="100%" src={cta} alt="cta" />
				</Box>
			</Box>
			<Box
				sx={{
					background: "rgba(239,239,239,0.4 )",
					p: "0 15px 30px",
					position: "relative",
					mt: "-5px"
				}}
			>
				{renderComponent(details)}
				{/* <Box mt={2} pb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '90%', m: '0 auto' }}>
                        <Link className="textecotationLinks" to="/welcome"><ButtonStyled fullWidth={true} title="Embark On a Quest" /></Link>
                    </Box>
                </Box> */}
			</Box>
		</Box>
	);
}

export default Dialogs;
