import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as SVGfear } from "../../../../assets/images/missions/mission-eight/fear.svg";
import FormAccordion from "../../../../components/FormAccordion";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";

import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowButton from "../../../../components/ArrowButton";
import BallButton from "../../../../components/BallButton";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks";

interface ArrValues
{
	title: string;
	define: string;
	prevent: string;
	repair: string;
}

function FaceToFears()
{
	const missionContext = useMission();
	const [ open, setOpen ] = useState(false);
	const [ showBtnNextStep, setShowBtnNextStep ] = useState(false);
	const [ title, setTitle ] = useState("");
	const [ fears, setFears ] = useState<any>([]);
	const [ expanded, setExpanded ] = useState([ true, true, true ]);

	function handleStep()
	{
		localStorage.setItem("mission-eight-fears-resolve", JSON.stringify(fears));
		missionContext.setStep((prevState) => prevState + 1);
	}

	useEffect(() =>
	{
		let count = 0;

		fears.forEach((item: ArrValues) =>
		{
			if (item.define) count += 1;
		});
		if (fears.length !== 0 && count === fears.length) setShowBtnNextStep(true);
	}, [ fears ]);
	useEffect(() =>
	{
		const newFearsValues = localStorage.getItem("mission-eight-fears");

		if (localStorage.getItem("mission-eight-wantInLine"))
		{
			const arrValues: ArrValues[] = [];

			JSON.parse(`${ localStorage.getItem("mission-eight-wantInLine") }`).forEach((element: string) =>
			{
				arrValues.push({
					title: element,
					define: "",
					prevent: "",
					repair: ""
				});
			});
			setFears(arrValues);
		}

		if (newFearsValues !== null)
		{
			const newFears = JSON.parse(newFearsValues);

			setFears(newFears);
		}

	}, []);
	function positionFears(index: number)
	{
		return {
			position: "absolute",
			left: index === 0
				? { lg: "321px", xl: "421px" }
				: index === 1
					? { lg: "163px", xl: "263px" }
					: index === 2
						? { lg: "457px", xl: "557px" }
						: index === 3
							? { lg: "127px", xl: "227px" }
							: index === 4
								? { lg: "553px", xl: "653px" }
								: index === 5
									? { lg: "11px", xl: "111px" }
									: index === 6 ? { lg: "644px", xl: "744px" } : index === 7 ? { lg: "-110px", xl: "10px" } : index === 8 ? { lg: "70px", xl: "170px" } : index === 9 ? { lg: "230px", xl: "330px" } : index === 10 ? { lg: "400px", xl: "500px" } : index === 11 ? { lg: "570px", xl: "670px" } : { lg: "0px", xl: "100px" },
			top: index === 1
				? { lg: "50px", xl: "110px" }
				: index === 2
					? { lg: "50px", xl: "110px" }
					: index === 3
						? { lg: "149px", xl: "209px" }
						: index === 4
							? { lg: "149px", xl: "209px" }
							: index === 5
								? { lg: "87px", xl: "147px" }
								: index === 6 ? { lg: "78px", xl: "138px" } : index === 7 ? { lg: "30px", xl: "90px" } : index === 8 ? { lg: "-10px", xl: "50px" } : index === 9 ? { lg: "-40px", xl: "20px" } : index === 10 ? { lg: "-50px", xl: "10px" } : index === 11 ? { lg: "-40px", xl: "20px" } : { lg: "40px", xl: "100px" }
		};
	}

	function onClick(title: string)
	{
		setOpen(true);
		setTitle(title);
	}

	function onClose()
	{
		setOpen(false);
	}

	const formik = useFormik({
		initialValues: {
			willRun: "",
			canCreate: "",
			canFind: ""
		},
		validationSchema: Yup.object({
			willRun: Yup.string().required("required"),
			canCreate: Yup.string().required("required"),
			canFind: Yup.string().required("required")
		}),
		onSubmit: (values, { resetForm }) =>
		{
			const newFears = [ ...fears ];

			newFears.forEach((fear: ArrValues) =>
			{
				if (fear.title === title)
				{
					fear.define = values.willRun;
					fear.prevent = values.canCreate;
					fear.repair = values.canFind;
				}
			});
			setFears(newFears);
			localStorage.setItem("mission-eight-fears", JSON.stringify(newFears));
			resetForm();
			onClose();
		}
	});

	return (
		<Box sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { lg: "802px", xl: "980px" } }}>
			<Box sx={{
				maxWidth: "100%",
				mt: "-20px",
				position: "relative",
				left: "-38px",
				top: "-5px"
			}}>
				<PanelTitle
					color="#ffffff"
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEight"
						keyText="face_to_fears_title"
						defaultValue="Face your fears"
						position="right"
					/>
					}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="face_to_fears_sub_title"
						defaultValue="Let’s go through all of your fears one by one and find a way to prevent each one of them from happening or repair the damage if needed."
						position="right"
						isDescription
					/>
					}
				/>
			</Box>
			<Box sx={{ height: "430px", position: "relative", top: "60px" }}>
				{fears.length > 0 && fears.map((fear: ArrValues, index: number) => (
					<Box
						key={index}
						sx={positionFears(index)}
					>
						<Box sx={{
							position: "relative", width: "136px", height: "226px"
						}}>
							{fear.define ?
								<Box sx={{
									position: "relative", width: "100%", height: "100%", zIndex: 0
								}}>
									<BallButton
										showValue={false}
										cursorBallButton="default"
										topBallButtonPosition="0px"
										leftBallButtonPosition="0px"
									/>
								</Box>
								:
								<Box
									onClick={() => onClick(fear.title)}
									sx={{ position: "relative", cursor: "pointer", zIndex: 1 }}
								>
									<SVGfear />
									<Box
										sx={{
											background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.169) 55.2%, rgba(0, 0, 0, 0) 100%)",
											width: "136px",
											opacity: 0.3,
											height: "22px",
											position: "absolute",
											bottom: "-20px",
											left: 0
										}} />
								</Box>
							}
						</Box>
					</Box>
				))}
				{open
					? (
						<FormAccordion
							formik={formik}
							title={title}
							expanded={expanded}
							setExpanded={setExpanded}
						/>
					)
					: null
				}
			</Box>
			{showBtnNextStep
				? (
					<StepperNavigationWrapper>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton direction="right" onClick={handleStep} />
					</StepperNavigationWrapper>
				)
				: null
			}
		</Box>
	);
}

export default FaceToFears;
