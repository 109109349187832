import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, alpha } from "@mui/material";
import octa from "assets/images/octaUnsubscribe.png";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { MouseEvent } from "react";
import { Link } from "react-router-dom";

export interface IUnsubscribeModalProps
{
	/**
	 * @default false
	 */
	open?: boolean;
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton" | "reject" | "resolve"): void;
}

function AccessToMission({ open = false, onClose, ...props }: IUnsubscribeModalProps & DialogProps)
{
	const userContext = useUser();

	// Render
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper":
				{
					maxWidth: "unset",
					width: "980px",
					height: "425px",
					padding: "56px 64px",
					backgroundColor: "#FFFFFF",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				},
				"& .MuiBackdrop-root":
				{
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
					backdropFilter: "blur(25px)"
				}
			}}
			{...props}
		>
			<CloseIcon
				onClick={() => onClose({}, "closeButton")}
				sx={{
					position: "absolute",
					top: "32px",
					right: "32px",
					cursor: "pointer",
					color: "primary.main"
				}}
			/>
			<Box sx={{ font: "700 40px/51px Lora-Bold", color: "#215C75" }}>
				Get access to the full quest
			</Box>

			<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", marginTop: "24px", maxWidth: "530px" }}>
				{userContext?.state?.user?.name}, this brings you to the end of free missions.
				With the full quest you will gain crystal clarity on your life, we promise!
			</Box>

			<CustomButton.Contained
				component={Link}
				to="/packages"
				onClick={(event: MouseEvent<HTMLButtonElement>) => onClose(event, "reject")}
				sx={{ marginTop: "auto" }}
			>
				View packages
			</CustomButton.Contained>

			<Box
				component="img"
				src={octa}
				alt=""
				sx={{ width: "230px", position: "absolute", top: "18px", right: "116px" }}
			/>
		</Dialog>
	);
}

export default AccessToMission;
