import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SendIcon from "@mui/icons-material/Send";
import { Masonry } from "@mui/lab";
import { Box, CircularProgress, Grow, Zoom, alpha } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import searchImages from "pages/missionSix/components/YourVisionBoard/searchImages";
import { DragEvent, FormEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import throttle from "utils/throttle";
import { ValidationError } from "yup";
import { BOARD_RULES } from ".";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";

export interface ICreateProps
{
	updateItems(): void;
}

interface IPageInfo
{
	page: number;
	total_pages: number;
}

const OFFSET_MARGIN_BOTTOM: number = 300;
const DEFAULT_SEARCH_TERMS: string = "small house in the mountains";

function Create({ updateItems }: ICreateProps)
{
	const navigate = useNavigate();
	const imagesBoxRef = useRef<HTMLDivElement>(null);

	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	const [ currentLink, setCurrentLink ] = useState<string | null>(null);
	const [ images, setImages ] = useState<string[]>([]);
	const [ savedImages, setSavedImages ] = useState<string[]>(() =>
	{
		return getLocalStorage<string[]>("dashboard-visionBoard-create-images") ?? [];
	});
	const [ pageInfo, setPageInfo ] = useState<IPageInfo>({ page: 1, total_pages: 1 });
	const [ search, setSearch ] = useState<string>("");
	const [ boardTitle, setBoardTitle ] = useState<string | null>(() =>
	{
		return getLocalStorage<string>("dashboard-visionBoard-create-title");
	});

	const [ isShowModalEditTitle, setIsShowModalEditTitle ] = useState<boolean>(false);
	const [ newBoardTitle, setNewBoardTitle ] = useState<string>(boardTitle as string);
	const [ editTitleError, setEditTitleError ] = useState<string | null>(null);

	// Effects
	useEffect(() =>
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		searchImages({ query: search || DEFAULT_SEARCH_TERMS })
			.then(({ results, total_pages }) =>
			{
				setPageInfo({ page: 1, total_pages });
				setImages(results.map(({ image }) => image));
			})
			.finally(() => setIsFetch(false));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-visionBoard-create-images", savedImages);
	}, [ savedImages ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-visionBoard-create-title", boardTitle);
	}, [ boardTitle ]);

	// Handles
	function onSave()
	{
		if (isFetch === true)
		{
			return;
		}

		if (savedImages.length < BOARD_RULES.MINIMUM_SELECT_IMAGES)
		{
			return;
		}

		setIsFetch(true);

		Api.missions
			.setDataMissionSix(savedImages, boardTitle as string, true)
			.then(() =>
			{
				localStorage.removeItem("dashboard-visionBoard-create-title");
				localStorage.removeItem("dashboard-visionBoard-create-images");

				updateItems();

				navigate("/dashboard/artifacts/vision-board");
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	function onAdd(link: string)
	{
		if (savedImages.includes(link) === true)
		{
			return;
		}

		setSavedImages((prevState) => [ ...prevState, link ]);
	}

	function onDelete(link: string)
	{
		const imageIndex = savedImages.findIndex((image) => image === link);
		if (imageIndex === -1)
		{
			return;
		}

		setSavedImages((prevState) =>
		{
			const state = [ ...prevState ];

			state.splice(imageIndex, 1);

			return state;
		});
	}

	const onScrollThrottle = throttle(() =>
	{
		if (imagesBoxRef.current === null || isFetch === true)
		{
			return;
		}

		const containerScrollTop = imagesBoxRef.current.scrollTop;
		const containerHeight = imagesBoxRef.current.clientHeight;
		const scrollHeight = imagesBoxRef.current.scrollHeight;

		if (scrollHeight - containerScrollTop - containerHeight - OFFSET_MARGIN_BOTTOM > 0)
		{
			return;
		}

		if (pageInfo.page === pageInfo.total_pages)
		{
			return;
		}

		const page = pageInfo.page + 1;

		setIsFetch(true);

		searchImages({ query: search || DEFAULT_SEARCH_TERMS, page })
			.then(({ results, total_pages }) =>
			{
				setPageInfo({ page, total_pages });
				setImages((images) =>
				{
					return [ ...images, ...results.map(({ image }) => image) ];
				});
			})
			.finally(() => setIsFetch(false));
	}, 500);

	function onSearch()
	{
		if (isFetch === true || search.trim() === "")
		{
			return;
		}

		setIsFetch(true);

		searchImages({ query: search.trim() })
			.then(({ results, total_pages }) =>
			{
				setPageInfo({ page: 1, total_pages });
				setImages(results.map(({ image }) => image));
			})
			.finally(() => setIsFetch(false));
	}

	function onChangeBoardTitle(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			BOARD_RULES.TITLE_YUP().validateSync(newBoardTitle);
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		setEditTitleError(error);

		if (error !== null)
		{
			return;
		}

		setBoardTitle(newBoardTitle);
		setIsShowModalEditTitle(false);
	}

	// Drag & Drop
	function onDragStart(link: string)
	{
		setCurrentLink(link);
	}

	function onDragOver(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function onDrop(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();

		if (currentLink === null)
		{
			return;
		}

		if (savedImages.includes(currentLink))
		{
			return;
		}

		setSavedImages((prevState) => [ ...prevState, currentLink ]);

		setCurrentLink(null);
	}

	// Render
	if (boardTitle === null)
	{
		return <Navigate to="/dashboard/artifacts/vision-board" />;
	}

	return (
		<ArtifactsContainer
			title={
				<>
					{boardTitle}

					<Box
						component="button"
						sx={{
							padding: "10.5px",
							backgroundColor: "#3E809D",
							border: "unset",
							borderRadius: "50%",
							transition: "linear 300ms opacity",
							cursor: "pointer",

							"&:hover":
							{
								opacity: 0.7
							}
						}}
						onClick={() => setIsShowModalEditTitle(true)}
					>
						<ModeEditIcon sx={{ color: "#FFFFFF", fontSize: "19px" }} />
					</Box>
				</>
			}
			onClose={() => navigate("/dashboard/artifacts/vision-board")}
			headerRight={
				<CustomButton.Outline
					onClick={onSave}
					loading={isFetch}
					disabled={savedImages.length < BOARD_RULES.MINIMUM_SELECT_IMAGES}
					sx={{ marginBottom: "-5px" }}
				>
					Save
				</CustomButton.Outline>
			}
			sx={{
				"& .ArtifactsContainer-header__title":
				{
					display: "flex",
					alignItems: "center",
					gap: "24px"
				}
			}}
		>
			<Box sx={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gridTemplateRows: "1fr",
				gap: "24px"
			}}>
				<Box sx={{
					height: { xl: "702px", lg: "545px", xs: "545px" },
					padding: "16px",
					display: "grid",
					gridTemplateRows: "auto 1fr",
					gap: "24px",
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
					border: "1px solid #3E809D",
					borderRadius: "24px"
				}}>
					<Box sx={{ position: "relative" }}>
						<CustomInput.Base
							placeholder="Search"
							value={search}
							onChange={({ target }) => setSearch(target.value)}
							onKeyDown={({ key }) =>
							{
								if (key === "Enter")
								{
									onSearch();
								}
							}}
							sx={{
								width: "100%",
								height: "50px",
								border: "1px solid " + alpha("#3E809D", 0.4),
								borderRadius: "30px",
								transition: "linear 300ms border-color",

								"&.Mui-focused":
								{
									borderColor: "#3E809D"
								},
								"& .MuiInputBase-input":
								{
									color: "primary.dark",
									padding: "10px 80px 10px 24px"
								}
							}}
						/>

						<TransitionGroup>
							{/* Loading button */}
							{isFetch === true &&
								(
									<Zoom>
										<TooltipStyled title="Searching..." placement="bottom" arrow>
											<CircularProgress
												size={24}
												sx={{
													position: "absolute",
													right: "10px",
													top: "11.5px",
													transform: "translateY(-50%)"
												}}
											/>
										</TooltipStyled>
									</Zoom>
								)
							}
							{/* Search button */}
							{isFetch === false &&
								(
									<Zoom>
										<TooltipStyled
											title={search.trim().length < 2 ? "Minimum 2 characters" : "Search"}
											TransitionComponent={Zoom}
											placement="bottom"
											arrow
										>
											<Box
												component="button"
												type="submit"
												sx={{
													width: "24px",
													height: "24px",
													padding: "unset",
													background: "unset",
													border: "unset",
													position: "absolute",
													right: "13px",
													top: "13px",
													cursor: (search.trim().length < 2 ? "not-allowed" : "pointer"),
													opacity: (search.trim().length < 2 ? 0.5 : 1),
													transition: "linear 300ms opacity",

													"&:hover":
													{
														opacity: 0.7
													}
												}}
												onClick={onSearch}
											>
												<SendIcon color="primary" />
											</Box>
										</TooltipStyled>
									</Zoom>
								)
							}
							{/* Clear button */}
							{search.trim() !== "" &&
								(
									<Zoom>
										<TooltipStyled
											title="Clear"
											TransitionComponent={Zoom}
											placement="bottom"
											arrow
										>
											<Box
												component="button"
												sx={{
													width: "24px",
													height: "24px",
													padding: "unset",
													background: "unset",
													border: "unset",
													position: "absolute",
													right: "45px",
													top: "13px",
													cursor: "pointer",
													transition: "linear 300ms opacity",

													"&:hover":
													{
														opacity: 0.7
													}
												}}
												onClick={() => setSearch("")}
											>
												<CloseRoundedIcon color="primary" />
											</Box>
										</TooltipStyled>
									</Zoom>
								)
							}
						</TransitionGroup>
					</Box>

					<Box
						ref={imagesBoxRef}
						className="customScroll"
						onScroll={onScrollThrottle}
						sx={{
							height: "100%",
							overflowY: "auto",
							paddingRight: "24px"
						}}
					>
						<Masonry spacing="8px" columns={3}>
							{images.map((image, index) => (
								<Grow key={index + image} in>
									<Box sx={{ position: "relative" }}>
										{savedImages.includes(image) === false &&
											<TooltipStyled title="Add" placement="left" arrow>
												<Box
													component="button"
													onClick={() => onAdd(image)}
													sx={{
														position: "absolute",
														top: "16px",
														right: "16px",
														width: "24px",
														height: "24px",
														padding: "unset",
														backgroundColor: "#FFFFFF",
														border: "unset",
														borderRadius: "50%",
														cursor: "pointer",
														transition: "linear 300ms opacity",
														"&:hover":
														{
															opacity: 0.7
														}
													}}
												>
													<AddRoundedIcon color="primary" />
												</Box>
											</TooltipStyled>
										}
										<Box
											draggable
											onDragStart={() => onDragStart(image)}
											component="img"
											src={image}
											alt=""
											sx={{ width: "100%", borderRadius: "15px" }}
										/>
									</Box>
								</Grow>
							))}
						</Masonry>
					</Box>
				</Box>

				<Box sx={{
					height: { xl: "702px", lg: "545px", xs: "545px" },
					padding: { xl: "24px", lg: "16px", xs: "16px" },
					display: "grid",
					gridTemplateRows: "auto 1fr",
					gap: "24px",
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				}}>
					<Box sx={{ color: "primary.main", font: "700 24px/31px Lora-Bold", textAlign: "center" }}>
						My Vision Board
					</Box>

					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							paddingRight: "24px"
						}}
						onDrop={onDrop}
						onDragOver={onDragOver}
					>
						<Masonry spacing="8px" columns={3}>
							{savedImages.map((image, index) => (
								<Grow key={index + image} in>
									<Box sx={{ position: "relative" }}>
										<TooltipStyled title="Delete" placement="left" arrow>
											<Box
												component="button"
												onClick={() => onDelete(image)}
												sx={{
													position: "absolute",
													top: "16px",
													right: "16px",
													width: "24px",
													height: "24px",
													padding: "unset",
													backgroundColor: "#FFFFFF",
													border: "unset",
													borderRadius: "50%",
													cursor: "pointer",
													transition: "linear 300ms opacity",
													"&:hover":
													{
														opacity: 0.7
													}
												}}
											>
												<CloseRoundedIcon color="primary" />
											</Box>
										</TooltipStyled>

										<Box
											component="img"
											src={image}
											alt=""
											sx={{ width: "100%", borderRadius: "15px" }}
										/>
									</Box>
								</Grow>
							))}
						</Masonry>
					</Box>
				</Box>
			</Box>

			<Dialog
				open={isShowModalEditTitle}
				onClose={() => setIsShowModalEditTitle(false)}
				title="Change board name"
			>
				<form onSubmit={onChangeBoardTitle}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="name"
							value={newBoardTitle}
							inputProps={{ maxLength: BOARD_RULES.MAXIMUM_LENGTH_TITLE }}
							onChange={({ target }) => setNewBoardTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{
								color: "#DC4242", font: "400 14px/19px 'Open Sans'"
							}}>
								{editTitleError}
							</Box>

							<Box sx={{
								color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'"
							}}>
								{newBoardTitle.length}/{BOARD_RULES.MAXIMUM_LENGTH_TITLE} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

export default Create;
