import { alpha, Box, Divider, Fade, FormControl, Link } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import Api from "api";
import { IResponseAuthenticate } from "api/Auth";
import { ReactComponent as SVGGoogle } from "assets/images/icons/google.svg";
import ImageJellyfish from "assets/images/jellyfish.png";
import { ReactComponent as SVGLogo } from "assets/images/logo.svg";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomButton from "components/customButton";
import CustomInput from "components/CustomInput";
import config from "config";
import { Formik } from "formik";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import { setLocalStorage } from "utils/localStorage";
import * as yup from "yup";
import "./index.css";
import ResetPasswordModal from "./ResetPassword.modal";

// const MIN_PASSWORD = 6;
// const MAX_PASSWORD = 16;

interface IMenuItem { link?: string; title: string; }

const MenuItems: IMenuItem[] = [
	{
		link: "https://mylifequest.io",
		title: "Homepage"
	},
	{
		link: "/privacy-policy",
		title: "Privacy Policy"
	},
	{
		link: "/terms-conditions",
		title: "Terms & Conditions"
	},
	{
		title: "hello@mylifequest.io"
	}
];

function LoginPage()
{
	const navigate = useNavigate();
	const location = useLocation();
	const userContext = useUser();

	// States
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ authErrorMessage, setAuthErrorMessage ] = useState<string | null>(null);
	const [ isShowModalResetPassword, setIsShowModalResetPassword ] = useState<boolean>(false);

	// const uri = `${ process.env.REACT_APP_GOOGLE_AUTH_URI }?redirect_uri=${ process.env.REACT_APP_GOOGLE_REDIRECT_URI }&response_type=code&client_id=${ process.env.REACT_APP_GOOGLE_CLIENT_ID }&scope=${ process.env.REACT_APP_SCOPES }&access_type=offline`;

	// Effects
	useEffect(() =>
	{
		if (userContext.state.isAuthenticated)
		{
			navigate((location.state as { from?: Location; })?.from?.pathname || "/dashboard");
		}
	}, [ userContext.state.isAuthenticated, navigate, location ]);

	// Handlers
	const login = useGoogleLogin({

		onSuccess: (tokenResponse) =>
		{
			Api.auth
				.authenticateByGoogle(tokenResponse.code)
				.then(onSuccessAuthenticate)
				.catch(onErrorAuthenticate)
				.finally(() => setIsLoading(false));
		},
		flow: "auth-code"
	});

	function onSuccessAuthenticate(res: IResponseAuthenticate)
	{
		if (!res.status)
		{
			return setAuthErrorMessage("Account suspended");
		}

		setLocalStorage("accessToken", res.accessToken);
		setLocalStorage("email", res.email);
		setLocalStorage("name", res.name);
		setLocalStorage("userId", res.id);
		setLocalStorage("onboarded", res.onboarded);
		setLocalStorage("completedMission", res.completedMission);

		setAuthErrorMessage(null);

		userContext.dispatch(
			{
				type: UserActionType.LOGIN,
				payload: {
					user: res,
					accessToken: res.accessToken
				}
			}
		);

		navigate((location.state as { from?: Location; })?.from?.pathname || "/dashboard");

	}

	function onErrorAuthenticate(error: any)
	{
		console.error(error);

		setAuthErrorMessage("Email or password is incorrect");
	}

	function onFormSubmit({ email, password, isRemember }: { email: string; password: string; isRemember: boolean; })
	{
		console.info("Form data:", { email, password, isRemember });

		if (isLoading === true)
		{
			return;
		}

		setAuthErrorMessage(null);

		setIsLoading(true);

		Api.auth
			.authenticate(email, password)
			.then(onSuccessAuthenticate)
			.catch(onErrorAuthenticate)
			.finally(() => setIsLoading(false));
	}

	// Render
	return (
		<Box sx={{
			padding: { sm: "unset", xs: "24px 24px 8px 24px" },
			minHeight: "100vh",
			background: "linear-gradient(35deg, #7889B0  0%, #7FA9D0 75%, #CACFCC 100%)",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			color: "#EDFAFD"
		}}>
			<Box
				component={SVGLogo}
				sx={(theme) => ({
					fill: "#FFFFFF",
					width: "58px",
					height: "40px",
					marginBottom: { sm: "unset", xs: "24px" },

					[ theme.breakpoints.up("sm") ]:
					{
						position: "absolute",
						left: "20px",
						top: "20px"
					}
				})
				}
			/>

			<Box sx={{ maxWidth: { sm: "680px", xs: "390px" }, width: "100%" }}>
				<Box sx={{ font: { sm: "700 42px/56px Lora-Bold", xs: "700 24px/31px Lora-Bold" }, textAlign: "center" }}>
					Welcome to My Life Quest
				</Box>

				<Box sx={{
					marginTop: { sm: "unset", xs: "8px" },
					font: { sm: "500 22px/30px 'Open Sans'", xs: "500 16px/22px 'Open Sans'" },
					textAlign: "center"
				}}>
					Please sign-in to your account and start the adventure
				</Box>

				{/* Form */}
				<Formik
					validateOnBlur
					validateOnChange
					initialValues={{ email: "", password: "", isRemember: false }}
					onSubmit={onFormSubmit}
					validationSchema={
						yup.object({
							email: config.email.yup(),
							password: config.password.yup()
						})
					}
				>
					{({ values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty }) =>
					{
						return (
							<Box
								component="form"
								onSubmit={handleSubmit}
								sx={{
									border: { sm: "2px solid #DFE7F1", xs: "unset" },
									borderRadius: "20px",
									backgroundColor: { sm: "rgba(245, 242, 242, .4)", xs: "unset" },
									margin: "10px 0"
								}}
							>
								<Box sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									gap: "20px 0",
									padding: { sm: "60px 100px 10px 100px", xs: "unset" }
								}}>
									{/* Email */}
									<FormControl>
										<CustomInput.Base
											type="email"
											placeholder="email"
											name="email"
											autoComplete="username"
											onChange={(...args) =>
											{
												handleChange(...args);
												setAuthErrorMessage(null);
											}}
											onBlur={handleBlur}
											value={values.email}
											sx={{ borderRadius: { sm: "10px", xs: "10px" } }}
										/>

										<Box sx={{
											position: "absolute",
											left: "5px",
											bottom: "-19px",
											color: "#DC4242",
											font: "400 14px/19px 'Open Sans'"
										}}>
											{touched.email ? errors.email : null}
										</Box>
									</FormControl>

									{/* Password */}
									<FormControl>
										<CustomInput.Password
											placeholder="password"
											name="password"
											autoComplete="current-password"
											onChange={(...args) =>
											{
												handleChange(...args);
												setAuthErrorMessage(null);
											}}
											onBlur={handleBlur}
											value={values.password}
											sx={{ borderRadius: { sm: "10px", xs: "10px" } }}
										/>

										<Box sx={{
											position: "absolute",
											left: "5px",
											bottom: "-19px",
											color: "#DC4242",
											font: "400 14px/19px 'Open Sans'"
										}}>
											{touched.password ? errors.password : null}
										</Box>
									</FormControl>

									<Fade in={authErrorMessage !== null}>
										<Box sx={{ font: "500 16px/20px 'Open Sans'", color: "#DC4242" }}>
											{authErrorMessage}
										</Box>
									</Fade>

									<Box sx={{
										margin: { sm: "10px 0", xs: "unset" },
										display: "flex",
										flexWrap: "wrap",
										alignItems: "center",
										justifyContent: "space-between",
										gap: "16px"
									}}>
										<CheckboxStyledWithLabel
											label="Remember me"
											checkboxProps={{ name: "isRemember" }}
											sx={{
												"--line-height": { sm: "20px", xs: "19px" },
												font: { sm: "400 16px/20px 'Open Sans'", xs: "400 14px/19px 'Open Sans'" }
											}}
										/>

										<Link
											href="/reset-password-step-one"
											sx={{
												font: "500 16px/20px 'Open Sans'",
												textDecoration: "none",
												color: "primary.main",
												display: { sm: "block", xs: "none" }
											}}
										>
											Forgot Password?
										</Link>
										<Box
											type="button"
											component="button"
											onClick={() => setIsShowModalResetPassword(true)}
											sx={{
												display: { sm: "none", xs: "flex" },
												padding: "unset",
												background: "unset",
												border: "unset",
												cursor: "pointer",
												color: "primary.main",
												font: "500 14px/19px 'Open Sans'"
											}}
										>
											Forgot Password?
										</Box>
									</Box>

									<Box
										component="img"
										src={ImageJellyfish}
										alt=""
										sx={{
											display: { sm: "none", xs: "block" },
											margin: "-7px auto 0 auto",
											width: "208px",
											height: "127px",
											transform: "rotateY(180deg)"
										}}
									/>

									<CustomButton.Base
										type="submit"
										loading={isLoading}
										disabled={!(dirty && isValid)}
										sx={{
											boxShadow: "unset",
											margin: "0 auto",
											width: { sm: "unset", xs: "100%" }
										}}
									>
										Login
									</CustomButton.Base>

									<Box sx={{
										marginTop: { sm: "-12", xs: "-12px" },
										font: { sm: "400 22px/30px 'Open Sans'", xs: "400 14px/19px 'Open Sans'" },
										color: "#000000",
										textAlign: "center"
									}}>
										New on out platform?{" "}
										<Link component={RouterLink} to="/registration" sx={{ textDecoration: "none", color: "primary.main" }}>
											Create an account
										</Link>
									</Box>
								</Box>

								<Divider
									sx={{
										display: { sm: "flex", xs: "none" },

										"&::before, &::after":
										{
											borderWidth: "2px",
											borderColor: "#FFFFFF"
										}
									}}
								>
									<Box sx={{ font: "500 22px/30px 'Open Sans'", color: "#403F3F" }}>
										Or
									</Box>
								</Divider>

								{/* Other auth methods */}
								<Box sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								}}>
									<CustomButton.Outline
										onClick={() => login()}
										sx={{
											width: { sm: "unset", xs: "100%" },
											marginTop: { sm: "unset", xs: "24px" },
											marginBottom: { sm: "10px" },
											textWrap: { sm: "unset", xs: "nowrap" },

											"& svg":
											{
												flexShrink: 0
											},
											"& svg *":
											{
												stroke: "unset"
											}
										}}
									>
										<Box
											component={SVGGoogle}
											sx={{
												width: "24px",
												height: "24px"
											}}
										/>

										Sign in with Google
									</CustomButton.Outline>
								</Box>
							</Box>
						);
					}
					}
				</Formik>

				{/* Menu */}
				<Box component="nav">
					<Box
						component="ul"
						sx={{
							listStyle: "none",
							padding: 0,
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "center",
							gap: { sm: "30px", xs: "22px" },

							"& li":
							{
								color: alpha("#FFFFFF", 0.7),
								transition: "linear 200ms color",
								font: { sm: "400 17px Lora", xs: "400 14px/18px Lora" },
								"&:hover":
								{
									color: "#FFFFFF"
								},
								"& a":
								{
									color: "inherit",
									textDecoration: "none"
								}
							}
						}}
					>
						{MenuItems.map((menuItem) => (
							<Box component="li" key={menuItem.title}>
								{menuItem.link
									? (<Box component="a" href={menuItem.link} target="_blank">{menuItem.title}</Box>)
									: (<Box>{menuItem.title}</Box>)
								}

							</Box>
						))}
					</Box>
				</Box>
			</Box>

			<Box
				component="img"
				src={ImageJellyfish}
				alt=""
				sx={{
					display: { sm: "block", xs: "none" },
					margin: "10px auto 0 auto",
					width: "246px",
					height: "143px"
				}}
			/>

			<ResetPasswordModal open={isShowModalResetPassword} onClose={() => setIsShowModalResetPassword(false)} />
		</Box>
	);
}

export default LoginPage;
