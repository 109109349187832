import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { setLocalStorage } from "../../../../../utils/localStorage";
import { InputField } from "./FormFields/InputField";

interface IForm
{
	setStepCourse: any;
	closeForm(): void;
	componentInputs: any;
	addInput: any;
	handleChange: any;
}

// export default function FormMyPurposeIs(props: any)
export default function FormMyPurposeIs({ setStepCourse, closeForm, componentInputs, addInput, handleChange }: IForm)
{
	function handleSubmit(step: number)
	{
		if ((componentInputs[ 0 ].value))
		{
			setStepCourse(step);
			setLocalStorage("mission-nine-inputs", componentInputs);
		}
	}

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "100%", maxWidth: "980px", minWidth: { lg: "785px", xl: "906px" } }}>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
				<Box
					sx={{ display: "flex", flexDirection: "column", height: "100%", maxHeight: "520px", justifyContent: "space-between" }}
					component="form"
					autoComplete="off"
				>
					<Box sx={{
						display: "flex",
						width: "100%",
						height: "auto",
						m: "0 auto",
						marginTop: "43px",
						marginLeft: "38px",
						border: "1px solid rgba(255, 255, 255, .6)",
						borderRadius: "24px",
						wordBreak: "break-all",
						flexDirection: "column",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						boxShadow: "0px 5px 10px 0px #A7C7E199",
						padding: "24px 32px 13px 32px"
					}}>
						<Box
							sx={{ position: "absolute", top: "22px", right: "22px" }}
							onClick={closeForm}
						>
							<CloseIcon sx={{ fill: "#fff", fontSize: "14px", width: "24px", backgroundColor: "#3E809D", borderRadius: "40px", height: "24px", p: "6px", cursor: "pointer" }} />
						</Box>

						<Box sx={{ margin: "0px" }}>
							<Typography sx={{ marginLeft: "10px", marginBottom: "17px", color: "#215C75", font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
								{/* My purpose is... */}
								{componentInputs[ 0 ].title}
							</Typography>

							<InputField
								required
								value={componentInputs[ 0 ].value}
								inputProps={{ maxLength: 120 }}
								onChange={(e: any) => handleChange(e.target.value, 0)}
								fullWidth
								autoFocus
								multiline
								rows={3}
								placeholder={componentInputs[ 0 ].placeholder}
								helperText={`${ componentInputs[ 0 ].value.length }/120 characters`}
							/>
						</Box>
					</Box>
					<Box sx={{ marginLeft: "38px", display: "flex", width: "100%", flexDirection: "row", padding: "15px 0px", justifyContent: "flex-end" }}>
						<Box
							sx={{
								position: "relative",
								color: "#fff",
								backgroundColor: ((!componentInputs[ 0 ].value) ? "#3E809D80" : "#3E809D"),
								borderRadius: "30px",
								width: "40px",
								height: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": {
									cursor: ((!componentInputs[ 0 ].value) ? "normal" : "pointer")
								}
							}}
							onClick={() => handleSubmit(2)}>
							<ArrowForwardIosIcon />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
