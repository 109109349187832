import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import { IGetDataMissionsFive } from "api/Missions";
import bgImgInner from "assets/images/missions/mission-ten/bgImgInner.png";
import ContentAdmin from "components/contentAdmin";
import { useState } from "react";

export interface IMission2Props
{
	isOpen?: boolean;

	closeModal?(): void;
	imgUrl?: string;
	title?: string;

}

function ModalMissionFive({ isOpen = false, closeModal, imgUrl, title }: IMission2Props)
{

	const [ data, setData ] = useState<IGetDataMissionsFive[ "data" ][ "mission_data" ] | null>(null);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	// Handles
	// useEffect(() =>
	// {
	// 	Api.missions
	// 		.getDataMissionFive()
	// 		.then(({ data }) =>
	// 		{
	// 			setIsErrorLoading(false);
	// 			setData(data.mission_data);
	// 		})
	// 		.catch((error) =>
	// 		{
	// 			setIsErrorLoading(true);
	// 			console.error(error);
	// 		});
	// }, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			maxHeight: "392px",
			position: "relative",
			// backgroundColor: '#D9D9D9',
			backgroundImage: `url(${ bgImgInner })`,
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
			borderRadius: "24px",
			borderTopLeftRadius: "75px",
			borderBottomLeftRadius: "75px"
		}}>
			<CancelIcon
				onClick={closeModal}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					fill: "#3E809D",
					"&:hover": { cursor: "pointer" }
				}}
			/>
			<Box
				component="img"
				src={imgUrl}
				alt="side image"
				className="ImageSideModal-image"
				sx={{
					width: "140px",
					height: "392px",
					borderRadius: "100px",
					position: "relative",
					left: "-5px",
					top: "2px"
				}}
			/>

			<Box sx={{ width: "100%", padding: "24px 24px 30px 40px" }}>

				<Typography sx={{ font: { lg: "700 24px/40px Lora", xl: "700 40px/51px Lora" }, color: "#215C75", mb: "12px" }}>
					{title}
				</Typography>
				<Typography sx={{ font: { lg: '400 18px/27px "Open Sans"', xl: '400 24px/33px "Open Sans"' }, color: "#4D4D4D", mb: "12px" }}>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="modalMissionFive_txt"
						defaultValue="If you only do one thing, then check in with your North Star regularly. It is there to show you the direction in life. Are you living your purpose? Are you living by what you stand for? Are you surrounded by people in your tribe?"
						position="top"
						isDescription
					/>
				</Typography>
				{/* <Box className="customScroll" sx={{ overflowY: "auto", height: '270px', paddingRight: "8px" }}>
						{(data === null || data === undefined) && isErrorLoading === false &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Loading data <CircularProgress size={24} />
								</Box>
							)
						}
						{(data === null || data === undefined) && isErrorLoading === true &&
							(
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
									Failed to retrieve data, please try again
								</Box>
							)
						}
						{data !== null && data !== undefined && isErrorLoading === false &&
							(
								<Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
									{Object.entries(data).map(([ title, text ], index) =>
									{
										return (
											<div key={title + index}>
												<Box component="h3" sx={{ margin: "0 0 8px 0", font: "700 24px/31px Lora-Bold", color: "#215C75", textTransform: "capitalize" }}>
													{title}
												</Box>

												<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D" }}>
													{text}
												</Box>
											</div>
										);
									})}
								</Box>
							)
						}
					</Box> */}
			</Box>
		</Box>
	);
}

export default ModalMissionFive;
