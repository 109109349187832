import { Box, Typography } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Meditate from "../../../assets/images/meditate.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const EverythingPossibleFinish = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ imgHeight, setImgHeight ] = useState("285px");
	const [ imgWidth, setImgWidth ] = useState("470px");

	useEffect(() =>
	{
		if (window.matchMedia("(max-width: 425px)").matches)
		{
			setImgWidth("255px");
			setImgHeight("155px");
		}
		else if (window.matchMedia("(max-width: 1024px)").matches)
		{
			setImgWidth("255px");
			setImgHeight("155px");
		}
		else if (window.matchMedia("(max-width: 1280px)").matches)
		{
			setImgWidth("255px");
			setImgHeight("155px");
		}
		else
		{
			setImgWidth("470px");
			setImgHeight("285px");
		}
	}, []);

	function nextStep()
	{
		setTimeout(() => missionContext.setStep(5), 0);
	}

	return (
		<Box sx={{ position: "relative", top: "-80px", display: "flex", flexDirection: "column", maxHeight: "80vh" }}>
			<Box sx={{ width: "100%", maxWidth: "980px", m: "0 auto", display: "flex", flexDirection: "column", textAlign: "center" }}>
				<Box>
					<img
						src={Meditate}
						alt="Meditate"
						style={{
							width: imgWidth,
							height: imgHeight
						}}
					/>
				</Box>

				<Typography sx={{ color: "#fff", font: { xs: '700 24px/30px "Lora-Bold"', xl: '700 40px/52px "Lora-Bold"' } }}>
					<ContentAdmin
						limit={111}
						keyContent="contentMissionEleven"
						keyText="ep_title"
						defaultValue="“Everything is possible.\nThe impossible just takes longer. “"
						position="top"
						isDescription
					/>

				</Typography>
				<Typography sx={{ width: { xs: "400px", xl: "675px" }, margin: "auto", marginBottom: "16px", color: "#E9EDF0", font: { xs: "400 16px/22px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }, textAlign: "right" }}>
					<ContentAdmin
						limit={50}
						keyContent="contentMissionEleven"
						keyText="ep_auth"
						defaultValue="Dan Brown"
						position="right"
					/>
				</Typography>
			</Box>
			<Box
				ref={ref}
				{...props}
				sx={{ width: "100%", maxWidth: { xs: "784px", xl: "970px" }, m: "0 auto", display: "flex", flexDirection: "column" }}>
				<Box sx={{
					display: "flex",
					width: "100%",
					height: "100%",
					margin: "0 auto",
					borderRadius: "24px",
					wordBreak: "break-word",
					background: "#E9EDF0",
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
					position: "relative",
					maxHeight: "318px",
					overflow: "hidden"
				}}>
					<Box sx={{
						width: "100%",
						position: "relative",
						padding: {
							xs: "15px 30px 35px 30px",
							md: "23px 24px 25px",
							xl: "40px"
						},
						wordBreak: "break-word"
					}}>

						<Typography sx={{
							color: "#4D4D4D",
							font: { xs: "400 14px/19px Open Sans", xl: "400 16px/22px Open Sans" },
							textAlign: "left"
						}}>
							<ContentAdmin
								limit={650}
								keyContent="contentMissionEleven"
								keyText="ep_txt"
								defaultValue="Congratulations on completing Your Life Quest! \n\n This has been a long journey of ups and downs, discoveries and revelations. You put in so much effort into setting up a solid foundation for your life, you should be proud!\n\n Keep it up, trailblazer. Be a chef, not a cook, and pave your own path. Follow your dreams, stay strong and never give up. The world needs trailblazers like you, people who are not afraid to challenge the status quo on a quest for a better, more fulfilling life.\n\n Thank you for chosing Life Startup as your growth partner. We are truly honored and are always here for you if you need us. You are awesome :)"
								position="left"
								isDescription
							/>
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "center", mt: { xs: "14px", xl: "40px" } }}>
					<CustomButton.Outline
						sx={{
							fontSize: { xs: "18px", xl: "24px" },
							lineHeight: { xs: "24px", xl: "33px" },
							background: "#3E809D",
							border: "#3E809D",
							color: "#fff",
							width: "240px"
						}}
						onClick={nextStep}
					>
						<ContentAdmin
							limit={111}
							keyContent="contentMissionEleven"
							keyText="finish"
							defaultValue="Finish"
							position="top"
						/>
					</CustomButton.Outline>
				</Box>
			</Box>
		</Box>
	);
});

export default EverythingPossibleFinish;
