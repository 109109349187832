import { Box } from "@mui/material";
import { forwardRef, Fragment, Ref, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import Buttons from "../clockMorning/feature/Buttons";
import Activities from "./feature/Activities";
import Environment from "./feature/Environment";
import People from "./feature/People";

const ClockNight = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const [ stepClockNight, setStepClockNight ] = useState<number>(1);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean, boolean ]>([ true, true, true ]);

	function handleBlip(key: number)
	{
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { [ key ]: false })
		));
	}

	const Components = [
		<Buttons setStepClockMorning={setStepClockNight} disabledButtons={disabledButtons} handleBlip={handleBlip} />,
		<Environment setStepClockMorning={setStepClockNight} />,
		<Activities setStepClockMorning={setStepClockNight} />,
		<People setStepClockMorning={setStepClockNight} />
	];

	return (
		<Box ref={ref} sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { xl: "980px", xs: "784px" }, m: "10px auto 0px" }}>
			<Box sx={{ maxWidth: "100%", mt: "-5px" }}>
				<PanelTitle
					positionOcta
					color="#E9EDF0"
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionFive"
						keyText="clock_night_title"
						defaultValue="Time to design your night "
						position="right"
					/>}
				/>
				{/* <TransitionGroup className="TransitionGroup">
					{Components.map((Component, index) =>
					{
						if (stepClockNight === index + 1)
						{
							return (
								<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
									{Component}
								</Grow>
							);
						}
						return <Fragment key={index}></Fragment>;
					})}
					{stepClockNight > Components.length && <Grow timeout={{ enter: 1000, exit: 0 }} unmountOnExit><h1>Step {stepClockNight}</h1></Grow>}
				</TransitionGroup> */}
				{Components.map((Component, index) =>
				{
					if (stepClockNight === index + 1)
					{
						return (
							<Box key={index}>{Component}</Box>
						);
					}

					return <Fragment key={index} />;
				})}
			</Box>
		</Box>
	);
});

export default ClockNight;
