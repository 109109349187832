import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Money from "../../../../assets/images/Money.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function MoneyDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Money} alt="Money" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Money
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Having money-related worries is more common than you think, {NAME}. Most people have financial concerns and fears. But there are strategies and mindsets you can apply to feel more confident about this area of your life." keyContent="contentMissionOne" keyText="moneyScreen1" />
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="Many people feel insecure about their finances because of the lack of knowledge and understanding of how money works. If you haven’t already, educate yourself on personal budgeting, basic rules of investment and other money-related topics to build your confidence." keyContent="contentMissionOne" keyText="moneyDescStart" />
							{/* Many people feel insecure about their finances because of the lack of knowledge and understanding of how money works. If you haven’t already, educate yourself on personal budgeting, basic rules of investment and other money-related topics to build your confidence. */}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Are you planning for financial freedom?\n- How much money per month would you need to feel comfortable?\n- What lifestyle change could help you ease the financial pressure?" keyContent="contentMissionOne" keyText="moneyDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Feeling good about money is a lot more achievable than you think. The secret is understanding that it’s not just about earning more. It is also about living a life full of exciting experiences and adventures, and not just material objects.\\n\\nWhen you live life a meaningful life, you don’t need to ‘bribe’ yourself with stuff. You will feel happier with less. And that’s a tremendous step towards your freedom. Life Strategy Quest will help you look at all aspects of your life and figure it what kind of life would feel most meaningful to you." keyContent="contentMissionOne" keyText="moneyScreen2" />
				{/*
				 Feeling good about money is a lot more achievable than you think. The secret is understanding that it’s not just about earning more. It is also about living a life full of exciting experiences and adventures, and not just material objects.<br></br><br></br>
				When you live life a meaningful life, you don’t need to ‘bribe’ yourself with stuff. You will feel happier with less. And that’s a tremendous step towards your freedom. Life Strategy Quest will help you look at all aspects of your life and figure it what kind of life would feel most meaningful to you.
				*/}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default MoneyDetails;
