import { Box, Typography } from "@mui/material";
import { Ref, forwardRef, useState } from "react";
import IMGBoat from "../../../../assets/images/missions/mission-nine/boat.png";
import IMGMission5 from "../../../../assets/images/missions/mission-nine/mission-five.png";
import IMGMission4 from "../../../../assets/images/missions/mission-nine/mission-four.png";
import IMGMission6 from "../../../../assets/images/missions/mission-nine/mission-six.png";
import IMGMission3 from "../../../../assets/images/missions/mission-nine/mission-three.png";
import IMGMission2 from "../../../../assets/images/missions/mission-nine/mission-two.png";
import IMGReviewEverythingBg from "../../../../assets/images/missions/mission-nine/review-everything-bg.png";
import IMGStart from "../../../../assets/images/missions/mission-nine/start.png";
import IMGYouAreHere from "../../../../assets/images/missions/mission-nine/you-are-here.png";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";
import ImageButton from "../ImageButton";

const TimeToReview = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ missionViews, setMissionViews ] = useState<[ boolean, boolean, boolean, boolean, boolean ]>(
		[ false, false, false, false, false ]
	);

	// Handles
	function setIsView(index: 0 | 1 | 2 | 3 | 4)
	{
		setMissionViews((prevState) =>
		{
			return Object.assign([], prevState, { [ index ]: true });
		});
	}

	function nextStep()
	{

		missionContext.setStep((prevState) => prevState + 1);

	}

	// Render
	return (
		<Box ref={ref} {...props} sx={{ height: "84vh", width: "100%", maxWidth: { lg: "814px", xl: "1030px" }, m: "0 auto" }}>
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					color="#ffffff"
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="review_everything_title"
						defaultValue="Review everything"
						position="right"
					/>}
					subTitle={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="review_everything_subTitle"
						defaultValue="{NAME}, all the hard work behind and now is the time to review everything you’ve done so far to refresh it in your memory before an important step — setting your bold vision."
						position="right"
						isDescription />}
				/>
				<Box sx={{
					position: "relative",
					backgroundImage: `url(${ IMGReviewEverythingBg })`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "82px -4px",
					marginTop: "231px",
					marginLeft: { lg: 0, xl: "-47px" },
					width: "100%",
					minWidth: "1030px",
					transform: { lg: "scale(0.8)", xl: "scale(1)" }
				}}>

					<Box sx={{ position: "absolute", top: "170px", left: "5px", width: "226px" }}>
						<img src={IMGBoat} alt="start" style={{ position: "absolute", top: "0px", left: "0px" }} />
						<Typography
							sx={{
								position: "relative",
								font: "700 24px/31px 'Lora-Bold'",
								top: "58px",
								left: "2px",
								color: "#215C75",
								textAlign: "center"
							}}
						>
							{/* Start of <br />your journey */}
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="review_start"
								defaultValue="Start of \nyour journey"
								position="left"
							/>

						</Typography>
					</Box>

					<Box
						sx={{
							position: "relative",
							display: "flex",
							justifyContent: "space-between",
							gap: "20px",
							height: "180px",
							width: "100%",
							"& .ImageButton-root:nth-of-type(odd)":
							{
								alignSelf: "flex-end"
							}
						}}
					>
						<img src={IMGStart} alt="start" style={{ position: "absolute", top: "44px", left: "44px" }} />
						<ImageButton
							posTop="-8px"
							posLeft="190px"
							value="Values"
							text="What matters to you the most?"
							image={IMGMission2}
							blur={missionViews[ 0 ]}
							onClick={() => { setIsView(0); missionContext.setShowMissionInfo(2); }}
						/>
						<ImageButton
							posTop="-30px"
							posLeft="143px"
							size={100}
							image={IMGMission3}
							blur={missionViews[ 1 ]}
							onClick={() => { setIsView(1); missionContext.setShowMissionInfo(3); }}
							value="Yearnings"
							text="What do you want?"
						/>
						<ImageButton
							posTop="-15px"
							posLeft="133px"
							size={100}
							blur={missionViews[ 2 ]}
							image={IMGMission4}
							onClick={() => { setIsView(2); missionContext.setShowMissionInfo(4); }}
							value="A Job to Love"
							text="What fulfills you?"
						/>
						<ImageButton
							posTop="-15px"
							posLeft="100px"
							size={100}
							blur={missionViews[ 3 ]}
							image={IMGMission5}
							onClick={() => { setIsView(3); missionContext.setShowMissionInfo(6); }}
							value="Perfect day"
							text="What brings you happiness every day?"
						/>
						<ImageButton
							posTop="-15px"
							posLeft="85px"
							size={100}
							image={IMGMission6}
							blur={missionViews[ 4 ]}
							onClick={() => { setIsView(4); missionContext.setShowMissionInfo(7); }}
							value="Vision board"
							text="What does your dream life look like?"
						/>

						<img src={IMGYouAreHere} alt="start" style={{ position: "relative", top: "-44px", left: "42px", width: "98px", height: "146px" }} />
						<Typography
							sx={{
								position: "absolute",
								top: "-83px",
								right: "-55px",
								font: "700 24px/31px 'Lora-Bold'",
								margin: "0px",
								color: "#ffffff",
								textAlign: "center"
							}}
						>
							{/* You are here */}
							<ContentAdmin
								keyContent="contentMissionNine"
								keyText="review_finish"
								defaultValue="You are here"
								position="top"
							/>
						</Typography>
					</Box>

				</Box>
			</Box>
			<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					sx={{ width: "56px", height: "56px", minWidth: "56px", background: !missionViews.every((isView) => isView === true) ? "rgba(255, 255, 255, 0.5)" : "#E9EDF0" }}
					direction="right"
					sxIcon={{ fill: !missionViews.every((isView) => isView === true) ? "#FFFFFF" : "#215C75" }}
					onClick={nextStep}
					disabled={!missionViews.every((isView) => isView === true)}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
});

export default TimeToReview;
