import { Box } from "@mui/material";
import { ReactComponent as SVGfear } from "assets/images/missions/mission-eight/fear.svg";
import ArrowButton from "components/ArrowButton";
import BallButton from "components/BallButton";
import FormAccordion from "components/FormAccordion";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { IFears } from "..";

interface DescFearsProps
{
	arrValues: string[];
	nextStep(): void;
	fears: IFears[];
	setFears: any;
}
interface ArrValues
{
	title: string;
	define: string;
	prevent: string;
	repair: string;
}
function DescFears({ arrValues, nextStep, fears, setFears }: DescFearsProps)
{
	const [ open, setOpen ] = useState(false);
	const [ showBtnNextStep, setShowBtnNextStep ] = useState(false);
	const [ title, setTitle ] = useState("");
	const [ expanded, setExpanded ] = useState([ true, true, true ]);

	useEffect(() =>
	{
		let count = 0;

		fears.forEach((item: IFears) =>
		{
			if (item.define) count += 1;
		});
		if (fears.length !== 0 && count === fears.length) setShowBtnNextStep(true);
	}, [ fears ]);
	useEffect(() =>
	{

		const newArrValues: ArrValues[] = [];

		arrValues.forEach((element: string) =>
		{
			newArrValues.push({
				title: element,
				define: "",
				prevent: "",
				repair: ""
			});
		});
		setFears(newArrValues);
	}, []);

	function positionFears(index: number)
	{
		return {
			position: "absolute",
			left: index === 0
				? { lg: "361px", xl: "421px" }
				: index === 1
					? { lg: "203px", xl: "263px" }
					: index === 2
						? { lg: "497px", xl: "557px" }
						: index === 3
							? { lg: "167px", xl: "227px" }
							: index === 4
								? { lg: "593px", xl: "653px" }
								: index === 5
									? { lg: "51px", xl: "111px" }
									: index === 6 ? { lg: "684px", xl: "744px" } : index === 7 ? { lg: "-70px", xl: "10px" } : index === 8 ? { lg: "110px", xl: "170px" } : index === 9 ? { lg: "270px", xl: "330px" } : index === 10 ? { lg: "440px", xl: "500px" } : index === 11 ? { lg: "610px", xl: "670px" } : { lg: "40px", xl: "100px" },
			top: index === 1
				? { lg: "50px", xl: "110px" }
				: index === 2
					? { lg: "50px", xl: "110px" }
					: index === 3
						? { lg: "149px", xl: "209px" }
						: index === 4
							? { lg: "149px", xl: "209px" }
							: index === 5
								? { lg: "87px", xl: "147px" }
								: index === 6 ? { lg: "78px", xl: "138px" } : index === 7 ? { lg: "30px", xl: "90px" } : index === 8 ? { lg: "-10px", xl: "50px" } : index === 9 ? { lg: "-40px", xl: "20px" } : index === 10 ? { lg: "-50px", xl: "10px" } : index === 11 ? { lg: "-40px", xl: "20px" } : { lg: "40px", xl: "100px" }
		};
	}

	function onClick(title: string)
	{
		setOpen(true);
		setTitle(title);
	}

	function onClose()
	{
		setOpen(false);
	}

	const formik = useFormik({
		initialValues: {
			willRun: "",
			canCreate: "",
			canFind: ""
		},
		validationSchema: Yup.object({
			willRun: Yup.string().required("required"),
			canCreate: Yup.string().required("required"),
			canFind: Yup.string().required("required")
		}),
		onSubmit: (values, { resetForm }) =>
		{
			const newFears = [ ...fears ];

			newFears.forEach((fear: ArrValues) =>
			{
				if (fear.title === title)
				{
					fear.define = values.willRun;
					fear.prevent = values.canCreate;
					fear.repair = values.canFind;
				}
			});
			setFears(newFears);
			resetForm();
			onClose();
		}
	});

	// Render
	return (
		<Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "center" }}>
			<Box sx={{ height: "430px", position: "relative", width: "900px", bottom: "-70px" }}>
				{fears.length > 0 && fears.map((fear: ArrValues, index: number) => (
					<Box
						key={index}
						sx={positionFears(index)}
					>
						<Box sx={{
							position: "relative", width: "136px", height: "226px"
						}}>
							{fear.define ?
								<Box sx={{
									position: "relative", width: "100%", height: "100%", zIndex: 0
								}}>
									<BallButton
										showValue={false}
										cursorBallButton="default"
										topBallButtonPosition="0px"
										leftBallButtonPosition="0px"
									/>
								</Box>
								:
								<Box
									onClick={() => onClick(fear.title)}
									sx={{ position: "relative", cursor: "pointer", zIndex: 1 }}
								>
									<SVGfear />
									<Box
										sx={{
											background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.169) 55.2%, rgba(0, 0, 0, 0) 100%)",
											width: "136px",
											opacity: 0.3,
											height: "22px",
											position: "absolute",
											bottom: "-20px",
											left: 0
										}} />
								</Box>
							}
						</Box>
					</Box>
				))}
				{open
					? (
						<FormAccordion
							formik={formik}
							title={title}
							expanded={expanded}
							setExpanded={setExpanded}
							sx={{ top: { lg: "-185px", xl: "-345px" } }}
						/>
					)
					: null
				}
			</Box>
			{showBtnNextStep
				? (
					<StepperNavigationWrapper sx={{ bottom: 0, right: "25px" }} absolute>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton direction="right" onClick={nextStep} />
					</StepperNavigationWrapper>
				)
				: null
			}
		</Box>
	);
}

export default DescFears;
