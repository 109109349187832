import { Box } from "@mui/material";
import FearOfFailure from "assets/images/missions/mission-eight/fear-of-failure.png";
import FearRejection from "assets/images/missions/mission-eight/fear-rejection.png";
import FearUnknown from "assets/images/missions/mission-eight/fear-unknown.png";
import LosingSecurity from "assets/images/missions/mission-eight/losing-security.png";
import NotEnoughResources from "assets/images/missions/mission-eight/not-enough-resources.png";
import ReturnPrevious from "assets/images/missions/mission-eight/return-previous.png";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import ModalWindow from "components/modalWindow/card";
import { forwardRef, Ref, useEffect, useState } from "react";
import useMission from "../../hooks";
import Buttons from "./Buttons";

interface BallsValues
{
	id: number;
	changed: boolean;
}

const cards = [
	{
		id: 1,
		src: FearOfFailure,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="fear_of_failure_title"
			defaultValue="title: Fear of failure"
			position="left"
		/>,
		description: <ContentAdmin
			limit={500}
			keyContent="contentMissionEight"
			keyText="fear_of_failure_descr"
			defaultValue="Everyone fails, it’s part of the growth process. If you have no fear, it means you are not pushing yourself hard enough. Imagine you are standing in front of a mountain If you decide to walk away without even trying to climb it — you’ve already failed to reach the peak without even giving yourself a chance. Luckily you didn’t do that with this quest and almost reached the top, excellent job, {NAME}!"
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="fear_of_failure_quote"
			defaultValue="“You are not a failure if you don’t make it — you’re a success because you tried.” — Susan Jeffers"
			position="left"
			isDescription
		/>
	},
	{
		id: 2,
		src: FearUnknown,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="fear_unknown_title"
			defaultValue="Fear of the unknown"
			position="left"
		/>,
		description: <ContentAdmin
			limit={500}
			keyContent="contentMissionEight"
			keyText="fear_unknown_descr"
			defaultValue="We tend to cling to our existing situation, regardless of how destructive it might be, out of fear of uncertainty. Uncertainty is scarier than the situation known to be unpleasant and harming us in the long run.But certainty is just an illusion. You never know what is awaiting you around the corner. With that in mind, wouldn’t it be a lot more fun to pursue your dream life than holding onto an illusion of certainty?"
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="fear_unknown_quote"
			defaultValue="“Uncertainty is the only certainty there is. And knowing how to live with insecurity is the only security”. ―John Allen Paulos."
			position="left"
			isDescription
		/>
	},
	{
		id: 3,
		src: FearRejection,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="fear_rejection_title"
			defaultValue="Fear of rejection"
			position="left"
		/>,
		description: <ContentAdmin
			limit={500}
			keyContent="contentMissionEight"
			keyText="fear_rejection_descr"
			defaultValue="The fear of rejection is easily explainable. We are social animals, and belonging to the tribe is one of our primary needs. While we evolved as a species and can easily survive on our own, our brains still think we will die if someone doesn’t approve of what we do. But the truth is, if your tribe does not agree with what you want to pursue, maybe all you need is to find a new tribe? Remember, when one door closes, another one opens."
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="fear_rejection_quote"
			defaultValue="“The fear of rejection has ruined more dreams than rejection itself.” — Jay Shetty"
			position="left"
			isDescription
		/>
	},
	{
		id: 4,
		src: NotEnoughResources,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="not_enough_resources_title"
			defaultValue="Fear of not having enough resources"
			position="left"
		/>,
		description: <ContentAdmin
			limit={500}
			keyContent="contentMissionEight"
			keyText="not_enough_resources_descr"
			defaultValue="{NAME}, I’ve got a secret to share. Most people are scared of not having enough money to go after their dreams, even millionaires. But the truth is, while having resources can definitely make your journey easier, lack of it shouldn’t stop you either."
			position="left"
			isDescription
		/>,
		description1: <ContentAdmin
			limit={250}
			keyContent="contentMissionEight"
			keyText="not_enough_resources_descr1"
			defaultValue="There are many ways in which you can pursue your dreams. You can carefully plan your budget, become a minimalis or think of ways to earn extra income. Your time is way more precious!"
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="not_enough_resources_quote"
			defaultValue="“There’s a myth that time is money. In fact, time is more precious than money. It’s a nonrenewable resource. Once you’ve spent it, and if you’ve spent it badly, it’s gone forever.” — Neil A. Fiore"
			position="left"
			isDescription
		/>
	},
	{
		id: 5,
		src: LosingSecurity,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="losing_security_title"
			defaultValue="Fear of losing security"
			position="left"
		/>,
		description: <ContentAdmin
			limit={400}
			keyContent="contentMissionEight"
			keyText="losing_security_descr"
			defaultValue="Have you heard an expression “It’s always dark before the dawn”? When you are starting a new chapter, you might lose the comfort of your current situation. For example, if you are changing a career, you may lose your status for a while until you build credibility in the new field."
			position="left"
			isDescription
		/>,
		description1: <ContentAdmin
			limit={250}
			keyContent="contentMissionEight"
			keyText="losing_security_descr1"
			defaultValue="But it’s temporary and inevitable. You can only reach new heights if you overcome the hardships of the ‘dark before the dawn’ moment. If you find yourself losing security of the status quo, remember, you are about to reach your new height!"
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="losing_security_quote"
			defaultValue="“If we don’t change, we don’t grow. If we don’t grow, we are not really living. Growth demands a temporary surrender of security.” — Gail Sheeny"
			position="left"
			isDescription
		/>
	},
	{
		id: 6,
		src: ReturnPrevious,
		title: <ContentAdmin
			limit={50}
			keyContent="contentMissionEight"
			keyText="return_previous_title"
			defaultValue="Fear of not being able to return to previous life"
			position="left"
		/>,
		description: <ContentAdmin
			limit={400}
			keyContent="contentMissionEight"
			keyText="return_previous_descr"
			defaultValue="I remember the moment when I wanted to become a quest guide and wanted to leave my diving career behind. It was scary. On the one hand I always wanted to show people a way to a more fulfilling life, on the other hand — what if I didn’t like it? But a colleague of mine said: “Octa, it’s not irreversible. You can always come back to being a diver.” And that really helped me."
			position="left"
			isDescription
		/>,
		description1: <ContentAdmin
			limit={250}
			keyContent="contentMissionEight"
			keyText="return_previous_descr1"
			defaultValue="Don’t worry, {NAME}, whatever you decide to pursue, it’s easier to come back to an old life than you think. Would you rather regret something that you’ve done or something you didn’t do because you were afraid?"
			position="left"
			isDescription
		/>,
		quote: <ContentAdmin
			limit={200}
			keyContent="contentMissionEight"
			keyText="return_previous_quote"
			defaultValue="“You’ll always miss 100% of the shots you don’t take.” Wayne Gretzky"
			position="left"
			isDescription
		/>
	}

];

const FailOrFly = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ open, setOpen ] = useState(false);
	const [ selectedBall, setSelectedBall ] = useState(0);
	const [ showBtnNextStep, setShowBtnNextStep ] = useState(false);
	const [ ballsValues, setBallsValues ] = useState<BallsValues[]>([
		{ id: 0, changed: false },
		{ id: 1, changed: false },
		{ id: 2, changed: false },
		{ id: 3, changed: false },
		{ id: 4, changed: false },
		{ id: 5, changed: false }
	]);

	function onOpen()
	{
		return setOpen(true);
	}

	function handleSelectedBall(id: number)
	{
		onOpen();
		// if (selectedBall === 5) return;
		// if (changedBallFirst)
		// {
		// 	if (!selectedBallArr.includes(id))
		// 	{
		// 		setSelectedBall(pre => pre + 1);
		// 		const newArr = [ ...selectedBallArr ];
		// 		newArr.push(id);
		// 		setSelectedBallArr(newArr);
		// 	} else return;
		// } else
		// {
		// 	setChangedBallFirst(true);
		// 	setSelectedBallArr([ id ]);
		// }
		setSelectedBall(id);
	}

	function handleStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
	}

	function handleChangeBallsValues()
	{
		const newBallsValues = [ ...ballsValues ];

		newBallsValues.forEach((item: BallsValues) =>
		{
			if (item.id === selectedBall)
			{
				item.changed = true;
			}
		});
		setBallsValues(newBallsValues);
		setOpen(false);
	}

	// useEffect(() =>
	// {
	// 	if (selectedBallArr.length === 6) setShowBtnNextStep(true);
	// }, [ selectedBallArr ]);
	useEffect(() =>
	{
		let count = 0;

		ballsValues.forEach(item =>
		{
			if (item.changed) count += 1;
		});
		if (count === 6) setShowBtnNextStep(true);
	}, [ ballsValues ]);

	return (
		<>
			<Box
				ref={ref}
				{...props}
				sx={{
					flexDirection: "column",
					display: "flex",
					justifyContent: "space-between",
					height: "81vh",
					width: "100%",
					m: "10px auto 0px",
					maxWidth: { lg: "802px", xl: "980px" },
					position: "relative",
					top: "-21px",
					left: "-38px"
				}}>
				<Box sx={{ position: "relative" }}>
					<PanelTitle
						color="#fff"
						positionOcta
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionEight"
							keyText="clock_fail_or_fly_title"
							defaultValue="What if I fail? Oh, but what if you fly?"
							position="right"
						/>
						}
						subTitle={<ContentAdmin
							limit={400}
							keyContent="contentMissionEight"
							keyText="clock_fail_or_fly_subtitle"
							defaultValue="Before you face your fears, I’d like to share some wisdom on the most common fears that people face when they are trying to change something in their life."
							position="right"
							isDescription
						/>
						}
					/>
					<Buttons
						handleSelectedBall={handleSelectedBall}
					/>
					<ModalWindow
						open={open}
						handleClose={handleChangeBallsValues}
						project={cards[ selectedBall ]}
						handleChangeBallsValues={handleChangeBallsValues}
					/>
				</Box>
			</Box>

			{showBtnNextStep
				? (
					<StepperNavigationWrapper>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton direction="right" onClick={handleStep} />
					</StepperNavigationWrapper>
				)
				: null
			}
		</>
	);
});

export default FailOrFly;
