import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import octa2 from "../../../assets/images/missions/mission-five/octa.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import PanelTitle from "../../../components/missions/PanelTitle";
import useMission from "../hooks";

const AwwThanks = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				height: "84vh",
				width: "100%",
				maxWidth: "840px",
				m: "auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				marginTop: "-30px"
			}}>
			<PanelTitle
				imgDisplay="none"
				color="#215C75"
				gapPanelTitle="25px"
				title={<ContentAdmin
					limit={111}
					keyContent="contentMissionNine"
					keyText="aww_thanks_title"
					defaultValue="Your North Star is truly inspiring!"
					position="top"
				/>}
			/>
			<Box sx={{ width: "199px", height: "359px", m: "54px auto 60px" }}>
				<img width="100%" src={octa2} alt="guide-chara" />

			</Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<CustomButton.Outline
					sx={{
						fontSize: "24px",
						lineHeight: "33px",
						background: "#3E809D",
						border: "#3E809D",
						color: "#fff",
						width: "335px"
					}}
					onClick={nextStep}>Aww, thanks!
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default AwwThanks;
