import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

export type IWelcomeProps = Record<string, any>;

function Welcome({ ...props }: IWelcomeProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_message_1" defaultValue="Psst, {NAME}, look at you, getting closer to clarity with every step!" isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [
				<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_1" defaultValue="Yay!" position="top" />,
				<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_2" defaultValue="I am ready for more!" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_message_2" defaultValue="Do you know the secret formula for an amazing life?" isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_3" defaultValue="I am intrigued" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_4" defaultValue="What’s that?" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_message_3" defaultValue="It’s simple, you need 3 things: somewhere to live + someone to love + something to do." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_5" defaultValue="Good one!" position="top" />
					]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_message_4" defaultValue="Thanks, {NAME}! While most people have homes and people they love, many struggle with their careers." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_6" defaultValue="That’s Me" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_7" defaultValue="Hm..." position="top" />
					]
				});
				break;
			}

			case 5: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_message_5" defaultValue="Today, I’ll help you figure out what would make you fulfilled. But first, I have a fun quiz for you!" isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_answer_8" defaultValue="Start the Quiz" position="top" />
					]
				});
				break;
			}

			case 6: { setChatStep((prevStep) => prevStep + 1); missionContext.nextStep(); }
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", marginTop: "-16px" }}>
				<Chat
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="Welcome_chat_title" defaultValue="My favorite {NAME} is back!" />}
					onResponseAnswer={onResponseAnswer}
					messages={messages}
					answers={answers}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(Welcome);
