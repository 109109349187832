import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Health from "../../../../assets/images/Health.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function HealthDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Health} alt="Health" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Health
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Your physical health is the foundation for all other spheres of your life. You can’t have much fun or advance your career if your health is suffering, that’s why it’s so important to look after your health well." keyContent="contentMissionOne" keyText="healthDescScreen1" />
				{/* Your physical health is the foundation for all other spheres of your life. You can’t have much fun or advance your career if your health is suffering, that’s why it’s so important to look after your health well. */}
			</Typography>
			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ p: "0px 0 15px 0" }}>
							{/* <Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
								<ContentAdmin isDescription position="left" defaultValue="- Are there any health issues you need to attend to?\n- Do you eat healthily?\n- Do you exercise enough?\n- How is your sleep?\n- Are you emotionally stable?\n\nSmall tweaks to your diet and exercise routine might make an enormous difference." keyContent="contentMissionOne" keyText="healthDescStart" />
							</Typography> */}
							{/* <Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Do you eat healthily?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Do you exercise enough?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									How is your sleep?
								</Typography>
							</Box>
							<Box sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Are you emotionally stable?
								</Typography>
							</Box> */}
						</Box>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							{/* Small tweaks to your diet and exercise routine might make an enormous difference. */}
							<ContentAdmin isDescription position="top" defaultValue="Small tweaks to your diet and exercise routine might make an enormous difference." keyContent="contentMissionOne" keyText="healthDescStart" />
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography mb={3} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="If you eat well, exercise and don’t have any chronic illnesses, but are still not satisfied with your health, you might need to take an honest look at your life.\n\n- Are you burnt out?\n- Are you under too much stress?\n- Are you fulfilled in your career?\n- Do you feel lonely?\n- Do you live life in alignment with your essence?" keyContent="contentMissionOne" keyText="healthDescBigger" />
							{/* If you eat well, exercise and don’t have any chronic illnesses, but are still not satisfied with your health, you might need to take an honest look at your life. */}
						</Typography>
						{/* <Box sx={{ p: '0px 10px 15px' }}>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Are you burnt out?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Are you under too much stress?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Are you fulfilled in your career?
								</Typography>
							</Box>
							<Box mb={2} sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Do you feel lonely?
								</Typography>
							</Box>
							<Box sx={{ display: 'flex' }}>
								<CircleIcon sx={{ fill: '#215C75', fontSize: '10px' }} />
								<Typography ml={1} sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#4D4D4D', mt: '-8px' }}>
									Do you live life in alignment with your essence?
								</Typography>
							</Box>
						</Box> */}
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Often, health-related symptoms occur when we ignore our inner voice. Sometimes we need time to realise that something isn’t serving us anymore, find courage to make the change or simply don’t know where to start.\\n\\nAll of that is ok, because that’s exactly why we have created Life Strategy Quest — to help you find clarity and realign with your essence." keyContent="contentMissionOne" keyText="healthDescScreen2" />
				{/*
				Often, health-related symptoms occur when we ignore our inner voice. Sometimes we need time to realise that something isn’t serving us anymore, find courage to make the change or simply don’t know where to start.<br></br><br></br>

				All of that is ok, because that’s exactly why we have created Life Strategy Quest — to help you find clarity and realign with your essence.
				*/}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default HealthDetails;
