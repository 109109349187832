// import CancelIcon from '@mui/icons-material/Cancel';
import { Masonry } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGExpandArrows } from "assets/images/icons/expand-arrows.svg";
import mission6img from "assets/images/missions/mission-nine/mission6img.png";
import ContentAdmin from "components/contentAdmin";
import { useCallback, useState } from "react";
import ImageSideModal from "../../../missionSix/components/ImageSideModal";
import useMission from "../../hooks/useMission";

export interface IMission2Props
{
	isOpen: boolean;
}

function ModalMission6Data({ isOpen = false }: IMission2Props)
{
	const missionContext = useMission();
	const [ data, setData ] = useState<string[]>([]);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);
	const [ isShowBoard, setIsShowBoard ] = useState<boolean>(false);

	// Handles
	const onOpen = useCallback(() =>
	{
		Api.missions
			.getDataMissionSix()
			.then(({ data }) =>
			{
				setIsErrorLoading(false);
				// @ts-ignore (data - array)
				setData(data[ 0 ].mission_data);
			})
			.catch((error) =>
			{
				setIsErrorLoading(true);
				console.error(error);
			});
	}, []);

	// Render
	return (
		<>
			<ImageSideModal
				open={isShowBoard === true}
				onClose={() => setIsShowBoard(false)}
				image={mission6img}
				title=""
				subTitle=""
				onOpen={onOpen}
				sx={{
					"& .ImageSideModal-image, & .ImageSideModal-title, & .ImageSideModal-subTitle":
					{
						display: "none"
					},
					"& .ImageSideModal-content":
					{
						padding: "64px 40px 24px 24px",
						width: "100%",
						gridTemplateRows: "1fr"
					}
				}}
			>
				{data !== null && data !== undefined && isErrorLoading === false &&
					(
						<Masonry spacing={2} columns={6}>
							{data.map((image, index) => (
								<Box
									sx={{ borderRadius: "24px" }}
									key={index + image}
									component="img"
									src={image}
									alt=""
								/>
							))}
						</Masonry>
					)
				}
			</ImageSideModal>

			<ImageSideModal
				open={isOpen === true && isShowBoard === false}
				onClose={() => missionContext.setShowMissionInfo(null)}
				image={mission6img}
				title={<ContentAdmin keyContent="contentMissionSix" keyText="dddddddddddddd" defaultValue="My Vision Board" position="left" />}
				subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="sssss" defaultValue="Test" position="left" isDescription />}
				onOpen={onOpen}
			>
				{(data === null || data === undefined) && isErrorLoading === false &&
					(
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
							Loading data <CircularProgress size={24} />
						</Box>
					)
				}
				{(data === null || data === undefined) && isErrorLoading === true &&
					(
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
							Failed to retrieve data, please try again
						</Box>
					)
				}
				{data !== null && data !== undefined && isErrorLoading === false &&
					(
						<Box sx={{
							padding: "22px",
							position: "relative",
							borderRadius: "24px",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)"
						}}>
							<Masonry spacing={2} columns={3}>
								<>
									{data.map((image, index) => (
										<Box
											sx={{ borderRadius: "24px" }}
											key={index + image}
											component="img"
											src={image}
											alt=""
										/>
									))}

									<Box
										component={SVGExpandArrows}
										sx={{
											position: "fixed",
											width: "30px !important",
											bottom: "30px",
											right: "30px",
											color: "primary.main",
											cursor: "pointer",
											opacity: 0.8,
											"&:hover": { opacity: 1 }
										}}
										onClick={() => setIsShowBoard(true)}
									/>
								</>
							</Masonry>
						</Box>
					)
				}
			</ImageSideModal>
		</>
	);
}

export default ModalMission6Data;
