import Api, { getAccessToken } from "api";

export interface IResponseGetUser
{
	age?: "16-24" | "25-34" | "35-44" | "45+" | null;
	city?: string | null;
	completedMission: number;
	missionProgress: number;
	stepMission: number;
	/**
	 * @example "2022-10-25T01:15:10.928Z"
	 */
	created: string;
	email: string;
	id: string;
	subscribeType: number;
	fullSubscribe?: boolean;
	subscriptionIsActive?: boolean;
	userRole: "user" | "admin" | "tester";
	isVerified: boolean;
	name: string;
	newsletterSubscribe?: boolean;
	suspendedSubscription?: boolean;
	dateSubscriptionStart: string;
	onboarded: boolean;
	status: boolean;
	/**
	 * unseen artifact
	 */
	notificationArtifact: number[];
	brevoRegistration?: boolean;
}

export interface IResponseChangePassword
{
	message: string;
}

export interface ICheckUserResponse
{
	data: boolean;
}

export interface IResponseGetMissionBuy
{
	/**
	 * @example 63e4d627ea1d98d6148a1bfd
	 */
	id: string;
	/**
	 * @example 63e4d627ea1d98d6148a1bfd
	 */
	_id: string;
	/**
	 * @example 63e4d627ea1d98d6148a1bfb
	 */
	user_id: string;
	missions: { id: number; payment: boolean; }[];
}

export interface IResponseGetStripeData
{
	/**
	 * @example 644bdc914ab44c47aa9d8f9d
	 */
	_id: string;
	/**
	 * @example 644bdc914ab44c47aa9d8f9d
	 */
	id: string;
	/**
	 * @example 63e4d627ea1d98d6148a1bfb
	 */
	user_id: string;
	/**
	 * @example 28/04/2023
	 */
	created_at: string;
	/**
	 * @example usd
	 */
	currency: string;
	/**
	 * @example cus_NKCXcTxqCYOFF5
	 */
	customer: string;
	/**
	 * @example pi_3MUUJzBZ96EI6mQe0ah5nuTsaz
	 */
	transaction_id: string;
	description: string;
	information: string;
	status: "succeeded";
	value: number;
}

export interface IResponseAddStripeData
{
	/**
	 * @example pi_3N1zTyBZ96EI6mQe0Z9gaSku_secret_xQSxIV4S6dPdmZF408azNYXJZ
	 */
	clientSecret: string;
}

export default class Users
{
	async sendLinkToUser()
	{
		return (
			await Api.instance.post<any>(
				"/users/sendLinkToUser",
				undefined,
				{
					headers:
					{
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async checkUser(email: string)
	{
		return (
			await Api.instance.post<ICheckUserResponse>(
				"/users/checkUser",
				{ email },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}

	async getUser(id: string)
	{
		return (
			await Api.instance.get<IResponseGetUser>(
				"/users/" + id,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getMe()
	{
		return (
			await Api.instance.get<IResponseGetUser>(
				"/users/me",
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async deleteUser()
	{
		return (
			await Api.instance.post<IResponseGetUser>(
				"/users/deleteUser",
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async deleteUserDataBase(id: string)
	{
		return (
			await Api.instance.post<IResponseGetUser>(
				"/users/deleteUserDataBase",
				{ id },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async changePassword(password: string, newPassword: string)
	{
		return (
			await Api.instance.post<IResponseChangePassword>(
				"/users/changePassword",
				{
					oldPassword: password,
					newPassword,
					confirmNewPassword: newPassword
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async updateUser(data: Partial<Pick<IResponseGetUser, "name" | "email" | "onboarded">>)
	{
		return (
			await Api.instance.post<"updated">(
				"/users/updateUser",
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async changeIsTested(data: { email: string; password: string; userRole: IResponseGetUser[ "userRole" ]; })
	{
		return (
			await Api.instance.post<"success">(
				"/admin/changeRoleUser",
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async addStripeData(data: Record<"description" | "amount" | "mission", any>)
	{
		return (
			await Api.instance.post<IResponseAddStripeData>(
				"/users/addStripeData",
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getStripeData()
	{
		return (
			await Api.instance.get<IResponseGetStripeData[]>(
				"/users/getTransactions",
				{
					headers: {
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async getMissionsBuy()
	{
		return (
			await Api.instance.get<IResponseGetMissionBuy>(
				"/users/getMissionsBuy",
				{
					headers: {
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async fullEmailsSend(email: string)
	{
		return (
			await Api.instance.post<string>(
				"/users/fullEmailsSend",
				{ email },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
