import CancelIcon from "@mui/icons-material/Cancel";
import { Box, FormControl, Modal } from "@mui/material";
import Api from "api";
import { IErrorResponseResetPassword } from "api/Auth";
import axios, { AxiosError } from "axios";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import { useFormik } from "formik";
import useUser from "hooks/useUser";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

interface IModalChangePasswordProps
{
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function ModalChangePassword({ isOpen, setIsOpen }: IModalChangePasswordProps)
{
	// States
	const navigate = useNavigate();
	const user = useUser().state.user;

	const [ isFetching, setIsFetching ] = useState<boolean>(false);

	const formik = useFormik(
		{
			initialValues:
			{
				"current-password": "",
				"new-password": "",
				"confirm-password": ""
			},
			onSubmit: onFormSubmit,
			validateOnBlur: true,
			validateOnChange: true,
			validationSchema: yup.object({
				"current-password": config.password.yup(),
				"new-password": config.password.yup(),
				"confirm-password": config.password.yup()
					.oneOf([ yup.ref("new-password") ], "Different passwords")
			})
		}
	);

	// Handles
	function onFormSubmit(values: Record<"current-password" | "new-password" | "confirm-password", string>)
	{
		if (isFetching === true)
		{
			return;
		}

		setIsFetching(true);

		Api.users
			.changePassword(values[ "current-password" ], values[ "new-password" ])
			.then(() =>
			{
				formik.resetForm();
				setIsOpen(false);
			})
			.catch((error) =>
			{
				if (axios.isAxiosError(error) === false)
				{
					return console.error(error);
				}

				console.error(error.response);

				const serverError = error as AxiosError<IErrorResponseResetPassword>;
				if (serverError.response === undefined)
				{
					return;
				}

				const { data } = serverError.response;

				switch (data.message)
				{
					case "Invalid token": {

						return navigate("/login");
					}

					case "Old password is incorrect": {
						return formik.setFieldError("current-password", "You entered a wrong password");
					}
				}
			})
			.finally(() =>
			{
				setIsFetching(false);
			});
	}

	return (
		<Modal
			open={isOpen}
			onClose={() => { formik.resetForm(); setIsOpen(false); }}
			sx={{
				"& .MuiBackdrop-root":
				{
					backdropFilter: "blur(3px)",
					backgroundColor: "rgba(120, 137, 177, .6)"
				},
				"&.MuiModal-root":
				{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: "20px"
				}
			}}
		>
			<Box sx={{ maxWidth: "680px", width: "100%" }}>
				<Box sx={{ font: "700 42px/56px Lora-Bold", textAlign: "center", color: "#FFFFFF" }}>
					Change password
				</Box>

				<Box sx={{
					marginTop: "20px",
					padding: "60px 100px 60px 100px",
					border: "2px solid #DFE7F1",
					borderRadius: "20px",
					backgroundColor: "rgba(245, 242, 242, .4)",
					position: "relative"
				}}>
					<CancelIcon
						onClick={() => setIsOpen(false)}
						sx={{
							position: "absolute",
							top: "20px",
							right: "20px",
							fill: "#215C75",
							opacity: 1,
							"&:hover": { opacity: 0.7, cursor: "pointer" }
						}}
					/>

					<Box
						component="form"
						onSubmit={formik.handleSubmit}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: "40px"
						}}
					>
						<input type="email" autoComplete="username" value={user?.email} hidden readOnly />

						{([
							[ "Password", "password", "current-password", "current-password" ],
							[ "New password", "new password", "new-password", "new-password" ],
							[ "Confirm new password", "confirm new password", "confirm-password", "new-password" ]
						] as [ label: string, placeholder: string, name: keyof typeof formik.initialValues, autoComplete: string ][])
							.map(([ label, placeholder, name, autoComplete ]) =>
							{
								return (
									<label key={name}>
										<Box sx={{ font: "700 22px/28px Lora", marginBottom: "10px", marginLeft: "10px", color: "#215C75" }}>
											{label}
										</Box>

										<FormControl sx={{ width: "100%" }}>
											<CustomInput.Password
												placeholder={placeholder}
												name={name}
												autoComplete={autoComplete}
												value={formik.values[ name ]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>

											<Box sx={{
												position: "absolute",
												left: "5px",
												bottom: "-19px",
												color: "#DC4242",
												font: "400 14px/19px 'Open Sans'"
											}}>
												{formik.touched[ name ] ? formik.errors[ name ] : null}
											</Box>
										</FormControl>
									</label>
								);
							})

						}

						<CustomButton.Base
							type="submit"
							disabled={isFetching === true || !(formik.dirty && formik.isValid)}
							loading={isFetching === true}
							sx={{ margin: "40px auto 0 auto" }}
						>
							Save
						</CustomButton.Base>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
}

export default ModalChangePassword;
