import { Box, SxProps, Tab, Tabs, Theme, alpha } from "@mui/material";
import { a11yProps } from "components/TabPanel";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ITabsItem
{
	to: string;
	value: string;
	label: any;
	icon: JSX.Element;
}

interface IResourceAsideProps
{
	header?: JSX.Element | ReactNode;
	title?: JSX.Element | ReactNode;
	sx?: SxProps<Theme>;
	children?: ReactNode;
	navigationProps?: Record<string, any>;
}

type IResourceAsideWithTabsProps =
	| {
		tabs: ITabsItem[];
		selectedTab: string;
	} |
	{
		tabs?: never;
		selectedTab?: never;
	};

function AsidePanel({
	header = null,
	title = null,
	tabs,
	selectedTab,
	children,
	sx = [],
	navigationProps,
	...props
}: IResourceAsideProps & IResourceAsideWithTabsProps)
{
	return (
		<Box
			className="AsidePanel-root"
			sx={[
				{
					position: "relative",
					flexShrink: 0,

					"& .MuiButton-root":
					{
						minHeight: "unset",
						height: { xl: "56px", lg: "40px", xs: "40px" },
						font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/24.51px 'Open Sans'", xs: "700 18px/24.51px 'Open Sans'" }
					},
					"& .AsidePanel-navigation":
					{
						display: "flex",
						flexDirection: "column",
						gap: { xl: "24px", lg: "16px", xs: "16px" },

						width: { xl: "520px", lg: "358.06px", xs: "358.06px" },
						height: { xl: "664px", lg: "519px", xs: "519px" },

						backgroundColor: alpha("#FFFFFF", 0.5),
						backdropFilter: "blur(30px)",
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderLeft: "unset",
						borderRadius: "0 24px 24px 0"
					},
					"& .AsidePanel-title":
					{
						margin: { xl: "32px 32px 0 32px", lg: "20px 20px 0 16px", xs: "20px 20px 0 16px" },
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 30px/38px Lora-Bold", xs: "700 30px/38px Lora-Bold" },
						color: "#215C75"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Box
				className="AsidePanel-header"
				sx={{
					height: { xl: "196px", lg: "157px", xs: "157px" },
					position: "relative"
				}}
			>
				{header}
			</Box>

			<Box
				className="AsidePanel-navigation"
				component="nav"
				{...navigationProps}
			>
				{title !== null &&
					(
						<Box className="AsidePanel-title">
							{title}
						</Box>
					)
				}

				{tabs && selectedTab
					? (
						<Tabs
							orientation="vertical"
							value={selectedTab}
							sx={{
								"& .MuiTabs-indicator": { display: "none" },
								"& .MuiTabs-flexContainer":
								{
									gap: { xl: "24px", lg: "16px", xs: "16px" }
								}
							}}
						>
							{tabs.map((item) => (
								<Tab
									{...a11yProps(item.value)}
									key={item.to}
									component={Link}
									to={item.to}
									value={item.value}
									label={item.label}
									icon={item.icon}
									iconPosition="end"
									disableRipple
									sx={{
										display: "flex",
										alignSelf: "flex-end",
										justifyContent: "space-between",
										gap: "24px",

										font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
										color: "#3E809D",
										textTransform: "none",

										minHeight: "unset",
										maxHeight: "unset",
										height: { xl: "64px", lg: "45px", xs: "45px" },
										minWidth: "unset",
										maxWidth: "unset",
										width: { xl: "488px", lg: "343.12px", xs: "343.12px" },

										padding: { xl: "0 40px", lg: "0 20px", xs: "0 20px" },

										border: "2px solid #3E809D",
										borderRight: "unset",
										borderRadius: "64px 0 0 64px",

										opacity: 0.5,

										"&.MuiTab-labelIcon.Mui-selected":
										{
											color: "#FFFFFF",
											opacity: 1,
											backgroundColor: "#3E809D"
										},
										"&.MuiTab-labelIcon .MuiTab-iconWrapper":
										{
											width: { xl: "40px", lg: "31px", xs: "31px" },
											height: { xl: "40px", lg: "31px", xs: "31px" },

											"& path":
											{
												fill: "#3E809D"
											}
										},
										"&.MuiTab-labelIcon.Mui-selected .MuiTab-iconWrapper path":
										{
											fill: "#FFFFFF"
										}
									}}
								/>
							))}
						</Tabs>
					)
					: null
				}

				{children}
			</Box>
		</Box>
	);
}

export default AsidePanel;
