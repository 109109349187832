import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Box, CircularProgress, Rating, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Api from "api";
import octa from "assets/images/calm.svg";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import { useState, type ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

const StyledRating = styled(Rating)({
	"& .MuiRating-iconFilled": {
		color: "#FBD37F"
	},
	"& .MuiRating-iconHover": {
		color: "#FBD37F"
	}
});

export interface FeedbackProps
{
	missionNumber: number;
	name: string | undefined;
	clearStorage?(): void;
	nextStep?(): void;
}

function FeedbackCreate(
	{
		missionNumber,
		name,
		clearStorage,
		nextStep
	}: FeedbackProps
)
{
	const navigate = useNavigate();
	const [ rating, setRating ] = useState<number>(0);
	const [ feedback, fetFeedback ] = useState<string>("");
	const [ err, setErr ] = useState<boolean>(false);
	const [ isLoading, setIsLoading ] = useState<boolean>(false);

	function submit()
	{
		const data = {
			missionNumber,
			rating: rating,
			feedback: feedback
		};
		if (feedback.length === 0)
		{
			setErr(true);
		}
		else
		{
			setIsLoading(true);
			Api.feedback
				.create(data)
				.then(() =>
				{
					clearStorage && clearStorage();
					setErr(false);
					setIsLoading(false);

					if (missionNumber === 2)
					{
						nextStep && nextStep();
					}
					else
					{
						if (missionNumber === 10)
						{
							setTimeout(() => navigate("/mission-eleven"), 0);
						}
						else
						{
							setTimeout(() => navigate("/dashboard"), 0);
						}

					}

				})
				.catch(() =>
				{
					setIsLoading(false);
				});
		}
	}

	// Render
	return (
		<Box sx={{
			height: "100vh",
			background: "rgba(78, 181, 56, .06)",
			backdropFilter: "blur(40px)",
			width: "100%",
			dsiplay: "flex",
			flexDirection: "column",
			justifyContent: "center"
		}}>
			<Panel sx={{
				height: { xl: "585px", lg: "585px", xs: "456px" },
				width: { xl: "642px", lg: "642px", sm: "442px", xs: "100%" },
				margin: "0 auto",
				padding: "24px 32px 32px 32px",
				display: "grid",
				gridTemplateRows: "auto 1fr",
				gap: "24px",
				background: "linear-gradient(103.55deg, rgba(207, 225, 255, 0.55) 9.99%, rgba(223, 223, 223, 0.13) 91.61%) !important",
				boxShadow: "none !important",
				mt: "150px",
				position: "relative"
			}}>
				<Box
					component="img"
					src={octa}
					sx={{
						width: { xs: "140px", lg: "186px" },
						transform: "rotate(-5.6deg)",
						position: "absolute",
						top: { xs: "-52px", lg: "-73px" },
						right: { xs: "-93px", lg: "-125px" }
					}} />
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
				}}>
					<Box sx={{
						color: missionNumber === 11 ? "#fff" : "primary.main",
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 32px/40px Lora-Bold", xs: "700 32px/23px Lora-Bold" },
						textAlign: "center"
					}}>
						Rate this mission
					</Box>
					<Box sx={{
						color: missionNumber === 11 ? "#fff" : "primary.main",
						font: { xl: "400 22px/29px Open Sans", xs: "400 18px/23px Open Sans" },
						textAlign: "center",
						mt: 4,
						mb: 2
					}}>
						{`${ name }, your opinion matters!`}<br />
						Share your feedback to help us improve.
					</Box>
					<StyledRating
						value={rating}
						onChange={(event, newValue) =>
						{
							newValue && setRating(newValue);
						}}
						name="customized-color"
						icon={<StarRoundedIcon sx={{ color: "#FBD37F", fontSize: { xs: "60px", lg: "100px" } }} />}
						emptyIcon={<StarRoundedIcon sx={{ color: "rgba(62, 128, 157, 0.3)", fontSize: { xs: "60px", lg: "100px" } }} />}
					/>
					<TextField
						value={feedback}
						fullWidth
						autoFocus
						inputProps={{ maxLength: 150 }}
						onChange={(e: ChangeEvent<HTMLInputElement>) => { fetFeedback(e.target.value); }}
						helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ feedback.length }/150 characters`}</Typography>}
						placeholder="Share your feedback here"
						multiline
						minRows={6}
						maxRows={6}
						sx={{
							"& .MuiInputBase-root fieldset": {
								border: "none"
							},
							"& .MuiOutlinedInput-root": {
								font: { xs: '400 16px/24px "Open Sans"', lg: '400 22px/30px "Open Sans"' },
								color: "#4D4D4D !important",
								backgroundColor: "rgba(255, 255, 255, 0.5)",
								borderRadius: "8px"
							},
							"& .MuiFormHelperText-contained": {
								textAlign: "right",
								mt: 0
							},
							mt: 4
						}}
					/>
					{err ? <Typography sx={{ color: "red" }}>Fill out your feedback</Typography> : null}
				</Box>
			</Panel>
			<Box sx={{ display: "flex", justifyContent: "center", mt: "64px" }}>
				{isLoading
					? <CircularProgress />
					:
					missionNumber !== 11
						?
						(
							<CustomButton.Contained
								size="large"
								onClick={submit}
							>
								Continue
							</CustomButton.Contained>
						)
						:
						(
							<CustomButton.Contained
								size="large"
								onClick={submit}
								sx={{ background: "#fff", color: "primary.main" }}
							>
								Continue
							</CustomButton.Contained>
						)
				}
			</Box>

		</Box>
	);
}

export default FeedbackCreate;
