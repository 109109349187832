import Api from "api";
import { IResponseGetUser } from "./Users";

export interface IResponseAuthenticate extends Omit<IResponseGetUser, "subscribeType">
{
	accessToken: string;
}

export interface IResponseRegister extends Omit<IResponseGetUser, "subscribeType">
{
	accessToken: string;
}

export interface IResponseForgotPassword
{
	message: string;
}

export interface IResponseResetPassword
{
	message: string;
}

export interface IErrorResponseResetPassword
{
	message: string;
}

export interface IRegisterParams extends Pick<IResponseGetUser, "age" | "email" | "name" | "newsletterSubscribe">
{
	password: string;
}

export default class Auth
{
	async authenticate(email: string, password: string)
	{
		return (
			await Api.instance.post<IResponseAuthenticate>(
				"/auth/authenticate",
				{ email, password }
			)
		).data;
	}

	async authenticateByGoogle(googleCode: string, name?: string)
	{
		const params = new URLSearchParams({ google_auth_code: googleCode });
		if (name !== undefined)
		{
			params.set("name", name);
		}

		return (
			await Api.instance.post<IResponseAuthenticate>("/auth/authenticate/?" + params.toString())
		).data;
	}

	async forgotPassword(email: string)
	{
		return (
			await Api.instance.post<IResponseForgotPassword>(
				"/auth/forgot-password",
				{ email }
			)
		).data;
	}

	async resetPassword(token: string, password: string, newPassword: string)
	{
		return (
			await Api.instance.post<IResponseForgotPassword>(
				"/auth/reset-password",
				{ token, password, newPassword }
			)
		);
	}

	async register(data: IRegisterParams)
	{
		return (
			await Api.instance.post<IResponseRegister>(
				"/auth/register",
				data,
				{
					headers:
					{
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken
					}
				}
			)
		).data;
	}
}
