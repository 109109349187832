import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-scroll";
import { getLocalStorage } from "utils/localStorage";
import Career from "../../../assets/images/Career.svg";
import Friend from "../../../assets/images/Friend.svg";
import Fun from "../../../assets/images/Fun.svg";
import Growth from "../../../assets/images/Growth.svg";
import Health from "../../../assets/images/Health.svg";
import Love from "../../../assets/images/Love.svg";
import Money from "../../../assets/images/Money.svg";
import home from "../../../assets/images/home.svg";
import octaDetails from "../../../assets/images/octaDetails.svg";
import ContentAdmin from "../../../components/contentAdmin";

/**
 * @param {Object} param0
 * @param {*} param0.handleDetails
 * @param {*} param0.items
 * @param {{health: number; friend: number; love: number; career: number; money: number; fun: number; growth: number; home: number; }} param0.dataGraphGet
 * @param {*} param0.name
 * @param {*} param0.handleClickOpen
 */
function DetailsBad({ handleDetails, items, dataGraphGet, name, handleClickOpen })
{
	const data = getLocalStorage("graph");
	const [ active, setActive ] = useState(true);
	const [ showOcta, setShowOcta ] = useState(false);

	function imageByTitle(title)
	{
		switch (title)
		{
			case "Health":
				return Health;
			case "Friends":
				return Friend;
			case "Love":
				return Love;
			case "Career":
				return Career;
			case "Money":
				return Money;
			case "Fun":
				return Fun;
			case "Growth":
				return Growth;
			default:
				return home;
		}
	}

	return (
		<Box>
			<Box className="customScroll" sx={{ maxHeight: "773px", overflowY: "auto" }}>
				<Typography className="h1-status" pt={5} sx={{ fontSize: { xs: "24px", sm: "40px" }, fontFamily: "Lora-Bold", color: "#215C75" }}>
					<ContentAdmin position="left" defaultValue="Time to re-assess your priorities" keyContent="contentMissionOne" keyText="mediumStatusTitle" />
				</Typography>
				{dataGraphGet
					? (
						<Typography
							className="p-status"
							mt={2}
							sx={{ fontSize: { xs: "16px", lg: "18px", xl: "22px" }, fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}
						>
							{ name ?? getLocalStorage("name") }, let’s start by acknowledging what’s going right in your life. It sounds like{" "}
							{ dataGraphGet.health > 0.6 && "Health, " }
							{ dataGraphGet.friend > 0.6 && "Friend, " }
							{ dataGraphGet.love > 0.6 && "Love, " }
							{ dataGraphGet.career > 0.6 && "Career, " }
							{ dataGraphGet.money > 0.6 && "Money, " }
							{ dataGraphGet.fun > 0.6 && "Fun, " }
							{ dataGraphGet.growth > 0.6 && "Growth, " }
							{ dataGraphGet.home > 0.6 && "Physical environment, " }
							have scored quite high. You should give yourself credit.<br /><br />
							{/*
							Most people struggle to maintain their life in balance at all times, so it’s completely normal that some areas of your life scored a little lower.<br></br><br></br>

							The good news is that you now have clarity of what needs your attention. Sometimes we feel down or not quite fulfilled without even understanding what needs fixing.
							When you know what to focus on, it is a lot easier to fix it.
							*/}
							<ContentAdmin
								isDescription
								position="left"
								defaultValue="Most people struggle to maintain their life in balance at all times, so it’s completely normal that some areas of your life scored a little lower.<br></br><br></br>
								The good news is that you now have clarity of what needs your attention. Sometimes we feel down or not quite fulfilled without even understanding what needs fixing.
								When you know what to focus on, it is a lot easier to fix it."
								keyContent="contentMissionOne"
								keyText="mediumStatusDesc"
							/>
						</Typography>
					)
					: data
						? (
							<Typography className="p-status" mt={2} sx={{ fontSize: { xs: "16px", sm: "22px" }, fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
								{ name ?? getLocalStorage("name") }, let’s start by acknowledging what’s going right in your life. It sounds like{" "}
								{ data.health > 0.6 && "Health, " }
								{ data.friend > 0.6 && "Friend, " }
								{ data.love > 0.6 && "Love, " }
								{ data.career > 0.6 && "Career, " }
								{ data.money > 0.6 && "Money, " }
								{ data.fun > 0.6 && "Fun, " }
								{ data.growth > 0.6 && "Growth, " }
								{ data.home > 0.6 && "Physical environment, " }
								have scored quite high. You should give yourself credit.<br /><br />
								{/*
							Most people struggle to maintain their life in balance at all times, so it’s completely normal that some areas of your life scored a little lower.<br></br><br></br>

							The good news is that you now have clarity of what needs your attention. Sometimes we feel down or not quite fulfilled without even understanding what needs fixing.
							When you know what to focus on, it is a lot easier to fix it.
							*/}
								<ContentAdmin
									isDescription
									position="left"
									defaultValue="Most people struggle to maintain their life in balance at all times, so it’s completely normal that some areas of your life scored a little lower.<br></br><br></br>
								The good news is that you now have clarity of what needs your attention. Sometimes we feel down or not quite fulfilled without even understanding what needs fixing.
								When you know what to focus on, it is a lot easier to fix it."
									keyContent="contentMissionOne"
									keyText="mediumStatusDesc"
								/>
							</Typography>
						)
						: ""
				}
			</Box>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Typography className="h1-status" mt={3} sx={{ fontSize: { xs: "24px", sm: "40px" }, fontFamily: "Lora-Bold", color: "#215C75" }}>
					Let’s look at each area in more detail
				</Typography>
				<Box mt={6}>
					<Grid container spacing={{ xs: 1, sm: 5 }}>
						{items?.filter((item) => item.value < 0.7).map(
							(item, index) => (
								<Grid key={index + 1} item xs={3}>
									<Link to="test1" spy smooth duration={500} onClick={() => { handleDetails(item.title); setShowOcta(true); }}>
										<Box sx={{
											border: "1px solid rgba(255,255,255,0.6)",
											background: "#E9EDF0",
											borderRadius: "10px",
											pt: "15px",
											boxShadow: item.active ? "-5px -5px 10px #215c75" : "-5px -5px 10px #F0F8FF",
											cursor: "pointer",
											"&:hover": {
												boxShadow: "-5px -5px 10px #215c75"
											},
											width: "160px"
										}}
										>
											<Box sx={{ width: "90px", m: "0 auto" }}>
												<img width="100%" height="90px" src={imageByTitle(item.title)} alt={item.title} />
											</Box>
											<Typography sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75", textAlign: "center" }}>
												{item.title === "Physical environment" ? "Physical env." : item.title}
											</Typography>
											<Box sx={{ display: "flex", justifyContent: "center", background: "#3E809D", height: "30px", borderRadius: "0 0 10px 10px", opacity: 0.5, "&:hover": { opacity: 1 } }}>
												<ArrowBackIosIcon sx={{ fill: "#fff", transform: "rotate(270deg)", mt: "-2px" }} />
											</Box>
										</Box>
									</Link>
								</Grid>
							)
						)}
					</Grid>
				</Box>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "none" } }}>
				<Accordion expanded={active} sx={{ background: "inherit", boxShadow: "none", ".MuiAccordionSummary-root": { p: 0 }, ".MuiAccordionDetails-root": { p: "16px 0 0" } }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						onClick={() => setActive(!active)}
					>
						<Typography className="h1-status" mt={3} sx={{ fontSize: { xs: "24px !important", sm: "42px" }, fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Let’s look at each area in more detail" keyContent="contentMissionOne" keyText="mediumStatusDetailsTitle" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={{ xs: 1, sm: 5 }}>
							{items?.filter((item) => item.value < 0.7).map(
								(item, index) => (
									<Grid key={index + 1} item xs={6}>
										<Box
											onClick={() =>
											{
												handleDetails(item.title);
												handleClickOpen();
											}}
											sx={{
												border: "1px solid rgba(255,255,255,0.6)",
												background: "#E9EDF0",
												borderRadius: "10px",
												pt: "15px",
												boxShadow: item.active ? "-5px -5px 10px #215c75" : "-5px -5px 10px #F0F8FF",
												cursor: "pointer",
												"&:hover": {
													boxShadow: "-5px -5px 10px #215c75"
												}
											}}
										>
											<Box sx={{ width: "90px", m: "0 auto" }}>
												<img width="100%" height="90px" src={imageByTitle(item.title)} alt={item.title} />
											</Box>
											<Typography sx={{ fontSize: "20px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75", textAlign: "center" }}>
												{item.title === "Physical environment" ? "Physical env." : item.title}
											</Typography>
											<Box sx={{ display: "flex", justifyContent: "center", background: "#3E809D", height: "30px", borderRadius: "0 0 10px 10px", opacity: 0.5 }}>
												<ArrowBackIosIcon sx={{ fill: "#fff", transform: "rotate(270deg)", mt: "-2px" }} />
											</Box>
										</Box>
									</Grid>
								)
							)}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Box>
			{showOcta
				? (
					<Box
						component="img"
						src={octaDetails}
						alt=""
						width="424px"
						// className="octaDetailsSmall"
						// sx={{ position: "absolute", left: "176px", bottom: "-92px", zIndex: 2 }}
						sx={{ position: "absolute", right: "441px", bottom: "-108px", zIndex: 2, display: { xs: "none", xl: "block" } }}
					/>
				)
				: null
			}
		</Box>

	);
}

export default DetailsBad;
