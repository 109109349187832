import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import Schedule from "./Schedule";

const Clock = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	return (
		<Box
			ref={ref}
			{...props}
			className="mission-main main"
			component="main"
			sx={{
				flexDirection: "column",
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				maxWidth: { xl: "980px", xs: "784px" },
				marginTop: "-5px"
			}}
		>
			<Box className="main__content">
				<Box sx={{ maxWidth: "100%" }}>
					<PanelTitle
						positionOcta
						sx={{ marginBottom: { xl: "16px", xs: "39px" } }}
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionFive"
							keyText="clock_title"
							defaultValue="Let’s set your schedule first"
							position="right"
						/>}
					/>
					<Schedule />
				</Box>
			</Box>
		</Box>
	);
});

export default Clock;
