import { Box, Grow, SxProps, Theme } from "@mui/material";
import { Ref, forwardRef, useLayoutEffect, useRef, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import deepClone from "../../../../utils/deepClone";
import randomInt from "../../../../utils/randomInt";
import IMGBubble from "../../assets/images/bubble.png";
import useMission from "../../hooks/useMission";
import ModalBubble from "./components/ModalBubble";

export type ILetsPlayGameProps = Record<string, any>;

type BubbleType = [ size: number, message: string, hidden: boolean, position: SxProps<Theme> ];

const BUBBLES: BubbleType[] = [
	[ 89, "Life is a battlefield", false, {} ],
	[ 80, "Life is unfair", false, {} ],
	[ 80, "I can do anything", false, {} ],
	[ 89, "Life is hard", false, {} ],

	[ 80, "I am unlovable", false, {} ],
	[ 63, "Life is full of opportunities", false, {} ],
	[ 80, "I don’t have what it takes", false, {} ],
	[ 89, "I should have a proper job", false, {} ],

	[ 63, "Everyone I meet wishes me well", false, {} ],
	[ 63, "I am worthy of my dreams and desires", false, {} ],
	[ 63, "I don’t have enough time", false, {} ],
	[ 63, "I’m just not good with money", false, {} ]
];

const UPDATE_POSITION_TIMER: number = 4000;

function LetsPlayGame({ ...props }: ILetsPlayGameProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const bubblesBoxRef = useRef<HTMLDivElement>(null);

	const [ bubbles, setBubbles ] = useState<BubbleType[]>(BUBBLES);
	const [ selectedBubble, setSelectedBubble ] = useState<string | null>(null);

	// Effects
	useLayoutEffect(() =>
	{
		const timerId = setInterval(animateBubbles, UPDATE_POSITION_TIMER - 100);

		animateBubbles();

		function animateBubbles()
		{
			if (bubblesBoxRef.current === null)
			{
				return;
			}

			const { width, height } = bubblesBoxRef.current.getBoundingClientRect();

			setBubbles((prevState) =>
			{
				let bubbles: BubbleType[] = deepClone(prevState);

				bubbles = bubbles.map((item) =>
				{
					if (item[ 2 ] === true)
					{
						return item;
					}

					item[ 3 ] =
					{
						left: randomInt(0, width - item[ 0 ]) + "px",
						top: randomInt(0, height - item[ 0 ]) + "px"
					};

					return item;
				});

				return bubbles;
			});
		}

		return () => clearInterval(timerId);
	}, []);

	useLayoutEffect(() =>
	{
		if (!bubbles.some(([ , , hidden ]) => hidden === false))
		{

			missionContext.nextStep();
		}
	}, [ bubbles ]); // eslint-disable-line

	// Handles
	function selectBubble(index: number)
	{
		setSelectedBubble(bubbles[ index ][ 1 ]);

		setBubbles((prevState) =>
		{
			const bubbles: typeof BUBBLES = deepClone(prevState);

			bubbles[ index ][ 2 ] = true;

			return bubbles;
		});
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsPlayGame_title" defaultValue="Let’s play a game, {NAME}!" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsPlayGame_subTitle" defaultValue="Let’s see how many of the bubbles you can catch and pop!" position="left" isDescription />}
					sx={{
						marginBottom: "50px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box
					ref={bubblesBoxRef}
					sx={{
						width: "80%",
						height: "450px",
						position: "relative",

						"& > img":
						{
							transition: `linear ${ UPDATE_POSITION_TIMER }ms left, linear ${ UPDATE_POSITION_TIMER }ms top`
						}
					}}
				>
					{bubbles
						.map(([ size, , hidden, position ], index) =>
						{
							if (hidden === true)
							{
								return null;
							}

							return (
								<Box
									key={index}
									component="img"
									src={IMGBubble}
									alt={"Bubble " + index}
									sx={{
										"--size": { xl: size + "px", lg: size * 0.9 + "px", xs: size * 0.9 + "px" },
										position: "absolute",
										maxWidth: "var(--size)",
										maxHeight: "var(--size)",
										cursor: "pointer",
										...position
									}}
									onClick={() => selectBubble(index)}
								/>
							);
						})
					}
				</Box>
			</Box>

			<ModalBubble
				open={selectedBubble !== null}
				TransitionComponent={Grow}
				onClose={() => setSelectedBubble(null)}
				onClick={({ target }) =>
				{
					if ((target as HTMLDivElement).classList.contains("MuiDialog-paper"))
					{
						setSelectedBubble(null);
					}
				}}
			>
				{selectedBubble}
			</ModalBubble>
		</Box>
	);
}

export default forwardRef(LetsPlayGame);
