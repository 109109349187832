import { Box, FormControl } from "@mui/material";
import Api from "api";
import ImageJellyfish from "assets/images/jellyfish.png";
import { ReactComponent as SVGLogo } from "assets/images/logo.svg";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";

function ResetPasswordStepOne()
{
	const [ isEmailSent, setIsEmailSent ] = useState<boolean>(false);
	const [ isFetching, setIsFetching ] = useState<boolean>(false);

	function onFormSubmit({ email }: { email: string; })
	{
		if (isFetching === true)
		{
			return;
		}

		setIsFetching(true);

		Api.auth
			.forgotPassword(email)
			.then(() =>
			{
				setIsEmailSent(true);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => setIsFetching(false));
	}

	return (
		<Box sx={{
			background: "linear-gradient(35deg, #7889B0  0%, #7FA9D0 75%, #CACFCC 100%)",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			minHeight: "100vh",
			padding: "20px"
		}}>
			<SVGLogo style={{ fill: "#FFFFFF", position: "absolute", left: "20px", top: "20px", width: "58px", height: "40px" }} />

			<Box sx={{ maxWidth: "680px", width: "100%" }}>
				<Box sx={{ font: "700 42px/56px Lora-Bold", textAlign: "center", color: "#FFFFFF" }}>
					Forgot Password
				</Box>

				<Box sx={{
					marginTop: "20px",
					padding: "60px 100px 60px 100px",
					border: "2px solid #DFE7F1",
					borderRadius: "20px",
					backgroundColor: "rgba(245, 242, 242, .4)"
				}}>
					<Formik
						validateOnBlur
						validateOnChange
						initialValues={{ email: "" }}
						onSubmit={onFormSubmit}
						validationSchema={
							yup.object({ email: config.email.yup() })
						}
					>
						{({ values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty }) =>
						{
							return (
								<Box
									component="form"
									onSubmit={handleSubmit}
									sx={{
										display: (isEmailSent === true ? "none" : "flex"),
										flexDirection: "column",
										justifyContent: "center",
										gap: "40px"
									}}
								>
									<label>
										<FormControl sx={{ width: "100%" }}>
											<Box sx={{ font: "700 22px/28px Lora-Bold", marginLeft: "10px", color: "#215C75" }}>
												Email
											</Box>

											<CustomInput.Base
												type="email"
												name="email"
												placeholder="email"
												autoComplete="email"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												sx={{ marginTop: "10px" }}
											/>

											<Box sx={{
												position: "absolute",
												left: "5px",
												bottom: "-19px",
												color: "#DC4242",
												font: "400 14px/19px 'Open Sans'"
											}}>
												{touched.email ? errors.email : null}
											</Box>
										</FormControl>
									</label>

									<CustomButton.Base
										type="submit"
										loading={isFetching === true}
										disabled={!(isValid && dirty)}
										sx={{ display: "flex", margin: "0 auto" }}
									>
										Send email
									</CustomButton.Base>
								</Box>
							);
						}}
					</Formik>

					<Box sx={[
						{
							font: "400 22px/30px 'Open Sans'",
							color: "#FFFFFF",
							textAlign: "center",
							transition: "linear 300ms opacity"
						},
						(isEmailSent === false) &&
						{
							display: "none",
							opacity: 0
						}
					]}>
						If a user with such an email address exists, then an email was sent to this email address.
						You can close this page.
					</Box>
				</Box>

				<img src={ImageJellyfish} alt="" style={{ display: "block", margin: "20px auto 0 auto", width: "246px", height: "143px" }} />
			</Box>
		</Box>
	);
}

export default ResetPasswordStepOne;
