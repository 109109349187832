import { Box, Grow } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { ReactNode, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "utils/localStorage";
import { IMissionContext, MissionCtx } from "./MissionCtx";
import IMGBackgroundBlur from "./assets/images/background-blur.jpg";
import IMGBackgroundGreen from "./assets/images/background-green.jpg";
import IMGBackgroundHomeCenterGratitude from "./assets/images/background-home-center-gratitude.jpg";
import IMGBackgroundHomeCenter from "./assets/images/background-home-center.jpg";
import IMGBackgroundHome from "./assets/images/background-home.jpg";
import IMGBackgroundMount from "./assets/images/background-mount.jpg";
import IMGBackground from "./assets/images/background.jpg";
import AnythingMissing from "./components/AnythingMissing";
import CelebrationScreen1 from "./components/Celebration/CelebrationScreen1";
import Complete from "./components/Complete";
import ViewArtifact from "./components/Complete/ViewArtifact";
import GettingStart from "./components/GettingStart";
import LetsPlayGame from "./components/LetsPlayGame";
import LifePeaks from "./components/LifePeaks";
import LifePeaksFormView from "./components/LifePeaks/FormView";
import ListOfValues from "./components/ListOfValues";
import PauseAndResume from "./components/PauseAndResume";
import PracticalRecommendations from "./components/PracticalRecommendations";
import PrioritiesYourValues from "./components/PrioritiesYourValues";
import ProgressMessage from "./components/ProgressMessage";
import ServiceChat from "./components/ServiceChat";
import ToughDecisions from "./components/ToughDecisions";
import ToughDecisionsFormView from "./components/ToughDecisions/FormView";
import TravelIntoFutureGratitudeForm from "./components/TravelIntoFuture/GratitudeForm";
import TravelIntoFutureRegretsForm from "./components/TravelIntoFuture/RegretsForm";
import WelcomeBack from "./components/WelcomeBack";
import WhatMakesProud from "./components/WhatMakesProud";
import WhatMakesProudFormView from "./components/WhatMakesProud/FormView";
import Feedback from "./components/feedback";

export type BackgroundType = Record<"default" | "green" | "blur" | "mount" | "home" | "homeCenter" | "homeCenterGratitude", string>;

export const Background: BackgroundType =
{
	default: `url(${ IMGBackground }) center center/cover`,
	green: `url(${ IMGBackgroundGreen }) center center/cover`,
	blur: `url(${ IMGBackgroundBlur }) center center/cover`,
	mount: `url(${ IMGBackgroundMount }) center center/cover`,
	home: `url(${ IMGBackgroundHome }) center center/cover`,
	homeCenter: `url(${ IMGBackgroundHomeCenter }) center center/cover`,
	homeCenterGratitude: `url(${ IMGBackgroundHomeCenterGratitude }) top center/cover no-repeat`
};

const BackgroundPreload = [
	IMGBackground,
	IMGBackgroundGreen,
	IMGBackgroundBlur,
	IMGBackgroundHome,
	IMGBackgroundHomeCenter,
	IMGBackgroundHomeCenterGratitude,
	IMGBackgroundMount
];

const keyContent = "contentMissionTwo" as const;
const missionPrefix = "mission-two" as const;

const STEPS = [
	/*  1 */ WelcomeBack,
	/*  2 */ GettingStart,
	/*  3 */ ServiceChat,
	/*  4 */ LetsPlayGame,
	/*  5 */ CelebrationScreen1,
	/*  6 */ PauseAndResume,
	/*  7 */ LifePeaks,
	/*  8 */ ListOfValues,
	/*  9 */ WhatMakesProud,
	/* 10 */ ListOfValues,
	/* 11 */ ToughDecisions,
	/* 12 */ ListOfValues,
	/* 13 */ ProgressMessage,
	/* 14 */ AnythingMissing,
	/* 15 */ PrioritiesYourValues,
	/* 16 */ PracticalRecommendations,
	/* 17 */ Complete,
	/* 18 */Feedback,
	/* 19 */ ViewArtifact
] as const;

const EXTRA_STEPS = [
	/* 1 */[ LifePeaksFormView, { index: 0 } ],
	/* 2 */[ LifePeaksFormView, { index: 1 } ],
	/* 3 */[ LifePeaksFormView, { index: 2 } ],
	/* 4 */[ LifePeaksFormView, { index: 0, preview: true } ],
	/* 5 */[ LifePeaksFormView, { index: 1, preview: true } ],
	/* 6 */[ LifePeaksFormView, { index: 2, preview: true } ],

	/* 7 */[ WhatMakesProudFormView, {} ],
	/* 8 */[ WhatMakesProudFormView, { preview: true } ],

	/*  9 */[ ToughDecisionsFormView, { index: 0 } ],
	/* 10 */[ ToughDecisionsFormView, { index: 1 } ],
	/* 11 */[ ToughDecisionsFormView, { index: 2 } ],
	/* 12 */[ ToughDecisionsFormView, { index: 3 } ],
	/* 13 */[ ToughDecisionsFormView, { index: 4 } ],
	/* 14 */[ ToughDecisionsFormView, { index: 0, preview: true } ],
	/* 15 */[ ToughDecisionsFormView, { index: 1, preview: true } ],
	/* 16 */[ ToughDecisionsFormView, { index: 2, preview: true } ],
	/* 17 */[ ToughDecisionsFormView, { index: 3, preview: true } ],
	/* 18 */[ ToughDecisionsFormView, { index: 4, preview: true } ],

	/* 19 */[ TravelIntoFutureRegretsForm, {} ],
	/* 20 */[ TravelIntoFutureGratitudeForm, {} ]
] as const;

const PERCENTAGE_PER_STEP: number = 100 / (STEPS.length - 1);

function MissionTwoContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();

	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ isShowTour, setIsShowTour ] = useState<boolean>(false);
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ showContent, setShowContent ] = useState<boolean>(true);

	const [ step, setStep ] = useLocalStorage<number>(missionPrefix + "-step", 1);
	const [ extraStep, setExtraStep ] = useLocalStorage<number | null>(missionPrefix + "-extraStep", null);
	const [ progress, setProgress ] = useLocalStorage<number>(missionPrefix + "-progress", 0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");

	const [ tipsData, setTipsData ] = useState<{ title?: string; content: ReactNode; position?: "bottom" | "center"; } | null>(null);

	// Context
	const missionTwoContext: IMissionContext = useMemo(() =>
	{
		return {
			step,
			setStep,
			prevStep,
			nextStep,
			extraStep,
			setExtraStep,
			progress,
			setProgress,
			background,
			setBackground,
			isShowTour,
			setIsShowTour,
			showContent,
			setShowContent,

			keyContent,
			missionPrefix
		};
	}, [ background, isShowTour, progress, showContent, step ]);

	// Utils
	function nextStep()
	{
		setStep((step) => step + 1);
	}

	function prevStep()
	{
		setStep((step) => step - 1);
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(2, step).catch((error) => console.error(error));

		// Change tips content
		setIsBlipTips(true);

		if (step === 4)
		{
			setIsShowTour(true);
		}

		// Progress
		setProgress((step - 1) * PERCENTAGE_PER_STEP);

		// Change tips content
		switch (step)
		{
			case 4: { // LetsPlayGame
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="LetsPlayGame_tips"
								defaultValue="**Why:** Before you define your values, it’s important to know which values are healthier for you. This game will help you understand how to choose good personal values. \n\n**Do:** Assess each value on the 2 criteria we’ve discussed in the previous step:\n\nConstructive vs Destructive\nControllable vs Uncontrollable\n\n\n**Think:** While we ask you to assess values as ‘bad’ or ‘good’, it’s not black and white. Each value has its positive and negative aspects to it. But the point of this game is to learn how to choose values that will nurture you.\n\nFor example, if you choose to value something that enhances you and others and is within your control, it’s a win!"
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 8:
			case 10:
			case 12:
			case 15: { // ListOfValues
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="ListOfValues_tips"
								defaultValue="You can combine values that are similar, for example, if you have Health and Wellness as values, you can combine them and call them something else, like Physical Wellbeing."
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 7: { // LifePeaks
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="LifePeaks_tips"
								defaultValue="**Why:** Peak moments you recalled are your cues to uncovering your personal values. Since each moment had a special meaning for you, it’s likely there is a deeper underlying thing about that moment that you value.\n\n**Do:** For each of your peak moments, think of what specifically made that moment so special.\n\n**Think:** What was an underlying big thing that made that time of your life special? Was it something you were doing? Or did you feel in a particular way? Or maybe you were in a certain environment? Were you surrounded by special type of people?"
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 9: { // WhatMakesProud
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="WhatMakesProud_tips"
								defaultValue="**Why:** If there is something you are proud of, it’s likely you think of it as an important quality, and hence it’s what you value.\n\n**Do:** Describe in a few sentences all the qualities and characteristics you are most proud of in yourself. \n\n**Think:** If you struggle to find something you are proud of, think about the things you really like about yourself. You can also think of the things that others admire in you or the things that you admire in other people."
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 11: { // ToughDecisions
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="ToughDecisions_tips"
								defaultValue="**Why:** Each tough decision you’ve recalled contains cues to your personal values. Whatever helped you make each of your tough decision, can be translated into your personal values.\n\n**Do:** For each of your tough decisions, think of the underlying things or decision-making criteria that guided your choice. \n\n**Think:** If you get stuck, think about what made your decision so hard, that can also contain cues to your values. Try to remember what was your biggest argument in making the decision, it’s likely to be one of your values."
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}

		switch (extraStep)
		{
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6: { // LifePeaks form
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="LifePeaks_tips"
								defaultValue="**Why:** Peak moments you recalled are your cues to uncovering your personal values. Since each moment had a special meaning for you, it’s likely there is a deeper underlying thing about that moment that you value.\n\n**Do:** For each of your peak moments, think of what specifically made that moment so special.\n\n**Think:** What was an underlying big thing that made that time of your life special? Was it something you were doing? Or did you feel in a particular way? Or maybe you were in a certain environment? Were you surrounded by special type of people?"
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 7:
			case 8: { // WhatMakesProud form
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="WhatMakesProud_tips"
								defaultValue="**Why:** If there is something you are proud of, it’s likely you think of it as an important quality, and hence it’s what you value.\n\n**Do:** Describe in a few sentences all the qualities and characteristics you are most proud of in yourself. \n\n**Think:** If you struggle to find something you are proud of, think about the things you really like about yourself. You can also think of the things that others admire in you or the things that you admire in other people."
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 9:
			case 10:
			case 11:
			case 12:
			case 13:
			case 14:
			case 15:
			case 16:
			case 17:
			case 18: { // ToughDecisions form
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="ToughDecisions_tips"
								defaultValue="**Why:** Each tough decision you’ve recalled contains cues to your personal values. Whatever helped you make each of your tough decision, can be translated into your personal values.\n\n**Do:** For each of your tough decisions, think of the underlying things or decision-making criteria that guided your choice. \n\n**Think:** If you get stuck, think about what made your decision so hard, that can also contain cues to your values. Try to remember what was your biggest argument in making the decision, it’s likely to be one of your values."
								position="right"
								isDescription
							/>
						)
					}
				);
				break;
			}

			case 19: { // TravelIntoFuture (Regrets)
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="LifeFullRegrets_tips"
								defaultValue="**Why:** Imagining yourself at the end of life gives you a perspective on what really matters to you in life. Reflecting on a life full of regrets is a great way to avoid those regrets in the future.\n\n**Do:** Let your imagination go wild. If it helps, you can close your eyes and imagine yourself as an old person at the end of your life. Think of all the things that you may regret — big or small.\n\n**Think:**  If you get stuck, think of the things you wish you did more of or less of. You can think of practical or more philosophical aspects of your life."
								position="right"
								isDescription
							/>
						)
					}
				);

				break;
			}

			case 20: { // TravelIntoFuture (Gratitude)
				setTipsData(
					{
						title: "Instructions",
						content: (
							<ContentAdmin
								limit={1000}
								keyContent="contentmissionTwo"
								keyText="LifeFullGratitude_tips"
								defaultValue="**Why:** This exercise gives you perspective on what you truly value in life. It’s a great way to zoom out and discover the underlying values that will help you live a life full of gratitude.\n\n**Do:** Imagine yourself as an old person, looking back at your happy and well-lived life. You can close your eyes. Think of all the things that you feel truly grateful for and that bring a smile to your face.\n\n**Think:** Some people find this exercise easier, some harder. Don’t take it too seriously and have fun with it. If you get stuck, reflect on the things that come to your mind first."
								position="right"
								isDescription
							/>
						)
					}
				);

				break;
			}
		}

		// Background
		if (extraStep === 20)
		{
			return setBackground("homeCenterGratitude");
		}

		switch (step)
		{
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
				setBackground("mount");
				break;

			case 17:
			case 18:
			case 19:
				setBackground("blur");
				break;

			default:
				setBackground("default");
		}

	}, [ step, extraStep ]);

	// Render
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "fixed", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<Box sx={{
				width: "100%",
				display: { sm: "flex", xs: "none" },
				flexDirection: "column",
				alignItems: "center",
				gap: "25px",
				background: Background[ background ],
				minHeight: "100vh",
				height: "100vh",
				maxHeight: "100vh",
				backgroundAttachment: "fixed",
				padding: { xl: step === 17 || step === 18 ? 0 : "24px", lg: step === 17 || step === 18 ? 0 : "16px", xs: step === 17 || step === 18 ? 0 : "16px" },
				zIndex: "10"
			}}>
				<Box sx={{
					display: (step === 29 || step === 17 || step === 18 ? "none" : "unset"),
					position: "fixed",
					top: "40%",
					right: "0",
					zIndex: 5555,
					pointerEvents: "none"
				}}>
					<GetHelpButton />
				</Box>

				{/* Header */}
				{step !== 18 && (
					<Box
						component="header"
						sx={{
							width: "100%",
							maxHeight: { xl: "48px", lg: "32px", xs: "32px" },
							display: (step === 17 || step === 18 || step === 19 ? "none" : "flex"),
							justifyContent: "space-between",
							alignItems: "center",
							gap: "15px"
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ margin: "unset", marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center", width: "58px" }}
									/> / {STEPS.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "18px" }}>
							{tipsData !== null &&
								<TooltipStyled title="Tips" placement="bottom" arrow>
									<TipsButton
										isActive={isBlipTips}
										blip={false}
										onClick={() =>
										{
											setIsShowTips(true);
											setIsBlipTips(false);
											setShowContent(false);
										}}
									/>
								</TooltipStyled>}
							<MissionProgressBar value={progress} />
						</Box>
					</Box>
				)
				}

				{/* Main */}
				<MissionCtx.Provider value={missionTwoContext}>
					<Box
						component="main"
						sx={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
							gap: "25px"
						}}
					>
						{extraStep !== null &&
							EXTRA_STEPS.map(([ Component, props ], index) =>
							{
								if (extraStep !== index + 1)
								{
									return false;
								}

								// @ts-ignore
								return (<Component key={index} {...props} />);

								// return (
								// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
								// 		<Component />
								// 	</Grow>
								// );
							})
						}

						{extraStep === null &&
							STEPS.map((Component, index) =>
							{
								if (step !== index + 1)
								{
									return false;
								}

								// @ts-ignore
								return (<Component key={index} />);
								// return (
								// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
								// 		<Component />
								// 	</Grow>
								// );
							})
						}
						{extraStep === null && step > STEPS.length &&
							(
								<Grow timeout={{ enter: 1000, exit: 0 }} in unmountOnExit><h1>Step: {step} / {STEPS.length}</h1></Grow>
							)
						}
					</Box>

					{/* Tips content */}
					{tipsData !== null &&
						(
							<MissionsDialog
								sx={{
									"& .MuiPaper-root": {
										color: "#4D4D4D",
										maxWidth: "985px",
										padding: "53px 56px 24px 56px",
										"& p": {
											m: "0px 0px 25px 0px"
										}
									},
									"& .MuiDialog-container": {
										position: "relative",
										top: (step === 4 ? "95px" : ((step === 12 || step === 9) ? "90px" : ((step === 5 || step === 14 || step === 10 || step === 11) ? "70px" : (step === 6 ? "110px" : (step === 8 ? "60px" : (step === 16 ? "50px" : "0px")))))),
										left: (step === 4 ? "10px" : ((step === 12 || step === 6 || step === 16) ? "10px" : "0px")),
										right: "auto"
									}
								}}
								title={tipsData.title}
								open={isShowTips}
								onClose={() => { setIsShowTips(false); setShowContent(true); }}
								position={tipsData.position ?? "center"}
							>
								{tipsData.content}
							</MissionsDialog>
						)
					}
				</MissionCtx.Provider>
			</Box>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default MissionTwoContainer;
