import { Box, Button, styled, SxProps, Theme } from "@mui/material";
import { MouseEvent, useLayoutEffect, useState } from "react";
import { ReactComponent as SVGghost } from "../../assets/images/missions/mission-eight/ghost.svg";
import { ReactComponent as SVGnewghost } from "../../assets/images/missions/mission-eight/newghost.svg";
import { mergeDeep } from "../../utils/mergeDeep";

const ButtonStyled = styled(Button)(() => (
	{
		position: "relative",
		padding: "0",
		minHeight: "55px",
		"&:hover":
		{
			backgroundColor: "unset"
		}
	}
)) as typeof Button;

interface IRadialButtonProps
{
	value?: string;
	showValue?: boolean;
	absoluteValuePosition?: boolean;
	direction?: "right" | "left";
	blip?: boolean;
	selected?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	color?: "white" | "blue";
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	disabled?: boolean;
	isTransform?: boolean;
	sx?: SxProps<Theme>;
	[ key: string ]: any;
	selectedBtn?: boolean;
}

function GhostButton(
	{
		value,
		showValue = true,
		absoluteValuePosition = false,
		direction = "right",
		blip = false,
		selected = false,
		blipDurationMs = 2000,
		offBlipOnClick = true,
		color = "white",
		onClick,
		className,
		isTransform,
		disabled = false,
		sx,
		selectedBtn,
		...props
	}: IRadialButtonProps
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<ButtonStyled
			disableRipple
			disabled={disabled}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={
				mergeDeep({
					margin: (showValue === false ? "11.5px 0" : direction === "right" ? "11.5px 0 11.5px 40px" : "11.5px 40px 11.5px 0")
				}, sx)
			}
			{...props}
		>
			<Box
				sx={{
					transform: isTransform ? "scale(-1, 1)" : ""
				}}
			>
				{selectedBtn ?
					<SVGnewghost />
					: <SVGghost />}
			</Box>
		</ButtonStyled>
	);
}

export default GhostButton;
