import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import LinkMI from "@mui/material/Link";
import type { ResGetCoupons } from "api/Coupons";
import octsSubscribe from "assets/images/octsSubscribe.png";
import Dialog from "components/Dialog";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useState, type Dispatch, type SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import getDateSubscription from "utils/date";
import type { PackagesObj } from "..";
import CheckCoupon from "./CheckCoupon";

interface DialogDetailsPackageProps
{
	activeItem: PackagesObj | undefined;
	openDialogDetails: boolean;
	setOpenDialogDetails(val: boolean): void;
	handleClick: any;
	setCoupon: Dispatch<SetStateAction<ResGetCoupons | undefined>>;
}
function DialogDetailsPackage({
	activeItem,
	openDialogDetails,
	setOpenDialogDetails,
	handleClick,
	setCoupon
}: DialogDetailsPackageProps)
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ detailsSub, setDetailsSub ] = useState<boolean>(false);
	const [ isCheckCoupon, setIsCheckCoupon ] = useState<boolean>(false);

	function formatPrice(value: number): string
	{
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	// Render
	return (
		<Dialog
			title={detailsSub ? "Details of your subscription" : isCheckCoupon ? "Coupon check" : activeItem?.details?.title}
			open={openDialogDetails}
			onClose={() => { setOpenDialogDetails(false); setDetailsSub(false); setIsCheckCoupon(false); }}
			sx={{
				"& .MuiPaper-root":
				{
					height: detailsSub ? "425px" : isCheckCoupon ? "340px" : "703px",
					"& *:not(img)": { zIndex: 1 },
					maxWidth: isCheckCoupon ? "630px" : "980px"
				}
			}}
		>
			{isCheckCoupon
				? <CheckCoupon setIsCheckCoupon={setIsCheckCoupon} setCoupon={setCoupon} /> :

				detailsSub ?
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Box>
							<Typography sx={{ font: "400 16px/32px 'Open Sans'", mt: 4, mb: 9 }}>
								{`Your subscription will be valid until ${ getDateSubscription(String(new Date()), 30) } and automatically renewed
								on ${ getDateSubscription(String(new Date()), 31) }. You can cancel it any time in your account settings.`}
							</Typography>
							{activeItem
								? (
									<CustomButton.Contained
										disabled={
											(activeItem.details?.title === "Lifetime Access" || activeItem.details?.title === "Monthly Subscription")
											&& userContext.state.user?.fullSubscribe
										}
										onClick={(event) =>
										{
											if (activeItem.details?.title === "Free Trial")
											{
												setOpenDialogDetails(false);
											}
											else
											{
												handleClick(activeItem)(event);
											}
										}}
										sx={{ flexGrow: 1, maxWidth: "100%" }}
									>
										Continue to payment
									</CustomButton.Contained>
								)
								: null

							}
						</Box>
						<Box
							component="img"
							src={octsSubscribe}
							alt=""
							sx={{
								width: "243px",
								mt: "-60px",
								display: { sm: "block", xs: "none" }
							}}
						/>
					</Box> :

					activeItem
						? (
							<>
								<Box
									component="img"
									src={octsSubscribe}
									alt=""
									sx={{
										position: "absolute",
										top: "119px",
										right: "46px",
										width: "305px",
										display: { sm: "block", xs: "none" }
									}}
								/>

								<Box sx={{
									font: { sm: "400 22px/30px 'Open Sans'", xs: "400 16px/20px 'Open Sans'" },
									color: "primary.dark",
									marginTop: { sm: "16px", xs: "8px" },
									opacity: 0.5
								}}>
									{activeItem.details?.description}
								</Box>

								<Box
									component="ul"
									sx={{
										margin: "unset",
										padding: "unset",
										marginTop: { sm: "40px", xs: "32px" },
										position: "relative",
										listStyle: "none",
										display: "flex",
										flexDirection: "column",
										gap: "30px",

										"& li":
										{
											display: "flex",
											gap: { sm: "18px", xs: "8px" }
										}
									}}
								>
									{activeItem.details?.subTitle.map((itemSubtitle, indexSubtitle) => (
										<li key={indexSubtitle}>
											<CheckIcon sx={{
												fill: "#215C75",
												fontSize: "24px",
												mt: { xl: "3px", xs: "4px" }
											}} />

											<Box sx={{ font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/32px 'Open Sans'" } }}>
												{itemSubtitle}
											</Box>
										</li>
									))}
								</Box>
								<CustomButton.Outline
									onClick={() => setIsCheckCoupon(true)}
									sx={{ maxWidth: "100%", mt: 2 }}
								>
									Apply Promo Code
								</CustomButton.Outline>
								<Box sx={{
									marginTop: "24px",
									width: "100%",
									display: "flex",
									flexGrow: 1,
									flexWrap: "wrap",
									justifyContent: "space-between",
									alignItems: "flex-end",
									gap: "16px 40px"
								}}>
									<CustomButton.Outline
										onClick={() =>
										{
											if (activeItem.details?.title === "Free Trial")
											{
												navigate("/dashboard");
											}
											else
											{
												setOpenDialogDetails(false);
											}
										}}
										sx={{ maxWidth: "100%" }}
									>
										{activeItem.details?.title === "Free Trial"
											? "Continue with free trial"
											: "Choose another plan"
										}
									</CustomButton.Outline>
									{activeItem.details?.title === "Personalised Guidance"
										? (
											<CustomButton.Contained
												component={LinkMI}
												href="https://www.yourlifestartup.com/change-your-life-step-by-step"
												sx={{ maxWidth: "100%" }}
											>
												Apply
											</CustomButton.Contained>
										)
										: (
											<CustomButton.Contained
												disabled={
													(activeItem.details?.title === "Lifetime Access" || activeItem.details?.title === "Monthly Subscription")
													&& userContext.state.user?.fullSubscribe
												}
												onClick={(event) =>
												{
													if (activeItem.details?.title === "Free Trial")
													{
														setOpenDialogDetails(false);
													}
													else
													{
														if (activeItem.details?.title === "Monthly Subscription")
														{
															setDetailsSub(true);
														}
														else
															handleClick(activeItem)(event);
													}
												}}
												sx={{ maxWidth: "100%" }}
											>
												{(activeItem.details?.title === "Monthly Subscription" && userContext.state?.user?.subscriptionIsActive)
													? "Unsubscribe"
													: activeItem.details?.title === "Free Trial"
														? "Get the Full Quest"
														// : coupon ? `Pay €  ${ formatPrice(activeItem.price - activeItem.price * (coupon.percentOff / 100)).toFixed(2) }` : `Pay € ${ formatPrice(activeItem.price) }`
														: "Pay €" + formatPrice(activeItem.price)
												}
											</CustomButton.Contained>
										)
									}
								</Box>
							</>
						)
						: null
			}
		</Dialog>

	);
}

export default DialogDetailsPackage;
