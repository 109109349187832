import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import bgImgInner from "assets/images/missions/mission-ten/bgImgInner.png";
import ContentAdmin from "components/contentAdmin";
import { ICluster } from "pages/dashboard/artifacts/yearning/Details";
import { useState } from "react";

export interface IMission2Props
{
	isOpen?: boolean;
	closeModal?(): void;
	imgUrl?: string;
	title?: string;
}

function ModalMissionThree({ isOpen = false, closeModal, imgUrl, title }: IMission2Props)
{
	const [ data, setData ] = useState<ICluster[] | null>(null);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);
	const [ isMove, setIsMove ] = useState<boolean>(false);

	// Handles
	// useEffect(() =>
	// {
	// 	Api.missions
	// 		.getDataMissionsThree()
	// 		.then(({ data }) =>
	// 		{
	// 			Api.missions
	// 				.getDataMissionThree(data[ data.length - 1 ].id)
	// 				.then(({ data }) =>
	// 				{
	// 					setIsErrorLoading(false);
	// 					setData(data.mission_data.clusters);
	// 				})
	// 				.catch((error) =>
	// 				{
	// 					setIsErrorLoading(true);
	// 					console.error(error);
	// 				});

	// 		})
	// 		.catch((error) =>
	// 		{
	// 			setIsErrorLoading(true);
	// 			console.error(error);
	// 		});
	// }, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			maxHeight: "392px",
			position: "relative",
			// backgroundColor: '#D9D9D9',
			backgroundImage: `url(${ bgImgInner })`,
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
			borderRadius: "24px",
			borderTopLeftRadius: "75px",
			borderBottomLeftRadius: "75px"
		}}>
			<CancelIcon
				onClick={closeModal}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					fill: "#3E809D",
					"&:hover": { cursor: "pointer" }
				}}
			/>

			<Box
				component="img"
				src={imgUrl}
				alt="side image"
				className="ImageSideModal-image"
				sx={{
					width: "140px",
					height: "392px",
					borderRadius: "100px",
					filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))"
				}}
			/>

			<Box sx={{ width: "100%", padding: "24px 24px 30px 40px" }}>

				<Typography sx={{ font: { lg: "700 24px/40px Lora", xl: "700 40px/51px Lora" }, color: "#215C75", mb: "12px" }}>
					{title}
				</Typography>
				<Box className="customScroll" sx={{ overflowY: "auto", height: "270px", paddingRight: "8px", font: { lg: '400 18px/27px "Open Sans"', xl: '400 24px/33px "Open Sans"' }, color: "#4D4D4D" }}>
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="modalMissionThree_txt"
						defaultValue="This is such a useful exercise to create clarity when you feel overwhelmed. As you move on in life, you will tick things off your list and will want to add new ones.\nYou can also use your old maps to track your progress. Are you achieving what you want? Have your priorities shifted? What area of your life is your primary focus today?"
						position="right"
						isDescription
					/>
					{/* <Typography sx={{ font: '400 24px/33px "Open Sans"', color: '#4D4D4D', mb: '12px' }}>
						This is such a useful exercise to create clarity when you feel overwhelmed. As you move on in life, you will tick things off your list and will want to add new ones.
					</Typography>
					<Typography sx={{ font: '400 24px/33px "Open Sans"', color: '#4D4D4D', mb: '12px' }}>
						You can also use your old maps to track your progress. Are you achieving what you want? Have your priorities shifted? What area of your life is your primary focus today?
					</Typography> */}

					{/* {(data === null || data === undefined) && isErrorLoading === false &&
						(
							<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
								Loading data <CircularProgress size={24} />
							</Box>
						)
					}
					{(data === null || data === undefined) && isErrorLoading === true &&
						(
							<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
								Failed to retrieve data, please try again
							</Box>
						)
					}
					{data !== null && data !== undefined && isErrorLoading === false && */}
					{/* <Box sx={{
						flexGrow: 1,
						display: "flex",
						gap: "10px",
						borderRadius: "24px",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						padding: "10px",
						height: "100%",
						cursor: (isMove ? "grabbing" : "grab"),
						// Chips
						"& .MuiChip-root":
						{
							borderRadius: "60px",
							padding: "10px 14px",
							height: "unset",
							justifyContent: "space-between",
							gap: "10px",
							display: "flex",
							boxShadow: "0px 3px 6px " + alpha("#000", 0.16),
							"& .MuiChip-label":
							{
								color: "#FFFFFF",
								font: "500 16px 'Open Sans'",
								padding: "0",
								whiteSpace: "normal"
							},
							"& .MuiChip-deleteIcon":
							{
								margin: "0px",
								fill: "#215C75",
								opacity: ".3",
							},
							"& .MuiChip-deleteIcon:hover":
							{
								opacity: "1"
							},
							"&.MuiChip-root--color-red":
							{
								background: alpha(COLORS.red, 0.5)
							},
							"&.MuiChip-root--color-green":
							{
								background: alpha(COLORS.green, 0.5)
							},
							"&.MuiChip-root--color-blue":
							{
								background: alpha(COLORS.blue, 0.5)
							},
							"&.MuiChip-root--color-none":
							{
								background: alpha(COLORS.none, 0.5),
								"& .MuiChip-label": { color: "#4D4D4D" }
							}
						}
					}}> */}
					{/* <Box
							sx={{
								position: "relative",
								zIndex: 2,
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								"& .cluster__column":
								{
									padding: "5px",
									borderRadius: "10px",
									width: "300px",
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									border: "1px solid " + alpha("#FFFFFF", 0.7),
									boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
									"&.cluster__column--highlight-drop .cluster__items:empty::after":
									{
										borderColor: alpha("#215C75", 1),
										color: "#4D4D4D"
									}
								},
								"& .cluster__title": { font: "700 16px/20px Lora", textAlign: "center", color: "#245D74" },
								"& .cluster__items":
								{
									display: "flex",
									flexWrap: "wrap",
									gap: "10px",
									alignItems: "center",
									overflow: "auto",
									maxHeight: "380px",
									padding: "0 10px 10px 10px",
									"&:empty":
									{
										"&::after":
										{
											content: "'Drop to here'",
											padding: "10px",
											display: "block",
											width: "100%",
											textAlign: "center",
											color: alpha("#4D4D4D", 0.6),
											font: "500 16px 'Open Sans'",
											border: "2px dashed " + alpha("#215C75", 0.6),
											borderRadius: "5px",
											transition: "linear 200ms border-color, linear 200ms color"
										}
									},
									"& .MuiChip-root":
									{
										flexGrow: 1,
										cursor: "inherit",
										"& .MuiChip-label":
										{
											textAlign: "center",
											flexGrow: 1
										}
									}
								},
								"& .react-transform-wrapper":
								{
									flexGrow: 1,
									width: "100%",
									height: "100%",
									cursor: (isMove ? "grabbing" : "grab"),
									borderRadius: "10px",
								},
								"& .react-transform-component":
								{
									gap: "20px",
									justifyContent: "center",
									alignItems: "flex-start",
									width: "100%",
									maxWidth: "628px",
									borderRadius: "10px"
								}
							}}> */}

					{/* <TransformWrapper
									minScale={0.5}
									maxScale={1}
									centerZoomedOut={true}
									centerOnInit={true}
									wheel={{ excluded: [ "cluster__items", "MuiChip-root", "MuiChip-label" ] }}
									onPinchingStart={() => setIsMove(true)}
									onPanningStart={() => setIsMove(true)}
									onPinchingStop={() => setIsMove(false)}
									onPanningStop={() => setIsMove(false)}
								>
									{({ zoomIn, zoomOut }) => (
										<>
											<TransformComponent>
												{data.map(
													(cluster, clusterIndex) =>
													(
														<Box key={cluster.title + "-" + clusterIndex} className="cluster__column">
															<Box className="cluster__title">{cluster.title}</Box>
															<Box className="cluster__items scrollCustom">
																{cluster.items.map(
																	(clusterItem, clusterItemIndex) =>
																	(
																		<Chip
																			key={clusterItem.title + "-" + clusterItemIndex}
																			label={clusterItem.title}
																			className={"MuiChip-root--color-" + clusterItem.color}
																		/>
																	)
																)}
															</Box>
														</Box>
													)
												)}
											</TransformComponent>
											<Box
												sx={{
													position: "absolute",
													bottom: "0px",
													right: "0px",
													display: "flex",
													gap: "10px",
													alignItems: "center",
													"& .navigation-button":
													{
														background: "none",
														border: "none",
														cursor: "pointer",
														padding: "5px",
														borderRadius: "10px",
														width: "49px",
														height: "49px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														transition: "linear 200ms background-color",
														"&:hover": { backgroundColor: alpha("#215C75", 0.2) }
													}
												}}
											>
												<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Zoom Out">
													<Box component="button" className="navigation-button" onClick={() => zoomOut(0.25)}>
														<SVGZoomOut />
													</Box>
												</TooltipStyled>

												<TooltipStyled TransitionComponent={Zoom} placement="top" arrow title="Zoom In">
													<Box component="button" className="navigation-button" onClick={() => zoomIn(0.25)}>
														<SVGZoomIn />
													</Box>
												</TooltipStyled>
											</Box>
										</>
									)}
								</TransformWrapper> */}
					{/* </Box> */}
					{/* </Box> */}
					{/* } */}
				</Box>
			</Box>
		</Box>
	);
}

export default ModalMissionThree;
