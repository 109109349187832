import { Box, Fade, Grow } from "@mui/material";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionTwo/hooks/useMission";
import { KeyboardEvent, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { useLocalStorage } from "utils/localStorage";

export type ListOfValuesData = string;

function ListOfValues()
{
	const missionContext = useMission();
	const [ listOfValues, setListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ newListOfValues, setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ newValue, setNewValue ] = useState<string>("");

	const [ isFirstRender, setIsFirstRender ] = useState<boolean>(true);

	// Effects
	useEffect(() =>
	{
		const timerId = setTimeout(() => setIsFirstRender(false), 1000);

		return () => clearTimeout(timerId);
	}, []);

	useEffect(() =>
	{
		if (newListOfValues.length > 0)
		{
			const intervalId = setInterval(() =>
			{
				setListOfValues((prevValues) =>
				{
					return Array.from(new Set([ ...prevValues, newListOfValues[ 0 ] ]));
				});
				setNewListOfValues((prevValues) => prevValues.slice(1));

				if (newListOfValues.length === 1)
				{
					clearInterval(intervalId);
				}
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [ newListOfValues ]);

	// Handles
	function onDeleteValue(value: ListOfValuesData)
	{
		return () =>
		{
			setListOfValues((prevState) =>
			{
				return prevState.filter((listValue) => listValue !== value);
			});
		};
	}

	function onKeyDownAddNewValue(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		const value = newValue.toLowerCase().trim();

		if (value.length < 2)
		{
			return;
		}

		if (listOfValues.indexOf(value) !== -1)
		{
			return setNewValue("");
		}

		setListOfValues((prevValues) =>
		{
			return [ ...prevValues, value ];
		});

		setNewValue("");
	}

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<PanelTitle
				imageVariation="closeEye"
				title={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="ListOfValues_title"
					defaultValue="Your list of values"
					position="left"
				/>}
				subTitle={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="ListOfValues_subTitle"
					defaultValue="{NAME}, look at all the values you’ve collected. Don’t worry about the order for now, but feel free to add, remove or edit your values until you have a list you are happy with"
					position="left"
					isDescription
				/>}
				positionOcta
				sx={{
					marginBottom: "41px",

					"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
					"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
				}}
			/>

			<Fade in={missionContext.showContent === true}>
				<div>
					<Panel sx={{
						height: { xl: "512px", lg: "434px", xs: "356px" },
						width: { xl: "740px", lg: "100%", xs: "100%" },
						margin: "0 auto",
						padding: "24px 32px 32px 32px",
						display: "grid",
						gridTemplateRows: "auto 1fr",
						gap: "24px"
					}}>
						<Box sx={{
							color: "primary.main",
							font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
							textAlign: "center"
						}}>
							My values
						</Box>

						<Box
							component={TransitionGroup}
							className="customScroll"
							sx={{
								paddingRight: "8px",
								paddingBottom: "5px",
								height: "100%",
								overflowY: "auto",
								display: "flex",
								flexWrap: "wrap",
								alignContent: "flex-start",
								gap: "16px"
							}}
						>
							{listOfValues.map((value, index, array) =>
							{
								return (
									<Grow
										key={value}
										timeout={
											isFirstRender === true
												? 0
												: { enter: 1000, exit: index + 1 === array.length && index === 19 ? 0 : 300 }
										}
									>
										<ChipStyled
											label={value}
											onDelete={onDeleteValue(value)}
											sx={{ textTransform: "capitalize" }}
										/>
									</Grow>
								);
							})}

							{(listOfValues.length < 20 && newListOfValues.length === 0) &&
								<Grow key="__input__" timeout={{ enter: 3000, exit: 0 }}>
									<ChipStyled
										label={
											<Box
												component="input"
												placeholder="Add new value"
												maxLength={20}
												value={newValue}
												onChange={({ target }) =>
												{
													setNewValue(target.value.replaceAll(/\s+/g, ""));
												}}
												onKeyDown={onKeyDownAddNewValue}
												sx={{
													padding: "unset",
													background: "unset",
													border: "unset",
													font: "inherit",
													color: "primary.dark",
													"&::placeholder":
													{
														color: "inherit",
														font: "inherit",
														opacity: 0.5
													}
												}}
											/>
										}
										sx={{ width: "142px" }}
									/>
								</Grow>
							}
						</Box>
					</Panel>

					<CustomButton.Contained
						size="large"
						onClick={() => missionContext.nextStep()}
						sx={{
							display: "flex",
							margin: "24px auto 0 auto"
						}}
					>
						Submit
					</CustomButton.Contained>
				</div>
			</Fade>
		</Box>
	);
}

export default ListOfValues;
