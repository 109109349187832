// import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CardContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiPaper-root": {
		[ theme.breakpoints.down("xl") ]: {
			minHeight: "108px",
			top: "130px",
			maxWidth: "633px"
		},
		[ theme.breakpoints.up("xl") ]: {
			minHeight: "132px",
			top: "200px",
			maxWidth: "870px"
		},
		width: "100%",
		borderRadius: 24,
		background: " linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
		backdropFilter: "blur(30px)",
		boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
		border: "1px solid rgba(255, 255, 255, .5)",
		position: "relative",
		margin: "0px"
	},

	"& .MuiDialogContent-root": {
		padding: theme.spacing(0),
		position: "relative"

	},
	"& .MuiCardContent-root": {
		[ theme.breakpoints.down("xl") ]: {
			padding: "24px"
		},
		[ theme.breakpoints.up("xl") ]: {
			padding: "32px"
		},
		display: "flex",
		flexDirection: "column"
	},
	"& .MuiDialogTitle-root": {
		marginBottom: "11px",
		color: "#215C75",
		fontFamily: "Lora-Bold",
		fontSize: "40px",
		fontWeight: "700",
		lineHeight: "51px",
		textAlign: "left",
		padding: "0px 40px 0 0"
	},
	"& .MuiIconButton-root": {
		width: "25px",
		height: "25px",
		position: "absolute",
		top: "30px",
		right: "30px",
		backgroundColor: "#3E809D",

		"&:hover": {
			backgroundColor: "#3E809D"
		}
	},
	"& .MuiIconButton-root svg": {
		fill: "#fff",
		width: "15px",
		height: "15px"
	},
	"& .MuiDialog-container": {
		alignItems: "center",
		paddingBottom: "15px",
		marginTop: "100px"
	},
	"& .MuiModal-backdrop": {
		backgroundColor: "rgba(0, 0, 0, 0)"
	}
}));

function BootstrapDialogTitle(props: any)
{
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle {...other} sx={{ minHeight: "102px" }}>
			{children}
			{onClose
				? (
					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				)
				: null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired
};

export default function ModalWindow({ open, handleClose, project, handleChangeBallsValues }: any)
{
	const nodeRef = useRef(null);

	return (
		<CSSTransition in={open} nodeRef={nodeRef} timeout={300}>
			<BootstrapDialog ref={nodeRef} onClose={handleClose} open={open}>
				<DialogContent dividers>
					<CardContent>
						<Typography sx={{
							marginBottom: "8px",
							color: "#4D4D4D",
							font: { xs: '400 16px/22px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
							textAlign: "left"
						}}>
							{/* {project.id}<br /> */}
							{project.desc}
						</Typography>
						{project.auth
							? (
								<Typography
									sx={{
										marginBottom: "0px",
										color: "#4D4D4D",
										font: { xs: '400 16px/22px "Open Sans" italic', xl: '400 22px/30px "Open Sans" italic' },
										fontStyle: "italic",
										textAlign: "right"
									}}>
									{project.auth}
								</Typography>
							)
							: null
						}
					</CardContent>
				</DialogContent>
			</BootstrapDialog>
		</CSSTransition>
	);
}
