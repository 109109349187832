import { Box } from "@mui/material";
import { forwardRef, Ref, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import Buttons from "./Buttons";
import SliderBlock from "./feature/SliderBlock";

interface ButtonValues
{
	value: number;
	id: number;
	changed: boolean;
}

const items = [
	{
		id: 1,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="emergency_plan"
			defaultValue="Emergency plan"
			position="left"
		/>
	},
	{
		id: 2,
		title: <ContentAdmin
			limit={35}
			keyContent="contentMissionEight"
			keyText="immediate_emergency_plan"
			defaultValue="Immediate action Plan"
			position="left"
		/>
	}
];
const YourPlan = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const [ open, setOpen ] = useState(false);
	const [ selectedButton, setSelectedButton ] = useState(1);

	function onClose()
	{
		return setOpen(false);
	}

	function onOpen()
	{
		return setOpen(true);
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				flexDirection: "column",
				display: "flex",
				justifyContent: "space-between",
				height: "80vh",
				width: "100%",
				maxWidth: { lg: "802px", xl: "980px" },
				m: "10px auto 0px",
				position: "relative",
				top: "-19px"
			}}>
			<Box sx={{ position: "relative" }}>
				<PanelTitle
					sx={{ fontSize: "10px !important" }}
					color="#ffffff"
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEight"
						keyText="your_plan_title"
						defaultValue="Your plan"
						position="right"
					/>}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="your_plan_subtitle"
						defaultValue="Now that you are well prepared, fears can’t stop you from reaching the stars, {NAME}. Review your plans and make any final tweaks. You will find both plans in your artifacts in case you want to come back to them later."
						position="right"
						isDescription
					/>}
				/>
				<Box sx={{ position: "relative", marginTop: "15px", transform: { lg: "scale(0.9)", xl: "scale(1)" } }}>
					<Buttons
						onOpen={onOpen}
						open={open}
						setSelectedButton={setSelectedButton}
						selectedButton={selectedButton}
					/>
					{open
						? (
							<Box sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: `${ selectedButton === 1 ? "flex-start" : "flex-end" }`,
								position: {
									xs: "relative",
									sm: "absolute"
								},
								zIndex: "10",
								top: "0px",
								width: "100%",
								marginLeft: "0px"
							}}>
								<SliderBlock
									onClose={onClose}
									item={items[ selectedButton - 1 ]}
									sx={{
										borderTopRightRadius: (selectedButton === 1 ? "0px" : "24px"),
										borderBottomRightRadius: (selectedButton === 1 ? "0px" : "24px"),
										borderTopLeftRadius: (selectedButton === 1 ? "24px" : "0px"),
										borderBottomLeftRadius: (selectedButton === 1 ? "24px" : "0px")
									}}
								/>
							</Box>
						)
						: null
					}
				</Box>
			</Box>
		</Box>
	);
});

export default YourPlan;
