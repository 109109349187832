import { Box, keyframes } from "@mui/material";
import { forwardRef, Ref } from "react";

export type IBouncingDotsLoaderProps = Record<string, any>;

const DOT_SIZE: number = 12;

const BouncingDotsLoader = forwardRef((props: IBouncingDotsLoaderProps, ref: Ref<HTMLDivElement>) =>
{
	const bouncing = keyframes`
		to
		{
			opacity: 0.2;
			transform: translateY(${ DOT_SIZE + "px" });
		}
	`;

	return (
		<Box
			ref={ref}
			className="BouncingDotsLoader-root"
			sx={{
				display: "inline-flex",
				justifyContent: "center",
				gap: "6px",
				"--dot-size": DOT_SIZE + "px",
				"& .BouncingDotsLoader-dot":
				{
					width: "var(--dot-size)",
					height: "var(--dot-size)",
					borderRadius: "50%",
					opacity: 1,
					backgroundColor: "#215C75",
					animation: bouncing + " 600ms infinite alternate",
					"&:nth-of-type(2)": { animationDelay: "200ms" },
					"&:nth-of-type(3)": { animationDelay: "400ms" }
				}
			}}
			{...props}
		>
			<div className="BouncingDotsLoader-dot" />
			<div className="BouncingDotsLoader-dot" />
			<div className="BouncingDotsLoader-dot" />
		</Box>
	);
});

export default BouncingDotsLoader;
