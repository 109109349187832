import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import ModalWindow from "../../../../components/modalWindow/item";
import useMission from "../../hooks";
import Buttons from "./Buttons";

interface StarValues
{
	value: number;
	id: number;
	changed: boolean;
}

const items = [
	{
		id: 1,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_first_text"
			defaultValue="“The best time to plant a tree was 20 years ago. The second best time is now.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_first_auth"
			defaultValue="Chinese proverb"
			position="right"
		/>
	},
	{
		id: 2,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_2_text"
			defaultValue="“There is only one success: to be able to spend your life in your own way.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_2_auth"
			defaultValue="Christoper Morley"
			position="right"
		/>
	},
	{
		id: 3,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_3_text"
			defaultValue="“Once we accept our limits, we go beyond them.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_3_auth"
			defaultValue="Albert Einstein"
			position="right"
		/>
	},
	{
		id: 4,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_4_text"
			defaultValue="“Do not go where the path may lead, go instead where there is no path and leave a trail.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_4_auth"
			defaultValue="Ralph Waldo Emerson"
			position="right"
		/>
	},
	{
		id: 5,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_5_text"
			defaultValue="“If you’re always trying to be normal you will never know how amazing you can be.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_5_auth"
			defaultValue="Maya Angelou"
			position="right"
		/>
	},
	{
		id: 6,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_6_text"
			defaultValue="“Promise me you’ll always remember you are braver than you believe, and stronger than you seem, and smarter than you think”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_6_auth"
			defaultValue="Christopher Robin"
			position="right"
		/>
	},
	{
		id: 7,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_7_text"
			defaultValue="“You only live once, but if you do it right, once is enough.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_7_auth"
			defaultValue="Mae West"
			position="right"
		/>
	},
	{
		id: 8,
		desc: <ContentAdmin
			limit={111}
			keyContent="contentMissionEleven"
			keyText="items_8_text"
			defaultValue="“The ship is safe in harbor. But that’s not what ships are for.”"
			position="top"
		/>,
		auth: <ContentAdmin
			limit={30}
			keyContent="contentMissionEleven"
			keyText="items_8_auth"
			defaultValue="John A. Shedd"
			position="right"
		/>
	}

];
const FewWords = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ open, setOpen ] = useState(false);
	const [ selectedStar, setSelectedStar ] = useState(1);
	const [ showBtnNextStep, setShowBtnNextStep ] = useState(false);
	const [ showBtns, setShowBtns ] = useState(true);
	const [ StarValues, setStarValues ] = useState<StarValues[]>([
		{ value: 1, id: 1, changed: false },
		{ value: 1, id: 2, changed: false },
		{ value: 1, id: 3, changed: false },
		{ value: 1, id: 4, changed: false },
		{ value: 1, id: 5, changed: false },
		{ value: 1, id: 6, changed: false },
		{ value: 1, id: 7, changed: false },
		{ value: 1, id: 8, changed: false }
	]);

	function onClose()
	{
		setOpen(false);
		setShowBtns(true);
		const buttons = document.querySelectorAll("button");

		buttons.forEach(item =>
		{
			if (item.style.display === "none")
			{
				item.style.display = "";
			}
		});

		localStorage.setItem("mission-eleven-few-words", JSON.stringify(StarValues));

	}

	function onOpen(id: number)
	{
		setStarValues(StarValues.map((item: StarValues) =>
		{
			if (item.id === id)
			{
				return { ...item, changed: true };
			}

			return item;

		})); setOpen(true);
	}

	function handleStep()
	{
		const localValue: any = [];

		StarValues.map((item: StarValues) => localValue.push({ id: item.id, value: item.value }));
		localStorage.setItem("few-words-star-change", JSON.stringify(localValue));

		missionContext.setStep((prevState) => prevState + 1);
	}

	function handleChangeStarValues(event: Event, newValue: number)
	{

		setStarValues(StarValues.map((item: StarValues) =>
		{
			if (item.id === selectedStar)
			{
				return { ...item, value: newValue, changed: true };
			}

			return item;

		}));
	}

	useEffect(() =>
	{
		let count = 0;

		StarValues.forEach((item: StarValues) =>
		{
			if (item.changed) count += 1;
		});
		if (count === 7) setShowBtnNextStep(true);
	}, [ StarValues ]);

	useEffect(() =>
	{
		const newStarValues = localStorage.getItem("mission-eleven-few-words");
		if (newStarValues !== null)
		{
			const newStarValuesConst = JSON.parse(newStarValues);

			setStarValues(newStarValuesConst);
		}
	}, []);

	return (
		<>
			<Box
				ref={ref}
				{...props}
				sx={{
					flexDirection: "column",
					display: "flex",
					justifyContent: "space-between",
					height: "81vh",
					width: "100%",
					maxWidth: { sm: "700px", md: "700px", lg: "814px", xl: "980px" },
					m: "10px auto 0px",
					position: "relative"
				}}>
				<Box sx={{ position: "relative" }}>
					<PanelTitle
						color="#ffffff"
						positionOcta
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionEleven"
							keyText="few_words_title"
							defaultValue="A few words of inspiration"
							position="right"
						/>}
						subTitle={<ContentAdmin
							limit={300}
							keyContent="contentMissionEleven"
							keyText="few_words_sub_title"
							defaultValue="Living life on your terms is the best and the hardest thing you will ever do. Before you go, I’d like to share with you some of my favourite inspiring quotes that keep me going when I start to doubt myself. Enjoy!"
							position="right"
							isDescription
						/>}
					/>
					{showBtns
						? (
							<Buttons
								onOpen={onOpen}
								setSelectedStar={setSelectedStar}
								StarValues={StarValues}
							/>
						)
						: null
					}

					{open
						? (
							<Box sx={{ position: "absolute", top: 0, width: { xs: "632px", xl: "100%" } }}>
								<ModalWindow
									open={open}
									handleClose={onClose}
									project={items[ selectedStar ]}
									handleChangeStarValues={handleChangeStarValues}
								/>
							</Box>
						)
						: null
					}
				</Box>
			</Box>

			{showBtnNextStep
				? (
					<StepperNavigationWrapper>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton direction="right" onClick={handleStep} />
					</StepperNavigationWrapper>
				)
				: null
			}
		</>
	);
});

export default FewWords;
