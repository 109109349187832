import { alpha, Dialog as MuiDialog, Slide, SlideProps, styled, SxProps, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, ReactNode } from "react";

const StyledDialog = styled(MuiDialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			borderRadius: "20px",
			font: "400 22px/30px 'Open Sans'"
		}
	}
);

const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionSlideDown = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionSlideRight = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="right" ref={ref} {...props} />;
});

const TransitionSlideLeft = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="left" ref={ref} {...props} />;
});

const TRANSITION =
{
	up: TransitionSlideUp,
	down: TransitionSlideDown,
	right: TransitionSlideRight,
	left: TransitionSlideLeft
};

export interface IDialogProps
{
	open: boolean;
	children: ReactNode;
	/**
	 * @default up
	 */
	directionSlide?: SlideProps[ "direction" ];
	position?: "bottom" | "center";
	sx?: SxProps<Theme>;
	onClose?(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton"): void;
	[ prop: string ]: any;
}

function Dialog({ children, directionSlide = "up", position = "center", onClose = undefined, open = false, sx = [], ...props }: IDialogProps)
{
	return (
		<StyledDialog
			open={open}
			TransitionComponent={TRANSITION[ directionSlide ] || undefined}
			onClose={onClose}
			sx={[
				{
					"& .MuiDialog-container":
					{
						alignItems: (position === "center" ? "center" : "flex-end")
					},
					"& .MuiDialog-paper":
					{
						marginBottom: (position === "bottom" ? "56px" : "unset")
					},
					"& .MuiPaper-root":
					{
						maxWidth: "980px",
						color: "#4D4D4D",
						background: "linear-gradient(135deg, rgba(239, 239, 239, .8), rgba(239, 239, 239, .1))",
						boxShadow: "0px 5px 30px rgba(36, 92, 116, .15)",
						padding: "32px",
						width: "100%",
						backdropFilter: "blur(25px)",
						border: "1px solid " + alpha("#FFFFFF", 0.5)
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			{children}
		</StyledDialog>
	);
}

export default Dialog;
