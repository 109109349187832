import Api, { getAccessToken } from "api";
import { IResponseGetUser } from "./Users";

export interface IResponseAdminLogin
{
	message: string;
}
export interface IResponseGetPackages
{
	id: string;
	monthly: number;
	yearly: number;
	lifetime: number;
	decideLater: number;
}
export interface IResponseAllUser extends IResponseGetUser { }

export interface IGetLetterData
{
	/**
	 * @example "63e642a7d60ede80bb12cace"
	 */
	_id: string;
	id: number;
	step: string;
	email: string;
	letterSubject: string;
	textLink: { text: string; link: string; };
	personalize: string;
	whatToSend: string;
	toWhomToSend: string;
	comment: string;
	trigerredTimes: number;
	opened: number;
	bounced: number;
	clicked: number;
	ctr: number;
}

export type ResponseAdminGetContent = [
	{ _id: string; id: string; } & Record<string, Record<string, string> | string>
];

export interface IAddContentData
{
	key: string;
	data: Record<string, string>;
}

export default class Admin
{
	async login(password: string)
	{
		return (
			await Api.instance.post<IResponseAdminLogin>(
				"/admin/login",
				{ password },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}

	async allUsers(password: string)
	{
		return (
			await Api.instance.post<IResponseAllUser[]>(
				"/admin/allUsers",
				{ password },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}

	async getAllLetters()
	{
		return (
			await Api.instance.get<IGetLetterData[]>(
				"/admin/getAllLetters",
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}

	async getContent()
	{
		return (
			await Api.instance.get<ResponseAdminGetContent>("/admin/getContent")
		).data;
	}

	async getPackages()
	{
		return (
			await Api.instance.get<IResponseGetPackages[]>("/admin/getPackages")
		).data;
	}

	async setPackages(data: any)
	{
		return (
			await Api.instance.post<"success">(
				"/admin/setPackages",
				{ data },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async setContentProd()
	{
		return (
			await Api.instance.get(
				"/admin/setContentProd",
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}

	async addContent(data: IAddContentData)
	{
		return (
			await Api.instance.post<string>(
				"/admin/addContent",
				data,
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}

	async followingLink(emailId: number, time: number)
	{
		return (
			await Api.instance.post<string>(
				"/admin/followingLink",
				{ emailId, time },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			)
		).data;
	}
}
