import { Box } from "@mui/material";
import { Ref, forwardRef } from "react";
import calmSuccess from "../../../assets/images/calmSuccess.svg";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const FinishReasonsAvoid = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		// missionContext.setBlurBg(false);
		missionContext.setStep((prevState) => prevState + 1);

	}

	return (
		<Box ref={ref} {...props} sx={{ height: "81vh", width: "100%", maxWidth: "980px", m: "0 auto" }}>
			<Box sx={{ width: { lg: "150px", xl: "199px" }, height: { lg: "300px", xl: "359px" }, m: { lg: "50px auto 30px", xl: "100px auto 30px" } }}>
				<img width="100%" src={calmSuccess} alt="guide-chara" />
			</Box>
			<Box sx={{
				font: { lg: "700 30px/41px Lora", xl: "700 40px/51px Lora" },
				color: "#E9EDF0",
				textAlign: "center",
				wordBreak: "break-word",
				maxHeight: "102px",
				overflow: "hidden",
				maxWidth: "760px",
				margin: "0 auto"
			}}>
				<ContentAdmin
					limit={111}
					keyContent="contentMissionEight"
					keyText="finish_reasons-avoid"
					defaultValue="Being honest with yourself is not easy, well done!"
					position="top"
				/>
			</Box>
			<Box sx={{
				display: "flex",
				justifyContent: "center",
				mt: 5
			}}>
				<CustomButton.Outline
					onClick={nextStep}
					sx={{
						background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)",
						backdropFilter: "blur(30px)",
						color: "#fff",
						borderColor: "#fff"
					}}
				>
					Thanks!
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default FinishReasonsAvoid;
