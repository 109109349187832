import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Coupons from "./Tabs/Coupons";
import Emails from "./Tabs/Emails";
import MergeContent from "./Tabs/MergeContent";
import Packages from "./Tabs/Packages";
import Users from "./Tabs/Users";

const TABS =
{
	users: 0,
	packages: 1,
	contentMerging: 2,
	emails: 3
};

const DEFAULT_TAB: keyof typeof TABS = "users";

function AdminPage()
{
	const navigate = useNavigate();
	const { tab } = useParams();
	const [ scroll, setScroll ] = useState(0);
	const [ selectedTab, setSelectedTab ] = useState((tab && TABS[ tab as keyof typeof TABS ]) ?? 0);

	// Utils
	function getTabName(index: number): keyof typeof TABS
	{
		const result = Object.entries(TABS).find(([ , number ]) => number === index);

		if (result === undefined)
		{
			return DEFAULT_TAB;
		}

		return result[ 0 ] as keyof typeof TABS;
	}

	// Effects
	useLayoutEffect(() =>
	{
		const password = sessionStorage.getItem("admin-password");

		if (password === null)
		{
			return navigate("/admin");
		}
	}, [ navigate ]);

	useEffect(() =>
	{
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	function handleScroll()
	{
		setScroll(window.scrollY);
	}

	function scrollTop()
	{
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	// Render
	return (
		<Box
			className="scrollAdmin"
			sx={{
				background: "linear-gradient(to right bottom, rgba(152, 176, 181,0.8 ), rgba(148, 230, 247,0.80196078431372549 ))",
				minHeight: "100vh",
				overflowY: "scroll",
				display: "grid",
				gridTemplateRows: "auto 1fr",

				"@supports (min-height: 100dvh)":
				{
					minHeight: "100dvh"
				}
			}}
		>
			<Box sx={{
				position: "fixed",
				top: 0,
				backgroundColor: "#3E809D",
				width: "100%",
				zIndex: 999
			}}>
				<Tabs
					sx={{ color: "#fff" }}
					value={selectedTab}
					onChange={(_event, newValue) =>
					{
						setSelectedTab(newValue);
						navigate("/admin/" + getTabName(newValue));
					}}
				>
					<Tab sx={{ color: "#fff !important" }} label="Users" />
					<Tab sx={{ color: "#fff !important" }} label="Packages" />
					<Tab sx={{ color: "#fff !important" }} label="Content merging and send all emails" />
					<Tab sx={{ color: "#fff !important" }} label="Emails" />
					<Tab sx={{ color: "#fff !important" }} label="Coupons" />
				</Tabs>
			</Box>
			<Box sx={{ margin: "70px 24px 24px" }}>
				{selectedTab === 0 && <Users />}
				{selectedTab === 1 && <Packages />}
				{selectedTab === 2 && <MergeContent />}
				{selectedTab === 3 && <Emails />}
				{selectedTab === 4 && <Coupons />}
			</Box>
			{scroll > 20
				?
				(
					<Button
						onClick={scrollTop}
						sx={{
							position: "fixed",
							bottom: "70px",
							right: "30px",
							background: "#3E809D",
							zIndex: 999,
							borderRadius: "50px",
							minWidth: "10px",
							p: 1,
							"&:hover": {
								background: "#6c9eb4"
							}
						}}
					>
						<KeyboardDoubleArrowUpIcon sx={{ color: "#fff" }} />
					</Button>
				)
				:
				null}
		</Box>
	);
}

export default AdminPage;
