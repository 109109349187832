import { createContext, Dispatch, SetStateAction } from "react";

export interface IRegistrationContext
{
	setStep: Dispatch<SetStateAction<number>>;

	name: string;
	setName: Dispatch<SetStateAction<string>>;

	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
}

export const RegistrationCtx = createContext<IRegistrationContext>({} as IRegistrationContext);
