import { Alert, Box, BoxProps, Fade, Snackbar, alpha } from "@mui/material";
import Api from "api";
import useUser from "hooks/useUser";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useRef, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import MissionsDialog from "../../../../components/missions/Dialog";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import * as SVGPersonIcons from "../../../missionSeven/components/WhatAreYourFamilyBeliefs/assets/icons";
import ImageButton from "../../../missionSeven/components/WhatAreYourFamilyBeliefs/components/ImageButton";
import useMission from "../../hooks/useMission";
import { AttractiveInfoType } from "../JobToLove";
import { IPointInfo } from "../LifeHighsLows";

export interface IPersonalJobAdProps extends BoxProps { }

export const INPUT_MAX_LEN: number = 1000;
export const EXAMPLES =
{
	Eve: "Eve loves coming up with new ideas and applying her creative touch to everyday problems. She wants to be the boss, working on projects that drive impact. She’s very comfortable in client facing roles and gets a thrill out of public speaking and presenting. She finds deep satisfaction in making a sale!\n\nEve draws pleasure from good design in all forms including art, architecture, interior design, curated products, marketing/web-design, and fashion. She is well organized, disciplined, and structured, but at the same time design focuses, creative, and bold.\n\nShe is looking for a role where she can work remotely and flexibly (mid-day workouts should be an acceptable norm). She’s most productive when surrounded by nature or in a clean and beautiful environment.",
	Robert: "Robert has a passion for solving complex problems, finding innovative solutions and building teams to solve these problems. He is analytical, critical thinker, structured, disciplined, organized, but also creative and loves to inspire those around him.\n\nRobert is fascinated with how things transform through space and time and always ponders over things that could drive the fundamental qualitative improvement to human lives.\n\nHe is looking for a role where decisions are made based on science/research/facts with the right leadership behind the wheel to make bold moves and leaps of faith sometimes trusting intuition over numbers. Needs a flexible schedule to work when she is most inspired or productive.",
	Natalya: "Natalya loves coming up with new ideas, analysing and structuring information and drawing insights from unstructured data.\n\nShe finds deep satisfaction in helping others achieve their goals. Natalya finds deep satisfaction in helping others deal with life struggles and lead happier lives.\n\nShe is well organised, disciplined, structured but at the same time creative, bold and courageous. She is looking for a role in which she would have the autonomy and flexibility to work around her schedule (she is more productive when she could squeeze in a cheeky yoga practice in her day) in a beautiful environment with wooden tables and friendly people.",
	George: "George is looking for work-life environment, where he can easily integrate his job responsibilities with his daily life in a playful and creative manner. He is looking for a creative role to direct his artistic energy and liberate his thoughts.\n\nGeorge would like to travel from time to time, meet new people, capture small cultural or natural details and transform them into his personal expression.\n\nWhen you organise his space, please, make sure it is outdoors, with lots of greenery and water (a seaside view would be a bonus). It should be quiet, peaceful and full of harmony. Do not force him to be static all the time, as he needs to move and take breaks with long walks or yoga sessions every day. Also, we hope that you could provide him with high-quality coffee, fruits and berries and desserts, as he is a real sweet tooth and loves taking a peaceful moment with a cup of coffee and some chocolate.",
	Mimi: "Mimi is a smart and authentic person, that connects to people and advocates high quality work. Mimi builds trust with people through demonstrating integrity, genuine interest and reliability.\n\nMimi grew up in a world of science (astronomy) and Information Technology Services. She knows a lot about everything, but is not a specialist of any kind. She is certified in the area of project management and has extensive experience as a manager of teams up to 100 people.\n\nHer passion is with people, traveling, nature and her family and friends. Mimi is always interested in doing or learning something new and gets bored easily if he doesn’t.\n\nMimi wants to work in an environment that matters to people, not just for money and profit. She is keen on having flexible working hours and work location, mixing his other responsibilities in life with work in an efficient way. She wants to work with other people that can challenge her and provide feedback / be a sparring partner."
};

function PersonalJobAd({ ...props }: IPersonalJobAdProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const isFetchRef = useRef<boolean>(false);
	const { state: userState } = useUser();
	const userName = userState.user?.name || "Name";

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isErrorGTP, setIsErrorGTP ] = useState<boolean>(false);
	const [ isErrorGTPSnack, setIsErrorGTPSnack ] = useState<boolean>(false);
	const [ showModalName, setShowModalName ] = useState<"Eve" | "Robert" | "Natalya" | "George" | "Mimi" | null>(null);
	const [ modalVisited, setModalVisited ] = useState<Set<string>>(new Set());

	const [ inputValue, setInputValue ] = useState<string>(() =>
	{
		const store = getLocalStorage<string>(missionContext.missionPrefix + "-PersonalJobAd-text");

		return store || "";
	});

	// Utils
	function getPointInputData(data: IPointInfo[], type: IPointInfo[ "type" ])
	{
		return data
			.filter((point) => point.type === type)
			.map((point) =>
			{
				const data: string[] = [];

				if (point.data.activities)
				{
					data.push(point.data.activities);
				}

				if (point.data.environment)
				{
					data.push(point.data.environment);
				}

				if (point.data.socialInteractions)
				{
					data.push(point.data.socialInteractions);
				}

				return data.join(", ");
			})
			.filter((items) => items.length > 0)
			.join(", ");
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchRef.current === true || isFetch === true || inputValue.length > 0)
		{
			return;
		}

		const store1 = getLocalStorage<[ string, string, string, string, string[] ]>(missionContext.missionPrefix + "-highsLows-reflects");
		if (store1 === null)
		{
			return missionContext.setStep(7);
		}

		const lifeHighsLowsPointsInfo = getLocalStorage<IPointInfo[]>(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo");
		if (lifeHighsLowsPointsInfo === null)
		{
			return missionContext.setStep(4);
		}

		const highsPointText = getPointInputData(lifeHighsLowsPointsInfo, "highs");
		const lowsPointText = getPointInputData(lifeHighsLowsPointsInfo, "lows");

		const attractiveInfo = getLocalStorage<AttractiveInfoType[]>(missionContext.missionPrefix + "-attractiveInfo");
		if (attractiveInfo === null)
		{
			return missionContext.setStep(11);
		}

		const attractiveInfoText = attractiveInfo
			.filter(([ jobName ]) => jobName !== "__FILL__")
			.map(([ , text ]) => text)
			.join(", ");

		setIsFetch(true);
		isFetchRef.current = true;

		Api.bot
			.getResponseBot(
				`
					Summaries this in under 1000 characters:
					${ userName } likes: ${ highsPointText }, ${ attractiveInfoText }.
					${ userName } dislikes: ${ lowsPointText }.
					${ userName } is the kind of person who: prefers spending time ${ store1[ 0 ] }, interacting with ${ store1[ 2 ] }. ${ userName } would rather be ${ store1[ 1 ] } and ${ store1[ 3 ] }. ${ userName } loves ${ store1[ 4 ].join(", ") }.
				`.trim().replaceAll("\t", ""),
				"summary"
			)
			.then(({ text }) =>
			{
				if (text)
				{
					setInputValue(text);
					setIsErrorGTPSnack(false);
					setIsErrorGTP(false);
				}
				else
				{
					setIsErrorGTPSnack(true);
					setIsErrorGTP(true);
				}

			})
			.catch((error) => console.info(error))
			.finally(() =>
			{
				isFetchRef.current = false;
				setIsFetch(false);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Handles
	function onOpenModal(name: Exclude<typeof showModalName, null>)
	{
		setShowModalName(name);
		setModalVisited((prevState) => prevState.add(name));
	}

	function nextStep()
	{
		setLocalStorage(missionContext.missionPrefix + "-PersonalJobAd-text", inputValue.trim());

		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="PersonalJobAd_title" defaultValue="Write your personal job ad" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="PersonalJobAd_subTitle" defaultValue="Let’s piece it all together! I want you to break the rules and imagine that there are more jobs than people and companies need to apply to fit your requirements. What would you requirements be, {NAME}?" position="left" isDescription />}
					positionOcta
					sx={{
						marginTop: "-20px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Fade in={showModalName === null} timeout={500}>
					<Panel sx={{ padding: "32px 32px 26px 32px" }}>
						<Box sx={{
							color: "primary.main",
							font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" }
						}}>
							<ContentAdmin keyContent={missionContext.keyContent} keyText="PersonalJobAd_panelTitle" defaultValue="{NAME}’s personal job ad" position="left" />
						</Box>

						<Box sx={{ marginTop: { xl: "24px", lg: "16px", xs: "16px" }, position: "relative" }}>
							<Box
								className="customScroll"
								sx={{
									padding: "12px 18px",
									height: { xl: "340px", lg: "246px", xs: "246px" },
									overflowY: "auto",
									backgroundColor: alpha("#FFFFFF", 0.5),
									borderRadius: "8px"
								}}
							>
								<CustomInput.Base
									placeholder="Write your personal job ad here. What talents, passions and skills would you like to use? What are your requirements to you schedule, colleagues, environment? Consult the examples if you get stuck."
									multiline
									inputProps={{ maxLength: INPUT_MAX_LEN }}
									value={inputValue}
									onChange={({ target }) =>
									{
										setInputValue(target.value.replaceAll(/\n{3,}/g, "\n\n"));
									}}
									sx={{
										alignItems: "flex-start",
										backgroundColor: "unset",
										color: "primary.dark",
										width: "100%",
										"& textarea":
										{
											font:
											{
												xl: "400 22px/30px 'Open Sans' !important",
												lg: "400 16px/22px 'Open Sans' !important",
												xs: "400 16px/22px 'Open Sans' !important"
											},
											padding: "0 9px 0 0 !important"
										}
									}}
								/>
							</Box>
							<Box sx={{ opacity: 0.5, font: "400 14px/19px 'Open Sans'", color: "primary.dark", position: "absolute", right: "16px", bottom: "-19px" }}>
								{inputValue?.length}/{INPUT_MAX_LEN} characters
							</Box>
						</Box>

						<Box sx={{
							color: "primary.main",
							font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" },
							marginTop: "32px"
						}}>
							Examples
						</Box>

						<Box sx={{
							marginTop: { xl: "18px", lg: "24px", xs: "24px" },
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-end"
						}}>
							<Box sx={{ display: "flex", gap: "16px" }}>
								{[
									[ "Grandma", "Eve" ],
									[ "Dad", "Robert" ],
									[ "Mom", "Natalya" ],
									[ "Brother", "George" ],
									[ "Sister", "Mimi" ]
								].map(([ icon, name ]) =>
								{
									return (
										<ImageButton
											key={icon}
											SVGImage={SVGPersonIcons[ icon as keyof typeof SVGPersonIcons ]}
											text=""
											isSelected={modalVisited.has(name) === true}
											sx={{
												"--size": { xl: "77px", lg: "48px", xs: "48px" },
												"& .ImageButton-text": { display: "none" }
											}}
											onClick={() => onOpenModal(name as Exclude<typeof showModalName, null>)}
										/>
									);
								})}
							</Box>
							<CustomButton.Contained
								disabled={inputValue?.trim().length < 2 || isErrorGTP}
								onClick={nextStep}
								sx={{
									paddingLeft: { xl: "80px !important", lg: "70px !important", xs: "70px !important" },
									paddingRight: { xl: "80px !important", lg: "70px !important", xs: "70px !important" }
								}}
							>
								Recommend me jobs
							</CustomButton.Contained>
						</Box>
					</Panel>
				</Fade>
			</Box>
			<Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={isErrorGTPSnack} autoHideDuration={6000} onClose={() => setIsErrorGTPSnack(false)}>
				<Alert onClose={() => setIsErrorGTPSnack(false)} severity="error" sx={{ width: "100%" }}>
					An unexpected error occurred. Try again later
				</Alert>
			</Snackbar>
			<MissionsDialog
				open={showModalName !== null}
				title={showModalName as string}
				onClose={() => setShowModalName(null)}
				position="center"
				sx={{
					"& .MuiDialog-container":
					{
						alignItems: { xl: "unset", lg: "flex-end", xs: "flex-end" }
					},
					"& .MuiPaper-root":
					{
						margin: "32px",
						marginTop: { xl: "260px", lg: "32px", xs: "32px" },
						maxWidth: "980px",
						height: "fit-content",
						maxHeight: "600px",
						padding: "32px 32px 32px 40px",
						display: "grid",
						gridTemplateRows: "auto 1fr",
						gap: "24px"
					},
					"& .MissionDialog-cancel":
					{
						top: "32px",
						right: "32px"
					},
					"& .MissionDialog-title":
					{
						font: "700 40px/51px Lora-Bold",
						marginBottom: "unset",
						paddingRight: "32px"
					}
				}}
			>
				<Box
					className="customScroll"
					sx={{
						overflowY: "auto",
						height: "100%",
						paddingRight: "28px",
						font: "400 22px/30px 'Open Sans'",
						color: "primary.dark"
					}}
				>
					{showModalName !== null &&
						(<ContentAdmin keyContent={missionContext.keyContent} keyText={"PersonalJobAd_example_" + showModalName} defaultValue={EXAMPLES[ showModalName ]} position="left" isDescription />)
					}
				</Box>
			</MissionsDialog>
		</Box>
	);
}

export default forwardRef(PersonalJobAd) as ForwardRefExoticComponent<IPersonalJobAdProps>;
