import { Box, SxProps, Theme, Typography } from "@mui/material";
import { MouseEvent } from "react";

interface ImageBlockProps
{
	url: string;
	sx?: SxProps<Theme>;
	title?: string;
	showCircle?: boolean;
	circleBottom?: string;
	onClick?(event: MouseEvent): void;
}

function ImageBlock({ url, sx, title, showCircle = true, circleBottom = "44px", onClick }: ImageBlockProps)
{
	return (
		<Box
			sx={{ cursor: "pointer" }}
			onClick={(event) =>
			{
				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
		>
			<Box sx={{
				width: { lg: "111px", xl: "140px" },
				height: { lg: "308px", xl: "392px" },
				borderRadius: "100px",
				background: `url(${ url })`,
				filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
				position: "relative",
				...sx
			}}>
				{showCircle
					? <Box sx={{
						filter: "drop-shadow(0px 5px 20px rgba(126, 196, 255, 0.4))",
						width: { lg: "44px", xl: "56px" },
						height: { lg: "44px", xl: "56px" },
						border: "2px solid #FFFFFF",
						borderRadius: "50%",
						background: "linear-gradient(180deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.102) 100%)",
						backdropFilter: "blur(30px)",
						position: "absolute",
						left: { lg: "34px", xl: "42px" },
						bottom: { lg: "36px", xl: "44px" },
						zIndex: 2
					}} />
					: null}
			</Box>
			<Typography sx={{
				textAlign: "center", font: { lg: "700 18px/22px Open Sans", xl: "700 24px/33px Open Sans" }, color: "#4D4D4D", mt: 1, maxWidth: "141px"
			}}>{title}
			</Typography>
		</Box>
	);
}

export default ImageBlock;
