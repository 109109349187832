import { Box, BoxProps, Slide, SlideProps, Snackbar } from "@mui/material";
import AlertStyled from "components/AlertStyled";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import { ForwardRefExoticComponent, Ref, forwardRef, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";

export interface ITopJobsForYouSelectProps extends BoxProps { }

function TopJobsForYouSelect({ ...props }: ITopJobsForYouSelectProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);
	const [ selectedJobs, setSelectedJobs ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>(missionContext.missionPrefix + "-selectedJobs");

		return store ?? [];
	});
	const [ jobsList ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsList");

		if (store === null)
		{
			missionContext.setStep(13);

			return [];
		}

		return store;
	});

	// Handles
	function onSelectJob(jobName: string)
	{
		setSelectedJobs((prevState) =>
		{
			const selected = [ ...prevState ];
			const foundJobIndex = selected.indexOf(jobName);

			if (foundJobIndex !== -1)
			{
				selected.splice(foundJobIndex, 1);
			}
			else
			{
				selected.push(jobName);
			}

			return selected;
		});
	}

	function nextStep()
	{
		if (selectedJobs.length < 3 || selectedJobs.length > 12)
		{
			return setIsShowAlert(true);
		}

		setLocalStorage(missionContext.missionPrefix + "-selectedJobs", selectedJobs);
		localStorage.removeItem(missionContext.missionPrefix + "-jobsInformation");

		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_title" defaultValue="Here are top 30 jobs for you" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_subTitle" defaultValue="Oh my, {NAME}, look at all those cool jobs that you could do!" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Panel sx={{
					padding: { xl: "32px", lg: "32px 24px 24px 24px", xs: "32px 24px 24px 24px" },
					maxWidth: { xl: "100%", lg: "609px", xs: "604px" },
					maxHeight: { xl: "623px", lg: "463px", xs: "463px" },
					display: "grid",
					gridTemplateRows: "1fr"
				}}>
					<Box
						className="customScroll"
						component="ul"
						sx={{
							listStyle: "none",
							height: "100%",
							margin: "unset",
							padding: "unset",
							paddingRight: "16px",
							overflowY: "auto",
							display: "grid",
							gridTemplateColumns: { xl: "1fr 1fr 1fr", lg: "1fr 1fr", xs: "1fr 1fr" },
							gap: "26px 50px",
							justifyContent: "space-between",
							color: "primary.dark"
						}}
					>
						{jobsList.map((jobName) =>
						{
							return (
								<li key={jobName}>
									<CheckboxStyledWithLabel
										label={jobName}
										checkboxProps={{
											checked: selectedJobs.includes(jobName),
											onChange()
											{
												onSelectJob(jobName);
											}
										}}
										sx={{
											"--line-height": "22px",
											gap: "16px",
											font: "400 16px/22px 'Open Sans' !important",

											"& .Mui-checked + .CheckboxStyledWithLabel-content":
											{
												fontWeight: "bold"
											}
										}}
									/>
								</li>
							);
						})}
					</Box>
				</Panel>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>

			<Snackbar
				open={isShowAlert}
				autoHideDuration={5000}
				onClose={() => setIsShowAlert(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "140px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
					You must choose from 3 to 12 jobs
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default forwardRef(TopJobsForYouSelect) as ForwardRefExoticComponent<ITopJobsForYouSelectProps>;
