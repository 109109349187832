import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { ReactComponent as SVGBat } from "../../../../assets/images/missions/mission-eight/bat.svg";

import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import capitalizeFirstLetter from "../../../../utils/capitalizerFirstLetter";
import useMission from "../../hooks";
import ButBtns from "./feature/BatBtns";

const titlesHelp = [
	<ContentAdmin
		limit={200}
		keyContent="contentMissionEight"
		keyText="fail_or_fly_one_bat"
		defaultValue="What if I won‘t be able to end my lease if I decide to go traveling"
		position="left"
		isDescription
	/>,
	<ContentAdmin
		limit={200}
		keyContent="contentMissionEight"
		keyText="fail_or_fly_two_bat"
		defaultValue="What if I will not have the same quality of life"
		position="left"
		isDescription
	/>,
	<ContentAdmin
		limit={200}
		keyContent="contentMissionEight"
		keyText="fail_or_fly_three_bat"
		defaultValue="What if I run out of money"
		position="left"
		isDescription
	/>
];

function Fears()
{
	const missionContext = useMission();
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ showHelpText, setShowHelpText ] = useState(false);
	const [ selectedTitle, setSelectedTitle ] = useState<number>(3);
	const [ error, setError ] = useState<string>("");
	const [ arrValues, setArrValues ] = useState<Set<string>>(() =>
	{
		const store = localStorage.getItem("mission-eight-wantInLine");

		if (store === null)
		{
			return new Set();
		}

		return new Set(JSON.parse(store));
	});

	function closeShowHelpText()
	{
		setSelectedTitle(3);
		setShowHelpText(false);
	}

	function handleSelectedTitle(index: number)
	{
		setSelectedTitle(index);
		setShowHelpText(true);
	}

	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 70)
		{
			return setError("The text should not exceed 40 characters");
		}

		if (arrValues.size !== 12)
			setArrValues((prevState) =>
			{
				const state = new Set<string>(prevState);
				const stateSize = state.size;

				state.add(capitalizeFirstLetter(`What if I ${ valueInput.trim() }`));

				if (state.size === stateSize)
				{
					return prevState;
				}

				localStorage.setItem("mission-eight-wantInLine", JSON.stringify(Array.from(state)));

				return state;
			});

		setValueInput("");
	}

	function deleteItemArrValues(item: string)
	{
		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);

			if (state.delete(item) === false)
			{
				return prevState;
			}

			localStorage.setItem("mission-eight-wantInLine", JSON.stringify(Array.from(state)));

			return state;
		});
	}

	function onClickNextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { lg: "802px", xl: "980px" } }}>
			<Box sx={{ maxWidth: "100%", mt: "-20px" }}>
				<Box sx={{ position: "relative", top: "-5px", left: "-39px" }}>
					<PanelTitle
						color="#fff"
						positionOcta
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionEight"
							keyText="fail_or_fly_title"
							defaultValue="Write down all your fears"
							position="right"
						/>
						}
						subTitle={<ContentAdmin
							limit={300}
							keyContent="contentMissionEight"
							keyText="fail_or_fly_sub_title"
							defaultValue="{NAME}, what is it that scares you the most? It’s time to write it all down so that\n you can conquer all of your fears."
							position="right"
							isDescription
						/>
						}
					/>
				</Box>

				<ButBtns selectedTitle={selectedTitle} handleSelectedTitle={handleSelectedTitle} />
				{showHelpText ?
					<Box sx={{
						p: "32px",
						borderRadius: "24px",
						boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						position: "relative",
						width: "715px",
						m: {
							lg: selectedTitle === 0 ? "23px auto 14px" : "23px auto 22px",
							xl: selectedTitle === 0 ? "46px auto 39px" : "46px auto 70px"
						},
						wordWrap: "break-word"
					}}>
						<Box sx={{ position: "relative" }}>
							<Typography sx={{ color: "#215C75", font: "700 24px/31px 'Lora'" }}>
								{titlesHelp[ selectedTitle ]}
							</Typography>
							<Box
								sx={{
									position: "absolute",
									top: "-17px",
									right: "-17px"
								}}
								onClick={closeShowHelpText}
							>
								<CloseIcon
									sx={{
										fill: "#fff",
										fontSize: "14px",
										width: "24px",
										backgroundColor: "#3E809D",
										borderRadius: "40px",
										height: "24px",
										p: "6px",
										cursor: "pointer"
									}} />
							</Box>
						</Box>
						{selectedTitle === 0 ?
							<Box sx={{
								position: "absolute",
								top: "-44px",
								left: "-162px"
							}}>
								<SVGBat />
							</Box>
							: selectedTitle === 1 ?
								<Box sx={{
									position: "absolute",
									top: "-85px",
									left: "226px",
									transform: "rotate(25deg)"
								}}>
									<SVGBat />

								</Box> :
								<Box sx={{
									position: "absolute",
									top: "-59px",
									right: "-149px",
									transform: "rotate(42deg)"
								}}>
									<SVGBat />
								</Box>
						}
					</Box> :
					<Box sx={{ mt: { lg: "23px", xl: 0 } }}>
						<Typography sx={{ color: "#fff", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5, textAlign: "right", mr: "30px", display: { lg: "block", xl: "none" }, mb: "-10px" }}>
							add between 3 and 12 fears
						</Typography>
						<Box sx={{
							display: "flex",
							flexDirection: "column",
							border: "1px solid rgba(255, 255, 255, 0.5)",
							p: { lg: "16px 24px 4px 24px", xl: "4px 24px 4px 24px" },
							borderRadius: "24px",
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							backdropFilter: "blur(30px)",
							mt: { lg: 2, xl: 6 }

						}}>
							<Typography sx={{ color: "#fff", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5, textAlign: "right", mr: "15px", display: { lg: "none", xl: "block" } }}>
								add between 3 and 12 fears
							</Typography>
							<Box sx={{ display: "flex" }}>
								<Typography sx={{ color: "#215C75", font: { lg: "700 18px/21px 'Lora-Bold'", xl: "700 24px/31px 'Lora-Bold'" }, width: "120px", mt: "13px", mr: "19px" }}>
									<ContentAdmin
										limit={20}
										keyContent="contentMissionEight"
										keyText="fail_or_fly_sub_what_if_i"
										defaultValue="What if I"
										position="left"
									/>
								</Typography>
								<TextField
									value={valueInput}
									fullWidth
									autoFocus
									inputProps={{ maxLength: 70 }}
									onChange={(e: ChangeEvent<HTMLInputElement>) => { setValueInput(e.target.value); setError(""); }}
									onKeyDown={handleAddArrValues}
									disabled={arrValues.size >= 12}
									helperText={<Typography sx={{ color: "#fff", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ valueInput.length }/70 characters`}</Typography>}
									placeholder="e.g. will not have the same quality of life"
									sx={{
										"& .MuiInputBase-root fieldset": {
											border: "none"
										},
										"& .MuiOutlinedInput-root": {
											font: '400 22px/30px "Open Sans"',
											color: "#4D4D4D !important",
											backgroundColor: "rgba(255, 255, 255, 0.6)",
											borderRadius: "8px",
											height: "59px",
											lineHeight: "59px"
										},
										"& .MuiFormHelperText-contained": {
											textAlign: "right",
											mt: 0
										}
									}}
								/>
							</Box>

							<Typography sx={{ color: "#E41616", font: "400 14px/31px 'Open Sans'" }}>
								{error || (arrValues.size >= 70 && "Limit of 70 has been reached")}
							</Typography>
						</Box>
					</Box>
				}
				<Box
					className="scrollCustom"
					sx={{
						display: "flex",
						flexWrap: "wrap",
						alignItems: "flex-start",
						alignContent: "flex-start",
						gap: "10px",
						overflow: "auto",
						height: "240px",
						paddingRight: "5px",
						marginTop: { lg: selectedTitle ? "15px" : "7px", xl: selectedTitle ? "56px" : "37px" }

					}}
				>
					<Grid container spacing={3}>
						{Array.from(arrValues).map((item) => (
							<Grid item>
								<Box sx={{ display: "flex" }}>
									<Chip
										key={item}
										label={item}
										onDelete={() => deleteItemArrValues(item)}
										sx={{
											padding: "10px 14px",
											height: "unset",
											justifyContent: "space-between",
											gap: "10px",
											background: "rgba(255, 255, 255, 0.5)",
											display: "flex",
											boxShadow: " 0px 3px 6px rgba(0, 0, 0, 0.161)",
											borderRadius: "40px",
											"& .MuiChip-label":
											{
												color: "#4D4D4D",
												font: "400 16px 'Open Sans'",
												padding: "0",
												whiteSpace: "normal",
												textAlign: "center"
											},
											"& .MuiChip-deleteIcon":
											{
												margin: "0",
												fill: "#215C75",
												opacity: ".3"
											},
											"& .MuiChip-deleteIcon:hover":
											{
												opacity: "1"
											}
										}}
									/>
								</Box>
							</Grid>
						))}
					</Grid>
				</Box>
			</Box>

			{!(arrValues.size < 3) &&
				(
					<StepperNavigationWrapper>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton onClick={onClickNextStep} />
					</StepperNavigationWrapper>
				)
			}
		</Box>
	);
}

export default Fears;
