import { Box, Button, styled, SxProps, Theme } from "@mui/material";
import { MouseEvent, useState } from "react";
import { ReactComponent as SVGInstruction } from "../../assets/images/icons/instruction.svg";

const ButtonStyled = styled(Button)(({ disabled }) => (
	{
		width: "80px",
		height: "80px",
		border: "2px solid #FFFFFF",
		borderRadius: "50%",
		background: "linear-gradient(to top, rgba(239, 239, 239, .6) 5%, rgba(239, 239, 239, .1))",
		boxShadow: "3px 3px 10px rgba(28, 130, 170, .66)",
		backdropFilter: "blur(15px)",
		opacity: (disabled === true ? ".5" : "1")
	}
)) as typeof Button;

interface IArrowButtonProps
{
	blip?: boolean;
	offBlipOnClick?: boolean;
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	sx?: SxProps<Theme>;
	[ key: string ]: any;
}

function InstructionButton({ blip = true, offBlipOnClick = false, onClick, className, ...props }: IArrowButtonProps)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (isBlip === true)
	{
		classList.push("animation--blip-brightness");
	}

	return (
		<ButtonStyled
			className={classList.join()}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			{...props}
		>
			<Box sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				transform: "translate(2px, 4px)"
			}}>
				<SVGInstruction />
			</Box>
		</ButtonStyled>
	);
}

export default InstructionButton;
