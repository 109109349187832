import { Box } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import CelebrationScreen from "components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

function ReflectParallelUniversesFinish()
{
	const missionContext = useMission();

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	// Render
	return (
		<Box className="main__content" sx={{ alignItems: "center" }}>
			<CelebrationScreen
				content={<ContentAdmin keyContent="contentMissionThree" keyText="screen_13a_finish" defaultValue="{NAME}, I hope you feel lighter already. Let’s create more clarity!" position="left" isDescription />}
				buttonText="Let’s do it!"
				onClickButton={() => onClickNextStep()}
				sx={{
					"& .CelebrationScreen-content":
					{
						color: "primary.main",
						textShadow: "unset"
					}
				}}
			/>
		</Box>
	);
}

export default ReflectParallelUniversesFinish;
