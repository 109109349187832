import { Box, Typography } from "@mui/material";
import octa2 from "../../../assets/images/octa2.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

function FinishAfternoon()
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setBlurBg(false);

		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box sx={{ height: "81vh", width: "100%", maxWidth: "980px", m: "0 auto" }}>
			<Box sx={{ width: { xl: "286px", xs: "199px" }, m: "40px auto 30px" }}>
				<img width="100%" src={octa2} alt="guide-chara" />
			</Box>
			<Typography
				className="component-missions-panel-title"
				sx={{
					font: "bold 40px/51px Lora",
					color: "#215C75",
					textAlign: "center"
				}}
			>
				<ContentAdmin limit={100} keyContent="contentMissionFive" keyText="well_done" defaultValue="Well done!" position="top" />
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
				<CustomButton.Contained
					sx={{
						fontSize: "24px",
						lineHeight: "33px",
						background: "#3E809D",
						borderColor: "#3E809D"
					}}
					onClick={nextStep}>Thank you, Octa!
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default FinishAfternoon;
