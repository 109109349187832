import { Box } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import CelebrationScreen from "components/missions/CelebrationScreen";
import useUser from "hooks/useUser";
import useMission from "pages/missionTwo/hooks/useMission";
import { useEffect } from "react";
import trackEvent from "utils/mixpanelUtil";

function CelebrationScreen1()
{
	const missionContext = useMission();
	const { state: userState } = useUser();

	// Effects
	useEffect(() =>
	{
		trackEvent({
			eventName: "Values Game Completed",
			userId: userState.user?.id
		});
	}, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
			width: "100%"
		}}>
			<CelebrationScreen
				content={<ContentAdmin keyContent={missionContext.keyContent} keyText="CelebrationScreen1_text" defaultValue="Excellent job, {NAME}!" position="left" isDescription />}
				buttonText="Continue!"
				onClickButton={() => missionContext.nextStep()}
				sx={{ "& .CelebrationScreen-content": { color: "primary.main", textShadow: "unset" } }}
			/>
		</Box>
	);
}

export default CelebrationScreen1;
