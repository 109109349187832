import { alpha, Box, keyframes } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import CustomInput from "components/CustomInput";
import PanelTitle from "components/missions/PanelTitle";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, forwardRef, KeyboardEvent, Ref, useLayoutEffect, useRef, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import IMGGoat from "../../assets/images/goat.png";
import useMission from "../../hooks/useMission";
import GoatTooltip from "./components/GoatTooltip";

export type ILetsUncoverYourBeliefsProps = Record<string, any>;

const animation = keyframes`
	0%, 100%
	{
		transform: rotateX(0deg);
	}
	50%
	{
		transform: rotateX(30deg) scaleY(1.1);
	}
`;

const BLITZ_QUESTIONS = [
	"Failure means…",
	"Success means…",
	"Experiencing challenges means…",
	"Other people are…",
	"Money is…",
	"Work is…",
	"A career should be…",
	"The future is…",
	"Being an adult means…",
	"Being a woman means…",
	"Being a man means…",
	"Love is…",
	"Being single means…",
	"Being in a relationship means…",
	"Being happy means…"
];

const TIMER_SECONDS: number = 30;
const INPUT_MAX_LEN: number = 50;

function LetsUncoverYourBeliefs({ ...props }: ILetsUncoverYourBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ showGoatTooltip, setShowGoatTooltip ] = useState<1 | 2 | 3 | null>(null);
	const [ viewGoatTooltip, setViewGoatTooltip ] = useState<Record<1 | 2 | 3, boolean>>({ 1: false, 2: false, 3: false });
	const [ isActiveBlitz, setIsActiveBlitz ] = useState<boolean>(false);
	const [ answers, setAnswers ] = useState<Record<string, string>>(() =>
	{
		const store = getLocalStorage<Record<string, string>>("mission-seven-blitz-answers");

		return store ?? {};
	});
	const [ blitzQuestionIndex, setBlitzQuestionIndex ] = useState<number>(() =>
	{
		return Object.keys(answers).length;
	});
	const [ timeLeft, setTimeLeft ] = useState<number>(0);
	const [ answerInput, setAnswerInput ] = useState<string>("");
	const timerIdRef = useRef<NodeJS.Timer>();

	// Effects
	useLayoutEffect(() =>
	{
		if (isActiveBlitz === false)
		{
			return;
		}

		timerIdRef.current = setInterval(() =>
		{
			setTimeLeft((prevTimeLeft) =>
			{
				if (prevTimeLeft < TIMER_SECONDS)
				{
					return prevTimeLeft + 1;
				}

				setAnswerInput("");
				setBlitzQuestionIndex((prevIndex) =>
				{
					if (prevIndex + 1 === BLITZ_QUESTIONS.length)
					{
						onBlitzEnd();

						return prevIndex + 1;
					}

					setAnswers((prevState) =>
					{
						return Object.assign(
							{},
							prevState,
							{ [ BLITZ_QUESTIONS[ prevIndex ] ]: "__TIMEOUT__" }
						);
					});

					return prevIndex + 1;
				});

				return 0;
			});
		}, 1000);

		return () =>
		{
			clearInterval(timerIdRef.current);
		};
	}, [ isActiveBlitz ]); // eslint-disable-line

	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-blitz-answers", answers);
	}, [ answers ]);

	// Handles
	function onChangeInput({ target }: ChangeEvent<HTMLInputElement>)
	{
		setAnswerInput(target.value.slice(0, INPUT_MAX_LEN));
	}

	function onInputKeyDown(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (answerInput.trim().length < 2)
		{
			return;
		}

		setAnswers((prevState) =>
		{
			return Object.assign(
				{},
				prevState,
				{ [ BLITZ_QUESTIONS[ blitzQuestionIndex ] ]: answerInput }
			);
		});
		setAnswerInput("");

		setTimeLeft(0);
		setBlitzQuestionIndex((prevIndex) =>
		{
			if (prevIndex + 1 === BLITZ_QUESTIONS.length)
			{
				onBlitzEnd();

				return prevIndex + 1;
			}

			return prevIndex + 1;
		});
	}

	function onBlitzEnd()
	{
		clearInterval(timerIdRef.current);

		missionContext.setStep(6);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverYourBeliefs_title" defaultValue="Let’s uncover your beliefs" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverYourBeliefs_subTitle" defaultValue="Hey, {NAME}, I want you to quickly answer a few questions, press start when you are ready. Philosophical goats are here for you with examples!" position="left" isDescription />}
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				{/* Start blitz */}
				{isActiveBlitz === false &&
					(
						<Box sx={{
							marginTop: "100px",
							display: "flex",
							flexDirection: "column",
							gap: { xl: "24px", lg: "16px", xs: "16px" },
							alignItems: "center"
						}}>
							<Box sx={{ color: "primary.main", font: "700 24px/31px Lora-Bold" }}>
								Are you ready?
							</Box>

							<CustomButton.Contained
								onClick={() => setIsActiveBlitz(true)}
							>
								Start beliefs blitz
							</CustomButton.Contained>
						</Box>
					)
				}

				{/* Blitz */}
				{isActiveBlitz === true &&
					(
						<Box sx={{
							width: { xl: "668px", lg: "579px", xs: "579px" },
							marginTop: "100px",
							padding: "24px",
							border: "1px solid " + alpha("#FFFFFF", 0.5),
							borderRadius: "24px",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							backdropFilter: "blur(30px)",
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)"
						}}>
							<Box sx={{
								color: "primary.main",
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" }
							}}>
								{BLITZ_QUESTIONS[ blitzQuestionIndex ]}
							</Box>

							<Box sx={{
								margin: { xl: "24px 0 40px 0", lg: "16px 0 32px 0", xs: "16px 0 32px 0" },
								position: "relative"
							}}>
								<CustomInput.Base
									inputProps={{ maxLength: INPUT_MAX_LEN }}
									sx={{ width: "100%" }}
									placeholder="Your answer"
									value={answerInput}
									onChange={onChangeInput}
									onKeyDown={onInputKeyDown}
									autoFocus
								/>

								<Box sx={{
									position: "absolute",
									color: "primary.dark",
									font: "400 14px/19px 'Open Sans'",
									opacity: 0.5,
									bottom: "-19px",
									right: "16px"
								}}>
									{answerInput.length}/{INPUT_MAX_LEN} characters
								</Box>
							</Box>

							{/* Progress bar */}
							<TooltipStyled title={`${ TIMER_SECONDS - timeLeft } seconds left`} placement="top" arrow>
								<Box sx={{
									width: "100%",
									border: "2px solid #FFFFFF",
									height: "24px",
									borderRadius: "24px"
								}}>
									<Box sx={{
										position: "relative",
										top: "5px",
										left: "5px",
										maxWidth: "98.3%",
										width: (timeLeft / TIMER_SECONDS) * 100 + "%",
										borderRadius: "24px",
										height: "10px",
										background: "linear-gradient(180deg, rgba(255, 255, 255, 0.871) 0%, rgba(255, 255, 255, 0.161) 100%)",
										transition: (timeLeft === 0 ? "unset" : "linear 1000ms width")
									}} />
								</Box>
							</TooltipStyled>
						</Box>
					)
				}

				{/* Goats */}
				<Box sx={{
					position: "fixed",
					bottom: "0px",
					width: { xl: "674px", lg: "538px", xs: "538px" },
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end"
				}}>
					<Box
						sx={{
							position: "relative",
							height: { xl: "126px", lg: "92px", xs: "92px" },
							animation: (viewGoatTooltip[ 1 ] ? "unset" : animation + " 1000ms infinite"),
							transformOrigin: "bottom"
						}}
						onMouseEnter={() =>
						{
							setShowGoatTooltip(1);
							setViewGoatTooltip((prevState) =>
							{
								return { ...prevState, 1: true };
							});
						}}
						onMouseLeave={() => setShowGoatTooltip(null)}
					>
						<GoatTooltip isShow={showGoatTooltip === 1} title={<><strong>The future is...</strong>full of possibilities</>} />
						<Box component="img" src={IMGGoat} alt="goat" sx={{ height: "100%" }} />
					</Box>

					<Box
						sx={{
							position: "relative",
							height: { xl: "166px", lg: "124px", xs: "124px" },
							animation: (viewGoatTooltip[ 2 ] ? "unset" : animation + " 1000ms infinite"),
							transformOrigin: "bottom"
						}}
						onMouseEnter={() =>
						{
							setShowGoatTooltip(2);
							setViewGoatTooltip((prevState) =>
							{
								return { ...prevState, 2: true };
							});
						}}
						onMouseLeave={() => setShowGoatTooltip(null)}
					>
						<GoatTooltip isShow={showGoatTooltip === 2} title={<><strong>Being an adult means...</strong>paying bills</>} />
						<Box component="img" src={IMGGoat} alt="goat" sx={{ height: "100%" }} />
					</Box>

					<Box
						sx={{
							position: "relative",
							height: { xl: "126px", lg: "92px", xs: "92px" },
							animation: (viewGoatTooltip[ 3 ] ? "unset" : animation + " 1000ms infinite"),
							transformOrigin: "bottom"
						}}
						onMouseEnter={() =>
						{
							setShowGoatTooltip(3);
							setViewGoatTooltip((prevState) =>
							{
								return { ...prevState, 3: true };
							});
						}}
						onMouseLeave={() => setShowGoatTooltip(null)}
					>
						<GoatTooltip isShow={showGoatTooltip === 3} title={<><strong>Love is...</strong>blind</>} />
						<Box component="img" src={IMGGoat} alt="goat" sx={{ height: "100%" }} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default forwardRef(LetsUncoverYourBeliefs);
