import * as yup from "yup";

const config =
{
	name:
	{
		minLength: 1,
		maxLength: 20,
		regExp: /^[A-Za-zА-Яа-яЁё]+$/,

		testRegExp(value: string)
		{
			return this.regExp.test(value);
		},

		yup()
		{
			return yup
				.string()
				.required("The field must not be empty")
				// eslint-disable-next-line no-template-curly-in-string
				.min(this.minLength, "Must contain at least ${min} characters")
				// eslint-disable-next-line no-template-curly-in-string
				.max(this.maxLength, "Must contain no more than ${max} characters")
				.test(
					"name-test",
					"The name must consist of A-z, А-я",
					(value) => this.testRegExp(value || "")
				);
		}
	},
	password:
	{
		minLength: 8,
		maxLength: 25,
		regExp: /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,

		testRegExp(value: string)
		{
			return this.regExp.test(value);
		},

		yup()
		{
			return yup
				.string()
				.required("The field must not be empty")
				// eslint-disable-next-line no-template-curly-in-string
				.min(this.minLength, "Must contain at least ${min} characters")
				// eslint-disable-next-line no-template-curly-in-string
				.max(this.maxLength, "Must contain no more than ${max} characters")
				.test(
					"password-test",
					"The password must consist of A-z, -, _, /",
					(value) => this.testRegExp(value || "")
				);
		}
	},
	email:
	{
		regExp: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,

		testRegExp(value: string)
		{
			return this.regExp.test(value);
		},

		yup()
		{
			return yup
				.string()
				.required("The field must not be empty")
				.test(
					"email-test",
					"This email is not valid",
					(value) => this.testRegExp(value || "")
				);
		}
	}
};

export default config;
