import { Box, SxProps, Theme } from "@mui/material";
import { ForwardRefExoticComponent, HTMLAttributes, Ref, forwardRef } from "react";

export interface IImageButtonProps extends HTMLAttributes<HTMLButtonElement>
{
	image: string;
	/**
	 * @default false
	 */
	isSelected?: boolean;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function ImageButton({ image, isSelected = false, sx = [], ...props }: IImageButtonProps, ref: Ref<HTMLButtonElement>)
{
	return (
		<Box
			className="ImageButton-root"
			component="button"
			ref={ref}
			sx={[
				{
					"--size": { xl: "80px", lg: "64px", xs: "64px" },
					position: "relative",
					backgroundColor: "unset",
					border: "4px solid #FFFFFF",
					borderRadius: "50%",
					padding: "unset",
					width: "var(--size)",
					height: "var(--size)",
					boxShadow: "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)"
				},
				(isSelected === false) &&
				{
					cursor: "pointer"
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Box
				className="ImageButton-image"
				component="img"
				src={image}
				alt=""
				sx={{
					position: "relative",
					width: "100%",
					height: "100%",
					zIndex: -1,
					filter: (isSelected === true ? "blur(5px)" : "unset")
				}}
			/>
		</Box>
	);
}

export default forwardRef(ImageButton) as ForwardRefExoticComponent<IImageButtonProps>;
