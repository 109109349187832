import { Box } from "@mui/material";
import { ReactComponent as SVGBat2 } from "../../../../../assets/images/missions/mission-eight/bat2.svg";

interface BatBtnsProps
{
	selectedTitle: number;
	handleSelectedTitle(val: number): void;
}

function BatBtns({ selectedTitle, handleSelectedTitle }: BatBtnsProps)
{
	return (
		<Box sx={{ display: "flex", justifyContent: "center", gap: "100px", width: "100%", m: { lg: "20px 0 0 40px", xl: "40px 0 0 40px" }, border: "1px solid transparent" }}>

			<Box
				onClick={() => handleSelectedTitle(0)}
				sx={{
					transform: "rotate(-56deg)",
					opacity: 0.7,
					cursor: "pointer",
					width: "160px",
					position: "relative",
					top: "-23px",
					left: "-38px"
				}}>
				{selectedTitle !== 0 && <SVGBat2 />}
			</Box>

			<Box
				onClick={() => handleSelectedTitle(1)}
				sx={{
					transform: "rotate(-20deg)",
					opacity: 0.7,
					ml: 2,
					cursor: "pointer",
					width: "160px",
					position: "relative",
					top: "-13px",
					left: "-37px"
				}}>
				{selectedTitle !== 1 && <SVGBat2 />}
			</Box>

			<Box
				onClick={() => handleSelectedTitle(2)}
				sx={{
					opacity: 0.7,
					cursor: "pointer",
					width: "160px",
					position: "relative",
					top: "-25px",
					left: "-20px"
				}}>
				{selectedTitle !== 2 && <SVGBat2 />}
			</Box>
		</Box>
	);
}

export default BatBtns;
