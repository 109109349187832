function isRegExp(re)
{
	return re instanceof RegExp;
}

function isString(value)
{
	return typeof value === "string";
}

function escapeRegExp(string)
{
	const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
	const reHasRegExpChar = RegExp(reRegExpChar.source);

	return (string && reHasRegExpChar.test(string))
		? string.replace(reRegExpChar, "\\$&")
		: string;
}

function flatten(array)
{
	let newArray = [];

	array.forEach((item) =>
	{
		if (Array.isArray(item))
		{
			newArray = newArray.concat(item);
		}
		else
		{
			newArray.push(item);
		}
	});

	return newArray;
}

function replaceString(str, match, fn)
{
	let curCharStart = 0;
	let curCharLen = 0;

	if (str === "")
	{
		return str;
	}
	else if (!str || !isString(str))
	{
		throw new TypeError("First argument to react-string-replace#replaceString must be a string");
	}

	let re = match;

	if (!isRegExp(re))
	{
		re = new RegExp("(" + escapeRegExp(re) + ")", "gi");
	}

	const result = str.split(re);

	for (let i = 1, length = result.length; i < length; i += 2)
	{
		if (result[ i ] === undefined || result[ i - 1 ] === undefined)
		{
			console.warn("reactStringReplace: Encountered undefined value during string replacement. Your RegExp may not be working the way you expect.");
			continue;
		}

		curCharLen = result[ i ].length;
		curCharStart += result[ i - 1 ].length;
		result[ i ] = fn(result[ i ], i, curCharStart);
		curCharStart += curCharLen;
	}

	return result;
}

function reactStringReplace(source, match, fn)
{
	if (!Array.isArray(source))
	{
		source = [ source ];
	}

	return flatten(source.map((x) =>
	{
		if (isString(x))
		{
			return replaceString(x, match, fn);
		}

		const children = x.props.children;

		if (isString(children))
		{
			x = { ...x, props: { ...x.props, children: reactStringReplace(children) } };
		}

		if (Array.isArray(children))
		{
			x = { ...x, props: { ...x.props, children: children.map(child => reactStringReplace(child, match, fn)) } };
		}

		return x;
	}));
}

export default reactStringReplace;
