import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ReactComponent as SVGEagle } from "../../../../assets/images/missions/mission-ten/eagle.svg";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks";
import EagleBtns from "./feature/EagleBtns";

interface EagleValues
{
	id: number;
	changed: boolean;
}
const BoxStyled = styled("div")(({ theme }) => (
	{
		"& li::marker": {
			fontSize: "15px",
			lineHeight: "15px"
		}
	}
));
const itemsHelp = [
	{
		id: 1,
		title: <ContentAdmin
			limit={200}
			keyContent="contentMissionTen"
			keyText="life_experiments_one_eagle_title"
			defaultValue="Go all-in"
			position="top"
			isDescription
		/>,
		description: <ContentAdmin
			limit={200}
			keyContent="contentMissionTen"
			keyText="life_experiments_one_eagle_text"
			defaultValue="Quit your job, start a business, move to a new country, sell all your stuff and become a minimalist. If you are an ‘all or nothing’ kind of person, this might be the best approach for you. It involves more risk, but allows you to test your ideas quicker."
			position="left"
			isDescription
		/>
	},
	{
		id: 2,
		title: <ContentAdmin
			limit={200}
			keyContent="contentMissionTen"
			keyText="life_experiments_two_eagle_title"
			defaultValue="Date the idea"
			position="top"
			isDescription
		/>,
		description: <ContentAdmin
			limit={400}
			keyContent="contentMissionTen"
			keyText="life_experiments_two_eagle_text"
			defaultValue="Try out a destination before committing. Shadow someone on a job, take a sabbatical to join a startup accelerator, spend a few months in another country to see if you like it, pack up your stuff in a storage unit and live with only a few items for some time."
			position="left"
			isDescription
		/>
	},
	{
		id: 3,
		title: <ContentAdmin
			limit={200}
			keyContent="contentMissionTen"
			keyText="life_experiments_three_eagle_title"
			defaultValue="Conversational research"
			position="top"
			isDescription
		/>,
		description: <ContentAdmin
			limit={200}
			keyContent="contentMissionTen"
			keyText="life_experiments_three_eagle_text"
			defaultValue="Talk to people from different walks of life who did the thing you are interested in doing. e.g. talk to an artist, watch YouTube videos from the minimalists, join an entrepreneur meetup. This is the least risky approach and requires minimum investment from you, so you can do as many of such experiments as you want."
			position="left"
			isDescription
		/>
	}
];

function LifeExperimentsEagle()
{
	const missionContext = useMission();
	const [ showHelpText, setShowHelpText ] = useState(false);
	const [ selectedTitle, setSelectedTitle ] = useState<number>(3);
	const [ eagleValues, setEagleValues ] = useState<EagleValues[]>([
		{ id: 0, changed: false },
		{ id: 1, changed: false },
		{ id: 2, changed: false }
	]);
	const [ disabledBtn, setDisabledBtn ] = useState(true);

	function closeShowHelpText()
	{
		setSelectedTitle(3);
		setShowHelpText(false);
	}

	function handleSelectedTitle(index: number)
	{
		setSelectedTitle(index);
		setShowHelpText(true);
		setEagleValues(eagleValues.map((item: EagleValues) =>
		{
			if ((item.id) === index)
			{
				return { ...item, changed: true };
			}

			return item;

		}));
	}

	function onClickNextStep()
	{

		missionContext.setStep((prevState) => prevState + 1);
	}

	useEffect(() =>
	{
		let count = 0;

		eagleValues.forEach((item: any) =>
		{
			if (item.changed) count += 1;
		});
		if (count === 3) { setDisabledBtn(false); }
	}, [ eagleValues ]);

	return (
		<>
			<Box sx={{
				flexDirection: "column",
				display: "flex",
				justifyContent: "space-between",
				height: "81vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "980px" },
				margin: "0 auto",
				position: "relative",
				top: "75px",
				left: "10px"
			}}>
				<Box sx={{ maxWidth: "100%", mt: "-20px" }}>
					<Box sx={{ position: "relative", top: "-36px", left: "-36px" }}>
						<PanelTitle
							positionOcta
							imageVariation="closeEye"
							title={<ContentAdmin
								limit={111}
								keyContent="contentMissionTen"
								keyText="life_experiments_title"
								defaultValue="Let’s design your life experiments!"
								position="right"
							/>
							}
							subTitle={<ContentAdmin
								limit={300}
								keyContent="contentMissionTen"
								keyText="life_experiments_sub_title"
								defaultValue="Alright, {NAME}, here are all of your unknowns, time to brainstorm experiments you can run on each one. Go through your unknows and think of ways you can create more certaint"
								position="right"
								isDescription
							/>
							}
						/>
					</Box>
					{missionContext.showContent
						? (
							<>
								{showHelpText ?
									<Box sx={{
										border: "1px solid rgba(255, 255, 255, 0.5)",
										p: { lg: "23px", xl: "32px" },
										borderRadius: "24px",
										boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
										background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
										backdropFilter: "blur(30px)",
										position: "relative",
										width: { lg: "483px", xl: "715px" },
										m: "46px auto 40px",
										wordWrap: "break-word",
										// border: '1px solid violet',
										top: "50px"
									}}>
										<Box sx={{ position: "relative" }}>
											<Box
												sx={{ position: "absolute", top: "-17px", right: "-17px" }}
												onClick={closeShowHelpText}
											>
												<CloseIcon
													sx={{
														fill: "#fff",
														fontSize: "14px",
														width: "24px",
														backgroundColor: "#3E809D",
														borderRadius: "40px",
														height: "24px",
														p: "6px",
														cursor: "pointer"
													}}
												/>
											</Box>
											<Typography sx={{ m: "-3px 0 13px 0", color: "#215C75", font: { lg: "700 24px/51px 'Lora'", xl: "700 40px/51px 'Lora'" } }}>
												{itemsHelp[ selectedTitle ].title}
											</Typography>

											<Typography sx={{
												color: "#4D4D4D", font: { lg: "400 16px/22px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
											}}>
												<BoxStyled>{itemsHelp[ selectedTitle ].description}</BoxStyled>
											</Typography>

										</Box>
										{(selectedTitle === 0 || selectedTitle === 2 || selectedTitle === 1) &&
											<Box sx={{
												position: "absolute",
												top: "-163px",
												left: "-92px",
												transform: "rotate(-12deg)"
											}}>
												<Box
													sx={{ transform: { lg: "scale(0.8)", xl: "scale(1)" } }}
												>
													<SVGEagle />
												</Box>
											</Box>
										}
									</Box>
									:
									<EagleBtns
										selectedTitle={selectedTitle}
										handleSelectedTitle={handleSelectedTitle}
									/>
								}
							</>
						)
						: null
					}
				</Box>
			</Box>

			<StepperNavigationWrapper sx={{ bottom: 0, right: "25px" }} absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					disabled={disabledBtn}
					onClick={onClickNextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default LifeExperimentsEagle;
