import { Box, Snackbar, alpha } from "@mui/material";
import AlertStyled from "components/AlertStyled";
import AccessToMission from "components/Dialog/AccessToMission";
import { TransitionSlideLeft } from "components/Transition";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getLocalStorage } from "utils/localStorage";
import DialogStripe from "./DialogStripe";
import MissionsAside from "./aside";
import Card from "./card";
import { IMission } from "./mission.interface";
import { getMissionData } from "./missionsData";

const DEFAULT_UNLOCK_MISSION_ID = 1;

function Missions()
{
	// const isFetchingRef = useRef<boolean>(false);

	const user = useUser().state.user;

	const completedMissionLocal = getLocalStorage<number>("completedMission");
	const completedMissionGlobal = user?.completedMission;
	const completedMission = completedMissionGlobal ?? completedMissionLocal ?? DEFAULT_UNLOCK_MISSION_ID;

	const missionsLoad = useMemo(() => getMissionData(completedMission), [ completedMission ]);

	const [ showBuyDialog, setShowBuyDialog ] = useState<number | null>(null);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ openAccessDialog, setOpenAccessDialog ] = useState<boolean>(false);
	const [ missions, setMissions ] = useState<IMission[]>(() => missionsLoad);
	// const [ missionsBuy, setMissionsBuy ] = useState<IResponseGetMissionBuy[ "missions" ]>([]);
	const [ missionIdSelect, setMissionIdSelect ] = useState<number>(() =>
	{
		return missions.find((mission) => mission.isLock === false && mission.complete < 100)?.id ?? DEFAULT_UNLOCK_MISSION_ID;
	});

	// const isAdmin = user?.userRole === "admin" || false;
	const mission = useMemo(() =>
	{
		return getMissionById(missionIdSelect);
	}, [ missionIdSelect ]); // eslint-disable-line react-hooks/exhaustive-deps

	// Utils
	function getMissionById(id: number)
	{
		return missions.find((mission) => mission.id === id);
	}

	// Effects
	// useLayoutEffect(() =>
	// {
	// 	const missions = [ ...missionsLoad ];

	// 	missionsBuy.forEach(({ id, payment }) =>
	// 	{
	// 		const missionIndex = missions.findIndex((mission) => mission.id === id);

	// 		if (missionIndex !== -1)
	// 		{
	// 			missions[ missionIndex ].payment = payment;
	// 		}
	// 	});

	// 	setMissions(missions);
	// }, [ missionsLoad, missionsBuy ]);

	// useLayoutEffect(() =>
	// {
	// 	if (isFetchingRef.current === true)
	// 	{
	// 		return;
	// 	}

	// 	isFetchingRef.current = true;

	// 	Api.users.getMissionsBuy()
	// 		.then(({ missions }) =>
	// 		{
	// 			setMissionsBuy(missions);
	// 		})
	// 		.catch((error: any) =>
	// 		{
	// 			console.error(error);
	// 		})
	// 		.finally(() => (isFetchingRef.current = false));
	// }, []);

	// Handles
	const handleActiveMission = useCallback((id: number) =>
	{
		setMissions((prevState) =>
		{
			const missions = [ ...prevState ];

			missions.forEach((mission) =>
			{
				if (mission.id === id)
				{
					mission.payment = true;
				}
			});

			return missions;
		});
	}, []);

	const handleClick = useCallback(() => setOpen(true), []);

	function handleClose(_event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	// Render
	return (
		<Box sx={{
			display: "flex",
			alignItems: "flex-start",
			gap: { xl: "40px", lg: "24px", xs: "24px" },
			maxWidth: "1792px"
		}}>
			{mission ? <MissionsAside mission={mission} setShowBuyDialog={setShowBuyDialog} /> : null}

			<Box
				data-tut="reactour__myJourney"
				sx={{
					display: "grid",
					gridTemplateRows: "auto 1fr",
					gap: { xl: "32px", lg: "51px", xs: "51px" },

					height: { xl: "860px", lg: "676px", xs: "676px" },
					padding: { xl: "32px 40px 40px 40px", lg: "24px", xs: "24px" },

					backgroundColor: alpha("#FFFFFF", 0.5),
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				}}
			>
				<Box sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-between",
					alignItems: "flex-end",
					gap: "16px",
					font: { xl: "700 40px/51px Lora-Bold", lg: "700 32px/41px Lora-Bold", xs: "700 32px/41px Lora-Bold" },
					color: "#215C75"
				}}>
					<ContentAdmin defaultValue="My Journey" limit={10} keyContent="contentDashboard" keyText="titleMissions" />

					{user?.fullSubscribe === false && user?.subscriptionIsActive === false &&
						(
							<CustomButton.Contained
								component={Link}
								to="/packages"
								sx={{
									width: { xl: "353px", lg: "248px", xs: "248px" },
									minHeight: "unset",
									height: { xl: "56px", lg: "40px", xs: "40px" },
									font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" },
									padding: { xl: "10px 45px", lg: "5.5px 45px", xs: "7.5px 45px" }
								}}
							>
								Buy Full Version
							</CustomButton.Contained>
						)
					}
				</Box>

				<Box
					className="customScroll"
					sx={{
						overflowY: "auto",
						display: "flex",
						flexWrap: "wrap",
						alignContent: "flex-start",
						justifyContent: "center",
						gap: { xl: "32px", lg: "52px 20px", xs: "52px 20px" },
						marginRight: "-16px",
						paddingRight: "16px",
						marginBottom: "-7px",
						paddingBottom: "7px"
					}}
				>
					{missions.map((mission) =>
					{
						return (
							<Card
								key={mission.id}
								mission={mission}
								setMissionIdSelect={setMissionIdSelect}
								setShowBuyDialog={setOpenAccessDialog}
							/>
						);
					})}
				</Box>
			</Box>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity="success">
					Payment successful
				</AlertStyled>
			</Snackbar>

			{showBuyDialog !== null &&
				(<DialogStripe
					open={showBuyDialog !== null}
					onClose={() => setShowBuyDialog(null)}
					handleClick={handleClick}
					amount={10000}
					description={showBuyDialog}
					mission={showBuyDialog}
					handleActiveMission={handleActiveMission}
				/>)
			}
			<AccessToMission open={openAccessDialog} onClose={() => setOpenAccessDialog(false)} />
		</Box>
	);
}

export default Missions;
