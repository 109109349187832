import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const ChatDoAnywayMissionEight = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionEight"
					keyText="chat_do_anyway_screen_1_message"
					defaultValue="See, {NAME}, you are not alone in this. Everyone is afraid of something!"
					isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionEight"
				keyText="chat_do_anyway_screen_1_answer"
				defaultValue="It seems like it"
				position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="chat_do_anyway_screen_2_message"
						defaultValue="But you are in control and in power to face your fears, so that they don’t dictate what you can and can’t be doing with your life!"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionEight"
							keyText="chat_do_anyway_screen_2_answer"
							defaultValue="Yeah!"
							position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionEight"
						keyText="yeah_answer"
						defaultValue="Ready to face your fears?"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin
						limit={64}
						keyContent="contentMissionEight"
						keyText="chat_do_anyway_screen_3_answer"
						defaultValue="Let’s do it!"
						position="top" /> ]
				});
				break;
			}

			case 4: {
				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);
	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main" sx={{ position: "relative", top: "-20px" }}>
			<Box className="main__content">
				<Chat
					titleColor="#E9EDF0"
					titleMarginTop="10px"
					titleMarginLeft="5px"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEight"
						keyText="do_it_anyway"
						defaultValue="Feel the fear and do it anyway"
					/>}
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatDoAnywayMissionEight;
