import { Box } from "@mui/material";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { LOCAL_STORAGE_PREFIX } from "..";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import IMGBackground from "./../assets/images/background.png";
import LearnMoreAboutJob from "./LearnMoreAboutJob";
import PersonalJobAd from "./PersonalJobAd";
import TopJobsForYouList from "./TopJobsForYouList";
import TopJobsForYouSelect from "./TopJobsForYouSelect";

export interface ICreateProps
{
	updateItems(callback?: () => void, force?: boolean): void;
}

function Create({ updateItems }: ICreateProps)
{
	const navigate = useNavigate();

	const [ step, setStep ] = useState<number>(() =>
	{
		return getLocalStorage<number>(LOCAL_STORAGE_PREFIX + "-create-step") ?? 1;
	});

	// Utils
	function renderStep(step: number)
	{
		switch (step)
		{
			case 1: return <PersonalJobAd nextStep={nextStep} />;
			case 2: return <TopJobsForYouList nextStep={nextStep} setStep={setStep} />;
			case 3: return <TopJobsForYouSelect nextStep={nextStep} setStep={setStep} />;
			case 4: return <LearnMoreAboutJob setStep={setStep} updateItems={updateItems} />;
		}
	}

	function nextStep()
	{
		setStep((prevState) => prevState + 1);
	}

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(LOCAL_STORAGE_PREFIX + "-create-step", step);
	}, [ step ]);

	// Render
	return (
		<ArtifactsContainer
			title="New job ad"
			backgroundImage={IMGBackground}
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			onClose={() => navigate("/dashboard/artifacts/career")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			{renderStep(step)}
		</ArtifactsContainer>
	);
}

export default Create;
