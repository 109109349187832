import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Snackbar, TextField, Typography } from "@mui/material";
import Api from "api";
import bgArtifactNine from "assets/images/bgArtifactNine.png";
import checkedIcon from "assets/images/checkedIcon.png";
import editIcon from "assets/images/editIcon.svg";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import checkboxNoActive from "assets/images/missions/mission-ten/checkboxNoActive.svg";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { ChangeEvent, FormEvent, KeyboardEvent, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import Dialog from "../../components/Dialog";
import { RULES } from "../../experiments";

function Create()
{
	const navigate = useNavigate();
	const [ dataMission, setDataMission ] = useState<any>([]);
	const [ error, setError ] = useState<string>("");
	const [ open, setOpen ] = useState(false);
	const [ titleModal, setTitleModal ] = useState("Name new plan");
	const [ isShowCreateModal, setIsShowCreateModal ] = useState<boolean>(false);
	const [ newLifePlanTitleError, setNewLifePlanTitleError ] = useState<string | null>(null);
	const [ newLifePlanTitle, setNewLifePlanTitle ] = useState<string>("");
	const [ titleArtifact, setTitleArtifact ] = useState<string>("");
	const [ editmode, setEditmode ] = useState("plan");
	const [ activeItem, setActiveItem ] = useState<string>("");
	const [ isLoading, setIsLoading ] = useState(false);

	function handleClose(event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	useLayoutEffect(() =>
	{
		if (localStorage.getItem("artifact-nine-title-create") !== null)
			setTitleArtifact(JSON.parse(`${ localStorage.getItem("artifact-nine-title-create") }`));
		if (localStorage.getItem("artifact-nine-create") !== null)
		{
			setDataMission(JSON.parse(`${ localStorage.getItem("artifact-nine-create") }`));
		}
		else setIsShowCreateModal(true);
	}, []);
	function setInputTask(value: string, id: number)
	{
		const newDataMission = [ ...dataMission ];

		newDataMission.forEach((element: any) =>
		{
			if (element.id === id) element.input = value;
		});
		setDataMission(newDataMission);
	}

	function setInputStep(value: string, id: number, idTask: number)
	{
		const newDataMission = [ ...dataMission ];

		newDataMission.forEach((element: any) =>
		{
			if (element.id === id)
			{
				element.items.forEach((itemTask: any) =>
				{
					if (itemTask.id === idTask) itemTask.input = value;
				});
			}
		});
		setDataMission(newDataMission);
	}

	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>, id: number)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		const newDataMission = [ ...dataMission ];

		newDataMission.forEach((element: any) =>
		{
			if (element.id === id)
			{
				if (element.input.trim().length < 2)
				{
					setError("Enter at least 2 characters");
					setOpen(true);

					return;
				}
				else if (element.input.trim().length > 200)
				{
					setError("The text should not exceed 200 characters");
					setOpen(true);

					return;
				}

				element.items.push({
					active: false,
					id: Math.random(),
					values: [],
					checked: false,
					input: "",
					title: element.input,
					deleted: false
				});
				element.input = "";

			}
		});
		savelocalArtifactNine(newDataMission);
		setDataMission(newDataMission);
	}

	function handleAddValues(event: KeyboardEvent<HTMLInputElement>, id: number, idTask: number)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		const newDataMission = [ ...dataMission ];

		newDataMission.forEach((element: any) =>
		{
			if (element.id === id)
			{
				element.items.forEach((itemTask: any) =>
				{
					if (itemTask.id === idTask)
					{
						if (itemTask.input.trim().length < 2)
						{
							setError("Enter at least 2 characters");
							setOpen(true);

							return;
						}
						else if (itemTask.input.trim().length > 200)
						{
							setError("The text should not exceed 200 characters");
							setOpen(true);

							return;
						}

						itemTask.values.push({
							checked: false,
							title: itemTask.input,
							deleted: false
						});
						itemTask.input = "";

					}
				});
			}
		});
		savelocalArtifactNine(newDataMission);
		setDataMission(newDataMission);
	}

	function deleteStep(id: number, idTask: number, index: number)
	{
		const newBoards = [ ...dataMission ];
		const updatedBoards = newBoards.map((element: any) =>
		{
			if (element.id === id)
			{
				const newItems = element.items.map((item: any) =>
				{
					if (item.id === idTask)
					{
						return { ...item, values: item.values.filter((itemBoard: any, indexItemBoard: number) => indexItemBoard !== index) };
					}

					return item;

				});

				return { ...element, items: newItems };
			}

			return element;

		});

		savelocalArtifactNine(updatedBoards);
		setDataMission(updatedBoards);
	}

	function showDeleteStep(id: number, idTask: number, index: number)
	{
		const newBoards = [ ...dataMission ];

		newBoards.forEach((item: any) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: any) =>
				{
					if (itemBoard.id === idTask)
					{
						itemBoard.values.forEach((itemStep: any, indexItmStep: number) =>
						{
							if (indexItmStep === index) itemStep.deleted = true;
						});
					}
				});
		});
		setDataMission(newBoards);
	}

	function closeDeleteStep(id: number, idTask: number, index: number)
	{
		const newBoards = [ ...dataMission ];

		newBoards.forEach((item: any) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: any) =>
				{
					if (itemBoard.id === idTask)
					{
						itemBoard.values.forEach((itemStep: any, indexItemBoard: number) =>
						{
							if (indexItemBoard === index) itemStep.deleted = false;
						});
					}
				});
		});
		setDataMission(newBoards);
	}

	function addHorizon()
	{
		setEditmode("add horizon");
		setTitleModal("Name new horizon");
		setIsShowCreateModal(true);
	}

	function editHorizon(title: string)
	{
		setActiveItem(title);
		setNewLifePlanTitle(title);
		setEditmode("edit horizon");
		setTitleModal("Name new horizon");
		setIsShowCreateModal(true);
	}

	function editTitle(title: string)
	{
		setEditmode("plan");
		setTitleModal("Change plan name");
		setIsShowCreateModal(true);
		setNewLifePlanTitle(title);
	}

	function onCreate(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			RULES.title.yupScheme().validateSync(newLifePlanTitle);
			const newBoards = [ ...dataMission ];
			if (editmode === "plan")
			{
				setTitleArtifact(newLifePlanTitle);
				localStorage.setItem("artifact-nine-title-create", JSON.stringify(newLifePlanTitle));
			}
			else if (editmode === "add horizon")
			{
				newBoards.push({
					active: false,
					title: newLifePlanTitle,
					id: Math.random(),
					input: "",
					items: []
				});
			}
			else if (editmode === "edit horizon")
			{
				newBoards.forEach((item: any) =>
				{
					if (item.title === activeItem) item.title = newLifePlanTitle;
				});

			}

			savelocalArtifactNine(newBoards);
			setDataMission(newBoards);
			setNewLifePlanTitle("");
		}
		catch (e)
		{
			error = (e as yup.ValidationError).message;
		}

		setNewLifePlanTitleError(error);

		if (error !== null)
		{
			return;
		}

		setIsShowCreateModal(false);
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>, id: number, idTask: number, index: number)
	{
		const newBoardsResults = [ ...dataMission ];

		newBoardsResults.forEach((board: any) =>
		{
			if (board.id === id)
				board.items.forEach((element: any) =>
				{
					if (element.id === idTask)
						element.values.forEach((value: any, indexItems: number) =>
						{
							if (indexItems === index) value.checked = event.target.checked;
						});
				});
		});
		savelocalArtifactNine(newBoardsResults);
		setDataMission(newBoardsResults);
	}

	function savelocalArtifactNine(data: any)
	{
		localStorage.setItem("artifact-nine-create", JSON.stringify(data));
	}

	function saveArtifactNine()
	{
		setIsLoading(true);
		const data: any = [ dataMission, [], [] ];

		Api.missions
			.setDataMissionNine(data, titleArtifact, true)
			.then((data) =>
			{
				setIsLoading(false);
				localStorage.removeItem("artifact-nine-create");
				localStorage.removeItem("artifact-nine-title-create");
				navigate("/dashboard/artifacts/my-life-plan");
			})
			.catch((error) => console.error(error));
	}

	return (
		<ArtifactsContainer
			title={
				<Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
					{titleArtifact}
					<Button
						onClick={() => editTitle(titleArtifact)}
						sx={{
							width: "40px",
							height: "40px",
							borderRadius: "50%",
							backgroundColor: "#3E809D",
							boxShadow: "0px 3px 6px rgba(0, 0, 0, .16)",
							backdropFilter: "blur(15px) brightness(.9)",
							pointerEvents: "auto",
							minWidth: "40px",
							"&:hover": {
								backgroundColor: "#3E809D"
							}
						}}>
						<Box component="img" src={editIcon} alt="" sx={{ width: "19px" }} />
					</Button>
				</Box>
			}
			backgroundImage={bgArtifactNine}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main",
					p: "46px 48px 0px"
				},
				"& .ArtifactsContainer-main": {
					p: "0px",
					background: "rgba(255, 255, 255, 0.2)",
					backdropFilter: "blur(15px)",
					borderRadius: "24px"
				},
				"& .ArtifactsContainer-content": {
					border: "none"
				}
			}}
			headerRight={
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<CustomButton.Outline
						onClick={saveArtifactNine}
						disabled={isLoading}
						sx={{ mr: "60px" }}
					>
						{isLoading ? <CircularProgress /> : "Save"}
					</CustomButton.Outline>
					<TooltipStyled title="Download" placement="left" arrow>
						<Box
							component="button"
							sx={{
								display: "none",
								padding: "unset",
								background: "unset",
								border: "unset",
								cursor: "pointer",
								color: "inherit"
							}}
						>
							<SVGIconDownload />
						</Box>
					</TooltipStyled>
				</Box>
			}
			onClose={() => navigate("/dashboard/artifacts/my-life-plan")}
		>
			<Box sx={{ p: "0px 48px 46px", minWidth: "100%" }}>
				<Box
					className="customScrollWhite"
					sx={{
						display: "flex",
						gap: "35px",
						overflowX: "auto",
						minWidth: "100%",
						height: "100%",
						pb: 5

					}}>
					{dataMission.map((item: any, index: number) => (
						<Box
							key={index}
							sx={{
								height: "100%",
								minWidth: { lg: "423px", xl: "504px" },
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
								borderRadius: "24px",
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								backdropFilter: "blur(30px)",
								p: "24px 32px",
								color: "primary.dark"
							}}>
							<Box sx={{ display: "flex", alignItems: "center", gap: "15px", mb: "24px", justifyContent: "center" }}>
								<Typography sx={{ font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, color: "primary.main" }}>{item.title}</Typography>
								<Button
									onClick={() => editHorizon(item.title)}
									sx={{
										width: "24px",
										height: "24px",
										borderRadius: "50%",
										backgroundColor: "#3E809D",
										boxShadow: "0px 3px 6px rgba(0, 0, 0, .16)",
										backdropFilter: "blur(15px) brightness(.9)",
										pointerEvents: "auto",
										minWidth: "24px",
										"&:hover": {
											backgroundColor: "#3E809D"
										}
									}}>
									<Box component="img" src={editIcon} alt="" sx={{ width: "11px" }} />
								</Button>
							</Box>
							<Box className="customScroll" sx={{ overflowY: "auto", maxHeight: { lg: "340px", xl: "520px" }, pr: "35px", width: "100%" }}>
								{item.items.map((itemTask: any, indexTask: number) => (
									<Box key={indexTask} sx={{ pr: "16px" }}>
										<Typography sx={{ font: { lg: "700 16px/30px Open Sans", xl: "700 22px/30px Open Sans" }, mb: 3 }}>{itemTask.title}</Typography>
										<FormGroup>
											{itemTask.values.map((itemValues: any, indexValues: number) => (
												<Box
													sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer", gap: "15px" }}
													onMouseOver={() => showDeleteStep(item.id, itemTask.id, indexValues)}
													onMouseOut={() => closeDeleteStep(item.id, itemTask.id, indexValues)}
												>
													<FormControlLabel
														control={<Checkbox
															icon={<Box
																component="img"
																width="100%"
																src={checkboxNoActive}
																alt=""
															/>}
															onChange={(e) => handleChange(e, item.id, itemTask.id, indexValues)}
															checkedIcon={<Box
																component="img"
																width="100%"
																src={checkedIcon}
																alt=""
															/>} />}
														label={itemValues.title}
														checked={itemValues.checked}
														sx={{
															".MuiTypography-root": {
																font: { lg: "400 16px/30px Open Sans", xl: "400 22px/30px Open Sans" },
																ml: 1
															},
															mb: "5px",
															ml: "-7px",
															".MuiButtonBase-root": {
																minWidth: "42px"
															}
														}} />
													{
														itemValues.deleted
															? (
																<CloseIcon
																	onClick={() => deleteStep(item.id, itemTask.id, indexValues)}
																	sx={{ fill: "#215C75", cursor: "pointer" }}
																/>
															)
															: null
													}
												</Box>
											))}
										</FormGroup>
										<TextField
											value={itemTask.input}
											inputProps={{ maxLength: 200 }}
											onChange={(e: ChangeEvent<HTMLInputElement>) => { setInputStep(e.target.value, item.id, itemTask.id); }}
											onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleAddValues(e, item.id, itemTask.id)}
											placeholder="add step"
											helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ item.input?.length }/200 characters`}</Typography>}
											sx={{
												"& .MuiInputBase-root fieldset": {
													border: "none"
												},
												"& .MuiOutlinedInput-root": {
													font: { lg: '400 16px/30px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
													color: "#4D4D4D !important",
													backgroundColor: "#fff",
													borderRadius: "8px",
													width: { lg: "331px", xl: "381px" }
												},
												"& .MuiFormHelperText-contained": {
													textAlign: "right",
													mt: 0
												}
											}}
										/>
										<Divider sx={{ mt: 3, mb: 3 }} />
									</Box>
								))}
								<TextField
									value={item.input}
									inputProps={{ maxLength: 200 }}
									onChange={(e: ChangeEvent<HTMLInputElement>) => { setInputTask(e.target.value, item.id); }}
									onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleAddArrValues(e, item.id)}
									placeholder="add task"
									helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ item.input?.length }/200 characters`}</Typography>}
									sx={{
										"& .MuiInputBase-root fieldset": {
											border: "none"
										},
										"& .MuiOutlinedInput-root": {
											font: { lg: '400 16px/30px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
											color: "#4D4D4D !important",
											backgroundColor: "#fff",
											borderRadius: "8px",
											width: { lg: "331px", xl: "381px" }
										},
										"& .MuiFormHelperText-contained": {
											textAlign: "right",
											mt: 0
										}
									}}
								/>
							</Box>
						</Box>
					))}
					<Box
						sx={{
							height: "100%",
							minWidth: { lg: "423px", xl: "504px" },
							boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
							borderRadius: "24px",
							background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
							backdropFilter: "blur(30px)",
							p: "24px 32px",
							color: "primary.main",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center"
						}}>
						<Box sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center"
						}}>
							<Typography sx={{ font: { lg: "700 18px/30px Lora-Bold", xl: "700 24px/30px Lora-Bold" } }}>Add new horizon</Typography>
							<Button onClick={addHorizon}>
								<AddIcon sx={{ fill: "primary.main", fontSize: "70px" }} />
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity="warning">
					{error}
				</AlertStyled>
			</Snackbar>

			<Dialog
				open={isShowCreateModal}
				onClose={() => setIsShowCreateModal(false)}
				title={titleModal}
			>
				<form onSubmit={onCreate}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="Name"
							value={newLifePlanTitle}
							inputProps={{ maxLength: RULES.title.maxLength }}
							onChange={({ target }) => setNewLifePlanTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{newLifePlanTitleError}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{newLifePlanTitle.length}/{RULES.title.maxLength} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

export default Create;
