import axios from "axios";

const CONFIG =
{
	accessKey: "a-SgFA-CS-P5MaqL8kl-gkz84C45cPV7fAvGfOGXLaY"
};

interface ISearchImagesParameters
{
	query: string;
	/**
	 * @default 1
	 */
	page?: number;
	/**
	 * @default 20
	 */
	per_page?: number;
	/**
	 * @default relevant
	 */
	order_by?: "relevant" | "latest";
	collections?: number[];
	/**
	 * @default low
	 */
	content_filter?: "low" | "hight";
	color?: "black_and_white" | "black" | "white" | "yellow" | "orange" | "red" | "purple" | "magenta" | "green" | "teal" | "blue";
	orientation?: "landscape" | "portrait" | "squarish";
}

interface IOutputData
{
	total: number;
	total_pages: number;
	results: { description: string; image: string; }[];
}

function searchImages(parameters: ISearchImagesParameters): Promise<IOutputData>
{
	const params = new URLSearchParams();

	params.append("query", String(parameters.query));
	params.append("page", String(parameters.page || 1));
	params.append("per_page", String(parameters.per_page || 20));
	params.append("order_by", String(parameters.order_by || "relevant"));
	params.append("content_filter", String(parameters.content_filter || "low"));

	if (Array.isArray(parameters.collections) && parameters.collections.length > 0)
	{
		params.append("collections", parameters.collections.join(","));
	}

	if (parameters.color !== undefined)
	{
		params.append("color", parameters.color);
	}

	if (parameters.orientation !== undefined)
	{
		params.append("orientation", parameters.orientation);
	}

	return new Promise((resolve, reject) =>
	{
		axios.get<{
			total: number;
			total_pages: number;
			results:
			{
				description: string | null;
				alt_description: string | null;
				urls:
				{
					raw: string;
					full: string;
					regular: string;
					small: string;
					thumb: string;
					small_s3: string;
				};
			}[];
		}>(
			"https://Api.unsplash.com/search/photos",
			{
				headers:
				{
					"Accept-Version": "v1",
					Authorization: "Client-ID " + CONFIG.accessKey
				},
				params
			}
		).then(({ data: responseData }) =>
		{
			const data: IOutputData = { total: responseData.total, total_pages: responseData.total_pages, results: [] };

			responseData.results.forEach(({ description, alt_description, urls }) =>
			{
				data.results.push(
					{
						description: description || alt_description || parameters.query,
						image: urls.small
					}
				);
			});

			resolve(data);

		}).catch(reject);
	});
}

export default searchImages;
