export default function getDateSubscription(date: string, countDays: number)
{
	const newDate = new Date(date);

	newDate.setDate(newDate.getDate() + countDays);

	const dateSubscription = `${ newDate.getDate() < 10 ? 0 : "" }${ newDate.getDate() }.${ newDate.getMonth() + 1 < 10 ? 0 : "" }${ newDate.getMonth() + 1 }.${ newDate.getFullYear() }`;

	return dateSubscription;
}
