import { createContext, Dispatch, SetStateAction } from "react";

export interface IMissionContext
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
	nextStep(): void;
	prevStep(): void;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	setShowMissionInfo: Dispatch<SetStateAction<2 | 3 | 4 | 5 | null>>;
}

export const MissionCtx = createContext<IMissionContext>({} as IMissionContext);
