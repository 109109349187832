import { Box, BoxProps, alpha } from "@mui/material";
import ArrowButton from "components/ArrowButton";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import MissionsDialog from "components/missions/Dialog";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import shuffleArray from "utils/shuffleArray";
import IMGHiddenImage from "../../assets/images/hidden-image.png";
import useMission from "../../hooks/useMission";

export interface IJobToLoveProps extends BoxProps { }
export type AttractiveInfoType = [ jobName: string, text: string ];

const INPUT_MIN_LEN: number = 2;
const INPUT_MAX_LEN: number = 200;

function JobToLove({ ...props }: IJobToLoveProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ inputValue, setInputValue ] = useState<string>("");
	const [ selectedJobIndex, setSelectedJobIndex ] = useState<number | null>(null);
	const [ attractiveInfo, setAttractiveInfo ] = useState<AttractiveInfoType[]>(loadAttractiveInfo);

	const columns = attractiveInfo.length > 9 ? 4 : 3;

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(missionContext.missionPrefix + "-attractiveInfo", attractiveInfo);
	}, [ attractiveInfo, missionContext.missionPrefix ]);

	// Utils
	function loadAttractiveInfo()
	{
		const store1 = getLocalStorage<AttractiveInfoType[]>(missionContext.missionPrefix + "-attractiveInfo");

		if (store1 !== null)
		{
			return store1;
		}

		const store2 = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsThatAttract");

		if (store2 === null)
		{
			missionContext.setStep(10);

			return [];
		}

		const data: AttractiveInfoType[] = store2.map((jobName) => [ jobName, "" ]);

		while ((data.length > 9 && data.length < 12) || data.length < 9)
		{
			data.push([ "__FILL__", "" ]);
		}

		shuffleArray(data);

		return data;
	}

	// Handles
	function onSelectJob(index: number)
	{
		if (attractiveInfo[ index ][ 1 ].length >= INPUT_MIN_LEN)
		{
			return;
		}

		if (attractiveInfo[ index ][ 0 ] === "__FILL__")
		{
			return saveInputValue(index, "0".repeat(INPUT_MIN_LEN));
		}

		setSelectedJobIndex(index);
		setInputValue("");
	}

	function saveJobAttractive()
	{
		if (selectedJobIndex === null)
		{
			return;
		}

		saveInputValue(selectedJobIndex, inputValue);
		setSelectedJobIndex(null);
	}

	function saveInputValue(index: number, value: string)
	{
		setAttractiveInfo((prevState) =>
		{
			const state = deepClone(prevState);

			state[ index ][ 1 ] = value.trim();

			return state;
		});
	}

	function isFill(index: number): boolean
	{
		if (attractiveInfo[ index ][ 1 ].length >= INPUT_MIN_LEN)
		{
			return true;
		}

		return false;
	}

	function nextStep()
	{
		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobToLove_title" defaultValue="Jobs that attract you" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobToLove_subTitle" defaultValue="Woah, {NAME}, that’s a great list. Now let’s dive a little deeper and reflect on what is it specifically that you find attractive in each job." position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: "30px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{
					width: { xl: "980px", xs: "784px" },
					height: { xl: "617px", xs: "493px" },

					display: "grid",
					gridTemplateColumns: `repeat(${ columns }, 1fr)`,
					gridTemplateRows: "1fr 1fr 1fr",

					borderRadius: "24px",
					background: `url(${ IMGHiddenImage }) center center/cover`
				}}>
					{attractiveInfo.map(([ jobName ], index) =>
					{
						return (
							<Box
								key={index}
								component="button"
								sx={[
									{
										border: "1px solid " + alpha("#FFF", 0.7),
										background: "rgba(239, 239, 239, 0.4)",
										backdropFilter: "blur(30px)",
										cursor: "pointer",

										font: "700 24px/31px Lora-Bold",
										color: "primary.main",
										textAlign: "center",

										display: "flex",
										justifyContent: "center",
										alignItems: "center",

										padding: "10px",

										transition: "linear 500ms background, linear 300ms border-radius"
									},
									(index === 0) && { borderTopLeftRadius: "24px" },
									(index === columns - 1) && { borderTopRightRadius: "24px" },
									(index === attractiveInfo.length - columns) && { borderBottomLeftRadius: "24px" },
									(index === attractiveInfo.length - 1) && { borderBottomRightRadius: "24px" },
									(isFill(index) === true) &&
									{
										background: "transparent",
										backdropFilter: "unset",
										border: "unset",
										cursor: "auto",
										borderRadius: "40%"
									}
								]}
								onClick={() => onSelectJob(index)}
							>
								{(isFill(index) === false) && (jobName === "__FILL__" ? "" : jobName)}
							</Box>
						);
					})}
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					onClick={nextStep}
					disabled={attractiveInfo.some((_item, index) => isFill(index) === false)}
				/>
			</StepperNavigationWrapper>

			<MissionsDialog open={selectedJobIndex !== null} onClose={() => setSelectedJobIndex(null)}>
				{selectedJobIndex !== null &&
					(
						<>
							<Box sx={{ font: "700 40px/51px Lora-Bold", color: "primary.main", textAlign: "center" }}>
								{attractiveInfo[ selectedJobIndex ][ 0 ]}
							</Box>

							<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark", textAlign: "center", marginTop: "8px" }}>
								What do you find the most attractive about this job?
							</Box>

							<Box sx={{ position: "relative" }}>
								<CustomInput.Base
									fullWidth
									multiline
									rows={3}
									placeholder="Text"
									sx={{ marginTop: "16px" }}
									inputProps={{ maxLength: INPUT_MAX_LEN }}
									value={inputValue}
									onChange={({ target }) => setInputValue(target.value)}
									inputRef={(ref: HTMLTextAreaElement | HTMLInputElement | null) =>
									{
										window.requestAnimationFrame(() => ref?.focus());
									}}
								/>

								<Box sx={{
									font: "400 14px/19px 'Open Sans'",
									color: "primary.dark",
									opacity: .5,
									position: "absolute",
									bottom: "-19px",
									right: "16px"
								}}>
									{inputValue.length}/{INPUT_MAX_LEN} characters
								</Box>
							</Box>

							<CustomButton.Contained
								sx={{ display: "flex", margin: "32px auto 0 auto" }}
								onClick={saveJobAttractive}
								disabled={inputValue.length < INPUT_MIN_LEN}
							>
								Submit
							</CustomButton.Contained>
						</>
					)
				}
			</MissionsDialog>
		</Box>
	);
}

export default forwardRef(JobToLove) as ForwardRefExoticComponent<IJobToLoveProps>;
