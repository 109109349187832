import { Box, Typography } from "@mui/material";
import { Ref, forwardRef } from "react";
import RhinocerosFon from "../../../assets/images/missions/mission-eight/rhinoceros-fon.png";
import Rhinoceros from "../../../assets/images/missions/mission-eight/rhinoceros.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const YourPlanFinish = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		setTimeout(() => missionContext.setStep(12), 0);
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				width: "100%",
				maxWidth: { lg: "802px", xl: "980px" },
				m: "0 auto",
				display: "flex",
				flexDirection: "column",
				marginTop: { lg: "40px", xl: "185px" }
			}}>
			<Box sx={{
				display: "flex",
				width: "100%",
				height: "100%",
				minHeight: "448px",
				margin: "0 auto",
				border: "1px solid rgba(255, 255, 255, .6)",
				borderRadius: "24px",
				wordBreak: "break-word",
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
				backdropFilter: "blur(25px)",
				boxShadow: "0px 5px 10px 0px #A7C7E199",
				position: "relative",
				maxHeight: "444px",
				overflow: "hidden"
			}}>
				<Box sx={{
					display: "flex",
					width: "261px",
					height: "100%",
					backgroundImage: `url(${ RhinocerosFon })`,
					backgroundRepeat: "no-repeat"

				}} />
				<Box sx={{
					width: "100%",
					position: "relative",
					padding: {
						xs: "15px 30px 35px 30px",
						md: "64px 65px 35px 50px"
					},
					wordBreak: "break-word"
				}}>
					<img
						src={Rhinoceros}
						alt="Rhinoceros"
						style={{
							width: "176px",
							height: "216px",
							position: "absolute",
							bottom: "70px",
							left: "-175px"
						}}
					/>

					<Typography sx={{
						marginLeft: "16px",
						marginBottom: "35px",
						color: "#fff",
						font: {
							lg: "700 18px/28px Lora-Bold",
							xl: "700 24px/38px Lora-Bold"
						},
						textAlign: "left"
					}}>
						<ContentAdmin
							limit={111}
							keyContent="contentMissionEight"
							keyText="haemin_sunim_p1"
							defaultValue="“When you are about to make an important decision, there is always a moment of hesitation right before you sign on the dotted line."
							position="left"
							isDescription
						/>
					</Typography>
					<Typography sx={{
						marginLeft: "16px",
						marginBottom: "25px",
						color: "#fff",
						font: {
							lg: "700 18px/28px Lora-Bold",
							xl: "700 24px/38px Lora-Bold"
						},
						textAlign: "left"
					}}>
						<ContentAdmin
							limit={211}
							keyContent="contentMissionEight"
							keyText="haemin_sunim_p2"
							defaultValue="Don’t pull back. You have come all this way after having given it serious thought. Don’t look back. Continue on your path courageously, like a one-horned rhinoceros.”"
							position="left"
							isDescription
						/>
					</Typography>
					<Typography sx={{
						marginLeft: "16px",
						color: "#ffffff",
						font: {
							lg: '400 18px/28px "Open Sans"',
							xl: '400 22px/30px "Open Sans"'
						},
						textAlign: "right"
					}}>
						<ContentAdmin
							limit={111}
							keyContent="contentMissionEight"
							keyText="haemin_sunim"
							defaultValue="Haemin Sunim"
							position="right"
						/>
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mt: "94px" }}>
				<CustomButton.Outline
					sx={{
						width: "293px",
						fontSize: "24px",
						lineHeight: "33px",
						background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)",
						backdropFilter: "blur(30px)",
						color: "#fff",
						borderColor: "#fff"
					}}
					onClick={nextStep}
				>Finish
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default YourPlanFinish;
