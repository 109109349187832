import { Box } from "@mui/material";
import Api from "api";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import useMission from "pages/missionFive/hooks";
import IMGCelebrationOcta from "pages/registration/assets/images/celebration-octa.png";
import { useEffect, useState } from "react";
import Confetti from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import clearLocalStorage from "utils/clearlocalStorage";
import trackEvent from "utils/mixpanelUtil";

function Complete()
{
	const clientWidth = document.documentElement.clientWidth;
	const navigate = useNavigate();
	const { state: userState, dispatch: userDispatch } = useUser();
	const missionContext = useMission();
	const [ bgIndex, setBgIndex ] = useState(0);
	const [ dataMissionString, setDataMissionString ] = useState({
		morning: "",
		afternoon: "",
		evening: "",
		night: ""
	});
	const [ isLoading, setIsLoading ] = useState(false);

	useEffect(() =>
	{
		const dataMission = {
			time: JSON.parse(`${ localStorage.getItem("mission-five-time") }`),
			morning: JSON.parse(`${ localStorage.getItem("mission-five-data-morning") }`),
			afternoon: JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon") }`),
			evening: JSON.parse(`${ localStorage.getItem("mission-five-data-evening") }`),
			night: JSON.parse(`${ localStorage.getItem("mission-five-data-night") }`)
		};

		setDataMissionString({
			morning: `I wake up at ${ dataMission.time[ 0 ].hours }:${ dataMission.time[ 0 ].minutes } ${ dataMission.morning?.environment?.wakeUp }. My home is ${ dataMission.morning?.environment?.lookLike }. It smells like ${ dataMission.morning?.environment?.howSmell }. I ${ dataMission.morning?.activities?.wakeUp }. I have breakfast at ${ dataMission.time[ 1 ].hours }:${ dataMission.time[ 1 ].minutes } For breakfast I eat ${ dataMission.morning?.activities?.howSmell }. I eat breakfast with ${ dataMission.morning?.people }.`,
			afternoon: `I ${ dataMission.afternoon?.environment?.spendAfternoon }. I am wearing ${ dataMission.afternoon?.environment?.wearing }. It’s ${ dataMission.afternoon?.environment?.theWeatherToday }. I ${ dataMission.afternoon?.activities?.dayActivities }. I have lunch at ${ dataMission.time[ 2 ].hours }:${ dataMission.time[ 2 ].minutes }. For lunch I have ${ dataMission.afternoon?.activities?.eatForLunch }. I  ${ dataMission.afternoon?.activities?.dayActivities }. I   ${ dataMission.afternoon?.activities?.atWork }`,
			evening: `In the evening, I ${ dataMission.evening?.environment?.spendEvening }. I get there by ${ dataMission.evening?.environment?.getThere }. When I am back, I ${ dataMission.evening?.activities?.spendEvening }. I ${ dataMission.evening?.activities?.personalFulfillment }. Dinner is at ${ dataMission.time[ 3 ].hours }:${ dataMission.time[ 3 ].minutes } I eat with ${ dataMission.evening?.people }.`,
			night: `My bedroom ${ dataMission.night?.environment }. I ${ dataMission.night?.activities }. I fall asleep at ${ dataMission.time[ 4 ].hours }:${ dataMission.time[ 4 ].minutes } with ${ dataMission.night?.people }.`
		});
	}, []);
	function finishMission()
	{
		setIsLoading(true);
		const data = dataMissionString;

		Api.missions
			.setDataMissionFive(data)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(5) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 5 }
					}
				);
			})
			.catch((error: any) =>
			{
				console.error(error);
			})
			.finally(() => setIsLoading(false));
		if (userState.user?.completedMission === 4)
		{
			setTimeout(() => missionContext.setStep(15), 0);
		}
		else
		{
			clearLocalStorage("mission-five");
			setTimeout(() => navigate("/dashboard", { replace: true }), 0);
		}
	}

	return (
		<Box sx={{
			padding: { xl: "60px 32px 32px 32px", sm: "48px 32px 32px 32px", xs: "54px 32px 0 32px" },
			flexGrow: 1,
			backdropFilter: "blur(40px)",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			height: "100vh"
		}}>
			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main",
				textAlign: "center"
			}}>
				Congratulations!<br />
				Another mission is complete.
			</Box>

			<Confetti
				particleCount={250}
				particleSize={clientWidth <= 700 ? 10 : 15}
				force={0.8}
				duration={5000}
				width={clientWidth}
				colors={[
					"#C1D2EF",
					"#CAEAB1",
					"#E39BBE",
					"#7D84DA",
					"#E2E49D",
					"#9DDE8A",
					"#B5A7E7",
					"#96B8E2",
					"#DF8EA7",
					"#F6E0DB"
				]}
			/>

			<Box
				component="img"
				src={IMGCelebrationOcta}
				alt=""
				sx={{
					marginTop: { xl: "40px", sm: 0, xs: "80px" },
					maxWidth: { xl: "716px", sm: "602px", xs: "390px" },
					width: "100%"
				}}
			/>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "40px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>

				<CustomButton.Contained
					size="large"
					onClick={finishMission}
					loading={isLoading}
					sx={{
						maxWidth: "390px",
						width: { sm: "auto", xs: "100%" },
						boxShadow: "unset"
					}}
				>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default Complete;
