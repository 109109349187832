import { alpha, Box, BoxProps, useMediaQuery } from "@mui/material";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import TooltipStyled from "components/TooltipStyled";
import { createRef, forwardRef, ForwardRefExoticComponent, MouseEvent, Ref, useEffect, useRef, useState } from "react";
import { setLocalStorage } from "utils/localStorage";
import { ReactComponent as SVGArrowHorizontal } from "../../assets/images/arrow-horizontal.svg";
import { ReactComponent as SVGArrowVertical } from "../../assets/images/arrow-vertical.svg";
import { ReactComponent as SVGIconEmotionPeaks } from "../../assets/images/emotion-peaks.svg";
import { ReactComponent as SVGIconEmotionValleys } from "../../assets/images/emotion-valleys.svg";
import { ReactComponent as SVGIconEraser } from "../../assets/images/eraser.svg";
import IMGPreviewGraph from "../../assets/images/graph.png";
import IMGPencil from "../../assets/images/pencil.png";
import useMission from "../../hooks/useMission";
import { findPeaksAndValleys, Point } from "./Utils";

export interface ILifeHighsLowsProps extends BoxProps { }

export interface IPointInfo extends Point
{
	isSaved: boolean;
	type: "highs" | "lows";
	data:
	{
		label: string;
		values: string[];
		environment?: string;
		activities?: string;
		socialInteractions?: string;
	};
}

const CURSOR_OFFSET: number = 0; // 36
const CANVAS_SIZE: Record<"xl" | "xs", [ width: number, height: number ]> = {
	xl: [ 823, 456 ],
	xs: [ 658, 364 ]
};
const LINE_STROKE =
	{
		width: 3,
		color: "#215C75"
	} as const;

function LifeHighsLows({ ...props }: ILifeHighsLowsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const loadRef = useRef<boolean>(false);
	const canvasRef = createRef<HTMLCanvasElement>();
	const contextRef = useRef<CanvasRenderingContext2D>();

	const [ isPreview, setIsPreview ] = useState<boolean>(true);

	const [ isDrawing, setIsDrawing ] = useState<boolean>(false);
	const [ path, setPath ] = useState<Point[]>([]);
	const [ isFinish, setIsFinish ] = useState<boolean>(false);

	const isXl = useMediaQuery((theme) => (theme as any).breakpoints.up("xl"));

	// Effects
	useEffect(() =>
	{
		function prepareCanvas()
		{
			const canvas = canvasRef.current!;

			canvas.width = CANVAS_SIZE[ isXl ? "xl" : "xs" ][ 0 ];
			canvas.height = CANVAS_SIZE[ isXl ? "xl" : "xs" ][ 1 ];
			canvas.style.width = CANVAS_SIZE[ isXl ? "xl" : "xs" ][ 0 ] + "px";
			canvas.style.height = CANVAS_SIZE[ isXl ? "xl" : "xs" ][ 1 ] + "px";

			const ctx = canvas.getContext("2d")!;

			ctx.lineCap = "round";

			contextRef.current = ctx;
		}

		if (loadRef.current === false && isPreview === false)
		{
			loadRef.current = true;
			prepareCanvas();
		}
	}, [ isPreview, canvasRef, isXl ]);

	// Functions
	function onStartDrawing({ nativeEvent }: MouseEvent<HTMLCanvasElement>)
	{
		if (!contextRef.current)
		{
			return;
		}

		const { offsetX: x, offsetY: y } = nativeEvent;

		clearCanvas();

		const ctx = contextRef.current;

		ctx.strokeStyle = LINE_STROKE.color;
		ctx.lineWidth = LINE_STROKE.width;

		ctx.beginPath();
		ctx.moveTo(x, y + CURSOR_OFFSET);

		setPath([ { x, y: y + CURSOR_OFFSET } ]);
		setIsDrawing(true);
		setIsFinish(false);
	}

	function onFinishDrawing()
	{
		if (!contextRef.current || !isDrawing)
		{
			return;
		}

		setIsDrawing(false);

		const ctx = contextRef.current;

		ctx.closePath();

		setIsFinish(true);
	}

	function onDraw({ nativeEvent }: MouseEvent<HTMLCanvasElement>)
	{
		if (!contextRef.current || !isDrawing)
		{
			return;
		}

		const { offsetX: x, offsetY: y } = nativeEvent;

		contextRef.current.lineTo(x, y + CURSOR_OFFSET);
		contextRef.current.stroke();

		setPath((prevPath) => [ ...prevPath, { x, y: y + CURSOR_OFFSET } ]);
	}

	function clearCanvas()
	{
		contextRef.current!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

		setIsFinish(false);
	}

	function nextStep()
	{
		const { peaks, valleys } = findPeaksAndValleys(path);

		const pointsInfo: IPointInfo[] = [];

		const highs = (peaks[ 0 ].y < valleys[ 0 ].y ? peaks : valleys)
			.map((point) =>
			{
				return {
					...point,
					isSaved: false,
					type: "highs",
					data:
					{
						label: "",
						values: []
					}
				} as IPointInfo;
			});

		const lows = (peaks[ 0 ].y < valleys[ 0 ].y ? valleys : peaks)
			.map((point) =>
			{
				return {
					...point,
					isSaved: false,
					type: "lows",
					data:
					{
						label: "",
						values: []
					}
				} as IPointInfo;
			});

		// Sort point highs-lows-highs-lows-* or lows-highs-lows-highs-*
		let s = Math.max(highs.length, lows.length) * 2;
		let d = (peaks[ 0 ].y < valleys[ 0 ].y ? [ lows, highs ] : [ highs, lows ]);
		let i = -1;
		let v: IPointInfo | undefined;

		while (s - i++)
		{
			v = d[ i % 2 ][ (i - i % 2) / 2 ];

			if (v !== undefined)
			{
				pointsInfo.push(v);
			}
		}

		setLocalStorage(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo", pointsInfo);
		setLocalStorage(missionContext.missionPrefix + "-lifeHighsLows-graphImage", canvasRef.current!.toDataURL());

		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", gap: "14px !important" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_title" defaultValue="Life’s Highs & Lows" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_subTitle" defaultValue="{NAME}, look at your life holistically and draw your life journey to date. Add your biggest highs & lows in chronological order from birth until now." position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				{isPreview === true &&
					(
						<Panel sx={{ padding: { xl: "32px 24px 32px 24px", xs: "24px" } }}>
							<Box sx={{
								font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								color: "primary.main",
								textAlign: "center",
								marginBottom: "14px"
							}}>
								<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_previewPanelTitle" defaultValue="It will look something like this:" position="left" />
							</Box>

							<Box sx={{
								marginRight: { xl: "40px", xs: "24px" },
								height: { xl: "460px", xs: "368px" },
								display: "grid",
								gridTemplateColumns: "auto 1fr",
								gridTemplateRows: "1fr auto",
								gap: "0 16px"
							}}>
								{/* Left arrow */}
								<Box className="leftArrow" sx={{ height: "100%", display: "flex" }}>
									<Box sx={{
										writingMode: "vertical-rl",
										transform: "rotate(-180deg)",
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										textAlign: "center",
										position: "relative",
										right: "-10px"
									}}>
										Life Satisfaction
									</Box>

									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "15px" }}>
										<SVGIconEmotionPeaks />
										<Box component={SVGArrowVertical} sx={{ height: { xl: "320px", xs: "230px" } }} />
										<SVGIconEmotionValleys />
									</Box>
								</Box>

								{/* Graph */}
								<Box
									className="graph"
									sx={{
										width: "100%",
										height: "100%",
										borderRadius: "16px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<img src={IMGPreviewGraph} alt="Graph" width="100%" />
								</Box>

								{/* Bottom arrow */}
								<Box className="bottomArrow" sx={{ gridColumn: "2", height: "44px", display: "flex", flexDirection: "column" }}>
									<Box sx={{
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										height: "100%",
										textAlign: "center",
										position: "relative",
										top: "-5px",
										order: 2
									}}>
										Time
									</Box>

									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
										<Box sx={{
											color: "primary.main",
											font: "700 18px/25px 'Open Sans'",
											textTransform: "uppercase",
											textAlign: "center"
										}}>
											Birth
										</Box>

										<SVGArrowHorizontal width="100%" />

										<Box sx={{
											color: "primary.main",
											font: "700 18px/25px 'Open Sans'",
											textTransform: "uppercase",
											textAlign: "center"
										}}>
											Now
										</Box>
									</Box>
								</Box>
							</Box>

							<CustomButton.Contained sx={{ margin: "15px auto 0 auto", display: "flex" }} onClick={() => setIsPreview(false)}>
								Got it
							</CustomButton.Contained>
						</Panel>
					)
				}

				{isPreview === false &&
					(
						<Panel sx={{ padding: { xl: "32px 24px 32px 24px", xs: "24px" } }}>
							<Box sx={{
								font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								color: "primary.main",
								marginLeft: "63px",
								marginBottom: "22px"
							}}>
								<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_panelTitle" defaultValue="{NAME}'s life journey to date" position="left" />
							</Box>

							<Box sx={{
								marginRight: { xl: "40px", xs: "24px" },
								display: "grid",
								gridTemplateColumns: "auto 1fr",
								gridTemplateRows: "1fr auto",
								gap: "14px"
							}}>
								{/* Left arrow */}
								<Box className="leftArrow" sx={{ height: "100%", width: "49px", display: "flex" }}>
									<Box sx={{
										writingMode: "vertical-rl",
										transform: "rotate(-180deg)",
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										textAlign: "center"
									}}>
										Life Satisfaction
									</Box>

									<Box sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "space-between",
										position: "relative",
										left: "-10px"
									}}>
										<SVGIconEmotionPeaks />
										<Box component={SVGArrowVertical} sx={{ height: { xl: "320px", xs: "230px" } }} />
										<SVGIconEmotionValleys />
									</Box>
								</Box>

								{/* Graph */}
								<Box
									className="graph"
									sx={{
										position: "relative",
										width: "100%",
										height: "100%",
										backgroundColor: alpha("#FFFFFF", 0.5),
										borderRadius: "16px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										cursor: `url(${ IMGPencil }), pointer`
									}}
								>
									<canvas
										ref={canvasRef}
										onMouseDown={onStartDrawing}
										onMouseUp={onFinishDrawing}
										onMouseLeave={onFinishDrawing}
										onMouseMove={onDraw}
										style={{ borderRadius: "16px" }}
									/>

									<TooltipStyled title="Clear canvas" placement="top" arrow>
										<Box
											component="button"
											sx={{
												position: "absolute",
												bottom: "19px",
												right: "19px",
												padding: "5px",
												border: "unset",
												borderRadius: "10px",
												background: "unset",
												cursor: "pointer",
												transition: "linear 200ms background-color",
												"&:hover": { backgroundColor: alpha("#215C75", 0.2) }
											}}
											onClick={clearCanvas}
										>
											<SVGIconEraser />
										</Box>
									</TooltipStyled>
								</Box>

								{/* Bottom arrow */}
								<Box className="bottomArrow" sx={{ gridColumn: "2", height: "44px", display: "flex", flexDirection: "column" }}>
									<Box sx={{
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										height: "100%",
										textAlign: "center",
										position: "relative",
										top: "-5px",
										order: 2
									}}>
										Time
									</Box>

									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
										<Box sx={{
											color: "primary.main",
											font: "700 18px/25px 'Open Sans'",
											textTransform: "uppercase",
											textAlign: "center"
										}}>
											Birth
										</Box>

										<SVGArrowHorizontal width="100%" />

										<Box sx={{
											color: "primary.main",
											font: "700 18px/25px 'Open Sans'",
											textTransform: "uppercase",
											textAlign: "center"
										}}>
											Now
										</Box>
									</Box>
								</Box>
							</Box>
						</Panel>
					)
				}
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={isFinish === false}
					hidden={isPreview === true}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(LifeHighsLows) as ForwardRefExoticComponent<ILifeHighsLowsProps>;
