import { Box, Checkbox, Divider, Fade, FormControlLabel, FormGroup, Typography } from "@mui/material";
import Api from "api";
import bgArtifactNine from "assets/images/bgArtifactNine.png";
import bgArtifactNineBtn from "assets/images/bgArtifactNineBtn.png";
import checkedIcon from "assets/images/checkedIcon.png";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import checkboxNoActive from "assets/images/missions/mission-ten/checkboxNoActive.svg";
import CustomButton from "components/customButton";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import Create from "./components/Create";
import Details from "./components/Details";

export interface DataTask
{
	id: number;
	title: string;
	active: boolean;
	define: string;
	items: DataStep[];
	input?: string;
}
export interface DataStep
{
	id: number;
	title: string;
	active: boolean;
	checked: boolean;
	values: Values[];
	input?: string;
}
export interface Values
{
	title: string;
	checked: boolean;
}
export interface DataMissions
{
	_id: string;
	created_at: string;
	user_id: string;
	id: string;
	mission_data: any;
	title: string;

}
function MyLifePlan()
{
	const navigate = useNavigate();
	const params = useParams();
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ dataMissions, setDataMissions ] = useState<DataMissions[]>([]);

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}

	useLayoutEffect(() =>
	{
		Api.missions
			.getDataMissionNine()
			.then(({ data }) =>
			{
				setDataMissions(data);
			})
			.catch((error) => console.error(error));
	}, [ navigate ]);

	switch (params.action)
	{
		case "create": return (<Create />);
		case "details": return (<Details />);
	}

	return (
		<ArtifactsContainer
			title="My Life Plan"
			headerRight={
				dataMissions?.[ selectedItemIndex ]
					? dataMissions[ selectedItemIndex ].title || formatDate(dataMissions[ selectedItemIndex ].created_at)
					: null
			}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "none"
							}
						}}
					>
						{dataMissions.length === 0
							? (
								<Box>Loading...</Box>
							)
							: (
								dataMissions.map((item, index: number) =>
								{
									return (
										<AsideCircleButton
											key={index}
											image={bgArtifactNineBtn}
											text={item.title || formatDate(item.created_at)}
											isSelected={selectedItemIndex === index}
											onClick={() => setSelectedItemIndex(index)}
											sx={{
												"& .AsideCircleButton-image": {
													borderRadius: 0,
													boxShadow: "none"
												}
											}}
										/>
									);
								})
							)
						}
					</Box>
					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: "355px"
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/my-life-plan/create");
						}}
					>
						Create new plan
					</CustomButton.Contained>
				</Box>}
			backgroundImage={bgArtifactNine}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main": {
					p: "40px 65px 40px 48px"
				},
				"& .ArtifactsContainer-content": {
					border: "none"
				}
			}}
			onExpand={() => navigate("/dashboard/artifacts/my-life-plan/details/" + dataMissions[ selectedItemIndex ].id)}
			onClose={() => navigate("/dashboard/artifacts")}
		>
			<Box sx={{ position: "relative" }}>
				{selectedItemIndex > 0 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState - 1)}
								sx={{
									"--size": { xl: "40px", lg: "32px", xs: "40px" },
									width: "var(--size)",
									height: "var(--size)",
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									left: { lg: "-40px", xl: "0px" },
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.main",
									cursor: "pointer",

									"& svg":
									{
										width: "var(--size)",
										height: "var(--size)"
									},
									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
							</Box>
						</Fade>
					)
				}

				{selectedItemIndex < dataMissions.length - 1 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState + 1)}
								sx={{
									"--size": { xl: "40px", lg: "32px", xs: "40px" },
									width: "var(--size)",
									height: "var(--size)",
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									right: { xl: "-50px", lg: "-50px", xs: "20px" },
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.main",
									cursor: "pointer",

									"& svg":
									{
										width: "var(--size)",
										height: "var(--size)"
									},
									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow />
							</Box>
						</Fade>
					)
				}
				<Box sx={{ widht: "100%", ml: { xs: 0, xl: 10 } }}>
					{dataMissions
						.filter((_item, index) => index === selectedItemIndex)
						.map((item) => (
							<Box
								className="customScroll"
								sx={{
									maxHeight: { lg: "535px", xl: "665px" },
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
									overflowY: "auto",
									gap: "24px",
									width: "100%",
									pr: "35px"
								}}
							>
								{item.mission_data[ 0 ].map((itemTask: DataTask, indexTask: number) => (
									<Box
										key={indexTask}
										sx={{
											boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
											borderRadius: "24px",
											border: "1px solid rgba(255, 255, 255, 0.5)",
											background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
											width: "100%",
											p: "24px 32px 32px",
											color: "primary.dark",
											backdropFilter: "blur(30px)"
										}}
									>
										<Typography sx={{ font: { lg: "700 18px/30px Lora-Bold", xl: "700 22px/30px Lora-Bold" }, color: "primary.main", mb: 2 }}>{itemTask.title}</Typography>
										{itemTask.items.map((itemStep: DataStep, indexItemStep: number) => (
											<Box key={indexItemStep} sx={{ display: "flex", gap: "32px" }}>
												<Typography sx={{ font: { lg: "700 16px/30px Open Sans", xl: "700 22px/30px Open Sans" }, minWidth: "264px" }}>{itemStep.title}</Typography>
												<Box sx={{ width: "100%" }}>
													<FormGroup>
														{itemStep.values.map((itemValues: Values, indexValues: number) => (
															<FormControlLabel
																key={indexValues}
																control={<Checkbox
																	icon={<Box
																		component="img"
																		width="100%"
																		src={checkboxNoActive}
																		alt=""
																	/>}
																	checked={itemValues.checked}
																	disabled
																	checkedIcon={<Box
																		component="img"
																		width="100%"
																		src={checkedIcon}
																		alt=""
																	/>} />}
																label={itemValues.title}
																sx={{
																	".MuiTypography-root": {
																		font: "400 16px/30px Open Sans",
																		xl: "400 22px/30px Open Sans",
																		ml: 1
																	},
																	".MuiButtonBase-root": {
																		minWidth: "42px"
																	}
																}} />
														))}
														{(itemTask.items.length - 1 !== indexItemStep && itemTask.items.length !== 0) &&
															(
																<Divider sx={{ mt: 3, mb: 3 }} />
															)
														}
													</FormGroup>
												</Box>
											</Box>
										))}
									</Box>
								))}
							</Box>
						))}
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default MyLifePlan;
