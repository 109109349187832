import { Box, CircularProgress } from "@mui/material";
import Api from "api";
import { IGetDataMissionsFive } from "api/Missions";
import { useCallback, useState } from "react";
import IMGMissionLarge5 from "../../../../assets/images/missions/mission-six/mission-five-large.png";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";
import ImageSideModal from "../ImageSideModal";

export interface IMission2Props
{
	isOpen: boolean;
}

function ModalMission5Data({ isOpen = false }: IMission2Props)
{
	const missionContext = useMission();
	const [ data, setData ] = useState<IGetDataMissionsFive[ "data" ][ "mission_data" ] | null>(null);
	const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	// Handles
	const onOpen = useCallback(() =>
	{
		Api.missions
			.getDataMissionFive(false)
			.then(({ data }) =>
			{
				setIsErrorLoading(false);
				setData(data[ 0 ].mission_data);
			})
			.catch((error) =>
			{
				setIsErrorLoading(true);
				console.error(error);
			});
	}, []);

	// Render
	return (
		<ImageSideModal
			open={isOpen}
			onClose={() => missionContext.setShowMissionInfo(null)}
			image={IMGMissionLarge5}
			size="long"
			title={<ContentAdmin keyContent="contentMissionSix" keyText="modal_perfectDay_title" defaultValue="{NAME}’s Perfect Day" position="left" />}
			subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="modal_perfectDay_subTitle" defaultValue="You thought about your everyday happiness and imagined what your Perfect Average Day might look like." position="left" isDescription />}
			onOpen={onOpen}
		>
			{(data === null || data === undefined) && isErrorLoading === false &&
				(
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
						Loading data <CircularProgress size={24} />
					</Box>
				)
			}
			{(data === null || data === undefined) && isErrorLoading === true &&
				(
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15px" }}>
						Failed to retrieve data, please try again
					</Box>
				)
			}
			{data !== null && data !== undefined && isErrorLoading === false &&
				(
					<Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
						{Object.entries(data).map(([ title, text ], index) =>
						{
							return (
								<div key={title + index}>
									<Box component="h3" sx={{ margin: "0 0 8px 0", font: "700 24px/31px Lora", color: "primary.main", textTransform: "capitalize" }}>
										{title}
									</Box>

									<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark" }}>
										{text}
									</Box>
								</div>
							);
						})}
					</Box>
				)
			}
		</ImageSideModal>
	);
}

export default ModalMission5Data;
