import fearsBg from "assets/images/fearsBg.png";
import fearsBgCreate from "assets/images/fearsBgCreate.png";
import { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFears } from "..";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import DescFears from "./DescFears";
import FinishCreate from "./FinishCreate";
import NewFears from "./NewFears";

function Create()
{
	const navigate = useNavigate();
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ fears, setFears ] = useState<IFears[]>([]);
	const [ step, setStep ] = useState(0);
	const [ error, setError ] = useState<string>("");
	const [ arrValues, setArrValues ] = useState<string[]>([]);

	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 70)
		{
			return setError("The text should not exceed 40 characters");
		}

		if (arrValues.length !== 12)
		{
			let newArrValues = arrValues;

			newArrValues.push(`What if I ${ valueInput.trim() }`);
			setArrValues(newArrValues);
		}

		setValueInput("");
	}

	function nextStep()
	{
		return setStep(pre => pre + 1);
	}

	function deleteItemArrValues(item: string)
	{
		setArrValues(arrValues.filter((itemVal: string) => itemVal !== item));
	}

	const renderComponents = [
		<NewFears
			valueInput={valueInput}
			setValueInput={setValueInput}
			setError={setError}
			error={error}
			handleAddArrValues={handleAddArrValues}
			arrValues={arrValues}
			deleteItemArrValues={deleteItemArrValues}
			nextStep={nextStep}
		/>,
		<DescFears arrValues={arrValues} nextStep={nextStep} fears={fears} setFears={setFears} />,
		<FinishCreate fears={fears} />
	];

	// Render
	return (
		<ArtifactsContainer
			title="New fears"
			backgroundImage={step === 2 ? fearsBg : fearsBgCreate}
			onClose={() => navigate("/dashboard/artifacts/fears")}
		>
			{renderComponents[ step ]}
		</ArtifactsContainer>
	);
}

export default Create;
