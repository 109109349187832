import { Box, Fade } from "@mui/material";
import Api from "api";
import IMGGuideOcta from "assets/images/guideChara-shrink.png";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import useRegistration from "pages/registration/hooks/useRegistration";
import { KeyboardEvent, useState } from "react";
import { ValidationError } from "yup";

function WithEmail()
{
	const { setStep, setEmail, email } = useRegistration();
	const [ isAcceptTerms, setIsAcceptTerms ] = useState<boolean>(false);
	const [ error, setError ] = useState<string | null>(null);
	const [ isFetch, setIsFetch ] = useState(false);

	// Handles
	function submitEmail()
	{
		if (isFetch === true)
		{
			return;
		}

		let error: string | null = null;

		try
		{
			config.email.yup().validateSync(email.trim());
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		if (error !== null)
		{
			return setError(error);
		}

		setIsFetch(true);
		setError(null);

		Api.users
			.checkUser(email)
			.then(({ data }) =>
			{
				setError(data === false ? null : "The specified email is already registered");

				if (data === false)
				{
					setStep((prevStep) => prevStep + 1);
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	function onKeyDownHandler(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key === "Enter")
		{
			if (isAcceptTerms === true)
			{
				submitEmail();
			}
		}
		else
		{
			setError(null);
		}
	}

	// Render
	return (
		<Box sx={{
			padding: { xl: "48px 24px 24px 24px", xs: "36px 32px 0px 32px" },
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			flexGrow: 1
		}}>
			<Box
				component="img"
				src={IMGGuideOcta}
				alt=""
				sx={{
					width: { xl: "211px", xs: "197px" }
				}}
			/>

			<Box sx={{ width: "100%", maxWidth: "478px" }}>
				<Box sx={{
					font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
					color: "primary.main",
					textAlign: "center"
				}}>
					Type your email here
				</Box>

				<CustomInput.Base
					placeholder="Email"
					value={email}
					onChange={({ target }) => setEmail(target.value.trim())}
					onKeyDown={onKeyDownHandler}
					sx={{
						marginTop: "32px",
						width: "100%",
						borderRadius: "8px !important",

						"& .MuiInputBase-input":
						{
							padding: "16px 13px !important"
						}
					}}
				/>

				<Fade in={error !== null}>
					<Box sx={{ padding: "0 8px", color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
						{error}
					</Box>
				</Fade>

				<CheckboxStyledWithLabel
					checkboxProps={{
						checked: isAcceptTerms,
						onChange(_event, value)
						{
							setIsAcceptTerms(value);
						}
					}}
					label={
						<>
							I accept the terms of the <a href="/terms-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
						</>
					}
					sx={{
						marginTop: "12px",

						font: "400 14px/16px 'Open Sans' !important",
						color: "primary.main",

						"& a":
						{
							textDecoration: "underline",
							color: "inherit"
						}
					}}
				/>
			</Box>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "128px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Contained
					onClick={() => submitEmail()}
					disabled={isAcceptTerms === false || isFetch === true}
					loader={isFetch === true}
					sx={{
						width: { sm: "unset", xs: "100%" }
					}}
				>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default WithEmail;
