import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import { IGetDataMissionFour, ISaveMissionFourData } from "api/Missions";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomButton from "components/customButton";
import { useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import { formatDate } from "../yearning";
import Create from "./Create";
import Details from "./Details";
import IMGBackground from "./assets/images/background.png";
import IMGButtonImage from "./assets/images/button-image.png";

export enum ETab
{
	PERFECT_JOB_AD = 1,
	RECOMMENDED_JOBS = 2,
	ACTION_PLAN = 3
}

export const LOCAL_STORAGE_PREFIX: string = "dashboard-artifact-career";

export const TABS: [ text: string, tabValue: ETab ][] = [
	[ "my perfect job ad", ETab.PERFECT_JOB_AD ],
	[ "recommended jobs", ETab.RECOMMENDED_JOBS ],
	[ "action plan", ETab.ACTION_PLAN ]
];

function Career()
{
	const navigate = useNavigate();
	const params = useParams();

	const [ selectedTab, setSelectedTab ] = useState<ETab>(ETab.PERFECT_JOB_AD);

	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ items, setItems ] = useState<IGetDataMissionFour[ "data" ] | null>(null);
	const [ selectedItem, setSelectedItem ] = useState<number>(0);

	// Utils
	function updateItems(callback?: () => void, force: boolean = false)
	{
		if (force === false && (isFetch === true || isFetchRef.current === true))
		{
			return;
		}

		setIsFetch(true);
		isFetchRef.current = true;

		Api.missions
			.getDataMissionFour()
			.then(({ data }) =>
			{
				setItems(data.filter(({ mission_data }) => mission_data !== undefined));

				callback && callback();
			})
			.catch((error) => console.info(error))
			.finally(() =>
			{
				setIsFetch(true);
				isFetchRef.current = false;
			});
	}

	// Effects
	useLayoutEffect(() => updateItems(), []); // eslint-disable-line react-hooks/exhaustive-deps

	// Render
	switch (params.action)
	{
		case "create": return <Create updateItems={updateItems} />;
		case "details": return <Details items={items} />;
		case undefined: break;
		default: return <Navigate to="/dashboard/artifacts/career" />;
	}

	return (
		<ArtifactsContainer
			title="My Fulfilling Career"
			backgroundImage={IMGBackground}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" }
						}}
					>
						{items === null
							? (<Box>Loading...</Box>)
							: (
								items.map((item, index) =>
								{
									return (
										<AsideCircleButton
											key={item.id}
											image={IMGButtonImage}
											text={formatDate(item.created_at)}
											isSelected={selectedItem === index}
											onClick={() => setSelectedItem(index)}
										/>
									);
								})
							)
						}

					</Box>

					<CustomButton.Contained onClick={() => navigate("/dashboard/artifacts/career/create")}>
						Add new job ad
					</CustomButton.Contained>
				</Box>
			}
			headerRight={items?.[ selectedItem ] !== undefined && formatDate(items[ selectedItem ].created_at)}
			onClose={() => navigate("/dashboard/artifacts")}
			onExpand={
				(items?.[ selectedItem ] === undefined)
					? undefined
					: () => navigate("/dashboard/artifacts/career/details/" + formatDate(items[ selectedItem ].id))
			}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					position: "relative",
					gap: { xl: "32px", lg: "24px", xs: "32px" }
				}
			}}
		>
			{items !== null && selectedItem > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItem((prevState) => prevState - 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "32px" },
								display: "inline-flex",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								left: { xl: "20px", lg: "0px", xs: "8px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{items !== null && selectedItem < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItem((prevState) => prevState + 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "32px" },
								display: "inline-flex",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "0px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}

			<Box sx={{
				padding: { xl: "40px 48px 48px 48px", lg: "20px 24px 24px 24px", xs: "40px 48px 48px 48px" },
				width: { xl: "932px", lg: "664px", xs: "932px" },
				height: { xl: "690px", lg: "544px", xs: "690px" },
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.8) 0%, rgba(239, 239, 239, 0.102) 98.96%)",
				backdropFilter: "blur(25px)",
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "24px",

				display: "grid",
				gridTemplateRows: { xl: "auto 520px", lg: "auto 443px", xs: "auto 520px" },
				justifySelf: "center",
				gap: { xl: "24px", lg: "16px", xs: "24px" }
			}}>
				<Box sx={{
					display: "flex",
					justifyContent: "space-between",
					gap: "8px",
					padding: { xl: "0 16px", lg: "0 8px", xs: "0 16px" }
				}}>
					{TABS.map(([ text, tabValue ]) =>
					{
						return (
							<CustomButton.Base
								key={tabValue}
								onClick={() => setSelectedTab(tabValue)}
								sx={[
									{
										textTransform: "uppercase",
										padding: { xl: "14px 24px", lg: "9px 21px", xs: "14px 24px" },
										font: { xl: "700 18px/25px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" },
										minWidth: "unset",
										minHeight: "unset",
										opacity: 0.5,
										border: "unset"
									},
									(selectedTab === tabValue) &&
									{
										opacity: 1
									}
								]}
							>
								{text}
							</CustomButton.Base>
						);
					})}
				</Box>

				<Box sx={{
					padding: { xl: "32px", lg: "16px", xs: "32px" },
					backgroundColor: alpha("#FFFFFF", 0.8),
					borderRadius: { xl: "32px", lg: "24px", xs: "32px" }
				}}>
					<Box
						className="customScroll"
						sx={{
							overflowY: "auto",
							height: "100%",
							paddingRight: "16px"
						}}
					>
						{items?.[ selectedItem ] !== undefined && formatData(items[ selectedItem ].mission_data, selectedTab)
						}
					</Box>
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export function formatData(item: ISaveMissionFourData | undefined, data: ETab)
{
	if (item === undefined)
	{
		return undefined;
	}

	switch (data)
	{
		case ETab.PERFECT_JOB_AD: {
			return (
				<Box sx={{ font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" } }}>
					{item.personalJobAdText}
				</Box>
			);
		}

		case ETab.RECOMMENDED_JOBS: {
			return (
				<Box
					component="ul"
					sx={{
						listStyle: "none",
						margin: "unset",
						padding: "unset",
						display: "flex",
						flexDirection: "column",

						"& li":
						{
							font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
							display: "flex",
							gap: "5px",

							"& .CheckboxStyledWithLabel-label":
							{
								"--line-height": { xl: "30px", xs: "22px" },
								font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
								borderRadius: "2px"
							},
							"& .CheckboxStyledWithLabel-checkbox":
							{
								backgroundColor: alpha("#3E809D", 0.4)
							}
						}
					}}
				>
					{item.jobList.map((jobName, index) =>
					{
						return (
							<li key={index + jobName} style={{ order: (item.selectedJobs.includes(jobName) ? 0 : 1) }}>
								<CheckboxStyledWithLabel
									label={jobName}
									checkboxProps={{
										checked: item.selectedJobs.includes(jobName),
										readOnly: true
									}}
								/>
							</li>
						);
					})}
				</Box>
			);
		}

		case ETab.ACTION_PLAN: {
			const list = Object.entries(item.jobsInformation)
				.filter(([ , jobInformation ]) =>
				{
					return jobInformation.list.filter(({ checked }) => checked === true).length > 0;
				});

			// Render
			if (list.length === 0)
			{
				return (
					<Box sx={{ font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" } }}>
						You did not select any action steps
					</Box>
				);
			}

			return (
				<Box
					component="ul"
					sx={{
						listStyle: "none",
						margin: "unset",
						padding: "unset",

						"& li":
						{
							"--line-height": { xl: "30px", xs: "22px" },
							display: "flex",
							gap: "8px",
							font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" },

							"&:not(:last-child)":
							{
								marginBottom: "16px"
							},
							"&::before":
							{
								"--size": "16px",
								content: "''",
								flexShrink: 0,
								marginTop: "calc((var(--line-height) - var(--size)) / 2)",
								display: "block",
								width: "var(--size)",
								height: "var(--size)",
								backgroundColor: alpha("#3E809D", 0.4),
								borderRadius: "2px"
							}
						}
					}}
				>
					{list.map(([ jobName, jobInformation ]) =>
					{
						return jobInformation.list
							.filter(({ checked }) => checked === true)
							.map(({ text }, index) =>
							{
								return <li key={jobName + index + text}>{text}</li>;
							});
					})}
				</Box>
			);
		}
	}
}

export default Career;
