import { alpha, Box, SxProps, Theme } from "@mui/material";
import { DragEvent, forwardRef, Ref, useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import deepClone from "../../../../utils/deepClone";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import randomInt from "../../../../utils/randomInt";
import IMGBubble from "../../assets/images/bubble.png";
import useMission from "../../hooks/useMission";
import { ReactComponent as SVGBubble } from "./assets/bubble.svg";
import { ReactComponent as SVGWaterDrip } from "./assets/water-drip.svg";

export type IWhatBeliefsWouldYouRatherHaveProps = Record<string, any>;

export interface IBoards
{
	bubble: string[];
	items: string[];
	"water-drip": string[];
}

type BubbleType = [ size: number, position: SxProps<Theme> ];

const ITEMS = [
	"Life is a battlefield",
	"Life is unfair",
	"I can do anything",
	"Life is hard",
	"I am unlovable",
	"Life is full of opportunities",
	"I don’t have what it takes",
	"I should have a proper job",
	"Everyone I meet wishes me well",
	"I am worthy of my dreams and desires",
	"I don’t have enough time",
	"I’m just not good with money"
];

const CONTAINER_SIZE: [ width: number, height: number ] = [ 800, 450 ]; // 980 600
const UPDATE_POSITION_TIMER: number = 4000;

function WhatBeliefsWouldYouRatherHave({ ...props }: IWhatBeliefsWouldYouRatherHaveProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const bubblesBoxRef = useRef<HTMLDivElement>(null);

	const [ currentItem, setCurrentItem ] = useState<string | null>(null);
	const [ isWaterDrop, setIsWaterDrop ] = useState<boolean>(false);
	const [ bubbles, setBubbles ] = useState<BubbleType[]>([]);
	const [ boards, setBoards ] = useState<IBoards>(() =>
	{
		const boards = getLocalStorage<IBoards>("mission-seven-WhatBeliefsWouldYouRatherHave-boards");

		return (boards !== null ? boards : { bubble: [], items: ITEMS, "water-drip": [] });
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-WhatBeliefsWouldYouRatherHave-boards", boards);

		if (boards.items.length === 0)
		{

			missionContext.setStep(4);
		}
		else if (boards.bubble.length > bubbles.length)
		{
			setBubbles((prevState) =>
			{
				let bubbles: BubbleType[] = deepClone(prevState);

				for (let i = bubbles.length; bubbles.length < boards.bubble.length; i++)
				{
					const size = randomInt(75, 90);

					bubbles.push([
						size,
						{
							left: randomInt(0, CONTAINER_SIZE[ 0 ] - size) + "px",
							top: randomInt(0, CONTAINER_SIZE[ 1 ] - size) + "px"
						}
					]);
				}

				return bubbles;
			});
		}

	}, [ boards, missionContext, bubbles.length ]);

	useEffect(() =>
	{
		const timerId = setInterval(animateBubbles, UPDATE_POSITION_TIMER - 100);

		animateBubbles();

		function animateBubbles()
		{
			if (bubblesBoxRef.current === null)
			{
				return;
			}

			const { width, height } = bubblesBoxRef.current.getBoundingClientRect();

			setBubbles((prevState) =>
			{
				let bubbles: BubbleType[] = deepClone(prevState);

				bubbles = bubbles.map((item) =>
				{
					item[ 1 ] =
					{
						left: randomInt(0, width - item[ 0 ] * 0.9) + "px",
						top: randomInt(0, height - item[ 0 ] * 0.9) + "px"
					};

					return item;
				});

				return bubbles;
			});
		}

		return () => clearInterval(timerId);

	}, [ bubbles.length ]);

	// Utils
	function highlightDrop(element: HTMLDivElement, show: boolean)
	{
		element.classList[ show ? "add" : "remove" ]("--glow");
	}

	// Handles
	function onDragStart(item: string)
	{
		setCurrentItem(item);
	}

	function onDragEnter({ currentTarget }: DragEvent<HTMLDivElement>)
	{
		highlightDrop(currentTarget, true);
	}

	function onDragLeave({ currentTarget }: DragEvent<HTMLDivElement>)
	{
		highlightDrop(currentTarget, false);
	}

	function onDragOver(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	function onDrop(board: keyof IBoards)
	{
		if (currentItem === null)
		{
			return;
		}

		const boardsClone: IBoards = deepClone(boards);

		const currentItemIndex = boardsClone.items.findIndex((item) => item === currentItem);
		if (currentItemIndex === -1)
		{
			return;
		}

		boardsClone[ board ].push(currentItem);
		boardsClone.items.splice(currentItemIndex, 1);

		setBoards(boardsClone);
		setCurrentItem(null);

		if (board === "water-drip")
		{
			setIsWaterDrop(true);
			setTimeout(() => setIsWaterDrop(false), 400);
		}
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatBeliefsWouldYouRatherHave_title" defaultValue="What beliefs would you rather have?" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatBeliefsWouldYouRatherHave_subTitle" defaultValue="{NAME}, turn beliefs you’d like to keep into bubbles\nand drop the ones you want to let go of into the water." position="left" isDescription />}
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "30px",
					height: { xl: "600px", lg: "520px", xs: "438px" },
					position: "relative"
				}}>
					{/* Bubbles */}
					<Box
						ref={bubblesBoxRef}
						sx={{
							width: "80%",
							height: "450px",
							position: "absolute",
							pointerEvents: "none",
							zIndex: 2,

							"& > img":
							{
								transition: `linear ${ UPDATE_POSITION_TIMER }ms left, linear ${ UPDATE_POSITION_TIMER }ms top`,
								zIndex: 2
							}
						}}
					>
						{bubbles.map(([ size, position ], index) =>
						{
							return (
								<Box
									key={index}
									component="img"
									src={IMGBubble}
									alt={"Bubble " + index}
									sx={{
										"--size": { xl: size + "px", lg: size * 0.9 + "px", xs: size * 0.9 + "px" },
										position: "absolute",
										maxWidth: "var(--size)",
										maxHeight: "var(--size)",
										...position
									}}
								/>
							);
						})}
					</Box>

					<Box
						component={SVGBubble}
						onDrop={() => onDrop("bubble")}
						onDragOver={onDragOver}
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						sx={{
							"--size": { xl: "102px", lg: "81px", xs: "67px" },

							width: "var(--size)",
							height: "var(--size)",
							position: "relative",
							zIndex: 5,

							"& path": { fillOpacity: 0.7 },
							"&.--glow path": { fillOpacity: 1 }
						}}
					/>

					<Box sx={{
						position: "relative",
						zIndex: 5,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						flexWrap: "wrap",
						gap: { xl: "24px", lg: "16px", xs: "16px" }
					}}>
						{boards.items.map((item) => (
							<Box
								key={item}
								sx={{
									font: "400 16px/22px 'Open Sans'",
									color: "primary.dark",
									backgroundColor: alpha("#FFFFFF", 0.5),
									boxShadow: "0px 3px 6px " + alpha("#000000", 0.161),
									borderRadius: "40px",
									padding: "9px 16px",
									cursor: "grab"
								}}
								draggable
								onDragStart={() => onDragStart(item)}
							>
								{item}
							</Box>
						))}
					</Box>

					<Box
						component={SVGWaterDrip}
						className={"water-drip" + (isWaterDrop ? " water-drip--drop" : "")}
						onDrop={() => onDrop("water-drip")}
						onDragOver={onDragOver}
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						sx={{
							width: "122px",
							height: "110px",
							position: "relative",
							zIndex: 5,

							"& path": { fillOpacity: 0.7 },
							"& .water-drip__circle-3": { fillOpacity: 0, transition: "linear 200ms fill-opacity" },
							"&.water-drip--drop .water-drip__circle-3": { fillOpacity: 1 },
							"&.--glow path:not(.water-drip__circle-3)": { fillOpacity: 1 }
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}

export default forwardRef(WhatBeliefsWouldYouRatherHave);
