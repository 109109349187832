import ContentAdmin from "components/contentAdmin";
import { useLayoutEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import IMGBackground from "./../assets/images/background.png";
import CreateCluster from "./CreateCluster";
import ListEntry from "./ListEntry";
import TimeToPriorities from "./TimeToPriorities";

export interface ICreateProps
{
	updateItems(): void;
}

function Create({ updateItems }: ICreateProps)
{
	const navigate = useNavigate();
	const [ step, setStep ] = useState<number>(() =>
	{
		const store = getLocalStorage<number>("dashboard-create-yearning-step");

		return store ?? 1;
	});

	// Utils
	function renderStep()
	{
		switch (step)
		{
			case 1: return <ListEntry nextStep={nextStep} />;
			case 2: return <CreateCluster nextStep={nextStep} />;
			case 3: return <TimeToPriorities nextStep={nextStep} />;
			case 4: {
				localStorage.removeItem("dashboard-create-yearning-cluster");
				localStorage.removeItem("dashboard-create-yearning-list");
				localStorage.removeItem("dashboard-create-yearning-step");
				updateItems();

				return <Navigate to="/dashboard/artifacts/yearning" />;
			}

			default: return null;
		}
	}

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-create-yearning-step", step);
	}, [ step ]);

	// Handles
	function nextStep()
	{
		setStep((prevState) => prevState + 1);
	}

	// Render
	return (
		<ArtifactsContainer
			backgroundImage={IMGBackground}
			title={<ContentAdmin position="top-start" defaultValue="My New Yearnings" keyContent="contentDashboard" keyText="artifacts_mission3_create_title" />}
			onClose={() => navigate("/dashboard/artifacts/yearning")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			{renderStep()}
		</ArtifactsContainer>
	);
}

export default Create;
