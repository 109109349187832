import { Box, CircularProgress, Fade, alpha, keyframes } from "@mui/material";
import Api from "api";
import { IGetDataMissionTwo } from "api/Missions";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import ChipStyled from "components/ChipStyled";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { DragEvent, KeyboardEvent, useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import deepClone from "utils/deepClone";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Recommendation from "./Recommendation";
import IMGBackground from "./assets/images/background.png";

const fadeOpacity = keyframes`
	0% { opacity: 1; }
	50% { opacity: .7; }
	100% { opacity: 1; }
`;

const VALUE_MAX_LENGTH = 20;

function MyValues()
{
	const navigate = useNavigate();
	const params = useParams();

	const inputRef = useRef<HTMLInputElement>(null);
	const isFetchingRef = useRef<boolean>(false);
	const [ isSubmitFetch, setIsSubmitFetch ] = useState<boolean>(false);

	const [ data, setData ] = useState<IGetDataMissionTwo | null>(null);
	const [ newValue, setNewValue ] = useState<string>("");

	const [ currentValue, setCurrentValue ] = useState<string | null>(null);

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getDataMissionTwo()
			.then((data) =>
			{
				setData(data);
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetchingRef.current = false));
	}, []);

	// Handles
	function onDelete(value: string)
	{
		return () =>
		{
			if (data === null || isSubmitFetch === true)
			{
				return;
			}

			const valueIndex = data.values.indexOf(value);

			if (valueIndex === -1)
			{
				return;
			}

			setIsSubmitFetch(true);

			const dataClone = deepClone(data);

			dataClone.values.splice(valueIndex, 1);

			Api.missions
				.setDataMissionTwo(dataClone, true)
				.then(() =>
				{
					setData(dataClone);
				})
				.catch((error) => console.error(error))
				.finally(() => setIsSubmitFetch(false));
		};
	}

	function onKeyDownNewValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter" || isSubmitFetch === true || data === null)
		{
			return;
		}

		const value = newValue.trim();

		if (data.values.findIndex((item) => item.trim().toLowerCase() === value.toLowerCase()) !== -1)
		{
			return setNewValue("");
		}

		if (value.length < 2 || value.length > VALUE_MAX_LENGTH)
		{
			// ? Added notification?
			return;
		}

		setIsSubmitFetch(true);

		const dataCloned = deepClone(data);

		dataCloned.values.push(value);

		Api.missions
			.setDataMissionTwo(dataCloned, true)
			.then(() =>
			{
				setData(dataCloned);
				setNewValue("");

				window.requestAnimationFrame(() => inputRef.current?.focus());
			})
			.catch((error) => console.error(error))
			.finally(() => setIsSubmitFetch(false));
	}

	// Drag n Drop
	function onDragStart(value: string)
	{
		return () =>
		{
			setCurrentValue(value);
		};
	}

	function onDragOver(event: DragEvent<HTMLLIElement>)
	{
		event.preventDefault();
	}

	function onDrop(value: string)
	{
		return (event: DragEvent<HTMLLIElement>) =>
		{
			event.stopPropagation();

			if (currentValue === null || data === null || isSubmitFetch === true)
			{
				return;
			}

			if (currentValue === value)
			{
				return setCurrentValue(null);
			}

			const valueIndex = data.values.indexOf(value);
			const currentValueIndex = data.values.indexOf(currentValue);

			if (valueIndex === -1 || currentValueIndex === -1)
			{
				return setCurrentValue(null);
			}

			const newData = deepClone(data);

			newData.values.splice(currentValueIndex, 1);
			newData.values.splice(valueIndex, 0, currentValue);

			setIsSubmitFetch(true);
			setCurrentValue(null);

			Api.missions
				.setDataMissionTwo(newData, true)
				.then(() =>
				{
					setData(newData);
				})
				.catch((error) => console.error(error))
				.finally(() => setIsSubmitFetch(false));
		};
	}

	// Render
	switch (params.action)
	{
		case "recommendation": return (<Recommendation data={data} />);
		case undefined: break;
		default: return (<Navigate to="/dashboard/artifacts/my-values" />);
	}

	return (
		<ArtifactsContainer
			title="My Values"
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			backgroundImage={IMGBackground}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "#215C75",
					gridColumn: "2 span"
				},
				"& .ArtifactsContainer-main":
				{
					alignItems: "flex-start",
					gridTemplateColumns: "auto 1fr",
					gap: "32px 40px"
				}
			}}
		>
			<Box sx={{
				width: { xl: "468px", lg: "276px", xs: "276px" }
			}}>
				<Box sx={{
					padding: { xl: "40px 64px 98px 64px", lg: "40px 64px 52px 64px", xs: "40px 64px 52px 64px" },
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
					backdropFilter: "blur(30px)",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				}}>
					<Box sx={{
						color: "primary.main",
						font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						textAlign: "center",
						marginBottom: "52px"
					}}>
						My top 5 Values
					</Box>
					{data === null
						? (
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
								<Box sx={{ color: "#215C75", font: "700 24px/40px Lora-Bold", animation: `1.5s linear ${ fadeOpacity } infinite` }}>
									Loading
								</Box>
								<CircularProgress size={30} />
							</Box>
						)
						: (
							<Fade in timeout={500}>
								<ol style={{ margin: "unset", paddingLeft: "30px" }}>
									{data.values.slice(0, 5).map((value, index) => (
										<Box
											component="li"
											key={index}
											sx={{
												color: "primary.dark",
												font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
												textTransform: "capitalize",

												"& div":
												{
													width: "100%",
													overflow: "hidden",
													textOverflow: "ellipsis"
												}
											}}
										>
											<TooltipStyled title={value} placement="right" arrow>
												<div>{value}</div>
											</TooltipStyled>
										</Box>
									))}
								</ol>
							</Fade>
						)
					}
				</Box>

				<Fade in={data !== null} timeout={500}>
					<CustomButton.Contained
						onClick={() => navigate("/dashboard/artifacts/my-values/recommendation")}
						sx={{
							width: "100%",
							marginTop: "88px"
						}}
					>
						Recommendations
					</CustomButton.Contained>
				</Fade>
			</Box>

			<Box sx={{
				height: "100%",
				padding: "40px 56px 32px 56px",
				background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
				boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
				backdropFilter: "blur(30px)",
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "24px",

				display: "grid",
				gridTemplateRows: "auto 1fr auto",
				gap: { xl: "52px", lg: "32px", xs: "32px" }
			}}>
				<Box sx={{ color: "primary.main", font: "700 24px/31px Lora-Bold", textAlign: "center" }}>
					All Values
				</Box>

				{data === null
					? (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
							<Box sx={{ color: "#215C75", font: "700 24px/40px Lora-Bold", animation: `1.5s linear ${ fadeOpacity } infinite` }}>
								Loading
							</Box>
							<CircularProgress size={30} />
						</Box>
					)
					: (
						<Fade in timeout={500}>
							<Box
								className="customScroll"
								component="ol"
								sx={{
									height: { xl: "426px", lg: "322px", xs: "322px" },
									overflowY: "auto",
									margin: "0 -16px 0 0",
									padding: { xl: "0 16px 6px 32px", lg: "0 16px 6px 22px", xs: "0 16px 6px 22px" },

									display: "grid",
									gridTemplateColumns:
									{
										xl: "repeat(5, calc(20% - 40px))",
										lg: "repeat(4, calc(25% - 28px))",
										xs: "repeat(4, 1fr)"
									},
									alignItems: "center",
									alignContent: "flex-start",
									gap: { xl: "32px 48px", lg: "24px 32px", xs: "24px 32px" },

									"& li":
									{
										whiteSpace: "nowrap",
										color: "primary.dark",
										font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										cursor: "grab"
									},
									"& .MuiChip-root":
									{
										cursor: "grab",
										height: "unset",
										minHeight: "40px"
									},
									"& .MuiChip-label":
									{
										textTransform: "capitalize"
										// wordBreak: "break-word",
										// whiteSpace: "normal"
									}
								}}
							>
								{data.values.map((value) => (
									<li
										key={value}
										draggable={isSubmitFetch === false && isFetchingRef.current === false}
										onDragStart={onDragStart(value)}
										onDragOver={onDragOver}
										onDrop={onDrop(value)}
									>
										<TooltipStyled title={value} placement="left" arrow>
											<ChipStyled
												label={value}
												onDelete={onDelete(value)}
											/>
										</TooltipStyled>
									</li>
								))}
							</Box>
						</Fade>
					)
				}

				<CustomInput.Base
					autoFocus
					placeholder="New value"
					data-tut="myValues-input"
					inputRef={inputRef}
					inputProps={{ maxLength: VALUE_MAX_LENGTH }}
					disabled={isSubmitFetch === true || isFetchingRef.current === true}
					value={newValue}
					onChange={({ target }) => setNewValue(target.value)}
					onKeyDown={onKeyDownNewValues}
					sx={{
						justifySelf: "center",
						width: "372px"
					}}
					endAdornment={(isSubmitFetch === true || isFetchingRef.current === true) && (
						<CircularProgress sx={{ fontSize: "18px" }} />
					)}
				/>
			</Box>
		</ArtifactsContainer>
	);
}

export default MyValues;
