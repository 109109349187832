import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Ref, forwardRef } from "react";

export interface IImageButtonProps
{
	value: JSX.Element | string;
	text?: JSX.Element | string;
	image: string;
	/**
	 * @default 100
	 */
	size?: number;
	blur?: boolean;
	posTop?: string;
	posLeft?: string;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

const DEFAULT_SIZE: number = 100;

function ImageButton(
	{
		value,
		text,
		image,
		size = DEFAULT_SIZE,
		posTop,
		posLeft,
		blur,
		sx = [],
		...props
	}: IImageButtonProps,
	ref: Ref<HTMLDivElement>
)
{
	const percent = size / DEFAULT_SIZE;

	return (
		<Box
			className="ImageButton-root"
			sx={[
				{
					// border: '1px solid violet',
					width: size + "px",
					position: "relative",
					top: posTop ? posTop : "0px",
					left: posLeft ? posLeft : "0px",
					cursor: "pointer",
					"&: hover": {
						transition: "linear 300ms transform",
						transform: blur ? "" : "scale(1.5)",

						".ImageButton-image": {
							transition: "linear 300ms transform"
						},
						".ImageButton-value": {
							paddingTop: "0",
							paddingBottom: "0",
							transition: "linear 300ms padding-top, linear 0ms padding-bottom 300ms"
						}
					},
					".ImageButton-value": {
						paddingTop: "0",
						paddingBottom: "0",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 300ms",
						border: "none"
					},
					"&:hover .ImageButton-value": {
						border: "1px solid rgba(255, 255, 255, 0.5);",
						width: "100%",
						paddingTop: 80 * percent + "px",
						paddingBottom: 24 * percent + "px",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 0ms"
					}
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			ref={ref}
			{...props}
		>
			<Box
				className="ImageButton-image"
				sx={{
					position: "relative",
					zIndex: "2",
					backgroundColor: "#E9EDF0",
					backgroundImage: `url(${ image })`,
					backgroundSize: "cover",
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					width: size + "px",
					height: size + "px",
					border: 5 * percent + "px solid #E9EDF0",
					borderRadius: "50%",
					boxShadow: "-3px -3px 7px 0px #FFFFFFCC, 3px 3px 7px 0px #283F493D",
					transition: "linear 300ms width, linear 0ms height 300ms"
				}} />
			{blur
				? <Box sx={{
					position: "absolute",
					top: 0,
					left: 0,
					border: 5 * percent + "px solid #E9EDF0",
					borderRadius: "50%",
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.395) 9.99%, rgba(239, 239, 239, 0.11) 91.61%)",
					width: size + "px",
					height: size + "px",
					zIndex: 3,
					backdropFilter: "blur(5px)"
				}} />
				: null}
			{!blur &&
				(
					<Box
						className="ImageButton-value"
						sx={{
							position: "absolute",
							zIndex: "1",
							top: 23 * percent + "px",
							left: "0",
							right: "0",
							background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
							padding: `${ 80 * percent / 1.5 }px ${ 10 * percent / 1.5 - 1 }px 0px ${ 10 * percent / 1.5 - 1 }px`,
							color: "#215C75",
							font: `700 ${ 18 * percent / 1.5 }px/${ 23 * percent / 1.5 }px Lora-Bold`,
							// font: `700 18px/23px Lora`,
							textAlign: "center",
							border: "1px solid #FFFFFF80",
							borderRadius: 24 * percent + "px",
							boxShadow: "0px 5px 10px 0px #A7C7E199",
							backdropFilter: "blur(25px)"
						}}
					>
						{value}
						<Typography
							sx={{
								font: `400 ${ 16 * percent / 1.5 }px/${ 21 * percent / 1.5 }px 'Open Sans'`,
								color: "#4D4D4D",
								textAlign: "center",
								marginTop: "8px"
							}}
						> {text}
						</Typography>
					</Box>
				)}
		</Box>
	);
}

export default forwardRef(ImageButton);
