import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Masonry } from "@mui/lab";
import { alpha, Box, CircularProgress, Grow, Zoom } from "@mui/material";
import { ReactComponent as SVGExpandArrows } from "assets/images/icons/expand-arrows.svg";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import CustomInput from "components/CustomInput";
import PanelTitle from "components/missions/PanelTitle";
import TooltipStyled from "components/TooltipStyled";
import { createRef, DragEvent, forwardRef, KeyboardEvent, Ref, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import throttle from "utils/throttle";
import useMission from "../../hooks/useMission";
import searchImages from "./searchImages";

const OFFSET_MARGIN_BOTTOM: number = 300;
const DEFAULT_SEARCH_TERMS: string = "small house in the mountains";
const MINIMUM_SELECT_IMAGES: number = 1;

const YourVisionBoard = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	const [ currentLink, setCurrentLink ] = useState<string | null>(null);
	const [ showBoard, setShowBoard ] = useState<1 | 2 | null>(null);
	const [ images, setImages ] = useState<{ description: string; image: string; }[]>([]);
	const [ selectedImages, setSelectedImages ] = useState<string[]>(() =>
	{
		const images = getLocalStorage<string[]>("mission-six-selectedImages");

		return (images === null ? [] : images);
	});
	const [ search, setSearch ] = useState<string>("");
	const [ pageInfo, setPageInfo ] = useState<{ page: number; total_pages: number; }>({ page: 1, total_pages: 1 });
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const imagesBoxRef = createRef<HTMLDivElement>();

	const columns: number = (showBoard === null ? 3 : 6);

	// Effects
	// Effects: Init images load
	useEffect(() =>
	{
		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		searchImages({ query: search || DEFAULT_SEARCH_TERMS })
			.then((data) =>
			{
				setPageInfo({ page: 1, total_pages: data.total_pages });
				setImages(data.results);
			})
			.finally(() => setIsFetch(false));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Effects: Save selected images
	useEffect(() =>
	{
		setLocalStorage("mission-six-selectedImages", selectedImages);
	}, [ selectedImages ]);

	// Handles
	function toggleSelectImage(imageSrc: string)
	{
		const imageIndex = selectedImages.findIndex((image) => image === imageSrc);

		setSelectedImages((prevState) =>
		{
			const state = [ ...prevState ];

			if (imageIndex === -1)
			{
				state.push(imageSrc);
			}
			else
			{
				state.splice(imageIndex, 1);
			}

			return state;
		});
	}

	function nextStep()
	{
		missionContext.nextStep();
	}

	function onSearch()
	{
		if (isFetch === true || search.trim() === "")
		{
			return;
		}

		setIsFetch(true);

		searchImages({ query: search.trim() })
			.then((data) =>
			{
				setPageInfo({ page: 1, total_pages: data.total_pages });
				setImages(data.results);
			})
			.finally(() => setIsFetch(false));
	}

	const onScrollThrottle = throttle(() =>
	{
		if (imagesBoxRef.current === null)
		{
			return;
		}

		const containerScrollTop = imagesBoxRef.current.scrollTop;
		const containerHeight = imagesBoxRef.current.clientHeight;
		const scrollHeight = imagesBoxRef.current.scrollHeight;

		if (scrollHeight - containerScrollTop - containerHeight - OFFSET_MARGIN_BOTTOM <= 0)
		{
			if (pageInfo.page === pageInfo.total_pages)
			{
				return;
			}

			const page = pageInfo.page + 1;

			setIsFetch(true);

			searchImages({ query: search || DEFAULT_SEARCH_TERMS, page })
				.then((data) =>
				{
					setPageInfo({ page, total_pages: data.total_pages });
					setImages((images) => [ ...images, ...data.results ]);
				})
				.finally(() => setIsFetch(false));
		}
	}, 500);

	// Drag & Drop
	function onDragStart(item: string)
	{
		setCurrentLink(item);
	}

	function onDragOver(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();
	}

	// function onDragEnter(event: DragEvent<HTMLDivElement>)
	// {
	// 	highlightDrop(event.currentTarget, true);
	// }

	// function onDragLeave(event: DragEvent<HTMLDivElement>)
	// {
	// 	highlightDrop(event.currentTarget, false);
	// }

	function onDrop(event: DragEvent<HTMLDivElement>)
	{
		event.preventDefault();

		if (currentLink === null)
		{
			return;
		}

		if (selectedImages.find((image) => image === currentLink))
		{
			return;
		}

		setSelectedImages((prevState) => [ ...prevState, currentLink ]);

		setCurrentLink(null);
	}

	// Utils
	// function highlightDrop(element: HTMLDivElement, show: boolean)
	// {
	// 	element.classList[ show ? "add" : "remove" ]("--highlight");
	// }

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSix" keyText="yourVisionBoard_title" defaultValue="Your Vision Board" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSix" keyText="yourVisionBoard_subTitle" defaultValue="Woah, love your new look, {NAME}! Now show me what your dream life looks like.\nGo ahead and create a mood board for your dream life." position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: "22px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>
				<Box sx={{
					"--height": { xl: "450px", lg: "416px", xs: "416px" },
					"--gutter": "7px",
					"--columns": columns,
					width: "100%",
					height: "var(--height)",
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: "25px"
				}}>
					<Box sx={[
						{
							border: "1px solid #215C75",
							borderRadius: "24px",
							width: "100%",
							backgroundColor: alpha("#FFFFFF", 0.5),
							height: "var(--height)",
							padding: "10px",
							display: "grid",
							gridTemplateRows: "auto 1fr",
							gap: "20px"
						},
						showBoard === 2 &&
						{
							display: "none"
						},
						showBoard === 1 &&
						{
							gridColumn: "2 span"
						}
					]}>
						{/* Input container */}
						<Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
							{/* Input */}
							<Box sx={{ width: "100%", position: "relative" }}>
								<CustomInput.Base
									fullWidth
									placeholder={"e.g. " + DEFAULT_SEARCH_TERMS}
									onChange={({ target }) => setSearch(target.value)}
									value={search}
									sx={{
										borderRadius: "15px",
										"& .MuiInputBase-input": { padding: "10px 68px 10px 15px" }
									}}
									onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
									{
										if (event.key === "Enter")
										{
											onSearch();
										}
									}}
								/>
								<TransitionGroup>
									{/* Search button */}
									<TooltipStyled
										title={
											isFetch === true
												? "Loading..."
												: search.trim().length < 2
													? "Minimum 2 characters"
													: "Search"
										}
										placement="bottom"
										TransitionComponent={Zoom}
										arrow
									>
										<Box
											component="button"
											type="submit"
											sx={{
												display: "flex",
												padding: "unset",
												border: "none",
												background: "none",

												position: "absolute",
												right: "8px",
												top: { xl: "16px", xs: "12px" },
												cursor: (isFetch === true
													? "wait"
													: search.trim().length < 2
														? "not-allowed"
														: "pointer"
												),
												opacity: (search.trim().length < 2 ? 0.5 : 1)
											}}
											onClick={onSearch}
										>
											{isFetch === true
												? <CircularProgress size={24} />
												: <SendIcon color="primary" />
											}
										</Box>
									</TooltipStyled>

									{/* Clear button */}
									{search.trim() !== "" &&
										(
											<Zoom in>
												<TooltipStyled title="Clear" placement="bottom" TransitionComponent={Zoom} arrow>
													<Box
														component="button"
														type="submit"
														sx={{
															display: "flex",
															padding: "unset",
															border: "none",
															background: "none",

															position: "absolute",
															right: "40px",
															top: { xl: "16px", xs: "12px" },
															cursor: (search.trim().length < 2 ? "not-allowed" : "pointer"),
															opacity: (search.trim().length < 2 ? 0.5 : 1)
														}}
														onClick={() => setSearch("")}
													>
														<CloseIcon color="primary" />
													</Box>
												</TooltipStyled>
											</Zoom>
										)
									}
								</TransitionGroup>
							</Box>

							<Box
								component={(showBoard === null ? SVGExpandArrows : CancelIcon)}
								sx={{
									"--size": { xl: "24px", lg: "18px", xs: "18px" },

									marginRight: "10px",
									width: "var(--size)",
									height: "var(--size)",

									color: "primary.main",
									cursor: "pointer",
									opacity: 1,
									transition: "linear 300ms opacity",

									"&:hover":
									{
										opacity: 0.7
									}
								}}
								onClick={() =>
								{
									setShowBoard((prevState) => prevState === 1 ? null : 1);
								}}
							/>
						</Box>

						{/* No results */}
						{images.length === 0 &&
							(
								<Box sx={{ textAlign: "center", margin: "auto", font: "400 22px/32px 'Open Sans'" }}>
									No search results
								</Box>
							)
						}
						{/* Found results */}
						{images.length > 0 &&
							(
								<Box
									ref={imagesBoxRef}
									className="customScroll"
									onScroll={() => onScrollThrottle()}
									sx={{
										overflowY: "scroll",
										borderRadius: "15px 0 0 15px",
										paddingRight: "10px",
										"& .MuiMasonry-root":
										{
											paddingRight: "calc(var(--gutter) * (var(--columns) - 1))", // gutter * (columnCount - 1)
											margin: "calc(0px - (var(--gutter) / 2))"
										},
										"& .MuiMasonry-root > *":
										{
											borderRadius: "15px",
											overflow: "hidden",
											margin: "calc(var(--gutter) / 2)"
										}
									}}
								>
									<Masonry spacing="7px" columns={columns}>
										{images.map((imageData, index) => (
											<Grow key={index + imageData.image} in>
												<Box
													draggable={showBoard === null}
													onDragStart={() => onDragStart(imageData.image)}
													sx={{ position: "relative", "&:hover button": { opacity: 1 } }}
												>
													<Box component="img" src={imageData.image} alt={imageData.description} sx={{ width: "100%", height: "100%" }} />

													{showBoard === 1 &&
														(
															<Box
																component="button"
																onClick={() => toggleSelectImage(imageData.image)}
																sx={{
																	transition: "linear 300ms opacity",
																	opacity: 0,
																	position: "absolute",
																	top: "5px",
																	right: "5px",
																	border: "none",
																	borderRadius: "15px",
																	fontSize: "14px",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																	width: "30px",
																	height: "30px",
																	backdropFilter: "blur(10px)",
																	backgroundColor: alpha("#FFFFFF", 0.5),
																	boxShadow: "inset 0px 0px 5px rgba(0, 0, 0, 0.5)",
																	"&:hover": { backgroundColor: alpha("#FFFFFF", 0.7), cursor: "pointer" }
																}}
															>
																{(selectedImages.find((image) => image === imageData.image) === undefined
																	? <AddIcon fontSize="small" color="primary" />
																	: <DeleteIcon fontSize="small" color="primary" />
																)}
															</Box>
														)
													}
												</Box>
											</Grow>
										))}
									</Masonry>
								</Box>
							)}
					</Box>

					<Box sx={[
						{
							position: "relative",
							border: "1px solid " + alpha("#FFFFFF", 0.5),
							boxShadow: "0px 5px 20px " + alpha("#7EC4FF66", 0.4),
							borderRadius: "24px",
							width: "100%",
							backgroundColor: alpha("#FFFFFF", 0.5),
							height: "var(--height)",
							padding: "22px 25px",
							display: "grid",
							gridTemplateRows: "auto 1fr",
							gap: "20px"
						},
						showBoard === 1 &&
						{
							display: "none"
						},
						showBoard === 2 &&
						{
							gridColumn: "2 span"
						}
					]}>
						<Box sx={{
							width: "100%",
							position: "relative",
							font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
							color: "primary.main",
							textAlign: "center"
						}}>
							My Vision Board

							<Box
								component={(showBoard === null ? SVGExpandArrows : CancelIcon)}
								sx={{
									"--size": { xl: "24px", lg: "18px", xs: "18px" },

									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									right: "5px",
									color: "primary.main",
									cursor: "pointer",
									opacity: 1,
									transition: "linear 300ms opacity",

									width: "var(--size)",
									height: "var(--size)",

									"&:hover":
									{
										opacity: 0.7
									}
								}}
								onClick={() =>
								{
									setShowBoard((prevState) => prevState === 2 ? null : 2);
								}}
							/>
						</Box>

						<Box
							className="customScroll"
							sx={{
								"--gutter": "7px",
								position: "relative",
								overflowY: "auto",
								borderRadius: "15px 0 0 15px",
								paddingRight: "10px",
								// "&.--highlight::before":
								// {
								// 	font: "700 24px/31px Lora",
								// 	color: "primary.main",
								// 	zIndex: 2,
								// 	content: "'Drop to here'",
								// 	position: "absolute",
								// 	top: 0,
								// 	bottom: 0,
								// 	left: 0,
								// 	right: 0,
								// 	backgroundColor: alpha("#FFFFFF", 0.5),
								// 	backdropFilter: "blur(5px)",
								// 	display: "flex",
								// 	justifyContent: "center",
								// 	alignItems: "center",
								// 	borderRadius: "15px 0 0 15px",
								// 	border: "2px dashed #215C75"
								// },
								"& .MuiMasonry-root":
								{
									paddingRight: "calc(var(--gutter) * (var(--columns) - 1))", // gutter * (columnCount - 1)
									margin: "calc(0px - (var(--gutter) / 2))"
								},
								"& .MuiMasonry-root > *":
								{
									borderRadius: "15px",
									overflow: "hidden",
									margin: "calc(var(--gutter) / 2)"
								}
							}}
							onDrop={onDrop}
							onDragOver={onDragOver}
						// onDragEnter={onDragEnter}
						// onDragLeave={onDragLeave}
						>
							<Masonry spacing="7px" columns={columns}>
								{selectedImages.map((imageSrc, index) => (
									<Grow key={index + imageSrc} in>
										<Box sx={{ position: "relative", "&:hover button": { opacity: 1 } }}>
											<Box component="img" src={imageSrc} alt="" sx={{ width: "100%", height: "100%" }} />
											<Box
												component="button"
												onClick={() => toggleSelectImage(imageSrc)}
												sx={{
													transition: "linear 300ms opacity",
													opacity: 0,
													position: "absolute",
													top: "5px",
													right: "5px",
													border: "none",
													borderRadius: "15px",
													fontSize: "14px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													width: "30px",
													height: "30px",
													backdropFilter: "blur(10px)",
													backgroundColor: alpha("#FFFFFF", 0.5),
													boxShadow: "inset 0px 0px 5px rgba(0, 0, 0, 0.5)",
													"&:hover": { backgroundColor: alpha("#FFFFFF", 0.7), cursor: "pointer" }
												}}
											>
												<DeleteIcon fontSize="small" color="primary" />
											</Box>
										</Box>
									</Grow>
								))}
							</Masonry>
						</Box>
					</Box>
				</Box>

				<CustomButton.Contained
					sx={{ display: "flex", margin: "5px auto 0 auto" }}
					onClick={nextStep}
					disabled={selectedImages.length < MINIMUM_SELECT_IMAGES}
				>
					I am done
				</CustomButton.Contained>
			</Box>
		</Box>
	);
});

export default YourVisionBoard;
