import { Box, BoxProps, alpha } from "@mui/material";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import { ReactComponent as SVGExpandArrows } from "assets/images/icons/size-24/expand-arrows.svg";
import { ReactNode } from "react";

export interface IArtifactsContainerProps
{
	title: ReactNode;
	aside?: ReactNode;
	backgroundImage?: string;
	headerRight?: ReactNode;
	onClose?(): void;
	onExpand?(): void;
}

function ArtifactsContainer(
	{
		title,
		aside,
		children,
		backgroundImage,
		headerRight,
		maxWidth = "",
		onClose,
		onExpand,
		sx = [],
		className = "",
		...props
	}: IArtifactsContainerProps & Omit<BoxProps, "title">
)
{
	return (
		<Box
			className={"ArtifactsContainer-root " + className}
			sx={[
				{
					marginLeft: { xl: "32px", lg: "24px", md: "24px" },
					maxWidth: maxWidth ? maxWidth : { xl: "1760px", lg: "1165px", md: "914px" },

					"& .ArtifactsContainer-header":
					{
						display: "flex",
						alignItems: "center",
						alignSelf: "flex-start",
						gap: { xl: "24px", lg: "16px", xs: "16px" },
						width: "100%",
						color: (backgroundImage !== undefined ? "#FFFFFF" : "#215C75")
					},
					"& .ArtifactsContainer-header__title":
					{
						flexGrow: 1,
						color: "inherit",
						font: { xl: "700 40px/51px Lora-Bold", xs: "700 32px/40px Lora-Bold" }
					},
					"& .ArtifactsContainer-header__right":
					{
						display: "flex",
						alignItems: "center",
						gap: { xl: "18px", xs: "8px" },
						font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" }
					},
					"& .ArtifactsContainer-closeButton":
					{
						width: { xl: "61px", xs: "49px" },
						height: { xl: "40px", xs: "32px" },
						opacity: 1,
						cursor: "pointer",
						transition: "linear 200ms opacity",
						color: "inherit",

						"& path":
						{
							fillOpacity: 1,
							fill: "currentColor"
						},
						"&:hover":
						{
							opacity: 0.7
						}
					},
					"& .ArtifactsContainer-expandButton":
					{
						"--size": { xl: "24px", xs: "16px" },
						background: "unset",
						border: "unset",
						padding: "unset",
						cursor: "pointer",
						width: "var(--size)",
						height: "var(--size)",
						color: "inherit",
						transition: "linear 200ms opacity",

						"& svg":
						{
							width: "var(--size)",
							height: "var(--size)"
						},
						"&:hover":
						{
							opacity: 0.7
						}
					},
					"& .ArtifactsContainer-content":
					{
						display: "flex",
						position: "relative",
						width: "100%",
						height: { xl: "860px", lg: "676px", md: "570px" },
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						background: `url(${ backgroundImage }) center center/cover`,
						backgroundColor: alpha("#FFFFFF", 0.5),
						backdropFilter: "blur(30px)",
						border: "2px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px"
					},
					"& .ArtifactsContainer-main":
					{
						padding: { xl: "40px 48px", xs: "32px 40px" },
						flexGrow: 1,
						display: "grid",
						gap: "32px",
						gridTemplateRows: "auto 1fr"
					},
					"& .ArtifactsContainer-aside":
					{
						display: "flex",
						flexDirection: "column",
						flexShrink: 0,
						width: { xl: "652px", xs: "416px" },
						height: "100%",
						padding: { xl: "62px 72px 40px 72px", xs: "24px 32px 32px 32px" },
						background: alpha("#FFFFFF", 0.5),
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						backdropFilter: "blur(30px)",
						borderRadius: "0 22px 22px 0",
						font: "400 22px/30px 'Open Sans'",
						color: "primary.dark",

						"& .MuiButton-root":
						{
							minWidth: "unset",
							width: "max-content",
							padding: { xl: "12px 80px", xs: "7px 63px" },
							margin: "0 auto",
							minHeight: "unset",
							height: { xl: "56px", xs: "40px" },
							font: { xl: "700 24px/33px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" }
						}
					}
				},
				(backgroundImage !== undefined) &&
				{
					"& .ArtifactsContainer-content":
					{
						border: "unset"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Box className="ArtifactsContainer-content">
				<Box className="ArtifactsContainer-main">
					<Box component="header" className="ArtifactsContainer-header">
						{typeof onClose === "function" && <SVGArrowBack className="ArtifactsContainer-closeButton" onClick={onClose} />}

						<Box className="ArtifactsContainer-header__title">
							{title}
						</Box>

						<Box className="ArtifactsContainer-header__right">
							{headerRight}

							{typeof onExpand === "function" &&
								(
									<Box component="button" className="ArtifactsContainer-expandButton" onClick={onExpand}>
										<SVGExpandArrows className="ArtifactsContainer-expandButton__icon" />
									</Box>
								)
							}
						</Box>
					</Box>

					{children}
				</Box>

				{aside !== undefined &&
					(
						<Box component="aside" className="ArtifactsContainer-aside">
							{aside}
						</Box>
					)
				}
			</Box>
		</Box>
	);
}

export default ArtifactsContainer;
