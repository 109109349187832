import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Panel = styled(
	Box,
	{
		target: "mission-panel"
	}
)(
	{
		background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
		border: "1px solid rgba(255, 255, 255, .5)",
		borderRadius: "24px",
		boxShadow: "0px 5px 10px rgba(126, 196, 255, .4)",
		backdropFilter: "blur(30px)",
		width: "100%"
	}
);

export default Panel;
