import { ReactNode } from "react";

interface ITabPanelProps
{
	children: ReactNode;
	value: string;
	active: string;
	index: number | string;
	[ propName: string ]: any;
}

export default function TabPanel({ children, value, active, index, ...other }: ITabPanelProps)
{
	return (
		<div
			role="tabpanel"
			hidden={value !== active}
			id={`tabs-router-${ index }`}
			aria-labelledby={`tabs-router-${ index }`}
			{...other}
		>
			{value === active && (
				<>
					{children}
				</>
			)}
		</div>
	);
}

export function a11yProps(index: number | string): { id: string; "aria-controls": string; }
{
	return { id: "tabs-router-" + index, "aria-controls": "tabs-router-" + index };
}
