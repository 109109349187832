import { Box, alpha, keyframes } from "@mui/material";
import Api from "api";
import IMGOcta from "assets/images/octa.png";
import ArrowButton from "components/ArrowButton";
import ChipStyled from "components/ChipStyled";
import FlippingCard from "components/FlippingCard";
import ContentAdmin from "components/contentAdmin";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionTwo/hooks/useMission";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import IMGCard1 from "../../assets/images/card-1.jpg";
import IMGCard2 from "../../assets/images/card-2.jpg";
import IMGCard3 from "../../assets/images/card-3.jpg";
import { ListOfValuesData } from "../ListOfValues";

// export type PracticalRecommendationsType = Record<"title" | "text" | "action", string>;

export type PracticalRecommendationsType = {
	title: string;
	text?: string;
	action?: string;
};

const waveAnimation = keyframes`
	0%
	{
		left: -10%;
		top: -10px;
	}
	50%
	{
		left: 110%;
	}
	80%
	{
		left: 200%;
		top: -10px;
		bottom: 0px;
	}
	100%
	{
		bottom: 200%;
		top: -200%;
	}
`;

const skeletonAnimation = keyframes`
	0%{ transform: translateX(-100%) }
	/* 75% { transform: translateX(100%) } */
	100% { transform: translateX(100%) }
`;

function PracticalRecommendations()
{
	const isFirstRenderRef = useRef<boolean>(true);
	const missionContext = useMission();
	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ practicalRecommendations, setPracticalRecommendations ] = useLocalStorage<PracticalRecommendationsType[] | null>(
		missionContext.missionPrefix + "-practicalRecommendations",
		null
	);

	const [ isCardClicked, setIsCardClicked ] = useState<[ boolean, boolean, boolean ]>([ false, false, false ]);

	const topValues = useMemo(() =>
	{
		return listOfValues.slice(0, 5);
	}, [ listOfValues ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (isFirstRenderRef.current === false)
		{
			return;
		}

		isFirstRenderRef.current = false;

		if (practicalRecommendations !== null)
		{
			return;
		}

		let attempt = 3;

		fetchRequest();

		function fetchRequest()
		{
			setIsFetch(true);

			Api.bot
				.getResponseBot(
					"Suggest 3 non-generic recommendation for a person based on this list of top 5 personal values. The purpose is to help this person take specific actions and concrete steps to make their life more fulfilling. Speak in simple and engaging manner with some humour. Present as a list with max 500 characters of text and max two sentences. " + topValues.join(", ") + ".",
					"recommendations"
				)
				.then(({ recommendations }) =>
				{
					setPracticalRecommendations(recommendations);
				})
				.catch((error) =>
				{
					console.error(error);

					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}
				})
				.finally(() => setIsFetch(false));
		}
	}, []);

	// Handless
	function onCardClick(index: number)
	{
		setIsCardClicked((prevState) =>
		{
			const state = [ ...prevState ];

			state[ index ] = true;

			return state as typeof prevState;
		});
	}

	console.log("practicalRecommendations", practicalRecommendations);

	// Render
	return (
		<>
			<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						keyContent={missionContext.keyContent}
						keyText="PracticalRecommendations_title"
						defaultValue="Your Practical Recommendations"
						position="right"
					/>}
					sx={{
						marginBottom: { xl: "52px", lg: "64px" },
						marginLeft: { xl: "72px", lg: "80px" },

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={[
					{
						position: "relative",
						width: { xl: "954px", lg: "785px" },
						height: { xl: "491px", lg: "389px" },

						display: "grid",
						gridTemplateColumns: "274px 1fr",
						gridTemplateRows: "1fr 1fr 1fr",
						gap: { xl: "20px 32px", lg: "20px 16px" },

						"& .FlippingCard-root":
						{
							overflow: "hidden",
							borderRadius: "24px",
							pointerEvents: "none",

							"&.waveAnimation::before":
							{
								zIndex: 2,
								animation: waveAnimation + " 3s infinite ease-in-out",
								content: "''",
								position: "absolute",
								top: "-20px",
								bottom: "-20px",
								left: "0",
								width: "35px",
								background: alpha("#FFFFFF", 0.5),
								transform: "rotate(20deg)"
							}
						},
						"& .FlippingCard-card > *":
						{
							padding: "32px 16px",

							background: "linear-gradient(0deg, rgba(62, 128, 157, 0.30), rgba(62, 128, 157, 0.30)), #FFFFFF",
							borderRadius: "24px",

							display: "flex",
							alignItems: "center",
							justifyContent: "center",

							color: "primary.main",
							font: { xl: "700 24px/31px Lora-Bold", lg: "700 20px/26px Lora-Bold" },
							textAlign: "center",

							transition: "linear 500ms background",

							"&:first-child":
							{
								position: "relative",

								"::before":
								{
									animation: skeletonAnimation + " linear 3600ms infinite",
									content: "''",
									background: "linear-gradient(103deg, transparent -5%, rgba(62, 128, 157, 0.3) 20.32%, transparent 51.15%, rgba(62, 128, 157, 0.5) 75.37%, transparent 99.04%)",

									position: "absolute",
									top: 0,
									bottom: 0,
									left: "-100%",
									right: "-100%"
								}
							},
							"&:last-child":
							{
								backgroundColor: alpha("#3E809D", 0.5),
								backdropFilter: "blur(30px)",
								boxShadow: "0px 5px 20px 0px " + alpha("#7ec4ff", 0.4),
								border: "1px solid " + alpha("#FFFFFF", 0.5),

								color: "#FFFFFF",
								font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'" }
							}
						}
					},
					isFetch === false && practicalRecommendations !== null &&
					{

						"& .FlippingCard-card > *::before":
						{
							display: "none"
						},
						"& .FlippingCard-root":
						{
							pointerEvents: "auto"
						},
						"& .FlippingCard-root:nth-of-type(2) .FlippingCard-card :first-child":
						{
							background: `url(${ IMGCard1 }) center center/cover`
						},
						"& .FlippingCard-root:nth-of-type(3) .FlippingCard-card :first-child":
						{
							background: `url(${ IMGCard2 }) center center/cover`
						},
						"& .FlippingCard-root:nth-of-type(4) .FlippingCard-card :first-child":
						{
							background: `url(${ IMGCard3 }) center center/cover`
						}
					}
				]}>
					<Panel sx={{
						padding: { xl: "24px", lg: "24px 16px 24px 16px" },
						gridRow: "3 span"
					}}>
						<Box sx={{
							marginTop: { xl: "52px", lg: "8px" },
							font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold" },
							color: "primary.main",
							textAlign: "center"
						}}>
							Top 5 Values
						</Box>

						<Box
							component="ol"
							sx={{
								counterReset: "list",
								paddingLeft: "unset",
								margin: "unset",
								marginTop: { xl: "40px", lg: "24px" },
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								gap: "16px",

								"& li":
								{
									counterIncrement: "list",
									listStyle: "none",
									display: "flex",
									alignItems: "center",
									gap: "8px",

									"&::before":
									{
										display: "block",
										content: "counter(list) '.'",
										width: "30px",
										flexShrink: 0,
										font: "400 22px/30px 'Open Sans'",
										// font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										textAlign: "right"
									}
								}
							}}
						>
							{topValues.map((value, index) =>
							{
								return (
									<li key={index}>
										<ChipStyled
											label={value}
											sx={{
												textTransform: "capitalize"
											}}
										/>
									</li>
								);
							})}
						</Box>
					</Panel>

					<FlippingCard
						className={isFetch === false && isCardClicked[ 0 ] === false ? "waveAnimation" : ""}
						onClick={() => onCardClick(0)}
					>
						<Box>{practicalRecommendations?.[ 0 ].title}</Box>
						<Box>{practicalRecommendations?.[ 0 ].action || practicalRecommendations?.[ 0 ].text}</Box>
					</FlippingCard>

					<FlippingCard
						className={isFetch === false && isCardClicked[ 1 ] === false ? "waveAnimation" : ""}
						onClick={() => onCardClick(1)}
					>
						<Box>{practicalRecommendations?.[ 1 ].title}</Box>
						<Box>{practicalRecommendations?.[ 1 ].action || practicalRecommendations?.[ 1 ].text}</Box>
					</FlippingCard>

					<FlippingCard
						className={isFetch === false && isCardClicked[ 2 ] === false ? "waveAnimation" : ""}
						onClick={() => onCardClick(2)}
					>
						<Box>{practicalRecommendations?.[ 2 ].title}</Box>
						<Box>{practicalRecommendations?.[ 2 ].action || practicalRecommendations?.[ 2 ].text}</Box>
					</FlippingCard>

					{isFetch === true && (
						<>
							<Box sx={{
								"--size": { xl: "196px" },
								position: "absolute",
								bottom: "24px",
								right: "24px",
								overflow: "hidden",
								width: "var(--size)",
								height: "var(--size)",
								background: `url(${ IMGOcta }) top center, #3E809D`,
								backgroundPosition: "-30px 20px",
								backgroundRepeat: "no-repeat",
								borderRadius: "50%"
							}} />
							<Box sx={{
								position: "absolute",
								bottom: "130px",
								right: "200px"
							}}>
								<Box sx={{
									padding: "24px",
									width: "331px",
									backgroundColor: alpha("#FFFFFF", 0.5),
									boxShadow: "0px 3px 6px 0px " + alpha("#000000", 0.16),
									border: "1px solid " + alpha("#FFFFFF", 0.5),
									borderRadius: "16px",
									color: "primary.dark",
									font: "400 22px/30px 'Open Sans'"
								}}>
									Take a deep breath while we are creating your personalised<br />
									recommendations.
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Box>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={isCardClicked.every((value) => value === true) === false}
					onClick={() => missionContext.nextStep()}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default PracticalRecommendations;
