import { CloseRounded } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Fade, Slide, SlideProps, Snackbar, alpha } from "@mui/material";
import Api from "api";
import AlertStyled from "components/AlertStyled";
import CheckboxStyled from "components/CheckboxStyled";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { useFormik } from "formik";
import { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import * as yup from "yup";
import { RULES } from ".";
import ArtifactsContainer from "../components/ArtifactsContainer";
import Dialog from "../components/Dialog";
import IMGBackground from "./assets/images/background.png";

export interface ICreateProps
{
	updateItems(callback?: () => void, force?: boolean): void;
}

export const MAX_FILED_LENGTH: number = 120;

const fieldYupScheme = yup
	.string()
	.trim()
	.required("This field is required")
	// eslint-disable-next-line no-template-curly-in-string
	.max(MAX_FILED_LENGTH, "You need a maximum of ${max} characters");

function Create({ updateItems }: ICreateProps)
{
	const navigate = useNavigate();
	const firstInitRef = useRef<boolean>(true);
	const formik = useFormik({
		initialValues:
		{
			whatTesting: "",
			definitionSuccess: ""
		},
		onSubmit({ whatTesting, definitionSuccess })
		{
			if (taskList.length === 0)
			{
				return setIsShowAlert(true);
			}

			if (isFetch === true || isFetchRef.current === true)
			{
				return;
			}

			setIsFetch(true);
			isFetchRef.current = true;

			Api.missions
				.setDataMissionTen(
					{
						title,
						whatTesting,
						experiments: taskList,
						definitionSuccess,
						keyInsights: "",
						decision: "",
						like: null
					},
					true
				)
				.then(() =>
				{
					updateItems(() =>
					{
						localStorage.removeItem("dashboard-experiments-create-title");
						localStorage.removeItem("dashboard-experiments-create-taskList");
						localStorage.removeItem("dashboard-experiments-create-whatTesting");
						localStorage.removeItem("dashboard-experiments-create-definitionSuccess");

						navigate("/dashboard/artifacts/experiments");
					}, true);
				})
				.catch((error) => console.error(error))
				.finally(() =>
				{
					setIsFetch(false);
					isFetchRef.current = false;
				});
		},
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: yup.object(
			{
				whatTesting: fieldYupScheme,
				definitionSuccess: fieldYupScheme
			}
		)
	});

	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);
	const [ title, setTitle ] = useState<string>(() =>
	{
		return getLocalStorage<string>("dashboard-experiments-create-title") ?? "";
	});
	const [ taskList, setTaskList ] = useState<{ checked: boolean; value: string; }[]>(() =>
	{
		return getLocalStorage<{ checked: boolean; value: string; }[]>("dashboard-experiments-create-taskList") ?? [];
	});
	const [ newTaskValue, setNewTaskValue ] = useState<string>("");
	const [ newTaskIsChecked, setNewTaskIsChecked ] = useState<boolean>(false);

	const [ isShowChangeTitleModal, setIsShowChangeTitleModal ] = useState<boolean>(false);
	const [ newTitle, setNewTitle ] = useState<string>(title);
	const [ newTitleError, setNewTitleError ] = useState<string | null>(null);

	// Effects
	useLayoutEffect(() =>
	{
		if (firstInitRef.current === false)
		{
			return;
		}

		const whatTesting = getLocalStorage<string>("dashboard-experiments-create-whatTesting");
		const definitionSuccess = getLocalStorage<string>("dashboard-experiments-create-definitionSuccess");

		if (whatTesting !== null)
		{
			formik.setFieldValue("whatTesting", whatTesting);
		}

		if (definitionSuccess !== null)
		{
			formik.setFieldValue("definitionSuccess", definitionSuccess);
		}

		window.requestAnimationFrame(() =>
		{
			if (whatTesting !== null)
			{
				formik.validateField("whatTesting");
			}

			if (definitionSuccess !== null)
			{
				formik.validateField("definitionSuccess");
			}
		});

		firstInitRef.current = false;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() =>
	{
		if (firstInitRef.current === true)
		{
			return;
		}

		const { whatTesting, definitionSuccess } = formik.values;

		setLocalStorage("dashboard-experiments-create-whatTesting", whatTesting);
		setLocalStorage("dashboard-experiments-create-definitionSuccess", definitionSuccess);
	}, [ formik.values ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-experiments-create-title", title);
	}, [ title ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-experiments-create-taskList", taskList);
	}, [ taskList ]);

	// Handles
	function onKeyDownNewTask(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		try
		{
			fieldYupScheme.validateSync(newTaskValue.trim());
		}
		catch (e)
		{
			return;
		}

		setTaskList((prevState) =>
		{
			return [ ...prevState, { checked: newTaskIsChecked, value: newTaskValue.trim() } ];
		});

		setNewTaskIsChecked(false);
		setNewTaskValue("");
	}

	function onDeleteTask(index: number)
	{
		return (event: MouseEvent<HTMLButtonElement>) =>
		{
			event.preventDefault();
			event.stopPropagation();

			setTaskList((prevState) =>
			{
				const state = [ ...prevState ];

				state.splice(index, 1);

				return state;
			});
		};
	}

	function onChangeTaskIsChecked(index: number)
	{
		return (_event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
		{
			setTaskList((prevState) =>
			{
				const state = [ ...prevState ];

				state[ index ].checked = checked;

				return state;
			});
		};

	}

	function onChangeTitle(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let error: string | null = null;

		try
		{
			RULES.title.yupScheme().validateSync(newTitle.trim());
		}
		catch (e)
		{
			error = (e as yup.ValidationError).message;
		}

		setNewTitleError(error);

		if (error !== null)
		{
			return;
		}

		setTitle(newTitle.trim());
		setIsShowChangeTitleModal(false);
	}

	// Render
	return (
		<ArtifactsContainer
			title={title}
			backgroundImage={IMGBackground}
			onClose={() => navigate("/dashboard/artifacts/experiments")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				sx={{
					display: "grid",
					gridTemplateRows: "auto 1fr auto",
					alignContent: "flex-start",
					alignItems: "flex-start",
					justifySelf: "center",
					alignSelf: "center",
					gap: { lg: "16px", xl: "24px" },

					maxHeight: { lg: "539px", xl: "670px" },
					width: { lg: "977px", xl: "1234px" },
					padding: "32px 32px 24px 32px",

					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					boxShadow: "0px 5px 20px" + alpha("#7EC4FF", 0.4),
					backdropFilter: "blur(30px)",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				}}
			>
				<Box sx={{
					display: "flex",
					alignItems: "center",
					gap: "16px",
					color: "primary.main",
					font: { lg: "700 18px/24px Lora-Bold", xl: "700 24px/31px Lora-Bold" }
				}}>
					{title}

					<Box
						component="button"
						sx={{
							width: "24px",
							height: "24px",
							backgroundColor: "#3E809D",
							border: "unset",
							borderRadius: "50%",
							transition: "linear 300ms opacity",
							cursor: "pointer",

							"&:hover":
							{
								opacity: 0.7
							}
						}}
						onClick={() => setIsShowChangeTitleModal(true)}
					>
						<ModeEditIcon sx={{ color: "#FFFFFF", fontSize: "12px" }} />
					</Box>
				</Box>

				<Box
					className="customScroll"
					sx={{
						overflowY: "auto",
						paddingRight: "16px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: { lg: "16px", xl: "24px" },

						"& > label":
						{
							position: "relative",
							display: "flex",
							flexDirection: "column",
							gap: "8px"
						},
						"& .CheckboxStyledWithLabel-content":
						{
							color: "primary.dark",
							font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
						},
						"& > * > span":
						{
							display: "block",
							marginLeft: "16px",
							color: "primary.dark",
							font: { lg: "400 16px/26px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" }
						}
					}}
				>
					<label>
						<span>What do you want to test?</span>

						<CustomInput.Base
							name="whatTesting"
							placeholder="e.g. I like being an artist & it brings income."
							inputProps={{ maxLength: MAX_FILED_LENGTH }}
							value={formik.values.whatTesting}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<Box sx={{
							margin: "-8px 10px 0 10px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{formik.errors.whatTesting}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{formik.values.whatTesting.length}/{MAX_FILED_LENGTH} characters
							</Box>
						</Box>
					</label>

					<div style={{ marginTop: "-19px" }}>
						<span style={{ marginBottom: "16px" }}>What experiments can you run?</span>

						<Box
							component={TransitionGroup}
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",

								"&:not(:empty):not(:last-child)":
								{
									marginBottom: "16px"
								}
							}}
						>
							{taskList.map(({ value, checked }, index) =>
							{
								return (
									<Fade key={index + value} timeout={{ appear: 300, enter: 300, exit: 0 }}>
										<Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
											<CheckboxStyledWithLabel
												label={value}
												checkboxProps={{
													checked,
													onChange: onChangeTaskIsChecked(index)
												}}
											/>

											<TooltipStyled title="Delete" placement="bottom" arrow>
												<Box
													component="button"
													onClick={() => onDeleteTask(index)}
													sx={{
														background: "unset",
														padding: "unset",
														border: "unset",
														cursor: "pointer",

														"&:hover":
														{
															opacity: 0.7
														}
													}}
												>
													<CloseRounded color="primary" sx={{ fontSize: "22px", marginTop: "4px" }} />
												</Box>
											</TooltipStyled>
										</Box>
									</Fade>
								);
							})}
						</Box>

						{taskList.length < 7 &&
							(
								<Fade in={taskList.length < 7} timeout={300}>
									<Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
										<CheckboxStyled
											checked={newTaskIsChecked}
											onChange={(_event, checked) => setNewTaskIsChecked(checked)}
										/>

										<CustomInput.Base
											placeholder="add new task"
											value={newTaskValue}
											onChange={({ target }) => setNewTaskValue(target.value)}
											onKeyDown={onKeyDownNewTask}
											inputProps={{ maxLength: MAX_FILED_LENGTH }}
											sx={{
												background: "unset",
												flexGrow: 1,

												"& .MuiInputBase-input":
												{
													padding: "unset",
													color: "primary.dark",
													font: { lg: "400 16px/24px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" },
													"&::placeholder":
													{
														opacity: "0.5 !important"
													}
												}
											}}
										/>
									</Box>
								</Fade>
							)
						}
					</div>

					<label>
						<span>How will you know the experiment was a success?</span>

						<CustomInput.Base
							name="definitionSuccess"
							placeholder="e.g. Create a website displaying my art with prices"
							inputProps={{ maxLength: MAX_FILED_LENGTH }}
							value={formik.values.definitionSuccess}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<Box sx={{
							margin: "-8px 10px 0 10px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{formik.errors.definitionSuccess}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{formik.values.definitionSuccess.length}/{MAX_FILED_LENGTH} characters
							</Box>
						</Box>
					</label>
				</Box>

				<CustomButton.Contained
					type="submit"
					disabled={!(formik.isValid && formik.dirty)}
					sx={{ justifySelf: "center", marginTop: "20px" }}
				>
					Save
				</CustomButton.Contained>
			</Box>

			<Snackbar
				open={isShowAlert}
				autoHideDuration={5000}
				onClose={() => setIsShowAlert(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "35px", right: "35px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
					You must add at least one task
				</AlertStyled>
			</Snackbar>

			<Dialog
				open={isShowChangeTitleModal}
				onClose={() => setIsShowChangeTitleModal(false)}
				title="Change experiment name"
			>
				<form onSubmit={onChangeTitle}>
					<Box sx={{ position: "relative", width: "568px" }}>
						<CustomInput.Base
							placeholder="Name"
							value={newTitle}
							inputProps={{ maxLength: RULES.title.maxLength }}
							onChange={({ target }) => setNewTitle(target.value)}
						/>
						<Box sx={{
							position: "absolute",
							bottom: "-19px",
							right: "5px",
							left: "5px",
							display: "flex",
							justifyContent: "space-between",
							gap: "16px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{newTitleError}
							</Box>

							<Box sx={{ color: "primary.dark", opacity: 0.5, font: "400 14px/19px 'Open Sans'" }}>
								{newTitle.length}/{RULES.title.maxLength} characters
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{ marginTop: "14px" }}
					>
						Save
					</CustomButton.Contained>
				</form>
			</Dialog>
		</ArtifactsContainer>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default Create;
