import { Box, Typography } from "@mui/material";
import { Ref, forwardRef } from "react";
import CardFonImg from "../../../assets/images/missions/mission-ten/card-fon-img.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import useMission from "../hooks";

const YourPlanFinish = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		// navigate("/dashboard");
		missionContext.setStep((prevState) => prevState + 1);
		missionContext.setBackground("surpriseBg");
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				width: "100%", maxWidth: { lg: "814px", xl: "980px" }, m: "0 auto", display: "flex", flexDirection: "column", marginTop: { lg: "100px", xl: "124px" }, position: "relative", left: "13px"
			}}>
			{missionContext.showContent
				? (
					<>
						<Box sx={{
							display: "flex",
							width: "100%",
							height: "100%",
							minHeight: { lg: "366px", xl: "480px" },
							margin: "0 auto",
							border: "1px solid rgba(255, 255, 255, .6)",
							borderRadius: "24px",
							wordBreak: "break-word",
							background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
							backdropFilter: "blur(25px)",
							boxShadow: "0px 5px 10px 0px #A7C7E199",
							position: "relative",
							maxHeight: "444px",
							overflow: "hidden"
						}}>
							<Box sx={{ display: "flex", width: { lg: "182px", xl: "261px" }, backgroundImage: `url(${ CardFonImg })`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />
							<Box sx={{
								width: "100%",
								position: "relative",
								padding: {
									lg: "32px 44px",
									xl: "36px 83px 44px 67px"
								},
								wordBreak: "break-word"
							}}>
								<Typography sx={{
									color: "#215C75",
									font: { lg: "700 24px/39px Lora-Bold", xl: "700 40px/51px Lora-Bold" },
									textAlign: "left",
									mb: { lg: "18px", xl: "24px" }
								}}>
									<ContentAdmin
										limit={111}
										keyContent="contentMissionTen"
										keyText="act_now_step_title"
										defaultValue="{NAME}, you are rocking it, just like you always do!"
										position="left"
										isDescription
									/>
								</Typography>
								<Typography sx={{
									color: "#4D4D4D",
									font: { lg: '400 16px/26px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
									textAlign: "left"
								}}>
									<ContentAdmin
										limit={111}
										keyContent="contentMissionTen"
										keyText="act_now_step_content"
										defaultValue="{NAME}, you’ve spent enough time analysing, researching, prioritising and theorising, it’s time to act!\n\n Feel the fear and do it anyway Sometimes you need to just do it. Despite all the ‘yes, buts’ in your head. You don’t need to have it all figured out. All you need to do is figure out which lilipad to jump on next.\n\nNot that scary, right?"
										position="left"
										isDescription
									/>
								</Typography>

							</Box>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "center", mt: { lg: "40px", xl: "58px" } }}>
							<CustomButton.Outline
								onClick={nextStep}
								sx={{ fontSize: "24px", lineHeight: "33px", background: "#3E809D", border: "#3E809D", color: "#fff" }}
							>Act now!
							</CustomButton.Outline>
						</Box>
					</>
				)
				: null
			}
		</Box>
	);
});

export default YourPlanFinish;
