import { Box } from "@mui/material";
import ArrowButton from "components/ArrowButton";
import ChipStyled from "components/ChipStyled";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";

export interface IListEntryProps
{
	nextStep(): void;
}

function ListEntry({ nextStep }: IListEntryProps)
{
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ error, setError ] = useState<string>("");
	const [ arrValues, setArrValues ] = useState<Set<string>>(() =>
	{
		const store = getLocalStorage<string[]>("dashboard-create-yearning-list");

		return new Set(store ?? []);
	});

	// Handles
	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 40)
		{
			return setError("The text should not exceed 40 characters");
		}

		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);
			const stateSize = state.size;

			state.add(valueInput.trim());

			if (state.size === stateSize)
			{
				return prevState;
			}

			setLocalStorage("dashboard-create-yearning-list", Array.from(state));

			return state;
		});

		setValueInput("");
	}

	function deleteItemArrValues(item: string)
	{
		setArrValues((prevState) =>
		{
			const state = new Set<string>(prevState);

			if (state.delete(item) === false)
			{
				return prevState;
			}

			setLocalStorage("dashboard-create-yearning-list", Array.from(state));

			return state;
		});
	}

	// Render
	return (
		<>
			<Box sx={{ width: "420px", margin: "auto", display: "flex", flexDirection: "column" }}>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Box sx={{ opacity: 0.5, color: "primary.dark", font: "400 14px/19px 'Open Sans'" }}>
						Added {arrValues.size}
					</Box>
				</Box>

				<TooltipStyled title="Press 'Enter' to add" placement="right" arrow>
					<CustomInput.Base
						value={valueInput}
						fullWidth
						autoFocus
						onChange={(e: ChangeEvent<HTMLInputElement>) => { setValueInput(e.target.value); setError(""); }}
						onKeyDown={handleAddArrValues}
						disabled={arrValues.size >= 50}
						sx={{
							padding: "unset",

							"& .MuiInputBase-input":
							{
								padding: "13px 16px",
								textTransform: "capitalize"
							}
						}}
					/>
				</TooltipStyled>

				<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "25px", gap: "10px" }}>
					<Box sx={{ color: "#E41616", font: "400 14px/19px 'Open Sans'" }}>
						{error || (arrValues.size >= 50 && "Limit of 50 has been reached")}
					</Box>

					<Box sx={{ opacity: 0.5, color: "primary.dark", font: "400 14px/19px 'Open Sans'" }}>
						Between 10 and 50
					</Box>
				</Box>

				{/* Item list */}
				<Box
					className="customScroll"
					sx={{
						display: "flex",
						flexWrap: "wrap",
						alignItems: "flex-start",
						alignContent: "flex-start",
						gap: "10px",
						overflowY: "auto",
						height: "250px",
						marginRight: "-16px",
						padding: "0 16px 5px 0"
					}}
				>
					{Array.from(arrValues).map((item) => (
						<ChipStyled
							key={item}
							label={item}
							onDelete={() => deleteItemArrValues(item)}
							sx={{
								flexGrow: 1,

								"& .MuiChip-label":
								{
									flexGrow: 1,
									textAlign: "center"
								}
							}}
						/>
					))}
				</Box>
			</Box>

			{/* FIXME: Correct icon */}
			<ArrowButton
				direction="right"
				disabled={arrValues.size < 10 || arrValues.size > 50}
				onClick={nextStep}
				sx={{
					position: "absolute",
					right: "40px",
					bottom: "40px",
					minWidth: "unset",
					width: "46px",
					height: "46px"
				}}
			/>
		</>
	);
}

export default ListEntry;
