import { Box, Grid, Typography } from "@mui/material";
import Fun from "../../../../assets/images/Fun.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function FunDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Fun} alt="Fun" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Fun
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="{NAME}, fun and recreation are key ingredients to a fulfilling life. Since you’ve rated this area lower, it could be you simply need to re-assess your priorities and create time for fun activities. It could also be a sign that you need a bigger life change." keyContent="contentMissionOne" keyText="funScreen1" />
				{/* {localStorage.getItem('name')}, fun and recreation are key ingredients to a fulfilling life. Since you’ve rated this area lower, it could be you simply need to re-assess your priorities and create time for fun activities. It could also be a sign that you need a bigger life change. */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Are you burnout?\n- Do you know how to relax?\n- Do you have fun often?\n- Do you enjoy sports or have hobbies?\n\nYou can restore balance by being more intentional about how you spend time. Schedule dedicated time daily for quality rest. Avoid scrolling through social media or binge-watching meaningless\nTV-shows.\n\nTry focusing on quality entertainment and activities that help you connect with others: dinner parties, board games, reading, going to an art exhibition with a friend, learning new skill.\n\nIf you’ve already tried that and your life doesn’t feel fun, it’s time to ask yourself some bigger questions." keyContent="contentMissionOne" keyText="funStart" />
						{/* You can restore balance by being more intentional about how you spend time. Schedule dedicated time daily for quality rest. Avoid scrolling through social media or binge-watching meaningless TV-shows.<br></br><br></br>
							Try focusing on quality entertainment and activities that help you connect with others: dinner parties, board games, reading, going to an art exhibition with a friend, learning new skill.<br></br><br></br>
							If you’ve already tried that and your life doesn’t feel fun, it’s time to ask yourself some bigger questions. */}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Is your career fulfilling and fun?\n- How often do you find yourself in the flow?\n- Do you have the time and energy for your hobbies?\n- Have you lost the spark in life and don’t know how to get it back?" keyContent="contentMissionOne" keyText="funDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "5px",
						height: "5px",
						borderRadius: "50%",
						backgroundColor: "#4D4D4D"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Answers to these questions might show that adding more rest time and recreational activities wouldn’t solve the issue and you need a bigger change in life. Re-designing your life can be a good way to feel the zest for life again. Life Strategy Quest is what can help you get your spark back!" keyContent="contentMissionOne" keyText="funScreen2" />
				{/* Answers to these questions might show that adding more rest time and recreational activities wouldn’t solve the issue and you need a bigger change in life. Re-designing your life can be a good way to feel the zest for life again. Life Strategy Quest is what can help you get your spark back! */}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default FunDetails;
