import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Friend()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-friend-reflection"
					defaultValue="- Are you surrounded by like-minded people?\n- Do you feel you belong in your workplace, friends circle, community, country?\n- Do you spend time with friends & family?\n- Are all of your connections meaningful?\n- How much time do you spend connecting in real life vs online?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-friend-actionPlan"
					defaultValue="1. **Audit your friendships:** restore meaningful connections, cease empty ones, fix any outstanding family issues. \n1. **Nurture your relationships.** Create time for meaningful relationships. Focus on giving and doing acts of kindness.\n1. **Find your tribe.** We aren’t all born into a community that fits us, and sometimes need to find (or create!) our tribe."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-friend-howCanHelp"
					defaultValue="My Life Quest will help you learn what you value in life and people. Once you know that, you can use those insights as cues to connect with the right tribe."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/m3z-zt3nwus?si=aa_ZEz-5XaNQiq_b"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Friend;
