import { Box } from "@mui/material";
import CelebrationScreen from "components/missions/CelebrationScreen";
import { getLocalStorage } from "utils/localStorage";

function LetsDoIt({ handleStep }: { handleStep(): void; })
{
	return (
		<Box sx={{
			minHeight: "100vh",
			padding: "24px 24px 16px 24px",
			textAlign: "center",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",

			"@supports (min-height: 100dvh)":
			{
				minHeight: "100dvh"
			}
		}}>
			<CelebrationScreen
				content={
					<>
						That’s a cool name, {getLocalStorage("name")}!<br />
						Are you ready?
					</>
				}
				buttonText="Let’s do it!"
				onClickButton={handleStep}
				sx={{ color: "primary.main" }}
			/>
		</Box>
	);
}

export default LetsDoIt;
