import { Box, Collapse } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGHeart } from "assets/images/heart.svg";
import { ReactComponent as SVGRadial } from "assets/images/icons/radial.svg";
import { ReactComponent as SVGChevron } from "assets/images/icons/size-24/chevron.svg";
import { ReactComponent as SVGIconWhatsApp } from "assets/images/icons/size-40/whats-app.svg";
import IMGOcta2 from "assets/images/octa2-shrink.png";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { useState } from "react";
import IMGHeaderImage from "./assets/images/header-image.png";
import IMGMonitors from "./assets/images/monitors.png";
import IMGSectionBackground from "./assets/images/section-background.jpg";
import IMGSectionBackground1 from "./assets/images/section-background1.jpg";

function MissionOneFirstComplete()
{
	const [ isExpandedMoneyBack, setIsExpandedMoneyBack ] = useState<boolean>(false);
	const [ isExpanded, setIsExpanded ] = useState<0 | 1 | 2>(0);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	// MARK: Handles
	function buyMissions()
	{
		if (isFetch)
		{
			return;
		}

		setIsFetch(true);

		Api.transactions
			.byAllMissions({ type: "full", id: undefined })
			.then((data) =>
			{
				window.location = data;
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	// MARK: Render
	return (
		<Box sx={{
			minHeight: "100vh",

			display: "flex",
			flexDirection: "column",
			alignItems: "center",

			"@supports (min-height: 100dvh)":
			{
				minHeight: "100dvh"
			},

			"& .wrapper":
			{
				maxWidth: "1920px",
				margin: "0 auto"
			},
			"& section":
			{
				width: "100%",
				paddingTop: { xl: "80px", lg: "56px", xs: "40px" },
				paddingLeft: "32px",
				paddingRight: "32px"
			},
			"& h1, & h2":
			{
				width: "fit-content",
				maxWidth: { xl: "900px", lg: "636px" },
				margin: "0 auto",

				font: { xl: "700 64px/82px Lora-Bold", lg: "700 45px/58px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "#FFFF",
				textAlign: "center"
			}
		}}>
			{/* [NAME], you are amazing! */}
			<Box
				component="header"
				sx={{
					width: "100%",
					background: `url(${ IMGSectionBackground1 }) center center/cover`
				}}
			>
				<Box
					className="wrapper"
					sx={{
						padding: { xl: "213px 152px 131px 152px", lg: "150px 82px 92px 58px", xs: "32px 32px 44px 32px" },
						display: "grid",
						gridTemplateColumns: { lg: "1fr 1fr" },
						gridTemplateAreas: { lg: "'title image' 'content image'", xs: "'title' 'image' 'content'" },
						gap: { xl: "72px 28px", lg: "52px 0px" },
						justifyItems: "center",

						font: { lg: "400 22px 'Open Sans'", xs: "400 16px 'Open Sans'" },
						color: "#FFFFFF"
					}}
				>
					<Box
						component="h1"
						sx={{
							gridArea: "title",
							textAlign: "left",
							margin: "unset !important",
							justifySelf: { lg: "flex-start" }
						}}
					>
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="header-title"
							defaultValue="{NAME}, you are amazing!"
							position="left"
						/>
					</Box>

					<Box sx={{
						gridArea: "image",
						position: "relative",
						"& img":
						{
							position: { lg: "absolute" },
							bottom: "50px",
							left: "50%",
							transform: { lg: "translateX(-50%)" },
							maxWidth: { xs: "286px", lg: "unset" },
							width: { xl: "728px", lg: "517px", xs: "100%" },
							height: { xl: "715px", lg: "507px", xs: "auto" }
						}
					}}>
						<img src={IMGHeaderImage} alt="" />
					</Box>

					<Box sx={{
						gridArea: "content",
						paddingRight: { lg: "66px" },
						marginTop: { xs: "24px", lg: "unset" }
					}}>
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="header-information"
							defaultValue="You’ve just taken a step to change your life for the better and completed your first mission.\n\n{NAME}, we know how you feel...stuck, lost, overwhelmed by all the options and not sure what to do next, it’s really hard.\n\nYou could stay stuck like that or...you could create a life that exceeds your wildest dreams."
							position="left"
						/>
						<Box sx={{
							width: { lg: "fit-content" },
							margin: { xs: "0 auto", lg: "unset" },
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "24px"
						}}>
							<CustomButton.Base
								loading={isFetch}
								onClick={buyMissions}
								sx={{
									width: { xs: "100%", sm: "auto" },
									marginTop: "72px",
									boxShadow: "unset"
								}}
							>
								Buy Full Quest
							</CustomButton.Base>
							<Box sx={{ font: { xl: "700 24px 'Open Sans'", lg: "700 17px 'Open Sans'", xs: "700 14px 'Open Sans'" } }}>
								<ContentAdmin
									keyText="mission-one-firstComplete"
									keyContent="header-moneyBack"
									defaultValue="Money back guarantee"
									position="left"
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Imagine if you could... */}
			<Box
				component="section"
				sx={{
					paddingBottom: { xl: "136px", lg: "96px", xs: "48px" },
					backgroundColor: "#698ABF"
				}}
			>
				<div className="wrapper">
					<h2>
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section1-title"
							defaultValue="Imagine if you could..."
							position="left"
						/>
					</h2>
					<Box
						component="ul"
						sx={{
							width: { xs: "fit-content", lg: "auto" },
							padding: "unset",
							margin: { xs: "0 auto", lg: "unset" },
							marginTop: { xl: "104px", lg: "72px", xs: "44px" },
							listStyle: "none",
							display: "flex",
							flexDirection: { xs: "column", lg: "row" },
							justifyContent: "space-around",
							gap: "40px",
							"& li":
							{
								textAlign: { lg: "center" },
								font: { xl: "700 40px Lora-Bold", lg: "700 28px Lora-Bold", xs: "700 18px Lora-Bold" },
								color: "#FFF",
								display: "flex",
								flexDirection: { xs: "row", lg: "column" },
								justifyContent: { lg: "center" },
								alignItems: "center",
								gap: "40px 24px"
							},
							"& .icon":
							{
								"--size": { xl: "80px", xs: "56px" },
								width: "var(--size)",
								height: "var(--size)",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								border: "2px solid #FFFFFF",
								borderRadius: "50%",
								background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))"
							}
						}}
					>
						<li>
							<div className="icon">
								<SVGRadial />
							</div>
							<span>
								<ContentAdmin
									keyText="mission-one-firstComplete"
									keyContent="section1-list-1"
									defaultValue="Live life\non your terms"
									position="left"
									isDescription
								/>
							</span>
						</li>
						<li>
							<div className="icon">
								<SVGRadial />
							</div>
							<span>
								<ContentAdmin
									keyText="mission-one-firstComplete"
									keyContent="section1-list-1"
									defaultValue="Wake up excited\nand motivated"
									position="left"
									isDescription
								/>
							</span>
						</li>
						<li>
							<div className="icon">
								<SVGRadial />
							</div>
							<span>
								<ContentAdmin
									keyText="mission-one-firstComplete"
									keyContent="section1-list-1"
									defaultValue="Realise your\nfull potential"
									position="left"
									isDescription
								/>
							</span>
						</li>
					</Box>
				</div>
			</Box>

			{/* Create the life you love starting today */}
			<Box
				component="section"
				sx={{
					background: `url(${ IMGSectionBackground1 }) center center/cover`
				}}
			>
				<Box
					className="wrapper"
					sx={{
						paddingBottom: "76px"
					}}
				>
					<h2>
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section2-title"
							defaultValue="Create the life you love starting today"
							position="left"
						/>
					</h2>
					<Box sx={{
						marginTop: { xl: "36px", lg: "24px" },
						paddingRight: { xl: "155px", lg: "52px" },
						display: "grid",
						gridTemplateColumns: { xl: "1fr 624px", lg: "1fr 442px", xs: "1fr" },
						gap: { xl: "92px", lg: "72px", xs: "20px" }
					}}>
						<Box
							component="img"
							src={IMGMonitors}
							alt=""
							sx={{
								// justifySelf: { lg: "flex-end", xs: "center" },
								justifySelf: "center",
								marginTop: "24px",
								height: { xl: "496px", lg: "356px" },
								width: { xs: "100%", lg: "auto" }
							}}
						/>
						<Box sx={{
							display: "flex",
							flexDirection: "column",
							gap: { xl: "24px", lg: "16px", xs: "8px" }
						}}>
							<Box sx={{
								position: "relative",
								padding: { xl: "56px 36px 36px 36px", lg: "40px 32px 26px 32px", xs: "24px 36px 20px 36px" },
								backgroundColor: "#FFFFFF",
								borderRadius: "24px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: { xl: "32px", lg: "24px", xs: "16px" },
								color: "primary.main",
								textAlign: "center",
								"& .heart":
								{
									"--size": { xl: "72px", lg: "52px", xs: "56px" },
									position: "absolute",
									top: "0",
									transform: "translateY(-50%)",
									width: "var(--size)",
									height: "var(--size)",
									backgroundColor: "#FFFFFF",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									"& svg":
									{
										width: { xl: "45px", lg: "32px", xs: "36px" }
									}
								},
								"& h4":
								{
									margin: "unset",
									font: { xl: "700 40px Lora-Bold", lg: "700 28px Lora-Bold", xs: "700 24px Lora-Bold" }
								},
								"& p":
								{
									margin: "unset",
									padding: "0 10px",
									font: { xl: "400 22px 'Open Sans'", lg: "400 16px 'Open Sans'", xs: "400 16px/150% 'Open Sans'" },
									textAlign: { lg: "center", xs: "left" }
								},
								"& .price":
								{
									font: { xl: "700 64px Lora-Bold", lg: "700 45px Lora-Bold", xs: "700 40px Lora-Bold" }
								}
							}}>
								<div className="heart">
									<SVGHeart />
								</div>
								<h4>Buy Full Quest Now</h4>
								<p>
									Unlock the full quest with one-time investment. Don't settle for mediocrity when you can experience
									a life that exceeds your wildest dreams.
								</p>
								<div className="price">€399</div>
								<CustomButton.Contained
									loading={isFetch}
									onClick={buyMissions}
									size="medium"
									sx={{
										width: { sm: "auto", xs: "100%" }
									}}
								>
									Buy Now
								</CustomButton.Contained>
							</Box>
							<Box sx={{
								overflow: "hidden",
								position: "relative",
								backgroundColor: "#FFFFFF",
								borderRadius: "24px",
								color: "primary.main",
								"& img":
								{
									position: "absolute",
									top: { xl: "12px", lg: "8px", xs: "4px" },
									left: "6px",
									height: { xl: "200px", lg: "137px", xs: "99px" },
									transform: "rotate(3.06deg)",
									pointerEvents: "none"
								},
								"& button":
								{
									width: "100%",
									height: { xl: "96px", lg: "68px", xs: "52px" },
									padding: { xl: "0 40px 0 80px", lg: "0 28px 0 56px", xs: "0 16px 0 32px" },
									backgroundColor: "unset",
									border: "unset",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: { xl: "20px", lg: "12px" },
									font: { xl: "700 24px 'Open Sans'", lg: "700 17px 'Open Sans'", xs: "700 14px 'Open Sans'" },
									color: "inherit",
									cursor: "pointer",
									"& span":
									{
										margin: { xs: "0 auto", lg: "unset" }
									},
									"& svg":
									{
										"--size": { xl: "24px", lg: "17px", xs: "14px" },
										width: "var(--size)",
										height: "var(--size)",
										transition: "linear 200ms transform",
										transform:
										{
											xl: `translateY(4.5px) rotate(${ isExpandedMoneyBack ? "-180deg" : "0deg" })`,
											lg: `translateY(2.5px) rotate(${ isExpandedMoneyBack ? "-180deg" : "0deg" })`,
											xs: `rotate(${ isExpandedMoneyBack ? "-180deg" : "0deg" })`
										}
									}
								},
								"& .content":
								{
									margin: "unset",
									padding: { xl: "0 38px 46px 138px", lg: "0 24px 32px 96px", xs: "0 24px 24px 64px" },
									font: { xl: "400 22px 'Open Sans'", xs: "400 16px 'Open Sans'" }
								}
							}}>
								<img src={IMGOcta2} alt="" />
								<button
									type="button"
									onClick={() => setIsExpandedMoneyBack((prevState) => !prevState)}
								>
									<span>
										<ContentAdmin
											keyText="mission-one-firstComplete"
											keyContent="section2-moneyBack-title"
											defaultValue="Money back guarantee"
											position="left"
										/>
									</span>
									<SVGChevron />
								</button>
								<Collapse in={isExpandedMoneyBack}>
									<div className="content">
										<ContentAdmin
											keyText="mission-one-firstComplete"
											keyContent="section2-moneyBack-desc"
											defaultValue="Positive change–guaranteed. We’ve helped over a thousand people around the world, just like you, find direction in life. We offer a money-back guarantee (no questions asked) because we're confident in the effectiveness of the quest."
											position="left"
										/>
									</div>
								</Collapse>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Accordions */}
			<Box
				component="section"
				sx={{
					paddingBottom: { xl: "80px", lg: "56px", xs: "40px" },
					background: `url(${ IMGSectionBackground }) center center / cover`,

					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "28px"
				}}
			>
				{[
					{
						title: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions1-title"
							defaultValue="Is it for me?"
							position="left"
						/>,
						desc: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions1-desc"
							defaultValue="**The quest is for you if:**\n- Not sure in which direction you want to go in life\n- Don't know what to do with your life\n- Not sure what career to choose\n- Don't know what your passions are\n- Need to make big life choices\n- You love structure & want to organise your life"
							position="left"
							isDescription
						/>
					},
					{
						title: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions2-title"
							defaultValue="Why should I do it?"
							position="left"
						/>,
						desc: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions2-desc"
							defaultValue="**By doing the quest you will:**\n- Understand yourself: your values, beliefs, fears, dreams and desires\n- Empty your head & organise your life\n- Prioritise your goals & objectives\n- Build a strategic life plan\n- Get unstuck and find the courage to make a change\n- Maximise who you are and create an extraordinary life"
							position="left"
							isDescription
						/>
					},
					{
						title: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions3-title"
							defaultValue="How does it work?"
							position="left"
						/>,
						desc: <ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="section3-accordions3-desc"
							defaultValue="**Here is what to expect:**\n- It is a profound, serious journey, so you need to be ready to put in real work if you want to get the results\n- You will have 10 missions, each requiring 20-40 minutes of your time\n- Your virtual mentor Octa will guide you through answering big life questions\n- We help you connect the dots & take actions from your insights\n- You will need a computer to access this web-based journey\n- Do it anytime, anywhere, pause and resume when you want"
							position="left"
							isDescription
						/>
					}
				].map((accordionData, index) =>
				{
					return (
						<Box
							key={index}
							sx={{
								overflow: "hidden",
								position: "relative",

								maxWidth: "752px",
								width: "100%",

								backgroundColor: "#FFFFFF",
								borderRadius: "24px",

								color: "primary.main",

								"& button":
								{
									width: "100%",
									height: { xl: "96px", lg: "68px", xs: "52px" },

									padding: { xl: "0 32px 0 36px", lg: "0 24px", xs: "0 24px" },

									backgroundColor: "unset",
									border: "unset",

									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: { xl: "20px", lg: "12px" },

									font: { xl: "700 24px 'Open Sans'", xs: "700 18px 'Open Sans'" },
									color: "inherit",

									cursor: "pointer",

									"& svg":
									{
										"--size": { xl: "24px", lg: "17px", xs: "14px" },

										width: "var(--size)",
										height: "var(--size)",

										transition: "linear 200ms transform",
										transform:
										{
											xl: `translateY(4.5px) rotate(${ isExpanded === index ? "-180deg" : "0deg" })`,
											lg: `translateY(2.5px) rotate(${ isExpanded === index ? "-180deg" : "0deg" })`,
											xs: `rotate(${ isExpanded === index ? "-180deg" : "0deg" })`
										}
									}
								},
								"& .content":
								{
									margin: "unset",
									padding: { xl: "0 36px 46px 36px", lg: "0 24px 32px 24px", xs: "0 24px 24px 24px" },
									font: { xl: "400 22px 'Open Sans'", xs: "400 16px 'Open Sans'" },

									"& ul":
									{
										paddingLeft: "28px"
									}
								}
							}}
						>
							<button
								type="button"
								onClick={() => setIsExpanded(index as 0 | 1 | 2)}
							>
								<span>{accordionData.title}</span>

								<SVGChevron />
							</button>

							<Collapse in={isExpanded === index}>
								<div className="content">
									{accordionData.desc}
								</div>
							</Collapse>
						</Box>
					);
				})}
			</Box>

			{/* Still got questions? */}
			<Box
				component="section"
				sx={{
					paddingBottom: { lg: "50px", xs: "32px" },
					backgroundColor: "#3C5E94"
				}}
			>
				<h2>
					<ContentAdmin
						keyText="mission-one-firstComplete"
						keyContent="footer-title"
						defaultValue="Still got questions?"
						position="left"
					/>
				</h2>

				<Box
					sx={{
						margin: { xl: "44px auto 0 auto", xs: "32px auto 0 auto" },

						width: "100%",
						maxWidth: { xl: "1140px", lg: "807px", xs: "327px" },
						height: { xl: "641px", lg: "454px", xs: "187px" },
						overflow: "hidden",
						borderRadius: { xl: "24px", xs: "16px" }
					}}
				>
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/sIrECLoOn7s?si=oXv-siDq_fFExKYG"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</Box>

				<Box
					component="footer"
					sx={{
						marginTop: { xl: "60px", xs: "40px" },

						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						gap: "16px 44px",

						"& a:not(.MuiButtonBase-root)":
						{
							font: { xl: "700 24px 'Open Sans'", xs: "700 18px 'Open Sans'" },
							color: "#FFF",
							textDecoration: "none"
						}
					}}
				>
					<a href="https://calendly.com/mylifequest/30min" target="_blank">
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="footer-link1"
							defaultValue="Book a call"
							position="left"
						/>
					</a>

					<CustomButton.Base
						component="a"
						href="https://wa.me/message/MHV2VW2FZWN4L1"
						target="_blank"
						sx={{
							width: { xs: "100%", lg: "auto" },
							order: { xs: "-1", lg: "0" },
							boxShadow: "unset",
							textWrap: "nowrap",

							"& svg":
							{
								flexShrink: 0
							}
						}}
					>
						<SVGIconWhatsApp />
						Chat on WhatsApp
					</CustomButton.Base>

					<a href="https://www.mylifequest.io/faq" target="_blank">
						<ContentAdmin
							keyText="mission-one-firstComplete"
							keyContent="footer-link2"
							defaultValue="FAQ"
							position="right"
						/>
					</a>
				</Box>
			</Box>
		</Box>
	);
}

export default MissionOneFirstComplete;
