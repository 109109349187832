import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Growth()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-growth-reflection"
					defaultValue="- Are you learning new things?\n- Does your job allow you to develop new skills?\n- What would help you grow as a person?\n- Does your career support a trajectory in which you want to develop?\n- Are you pursuing hobbies that don’t suit you anymore?\n- Have you lost the spark in life and don’t feel like doing anything?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-growth-actionPlan"
					defaultValue="1. **Learn new skills.** Bring your personal growth agenda back into the spotlight. Think of the skills you'd like to develop. Enrol in courses, attend workshops, or join online communities.\n1. **Develop your career.** Explore opportunities for career advancement, such as additional responsibilities, projects, or skill-building initiatives.\n1. **Reignite passion and purpose.** Reflect on hobbies and activities that once brought you joy and fulfilment. Experiment with new hobbies or revisit old ones to find what resonates with your current self."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-growth-howCanHelp"
					defaultValue="My Life Quest will help you uncover the direction for your growth and turn that into reality with a step-by-step  action plan."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/EBHYRzkaemc?si=3lTCls4Nhwe3Kf7Z"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Growth;
