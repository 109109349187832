import { Box, Fade } from "@mui/material";
import Api from "api";
import ArrowButton from "components/ArrowButton";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionTwo/hooks/useMission";
import { useLayoutEffect, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import LifePeaksButtons from "../LifePeaks/Buttons";
import { ListOfValuesData } from "../ListOfValues";
import WhatMakesProudButtons from "../WhatMakesProud/Buttons";
import ToughDecisionsClouds from "./Clouds";
import ToughDecisionsCreateFields from "./CreateFields";
import ToughDecisionsTemplate from "./Template";

export type ToughDecisionsField = [ fieldName: string, value: string ];
export type ToughDecisionsFields = ToughDecisionsField[];

export function filledForm(data: ToughDecisionsFields)
{
	if (data.length === 0)
	{
		return false;
	}

	return data.every(([ , value ]) => value.trim().length > 0);
}

export function filledFormField(data: ToughDecisionsFields, field: number)
{
	return data[ field ][ 1 ].trim().length > 0;
}

function ToughDecisions()
{
	const missionContext = useMission();
	const [ toughDecisionsFields, setToughDecisionsFields ] = useLocalStorage<ToughDecisionsFields>(missionContext.missionPrefix + "-ToughDecisionsFields", [ [ "", "" ] ]);
	const [ fieldsSubmit, setFieldsSubmit ] = useLocalStorage<boolean>(missionContext.missionPrefix + "-ToughDecisionsFieldsSubmit", false);

	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ , setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Effects
	useLayoutEffect(() =>
	{
		if (toughDecisionsFields[ 0 ][ 0 ] === "" && fieldsSubmit === true)
		{
			setFieldsSubmit(false);
		}
	}, []);

	// Handles
	function submitFields()
	{
		setFieldsSubmit(true);
	}

	function nextStep()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					toughDecisionsFields
						.map(([ , value ]) =>
						{
							return "Define in max 5 nouns, with no additional text, the personal values of this person based on their life’s tough decisions " + value.trim();
						}),
					"personBased"
				)
				.then((values) =>
				{
					const newValues =
						Array.from(
							new Set(
								values
									.map(({ personBased }) => personBased)
									.flat()
									.map((value) => value.toLowerCase())
							)
						).filter((value) => listOfValues.includes(value) === false);

					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 2) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	// Render
	return (
		<>
			<ToughDecisionsTemplate>
				<Fade in={missionContext.showContent}>
					<Box sx={{ position: "relative", width: "100%" }}>
						{fieldsSubmit === false
							? (
								<ToughDecisionsCreateFields
									submitFields={submitFields}
									fields={toughDecisionsFields}
									setFields={setToughDecisionsFields}
								/>
							)
							: (
								<>
									<LifePeaksButtons preview />
									<WhatMakesProudButtons preview />
									<ToughDecisionsClouds />
								</>
							)
						}
					</Box>
				</Fade>
			</ToughDecisionsTemplate>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					loading={loading}
					disabled={fieldsSubmit === false || filledForm(toughDecisionsFields) === false}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default ToughDecisions;
