import { ReactNode, ReactNodeArray } from "react";
import reactStringReplace from "./reactStringReplace";
// import reactStringReplace from "react-string-replace";

export function randomKey(): string
{
	return Date.now() + "" + Math.random();
}

function _wrappedItalic(match: string): ReactNode
{
	return <em key={randomKey()}>{match}</em>;
}

function _wrappedBold(match: string): ReactNode
{
	return <strong key={randomKey()}>{match}</strong>;
}

function _wrappedBoldAndItalic(match: string): ReactNode
{
	return <em key={randomKey()}><strong>{match}</strong></em>;
}

// function _wrappedLink(match: string): ReactNode
// {
// 	const results = /\[(?<text>.*)\]\((?<link>.*)\)/g.exec(match);
// 	if (results === null || results.groups === undefined)
// 	{
// 		return null;
// 	}

// 	return <a key={randomKey()} href={results.groups.link}>{results.groups.text}</a>;
// }

function _wrappedList(match: string): ReactNode
{
	const items: string[] = match.trim().split(/\n|\\n/g)
		.map((item) => item.replace(/^-\s+/, "").trim())
		.filter((item) => item.trim() !== "");
	// console.info(items);

	return (
		<ul style={{ margin: 0 }} key={randomKey()}>
			{items.map((item) => <li key={item}>{item}</li>)}
		</ul>
	);
}

function _wrappedOrderedList(match: string): ReactNode
{
	const items: string[] = match.trim().split(/\n|\\n/g)
		.map((item) => item.replace(/^\d+\.\s+/, "").trim())
		.filter((item) => item.trim() !== "");

	// console.info(items);

	return (
		<ol style={{ margin: 0 }} key={randomKey()}>
			{items.map((item) => <li key={randomKey()}>{item}</li>)}
		</ol>
	);
}

function convertMarkdown(text: string | ReactNodeArray | undefined): ReactNodeArray
{
	if (typeof text === "string")
	{
		text = text.replace(/\\n/g, "\n");
		text = text.replace(/\t/g, "");
	}

	// Unordered Lists
	text = reactStringReplace(text, /((?:^-\s+.*\n?)+)/gm, _wrappedList);

	// Ordered Lists
	text = reactStringReplace(text, /((?:^\d+\.\s+.*\n?)+)/gm, _wrappedOrderedList);

	// Bold and Italic
	text = reactStringReplace(text, /___(.*?)___/g, _wrappedBoldAndItalic);
	text = reactStringReplace(text, /\*\*\*(.*?)\*\*\*/g, _wrappedBoldAndItalic);
	text = reactStringReplace(text, /__\*(.*?)\*__/g, _wrappedBoldAndItalic);
	text = reactStringReplace(text, /\*\*_(.*?)_\*\*/g, _wrappedBoldAndItalic);

	// Bold
	text = reactStringReplace(text, /\*\*(.*?)\*\*/g, _wrappedBold);
	text = reactStringReplace(text, /__(.*?)__/g, _wrappedBold);

	// Italic
	text = reactStringReplace(text, /\*(.*?)\*/g, _wrappedItalic);
	text = reactStringReplace(text, /_(.*?)_/g, _wrappedItalic);

	// Link
	// text = reactStringReplace(text, /(\[.*\]\(.*\))/g, _wrappedLink);

	// Line break
	text = reactStringReplace(text, /(\n|\\n)/g, () => (<br key={randomKey()} />));

	return text;
}

export default convertMarkdown;
