import { Box, Typography } from "@mui/material";
import bgAbout from "../../assets/images/bgAbout2.png";
import bgSteper from "../../assets/images/bgSteper.png";
import octa from "../../assets/images/calm.svg";
import ButtonStyled from "../../components/buttonStyled";
import useUser from "../../hooks/useUser";

function TemporaryPage()
{
	const { state: userState } = useUser();

	return (
		<Box sx={{ background: { xs: `url(${ bgSteper }) no-repeat`, sm: `url(${ bgAbout }) no-repeat` }, backgroundSize: "cover", minHeight: "100vh", backgroundPosition: "0%  0%" }}>
			<Box sx={{ background: "linear-gradient(to bottom, rgba(239,239,239, 0.8), rgba(239,239,239, 0.3))", minHeight: "100vh" }}>
				<Box sx={{ pt: "320px", display: { xs: "none", sm: "block" } }}>
					<Box sx={{ width: "80%", m: "0 auto" }}>
						<Box sx={{ position: "relative", width: "510px", m: "0 auto" }}>
							<Typography mb={6} sx={{ fontSize: "42px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#245C74" }}>
								{userState.user?.name}, you are amazing!
							</Typography>
							<Box sx={{ width: "156px", position: "absolute", top: "-246px", left: "-140px" }}>
								<img width="100%" src={octa} alt="guide-chara" />
							</Box>
						</Box>
						<Box sx={{ width: "70%", m: "0 auto" }}>
							<Typography mb={2} sx={{ fontSize: { xs: "18px", sm: "22px" }, fontFamily: "Open Sans", textAlign: "center", fontWeight: "medium", color: "#245C74" }}>
								It’s great to see how committed you are to designing a meaningful life.<br /><br />

								We are working day and night (though mostly day, because we hate burnout ) to bring the quest to you as soon as
								possible. So, please bear with us! We’ve got your email and will let you know as soon as it’s ready.
								In the meantime, check out our tips for living life on your terms.
							</Typography>
						</Box>
					</Box>
					<Box sx={{ width: "300px", m: "40px auto 0" }}>
						<a className="textecotationLinks" href="https://www.yourlifestartup.com/tips-to-change-your-life"><ButtonStyled fullWidth title="Read Blog" /></a>
					</Box>
				</Box>
				<Box sx={{ display: { xs: "block", sm: "none" }, p: "40px 15px 0" }}>
					<Box sx={{ position: "relative" }}>
						<Box sx={{ width: "109px", m: "0 auto" }}>
							<img width="100%" src={octa} alt="guide-chara" />
						</Box>
						<Typography mb={2} sx={{ fontSize: "24px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#245C74" }}>
							{userState.user?.name}, you are amazing!
						</Typography>
						<Typography mb={2} sx={{ fontSize: { xs: "16px", sm: "22px" }, fontFamily: "Open Sans", textAlign: "center", fontWeight: "medium", color: "#245C74" }}>
							It’s great to see how committed you are to designing a meaningful life.<br />

							We are working day and night (though mostly day, because we hate burnout ) to bring the quest to you as soon
							as possible. So, please bear with us! We’ve got your email and will let you know as soon as it’s ready.
							In the meantime, check out our tips for living life on your terms.
						</Typography>
					</Box>
				</Box>
				<Box pb={3} sx={{ display: { xs: "block", sm: "none" } }}>
					<Box sx={{ width: "90%", m: "0 auto" }}>
						<Box>
							<a className="textecotationLinks" href="https://www.yourlifestartup.com/tips-to-change-your-life"><ButtonStyled fullWidth title="Read Blog" /></a>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default TemporaryPage;
