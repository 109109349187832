import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import bgRadialNight from "../../../assets/images/bgRadialNight.png";
import ContentAdmin from "../../../components/contentAdmin";
import PanelTitle from "../../../components/missions/PanelTitle";
import RadialButton from "../../../components/RadialButton";
import useMission from "../hooks";

const RadialChartNight = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setBlurBg(false);

		missionContext.setStep((prevState) => prevState + 1);
	}

	return (
		<Box ref={ref} {...props} sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { xl: "980px", xs: "802px" }, m: "10px auto 0px" }}>
			<Box sx={{ maxWidth: "100%", mt: "-5px" }}>
				<PanelTitle
					positionOcta
					color="#E9EDF0"
					imageVariation="closeEye"
					title={<ContentAdmin limit={111} keyContent="contentMissionFive" keyText="radial_chart_night_title" defaultValue="{NAME}, it’s been a long day!" position="right" />}
				/>
				<Box
					onClick={nextStep}
					sx={{
						width: { xl: "574px", xs: "444px" },
						height: { xl: "574px", xs: "444px" },
						borderRadius: "574px",
						margin: "80px auto 0px",
						background: `url(${ bgRadialNight }) center center/ 110%`,
						position: "relative",
						overflow: "hidden",
						border: "1px solid rgba(255, 255, 255, 0.5)",
						cursor: "pointer",
						boxShadow: "0px 4px 6px rgba(73, 140, 168, 0.3)"
					}}>
					<Box
						className="radialNight"
						sx={{
							width: "404px",
							height: "287px",
							transform: "rotate(-225deg)",
							border: "2px solid #FFFFFF",
							position: "absolute",
							top: { xl: "393px", xs: "323px" },
							left: { xl: "47px", xs: "-22px" },
							zIndex: 2,
							overflow: "hidden",
							filter: "drop-shadow(-3px 3px 10px rgba(28, 130, 170, 0.659))"
						}} />
					<Box
						className="bgGr"
						sx={{
							backdropFilter: "blur(35px)",
							border: "1px solid rgba(255, 255, 255, 0.5)",
							width: { xl: "153px", xs: "110px" },
							height: { xl: "153px", xs: "110px" },
							borderRadius: "100px",
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							position: "absolute",
							top: { xl: "215px", xs: "calc(50% - 55px)" },
							left: { xl: "215px", xs: "calc(50% - 55px)" },
							zIndex: 222
						}}>
						<Box sx={{ ml: "60px" }}>
							<RadialButton sx={{ "& .RadialButton-iconContainer": { boxShadow: "none" } }} value="Peak Moments" showValue={false} blip />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
});

export default RadialChartNight;
