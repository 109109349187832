import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Love from "../../../../assets/images/Love.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function LoveDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Love} alt="Love" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Love
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="{NAME}, All You Need Is Love, right? Well, that’s a bit of an exaggeration, of course, but love is an important aspect of life. While we are all different when it comes to love, it’s important to understand your needs and fulfil them." keyContent="contentMissionOne" keyText="loveDescScreen1" />
				{/* {localStorage.getItem('name')}, All You Need Is Love, right? Well, that’s a bit of an exaggeration, of course, but love is an important aspect of life. While we are all different when it comes to love, it’s important to understand your needs and fulfil them. */}
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>

						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="{NAME}, if you have a romantic partner, it’s time to take a hard look at what needs fixing in a relationship. Or maybe it’s time for a new chapter all together.\\n\\nIf you don’t have a significant other, but want one, what are the steps you can take to make it happen? Sign up for a group class, a meetup, join friends for dinner parties — explore every opportunity to meet someone." keyContent="contentMissionOne" keyText="loveDescStart" />
							{/*
							{localStorage.getItem('name')}, if you have a romantic partner, it’s time to take a hard look at what needs fixing in a relationship. Or maybe it’s time for a new chapter all together.<br></br><br></br>
							If you don’t have a significant other, but want one, what are the steps you can take to make it happen? Sign up for a group class, a meetup, join friends for dinner parties — explore every opportunity to meet someone.
							*/}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Do you have time and space for love in your life?\n- Have you dealt with the trauma from past relationships?\n- Do you feel in a good place as an individual to attract healthy love?" keyContent="contentMissionOne" keyText="loveDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Scoring low on Love often starts with self-love. To meet someone great or build a meaningful relationship with your partner, you need to be in the right energy.\\n\\n{NAME}, you may have noticed that most people are more attracted to confident, fulfilled people living their dream life. How can you become such a person? Do you need to do more of what you love? Take better care of your health? Have more fun in life?\\n\\nLife Strategy Quest will help you find answers to all these questions and become the person who attracts love wherever you go." keyContent="contentMissionOne" keyText="loveDescScreen2" />
				{/*
				Scoring low on Love often starts with self-love. To meet someone great or build a meaningful relationship with your partner, you need to be in the right energy.<br></br><br></br>
				{localStorage.getItem('name')}, you may have noticed that most people are more attracted to confident, fulfilled people living their dream life. How can you become such a person? Do you need to do more of what you love? Take better care of your health? Have more fun in life?<br></br><br></br>
				Life Strategy Quest will help you find answers to all these questions and become the person who attracts love wherever you go.
				*/}
			</Typography>

		</Box>
	);
}

export default LoveDetails;
