import { alpha, Box, SxProps, Theme } from "@mui/material";
import { forwardRef, MouseEvent, Ref } from "react";

export interface ICharButtonProps
{
	char: string;
	value: string;
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	/**
	 * @default false
	 */
	selected?: boolean;
	/**
	 * @default false
	 */
	active?: boolean;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function CharButton(
	{
		char,
		value,
		onClick,
		selected = false,
		active = false,
		sx = [],
		...props
	}: ICharButtonProps,
	ref: Ref<HTMLDivElement>
)
{
	return (
		<Box
			component="button"
			className="CharButton-root"
			ref={ref}
			sx={[
				{
					position: "relative",
					padding: "12px 0 12px 41px",
					background: "unset",
					border: "unset",

					"&:hover":
					{
						cursor: "pointer"
					},
					"&:hover .CharButton-value::before":
					{
						display: "none"
					},
					"&:hover .CharButton-value::after":
					{
						transition: "linear 300ms opacity",
						opacity: 1
					}
				},
				active === true &&
				{
					"& .CharButton-value":
					{
						background: alpha("#3E809D", 0.5)
					}
				},
				(selected === true || active === true) &&
				{
					"& .CharButton-value::before":
					{
						display: "none"
					},
					"& .CharButton-value::after":
					{
						transition: "linear 300ms opacity",
						opacity: 1
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			onClick={onClick}
			{...props}
		>
			<Box
				className="CharButton-char"
				sx={{
					"--size": { xl: "80px", lg: "64px", xs: "64px" },
					position: "absolute",
					zIndex: 2,
					left: "0px",
					top: "0px",
					backgroundColor: "rgba(239, 239, 239, 0.65)",
					backdropFilter: "blur(30px)",
					border: "2px solid #FFFFFF",
					borderRadius: "50%",
					width: "var(--size)",
					height: "var(--size)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
					color: "primary.main"
				}}
			>
				{char}
			</Box>

			<Box
				className="CharButton-value"
				aria-label={value}
				sx={{
					position: "relative",
					padding: { xl: "12px 80px", lg: "9px 40px", xs: "9px 40px" },
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					backdropFilter: "blur(30px)",
					boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "40px",
					whiteSpace: "nowrap",
					font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },

					"&::after, &::before":
					{
						content: "attr(aria-label)"
					},
					"&::before":
					{
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",

						color: "primary.dark"
					},
					"&::after":
					{
						opacity: 0,

						fontWeight: 700,
						color: "#FFFFFF"
					}
				}}
			/>
		</Box>
	);
}

export default forwardRef(CharButton);
