import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { useState } from "react";

function FirstStepSection()
{
	const [ activeLearnMore, setActiveLearnMore ] = useState(true);

	return (
		<Box>
			<Box sx={{ pb: { xs: "800px", sm: "270px" }, position: "relative" }}>
				<Box sx={{ zIndex: "2", position: "absolute", top: 0, width: "100%" }}>
					<Grid container justifyContent="space-between" sx={{ width: { xs: "100%", md: "98%", xl: "90%" } }}>
						<Grid
							className="paddingFirstStep"
							item
							sx={{
								pl: { xs: 0, md: "80px !important", xl: "180px !important" },
								pt: { xs: 0, md: "50px !important", xl: "101px !important" },
								border: { xs: "3px solid #fff", sm: "1px solid #fff" },
								borderLeft: { xs: "1px solid #fff", sm: "none" },
								borderRight: "1px solid #fff !important",
								background: "linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))",
								borderTopRightRadius: { xs: "0", sm: "20px" },
								borderBottomRightRadius: { xs: "0", sm: "20px" },
								// mr: { xs: 0, md: '230px' },
								position: "relative",
								backdropFilter: "blur(100px)"
							}}
						>
							<Typography sx={{ fontFamily: "Lora-Bold", fontSize: { xs: "32px", sm: "60px" }, color: "#fff", textAlign: { xs: "center", sm: "left" } }}>Your First Step</Typography>
							<Typography sx={{ fontFamily: "Open Sans", fontSize: "34px", color: "#fff", display: { xs: "none", sm: "block" } }}>Take the test and understand what’s<br /> missing in your life right now.</Typography>
							<Typography sx={{ fontFamily: "Open Sans", fontSize: "16px", color: "#fff", display: { xs: "block", sm: "none" }, textAlign: "center", mt: { xs: "15px", sm: 0 } }}>Take the test and understand what’s missing <br /> in your life right now.</Typography>
							<Typography mt={3} sx={{ fontFamily: "Lora-Bold", fontSize: { xs: "20px", sm: "24px" }, color: "#fff", textAlign: { xs: "center", sm: "left" } }}>Gain clarity on:</Typography>
							<Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, mt: "10px" }}>
								<Box>
									<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
										<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
										<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>What’s working in your life</Typography>
									</Box>
									<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
										<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
										<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>What are the gaps</Typography>
									</Box>
									<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
										<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
										<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>What needs recalibrating</Typography>
									</Box>
								</Box>
							</Box>
							<Box sx={{
								border: "2px solid #fff",
								p: "20px",
								borderRadius: "20px",
								width: "85%",
								backdropFilter: "blur(80px)",
								display: { xs: "flex", sm: "none" },
								m: "50px auto",
								alignItems: "center",
								justifyContent: "center"
							}}
							>
								<Box sxs={{ ml: "-10px" }}>
									<img height="100%" src="icons/imgMobile.svg" alt="Pic" />
								</Box>
							</Box>
							<Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "flex-end" }, p: "10px 20px" }}>
								<Box onClick={() => setActiveLearnMore(!activeLearnMore)} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
									<Typography sx={{ fontFamily: "Lora-Bold", fontSize: "24px", color: "#fff" }}>Learn more</Typography>
									<KeyboardArrowDownIcon sx={{ fill: "#fff", fontSize: "40px" }} />
								</Box>
							</Box>
							<Box sx={{ position: "absolute", top: { md: "-60px", xl: "-80px" }, right: { md: "-80px", xl: "-120px" }, display: { xs: "none", sm: "block" }, width: { md: "160px", xl: "246px" } }}>
								<img width="100%" height="100%" src="icons/guide-chara.png" alt="" />
							</Box>
						</Grid>
						<Grid
							className="bgFirst"
							item
							xs={12}
							sm={4}
							sx={{
								border: "1px solid #fff",
								background: "linear-gradient(to right bottom, rgba(239,239,239,0.3 ), rgba(0,0,0,0.10196078431372549 ))",
								p: "45px",
								maxHeight: "559px",
								borderRadius: "20px",
								maxWidth: { xs: "100%", sm: "549px !important" },
								backdropFilter: "blur(20px)",
								display: { xs: "none", sm: "flex" },
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Box>
								<img height="100%" src="icons/bgFirst.svg" alt="Pic" />
							</Box>
						</Grid>
					</Grid>
					{!activeLearnMore &&
						(
							<Box sx={{
								border: { xs: "2px solid #fff", sm: "1px solid #fff" },
								background: "linear-gradient(to right bottom, rgba(126,196,255,0.2), rgba(0,0,0,0.10196078431372549 ))",
								borderRadius: "20px",
								width: "86.3%",
								margin: { xs: "50px auto 0", sm: "110px auto 0" },
								backdropFilter: "blur(80px)"
							}}
							>
								<Grid container>
									<Grid item xs={12} sm={6} sx={{ p: { xs: "25px 25px 0", sm: "24px" } }}>
										<Typography sx={{ fontFamily: "Lora-Bold", fontSize: "24px", color: "#fff" }}>About the assessment</Typography>
										<Typography sx={{ fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, color: "#fff", lineHeight: { xs: "22px", sm: "30px" } }}>
											When something is not working in life, it might be hard to pinpoint exactly what is missing.
											We designed this simple tool to help you take a ‘helicopter view’ of your life and see what
											needs recalibrating.<br /><br />
											You will get a graphical representation of the present balance in your life and identify the areas of your
											life that need the most attention from you.
										</Typography>
									</Grid>
									<Grid p={4} item xs={12} sm={6}>
										<Typography sx={{ fontFamily: "Lora-Bold", fontSize: "24px", color: "#fff" }}>What will I get?</Typography>
										<Box sx={{ mt: "15px" }}>
											<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
												<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
												<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>Your Wheel of Life chart</Typography>
											</Box>
											<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
												<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
												<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>Detailed report with recommendations</Typography>
											</Box>
											<Box sx={{ display: "flex", mt: { xs: "4px", sm: "10px" } }}>
												<CircleIcon sx={{ fill: "#fff", fontSize: { xs: "14px", sm: "10px" }, mr: { xs: "10px", sm: "15px" } }} />
												<Typography sx={{ color: "#fff", fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, mt: { xs: "-5px", sm: "-12px" }, width: { xs: "100%" } }}>Your personalized action plan</Typography>
											</Box>
										</Box>
										<Typography mt={2} sx={{ fontFamily: "Lora-Bold", fontSize: "24px", color: "#fff" }}>How long does it take?</Typography>
										<Typography sx={{ fontFamily: "Open Sans", fontSize: { xs: "16px", sm: "24px" }, color: "#fff", lineHeight: { xs: "22px", sm: "30px" } }}>
											Just a couple of minutes. You will answer 8 simple questions and have access to the report right away.
										</Typography>
									</Grid>
								</Grid>
							</Box>
						)
					}
				</Box>
			</Box>
			<Box sx={{ position: "relative", mt: { xs: activeLearnMore ? "-130px" : "-100px", sm: "0" } }}>
				<Box sx={{
					zIndex: "-2",
					width: "100%",
					height: { xs: activeLearnMore ? "262px" : "812px", sm: "100%" },
					background: { xs: activeLearnMore ? "url(icons/Back-Pic.png) no-repeat 100% / cover" : "url(icons/Back-Pic2.png) no-repeat 100% / cover", sm: "none" }
				}}
				>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						{activeLearnMore
							? <img width="100%" src="icons/Back-Pic.png" alt="Pic" /> :
							<img width="100%" src="icons/Back-Pic2.png" alt="Pic" />}
					</Box>
				</Box>
				<Box sx={{ position: "absolute", top: "0px", zIndex: "0", width: "100%", display: { xs: "none", sm: "block" } }}>
					<img width="100%" src="icons/Pathxc.svg" alt="Pic" />
				</Box>
				<Box sx={{ position: "absolute", top: "0px", zIndex: "0", width: "100%", display: { xs: "none", sm: "block" } }}>
					<img width="100%" src="icons/Path332.png" alt="Pic" />
				</Box>
				<Box sx={{ position: "absolute", bottom: "-3px", zIndex: "0", width: "100%", background: { xs: "url(icons/Waves-Bottom.png) no-repeat 100% / cover", sm: "none" }, height: { xs: "100px", sm: "auto" } }}>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						<img width="100%" src="icons/Waves-Bottom.png" alt="Pic" />
					</Box>
				</Box>
			</Box>
			<Box className={!activeLearnMore ? "marginLargeScreenFirstStepActive" : "marginSmallScreen marginLargeScreenFirstStep"} sx={{ width: { xs: "85%", md: "400px" }, margin: { xs: activeLearnMore ? "0px auto 0" : "-30px auto 0", sm: "0px auto 0" } }}>
				<Link className="btnFirstStep" sx={{ textDecoration: "none" }} href="https://forms.gle/wDrezUrT2PVtnUV59">
					<Button>Take the Assessment</Button>
				</Link>
				<Typography mt={2} sx={{ fontFamily: "Open Sans", fontSize: "20px", color: "#fff", textAlign: "center" }}>Only takes 2 minutes</Typography>
			</Box>
		</Box>
	);
}

export default FirstStepSection;
