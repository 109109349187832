import { Box } from "@mui/material";
import GhostButton from "../../../../components/GhostButton";

interface ButtonsPorps
{
	onOpen(id: number): void;
	setSelectedGhost(value: number): void;
	ghostValues: any;
}

function Buttons({ onOpen, setSelectedGhost, ghostValues }: ButtonsPorps)
{
	function handelClick(e: any)
	{
		let elem = e.target;
		let parent = elem.closest("button");

		parent.style.display = "none";
	}

	return (
		<Box className="buttonsBox" sx={{ position: "relative", width: "700px", m: { lg: "70px auto 0px", xl: "140px auto 0px" }, transform: { lg: "scale( 0.8)", xl: "scale( 1)" } }}>
			<Box sx={{ position: "absolute", top: "144px", left: "-27px" }}>
				<GhostButton
					onClick={(e) => { onOpen(1); setSelectedGhost(1); handelClick(e); }}
					selectedBtn={ghostValues[ 0 ].changed}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "-8px", right: "50%" }}>
				<GhostButton
					isTransform
					onClick={(e) => { onOpen(2); setSelectedGhost(2); handelClick(e); }}
					selectedBtn={ghostValues[ 1 ].changed}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "46px", right: "-14px" }}>
				<GhostButton
					onClick={(e) => { onOpen(3); setSelectedGhost(3); handelClick(e); }}
					selectedBtn={ghostValues[ 2 ].changed}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "309px", left: "164px" }}>
				<GhostButton
					onClick={(e) => { onOpen(4); setSelectedGhost(4); handelClick(e); }}
					selectedBtn={ghostValues[ 3 ].changed}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "181px", right: "174px" }}>
				<GhostButton
					isTransform
					onClick={(e) => { onOpen(5); setSelectedGhost(5); handelClick(e); }}
					selectedBtn={ghostValues[ 4 ].changed}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "357px", right: "17px" }}>
				<GhostButton
					onClick={(e) => { onOpen(6); setSelectedGhost(6); handelClick(e); }}
					selectedBtn={ghostValues[ 5 ].changed}
				/>
			</Box>
		</Box>
	);
}

export default Buttons;
