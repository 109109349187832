import { alpha, Box, Grow, SxProps, TextField, Theme, Tooltip, Zoom } from "@mui/material";
import { ChangeEvent, forwardRef, KeyboardEvent, Ref, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import deepClone from "../../../../utils/deepClone";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import randomInt from "../../../../utils/randomInt";
import IMGBubbleHighlight from "../../assets/images/bubble-highlight.png";
import IMGBubble from "../../assets/images/bubble.png";
import useMission from "../../hooks/useMission";
import IMGTree from "./assets/tree.png";
import ModalBubble from "./components/ModalBubble";

export type IRewriteYourLimitingBeliefsProps = Record<string, any>;

type ItemInfo = [ size: number, position: SxProps<Theme>, title: string ];

const MIN_REQUIRED_INPUT_LENGTH: number = 2;
const MAX_INPUT_LENGTH: number = 70;

function RewriteYourLimitingBeliefs({ ...props }: IRewriteYourLimitingBeliefsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ selectedValueIndex, setSelectedValueIndex ] = useState<number | null>(null);
	const [ prevValue, setPrevValue ] = useState<string | null>(null);
	const [ items ] = useState<ItemInfo[]>(() =>
	{
		const store = getLocalStorage<string[]>("mission-seven-top5");

		if (store === null)
		{
			missionContext.setStep(11);

			return [];
		}

		const items: ItemInfo[] = [];

		for (const element of store)
		{
			const size = randomInt(65, 80);
			// const size = 80;

			const horizontalDirection = (randomInt(0, 1) === 0 ? "top" : "bottom");
			const verticalDirection = (randomInt(0, 1) === 0 ? "left" : "right");

			const offsetY = randomInt(10, 20) * -1;
			const offsetX = randomInt(10, 20) * -1;

			items.push([
				size,
				{
					[ horizontalDirection ]: offsetY,
					[ verticalDirection ]: offsetX
				},
				element
			]);
		}

		return items;
	});
	const [ values, setValues ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>("mission-seven-top-rewrite");

		if (store === null)
		{
			return new Array(items.length).fill("");
		}

		return store;
	});

	// Handles
	function onChange({ target }: ChangeEvent<HTMLInputElement>)
	{
		setValues((prevState) =>
		{
			const items = deepClone(prevState);

			items[ selectedValueIndex as number ] = target.value.slice(0, 70);

			return items;
		});
	}

	function onKeyDown({ key }: KeyboardEvent<HTMLInputElement>)
	{
		if (key !== "Enter")
		{
			return;
		}

		setPrevValue(null);
		setSelectedValueIndex(null);
	}

	function onNextStep()
	{
		setLocalStorage("mission-seven-top-rewrite", values);

		setTimeout(() => missionContext.nextStep(), 0);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_title" defaultValue="Rewrite your limiting beliefs" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_subTitle" defaultValue="Work through each of your limiting beliefs one by one and reframe them into empowering statements." position="left" isDescription />}
					sx={{
						marginBottom: "80px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { left: "-35px" }
					}}
				/>

				<Box sx={{
					width: "530px",
					height: "274px",
					position: "relative",
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					gap: "70px"
				}}>
					{items.map(([ size, position ], index) =>
					{
						return (
							<Zoom key={index} in timeout={size * 11} style={{ transitionDelay: "120ms" }}>
								<Box
									component="img"
									src={
										(selectedValueIndex === index ?
											(prevValue || "").length >= 2 :
											values[ index ].length >= MIN_REQUIRED_INPUT_LENGTH
										)
											? IMGBubbleHighlight
											: IMGBubble
									}
									alt={"Bubble " + index}
									sx={{
										position: "relative",
										width: size + "px",
										height: size + "px",
										cursor: "pointer",
										...position
									}}
									onClick={() => { setPrevValue(values[ index ]); setSelectedValueIndex(index); }}
								/>
							</Zoom>
						);
					})}
				</Box>

				{/* Tree (Left to Right) */}
				<>
					<TreeImg
						left={130}
						bottom={170}
						width={128}
						tooltipTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip1_title" defaultValue="Being a woman means more work for less money" position="right" isDescription />}
						tooltipSubTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip1_subTitle" defaultValue="I am rewarded generously for what I do" position="right" isDescription />}
					/>
					<TreeImg
						right={190}
						bottom={130}
						width={97}
						tooltipTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip2_title" defaultValue="Being in a relationship means hard work" position="right" isDescription />}
						tooltipSubTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip2_subTitle" defaultValue="Relationships are a worthwhile investment." position="right" isDescription />}
					/>
					<TreeImg
						right={60}
						bottom={-30}
						width={144}
						tooltipTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip3_title" defaultValue="I am not skilled enough" position="right" isDescription />}
						tooltipSubTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip3_subTitle" defaultValue="With the right focus, I can learn any skill I want." position="right" isDescription />}
					/>
					<TreeImg
						right={80}
						bottom={190}
						width={88}
						tooltipTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip4_title" defaultValue="I am bad with money" position="right" isDescription />}
						tooltipSubTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip4_subTitle" defaultValue="Learning how money works is accessible to me and money is my friend." position="right" isDescription />}
					/>
					<TreeImg
						right={-140}
						bottom={300}
						width={120}
						tooltipTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip5_title" defaultValue="I should work hard" position="right" isDescription />}
						tooltipSubTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tooltip5_subTitle" defaultValue="I am effective, work is easy and fun." position="right" isDescription />}
					/>
				</>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					onClick={onNextStep}
					disabled={!values.every((value) => value.length >= MIN_REQUIRED_INPUT_LENGTH)}
				/>
			</StepperNavigationWrapper>

			<ModalBubble
				TransitionComponent={Grow}
				open={selectedValueIndex !== null}
				onClose={() => { setPrevValue(null); setSelectedValueIndex(null); }}
				sx={{
					"& .MuiPaper-root":
					{
						"--size": { xl: "480px", lg: "350px", xs: "350px" },
						flexDirection: "column",
						gap: { xl: "24px", lg: "16px", xs: "16px" },

						width: "var(--size) !important",
						height: "var(--size) !important",
						padding: { xl: "85px !important", lg: "64px !important", xs: "64px !important" }
					}
				}}
			>
				{selectedValueIndex !== null &&
					(
						<>
							<Box sx={{}}>
								{items[ selectedValueIndex ][ 2 ]}
							</Box>

							<Box sx={{ position: "relative" }}>
								<TextField
									autoFocus
									multiline
									placeholder="Type your new empowering belief here"
									rows={6}
									value={values[ selectedValueIndex ]}
									onChange={onChange}
									onKeyDown={onKeyDown}
									inputProps={{ maxLength: MAX_INPUT_LENGTH }}
									sx={{
										width: { xl: "309px", lg: "224px", xs: "224px" },
										border: "unset",
										"& .MuiInputBase-root":
										{
											backgroundColor: alpha("#FFFFFF", 0.5),
											padding: "13px 16px",
											borderRadius: "8px"
										},
										"& .MuiInputBase-input":
										{
											padding: "0"
										},
										"& .MuiOutlinedInput-notchedOutline":
										{
											display: "none"
										}
									}}
								/>

								<Box sx={{
									position: "absolute",
									right: "10px",
									font: "400 14px/19px 'Open Sans'",
									color: "primary.dark",
									opacity: 0.5
								}}>
									{values[ selectedValueIndex ].length}/{MAX_INPUT_LENGTH} characters
								</Box>
							</Box>
						</>
					)
				}
			</ModalBubble>
		</Box>
	);
}

type TreeImgProps = Partial<Record<"left" | "right" | "bottom" | "width", number>> & Record<"tooltipTitle" | "tooltipSubTitle", JSX.Element>;

function TreeImg({ left, right, bottom, tooltipTitle, tooltipSubTitle, width = 144 }: TreeImgProps)
{
	return (
		<Tooltip
			placement="top"
			title={(
				<>
					{tooltipTitle}

					<svg
						width="24"
						height="43"
						viewBox="0 0 24 43"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{ margin: "10px 0" }}>
						<path d="M10.9393 42.0607C11.5251 42.6464 12.4749 42.6464 13.0607 42.0607L22.6066 32.5147C23.1924 31.9289 23.1924 30.9792 22.6066 30.3934C22.0208 29.8076 21.0711 29.8076 20.4853 30.3934L12 38.8787L3.51472 30.3934C2.92893 29.8076 1.97918 29.8076 1.3934 30.3934C0.80761 30.9792 0.80761 31.9289 1.3934 32.5147L10.9393 42.0607ZM10.5 -6.55671e-08L10.5 41L13.5 41L13.5 6.55671e-08L10.5 -6.55671e-08Z" fill="#3E809D" fill-opacity="0.5" />
					</svg>

					<strong>{tooltipSubTitle}</strong>
				</>
			)}
			componentsProps={{
				tooltip:
				{
					sx:
					{
						maxWidth: "427px",
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(30px)",
						boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "24px",
						padding: { xl: "23px 46px 33px 23px", lg: "16px", xs: "16px" },
						font: { xl: "400 24px/33px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "primary.dark"
					}
				},
				popper: { sx: { zIndex: "1299" } }
			}}
			TransitionComponent={Zoom}
		>
			<Box
				component="img"
				src={IMGTree}
				alt="Tree help"
				sx={[
					{
						position: "absolute",
						width: width + "px"
					},
					left !== undefined && { left: left + "px" },
					right !== undefined && { right: right + "px" },
					bottom !== undefined && { bottom: bottom + "px" }
				]}
			/>
		</Tooltip>
	);
}

export default forwardRef(RewriteYourLimitingBeliefs);
