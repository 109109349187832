import Api, { getAccessToken } from "api";
import { IGetDataMissionTwo } from "./Missions";

export type GetResponseBotTaskType = "returnProfessions" | "description" | "summary" | "recommendations" | "personBased";

type getResponseBotReturn =
	| { personBased: string[]; }
	| { personBased: string[]; }[]
	| { recommendations: IGetDataMissionTwo[ "recommendation" ]; }
	| { text: string; }
	| { professions: string[]; }
	| { descJob: [ string, string, string, string, string ]; };

export default class Bot
{
	async getResponseBot(text: string[], task: "personBased"): Promise<{ personBased: string[]; }[]>;
	async getResponseBot(text: string, task: "personBased"): Promise<{ personBased: string[]; }>;
	async getResponseBot(text: string, task: "summary"): Promise<{ text: string; }>;
	async getResponseBot(text: string, task: "returnProfessions"): Promise<{ professions: string[]; }>;
	async getResponseBot(text: string, task: "recommendations"): Promise<{ recommendations: IGetDataMissionTwo[ "recommendation" ]; }>;
	async getResponseBot(text: string, task: "description"): Promise<{ descJob: [ string, string, string, string, string ]; }>;
	async getResponseBot(text: string | string[], task: GetResponseBotTaskType): Promise<getResponseBotReturn>
	{
		return (
			await Api.instance.post<getResponseBotReturn>(
				"/bot/getResponseBot",
				{
					text,
					...(task !== undefined && { task })
				},
				{
					headers: {
						"Content-Type": "application/json",
						Cookie: "refreshToken=" + Api.refreshToken,
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
