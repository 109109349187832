import CancelIcon from "@mui/icons-material/Cancel";
import { Box, BoxProps, CircularProgress, alpha } from "@mui/material";
import Api from "api";
import { IGetMissionsThreeUniverseData, IMissionThereUniverseFields } from "api/Missions";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import useMission from "../../hooks/useMission";

export interface IJobsAttractYouModalsProps extends BoxProps
{
	type: keyof IGetMissionsThreeUniverseData[ "data" ][ "DreamUpYourUniversesForm" ];
}

function Modal({ type, ...props }: IJobsAttractYouModalsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const [ universeData, setUniverseData ] = useState<null | IMissionThereUniverseFields>(null);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	useLayoutEffect(() =>
	{
		if (isFetch === true)
		{
			return;
		}

		Api.missions
			.getMissionsThreeUniverseData()
			.then(({ data: responseData }) =>
			{
				const data = responseData.DreamUpYourUniversesForm[ type ];

				setUniverseData(data);
			})
			.finally(() => setIsFetch(false));
	}, []); // eslint-disable-line

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobsAttractYou_title" defaultValue="Jobs that attract you" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobsAttractYou_subTitle" defaultValue="Write down all the jobs, careers and roles that attract you. Those don’t\nhave to be realistic, go wild! You can use your dream universes as a starting point!" position="left" isDescription />}
					positionOcta
				/>

				<Panel sx={{
					marginTop: "100px",
					padding: "30px",
					width: "820px !important",
					height: "474px",
					position: "relative"
				}}>
					<CancelIcon
						onClick={() => { missionContext.setExtraStep(null); missionContext.setIsShowTour(false); }}
						sx={{
							position: "absolute",
							top: "32px",
							right: "32px",
							fill: "#215C75",
							opacity: 1,

							"&:hover": { opacity: 0.5, cursor: "pointer" }
						}}
					/>

					{isFetch === true &&
						(
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								width: "100%",
								gap: "15px",
								font: "700 24px/36px Lora-Bold",
								color: "primary.main",
								textAlign: "center"
							}}>
								Loading data <CircularProgress size={24} />
							</Box>
						)
					}

					{(isFetch === false && universeData === null) &&
						(
							<Box sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								width: "100%",
								gap: "15px",
								font: "700 24px/36px Lora-Bold",
								color: "primary.main",
								textAlign: "center"
							}}>
								Failed to retrieve data, please try again
							</Box>
						)
					}

					{(isFetch === false && universeData !== null) &&
						(
							<>
								<Box sx={{ font: "700 42px/54px Lora-Bold", color: "primary.main" }}>
									{universeData?.title}
								</Box>

								<Box component="hr" sx={{ borderBottom: "2px solid " + alpha("#3E809D", 0.5), margin: "30px 0" }} />

								<Box sx={{ color: "primary.dark", font: "400 22px/30px 'Open Sans'" }}>
									<ContentAdmin
										keyContent={missionContext.keyContent}
										keyText="JobsAttractYou_modal1_desc"
										defaultValue="In this universe, I am a {FIELD1}. I live in {FIELD2} with {FIELD3}. I make money by {FIELD4}. In my free time, I {FIELD5}. The best thing about my life is that I can {FIELD6}"
										position="left"
										isDescription
										replacer={{
											"{FIELD1}": universeData.fields[ 0 ],
											"{FIELD2}": universeData.fields[ 1 ],
											"{FIELD3}": universeData.fields[ 2 ],
											"{FIELD4}": universeData.fields[ 3 ],
											"{FIELD5}": universeData.fields[ 4 ],
											"{FIELD6}": universeData.fields[ 5 ]
										}}
									/>
								</Box>
							</>
						)
					}
				</Panel>
			</Box>
		</Box>
	);
}

export const JobsAttractYouModal = forwardRef(Modal) as ForwardRefExoticComponent<IJobsAttractYouModalsProps>;
