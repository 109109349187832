import { Box, Grow } from "@mui/material";
import { ReactComponent as SVGIconContentCopy } from "assets/images/icons/size-32/content-copy.svg";
import IMGOcta from "assets/images/octa.png";
import TooltipStyled from "components/TooltipStyled";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Dialog, { IDialogProps } from ".";

export interface IReferFriendsModalProps extends IDialogProps
{
	/**
	 * @default false
	 */
	open: boolean;
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton"): void;
}

function ReferFriendsModal({ open = false, onClose, ...props }: IReferFriendsModalProps)
{
	const [ isCopied, setIsCopied ] = useState<boolean>(false);
	const [ isShowTooltip, setIsShowTooltip ] = useState<boolean>(false);

	// Handles
	function onCopy()
	{
		setIsCopied(true);

		setTimeout(() => setIsCopied(false), 1000);
	}

	// Render
	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Refer a friend"
			sx={{
				"& .MuiPaper-root":
				{
					gap: "32px",
					minHeight: { xl: "488px", sm: "320px" },
					"& *:not(img)": { zIndex: 1 }
				}
			}}
			{...props}
		>
			<Box sx={{
				overflow: "hidden",
				position: "absolute",
				top: { xl: "36px", sm: "33px", xs: "unset" },
				bottom: { sm: "unset", xs: "0" },
				right: { sm: "80px", xs: "0" },
				transform: { sm: "rotate(4.15deg)" },
				width: { xl: "248px", sm: "158px", xs: "106px" },
				height: { sm: "unset", xs: "190px" },

				"& img":
				{
					width: { sm: "100%", xs: "202px" },
					transform: { sm: "unset", xs: "rotate(-16.4deg)" }
				}
			}}>
				<img src={IMGOcta} alt="" />
			</Box>

			<Box sx={{
				font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: "primary.dark",
				width: { sm: "568px", xs: "280px" }
			}}>
				I came across this cool life assessment tool,<br />
				thought you might find it useful!
			</Box>

			<Box sx={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "auto" }}>
				<Box
					component="a"
					href="https://mylifequest.io/firststep"
					target="_blank"
					rel="noreferrer"
					sx={{
						display: "block",
						padding: { xl: "12px 16px", xs: "12px 24px 12px 16px" },
						backgroundColor: "#3E809D",
						borderRadius: "8px",

						font: { xl: "400 24px/33px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "#FFFFFF",

						"&:visited": { color: "inherit" }
					}}
				>
					https://mylifequest.io/
				</Box>

				<CopyToClipboard onCopy={onCopy} text="https://mylifequest.io/firststep I came across this cool life assessment tool, thought you might find it useful">
					<TooltipStyled
						open={isCopied || isShowTooltip}
						title={isCopied === true || isShowTooltip === false ? "Copied" : "Copy to clipboard"}
						placement="top"
						TransitionComponent={Grow}
						arrow
					>
						<Box
							component={SVGIconContentCopy}
							onMouseEnter={() => setIsShowTooltip(true)}
							onMouseLeave={() => setIsShowTooltip(false)}
							sx={{
								fill: "#215C75",
								cursor: "pointer"
							}}
						/>
					</TooltipStyled>
				</CopyToClipboard>
			</Box>
		</Dialog>
	);
}

export default ReferFriendsModal;
