import { createContext, Dispatch, SetStateAction } from "react";
import { IBackground } from ".";

export interface IMissionContextElevenMission
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof IBackground;
	setBackground: any;

	name: string;
	setName: Dispatch<SetStateAction<string>>;
}

export const MissionCtx = createContext<IMissionContextElevenMission>({} as IMissionContextElevenMission);
