import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks";
import Buttons from "./Buttons";
import SliderBlock from "./feature/SliderBlock";

interface GhostValues
{
	value: number;
	id: number;
	changed: boolean;
}

const titles = [
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="not_want_change"
		defaultValue="You don’t want to change"
		position="top"
	/>,
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="not_know_want_change"
		defaultValue="You don’t know what you want"
		position="top"
	/>,
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="dream_isnt_big"
		defaultValue="Your dream isn’t big enough"
		position="top"
	/>,
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="fear_bigger-than_you"
		defaultValue="You’re letting your fear be bigger than you"
		position="top"
	/>,
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="attached_current_situation"
		defaultValue="You are attached to your current situation"
		position="top"
	/>,
	<ContentAdmin
		limit={111}
		keyContent="contentMissionEight"
		keyText="being_unfulfilled"
		defaultValue="Being unfulfilled has become a habit"
		position="top"
	/>
];
const ReasonsAvoid = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();
	const [ open, setOpen ] = useState(false);
	const [ selectedGhost, setSelectedGhost ] = useState(1);
	const [ showBtnNextStep, setShowBtnNextStep ] = useState(false);
	const [ showBtns, setShowBtns ] = useState(true);
	const [ ghostValues, setGhostValues ] = useState<GhostValues[]>([
		{ value: 1, id: 1, changed: false },
		{ value: 1, id: 2, changed: false },
		{ value: 1, id: 3, changed: false },
		{ value: 1, id: 4, changed: false },
		{ value: 1, id: 5, changed: false },
		{ value: 1, id: 6, changed: false }
	]);

	function onClose()
	{
		setShowBtnNextStep(true);
		setOpen(false);
		setShowBtns(true);
		const buttons = document.querySelectorAll("button");

		buttons.forEach(item =>
		{
			if (item.style.display === "none")
			{
				item.style.display = "";
			}
		});

		localStorage.setItem("mission-eight-reasons-avoid", JSON.stringify(ghostValues));

	}

	function onOpen(id: number)
	{
		setGhostValues(ghostValues.map((item: GhostValues) =>
		{
			if (item.id === id)
			{
				return { ...item, changed: true };
			}

			return item;

		})); setOpen(true);
		setShowBtns(false);
	}

	function handleStep()
	{
		const localValue: any = [];

		ghostValues.map((item: GhostValues) => localValue.push({ id: item.id, value: item.value }));
		localStorage.setItem("reasons-to-avoid-change", JSON.stringify(localValue));
		missionContext.setStep((prevState) => prevState + 1);
	}

	function handleChangeGhostValues(_event: Event, newValue: number)
	{
		setGhostValues(ghostValues.map((item: GhostValues) =>
		{
			if (item.id === selectedGhost)
			{
				return { ...item, value: newValue, changed: true };
			}

			return item;

		}));
	}

	useEffect(() =>
	{
		const newGhostValues = localStorage.getItem("mission-eight-reasons-avoid");
		if (newGhostValues !== null)
		{
			const newGhostValuesConst = JSON.parse(newGhostValues);

			setGhostValues(newGhostValuesConst);
			setShowBtnNextStep(true);
		}
	}, []);

	return (
		<>
			<Box
				ref={ref}
				{...props}
				sx={{
					flexDirection: "column",
					display: "flex",
					justifyContent: "space-between",
					height: "81vh",
					width: "100%",
					maxWidth: { lg: "802px", xl: "980px" },
					m: "10px auto 0px",
					position: "relative",
					top: "-21px",
					left: "-38px"
				}}>
				<Box sx={{ position: "relative" }}>
					<PanelTitle
						color="#ffffff"
						positionOcta
						imageVariation="closeEye"
						title={<ContentAdmin
							limit={111}
							keyContent="contentMissionEight"
							keyText="reasons_avoid_change"
							defaultValue="What are your reasons to avoid change?"
							position="right"
						/>}
						subTitle={<ContentAdmin
							limit={300}
							keyContent="contentMissionEight"
							keyText="reasons_avoid_change_txt"
							defaultValue="Changing your life is not easy, and there are several reasons why you might be avoiding the change. Let’s explore which reasons might apply to you the most."
							position="left"
							isDescription
						/>}
					/>
					{showBtns
						? (
							<Buttons
								onOpen={onOpen}
								setSelectedGhost={setSelectedGhost}
								ghostValues={ghostValues}
							/>
						)
						: null
					}
					{open
						? (
							<Box sx={{ position: "absolute", top: 0, width: "100%" }}>
								<SliderBlock
									onClose={onClose}
									value={ghostValues[ selectedGhost - 1 ].value}
									title={titles[ selectedGhost - 1 ]}
									handleChangeGhostValues={handleChangeGhostValues}
								/>
							</Box>
						)
						: null
					}
				</Box>
			</Box>

			{showBtnNextStep
				? (
					<StepperNavigationWrapper absolute>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton direction="right" onClick={handleStep} />
					</StepperNavigationWrapper>
				)
				: null
			}
		</>
	);
});

export default ReasonsAvoid;
