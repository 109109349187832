import { alpha, Box, BoxProps } from "@mui/material";
import Api from "api";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import useMission from "pages/missionSeven/hooks/useMission";
import { forwardRef, Ref, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import trackEvent from "utils/mixpanelUtil";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import { UserActionType } from "../../../../reducers/userReducer";
import { getLocalStorage } from "../../../../utils/localStorage";
import IMGImage from "./assets/image.png";

export interface ISummaryScreenProps extends BoxProps { }

const TIME_TO_CHANGE_BACKGROUND = 1700;

function SummaryScreen({ ...props }: ISummaryScreenProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();
	const navigate = useNavigate();

	const { state: userState, dispatch: userDispatch } = useUser();

	const [ isShowContent, setIsShowContent ] = useState<boolean>(true);
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ comMission, setComMission ] = useState<boolean>(false);

	// Effects
	useLayoutEffect(() =>
	{
		if (isShowContent === true)
		{
			return;
		}

		let timerId: NodeJS.Timeout;

		if (missionContext.background === "cave1")
		{
			setTimeout(() => missionContext.setBackground("cave2"), TIME_TO_CHANGE_BACKGROUND);
		}
		else if (missionContext.background === "cave2")
		{
			if (comMission)
			{
				missionContext.setFirstPlaythrough(true);
			}

			setTimeout(() => missionContext.setStep(18), TIME_TO_CHANGE_BACKGROUND);

		}

		return () => clearTimeout(timerId);

	}, [ missionContext.background ]); // eslint-disable-line

	// Handles
	function onClickButton()
	{
		if (isLoading === true)
		{
			return;
		}

		const top = getLocalStorage<string[]>("mission-seven-top5");
		if (top === null)
		{
			return missionContext.setStep(11);
		}

		const rewriteTop = getLocalStorage<string[]>("mission-seven-top-rewrite");
		if (rewriteTop === null)
		{
			return missionContext.setStep(14);
		}

		setIsLoading(true);

		Api.missions
			.setDataMissionSeven({ top, rewriteTop })
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(7) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 7 }
					}
				);
				if (userState.user?.completedMission === 6) setComMission(true);
				setTimeout(() => { setIsShowContent(false); missionContext.setIsDisplayHeader(false); }, 0);

				setTimeout(() =>
				{
					missionContext.setBackground("cave1");
				}, TIME_TO_CHANGE_BACKGROUND);
			})
			.catch((error: any) =>
			{
				console.error(error);
			})
			.finally(() =>
			{
				setIsLoading(false);
			});
	}

	// Render
	if (isShowContent === false)
	{
		return null;
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Box sx={{
					display: "flex",
					backgroundColor: alpha("#C0D3DF", 0.7),
					boxShadow: "0px 5px 10px 0px rgba(167, 199, 225, 0.6)",
					border: "1px solid rgba(255, 255, 255, .6)",
					borderRadius: "20px",
					maxWidth: "980px",
					width: "100%",
					height: "447px",
					backdropFilter: "blur(25px)",
					marginTop: "120px"
				}}>
					<img src={IMGImage} alt="" />

					<div style={{ padding: "63px" }}>
						<Box sx={{
							font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 40px/51px Lora-Bold" },
							color: "primary.main"
						}}>
							<ContentAdmin keyContent="contentMissionSeven" keyText="SummaryScreen_title" defaultValue="You are a superhero, {NAME}!" position="left" />
						</Box>

						<Box sx={{
							color: "primary.dark",
							font: "400 22px/30px 'Open Sans'",
							marginTop: "50px"
						}}>
							<ContentAdmin keyContent="contentMissionSeven" keyText="SummaryScreen_text" defaultValue="Did you know that courage is your natural setting? You don’t need to become courageous, but rather peel the layers of limiting beliefs that are keeping you small. And you did an excellent job today.\n\nI hope you are feeling stronger!" position="left" isDescription />
						</Box>
					</div>
				</Box>

				<CustomButton.Contained
					sx={{
						marginTop: "80px",
						paddingLeft: "80px !important",
						paddingRight: "80px !important"
					}}
					onClick={onClickButton}
					loading={isLoading}
				>
					Finish
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default forwardRef(SummaryScreen);
