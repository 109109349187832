import { Box, Grid, Typography } from "@mui/material";
import Friend from "../../../../assets/images/Friend.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function FriendsDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Friend} alt="Friend" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Friends & Family
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="Social connections are the foundation of your emotional and physical wellbeing. When you feel connected to others, you are less prone to anxiety and depression. Loneliness is on the rise in many countries, so you are definitely not alone in feeling somewhat disconnected, {NAME}!" keyContent="contentMissionOne" keyText="friendsScreen1" />
				{/* Social connections are the foundation of your emotional and physical wellbeing. When you feel connected to others, you are less prone to anxiety and depression. Loneliness is on the rise in many countries, so you are definitely not alone in feeling somewhat disconnected, {localStorage.getItem('name')}! */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Do you spend enough time with friends and family?\n- Are you on good terms with every member of your family?\n- Are all your friendships meaningful?\n- How connected are you with your local community?\n- How much time do you spend connecting in real life vs online?\n\nIt might be a good idea to do an audit of your friendships, restore meaningful connections that were lost, cease empty ones, fix any outstanding family issues. Focus on giving, sharing, and doing acts of services or kindness for others." keyContent="contentMissionOne" keyText="friendsDescStart" />
						{/* It might be a good idea to do an audit of your friendships, restore meaningful connections that were lost, cease empty ones, fix any outstanding family issues. Focus on giving, sharing, and doing acts of services or kindness for others. */}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Are you surrounded by like-minded people?\n- Do you feel you belong in your workplace, friends circle, community, country?\n- Does your life design allow enough time for human connection?" keyContent="contentMissionOne" keyText="friendsDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "7px",
						height: "7px",
						borderRadius: "50%",
						backgroundColor: "#215C75"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Feeling disconnected can sometimes mean that you need a change in your environment. We aren’t all born into a community that fits us, and need to find (or create!) our tribe.\\n\\nYou can do that by running life experiments, trying different lifestyle, connecting with diverse people until you find your tribe.\\n\\nWe can help you on that path with Life Strategy Quest, where you will learn what you value the most in life and people. Once you know that, you can use those insights as cues to connect with the right people meaningfully." keyContent="contentMissionOne" keyText="friendsScreen2" />
				{/*
				Feeling disconnected can sometimes mean that you need a change in your environment. We aren’t all born into a community that fits us, and need to find (or create!) our tribe.<br></br><br></br>
				You can do that by running life experiments, trying different lifestyle, connecting with diverse people until you find your tribe.<br></br><br></br>
				We can help you on that path with Life Strategy Quest, where you will learn what you value the most in life and people. Once you know that, you can use those insights as cues to connect with the right people meaningfully.
				*/}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default FriendsDetails;
