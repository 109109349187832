import { alpha, Box, BoxProps, capitalize } from "@mui/material";
import { ChangeEvent, forwardRef, ForwardRefExoticComponent, Ref, useState } from "react";
import { IPointInfo } from ".";
import ArrowButton from "../../../../components/ArrowButton";
import ChipStyled from "../../../../components/ChipStyled";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import CustomInput from "../../../../components/CustomInput";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

export interface IReflectOnYourHighsLowsProps extends BoxProps { }

const INPUT_MAX_LEN: number = 50;
const QUESTIONS = [
	{
		title: "Where did you prefer to spend your time?",
		type: "buttons",
		variables: [ "Indoors", "Outdoors" ]
	},
	{
		title: "Did you prefer to be alone or with others?",
		type: "buttons",
		variables: [ "Alone", "With others" ]
	},
	{
		title: "What / who did you enjoy interacting with the most?",
		type: "buttons",
		variables: [ "People", "Animals", "Nature", "Objects" ]
	},
	{
		title: "How physically active did you like to be?",
		type: "buttons",
		variables: [ "Not very active", "Active", "Very active" ]
	},
	{
		title: "What 4 things did you enjoy doing the most?",
		type: "inputs"
	}
];

function ReflectOnYourHighsLows({ ...props }: IReflectOnYourHighsLowsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ questionIndex, setQuestionIndex ] = useState<number>(0);
	const [ fieldsValue, setFieldsValue ] = useState<[ string, string, string, string ]>([ "", "", "", "" ]);
	const [ answers, setAnswers ] = useState<string[]>([]);

	const [ pointsInfo ] = useState<IPointInfo[]>(() =>
	{
		const store = getLocalStorage<IPointInfo[]>(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo");

		if (store === null)
		{
			missionContext.setStep(4);

			return [];
		}

		return store;
	});

	// Handles
	function onSelectAnswer(value: string)
	{
		setAnswers((prevState) => [ ...prevState, value ]);
		setQuestionIndex((prevState) => prevState + 1);
	}

	function onChangeField(index: number)
	{
		return ({ target }: ChangeEvent<HTMLInputElement>) =>
		{
			setFieldsValue((prevState) =>
			{
				return Object.assign([], prevState, { [ index ]: capitalize(target.value) });
			});
		};
	}

	function nextStep()
	{
		setLocalStorage(
			missionContext.missionPrefix + "-highsLows-reflects",
			[
				...answers,
				fieldsValue.map((value) => value.trim())
			]
		);

		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="openEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="ReflectOnYourHighsLows_title" defaultValue="Reflect on Your Highs & Lows" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="ReflectOnYourHighsLows_subTitle" defaultValue="Now that you’ve reflected on your highs & lows, it’s time to bring it all together. Answer a few questions that would summaries you as a person. " position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: { xl: "75px", lg: "29px", xs: "29px" },

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
					{pointsInfo.map(({ data }, index) =>
					{
						return (<ChipStyled key={index} label={data.label} sx={{ boxShadow: "unset" }} />);
					})}
				</Box>

				<Panel sx={{
					margin: "auto 0 128px 0",
					padding: { xl: "22px 110px 55px 110px", lg: "32px 50px 38px 50px", xs: "22px 110px 55px 110px" }
				}}>
					<Box sx={{
						color: "primary.main",
						font: { xl: "700 24px/33px 'Open Sans'", lg: "700 16px/25px 'Open Sans'", xs: "700 16px/25px 'Open Sans'" },
						textAlign: "center",
						marginBottom: { xl: "50px", lg: "30px", xs: "30px" },

						"&::before":
						{
							content: `"${ questionIndex + 1 }. "`
						}
					}}>
						{QUESTIONS[ questionIndex ].title}
					</Box>

					<Box sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-around",
						gap: { xl: "54px 68px", lg: "24px 68px", xs: "24px 68px" }
					}}>
						{QUESTIONS[ questionIndex ].type === "buttons"
							? (
								<>
									{QUESTIONS[ questionIndex ].variables!.map((value) =>
									{
										return (
											<CustomButton.Base
												key={value}
												onClick={() => onSelectAnswer(value)}
												sx={{
													boxShadow: "0px 5px 20px rgb(126, 196, 255)",
													borderColor: alpha("#FFFFFF", 0.5),
													font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
													color: "primary.dark",
													padding: { xl: "12px 80px", lg: "12px 80px", xs: "12px 80px" },
													height: { xl: "56px", lg: "40px", xs: "40px" },

													"&:hover":
													{
														borderColor: "transparent",
														backgroundColor: alpha("#3E809D", 0.4),
														color: "#FFFFFF",
														fontWeight: "bold"
													}
												}}
											>
												{value}
											</CustomButton.Base>
										);
									})}
								</>
							)
							: (
								<>
									{[ 0, 1, 2, 3 ].map((_value, index) =>
									{
										return (
											<Box key={index} sx={{ position: "relative" }}>
												<CustomInput.Base
													placeholder={"Activity " + (index + 1)}
													value={fieldsValue[ index ]}
													onChange={onChangeField(index)}
													inputProps={{ maxLength: INPUT_MAX_LEN }}
												/>

												<Box sx={{
													position: "absolute",
													right: "16px",
													bottom: "-19px",
													font: "400 14px/19px 'Open Sans'",
													color: "primary.dark",
													opacity: "0.5"
												}}>
													{fieldsValue[ index ].length}/{INPUT_MAX_LEN} characters
												</Box>
											</Box>
										);
									})}
								</>
							)
						}
					</Box>
				</Panel>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={answers.length !== 4 || fieldsValue.some((value) => value.length < 2)}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(ReflectOnYourHighsLows) as ForwardRefExoticComponent<IReflectOnYourHighsLowsProps>;
