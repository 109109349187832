import { Box } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import useMission from "pages/missionTwo/hooks/useMission";
import { ChangeEvent, useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import type { ListOfValuesData } from "../ListOfValues";
import { WhatMakesProudData, filledForm } from "./Buttons";

export interface IWhatMakesProudFormProps
{
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

const MAX_LENGTH_TEXTAREA: number = 500;

function WhatMakesProudForm({ preview = false }: IWhatMakesProudFormProps)
{
	const missionContext = useMission();
	const [ whatMakesProud, setWhatMakesProud ] = useLocalStorage<WhatMakesProudData>(missionContext.missionPrefix + "-whatMakesProud", "");
	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ _newListOfValues, setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Handles
	function onChange({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setWhatMakesProud(target.value.replaceAll(/\s{2,}/g, " "));
	}

	function getBot()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					"Define in max 5 nouns, with no additional text, the personal values of this person based on what they are proud of " + whatMakesProud.trim(),
					"personBased"
				)
				.then(({ personBased }) =>
				{
					const newValues =
						personBased
							.map((value) => value.toLowerCase())
							.filter((value) => listOfValues.includes(value) === false);

					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 3) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
					setTimeout(() => missionContext.setExtraStep(null), 0);
				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	function nextStep()
	{
		if (preview === true)
		{
			missionContext.setExtraStep(null);
		}
		else
		{
			getBot();
		}

	}

	// Render
	return (
		<Panel sx={{
			marginTop: "70px",
			padding: "24px 24px 24px 32px"
		}}>
			<Box sx={{
				marginTop: "8px",
				paddingLeft: "16px",
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
				color: "primary.main"
			}}>
				<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="WhatMakesProud_formTitle"
					defaultValue="What qualities are you most proud of?"
					isDescription
					position="right"
				/>
			</Box>

			<CustomInput.Base
				readOnly={preview}
				multiline
				rows={4}
				value={whatMakesProud}
				onChange={onChange}
				inputProps={{ maxLength: MAX_LENGTH_TEXTAREA }}
				sx={{
					marginTop: "16px",
					width: "100%"
				}}
			/>

			<Box sx={{
				padding: "0 16px",
				width: "100%",

				font: "400 14px/16px 'Open Sans'",
				color: "primary.dark",
				textAlign: "right",
				opacity: 0.5
			}}>
				{whatMakesProud.trim().length}/{MAX_LENGTH_TEXTAREA} characters
			</Box>

			<CustomButton.Contained
				disabled={preview === false && filledForm(whatMakesProud) === false}
				onClick={nextStep}
				loading={loading}
				sx={{
					margin: "8px auto 0 auto",
					display: "flex"
				}}
			>
				{preview === true ? "Back" : "Submit"}
			</CustomButton.Contained>
		</Panel>
	);
}

export default WhatMakesProudForm;
