import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-scroll";
import Career from "../../../assets/images/Career.svg";
import Friend from "../../../assets/images/Friend.svg";
import Fun from "../../../assets/images/Fun.svg";
import Growth from "../../../assets/images/Growth.svg";
import Health from "../../../assets/images/Health.svg";
import Love from "../../../assets/images/Love.svg";
import Money from "../../../assets/images/Money.svg";
import home from "../../../assets/images/home.svg";
import ContentAdmin from "../../../components/contentAdmin";

function BadStatus({ name, handleDetails, items, handleClickOpen })
{
	const [ active, setActive ] = useState(true);

	function imageByTitle(title)
	{
		switch (title)
		{
			case "Health":
				return Health;
			case "Friends":
				return Friend;
			case "Love":
				return Love;
			case "Career":
				return Career;
			case "Money":
				return Money;
			case "Fun":
				return Fun;
			case "Growth":
				return Growth;
			default:
				return home;
		}
	}

	return (
		<Box>
			<Typography pt={5} sx={{ fontSize: { xs: "24px", sm: "40px" }, fontFamily: "Lora-Bold", color: "#215C75" }}>
				<ContentAdmin position="left" defaultValue="Time to take an honest look at your life" keyContent="contentMissionOne" keyText="badStatusTitle" />
			</Typography>
			<Typography mt={2} sx={{ fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin
					isDescription
					position="left"
					defaultValue="{NAME}, it sounds like you aren’t so happy with most areas of your life right now. Life is a rollercoaster and sometimes things go better than other times. But acknowledging that things aren’t working is the first step to a more fulfilling life.\\n\\nMaybe it’s just a phase you are going through, and slight adjustments across all areas will help you get back on track. But maybe you need a bigger change in life. Often, when we are unhappy with how things are going, it’s a sign you might be on the wrong path in life and need a course correction.\\n\\nReflect on each individual area and ask yourself if it’s time for a new chapter. Recognising you need a change can be scary, but we’ve got your back. With Life Strategy Quest, we will guide you through all the steps to a more fulfilling life."
					keyContent="contentMissionOne"
					keyText="badStatusDesc"
				/>
				{/*
				{`${ name ? name : localStorage.getItem('name') }, it sounds like you aren’t so happy with most areas of your life right now. Life is a rollercoaster and sometimes things go better than other times. But acknowledging that things aren’t working is the first step to a more fulfilling life.`}<br></br><br></br>

				Maybe it’s just a phase you are going through, and slight adjustments across all areas will help you get back on track. But maybe you need a bigger change in life. Often, when we are unhappy with how things are going, it’s a sign you might be on the wrong path in life and need a course correction.<br></br><br></br>

				Reflect on each individual area and ask yourself if it’s time for a new chapter. Recognising you need a change can be scary, but we’ve got your back. With Life Strategy Quest, we will guide you through all the steps to a more fulfilling life.
				*/}
			</Typography>
			<Grid mt={{ lg: 2, xl: 1 }} container spacing={{ lg: 2, xl: 5 }} sx={{ display: { xs: "none", sm: "flex" } }}>
				{items?.filter((item) => item.value < 0.7).map(
					(item, index) => (
						<Grid key={index + 1} item xs={3}>
							<Link to="test1" spy smooth duration={500} onClick={() => handleDetails(item.title)}>
								<Box
									sx={{
										border: "1px solid rgba(255,255,255,0.6)",
										background: "#E9EDF0",
										borderRadius: "10px",
										pt: "15px",
										boxShadow: item.active ? "-5px -5px 10px #215c75" : "-5px -5px 10px #F0F8FF",
										cursor: "pointer",
										"&:hover": {
											boxShadow: "-5px -5px 10px #215c75"
										},
										width: "160px"
									}}
								>
									<Box sx={{ width: "90px", m: "0 auto" }}>
										<img width="100%" height="90px" src={imageByTitle(item.title)} alt={item.title} />
									</Box>
									<Typography sx={{ fontSize: "20px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75", textAlign: "center" }}>
										{item.title === "Physical environment" ? "Physical env." : item.title}
									</Typography>
									<Box sx={{ display: "flex", justifyContent: "center", background: "#3E809D", height: "30px", borderRadius: "0 0 10px 10px", opacity: 0.5, "&:hover": { opacity: 1 } }}>
										<ArrowBackIosIcon sx={{ fill: "#fff", transform: "rotate(270deg)", mt: "-2px" }} />
									</Box>
								</Box>
							</Link>
						</Grid>
					)
				)}
			</Grid>
			<Box sx={{ display: { xs: "block", sm: "none" } }}>
				<Accordion expanded={active} sx={{ background: "inherit", boxShadow: "none", ".MuiAccordionSummary-root": { p: 0 }, ".MuiAccordionDetails-root": { p: "16px 0 0" } }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						onClick={() => setActive(!active)}
					>
						<Typography className="h1-status" mt={3} sx={{ fontSize: { xs: "24px !important", sm: "42px" }, fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Let’s look at each area in more detail" keyContent="contentMissionOne" keyText="mediumStatusDetailsTitle" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={{ xs: 1, sm: 5 }}>
							{items?.filter((item) => item.value < 0.7).map(
								(item, index) => (
									<Grid key={index + 1} item xs={6}>
										<Box
											onClick={() =>
											{
												handleDetails(item.title);
												handleClickOpen();
											}}
											sx={{
												border: "1px solid rgba(255,255,255,0.6)",
												background: "#E9EDF0",
												borderRadius: "10px",
												pt: "15px",
												boxShadow: item.active ? "-5px -5px 10px #215c75" : "-5px -5px 10px #F0F8FF",
												cursor: "pointer",
												"&:hover": {
													boxShadow: "-5px -5px 10px #215c75"
												}
											}}
										>
											<Box sx={{ width: "90px", m: "0 auto" }}>
												<img width="100%" height="90px" src={imageByTitle(item.title)} alt={item.title} />
											</Box>
											<Typography sx={{ fontSize: "20px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75", textAlign: "center" }}>
												{item.title === "Physical environment" ? "Physical env." : item.title}
											</Typography>
											<Box sx={{ display: "flex", justifyContent: "center", background: "#3E809D", height: "30px", borderRadius: "0 0 10px 10px", opacity: 0.5 }}>
												<ArrowBackIosIcon sx={{ fill: "#fff", transform: "rotate(270deg)", mt: "-2px" }} />
											</Box>
										</Box>
									</Grid>
								)
							)}
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>

	);
}

export default BadStatus;
