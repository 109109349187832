import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, alpha } from "@mui/material";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import { ChangeEvent } from "react";
import { SetValue } from "utils/localStorage";
import { ToughDecisionsFields } from ".";

const MAX_LENGTH_INPUT: number = 30;

export interface IToughDecisionsCreateFieldsProps
{
	submitFields(): void;
	readonly fields: ToughDecisionsFields;
	setFields: SetValue<ToughDecisionsFields>;
}

function ToughDecisionsCreateFields({ fields, setFields, submitFields }: IToughDecisionsCreateFieldsProps)
{
	// Handles
	function onChange(index: number)
	{
		return ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		{
			setFields((prevState) =>
			{
				const fields = [ ...prevState ];

				fields[ index ][ 0 ] = target.value;

				return fields;
			});
		};
	}

	function createField()
	{
		setFields((prevState) => [ ...prevState, [ "", "" ] ]);
	}

	// Render
	return (
		<Box sx={{
			marginTop: "70px",
			padding: "24px 24px 24px 32px",

			background: "linear-gradient(104deg, rgba(229, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
			boxShadow: "0px 5px 20px 0px " + alpha("#7EC4FF", 0.4),
			backdropFilter: "blur(30px)",
			border: "1px solid " + alpha("#FFFFFF", 0.5),
			borderRadius: "24px"
		}}>
			<Box sx={{
				paddingLeft: "16px",
				font: "700 24px/31px Lora-Bold",
				color: "primary.main"
			}}>
				What were your top  hard decisions in life?
			</Box>

			<Box sx={{
				marginTop: "16px",
				display: "flex",
				flexDirection: "column",
				gap: "12px"
			}}>
				{fields.map(([ fieldName ], index) =>
				{
					return (
						<div key={"field-" + index}>
							<CustomInput.Base
								placeholder="moving countries"
								value={fieldName}
								onChange={onChange(index)}
								inputProps={{ maxLength: MAX_LENGTH_INPUT }}
								sx={{
									width: "100%"
								}}
							/>

							<Box sx={{
								padding: "0 16px",
								width: "100%",
								font: "400 14px/16px 'Open Sans'",
								color: "primary.dark",
								textAlign: "right",
								opacity: 0.5
							}}>
								{fieldName.trim().length ?? 0}/{MAX_LENGTH_INPUT} characters
							</Box>
						</div>
					);
				})}
			</Box>

			{fields.length < 5 &&
				(
					<Box onClick={createField} sx={{ display: "flex", alignItems: "center", cursor: "pointer", gap: 1 }}>
						<AddIcon color="primary" sx={{ fontSize: "36px" }} />
						<Typography sx={{ font: "700 22px/30px Open Sans", color: "primary.main" }}>add more tough decisions</Typography>

					</Box>

				)
			}

			<CustomButton.Contained
				disabled={fields.some(([ fieldName ]) => fieldName.trim().length > 0) === false}
				onClick={() =>
				{
					setFields((prevState) =>
					{
						return prevState.filter(([ fieldName ]) => fieldName.trim().length > 0);
					});

					submitFields();
				}}
				sx={{
					margin: "24px auto 0 auto",
					display: "flex"
				}}
			>
				Submit
			</CustomButton.Contained>
		</Box>
	);
}

export default ToughDecisionsCreateFields;
