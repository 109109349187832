import { Box } from "@mui/material";
import bg from "../../assets/images/bg.svg";

function Container({ children, step })
{
	return (
		<Box>
			<Box
				className="heighSteper"
				sx={{
					width: "100%",
					minHeight: "100vh",
					background: `url(${ bg }) center center/cover`,
					backgroundPositionX: { sm: "center", xs: "right" },

					"@supports (min-height: 100dvh)":
					{
						minHeight: "100dvh"
					}
				}}
			>
				{children}
			</Box>
		</Box>
	);
}

export default Container;
