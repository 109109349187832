import { Box, CircularProgress, SxProps, Theme, Zoom, alpha } from "@mui/material";
import TooltipStyled from "components/TooltipStyled";
import { Dispatch, SetStateAction } from "react";
import { ReactComponent as SVGLock } from "../../../assets/images/dashboards/lock.svg";
import useUser from "../../../hooks/useUser";
import { IMission } from "./mission.interface";

interface ICardProps
{
	readonly mission: IMission;
	readonly setMissionIdSelect?: Dispatch<SetStateAction<number>>;
	readonly setShowBuyDialog?: Dispatch<SetStateAction<boolean>>;
	readonly sx?: SxProps<Theme>;
}

function Card({ mission, setMissionIdSelect, setShowBuyDialog, sx = [] }: ICardProps)
{
	const userState = useUser().state.user;
	const isTester = userState?.userRole === "tester" || false;
	const isAdmin = userState?.userRole === "admin" || false;
	const isLock = isTester === true || isAdmin === true ? false : mission.isLock;

	// Handles
	function onNavigateToMission()
	{
		setMissionIdSelect?.(mission.id);
	}

	// Render
	return (
		<Box sx={[
			{
				position: "relative",
				userSelect: (isLock === true ? "none" : "auto"),
				width: { xl: "202px", lg: "135px", xs: "135px" }
			},
			...Array.isArray(sx) ? sx : [ sx ]
		]}>
			<TooltipStyled
				title="Select mission"
				followCursor
				PopperProps={{
					sx:
					{
						"& .MuiTooltip-tooltip":
						{
							padding: "24px !important",
							marginTop: "30px !important"
						}
					}
				}}
			>
				<Box
					component="button"
					onClick={() => onNavigateToMission()}
					sx={{
						cursor: "pointer",
						width: "100%",
						height: { xl: "284px", lg: "189.9px", xs: "189.9px" },
						backgroundColor: alpha("#FFFFFF", 0.5),
						border: "unset",
						borderRadius: "24px",
						padding: { xl: "12.5px 12px", lg: "8px", xs: "8px" },
						display: "flex",
						flexDirection: "column",
						alignItems: "center"
					}}
				>
					<Box sx={{
						display: "block",
						padding: "unset",
						border: { xl: "9px solid #E9EDF0", lg: "6px solid #E9EDF0", xs: "6px solid #E9EDF0" },
						borderRadius: "50%",
						background: "none",
						boxShadow: "-5px -5px 10px rgba(255, 255, 255, .73), 5px 5px 10px rgba(0, 0, 0, .16)",
						width: { xl: "178px", lg: "118.74px", xs: "118.74px" },
						height: { xl: "178px", lg: "118.74px", xs: "118.74px" },
						position: "relative",
						overflow: "hidden",
						flexShrink: 0
					}}>
						<Box
							component="img"
							src={mission.image}
							alt={mission.title}
							sx={{
								width:
								{
									xl: isLock ? "110px" : "160px",
									lg: isLock ? "110px" : "107px",
									xs: isLock ? "110px" : "107px"
								},
								height:
								{
									xl: isLock ? "110px" : "160px",
									lg: isLock ? "110px" : "107px",
									xs: isLock ? "110px" : "107px"
								},
								filter: isLock ? "blur(20px)" : "",
								objectFit: "cover",
								marginTop: isLock ? "10px" : "0px",
								marginLeft: isLock ? "10px" : "0px",
								zIndex: 1
							}} />
					</Box>

					<Box sx={{
						marginTop: { xl: "8px", lg: "5.34px", xs: "5.34px" },
						font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						color: "primary.main",
						textAlign: "center"
					}}>
						Mission {mission.id}
					</Box>

					<Box sx={{
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "primary.dark",
						textAlign: "center",
						width: "100%",

						"& *":
						{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							width: "100%"
						}
					}}>
						{mission.title}
					</Box>
				</Box>
			</TooltipStyled>

			<TooltipStyled title={Math.round(mission.complete) + "%"} TransitionComponent={Zoom} placement="right" arrow>
				<Box sx={{
					position: "relative",

					display: "flex",
					justifyContent: "center",
					alignItems: "center",

					width: { xl: "60px", lg: "40px", xs: "40px" },
					height: { xl: "60px", lg: "40px", xs: "40px" },
					margin: { xl: "-12px auto 0 auto", lg: "-7px auto 0 auto", xs: "-7px auto 0 auto" },
					backgroundColor: "#E9EDF0",
					borderRadius: "50%",
					boxShadow: "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)"
				}}>
					<CircularProgress
						variant="determinate"
						value={mission.complete}
						thickness={7}
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: { xl: "60px !important", lg: "40px !important", xs: "40px !important" },
							height: { xl: "60px !important", lg: "40px !important", xs: "40px !important" },

							"& .MuiCircularProgress-circle":
							{
								stroke: "#215C75"
							}
						}}
					/>

					{isLock
						? (<SVGLock style={{ fill: "#215C75", width: "22px", height: "28px" }} />)
						: (
							<Box sx={{
								font: "400 24px/33px 'Open Sans'",
								color: "#215C75",

								display: "flex",
								justifyContent: "center",
								alignItems: "center",

								width: "42px",
								height: "42px",
								lineHeight: "42px",

								backgroundColor: "#FFFFFF",
								borderRadius: "50%"
							}}>
								{mission.id}
							</Box>
						)
					}
				</Box>
			</TooltipStyled>
		</Box>
	);
}

export default Card;
