import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useUser from "../../../hooks/useUser";
import useMission from "../hooks";

const ChatAfterUncertaintyCheck = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();
	const { content } = useUser().state;

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionNine"
					keyText="screen_1_chat2_message"
					defaultValue="Excellent job, {NAME}! I have a special approach for tackling your biggest uncertainties."
					isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionNine"
				keyText="screen_1_chat2_answer"
				defaultValue="Special approach?"
				position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionNine"
						keyText="screen_2_chat2_message"
						defaultValue="Yes, it's a lot of fun and you will tackle them in your next mission."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionNine"
							keyText="screen_2_chat2_message_answer"
							defaultValue="Can't wait! "
							position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionNine"
						keyText="screen_3_chat2_message"
						defaultValue="And for now let's organise everything else into your life plan."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [ <ContentAdmin
						limit={64}
						keyContent="contentMissionNine"
						keyText="screen_3_chat2_answer"
						defaultValue="Let's do it!"
						position="top" /> ]
				});
				break;
			}

			case 4: {

				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Box
			ref={ref}
			{...props}
			className="mission-main main"
			component="main"
			sx={{
				minHeight: "calc(100vh - 48px - 36px - 35px)",
				position: "relative",
				top: "-14px",
				left: "10px"
			}}
		>
			<Box className="main__content" sx={{ marginTop: "15px", alignItems: "center" }}>
				<Chat
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatAfterUncertaintyCheck;
