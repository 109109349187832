import Api, { getAccessToken } from "api";

interface Data
{
	missionNumber: number;
	rating: number;
	feedback: string;
}
export class Feedback
{
	async create(data: Data)
	{
		return (
			await Api.instance.post<string>(
				"/feedback/create",
				{ data },
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + getAccessToken()
					}
				}
			)
		).data;
	}
}
