import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Box, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import Api from "api";
import { IResponseAllUser } from "api/Admin";
import { IResponseGetUser } from "api/Users";
import CustomInput from "components/CustomInput";
import TooltipStyled from "components/TooltipStyled";
import useDebounce from "hooks/useDebounce";
import { ChangeEvent, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getSearchParamsValue from "utils/getSearchParamsValue";
import DeleteAccountModal from "./DeleteAccount.modal";
import { TablePaginationActions } from "./Emails";

const COLS: string[] = [
	"Name",
	"Leave Mission",
	"Email",
	"News Subscribe",
	"Add To Brevo",
	"Created",
	"Role",
	"Actions"
];

function Users()
{
	const navigate = useNavigate();
	const isFirstInitRef = useRef<boolean>(true);
	const [ searchParams, setSearchParams ] = useSearchParams();
	const [ isFetching, setIsFetch ] = useState<boolean>(false);
	const [ isOpenDeleteUser, setIsOpenDeleteUser ] = useState<boolean>(false);
	const [ selectedUser, setSelectedUser ] = useState<string | null>(null);
	const [ users, setUsers ] = useState<IResponseAllUser[]>([]);
	const [ filteredUsers, setFilteredUsers ] = useState<IResponseAllUser[]>([]);
	const [ page, setPage ] = useState<number>(() =>
	{
		const value = getSearchParamsValue(searchParams, "page", "number");

		return value === null ? 0 : value - 1;
	});
	const [ rowsPerPage, setRowsPerPage ] = useState<number>(getSearchParamsValue(searchParams, "rowsPerPage", "number") ?? 10);

	const [ searchValue, setSearchValue ] = useState<string>(searchParams.get("search") ?? "");
	const debouncedSearchValue = useDebounce(searchValue, 150);

	// Effects
	useLayoutEffect(() =>
	{
		if (isFirstInitRef.current === false)
		{
			return;
		}

		isFirstInitRef.current = true;

		const password = sessionStorage.getItem("admin-password");

		if (password === null)
		{
			return navigate("/admin");
		}

		const timerId = setTimeout(() => setIsFetch(true), 450);

		Api.admin
			.allUsers(password)
			.then((res) =>
			{
				setUsers(res);
			})
			.catch((error) => console.error(error))
			.finally(() =>
			{
				clearTimeout(timerId);
				setIsFetch(false);
			});

		return () => clearTimeout(timerId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() =>
	{
		const search = debouncedSearchValue.trim().toLowerCase();
		let filteredUsers: IResponseAllUser[] = users;

		if (search)
		{
			filteredUsers = users.filter((user) =>
			{
				return user.name.toLowerCase().includes(search) || user.email.toLowerCase().includes(search);
			});
		}

		setFilteredUsers(filteredUsers);

	}, [ debouncedSearchValue, users ]);

	useLayoutEffect(() =>
	{
		const updatedParams = new URLSearchParams(searchParams);
		const value = debouncedSearchValue.trim();

		if (value)
		{
			updatedParams.set("search", value);
		}
		else
		{
			updatedParams.delete("search");
		}

		setSearchParams(updatedParams);
	}, [ debouncedSearchValue, searchParams, setSearchParams ]);

	useLayoutEffect(() =>
	{
		const updatedParams = new URLSearchParams(searchParams);

		updatedParams.set("page", String(page + 1));
		updatedParams.set("rowsPerPage", String(rowsPerPage));

		setSearchParams(updatedParams);
	}, [ page, rowsPerPage, searchParams, setSearchParams ]);

	// Handles
	function handleChangePage(_event: MouseEvent<HTMLButtonElement> | null, newPage: number)
	{
		setPage(newPage);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	function onDeleteUser(id: string)
	{
		setIsOpenDeleteUser(true);
		setSelectedUser(id);
	}

	function deleteUser(id: string)
	{
		setUsers((users) =>
		{
			return users.filter((user) => user.id !== id);
		});

		setSelectedUser(null);
	}

	function changeRole(value: IResponseGetUser[ "userRole" ], user: IResponseAllUser)
	{
		const password = sessionStorage.getItem("admin-password");

		if (password === null)
		{
			return navigate("/admin");
		}

		Api.users
			.changeIsTested(
				{
					password,
					email: user.email,
					userRole: value
				}
			)
			.then(() =>
			{
				setUsers((users) =>
				{
					return users.map((userInfo) =>
					{
						if (userInfo.id === user.id)
						{
							userInfo.userRole = value;
						}

						return userInfo;
					});
				});
			})
			.catch((error) =>
			{
				console.error(error);
			});
	}

	// Render
	return (
		<Box>
			<CustomInput.Base
				placeholder="Type name or email"
				value={searchValue}
				onChange={({ target }) => setSearchValue(target.value)}
			/>

			<Table>
				<TableHead>
					<TableRow>
						{COLS.map((col) =>
						{
							return (
								<TableCell
									key={col}
									sx={{
										fontWeight: "bold",
										verticalAlign: "top",
										whiteSpace: "nowrap"
									}}
								>
									{col}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{isFetching === true &&
						(
							<TableRow>
								<TableCell colSpan={COLS.length} sx={{ fontSize: "18px", textAlign: "center" }}>
									Loading...
								</TableCell>
							</TableRow>
						)
					}
					{(rowsPerPage > 0
						? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: filteredUsers
					).map((user) =>
					{
						const created: Date = new Date(user.created);

						return (
							<TableRow key={user.id}>
								<TableCell>{user.name}</TableCell>
								<TableCell>
									Completed: {user.completedMission}<br />
									Mission in progress: {user.missionProgress}, step: {user.stepMission}
								</TableCell>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.newsletterSubscribe ? "Yes" : "No"}</TableCell>
								<TableCell>{user.brevoRegistration === true ? "Success" : user.brevoRegistration === false ? "Failed" : "Not found"}</TableCell>
								<TableCell>{created.toLocaleString()}</TableCell>
								<TableCell>
									<Select
										variant="standard"
										value={user.userRole}
										onChange={({ target }) =>
										{
											changeRole(target.value as IResponseAllUser[ "userRole" ], user);
										}}
									>
										<MenuItem
											value="user"
											sx={{ "&.Mui-selected": { background: "rgba(0, 0, 0, 0.2)" } }}
										>
											User
										</MenuItem>
										<MenuItem
											value="admin"
											sx={{ "&.Mui-selected": { background: "rgba(0, 0, 0, 0.2)" } }}
										>
											Admin
										</MenuItem>
										<MenuItem
											value="tester"
											sx={{ "&.Mui-selected": { background: "rgba(0, 0, 0, 0.2)" } }}
										>
											Tester
										</MenuItem>
									</Select>
								</TableCell>
								<TableCell>
									<TooltipStyled title="Delete account" placement="bottom" arrow>
										<IconButton onClick={() => onDeleteUser(user.id)}>
											<DeleteOutlineRoundedIcon />
										</IconButton>
									</TooltipStyled>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>

				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[ 10, 15, 25, 50, { label: "All", value: -1 } ]}
							colSpan={COLS.length}
							count={filteredUsers.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{ inputProps: { "aria-label": "rows per page" }, variant: "standard" }}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>

			<DeleteAccountModal
				userId={selectedUser!}
				open={isOpenDeleteUser ? selectedUser !== null : false}
				onClose={(_event, reason) =>
				{
					setIsOpenDeleteUser(false);

					if (reason !== "resolve")
					{
						setSelectedUser(null);

						return;
					}

					deleteUser(selectedUser!);
				}}
			/>
		</Box>
	);
}

export default Users;
