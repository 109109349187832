import FeedbackCreate from "components/feedback";
import { useReducer } from "react";
import { userInitialState } from "reducers/userInitState";
import { userReducer } from "reducers/userReducer";
import clearLocalStorage from "utils/clearlocalStorage";

function Feedback()
{
	const [ userState ] = useReducer(userReducer, userInitialState);

	function clearStorage()
	{
		clearLocalStorage("mission-five");
	}

	return (
		<FeedbackCreate missionNumber={5} name={userState.user?.name} clearStorage={clearStorage} />
	);
}

export default Feedback;
