import { Box, alpha } from "@mui/material";
import Api from "api";
import bgMissionEightArtidact from "assets/images/bgMissionEightArtidact.png";
import fearsBg from "assets/images/fearsBg.png";
import IMGSRC from "assets/images/icons/ball-list.svg";
import IMGBg from "assets/images/missions/mission-eight/bigBall.png";
import RadialButtonBall from "components/RadialButtonBall";
import CustomButton from "components/customButton";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import Create from "./feature/Create";
import Details from "./feature/Details";

export interface IFears
{
	title: string;
	repair: string;
	prevent: string;
	define: string;
}
interface DataMissions
{
	_id: string;
	created_at: string;
	user_id: string;
	id: string;
	mission_data: IFears[];

}
function Fears()
{
	const navigate = useNavigate();
	const params = useParams();
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ dataMissions, setDataMissions ] = useState<DataMissions[]>([]);

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}

	useLayoutEffect(() =>
	{
		Api.missions
			.getDataMissionEight(true)
			.then(({ data }) =>
			{
				setDataMissions(data);
			})
			.catch((error) =>
			{
				console.error(error);
			});
	}, [ navigate ]);
	switch (params.action)
	{
		case "create": return (<Create />);
		case "details": return (<Details item={dataMissions[ selectedItemIndex ]} />);
	}

	return (
		<ArtifactsContainer
			title="My Fears"
			headerRight={
				<Box sx={{ font: "700 24px/31px Lora-Bold" }}>
					{dataMissions?.[ selectedItemIndex ] ? formatDate(dataMissions[ selectedItemIndex ].created_at) : ""}
				</Box>
			}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "0px 4px 42px " + alpha("#68AEC5", 0.4)
							}
						}}
					>
						{dataMissions.length === 0
							? (
								<Box>Loading...</Box>
							)
							: (
								dataMissions.map((item, index: number) =>
								{
									return (
										<AsideCircleButton
											key={index}
											image={bgMissionEightArtidact}
											text={formatDate(item.created_at)}
											isSelected={selectedItemIndex === index}
											onClick={() => setSelectedItemIndex(index)}
										/>
									);
								})
							)
						}
					</Box>
					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: "355px"
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/fears/create");
						}}
					>
						Work on new fears
					</CustomButton.Contained>
				</Box>}
			backgroundImage={fearsBg}
			onExpand={() => navigate("/dashboard/artifacts/fears/details/" + dataMissions[ selectedItemIndex ].id)}
			onClose={() => navigate("/dashboard/artifacts")}
		>
			<Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
				<Box sx={{
					position: {
						xs: "relative",
						md: "absolute"
					},
					width: "615px",
					height: "615px",
					backgroundImage: `url(${ IMGBg })`,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					boxShadow: "0px 0 35px 7px rgb(255 255 255 / 66%)",
					borderRadius: "50%",
					transform: { lg: "scale(0.8)", xl: "scale(1)" }
				}}>
					<>
						<Box sx={{
							position: "absolute", top: "354px", right: "160px"

						}}>
							<RadialButtonBall
								sx={{ backdropFilter: "none" }}
								imgRadialButton={IMGSRC}
								direction="left"
								value="Emergency plan"
								color="blue"
								absoluteValuePosition
								disabled
							/>
						</Box>
						<Box sx={{ position: "absolute", top: "214px", right: "399px" }}>
							<RadialButtonBall
								sx={{ backdropFilter: "none" }}
								imgRadialButton={IMGSRC}
								value="Immediate action plan"
								color="blue"
								absoluteValuePosition
								disabled
							/>
						</Box>
						<Box sx={{
							position: "absolute",
							// top: '97%',
							top: {
								xs: "89%",
								md: "96%"
							},
							left: "186px"
						}} />
					</>
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default Fears;
