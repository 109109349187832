import { Dialog, styled } from "@mui/material";
import IMGBigBubble from "./bubble.png";

const ModalBubble = styled(Dialog)(({ theme }) => ({
	"& .MuiBackdrop-root": { backgroundColor: "transparent" },
	"& .MuiPaper-root":
	{
		background: `url(${ IMGBigBubble }) center center/cover`,
		boxShadow: "unset",
		maxWidth: "unset",
		width: "344px",
		height: "344px",
		font: "700 24px/31px Lora-Bold",
		color: "#215C75",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "40px",
		textAlign: "center",

		[ theme.breakpoints.down("xl") ]:
		{
			font: "700 18px/23px Lora-Bold"
		}
	}
}));

export default ModalBubble;
