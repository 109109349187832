import { alpha, Box, BoxProps, Fade, Grow } from "@mui/material";
import Api from "api";
import ArrowButton from "components/ArrowButton";
import CheckboxStyled from "components/CheckboxStyled";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import CustomInput from "components/CustomInput";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, forwardRef, ForwardRefExoticComponent, Ref, useLayoutEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { IPointInfo } from ".";
import { ReactComponent as SVGIconArrowComplete } from "../../assets/images/arrow-complete.svg";
import { ReactComponent as SVGArrowHorizontal } from "../../assets/images/arrow-horizontal.svg";
import { ReactComponent as SVGArrowVertical } from "../../assets/images/arrow-vertical.svg";
import { ReactComponent as SVGIconEmotionPeaks } from "../../assets/images/emotion-peaks.svg";
import { ReactComponent as SVGIconEmotionValleys } from "../../assets/images/emotion-valleys.svg";
import useMission from "../../hooks/useMission";
import Dialog from "./Dialog";

export interface ILetTakeCloserLookYourHighsLowsProps extends BoxProps { }

const INPUT_MAX_LEN: number = 100;

function LetTakeCloserLookYourHighsLows({ ...props }: ILetTakeCloserLookYourHighsLowsProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ selectedPointIndex, setSelectedPointIndex ] = useState<null | number>(null);
	const [ isChecked, setIsChecked ] = useState<[ boolean, boolean, boolean ]>([ false, false, false ]);
	const [ fieldsValue, setFieldsValue ] = useState<[ string, string, string ]>([ "", "", "" ]);
	const [ selectedValues, setSelectedValues ] = useState<string[]>([]);

	const [ isFetchingValues, setIsFetchingValues ] = useState<boolean>(false);
	const [ values, setValues ] = useState<string[] | null>(null);

	const [ pointsInfo, setPointsInfo ] = useState<IPointInfo[]>(() =>
	{
		const store = getLocalStorage<IPointInfo[]>(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo");

		if (store === null)
		{
			missionContext.setStep(5);

			return [];
		}

		return store;
	});

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetchingValues === true)
		{
			return;
		}

		setIsFetchingValues(true);

		Api.missions
			.getDataMissionTwo()
			.then(({ values }) =>
			{
				setValues(values.slice(0, 10));
			})
			.finally(() => setIsFetchingValues(false));
	}, []); // eslint-disable-line

	// Utils
	function isPointFilled(index: number)
	{
		return pointsInfo[ index ].isSaved === true;
	}

	// Handles
	function nextStep()
	{
		setLocalStorage(missionContext.missionPrefix + "-lifeHighsLows-pointsInfo", pointsInfo);

		missionContext.nextStep();
	}

	function onSelectPoint(index: number)
	{
		setSelectedPointIndex(index);
		setSelectedValues(pointsInfo[ index ].data.values);
		setIsChecked([
			!!pointsInfo[ index ].data.environment,
			!!pointsInfo[ index ].data.activities,
			!!pointsInfo[ index ].data.socialInteractions
		]);
		setFieldsValue([
			pointsInfo[ index ].data.environment || "",
			pointsInfo[ index ].data.activities || "",
			pointsInfo[ index ].data.socialInteractions || ""
		]);
	}

	function onChangeCheckbox(index: 0 | 1 | 2)
	{
		return () =>
		{
			setIsChecked((prevState) =>
			{
				return Object.assign([], prevState, { [ index ]: !prevState[ index ] });
			});
		};
	}

	function setFieldValue(index: 0 | 1 | 2)
	{
		return ({ target }: ChangeEvent<HTMLTextAreaElement>) =>
		{
			setFieldsValue((prevState) =>
			{
				return Object.assign([], prevState, { [ index ]: target.value });
			});
		};
	}

	function onSelectValue(value: string)
	{
		setSelectedValues((prevState) =>
		{
			const values = [ ...prevState ];

			const valueIndex = values.indexOf(value);

			if (valueIndex !== -1)
			{
				values.splice(valueIndex, 1);

				return values;
			}

			values.push(value);

			return values;
		});
	}

	function onSaveData()
	{
		setPointsInfo((prevState) =>
		{
			const state = deepClone(prevState);

			state[ selectedPointIndex! ].isSaved = true;
			state[ selectedPointIndex! ].data.values = selectedValues;

			if (isChecked[ 0 ] === true && fieldsValue[ 0 ].length > 0)
			{
				state[ selectedPointIndex! ].data.environment = fieldsValue[ 0 ];
			}
			else
			{
				delete state[ selectedPointIndex! ].data.environment;
			}

			if (isChecked[ 1 ] === true && fieldsValue[ 1 ].length > 0)
			{
				state[ selectedPointIndex! ].data.activities = fieldsValue[ 1 ];
			}
			else
			{
				delete state[ selectedPointIndex! ].data.activities;
			}

			if (isChecked[ 2 ] === true && fieldsValue[ 2 ].length > 0)
			{
				state[ selectedPointIndex! ].data.socialInteractions = fieldsValue[ 2 ];
			}
			else
			{
				delete state[ selectedPointIndex! ].data.socialInteractions;
			}

			return state;
		});

		setSelectedPointIndex(null);
		setSelectedValues([]);
		setIsChecked([ false, false, false ]);
		setFieldsValue([ "", "", "" ]);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", gap: "44px !important" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="LetTakeCloserLookYourHighsLows_title" defaultValue="Let’s take closer look at your Highs & Lows" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="LetTakeCloserLookYourHighsLows_subTitle" defaultValue="Excellent job, {NAME}! Let’s take a deeper look at each of your ups and downs." position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Fade in={selectedPointIndex === null} timeout={500}>
					<Panel sx={{ padding: { xl: "32px 24px 32px 24px", xs: "24px" } }}>
						<Box sx={{
							font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
							color: "primary.main",
							marginLeft: "63px",
							marginBottom: "22px"
						}}>
							<ContentAdmin keyContent={missionContext.keyContent} keyText="LifeHighsLows_panelTitle" defaultValue="{NAME}'s life journey to date" position="left" />
						</Box>

						<Box sx={{
							marginRight: { xl: "40px", xs: "24px" },
							display: "grid",
							gridTemplateColumns: "auto 1fr",
							gridTemplateRows: "1fr auto",
							gap: "14px"
						}}>
							{/* Left arrow */}
							<Box className="leftArrow" sx={{ height: "100%", width: "49px", display: "flex" }}>
								<Box sx={{
									writingMode: "vertical-rl",
									transform: "rotate(-180deg)",
									color: "primary.main",
									font: "700 18px/25px 'Open Sans'",
									textTransform: "uppercase",
									textAlign: "center"
								}}>
									Life Satisfaction
								</Box>

								<Box sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "space-between",
									position: "relative",
									left: "-10px"
								}}>
									<SVGIconEmotionPeaks />
									<Box component={SVGArrowVertical} sx={{ height: { xl: "320px", xs: "230px" } }} />
									<SVGIconEmotionValleys />
								</Box>
							</Box>

							{/* Graph */}
							<Box
								className="graph"
								sx={{
									position: "relative",
									width: "100%",
									height: { xl: "476px", xs: "380px" },
									backgroundColor: alpha("#FFFFFF", 0.5),
									borderRadius: "16px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								<img
									src={getLocalStorage<string>(missionContext.missionPrefix + "-lifeHighsLows-graphImage") || ""}
									alt="Graph"
								/>

								{pointsInfo.map(({ x: left, y: top, data }, index) =>
								{
									return (
										<Box
											key={index}
											sx={{
												position: "absolute",
												left,
												top,
												transform: "translateX(-50%) translateY(-75%)",
												"&:hover": { zIndex: 2 }
											}}
										>
											<Grow in timeout={{ enter: 500 + (index * 500) }}>
												<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
													<Box
														component="button"
														onClick={() => onSelectPoint(index)}
														sx={[
															{
																backgroundColor: "#FFFFFF",
																boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
																border: "unset",
																borderRadius: "40px",
																padding: "9px 22px",
																font: "400 16px/22px 'Open Sans'",
																whiteSpace: "nowrap",
																textAlign: "center",
																cursor: "pointer"
															},
															(isPointFilled(index) === true) &&
															{
																border: "2px solid " + alpha("#FFFFFF", 0.5),
																backgroundColor: "unset",
																background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
																boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
																backdropFilter: "blur(30px)"
															}
														]}
													>
														{data.label}
													</Box>
													<Box sx={{
														width: "16px",
														height: "16px",
														backgroundColor: "#3E809D",
														border: "2px solid #FFFFFF",
														borderRadius: "50%",
														display: "flex",
														justifyContent: "flex-end",
														alignItems: "center"
													}}>
														{isPointFilled(index) && <SVGIconArrowComplete width="10px" />}
													</Box>
												</Box>
											</Grow>
										</Box>
									);
								})}
							</Box>

							{/* Bottom arrow */}
							<Box className="bottomArrow" sx={{ gridColumn: "2", height: "44px", display: "flex", flexDirection: "column" }}>
								<Box sx={{
									color: "primary.main",
									font: "700 18px/25px 'Open Sans'",
									textTransform: "uppercase",
									height: "100%",
									textAlign: "center",
									position: "relative",
									top: "-5px",
									order: 2
								}}>
									Time
								</Box>

								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<Box sx={{
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										textAlign: "center"
									}}>
										Birth
									</Box>

									<SVGArrowHorizontal width="100%" />

									<Box sx={{
										color: "primary.main",
										font: "700 18px/25px 'Open Sans'",
										textTransform: "uppercase",
										textAlign: "center"
									}}>
										Now
									</Box>
								</Box>
							</Box>
						</Box>
					</Panel>
				</Fade>
			</Box>

			<Dialog
				open={selectedPointIndex !== null}
				position="center"
				sx={{
					"& .MuiPaper-root":
					{
						height: "fit-content",
						maxHeight: "637px",
						display: "grid",
						gridTemplateRows: "auto auto 1fr auto",
						gridTemplateColumns: "1fr",
						overflow: "hidden"
					}
				}}
			>
				{selectedPointIndex !== null && (
					<>
						<Box sx={{ width: "100%", display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", gap: "10px" }}>
							<TooltipStyled title={pointsInfo[ selectedPointIndex ].data.label} placement="bottom" arrow>
								<Box sx={{
									font: "700 40px/51px Lora-Bold",
									color: "primary.main",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}>
									{pointsInfo[ selectedPointIndex ].data.label}
								</Box>
							</TooltipStyled>

							{pointsInfo[ selectedPointIndex ].type === "highs"
								? <SVGIconEmotionPeaks width="47px" height="47px" />
								: <SVGIconEmotionValleys width="47px" height="47px" />
							}
						</Box>

						<Box sx={{ margin: "8px 0 12px 0", "& span": { wordBreak: "break-all" } }}>
							{pointsInfo[ selectedPointIndex ].type === "highs"
								? (
									<>
										What made you happy and satisfied during
										<span>{pointsInfo[ selectedPointIndex ].data.label}</span>? Check all that apply.
									</>
								)
								: (
									<>
										What played the most detrimental role to your happiness during your
										<span>{pointsInfo[ selectedPointIndex ].data.label}</span>? Check all that apply.
									</>
								)
							}
						</Box>

						<Box className="customScroll" sx={{ overflowY: "auto", paddingRight: "16px" }}>
							{[
								{
									placeholder: (pointsInfo[ selectedPointIndex ].type === "highs"
										? "Describe the environment which made you happy"
										: "Describe the environment that made you unhappy."
									),
									label: "Environment"
								},
								{
									placeholder: (pointsInfo[ selectedPointIndex ].type === "highs"
										? "What specific activities were you performing?"
										: "What specific activities were you performing?"
									),
									label: "Activities"
								},
								{
									placeholder: (pointsInfo[ selectedPointIndex ].type === "highs"
										? "Who was around at that time? How did you interact with them?"
										: "Who was around at that time? How did you interact with them?"
									),
									label: "Social Interactions"
								}
							].map(({ placeholder, label }, index) =>
							{
								return (
									<Box key={label} sx={{ marginBottom: "16px" }}>
										<Box
											component="label"
											sx={{
												color: "primary.main",
												font: "700 24px/31px Lora-Bold",
												display: "flex",
												alignItems: "center",
												gap: "8px",
												marginBottom: "8px",
												cursor: "pointer"
											}}
										>
											<CheckboxStyled
												onChange={onChangeCheckbox(index as 0 | 1 | 2)}
												checked={isChecked[ index ]}
												sx={{
													padding: "0px",

													"& .CheckboxStyled-checkbox":
													{
														backgroundColor: alpha("#FFFFFF", 0.5)
													}
												}}
											/>
											{label}
										</Box>

										<TransitionGroup>
											{isChecked[ index ] === true &&
												(
													<Fade timeout={{ enter: 1000, exit: 500 }} unmountOnExit>
														<Box sx={{ position: "relative" }}>
															<CustomInput.Base
																placeholder={placeholder}
																multiline
																minRows={2}
																fullWidth
																value={fieldsValue[ index ]}
																onChange={setFieldValue(index as 0 | 1 | 2)}
																inputProps={{ maxLength: INPUT_MAX_LEN }}
															/>
															<Box sx={{
																position: "absolute",
																right: "16px",
																bottom: "-19px",
																font: "400 14px/19px 'Open Sans'",
																color: "primary.dark",
																opacity: "0.5"
															}}>
																{fieldsValue[ index ].length}/{INPUT_MAX_LEN} characters
															</Box>
														</Box>
													</Fade>
												)
											}
										</TransitionGroup>
									</Box>
								);
							})}

							<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark", paddingTop: "7px" }}>
								{pointsInfo[ selectedPointIndex ].type === "highs"
									? <>Were you doing something especially in sync with one or a few of your values?</>
									: <>Does this low relate to you not living in sync with one or a few of your values? Which ones?</>
								}
							</Box>

							<Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", marginTop: "16px", marginBottom: "5px" }}>
								{values?.map((value, index) =>
								{
									return (
										<ChipStyled
											key={index}
											clickable
											label={value}
											variant={selectedValues.includes(value) ? "filled" : "outlined"}
											onClick={() => onSelectValue(value)}
										/>
									);
								})}
							</Box>
						</Box>

						<CustomButton.Contained
							sx={{ display: "flex", margin: "24px auto 0 auto" }}
							onClick={onSaveData}
						>
							Save
						</CustomButton.Contained>
					</>
				)}
			</Dialog>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={pointsInfo.some((_point, index) => isPointFilled(index)) === false}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(LetTakeCloserLookYourHighsLows) as ForwardRefExoticComponent<ILetTakeCloserLookYourHighsLowsProps>;
