import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import { getLocalStorage } from "utils/localStorage";
import Career from "../../assets/images/Career.svg";
import Friend from "../../assets/images/Friend.svg";
import Fun from "../../assets/images/Fun.svg";
import Growth from "../../assets/images/Growth.svg";
import Health from "../../assets/images/Health.svg";
import Love from "../../assets/images/Love.svg";
import Money from "../../assets/images/Money.svg";
import bgDetails from "../../assets/images/bgDetails.png";
import home from "../../assets/images/home.svg";
import meditateleft from "../../assets/images/meditateleft.png";
import Dialogs from "./Dialogs";
import BadStatus from "./fields/BadStatus";
import DetailsBad from "./fields/DetailsBad";
import DetailsItem from "./fields/DetailsItem";
import GoodStatus from "./fields/GoogStatus";

function DetailsContainerShared()
{
	const { state: userState } = useUser();
	const location = useLocation();
	const navigate = useNavigate();
	const [ filteredData, setFilteredData ] = useState();
	const [ details, setDetails ] = useState("");
	const [ name, setName ] = useState();
	const [ dataGraphGet, setDataGraphGet ] = useState();
	const [ items, setItems ] = useState();
	const [ open, setOpen ] = useState(false);
	const data = [
		{
			data: dataGraphGet
		}
	];
	const date = new Date();
	const mm = String(date.getMonth() + 1).padStart(2, "0");
	const captions =
	{
		home: "home",
		health: "health",
		friend: "friend",
		love: "love",
		career: "Speed",
		money: "Weight",
		fun: "Weight2",
		growth: "Weight3"
	};

	// Hooks
	useEffect(() =>
	{
		// FIXME: You can get it from useParams
		const missionId = location.pathname.split(":")[ 1 ];

		if (location.pathname.split("/")[ 1 ] === "dashboard")
		{
			Api.missions.getDataMission(missionId)
				.then(({ data }) =>
				{
					const { mission_data } = data;

					setDataGraphGet(mission_data);
					setItems([
						{
							title: "Health",
							value: mission_data.health,
							active: false
						},
						{
							title: "Friends",
							value: mission_data.friend,
							active: false
						},
						{
							title: "Love",
							value: mission_data.love,
							active: false
						},
						{
							title: "Career",
							value: mission_data.career,
							active: false
						},
						{
							title: "Money",
							value: mission_data.money,
							active: false
						},
						{
							title: "Fun",
							value: mission_data.fun,
							active: false
						},
						{
							title: "Growth",
							value: mission_data.growth,
							active: false
						},
						{
							title: "Physical environment",
							value: mission_data.home,
							active: false
						}
					]);

					const arrFilter = [
						{
							title: "Health",
							value: mission_data.health
						},
						{
							title: "Friends",
							value: mission_data.friend
						},
						{
							title: "Love",
							value: mission_data.love
						},
						{
							title: "Career",
							value: mission_data.career
						},
						{
							title: "Money",
							value: mission_data.money
						},
						{
							title: "Fun",
							value: mission_data.fun
						},
						{
							title: "Growth",
							value: mission_data.growth
						},
						{
							title: "Physical environment",
							value: mission_data.home
						}
					];
					const filterArr = arrFilter.filter((item) => item.value < 0.7);

					setFilteredData(filterArr);
				}).catch((error) =>
				{
					console.error(error);
				});
		}
		else
		{
			if (missionId)
			{
				Api.missions.getShared(missionId)
					.then(({ data, user }) =>
					{
						setDataGraphGet(data);
						setName(user.name);
						setItems([
							{
								title: "Health",
								value: data.health,
								active: false
							},
							{
								title: "Friends",
								value: data.friend,
								active: false
							},
							{
								title: "Love",
								value: data.love,
								active: false
							},
							{
								title: "Career",
								value: data.career,
								active: false
							},
							{
								title: "Money",
								value: data.money,
								active: false
							},
							{
								title: "Fun",
								value: data.fun,
								active: false
							},
							{
								title: "Growth",
								value: data.growth,
								active: false
							},
							{
								title: "Physical environment",
								value: data.home,
								active: false
							}
						]);

						const arrFilter = [
							{
								title: "Health",
								value: data.health
							},
							{
								title: "Friends",
								value: data.friend
							},
							{
								title: "Love",
								value: data.love
							},
							{
								title: "Career",
								value: data.career
							},
							{
								title: "Money",
								value: data.money
							},
							{
								title: "Fun",
								value: data.fun
							},
							{
								title: "Growth",
								value: data.growth
							},
							{
								title: "Physical environment",
								value: data.home
							}
						];

						const filterArr = arrFilter.filter((item) => item.value < 0.7);

						setFilteredData(filterArr);
					});
			}
			else
			{
				if (getLocalStorage("artifactGraph"))
				{
					const dataArtifact = getLocalStorage("artifactGraph");

					setDataGraphGet(dataArtifact);
					setItems([
						{
							title: "Health",
							value: dataArtifact.health,
							active: false
						},
						{
							title: "Friends",
							value: dataArtifact.friend,
							active: false
						},
						{
							title: "Love",
							value: dataArtifact.love,
							active: false
						},
						{
							title: "Career",
							value: dataArtifact.career,
							active: false
						},
						{
							title: "Money",
							value: dataArtifact.money,
							active: false
						},
						{
							title: "Fun",
							value: dataArtifact.fun,
							active: false
						},
						{
							title: "Growth",
							value: dataArtifact.growth,
							active: false
						},
						{
							title: "Physical environment",
							value: dataArtifact.home,
							active: false
						}
					]);

					const arrFilter = [
						{
							title: "Health",
							value: dataArtifact.health
						},
						{
							title: "Friends",
							value: dataArtifact.friend
						},
						{
							title: "Love",
							value: dataArtifact.love
						},
						{
							title: "Career",
							value: dataArtifact.career
						},
						{
							title: "Money",
							value: dataArtifact.money
						},
						{
							title: "Fun",
							value: dataArtifact.fun
						},
						{
							title: "Growth",
							value: dataArtifact.growth
						},
						{
							title: "Physical environment",
							value: dataArtifact.home
						}
					];

					const filterArr = arrFilter.filter((item) => item.value < 0.7);

					setFilteredData(filterArr);
				}
			}

		}
	}, [ location.pathname ]);

	function handleClickOpen()
	{
		setOpen(true);
	}

	function handleClose()
	{
		setOpen(false);
	}

	function handleDetails(value)
	{
		let arrItems = items;

		arrItems.forEach((element) =>
		{
			element.active = (element.title === value);
		});

		setItems(arrItems);
		setDetails(value);
	}

	return (
		<Box sx={{ background: `url(${ bgDetails }) no-repeat`, backgroundSize: "cover" }}>
			<Box sx={{ width: "100%", height: "100%", position: "relative" }}>
				{location.pathname.split("/")[ 1 ] === "artifact" &&
					(
						<Box sx={{
							cursor: "pointer",
							position: "absolute",
							top: "24px",
							left: "40px"
						}}
						>
							<SVGArrowBack
								sx={{
									transition: "linear 200ms opacity",
									color: "inherit",
									"& path":
							{
								fillOpacity: 1,
								fill: "currentColor"
							},
									"&:hover":
							{
								opacity: 0.7
							}
								}}
								onClick={() => navigate("/dashboard/artifacts/wheel-of-life")}
							/>
						</Box>
					)
				}

				<Element name="topAncor" className="element" />
				<Grid
					container
					className="marginCenter"
				>
					<Grid
						item
						className="withSmallGraph"
						// xl={6}
						// md={7}
						// sx={{ width: { xs: '850px' } }}
						sx={{
							width: {
								xs: "100%", md: "50%", xl: "45%"
								// border: '2px solid red'
							}
						}}
					>
						<Box sx={{ transform: { lg: "scale(0.8)", xl: "scale(1)" }, mt: { lg: "-50px", xl: 0 } }}>
							{dataGraphGet
								? (
									<Box sx={{ width: { xs: "310px", sm: "670px" }, m: "70px auto 50px", position: "relative" }}>
										<Box sx={{ display: { xs: "none", sm: "block" } }}>
											<RadarChart
												captions={captions}
												data={data}
												size={670}
												options={{
													scales: 10
												}}
											/>
										</Box>
										<Box sx={{ display: { xs: "block", sm: "none" } }}>
											<RadarChart
												captions={captions}
												data={data}
												size={310}
												options={{
													scales: 10
												}}
											/>
										</Box>

										<Box sx={{ position: "absolute", top: { xs: "87px", sm: "178px" }, left: { xs: "94px", sm: "207px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "107px", sm: "228px" }, left: { xs: "141px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "160px", sm: "362px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
										<Box sx={{ position: "absolute", top: { xs: "200px", sm: "440px" }, left: { xs: "140px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(225deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "219px", sm: "488px" }, left: { xs: "93px", sm: "206px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "199px", sm: "439px" }, left: { xs: "47px", sm: "99px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "28px", sm: "51px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
										<Box sx={{ position: "absolute", top: { xs: "108px", sm: "226px" }, left: { xs: "48px", sm: "98px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(45deg)" }} />
										<Box sx={{ position: "absolute", top: { xs: "-11px", sm: "-37px" }, left: { xs: "136px", sm: "287px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={home} alt="moneyOpacity" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "30px", sm: "60px" }, right: { xs: "32px", sm: "57px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Health} alt="Health" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "137px", sm: "289px" }, right: { xs: "-13px", sm: "-41px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Friend} alt="Friend" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "240px", sm: "514px" }, right: { xs: "32px", sm: "54px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Love} alt="Love" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "282px", sm: "618px" }, right: { xs: "135px", sm: "288px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Career} alt="Friend" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "239px", sm: "518px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Money} alt="step6" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "135px", sm: "285px" }, right: { xs: "282px", sm: "619px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Fun} alt="Fun" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "32px", sm: "55px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
											<img width="100%" src={Growth} alt="Growth" />
										</Box>
										<Box sx={{ position: "absolute", top: { xs: "142px", sm: "308px" }, right: { xs: "142px", sm: "308px" }, width: { xs: "25px", sm: "55px" }, height: { xs: "25px", sm: "55px" }, borderRadius: "100px", background: "#fff", opacity: .4 }} />
									</Box>
								)
								: null
							}
						</Box>
						<Typography mb={1} sx={{ fontSize: { xs: "24px", sm: "36px" }, fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff", lineHeight: "30px" }}>
							{userState.user.name}’s Wheel of Life
						</Typography>
						<Typography sx={{ fontSize: "22px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff", textAlign: "center" }}>
							Date: {date.getDate()}.{mm}.{date.getFullYear()}
						</Typography>

					</Grid>
					<Box mt={8} sx={{ display: { xs: "block", sm: "none", width: "100%" }, position: "relative" }}>
						<Box sx={{ width: "80px", height: "50px", m: "0 auto", background: "rgba(255,255,255, 0.2)", borderTopRightRadius: "60px", borderTopLeftRadius: "60px" }}>
							<ArrowBackIosIcon sx={{ color: "#fff", fontSize: "40px", transform: "rotate(270deg)", pl: "19px", pt: "20px" }} />
						</Box>
						<Box sx={{ position: "absolute", width: "189px", left: 0, top: "-47px" }}>
							<img width="100%" src={meditateleft} alt="shared" />
						</Box>
					</Box>
					<Grid className="withSmallDesc" item sx={{ width: { xs: "100%", md: "50%", xl: "55%" } }}>
						<Box
							className="heightDesc"
							sx={{
								background: "rgba(239,239,239,0.4 )",
								height: { xs: "100%", sm: "100vh" },
								p: { xs: "0 20px", xl: "0 40px" },
								mt: { xs: "0px", sm: 0 }
								// border: '1px solid blue'
							}}
						>
							{filteredData?.length === 0 ?
								<GoodStatus name={name} /> :
								filteredData?.length > 4
									? (
										<BadStatus
											handleDetails={handleDetails}
											items={items}
											name={name}
											handleClickOpen={handleClickOpen}
										/>
									)
									: (
										<DetailsBad
											handleDetails={handleDetails}
											items={items}
											name={name}
											handleClickOpen={handleClickOpen}
											dataGraphGet={dataGraphGet}
										/>
									)
							}
						</Box>
					</Grid>
				</Grid>
				<Box sx={{ display: { xs: "block", sm: "none", background: "rgba(239,239,239,0.4 )" } }}>
					<Box sx={{ width: "90%", m: "0 auto", p: "30px 0 0 0" }} />
				</Box>
			</Box>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Element name="test1" className="element">
					{details !== "" && <DetailsItem details={details} />}
				</Element>
			</Box>
			<Box>
				<Dialog
					fullScreen
					open={open}
					onClose={handleClose}
					// TransitionComponent={Transition}
					sx={{ ".MuiDialog-paper": { background: `url(${ bgDetails }) no-repeat`, backgroundSize: "cover" } }}
				>
					<Dialogs details={details} handleClose={handleClose} />
				</Dialog>
			</Box>
		</Box>

	);
}

export default DetailsContainerShared;
