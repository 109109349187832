import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import useMission from "pages/missionNine/hooks";
import { useEffect, useState } from "react";
import CustomButton from "../../../../../components/customButton";
import { setLocalStorage } from "../../../../../utils/localStorage";
import { InputField } from "./FormFields/InputField";

interface IForm
{
	setStepCourse: any;
	closeForm(): void;

	componentInputs: any;
	addInput: any;
	handleChange: any;
}
export default function FormThisIsWhy({ setStepCourse, closeForm, componentInputs, addInput, handleChange }: IForm)
{
	const missionContext = useMission();
	const [ countValueInputs, setCountValueInputs ] = useState(0);

	function handleStep(step: number)
	{
		setStepCourse(step);
	}

	function onClickNextStep()
	{
		setLocalStorage("mission-nine-inputs", componentInputs);
		if ((componentInputs[ 4 ].value)) { closeForm(); }

		missionContext.setStep(6);
	}

	useEffect(() =>
	{
		let count: number = 0;

		componentInputs.forEach((element: any) =>
		{
			if (element.value)
			{
				count += 1;
			}
			else if (element.items)
			{
				element.items.forEach((elementItems: any) =>
				{
					if (elementItems.value) count += 1;
				});
			}
		});
		setCountValueInputs(count);
	}, [ componentInputs ]);

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "100%", maxWidth: "980px", minWidth: { lg: "785px", xl: "906px" } }}>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
				<Box
					sx={{ display: "flex", flexDirection: "column", height: "100%", maxHeight: "520px", justifyContent: "space-between" }}
					component="form"
					autoComplete="off"
				>
					<Box sx={{
						display: "flex",
						width: "100%",
						height: "auto",
						m: "0 auto",
						marginTop: "80px",
						marginLeft: "37px",
						border: "1px solid rgba(255, 255, 255, .6)",
						borderRadius: "24px",
						wordBreak: "break-all",
						flexDirection: "column",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						boxShadow: "0px 5px 10px 0px #A7C7E199",
						padding: "22px 25px 6px 26px"
					}}>
						<Box
							sx={{ position: "absolute", top: "22px", right: "22px" }}
							onClick={closeForm}
						>
							<CloseIcon sx={{ fill: "#fff", fontSize: "14px", width: "24px", backgroundColor: "#3E809D", borderRadius: "40px", height: "24px", p: "6px", cursor: "pointer" }} />
						</Box>
						<Box sx={{ margin: "0px" }}>
							<Typography sx={{ font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, marginLeft: "16px", marginBottom: "10px", color: "#215C75", textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
								{/* This is why I want to fulfill my purpose... */}
								{componentInputs[ 4 ].title}
							</Typography>
							<InputField
								value={componentInputs[ 4 ].value}
								onChange={(e: any) => handleChange(e.target.value, 4)}
								fullWidth
								autoFocus
								multiline
								rows={3}
								inputProps={{ maxLength: 120 }}
								placeholder={componentInputs[ 4 ].placeholder}
								helperText={`${ componentInputs[ 4 ].value.length }/120 characters`}
							/>
						</Box>
					</Box>
					<Box sx={{ marginLeft: "37px", display: "flex", width: "100%", flexDirection: "row", padding: "15px 0px", justifyContent: "space-between" }}>
						<Box
							sx={{
								position: "relative",
								color: "#fff",
								backgroundColor: "#3E809D",
								borderRadius: "30px",
								width: "40px",
								height: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": { cursor: "pointer" }
							}}
							onClick={() => handleStep(4)}>
							<ArrowBackIosNewIcon />
						</Box>
						<Box />
					</Box>
					<Box sx={{
						marginTop: "-30px",
						marginLeft: "37px",
						display: "flex",
						width: "100%",
						flexDirection: "row",
						padding: "15px 0px",
						justifyContent: "center"
					}}>

						<CustomButton.Contained
							sx={{
								border: "unset",
								backgroundColor: (countValueInputs < 11 ? "#3E809D80" : "#3E809D"),
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)"
							}}
							disabled={countValueInputs < 11}
							onClick={onClickNextStep}
						>
							I am done
						</CustomButton.Contained>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
