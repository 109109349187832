import { Box, Grow } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import IMGBgCave1 from "assets/images/missions/mission-seven/bg-cave1.png";
import IMGBgCave2 from "assets/images/missions/mission-seven/bg-cave2.png";
import IMGBgDefault from "assets/images/missions/mission-seven/bg-default.png";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { IMissionContext, MissionCtx } from "./MissionCtx";
import Complete from "./components/Complete";
import KeepAnEyeOnYourBeliefs from "./components/KeepAnEyeOnYourBeliefs";
import LetsPlayGame from "./components/LetsPlayGame";
import LetsTalkAboutBeliefs from "./components/LetsTalkAboutBeliefs";
import LetsUncoverDeepestSittingBeliefs from "./components/LetsUncoverDeepestSittingBeliefs";
import LetsUncoverDeepestSittingBeliefsIAm from "./components/LetsUncoverDeepestSittingBeliefs/IAm";
import LetsUncoverDeepestSittingBeliefsIShould from "./components/LetsUncoverDeepestSittingBeliefs/IShould";
import LetsUncoverYourBeliefs from "./components/LetsUncoverYourBeliefs";
import LetsUncoverYourBeliefsFinish from "./components/LetsUncoverYourBeliefs/Finish";
import MyNewEmpoweringBeliefs from "./components/MyNewEmpoweringBeliefs";
import RewriteYourLimitingBeliefs from "./components/RewriteYourLimitingBeliefs";
import SummaryScreen from "./components/SummaryScreen/index";
import WhatAreYourFamilyBeliefsCelebration from "./components/WhatAreYourFamilyBeliefs/Celebration";
import WhatAreYourFamilyBeliefsInputs from "./components/WhatAreYourFamilyBeliefs/Inputs";
import WhatAreYourFamilyBeliefsSelect from "./components/WhatAreYourFamilyBeliefs/Select";
import WhatBeliefsWouldYouRatherHave from "./components/WhatBeliefsWouldYouRatherHave";
import WhatBeliefsWouldYouRatherHaveFinish from "./components/WhatBeliefsWouldYouRatherHave/Finish";
import YouCanChallengeYourBeliefs from "./components/YouCanChallengeYourBeliefs";
import YouTopLimitingBeliefs from "./components/YouTopLimitingBeliefs";
import YouTopLimitingBeliefsCelebration from "./components/YouTopLimitingBeliefs/Celebration";
import Feedback from "./components/feedback";

export type BackgroundType = Record<"default" | "cave1" | "cave2", string>;

// Background
const Background: BackgroundType =
{
	default: `url(${ IMGBgDefault }) center center/cover`,
	cave1: `url(${ IMGBgCave1 }) center center/cover`,
	cave2: `url(${ IMGBgCave2 }) center center/cover`
};

const BackgroundPreload = [
	IMGBgDefault,
	IMGBgCave1,
	IMGBgCave2
];

const STEPS = [
	/* Step:  1 */ LetsPlayGame,
	/* Step:  2 */ LetsTalkAboutBeliefs, /* Chat */
	/* Step:  3 */ WhatBeliefsWouldYouRatherHave,
	/* Step:  4 */ WhatBeliefsWouldYouRatherHaveFinish,
	/* Step:  5 */ LetsUncoverYourBeliefs, /* Blitz */
	/* Step:  6 */ LetsUncoverYourBeliefsFinish,
	/* Step:  7 */ WhatAreYourFamilyBeliefsSelect,
	/* Step:  8 */ WhatAreYourFamilyBeliefsInputs,
	/* Step:  9 */ WhatAreYourFamilyBeliefsCelebration,
	/* Step: 10 */ LetsUncoverDeepestSittingBeliefs, /* and Extra step 0, 1 */
	/* Step: 11 */ YouTopLimitingBeliefs,
	/* Step: 12 */ YouTopLimitingBeliefsCelebration,
	/* Step: 13 */ YouCanChallengeYourBeliefs, /* Chat */
	/* Step: 14 */ RewriteYourLimitingBeliefs,
	/* Step: 15 */ MyNewEmpoweringBeliefs,
	/* Step: 16 */ KeepAnEyeOnYourBeliefs,
	/* Step: 17 */ SummaryScreen,
	Complete,
	Feedback
] as const;

const EXTRA_STEPS = [
	LetsUncoverDeepestSittingBeliefsIShould,
	LetsUncoverDeepestSittingBeliefsIAm
] as const;

const PERCENTAGE_PER_STEP: number = 100 / (STEPS.length - 1);

function MissionSevenContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ step, setStep ] = useState<number>(() =>
	{
		const step = getLocalStorage<number>("mission-seven-step");

		return step ?? 1;
	});
	const [ extraStep, setExtraStep ] = useState<number | null>(() =>
	{
		const extraStep = getLocalStorage<number>("mission-seven-extraStep");

		return extraStep;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ firstPlaythrough, setFirstPlaythrough ] = useState<boolean>(false);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: JSX.Element; position?: "bottom" | "center"; } | null>(null);
	const [ isDisplayHeader, setIsDisplayHeader ] = useState<boolean>(true);

	// Context
	const MissionContext: IMissionContext = useMemo(() =>
	{
		return {
			step,
			setStep,
			extraStep,
			setExtraStep,
			nextStep,
			prevStep,
			progress,
			setProgress,
			background,
			setBackground,
			isDisplayHeader,
			setIsDisplayHeader,
			firstPlaythrough,
			setFirstPlaythrough
		};
	}, [ background, extraStep, isDisplayHeader, progress, step ]);

	// Utils
	function changeBackground(step: number)
	{
		switch (step)
		{
			default: { setBackground("default"); }
		}
	}

	function prevStep()
	{
		setStep((prevStep) => prevStep - 1);
	}

	function nextStep()
	{
		setStep((prevStep) => prevStep + 1);
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(7, step).catch((error) => console.error(error));

		setLocalStorage("mission-seven-step", step);

		changeBackground(step);
		setProgress((step - 1) * PERCENTAGE_PER_STEP);

		// Close modal
		setIsShowTips(false);

		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 5: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent="contentMissionSeven" keyText="LetsUncoverYourBeliefs_tips" defaultValue="**Why:** In this exercise you will identify your perspective about important things. It will help you discover those beliefs that may be holding you back.\n\n**Do:** Answer each quickly with your FIRST inclination before the timer runs out.\n\n**Think:** Don’t think too much about each question, just write down whatever comes to mind first. If you are stuck, ask yourself" position="right" isDescription />)
					}
				);
				break;
			}

			case 7:
			case 8: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefs_tips" defaultValue="**Why:** Reflecting on your family beliefs can help you uncover the deepest sitting perspectives that you’ve learned as a child. Not all beliefs you’ll uncover in this exercise will be limiting. You may discover a few empowering ones too. It’s good to be aware of why you believe certain things about various aspect of your life.\n\n**Do:** Think about your family members, one by one. Write down phrases you heard from them say over and over again as a child or as an adult.\n\n**Think:** What phrases come to mind as their life credos when you think about them? What do they often say about life, money, work, marriage and other things?" position="right" isDescription />)
					}
				);
				break;
			}

			case 14: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent="contentMissionSeven" keyText="RewriteYourLimitingBeliefs_tips" defaultValue="**Why:** If a belief is disempowering, we should question it and replace it with a new, empowering belief. Once you change your belief system, you will open up doors to new opportunities for designing a more fulfilling life.\n\n**Do:** Work through your limiting beliefs one by one. Turn each limiting belief on its head and rewrite it as an empowering statement.\n\n**Think:** Rewriting some beliefs might be tricky, or feel unnatural. That’s normal, as you held on to that belief for a while. If you get stuck, you can try to rewrite your belief as an opposite statement or a statement that puts you in control of a situation." position="right" isDescription />)
					}
				);
				break;
			}

			default: { setTipsData(null); }
		}
	}, [ step ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-extraStep", extraStep);
	}, [ extraStep ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-progress", progress);
	}, [ progress ]);

	// Render
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<MissionCtx.Provider value={MissionContext}>
				<Box
					className="mission-root"
					sx={{
						width: "100%",
						display: { sm: "flex", xs: "none" },
						flexDirection: "column",
						alignItems: "center",
						gap: "25px",
						background: Background[ background ],
						minHeight: "100vh",
						backgroundAttachment: "fixed",
						padding: { xl: step === 18 || step === 19 ? 0 : "24px", lg: step === 18 || step === 19 ? 0 : "16px", xs: step === 18 || step === 19 ? 0 : "16px" },
						transition: `linear ${ step === STEPS.length ? 1500 : 300 }ms background`,
						overflowX: "hidden",

						"& .TransitionGroup":
						{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							width: "100%"
						},
						"& .mission-main":
						{
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "25px"
						},
						"& .main__content":
						{
							maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}
					}}
				>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 18 || step === 19 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box sx={{ display: step === 18 || step === 19 ? "none" : "block", width: "100%" }}>
						<Box
							component="header"
							className="mission-header"
							sx={{
								display: (isDisplayHeader === true ? "flex" : "none"),
								justifyContent: "space-between",
								gap: "15px",
								alignItems: "center",
								width: "100%",
								maxHeight: "40px",
								position: "relative",
								zIndex: "10"
							}}
						>
							<Box
								component={Link}
								to="/dashboard"
								sx={{
									width: { xl: "60px", lg: "48px", xs: "60px" },
									height: { xl: "40px", lg: "32px", xs: "32px" },
									"& svg": { width: "100%", height: "100%" },
									"&:hover svg path": { fillOpacity: 1 }
								}}
							>
								<TooltipStyled title="Back to missions" placement="bottom" arrow>
									<SVGArrowBack />
								</TooltipStyled>
							</Box>

							{process.env.NODE_ENV === "development" &&
								(
									<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
										Step:
										<Box
											component="input"
											type="number"
											min={1}
											max={STEPS.length}
											value={step}
											onChange={({ target }) => setStep(target.valueAsNumber)}
											sx={{ marginLeft: "10px", textAlign: "center" }}
										/> / {STEPS.length}

									</Box>
								)
							}

							<Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
								<Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
									{tipsData !== null &&
										(
											<TooltipStyled title="Tips" placement="bottom" arrow>
												<TipsButton
													isActive={isBlipTips}
													blip={false}
													onClick={() => { setIsShowTips(true); setIsBlipTips(false); }}
												/>
											</TooltipStyled>
										)
									}
								</Box>

								<MissionProgressBar value={progress} />
							</Box>
						</Box>
					</Box>

					{/* <TransitionGroup className="TransitionGroup"> */}
					{extraStep !== null &&
						EXTRA_STEPS.map((Component, index) =>
						{
							if (extraStep !== index + 1)
							{
								return false;
							}

							// @ts-ignore
							return (<Component key={index} />);
							// return (
							// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
							// 		<Component />
							// 	</Grow>
							// );
						})
					}
					{extraStep === null &&
						STEPS.map((Component, index) =>
						{
							if (step !== index + 1)
							{
								return false;
							}

							// @ts-ignore
							return (<Component key={index} />);
							// return (
							// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
							// 		<Component />
							// 	</Grow>
							// );
						})
					}
					{extraStep === null && step > STEPS.length &&
						(
							<Grow timeout={{ enter: 1000, exit: 0 }} in unmountOnExit><h1>Step: {step} / {STEPS.length}</h1></Grow>
						)
					}
					{/* </TransitionGroup> */}

					{/* Tips content */}
					{tipsData !== null &&
						(
							<MissionsDialog
								title={tipsData.title}
								open={isShowTips}
								directionSlide="down"
								onClose={() => setIsShowTips(false)}
								position={tipsData.position ?? "center"}
								sx={{ "& .MuiPaper-root": { maxWidth: "980px" } }}
							>
								{tipsData.content}
							</MissionsDialog>
						)
					}
				</Box>
			</MissionCtx.Provider>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default MissionSevenContainer;
