import { Box, SxProps, Theme, alpha } from "@mui/material";
import { ReactNode } from "react";
import { ReactourStep } from "reactour";

import IMGOcta from "assets/images/octa.png";
import IMGOcta2 from "assets/images/octa2-shrink.png";

export interface ITourContentProps
{
	/**
	 * @default "right"
	 */
	readonly octaPosition?: "right" | "left" | "bottom";
	/**
	 * @default "octa2"
	 */
	readonly octaVariation?: "octa" | "octa2";
	readonly sx?: SxProps<Theme>;
	readonly children: ReactNode;
}

const OCTA_VARIATION =
{
	octa: IMGOcta,
	octa2: IMGOcta2
};

export function TourContent({ octaPosition = "right", octaVariation = "octa2", sx = [], children }: ITourContentProps)
{
	return (
		<Box
			className="TourContent-root"
			sx={[
				{
					width: { xl: "356px", lg: "272px", xs: "272px" },
					height: "fit-content",
					padding: "24px",
					backgroundColor: "#E9EDF0",
					boxShadow: "0px 3px 6px " + alpha("#000000", 0.161),
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "16px",
					color: "primary.dark",
					font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
					position: "relative"
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			{children}

			<Box
				className="TourContent-image"
				component="img"
				src={OCTA_VARIATION[ octaVariation ] ?? IMGOcta2}
				alt=""
				sx={[
					{
						height: { xl: "267px", lg: "213px", xs: "213px" },
						position: "absolute",
						top: "-15px"
					},
					(octaPosition === "right") &&
					{
						right: "-34px",
						transform: "translateX(100%) rotate(2.63deg)"
					},
					(octaPosition === "left" && octaVariation === "octa2") &&
					{
						left: "-7px",
						transform: "translateX(-105%) rotate(2.63deg)"
					},
					(octaPosition === "left" && octaVariation === "octa") &&
					{
						left: "-7px",
						transform: "translateX(-105%) rotate(13.78deg)",
						height: { xl: "345px", lg: "300px" }
					},
					(octaPosition === "bottom") &&
					{
						transform: "translateY(140%) translateX(-100%) rotate(2.63deg)"
					}
				]}
			/>
		</Box>
	);
}

// <img src={OctaTourPage} style={{ height: "236px" }} alt="" />

export const tourConfig: ReactourStep[] = [
	{ // 1
		selector: '[data-tut="artifact-1"]',
		content()
		{
			return (
				<TourContent
					octaPosition="left"
					sx={{
						left: { xl: "-100px", lg2: "-50px", xs: "50px" },
						top: { xl: "-70px", lg2: "-80px", xs: "-70px" },

						"& + div, & + div + *":
						{
							position: "relative !important",
							left: { xl: "-100px", lg2: "-50px", xs: "50px" },
							top: { xl: "-70px", lg2: "-80px", xs: "-70px" }
						}
					}}
				>
					After completing each mission, an artifact will appear here.
				</TourContent>
			);
		},
		highlightedSelectors: [ "body" ]
	},
	{ // 2
		selector: '[data-tut="wheel-of-life"]',
		content()
		{
			return (
				<TourContent
					octaPosition="left"
					octaVariation="octa"
					sx={{
						left: { xl: "300px", lg2: "125px", xs: "190px" },
						top: { xl: "150px", lg2: "50px", xs: "90px" },

						"& + div, & + div + *":
						{
							position: "relative !important",
							left: { xl: "300px", lg2: "125px", xs: "190px" },
							top: { xl: "150px", lg2: "50px", xs: "90px" }
						}
					}}
				>
					You can view, update or create new artifacts. We will store them all here.
				</TourContent>
			);
		}
	},
	{ // 3
		selector: '[data-tut="reactour__myJourney"]',
		content()
		{
			return (
				<TourContent
					octaPosition="left"
					sx={{
						left: { xl: "10px", lg: "24px", xs: "20px" },
						top: { xl: "120px", lg: "120px", xs: "260px" },

						"& + div, & + div + *":
						{
							position: "relative !important",
							left: { xl: "10px", lg: "24px", xs: "20px" },
							top: { xl: "120px", lg: "120px", xs: "260px" }
						}
					}}
				>
					And here you can see all your missions and continue the quest!
				</TourContent>
			);
		}
	}
];
