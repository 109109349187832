import { alpha, Chip, styled } from "@mui/material";

export default styled(Chip)(({ variant = "outlined" }) => (
	[
		{
			padding: "9px 16px",
			height: "unset",
			gap: "16px",
			backgroundColor: alpha("#FFFFFF", 0.5),
			boxShadow: "0px 3px 6px " + alpha("#000000", 0.161),
			border: "unset",
			borderRadius: "40px",
			font: "400 16px/22px 'Open Sans'",
			color: "#4D4D4D",
			transition: "linear 300ms background-color",

			"&.Mui-focusVisible":
			{
				backgroundColor: alpha("#FFFFFF", 0.5)
			},
			"& .MuiChip-label":
			{
				padding: "unset"
			},
			"& .MuiChip-deleteIcon":
			{
				margin: "0 -3px",
				fontSize: "19px",
				fill: "#3E809D",
				opacity: 0.5,

				"&:hover": { opacity: 1 }
			}
		},
		(variant === "filled") &&
		{
			color: "#FFFFFF",
			backgroundColor: alpha("#215C75", 0.5),

			"&.Mui-focusVisible":
			{
				backgroundColor: alpha("#215C75", 0.5)
			},
			"& .MuiChip-deleteIcon":
			{
				fill: "#FFFFFF"
			}
		}
	]
)) as typeof Chip;
