import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, TextField, Typography } from "@mui/material";
import Api from "api";
import union from "assets/images/missions/mission-nine/Union.svg";
import ArrowButton from "components/ArrowButton";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { UserActionType } from "reducers/userReducer";
import trackEvent from "utils/mixpanelUtil";
import useMission from "../../hooks/useMission";
import Results from "./Results";

export interface ItemsBoard
{
	id: number;
	title: string;
	active: boolean;
	values: Values[];
	checked: boolean;
	deleted: boolean;
}
interface Values
{
	title: string;
	checked: boolean;
	deleted: boolean;
}
export interface Board
{
	id: number;
	title: string;
	active: boolean;
	items: ItemsBoard[];
	checkedItems?: string[];
}
function ActionPlan()
{
	const missionContext = useMission();
	const [ boards, setBoards ] = useState<Board[]>([]);
	const [ showResults, setShowResults ] = useState(false);
	const [ checkedItems, setCheckedItems ] = useState<string[]>([]);
	const [ valueInput, setValueInput ] = useState<string>("");
	const [ valueInputTask, setValueInputTask ] = useState<string>("");
	const [ error, setError ] = useState<string>("");
	const [ errorAddTask, setErrorAddTask ] = useState<string>("");
	const { state: userState, dispatch: userDispatch } = useUser();

	function handleShowResults()
	{

		const data = [ boards, checkedItems ];

		Api.missions
			.setDataMissionNine(data)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(9) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 9 }
					}
				);
				localStorage.setItem("mission-nine-graph-data-plan", JSON.stringify(boards));
				setShowResults(true);
			})
			.catch((error: any) =>
			{
				console.error(error);
			});
	}

	function onClickNextStep()
	{

		missionContext.setStep((prevStep) => prevStep + 1);
	}

	useEffect(() =>
	{
		const localData = JSON.parse(`${ localStorage.getItem("mission-nine-graph-data-good") }`);
		let arrCheckedItems: string[] = [];

		localData.forEach((element: any) =>
		{
			element.items.forEach((item: any) =>
			{
				if (item.checked === true) arrCheckedItems.push(item.title);
			});
		});
		setCheckedItems(arrCheckedItems);
		if (localStorage.getItem("mission-nine-graph-data-plan") !== null)
		{
			setBoards(JSON.parse(`${ localStorage.getItem("mission-nine-graph-data-plan") }`));

			return;
		}

		const data = [
			{
				id: 1,
				title: "Now",
				items: localData[ 0 ].items.filter((item: any) => item.checked === false).map((item: any, index: number) => (
					{
						id: index + 1,
						title: item.title,
						active: index === 0,
						values: [],
						checked: item.checked,
						deleted: false
					}
				)),
				active: true
			},
			{
				id: 2,
				title: "6 months",
				items: localData[ 1 ].items.filter((item: any) => item.checked === false).map((item: any, index: number) => (
					{
						id: index + 1,
						title: item.title,
						active: index === 0,
						values: [],
						checked: item.checked,
						deleted: false
					}
				)),
				active: false
			},
			{
				id: 3,
				title: "1-2 years",
				items: localData[ 2 ].items.filter((item: any) => item.checked === false).map((item: any, index: number) => (
					{
						id: index + 1,
						title: item.title,
						active: index === 0,
						values: [],
						checked: item.checked,
						deleted: false
					}
				)),
				active: false
			},
			{
				id: 4,
				title: "2-5 years",
				items: localData[ 3 ].items.filter((item: any) => item.checked === false).map((item: any, index: number) => (
					{
						id: index + 1,
						title: item.title,
						active: index === 0,
						values: [],
						checked: item.checked,
						deleted: false
					}
				)),
				active: false
			}
		];

		setBoards(data);
	}, []);
	function handleAddArrValues(event: KeyboardEvent<HTMLInputElement>, id: number)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInput.trim().length < 2)
		{
			return setError("Enter at least 2 characters");
		}
		else if (valueInput.trim().length > 200)
		{
			return setError("The text should not exceed 200 characters");
		}

		const newBoards = [ ...boards ];

		newBoards.forEach(element =>
		{
			if (element.id === id)
			{
				element.items.forEach((itemValue) =>
				{
					if (itemValue.active)
					{
						itemValue.values.push({
							title: valueInput,
							checked: false,
							deleted: false
						});
					}
				});
			}
		});
		setBoards(newBoards);
		setValueInput("");
	}

	function handleAddArrTask(event: KeyboardEvent<HTMLInputElement>, id: number)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		if (valueInputTask.trim().length < 2)
		{
			return setErrorAddTask("Enter at least 2 characters");
		}
		else if (valueInputTask.trim().length > 100)
		{
			return setErrorAddTask("The text should not exceed 100 characters");
		}

		const newBoards = [ ...boards ];

		newBoards.forEach(element =>
		{
			if (element.id === id)
			{
				element.items.push({
					id: Math.random(),
					title: valueInputTask,
					active: element.items.length === 0,
					values: [],
					checked: false,
					deleted: false
				});
			}
		});
		setBoards(newBoards);
		setValueInputTask("");
		setErrorAddTask("");
	}

	function handleBoard(id: number)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((board) =>
		{
			if (board.id === id)
			{
				board.active = true;
			}
			else board.active = false;
		});
		setBoards(newBoards);
	}

	function handleItem(id: number)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((board) =>
		{
			if (board.active)
			{
				board.items.forEach((item) =>
				{
					if (item.id === id)
					{
						item.active = true;
					}
					else item.active = false;
				});
			}

		});
		setBoards(newBoards);
	}

	function showDelete(id: number, idItem: number)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((item: Board) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: ItemsBoard) =>
				{
					if (itemBoard.id === idItem)
					{
						itemBoard.deleted = true;
					}
				});
		});
		setBoards(newBoards);
	}

	function closeDelete(id: number, idItem: number)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((item: Board) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: ItemsBoard) =>
				{
					if (itemBoard.id === idItem)
					{
						itemBoard.deleted = false;
					}
				});
		});
		setBoards(newBoards);
	}

	function deleteItem(id: number, idItem: number)
	{
		const newBoards = [ ...boards ];
		const updatedBoards = newBoards.map((element: any) =>
		{
			if (element.id === id)
			{
				return { ...element, items: element.items.filter((itemBoard: any) => itemBoard.id !== idItem) };
			}

			return element;

		});

		updatedBoards.forEach(item =>
		{
			if (item.id === id)
			{
				if (item.items.length > 0)
					item.items[ 0 ].active = true;
			}
		});
		setBoards(updatedBoards);
	}

	function showDeleteStep(id: number, title: string)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((item: Board) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: ItemsBoard) =>
				{
					if (itemBoard.active)
					{
						itemBoard.values.forEach(itemStep =>
						{
							if (itemStep.title === title) itemStep.deleted = true;
						});
					}
				});
		});
		setBoards(newBoards);
	}

	function closeDeleteStep(id: number, title: string)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((item: Board) =>
		{
			if (item.id === id)
				item.items.forEach((itemBoard: ItemsBoard) =>
				{
					if (itemBoard.active)
					{
						itemBoard.values.forEach(itemStep =>
						{
							if (itemStep.title === title) itemStep.deleted = false;
						});
					}
				});
		});
		setBoards(newBoards);
	}

	function deleteStep(id: number, title: string)
	{
		const newBoards = [ ...boards ];
		const updatedBoards = newBoards.map((element: any) =>
		{
			if (element.id === id)
			{
				const newItems = element.items.map((item: ItemsBoard) =>
				{
					if (item.active)
					{
						return { ...item, values: item.values.filter((itemBoard: any) => itemBoard.title !== title) };
					}

					return item;

				});

				return { ...element, items: newItems };
			}

			return element;

		});

		setBoards(updatedBoards);
	}

	return (
		<>
			<Box
				className="main__content"
				sx={{
					height: "84vh",
					width: "100%",
					m: "0 auto",
					position: "relative",
					maxWidth: { lg: "814px", xl: "1036px" },
					top: "20px",
					left: "0px"
				}}>
				<PanelTitle
					positionOcta
					title={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="action_plan_title"
						defaultValue="Your action plan"
						position="left"
					/>}
					subTitle={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="action_plan_title_subTitle"
						defaultValue="You will come back to your unknowns in your next mission, but for now, let’s define actions for everything that you are certain about."
						position="left"
						isDescription />}
					imageVariation="closeEye"
				/>
				{!showResults
					? (
						<Box>
							<Box sx={{
								// border: '1px solid rgba(255, 255, 255, 0.5)',
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								p: { lg: "19px 19px 25px 19px", xl: "28px 24px 32px" },
								boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
								borderRadius: "24px",
								width: { lg: "785px", xl: "980px" },
								height: { lg: "402px", xl: "584px" },
								ml: 3,
								backdropFilter: "blur(30px)",
								position: "relative",
								top: "-10px",
								left: "15px"
							}}>
								<Box sx={{ display: "flex", justifyContent: "space-between", mb: "28px" }}>
									{boards?.map((item) => (
										<Box
											key={item.id}
											onClick={() => handleBoard(item.id)}
											sx={{
												width: { lg: "165px", xl: "205px" },
												background: "rgba(255, 255, 255, 0.5)",
												borderRadius: "40px",
												p: { lg: 1, xl: "14px" },
												font: { lg: "700 16px/25px Open Sans", xl: "700 18px/25px Open Sans" },
												color: item.active ? "#215C75" : "rgba(62, 128, 157, 0.5)",
												textAlign: "center",
												textTransform: "uppercase",
												boxShadow: item.active ? "0px 3px 6px rgba(0, 0, 0, 0.161)" : "none",
												cursor: "pointer"
											}}>
											{item.title}
										</Box>
									))}
								</Box>
								{boards?.filter((item) => item.active).map((item) => (
									<Box key={item.id} sx={{ display: "flex", justifyContent: "space-between" }}>
										<Box
											className="customScroll"
											sx={{
												width: "442px",
												height: { lg: "280px", xl: "442px" },
												overflow: "auto",
												pr: 2
											}}>
											{item.items
												.filter((itemContent) => itemContent.checked === false)
												.map((itemContent, index: number) =>
												{
													return (
														<Box
															key={itemContent.id}
															sx={{
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
																width: "100%",
																p: { lg: 1, xl: "13px 13px" },
																borderRadius: "8px",
																cursor: "pointer",
																background: itemContent.active ? "rgba(62, 128, 157, 0.5)" : "rgba(255, 255, 255, 0.5)",
																mb: index + 1 !== item.items.length ? 2 : 0,
																zIndex: 1,
																position: "relative"
															}}
															onMouseOver={() => showDelete(item.id, itemContent.id)}
															onMouseOut={() => closeDelete(item.id, itemContent.id)}
														>
															<Box onClick={() => handleItem(itemContent.id)} sx={{ width: "100%" }}>
																<Typography
																	key={index}
																	sx={{
																		font: { lg: "400 16px/30px Open Sans", xl: "400 22px/30px Open Sans" },
																		color: itemContent.active ? "#fff" : "#4D4D4D",
																		maxWidth: "330px"
																	}}>
																	{`${ index + 1 }. ${ itemContent.title }`}
																</Typography>
															</Box>

															{itemContent.deleted
																? (
																	<CloseIcon
																		onClick={() => deleteItem(item.id, itemContent.id)}
																		sx={{ fill: "#215C75", cursor: "pointer", zIndex: 9999, position: "absolute", right: "15px" }}
																	/>
																)
																: null}
														</Box>
													);

												})
											}
											<TextField
												placeholder="Add new"
												value={valueInputTask}
												onChange={(e: ChangeEvent<HTMLInputElement>) => setValueInputTask(e.target.value)}
												onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleAddArrTask(e, item.id)}
												inputProps={{ maxLength: 100 }}
												sx={{
													border: "unset",
													width: "100%",
													mt: 2,
													"& .MuiInputBase-root":
													{
														backgroundColor: "#fff",
														padding: "13px 16px",
														borderRadius: "8px"
													},
													"& .MuiInputBase-input":
													{
														font: { lg: '400 16px/30px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
														padding: "0",
														color: "primary.dark",
														"&::placeholder": { opacity: 0.5 }
													},
													"& .MuiOutlinedInput-notchedOutline":
													{
														display: "none"
													}
												}}
											/>
											{errorAddTask}
										</Box>
										<Box sx={{ background: `url(${ union })`, width: { lg: "415px", xl: "443px" }, height: { lg: "280px", xl: "442px" } }}>
											<Typography sx={{
												font: { lg: "700 16px/30px Open Sans", xl: "700 22px/30px Open Sans" },
												color: "#4D4D4D",
												textAlign: "center",
												m: "24px 0px 16px 20px"
											}}>
												<ContentAdmin
													keyContent="contentMissionNine"
													keyText="asdsadasdassad"
													defaultValue="What steps will you take?"
													position="left"
												/>
											</Typography>
											<TextField
												value={valueInput}
												inputProps={{ maxLength: 200 }}
												onChange={(e: ChangeEvent<HTMLInputElement>) => { setValueInput(e.target.value); setError(""); }}
												onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleAddArrValues(e, item.id)}
												placeholder="add step here"
												helperText={<Typography sx={{ color: "#4D4D4D", font: "400 14px/19px 'Open Sans'", mt: 0, opacity: 0.5 }}>{`${ valueInput.length }/200 characters`}</Typography>}
												sx={{
													"& .MuiInputBase-root fieldset": {
														border: "none"
													},
													"& .MuiOutlinedInput-root": {
														font: { lg: '400 16px/30px "Open Sans"', xl: '400 22px/30px "Open Sans"' },
														color: "#4D4D4D !important",
														backgroundColor: "#fff",
														borderRadius: "8px",
														height: { lg: "67px", xl: "84px" },
														width: { lg: "303px", xl: "378px" },
														ml: "40px"
													},
													"& .MuiFormHelperText-contained": {
														textAlign: "right",
														mt: 0
													}
												}}
											/>
											{error}
											<Box
												className="customScroll"
												sx={{
													overflow: "auto",
													height: { lg: "100px", xl: "240px" },
													mr: "25px",
													mt: "13px"
												}}>
												{item.items.filter((item) => item.active)[ 0 ]?.values?.map((itemStep: Values, indexVal: number) =>
												{
													return (
														<Box
															key={indexVal}
															onMouseOver={() => showDeleteStep(item.id, itemStep.title)}
															onMouseOut={() => closeDeleteStep(item.id, itemStep.title)}
															sx={{
																display: "flex",
																alignItems: "center",
																m: "0px 0px 16px 50px",
																justifyContent: "space-between",
																cursor: "pointer"
															}}>
															<Box sx={{
																display: "flex", alignItems: "center", gap: "15px"
															}}>
																<FiberManualRecordIcon sx={{ fill: "#4D4D4D", fontSize: "7px" }} />
																<Typography
																	sx={{ color: "#4D4D4D", font: { lg: "400 16px/30px 'Open Sans'", xl: "400 22px/30px 'Open Sans'" } }}
																>
																	{itemStep.title}
																</Typography>
															</Box>
															{itemStep.deleted
																? (
																	<CloseIcon
																		onClick={() => deleteStep(item.id, itemStep.title)}
																		sx={{ fill: "#215C75", cursor: "pointer" }}
																	/>
																)
																: null
															}
														</Box>
													);
												})}
											</Box>
										</Box>
									</Box>
								))}
							</Box>
							<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
								<CustomButton.Outline
									sx={{
										fontSize: "24px",
										lineHeight: "33px",
										backgroundColor: "#3E809D",
										border: "none",
										color: "#fff !important",
										boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
										width: "280px",
										position: "relative",
										top: "-10px"
									}}
									onClick={handleShowResults}
								>
									Submit
								</CustomButton.Outline>
							</Box>
						</Box>
					)
					: (
						<Box sx={{ position: "relative", top: "-10px", left: "15px" }}>
							<Results boards={boards} />
						</Box>
					)
				}
			</Box>

			{showResults
				? (
					<StepperNavigationWrapper absolute sx={{ bottom: 0, right: "25px" }}>
						<ArrowButton direction="left" disabled hidden />
						<ArrowButton
							direction="right"
							onClick={onClickNextStep}
						/>
					</StepperNavigationWrapper>
				)
				: null}
		</>
	);
}

export default ActionPlan;
