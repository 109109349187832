import { Box, BoxProps, CardMedia } from "@mui/material";
import { ForwardRefExoticComponent, Ref, forwardRef } from "react";

const DEFAULT_SIZE: number = 200;

export interface IImageButtonProps extends Omit<BoxProps<"button">, "value">
{
	value: JSX.Element | string;
	/**
	 * @default false
	 */
	displayValue?: boolean;
	image: string;
	/**
	 * @default 200
	 */
	size?: number;
	width?: number;
	showImage?: boolean;
}

function ImageButton(
	{
		value,
		displayValue = false,
		image,
		size = DEFAULT_SIZE,
		width,
		showImage,
		sx = [],
		...props
	}: IImageButtonProps,
	ref: Ref<HTMLDivElement>
)
{
	return (
		<Box
			component="button"
			className="ImageButton-root"
			sx={[
				{
					"--imageWidth": width,
					"--size": size,
					"--defaultSize": DEFAULT_SIZE,
					"--percent": "calc(var(--size) / var(--defaultSize))",

					width: "calc(var(--size) * 1px)",
					position: "relative",
					cursor: "pointer",
					border: "unset",
					background: "unset",
					padding: "unset",

					"& .ImageButton-image":
					{
						position: "relative",
						zIndex: "2",
						backgroundColor: "#E9EDF0",
						width: "calc(var(--size) * 1px)",
						height: "calc(var(--size) * 1px)",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						border: "3px solid #fff",
						boxShadow: "0px 1.07502px 16.3941px rgba(0, 0, 0, 0.25)",
						"&:hover": {
							boxShadow: "0px 0px 4.95924px #FFFFFF, 0px 0.727132px 12.6736px #FFD25E"
						}
					},
					"& .ImageButton-value":
					{
						position: "absolute",
						zIndex: "1",
						top: "calc(90px * var(--percent))",
						left: "0",
						right: "0",
						background: "#E7E7E7",
						padding: "calc(126px * var(--percent)) calc(10px * var(--percent)) calc(24px * var(--percent)) calc(10px * var(--percent))",
						color: "#487382",
						font: "700 16px/20px 'Lora-Bold'",
						textAlign: "center",
						borderRadius: "24px",
						opacity: 0,
						paddingTop: "0px",
						paddingBottom: "0px",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 300ms, linear 0ms opacity 300ms"
					},
					"&:hover .ImageButton-value, .ImageButton-value--display":
					{
						opacity: 1,
						paddingTop: "calc(126px * var(--percent))",
						paddingBottom: "calc(24px * var(--percent))",
						transition: "linear 300ms padding-top, linear 0ms padding-bottom 0ms, linear 0ms opacity"
					}
				},
				...(Array.isArray(sx) ? sx : [ sx ])
			]}
			ref={ref}
			{...props}
		>
			<div className="ImageButton-image">
				{!showImage && <CardMedia
					component="img"
					image={image}
					alt=""
					sx={{ width: "calc(var(--imageWidth) * 1px)" }}
				/>}
			</div>

			<div className={"ImageButton-value " + (displayValue === true ? "ImageButton-value--display" : "")}>
				{value}
			</div>
		</Box>
	);
}

export default forwardRef(ImageButton) as ForwardRefExoticComponent<IImageButtonProps>;
