import { Box, Button, ButtonProps, CircularProgress, alpha, styled } from "@mui/material";
import { ExoticComponent, ReactNode, Ref, forwardRef } from "react";

const ButtonBaseStyled = styled(Button)(({ theme, fullWidth = false, size }) => (
	{
		display: "inline-flex",
		gap: "16px",
		backgroundColor: "#E9EDF0",
		borderRadius: "40px",
		boxShadow: "-3px -3px 7px #FFFFFF, 3px 3px 7px rgba(0, 0, 0, .2)",
		font: "700 24px/33px 'Open Sans'",
		flexGrow: (fullWidth ? 1 : 0),
		width: "fit-content",
		minHeight: "56px",
		height: "auto",
		lineHeight: "100%",
		color: "#215C75",
		padding: "10px 80px",
		textTransform: "none",
		flexShrink: 0,

		"&:hover":
		{
			backgroundColor: alpha("#FFFFFF", 0.5),
			color: "#215C75"
		},
		"&:pressed":
		{
			backgroundColor: "#FFFFFF",
			color: "#215C75"
		},
		"&:disabled":
		{
			opacity: 0.5,
			backgroundColor: "#E9EDF0",
			color: theme.palette.primary.dark
		},
		"& > div > span": { width: "unset !important", height: "unset !important" },
		"& svg": { width: "25px", height: "25px" },
		"& svg *": { stroke: "#215C75" },
		"&:hover svg *": { stroke: "#215C75" },

		[ theme.breakpoints.down("xl") ]:
		{
			minWidth: "unset",
			minHeight: "40px",
			height: "40px",
			padding: (size === "large" ? "7px 80px" : "7px 43px"),
			font: "700 18px/25px 'Open Sans'"
		}
	}
)) as typeof Button;

const ButtonOutlineStyled = styled(Button)(({ theme, fullWidth = false, size }) => (
	{
		display: "inline-flex",
		gap: "16px",
		backgroundColor: alpha("#FFFFFF", 0.5),
		border: "2px solid #215C75",
		borderRadius: "40px",
		font: "700 24px/33px 'Open Sans'",
		flexGrow: (fullWidth ? 1 : 0),
		width: "fit-content",
		minWidth: "240px",
		minHeight: "56px",
		height: "auto",
		lineHeight: "100%",
		color: theme.palette.primary.main,
		padding: "10px 80px",
		textTransform: "none",
		flexShrink: 0,

		"&:hover":
		{
			backgroundColor: alpha("#E9EDF0", 0.5),
			color: theme.palette.primary.main
		},
		"&:pressed":
		{
			backgroundColor: "#FFFFFF",
			color: theme.palette.primary.main
		},
		"&:disabled":
		{
			opacity: 0.5,
			backgroundColor: alpha("#FFFFFF", 0.5),
			borderColor: theme.palette.primary.dark,
			color: theme.palette.primary.dark
		},
		"& > div > span": { width: "unset !important", height: "unset !important" },
		"& svg": { width: "25px", height: "25px" },
		"& svg *": { stroke: "#215C75" },
		"&:hover svg *": { stroke: theme.palette.primary.main },

		[ theme.breakpoints.down("xl") ]:
		{
			minWidth: "unset",
			minHeight: "40px",
			height: "40px",
			padding: (size === "large" ? "7px 80px" : "7px 43px"),
			font: "700 18px/25px 'Open Sans'"
		}
	}
)) as typeof Button;

const ButtonContainedStyled = styled(Button)(({ theme, fullWidth = false, size }) => (
	{
		display: "inline-flex",
		gap: "16px",
		backgroundColor: "#3E809D",
		backdropFilter: "blur(30px)",
		borderRadius: "40px",
		font: "700 24px/33px 'Open Sans'",
		flexGrow: (fullWidth ? 1 : 0),
		width: "fit-content",
		minWidth: "240px",
		minHeight: "56px",
		height: "auto",
		lineHeight: "100%",
		color: "#FFFFFF",
		padding: "10px 80px",
		textTransform: "none",
		flexShrink: 0,

		"&:hover":
		{
			backgroundColor: alpha("#3E809D", 0.5),
			borderColor: "transparent",
			color: "#FFFFFF"
		},
		"&:pressed":
		{
			backgroundColor: "#FFFFFF",
			color: "#215C75"
		},
		"&:disabled":
		{
			background: "linear-gradient(104deg, rgba(229, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
			boxShadow: "0px 5px 20px 0px " + alpha("#7EC4FF", 0.4),
			borderColor: "transparent",
			color: "#FFFFFF"
		},
		"& > div > span": { width: "unset !important", height: "unset !important" },
		"& svg": { width: "25px", height: "25px" },
		"& svg *": { stroke: "#FFFFFF" },
		"&:hover svg *": { stroke: "#FFFFF" },

		[ theme.breakpoints.down("xl") ]:
		{
			minWidth: "unset",
			minHeight: "40px",
			height: "40px",
			padding: (size === "large" ? "7px 80px" : "7px 43px"),
			font: "700 18px/25px 'Open Sans'"
		}
	}
)) as typeof Button;

interface IButtonProps extends Pick<ButtonProps, "onClick" | "sx">
{
	children: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	loadingPosition?: "end" | "start";
	endIcon?: ReactNode;
	startIcon?: ReactNode;
	[ key: string ]: any;
}

const ButtonBase = forwardRef((
	{
		children,
		disabled = false,
		loading = false,
		loadingPosition = "end",
		startIcon,
		endIcon,
		...props
	}: IButtonProps,
	ref: Ref<HTMLButtonElement>
) =>
{
	return (
		<ButtonBaseStyled {...props} ref={ref} disabled={loading || disabled}>
			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				left: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "start" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : startIcon}
			</Box>

			{children}

			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				right: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "end" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : endIcon}
			</Box>
		</ButtonBaseStyled>
	);
}) as ExoticComponent<IButtonProps>;

const ButtonOutline = forwardRef((
	{
		children,
		disabled = false,
		loading = false,
		loadingPosition = "end",
		startIcon,
		endIcon,
		...props
	}: IButtonProps,
	ref: Ref<HTMLButtonElement>
) =>
{
	return (
		<ButtonOutlineStyled {...props} ref={ref} disabled={loading || disabled}>
			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				left: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "start" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : startIcon}
			</Box>

			{children}

			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				right: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "end" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : endIcon}
			</Box>
		</ButtonOutlineStyled>
	);
}) as ExoticComponent<IButtonProps>;

const ButtonContained = forwardRef((
	{
		children,
		disabled = false,
		loading = false,
		loadingPosition = "end",
		startIcon,
		endIcon,
		...props
	}: IButtonProps,
	ref: Ref<HTMLButtonElement>
) =>
{
	return (
		<ButtonContainedStyled {...props} ref={ref} disabled={loading || disabled}>
			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				left: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "start" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : startIcon}
			</Box>

			{children}

			<Box sx={{
				position: "absolute",
				top: "50%",
				transform: "translateY(-50%)",
				right: "14.5px",
				display: "flex",
				alignItems: "center"
			}}>
				{loading && loadingPosition === "end" ? <CircularProgress sx={{ fontSize: "inherit" }} /> : endIcon}
			</Box>
		</ButtonContainedStyled>
	);
}) as ExoticComponent<IButtonProps>;

const CustomButton =
{
	Base: ButtonBase,
	Outline: ButtonOutline,
	Contained: ButtonContained
};

export default CustomButton;
