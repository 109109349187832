import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { DEFAULT_STORE_FORM, IStoreForm } from "../..";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

function ReflectParallelUniverses()
{
	const missionContext = useMission();

	const [ chatStep, setChatStep ] = useState<number>(1);
	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_message_1" defaultValue="Excellent job, {NAME}, these are some fun lives you’ve dreamt of!" position="right" isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>({
		type: "select",
		items: [
			<ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_answer_1" defaultValue="Haha, thanks, Octa!" position="top" />
		]
	});

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_message_2" defaultValue="Let’s spend a moment looking at all of them and see if they have anything in common, because that’s where the real insights will come from!" position="right" isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_answer_2" defaultValue="Let’s do it!" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_message_3" defaultValue="Do you see any common elements in all of your universes?" position="right" isDescription />
				);
				setAnswers({ type: "input" });
				break;
			}

			case 4: {

				missionContext.setStep((currentStep) => currentStep + 1);
				break;
			}
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ type, message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		if (type === "input")
		{
			const store = getLocalStorage<IStoreForm>("mission-three-form") || DEFAULT_STORE_FORM;

			store.ReflectParallelUniverses = message;

			setLocalStorage("mission-three-form", store);
		}

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box className="main__content">
			<Chat
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_11_chat_title" defaultValue="Reflect on your parallel universes" position="right" />}
				onResponseAnswer={onResponseAnswer}
				messages={messages}
				answers={answers}
			/>
		</Box>
	);
}

export default ReflectParallelUniverses;
