import { Box, Button, ButtonProps, SxProps, Theme } from "@mui/material";
import { MouseEvent, useLayoutEffect, useState } from "react";
import { ReactComponent as SVGRadial } from "../../assets/images/icons/radial.svg";

interface IRadialButtonProps extends Omit<ButtonProps, "color">
{
	value?: string;
	textTransformValue?: string;
	showValue?: boolean;
	showIcon?: boolean;
	absoluteValuePosition?: boolean;
	direction?: "right" | "left";
	blip?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	color?: "white" | "blue";
	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	className?: string;
	disabled?: boolean;
	sx?: SxProps<Theme>;
}

function RadialButton(
	{
		value,
		textTransformValue = "capitalize",
		showValue = true,
		showIcon = true,
		absoluteValuePosition = false,
		direction = "right",
		blip = false,
		blipDurationMs = 2000,
		offBlipOnClick = true,
		color = "white",
		onClick,
		disabled = false,
		sx = [],
		...props
	}: IRadialButtonProps
)
{
	const [ isBlip, setIsBlip ] = useState<boolean>(blip);

	useLayoutEffect(() => setIsBlip(blip), [ blip ]);

	return (
		<Button
			className="RadialButton-root"
			disableRipple
			disabled={disabled}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					setIsBlip(false);
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
			sx={[
				{
					position: "relative",
					padding: "0",
					minHeight: { xl: "56px", xs: "40px" },
					margin: (showValue === false ? "11.5px 0" : direction === "right" ? "11.5px 0 11.5px 40px" : "11.5px 40px 11.5px 0"),

					"&:hover":
					{
						backgroundColor: "unset"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Box
				className={"RadialButton-iconContainer " + (isBlip === true ? "animation--blip-radialButton" : "")}
				sx={{
					"--size": { xl: "80px", xs: "64px" },

					position: "absolute",
					top: "-12px",
					left: direction === "right" ? { xl: "-40px", xs: "-32px" } : "unset",
					right: (direction === "left" ? "-40px" : "unset"),
					// display: "flex",
					display: (showIcon === false ? "none" : "flex"),
					justifyContent: "center",
					alignItems: "center",
					width: "var(--size) !important",
					height: "var(--size) !important",
					border: "2px solid #FFFFFF",
					borderRadius: "50%",
					background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
					boxShadow: "3px 3px 10px rgba(28, 130, 170, .66)",
					backdropFilter: "blur(200px)",
					opacity: (showValue === false && disabled === true ? ".5" : "1"),
					zIndex: 2,
					"--duration": blipDurationMs + "ms"
				}}
			>
				<SVGRadial className="RadialButton-icon" />
			</Box>

			<Box
				className="RadialButton-value"
				sx={{
					position: (absoluteValuePosition === true ? "absolute" : "relative"),
					font: { xl: "700 24px/31px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
					// textTransform: "capitalize",
					textTransform: textTransformValue,
					color: !isBlip ? "rgba(62, 128, 157, 0.5)" : "#215C75",
					display: (showValue === true ? "flex" : "none"),
					justifyContent: "center",
					alignItems: "center",
					padding: (direction === "right" ? { xl: "0 55px 0 80px", xs: "0 40px" } : { xl: "0 80px 0 55px", xs: "0 40px" }),
					height: { xl: "56px", xs: "40px" },
					border: "1px solid rgba(255, 255, 255, .6)",
					background: "linear-gradient(to right, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .2))",
					borderRadius: "40px",
					backdropFilter: "blur(100px)",
					// textShadow: isBlip ? "none" : "1px 1px 5px rgba(0, 0, 0, .6)",
					width: "max-content",
					right: (absoluteValuePosition === true ? (direction === "left" ? 0 : "unset") : "unset"),
					left: (absoluteValuePosition === true ? (direction === "right" ? 0 : "unset") : "unset"),
					top: (absoluteValuePosition === true ? "50%" : "unset"),
					transform: (absoluteValuePosition === true ? "translateY(-50%)" : "unset")
				}}
			>
				{value}
			</Box>
		</Button>
	);
}

export default RadialButton;
