import { Box, Fade } from "@mui/material";
import Api from "api";
import IMGGuideOcta from "assets/images/guideChara-shrink.png";
import CustomInput from "components/CustomInput";
import CustomButton from "components/customButton";
import config from "config";
import useUser from "hooks/useUser";
import useRegistration from "pages/registration/hooks/useRegistration";
import { KeyboardEvent, useState } from "react";
import { UserActionType } from "reducers/userReducer";
import { setLocalStorage } from "utils/localStorage";
import trackEvent from "utils/mixpanelUtil";
import { ValidationError } from "yup";

function Password()
{
	const userContext = useUser();
	const { setStep, name, email } = useRegistration();
	const [ password, setPassword ] = useState<string>("");
	const [ error, setError ] = useState<null | string>(null);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	// Handles
	function submitPassword()
	{
		let error: string | null = null;

		try
		{
			config.password.yup().validateSync(password.trim());
		}
		catch (e)
		{
			error = (e as ValidationError).message;
		}

		if (error !== null)
		{
			return setError(error);
		}

		setIsFetch(true);

		Api.auth
			.register({ name, email, password, newsletterSubscribe: false })
			.then((user) =>
			{
				setLocalStorage("accessToken", user.accessToken);
				setLocalStorage("email", user.email);
				setLocalStorage("name", user.name);
				setLocalStorage("userId", user.id);
				setLocalStorage("onboarded", user.onboarded);
				setLocalStorage("completedMission", user.completedMission);

				trackEvent({
					eventName: "Registration Success",
					userId: user.id,
					properties: { "Auth method": "Email" }
				});

				userContext.dispatch({ type: UserActionType.LOGIN, payload: { accessToken: user.accessToken, user } });

				setTimeout(() => setStep((prevState) => prevState + 1), 0);
			})
			.catch((error) =>
			{
				console.error(error);
			})
			.finally(() => setIsFetch(false));
	}

	function onKeyDownHandler(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key === "Enter")
		{
			submitPassword();
		}
	}

	// Render
	return (
		<Box sx={{
			padding: { xl: "48px 24px 24px 24px", xs: "36px 32px 0px 32px" },
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			flexGrow: 1
		}}>
			<Box
				component="img"
				src={IMGGuideOcta}
				alt=""
				sx={{
					width: { xl: "211px", xs: "197px" }
				}}
			/>

			<Box sx={{
				marginTop: { xl: "40px", sm: "24px", xs: "30px" },
				padding: { sm: "unset", xs: "0 8px" },

				maxWidth: { xl: "844px", sm: "530px" },
				width: "100%",

				font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main",
				textAlign: "center"
			}}>
				Create your password
			</Box>

			<CustomInput.Password
				placeholder="Password"
				value={password}
				onChange={({ target }) => setPassword(target.value)}
				onKeyDown={onKeyDownHandler}
				sx={{
					marginTop: { xl: "40px", xs: "16px" },
					maxWidth: { xl: "478px", sm: "410px" },
					width: "100%",
					borderRadius: "8px !important",

					"& .MuiInputBase-input":
					{
						padding: "16px 13px !important"
					}
				}}
			/>

			<Fade in={error !== null}>
				<Box sx={{ padding: "0 8px", color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
					{error}
				</Box>
			</Fade>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "128px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Contained
					onClick={() => submitPassword()}
					disabled={isFetch === true}
					loader={isFetch === true}
					sx={{
						width: { sm: "unset", xs: "100%" }
					}}
				>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default Password;
