import CancelIcon from "@mui/icons-material/Cancel";
import { alpha, Box, Dialog, Slide, styled, SxProps, Theme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactNode, useLayoutEffect } from "react";

const StyledDialog = styled(Dialog)(
	{
		"& .MuiBackdrop-root": { backgroundColor: "transparent" },
		"& .MuiPaper-root":
		{
			// background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
			backgroundColor: alpha("#C0D3DF", 0.7),
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "20px",
			maxWidth: "980px",
			width: "100%",
			boxShadow: "0px 5px 10px 0px rgba(167, 199, 225, 0.6)",
			font: "400 22px/30px 'Open Sans'",
			color: "rgba(77, 77, 77, .7)",
			backdropFilter: "blur(25px)",
			display: "block"
		}
	}
);

const Transition = forwardRef((
	props: TransitionProps & { children: React.ReactElement<any, any>; },
	ref: React.Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

export interface IImageSideModalProps
{
	open: boolean;
	image: string;
	title: string | JSX.Element;
	subTitle: string | JSX.Element;
	children: ReactNode;
	size?: "normal" | "long";
	sx?: SxProps<Theme>;
	onOpen?(): void;
	onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton") => void) | null;
	noIcon?: boolean;
	[ prop: string ]: any;
}

export default function ImageSideModal({ title, subTitle, image, size = "normal", onOpen = undefined, onClose = null, open = false, children, noIcon = false, ...props }: IImageSideModalProps)
{
	useLayoutEffect(() =>
	{
		if (open === true && typeof onOpen === "function")
		{
			onOpen();
		}
	}, [ open, onOpen ]);

	// Render
	return (
		<StyledDialog open={open} TransitionComponent={Transition} onClose={onClose || undefined} {...props}>
			<CancelIcon
				onClick={() => typeof onClose === "function" && onClose({}, "closeButton")}
				sx={[
					{
						position: "absolute",
						top: "32px",
						right: "32px",
						fill: "#215C75",
						opacity: 1,
						"&:hover": { opacity: 0.3, cursor: "pointer" }
					},
					(typeof onClose !== "function" || noIcon === true) && { display: "none" }
				]}
			/>

			<Box sx={{
				display: "flex",
				height: (size === "normal" ? "564px" : "737px"),
				"& .ImageSideModal-image": {},
				"& .ImageSideModal-title":
				{
					font: "700 40px/51px Lora",
					color: "primary.main"
				},
				"& .ImageSideModal-subTitle":
				{
					font: "400 24px/33px 'Open Sans'",
					color: "primary.dark",
					margin: "16px 0 24px 0"
				},
				"& .ImageSideModal-content":
				{
					font: "400 24px/33px 'Open Sans'",
					color: "primary.dark",
					padding: "56px 32px 32px 71px",
					display: "grid",
					gridTemplateRows: "auto auto 1fr",
					alignContent: "flex-start"
				}
			}}>
				<Box component="img" src={image} alt="side image" className="ImageSideModal-image" />

				<Box className="ImageSideModal-content">
					<Box className="ImageSideModal-title">
						{title}
					</Box>

					<Box className="ImageSideModal-subTitle">
						{subTitle}
					</Box>

					<Box className="customScroll" sx={{ overflowY: "auto", paddingRight: "8px" }}>
						{children}
					</Box>
				</Box>
			</Box>
		</StyledDialog>
	);
}
