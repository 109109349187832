import { alpha, Box, SxProps, Theme } from "@mui/material";
import { forwardRef, Ref } from "react";

export interface IAnimalTooltipProps
{
	leftPos?: string;
	bgTooltip?: string;
	title: string | JSX.Element;
	/**
	 * @default false
	 */
	isShow: boolean;
	bgBlur: boolean;

	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function AnimalTooltip(
	{
		title,
		leftPos,
		bgTooltip,
		isShow = false,
		bgBlur = false,
		sx = [],
		...props
	}: IAnimalTooltipProps,
	ref: Ref<HTMLDivElement>
)
{
	return (
		<Box
			ref={ref}
			sx={[
				{
					position: "absolute",
					top: "-75px",
					left: leftPos ?? "-113px",
					opacity: 1,
					transition: "linear 300ms opacity"
				},
				...[ isShow === false && { opacity: 0 } ],
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<Box
				ref={ref}
				sx={{
					font: "400 14px/19px 'Open Sans'",
					color: "#4D4D4D",
					background: (bgTooltip ?? alpha("#FFFFFF", 0.5)),
					borderRadius: "16px",
					padding: "25px",
					width: "max-content",
					backdropFilter: (bgBlur ? "blur(25px)" : "none"),
					boxShadow: (bgBlur ? "0px 5px 10px rgba(167, 199, 225, 0.6)" : "none")
				}}
				{...props}
			>
				{title}
			</Box>

		</Box>
	);
}

export default forwardRef(AnimalTooltip);
