import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorage } from "utils/localStorage";
import ButtonStyled from "../../../components/buttonStyled";
import ContentAdmin from "../../../components/contentAdmin";

function GoodStatus({ name })
{
	const location = useLocation();

	return (
		<Box>
			<Box className="customScroll" sx={{ maxHeight: "773px", overflowY: "auto" }}>
				<Box sx={{ display: { xs: "none", sm: "block" } }}>
					<Typography className="h1-status" pt={5} sx={{ fontSize: "42px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
						<ContentAdmin position="left" defaultValue="You are on the horse, keep it up!" keyContent="contentMissionOne" keyText="goodStatusTitle" />
					</Typography>
					<Typography mt={2} sx={{ fontSize: "18px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
						{/* {`Congratulations, ${ name ? name : getLocalStorage('name') }! Your wheel is nice and round, with all areas of your life in balance.`}<br></br><br></br>

					Most people struggle to maintain such balance, which is completely normal. While you are in a great place, think about what helped you to achieve that..<br></br><br></br>

					You might draw some useful insights for the future you, in case something goes out of balance. */}
						<ContentAdmin isDescription position="left" defaultValue="Congratulations, {NAME}! Your wheel is nice and round, with all areas of your life in balance.\\n\\n Most people struggle to maintain such balance, which is completely normal. While you are in a great place, think about what helped you to achieve that.\\n\\nYou might draw some useful insights for the future you, in case something goes out of balance." keyContent="contentMissionOne" keyText="goodStatusDesc" />
					</Typography>
					<Typography mt={3} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
						{/* Recommendation */}
						<ContentAdmin position="left" defaultValue="Recommendation" keyContent="contentMissionOne" keyText="recommendation" />
					</Typography>
					<Typography mt={1} sx={{ fontSize: "18px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
						{/*
						While you are in a good place right now, we recommend checking in with yourself regularly to see if anything needs re-calibrating. You can come back to this tool and do the test again, or simply do a mental check-in.<br></br><br></br>

						{name ? name : getLocalStorage('name')}, you strike me as a person who likes to grow and improve, and probably wondering, how can you make your scores even higher? The best way to do it is by mindfully and intentionally designing your life.<br></br><br></br>

						Don’t worry, we have designed a powerful tool to help you do that — Life Strategy Quest. It is an interactive online quest to help you understand yourself even better and gain clarity of thought in a simple and structured way.
						*/}
						<ContentAdmin isDescription position="left" defaultValue="While you are in a good place right now, we recommend checking in with yourself regularly to see if anything needs re-calibrating. You can come back to this tool and do the test again, or simply do a mental check-in.\\n\\n{NAME}, you strike me as a person who likes to grow and improve, and probably wondering, how can you make your scores even higher? The best way to do it is by mindfully and intentionally designing your life\\n\\nDon’t worry, we have designed a powerful tool to help you do that — Life Strategy Quest. It is an interactive online quest to help you understand yourself even better and gain clarity of thought in a simple and structured way." keyContent="contentMissionOne" keyText="goodStatusRecommendation" />
					</Typography>
					<Typography mt={3} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
						<ContentAdmin position="left" defaultValue="Your next step" keyContent="contentMissionOne" keyText="your_next_step" />
					</Typography>
					<Typography mt={1} sx={{ fontSize: "18px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
						<ContentAdmin isDescription position="left" defaultValue="{NAME}, you took the assessment, which was the first step of your journey. In the next step, you will define your personal values, which are the foundation for designing life on your terms." keyContent="contentMissionOne" keyText="goodStatusNextStep" />
					</Typography>
				</Box>
			</Box>
			{location.pathname.split("/")[ 1 ] !== "artifact" &&
				(
					<Box sx={{ width: "268px", margin: "40px auto 0" }}>
						{/* <Link className="textecotationLinks" to="/welcome"><ButtonStyled fullWidth={true} title="Let’s do it!" /></Link> */}
					</Box>
				)
			}
			<Box sx={{ display: { xs: "block", sm: "none" } }}>
				<Typography pt={5} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
					{/* You are on the horse, keep it up! */}
					<ContentAdmin position="left" defaultValue="You are on the horse, keep it up!" keyContent="contentMissionOne" keyText="goodStatusTitle" />
				</Typography>
				<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
					Congratulations, { name ?? getLocalStorage("name") }! Your wheel is nice and round, with all areas of your life in balance.<br /><br />

					Most people struggle to maintain such balance, which is completely normal. While you are in a great place, think about what
					helped you to achieve that..<br /><br />

					You might draw some useful insights for the future you, in case something goes out of balance.
				</Typography>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							Recommendation
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							While you are in a good place right now, we recommend checking in with yourself regularly to see if anything
							needs re-calibrating. You can come back to this tool and do the test again, or simply do a mental check-in.<br /><br />

							{name ?? getLocalStorage("name")}, you strike me as a person who likes to grow and improve, and probably wondering,
							how can you make your scores even higher? The best way to do it is by mindfully
							and intentionally designing your life.<br /><br />

							Don’t worry, we have designed a powerful tool to help you do that — Life Strategy Quest.
							It is an interactive online quest to help you understand yourself even better and gain clarity
							of thought in a simple and structured way.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							Your next step
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							{name ? name : getLocalStorage("name")}, you took the assessment, which was the first step of your journey. In the next step, you will define your personal values, which are the foundation for designing life on your terms.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Box sx={{ width: "268px", margin: "40px auto 0", pb: "30px" }}>
					<Link className="textecotationLinks" to="/welcome"><ButtonStyled fullWidth title="Let’s do it!" /></Link>
				</Box>
			</Box>
		</Box>

	);
}

export default GoodStatus;
