import { AlertProps, Box, CircularProgress, Snackbar } from "@mui/material";
import Api from "api";
import afternoonBg from "assets/images/afternoonBg.png";
import eveningBg from "assets/images/eveningBg.png";
import morningBg from "assets/images/morningBg.png";
import nightBg from "assets/images/nightBg.png";
import AlertStyled from "components/AlertStyled";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArtifactsContainer from "../../components/ArtifactsContainer";
import CardContent from "./CardContent";

function Create()
{
	const navigate = useNavigate();
	const [ valuesInput, setValuesInput ] = useState({
		morning: "",
		afternoon: "",
		evening: "",
		night: ""
	});
	const [ isLoading, setIsLoading ] = useState(false);
	const [ textSnackbar, setTextSnackbar ] = useState<[ severity: AlertProps[ "severity" ], message: string ] | null>(null);
	const [ open, setOpen ] = useState<boolean>(false);

	function handleClose(_event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	function handleChangeValueCreateMode(value: string, key: string)
	{
		setValuesInput({
			...valuesInput,
			[ key ]: value
		});
	}

	function saveMission()
	{
		if (valuesInput.morning && valuesInput.afternoon && valuesInput.evening && valuesInput.night)
		{
			setIsLoading(true);
			const data = valuesInput;

			Api.missions
				.setDataMissionFive(data, true)
				.then((res: any) =>
				{
					console.info({ res });
					setTextSnackbar([ "success", "Success" ]);
					navigate("/dashboard/artifacts/perfect-day");
					setOpen(true);
					setIsLoading(false);
				})
				.catch((error: any) =>
				{
					console.error(error);
					setIsLoading(false);
				});
		}
		else
		{
			setTextSnackbar([ "warning", "Fill in all the fields" ]);
			setOpen(true);
		}
	}

	// Render
	return (
		<ArtifactsContainer
			title="My New Perfect day"
			headerRight={
				<CustomButton.Outline
					onClick={saveMission}
					disabled={isLoading}
					sx={{ mr: { xs: "10px", xl: "60px" } }}
				>
					{isLoading ? <CircularProgress /> : "Save"}
				</CustomButton.Outline>
			}
			onClose={() => navigate("/dashboard/artifacts/perfect-day")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				}
			}}
		>
			<Box sx={{ display: "flex", gap: { lg: "21px", xl: 4 }, flexWrap: "wrap", justifyContent: "center" }}>
				<CardContent
					title="morning"
					bgImage={morningBg}
					createModeValue={valuesInput.morning}
					createMode
					handleChangeValueCreateMode={handleChangeValueCreateMode}
					sx={{
						width: { lg: "520px", xl: "752px" },
						height: { lg: "230px", xl: "333px" },
						"& .contentStyle": {
							font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
						}
					}}
				/>
				<CardContent
					title="afternoon"
					bgImage={afternoonBg}
					createModeValue={valuesInput.afternoon}
					createMode
					handleChangeValueCreateMode={handleChangeValueCreateMode}
					sx={{
						width: { lg: "520px", xl: "752px" },
						height: { lg: "230px", xl: "333px" },
						"& .contentStyle": {
							font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
						}
					}}
				/>
				<CardContent
					title="evening"
					bgImage={eveningBg}
					createModeValue={valuesInput.evening}
					createMode
					handleChangeValueCreateMode={handleChangeValueCreateMode}
					sx={{
						width: { lg: "520px", xl: "752px" },
						height: { lg: "230px", xl: "333px" },
						"& .contentStyle": {
							font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
						}
					}}
				/>
				<CardContent
					title="night"
					bgImage={nightBg}
					createModeValue={valuesInput.night}
					createMode
					handleChangeValueCreateMode={handleChangeValueCreateMode}
					sx={{
						color: "primary.light",
						width: { lg: "520px", xl: "752px" },
						height: { lg: "230px", xl: "333px" },
						"& .contentStyle": {
							font: { lg: "400 14px/22px Open Sans", xl: "400 22px/30px Open Sans" }
						}
					}}
				/>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity={textSnackbar?.[ 0 ]}>
					{textSnackbar?.[ 1 ]}
				</AlertStyled>
			</Snackbar>
		</ArtifactsContainer>
	);
}

export default Create;
