import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import afternoonMini from "assets/images/afternoonMini.png";
import bgBtnMissionFive from "assets/images/bgBtnMissionFive.png";
import evningMini from "assets/images/evningMini.png";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import morningMini from "assets/images/morningMini.png";
import nightMini from "assets/images/nightMini.png";
import CustomButton from "components/customButton";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import CardContent from "./components/CardContent";
import Create from "./components/Create";
import Details from "./components/Details";

function PerfectDay()
{
	const navigate = useNavigate();
	const params = useParams();
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ dataMissions, setDataMissions ] = useState<any>([]);

	function formatDate(date: string)
	{
		return date.replaceAll("/", ".");
	}

	useLayoutEffect(() =>
	{
		Api.missions
			.getDataMissionFive(true)
			.then(({ data }) =>
			{
				setDataMissions(data.filter((item: any) => typeof (item.mission_data.afternoon) === "string"));
			})
			.catch((error) =>
			{
				console.error(error);
			});
	}, [ navigate ]);
	switch (params.action)
	{
		case "create": return (<Create />);
		case "details": return (<Details items={dataMissions} />);
	}

	return (
		<ArtifactsContainer
			title="My Perfect day"
			headerRight={
				dataMissions?.[ selectedItemIndex ] ? formatDate(dataMissions[ selectedItemIndex ].created_at) : null
			}
			aside={
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "0px 4px 42px " + alpha("#68AEC5", 0.4)
							}
						}}
					>
						{dataMissions.length === 0
							? (
								<Box>Loading...</Box>
							)
							: (
								dataMissions.map((item: any, index: number) =>
								{
									return (
										<AsideCircleButton
											key={index}
											image={bgBtnMissionFive}
											text={formatDate(item.created_at)}
											isSelected={selectedItemIndex === index}
											onClick={() => setSelectedItemIndex(index)}
											sx={{
												"& .AsideCircleButton-image": {
													borderRadius: 0,
													boxShadow: "none"
												}
											}}
										/>
									);
								})
							)
						}
					</Box>
					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: "355px"
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/perfect-day/create");
						}}
					>
						Create new day
					</CustomButton.Contained>
				</Box>}
			onExpand={() => navigate("/dashboard/artifacts/perfect-day/details/" + dataMissions[ selectedItemIndex ].id)}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "#215C75"
				},
				".ArtifactsContainer-main": {
					gap: { lg: 2, xl: 4 }
				}
			}}
		>
			<Box sx={{ height: "100%", position: "relative", p: { lg: 0, xl: "0px 0 0 40px" }, maxWidth: "1008px" }}>
				{selectedItemIndex > 0 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState - 1)}
								sx={{
									"--size": { xl: "40px", lg: "32px", xs: "40px" },
									width: "var(--size)",
									height: "var(--size)",
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									left: { xl: "20px", lg: "-33px", xs: "20px" },
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.main",
									cursor: "pointer",

									"& svg":
									{
										width: "var(--size)",
										height: "var(--size)"
									},
									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
							</Box>
						</Fade>
					)
				}

				{selectedItemIndex < dataMissions.length - 1 &&
					(
						<Fade in>
							<Box
								component="button"
								onClick={() => setSelectedItemIndex((prevState) => prevState + 1)}
								sx={{
									"--size": { xl: "40px", lg: "32px", xs: "40px" },
									width: "var(--size)",
									height: "var(--size)",
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									right: { xl: "20px", lg: "-30px", xs: "20px" },
									padding: "unset",
									background: "unset",
									border: "unset",
									color: "primary.main",
									cursor: "pointer",

									"& svg":
									{
										width: "var(--size)",
										height: "var(--size)"
									},
									"&:hover":
									{
										opacity: "0.7 !important"
									}
								}}
							>
								<SVGChevronArrow />
							</Box>
						</Fade>
					)
				}
				{dataMissions.length > 0 && dataMissions.map((item: any, index: number) => (
					index === selectedItemIndex &&
						<Box key={index} sx={{ display: "flex", gap: { lg: 2, xl: 4 }, flexWrap: "wrap" }}>
							<CardContent title="Morning" content={item.mission_data.morning} bgImage={morningMini} />
							<CardContent title="Afternoon" content={item.mission_data.afternoon} bgImage={afternoonMini} />
							<CardContent title="Evening" content={item.mission_data.evening} bgImage={evningMini} />
							<CardContent title="Night" content={item.mission_data.night} bgImage={nightMini} sx={{ color: "primary.light" }} />
						</Box>
				))}
			</Box>
		</ArtifactsContainer>
	);
}

export default PerfectDay;
