import { AlertColor, Box, Snackbar, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import Api from "api";
import type { ResGetCoupons } from "api/Coupons";
import AlertStyled from "components/AlertStyled";
import { TransitionSlideLeft } from "components/Transition";
import CreateCoupons from "components/createCoupon";
import { ReactNode, useEffect, useLayoutEffect, useState, type ChangeEvent, type MouseEvent } from "react";
import { useSearchParams } from "react-router-dom";
import getSearchParamsValue from "utils/getSearchParamsValue";
import DeleteCoupon from "./DeleteCoupon";
import { TablePaginationActions } from "./Emails";

const COLS: string[] = [
	"Name",
	"Code",
	"Discount",
	"Validity",
	// "Status",
	"Actions"
];

function Coupons()
{
	const [ searchParams, setSearchParams ] = useSearchParams();
	const [ coupons, setCoupons ] = useState<ResGetCoupons[]>([]);
	const [ isFetching, setIsFetch ] = useState<boolean>(false);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);
	const [ rowsPerPage, setRowsPerPage ] = useState<number>(getSearchParamsValue(searchParams, "rowsPerPage", "number") ?? 10);
	const [ page, setPage ] = useState<number>(() =>
	{
		const value = getSearchParamsValue(searchParams, "page", "number");

		return value === null ? 0 : value - 1;
	});

	useEffect(() =>
	{
		Api.coupons
			.getCoupons()
			.then((data) =>
			{
				setCoupons(data);
			})
			.catch((err) =>
			{
				console.error(err);
			})
			.finally(() => setIsFetch(false));
	}, []);

	useLayoutEffect(() =>
	{
		const updatedParams = new URLSearchParams(searchParams);

		updatedParams.set("page", String(page + 1));
		updatedParams.set("rowsPerPage", String(rowsPerPage));

		setSearchParams(updatedParams);
	}, [ page, rowsPerPage, searchParams, setSearchParams ]);

	function handleChangePage(_event: MouseEvent<HTMLButtonElement> | null, newPage: number)
	{
		setPage(newPage);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	// Render
	return (
		<Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
			<CreateCoupons coupons={coupons} setCoupons={setCoupons} />
			<Table>
				<TableHead>
					<TableRow>
						{COLS.map((col) =>
						{
							return (
								<TableCell
									key={col}
									sx={{
										fontWeight: "bold",
										verticalAlign: "top",
										whiteSpace: "nowrap"
									}}
								>
									{col}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{isFetching === true &&
						(
							<TableRow>
								<TableCell colSpan={COLS.length} sx={{ fontSize: "18px", textAlign: "center" }}>
									Loading...
								</TableCell>
							</TableRow>
						)
					}
					{(rowsPerPage > 0
						? coupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: coupons
					).map((coupon) => (
						<TableRow key={coupon.id}>
							<TableCell>{coupon.name}</TableCell>
							<TableCell>
								{coupon.id}
							</TableCell>
							<TableCell>{coupon.percentOff}</TableCell>
							<TableCell>{coupon.redeemBy}</TableCell>
							{/* <TableCell>{coupon.status ? "Active" : "Canceled"}</TableCell> */}
							<TableCell>
								<DeleteCoupon id={coupon.id} setCoupons={setCoupons} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>

				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[ 10, 15, 25, 50, { label: "All", value: -1 } ]}
							colSpan={COLS.length}
							count={coupons.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{ inputProps: { "aria-label": "rows per page" }, variant: "standard" }}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={3000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
		</Box>
	);
}

export default Coupons;
