import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Career()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-career-reflection"
					defaultValue="- Does your job reflect your values?\n- Do you have a work life balance?\n- What legacy do you want to leave behind?\n- What career could support your desired lifestyle?\n- What is stopping you from making a career shift?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-career-actionPlan"
					defaultValue="1. **Start with the small tweaks.** Take on a new exciting project, step up at work or create healthy boundaries for a better work life balance.\n1. **Review your lifestyle.** Reflect on what’s important to you and think what career could support your desired lifestyle.\n1. **Consider a career change.** Take a step back to explore options within and outside your career field."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-career-howCanHelp"
					defaultValue="My Life Quest will help you explore the career paths that are right for you and identify the specific action steps that you can take."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/8XLStXvwV3w?si=fCOtgCbjnGTrbgBH"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Career;
