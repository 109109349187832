import { Box } from "@mui/material";
import CelebrationScreen from "components/missions/CelebrationScreen";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

function MustHaveFinish()
{
	const missionContext = useMission();

	// Handles
	function onClickNextStep()
	{
		missionContext.setStep((currentStep) => currentStep + 1);
	}

	// Render
	return (
		<Box className="main__content" sx={{ alignItems: "center" }}>
			<CelebrationScreen
				content={<ContentAdmin keyContent="contentMissionThree" keyText="screen_28_text" defaultValue="Your second name is clarity, {NAME}!" position="left" isDescription />}
				buttonText="What’s next"
				onClickButton={() => onClickNextStep()}
				sx={{
					"& .CelebrationScreen-content":
					{
						color: "primary.main",
						textShadow: "unset"
					}
				}}
			/>
		</Box>
	);
}

export default MustHaveFinish;
