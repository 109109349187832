import { createContext, Dispatch, SetStateAction } from "react";
import { BackgroundType } from ".";

export interface IMissionContextFiveMission
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof BackgroundType;
	setBackground: any;

	name: string;
	setName: Dispatch<SetStateAction<string>>;
	blurBg: boolean;
	setBlurBg: Dispatch<SetStateAction<boolean>>;
	isDuration: number;
	setIsDuration: Dispatch<SetStateAction<number>>;
}

export const MissionCtx = createContext<IMissionContextFiveMission>({} as IMissionContextFiveMission);
