import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, alpha } from "@mui/material";
import Api from "api";
import CustomButton from "components/customButton";
import { MouseEvent, useRef, useState } from "react";

export interface IDeleteAccountModalProps
{
	userId: string;
	/**
	 * @default false
	 */
	open: boolean;
	onClose(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton" | "reject" | "resolve"): void;
}

function DeleteAccountModal({ userId, open = false, onClose, ...props }: IDeleteAccountModalProps & DialogProps)
{
	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	// Handles
	function onDeleteUser()
	{
		if (isFetchRef.current === true || isFetch === true)
		{
			return;
		}

		isFetchRef.current = true;
		setIsFetch(true);

		Api.users
			.deleteUserDataBase(userId)
			.then(() =>
			{
				onClose({}, "resolve");
			})
			.catch((error) =>
			{
				console.error(error);
				onClose({}, "reject");
			})
			.finally(() =>
			{
				isFetchRef.current = false;
				setIsFetch(false);
			});
	}

	// Render
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper":
				{
					maxWidth: "unset",
					width: "980px",
					height: "440px",
					padding: "48px 64px 64px 64px",
					backgroundColor: "#FFFFFF",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				},
				"& .MuiBackdrop-root":
				{
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
					backdropFilter: "blur(25px)"
				}
			}}
			{...props}
		>
			{onClose !== undefined &&
				<CloseIcon
					onClick={() => onClose({}, "closeButton")}
					sx={{
						position: "absolute",
						top: "32px",
						right: "32px",
						cursor: "pointer",
						color: "primary.main"
					}}
				/>
			}

			<Box sx={{ font: "700 40px/51px Lora-Bold", color: "primary.main", marginRight: "300px" }}>
				Delete a user account
			</Box>

			<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "primary.dark", marginTop: "24px" }}>
				You are about to delete your account. Note that this is a non-reversible process.
			</Box>

			<Box sx={{ marginTop: "auto", display: "flex", justifyContent: "space-between" }}>
				<CustomButton.Contained
					loading={isFetch === true}
					onClick={() => onDeleteUser()}
				>
					Delete account
				</CustomButton.Contained>

				<CustomButton.Outline
					disabled={isFetch === true}
					onClick={(event: MouseEvent<HTMLButtonElement>) => onClose && onClose(event, "reject")}
				>
					I've changed my mind
				</CustomButton.Outline>
			</Box>
		</Dialog>
	);
}

export default DeleteAccountModal;
