import { Box, BoxProps, Skeleton, alpha } from "@mui/material";
import Api from "api";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useRef, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import randomInt from "utils/randomInt";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import Panel from "../../../../components/missions/Panel";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import useMission from "../../hooks/useMission";

export interface ITopJobsForYouListProps extends BoxProps { }

export const EMPTY_LIST: false[] = new Array(30).fill(false);
export const MIN_JOBS_COUNT: number = 20;

function TopJobsForYouList({ ...props }: ITopJobsForYouListProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const isFetch = useRef(false);

	const [ jobsList, setJobsList ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsList");

		return store ?? [];
	});

	// Effects
	useLayoutEffect(() =>
	{
		if (isFetch.current === true || jobsList.length !== 0)
		{
			return;
		}

		const store = getLocalStorage<string>(missionContext.missionPrefix + "-PersonalJobAd-text");

		if (store === null)
		{
			missionContext.setStep(12);

			return;
		}

		isFetch.current = true;

		Api.bot
			.getResponseBot(
				`Recommend ${ MIN_JOBS_COUNT } most suitable jobs for a person with the following description: ${ store }, and output these works point by point in the array`,
				"returnProfessions"
			)
			.then(({ professions }) =>
			{
				localStorage.removeItem(missionContext.missionPrefix + "+selectedJobs");
				setJobsList(professions);
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetch.current = false));

	}, []); // eslint-disable-line

	useLayoutEffect(() =>
	{
		setLocalStorage(missionContext.missionPrefix + "-jobsList", jobsList);
	}, [ jobsList, missionContext.missionPrefix ]);

	// Handles
	function nextStep()
	{
		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_title" defaultValue="Here are top {COUNT} jobs for you" position="left" replacer={{ "{COUNT}": String(MIN_JOBS_COUNT) }} />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_subTitle" defaultValue="Oh my, {NAME}, look at all those cool jobs that you could do!" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Panel sx={{
					padding: { xl: "32px", lg: "24px", xs: "24px" },
					maxHeight: { xl: "613px", lg: "463px", xs: "463px" },
					display: "grid",
					gridTemplateRows: "auto 1fr",
					gap: "18px"
				}}>
					<Box sx={{
						color: "primary.main",
						font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 18px/25px 'Open Sans'" }
					}}>
						<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_panelTitle" defaultValue="Here are the jobs that might be suitable for you" position="left" />
					</Box>

					{/* <Box sx={{ color: "primary.dark", font: "400 16px/22px 'Open Sans'", marginBottom: "30px", paddingRight: "26px" }}>
						<ContentAdmin keyContent={missionContext.keyContent} keyText="TopJobsForYou_panelDesc" defaultValue="These job roles vary across industries such as marketing, HR, social work, science, management, and travel. They offer flexibility, challenges, and opportunities to learn new skills while making a positive impact on people and the environment. Additionally, they might provide the work-life balance that Mimi desires while allowing her to work with a team that can challenge and support her." position="left" isDescription />
					</Box> */}

					<Box
						className="customScroll"
						component="ul"
						sx={{
							listStyle: "none",
							height: "100%",
							margin: "unset",
							padding: "unset",
							paddingRight: "16px",
							overflowY: "auto",
							display: "grid",
							gridTemplateColumns: { xl: "1fr 1fr 1fr", lg: "1fr 1fr", xs: "1fr 1fr" },
							gap: "26px 50px",
							justifyContent: "space-between",
							alignItems: "center",
							color: "primary.dark",
							font: "400 16px/22px 'Open Sans'",

							"& .MuiSkeleton-wave::after":
							{
								background: `linear-gradient( 90deg, transparent, ${ alpha("#3E809D", 0.4) }, transparent )`
							}
						}}
					>
						{isFetch.current === true &&
							EMPTY_LIST.map((_value, index) =>
							{
								return (
									<li key={index}>
										<Skeleton
											animation="wave"
											sx={{
												backgroundColor: alpha("#3E809D", 0.3),
												height: "1em",
												width: randomInt(30, 85) + "%"
											}}
										/>
									</li>
								);
							})
						}
						{isFetch.current === false && jobsList.map((jobName) =>
						{
							return (
								<li key={jobName}>
									{jobName}
								</li>
							);
						})}
					</Box>
				</Panel>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={isFetch.current === true || jobsList.length < 3}
					// disabled={isFetch.current === true || jobsList.length < MIN_JOBS_COUNT}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(TopJobsForYouList) as ForwardRefExoticComponent<ITopJobsForYouListProps>;
