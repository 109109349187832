import SendIcon from "@mui/icons-material/Send";
import { Zoom, alpha } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import TooltipStyled from "components/TooltipStyled";
import { ChangeEvent, FormEvent, MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import CustomInput from "../CustomInput";
import GuideChara from "../GuideChara";
import Panel from "../missions/Panel";
import Answer, { AnswersType } from "./Answer";
import Message, { IMessage } from "./Message";

export interface IResponseAnswer
{
	type: "input" | "select";
	message: string;
}

interface IChatProps
{
	messages: IMessage[];
	answers: AnswersType | null;
	onResponseAnswer({ type, message }: IResponseAnswer): void;
	title?: string | JSX.Element;
	titleNoDisplay?: boolean;
	minHeight?: string;
	titleColor?: string;
	titleMarginTop?: string;
	titleMarginLeft?: string;
	isAlignItems?: boolean;
	sx?: SxProps<Theme>;
	[ key: string ]: any;

}
function Chat(
	{
		messages,
		answers,
		onResponseAnswer,
		title,
		titleNoDisplay,
		minHeight,
		titleColor,
		titleMarginTop,
		titleMarginLeft,
		isAlignItems,
		sx = []
	}: IChatProps
)
{
	// States
	const messagesRef = useRef<HTMLDivElement | null>(null);
	const [ inputAnswer, setInputAnswer ] = useState<string>("");
	const [ visibilityAnswers, setVisibilityAnswers ] = useState<boolean>(false);

	// Hooks
	useEffect(() =>
	{
		if (messagesRef.current === null)
		{
			return;
		}

		messagesRef.current.scrollBy(
			{
				top: messagesRef.current.scrollHeight,
				behavior: "smooth"
			}
		);
	}, [ messagesRef, messages ]);

	useLayoutEffect(() =>
	{
		if (messages.length > 1)
		{
			setVisibilityAnswers(false);
		}
	}, [ answers, messages.length ]);

	// Handles
	function onClickAnswer({ currentTarget }: MouseEvent<HTMLButtonElement>)
	{
		onResponseAnswer({ type: "select", message: currentTarget.innerText });
	}

	function onSubmitForm(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		if (inputAnswer.trim().length < 2)
		{
			return;
		}

		onResponseAnswer({ type: "input", message: inputAnswer });
		setInputAnswer("");
	}

	const onSent = useCallback(({ onMessage }: IMessage) =>
	{
		window.requestAnimationFrame(() =>
		{
			if (messagesRef.current === null)
			{
				return;
			}

			messagesRef.current.scrollBy(
				{
					top: messagesRef.current.scrollHeight,
					behavior: "smooth"
				}
			);
		});

		(typeof onMessage === "function" && onMessage());

		setVisibilityAnswers(true);
	}, []);

	// Render
	return (
		<Box sx={[
			{
				marginLeft: "auto",
				marginRight: "auto",
				width: "100%",
				maxWidth: { xl: "980px", lg: "784px", xs: "980px" },
				flexGrow: 1,
				display: "flex",
				flexDirection: "column",
				gap: { xl: "24px", lg: "8px", xs: "24px" }
			},
			...Array.isArray(sx) ? sx : [ sx ]
		]}>
			<Box sx={{
				display: titleNoDisplay ? "none" : "flex",
				alignItems: isAlignItems ? "unset" : "center",
				gap: { xl: "32px", lg: "16px", xs: "32px" },
				zIndex: "1"
			}}>
				<GuideChara />

				{title !== undefined &&
					(
						<Box sx={{
							font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 40px/51px Lora-Bold" },
							color: titleColor ?? "#215C75",
							marginTop: titleMarginTop ?? "unset",
							marginLeft: titleMarginLeft ?? "unset"
						}}>
							{title}
						</Box>
					)
				}
			</Box>

			<Panel
				className="component-chat"
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					justifyContent: "space-between",
					padding: "20px 20px 15px 20px",
					height: { xl: "680px", lg: "510px", xs: "680px" },
					zIndex: "0",
					minHeight: minHeight ?? ""
				}}
			>
				<Box
					className="component-chat-messages"
					ref={messagesRef}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						overflowY: "auto",
						flexGrow: 1
					}}
				>
					{messages.map((message, index) => <Message key={index} messageIndex={index} message={message} onSent={onSent} />)}
				</Box>

				<Box
					className="component-chat-answers"
					sx={{
						backgroundColor: alpha("#FFFFFF", 0.5),
						padding: (
							answers?.type === "input"
								? "unset"
								: { xl: "12px", lg: "7px", xs: "12px" }
						),
						borderRadius: { xl: "24px", lg: "16px", xs: "20px" },
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						justifySelf: "flex-end",
						gap: "13px 31px",
						minHeight: { xl: "80px", lg: "54px", xs: "80px" }
					}}
				>
					{answers !== null && visibilityAnswers === true &&
						(answers.type === "input"
							? (
								<Box component="form" onSubmit={onSubmitForm} sx={{ width: "100%", position: "relative", display: "flex" }}>
									<CustomInput.Base
										onChange={({ target }: ChangeEvent<HTMLInputElement>) => setInputAnswer(target.value)}
										autoFocus
										fullWidth
										placeholder="Text"
										sx={{
											flexGrow: 1,
											borderRadius: { xl: "24px", lg: "16px", xs: "20px" },

											"& .MuiInputBase-input":
											{
												font: "400 22px/30px 'Open Sans'",
												color: "primary.dark",
												padding: { xl: "0 68px 0 32px", lg: "0 68px 0 16px", xs: "0 68px 0 16px" },
												minHeight: "unset",
												height: "100%",

												"&:placeholder":
												{
													opacity: "0.5"
												}
											}
										}}
									/>

									<TooltipStyled title={inputAnswer.trim().length < 2 ? "Minimum 2 characters" : "Send"} placement="top" TransitionComponent={Zoom} arrow>
										<Box
											component="button"
											type="submit"
											sx={{
												width: "36px",
												height: "36px",
												padding: "unset",
												border: "unset",
												background: "unset",
												position: "absolute",
												right: "16px",
												top: "50%",
												transform: "translateY(-50%)",
												cursor: (inputAnswer.trim().length < 2 ? "not-allowed" : "pointer"),
												opacity: (inputAnswer.trim().length < 2 ? 0.5 : 1)
											}}
										>
											<SendIcon color="primary" sx={{ fontSize: "36px" }} />
										</Box>
									</TooltipStyled>
								</Box>
							)
							: (
								answers.items.map((answer, index) => (
									<Answer key={index} text={answer} onClick={onClickAnswer} />
								))
							)
						)
					}
				</Box>
			</Panel>
		</Box>
	);
}

export default Chat;
