import mixpanel, { Callback } from "mixpanel-browser";

if (process.env.REACT_APP_ENVIRONMENT === "production")
{
	console.log("trackEvent prod 1");
	mixpanel.init(
		process.env.REACT_APP_MIXPANEL_TOKEN ?? "e0e88ad21a53ed69ffc0a5a346ccd14a",
		{ debug: true, track_pageview: true, ignore_dnt: true }
	);
}

interface TrackEventProps
{
	eventName: string;
	userId?: string;
	properties?: Record<string, any>;
}

function trackEvent({ userId, eventName, properties = {} }: TrackEventProps, callback?: Callback): void
{
	if (process.env.REACT_APP_ENVIRONMENT !== "production")
	{
		callback?.(1);

		return;
	}

	console.log("trackEvent prod 2");

	if (userId)
	{
		mixpanel.identify(userId);
	}

	mixpanel.track(eventName, properties, callback);
}

export default trackEvent;
