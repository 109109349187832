import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

function LetsCreateClarityForYou()
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_message_1" defaultValue="Hey, {NAME}! So good to see you again.In your previous mission, you’ve defined your values. I hope they already made things clearer!" isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [

				<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_answer_1" defaultValue="Not really" position="top" />,
				<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_answer_2" defaultValue="They surely did!" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_message_2" defaultValue="Well, today’s mission is all about creating clarity. Let me guess, there is so much you want to do and achieve in life, it can be overwhelming, am I right?" isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_answer_3" defaultValue="Not really" position="top" />,
						<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_answer_4" defaultValue="Spot on!" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_message_3" defaultValue="You might know exactly what you want, have some idea, or no idea at all. All of that is ok, because this mission will help you figure out what you want in life, create structure and feel so much lighter as the result." isDescription />

				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_answer_5" defaultValue="I want clarity, let’s do it!" position="top" />
					]
				});
				break;
			}

			case 4: {

				missionContext.setStep((currentStep) => currentStep + 1);
				break;
			}
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box className="main__content" sx={{ marginTop: "-16px" }}>
			<Chat
				title={<ContentAdmin keyContent="contentMissionThree" keyText="screen_1_chat_title" defaultValue="11Let’s create clarity for you!" />}
				onResponseAnswer={onResponseAnswer}
				messages={messages}
				answers={answers}
			/>
		</Box>
	);
}

export default LetsCreateClarityForYou;
