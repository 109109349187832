import { Box, SxProps, Theme, alpha } from "@mui/material";
import bgTime from "assets/images/bgTime.png";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { ChangeEvent, Fragment, useLayoutEffect, useState } from "react";
import useMission from "../hooks";

interface ScheduleItems
{
	title: any;
	startClock: number;
	startMin: number;
}

interface DataTime
{
	hours: string;
	minutes: string;
}
function Schedule()
{
	const missionContext = useMission();

	const [ scheduleItems, setScheduleItems ] = useState<ScheduleItems[]>([
		{
			title: <ContentAdmin limit={26} keyContent="contentMissionFive" keyText="schedule_title_1" defaultValue="Wake up time" position="left" />,
			startClock: 7,
			startMin: 0
		},
		{
			title: <ContentAdmin limit={26} keyContent="contentMissionFive" keyText="schedule_breakfast" defaultValue="Breakfast" position="left" />,
			startClock: 8,
			startMin: 0
		},
		{
			title: <ContentAdmin limit={26} keyContent="contentMissionFive" keyText="schedule_lunch" defaultValue="Lunch" position="left" />,
			startClock: 13,
			startMin: 0
		},
		{
			title: <ContentAdmin limit={26} keyContent="contentMissionFive" keyText="schedule_dinner" defaultValue="Dinner" position="left" />,
			startClock: 19,
			startMin: 0
		},
		{
			title: <ContentAdmin limit={26} keyContent="contentMissionFive" keyText="schedule_go_bed" defaultValue="Go to bed" position="left" />,
			startClock: 22,
			startMin: 0
		}
	]);

	// Handles
	function nextStep()
	{
		const dataTime: DataTime[] = [];

		scheduleItems.forEach((item: ScheduleItems) =>
		{
			dataTime.push(
				{
					hours: String(item.startClock).padStart(2, "0"),
					minutes: String(item.startMin).padStart(2, "0")
				}
			);
		});

		localStorage.setItem("mission-five-time", JSON.stringify(dataTime));

		missionContext.setStep((prevState) => prevState + 1);
	}

	function onChangeTime(index: number, type: "hour" | "min")
	{
		return (value: number) =>
		{
			setScheduleItems((prevState) =>
			{
				const items = [ ...prevState ];

				items[ index ][ (type === "hour" ? "startClock" : "startMin") ] = value;

				return items;
			});
		};
	}

	// Render
	return (
		<Box sx={{
			display: "flex",
			width: { xl: "100%", xs: "746px" },
			height: { xl: "auto", xs: "475px" },
			m: { xl: "-20px auto 0", xs: "0 auto" },
			border: "1px solid rgba(255, 255, 255, .6)",
			borderRadius: "24px",
			boxShadow: "0px 5px 10px 0px #A7C7E199"
		}}>
			<Box sx={{
				width: { xl: "50%", xs: "40%" },
				background: `url(${ bgTime }) right/cover`,
				borderRadius: "24px 0 0 24px"
			}} />

			<Box sx={{
				padding: { xl: "80px 24px 48px 24px", xs: "32px 24px 24px 24px" },
				width: { xl: "50%", xs: "60%" },
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
				backdropFilter: "blur(25px)"
			}}>
				<Box sx={{
					display: "inline-block",
					wordBreak: "break-all",
					width: "100%",
					overflow: "hidden",
					color: "#215C75",
					font: { xl: "700 24px/33px 'Open Sans'", xs: "700 18px/24px 'Open Sans'" },
					textAlign: "center"
				}}>
					<ContentAdmin limit={86} keyContent="contentMissionFive" keyText="name_schedule" defaultValue="{NAME}'s schedule" position="left" />
				</Box>

				<Box sx={{
					marginTop: { xl: "60px", xs: "24px" },
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: { xl: "32px 40px", xs: "16px 24px" }
				}}>
					{scheduleItems.map((item, index) => (
						<Fragment key={index}>
							<Box sx={{
								alignSelf: "center",
								textAlign: "right",
								wordBreak: "break-all",
								font: { xl: "400 22px / 30px 'Open Sans'", xs: "400 16px / 22px 'Open Sans'" },
								color: "primary.dark"
							}}>
								{item.title}
							</Box>

							<Box sx={{ alignSelf: "center", display: "flex", gap: "8px" }}>
								<NumberInput
									value={String(item.startClock).padStart(2, "0")}
									onChange={onChangeTime(index, "hour")}
									min={0}
									max={23}
									step={1}
								/>

								<NumberInput
									value={String(item.startMin).padStart(2, "0")}
									onChange={onChangeTime(index, "min")}
									min={0}
									max={59}
									step={5}
								/>
							</Box>
						</Fragment>
					))}
				</Box>

				<CustomButton.Contained
					onClick={nextStep}
					sx={{
						margin: { xl: "64px auto 0 auto", xs: "32px auto 0 auto" },
						display: "flex",
						padding: { xl: "10px 45px", xs: "7.5px 80px" }
					}}
				>
					Submit
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

interface INumberInputProps
{
	value?: string | number | readonly string[];
	onChange?(value: number): void;
	min?: string | number;
	max?: string | number;
	step?: string | number;
	sx?: SxProps<Theme>;
}

function NumberInput({ value: valueProps, onChange: onChangeProps, min, max, step, sx = [] }: INumberInputProps)
{
	const [ value, setValue ] = useState<typeof valueProps>(valueProps);

	// Effects
	useLayoutEffect(() =>
	{
		onChangeProps !== undefined && onChangeProps(Number(value));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ value ]);

	useLayoutEffect(() => setValue(valueProps), [ valueProps ]);

	// Handles
	function onChange(event: ChangeEvent<HTMLInputElement>)
	{
		let value: number = event.target.valueAsNumber;

		if (max !== undefined && value > Number(max))
		{
			return event.preventDefault();
		}
		else if (min !== undefined && value < Number(min))
		{
			return event.preventDefault();
		}

		setValue(value);
	}

	// Render
	return (
		<Box
			className="NumberInput-root"
			sx={[
				{
					position: "relative",
					width: { xl: "70px", lg: "58px", xs: "58px" },

					"& .NumberInput-input":
					{
						appearance: "textfield",
						width: "100%",
						padding: { xl: "13px 28px 13px 16px", lg: "13px 24px 13px 16px", xs: "13px 24px 13px 16px" },
						backgroundColor: alpha("#FFFFFF", 0.5),
						border: "unset",
						borderRadius: "8px",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "primary.dark",

						"&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
						{
							appearance: "none",
							margin: 0
						}
					},
					"& .NumberInput-arrow":
					{
						position: "absolute",
						right: { xl: "8px", lg: "6px", xs: "6px" },
						padding: "unset",
						display: "flex",
						background: "unset",
						border: "unset",
						cursor: "pointer",
						opacity: 0.5,
						transition: "linear 200ms opacity",

						"&:hover":
						{
							opacity: 1
						},
						"&::after":
						{
							content: "''",
							borderStyle: "solid",
							borderWidth: { xl: "0 6px 12px 6px", lg: "0 5px 10px 5px", xs: "0 5px 10px 5px" },
							borderColor: "transparent transparent #3E809D transparent"
						},
						"&.NumberInput-arrow--top":
						{
							top: { xl: "6px", lg: "5px", xs: "5px" }
						},
						"&.NumberInput-arrow--bottom":
						{
							bottom: { xl: "6px", lg: "5px", xs: "5px" },

							"&::after":
							{
								transform: "rotate(180deg)"
							}
						}
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
		>
			<input
				className="NumberInput-input"
				type="number"
				value={value}
				onChange={onChange}
				min={min}
				max={max}
				step={step}
			/>

			<button
				className="NumberInput-arrow NumberInput-arrow--top"
				onClick={() =>
				{
					setValue((prevValue) =>
					{
						let value: number = Number(prevValue) + (step === undefined ? 1 : Number(step));

						if (max !== undefined && value > Number(max))
						{
							value = Number(max);
						}
						else if (min !== undefined && value < Number(min))
						{
							value = Number(min);
						}

						return value;
					});
				}}
			/>

			<button
				className="NumberInput-arrow NumberInput-arrow--bottom"
				onClick={() =>
				{
					setValue((prevValue) =>
					{
						let value: number = Number(prevValue) - (step === undefined ? 1 : Number(step));

						if (max !== undefined && value > Number(max))
						{
							value = Number(max);
						}
						else if (min !== undefined && value < Number(min))
						{
							value = Number(min);
						}

						return value;
					});
				}}
			/>
		</Box>
	);
}

export default Schedule;
