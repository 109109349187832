import FeedbackCreate from "components/feedback";
import useMission from "pages/missionTwo/hooks/useMission";
import { useEffect, useReducer } from "react";
import { userInitialState } from "reducers/userInitState";
import { userReducer } from "reducers/userReducer";

function Feedback()
{
	const missionContext = useMission();
	const [ userState ] = useReducer(userReducer, userInitialState);

	useEffect(() =>
	{
		userState.user?.completedMission !== 1 && setTimeout(() => missionContext.nextStep(), 0);

	}, []);

	function nextStep()
	{
		setTimeout(() => missionContext.nextStep(), 0);
	}

	return (
		<FeedbackCreate missionNumber={2} name={userState.user?.name} nextStep={nextStep} />
	);
}

export default Feedback;
