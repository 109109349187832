import { Box, Link } from "@mui/material";

import IMGFacebook from "assets/images/dashboards/supports/facebook.png";
import IMGInstagram from "assets/images/dashboards/supports/instagram.png";
import IMGLinkedIn from "assets/images/dashboards/supports/linkedin.png";
import IMGMedium from "assets/images/dashboards/supports/medium.png";
import IMGYoutube from "assets/images/dashboards/supports/youtube.png";
import ContentAdmin from "components/contentAdmin";

function Community()
{
	return (
		<>
			<Box sx={{
				font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: "primary.dark"
			}}>
				<ContentAdmin
					isDescription
					position="left"
					defaultValue="Join our community of trailblazers and get inspired at every step of the
        journey"
					keyContent="contentDashboard"
					keyText="communityDesc" />
			</Box>

			<Box sx={{
				font: { xl: "700 22px/30px 'Open Sans'", lg: "700 16px/22px 'Open Sans'", xs: "700 16px/22px 'Open Sans'" },
				color: "primary.main"
			}}>
				Website{" "}
				<Link
					target="_blank"
					href="https://www.yourlifestartup.com/"
					sx={{ color: "inherit", textDecorationColor: "#215C75" }}
				>
					https://www.yourlifestartup.com/
				</Link>
			</Box>

			<Box sx={{
				marginTop: { xl: "64px", lg: "40px", xs: "40px" },
				display: "flex",
				justifyContent: "center",
				gap: { xl: "40px", lg: "30px", xs: "30px" },

				"& img":
				{
					width: { xl: "56px", lg: "42px", xs: "42px" },
					height: { xl: "56px", lg: "42px", xs: "42px" }
				}
			}}>
				<Link target="_blank" href="https://www.facebook.com/YourLifeStartup">
					<img src={IMGFacebook} alt="Facebook" />
				</Link>

				<Link target="_blank" href="https://www.instagram.com/mylifequest.io/">
					<img src={IMGInstagram} alt="Instagram" />
				</Link>

				<Link target="_blank" href="https://www.linkedin.com/company/42876385/">
					<img src={IMGLinkedIn} alt="LinkedIn" />
				</Link>

				<Link target="_blank" href="https://www.youtube.com/channel/UCHXowvO96Yc8ngQ-mEGPCJQ">
					<img src={IMGYoutube} alt="YouTube" />
				</Link>

				<Link target="_blank" href="https://medium.com/@natalya.permyakova">
					<img src={IMGMedium} alt="Medium" />
				</Link>
			</Box>
		</>
	);
}

export default Community;
