import { Box, alpha } from "@mui/material";
import Api from "api";
import CustomInput from "components/CustomInput";
import { ChangeEvent, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import ArtifactsContainer from "../components/ArtifactsContainer";
import IMGBackgroundCreate from "./assets/images/background-create.png";
import { ReactComponent as SVGMailClose } from "./assets/images/mail-close.svg";

export interface ICreateProps
{
	updateItems(): void;
}

export const LETTER_MAX_LENGTH: number = 1000;
const PERIODS: [ label: string, value: string ][] = [
	[ "3 month", "3,months" ],
	[ "6 month", "6,months" ],
	[ "1 year", "1,years" ],
	[ "2 year", "2,years" ],
	[ "5 year", "5,years" ]
];

function Create({ updateItems }: ICreateProps)
{
	const navigate = useNavigate();
	const isFetchRef = useRef<boolean>(false);

	const [ selectedPeriod, setSelectedPeriod ] = useState<string>(PERIODS[ 1 ][ 1 ]);
	const [ inputValue, setInputValue ] = useState<string>(() =>
	{
		return getLocalStorage<string>("dashboard-letter-yourself-text") ?? "";
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage("dashboard-letter-yourself-text", inputValue);
	}, [ inputValue ]);

	// Handles
	function onChangeInput({ target }: ChangeEvent<HTMLTextAreaElement>)
	{
		setInputValue(target.value.replaceAll(/\n{3,}/g, "\n\n"));
	}

	function onMailSend()
	{
		const value = inputValue.trim();

		if (value.length === 0 || value.length > LETTER_MAX_LENGTH || isFetchRef.current === true)
		{
			return;
		}

		isFetchRef.current = true;

		const [ period, monthOrYear ] = selectedPeriod.split(",");

		Api.missions
			.setDataMissionEleven(
				value,
				{
					artifact: true,
					monthOrYear: monthOrYear as "months" | "years",
					number: Number(period)
				},
				true
			)
			.then(() =>
			{
				localStorage.removeItem("dashboard-letter-yourself-text");
				updateItems();
				navigate("/dashboard/artifacts/letter-yourself");
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetchRef.current = false));
	}

	// Render
	return (
		<ArtifactsContainer
			title="New letter"
			backgroundImage={IMGBackgroundCreate}
			maxWidth="unset"
			onClose={() => navigate("/dashboard/artifacts/letter-yourself")}
			sx={{
				"& .ArtifactsContainer-main":
				{
					gap: "unset",
					paddingBottom: "60px"
				},
				"& .ArtifactsContainer-content":
				{
					height: "100%"
				}
			}}
		>
			<Box sx={{
				justifySelf: "center",
				width: { xl: "1059px", md: "780px" },
				height: "100%",
				padding: { xl: "32px 32px 48px 32px", xs: "23px 23px 38px" },

				background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
				boxShadow: "0px 4.58px 18.3px " + alpha("#7EC4FF", 0.4),
				backdropFilter: "blur(30px)",
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "40px",

				display: { xl: "grid", lg: "flex", md: "flex" },
				flexDirection: { xl: "unset", lg: "column", md: "column" },
				justifyContent: { xl: "unset", lg: "space-between", md: "space-between" },
				gridTemplateRows: "540px auto",
				gap: { xl: "8px", xs: "16px" }
			}}>
				<Box sx={{
					position: "relative",
					height: { xl: "540px", lg: "397px", md: "400px" },
					borderRadius: "24px"
				}}>
					<CustomInput.Base
						className="customScroll"
						multiline
						minRows={10}
						value={inputValue}
						onChange={onChangeInput}
						inputProps={{ maxLength: LETTER_MAX_LENGTH }}
						sx={{
							overflowY: "auto",
							width: "100%",
							height: "100%",
							background: "unset",
							backgroundColor: alpha("#FFFFFF", 0.5),
							borderRadius: "24px",
							padding: { xl: "0 48px 0 48px !important", xs: "0 34px !important" },

							".MuiOutlinedInput-input":
							{
								marginTop: "auto",
								padding: "unset",
								font: "400 22px/50px 'Satisfy'",
								color: "#4D4D4D",
								background: "linear-gradient(rgba(255, 255, 255, 0.5) 2px, transparent 0px)",
								backgroundSize: "100% 50px",
								backgroundPositionY: "34px",

								"&:placeholder":
								{
									textAlign: "center"
								}
							}
						}}
					/>

					<Box sx={{
						position: "absolute",
						bottom: "-18px",
						right: "47px",
						color: "#FFFFFF",
						font: "400 12px/17px 'Open Sans'",
						opacity: 0.5
					}}>
						{inputValue.length}/{LETTER_MAX_LENGTH} characters
					</Box>

					{inputValue === "" &&
						(
							<Box sx={{
								position: "absolute",
								top: { xl: "14px", xs: "0" },
								width: "100%",
								textAlign: "center",
								font: { xl: "400 22px/50px Satisfy", xs: "400 18px/40px Satisfy" },
								color: "primary.dark",
								opacity: 0.5,
								zIndex: 5,
								pointerEvents: "none"
							}
							}>
								Write yourself a letter
							</Box>
						)
					}
				</Box>

				<Box>
					<Box sx={{
						color: "#FFFFFF", font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }, textAlign: "center"
					}}>
						Choose after what period you want to open the letter
					</Box>

					<Box sx={{
						marginTop: { xl: "8px", xs: "16px" },
						display: "flex",
						justifyContent: "center",
						gap: "16px",

						"& label":
						{
							cursor: "pointer",

							"& span":
							{
								display: "block",
								minWidth: "112px",
								padding: "11px 22px",
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 3px 5px " + alpha("#000000", 0.161),
								borderRadius: "32px",
								font: "700 14px/20px 'Open Sans'",
								color: "primary.main",
								textAlign: "center",
								textTransform: "uppercase",
								transition: "linear 300ms background-color"
							},
							"& input":
							{
								display: "none"
							},
							"& input:checked + span":
							{
								color: "#FFFFFF",
								backgroundColor: alpha("#3E809D", 0.5)
							}
						}
					}}>
						{PERIODS.map(([ label, value ]) =>
						{
							return (
								<Box component="label" key={value}>
									<input
										type="radio"
										name="period"
										value={value}
										checked={selectedPeriod === value}
										onChange={({ target }) =>
										{
											setSelectedPeriod(target.value);
										}}
									/>
									<span>{label}</span>
								</Box>
							);
						})}
					</Box>
				</Box>

				<Box
					component="button"
					onClick={onMailSend}
					sx={{
						position: "absolute",
						bottom: { xl: "-36px", xs: "-27px" },
						left: "50%",
						transform: "translateX(-50%)",
						width: { xl: "72px", xs: "54px" },
						height: { xl: "72px", xs: "54px" },
						background: "linear-gradient(180deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.102) 100%)",
						border: "2px solid #FFFFFF",
						borderRadius: "50%",
						filter: "drop-shadow(2.74894px 2.74894px 9.16312px rgba(28, 130, 170, 0.659))",
						cursor: "pointer",

						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<SVGMailClose />
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default Create;
