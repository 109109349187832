import { Box, BoxProps, alpha } from "@mui/material";
import { ReactComponent as SVGGetHelp } from "assets/images/icons/size-24/get-help.svg";
import { ForwardRefExoticComponent, Ref, forwardRef, useEffect, useState } from "react";

export interface IGetHelpButtonProps extends BoxProps { }

const AUTO_HIDE_TIME: number = 3000;

function GetHelpButton({ sx, ...props }: IGetHelpButtonProps, ref: Ref<HTMLDivElement>)
{
	const [ isShow, setIsShow ] = useState<boolean>(false);

	// Effects
	useEffect(() =>
	{
		let timerId: NodeJS.Timeout;

		if (isShow === true)
		{
			timerId = setTimeout(() => setIsShow(false), AUTO_HIDE_TIME);
		}

		return () => clearTimeout(timerId);
	}, [ isShow ]);

	// Handles
	function onClickButton()
	{
		setIsShow((prevState) =>
		{
			if (prevState === true)
			{
				window.open(process.env.REACT_APP_GET_HELP_LINK, "_blank");
			}

			return !prevState;
		});
	}

	// Render
	return (
		<Box
			className="GetHelpButton-root"
			component="button"
			ref={ref}
			sx={[
				{
					pointerEvents: "auto",
					position: "relative",
					right: "-80%",

					padding: "12px 80px",

					backgroundColor: alpha("#FFFFFF", 0.5),
					backdropFilter: "blur(10px)",
					border: "unset",
					borderRadius: "40px",

					display: "flex",
					alignItems: "center",
					gap: "16px",

					cursor: "pointer",

					transition: "300ms linear right",

					"& .GetHelpButton-text":
					{
						font: "700 24px/33px 'Open Sans'",
						color: "primary.main"
					},
					"& .GetHelpButton-icon":
					{
						position: "absolute",
						left: "18px"
					}
				},
				isShow === true &&
				{
					right: "-40px",

					"& .GetHelpButton-icon":
					{
						display: "none"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			onClick={onClickButton}
			{...props}
		>
			<SVGGetHelp className="GetHelpButton-icon" />

			<span className="GetHelpButton-text">get help</span>
		</Box>
	);
}

export default forwardRef(GetHelpButton) as ForwardRefExoticComponent<IGetHelpButtonProps>;
