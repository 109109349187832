import { Box, Grid, Typography } from "@mui/material";
import Money from "../../../../assets/images/Money.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function MoneyDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Money} alt="Money" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Money
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="Having money-related worries is more common than you think, {NAME}. Most people have financial concerns and fears. But there are strategies and mindsets you can apply to feel more confident about this area of your life." keyContent="contentMissionOne" keyText="moneyScreen1" />
				{/* Having money-related worries is more common than you think, {localStorage.getItem('name')}. Most people have financial concerns and fears. But there are strategies and mindsets you can apply to feel more confident about this area of your life. */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Do you earn enough?\n- Do you live within your means?\n- Do you have an emergency fund?\n\nMany people feel insecure about their finances because of the lack of knowledge and understanding of how money works. If you haven’t already, educate yourself on personal budgeting, basic rules of investment and other money-related topics to build your confidence." keyContent="contentMissionOne" keyText="moneyDescStart" />
						{/* Many people feel insecure about their finances because of the lack of knowledge and understanding of how money works. If you haven’t already, educate yourself on personal budgeting, basic rules of investment and other money-related topics to build your confidence. */}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Are you planning for financial freedom?\n- How much money per month would you need to feel comfortable?\n- What lifestyle change could help you ease the financial pressure?" keyContent="contentMissionOne" keyText="moneyDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "5px",
						height: "5px",
						borderRadius: "50%",
						backgroundColor: "#4D4D4D"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Feeling good about money is a lot more achievable than you think. The secret is understanding that it’s not just about earning more. It is also about living a life full of exciting experiences and adventures, and not just material objects.\\n\\nWhen you live life a meaningful life, you don’t need to ‘bribe’ yourself with stuff. You will feel happier with less. And that’s a tremendous step towards your freedom. Life Strategy Quest will help you look at all aspects of your life and figure it what kind of life would feel most meaningful to you." keyContent="contentMissionOne" keyText="moneyScreen2" />
				{/*
				Feeling good about money is a lot more achievable than you think. The secret is understanding that it’s not just about earning more. It is also about living a life full of exciting experiences and adventures, and not just material objects.<br></br><br></br>
				When you live life a meaningful life, you don’t need to ‘bribe’ yourself with stuff. You will feel happier with less. And that’s a tremendous step towards your freedom. Life Strategy Quest will help you look at all aspects of your life and figure it what kind of life would feel most meaningful to you.
				*/}
			</Box>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default MoneyDetails;
