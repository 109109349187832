import { createContext, Dispatch, SetStateAction } from "react";
import { BackgroundType } from ".";

export interface IMissionContextNineMission
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	firstPlaythrough: boolean;
	setFirstPlaythrough: Dispatch<SetStateAction<boolean>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof BackgroundType;
	setBackground: any;

	name: string;
	setName: Dispatch<SetStateAction<string>>;
	setShowMissionInfo: Dispatch<SetStateAction<2 | 3 | 4 | 5 | 6 | 7 | null>>;
	showContent: boolean;
	setShowContent: any;
}

export const MissionCtx = createContext<IMissionContextNineMission>({} as IMissionContextNineMission);
