import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, Typography } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGIconDownload } from "assets/images/icons/download.svg";
import bgNorthStar from "assets/images/missions/mission-nine/missionNineStar.svg";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";

export interface DataTask
{
	id: number;
	title: string;
	active: boolean;
	define: string;
	items: DataStep[];
	input?: string;
}
export interface DataStep
{
	id: number;
	title: string;
	active: boolean;
	checked: boolean;
	values: Values[];
	input?: string;
}
export interface Values
{
	title: string;
	checked: boolean;
}
export interface DataMissions
{
	_id: string;
	created_at: string;
	user_id: string;
	id: string;
	mission_data: any;
	title: string;

}
function NorthStar()
{
	const navigate = useNavigate();
	const [ dataMissions, setDataMissions ] = useState<any>([]);

	useLayoutEffect(() =>
	{
		Api.missions
			.getDataMissionNine()
			.then(({ data }) =>
			{
				setDataMissions(data[ 0 ].mission_data[ 2 ]);
			})
			.catch((error) => console.error(error));
	}, [ navigate ]);

	return (
		<ArtifactsContainer
			title="My North Star"
			headerRight={
				<TooltipStyled title="Download" placement="left" arrow>
					<Box
						component="button"
						sx={{
							display: "none",
							padding: "unset",
							background: "unset",
							border: "unset",
							cursor: "pointer",
							color: "inherit"
						}}
					>
						<SVGIconDownload />
					</Box>
				</TooltipStyled>
			}
			backgroundImage={bgNorthStar}
			sx={{
				"& .ArtifactsContainer-content": {
					border: "none"
				}
			}}
			onClose={() => navigate("/dashboard/artifacts")}
		>
			<Box sx={{
				margin: "0px",
				width: "980px",
				height: { xl: "649px", xs: "502px" },
				borderRadius: "24px",
				background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
				backdropFilter: "blur(25px)",
				m: "0 auto",
				p: "61px 45px"
			}}>
				<Box
					className="customScrollWhite"
					sx={{
						overflowY: "auto",
						overflowX: "hidden",
						paddingRight: "45px",
						maxHeight: "100%"
					}}
				>
					{/* <Box component="img" src={starNineartifact} /> */}
					{dataMissions.length
						? (
							<Grid container spacing={2}>
								<Grid
									item
									xs={6}
									sx={{
										m: "0 0 33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere"
									}}>
									<Typography sx={{ font: "700 24px/31px Lora-Bold", m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
										My purpose is
									</Typography>
									<Typography sx={{ font: '400 22px/30px "Open Sans"', m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
										{dataMissions[ 0 ].value}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{ m: "0 0 33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
									<Typography sx={{ font: "700 24px/31px Lora-Bold", m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
										My tribe is...
									</Typography>
									<Typography sx={{ font: '400 22px/30px "Open Sans"', m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
										{dataMissions[ 2 ].value}
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{ m: "8px 0 15px 0", overflow: "hidden", maxWidth: "100% !important", overflowWrap: "anywhere" }}>
									<Typography sx={{ font: "700 24px/31px Lora-Bold", m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
										This is why I am motivated every day to fulfill my purpose
									</Typography>
									<Typography sx={{ font: '400 22px/30px "Open Sans"', m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
										{dataMissions[ 4 ].value}
									</Typography>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{ m: "0 0 33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
									<Typography sx={{ font: "700 24px/31px Lora-Bold", m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
										This is what I stand for
									</Typography>
									{dataMissions[ 3 ].items
										? (
											<Typography sx={{ font: '400 22px/30px "Open Sans"', m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
												{dataMissions[ 3 ].items.map((item: any, index: number) => (
													<Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
														<FiberManualRecordIcon sx={{ fill: "#4D4D4D", mt: "4px", fontSize: "10px" }} />
														<Typography key={index} sx={{ font: '400 22px/30px "Open Sans"', color: "#4D4D4D", textAlign: "left" }}>
															{item.value}
														</Typography>
													</Box>
												))}
											</Typography>
										)
										: null
									}
								</Grid>
								<Grid
									item
									xs={6}
									sx={{ m: "0 0 33px 0", overflow: "hidden", maxWidth: "50% !important", overflowWrap: "anywhere" }}>
									<Typography sx={{ font: "700 24px/31px Lora-Bold", m: "-6px 0 20px 19px", color: "#215C75", textAlign: "left" }}>
										Quotes I live by
									</Typography>
									{dataMissions[ 1 ].items
										? (
											<Typography sx={{ font: '400 22px/30px "Open Sans"', m: "-6px 0 16px 19px", color: "#4D4D4D", textAlign: "left" }}>
												{dataMissions[ 1 ].items.map((item: any, index: number) => (
													<Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
														<FiberManualRecordIcon sx={{ fill: "#4D4D4D", mt: "4px", fontSize: "10px" }} />
														<Typography key={index} sx={{ font: '400 22px/30px "Open Sans"', color: "#4D4D4D", textAlign: "left" }}>
															{item.value}
														</Typography>
													</Box>
												))}
											</Typography>
										)
										: null
									}
								</Grid>
							</Grid>
						)
						: null
					}
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default NorthStar;
