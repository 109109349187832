import { Box, Zoom } from "@mui/material";
import CharButton from "components/CharButton";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import { ForwardRefExoticComponent, Ref, forwardRef, useState } from "react";
import { ReactComponent as SVGBulbGreen } from "../../assets/images/green.svg";
import { ReactComponent as SVGBulbNone } from "../../assets/images/none.svg";
import IMGOctaHead from "../../assets/images/octa-head.png";
import { ReactComponent as SVGBulbRed } from "../../assets/images/red.svg";
import useMission from "../../hooks/useMission";

export type IQuizProps = Record<string, any>;

interface IQuestion<A extends readonly [ string, string, string, string ] = readonly [ string, string, string, string ]>
{
	readonly title: string;
	readonly answers: A;
	readonly answer: A[ number ];
	readonly description: string;
}

type QuestionsAreIQuestionArray = typeof questions extends readonly IQuestion[]
	? {}
	: "questions should be IQuestion[]";

type NumericKeysFor<T extends readonly any[], A extends any[] = [], R = never> = A[ "length" ] extends T[ "length" ] ? R : NumericKeysFor<T, [ ...A, any ], R | A[ "length" ]>;

type EachQuestionHasValidAnswer =
	{
		[ key in NumericKeysFor<typeof questions> ]: (typeof questions)[ key ] extends { readonly answers: readonly [ infer A, infer B, infer C, infer D ]; readonly answer: infer R; } ? R extends A | B | C | D ? {} : `Question ${ key } has invalid answer` : `Question ${ key } is invalid`;
	}[ NumericKeysFor<typeof questions> ];

interface _CheckQuestions extends QuestionsAreIQuestionArray, EachQuestionHasValidAnswer { } // eslint-disable-line @typescript-eslint/no-unused-vars

/*
Template

{
	title: "",
	answers: [ "", "", "", "" ],
	answer: "",
	description: ""
}
*/

const questions = [
	{
		title: "What is the most common job in the world?",
		answers: [ "Doctor", "Farmer", "Teacher", "Cook" ],
		answer: "Farmer",
		description: "Farming, fishing, and forestry, jobs in the service industry such as retail and food are also very common."
	},
	{
		title: "What is the oldest known profession?",
		answers: [ "Pharmacist", "Prostitute", "Merchant", "Fisherman" ],
		answer: "Prostitute",
		description: "Prostitution and hunting & gathering."
	},
	{
		title: "What is the highest paying job in the world?",
		answers: [ "Surgeon", "Banker", "Lawyer", "Engineer" ],
		answer: "Surgeon",
		description: "Surgeon or a physician, followed by lawyer, and then engineer."
	},
	{
		title: "What are the top in-demand jobs of the future?",
		answers: [ "Technology", "Healthcare", "Renewable energy", "All of the above" ],
		answer: "All of the above",
		description: "Jobs in technology, healthcare, and renewable energy are expected to be in high demand."
	},
	{
		title: "How many percent of our lives do we spend working on average?",
		answers: [ "5-10%", "15-20%", "25-30%", "30-35%" ],
		answer: "15-20%",
		description: "15–20% of their lives working, assuming a working age between 18–65 and 8 hours per day, 5 days a week."
	},
	{
		title: "What percentage of people globally are happy with their current job?",
		answers: [ "5%", "13%", "27%", "54%" ],
		answer: "13%",
		description: "According to a 2019 Gallup survey, only 13% of employees worldwide report being satisfied with their current job."
	}
] as const;

const BUTTONS_CHAR = [ "A", "B", "C", "D" ] as const;

function Quiz({ ...props }: IQuizProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ questionIndex, setQuestionIndex ] = useState<number>(0);
	const [ selectedAnswer, setSelectedAnswer ] = useState<0 | 1 | 2 | 3 | null>(null);

	// Utils
	function isAnswer(index: Exclude<typeof selectedAnswer, null>)
	{
		return questions[ questionIndex ].answer === questions[ questionIndex ].answers[ index ];
	}

	// Handles
	function onSelectAnswer(index: Exclude<typeof selectedAnswer, null>)
	{
		if (selectedAnswer !== null)
		{
			return;
		}

		setSelectedAnswer(index);
	}

	function onNextQuestion()
	{
		setQuestionIndex((prevState) =>
		{
			if (prevState === questions.length - 1)
			{
				missionContext.nextStep();

				return prevState;
			}

			setSelectedAnswer(null);

			return prevState + 1;
		});
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Box sx={{
					display: "flex",
					alignItems: "flex-start",
					gap: { xl: "16px", lg: "10px", xs: "10px" },
					width: "100%",
					marginTop: "-37px"
				}}>
					<Box
						component="img"
						src={IMGOctaHead}
						alt=""
						sx={{
							width: { xl: "154px", lg: "103px", xs: "103px" },
							position: "relative",
							zIndex: 2
						}}
					/>

					<Box sx={{
						color: "primary.main",
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						marginTop: "22px"
					}}>
						Let's do a quiz!
					</Box>
				</Box>

				<Panel sx={{
					marginTop: { xl: "-45px", lg: "-6px", xs: "-6px" },
					maxHeight: { xl: "754px", lg: "741px", xs: "741px" },
					padding: { xl: "20px 48px 60px 60px", lg: "20px 62px 32px 62px", xs: "20px 62px 32px 62px" },
					display: "flex",
					flexDirection: "column"
				}}>
					<Box sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "24px",

						"& svg":
						{
							"--size": { xl: "122px", lg: "86px", xs: "56px" },
							margin: { xl: "-17px -22px -26px -22px", lg: "-12px -15px -18px -15px", xs: "-15px 0" },
							width: "var(--size)",
							height: "var(--size)"
						}
					}}>
						<Box
							sx={{
								color: "primary.main",
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
								textAlign: "center",
								width: { xl: "592px", lg: "402px", xs: "402px" },

								"&::before":
								{
									content: "attr(data-index) '.'",
									marginRight: "10px"
								}
							}}
							data-index={questionIndex + 1}
						>
							{questions[ questionIndex ].title}
						</Box>

						{selectedAnswer === null
							? <SVGBulbNone />
							: isAnswer(selectedAnswer)
								? <SVGBulbGreen />
								: <SVGBulbRed />
						}
					</Box>

					<Box sx={{
						marginTop: "20px",
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						justifyItems: "flex-start",
						gap: "40px 76px"
					}}>
						{BUTTONS_CHAR.map((char, index) =>
						{
							return (
								<CharButton
									key={char}
									char={char}
									value={questions[ questionIndex ].answers[ index ]}
									selected={selectedAnswer === index}
									active={selectedAnswer !== null && isAnswer(index as Exclude<typeof selectedAnswer, null>)}
									onClick={() => onSelectAnswer(index as Exclude<typeof selectedAnswer, null>)}
								/>
							);
						})}
					</Box>

					{selectedAnswer !== null &&
						(
							<>
								<Zoom in timeout={1000}>
									<Box sx={{
										margin: { xl: "56px auto 56px auto", lg: "32px auto 24px auto", xs: "32px auto 24px auto" },
										width: "616px",
										font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										color: "primary.dark",
										textAlign: "center"
									}}>
										{questions[ questionIndex ].description}
									</Box>
								</Zoom>

								<CustomButton.Contained
									onClick={onNextQuestion}
									sx={{
										display: "flex",
										margin: "0 auto",
										paddingLeft: { xl: "80px !important", lg: "32px !important", xs: "32px !important" },
										paddingRight: { xl: "80px !important", lg: "32px !important", xs: "32px !important" }
									}}
								>
									{questionIndex === questions.length - 1 ? "I am done" : "Next question"}
								</CustomButton.Contained>
							</>
						)
					}
				</Panel>
			</Box>
		</Box>
	);
}

export default forwardRef(Quiz) as ForwardRefExoticComponent<IQuizProps>;
