import { Box, Button, CardMedia, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MuiTextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { forwardRef, Ref, useEffect, useState } from "react";
import * as Yup from "yup";
import cursorCustom from "../../../../../assets/images/cursorCustom.svg";
import { ReactComponent as SVGColleagues } from "../../../../../assets/images/icons/colleagues.svg";
import { ReactComponent as SVGMyfamily } from "../../../../../assets/images/icons/myfamily.svg";
import { ReactComponent as SVGMyfriends } from "../../../../../assets/images/icons/myfriends.svg";
import mypartner from "../../../../../assets/images/icons/mypartner.svg";
import { ReactComponent as SVGMypet } from "../../../../../assets/images/icons/mypet.svg";
import { ReactComponent as SVGRadial } from "../../../../../assets/images/icons/radial.svg";
import peopleBtn from "../../../../../assets/images/peopleBtn.svg";
import ContentAdmin from "../../../../../components/contentAdmin";
import CustomButton from "../../../../../components/customButton";
import useMission from "../../../hooks";

const TextField = styled(MuiTextField)(({ theme }) => ({
	"& .MuiInputBase-root fieldset": {
		border: "none !important"
	},
	"& .MuiOutlinedInput-root": {
		[ theme.breakpoints.down("xl") ]: {
			font: '400 16px/22px "Open Sans" !important',
			height: "48px",
			lineHeight: "48px"
		},
		[ theme.breakpoints.up("xl") ]: {
			font: '400 22px/30px "Open Sans" !important',
			height: "59px",
			lineHeight: "59px"
		},
		color: "#4D4D4D !important",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		borderRadius: "10px !important"
	},
	"& .MuiInputBase-input": {
		[ theme.breakpoints.down("xl") ]: {
			padding: "13px 16px 13px 0"
		},
		[ theme.breakpoints.up("xl") ]: {
			padding: "16.5px 14px 16.5px 0"
		}
	},
	"& .MuiFormHelperText-contained": {
		textAlign: "right",
		font: '400 14px "Open Sans" !important'
	},
	"& .MuiInputAdornment-positionStart": {
		font: "inherit",
		letterSpacing: "inherit",
		color: "currentColor",
		background: "none",
		height: "1.4375em",
		margin: "0px 5px 0px 0px"
	},
	"& .MuiInputAdornment-positionStart p": {
		[ theme.breakpoints.down("xl") ]: {
			font: '400 16px/22px "Open Sans" !important',
			paddingLeft: "2px !important"
		},
		[ theme.breakpoints.up("xl") ]: {
			font: '400 22px/30px "Open Sans" !important',
			paddingLeft: "5px !important"
		},
		color: "#4D4D4D !important"
	}
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(135deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98%)",
		font: '400 14px/17px "Open Sans"',
		color: "#4D4D4D",
		boxShadow: "0px 5px 10px rgba(167, 199, 225, 0.6)",
		padding: "24px",
		borderRadius: "16px",
		backdropFilter: "blur(15px)",
		textTransform: "capitalize",
		border: "1px solid  rgba(239, 239, 239, 0.0816)"
	}
}));

const ButtonStyled = styled(Button)(
	{
		width: "80px",
		height: "80px",
		border: "2px solid #FFFFFF",
		borderRadius: "50%",
		boxShadow: "3px 3px 10px rgba(28, 130, 170, .66)",
		backdropFilter: "blur(15px)"

	}
) as typeof Button;

interface Ibuttons
{
	id: number;
	icon: JSX.Element;
	active: boolean;
	word: string;
}
const People = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const { setStepClockMorning } = props;
	const missionContext = useMission();
	const [ selectedBtns, setSelectedBtns ] = useState<any>([]);
	const [ btns, setBtns ] = useState<Ibuttons[]>([
		{
			id: 0,
			// icon: <SVGMyself />,
			icon: <CardMedia
				image={peopleBtn}
				sx={{
					width: "100px",
					height: "100px",
					backgroundPosition: "center",
					backgroundSize: "contain"
				}}
			/>,
			active: false,
			word: " myself"
		},
		{
			id: 1,
			// icon: <SVGMypartner />,
			icon: <CardMedia
				image={mypartner}
				sx={{
					width: "75px",
					height: "75px",
					backgroundPosition: "center",
					backgroundSize: "contain"
				}}
			/>,
			active: false,
			word: " my partner"
		},
		{
			id: 2,
			icon: <SVGMyfamily />,
			active: false,
			word: " my family"
		},
		{
			id: 3,
			icon: <SVGColleagues />,
			active: false,
			word: " colleagues"
		},
		{
			id: 4,
			icon: <SVGMyfriends />,
			active: false,
			word: " my friends"
		},
		{
			id: 5,
			icon: <SVGMypet />,
			active: false,
			word: " my pet"
		}
	]);
	const formik = useFormik({
		initialValues: {
			interactDay: ""
		},
		validationSchema: Yup.object({
			interactDay: Yup.string()
				.max(64, "Must be 64 characters or less")
				.required("required")
		}),
		onSubmit: (values) =>
		{
			if (localStorage.getItem("mission-five-data-afternoon-environment") && localStorage.getItem("mission-five-data-afternoon-activities"))
			{
				const dataAfternoon = {
					environment: JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon-environment") }`),
					activities: JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon-activities") }`),
					people: values.interactDay
				};

				localStorage.setItem("mission-five-data-afternoon", JSON.stringify(dataAfternoon));
				localStorage.removeItem("mission-five-data-afternoon-environment");
				localStorage.removeItem("mission-five-data-afternoon-activities");
				localStorage.removeItem("mission-five-data-afternoon-people");
				localStorage.removeItem("mission-five-data-afternoon-active-people");
				missionContext.setBlurBg(true);

				missionContext.setStep((prevState) => prevState + 1);
			}
			else
			{
				localStorage.setItem("mission-five-data-afternoon-people", values.interactDay);
				const newbtns = [ ...btns ];
				const arrSelectedBtns: any = [];

				newbtns.forEach((item) =>
				{
					if (item.active) arrSelectedBtns.push({
						id: item.id,
						word: item.word
					});
				});
				localStorage.setItem("mission-five-data-afternoon-active-people", JSON.stringify(arrSelectedBtns));
				setStepClockMorning(1);
			}
		}
	});

	useEffect(() =>
	{
		const dataNight = localStorage.getItem("mission-five-data-afternoon-people");
		if (dataNight) formik.setFieldValue("interactDay", dataNight);
		const localActiveBtns = JSON.parse(`${ localStorage.getItem("mission-five-data-afternoon-active-people") }`);
		if (localActiveBtns && localActiveBtns.length > 0)
		{
			const newArrBtns = [ ...btns ];

			newArrBtns.forEach((item: Ibuttons) =>
			{
				localActiveBtns.forEach((btn: any) =>
				{
					if (item.id === btn.id)
					{
						item.active = true;
					}
				});
			});
			setSelectedBtns(localActiveBtns);
			setBtns(newArrBtns);
		}
	}, []);
	function handleClick(item: Ibuttons)
	{
		const newbtns = [ ...btns ];
		const arrSelectedBtns = [ ...selectedBtns ];

		newbtns.forEach((btn) =>
		{
			if (btn.id === item.id)
			{
				if (btn.active)
				{
					const activeButtons = arrSelectedBtns.filter(n => n.id !== item.id);

					btn.active = false;
					setSelectedBtns(activeButtons);
					formik.setFieldValue("interactDay", formik.values.interactDay.includes(`,${ btn.word }`) ? formik.values.interactDay.replace(`,${ btn.word }`, "") : formik.values.interactDay.replace(`${ btn.word }`, ""));
				}

				else
				{
					arrSelectedBtns.push({
						id: btn.id,
						word: btn.word
					});
					btn.active = true;
					setSelectedBtns(arrSelectedBtns);
					formik.setFieldValue("interactDay", formik.values.interactDay + (arrSelectedBtns.length > 1 ? "," : "") + btn.word);
				}
			}
		});
		setBtns(newbtns);
	}

	function handleChange(value: string)
	{
		formik.setFieldValue("interactDay", value);
		const newbtns = [ ...btns ];
		const arrSelectedBtns = [ ...selectedBtns ];

		newbtns.forEach((btn: Ibuttons) =>
		{
			if (!value.includes(btn.word))
			{
				btn.active = false;
			}
		});
		const data = arrSelectedBtns.filter(item => value.includes(item.word));

		localStorage.setItem("mission-five-data-afternoon-active-people", JSON.stringify(data));
		setSelectedBtns(data);
		setBtns(newbtns);
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				flexDirection: "column",
				display: "flex",
				width: "100%",
				maxWidth: { xl: "980px", xs: "784px" },
				minWidth: { xl: "906px", xs: "784px" },
				height: "calc(100vh - 303px)",
				margin: { xl: "auto", xs: "0 auto" },
				marginTop: { xl: "160px", xs: "97px" }
			}}>

			<Box sx={{
				display: "flex",
				alignItems: "center"
			}}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: { xl: "80px", xs: "64px" },
						height: { xl: "80px", xs: "64px" },
						border: "2px solid #FFFFFF",
						borderRadius: "50%",
						background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
						backdropFilter: "blur(200px)",
						marginRight: "30px",
						boxShadow: "3px 3px 10px rgb(28 130 170 / 66%)"
					}}
				>
					<SVGRadial />
				</Box>
				<Typography sx={{ color: "#215C75", fontFamily: "Lora", fontSize: { xl: "40px", xs: "24px" }, fontWeight: "700", lineHeight: { xl: "51px", xs: "31px" }, textAlign: "left", wordBreak: "break-all", maxHeight: "102px" }}>
					{/* People */}
					<ContentAdmin limit={111} keyContent="contentMissionFive" keyText="mission_5_people" defaultValue="People" position="right" />
				</Typography>
			</Box>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "space-between"
			}}>
				<Box
					component="form"
					autoComplete="off"
					onSubmit={formik.handleSubmit}
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						maxHeight: { xl: "520px", xs: "395px" },
						justifyContent: "space-between"
					}}>
					<Box sx={{
						display: "flex",
						width: "calc(100% - 75px)",
						height: "auto",
						m: "0 auto",
						marginLeft: { xl: "75px", xs: "68px" },
						border: "1px solid rgba(255, 255, 255, .6)",
						borderRadius: "24px",
						overflow: "hidden",
						wordBreak: "break-all",
						flexDirection: "column",
						background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
						backdropFilter: "blur(25px)",
						boxShadow: "0px 5px 10px 0px #A7C7E199"
					}}>
						<Box sx={{ padding: { xl: "24px", xs: "16px" } }}>
							<Box>
								<Typography sx={{ marginLeft: "16px", marginBottom: "10px", color: "#215C75", fontFamily: "Open Sans", fontSize: { xl: "22px", xs: "18px" }, fontWeight: "700", lineHeight: { xl: "30px", xs: "24px" }, textAlign: "left", maxHeight: "60px", overflow: "hidden" }}>
									{/* Who do you interact with during the day? */}
									<ContentAdmin limit={175} keyContent="contentMissionFive" keyText="clock_afternoon_during_day" defaultValue="Who do you interact with during the day?" position="left" />
								</Typography>
								<TextField
									inputProps={{ maxLength: 80 }}
									className="textField"
									value={formik.values.interactDay}
									onChange={(e) => handleChange(e.target.value)}
									id="interactDay"
									fullWidth
									name="interactDay"
									InputProps={{
										startAdornment: <InputAdornment position="start">I interact with</InputAdornment>
									}}
									placeholder="my clients, my boyfriend, my pet"
									helperText={`${ formik.values.interactDay.length }/80`}
								/>
								{/* <Typography sx={{ color: "red", marginLeft: '60px' }}>
									{formik.touched.interactDay && formik.errors.interactDay}
								</Typography> */}
							</Box>

							<Box sx={{ margin: "-5px -10px -4px -10px", width: "calc(100% + 20px)", display: "flex", flexWrap: "wrap", justifyContent: { xs: "center", md: "space-between" } }}>
								{/* <GroupButtons /> */}
								{btns.map((item, index) => (
									<Box key={index} sx={{ margin: "10px" }}>
										<LightTooltip title={item.word} placement="bottom">
											<ButtonStyled
												// disabled={item.active}
												sx={{
													width: { xl: "116px", xs: "86px" },
													height: { xl: "116px", xs: "86px" },
													border: "none",
													borderRadius: "50%",
													background: item.active ? "rgba(62, 128, 157, 0.5)" : "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%);",
													backdropFilter: "blur(15px)",
													boxShadow: item.active ? "0px 3px 6px rgba(0, 0, 0, 0.161)" : "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)",
													"&:hover": {
														background: "rgba(62, 128, 157, 0.3)",
														boxShadow: "3px 3px 7px rgba(40, 63, 73, 0.24), -3px -3px 7px rgba(255, 255, 255, 0.8)",
														cursor: `url(${ cursorCustom }), auto`
													}
												}}
												onClick={() =>
												{
													handleClick(item);
												}}
											>
												<Box sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
													transform: item.id === 1 ? "translate(2px, 4px)" : item.id === 5 ? "translate(2px, 4px)" : "",
													width: { xl: "79px", xs: "54px" },
													height: { xl: item.id === 0 ? "87px" : item.id === 1 ? "75px" : item.id === 3 ? "72px" : "65px", xs: item.id === 0 ? "65px" : item.id === 1 ? "59px" : item.id === 3 ? "56px" : item.id === 4 ? "51px" : "55px" }
												}}>
													{item.icon}
												</Box>
											</ButtonStyled>
										</LightTooltip>
									</Box>
								))}
							</Box>
						</Box>
					</Box>
					<CustomButton.Contained
						disabled={!formik.values.interactDay}
						sx={{
							margin: { xl: "30px auto 30px auto", xs: "auto auto 0" },
							padding: { xl: "7px 43px", xs: "7.5px 80px" },
							display: "flex",
							fontSize: "24px",
							lineHeight: "33px",
							background: "#3E809D",
							borderColor: "#3E809D"
						}}
						onClick={() =>
						{

							formik.handleSubmit();
						}}>
						Submit
					</CustomButton.Contained>
				</Box>
			</Box>
		</Box>
	);
});

export default People;
