import { Box, Fade } from "@mui/material";
import Api from "api";
import ArrowButton from "components/ArrowButton";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import useMission from "pages/missionTwo/hooks/useMission";
import { useState } from "react";
import { useLocalStorage, useReadLocalStorage } from "utils/localStorage";
import LifePeaksButtons from "../LifePeaks/Buttons";
import { ListOfValuesData } from "../ListOfValues";
import WhatMakesProudButtons, { WhatMakesProudData } from "./Buttons";
import WhatMakesProudTemplate from "./Template";

function WhatMakesProud()
{
	const missionContext = useMission();
	const whatMakesProud = useReadLocalStorage<WhatMakesProudData>(missionContext.missionPrefix + "-whatMakesProud", "");

	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues", []);
	const [ _newListOfValues, setNewListOfValues ] = useLocalStorage<ListOfValuesData[]>(missionContext.missionPrefix + "-listOfValues-new", []);
	const [ loading, setLoading ] = useState<boolean>(false);

	// Handles
	function nextStep()
	{
		let attempt: number = 3;

		fetchRequest();

		function fetchRequest()
		{
			setLoading(true);

			Api.bot
				.getResponseBot(
					"Define in max 5 nouns, with no additional text, the personal values of this person based on what they are proud of " + whatMakesProud.trim(),
					"personBased"
				)
				.then(({ personBased }) =>
				{
					const newValues =
						personBased
							.map((value) => value.toLowerCase())
							.filter((value) => listOfValues.includes(value) === false);

					setNewListOfValues((prevState) =>
					{
						return [ ...prevState, ...newValues.slice(0, 3) ];
					});

					setTimeout(() => missionContext.nextStep(), 0);
				})
				.catch((error) =>
				{
					if (attempt > 0)
					{
						attempt -= 1;
						fetchRequest();
					}

					console.error(error);
				})
				.finally(() => setLoading(false));
		}
	}

	// Render
	return (
		<>
			<WhatMakesProudTemplate>
				<Fade in={missionContext.showContent}>
					<Box sx={{ position: "relative", width: "100%" }}>
						<LifePeaksButtons preview />
						<WhatMakesProudButtons />
					</Box>
				</Fade>
			</WhatMakesProudTemplate>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					loading={loading}
					disabled={whatMakesProud.trim().length === 0}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</>
	);
}

export default WhatMakesProud;
