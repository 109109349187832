import { alpha, Box, SxProps, Theme, useMediaQuery } from "@mui/material";
import { ExoticComponent, forwardRef, MouseEvent, Ref } from "react";
import IMGGuideChara from "../../../assets/images/octa2.png";
import CustomButton from "../../customButton";

export interface ICelebrationScreenProps
{
	content: string | JSX.Element;
	buttonText: string | JSX.Element;
	onClickButton(event: MouseEvent<HTMLButtonElement>): void;
	/**
	 * @default false
	 */
	buttonIsLoading?: boolean;
	/**
	 * @default Contained
	 */
	buttonStyle?: keyof typeof CustomButton;
	sx?: SxProps<Theme>;
	[ prop: string ]: any;
}

function CelebrationScreen(
	{
		content,
		buttonText,
		onClickButton,
		buttonIsLoading = false,
		buttonStyle = "Contained",
		sx = [],
		...props
	}: ICelebrationScreenProps,
	ref: Ref<HTMLDivElement>
)
{
	const Button = CustomButton[ buttonStyle ];

	return (
		<Box
			className="CelebrationScreen-root"
			ref={ref}
			sx={[
				{
					flexGrow: 1,
					width: { xl: "844px", lg: "610px", xs: "100%" },
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: { sm: "95px", xs: "12px" },
					marginLeft: "auto",
					marginRight: "auto",
					color: "#FFFFFF",

					"& .CelebrationScreen-image":
					{
						height: { xl: "359px", lg: "305px", xs: "318px" }
					},
					"& .CelebrationScreen-content":
					{
						margin: "40px 0",
						color: "inherit",
						font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						textAlign: "center",
						textShadow: "0px 3px 6px " + alpha("#000000", 0.161)
					},
					"& .CelebrationScreen-button":
					{
						marginTop: { sm: "unset", xs: "auto" },
						width: { sm: "unset", xs: "100%" }
					}
				},
				(theme) =>
				{
					return useMediaQuery(theme.breakpoints.down("lg")) &&
					{
						"&.CelebrationScreen-root":
						{
							gap: "52px"
						},
						"& .CelebrationScreen-content":
						{
							margin: "54px 0 0 0",
							order: -1
						},
						"& .CelebrationScreen-buttonWrapper":
						{
							marginTop: "auto",
							width: "100%",
							minHeight: "120px"
						}
					};
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<img
				className="CelebrationScreen-image"
				src={IMGGuideChara}
				alt=""
			/>

			<div className="CelebrationScreen-content">
				{content}
			</div>

			<div className="CelebrationScreen-buttonWrapper">
				<Button className="CelebrationScreen-button" onClick={onClickButton} loading={buttonIsLoading}>
					{buttonText}
				</Button>
			</div>
		</Box>
	);
}

export default forwardRef(CelebrationScreen) as ExoticComponent<ICelebrationScreenProps>;
