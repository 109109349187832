import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Home()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-home-reflection"
					defaultValue="- Is your home comfortable? \n- Do you like the area in which you live?\n- How can you fill your life to fill it with joy?\n- Does the climate where you live suit you?\n- Does your body feel good where you are?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-home-actionPlan"
					defaultValue="1. **Create comfort in your environment.** Make your home more cosy, buy comfortable furniture, clean up and organise.\n1. **Fill your life with joy.** Think of all the things that bring you joy daily and fill your life with small pleasures.\n1. **Consider a move.** It is important your environment supports your desired lifestyle. If you home, neighbourhood, country aren’t meeting your needs, it may be a time for a change."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-home-howCanHelp"
					defaultValue="My Life Quest will help you find out what matters to you in your immediate surroundings and design your life around it."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/mJpJZdSiuO8?si=qy7UGQiglga8qe11"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Home;
