import { Box, Slide, SlideProps, Snackbar } from "@mui/material";
import AlertStyled from "components/AlertStyled";
import { Ref, forwardRef, useLayoutEffect, useState } from "react";
import ArrowButton from "../../../../components/ArrowButton";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";
import * as SVGIcons from "./assets/icons";
import ImageButton, { Colors } from "./components/ImageButton";

export type IWhatAreYourFamilyBeliefsSelectProps = Record<string, any>;

export type FamilySelectType = Record<"Mom" | "Dad" | "Brother" | "Sister" | "Grandma" | "Grandpa" | "Uncle" | "Aunt", boolean>;

function WhatAreYourFamilyBeliefsSelect({ ...props }: IWhatAreYourFamilyBeliefsSelectProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ isShowAlert, setIsShowAlert ] = useState<boolean>(false);
	const [ family, setFamily ] = useState<FamilySelectType>(() =>
	{
		const family = getLocalStorage<FamilySelectType>("mission-seven-family-select");

		return (family !== null
			? family
			: {
				Mom: false,
				Dad: false,
				Brother: false,
				Sister: false,
				Grandma: false,
				Grandpa: false,
				Uncle: false,
				Aunt: false
			});
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage("mission-seven-family-select", family);
	}, [ family ]);

	// Handles
	function onToggleSelect(text: keyof FamilySelectType)
	{
		setFamily((family) =>
		{
			return Object.assign({}, family, { [ text ]: !family[ text ] });
		});
	}

	function nextStep()
	{
		if (Object.values(family).some((isSelected) => isSelected === true) === false)
		{
			return setIsShowAlert(true);
		}

		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center", flexGrow: 1, gap: "40px !important", position: "relative", marginTop: "-13px" }}>
				<PanelTitle
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefs_title" defaultValue="What are your family beliefs?" position="left" />}
					subTitle={<ContentAdmin keyContent="contentMissionSeven" keyText="WhatAreYourFamilyBeliefs_subTitle" defaultValue="Write down what your hear from your family a lot.\nEach family member can have more than one belief." position="left" isDescription />}
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{ font: "700 24px/31px Lora-Bold", color: "primary.main", margin: { xl: "50px 0", lg: "40px 0", xs: "40px 0" } }}>
					Who is your family?
				</Box>

				<Box sx={{
					width: { xl: "787px", lg: "612px", xs: "612px" },
					display: "grid",
					gridTemplateColumns: "repeat(4, max-content)",
					gap: { xl: "54px", lg: "42px 54px", xs: "42px 54px" },
					justifyContent: "space-between"
				}}>
					{Object.entries(family).map(([ text, selected ]: [ string, boolean ]) => (
						<ImageButton
							key={text}
							SVGImage={SVGIcons[ text as keyof FamilySelectType ]}
							text={text}
							isSelected={selected}
							onClick={() => onToggleSelect(text as keyof FamilySelectType)}
							sx={{
								"--size": { xl: "116px", lg: "90px", xs: "90px" },

								"&:hover  .ImageButton-image":
								{
									filter: "drop-shadow(0px 0px 10px #FFE8B9)",

									"& path":
									{
										fill: Colors.SELECTED
									}
								},
								"& .ImageButton-text":
								{
									padding: { xl: "8px 32px", lg: "4px 16px", xs: "4px 16px" }
								}
							}}
						/>
					))}
				</Box>

				<Snackbar
					open={isShowAlert}
					autoHideDuration={5000}
					onClose={() => setIsShowAlert(false)}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					sx={{ "&.MuiSnackbar-root": { bottom: "140px", right: "35px" } }}
					TransitionComponent={TransitionLeft}
				>
					<AlertStyled onClose={() => setIsShowAlert(false)} severity="warning">
						You must choose at least one family member
					</AlertStyled>
				</Snackbar>
			</Box>
			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={nextStep} />
			</StepperNavigationWrapper>
		</Box>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default forwardRef(WhatAreYourFamilyBeliefsSelect);
