import { Box, Fade } from "@mui/material";
import useMission from "pages/missionTwo/hooks/useMission";
import LifePeaksForm from "./Form";
import LifePeaksTemplate from "./Template";

export interface ILifePeaksFormViewProps
{
	readonly index: 0 | 1 | 2;
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

function LifePeaksFormView({ index, preview = false }: ILifePeaksFormViewProps)
{
	const missionContext = useMission();

	return (
		<LifePeaksTemplate>
			<Fade in={missionContext.showContent}>
				<Box sx={{ position: "relative", width: "100%" }}>
					<LifePeaksForm index={index} preview={preview} />
				</Box>
			</Fade>
		</LifePeaksTemplate>
	);
}

export default LifePeaksFormView;
