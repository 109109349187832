import { Box, alpha } from "@mui/material";
import useRegistration from "pages/registration/hooks/useRegistration";
import { useEffect, useRef, useState } from "react";
import IMGProgress54 from "./assets/images/54.png";
import IMGProgress76 from "./assets/images/76.png";
import IMGProgress96 from "./assets/images/96.png";

const SECONDS_PER_STEP: number = 4;
const STEP_COUNT: number = 3;

function PersonalReport()
{
	const { setStep: registrationSetStep } = useRegistration();
	const [ step, setStep ] = useState(0);
	const stepRef = useRef(step);

	// Effects
	useEffect(() =>
	{
		stepRef.current = step;
	}, [ step ]);

	useEffect(() => setStep(1), []);

	useEffect(() =>
	{
		const timer = setInterval(() =>
		{
			if (stepRef.current >= STEP_COUNT)
			{
				clearInterval(timer);
				registrationSetStep(prevStep => prevStep + 1);

				return;
			}

			setStep(prevState => prevState + 1);
		}, SECONDS_PER_STEP * 1000);

		return () => clearInterval(timer);
	}, []);

	return (
		<Box sx={{
			padding: { xl: "98px 24px 24px 24px", xs: "56px 24px 24px 24px" }
		}}>
			<Box sx={{
				textAlign: "center",
				font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "primary.main"
			}}>
				Creating your personalised report
			</Box>

			<Box sx={{
				margin: "35px auto 0 auto",
				padding: "2px 3px",
				width: { xs: "288px", xl: "392px" },
				height: { xs: "18px", xl: "20px" },
				border: "2px solid #3E809D",
				borderRadius: "50px",
				display: "flex",
				alignItems: "center"
			}}>
				<Box sx={{
					background: "linear-gradient(240deg, #215C75 3.49%, rgba(255, 255, 255, 0.00) 140.05%)",
					borderRadius: "50px",
					height: "100%",
					width: ((step * 100) / STEP_COUNT) + "%",
					transition: SECONDS_PER_STEP + "s linear width"
				}} />
			</Box>

			<Box sx={{
				padding: { xl: "36px 28px 36px 54px", md: "32px", xs: "16px 16px 32px 16px" },
				margin: "56px auto 0 auto",
				width: { xl: "816px", md: "474px", xs: "240px" },

				backgroundColor: alpha("#FFFFFF", 0.3),
				border: "1px solid " + alpha("#FFFFFF", 0.5),
				borderRadius: "24px",

				display: "flex",
				alignItems: "center",
				flexDirection: { md: "row", xs: "column" },
				gap: "16px 28px"
			}}>
				<Box sx={{
					position: "relative",

					"& img":
					{
						"--size": { xl: "243px", xs: "166px" },
						transform: "rotate(12.47deg)",
						width: "var(--size)",
						height: "var(--size)"
					}
				}}>
					<img
						src={step < 2 ? IMGProgress96 : step === 2 ? IMGProgress76 : IMGProgress54}
						alt=""
					/>

					<Box sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -55%)",

						font: { xl: "700 80px 'Open Sans'", xs: "700 48px 'Open Sans'" },
						color: "primary.main",

						"& span":
						{
							fontSize: { xl: "48px", xs: "32px" }
						}
					}}>
						{step < 2 ? 96 : step === 2 ? 76 : 54}<span>%</span>
					</Box>
				</Box>

				<Box sx={{
					font: { xl: "400 24px/33px 'Open sans'", xs: "400 16px/22px 'Open sans'" },
					color: "primary.main",
					textAlign: { md: "left", xs: "center" }
				}}>
					{step < 2
						? <>96% have experienced a profound transformation thanks to My Life Quest</>
						: step === 2
							? <>76% report an increased level<br />of happiness</>
							: <>54% of people made profound change<br />in their life</>
					}
				</Box>
			</Box>
		</Box>
	);
}

export default PersonalReport;
