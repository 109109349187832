import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import bgImgInner from "assets/images/missions/mission-ten/bgImgInner.png";
import ContentAdmin from "components/contentAdmin";

export interface IMission2Props
{
	isOpen?: boolean;

	closeModal?(): void;
	imgUrl?: string;
	title?: string;

}

function modalMissionSix({ isOpen = false, closeModal, imgUrl, title }: IMission2Props)
{

	// const [ data, setData ] = useState<string[]>([]);
	// const [ isErrorLoading, setIsErrorLoading ] = useState<boolean>(false);

	// Handles
	// useEffect(() =>
	// {
	// 	Api.users
	// 		.topValuesUser()
	// 		.then((values) =>
	// 		{
	// 			setData(values.slice(0, 10));
	// 		});
	// }, []);

	// Render
	return (
		<Box sx={{
			display: "flex",
			maxHeight: "392px",
			position: "relative",
			backgroundImage: `url(${ bgImgInner })`,
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))",
			borderRadius: "24px",
			borderTopLeftRadius: "75px",
			borderBottomLeftRadius: "75px"
		}}>
			<CancelIcon
				onClick={closeModal}
				sx={{
					position: "absolute",
					top: "24px",
					right: "24px",
					fill: "#3E809D",
					"&:hover": { cursor: "pointer" }
				}}
			/>
			<Box
				component="img"
				src={imgUrl}
				alt="side image"
				className="ImageSideModal-image"
				sx={{
					width: "140px",
					height: "392px",
					borderRadius: "100px",
					filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))"
				}}
			/>

			<Box sx={{ width: "100%", padding: "24px 24px 30px 40px" }}>

				<Typography sx={{ font: { lg: "700 24px/40px Lora", xl: "700 40px/51px Lora" }, color: "#215C75", mb: "12px" }}>
					{title}
				</Typography>
				<Box className="customScroll" sx={{ overflowY: "auto", height: "270px", paddingRight: "8px" }}>
					<Typography sx={{ font: { lg: '400 18px/27px "Open Sans"', xl: '400 24px/33px "Open Sans"' }, color: "#4D4D4D", mb: "12px" }}>
						<ContentAdmin
							keyContent="contentMissionTen"
							keyText="contentMissionSix_txt"
							defaultValue="It will take time to run all of your experiments. It is best to keep all your insights in one place. You can also add new experiments and track them too.\nDream life is never a finished product. You will change, your yearnings, dreams and priorities will evolve and new uncertainty will emerge. Use this tool to get clarity at any point in your life when you feel lost again."
							position="left"
							isDescription
						/>
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default modalMissionSix;
