import { yupResolver } from "@hookform/resolvers/yup";
import type { RecordStringAny } from "types";
import { number, object, setLocale, string, type ObjectSchema } from "yup";
import type { AnyObject } from "yup/lib/types";

const yupMessage = {
	mixed: {
		default: "Is invalid",
		required: "Is a required field",
		defined: "Must be defined",
		notNull: "Cannot be null",
		oneOf: "Must be one of the following values: ${values}",
		notOneOf: "Must not be one of the following values: ${values}"
	},
	string: {
		length: "Must be exactly ${length} characters",
		min: "Must be at least ${min} characters",
		max: "Must be at most ${max} characters",
		matches: 'Must match the following: "${regex}"',
		email: "Must be a valid email",
		url: "Must be a valid URL",
		uuid: "Must be a valid UUID",
		trim: "Must be a trimmed string",
		lowercase: "Must be a lowercase string",
		uppercase: "Must be a upper case string"
	},
	number: {
		min: "Must be greater than or equal to ${min}",
		max: "Must be less than or equal to ${max}",
		lessThan: "Must be less than ${less}",
		moreThan: "Must be greater than ${more}",
		positive: "Must be a positive number",
		negative: "Must be a negative number",
		integer: "Must be an integer"
	},
	date: {
		min: "Must be later than ${min}",
		max: "Must be at earlier than ${max}"
	},
	boolean: {
		isValue: "Must be ${value}"
	},
	object: {
		noUnknown: "Has unspecified keys: ${unknown}"
	},
	array: {
		min: "Must have at least ${min} items",
		max: "Must have less than or equal to ${max} items",
		length: "Must have ${length} items"
	}
};

export function useSchema(
	schema: (params?: RecordStringAny) => ObjectSchema<AnyObject>,
	params?: RecordStringAny
)
{
	setLocale(yupMessage);
	const resolver = schema(params);

	return yupResolver(resolver);
}

export function getCreateCoupon(): ObjectSchema<AnyObject>
{
	return object().shape({
		id: string().required(),
		name: string().required(),
		percentOff: number().min(1).max(100)
			.required()
	});
}
