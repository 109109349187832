import { Box, Typography } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import TooltipStyled from "components/TooltipStyled";
import GetHelpButton from "components/missions/GetHelpButton";
import { ReactNode, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import missionEightDefualt from "../../assets/images/missions/mission-eight/missionEightDefualt.png";
import missionEightYourPlan from "../../assets/images/missions/mission-eight/missionEightYourPlan.png";
import MissionProgressBar from "../../components/MissionProgressBar";
import MobileContent from "../../components/MobileContent";
import TipsButton from "../../components/TipsButton";
import ContentAdmin from "../../components/contentAdmin";
import MissionsDialog from "../../components/missions/Dialog";
import useUser from "../../hooks/useUser";
import { setLocalStorage } from "../../utils/localStorage";
import { IMissionContextEightMission, MissionCtx } from "./MissionCtx";
import ChatDoAnywayMissionEight from "./feature/ChatDoAnywayMissionEight";
import ChatPageMissionEight from "./feature/ChatPageMissionEight";
import Complete from "./feature/Complete";
import ExcellentJob from "./feature/ExcellentJob";
import FinishReasonsAvoid from "./feature/FinishReasonsAvoid";
import ToFaceFears from "./feature/ToFaceFears";
import YourPlanFinish from "./feature/YourPlanFinish";
import FaceToFears from "./feature/faceToFears";
import FailOrFly from "./feature/failOrFly/FailOrFly";
import Fears from "./feature/fears";
import Feedback from "./feature/feedback";
import ReasonsAvoid from "./feature/reasonsAvoid/ReasonsAvoid";
import YourPlan from "./feature/yourPlan/YourPlan";

export type BackgroundType = Record<"reasonsAvoid" | "yourPlan", string>;

export const Background: BackgroundType =
{
	reasonsAvoid: `url(${ missionEightDefualt }) center center/cover`,
	yourPlan: `url(${ missionEightYourPlan }) center center/cover`
};

const BackgroundPreload = [
	missionEightDefualt,
	missionEightYourPlan
];

const Components = [
	ChatPageMissionEight,
	ReasonsAvoid,
	FinishReasonsAvoid,
	FailOrFly,
	ChatDoAnywayMissionEight,
	Fears,
	ExcellentJob,
	FaceToFears,
	ToFaceFears,
	YourPlan,
	YourPlanFinish,
	Complete,
	Feedback
];

const STEPS = 5;

export const PROGRESS_STEP = 100 / STEPS;
function MissionEightContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ step, setStep ] = useState<number>(() =>
	{
		const storageStep = localStorage.getItem("mission-eight-step");
		if (storageStep !== null)
		{
			return Number(storageStep);
		}

		return 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("reasonsAvoid");
	const [ name, setName ] = useState<string>("");
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: ReactNode; position?: "bottom" | "center"; } | null>(null);

	const MissionTwoContext: IMissionContextEightMission = useMemo(() =>
	{
		return {
			step,
			setStep,
			progress,
			setProgress,
			background,
			setBackground,
			name,
			setName
		};
	}, [ background, name, progress, step ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		if (userContext.state.user === null)
		{
			return;
		}

		Api.users
			.getUser(userContext.state.user.id)
			.then((user) =>
			{
				setName(user.name);
			});
	}, [ userContext.state.user, userContext.state.user?.id ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(8, step).catch((error) => console.error(error));

		setLocalStorage("mission-eight-step", step);
		setProgress((step - 1) * (100 / (Components.length - 1)));
	}, [ step ]);

	useLayoutEffect(() =>
	{
		// Change background
		setBackground(step < 10 ? "reasonsAvoid" : "yourPlan");

		// Change tips content
		setIsBlipTips(true);

		switch (step)
		{
			case 6: { // screen 6 ( Write down all your fears )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<Typography sx={{ color: "#4D4D4D", font: "400 22px/30px Open Sans" }}>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionEight"
									keyText="tips_step_6"
									defaultValue="**Why:** Writing down all your fears is the first step to facing your fears. Once you get them out of your system, you can work through each one methodically until none of your fears are holding you back from pursuing your dreams.\n\n**Do:** Think about all the different thing that worry you and write them down. Define between 3 and 12 fears to keep your list manageable.\n\n**Think:** You can think of different aspects of changing your life: practical, psychological, financial, etc. For example, you may be worrid you won’t succeed or that you will run out of money. Write it all down."
									position="right"
									isDescription
								/>
							</Typography>
						)
					}
				);
				break;
			}

			case 8: { // screen 8 ( Face your fears )
				setTipsData(
					{
						title: "Instructions",
						content: (
							<p>
								<ContentAdmin
									limit={1000}
									keyContent="contentMissionEight"
									keyText="tips_step_8"
									defaultValue="**Why:** Unless your fears are very specifically defined, you cannot overcome them. Defining your fears and a specific action plan will help you feel in control. You might even realise that it all is not so scary after all.\n\n**Do:** Face your fears in 3 steps:\n 1. Define your fear by specifying exactly what it is that scares you the most. Think about each fear asking yourself ‘So what?’ to help you get to the root cause of your fear. Once you get to the bottom of it, write your defined fear down.\n 2. Write down specific actions that you can take now to prevent each one of the things on your list from happening.\n 3. Write down the things you can do to fix it in case the worst case scenario materialises.\n\n**Think:** Imagine the worst case scenario that could occur as the result of your decision. What often happens, is the fear is not as scary as you thought it would be once you face it. Now imagine it happened. What would you do?"
									position="right"
									isDescription
								/>
							</p>
						)
					}
				);
				break;
			}

			default: {
				setTipsData(null);
				break;
			}
		}
	}, [ step ]);

	useEffect(() =>
	{
		localStorage.setItem("mission-eight-progress", String(progress));
	}, [ progress ]);

	// Handles
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<Box
				sx={{
					width: "100%",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					zIndex: "10",
					transition: "linear 300ms background",
					overflow: "hidden",
					backgroundPosition: "50% 60%"
				}}
			>
				<Box sx={{
					minHeight: "100vh",
					width: "100%",
					padding: { xl: step === 12 || step === 13 ? 0 : "24px", lg: step === 12 || step === 13 ? 0 : "16px", xs: step === 12 || step === 13 ? 0 : "16px" },
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: (step === 1 ? "0px" : "25px")
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 12 || step === 13 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							display: step === 12 || step === 13 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "10px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px"
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={Components.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {Components.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>

							{(step === 6 || step === 8) &&
								<TooltipStyled title="Tips" placement="bottom" arrow>
									<TipsButton
										isActive={isBlipTips}
										blip={false}
										onClick={() => { setIsShowTips(true); setIsBlipTips(false); }}

									/>
								</TooltipStyled>
							}
							<MissionProgressBar value={progress} />
						</Box>
					</Box>

					<MissionCtx.Provider value={MissionTwoContext}>
						<Box
							className="mission-root"
							sx={{
								width: "100%",
								flexGrow: 1,

								"& .TransitionGroup":
								{
									flexGrow: 1,
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									width: "100%"
								},
								"& .mission-main":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								},
								"& .main__content":
								{
									maxWidth: "980px",
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									gap: "20px"
								}
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								{Components.map((Component, index) =>
								{
									if (step !== index + 1)
									{
										return false;
									}

									return <Component key={index} />;
								})}
							</Box>
						</Box>

						{/* Tips content */}
						{tipsData !== null &&
							(
								<MissionsDialog
									sx={{
										marginTop: "90px",
										"& .MuiPaper-root": {
											color: "#4D4D4D",
											maxWidth: "985px",
											maxHeight: (step === 8 ? "635px" : (step === 6 ? "460px" : "auto")),
											padding: "56px",
											"& p": {
												m: "0px 0px 25px 0px"
											}
										},
										"& .MuiDialog-container": {
											position: "relative",
											top: (step === 8 ? "80px" : (step === 6 ? "-5px" : "0px")),
											left: "0px",
											right: "auto"
										}
									}}
									sxCancelIcon={{
										fill: "#3E809D",
										opacity: "1",
										top: "30px",
										right: "30px"
									}}
									title={tipsData.title}
									open={isShowTips}
									onClose={() => setIsShowTips(false)}
									position={tipsData.position ?? "center"}
								>
									{tipsData.content}
								</MissionsDialog>
							)
						}
					</MissionCtx.Provider>
				</Box>
			</Box>
			<MobileContent />
		</>
	);
}

export default MissionEightContainer;
