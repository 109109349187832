function getSearchParamsValue(searchParams: URLSearchParams, name: string, type: "string"): string | null;
function getSearchParamsValue(searchParams: URLSearchParams, name: string, type: "number"): number | null;
function getSearchParamsValue(searchParams: URLSearchParams, name: string, type: "string" | "number"): string | number | null
{
	const value = searchParams.get(name);

	return value === null
		? null
		: (type === "number")
			? Number(value)
			: value;
}

export default getSearchParamsValue;
