import { Box } from "@mui/material";
import Api from "api";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { getMissionNameById } from "pages/dashboard/missions/missionsData";
import useMission from "pages/missionTwo/hooks/useMission";
import IMGCelebrationOcta from "pages/registration/assets/images/celebration-octa.png";
import { useState } from "react";
import Confetti from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import { useReadLocalStorage } from "utils/localStorage";
import trackEvent from "utils/mixpanelUtil";
import { ListOfValuesData } from "../ListOfValues";
import { PracticalRecommendationsType } from "../PracticalRecommendations";

function Complete()
{
	const missionContext = useMission();
	const navigate = useNavigate();
	const { state: userState, dispatch: userDispatch } = useUser();

	const listOfValues = useReadLocalStorage<ListOfValuesData[]>(
		missionContext.missionPrefix + "-listOfValues",
		[]
	);
	const practicalRecommendations = useReadLocalStorage<PracticalRecommendationsType[] | null>(
		missionContext.missionPrefix + "-practicalRecommendations",
		null
	);

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const clientWidth = document.documentElement.clientWidth;

	function onClickButton()
	{
		if (isFetch === true)
		{
			return;
		}

		if (practicalRecommendations === null)
		{
			return missionContext.setStep(16);
		}

		setIsFetch(true);

		Api.missions
			.setDataMissionTwo(
				{
					recommendation: practicalRecommendations,
					values: listOfValues
				}
			)
			.then(() =>
			{
				trackEvent({
					eventName: "Mission Completed",
					userId: userState.user?.id,
					properties: { Mission: getMissionNameById(2) }
				});

				userDispatch(
					{
						type: UserActionType.COMPLETED_MISSION,
						payload: { completedMission: 2 }
					}
				);
				if (userState.user?.completedMission === 1)
				{
					setTimeout(() => missionContext.nextStep(), 0);
				}
				else
				{
					setTimeout(() => missionContext.setStep(19), 0);
				}

			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	return (
		<Box sx={{
			padding: { xl: "60px 32px 32px 32px", sm: "48px 32px 32px 32px", xs: "54px 32px 0 32px" },
			flexGrow: 1,

			display: "flex",
			flexDirection: "column",
			alignItems: "center"
		}}>
			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "#FFFFFF",
				textAlign: "center"
			}}>
				Congratulations!<br />
				Another mission is complete.
			</Box>

			<Confetti
				particleCount={250}
				particleSize={clientWidth <= 700 ? 10 : 15}
				force={0.8}
				duration={5000}
				width={clientWidth}
				colors={[
					"#C1D2EF",
					"#CAEAB1",
					"#E39BBE",
					"#7D84DA",
					"#E2E49D",
					"#9DDE8A",
					"#B5A7E7",
					"#96B8E2",
					"#DF8EA7",
					"#F6E0DB"
				]}
			/>

			<Box
				component="img"
				src={IMGCelebrationOcta}
				alt=""
				sx={{
					marginTop: { xl: "40px", sm: 0, xs: "80px" },
					maxWidth: { xl: "716px", sm: "602px", xs: "390px" },
					width: "100%"
				}}
			/>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "40px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Base
					size="large"
					onClick={onClickButton}
					loading={isFetch === true}
					sx={{
						maxWidth: "390px",
						width: { sm: "auto", xs: "100%" },
						boxShadow: "unset"
					}}
				>
					Continue
				</CustomButton.Base>
			</Box>
		</Box>
	);
}

export default Complete;
