import { Box, Grid, Typography } from "@mui/material";
import Love from "../../../../assets/images/Love.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function LoveDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Love} alt="CaLovereer" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Love
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="{NAME}, All You Need Is Love, right? Well, that’s a bit of an exaggeration, of course, but love is an important aspect of life. While we are all different when it comes to love, it’s important to understand your needs and fulfil them." keyContent="contentMissionOne" keyText="loveDescScreen1" />
				{/* {localStorage.getItem('name')}, All You Need Is Love, right? Well, that’s a bit of an exaggeration, of course, but love is an important aspect of life. While we are all different when it comes to love, it’s important to understand your needs and fulfil them. */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "1px solid #707070", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Do you have or want a significant other?\n- If you have a partner, do you share values, intimacy?\n- Are you nurturing a relationship you have with each other?\n\n{NAME}, if you have a romantic partner, it’s time to take a hard look at what needs fixing in a relationship. Or maybe it’s time for a new chapter all together.\n\nIf you don’t have a significant other, but want one, what are the steps you can take to make it happen? Sign up for a group class, a meetup, join friends for dinner parties — explore every opportunity to meet someone." keyContent="contentMissionOne" keyText="loveDescStart" />
						{/*
						{localStorage.getItem('name')}, if you have a romantic partner, it’s time to take a hard look at what needs fixing in a relationship. Or maybe it’s time for a new chapter all together.<br></br><br></br>
							If you don’t have a significant other, but want one, what are the steps you can take to make it happen? Sign up for a group class, a meetup, join friends for dinner parties — explore every opportunity to meet someone.
							*/}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Do you have time and space for love in your life?\n- Have you dealt with the trauma from past relationships?\n- Do you feel in a good place as an individual to attract healthy love?" keyContent="contentMissionOne" keyText="loveDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "5px",
						height: "5px",
						borderRadius: "50%",
						backgroundColor: "#4D4D4D"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Scoring low on Love often starts with self-love. To meet someone great or build a meaningful relationship with your partner, you need to be in the right energy.\\n\\n{NAME}, you may have noticed that most people are more attracted to confident, fulfilled people living their dream life. How can you become such a person? Do you need to do more of what you love? Take better care of your health? Have more fun in life?\\n\\nLife Strategy Quest will help you find answers to all these questions and become the person who attracts love wherever you go." keyContent="contentMissionOne" keyText="loveDescScreen2" />
				{/*
				Scoring low on Love often starts with self-love. To meet someone great or build a meaningful relationship with your partner, you need to be in the right energy.<br></br><br></br>
				{localStorage.getItem('name')}, you may have noticed that most people are more attracted to confident, fulfilled people living their dream life. How can you become such a person? Do you need to do more of what you love? Take better care of your health? Have more fun in life?<br></br><br></br>
				Life Strategy Quest will help you find answers to all these questions and become the person who attracts love wherever you go.
				*/}
			</Box>

		</Box>
	);
}

export default LoveDetails;
