import { Button, SxProps, Theme } from "@mui/material";
import { MouseEvent } from "react";
import { mergeDeep } from "../../../utils/mergeDeep";
import { ReactComponent as SVGSolarSystemButton } from "./solar-system-button-icon.svg";

export interface ISolarSystemButtonProps
{

	onClick?(event: MouseEvent<HTMLButtonElement>): void;
	blip?: boolean;
	blipDurationMs?: number;
	offBlipOnClick?: boolean;
	className?: string;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	[ key: string ]: any;
}

function SolarSystemButton({
	onClick,
	blip = false,
	blipDurationMs = 3000,
	offBlipOnClick = false,
	className,
	disabled,
	sx,
	...props
}: ISolarSystemButtonProps)
{
	const classList: string[] = (typeof className === "string" ? className.split(/\s+/g) : []);
	if (blip === true)
	{
		classList.push("animation--blip-radialButton");
	}

	return (
		<Button
			{...props}
			className={classList.join(" ")}
			sx={mergeDeep(
				{
					width: "80px",
					height: "80px",
					border: "2px solid #FFFFFF",
					borderRadius: "50%",
					background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
					boxShadow: "3px 3px 10px rgba(28, 130, 170, .66)",
					backdropFilter: "blur(100px)",
					opacity: (disabled === true ? 0.5 : 1),
					"--duration": blipDurationMs + "ms",
					"& svg": { position: "absolute", top: "-4px", left: "-2px" }
				},
				sx
			)}
			onClick={(event) =>
			{
				if (offBlipOnClick === true)
				{
					blip = false;
				}

				if (onClick !== undefined)
				{
					onClick(event);
				}
			}}
		>
			<SVGSolarSystemButton />
		</Button>
	);
}

export default SolarSystemButton;
