import { Box, Menu, MenuItem, Slide, SlideProps, Snackbar, alpha } from "@mui/material";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import Api from "api";
import { ReactComponent as SVGMagicalWand } from "assets/images/icons/magical-wand.svg";
import { ReactComponent as SVGZoomIn } from "assets/images/icons/zoom-in.svg";
import { ReactComponent as SVGZoomOut } from "assets/images/icons/zoom-out.svg";
import AlertStyled from "components/AlertStyled";
import ChipStyled from "components/ChipStyled";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import { COLORS, IBoard, IBoardItem } from "pages/missionThree/components/ClusterYourYearnings";
import { CURSORS, ColorSquare, MENU_ITEMS } from "pages/missionThree/components/TimeToPriorities";
import { MouseEvent, SyntheticEvent, useEffect, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";

export interface ITimeToPrioritiesProps
{
	nextStep(): void;
}

function TimeToPriorities({ nextStep }: ITimeToPrioritiesProps)
{
	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ isMove, setIsMove ] = useState<boolean>(false);
	const [ color, setColor ] = useState<keyof typeof COLORS>("red");
	const [ anchorElement, setAnchorElement ] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorElement);
	const [ isOpenSnackbar, setIsOpenSnackBar ] = useState<boolean>(false);
	const [ isCursorHand, setIsCursorHand ] = useState<boolean>(false);
	const [ boards, setBoards ] = useState<IBoard[]>(() =>
	{
		const clusters = getLocalStorage<IBoard[]>("dashboard-create-yearning-cluster");
		if (clusters !== null)
		{
			return clusters;
		}

		let values = getLocalStorage<string[]>("dashboard-create-yearning-list");
		if (values === null)
		{
			values = [];
		}

		const boards: IBoard[] = [
			{
				id: "__values__",
				title: "firstColumn",
				items: values.map((item, index): IBoardItem => ({ id: index, title: item, color: "none" }))
			}
		];

		return boards;
	});

	const cursor = (isMove === true ?
		"grabbing" :
		(isCursorHand ? `url(${ CURSORS.grab }), grab` : `url(${ CURSORS[ color ] }), pointer`)
	);

	// Effects
	useEffect(() =>
	{
		setLocalStorage("dashboard-create-yearning-cluster", boards);
	}, [ boards ]);

	// Utils
	function searchBoardIndex(boardId: IBoard[ "id" ]): number
	{
		return boards.findIndex((board) => board.id === boardId);
	}

	// Handles
	function onClickNextStep()
	{
		if (isFetch === true)
		{
			return;
		}

		if (!boards.some((board) => board.items.filter((boardItem) => boardItem.color === "red").length > 0))
		{
			return setIsOpenSnackBar(true);
		}

		setIsFetch(true);

		Api.missions
			.setDataMissionThree(
				{
					clusters: boards
						.filter(({ id }) => id !== "__values__")
						.map(({ items, title }) =>
						{
							return {
								title,
								items: items.map((boardItem) =>
								{
									return { title: boardItem.title, color: boardItem.color };
								})
							};
						})
				},
				true
			)
			.then(() =>
			{
				nextStep();
			})
			.catch((error) => console.error(error))
			.finally(() => setIsFetch(false));
	}

	function onCloseSnackbar(_event: SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setIsOpenSnackBar(false);
	}

	// Menu
	function openMenuHandler({ currentTarget }: MouseEvent<HTMLElement>)
	{
		setAnchorElement(currentTarget);
	}

	function closeMenuHandler()
	{
		setAnchorElement(null);
	}

	function onSelectMenuItem(color: keyof typeof COLORS)
	{
		closeMenuHandler();

		setIsCursorHand(false);
		setColor(color);
	}

	function itemChangeColor(boardIndex: number, boardItem: IBoardItem): void
	{
		if (isCursorHand)
		{
			return;
		}

		setBoards((prevBoards) =>
		{
			const boards = deepClone(prevBoards);

			const boardItemIndex = boards[ boardIndex ].items.findIndex((item) => item.id === boardItem.id);

			boards[ boardIndex ].items[ boardItemIndex ].color = color;

			return boards;
		});
	}

	// Render
	return (
		<>
			<Box sx={{
				width: { xl: "980px", lg: "938px", xs: "980px" },
				height: "100%",
				maxHeight: { xl: "596px", lg: "477px", xs: "596px" },
				margin: { xl: "8px auto 0 auto", lg: "0px auto 0 auto", xs: "8px auto 0 auto" },
				display: "flex",
				gap: "10px",
				backdropFilter: "blur(15px)",
				borderRadius: "24px",
				background: "linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1))",
				border: "1px solid " + alpha("#FFFFFF", 0.6),
				padding: "30px 30px 10px 30px",
				boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
				cursor: cursor,

				// Chips
				"& .MuiChip-root":
				{
					height: "unset",
					whiteSpace: "normal"
				},
				"& .MuiChip-label":
				{
					color: "#FFFFFF"
				},
				"& .MuiChip-root--color-red":
				{
					background: alpha(COLORS.red, 0.5)
				},
				"& .MuiChip-root--color-green":
				{
					background: alpha(COLORS.green, 0.5)
				},
				"& .MuiChip-root--color-blue":
				{
					background: alpha(COLORS.blue, 0.5)
				},
				"& .MuiChip-root--color-none":
				{
					background: alpha(COLORS.none, 0.5),

					"& .MuiChip-label":
					{
						color: "primary.dark"
					}
				}
			}}>
				<Box sx={{
					position: "relative",
					zIndex: 2,
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",

					// Cluster
					"& .cluster-root":
					{
						padding: { xl: "24px 32px 32px 32px", lg: "24px", xs: "24px 32px 32px 32px" },
						borderRadius: "24px",
						width: { xl: "423px", lg: "320px", xs: "423px" },
						display: "flex",
						flexDirection: "column",
						gap: "16px",
						background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
						backdropFilter: "blur(10px)",
						border: "1px solid " + alpha("#FFFFFF", 0.5),
						boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5)
					},
					"& .cluster__title":
					{
						font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
						color: "primary.main"
					},
					"& .cluster__items":
					{
						display: "flex",
						flexWrap: "wrap",
						gap: "16px",
						alignItems: "center",
						overflow: "auto",
						maxHeight: "380px",
						paddingBottom: "5px",

						"&:empty":
						{
							"&::after":
							{
								content: "'Drop to here'",
								padding: "10px",
								display: "block",
								width: "100%",
								textAlign: "center",
								color: alpha("#4D4D4D", 0.6),
								font: "500 16px 'Open Sans'",
								border: "2px dashed " + alpha("#215C75", 0.6),
								borderRadius: "5px",
								transition: "linear 200ms border-color, linear 200ms color"
							}
						},
						"& .MuiChip-root":
						{
							flexGrow: 1,
							cursor: "inherit",
							"& .MuiChip-label":
							{
								textAlign: "center",
								flexGrow: 1
							}
						}
					},
					"& .react-transform-wrapper":
					{
						flexGrow: 1,
						width: "100%",
						height: "100%",
						cursor: cursor,
						borderRadius: "10px"
					},
					"& .react-transform-component":
					{
						gap: "24px",
						justifyContent: "center",
						alignItems: "flex-start",
						width: "100%",
						borderRadius: "10px"
					}
				}}>
					<TransformWrapper
						minScale={0.5}
						maxScale={1}
						centerZoomedOut
						centerOnInit={false}
						panning={{ excluded: (isCursorHand ? [] : [ "MuiChip-root", "MuiChip-label" ]) }}
						wheel={{ excluded: [ "cluster__items", "MuiChip-root", "MuiChip-label" ] }}
						onPinchingStart={() => setIsMove(true)}
						onPanningStart={() => setIsMove(true)}
						onPinchingStop={() => setIsMove(false)}
						onPanningStop={() => setIsMove(false)}
					>
						{({ zoomIn, zoomOut }) => (
							<>
								<TransformComponent>
									{boards.filter((board) => board.id !== "__values__").map(
										({ id, title, items }) => (
											<Box key={id} className="cluster-root">
												<Box className="cluster__title">{title}</Box>
												<Box className="cluster__items customScroll">
													{items.map(
														(boardItem) => (
															<ChipStyled
																key={boardItem.id}
																label={boardItem.title}
																className={"MuiChip-root--color-" + boardItem.color}
																onClick={() => itemChangeColor(searchBoardIndex(id), boardItem)}
															/>
														)
													)}
												</Box>
											</Box>
										)
									)}
								</TransformComponent>

								{/* Buttons */}
								<Box sx={{
									width: "100%",
									display: "flex",
									gap: "20px",
									justifyContent: "flex-end",
									alignItems: "center",
									marginTop: "10px",
									"& .navigation-button":
									{
										background: "none",
										border: "none",
										cursor: "pointer",
										padding: "5px",
										borderRadius: "10px",
										width: { xl: "49px", lg: "36px", xs: "49px" },
										height: { xl: "49px", lg: "36px", xs: "49px" },
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										transition: "linear 200ms background-color",
										"&:hover, &.navigation-button--active": { backgroundColor: alpha("#215C75", 0.2) },
										"& #Rectangle_92": // Color square
										{
											fill: COLORS[ color ]
										}
									}
								}}>
									<TooltipStyled placement="top" arrow title="Zoom In">
										<button className="navigation-button" onClick={() => zoomIn(0.25)}>
											<SVGZoomIn />
										</button>
									</TooltipStyled>

									<TooltipStyled placement="top" arrow title="Zoom Out">
										<button className="navigation-button" onClick={() => zoomOut(0.25)}>
											<SVGZoomOut />
										</button>
									</TooltipStyled>

									<TooltipStyled placement="top" arrow title="Hand">
										<button
											className={`navigation-button ${ (isCursorHand ? "navigation-button--active" : "") }`}
											onClick={() => setIsCursorHand(true)}
										>
											<Box component="img" src={CURSORS.grab} />
										</button>
									</TooltipStyled>

									<TooltipStyled placement="top" arrow title="Tips">
										<button className={`navigation-button ${ (anchorElement !== null ? "navigation-button--active" : "") }`} onClick={openMenuHandler}>
											<SVGMagicalWand />
										</button>
									</TooltipStyled>

									<Menu
										open={isMenuOpen}
										anchorEl={anchorElement}
										anchorOrigin={{ vertical: "top", horizontal: "right" }}
										transformOrigin={{ vertical: "bottom", horizontal: "right" }}
										onClose={closeMenuHandler}
										PaperProps={{ style: { background: "unset", boxShadow: "unset" } }}
										MenuListProps={{ style: { padding: "unset" } }}
										sx={{
											"& .MuiMenuItem-root":
											{
												padding: "15px",
												background: `linear-gradient(to bottom right, ${ alpha("#F7F7F7", 0.8) }, ${ alpha("#F7F7F7", 0) })`,
												borderRadius: "5px",
												border: "1px solid " + alpha("#FFFFFF", 0.6),
												flexDirection: "column",
												alignItems: "flex-start",
												width: "255px",
												boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5),
												whiteSpace: "normal",
												backdropFilter: "blur(40px) brightness(.95)",
												"&:not(:first-of-type)": { borderTop: "none" },
												"& .menu__title": { display: "flex", gap: "10px", alignItems: "center", "& .MuiTypography-root": { font: "700 14px/19px 'Open Sans'" } },
												"& .menu__body": { font: "500 14px/19px 'Open Sans'", opacity: 0.6, transition: "linear 200ms opacity" },
												"&:hover .menu__body": { opacity: 0.8 }
											}
										}}
									>
										{MENU_ITEMS.map((menuItem, index) => (
											<MenuItem key={index} onClick={() => onSelectMenuItem(menuItem.color)}>
												<div className="menu__title">
													<div>{menuItem.title}</div>
													<ColorSquare color={menuItem.color} />
												</div>

												{menuItem.desc !== undefined &&
													(
														<div className="menu__body">
															{menuItem.desc}
														</div>
													)
												}
											</MenuItem>
										))}
									</Menu>
								</Box>
							</>
						)}
					</TransformWrapper>
				</Box>
			</Box>

			<CustomButton.Contained
				loading={isFetch === true}
				onClick={onClickNextStep}
				sx={{
					margin: { xl: "4px auto 0 auto", lg: "-8px auto 0 auto", xs: "4px auto 0 auto" },
					padding: { xl: "12px 80px", lg: "7px 63px", xs: "12px 80px" },
					minWidth: "unset",
					width: "max-content",
					minHeight: "unset",
					height: { xl: "56px", lg: "40px", xs: "56px" },
					font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 24px/33px 'Open Sans'" }
				}}
			>
				Save
			</CustomButton.Contained>

			<Snackbar
				open={isOpenSnackbar}
				autoHideDuration={5000}
				onClose={onCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "40px", right: "40px" } }}
				TransitionComponent={TransitionLeft}
			>
				<AlertStyled onClose={onCloseSnackbar} severity="warning">
					You have not marked any of your yearnings as a Must Have.
				</AlertStyled>
			</Snackbar>
		</>
	);
}

function TransitionLeft(props: Omit<SlideProps, "direction">)
{
	return (<Slide {...props} direction="left" />);
}

export default TimeToPriorities;
