import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, Typography } from "@mui/material";
import LinkMI from "@mui/material/Link";
import Api from "api";
import TooltipStyled from "components/TooltipStyled";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "utils/localStorage";
import bgMobileWelcomSecond from "../../assets/images/bgMobileWelcomSecond.svg";
import bgPrice from "../../assets/images/bgPrice.png";
import bgWElcomMobileFirst from "../../assets/images/bgWElcomMobileFirst.svg";
import octa from "../../assets/images/calm.svg";
import meditateleft from "../../assets/images/octaRightWelcom.svg";
import octaWelcomSecond from "../../assets/images/octaWelcomSecond.svg";

function MobileWelcom({ packages })
{
	const navigate = useNavigate();
	const userContext = useUser();
	const [ stripeStatus, setStripeStatus ] = useState(false);
	const [ activeItem, setActiveItem ] = useState();
	const [ openDialogDetails, setOpenDialogDetails ] = useState(false);
	const [ activeSubscripotion, setActiveSubscripotion ] = useState("");
	const [ isShowModalUnsubscribe, setIsShowModalUnsubscribe ] = useState(false);

	function openDetails(item)
	{
		setActiveItem(item);
		setOpenDialogDetails(true);
	}

	function closeDialogStripeStatus()
	{
		if (window.location.search.split("=")[ 1 ] === "success")
		{
			setStripeStatus(false);
			navigate("/dashboard");
		}

		else
		{
			setStripeStatus(false);
		}
	}

	useLayoutEffect(() =>
	{
		if (!getLocalStorage("graph"))
		{
			navigate("/");
		}
	}, [ navigate ]);

	function handleClick(e, item)
	{
		e.stopPropagation();
		if (item.id !== 1)
		{
			if (userContext.state?.user?.subscriptionIsActive)
			{
				setIsShowModalUnsubscribe(true);
				// handleSubscribe();
			}

			else
			{
				setActiveSubscripotion(item.idProduct);
				// setOpen(true);
				Api.transactions
					.byAllMissions()
					.then((data) =>
					{
						window.location = data;
					})
					.catch((error) => console.error(error));
				setOpenDialogDetails(false);
			}
		}

	}

	return (
		<Box sx={{ background: `url(${ bgPrice }) no-repeat` }}>
			<Box>
				<Typography pt={4} sx={{ fontSize: "24px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff" }}>
					Welcome to Life <br /> Strategy Quest
				</Typography>
				<Typography pt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff", width: "90%", textAlign: "center", m: "0 auto" }}>
					{getLocalStorage("name")}, thank you for trusting us. We hope the assessment brought some clarity for you.<br /><br />

					We would be happy to guide you to even more clarity with Life Strategy Quest. It’s a fun, self-paced journey to help you
					uncover your true self, understand your options, and turn your dream life into reality.
				</Typography>
			</Box>
			<Box mt={9}>
				<Box sx={{ height: "216px", background: `url(${ bgWElcomMobileFirst }) no-repeat`, backgroundSize: "cover", backgroundPosition: "63%  90%", position: "relative" }}>
					<Box sx={{ position: "absolute", left: 0, top: "-70px", width: "189px" }}>
						<img width="100%" src={meditateleft} alt="shared" />
					</Box>
				</Box>
				<Box
					sx={{
						background: "linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))",
						borderTop: "2px solid #fff",
						borderBottom: "2px solid #fff",
						borderLeft: "none",
						p: "20px 15px",
						backdropFilter: "blur(60px)"
					}}
				>
					<Box sx={{ position: "relative" }}>
						<Typography sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#fff" }}>
							You don’t have to decide just yet
						</Typography>
						<Typography mt={3} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff" }}>
							Enjoy the first steps of the quest for free. Even if you decide to do just that, you will already feel a lot clearer
							about your life. We hope you will have fun!
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box mt={6}>
				<Box sx={{ height: "216px", background: `url(${ bgMobileWelcomSecond }) no-repeat`, backgroundSize: "cover", backgroundPosition: "0%  90%", position: "relative" }}>
					<Box sx={{ position: "absolute", right: 0, top: "-70px", width: "189px" }}>
						<img width="100%" src={octaWelcomSecond} alt="shared" />
					</Box>
				</Box>
				<Box
					sx={{
						background: "linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))",
						borderTop: "2px solid #fff",
						borderBottom: "2px solid #fff",
						borderLeft: "none",
						p: "20px 15px",
						backdropFilter: "blur(60px)"
					}}
				>
					<Box sx={{ position: "relative" }}>
						<Typography sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#fff" }}>
							A quick word about our pricing
						</Typography>
						<Typography mt={3} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff" }}>
							We are a small, self-funded team. We don’t believe in ads or selling your data.<br /><br />
							This is why we are offering a full quest as a premium subscription, so that you can enjoy your personal growth journey
							in a peaceful and serene way.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff" }}>
				Our packages
			</Typography>
			<Typography pt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#fff", textAlign: "center", p: "0 15px" }}>
				Get access to the full quest and all life management tools with a payment plan that works best for you:
			</Typography>
			<Box sx={{ width: "90%", m: "20px auto 0" }}>
				{/* <Grid container spacing={5} >
					<Grid item xs={12} sm={3}>
						<Box sx={{
							background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
							backdropFilter: 'blur(60px)',
							border: '2px solid #fff',
							borderRadius: '20px',
							p: '20px 30px 60px',
							height: '171px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px' }}>
									Monthly<br></br>
									subscription
								</Typography>
							</Box>
							<Box sx={{ mt: '-10px' }}>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px', textAlign: 'right' }}>
									€12.99
								</Typography>
								<Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
									cancel any time
								</Typography>
							</Box>
						</Box>
						<Box sx={{ m: '-40px auto 0' }}>
							<Link className="textecotationLinks" to="/temporary-page"><ButtonStyled fullWidth={true} title="Subscribe Monthly" /></Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Box sx={{
							background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
							backdropFilter: 'blur(60px)',
							border: '2px solid #fff',
							borderRadius: '20px',
							p: '20px 30px 60px',
							height: '171px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px' }}>
									Yearly<br></br>
									subscription
								</Typography>
							</Box>
							<Box sx={{ mt: '-10px' }}>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px', textAlign: 'right' }}>
									€129.99
								</Typography>
								<Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
									cancel any time
								</Typography>
							</Box>
						</Box>
						<Box sx={{ m: '-40px auto 0' }}>
							<Link className="textecotationLinks" to="/temporary-page"> <ButtonStyled fullWidth={true} title="Subscribe Annually" /></Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Box sx={{
							background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
							backdropFilter: 'blur(60px)',
							border: '2px solid #fff',
							borderRadius: '20px',
							p: '20px 30px 60px',
							height: '171px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px' }}>
									Lifetime access
								</Typography>
								<Typography sx={{ fontSize: '16px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '22px' }}>
									to the quest and all the tools
								</Typography>
							</Box>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px', textAlign: 'right' }}>
									€399.99
								</Typography>
								<Typography sx={{ fontSize: '16px', fontFamily: 'Open Sans', fontWeight: 'medium', color: '#fff', textAlign: 'right' }}>
									cancel any time
								</Typography>
							</Box>
						</Box>
						<Box sx={{ m: '-40px auto 0' }}>
							<Link className="textecotationLinks" to="/temporary-page"><ButtonStyled fullWidth={true} title="Get Lifetime Access" /></Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Box sx={{
							background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
							backdropFilter: 'blur(60px)',
							border: '2px solid #fff',
							borderRadius: '20px',
							p: '20px 30px 60px',
							height: '151px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px' }}>
									Decide later
								</Typography>
							</Box>
							<Box>
								<Typography sx={{ fontSize: '24px', fontFamily: 'Lora-Bold', fontWeight: 'bold', color: '#fff', lineHeight: '32px', textAlign: 'right' }}>
									€0
								</Typography>
							</Box>
						</Box>
						<Box sx={{ m: '-40px auto 0' }}>
							<Link className="textecotationLinks" to="/temporary-page"><Button sx={{
								background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								backdropFilter: 'blur(60px)',
								color: '#fff',
								fontFamily: 'Open Sans',
								fontSize: '24px',
								width: '100%',
								borderRadius: '160px',
								height: '60px',
								textTransform: 'none',
								border: '3px solid #fff',
								"&:hover": {
									background: 'linear-gradient(to right bottom, rgba(239,239,239,0.4 ), rgba(0,0,0,0.10196078431372549 ))',
								}
							}}>Try for Free</Button></Link>
						</Box>
					</Grid>
				</Grid> */}
				<Grid container spacing={4} justifyContent="center" sx={{ mt: "55px", pb: "55px" }}>
					{packages.map((item, index) => (
						<Grid item key={index} xs={12} sm={3}>
							<Box
								onClick={() => openDetails(item)}
								sx={{
									border: "2px solid rgba(255, 255, 255, 0.5)",
									borderRadius: "24px",
									background: "linear-gradient(259.97deg, rgba(207, 225, 255, 0.55) 7.03%, rgba(223, 223, 223, 0.132) 102.08%)",
									p: { lg: "24px", xl: "24px 36px 32px" },
									height: { lg: "357px", xl: "501px" },
									display: "flex",
									justifyContent: "space-between",
									flexDirection: "column",
									color: "primary.light",
									position: "relative",
									cursor: "pointer"
								}}
							>
								<Box>
									<Typography sx={{ font: { lg: '700 24px/31px "lora-Bold"', xl: '700 40px/51px "lora-Bold"' }, textAlign: "center", mb: { lg: "15px", xl: 4 } }}>
										{item.title}
									</Typography>
									{item.subTitle.map((itemSubtitle, indexSubtitle) => (
										<Box key={indexSubtitle} sx={{ display: "flex", gap: { lg: "10px", xl: "14px" }, mb: { lg: "10px", xl: 2 } }}>
											<CheckIcon sx={{ fill: index === 2 ? "primary.main" : "primary.light", fontSize: { lg: "14px", xl: "20px" }, mt: { lg: "2px", xl: "5px" }, opacity: itemSubtitle.active ? 1 : .5 }} />
											<Typography sx={{ font: { lg: '400 16px/20px "Open Sans"', xl: '400 22px/26px "Open Sans"' }, opacity: itemSubtitle.active ? 1 : .5 }}>
												{itemSubtitle.title}
											</Typography>
										</Box>
									))}
								</Box>
								{index === 1 && userContext.state?.user?.subscriptionIsActive ?
									<TooltipStyled title="You have an active subscription" placement="top" arrow>
										<Box>
											<CustomButton.Contained
												disabled={(userContext.state?.user?.subscriptionIsActive)}
												onClick={(e) =>
												{
													handleClick(e, item);
												}}
												sx={{ width: "100%", fontFamily: "lora-Bold", fontSize: { lg: "24px", xl: "32px" }, minHeight: { lg: "40px", xl: "56px" } }}
											>
												{`€${ item.price }`}
											</CustomButton.Contained>
										</Box>
									</TooltipStyled>
									:
									<Box>
										{index === 2 ?
											<LinkMI
												onClick={(e) => e.stopPropagation()}
												href="https://www.yourlifestartup.com/change-your-life-step-by-step"
												sx={{
													textDecoration: "none",
													width: "100%"
												}}
											>
												<CustomButton.Contained
													sx={{ width: "100%", fontFamily: "lora-Bold", fontSize: { lg: "24px", xl: "32px" }, minHeight: { lg: "40px", xl: "56px" } }}
												>
													{`€${ item.price }`}
												</CustomButton.Contained>
											</LinkMI> :
											<CustomButton.Contained
												disabled={
													(index === 2 && userContext.state?.user?.subscriptionIsActive) ||
													(index === 2 && userContext.state?.user?.fullSubscribe) ||
													(index === 1 && userContext.state?.user?.fullSubscribe)
												}
												onClick={(e) => handleClick(e, item)}
												sx={{ width: "100%", fontFamily: "lora-Bold", fontSize: { lg: "24px", xl: "32px" }, minHeight: { lg: "40px", xl: "56px" } }}
											>
												{(index === 1 && userContext.state?.user?.subscriptionIsActive) ? "Unsubscribe" : `€${ item.price }`}
											</CustomButton.Contained>}
									</Box>
								}

								{/* {index === 2 && <Box sx={{ position: 'absolute', top: { lg: '-32px', xl: '-46px' }, left: { lg: 'calc(50% - 28px)', xl: 'calc(50% - 36px)' } }}>
											<Box sx={{ width: { lg: '56px', xl: '72px' }, height: { lg: '56px', xl: '72px' }, position: 'relative', p: '13px', background: '#fff', borderRadius: '50%' }}>
												<Box
													component='img'
													src={heart}
													sx={{ width: { lg: '35px', xl: '45px' }, top: { lg: '13px', xl: '20px' }, position: 'absolute', left: { lg: '10px', xl: '13px' } }}
												/>
											</Box>
										</Box>} */}
							</Box>
						</Grid>
					))}
				</Grid>
				<Box sx={{ width: "97px", display: { xs: "block", sm: "none" }, m: "0 auto", p: "50px 0 70px" }}>
					<img width="100%" src={octa} alt="guide-chara" />
				</Box>
			</Box>

		</Box>

	);
}

export default MobileWelcom;
