import { Dialog, styled } from "@mui/material";
import IMGBigBubble from "./bubble.png";

const ModalBubble = styled(Dialog)(({ theme }) => ({
	"& .MuiBackdrop-root": { backgroundColor: "transparent" },
	"& .MuiPaper-root":
	{
		"--size": "344px",

		padding: "40px",
		width: "var(--size)",
		height: "var(--size)",
		background: `url(${ IMGBigBubble }) center center/cover`,
		boxShadow: "unset",
		font: "700 24px/31px Lora-Bold",
		color: theme.palette.primary.main,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",

		[ theme.breakpoints.down("xl") ]:
		{
			"--size": "246px",
			padding: "24px",
			font: "700 18px/23px Lora-Bold"
		}
	}
}));

export default ModalBubble;
