import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogProps, alpha } from "@mui/material";
import Api from "api";
import IMGOctaModal from "assets/images/guideChara.png";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { MouseEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";

export interface IDeleteAccountModalProps
{
	/**
	 * @default false
	 */
	open?: boolean;
	onClose?(event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeButton" | "reject" | "resolve"): void;
}

function DeleteAccountModal({ open = false, onClose, ...props }: IDeleteAccountModalProps & DialogProps)
{
	const navigate = useNavigate();
	const userContext = useUser();
	const isFetchRef = useRef<boolean>(false);
	const [ isFetch, setIsFetch ] = useState<boolean>(false);

	// Handles
	function onDeleteUser()
	{
		if (isFetchRef.current === true || isFetch === true)
		{
			return;
		}

		isFetchRef.current = true;
		setIsFetch(true);

		Api.users
			.deleteUser()
			.then(() =>
			{
				userContext.dispatch({ type: UserActionType.LOGOUT });

				setTimeout(() =>
				{
					navigate("/login");

					onClose && onClose({}, "resolve");
				}, 0);
			})
			.catch((error) => console.error(error))
			.finally(() =>
			{
				isFetchRef.current = false;
				setIsFetch(false);
			});
	}

	// Render
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper":
				{
					maxWidth: "unset",
					width: "980px",
					height: "440px",
					padding: "48px 64px 64px 64px",
					backgroundColor: "#FFFFFF",
					border: "1px solid " + alpha("#FFFFFF", 0.5),
					borderRadius: "24px"
				},
				"& .MuiBackdrop-root":
				{
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.474) 9.99%, rgba(239, 239, 239, 0.132) 91.61%)",
					backdropFilter: "blur(25px)"
				}
			}}
			{...props}
		>
			{onClose !== undefined &&
				<CloseIcon
					onClick={() => onClose({}, "closeButton")}
					sx={{
						position: "absolute",
						top: "32px",
						right: "32px",
						cursor: "pointer",
						color: "primary.main"
					}}
				/>
			}

			<Box
				component="img"
				src={IMGOctaModal}
				alt=""
				sx={{
					position: "absolute",
					top: "19px",
					right: "96px",
					width: "235px"
				}}
			/>

			<Box sx={{ font: "700 40px/51px Lora-Bold", color: "#215C75", marginRight: "300px" }}>
				Delete a user account
			</Box>

			<Box sx={{ font: "400 22px/30px 'Open Sans'", color: "#4D4D4D", marginTop: "24px", marginRight: "300px" }}>
				You are about to delete your account. Please note that you will not be able to access or restore your data once you proceed.
			</Box>

			<Box sx={{ marginTop: "auto", display: "flex", justifyContent: "space-between" }}>
				<CustomButton.Contained
					loading={isFetch === true}
					onClick={() => onDeleteUser()}
				>
					Delete account
				</CustomButton.Contained>

				<CustomButton.Outline
					disabled={isFetch === true}
					onClick={(event: MouseEvent<HTMLButtonElement>) => onClose && onClose(event, "reject")}
				>
					I've changed my mind
				</CustomButton.Outline>
			</Box>
		</Dialog>
	);
}

export default DeleteAccountModal;
