import { Box, alpha } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { DEFAULT_STORE_FORM, IStoreForm } from "../..";
import ArrowButton from "../../../../components/ArrowButton";
import CustomButton from "../../../../components/customButton";
import TextInput from "../../../../components/missionThree/TextInput";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import setFieldError from "../../../../utils/setFieldError";
import setFieldValue from "../../../../utils/setFieldValue";
import useMission from "../../hooks/useMission";

interface IDreamUpYourUniversesFormProps
{
	type: "yellow" | "pink" | "purple";
}

type InputField = { value: string; error: boolean; };

interface IFieldsState
{
	title: InputField;
	field1: InputField;
	field2: InputField;
	field3: InputField;
	field4: InputField;
	field5: InputField;
	field6: InputField;
}

function DreamUpYourUniversesForm({ type }: IDreamUpYourUniversesFormProps)
{
	const store = getLocalStorage<IStoreForm>("mission-three-form") || DEFAULT_STORE_FORM;
	const initFieldsState: IFieldsState =
	{
		title: { value: store.DreamUpYourUniversesForm[ type ].title || "", error: false },
		field1: { value: store.DreamUpYourUniversesForm[ type ].fields[ 0 ] || "", error: false },
		field2: { value: store.DreamUpYourUniversesForm[ type ].fields[ 1 ] || "", error: false },
		field3: { value: store.DreamUpYourUniversesForm[ type ].fields[ 2 ] || "", error: false },
		field4: { value: store.DreamUpYourUniversesForm[ type ].fields[ 3 ] || "", error: false },
		field5: { value: store.DreamUpYourUniversesForm[ type ].fields[ 4 ] || "", error: false },
		field6: { value: store.DreamUpYourUniversesForm[ type ].fields[ 5 ] || "", error: false }
	};

	const missionContext = useMission();
	const [ fields, setFields ] = useState<IFieldsState>(initFieldsState);

	// Handles
	function onFormSubmit(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		let isError: boolean = false;

		for (const field in fields)
		{
			if (fields[ field as keyof IFieldsState ].value.trim().length < 2)
			{
				isError = true;
				setFieldError(setFields, field as keyof IFieldsState, true);
			}
			else
			{
				setFieldError(setFields, field as keyof IFieldsState, false);
			}
		}

		if (isError)
		{
			return;
		}

		const store = getLocalStorage<IStoreForm>("mission-three-form") || DEFAULT_STORE_FORM;

		store.DreamUpYourUniversesForm[ type ].title = fields.title.value;
		store.DreamUpYourUniversesForm[ type ].fields = Object.entries(fields)
			.filter(([ fieldName ]) => fieldName !== "title")
			.map(([ _fieldName, fieldData ]: [ string, InputField ]) => fieldData.value);

		setLocalStorage("mission-three-form", store);

		missionContext.setExtraStep(null);
	}

	function onChange({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
	{
		let { value } = target;

		value = value.replaceAll("\n", " ").replaceAll("\t", " ").replaceAll(/\s{2,}/g, " ");

		setFieldValue(setFields, target.name as keyof IFieldsState, value);
		setFieldError(setFields, target.name as keyof IFieldsState, false);
	}

	// Render
	return (
		<>
			<Box className="main__content">
				<PanelTitle positionOcta imageVariation="closeEye" title="Dream up your universes!" />

				<Box
					component="form"
					onSubmit={onFormSubmit}
					sx={{
						width: "100%",
						margin: "40px auto 0 auto",
						maxWidth: { xl: "820px", lg: "648px", xs: "820px" }
					}}
				>
					<Box sx={{
						backgroundColor: alpha("#FFFFFF", 0.7),
						padding: { xl: "30px", lg: "24px", xs: "20px" },
						borderRadius: "10px",
						backdropFilter: "blur(30px)"
					}}>
						<Box
							component="input"
							placeholder="Name your Universe"
							name="title"
							value={fields.title.value}
							onChange={onChange}
							sx={{
								background: "none",
								border: "none",
								borderBottom: "2px solid " + (fields.title.error ? "#E41616" : "#245C74"),
								width: "100%",
								paddingBottom: { xl: "30px", lg: "16px", xs: "10px" },
								marginBottom: { xl: "32px", lg: "8px", xs: "30px" },
								outline: "none",
								font: { xl: "700 42px/54px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 42px/54px Lora-Bold" },
								color: "primary.main",
								"::placeholder":
								{
									color: "inherit",
									opacity: ".4"
								}
							}}
						/>

						<Box sx={{
							"--line-height": { xl: "29px", lg: "21px", xs: "" },
							color: "#4D4D4D",
							font: {
								xl: "400 22px/var(--line-height) 'Segoe UI'",
								lg: "400 16px/var(--line-height) 'Segoe UI'",
								xs: "400 22px/var(--line-height) 'Open Sans'"
							},

							"& textarea":
							{
								textIndent: { xl: "414px", lg: "290px", xs: "414px" }
							}
						}}>
							In this universe, I am a <TextInput
								name="field1"
								size={150}
								value={fields.field1.value}
								onChange={onChange}
								isError={fields.field1.error}
								onBlur={() => setFieldValue(setFields, "title", fields.field1.value.trim() + " Universe")} /> .
							<br /><br />
							I live in <TextInput name="field2" size={183} value={fields.field2.value} onChange={onChange} isError={fields.field2.error} /> with <TextInput name="field3" size={231} value={fields.field3.value} onChange={onChange} isError={fields.field3.error} /> .
							<br /><br />
							I make money by <TextInput name="field4" size={240} value={fields.field4.value} onChange={onChange} isError={fields.field4.error} /> . In my free time, I <TextInput name="field5" size={327} value={fields.field5.value} onChange={onChange} isError={fields.field5.error} /> .
							<br /><br />
							<Box sx={{ position: "relative" }}>
								<TextInput
									name="field6"
									fullWidth
									multiline
									rows={3}
									maxRows={3}
									maxLength={167}
									onChange={onChange}
									value={fields.field6.value}
									isError={fields.field6.error}
								/>

								<Box component="span" sx={{ paddingRight: "5px", zIndex: 2, position: "absolute", top: 0, left: 0 }}>
									The best thing about my life is that I can
								</Box>
							</Box>
						</Box>
					</Box>

					<CustomButton.Contained
						type="submit"
						sx={{
							display: "flex",
							margin: "32px auto 0 auto",
							padding: { xl: "10px 80px", lg: "10px 32px", xs: "10px 32px" }
						}}
					>
						I am done!
					</CustomButton.Contained>
				</Box>
			</Box>

			<StepperNavigationWrapper>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton direction="right" disabled />
			</StepperNavigationWrapper>
		</>
	);
}

export default DreamUpYourUniversesForm;
