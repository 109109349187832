import { createContext, Dispatch, SetStateAction } from "react";
import { BackgroundType } from ".";

export interface IMissionContextEightMission
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof BackgroundType;
	setBackground: any;

	name: string;
	setName: Dispatch<SetStateAction<string>>;
}

export const MissionCtx = createContext<IMissionContextEightMission>({} as IMissionContextEightMission);
