import { Box, Fade } from "@mui/material";
import CustomInput from "components/CustomInput";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import Panel from "components/missions/Panel";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionTwo/hooks/useMission";
import { ChangeEvent } from "react";
import { useLocalStorage } from "utils/localStorage";

const MAX_LENGTH_TEXTAREA: number = 500;

function TravelIntoFutureGratitudeForm()
{
	const missionContext = useMission();
	const [ value, setValue ] = useLocalStorage<string>(missionContext.missionPrefix + "-travelIntoFutureGratitude", "");

	// Handles
	function onChange({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setValue(target.value.replaceAll(/\s{2,}/g, " "));
	}

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<PanelTitle
				positionOcta
				imageVariation="closeEye"
				title={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="LifeFullGratitude_title"
					defaultValue="Life full of gratitude"
					position="left"
				/>}
				subTitle={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="LifeFullGratitude_subTitle"
					defaultValue="In this scenario, you’ve lived a happy life.\nYou look back at it with a smile and full of gratitude."
					position="left"
					isDescription
				/>}
			/>

			<Fade in={missionContext.showContent}>
				<Panel sx={{
					marginTop: "70px",
					padding: "24px 24px 24px 32px"
				}}>
					<Box sx={{
						paddingLeft: "16px",
						font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
						color: "primary.main"
					}}>
						<ContentAdmin
							keyContent={missionContext.keyContent}
							keyText="LifeFullGratitude_formTitle"
							defaultValue="What made your life well-lived and full of gratitude?"
							position="left"
						/>
					</Box>

					<CustomInput.Base
						multiline
						placeholder="I lived a life of purpose, connected with other people, surrounded by my tribe. I spent a lot of time in nature, moving my body, eating good food, pursuing my dreams and travelling."
						rows={12}
						value={value}
						onChange={onChange}
						inputProps={{ maxLength: MAX_LENGTH_TEXTAREA }}
						sx={{
							marginTop: "16px",
							width: "100%"
						}}
					/>

					<Box sx={{
						padding: "0 16px",
						width: "100%",

						font: "400 14px/16px 'Open Sans'",
						color: "primary.dark",
						textAlign: "right",
						opacity: 0.5
					}}>
						{value.trim().length}/{MAX_LENGTH_TEXTAREA} characters
					</Box>

					<CustomButton.Contained
						size="large"
						disabled={value.trim().length === 0}
						onClick={() =>
						{
							missionContext.setExtraStep(null);
						}}
						sx={{
							margin: "8px auto 0 auto",
							display: "flex"
						}}
					>
						Submit
					</CustomButton.Contained>
				</Panel>
			</Fade>
		</Box>
	);
}

export default TravelIntoFutureGratitudeForm;
