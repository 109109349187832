import Container from "components/container";
import useUser from "hooks/useUser";
import { useLayoutEffect, useState } from "react";
import Name from "./fields/Name";
import SteperContainer from "./fields/SteperContainer";
import LetsDoIt from "./fields/letsDoIt";

function StartPage()
{
	const userContext = useUser();
	const [ step, setStep ] = useState(0);

	useLayoutEffect(() =>
	{
		if (userContext.state.isAuthenticated)
		{
			setStep(2);
		}
	}, [ userContext.state.isAuthenticated ]);

	function handleStep()
	{
		setStep(prev => prev + 1);
	}

	function renderStep(step)
	{
		switch (step)
		{
			case 0:
				return <Name handleStep={handleStep} />;
			case 1:
				return <LetsDoIt handleStep={handleStep} />;
			case 2:
				return <SteperContainer handleStep={handleStep} />;
		}
	}

	return (
		<Container step={step}>
			{renderStep(step)}
		</Container>
	);
}

export default StartPage;
