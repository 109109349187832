import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CelebrationScreen from "../../../../components/missions/CelebrationScreen";
import useMission from "../../hooks/useMission";

export type ICelebrationScreen2Props = Record<string, any>;

function CelebrationScreen2({ ...props }: ICelebrationScreen2Props, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<CelebrationScreen
					content={<ContentAdmin keyContent={missionContext.keyContent} keyText="CelebrationScreen2_text" defaultValue="Excellent job, {NAME}!" position="left" isDescription />}
					buttonText="Thanks, Octa!"
					onClickButton={() => missionContext.nextStep()}
					sx={{ "& .CelebrationScreen-content": { color: "primary.main", textShadow: "unset" } }}
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(CelebrationScreen2);
