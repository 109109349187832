import { Box, Tab, Tabs, Zoom, alpha } from "@mui/material";
import Api from "api";
import IMGBgGradient from "assets/images/bg-gradient.png";
import { ReactComponent as SVGAccount } from "assets/images/icons/dashboards/account.svg";
import { ReactComponent as SVGSupports } from "assets/images/icons/dashboards/communityAndSupport.svg";
import { ReactComponent as SVGMissions } from "assets/images/icons/dashboards/level.svg";
import { ReactComponent as SVGResources } from "assets/images/icons/dashboards/resources.svg";
import { ReactComponent as SVGLogo } from "assets/images/logo.svg";
import MobileContent from "components/MobileContent";
import TabPanel, { a11yProps } from "components/TabPanel";
import TooltipStyled from "components/TooltipStyled";
import useUser from "hooks/useUser";
import { useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import Account from "./account";
import Resources from "./artifacts";
import Missions from "./missions";
import Supports from "./supports";
import { MylifequestProductTour } from "./tour/Tour";

interface ITabsData
{
	index: number;
	to: string;
	value: string;
	label?: string;
	icon: JSX.Element;
	visible?: boolean;
	dataTut?: string;
}

const TAB_INDEX =
{
	missions: 0,
	artifacts: 1,
	supports: 2,
	account: 3
};

function ResourcesIcon({ unseenCount }: { readonly unseenCount: number; })
{
	return (
		<Box sx={[
			(unseenCount > 0) &&
			{
				"&::before":
				{
					content: `'${ unseenCount }'`,
					width: "14px",
					height: "16px",
					position: "absolute",
					top: "2px",
					left: "50%",
					transform: { xl: "translateX(-13px)", lg: "translateX(-6px)", xs: "translateX(-7px)" },
					backgroundColor: "#FCEFEA",
					boxShadow: "0px 0px 10px 0px #FFE8B9",
					borderRadius: "16px",
					color: "primary.main",
					font: "700 11px/19px 'Open Sans'",
					textAlign: "center",
					lineHeight: "14px"
				},
				"& path":
				{
					filter: "drop-shadow(0px 0px 3px #FFE8B9)"
				}
			}
		]}>
			<SVGResources />
		</Box>
	);
}

function Dashboard()
{
	const userContext = useUser();
	const { tab: currentTab = "missions", subtab: subdividedTab } = useParams();

	const [ isTourOpen, setTourOpen ] = useState(false);

	const TABS: ITabsData[] = [
		{
			index: TAB_INDEX.missions,
			to: "/dashboard/",
			value: "missions",
			icon: <SVGMissions />,
			dataTut: "reactour__nextMission_tab"
		},
		{
			index: TAB_INDEX.artifacts,
			to: "/dashboard/artifacts",
			value: "artifacts",
			label: "Artifacts",
			icon: <ResourcesIcon unseenCount={userContext.state.user?.notificationArtifact?.length || 0} />,
			dataTut: "reactour__artifacts_tab"
		},
		{
			index: TAB_INDEX.supports,
			to: "/dashboard/supports",
			value: "supports",
			label: "Support",
			icon: <SVGSupports />,
			dataTut: "reactour__support_tab"
		},
		{
			index: TAB_INDEX.account,
			to: "/dashboard/account",
			value: "account",
			icon: <SVGAccount />,
			dataTut: "reactour__myAccount_tab"
		}
	];

	// Effects
	useLayoutEffect(() =>
	{
		if (userContext.state.user?.onboarded === false && userContext.state.user.completedMission >= 1)
		{
			setTourOpen(true);
		}
	}, [ userContext.state?.user?.onboarded ]);

	// Render
	return (
		<>
			<MylifequestProductTour
				isTourOpen={isTourOpen}
				setTourOpen={setTourOpen}
				onClose={() =>
				{
					Api.users
						.updateUser({ onboarded: true })
						.then(() =>
						{
							return Api.users.getMe();
						})
						.then((user) =>
						{
							userContext.dispatch({
								type: UserActionType.GET_USER,
								payload: { user }
							});
						});
				}}
			/>

			{/* Desktop content */}
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				{/* Navigation */}
				<Box
					component="nav"
					sx={{
						position: "fixed",
						zIndex: 1000,
						left: 0,
						top: 0,
						bottom: 0,
						background: "linear-gradient(179.6deg, #7CB3CB 0.13%, #215C75 101.9%)",
						width:
						{
							xl: "88px",
							lg: "67px",
							xs: "61.73px"
						}
					}}
				>
					<Box
						component="a"
						href="https://www.yourlifestartup.com/"
						sx={{
							display: "block",
							textAlign: "center",
							marginTop:
							{
								xl: "20px",
								lg: "15.31px",
								xs: "14.1px"
							}
						}}
					>
						<Box
							component={SVGLogo}
							sx={{
								fill: "#FFFFFF",
								width:
								{
									xl: "58px",
									lg: "44.39px",
									xs: "40.9px"
								},
								height:
								{
									xl: "40px",
									lg: "30.62px",
									xs: "28.21px"
								}
							}}
						/>
					</Box>

					<Tabs
						orientation="vertical"
						value={TAB_INDEX[ currentTab as keyof typeof TAB_INDEX ]}
						sx={{
							marginTop:
							{
								xl: "172px",
								lg: "131px",
								xs: "135px"
							},

							"& .MuiTabs-flexContainer":
							{
								gap:
								{
									xl: "16px",
									lg: "12.24px",
									xs: "11.28px"
								},
								paddingBottom: "20px",
								alignItems: "flex-end"
							},
							"& .MuiTabs-indicator": { display: "none" }
						}}
					>
						{TABS.map((tab) => (
							<TooltipStyled
								title={<Box sx={{ fontSize: "16px", textTransform: "capitalize" }}>{tab.label || tab.value}</Box>}
								key={tab.value}
								placement="right"
								TransitionComponent={Zoom}
								arrow
							>
								<Tab
									data-tut={tab.dataTut}
									component={Link}
									to={tab.to}
									value={tab.value}
									{...a11yProps(tab.index)}
									icon={tab.icon}
									sx={{
										height:
										{
											xl: "88px",
											lg: "67.35px",
											xs: "62.05px"
										},
										width:
										{
											xl: "88px",
											lg: "67.35px",
											xs: "62.05px"
										},
										minWidth: "unset",
										padding: "unset",

										backgroundColor: alpha("#FFFFFF", 0.25),
										backdropFilter: "blur(30px)",
										borderRadius: "64px 0 0 64px",

										visibility: tab.visible === false ? "hidden" : "visible",

										"& > *":
										{
											position: "absolute",
											width: "calc(100% - 16px)"
										},
										"& svg path": { fill: "#FFFFFF" },
										"&.Mui-selected":
										{
											boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
											backgroundColor: "#E8EDF1"
										},
										"&.Mui-selected svg path": { fill: "#215C75" },
										"&.Mui-selected svg circle": { stroke: "#215C75" }
									}}
								/>
							</TooltipStyled>
						))}
					</Tabs>
				</Box>

				{/* Content */}
				<Box
					sx={{
						position: "relative",
						minHeight: "100vh",
						"&::after": {
							content: "''",
							position: "fixed",
							zIndex: 2,
							top: 0,
							bottom: 0,
							right: 0,
							left: 0,
							opacity: ".6",
							backgroundImage: `url(${ IMGBgGradient })`,
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat"
						}
					}}
				>
					<Box sx={{
						padding: { xl: "40px 40px 0px 88px", lg: "24px 24px 0px 88px", xs: "24px 24px 0px 88px" },
						paddingLeft:
						{
							xl: "88px",
							lg: "67px",
							xs: "61.73px"
						},
						zIndex: 10,
						position: "relative"
					}}>
						{/* Main content */}
						<Box component="main">
							{/* Tab: Missions */}
							<TabPanel value={currentTab} index={0} active="missions">
								<Missions />
							</TabPanel>

							{/* Tab: Resources */}
							<TabPanel value={currentTab} index={1} active="artifacts">
								<Resources selectedResource={subdividedTab} />
							</TabPanel>

							{/* Tab: Supports */}
							<TabPanel value={currentTab} index={2} active="supports">
								<Supports selectedTab={subdividedTab} />
							</TabPanel>

							{/* Tab: Settings */}
							<TabPanel value={currentTab} index={3} active="account">
								<Account selectedTab={subdividedTab} />
							</TabPanel>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default Dashboard;
