import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Fun()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-fun-reflection"
					defaultValue="- How often do you find yourself in the flow?\n- Are you burnt out?\n- Do you know how to relax?\n- Do you have the time and energy for your hobbies?\n- Have you lost the spark in life and don’t know how to get it back?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-fun-actionPlan"
					defaultValue="1. **Create space for fun.** Re-assess your priorities and create time for fun activities in your life.\n1. **Prioritise quality entertainment.** Instead of scrolling through social media or binge-watching TV-shows, focus on activities that develop you and help you connect with others.\n1. **Re-design your lifestyle.** If you’ve already tried that and your life doesn’t feel fun, it’s time for a bigger life change."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-fun-howCanHelp"
					defaultValue="Re-designing your life can be a good way to feel the zest for life again. My Life Quest will help you get your spark back!"
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/az1pD7LSf7I?si=dN2zpuuEJOTT6Kqk"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Fun;
