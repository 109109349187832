import { Box, SxProps, Theme } from "@mui/material";
import IMGguideChara from "assets/images/guideChara.png";
import IMGOcta2 from "assets/images/octa2.png";
import InstructionButton from "../../InstructionButton";
import TipsButton from "../../TipsButton";

interface IPanelTitleProps
{
	title: string | JSX.Element;
	subTitle?: string | JSX.Element;
	imgDisplay?: string;
	gapPanelTitle?: string;
	imageVariation?: "closeEye" | "openEye";
	onClickInstruction?(): void;
	blipInstruction?: boolean;
	onClickTips?(): void;
	blipTips?: boolean;
	sx?: SxProps<Theme>;
	sxSubtitle?: SxProps<Theme>;
	[ key: string ]: any;
	color?: string;
	positionOcta?: boolean;
	zIndexOcta?: string;
}

function PanelTitle({
	title,
	subTitle,
	imgDisplay,
	gapPanelTitle,
	onClickInstruction,
	blipInstruction = false,
	onClickTips,
	blipTips = true,
	imageVariation = "openEye",
	sx = [],
	sxSubtitle = [],
	color,
	positionOcta,
	zIndexOcta,
	...props
}: IPanelTitleProps)
{
	return (
		<Box
			className="component-missions-panelTitle"
			sx={[
				{
					position: "relative",
					display: "flex",
					justifyContent: "space-between",
					alignItems: (subTitle !== undefined ? "flex-start" : "center"),
					gap: "15px",
					width: "100%"
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		>
			<Box
				className="component-missions-panelTitle-imageContainer"
				sx={{
					display: imgDisplay ?? "flex",
					flexDirection: "column",
					alignItems: "center",
					position: "absolute",
					width: "180px",
					top: positionOcta ? "-37px" : "-80px", // TODO: Need corrected
					left: positionOcta ? "-35px" : "-15px",
					userSelect: "none",
					pointerEvents: "none"
				}}
			>
				<Box
					component="img"
					className={"component-missions-panelTitle-image component-missions-panelTitle-image--variation-" + imageVariation}
					src={(imageVariation === "closeEye" ? IMGguideChara : IMGOcta2)}
					alt=""
					sx={{
						width: { xl: "180px", lg: "135px", xs: "180px" },
						userSelect: "none",
						pointerEvents: "none",
						zIndex: zIndexOcta
					}}
				/>
				{onClickTips !== undefined && (<TipsButton onClick={onClickTips} blip={blipTips} offBlipOnClick />)}
			</Box>

			<Box
				className="component-missions-panelTitle-container"
				sx={{
					display: "flex",
					flexDirection: "column",
					// gap: "16px 0",
					gap: gapPanelTitle ?? "16px 0",
					overflowX: "hidden",
					width: "100%",
					marginLeft: imgDisplay ? "" : { xl: "150px", lg: "120px", xs: "120px" }
				}}
			>
				<Box
					className="component-missions-panelTitle-title"
					sx={{
						font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 40px/51px Lora-Bold" },
						textAlign: imgDisplay ? "center" : "",

						color: color ?? "#215C75",
						wordBreak: "break-word",
						maxHeight: "108px",
						overflow: "hidden"
					}}
				>
					<Box sx={{ display: "inline-block" }}>
						{title}
					</Box>
				</Box>

				{subTitle !== undefined &&
					(
						<Box
							className="component-missions-panelTitle-subTitle"
							sx={[
								{
									color: color ?? "#215C75",
									textAlign: imgDisplay ? "center" : "",

									wordBreak: "break-word",
									maxHeight: "150px",
									overflow: "hidden",
									font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" }
								},
								...Array.isArray(sxSubtitle) ? sxSubtitle : [ sxSubtitle ]
							]}
						>
							{subTitle}
						</Box>
					)
				}
			</Box>

			{onClickInstruction !== undefined && (<InstructionButton onClick={onClickInstruction} blip={blipInstruction} offBlipOnClick />)}
		</Box>
	);
}

export default PanelTitle;
