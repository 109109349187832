import { Box, Grid, Typography } from "@mui/material";
import Career from "../../../../assets/images/Career.svg";
import ContentAdmin from "../../../../components/contentAdmin";
import { ReactComponent as ArrowForwardIcon } from "./arrowForward.svg";

function CareerDetails({ arr })
{
	return (
		<Box>
			<Box sx={{ display: "flex", position: "relative", alignItems: "center", padding: "40px 0" }}>
				<Box component="img" src={Career} alt="Career" className="imgDetails" sx={{ marginRight: "20px", width: "94px" }} />

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography className="headDetails" sx={{ marginRight: "20px", font: "700 42px/54px Lora", color: "#245C74" }}>
						Career
					</Typography>
					<Box sx={{ transform: "rotate(-90deg)" }}>
						<ArrowForwardIcon />
					</Box>
				</Box>
			</Box>

			<Box sx={{ font: "500 18px/24px 'Open sans'", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="left" defaultValue="{NAME}, I am sorry to hear you aren’t so happy with your career. It is a fundamental aspect of life that impacts your overall sense of wellbeing. But don’t despair, no matter how far you feel from your perfect career, there are ways to fix that!" keyContent="contentMissionOne" keyText="careerScreen1" />
				{/* {localStorage.getItem('name')}, I am sorry to hear you aren’t so happy with your career. It is a fundamental aspect of life that impacts your overall sense of wellbeing. But don’t despair, no matter how far you feel from your perfect career, there are ways to fix that! */}
			</Box>

			<Grid
				container
				sx={{
					marginTop: "40px",
					font: "500 18px/24px 'Open sans'",
					color: "#4D4D4D",
					"& ul":
					{
						listStyle: "none",
						paddingLeft: "18px",
						marginLeft: "12px !important",
						"& li": { position: "relative", lineHeight: "30px" },
						"& li::before":
						{
							content: "''",
							display: "block",
							position: "absolute",
							left: "-18px",
							top: "calc(30px / 2)", // line-height / 2
							transform: "translateY(-50%)",
							width: "5px",
							height: "5px",
							borderRadius: "50%",
							backgroundColor: "#4D4D4D"
						}
					}
				}}
			>
				<Grid item xs={12} sm={6} sx={{ borderRight: "2px solid rgba(62, 128, 157, 0.5)", paddingRight: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- Is your job rewarding?\n- Does it reflect your values?\n- Do you have opportunities for advancement?\n- Do you make the most of your skills and potential?\n- Do you have a good work life balance?\n\nMaybe you only need to address a couple of specific issues to feel good about your career again. Take on a new exciting project, talk to your boss about stepping up or create healthy boundaries\nfor a better work life balance.\n\nIt could also be you need a career change, within your field, or even outside of it. If you feel you’ve got untapped potential, it’s time to take a step back and strategies your future." keyContent="contentMissionOne" keyText="careerDescStart" />
						{/*
						Maybe you only need to address a couple of specific issues to feel good about your career again. Take on a new exciting project, talk to your boss about stepping up or create healthy boundaries for a better work life balance.<br></br><br></br>
							It could also be you need a career change, within your field, or even outside of it. If you feel you’ve got untapped potential, it’s time to take a step back and strategise your future.
							*/}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6} sx={{ paddingLeft: "60px" }}>
					<Box sx={{ font: "700 24px/31px Lora", color: "#215C75", marginBottom: "20px" }}>
						<ContentAdmin position="left" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
					</Box>

					<Box>
						<ContentAdmin isDescription position="left" defaultValue="- What legacy do you want to leave behind?\n- What kind of life do you want to live?\n- What career could support your desired lifestyle?\n- What is missing for you right now to make a career shift?" keyContent="contentMissionOne" keyText="careerDescBigger" />
					</Box>
				</Grid>
			</Grid>

			<Box sx={{
				font: "500 18px/24px 'Open sans'",
				color: "#4D4D4D",
				marginTop: "40px",
				"& ul":
				{
					listStyle: "none",
					paddingLeft: "18px",
					marginLeft: "12px !important",
					"& li": { position: "relative", lineHeight: "30px" },
					"& li::before":
					{
						content: "''",
						display: "block",
						position: "absolute",
						left: "-18px",
						top: "calc(30px / 2)", // line-height / 2
						transform: "translateY(-50%)",
						width: "7px",
						height: "7px",
						borderRadius: "50%",
						backgroundColor: "#215C75"
					}
				}
			}}
			>
				<ContentAdmin isDescription position="left" defaultValue="Let me guess, {NAME}, you are probably overwhelmed with all the options out there. You could pursue so many paths. How do you even know which one to choose? The answer is simple — experimentation.\\nFirst, learn about yourself: understand your values, your yearnings, what fulfills you. You can then try lots of different things and see works for you best.\\nWe designed Life Strategy Quest to help you build a clear picture of who you are, what you want, and guide you through all the options to a purposeful life and a fulfilling career." keyContent="contentMissionOne" keyText="careerScreen2" />
				{/*
				Let me guess, {localStorage.getItem('name')}, you are probably overwhelmed with all the options out there. You could pursue so many paths. How do you even know which one to choose? The answer is simple — experimentation.<br></br>
				First, learn about yourself: understand your values, your yearnings, what fulfills you. You can then try lots of different things and see works for you best.<br></br>
				We designed Life Strategy Quest to help you build a clear picture of who you are, what you want, and guide you through all the options to a purposeful life and a fulfilling career.
				*/}
			</Box>
		</Box>
	);
}

export default CareerDetails;
