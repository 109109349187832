import { Box, Grow } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import MissionProgressBar from "components/MissionProgressBar";
import MobileContent from "components/MobileContent";
import TipsButton from "components/TipsButton";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import MissionsDialog from "components/missions/Dialog";
import GetHelpButton from "components/missions/GetHelpButton";
import useUser from "hooks/useUser";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Indices } from "types";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import { IMissionContext, MissionCtx } from "./MissionCtx";
import IMGBgDefault from "./assets/images/bg-default.png";
import IMGBgPink from "./assets/images/bg-pink.png";
import IMGBgPurple from "./assets/images/bg-purple.png";
import IMGBgYellow from "./assets/images/bg-yellow.png";
import IMGImageButton1 from "./assets/images/image-button-1.png";
import IMGImageButton2 from "./assets/images/image-button-2.png";
import IMGImageButton3 from "./assets/images/image-button-3.png";
import CelebrationScreen1 from "./components/Celebration/CelebrationScreen1";
import CelebrationScreen2 from "./components/Celebration/CelebrationScreen2";
import Complete from "./components/Complete";
import ImageButton from "./components/ImageButton";
import IntroJobToLove from "./components/IntroJobToLove";
import IntroToTopic from "./components/IntroToTopic";
import JobToLove from "./components/JobToLove";
import JobsAttractYou from "./components/JobsAttractYou";
import { JobsAttractYouModal } from "./components/JobsAttractYou/Modals";
import LifeHighsLows from "./components/LifeHighsLows";
import LetTakeCloserLookYourHighsLows from "./components/LifeHighsLows/LetTakeCloserLookYourHighsLows";
import NowNameYourHighsLows from "./components/LifeHighsLows/NowNameYourHighsLows";
import ReflectOnYourHighsLows from "./components/LifeHighsLows/ReflectOnYourHighsLows";
import PerfectJob from "./components/PerfectJob";
import PersonalJobAd from "./components/PersonalJobAd";
import LearnMoreAboutEachJob from "./components/PersonalJobAd/LearnMoreAboutEachJob";
import TopJobsForYouList from "./components/PersonalJobAd/TopJobsForYouList";
import TopJobsForYouSelect from "./components/PersonalJobAd/TopJobsForYouSelect";
import YourActionPlan from "./components/PersonalJobAd/YourActionPlan";
import Quiz from "./components/Quiz";
import TimeToAct from "./components/TimeToAct";
import Welcome from "./components/Welcome";
import Feedback from "./components/feedback";

export type BackgroundType = Record<"default" | "purple" | "pink" | "yellow", string>;
export type StepsIndex = Indices<typeof STEPS>;

// Background
const Background: BackgroundType =
{
	default: `url(${ IMGBgDefault }) center center/cover`,
	yellow: `url(${ IMGBgYellow }) center center/cover`,
	pink: `url(${ IMGBgPink }) center center/cover`,
	purple: `url(${ IMGBgPurple }) center center/cover`
};

const BackgroundPreload = [
	IMGBgDefault,
	IMGBgPink,
	IMGBgYellow,
	IMGBgPurple
];

const STEPS = [
	/* Step:  1 */ Welcome, /* Chat */
	/* Step:  2 */ Quiz,
	/* Step:  3 */ IntroToTopic, /* Chat */
	/* Step:  4 */ LifeHighsLows,
	/* Step:  5 */ NowNameYourHighsLows,
	/* Step:  6 */ LetTakeCloserLookYourHighsLows,
	/* Step:  7 */ ReflectOnYourHighsLows,
	/* Step:  8 */ CelebrationScreen1,
	/* Step:  9 */ IntroJobToLove, /* Chat */
	/* Step: 10 */ JobsAttractYou,
	/* StepL 11 */ JobToLove, /* Puzzle */
	/* Step: 12 */ CelebrationScreen2,
	/* Step: 13 */ PersonalJobAd,
	/* Step: 14 */ TopJobsForYouList,
	/* Step: 15 */ TopJobsForYouSelect,
	/* Step: 16 */ LearnMoreAboutEachJob,
	/* Step: 17 */ YourActionPlan,
	/* Step: 18 */ TimeToAct,
	/* Step: 19 */ PerfectJob,
	// /* Step: 20 */ CelebrationScreen3,
	/* Step: 20 */Complete,
	/* Step: 21 */ Feedback
] as const;

const EXTRA_STEPS = [
	[ JobsAttractYouModal, { type: "pink" } ],
	[ JobsAttractYouModal, { type: "purple" } ],
	[ JobsAttractYouModal, { type: "yellow" } ]
] as const;

const PERCENTAGE_PER_STEP: number = 100 / (STEPS.length - 1);

const keyContent = "contentMissionFour" as const;
const missionPrefix = "mission-four" as const;

function MissionFourContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ step, setStep ] = useState<number>(() =>
	{
		const step = getLocalStorage<StepsIndex>(missionPrefix + "-step");

		return (step === null ? 1 : step);
	});
	const [ extraStep, setExtraStep ] = useState<number | null>(() =>
	{
		const extraStep = getLocalStorage<number>(missionPrefix + "-extraStep");

		return extraStep;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof BackgroundType>("default");
	const [ isShowTips, setIsShowTips ] = useState<boolean>(false);
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ tipsData, setTipsData ] = useState<{ title?: string; content: JSX.Element; position?: "bottom" | "center"; } | null>(null);
	const [ isDisplayHeader, setIsDisplayHeader ] = useState<boolean>(true);
	const [ isShowTour, setIsShowTour ] = useState<boolean>(true);

	// Context
	const MissionContext: IMissionContext = useMemo(() =>
	{
		return {
			step,
			setStep,
			extraStep,
			setExtraStep,
			nextStep,
			prevStep,
			progress,
			setProgress,
			background,
			setBackground,
			isDisplayHeader,
			setIsDisplayHeader,
			isShowTour,
			setIsShowTour,

			keyContent,
			missionPrefix
		};
	}, [ background, extraStep, isDisplayHeader, isShowTour, progress, step ]);

	// Utils
	const changeBackground = useCallback((_step: typeof step, _extraStep: typeof extraStep) =>
	{
		switch (_extraStep)
		{
			case 1: return setBackground("pink");
			case 2: return setBackground("purple");
			case 3: return setBackground("yellow");
		}

		switch (_step)
		{
			default: { setBackground("default"); }
		}
	}, []);

	function prevStep()
	{
		setStep((prevStep) => (prevStep - 1) as StepsIndex);
	}

	function nextStep()
	{
		setStep((prevStep) => (prevStep + 1) as StepsIndex);
	}

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		changeBackground(step, extraStep);
	}, [ step, extraStep, changeBackground ]);

	useLayoutEffect(() =>
	{
		setLocalStorage(missionPrefix + "-extraStep", extraStep);

	}, [ extraStep ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(4, step).catch((error) => console.error(error));

		setLocalStorage(missionPrefix + "-step", step);

		setProgress((step - 1) * PERCENTAGE_PER_STEP);

		// Close modal
		setIsShowTips(false);

		// Show tour
		if (step === 10)
		{
			setIsShowTour(true);
		}

		// Change tips content
		setIsBlipTips(true);

		switch (step as number)
		{
			case 4:
			case 5: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent={keyContent} keyText="LifeHighsLows_tips" defaultValue="**Why:** This exercise helps you look at your whole life and understand the key factors that added to or detracted from your life satisfaction.\n\n**Do:** Draw up your life journey to date. Think of 5–10 biggest ups & downs of your life so far. Place them on a graph chronologically:\nX — This is your life’s timeline from birth until now. You don’t need to be precise with timing, just place things in the right sequence.\nY — This is your life’s satisfaction. Think about how happy you were with your life during that period.\n\n**Think:** This exercise is not an exact science. Just focus on roughly recalling your life’s most and least fulfilling phases and map them sequentially." position="right" isDescription />)
					}
				);
				break;
			}

			case 6: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent={keyContent} keyText="LetTakeCloserLookYourHighsLows_tips" defaultValue="**Why:** Reflecting on moments of your highest and lowest life satisfaction will help you find clues for the specific things that would make your life more fulfilling.\n\n**Do:** Click on each of your highs and lows in whatever order you wish. Add your reflections.\n\n**Think:** The goal here is to identify what specific environments, interactions and activities you thrive the most in. You will use those as data points in helping you define your fulfilling career." position="right" isDescription />)
					}
				);
				break;
			}

			case 10: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent={keyContent} keyText="JobsAttractYou_tips" defaultValue="**Why:** Thinking of jobs that attract you is a great way to collect cues for what are the ingredients of your fulfilling career.\n\n**Do:** Write down between 4–12 jobs or careers that attract you in one way or another. Jobs that attract you can be realistic or not. Maybe you’ve always wanted to be a president, a french pastry chef, a scientist or an architect? Now it’s your turn.\n\n**Think:** You can think of jobs that attract you now, attracted you in the past or even when you were a child. It doesn’t matter if those are hard to get into, or if you don’t have the talents or skills. Just imagine you are in the universe where anything is possible." position="right" isDescription />)
					}
				);
				break;
			}

			case 11: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent={keyContent} keyText="JobToLove_tips" defaultValue="**Why:** When we dream of a job, we don’t necessarily want all aspects of it. We might want being an architect so that we get to spend time in a beautiful office. Or we may dream of being a surfing instructor so that we are not tied to our desks all day. Singling out 1–2 things that specifically attract you in your dream career can help you gain valuable insight into what are the components of your dream job.\n\n**Do:** Go through each of your dream jobs one by one. For each job, close your eyes for a few seconds, and imagine yourself doing that job. Then write down with 2–3 words what specific bits about that job you find attractive.\n\n**Think:** What attracts you most in each job? A beautiful office? Helping people? Wearing nice clothes? Recognition? Fame? Flexibility?\n\nWhat would your peak moments be? Presenting in front of a big audience? Seeing someone smile? Enjoying a yoga practice in the middle of your work day?" position="right" isDescription />)
					}
				);
				break;
			}

			case 13: {
				setTipsData(
					{
						title: "Instructions",
						content: (<ContentAdmin keyContent={keyContent} keyText="PersonalJobAd_tips" defaultValue="**Why:** This exercise will help you to bring everything that you love, value, and like in one place. You can also use it to brainstorm potential career options with your friends.\n\n**Do:** Use the input from previous steps as a starting point. Write down a few paragrahs describing yourself and what you are looking for. If you get stuck, take a look at the examples.\n\n**Think:** Embrace your most rebellious you and turn the world upside down. It’s not you applying for jobs now, it’s the other way around. So what would your requirements be?" position="right" isDescription />)
					}
				);
				break;
			}

			default: { setTipsData(null); }
		}
	}, [ step ]);

	useLayoutEffect(() =>
	{
		setLocalStorage("mission-four-progress", progress);
	}, [ progress ]);

	// Render
	return (
		<>
			{BackgroundPreload.map((backgroundLink) =>
			{
				return <img key={backgroundLink} src={backgroundLink} alt="" style={{ position: "absolute", top: "-100%", left: "-100%", visibility: "hidden" }} />;
			})}

			<MissionCtx.Provider value={MissionContext}>
				<Box
					className="mission-root"
					sx={{
						width: "100%",
						display: { sm: "flex", xs: "none" },
						flexDirection: "column",
						alignItems: "center",
						gap: "24px",
						background: Background[ background ],
						minHeight: "100vh",
						backgroundAttachment: "fixed",
						padding: { xl: step === 21 || step === 20 ? 0 : "24px", lg: step === 21 || step === 20 ? 0 : "16px", xs: step === 21 || step === 20 ? 0 : "16px" },
						transition: "linear 300ms background",
						overflowX: "hidden",

						"& .TransitionGroup":
						{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							width: "100%"
						},
						"& .mission-main":
						{
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "25px"
						},
						"& .main__content":
						{
							maxWidth: { xl: "980px", lg: "785px", xs: "980px" },
							width: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}
					}}
				>

					<Box sx={{
						position: "fixed",
						top: "40%",
						right: "0",
						zIndex: 5555,
						pointerEvents: "none",
						display: (step === 20 || step === 21 ? "none" : "unset")
					}}>
						<GetHelpButton />
					</Box>

					{/* Header */}
					{step !== 21 && (
						<Box sx={{ display: step === 20 || step === 21 ? "none" : "block", width: "100%" }}>
							<Box
								component="header"
								className="mission-header"
								sx={{
									display: (isDisplayHeader === true ? "flex" : "none"),
									justifyContent: "space-between",
									gap: "15px",
									alignItems: "center",
									width: "100%",
									maxHeight: "40px",
									position: "relative",
									zIndex: "10"
								}}
							>
								<Box
									component={Link}
									to="/dashboard"
									sx={{
										width: { xl: "60px", lg: "48px", xs: "60px" },
										height: { xl: "40px", lg: "32px", xs: "32px" },
										"& svg": { width: "100%", height: "100%" },
										"&:hover svg path": { fillOpacity: 1 }
									}}
								>
									<TooltipStyled title="Back to missions" placement="bottom" arrow>
										<SVGArrowBack />
									</TooltipStyled>
								</Box>

								{process.env.NODE_ENV === "development" &&
									(
										<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
											Step:
											<Box
												component="input"
												type="number"
												min={1}
												max={STEPS.length}
												value={step}
												onChange={({ target }) => setStep(target.valueAsNumber as StepsIndex)}
												sx={{ marginLeft: "10px", textAlign: "center" }}
											/> / {STEPS.length}

										</Box>
									)
								}

								<Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
									{step === 10 &&
										(
											<Box className="ImageButtonBox" sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
												<ImageButton image={IMGImageButton1} isSelected={extraStep === 1} onClick={() => setExtraStep(1)} />
												<ImageButton image={IMGImageButton2} isSelected={extraStep === 2} onClick={() => setExtraStep(2)} />
												<ImageButton image={IMGImageButton3} isSelected={extraStep === 3} onClick={() => setExtraStep(3)} />
											</Box>
										)
									}

									{tipsData !== null &&
										(
											<TooltipStyled title="Tips" placement="bottom" arrow>
												<TipsButton
													isActive={isBlipTips}
													blip={false}
													onClick={() => { setIsShowTips(true); setIsBlipTips(false); }}
												/>
											</TooltipStyled>
										)
									}

									<MissionProgressBar value={progress} />
								</Box>
							</Box>
						</Box>
					)
					}
					{/* <TransitionGroup className="TransitionGroup"> */}
					{extraStep !== null &&
						EXTRA_STEPS.map(([ Component, props ], index) =>
						{
							if (extraStep !== index + 1)
							{
								return false;
							}

							// @ts-ignore
							return (<Component key={index} {...props} />);

							// return (
							// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
							// 		<Component />
							// 	</Grow>
							// );
						})
					}

					{extraStep === null &&
						STEPS.map((Component, index) =>
						{
							if (step !== index + 1)
							{
								return false;
							}

							// @ts-ignore
							return (<Component key={index} />);
							// return (
							// 	<Grow key={index} timeout={{ enter: 1000, exit: 0 }} unmountOnExit>
							// 		<Component />
							// 	</Grow>
							// );
						})
					}
					{extraStep === null && step > STEPS.length &&
						(
							<Grow timeout={{ enter: 1000, exit: 0 }} in unmountOnExit><h1>Step: {step} / {STEPS.length}</h1></Grow>
						)
					}
					{/* </TransitionGroup> */}

					{/* Tips content */}
					{tipsData !== null &&
						(
							<MissionsDialog
								title={tipsData.title}
								open={isShowTips}
								directionSlide="down"
								onClose={() => setIsShowTips(false)}
								position={tipsData.position ?? "center"}
								sx={{ "& .MuiPaper-root": { maxWidth: "980px" } }}
							>
								{tipsData.content}
							</MissionsDialog>
						)
					}
				</Box>
			</MissionCtx.Provider>

			{/* Mobile Content */}
			<MobileContent />
		</>
	);
}

export default MissionFourContainer;
