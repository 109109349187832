import { Box, BoxProps, Fade, Skeleton, alpha } from "@mui/material";
import Api from "api";
import ArrowButton from "components/ArrowButton";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import TooltipStyled from "components/TooltipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import PanelTitle from "components/missions/PanelTitle";
import StepperNavigationWrapper from "components/missions/StepperNavigationWrapper";
import { ForwardRefExoticComponent, Ref, forwardRef, useLayoutEffect, useState } from "react";
import deepClone from "utils/deepClone";
import { getLocalStorage, setLocalStorage } from "utils/localStorage";
import reactStringReplace from "utils/reactStringReplace";
import useMission from "../../hooks/useMission";
import Dialog from "../LifeHighsLows/Dialog";

export interface ILearnMoreAboutEachJobProps extends BoxProps { }
export type JobInformation = { isSaved: boolean; text: string; list: { text: string; checked: boolean; }[]; };

// const INPUT_MIN_LEN: number = 2;

function LearnMoreAboutEachJob({ ...props }: ILearnMoreAboutEachJobProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	// const inputRef = useRef<HTMLInputElement>(null);

	const [ loadedJobsList, setLoadedJobsList ] = useState<string[]>([]);
	const [ selectedJobName, setSelectedJobName ] = useState<string | null>(null);
	// const [ inputValue, setInputValue ] = useState<string>("");
	// const [ isCheckboxChecked, setIsCheckboxChecked ] = useState<boolean>(false);

	const [ jobsInformation, setJobsInformation ] = useState<Record<string, JobInformation>>(() =>
	{
		const store = getLocalStorage<Record<string, JobInformation>>(
			missionContext.missionPrefix + "-jobsInformation"
		);

		return store ?? {};
	});
	const [ selectedJobsList ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>(missionContext.missionPrefix + "-selectedJobs");

		if (store === null)
		{
			missionContext.setStep(14);
		}

		return store ?? [];
	});

	// Effects
	useLayoutEffect(() =>
	{
		setLocalStorage(missionContext.missionPrefix + "-jobsInformation", jobsInformation);
	}, [ jobsInformation, missionContext.missionPrefix ]);

	// Utils
	function getJobInformation(jobName: string, force = false)
	{
		if (jobsInformation[ jobName ] !== undefined || (force === false && loadedJobsList.includes(jobName) === true))
		{
			return;
		}

		setLoadedJobsList((prevState) => [ ...prevState, jobName ]);

		Api.bot
			.getResponseBot(
				`Tell me in detail about the ${ jobName } and suggest 3 steps I can take to further explore this job`,
				"description"
			)
			.then(({ descJob: result }) =>
			{
				let [ text1, text2, ...list ] = result.map((item) => item.trim());

				list = list.map((listItem) => listItem.replaceAll(/^\d+\.?\)?\s*/g, ""));

				setJobsInformation((prevState) =>
				{
					return {
						...prevState,
						[ jobName ]:
						{
							isSaved: false,
							text: `${ text1 }\n\n${ text2 }`,
							list: list.map((listItem) => ({ text: listItem, checked: false }))
						}
					};
				});
			})
			.catch((error) =>
			{
				getJobInformation(jobName, true);
				console.error(error);
			})
			.finally(() =>
			{
				setLoadedJobsList((prevState) =>
				{
					return prevState.filter((listJobName) => listJobName !== jobName);
				});
			});
	}

	function isJobsSaved()
	{
		for (const jobName of selectedJobsList)
		{
			if (jobsInformation[ jobName ] === undefined)
			{
				return false;
			}

			if (jobsInformation[ jobName ].isSaved === false)
			{
				return false;
			}
		}

		return true;
	}

	// Handles
	function nextStep()
	{
		missionContext.nextStep();
	}

	function onSelectJob(jobName: string)
	{
		setSelectedJobName(jobName);
		getJobInformation(jobName);

		// setInputValue("");
		// setIsCheckboxChecked(false);
	}

	function onSelectCheckbox(index: number)
	{
		if (selectedJobName === null)
		{
			return;
		}

		setJobsInformation((prevState) =>
		{
			const state = deepClone(prevState);

			state[ selectedJobName ].list[ index ].checked = !state[ selectedJobName ].list[ index ].checked;

			return state;
		});
	}

	// function onInputKeyDown({ key }: KeyboardEvent<HTMLInputElement>)
	// {
	// 	if (key !== "Enter")
	// 	{
	// 		return;
	// 	}

	// 	if (selectedJobName === null)
	// 	{
	// 		return;
	// 	}

	// 	if (inputValue.trim().length < INPUT_MIN_LEN)
	// 	{
	// 		return;
	// 	}

	// 	setJobsInformation((prevState) =>
	// 	{
	// 		const state = deepClone(prevState);

	// 		state[ selectedJobName ].list.push({ text: inputValue.trim(), checked: isCheckboxChecked });

	// 		return state;
	// 	});

	// 	setInputValue("");
	// 	setIsCheckboxChecked(false);

	// 	window.requestAnimationFrame(() =>
	// 	{
	// 		inputRef.current?.scrollIntoView({ behavior: "auto" });
	// 	});
	// }

	function onClickSave()
	{
		if (selectedJobName === null)
		{
			return;
		}

		setJobsInformation((prevState) =>
		{
			const state = deepClone(prevState);

			state[ selectedJobName ].isSaved = true;

			return state;
		});

		setSelectedJobName(null);
		// setInputValue("");
		// setIsCheckboxChecked(false);
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="LearnMoreAboutEachJob_title" defaultValue="Learn more about each job" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="LearnMoreAboutEachJob_subTitle" defaultValue="{NAME}, now that you’ve done all the hard work, let’s learn more about each job that might be right for you. Here are the steps that you can take to explore each job further." position="left" isDescription />}
					positionOcta
					sx={{
						marginBottom: "25px",

						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Fade in={selectedJobName === null} timeout={500}>
					<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: { xl: "16px", lg: "13px", xs: "13px" } }}>
						{selectedJobsList.map((jobName) =>
						{
							return (
								<Box
									component="button"
									key={jobName}
									sx={[
										{
											background: "linear-gradient(135deg, rgba(239, 239, 239, .8), rgba(239, 239, 239, .1))",
											border: "1px solid rgba(255, 255, 255, .6)",
											borderRadius: "24px",
											boxShadow: "0px 5px 20px rgba(126, 196, 255, .4)",
											backdropFilter: "blur(15px)",
											width: { xl: "233px", lg: "185px", xs: "185px" },
											height: { xl: "178px", lg: "145px", xs: "145px" },
											padding: "10px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											textAlign: "center",
											font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" },
											color: "primary.main",
											wordBreak: "break-word"
										},
										jobsInformation[ jobName ]?.isSaved === true &&
										{
											background: alpha("#3E809D", 0.5),
											color: "#FFFFFF"
										}
									]}
									onClick={() => onSelectJob(jobName)}
								>
									{jobName}
								</Box>
							);
						})}
					</Box>
				</Fade>
			</Box>

			<Dialog
				open={selectedJobName !== null}
				sx={{
					"& .MuiDialog-container":
					{
						alignItems: { xl: "unset", lg: "center", xs: "center" }
					},
					"& .MuiPaper-root":
					{
						margin: "32px",
						marginTop: { xl: "260px", lg: "32px", xs: "32px" },
						padding: { xl: "32px", lg: "24px", xs: "24px" },
						maxWidth: { xl: "980px", lg: "784px", xs: "784px" },
						maxHeight: { xl: "600px", lg: "463px", xs: "463px" },
						height: "fit-content",
						display: "grid",
						gridTemplateRows: "auto 1fr auto",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" }
					}
				}}
				onClose={() => setSelectedJobName(null)}
			>
				{selectedJobName !== null && (
					<>
						<Box sx={{
							font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
							color: "primary.main"
						}}>
							{selectedJobName}
						</Box>

						<Box
							className="customScroll"
							sx={{
								overflowY: "auto",
								paddingRight: "16px",
								marginTop: { xl: "16px", lg: "8px", xs: "8px" },

								"& .MuiSkeleton-root":
								{
									backgroundColor: alpha("#3E809D", 0.3),
									height: "1em"
								},
								"& .MuiSkeleton-wave::after":
								{
									background: `linear-gradient( 90deg, transparent, ${ alpha("#3E809D", 0.4) }, transparent )`
								}
							}}
						>
							{jobsInformation[ selectedJobName ] === undefined &&
								<>
									<Skeleton sx={{ width: "100%" }} animation="wave" />
									<Skeleton sx={{ width: "100%" }} animation="wave" />
									<Skeleton sx={{ width: "100%" }} animation="wave" />

									<Skeleton sx={{ width: "40%", marginTop: "1em" }} animation="wave" />

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>

									<Box sx={{ display: "flex", gap: "16px", marginTop: "1em", alignItems: "flex-start" }}>
										<Skeleton sx={{ width: "1em" }} animation="wave" variant="rounded" />

										<Box sx={{ flexGrow: 1 }}>
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
											<Skeleton sx={{ height: "1em" }} animation="wave" />
										</Box>
									</Box>
								</>
							}
							{jobsInformation[ selectedJobName ] !== undefined &&
								<>
									{reactStringReplace(
										jobsInformation[ selectedJobName ].text,
										/(\n)/g,
										(_math, index) => (<br key={index} />)
									)}

									<Box
										component="ul"
										sx={{
											listStyle: "none",
											margin: "unset",
											padding: "unset",
											marginTop: "16px",

											display: "flex",
											flexDirection: "column",
											gap: "16px"
										}}
									>
										{jobsInformation[ selectedJobName ].list.map(({ text, checked }, index) =>
										{
											const [ titleRaw, ...descriptionRaw ] = text.split(":");

											const description = descriptionRaw.join(":");
											const title = <b>{titleRaw}:</b>;

											return (
												<li key={index + text}>
													<CheckboxStyledWithLabel
														variant="outlined"
														label={descriptionRaw.length === 0 ? titleRaw : <>{title}{description}</>}
														checkboxProps={{
															checked,
															onChange()
															{
																onSelectCheckbox(index);
															}
														}}
														sx={{
															"--line-height": { xl: "30px", xs: "22px" },
															gap: "10px",
															font: { xl: "400 22px/30px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },

															"& .MuiCheckbox-root":
															{
																position: "sticky",
																top: 0,
																left: 0,
																zIndex: 2
															}
														}}
													/>
												</li>
											);
										})}
									</Box>

									{/* <Box sx={{
										marginTop: "24px",
										display: "flex",
										alignItems: "center",
										gap: "10px",
										cursor: "pointer",
										font: "400 22px/30px 'Open Sans'",
										color: "primary.dark"
									}}>
										<CheckboxStyled
											checked={isCheckboxChecked}
											onChange={({ target }: ChangeEvent<HTMLInputElement>) => setIsCheckboxChecked(target.checked)}
											sx={{
												padding: "0px",

												"& .CheckboxStyled-checkbox":
												{
													backgroundColor: alpha("#FFFFFF", 0.5)
												}
											}}
										/>

										<CustomInput.Base
											fullWidth
											placeholder="Type here if you want to add one more step"
											value={inputValue}
											onChange={({ target }) => setInputValue(target.value)}
											onKeyDown={onInputKeyDown}
											inputRef={inputRef}
											sx={{
												backgroundColor: "unset",

												"& .MuiInputBase-input":
												{
													padding: "unset",
													font: "400 22px/30px 'Open Sans'",

													"&::placeholder":
													{
														opacity: 0.5
													}
												}
											}}
										/>
									</Box> */}
								</>
							}
						</Box>

						<Box sx={{ margin: { xl: "24px auto 0 auto", lg: "8px auto 0 auto", xs: "8px auto 0 auto" } }}>
							<TooltipStyled title="Need chose minimum one checkbox" placement="bottom" arrow>
								<div>
									<CustomButton.Contained
										disabled={
											jobsInformation[ selectedJobName ] === undefined
											|| jobsInformation[ selectedJobName ].list.every(({ checked }) => checked === false)
										}
										onClick={onClickSave}
										sx={{
											paddingRight: "80px !important",
											paddingLeft: "80px !important"
										}}
									>
										I’m done!
									</CustomButton.Contained>
								</div>
							</TooltipStyled>
						</Box>
					</>
				)}
			</Dialog>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton
					direction="right"
					disabled={isJobsSaved() === false}
					onClick={nextStep}
				/>
			</StepperNavigationWrapper>
		</Box>
	);
}

export default forwardRef(LearnMoreAboutEachJob) as ForwardRefExoticComponent<ILearnMoreAboutEachJobProps>;
