import { Box } from "@mui/material";
import { Ref, forwardRef, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../components/Chat";
import { AnswersType } from "../../../components/Chat/Answer";
import { IMessage } from "../../../components/Chat/Message";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const ChatPageCorrectCourse = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	// States
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[
			{
				type: "from",
				message: <ContentAdmin
					limit={300}
					keyContent="contentMissionTen"
					keyText="correct_course_screen_1_chat_message"
					defaultValue="{NAME}, designing your dream life is a never ending journey. You may reach some heights only to discover that there are new peaks to be conquered."
					isDescription />
			}
		]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [ <ContentAdmin
				limit={64}
				keyContent="contentMissionTen"
				keyText="correct_course_screen_1_chat_answer"
				defaultValue="That’s so true!"
				position="top" /> ]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ], onMessage?: () => void)
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message, onMessage } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="correct_course_screen_2_chat_message"
						defaultValue="Yeah, and you may also discover that some of the things you wanted or pursued don’t serve you anymore. It’s important to check in with yourself regularly and correct the course if needed."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="correct_course_screen_2_chat_message_answer"
							defaultValue="How can I do that?"
							position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="correct_course_screen_3_chat_message"
						defaultValue="Well, don’t be afraid to deromantisize things and let go. That’s the whole point of experimentation. But at the same time don’t be afraid to embrace something unexpected that works for you!"
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="correct_course_screen_3_chat_answer"
							defaultValue="Ha"
							position="top" />
					]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin
						limit={300}
						keyContent="contentMissionTen"
						keyText="correct_course_screen_4_chat_message"
						defaultValue="And embrace your radical honesty mindset. If you have gone far in a specific direction only to realise it doesn’t fulfill you, recognise it timely, be honest with yourself and move on to something that will be more fulfilling."
						isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin
							limit={64}
							keyContent="contentMissionTen"
							keyText="correct_course_screen_4_chat_answer"
							defaultValue="Sounds like a plan!"
							position="top" />
					]
				});
				break;
			}

			case 5: {

				setChatStep((prevStep) => prevStep + 1);
				missionContext.setStep((prevState) => prevState + 1);
			}
		}
	}, [ chatStep, missionContext ]);
	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		setMessages((prevState) => (
			[ ...prevState, { type: "to", message } ]
		));
		setAnswers(null);
		setChatStep((prevStep) => prevStep + 1);
	}

	// Render
	return (
		<Box
			ref={ref}
			{...props}
			className="mission-main main"
			component="main"
			sx={{
				minHeight: "calc(100vh - 48px - 36px - 35px - 20px)",
				position: "relative",
				top: "-30px",
				left: "10px"
			}}
		>
			<Box className="main__content" sx={{ marginTop: "15px", alignItems: "center" }}>
				<Chat
					titleMarginTop="10px"
					titleMarginLeft="5px"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionTen"
						keyText="correct_course_correct_course_title"
						defaultValue="Correct the course"
					/>}
					messages={messages}
					isAlignItems
					answers={answers}
					onResponseAnswer={onResponseAnswer}
				/>
			</Box>
		</Box>

	);
});

export default ChatPageCorrectCourse;
