import { Box } from "@mui/material";
import StarButton from "../../../../components/StarButton";

interface ButtonsPorps
{
	onOpen(id: number): void;
	setSelectedStar(value: number): void;
	StarValues: any;
}

function Buttons({ onOpen, setSelectedStar, StarValues }: ButtonsPorps)
{
	function handelClick(e: any)
	{
		let elem = e.target;
		let parent = elem.closest("button");
		// parent.style.display = 'none';
		// parent.style.border = '1px solid red';
	}

	return (
		<Box className="buttonsBox" sx={{ position: "relative", width: "700px", m: "140px auto 0px" }}>
			<Box sx={{ position: "absolute", top: { sm: "-161px", md: "-161px", lg: "-111px", xl: "-93px" }, right: "auto", left: { sm: "0", md: "0", lg: "0", xl: "-109px" }, transform: { xs: StarValues[ 0 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(1); setSelectedStar(0); handelClick(e); }} selectedBtn={StarValues[ 0 ].changed} id={0} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "-151px", md: "-151px", lg: "-101px", xl: "-41px" }, right: "auto", left: { sm: "124px", md: "124px", lg: "124px", xl: "74px" }, transform: { xs: StarValues[ 1 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(2); setSelectedStar(1); handelClick(e); }} selectedBtn={StarValues[ 1 ].changed} id={1} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "-110px", md: "-110px", lg: "-60px", xl: "15px" }, right: "auto", left: "283px", transform: { xs: StarValues[ 2 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(3); setSelectedStar(2); handelClick(e); }} selectedBtn={StarValues[ 2 ].changed} id={2} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "-156px", md: "-156px", lg: "-106px", xl: "-46px" }, left: "auto", right: { sm: "88px", md: "88px", lg: "68px", xl: "8px" }, transform: { xs: StarValues[ 3 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(4); setSelectedStar(3); handelClick(e); }} selectedBtn={StarValues[ 3 ].changed} id={3} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "-88px", md: "-88px", lg: "-38px", xl: "63px" }, right: { sm: "144px", md: "144px", lg: "144px", xl: "89px" }, left: "auto", transform: { xs: StarValues[ 4 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(5); setSelectedStar(4); handelClick(e); }} selectedBtn={StarValues[ 4 ].changed} id={4} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "33px", md: "33px", lg: "83px", xl: "193px" }, right: { sm: "87px", md: "87px", lg: "67px", xl: "7px" }, transform: { xs: StarValues[ 5 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(6); setSelectedStar(5); handelClick(e); }} selectedBtn={StarValues[ 5 ].changed} id={5} />
			</Box>

			<Box sx={{ position: "absolute", top: { sm: "33px", md: "33px", lg: "83px", xl: "193px" }, right: "auto", left: { sm: "114px", md: "114px", lg: "84px", xl: "44px" }, transform: { xs: StarValues[ 6 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(7); setSelectedStar(6); handelClick(e); }} selectedBtn={StarValues[ 6 ].changed} id={6} />
			</Box>
			<Box sx={{ position: "absolute", top: { sm: "8px", md: "8px", lg: "58px", xl: "153px" }, right: "auto", left: "202px", transform: { xs: StarValues[ 7 ].changed ? "scale(0.75)" : "scale(1)", xl: "scale(1)" } }}>
				<StarButton onClick={(e) => { onOpen(8); setSelectedStar(7); handelClick(e); }} selectedBtn={StarValues[ 7 ].changed} id={7} />
			</Box>
		</Box>
	);
}

export default Buttons;
