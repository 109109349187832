import { Box, BoxProps, Grow, alpha, capitalize } from "@mui/material";
import useUser from "hooks/useUser";
import { KeyboardEvent, Ref, forwardRef, useLayoutEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import Tour from "reactour";
import IMGOcta2 from "../../../../assets/images/octa2.png";
import ArrowButton from "../../../../components/ArrowButton";
import ChipStyled from "../../../../components/ChipStyled";
import CustomInput from "../../../../components/CustomInput";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import PanelTitle from "../../../../components/missions/PanelTitle";
import StepperNavigationWrapper from "../../../../components/missions/StepperNavigationWrapper";
import { getLocalStorage, setLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";

export interface IJobsAttractYouProps extends BoxProps { }

const INPUT_MAX_LEN: number = 50;
const MAX_VALUES_COUNT: number = 12;

function JobsAttractYou({ ...props }: IJobsAttractYouProps, ref: Ref<HTMLDivElement>)
{
	const { user } = useUser().state;
	const missionContext = useMission();
	const [ inputValue, setInputValue ] = useState<string>("");
	const [ inputError, setInputError ] = useState<string | null>(null);

	const [ values, setValues ] = useState<string[]>(() =>
	{
		const store = getLocalStorage<string[]>(missionContext.missionPrefix + "-jobsThatAttract");

		return store || [];
	});

	// Effects
	useLayoutEffect(() =>
	{
		localStorage.removeItem(missionContext.missionPrefix + "-attractiveInfo");
		setLocalStorage(missionContext.missionPrefix + "-jobsThatAttract", values);
	}, [ missionContext.missionPrefix, values ]);

	// Handles
	function onKeyDown(event: KeyboardEvent<HTMLInputElement>)
	{
		if (event.key !== "Enter")
		{
			return;
		}

		const value = capitalize(inputValue.trim());

		if (value.length < 2)
		{
			setInputError("At least 2 characters");

			return;
		}

		if (values.length < MAX_VALUES_COUNT && values.includes(value) === false)
		{
			setValues((prevState) => [ ...prevState, value ]);
		}

		setInputError(null);
		setInputValue("");
	}

	function nextStep()
	{
		missionContext.nextStep();
	}

	// Render
	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<PanelTitle
					imageVariation="closeEye"
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobsAttractYou_title" defaultValue="Jobs that attract you" position="left" />}
					subTitle={<ContentAdmin keyContent={missionContext.keyContent} keyText="JobsAttractYou_subTitle" defaultValue="Write down all the jobs, careers and roles that attract you. Those don’t\nhave to be realistic, go wild! You can use your dream universes as a starting point!" position="left" isDescription />}
					positionOcta
					sx={{
						"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
						"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
					}}
				/>

				<Box sx={{ width: "478px", marginTop: "100px" }}>
					<Box sx={{ position: "relative" }}>
						<Box sx={{
							position: "absolute",
							right: "5px",
							top: "-19px",
							opacity: 0.5,
							font: "400 14px/19px 'Open Sans'",
							color: "primary.dark"
						}}>
							{values.length}/{MAX_VALUES_COUNT} added
						</Box>

						<CustomInput.Base
							placeholder="Text"
							value={inputValue}
							onChange={({ target }) => setInputValue(capitalize(target.value))}
							onKeyDown={onKeyDown}
							inputProps={{ maxLength: INPUT_MAX_LEN }}
							sx={{
								width: "100%",

								"& .MuiInputBase-input":
								{
									color: "primary.dark",
									font: "400px 22px/30px 'Open Sans'",

									"&::placeholder":
									{
										opacity: 0.5
									}
								}
							}}
						/>

						<Box sx={{
							display: "flex",
							justifyContent: "space-between",
							position: "absolute",
							right: "5px",
							left: "5px",
							bottom: "-19px"
						}}>
							<Box sx={{ color: "#DC4242", font: "400 14px/19px 'Open Sans'" }}>
								{inputError}
							</Box>

							<Box sx={{ opacity: 0.5, font: "400 14px/19px 'Open Sans'", color: "primary.dark" }}>
								{inputValue.length}/{INPUT_MAX_LEN} characters
							</Box>
						</Box>
					</Box>

					<Box
						component={TransitionGroup}
						className="customScroll"
						sx={{
							display: "flex",
							gap: "16px",
							flexWrap: "wrap",
							padding: "0 9px 5px 0",
							marginTop: "25px",
							overflowY: "auto",
							maxHeight: "325px"
						}}
					>
						{values.map((text) =>
						{
							return (
								<Grow key={text} timeout={500}>
									<ChipStyled
										label={text}
										onDelete={() =>
										{
											setValues((prevState) =>
											{
												return prevState.filter((value) => value !== text);
											});
										}}
									/>
								</Grow>
							);
						})}
					</Box>
				</Box>
			</Box>

			<StepperNavigationWrapper absolute>
				<ArrowButton direction="left" disabled hidden />
				<ArrowButton onClick={nextStep} disabled={values.length < 4 || values.length > MAX_VALUES_COUNT} />
			</StepperNavigationWrapper>

			<Tour
				isOpen={missionContext.isShowTour === true}
				rounded={40}
				maskSpace={5}
				showCloseButton={false}
				showNavigation={false}
				showNumber={false}
				disableInteraction
				closeWithMask={false}
				lastStepNextButton={<CustomButton.Outline sx={{ transform: "translateX(-70px)" }}>Got it!</CustomButton.Outline>}
				steps={[
					{
						selector: ".ImageButtonBox",
						position: "bottom",
						content: () =>
						{
							return (
								<Box sx={{ position: "relative", transform: "translateX(-70px)" }}>
									<Box
										component="img"
										src={IMGOcta2}
										sx={{
											position: "absolute",
											left: 0,
											top: { xl: "-110px", lg: "-90px", xs: "-90px" },
											transform: "rotate(-9.11deg) translateX(-80%)",
											width: { xl: "148px", lg: "117px", xs: "117px" }
										}}
									/>

									<Box sx={{
										width: { xl: "375px", lg: "286px", xs: "286px" },
										background: "#E6F4F6",
										boxShadow: "0px 3px 6px " + alpha("#000000", 0.5),
										border: "1px solid " + alpha("#FFFFFF", 0.5),
										borderRadius: "16px",
										padding: "24px",
										font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
										color: "primary.dark"
									}}>
										{user?.name || "Name"}, here are the universes you’ve created, you can refer to them for inspiration!
									</Box>
								</Box>
							);
						}
					}
				]}
				onRequestClose={() => missionContext.setIsShowTour(false)}
			/>
		</Box>
	);
}

export default forwardRef(JobsAttractYou);
