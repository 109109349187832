import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Love()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-love-reflection"
					defaultValue="- Do you have or want a significant other?\n- If you have a partner, do you share values?\n- Do you have time and space for love in life?\n- Have you dealt with the trauma from past relationships?\n- Do you feel in a good place as an individual to attract healthy love?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-love-actionPlan"
					defaultValue="1. **Address what’s holding you back.** Heal trauma from your past relationships, work on your love-related fears and challenge limiting beliefs.\n1. **Create space for love.** Re-assess your priorities to create time and space for a romantic relationship in your life.\n1. **Become an attractive partner.** Become a confident, fulfilled person living an exciting life. Take good care of yourself, do what you love and have fun."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-love-howCanHelp"
					defaultValue="My Life Quest will help you design and live an extraordinary life, so that you become the person who attracts love wherever you go."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/N6fRSx7UKs4?si=uAdwMTj5ArHm5dVb"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Love;
