import { createTheme } from "@mui/material/styles";

const theme = createTheme(
	{
		palette:
		{
			primary:
			{
				main: "#215C75",
				dark: "#4D4D4D",
				light: "#fff"
			}
		},
		breakpoints:
		{
			values:
			{
				xs: 0,
				sm: 700,
				md: 1024,
				lg: 1280,
				lg2: 1420,
				xl: 1700
			}
		}
	}
);

export default theme;
