import ContentAdmin from "components/contentAdmin";
import Card from "../components/Card";

function Money()
{
	return (
		<>
			<Card title="Questions for reflection">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-money-reflection"
					defaultValue="- Do you earn enough?\n- Are you planning for financial freedom?\n- How much money per month do you need to feel comfortable?\n- What limiting beliefs do you have about money?\n- What are your biggest money-related fears?"
					isDescription
				/>
			</Card>

			<Card title="Action plan">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-money-actionPlan"
					defaultValue="1. **Improve your financial literacy.** Educate yourself and seek guidance from financial experts to improve your understanding of wealth-building strategies. \n1. **Challenge your limiting beliefs.** Identify and your beliefs about money that may be holding you back. Replace those beliefs with positive affirmations that align with your financial goals and aspirations.\n1. **Face money-related fears.** List your specific money-related fears. Break down each fear into manageable steps or actions that can help address or mitigate them."
					isDescription
				/>
			</Card>

			<Card title="How we can help">
				<ContentAdmin
					keyContent="contentMissionOne"
					keyText="contents-money-howCanHelp"
					defaultValue="My Life Quest will help you face your fears, challenge limiting beliefs and create a step-by-step plan to your financial freedom."
					isDescription
				/>

				<div className="video-container">
					<iframe
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/RKA86KIpMQQ?si=ULs9HqBrqMItmgzF"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						style={{
							border: "unset",
							borderRadius: "inherit"
						}}
					/>
				</div>
			</Card>
		</>
	);
}

export default Money;
