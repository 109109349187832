import { Box, alpha, keyframes } from "@mui/material";
import IMGOcta2Shrink from "assets/images/octa2-shrink.png";
import AccessToMission from "components/Dialog/AccessToMission";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import trackEvent from "utils/mixpanelUtil";
import randomInt from "utils/randomInt";
import AsidePanel from "../aside";
import { IMission } from "./mission.interface";
import { getMissionNameById } from "./missionsData";

const QUOTES = [
	<ContentAdmin key={1} position="left" defaultValue="Hey, {NAME}, I’ve missed you! So good to see you again." keyContent="contentDashboard" keyText="quotes1" />,
	<ContentAdmin key={2} position="left" defaultValue="{NAME}, you are rocking it, keep up the great work!" keyContent="contentDashboard" keyText="quotes2" />,
	<ContentAdmin key={3} position="left" defaultValue="Hey, {NAME}, welcome back! Your next mission is ready." keyContent="contentDashboard" keyText="quotes3" />,
	<ContentAdmin key={4} position="left" defaultValue="{NAME}, you are a true trailblazer, keep going!" keyContent="contentDashboard" keyText="quotes4" />,
	<ContentAdmin key={5} position="left" defaultValue="Hi, {NAME}! Let’s work on your next mission!" keyContent="contentDashboard" keyText="quotes5" />,
	<ContentAdmin key={6} position="left" defaultValue="{NAME}, I am happy to see you back!" keyContent="contentDashboard" keyText="quotes6" />
	// "Hey, [NAME], I’ve missed you! So good to see you again.",
	// "[NAME], you are rocking it, keep up the great work!",
	// "Hey, [NAME], welcome back! Your next mission is ready.",
	// "[NAME], you are a true trailblazer, keep going!",
	// "Hi, [NAME]! Let’s work on your next mission!",
	// "[NAME], I am happy to see you back!"
];

const MAX_MISSION_ACCESS: number = 10;

interface IAsideProps
{
	readonly mission: IMission;
	readonly setShowBuyDialog: Dispatch<SetStateAction<number | null>>;
}

const waveAnimation = keyframes`
	0%
	{
		left: -10%;
		top: -10px;
	}
	50%
	{
		left: 110%;
	}
	80%
	{
		left: 200%;
		top: -10px;
		bottom: 0px;
	}
	100%
	{
		bottom: 200%;
		top: -200%;
	}
`;

function MissionsAside({ mission, setShowBuyDialog }: IAsideProps)
{
	const navigate = useNavigate();
	const { state: { user: userState } } = useUser();
	const [ quote, setQuote ] = useState<JSX.Element | null>(null);
	const [ open, setOpen ] = useState(false);
	const isBlockStartButton: boolean = (window.location.hostname !== "localhost" && mission.id > MAX_MISSION_ACCESS);
	const isTester = userState?.userRole === "tester" || false;
	const isAdmin = userState?.userRole === "admin" || false;
	const isLock = (isTester === false && isAdmin === false) && mission.isLock === true;

	const isPayment = (
		mission.id > 1
			? (
				userState?.subscriptionIsActive === true
				|| userState?.fullSubscribe === true
				|| isTester === true
				|| isAdmin === true
			)
			: true
	);

	// Effects
	useLayoutEffect(() =>
	{
		const quoteSelected: number = randomInt(0, QUOTES.length - 1);

		setQuote(QUOTES[ quoteSelected ]);
	}, []);

	// Handles
	function handleLink()
	{
		if (isPayment === false)
		{
			return navigate("/quest-power");
		}

		if (isLock === true)
		{
			return;
		}

		trackEvent({
			eventName: "Mission Started",
			userId: userState?.id,
			properties: { Mission: getMissionNameById(mission.id) }
		});

		navigate(mission.link || "/temporary-page");
	}

	function startButtonContent()
	{
		// if (isPayment === false || isLock === true)
		// {
		// 	return (<Box component={SVGLock} />);
		// }

		if (mission.complete > 0)
		{
			return "Resume";
		}

		return "Start";
	}

	// Render
	return (
		<AsidePanel
			header={(
				<Box sx={{ width: "100%", position: "absolute" }}>
					<Box
						component="img"
						src={IMGOcta2Shrink}
						alt=""
						sx={{
							position: "absolute",
							left: { xl: "19px", lg: "16px", xs: "16px" },
							top: "0",
							width: { xl: "179px", lg: "127px", xs: "127px" },
							height: { xl: "324px", lg: "230px", xs: "230px" },
							zIndex: -1
						}}
					/>

					<Box sx={{
						width: { xl: "288px", lg: "200px", xs: "200px" },
						padding: { xl: "24px 31px", lg: "17px", xs: "17px" },
						backgroundColor: alpha("#FFFFFF", 0.5),
						backdropFilter: "blur(30px)",
						boxShadow: "0px 5px 20px " + alpha("#7EC4FF", 0.4),
						border: "2px solid " + alpha("#FFFFFF", 0.5),
						borderRadius: "16px",
						font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
						color: "primary.dark",
						marginLeft: "auto"
					}}>
						{quote}
					</Box>
				</Box>
			)}
			navigationProps={{ "data-tut": "reactour__nextMission_aside" }}
		>
			<Box sx={{
				position: "relative",
				font: { xl: "700 40px/51px Lora-Bold", lg: "700 32px/41px Lora-Bold", xs: "700 32px/41px Lora-Bold" },
				color: "primary.main",
				margin: { xl: "32px 32px 0 32px", lg: "20px 16px 0 16px", xs: "20px 16px 0 16px" }
			}}>
				<ContentAdmin position="right" defaultValue="My Next Mission" keyContent="contentDashboard" keyText="title" />
				<Box sx={{
					position: "absolute",
					right: "0px",
					top: { xl: "-40px", lg: "-28px", xs: "-28px" },
					font: { xl: "700 96px/131px 'Open Sans'", lg: "700 64px/87px 'Open Sans'", xs: "700 64px/87px 'Open Sans'" },
					color: "#3E809D"
				}}>
					{mission.id}
				</Box>
			</Box>

			{/* Mission title */}
			<Box sx={{
				height: { xl: "64px", lg: "45px", xs: "45px" },
				width: { xl: "488px", lg: "343px", xs: "343px" },
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				gap: "16px",
				backgroundColor: "#3E809D",
				backdropFilter: "blur(30px)",
				boxShadow: "0px 5px 20px " + alpha("#3E809D", 0.4),
				borderRadius: "64px 0 0 64px",
				marginLeft: "auto",
				marginRight: "-1px",
				padding: { xl: "12px 32px 12px 40px", lg: "11px 20px 12px 16px", xs: "11px 20px 12px 16px" },
				color: "#FFFFFF",
				font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 18px/23px Lora-Bold" }
			}}>
				{mission.title}

				<Box sx={{
					backgroundColor: alpha("#FFFFFF", 0.5),
					borderRadius: "40px",
					font: { xl: "600 22px/30px 'Open Sans'", lg: "600 16px/22px 'Open Sans'", xs: "600 16px/22px 'Open Sans'" },
					padding: { xl: "5px 18px", lg: "3.5px 12px", xs: "3.5px 12px" }
				}}>
					0:{mission.time.toString().padStart(2, "0")}
				</Box>
			</Box>

			{/* Mission description */}
			<Box sx={{
				width: { xl: "416px", lg: "307px", xs: "307px" },
				margin: { xl: "6px 32px 0 auto", lg: "0px 16px 0 auto", xs: "6px 16px 0 auto" },
				font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: "primary.dark"
			}}>
				{mission.description}
			</Box>

			{/* Mission image */}
			<Box
				component="img"
				src={mission.imageMissionsAside}
				alt={mission.title}
				sx={{
					width: "100%",
					maxHeight: "160px",
					marginTop: "auto",
					filter: (isLock ? "blur(20px)" : "unset")
				}}
			/>

			{/* Button */}
			<CustomButton.Base
				// component={Link}
				// to={(isPayment === false ? "/packages" : (mission.link || "/temporary-page"))}
				onClick={handleLink}
				disabled={isBlockStartButton || (isPayment === true && isLock === true)}
				sx={{
					margin: { xl: "0 auto 32px auto", lg: "8px auto 24px auto", xs: "8px auto 32px auto" },
					overflow: "hidden",

					"& svg":
					{
						fill: "#215C75"
					},
					"&:hover svg":
					{
						fill: "#215C75"
					},
					"&:hover::before, &:disabled::before":
					{
						opacity: 0
					},
					"&::before":
					{
						animation: waveAnimation + " 3s infinite ease-in-out",
						content: "''",
						position: "absolute",
						top: "-20px",
						bottom: "-20px",
						left: "0",
						width: "25px",
						background: alpha("#FFFFFF", 0.5),
						transform: "rotate(20deg)"
					}
				}}
			>
				{startButtonContent()}
			</CustomButton.Base>
			<AccessToMission open={open} onClose={() => setOpen(false)} />
		</AsidePanel>
	);
}

export default MissionsAside;
