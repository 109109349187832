import { Box } from "@mui/material";
import { useState } from "react";
import IMGSRC from "../../../../assets/images/icons/ball-list.svg";
import IMGBg from "../../../../assets/images/missions/mission-eight/bigBall.png";
import RadialButtonBall from "../../../../components/RadialButtonBall";
import CustomButton from "../../../../components/customButton";
import useMission from "../../hooks";

interface ButtonsPorps
{
	open: boolean;
	onOpen(): void;
	setSelectedButton(value: number): void;
	selectedButton: any;

}

function Buttons({ open, onOpen, setSelectedButton, selectedButton }: ButtonsPorps)
{
	// States
	const missionContext = useMission();
	const [ isButtonState, setIsButtonState ] = useState(
		{
			oneButton: { open: false },
			twoButton: { open: false }
		}
	);

	// Handles
	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
	}

	function onShowButtonOne()
	{
		setIsButtonState((prevState) => (
			{
				...prevState,
				oneButton: {
					open: true
				},
				twoButton: {
					...prevState.twoButton
				}
			}
		));
	}

	function onShowButtonTwo()
	{
		setIsButtonState((prevState) => (
			{
				...prevState,
				oneButton: {
					...prevState.oneButton
				},
				twoButton: {
					open: true
				}
			}
		));
	}

	function onOpenButtonTwo()
	{
		onOpen();
		setSelectedButton(2);
		onShowButtonTwo();
	}

	function onOpenButtonOne()
	{
		onOpen();
		setSelectedButton(1);
		onShowButtonOne();
	}

	return (
		<Box sx={{
			position: { xs: "relative", md: "absolute" },
			width: "615px",
			height: "615px",
			backgroundImage: `url(${ IMGBg })`,
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			boxShadow: "0px 0 35px 7px rgb(255 255 255 / 66%)",
			borderRadius: "50%",
			top: "0px",
			left: (open ? (selectedButton === 1 ? "calc(100% - 615px)" : "0px") : "calc(50% - 274px)")
		}}>
			{!open &&
				<>
					<Box sx={{
						position: "absolute", top: "354px", right: "160px"

					}}>
						<RadialButtonBall
							sx={{ backdropFilter: "none" }}
							imgRadialButton={IMGSRC}
							direction="left"
							value="Emergency plan"
							color="blue"
							absoluteValuePosition
							onClick={onOpenButtonOne}
						/>
					</Box>
					<Box sx={{ position: "absolute", top: "214px", right: "399px" }}>
						<RadialButtonBall
							sx={{ backdropFilter: "none" }}
							imgRadialButton={IMGSRC}
							value="Immediate action plan"
							color="blue"
							absoluteValuePosition
							onClick={onOpenButtonTwo}
						/>
					</Box>
					<Box sx={{
						position: "absolute",
						// top: '97%',
						top: {
							xs: "89%",
							md: "96%"
						},
						left: "50%",
						transform: "translateX(-50%)"
					}}>
						<CustomButton.Outline
							disabled={!isButtonState.oneButton.open || !isButtonState.twoButton.open}
							onClick={nextStep}
							sx={{
								background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)",
								backdropFilter: "blur(25px)",
								color: "#fff !important",
								borderColor: "#fff",
								boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
								"&:hover": {
									cursor: "pointer",
									background: "linear-gradient(103.55deg, rgba(205, 204, 204, 0.3) 9.99%, rgba(239, 239, 239, 0.066) 91.61%)"
								}
							}}
						>
							Submit
						</CustomButton.Outline>
					</Box>
				</>
			}
		</Box>
	);
}

export default Buttons;
