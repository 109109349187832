import { Box } from "@mui/material";
import { forwardRef, Fragment, Ref, useEffect, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import PanelTitle from "../../../../components/missions/PanelTitle";

import useMission from "../../hooks";

import Buttons from "./feature/Buttons";
import FormMyPurposeIs from "./Forms/FormMyPurposeIs";
import FormMyTribeIs from "./Forms/FormMyTribeIs";
import FormQuotes from "./Forms/FormQuotes";
import FormStandFor from "./Forms/FormStandFor";
import FormThisIsWhy from "./Forms/FormThisIsWhy";

import { getLocalStorage } from "../../../../utils/localStorage";

const steps = [ "Shipping address", "Payment details", "Review your order" ];

const SetCourse = forwardRef((
	props: Record<string, any>,
	ref: Ref<HTMLDivElement>
) =>
{
	const [ stepCourse, setStepCourse ] = useState<number>(0);
	const [ disabledButtons, setDisabledButtons ] = useState<[ boolean, boolean, boolean, boolean, boolean, boolean ]>(
		[ true, true, true, true, true, true ]
	);

	function handleBlip(key: number)
	{
		setDisabledButtons((prevState) => (
			Object.assign([], prevState, { [ key ]: false })
		));
	}

	const missionContext = useMission();
	const [ componentInputs, setcomponentInputs ] = useState<any>([
		{
			title: "My purpose is... ",
			value: "",
			placeholder: "To inspire and lead others to realise their full potential, be happy \nand live their best life.",
			error: ""
		},
		{
			title: "Quotes I live by...",
			items: [
				{
					id: 1,
					value: "",
					error: "",
					placepolder: "To be a chef, not a cook"
				},
				{
					id: 2,
					value: "",
					error: "",
					placepolder: "There is only one success in life — to be able to spend it in your own way"
				},
				{
					id: 3,
					value: "",
					error: "",
					placepolder: "Everything is possible, the impossible just takes longer"
				}
			]
		},
		{
			title: "My tribe is...",
			value: "",
			placeholder: "Ambitious and hard-working people, critical thinkers, brave, daring and fun!",
			error: ""
		},
		{
			title: "This is what I stand for...",
			items: [
				{
					id: 1,
					value: "",
					error: "",
					placepolder: "Being honest with myself and others"
				},
				{
					id: 2,
					value: "",
					error: "",
					placepolder: "Conquering fears"
				},
				{
					id: 3,
					value: "",
					error: "",
					placepolder: "Being kind"
				},
				{
					id: 4,
					value: "",
					error: "",
					placepolder: "Being mindful & present"
				},
				{
					id: 5,
					value: "",
					error: "",
					placepolder: "Human connection first"
				}
			]
		},
		{
			title: "This is why I want to fulfill my purpose...",
			value: "",
			placeholder: "I want to help people suffer less from their unfulfilled potential",
			error: ""
		}

	]);
	const [ showForm, setShowForm ] = useState(false);

	// Handles
	function checkValue(num: number, checkInput: boolean)
	{
		if (componentInputs[ num ].items)
		{
			let count: number = 0;

			componentInputs[ num ].items.forEach((element: any) =>
			{
				if (element.value !== "") count += 1;
			});
			if (checkInput)
			{
				if (count === componentInputs[ num ].items.length) { return false; }
			}

			else
			{
				if (num === 3 && count >= 5) return false;
				if (num === 1 && count >= 3) return false;
			}
		}
		else
		{
			if (componentInputs[ num ].value) { return false; }
		}

		return true;

	}

	function closeForm()
	{
		setShowForm(false);

	}

	function addInput(index: number)
	{
		const newcomponentInputs = [ ...componentInputs ];
		let newItem = {
			id: componentInputs[ index ].items.length + 1,
			value: "",
			error: "",
			placepolder: ""
		};

		newcomponentInputs[ index ].items.push(newItem);
		setcomponentInputs(newcomponentInputs);
	}

	function handleChange(value: string, indexArr: number)
	{
		const newcomponentInputs = [ ...componentInputs ];

		newcomponentInputs[ indexArr ].value = value;
		setcomponentInputs(newcomponentInputs);
	}

	function handleChangeArrInputs(value: string, indexArr: number, indexItem: number)
	{
		const newcomponentInputs = [ ...componentInputs ];

		newcomponentInputs[ indexArr ].items[ indexItem ].value = value;
		setcomponentInputs(newcomponentInputs);
	}

	const Components = [
		<FormMyPurposeIs
			setStepCourse={setStepCourse}
			closeForm={closeForm}
			componentInputs={componentInputs}
			addInput={addInput}
			handleChange={handleChange}
		/>,
		<FormQuotes
			setStepCourse={setStepCourse}
			closeForm={closeForm}
			componentInputs={componentInputs}
			addInput={addInput}
			handleChangeArrInputs={handleChangeArrInputs}
		/>,
		<FormMyTribeIs
			setStepCourse={setStepCourse}
			closeForm={closeForm}
			componentInputs={componentInputs}
			addInput={addInput}
			handleChange={handleChange}
		/>,
		<FormStandFor
			setStepCourse={setStepCourse}
			closeForm={closeForm}
			componentInputs={componentInputs}
			addInput={addInput}
			handleChangeArrInputs={handleChangeArrInputs}
		/>,
		<FormThisIsWhy
			setStepCourse={setStepCourse}
			closeForm={closeForm}
			componentInputs={componentInputs}
			addInput={addInput}
			handleChange={handleChange}
		/>
	];

	function handleShowButtons(step: number)
	{
		setStepCourse(step);
		setShowForm(true);
	}

	useEffect(() =>
	{
		const getComponentInputs: any = getLocalStorage("mission-nine-inputs");
		if (getComponentInputs)
		{
			setcomponentInputs(getComponentInputs);
		}
	}, []);

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				height: "75vh",
				width: "100%",
				maxWidth: { lg: "814px", xl: "990px" },
				m: { lg: "30px auto 0", xl: "0 auto" },
				position: "relative",
				top: "-12px",
				left: "-21px"
			}}
		>
			<Box
				sx={{
					maxWidth: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column"
				}}>
				<PanelTitle
					color="#ffffff"
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionNine"
						keyText="you_set_course_title"
						defaultValue="The North Star guides the way, \nbut you set the course"
						position="right"
					/>
					}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionNine"
						keyText="you_set_course_sub_title"
						defaultValue="{NAME}, it’s time to set your North Star. Embrace your most empowered, and amazing self, let go of any fears and concerns and set a bold vision for your life!"
						position="right"
						isDescription
					/>
					}
				/>
				{missionContext.showContent
					? (
						<>
							{showForm ?
								<Box sx={{ display: "flex", height: "100%" }}>
									{Components.map((Component, index) =>
									{
										if (stepCourse === index + 1)
										{
											return (
												<Box
													sx={{ margin: "auto", width: "100%" }}
													key={index}
												>
													{Component}
												</Box>
											);
										}

										return <Fragment key={index} />;
									})}
								</Box>
								:
								<Buttons
									checkValue={checkValue}
									handleShowButtons={handleShowButtons}
									setStepCourse={setStepCourse}
									disabledButtons={disabledButtons}
									handleBlip={handleBlip} />
							}
						</>
					)
					: null
				}
			</Box>
		</Box>
	);
});

export default SetCourse;
