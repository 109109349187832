import { Box, Button, CardMedia, TextField } from "@mui/material";
import Mission10Img from "assets/images/missions/mission-eleven/Mission10Img.png";
import Mission2Img from "assets/images/missions/mission-eleven/Mission2Img.png";
import Mission3Img from "assets/images/missions/mission-eleven/Mission3Img.png";
import Mission4Img from "assets/images/missions/mission-eleven/Mission4Img.png";
import Mission5Img from "assets/images/missions/mission-eleven/Mission5Img.png";
import Mission6Img from "assets/images/missions/mission-eleven/Mission6Img.png";
import Mission7Img from "assets/images/missions/mission-eleven/Mission7Img.png";
import Mission8Img from "assets/images/missions/mission-eleven/Mission8Img.png";
import Mission9Img from "assets/images/missions/mission-eleven/Mission9Img.png";
import firstMissionImg from "assets/images/missions/mission-eleven/firstMissionImg.png";
import letter from "assets/images/missions/mission-eleven/letter.svg";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import { ChangeEvent, useState } from "react";
import useMission from "../../hooks";

function Letter()
{
	const missionContext = useMission();
	const [ inputValue, setInputValue ] = useState<string>("");

	// Handles
	function onChangeInput({ target }: ChangeEvent<HTMLTextAreaElement>)
	{
		setInputValue(target.value.replaceAll(/\n{3,}/g, "\n\n"));
	}

	function sendLetter()
	{
		localStorage.setItem("mission-eleven-fears-resolve", String(inputValue));
		missionContext.setStep((prevState) => prevState + 1);
	}

	// Render
	return (
		<Box sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between", height: "81vh", width: "100%", maxWidth: { sm: "784px", md: "784px", lg: "784px", xl: "980px" } }}>
			<Box sx={{ maxWidth: "100%", position: "relative" }}>
				<PanelTitle
					zIndexOcta="1"
					color="#ffffff"
					positionOcta
					imageVariation="closeEye"
					title={<ContentAdmin
						limit={111}
						keyContent="contentMissionEleven"
						keyText="letter_title"
						defaultValue="Write yourself a letter"
						position="left"
					/>
					}
					subTitle={<ContentAdmin
						limit={300}
						keyContent="contentMissionEleven"
						keyText="letter_sub_title"
						defaultValue="{NAME}, when embarking on a journey, you are full of ideas, energy and enthusiasm. Use that to keep you going through your hard times. Write yourself an empowering letter which you can read when things get rough."
						position="left"
						isDescription
					/>
					}
				/>
				<Box sx={{
					width: { sm: "784px", md: "784px", lg: "784px", xl: "980px" },
					boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
					background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
					border: "1px solid rgba(255, 255, 255, 0.5)",
					p: { sm: "30px 155px 33px", md: "30px 155px 33px", lg: "30px 155px 33px", xl: "30px 195px 33px" },
					backdropFilter: "blur(30px)",
					borderRadius: "48px",
					mt: 3,
					position: "relative"
				}}>
					<Box sx={{
						width: { sm: "474px", md: "474px", lg: "474px", xl: "592px" },
						height: { sm: "392px", md: "392px", lg: "392px", xl: "518px" },
						background: "rgba(255, 255, 255, 0.6)",
						borderRadius: "8px",
						p: { sm: "29px 29px 20px", md: "29px 29px 20px", lg: "29px 29px 20px", xl: "29px 36px 20px" },
						position: "relative",
						overflow: "auto"
					}}>
						<Box sx={{ fontFamily: "Satisfy", color: "#4D4D4D", fontSize: "24px", opacity: 0.5, textAlign: "center", height: "36px" }}>
							{inputValue === "" && <ContentAdmin
								limit={300}
								keyContent="contentMissionEleven"
								keyText="letter_write_yourself"
								defaultValue="Write yourself a letter"
								position="top"
							/>}
						</Box>

						<TextField
							id="letterValue"
							name="letterValue"
							multiline
							minRows={12}
							value={inputValue}
							onChange={onChangeInput}
							inputProps={{ maxLength: 1000 }}
							sx={{
								width: { sm: "416px", md: "416px", lg: "416px", xl: "520px" },
								height: "458px",
								position: "absolute",
								top: "10px",
								maxHeight: "458px",

								".MuiOutlinedInput-root": {
									p: "16px 0"
								},
								".MuiOutlinedInput-input": {
									font: '400 22px/40px "Satisfy" !important',
									color: "#4D4D4D !important",
									overflow: "hidden",
									background: "linear-gradient(rgba(255, 255, 255, 0.5) 2px, transparent 0px)",
									backgroundSize: "100% 40px",
									backgroundPositionY: "28px"
								},
								"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
									border: "none"
								}
							}}
						/>
					</Box>

					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: "29px", left: "19px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={firstMissionImg}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "109px", md: "109px", lg: "109px", xl: "136px" }, left: { sm: "103px", md: "103px", lg: "103px", xl: "123px" } }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission2Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "189px", md: "189px", lg: "189px", xl: "243px" }, left: "19px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission3Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "269px", md: "269px", lg: "269px", xl: "350px" }, left: { sm: "103px", md: "103px", lg: "103px", xl: "123px" } }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission4Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "349px", md: "349px", lg: "349px", xl: "457px" }, left: "19px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission5Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: "29px", right: "22px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission6Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "109px", md: "109px", lg: "109px", xl: "136px" }, right: { sm: "106px", md: "106px", lg: "106px", xl: "126px" } }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission7Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "189px", md: "189px", lg: "189px", xl: "243px" }, right: "22px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission8Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "269px", md: "269px", lg: "269px", xl: "350px" }, right: { sm: "106px", md: "106px", lg: "106px", xl: "126px" } }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission9Img}
							alt=""
						/>
					</Box>
					<Box sx={{ width: { sm: "80px", md: "80px", lg: "80px", xl: "100px" }, position: "absolute", top: { sm: "349px", md: "349px", lg: "349px", xl: "457px" }, right: "22px" }}>
						<CardMedia
							component="img"
							width="100%"
							image={Mission10Img}
							alt=""
						/>
					</Box>
					<Button
						sx={{
							width: { xs: "62px", xl: "77px" },
							height: { xs: "62px", xl: "77px" },
							borderRadius: "50%",
							position: "absolute",
							// bottom: '-30px',
							left: { sm: "361px", md: "361px", lg: "361px", xl: "461px" },
							border: "2px solid #FFFFFF",
							background: "linear-gradient(180deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.102) 100%)",
							filter: "drop-shadow(3px 3px 10px rgba(28, 130, 170, 0.659))",
							backdropFilter: "blur(30px)"
						}}
						disabled={inputValue.trim().length === 0}
						onClick={sendLetter}
					>
						<CardMedia
							component="img"
							width="100%"
							image={letter}
							alt=""
						/>
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default Letter;
