import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, useTheme } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import Api from "api";
import { IGetLetterData } from "api/Admin";
import { ChangeEvent, MouseEvent, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import getSearchParamsValue from "utils/getSearchParamsValue";

const COLS: string[] = [
	"No",
	"Step",
	"Email",
	"Email subject",
	"Text",
	"What to personalize",
	"When to send",
	"To send to",
	"Comment",
	"Triggered times",
	"Opened",
	"Bounced",
	"Clicked",
	"CTR"
];

function Emails()
{
	const firstInitRef = useRef<boolean>(true);

	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ rows, setRows ] = useState<IGetLetterData[]>([]);
	const [ page, setPage ] = useState<number>(() =>
	{
		const value = getSearchParamsValue(searchParams, "page", "number");

		return value === null ? 0 : value - 1;
	});
	const [ rowsPerPage, setRowsPerPage ] = useState<number>(getSearchParamsValue(searchParams, "rowsPerPage", "number") ?? 5);

	// Effects
	useLayoutEffect(() =>
	{
		if (firstInitRef.current === false)
		{
			return;
		}

		firstInitRef.current = false;

		Api.admin
			.getAllLetters()
			.then((data) => setRows(data));
	}, []);

	useLayoutEffect(() =>
	{
		const updatedParams = new URLSearchParams(searchParams);

		updatedParams.set("page", String(page + 1));
		updatedParams.set("rowsPerPage", String(rowsPerPage));

		setSearchParams(updatedParams);
	}, [ page, rowsPerPage, searchParams, setSearchParams ]);

	// Handles
	function handleChangePage(_event: MouseEvent<HTMLButtonElement> | null, newPage: number)
	{
		setPage(newPage);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	// Render
	return (
		<Table>
			<TableHead>
				<TableRow>
					{COLS.map((col) =>
					{
						return (
							<TableCell
								key={col}
								sx={{
									fontWeight: "bold",
									verticalAlign: "top",
									whiteSpace: "nowrap"
								}}
							>
								{col}
							</TableCell>
						);
					})}
				</TableRow>
			</TableHead>

			<TableBody>
				{(rowsPerPage > 0
					? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: rows
				).map((row) =>
				{
					return (
						<TableRow key={row._id}>
							<TableCell sx={{ verticalAlign: "top" }}>{row.id}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.step}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.email}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.letterSubject}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>
								{row.textLink.link
									? (<a href={row.textLink.link} target="_blank" rel="noreferrer">{row.textLink.text}</a>)
									: (row.textLink.text)
								}
							</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.personalize}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.whatToSend}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.toWhomToSend}</TableCell>
							<TableCell sx={{ verticalAlign: "top" }}>{row.comment}</TableCell>
							<TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>{row.trigerredTimes}</TableCell>
							<TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>{row.opened}</TableCell>
							<TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>{row.bounced}</TableCell>
							<TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>{row.clicked}</TableCell>
							<TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>{row.ctr}</TableCell>
						</TableRow>
					);
				})}
			</TableBody>

			<TableFooter>
				<TableRow>
					<TablePagination
						rowsPerPageOptions={[ 5, 10, 15, 25, 50, { label: "All", value: -1 } ]}
						colSpan={COLS.length}
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{ inputProps: { "aria-label": "rows per page" }, variant: "standard" }}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</TableRow>
			</TableFooter>
		</Table>
	);
}

export function TablePaginationActions(props: TablePaginationActionsProps)
{
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	function handleFirstPageButtonClick(event: MouseEvent<HTMLButtonElement>)
	{
		onPageChange(event, 0);
	}

	function handleBackButtonClick(event: MouseEvent<HTMLButtonElement>)
	{
		onPageChange(event, page - 1);
	}

	function handleNextButtonClick(event: MouseEvent<HTMLButtonElement>)
	{
		onPageChange(event, page + 1);
	}

	function handleLastPageButtonClick(event: MouseEvent<HTMLButtonElement>)
	{
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	}

	return (
		<div style={{ flexShrink: 0, marginLeft: "24px" }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

export default Emails;
