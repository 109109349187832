import MissionOneContainer from "pages/missionOne";
import MissionOneResult from "pages/missionOne/Results";
import MissionTwoContainer from "pages/missionTwo";
import Packages from "pages/ourPackages";
import PrivacyPolicy from "pages/privacyPolicy";
import QuestPower from "pages/questPower";
import Registration from "pages/registration";
import CompleteRegistration from "pages/registration/Complete";
import MissionOneFirstComplete from "pages/registration/FirstComplete";
import StartPage from "pages/startPage";
import TermsConditions from "pages/termsConditions";
import { Navigate } from "react-router-dom";
import AdminPage from "./pages/admin/AdminPage";
import LoginAdmin from "./pages/admin/Login";
import ComponentTesting from "./pages/component-testing";
import Dashboard from "./pages/dashboard";
import DetailsContainer from "./pages/details";
import DetailsContainerShared from "./pages/details/shared";
import FirstStepSection from "./pages/firstStep";
import LoginPage from "./pages/loginPage";
import MissionEightContainer from "./pages/missionEight";
import MissionElevenContainer from "./pages/missionEleven";
import MissionFiveContainer from "./pages/missionFive";
import MissionFourContainer from "./pages/missionFour";
import MissionNineContainer from "./pages/missionNine";
import MissionSevenContainer from "./pages/missionSeven";
import MissionSixContainer from "./pages/missionSix";
import MissionTenContainer from "./pages/missionTen";
import MissionThreeContainer from "./pages/missionThree";
import ResetPasswordStepOne from "./pages/resetPasswordStepOne";
import ResetPasswordStepTwo from "./pages/resetPasswordStepTwo";
import TemporaryPage from "./pages/temporaryPage";
import WelcomPage from "./pages/welcome";

interface IRouteData
{
	path: string;
	element: JSX.Element;
}

interface IPrivateRouteData extends IRouteData
{
	/**
	 * @default "/login"
	 */
	redirectPath?: string;
}

export const publicRoutes: IRouteData[] = [
	{ path: "/registration", element: <Registration /> },
	{ path: "/registration/complete", element: <CompleteRegistration /> },
	{ path: "/registration/first-complete", element: <MissionOneFirstComplete /> },
	{ path: "/details", element: <DetailsContainer /> },
	{ path: "/detailsshared/:userid", element: <DetailsContainerShared /> },
	{ path: "/artifact/wheel-of-life/details/:userid", element: <DetailsContainerShared /> },
	{ path: "/firststep", element: <FirstStepSection /> },
	{ path: "/component-testing", element: <ComponentTesting /> },
	{ path: "/mission-one", element: <MissionOneContainer /> },
	{ path: "/privacy-policy", element: <PrivacyPolicy /> },
	{ path: "/terms-conditions", element: <TermsConditions /> }
];

export const privateRoutes: IPrivateRouteData[] = [
	{ path: "/", element: <Navigate to="/dashboard" replace /> },
	{ path: "/welcome", element: <WelcomPage /> },
	{ path: "/admin", element: <LoginAdmin /> },
	{ path: "/admin/:tab", element: <AdminPage /> },
	{ path: "/dashboard", element: <Dashboard /> },
	{ path: "/dashboard/artifacts/wheel_of_life_details/:userid", element: <DetailsContainerShared /> },
	{ path: "/dashboard/:tab/:subtab/:action/:data", element: <Dashboard /> },
	{ path: "/dashboard/:tab/:subtab/:action", element: <Dashboard /> },
	{ path: "/dashboard/:tab/:subtab", element: <Dashboard /> },
	{ path: "/dashboard/:tab", element: <Dashboard /> },
	{ path: "/temporary-page", element: <TemporaryPage /> },
	{ path: "/packages", element: <Packages /> },
	{ path: "/wheeloflife", element: <StartPage />, redirectPath: "/registration" },
	{ path: "/mission-one/results", element: <MissionOneResult /> },
	{ path: "/mission-two", element: <MissionTwoContainer /> },
	{ path: "/mission-three", element: <MissionThreeContainer /> },
	{ path: "/mission-four", element: <MissionFourContainer /> },
	{ path: "/mission-five", element: <MissionFiveContainer /> },
	{ path: "/mission-six", element: <MissionSixContainer /> },
	{ path: "/mission-seven", element: <MissionSevenContainer /> },
	{ path: "/mission-eight", element: <MissionEightContainer /> },
	{ path: "/mission-nine", element: <MissionNineContainer /> },
	{ path: "/mission-ten", element: <MissionTenContainer /> },
	{ path: "/mission-eleven", element: <MissionElevenContainer /> },
	{ path: "/quest-power", element: <QuestPower /> }
];

export const unauthorizedRoutes: IRouteData[] = [
	{ path: "/login", element: <LoginPage /> },
	{ path: "/reset-password-step-one", element: <ResetPasswordStepOne /> },
	{ path: "/reset-password-step-two", element: <ResetPasswordStepTwo /> }
];
