import { Box } from "@mui/material";
import ContentAdmin from "components/contentAdmin";
import PanelTitle from "components/missions/PanelTitle";
import useMission from "pages/missionTwo/hooks/useMission";
import { ReactNode } from "react";

function ToughDecisionsTemplate({ children }: { readonly children?: ReactNode; })
{
	const missionContext = useMission();

	// Render
	return (
		<Box sx={{ maxWidth: { xl: "980px", lg: "785px", xs: "980px" }, width: "100%" }}>
			<PanelTitle
				positionOcta
				imageVariation="closeEye"
				title={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="ToughDecisions_title"
					defaultValue="Tough Decisions"
					isDescription
					position="top"
				/>}
				subTitle={<ContentAdmin
					keyContent={missionContext.keyContent}
					keyText="ToughDecisions_subTitle"
					defaultValue="You’ve seen a lot, {NAME}! I am impressed. Now, can you think of what helped you make each decision? How did you go about it?"
					isDescription
					position="top"
				/>}
				sx={{
					marginBottom: "40px",

					"& .component-missions-panelTitle-container": { marginLeft: { xl: "150px", lg: "120px", xs: "120px" } },
					"& .component-missions-panelTitle-imageContainer": { zIndex: 2, left: "-35px" }
				}}
			/>

			{children}
		</Box>
	);
}

export default ToughDecisionsTemplate;
