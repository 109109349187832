import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import Fun from "../../../../assets/images/Fun.svg";
import ContentAdmin from "../../../../components/contentAdmin";

function FunDetails({ arr })
{

	return (
		<Box>
			<Box sx={{ position: "relative" }}>
				<Box sx={{ width: "100%", position: "absolute", top: "-40px" }}>
					<Box sx={{ width: "80px", m: "0 auto" }}>
						<img width="100%" src={Fun} alt="Fun" />
					</Box>
				</Box>
			</Box>
			<Typography pt={6} sx={{ fontSize: "24px", fontFamily: "Lora", fontWeight: "bold", color: "#245C74", textAlign: "center" }}>
				Fun
			</Typography>
			<Typography mt={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="{NAME}, fun and recreation are key ingredients to a fulfilling life. Since you’ve rated this area lower, it could be you simply need to re-assess your priorities and create time for fun activities. It could also be a sign that you need a bigger life change." keyContent="contentMissionOne" keyText="funScreen1" />
				{/* {localStorage.getItem('name')}, fun and recreation are key ingredients to a fulfilling life. Since you’ve rated this area lower, it could be you simply need to re-assess your priorities and create time for fun activities. It could also be a sign that you need a bigger life change. */}
			</Typography>

			<Box>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Start with the basics" keyContent="contentMissionOne" keyText="start_with_the_basics" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="You can restore balance by being more intentional about how you spend time. Schedule dedicated time daily for quality rest. Avoid scrolling through social media or binge-watching meaningless TV-shows.\\n\\nTry focusing on quality entertainment and activities that help you connect with others: dinner parties, board games, reading, going to an art exhibition with a friend, learning new skill.\\n\\nIf you’ve already tried that and your life doesn’t feel fun, it’s time to ask yourself some bigger questions." keyContent="contentMissionOne" keyText="monyDescStart" />
							{/* You can restore balance by being more intentional about how you spend time. Schedule dedicated time daily for quality rest. Avoid scrolling through social media or binge-watching meaningless TV-shows.<br></br><br></br>
							Try focusing on quality entertainment and activities that help you connect with others: dinner parties, board games, reading, going to an art exhibition with a friend, learning new skill.<br></br><br></br>
							If you’ve already tried that and your life doesn’t feel fun, it’s time to ask yourself some bigger questions. */}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
			<Box sx={{ mt: "-5px", height: "2px", background: "#3E809D" }}>   </Box>
			<Box mb={4}>
				<Accordion sx={{
					background: "inherit",
					boxShadow: "none",
					".MuiAccordionSummary-root": { p: 0 },
					".MuiAccordionDetails-root": { p: "0 0 15px 0" },
					".MuiAccordionSummary-content": { m: 0 }
				}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={{ fontSize: "18px", fontFamily: "Lora", fontWeight: "bold", color: "#215C75" }}>
							<ContentAdmin position="top" defaultValue="Look at a bigger picture" keyContent="contentMissionOne" keyText="look_at_a_bigger_picture" />
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
							<ContentAdmin isDescription position="top" defaultValue="- Is your career fulfilling and fun?\n- How often do you find yourself in the flow?\n- Do you have the time and energy for your hobbies?\n- Have you lost the spark in life and don’t know how to get it back?" keyContent="contentMissionOne" keyText="funDescBigger" />
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>

			<Typography sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: "medium", color: "#4D4D4D" }}>
				<ContentAdmin isDescription position="top" defaultValue="Answers to these questions might show that adding more rest time and recreational activities wouldn’t solve the issue and you need a bigger change in life. Re-designing your life can be a good way to feel the zest for life again. Life Strategy Quest is what can help you get your spark back!" keyContent="contentMissionOne" keyText="funScreen2" />
				{/* Answers to these questions might show that adding more rest time and recreational activities wouldn’t solve the issue and you need a bigger change in life. Re-designing your life can be a good way to feel the zest for life again. Life Strategy Quest is what can help you get your spark back! */}
			</Typography>
			{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '382px', mr: '-60px', mt: '20px' }}>
                    <ButtonStyled fullWidth={true} title="Embark On a Quest" />
                </Box>
            </Box> */}
		</Box>
	);
}

export default FunDetails;
