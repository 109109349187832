import { createContext, Dispatch, SetStateAction } from "react";
import { BackgroundType } from ".";

export interface IMissionContext
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
	firstPlaythrough: boolean;
	setFirstPlaythrough: Dispatch<SetStateAction<boolean>>;
	extraStep: number | null;
	setExtraStep: Dispatch<SetStateAction<number | null>>;
	nextStep(): void;
	prevStep(): void;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	background: keyof BackgroundType;
	setBackground: Dispatch<SetStateAction<keyof BackgroundType>>;

	isDisplayHeader: boolean;
	setIsDisplayHeader: Dispatch<SetStateAction<boolean>>;
}

export const MissionCtx = createContext<IMissionContext>({} as IMissionContext);
