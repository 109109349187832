import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ReactElement, Ref, forwardRef } from "react";

export const TransitionSlideUp = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: Ref<unknown>
) =>
{
	return <Slide direction="up" ref={ref} {...props} />;
});

export const TransitionSlideDown = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: Ref<unknown>
) =>
{
	return <Slide direction="down" ref={ref} {...props} />;
});

export const TransitionSlideRight = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: Ref<unknown>
) =>
{
	return <Slide direction="right" ref={ref} {...props} />;
});

export const TransitionSlideLeft = forwardRef((
	props: TransitionProps & { children: ReactElement<any, any>; },
	ref: Ref<unknown>
) =>
{
	return <Slide direction="left" ref={ref} {...props} />;
});
