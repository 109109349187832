import { Box } from "@mui/material";
import { forwardRef, Ref, useEffect, useState } from "react";
import Chat, { IResponseAnswer } from "../../../../components/Chat";
import { AnswersType } from "../../../../components/Chat/Answer";
import { IMessage } from "../../../../components/Chat/Message";
import ContentAdmin from "../../../../components/contentAdmin";
import useMission from "../../hooks/useMission";

export type IIntroJobToLoveProps = Record<string, any>;

function IntroJobToLove({ ...props }: IIntroJobToLoveProps, ref: Ref<HTMLDivElement>)
{
	const missionContext = useMission();

	const [ messages, setMessages ] = useState<IMessage[]>(
		[ {
			type: "from",
			message: <ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_message_1" defaultValue="{NAME}, before we move on, I wanted to tell you that a ‘job’ doesn’t have to be a job at a company." isDescription />
		} ]
	);
	const [ answers, setAnswers ] = useState<AnswersType | null>(
		{
			type: "select",
			items: [
				<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_1" defaultValue="Oh yeah?" position="top" />,
				<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_2" defaultValue="That’s right" position="top" />
			]
		}
	);
	const [ chatStep, setChatStep ] = useState<number>(1);

	// Utils
	function addMessage(type: IMessage[ "type" ], message: IMessage[ "message" ])
	{
		setMessages((prevState) => (
			[ ...prevState, { type, message } ]
		));
	}

	// Effects
	useEffect(() =>
	{
		switch (chatStep)
		{
			case 2: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_message_2" defaultValue="Yes, you can be a freelancer, a volunteer, a founder of a for-profit or non-for-profit organization, or even a combination of all those things in the form of a portfolio career." isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_3" defaultValue="Interesting" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_4" defaultValue="I thought so" position="top" />
					]
				});
				break;
			}

			case 3: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_message_3" defaultValue="Work takes up a large proportion of your life, so it’s important to find a career that makes the most of your skills and talents, but that is also in line with your values, likes and wants." isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_5" defaultValue="I agree, Octa" position="top" />,
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_6" defaultValue="Makes sense" position="top" />
					]
				});
				break;
			}

			case 4: {
				addMessage(
					"from",
					<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_message_4" defaultValue="Are you ready to design your dream job?" isDescription />
				);
				setAnswers({
					type: "select",
					items: [
						<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_answer_7" defaultValue="Let’s do it!" position="top" />
					]
				});
				break;
			}

			case 5: { setChatStep((prevStep) => prevStep + 1); missionContext.nextStep(); }
		}
	}, [ chatStep, missionContext ]);

	// Handles
	function onResponseAnswer({ message }: IResponseAnswer)
	{
		addMessage("to", message);
		setAnswers(null);

		setChatStep((prevStep) => prevStep + 1);
	}

	return (
		<Box ref={ref} {...props} className="mission-main main" component="main">
			<Box className="main__content" sx={{ alignItems: "center" }}>
				<Chat
					title={<ContentAdmin keyContent={missionContext.keyContent} keyText="IntroJobToLove_chat_title" defaultValue="A Job to Love" />}
					onResponseAnswer={onResponseAnswer}
					messages={messages}
					answers={answers}
					isAlignItems
				/>
			</Box>
		</Box>
	);
}

export default forwardRef(IntroJobToLove);
