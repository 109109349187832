export type Point = { x: number; y: number; };

export function findPeaksAndValleys(path: Point[])
{
	const smoothPath = path.map((point) => point.y);
	const smoothWindowSize = 5;

	for (let i = 0; i < smoothPath.length; i++)
	{
		const windowStart = Math.max(0, i - smoothWindowSize);
		const windowEnd = Math.min(smoothPath.length - 1, i + smoothWindowSize);
		const window = smoothPath.slice(windowStart, windowEnd + 1);
		const windowSum = window.reduce((a, b) => a + b, 0);

		smoothPath[ i ] = windowSum / window.length;
	}

	const peaks: Point[] = [];
	let peakStart: null | number = null;
	let peakEnd: null | number = null;
	let peakMax = -Infinity;
	let peakThreshold = 0.05;

	for (let i = 0; i < smoothPath.length; i++)
	{
		const value = smoothPath[ i ];

		if (value > peakMax)
		{
			peakMax = value;
			peakEnd = i;
		}
		else if (peakStart !== null && peakEnd !== null)
		{
			const peakIndex = findPeakIndex(path.slice(peakStart, peakEnd + 1));
			if (peakIndex !== null)
			{
				peaks.push(path[ peakStart + peakIndex ]);
			}

			peakStart = null;
			peakEnd = null;
			peakMax = -Infinity;
			peakThreshold = 0.05;
		}

		if (peakStart === null || value > peakMax - peakThreshold * peakMax)
		{
			peakStart = i;
			peakMax = value;
		}
		else if (value < peakMax - peakThreshold * peakMax)
		{
			peakThreshold = 0.01;
		}
	}

	const valleys: Point[] = [];
	let valleyStart: null | number = null;
	let valleyEnd: null | number = null;
	let valleyMin = Infinity;
	let valleyThreshold = 0.05;

	for (let i = 0; i < smoothPath.length; i++)
	{
		const value = smoothPath[ i ];

		if (value < valleyMin)
		{
			valleyMin = value;
			valleyEnd = i;
		}
		else if (valleyStart !== null && valleyEnd !== null)
		{
			const valleyIndex = findValleyIndex(path.slice(valleyStart, valleyEnd + 1));
			if (valleyIndex !== null)
			{
				valleys.push(path[ valleyStart + valleyIndex ]);
			}

			valleyStart = null;
			valleyEnd = null;
			valleyMin = Infinity;
			valleyThreshold = 0.05;
		}

		if (valleyStart === null || value < valleyMin + valleyThreshold * valleyMin)
		{
			valleyStart = i;
			valleyMin = value;
		}
		else if (value > valleyMin + valleyThreshold * valleyMin)
		{
			valleyThreshold = 0.01;
		}
	}

	return { peaks, valleys };
}

export function findPeakIndex(points: Point[])
{
	let maxIndex: null | number = null;
	let maxValue = -Infinity;

	for (let i = 0; i < points.length; i++)
	{
		if (points[ i ].y > maxValue)
		{
			maxIndex = i;
			maxValue = points[ i ].y;
		}
	}

	return maxIndex;
}

export function findValleyIndex(points: Point[])
{
	let minIndex: null | number = null;
	let minValue = Infinity;

	for (let i = 0; i < points.length; i++)
	{
		if (points[ i ].y < minValue)
		{
			minIndex = i;
			minValue = points[ i ].y;
		}
	}

	return minIndex;
}
