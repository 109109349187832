import { Box, Snackbar } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import AlertStyled from "components/AlertStyled";
import TooltipStyled from "components/TooltipStyled";
import { TransitionSlideLeft } from "components/Transition";
import { useEffect, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import PanelTitle from "../../../../components/missions/PanelTitle";
import useMission from "../../hooks/useMission";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[ `& .${ tooltipClasses.tooltip }` ]: {
		background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
		font: '400 14px/17px "Open Sans"',
		color: "#4D4D4D",
		boxShadow: "0px 5px 20px rgba(126, 196, 255, 0.4)",
		padding: "24px",
		borderRadius: "16px",
		backdropFilter: "blur(15px)",
		textTransform: "capitalize",
		border: "1px solid  rgba(239, 239, 239, 0.0816)",
		minWidth: "471px"
	}
}));

function UncertaintyCheck()
{
	const missionContext = useMission();
	const [ boards, setBoards ] = useState<any>([]);
	const [ countChecked, setCountChecked ] = useState(0);
	const [ open, setOpen ] = useState<boolean>(false);

	// Handles
	function handleClose(_event: React.SyntheticEvent | Event, reason?: string)
	{
		if (reason === "clickaway")
		{
			return;
		}

		setOpen(false);
	}

	function onClickNextStep()
	{
		// const newBoards = [ ...boards ];
		// let arrCheckedItems: string[] = [];
		// newBoards.forEach((board) =>
		// {
		// 	board.items.forEach((element: any, index: number) =>
		// 	{
		// 		if (element.checked)
		// 		{
		// 			arrCheckedItems.push(element.title);
		// 			board.items.splice(index, 1);
		// 		};
		// 	});
		// 	board.items.map((item: any) => item.checked === false);
		// });
		// console.log('newBoards', newBoards);
		localStorage.setItem("mission-nine-graph-data-good", JSON.stringify(boards));
		missionContext.setStep((prevStep) => prevStep + 1);
	}

	useEffect(() =>
	{
		const localData = JSON.parse(`${ localStorage.getItem("mission-nine-graph-data-checked") }`);
		const data = [
			{
				id: 1,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_focus"
					defaultValue="Do right away"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_focus_tooltip"
					defaultValue="Exciting things you will do right away to quickly improve your life."
					position="left"
					isDescription
				/>,
				items: localData[ 0 ].items.map((item: any, index: number) => (
					{
						id: index,
						title: item,
						checked: false
					}
				))
			},
			{
				id: 2,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_research"
					defaultValue="Do in the next 6 months"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_fillIns"
					defaultValue="Impactful things that are a little more complex, but you really want them"
					position="left"
					isDescription
				/>,
				items: localData[ 1 ].items.map((item: any, index: number) => (
					{
						id: index,
						title: item,
						checked: false
					}
				))
			},
			{
				id: 3,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_fillIns"
					defaultValue="Do in the next 1-2 years"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_fillIns_tooltip"
					defaultValue="Anything else that is still important to you but you are less excited about"
					position="left"
					isDescription
				/>,
				items: localData[ 2 ].items.map((item: any, index: number) => (
					{
						id: index,
						title: item,
						checked: false
					}
				))
			},
			{
				id: 4,
				title: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_luxuries"
					defaultValue="Luxuries"
					position="left"
				/>,
				tooltip: <ContentAdmin
					keyContent="contentMissionNine"
					keyText="life_road_map_luxuries_tooltip"
					defaultValue="Everything else that you want to do but might not be your biggest priority right now."
					position="left"
					isDescription
				/>,
				items: localData[ 3 ].items.map((item: any, index: number) => (
					{
						id: index,
						title: item,
						checked: false
					}
				))
			}
		];

		setBoards(data);
	}, []);
	function handleCheck(idBoard: number, idItem: number)
	{
		const newBoards = [ ...boards ];

		newBoards.forEach((board: any) =>
		{
			if (board.id === idBoard)
			{
				board.items.forEach((item: any) =>
				{
					if (item.id === idItem && countChecked !== 12)
					{
						if (item.checked)
						{
							setCountChecked(pre => pre - 1);
							item.checked = false;
						}

						else
						{
							setCountChecked(pre => pre + 1);
							item.checked = true;
						}
					}
					else if (item.id === idItem && countChecked === 12)
					{
						if (item.checked)
						{
							setCountChecked(pre => pre - 1);
							item.checked = false;
						}

						else
						{
							setOpen(true);
						}
					}
				});
			}
		});
		setBoards(newBoards);
	}

	return (
		<>
			<Box
				className="main__content"
				sx={{
					height: "84vh",
					width: "100%",
					m: "0 auto",
					position: "relative",
					maxWidth: { lg: "814px", xl: "1036px" },
					top: "-15px",
					left: "0px"
				}}>
				<PanelTitle
					positionOcta
					title={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="uncertainty_check_title"
						defaultValue="Uncertainty check"
						position="left"
					/>}
					subTitle={<ContentAdmin
						keyContent="contentMissionNine"
						keyText="uncertainty_check_subTitle"
						defaultValue="Now, some of your ideas can easily be turned into actions, and some are hunches and are less defined. Go through all your ideas and mark your biggest unknowns."
						position="left"
						isDescription />}
					imageVariation="closeEye"
				/>
				{missionContext.showContent
					? (
						<>

							<Box
								sx={{
									display: "flex",
									gap: "16px",
									marginLeft: "30px"
								}}
							>
								<Box
									className="scrollCustom"
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "start",
										flexWrap: "wrap",
										width: "100%",
										"& .cluster__column":
										{
											padding: "17px 26px 12px 26px",
											borderRadius: "24px",
											width: "calc(50% - 16px)",
											margin: "8px",
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											border: "1px solid rgba(255, 255, 255, 0.5)",
											background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
											backdropFilter: "blur(30px)",
											boxShadow: "0px 5px 10px rgba(167, 199, 225, 0.6)",
											height: { lg: "194px", xl: "271px" },
											overflowY: "scroll",
											overflowX: "hidden"
										},
										"& .cluster__title": { font: { lg: "700 18px/31px Lora-Bold", xl: "700 24px/31px Lora-Bold" }, textTransform: "capitalize", textAlign: "left", color: "#215C75" },
										"& .cluster__items":
										{
											display: "flex",
											flexWrap: "wrap",
											gap: "7px",
											alignItems: "center",
											overflow: "auto",
											padding: "7px 0 10px 0",
											cursor: "pointer",
											color: "#4D4D4D"
										}
									}}
								>
									{boards.length > 0 && boards.map(
										(board: any, index: number) => (
											<Box
												key={index}
												className="cluster__column"
											>
												<Box sx={{ display: "flex", alignItems: "center" }}>
													<Box sx={{ display: "flex", alignItems: "center" }}>
														<Box className="cluster__title">{index + 1}. &nbsp;</Box>
														<Box className="cluster__title">{board.title}</Box>
														{/* <LightTooltip placement="top" title={<Typography sx={{
														color: '#4D4D4D', font: '400 14px/19px "Open Sans"',
													}}>
														{board.tooltip}
													</Typography>}>
														<InfoOutlinedIcon sx={{
															fill: '#215C75', ml: 1,
															cursor: `pointer`,
														}} />
													</LightTooltip> */}
													</Box>
												</Box>
												<Box className="cluster__items scrollCustom">
													{board.items.map(
														(boardItem: any, index: number) => (
															<Box
																key={index}
																onClick={() => handleCheck(board.id, boardItem.id)}
																sx={{
																	display: "flex",
																	alignItems: "center",
																	backgroundColor: boardItem.checked ? "rgba(62, 128, 157, 0.5)" : "rgba(255, 255, 255, 0.5);",
																	boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
																	borderRadius: "60px",
																	padding: "9px 16px",
																	color: boardItem.checked ? "#fff" : "#4D4D4D"
																}}>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		"&:hover": {
																			cursor: "pointer"
																		}
																	}}
																>
																	{boardItem.title}
																</Box>
															</Box>
														)
													)}
												</Box>
											</Box>
										)
									)}
								</Box>
							</Box>
							<Box sx={{ position: "relative", marginLeft: "30px" }}>
								<TooltipStyled title={countChecked === 0 ? "You need to choose at least one step" : ""} placement="bottom" arrow>
									<div>
										<CustomButton.Contained
											disabled={countChecked === 0}
											onClick={onClickNextStep}
											sx={{
												display: "flex",
												margin: "0 auto",
												background: "#3E809D",
												borderColor: "#3E809D"
											}}
										>
											Submit
										</CustomButton.Contained>
									</div>
								</TooltipStyled>
							</Box>
						</>
					)
					: null
				}
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				sx={{ "&.MuiSnackbar-root": { bottom: "140px", right: "35px" } }}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={handleClose} severity="warning">
					Max 12
				</AlertStyled>
			</Snackbar>
		</>
	);
}

export default UncertaintyCheck;
