import CloseIcon from "@mui/icons-material/Close";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import IMGLISTITEM from "../../../../../assets/images/icons/ball-list.svg";

interface SliderBlockPorps
{
	onClose(): void;
	item: any;
	sx?: SxProps<Theme>;
	itemSelected?: any;
}

function SliderBlock({ onClose, itemSelected, item, sx }: SliderBlockPorps)
{
	const [ dense, setDense ] = useState(false);

	const actionsPlanItems = {
		item: JSON.parse(`${ localStorage.getItem("mission-eight-fears-resolve") }`)
	};

	return (
		<Box sx={{
			display: "flex",
			width: "calc(100% - 307px)",
			maxWidth: "calc(100% - 307px)",
			height: "615px",
			border: "1px solid rgba(255, 255, 255, .6)",
			flexDirection: "column",
			background: "linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816) 98.96%)",
			backdropFilter: "blur(25px)",
			boxShadow: "0px 5px 10px 0px #A7C7E199",
			position: "relative",
			...sx
		}}>
			<Box
				sx={{
					padding: "31px 24px 0px 17px",
					position: "relative"
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "30px",
						right: "40px",
						zIndex: "10"
					}}
					onClick={onClose}>
					<CloseIcon
						sx={{
							fill: "#fff",
							fontSize: "14px",
							backgroundColor: "#3E809D",
							borderRadius: "40px",
							width: "24px",
							height: "24px",
							p: "3px",
							cursor: "pointer"
						}} />
				</Box>
				<Typography sx={{
					marginBottom: "25px",
					color: "#215C75",
					font: {
						xs: '700 20px/21px "Lora"',
						md: '700 40px/51px "Lora"'
					},
					textAlign: "left",
					marginLeft: "30px",
					wordBreak: "break-word"

				}}>
					{item.title}
				</Typography>
				<Box
					className="scrollCustom"
					sx={{
						overflowY: "scroll",
						overflowX: "hidden",
						paddingLeft: "25px",
						maxHeight: "450px",
						width: "calc(100% - 26px)"
					}}>
					<List dense={dense} sx={{ padding: "0px" }}>
						{actionsPlanItems.item.map((element: any, index: number) => (
							<ListItem key={index} sx={{ alignItems: "flex-start", marginBottom: "8px", marginLeft: "-28px", padding: "0px" }}>
								<ListItemIcon>
									<Box sx={{
										width: "80px",
										height: "80px",
										backgroundImage: `url(${ IMGLISTITEM })`,
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat"
									}} />
								</ListItemIcon>

								{(item.id === 2) && <ListItemText
									primary={
										<Typography
											sx={{
												color: "#215C75",
												font: {
													xs: '700 18px/18px "Lora"',
													md: '700 24px/31px "Lora"'
												},
												marginBottom: "8px",
												wordBreak: "break-word"
											}}
											component="div"
										>{actionsPlanItems.item[ index ].title}
										</Typography>
									}
									secondary={
										<Typography
											sx={{
												color: "#4D4D4D",
												font: {
													xs: '400 18px/18px "Lora"',
													md: '400 22px/30px "Lora"'
												}
											}}
											component="div"
										>{actionsPlanItems.item[ index ].prevent}
										</Typography>
									}
								/>}

								{(item.id === 1) && <ListItemText
									primary={
										<Typography
											sx={{ color: "#215C75", font: '700 24px/31px "Lora"', marginBottom: "8px" }}
											component="div"
										>{actionsPlanItems.item[ index ].title}
										</Typography>
									}
									secondary={
										<Typography
											sx={{ color: "#4D4D4D", font: '400 22px/30px "Open Sans"' }}
											component="div"
										>{actionsPlanItems.item[ index ].repair}
										</Typography>
									}
								/>}
							</ListItem>
						))}

					</List>
				</Box>
			</Box>
		</Box>
	);
}

export default SliderBlock;
