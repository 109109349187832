import { ReactNode } from "react";
import { Helmet } from "react-helmet";

function TrackingWrapper({ children }: { children: ReactNode; })
{
	if (window.location.host !== process.env.REACT_APP_PROD_HOST)
	{
		return <>{children}</>;
	}

	return (
		<>
			<Helmet>
				<script async src={"https://www.googletagmanager.com/gtag/js?id=" + process.env.REACT_APP_GA_TRACKING_CONVERSATION_ID} />
				<script>
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${ process.env.REACT_APP_GA_TRACKING_CONVERSATION_ID }');
				`}
				</script>
			</Helmet>

			{children}
		</>
	);
}

export default TrackingWrapper;
