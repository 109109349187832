import { AlertColor, Box, Snackbar } from "@mui/material";
import Api from "api";
import { IResponseGetPackages } from "api/Admin";
import AlertStyled from "components/AlertStyled";
import CustomInput from "components/CustomInput";
import { TransitionSlideLeft } from "components/Transition";
import CustomButton from "components/customButton";
import { ChangeEvent, FormEvent, ReactNode, useLayoutEffect, useRef, useState } from "react";

function Packages()
{
	const isFirstInitRef = useRef<boolean>(true);

	const [ isFetch, setIsFetch ] = useState<boolean>(false);
	const [ packages, setPackages ] = useState<IResponseGetPackages | null>(null);
	const [ snackbarContent, setSnackbarContent ] = useState<null | { severity: AlertColor; content: ReactNode; }>(null);

	// Effects
	useLayoutEffect(() =>
	{
		if (isFirstInitRef.current === false)
		{
			return;
		}

		isFirstInitRef.current = false;

		Api.admin
			.getPackages()
			.then(([ res ]) =>
			{
				setPackages(res);
			});

	}, []);

	function handleChangeValue(key: keyof Omit<IResponseGetPackages, "id">)
	{
		return ({ target }: ChangeEvent<HTMLInputElement>) =>
		{
			setPackages((prevState) =>
			{
				if (prevState === null)
				{
					return null;
				}

				return {
					...prevState,
					[ key ]: target.valueAsNumber
				};
			});
		};
	}

	function onSubmit(event: FormEvent)
	{
		event.preventDefault();

		if (isFetch === true)
		{
			return;
		}

		setIsFetch(true);

		Api.admin
			.setPackages(packages)
			.then(() =>
			{
				setSnackbarContent({ severity: "success", content: "Success" });
			})
			.catch((err) =>
			{
				setSnackbarContent({ severity: "error", content: "An error occurred" });
				console.error(err);
			})
			.finally(() => setIsFetch(false));
	}

	// Render
	if (packages === null)
	{
		return (
			<>
				Loading...
			</>
		);
	}

	return (
		<>
			<form onSubmit={onSubmit}>
				<Box sx={{ display: "flex", gap: "24px" }}>
					{([
						[ "Life Designer", "monthly" ],
						[ "Life Architect", "yearly" ],
						[ "Life Master", "lifetime" ],
						[ "Free Trial", "decideLater" ]
					] as [ label: string, key: keyof Omit<IResponseGetPackages, "id"> ][])
						.map(([ label, key ]) =>
						{
							return (
								<label key={key}>
									<Box sx={{ font: "700 18px/23px Lora-Bold", color: "#FFFFFF", textAlign: "center" }}>
										{label}
									</Box>

									<CustomInput.Base
										required
										type="number"
										value={packages[ key ]}
										onChange={handleChangeValue(key)}
										inputProps={{ min: 0, step: "any" }}
										sx={{ width: "125px", marginTop: "8px" }}
									/>
								</label>
							);
						})
					}
				</Box>

				<CustomButton.Contained type="submit" loading={isFetch === true} sx={{ marginTop: "24px" }}>
					Save
				</CustomButton.Contained>
			</form>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={snackbarContent !== null}
				autoHideDuration={3000}
				onClose={() => setSnackbarContent(null)}
				TransitionComponent={TransitionSlideLeft}
			>
				<AlertStyled onClose={() => setSnackbarContent(null)} severity={snackbarContent?.severity}>
					{snackbarContent?.content}
				</AlertStyled>
			</Snackbar>
		</>
	);
}

export default Packages;
