import { alpha, Box, capitalize } from "@mui/material";
import octa2 from "assets/images/octa2.png";
import TooltipStyled from "components/TooltipStyled";
import { useLayoutEffect, useState } from "react";
import ContentAdmin from "../../../../components/contentAdmin";
import CustomButton from "../../../../components/customButton";
import { getLocalStorage } from "../../../../utils/localStorage";
import useMission from "../../hooks/useMission";
import getClusterItemsByColor from "../../utils/getClusterItemsByColor";
import { IBoard } from "../ClusterYourYearnings";

function MustHave()
{
	const missionContext = useMission();
	const [ items, setItems ] = useState<string[]>([]);

	// Effects
	useLayoutEffect(() =>
	{
		const clusters = getLocalStorage<IBoard[]>("mission-three-cluster") as IBoard[];

		setItems(getClusterItemsByColor(clusters, "red").map((item) => item.title));
	}, []);

	// Handles
	function onClickNextStep()
	{

		missionContext.setStep((currentStep) => currentStep + 1);
	}

	// Render
	return (
		<Box
			className="main__content"
			sx={{
				alignItems: "center",
				flexDirection: "column !important",
				gap: { xl: "24px !important", lg: "54px !important", xs: "24px !important" }
			}}>
			<Box sx={{
				position: "relative",
				width: { xl: "526px", lg: "340px", xs: "526px" }
			}}>
				<Box sx={{
					position: "relative",
					zIndex: 2,
					height: { xl: "444px", lg: "274px", xs: "444px" },
					padding: { xl: "40px 36px 56px 36px", lg: "16px 24px 24px 24px", xs: "40px 36px 56px 36px" },
					borderRadius: "24px",
					backgroundColor: alpha("#57ABD9", 0.5),
					color: "#FFFFFF",
					display: "grid",
					gridTemplateRows: "auto 1fr",
					gap: { xl: "unset", lg: "16px", xs: "unset" },

					"& ol":
					{
						overflowY: "auto",
						height: "100%",
						margin: "unset",
						paddingRight: "8px",

						"&::-webkit-scrollbar":
						{
							backgroundColor: alpha("#FFFFFF", 0.5)
						},
						"&::-webkit-scrollbar-thumb":
						{
							backgroundColor: "#FFFFFF"
						},
						"& li":
						{
							font: { xl: "700 24px/40px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 24px/40px Lora-Bold" },
							width: "100%",

							"& > div":
							{
								width: "100%",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								overflowWrap: "break-word",
								cursor: "pointer"
							}
						}
					}
				}}>
					<Box sx={{
						font: { xl: "700 42px/54px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 42px/54px Lora-Bold" },
						textShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
						textAlign: "center"
					}}>
						<ContentAdmin keyContent="contentMissionThree" keyText="screen_27_mustHave" defaultValue="{NAME}’s\n Must Haves" position="left" />
					</Box>

					<ol className="customScroll">
						{items.map((item, index) => (
							<TooltipStyled
								key={index}
								title={capitalize(item)}
								placement="left"
								arrow
							>
								<li>
									<div>{capitalize(item)}</div>
								</li>
							</TooltipStyled>
						))}
					</ol>
				</Box>

				<Box sx={{
					width: { xl: "284px", lg: "188px", xs: "239px" },
					transform: { xl: "rotate(22.35deg)", lg: "rotate(19.36deg)", xs: "rotate(22.35deg)" },
					position: "absolute",
					top: { xl: "74px", lg: "22px", xs: "74px" },
					right: "-29%"
				}}>
					<img width="100%" src={octa2} alt="" />
				</Box>
			</Box>

			<Box sx={{
				width: { xl: "844px", lg: "598px", xs: "844px" },
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "24px"
			}}>
				<Box sx={{
					font: { xl: "700 40px/51px Lora-Bold", lg: "700 24px/31px Lora-Bold", xs: "700 40px/51px Lora-Bold" },
					textAlign: "center",
					color: "primary.main"
				}}>
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_27_title" defaultValue="Congratulations, {NAME}!" position="left" />
				</Box>

				<Box sx={{
					padding: "0 14px",
					font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 22px/30px 'Open Sans'" },
					color: "primary.main",
					textShadow: "0px 0px 20px #FFFFFF",
					textAlign: "center"
				}}>
					<ContentAdmin keyContent="contentMissionThree" keyText="screen_27_text" defaultValue="You now have a lot more clarity on what you want!\n\nTogether with your values, this mission brings you one step closer to understanding of what your best life looks like." position="left" isDescription />
				</Box>

				<CustomButton.Contained
					sx={{
						padding: { xl: "12px 80px", lg: "12px 32px", xs: "12px 32px" },
						minWidth: "unset",
						minHeight: "unset",
						height: { xl: "56px", lg: "40px", xs: "40px" },
						font: { xl: "700 24px/33px 'Open Sans'", lg: "700 18px/25px 'Open Sans'", xs: "700 24px/33px 'Open Sans'" }
					}}
					onClick={onClickNextStep}
				>
					Celebrate!
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default MustHave;
