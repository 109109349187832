import { Box, IconButton } from "@mui/material";
import RadialButton from "components/RadialButton";
import useMission from "pages/missionTwo/hooks/useMission";
import { useReadLocalStorage } from "utils/localStorage";
import { LifePeaks, filledForm } from ".";
import { ReactComponent as SVGFlag } from "../../assets/images/flag.svg";

export interface ILifePeaksButtonsProps
{
	/**
	 * @default false
	 */
	readonly preview?: boolean;
}

const PREVIEW_OFFSET: number = 3;

function LifePeaksButtons({ preview = false }: ILifePeaksButtonsProps)
{
	const missionContext = useMission();
	const lifePeaks = useReadLocalStorage<LifePeaks[]>(missionContext.missionPrefix + "-lifePeaks", []);

	// Handles
	function onClickButton(buttonNumber: 0 | 1 | 2)
	{
		return () =>
		{
			missionContext.setExtraStep(buttonNumber + 1 + (preview ? PREVIEW_OFFSET : 0));
		};
	}

	// Render
	return (
		<>
			{([ 0, 1, 2 ] as const).map((buttonNumber) =>
			{
				const positions: [
					top: Partial<Record<"xl" | "lg2" | "lg" | "xs", string>>,
					left: Partial<Record<"xl" | "lg2" | "lg" | "xs", string>>
				][] =
					[ // Order: bottom button to top
						[
							{ xl: "240px", lg2: "215px", xs: "165px" },
							{ xl: "49%", lg2: "49%", xs: "53%" }
						],
						[
							{ xl: "68px", lg2: "75px", xs: "48px" },
							{ xl: "58%", lg2: "59%", xs: "61%" }
						],
						[
							{ xl: "-25px", lg2: "-15px", xs: "-40px" },
							{ xl: "64%", lg2: "66%", xs: "66%" }
						]
					];

				if (buttonNumber < lifePeaks.length && filledForm(lifePeaks, buttonNumber) === true)
				{
					return (
						<Box
							key={"flag" + buttonNumber}
							sx={{
								position: "absolute",
								top: positions[ buttonNumber ][ 0 ],
								left: positions[ buttonNumber ][ 1 ],

								"& svg":
								{
									width: { xl: "43px", lg2: "36px", xs: "29px" }
								}
							}}
						>
							<IconButton
								disableFocusRipple
								disableRipple
								disableTouchRipple
								onClick={onClickButton(buttonNumber)}
								sx={{
									backgroundColor: "unset !important"
								}}
							>
								<SVGFlag />
							</IconButton>
						</Box>
					);
				}

				if (buttonNumber > 0 && filledForm(lifePeaks, buttonNumber - 1 as 0 | 1 | 2) === false)
				{
					return null;
				}

				if (filledForm(lifePeaks, buttonNumber) === false)
				{
					return (
						<Box
							key={"radial" + buttonNumber}
							onClick={onClickButton(buttonNumber)}
							sx={{
								position: "absolute",
								top: positions[ buttonNumber ][ 0 ],
								left: positions[ buttonNumber ][ 1 ]
							}}
						>
							<RadialButton
								blip
								offBlipOnClick={false}
								value=""
								showValue={false}
							/>
						</Box>
					);
				}

				return null;
			})}
		</>
	);
}

export default LifePeaksButtons;
