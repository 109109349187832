import { Box } from "@mui/material";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import Confetti from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import clearLocalStorage from "utils/clearlocalStorage";
import IMGCelebrationOcta from "./assets/images/celebration-octa.png";
import IMGBackground from "./assets/images/complete-background.jpg";

function CompleteRegistration()
{
	const navigate = useNavigate();
	const { state: userState } = useUser();
	const clientWidth = document.documentElement.clientWidth;

	function onClickButton()
	{
		clearLocalStorage("registration");

		navigate("/registration/first-complete");
	}

	return (
		<Box sx={{
			padding: { xl: "68px 32px 32px 32px", sm: "48px 32px 32px 32px", xs: "54px 32px 0 32px" },
			minHeight: "100vh",

			background: `url(${ IMGBackground }) center center/cover`,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",

			"@supports (min-height: 100dvh)":
			{
				minHeight: "100dvh"
			}
		}}>
			<Box sx={{
				font: { xl: "700 64px/82px Lora-Bold", sm: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
				color: "#FFFFFF",
				textAlign: "center"
			}}>
				Congratulations!<br />
				You’ve completed your first mission!
			</Box>

			<Confetti
				particleCount={250}
				particleSize={clientWidth <= 700 ? 10 : 15}
				force={0.8}
				duration={5000}
				width={clientWidth}
				colors={[
					"#C1D2EF",
					"#CAEAB1",
					"#E39BBE",
					"#7D84DA",
					"#E2E49D",
					"#9DDE8A",
					"#B5A7E7",
					"#96B8E2",
					"#DF8EA7",
					"#F6E0DB"
				]}
			/>

			<Box
				component="img"
				src={IMGCelebrationOcta}
				alt=""
				sx={{
					marginTop: { xl: "40px", sm: 0, xs: "80px" },
					maxWidth: { xl: "716px", sm: "602px", xs: "390px" },
					width: "100%"
				}}
			/>

			<Box sx={{
				margin: "0 auto",
				marginTop: { sm: "40px", xs: "auto" },
				width: { xs: "100%", sm: "unset" },
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Base
					onClick={onClickButton}
					sx={{
						maxWidth: "390px",
						width: { sm: "auto", xs: "100%" },
						boxShadow: "unset"
					}}
				>
					What's Next?
				</CustomButton.Base>
			</Box>
		</Box>
	);
}

export default CompleteRegistration;
