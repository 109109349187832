import { alpha, Box } from "@mui/material";
import { forwardRef, ReactChild, ReactFragment, ReactPortal, Ref, useLayoutEffect, useState } from "react";
import BouncingDotsLoader from "../BouncingDotsLoader";

export interface IMessage
{
	type: "from" | "to";
	message: string | ReactChild | ReactFragment | ReactPortal;
	onMessage?(): void;
}

interface IMessageProps
{
	message: IMessage;
	messageIndex: number;
	onSent(message: IMessage): void;
}

const TYPING_TIME: number = 1500;

const Message = forwardRef(({ message, messageIndex, onSent }: IMessageProps, ref: Ref<HTMLDivElement>) =>
{
	const [ isTyping, setIsTyping ] = useState<boolean>(true);

	useLayoutEffect(() =>
	{
		if (message.type === "from" && messageIndex !== 0)
		{
			const timerId = setTimeout(() =>
			{
				setIsTyping(false);
				onSent(message);
			}, TYPING_TIME);

			return () => clearTimeout(timerId);
		}

		onSent(message);

	}, [ message, messageIndex, onSent ]);

	return (
		<Box
			ref={ref}
			className="component-chat-messages__message"
			sx={{
				backgroundColor: (message.type === "to" ? alpha("#278EE3", 0.5) : alpha("#FFFFFF", 0.5)),
				borderRadius: "16px",
				padding: (
					message.type === "to"
						? { xl: "24px", lg: "15px 24px", xs: "24px" }
						: { xl: "24px", lg: "12px 24px", xs: "24px" }
				),
				font: { xl: "400 22px/30px 'Open Sans'", lg: "400 16px/22px 'Open Sans'", xs: "400 16px/22px 'Open Sans'" },
				color: (message.type === "to" ? "#FFFFFF" : "primary.dark"),
				maxWidth: "90%",
				alignSelf: (message.type === "to" ? "flex-end" : "flex-start")
			}}
		>
			{message.type === "from" && isTyping === true && messageIndex !== 0 ? <BouncingDotsLoader /> : message.message}
		</Box>
	);
});

export default Message;
