/* eslint-disable vars-on-top */
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import Api from "api";
import Career from "assets/images/Career.svg";
import Friend from "assets/images/Friend.svg";
import Fun from "assets/images/Fun.svg";
import Growth from "assets/images/Growth.svg";
import Health from "assets/images/Health.svg";
import Love from "assets/images/Love.svg";
import Money from "assets/images/Money.svg";
import bgDetails from "assets/images/bgDetails.png";
import home from "assets/images/home.svg";
import meditateleft from "assets/images/meditateleft.png";
import share from "assets/images/share.svg";
import shared from "assets/images/shared.svg";
import ReferFriendsModal from "components/Dialog/ReferFriends.modal";
import CustomButton from "components/customButton";
import useUser from "hooks/useUser";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import { getLocalStorage } from "utils/localStorage";
import Dialogs from "./Dialogs";
import BadStatus from "./fields/BadStatus";
import DetailsBad from "./fields/DetailsBad";
import DetailsItem from "./fields/DetailsItem";
import GoodStatus from "./fields/GoogStatus";

function DetailsContainer()
{
	const { state: userState } = useUser();
	const didLogRef = useRef(false);
	const navigate = useNavigate();
	const [ dataGraph, setDataGraph ] = useState();
	const [ details, setDetails ] = useState("");
	const [ idMission, setIdMission ] = useState("");
	const isFetchRef = useRef(true);
	const [ idMissionPre, setIdMissionPre ] = useState();
	const [ dataStorage ] = useState(getLocalStorage("graph"));
	const [ items, setItems ] = useState();
	const [ open, setOpen ] = useState(false);
	const [ isOpenShare, setIsOpenShare ] = useState(false);

	if (dataStorage !== null)
	{
		var arrFilter = [
			{
				title: "Health",
				value: dataStorage.health
			},
			{
				title: "Friends",
				value: dataStorage.friend
			},
			{
				title: "Love",
				value: dataStorage.love
			},
			{
				title: "Career",
				value: dataStorage.career
			},
			{
				title: "Money",
				value: dataStorage.money
			},
			{
				title: "Fun",
				value: dataStorage.fun
			},
			{
				title: "Growth",
				value: dataStorage.growth
			},
			{
				title: "Physical environment",
				value: dataStorage.home
			}
		];
		var filterArr = arrFilter.filter(item => item.value < 0.7);
	}

	const data = [
		{
			data: dataGraph
		}
	];

	const date = new Date();
	const mm = String(date.getMonth() + 1).padStart(2, "0");

	const captions =
	{
		home: "home",
		health: "health",
		friend: "friend",
		love: "love",
		career: "Speed",
		money: "Weight",
		fun: "Weight2",
		growth: "Weight3"
	};

	// Hooks
	useLayoutEffect(() =>
	{
		if (dataStorage === null)
		{
			return navigate("/");
		}

		// const isSend = JSON.parse(getLocalStorage("graph-send"));
		// if (isSend === true)
		// {
		// 	return;
		// }

		// localStorage.setItem("graph-send", JSON.stringify(true));

		Api.missions.getMissions()
			.then(({ missions }) =>
			{
				const lastMissionId = Math.max(
					...missions.filter((mission) => isNaN(mission.mission_id) === false).map((mission) => mission.mission_id)
				);

				console.info({ missions, lastMissionId });
				setIdMissionPre(lastMissionId + 1);
			}).catch((error) =>
			{
				console.error(error);
			});

	}, [ navigate, dataStorage ]);
	function saveMission()
	{
		// Api.missions.saveMission(dataStorage, idMissionPre)
		// 	.then((res) =>
		// 	{
		// 		setIdMission(res);
		// 		navigate(`/detailsshared/:${ res }`);
		// 	})
		// 	.catch((error) =>
		// 	{
		// 		console.error(error);
		// 	});
		navigate(`/detailsshared/:${ idMission }`);
	}

	useEffect(
		() =>
		{
			if (dataStorage === null)
			{
				return;
			}

			setItems([
				{
					title: "Health",
					value: dataStorage.health,
					active: false
				},
				{
					title: "Physical environment",
					value: dataStorage.home,
					active: false
				},
				{
					title: "Love",
					value: dataStorage.love,
					active: false
				},
				{
					title: "Career",
					value: dataStorage.career,
					active: false
				},
				{
					title: "Money",
					value: dataStorage.money,
					active: false
				},
				{
					title: "Fun",
					value: dataStorage.fun,
					active: false
				},
				{
					title: "Growth",
					value: dataStorage.growth,
					active: false
				},
				{
					title: "Friends",
					value: dataStorage.friend,
					active: false
				}
			]);

		},
		[
			dataStorage,
			dataStorage.health,
			dataStorage.friend,
			dataStorage.love,
			dataStorage.career,
			dataStorage.money,
			dataStorage.fun,
			dataStorage.growth,
			dataStorage.home
		]
	);

	useEffect(() =>
	{
		if (dataStorage !== null)
		{

			setDataGraph(dataStorage);
		}

	}, [ dataStorage ]);

	useEffect(() =>
	{
		if (didLogRef.current === false)
		{
			Api.missions.saveMission(dataStorage, idMissionPre, 1)
				.then((res) =>
				{
					setIdMission(res);
				})
				.catch((error) =>
				{
					console.error(error);
				});
		}
	}, []);

	// Handles
	function handleClickOpen()
	{
		setOpen(true);
	}

	function handleClose()
	{
		setOpen(false);
	}

	function handleDetails(value)
	{
		let arrItems = items;

		arrItems.forEach(element =>
		{
			element.active = false;
		});
		arrItems.forEach(element =>
		{
			if (element.title === value)
			{
				element.active = true;
			}
		});
		setItems(arrItems);
		setDetails(value);
		// handleClickOpen()
	}

	function onClickContinue()
	{
		if (getLocalStorage("registration-name"))
		{
			navigate("/registration/complete");
		}
		else
		{
			navigate("/dashboard");
		}
	}

	return (
		<Box sx={{ background: `url(${ bgDetails }) no-repeat`, backgroundSize: "cover", position: "relative", overflowX: "hidden" }}>
			<Box sx={{ width: "100%", height: "100%" }}>
				<Grid className="marginCenter" container>
					<Grid
						item
						className="withSmallGraph marginBottomd"
						// xl={6}
						// md={7}
						sx={{
							width: {
								xs: "100%", md: "50%", xl: "45%"
								// border: '2px solid red'
							}
						}}
					>
						<div id="topAncor" />
						<Element name="topAncor" className="element" />
						{dataGraph
							? (
								<Box sx={{
									width: { xs: "310px", sm: "670px" },
									m: "70px auto 50px",
									position: "relative",

									transform: { lg: "scale(0.637)", xl: "scale(0.9)" },
									mt: { lg: "-60px", xl: "0" }
								}}
								>
									<Box sx={{ display: { xs: "none", sm: "block" } }}>
										<RadarChart
											captions={captions}
											data={data}
											size={670}
											options={{
												scales: 10
											}}
										/>
									</Box>
									<Box sx={{ display: { xs: "block", sm: "none" } }}>
										<RadarChart
											captions={captions}
											data={data}
											size={310}
											options={{
												scales: 10
											}}
										/>
									</Box>

									<Box sx={{ position: "absolute", top: { xs: "87px", sm: "178px" }, left: { xs: "94px", sm: "207px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "107px", sm: "228px" }, left: { xs: "141px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "160px", sm: "362px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
									<Box sx={{ position: "absolute", top: { xs: "200px", sm: "440px" }, left: { xs: "140px", sm: "315px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(225deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "219px", sm: "488px" }, left: { xs: "93px", sm: "206px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(270deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "199px", sm: "439px" }, left: { xs: "47px", sm: "99px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(315deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "154px", sm: "333px" }, left: { xs: "28px", sm: "51px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff" }} />
									<Box sx={{ position: "absolute", top: { xs: "108px", sm: "226px" }, left: { xs: "48px", sm: "98px" }, width: { xs: "123px", sm: "256px" }, height: "3px", background: "#fff", transform: "rotate(45deg)" }} />
									<Box sx={{ position: "absolute", top: { xs: "-11px", sm: "-37px" }, left: { xs: "136px", sm: "287px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={home} alt="moneyOpacity" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "30px", sm: "60px" }, right: { xs: "32px", sm: "57px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Health} alt="Health" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "137px", sm: "289px" }, right: { xs: "-13px", sm: "-41px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Friend} alt="Friend" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "240px", sm: "514px" }, right: { xs: "32px", sm: "54px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Love} alt="Love" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "282px", sm: "618px" }, right: { xs: "135px", sm: "288px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Career} alt="Friend" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "239px", sm: "518px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Money} alt="step6" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "135px", sm: "285px" }, right: { xs: "282px", sm: "619px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Fun} alt="Fun" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "32px", sm: "55px" }, right: { xs: "238px", sm: "518px" }, width: { xs: "40px", sm: "93px" } }}>
										<img width="100%" src={Growth} alt="Growth" />
									</Box>
									<Box sx={{ position: "absolute", top: { xs: "142px", sm: "308px" }, right: { xs: "142px", sm: "308px" }, width: { xs: "25px", sm: "55px" }, height: { xs: "25px", sm: "55px" }, borderRadius: "100px", background: "#fff", opacity: .4 }} />
								</Box>
							)
							: null
						}
						<Typography mb={1} sx={{ fontSize: { xs: "24px", sm: "36px" }, fontFamily: "Lora", textAlign: "center", fontWeight: "bold", color: "#fff", lineHeight: "30px" }}>
							{userState.user.name}’s Wheel of Life
						</Typography>
						<Typography sx={{ fontSize: "22px", fontFamily: "Open Sans", textAlign: "center", fontWeight: "medium", color: "#fff" }}>
							{`Date: ${ date.getDate() }.${ mm }.${ date.getFullYear() }`}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "space-between" } }}>
							<Box onClick={() => setIsOpenShare(true)} sx={{ display: { xs: "none", sm: "block" }, ml: "30px" }}>
								<Box
									className="share"
									sx={{
										border: "3px solid #fff",
										borderRadius: "60px",
										display: "flex",
										alignItems: "center",
										p: "10px 25px 10px 15px",
										cursor: "pointer"
									}}
								>
									<Box ml={2}>
										<img width="30px" src={share} alt="share" />
									</Box>
									<Typography ml={2} sx={{ fontSize: "16px", fontFamily: "Open Sans", fontWeight: 700, color: "#fff" }}>Share the tool with a friend</Typography>
								</Box>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", m: { xs: "20px 0 0 0", sm: "0 48px 0 0" } }}>
								{/* <a

									className='textDecorNone'
									target="_blank"
									rel="noreferrer"
									// href={`${ process.env.REACT_APP_URL }detailsshared/:${ idMission }`}
									href={`http://localhost:3000/detailsshared/:${ idMission }`}
								> */}
								<Box onClick={saveMission} sx={{ cursor: "pointer" }}>
									<img width="35px" src={shared} alt="shared" />
								</Box>
								{/* </a> */}
							</Box>
						</Box>
						<Box mt={8} sx={{ display: { xs: "block", sm: "none" }, position: "relative" }}>
							<Box sx={{ width: "80px", height: "50px", m: "0 auto", background: "rgba(255,255,255, 0.2)", borderTopRightRadius: "60px", borderTopLeftRadius: "60px" }}>
								<ArrowBackIosIcon sx={{ color: "#fff", fontSize: "40px", transform: "rotate(270deg)", pl: "19px", pt: "20px" }} />
							</Box>
							<Box sx={{ position: "absolute", width: "189px", left: 0, top: "-47px" }}>
								<img width="100%" src={meditateleft} alt="shared" />
							</Box>
						</Box>
					</Grid>
					<Grid className={filterArr?.length < 5 ? "withSmallDesc" : "withSmallDesc heightDetails"} item sx={{ width: { xs: "100%", md: "50%", xl: "55%" } }}>
						<Box
							className="heightDesc"
							sx={{
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								minHeight: { xs: "100%", sm: "100vh" },
								p: { xs: "0 20px", md: "0 20px", xl: "0 40px" },
								mt: { xs: "0px", sm: 0 },
								position: filterArr?.length > 0 && filterArr?.length < 4 && "relative"
							}}
						>
							{filterArr?.length === 0 ?
								<GoodStatus /> :
								filterArr?.length > 4 ?
									<BadStatus handleDetails={handleDetails} handleClickOpen={handleClickOpen} items={items} /> :
									<DetailsBad handleClickOpen={handleClickOpen} handleDetails={handleDetails} items={items} />}
							{details === "" &&
								(
									<Box sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", mt: 2, position: filterArr?.length > 0 && filterArr?.length < 4 && "absolute", bottom: "48px", right: "calc(50% - 191px)" }}>
										<Box sx={{ width: "382px" }}>
											<CustomButton.Contained onClick={() => onClickContinue()} sx={{ width: "100%" }}>
												Continue
											</CustomButton.Contained>
										</Box>
									</Box>
								)
							}
						</Box>

					</Grid>
				</Grid>
				{filterArr?.length !== 0 &&
					<Box sx={{ display: { xs: "block", sm: "none" }, background: "rgba(239,239,239,0.4 )" }}>
						<Box sx={{ width: "90%", m: "0 auto", p: "50px 0 15px" }}>
							<CustomButton.Contained onClick={() => onClickContinue()} sx={{ width: "100%" }}>
								Continue
							</CustomButton.Contained>
						</Box>
					</Box>}
			</Box>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Element name="test1" className="element">
					{details !== "" && <DetailsItem details={details} />}
				</Element>
			</Box>
			<Box>
				<Dialog
					fullScreen
					open={open}
					onClose={handleClose}
					// TransitionComponent={Transition}
					sx={{ ".MuiDialog-paper": { background: `url(${ bgDetails }) no-repeat`, backgroundSize: "cover" } }}
				>
					<Dialogs details={details} handleClose={handleClose} />
				</Dialog>
			</Box>
			<ReferFriendsModal
				open={isOpenShare}
				onClose={() => setIsOpenShare(false)}
			/>
			{/* <TopArrow /> */}
		</Box>

	);
}

export default DetailsContainer;
