// @ts-nocheck
import { Box, Button, CircularProgress, Dialog } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Api from "api";
import useUser from "hooks/useUser";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserActionType } from "reducers/userReducer";
import CheckoutForm from "./CheckoutForm";

interface DialogStripeProps
{
	open: boolean;
	onClose: any;
	handleClick: any;
	description?: number | string;
	amount: number;
	mission?: number;
	handleActiveMission?: any;
	idSecret?: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

function DialogStripe({ open, onClose, handleClick, description, amount, mission, handleActiveMission, idSecret }: DialogStripeProps)
{
	const navigate = useNavigate();
	const isFetchingRef = useRef<boolean>(false);
	const { dispatch: userDispatch } = useUser();
	const [ clientSecret, setClientSecret ] = useState<string>("");
	const [ optionsStripe, setOptionsStripe ] = useState<{ clientSecret: string; }>({ clientSecret: "" });
	const [ loader, setLoader ] = useState<boolean>(false);
	const [ message, setMessage ] = useState<string>("");
	const [ packages, setPackages ] = useState<any>();
	const [ stripe, setStripe ] = useState(null);
	const refForm = useRef();
	const [ elements, setElements ] = useState(null);

	// Effects
	useEffect(() =>
	{

		if (idSecret)
		{
			if (idSecret !== "full")
			{

				Api.transactions
					.stripeSubscription(idSecret)
					.then((res) =>
					{

						setClientSecret(res);
						setOptionsStripe({ clientSecret: res });
					});
			}
			else if (idSecret === "full")
			{
				Api.users
					.addStripeData(
						{

							description: description,
							amount: amount,
							mission: mission
						}
					)
					.then((res) =>
					{

						setClientSecret(res.clientSecret);
						setOptionsStripe({ clientSecret: res.clientSecret });
					})
					.catch((error) => console.error(error))
					.finally(() => (isFetchingRef.current = false));
				Api.admin
					.getPackages()
					.then((res) =>
					{
						setPackages(res[ 0 ].priceMission);
					});
			}
		}

	}, [ idSecret, open ]);

	// Handles
	async function handleSubmit(event: React.FormEvent<EventTarget>)
	{

		event.preventDefault();
		setLoader(true);
		if (!stripe || !elements)
		{
			setLoader(false);

			return;
		}

		try
		{
			const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);

			console.debug("paymentIntent", paymentIntent);
			console.debug("error", error);

			if (paymentIntent.status === "succeeded" || paymentIntent.status === "processing")
			{
				setMessage("Payment succeeded or is processing");

				return;
			}

			if (paymentIntent.status === "requires_payment_method")
			{
				const { error } = await stripe.confirmPayment({
					elements,
					client_secret: clientSecret,
					redirect: "if_required",
					setup_future_usage: "off_session",
					confirmParams: {
						return_url: `${ process.env.REACT_APP_URL }/packages`
					}
				});

				if (error)
				{
					console.error("[error]", error);
					setMessage("Payment failed!");
					setLoader(false);
				}
				else
				{
					setLoader(false);
					if (paymentIntent?.description === "Subscription creation")
					{
						userDispatch(
							{
								type: UserActionType.SUBSCRIPTION_IS_ACTIVE,
								payload: { subscriptionIsActive: true }
							}
						);
					}
					else
					{
						userDispatch(
							{
								type: UserActionType.FULL_SUBSCRIBE,
								payload: { fullSubscribe: true }
							}
						);
					}

					navigate("/dashboard");
					onClose();
				}
			}
			else
			{
				setMessage("Something went wrong.");
			}
		}
		catch (error)
		{
			console.error("Failed to retrieve or confirm payment intent:", error);
		}

	}

	// Render
	return (
		<Dialog open={open} onClose={onClose} sx={{ "& .MuiPaper-root": { maxWidth: "unset" } }}>
			<Box sx={{ background: "#E9EDF0", padding: "45px 20px 20px 20px", borderRadius: "20px", width: "500px" }}>
				{packages ? `Price mission: ${ packages }` : null}
				{/* <Stripe onClose={onClose} handleClick={handleClick} description={description} amount={amount} mission={mission} handleActiveMission={handleActiveMission} /> */}
				{clientSecret
					? (
						<Elements stripe={stripePromise} options={optionsStripe}>
							<CheckoutForm
								clientSecret={clientSecret}
								setMessage={setMessage}
								message={message}
								isLoading={loader}
								setIsLoading={setLoader}
								refForm={refForm}
								setStripe={setStripe}
								setElements={setElements}
							/>
						</Elements>
					)
					: null
				}
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					{loader ?
						<CircularProgress />
						:
						<Button
							onClick={handleSubmit}
							disabled={!stripe}
						>
							Submit
						</Button>
					}
				</Box>
			</Box>
		</Dialog>
	);
}

export default DialogStripe;
