import { Box } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGArrowBack } from "assets/images/icons/arrowBack.svg";
import TooltipStyled from "components/TooltipStyled";
import GetHelpButton from "components/missions/GetHelpButton";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import missionElevenDefualt from "../../assets/images/missions/mission-eleven/missionElevenDefualt.png";
import missionElevenEverythingPossible from "../../assets/images/missions/mission-eleven/missionElevenEverythingPossible.png";
import missionElevenFewWords from "../../assets/images/missions/mission-eleven/missionElevenFewWords.png";
import MissionProgressBar from "../../components/MissionProgressBar";
import MobileContent from "../../components/MobileContent";
import TipsButton from "../../components/TipsButton";
import useUser from "../../hooks/useUser";
import { setLocalStorage } from "../../utils/localStorage";
import { IMissionContextElevenMission, MissionCtx } from "./MissionCtx";
import ChatPageMissionEleven from "./feature/ChatPageMissionEleven";
import Complete from "./feature/Complete";
import EverythingPossibleFinish from "./feature/EverythingPossibleFinish";
import Feedback from "./feature/feedback";
import FewWords from "./feature/fewWords/FewWords";
import Letter from "./feature/letter";

export interface IBackground
{
	DefultImg: string;
	FewWords: string;
	EverythingPossible: string;
}

export const Background: IBackground =
{
	DefultImg: `url(${ missionElevenDefualt }) center center/cover`,
	FewWords: `url(${ missionElevenFewWords }) center center/cover`,
	EverythingPossible: `url(${ missionElevenEverythingPossible }) center center/cover`
};
const Components = [
	ChatPageMissionEleven,
	FewWords,
	Letter,
	EverythingPossibleFinish,
	Complete,
	Feedback
];

const PERCENTAGE_PER_STEP: number = 100 / (Components.length - 1);

function MissionElevenContainer()
{
	const userContext = useUser();
	const navigate = useNavigate();
	const [ isBlipTips, setIsBlipTips ] = useState<boolean>(true);
	const [ step, setStep ] = useState<number>(() =>
	{
		const storageStep = localStorage.getItem("mission-eleven-step");
		if (storageStep !== null)
		{
			return Number(storageStep);
		}

		return 1;
	});
	const [ progress, setProgress ] = useState<number>(0);
	const [ background, setBackground ] = useState<keyof IBackground>("FewWords");
	const [ name, setName ] = useState<string>("");

	const MissionTwoContext: IMissionContextElevenMission = useMemo(() =>
	{
		return {
			step,
			setStep,
			progress,
			setProgress,
			background,
			setBackground,
			name,
			setName
		};
	}, [ background, name, progress, step ]);

	// Effects
	useLayoutEffect(() =>
	{
		if (
			!userContext.state.user?.fullSubscribe && !userContext.state.user?.subscriptionIsActive
			&& [ "tester", "admin" ].includes(userContext.state.user?.userRole ?? "") === false
		)
		{
			navigate("/packages");
		}
	}, [ userContext, navigate ]);

	useLayoutEffect(() =>
	{
		if (userContext.state.user === null)
		{
			return;
		}

		Api.users
			.getUser(userContext.state.user.id)
			.then((user) =>
			{
				setName(user.name);
			});
	}, [ userContext.state.user, userContext.state.user?.id ]);

	useLayoutEffect(() =>
	{
		Api.missions.saveMissionStep(11, step).catch((error) => console.error(error));

		// Change tips content
		setIsBlipTips(true);

		setLocalStorage("mission-eleven-step", step);
		setProgress((step - 1) * PERCENTAGE_PER_STEP);

	}, [ step ]);

	useEffect(() =>
	{
		localStorage.setItem("mission-eleven-progress", String(progress));
	}, [ progress ]);

	// Handles
	return (
		<>
			<Box
				sx={{
					width: "100%",
					background: Background[ background ],
					minHeight: "100vh",
					backgroundAttachment: "fixed",
					zIndex: "10",
					transition: "linear 300ms background",
					overflow: "hidden",
					backgroundPosition: "50% 60%"
				}}
			>
				<Box sx={{
					minHeight: "100vh",
					width: "100%",
					padding: step === 5 || step === 6 ? 0 : "35px",
					display: { sm: "flex", xs: "none" },
					flexDirection: "column",
					alignItems: "center",
					gap: (step === 1 ? "0px" : "25px")
				}}>

					<Box sx={{ position: "fixed", top: "40%", right: "0", zIndex: 5555, pointerEvents: "none", display: (step === 5 || step === 6 ? "none" : "unset") }}>
						<GetHelpButton />
					</Box>

					{/* Header */}

					<Box
						component="header"
						sx={{
							display: step === 5 || step === 6 ? "none" : "flex",
							justifyContent: "space-between",
							gap: "10px",
							alignItems: "center",
							width: "100%",
							maxHeight: "40px",
							zIndex: 10
						}}
					>
						<Box
							component={Link}
							to="/dashboard"
							sx={{
								width: { xl: "60px", lg: "48px", xs: "60px" },
								height: { xl: "40px", lg: "32px", xs: "32px" },
								"& svg": { width: "100%", height: "100%" },
								"&:hover svg path": { fillOpacity: 1 }
							}}
						>
							<TooltipStyled title="Back to missions" placement="bottom" arrow>
								<SVGArrowBack />
							</TooltipStyled>
						</Box>

						{process.env.NODE_ENV === "development" &&
							(
								<Box component="h3" sx={{ marginRight: "auto", "&:not(:hover)": { opacity: 0.2 } }}>
									Step:
									<Box
										component="input"
										type="number"
										min={1}
										max={Components.length}
										value={step}
										onChange={({ target }) => setStep(target.valueAsNumber)}
										sx={{ marginLeft: "10px", textAlign: "center" }}
									/> / {Components.length}

								</Box>
							)
						}

						<Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
							{(step === 6 || step === 8) &&
								<TooltipStyled title="Tips" placement="bottom" arrow>
									<TipsButton
										isActive={isBlipTips}
										blip={false}
										onClick={() => setIsBlipTips(false)}

									/>
								</TooltipStyled>
							}
							<MissionProgressBar value={progress} />
						</Box>
					</Box>

					<MissionCtx.Provider value={MissionTwoContext}>
						<Box
							className="mission-root"
							sx={{
								flexGrow: 1,
								width: "100%",
								"& .TransitionGroup":
								{
									flexGrow: 1,
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									width: "100%"
								},
								"& .mission-main":
								{
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "25px"
								},
								"& .main__content":
								{
									maxWidth: { sm: "814px", md: "814px", lg: "980px", xl: "980px" },
									width: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									gap: "20px"
								}
							}}
						>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								{Components.map((Component, index) =>
								{
									if (step !== index + 1)
									{
										return false;
									}

									return <Component key={index} />;
								})}
							</Box>
						</Box>
					</MissionCtx.Provider>
				</Box>
			</Box>
			<MobileContent />
		</>
	);
}

export default MissionElevenContainer;
