import { Masonry } from "@mui/lab";
import { Box, Fade, alpha } from "@mui/material";
import Api from "api";
import { ReactComponent as SVGChevronArrow } from "assets/images/icons/size-40/chevron-arrow.svg";
import ChipStyled from "components/ChipStyled";
import ContentAdmin from "components/contentAdmin";
import CustomButton from "components/customButton";
import { COLORS } from "pages/missionThree/components/ClusterYourYearnings";
import { useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ArtifactsContainer from "../components/ArtifactsContainer";
import AsideCircleButton from "../components/AsideCircleButton";
import Create from "./Create";
import Details, { ICluster } from "./Details";
import IMGAsideButtonBackground from "./assets/images/aside-button-background.png";
import IMGBackground from "./assets/images/background.png";

export type MissionData = [ id: string, date: string ];

export function formatDate(date: string)
{
	return date.replaceAll(/[/-]/g, ".");
}

function Yearning()
{
	const navigate = useNavigate();
	const params = useParams();

	const isFetchingRef = useRef<boolean>(false);
	const [ items, setItems ] = useState<MissionData[]>([]);
	const [ selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);
	const [ clusters, setClusters ] = useState<ICluster[]>([]);

	// TODO: Added cache for clusters data

	// Utils
	function updateItems()
	{
		if (isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getDataMissionsThree()
			.then(({ data }) =>
			{
				setItems(data.map(({ id, created_at }): MissionData => ([ id, created_at ])));
			})
			.finally(() => (isFetchingRef.current = false));
	}

	// Effects
	useLayoutEffect(() =>
	{
		updateItems();
	}, []);

	useLayoutEffect(() =>
	{
		if (selectedItemIndex === -1 || isFetchingRef.current === true)
		{
			return;
		}

		isFetchingRef.current = true;

		Api.missions
			.getDataMissionThree(items[ selectedItemIndex ][ 0 ])
			.then(({ data }) =>
			{
				setClusters(
					data.mission_data.clusters.filter(({ items }) => items.length > 0)
				);
			})
			.catch((error) => console.error(error))
			.finally(() => (isFetchingRef.current = false));
	}, [ items, selectedItemIndex ]);

	// Render
	switch (params.action)
	{
		case "create": return (<Create updateItems={updateItems} />);
		case "details": return (<Details items={items} />);
		case undefined: break;
		default: return (<Navigate to="/dashboard/artifacts/yearning" />);
	}

	return (
		<ArtifactsContainer
			backgroundImage={IMGBackground}
			title={<ContentAdmin position="top-start" defaultValue="My Yearning" keyContent="contentDashboard" keyText="artifacts_mission3_title" />}
			aside={(
				<Box sx={{
					height: "100%",
					display: "grid",
					gridTemplateRows: "1fr auto",
					gap: "32px",
					flexGrow: 1
				}}>
					<Box
						className="customScroll"
						sx={{
							height: "100%",
							overflowY: "auto",
							marginRight: "-16px",
							paddingRight: "8px",

							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							alignItems: "flex-start",
							gap: { xl: "50px 70px", lg: "48px 32px", xs: "50px 70px" },

							"& .AsideCircleButton-image":
							{
								boxShadow: "0px 4px 42px " + alpha("#68AEC5", 0.4)
							}
						}}
					>
						{items.length === 0
							? (
								<Box>Loading...</Box>
							)
							: (
								items.map((item, index) =>
								{
									return (
										<AsideCircleButton
											key={item[ 0 ]}
											image={IMGAsideButtonBackground}
											text={formatDate(item[ 1 ])}
											isSelected={selectedItemIndex === index}
											onClick={() => setSelectedItemIndex(index)}
										/>
									);
								})
							)
						}
					</Box>

					<CustomButton.Contained
						sx={{
							margin: "0 auto",
							width: { xl: "355px", lg: "355px", xs: "355px" }
						}}
						onClick={() =>
						{
							return navigate("/dashboard/artifacts/yearning/create");
						}}
					>
						Create new map
					</CustomButton.Contained>
				</Box>
			)}
			headerRight={
				(items[ selectedItemIndex ] !== undefined) && formatDate(items[ selectedItemIndex ][ 1 ])
			}
			onExpand={() => navigate("/dashboard/artifacts/yearning/details/" + items[ selectedItemIndex ][ 0 ])}
			onClose={() => navigate("/dashboard/artifacts")}
			sx={{
				"& .ArtifactsContainer-header":
				{
					color: "primary.main"
				},
				"& .ArtifactsContainer-main":
				{
					position: "relative"
				}
			}}
		>
			{selectedItemIndex > 0 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItemIndex((prevState) => prevState - 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								left: { xl: "20px", lg: "0px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow style={{ transform: "rotate(180deg)" }} />
						</Box>
					</Fade>
				)
			}

			{selectedItemIndex < items.length - 1 &&
				(
					<Fade in>
						<Box
							component="button"
							onClick={() => setSelectedItemIndex((prevState) => prevState + 1)}
							sx={{
								"--size": { xl: "40px", lg: "32px", xs: "40px" },
								width: "var(--size)",
								height: "var(--size)",
								position: "absolute",
								top: "50%",
								transform: "translateY(-50%)",
								right: { xl: "20px", lg: "0px", xs: "20px" },
								padding: "unset",
								background: "unset",
								border: "unset",
								color: "primary.main",
								cursor: "pointer",

								"& svg":
								{
									width: "var(--size)",
									height: "var(--size)"
								},
								"&:hover":
								{
									opacity: "0.7 !important"
								}
							}}
						>
							<SVGChevronArrow />
						</Box>
					</Fade>
				)
			}
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Box
					className="customScroll"
					sx={{
						"--width": { xl: "918px", lg: "672px", xs: "918px" },

						overflowY: "auto",
						maxHeight: { xl: "664px", lg: "564px", xs: "664px" },
						marginRight: { xl: "-24px", lg: "-8px", xs: "-24px" },
						paddingRight: { xl: "16px", lg: "8px", xs: "16px" },
						maxWidth: "var(--width)",
						width: "100%"
					}}
				>
					<Masonry
						columns={2}
						spacing={{ xl: "48px", lg: "24px", xs: "48px" }}
						sx={{
							width: "var(--width)",

							// Chips
							"& .MuiChip-root":
							{
								height: "unset",
								whiteSpace: "normal"
							},
							"& .MuiChip-label":
							{
								color: "#FFFFFF"
							},
							"& .MuiChip-root--color-red":
							{
								background: alpha(COLORS.red, 0.5)
							},
							"& .MuiChip-root--color-green":
							{
								background: alpha(COLORS.green, 0.5)
							},
							"& .MuiChip-root--color-blue":
							{
								background: alpha(COLORS.blue, 0.5)
							},
							"& .MuiChip-root--color-none":
							{
								background: alpha(COLORS.none, 0.5),

								"& .MuiChip-label":
								{
									color: "#4D4D4D"
								}
							},
							// Cluster
							"& .cluster-root":
							{
								padding: { xl: "24px 32px 32px 32px", lg: "24px", xs: "24px 32px 32px 32px" },
								width: { xl: "423px", lg: "320px", xs: "423px" },
								display: "flex",
								flexDirection: "column",
								gap: "16px",
								background: "linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)",
								backdropFilter: "blur(10px)",
								border: "1px solid " + alpha("#FFFFFF", 0.5),
								borderRadius: "24px",
								boxShadow: "0px 5px 10px " + alpha("#7EC4FF", 0.5)
							},
							"& .cluster__title":
							{
								font: { xl: "700 24px/31px Lora-Bold", lg: "700 18px/23px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
								color: "primary.main"
							},
							"& .cluster__items":
							{
								display: "flex",
								flexWrap: "wrap",
								gap: "16px",
								alignItems: "center",
								overflow: "auto",
								maxHeight: "380px",
								paddingBottom: "5px",

								"&:empty":
								{
									"&::after":
									{
										content: "'Drop to here'",
										padding: "10px",
										display: "block",
										width: "100%",
										textAlign: "center",
										color: alpha("#4D4D4D", 0.6),
										font: "500 16px 'Open Sans'",
										border: "2px dashed " + alpha("#215C75", 0.6),
										borderRadius: "5px",
										transition: "linear 200ms border-color, linear 200ms color"
									}
								},
								"& .MuiChip-root":
								{
									flexGrow: 1,
									cursor: "inherit",
									"& .MuiChip-label":
									{
										textAlign: "center",
										flexGrow: 1
									}
								}
							}
						}}
					>
						{clusters.map(
							(cluster, clusterIndex) => (
								<Box key={cluster.title + "-" + clusterIndex} className="cluster-root">
									<Box className="cluster__title">{cluster.title}</Box>
									<Box className="cluster__items customScroll">
										{cluster.items.map(
											(clusterItem, clusterItemIndex) => (
												<ChipStyled
													key={clusterItem.title + "-" + clusterItemIndex}
													label={clusterItem.title}
													className={"MuiChip-root--color-" + clusterItem.color}
												/>
											)
										)}
									</Box>
								</Box>
							)
						)}
					</Masonry>
				</Box>
			</Box>
		</ArtifactsContainer>
	);
}

export default Yearning;
