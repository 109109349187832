import { createContext, Dispatch, SetStateAction } from "react";

export interface IMissionContext
{
	step: number;
	setStep: Dispatch<SetStateAction<number>>;

	extraStep: number | null;
	setExtraStep: Dispatch<SetStateAction<number | null>>;

	progress: number;
	setProgress: Dispatch<SetStateAction<number>>;

	name: string;
}

export const MissionCtx = createContext<IMissionContext>({} as IMissionContext);
