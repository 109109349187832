import { IMission } from "./mission.interface";

import IMGMission1_wide from "assets/images/dashboards/missions/mission-1-wide.png";
import IMGMission1 from "assets/images/dashboards/missions/mission-1.png";
import IMGMission10_wide from "assets/images/dashboards/missions/mission-10-wide.png";
import IMGMission10 from "assets/images/dashboards/missions/mission-10.png";
import IMGMission2_wide from "assets/images/dashboards/missions/mission-2-wide.png";
import IMGMission2 from "assets/images/dashboards/missions/mission-2.png";
import IMGMission3_wide from "assets/images/dashboards/missions/mission-3-wide.png";
import IMGMission3 from "assets/images/dashboards/missions/mission-3.png";
import IMGMission4_wide from "assets/images/dashboards/missions/mission-4-wide.png";
import IMGMission4 from "assets/images/dashboards/missions/mission-4.png";
import IMGMission5_wide from "assets/images/dashboards/missions/mission-5-wide.png";
import IMGMission5 from "assets/images/dashboards/missions/mission-5.png";
import IMGMission6_wide from "assets/images/dashboards/missions/mission-6-wide.png";
import IMGMission6 from "assets/images/dashboards/missions/mission-6.png";
import IMGMission7_wide from "assets/images/dashboards/missions/mission-7-wide.png";
import IMGMission7 from "assets/images/dashboards/missions/mission-7.png";
import IMGMission8_wide from "assets/images/dashboards/missions/mission-8-wide.png";
import IMGMission8 from "assets/images/dashboards/missions/mission-8.png";
import IMGMission9_wide from "assets/images/dashboards/missions/mission-9-wide.png";
import IMGMission9 from "assets/images/dashboards/missions/mission-9.png";
import ContentAdmin from "components/contentAdmin";
import { getLocalStorage } from "utils/localStorage";

function getMissionProgress(localStorageItemName: string): number
{
	const missionProgress: string | number | null = getLocalStorage(localStorageItemName);

	return (missionProgress === null ? 0 : Number(missionProgress));
}

export function getMissionNameById(id: number)
{
	const missions = [
		"registration",
		"wheel_of_life",
		"values",
		"yearnings",
		"fulfilling_career",
		"daily_life_design",
		"vision_board",
		"beliefs",
		"fears",
		"life_plan",
		"experiments",
		"secret_mission"
	];

	return missions[ id ];
}

export function getMissionData(completedMission: number): IMission[]
{
	const missions: IMission[] = [
		{
			id: 1,
			isLock: false,
			complete: 100,
			link: "/mission-one",
			title: <ContentAdmin defaultValue="My Starting Point" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission1Title" />,
			image: IMGMission1,
			imageMissionsAside: IMGMission1_wide,
			// description: "",
			description: <ContentAdmin
				defaultValue="mission1Desc"
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission1Desc" />,
			time: 40
		},
		{
			id: 2,
			isLock: true,
			complete: getMissionProgress("mission-two-progress"),
			link: "/mission-two",
			title: <ContentAdmin defaultValue="My Values" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission2Title" />,
			image: IMGMission2,
			imageMissionsAside: IMGMission2_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission2Desc" />,
			time: 25
		},
		{
			id: 3,
			isLock: (getMissionProgress("mission-two-progress") !== 100),
			complete: getMissionProgress("mission-three-progress"),
			link: "/mission-three",
			title: <ContentAdmin defaultValue="My Yearnings" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission3Title" />,
			image: IMGMission3,
			imageMissionsAside: IMGMission3_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission3Desc" />,
			time: 40
		},
		{
			id: 4,
			isLock: (getMissionProgress("mission-three-progress") !== 100),
			complete: getMissionProgress("mission-four-progress"),
			link: "/mission-four",
			title: <ContentAdmin defaultValue="Fulfilling Career" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission4Title" />,
			image: IMGMission4,
			imageMissionsAside: IMGMission4_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission4Desc" />,
			time: 40
		},
		{
			id: 5,
			isLock: (getMissionProgress("mission-four-progress") !== 100),
			complete: getMissionProgress("mission-five-progress"),
			link: "/mission-five",
			title: <ContentAdmin defaultValue="Quality of Life" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission5Title" />,
			image: IMGMission5,
			imageMissionsAside: IMGMission5_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission5Desc" />,
			time: 40
		},
		{
			id: 6,
			isLock: (getMissionProgress("mission-five-progress") !== 100),
			complete: getMissionProgress("mission-six-progress"),
			link: "/mission-six",
			title: <ContentAdmin defaultValue="My Vision Board" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission6Title" />,
			image: IMGMission6,
			imageMissionsAside: IMGMission6_wide,
			description: <ContentAdmin
				defaultValue="Have fun visualizing and manifesting your dream life"
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission6Desc" />,
			time: 40
		},
		{
			id: 7,
			isLock: (getMissionProgress("mission-six-progress") !== 100),
			complete: getMissionProgress("mission-seven-progress"),
			link: "/mission-seven",
			title: <ContentAdmin defaultValue="Trends & Inspiration" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission7Title" />,
			image: IMGMission7,
			imageMissionsAside: IMGMission7_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission7Desc" />,
			time: 40
		},
		{
			id: 8,
			isLock: (getMissionProgress("mission-seven-progress") !== 100),
			complete: getMissionProgress("mission-eight-progress"),
			link: "/mission-eight",
			title: <ContentAdmin defaultValue="Thinking Bigger" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission8Title" />,
			image: IMGMission8,
			imageMissionsAside: IMGMission8_wide,
			description: <ContentAdmin
				defaultValue="mission 8"
				position="left"
				isDescription
				limit={150}
				keyContent="contentMissionEight"
				keyText="mission_8" />,
			time: 40
		},
		{
			id: 9,
			isLock: (getMissionProgress("mission-eight-progress") !== 100),
			complete: getMissionProgress("mission-nine-progress"),
			link: "/mission-nine",
			title: <ContentAdmin defaultValue="My North Star" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission9Title" />,
			image: IMGMission9,
			imageMissionsAside: IMGMission9_wide,
			description: <ContentAdmin
				defaultValue="mission 9"
				position="left"
				isDescription
				limit={150}
				keyContent="contentMissionNine"
				keyText="mission_9" />,
			time: 40
		},
		{
			id: 10,
			isLock: (getMissionProgress("mission-nine-progress") !== 100),
			complete: getMissionProgress("mission-ten-progress"),
			link: "/mission-ten",
			title: <ContentAdmin defaultValue="Life Experiments" position="top-start" limit={24} keyContent="contentDashboard" keyText="mission10Title" />,
			image: IMGMission10,
			imageMissionsAside: IMGMission10_wide,
			description: <ContentAdmin
				defaultValue=""
				position="left"
				isDescription
				limit={150}
				keyContent="contentDashboard"
				keyText="mission10Desc" />,
			time: 40
		}
	];

	return missions.map((mission) =>
	{
		if (mission.id <= completedMission)
		{
			mission.isLock = false;
			mission.complete = 100;
		}
		else if (mission.id === completedMission + 1)
		{
			mission.isLock = false;
			mission.time = Math.max(0, 25 - (Math.ceil((mission.time * mission.complete) / 100)));
			// mission.time = 25 - (Math.ceil((mission.time * progressMission) / 100));
			// mission.complete = progressMission;
		}

		return mission;
	});
}
