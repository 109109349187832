import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import octa2 from "../../../assets/images/missions/mission-five/octa.png";
import ContentAdmin from "../../../components/contentAdmin";
import CustomButton from "../../../components/customButton";
import PanelTitle from "../../../components/missions/PanelTitle";
import useMission from "../hooks";

const AmazingJob = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
		missionContext.setBackground("lifePlanDay");
	}

	return (
		<Box ref={ref} {...props} sx={{ height: "84vh", width: "100%", maxWidth: "840px", m: "0 auto" }}>
			<PanelTitle
				imgDisplay="none"
				gapPanelTitle="25px"
				title={<ContentAdmin
					limit={111}
					keyContent="contentMissionNine"
					keyText="amazing_job_title"
					defaultValue="Amazing job, {NAME}!"
					position="top"
				/>}
			/>
			<Box sx={{ width: "199px", height: "359px", m: "52px auto 55px" }}>
				<img width="100%" src={octa2} alt="guide-chara" />

			</Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<CustomButton.Outline
					sx={{
						fontSize: "24px",
						lineHeight: "33px",
						background: "#3E809D",
						border: "#3E809D",
						color: "#fff"
					}}
					onClick={nextStep}>Thanks
				</CustomButton.Outline>
			</Box>
		</Box>
	);
});

export default AmazingJob;
