import { Box, Stack, alpha } from "@mui/material";
import IMGOcta from "assets/images/octa2-shrink.png";
import CheckboxStyledWithLabel from "components/CheckboxStyledWithLabel";
import CustomButton from "components/customButton";
import useRegistration from "pages/registration/hooks/useRegistration";
import { useState } from "react";

const CHECKBOXES = [
	"Maximise my potential",
	"Figure out what to do next",
	"Discover my life purpose",
	"Understand what I want",
	"Create a life plan",
	"Get inspired and start moving"
];

function MainGoals()
{
	const { setStep, name } = useRegistration();
	const [ checkboxes, setCheckboxes ] = useState<{ label: string; checked: boolean; }[]>(() =>
	{
		return CHECKBOXES.map((value) => ({ label: value, checked: false }));
	});

	// Handles
	function checkCheckbox(index: number, value: boolean)
	{
		setCheckboxes((prevState) =>
		{
			const checkboxes = [ ...prevState ];

			checkboxes[ index ].checked = value;

			return checkboxes;
		});
	}

	// Render
	return (
		<Box sx={{
			position: "relative",

			padding: "16px",
			paddingTop: { xl: "88px", xs: "54px" },
			paddingBottom: "unset",
			margin: "0 auto",

			maxWidth: { xl: "844px", sm: "784px" },
			width: "100%",

			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
			gap: { sm: "unset", xs: "24px" }
		}}>
			<Box
				component="img"
				src={IMGOcta}
				alt=""
				sx={{
					display: { xs: "none", lg: "block" },
					position: "absolute",
					top: { xl: "74px", lg: "45px" },
					left: { xl: "-36px", lg: "35px" },
					width: { xl: "199px", lg: "130px" },
					transform: "rotate(-15.4deg)"
				}}
			/>

			<div>
				<Box sx={{
					textAlign: "center",
					font: { xl: "700 40px/51px Lora-Bold", xs: "700 24px/31px Lora-Bold" },
					color: "primary.main"
				}}>
					That’s a cool name, {name}!<br />
					What are your main goals?
				</Box>

				<Stack
					sx={{
						margin: { xl: "56px auto 0 auto", lg: "32px auto 0 auto", xs: "48px auto 0 auto" },
						maxWidth: { xl: "496px", xs: "434px" },
						width: "100%",
						gap: "8px"
					}}
				>
					{checkboxes.map(({ label, checked }, index) =>
					{
						return (
							<CheckboxStyledWithLabel
								key={label}
								label={label}
								checkboxProps={{
									checked,
									onChange(_event, checked)
									{
										checkCheckbox(index, checked);
									}
								}}
								sx={{
									padding: { xl: "24px", xs: "20px" },
									backgroundColor: (checked ? alpha("#3E809D", 0.3) : alpha("#FFFFFF", 0.5)),
									borderRadius: "16px",
									gap: { xl: "24px", xs: "20px" },
									font: {
										xl: "400 24px/33px 'Open Sans' !important",
										xs: "400 16px/22px 'Open Sans' !important"
									},
									transition: "linear 300ms background-color",

									"& .CheckboxStyledWithLabel-checkbox":
									{
										backgroundColor: "#FFFFFF",

										"& svg":
										{
											top: { xl: "8px", xs: "6px" },
											left: { xl: "8px", xs: "6px" },
											width: { xl: "16px", xs: "12px" },
											height: { xl: "16px", xs: "12px" }
										}
									},
									"& .MuiCheckbox-root.Mui-checked + .CheckboxStyledWithLabel-content":
									{
										fontWeight: "bold",
										color: "primary.main"
									},
									"& .CheckboxStyledWithLabel-root":
									{
										"--size": { xl: "32px", xs: "24px" },
										"--line-height": "19px",
										marginTop: { xl: "unset", xs: "-1px" }
									}
								}}
							/>
						);
					})}
				</Stack>
			</div>

			<Box sx={{
				padding: "0 16px",
				marginTop: { xl: "62px", lg: "80px", xs: "auto" },
				width: "100%",
				minHeight: { sm: "unset", xs: "120px" }
			}}>
				<CustomButton.Contained
					size="large"
					onClick={() => setStep((prevStep) => prevStep + 1)}
					sx={(theme) => ({
						display: "flex",
						margin: "0 auto",

						[ theme.breakpoints.down("sm") ]:
						{
							maxWidth: "434px",
							width: "100%"
						}
					})}
				>
					Continue
				</CustomButton.Contained>
			</Box>
		</Box>
	);
}

export default MainGoals;
