import { Box } from "@mui/material";
import CelebrationScreen from "components/missions/CelebrationScreen";
import { forwardRef, Ref } from "react";
import ContentAdmin from "../../../components/contentAdmin";
import useMission from "../hooks";

const HeHe = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const missionContext = useMission();

	function nextStep()
	{
		missionContext.setStep((prevState) => prevState + 1);
		missionContext.setBackground("lifePlanDay");
	}

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				height: "84vh",
				width: "100%",
				maxWidth: "840px",
				m: "0 auto",
				position: "relative",
				top: "40px",
				left: "9px"
			}}>
			<CelebrationScreen
				content={<ContentAdmin
					limit={111}
					keyContent="contentMissionNine"
					keyText="he_he_title"
					defaultValue="I knew you will come up with some great ideas, {NAME}!"
					position="top"
				/>}
				buttonText="He-he"
				onClickButton={nextStep}
				sx={{
					color: "primary.main",

					"& .CelebrationScreen-content":
					{
						textShadow: "unset"
					}
				}}
			/>
		</Box>
	);
});

export default HeHe;
