import { Box } from "@mui/material";
import { forwardRef, Ref } from "react";
import Сontour from "../../../../../assets/images/missions/mission-nine/contour.png";
import RadialButtonBall from "../../../../../components/RadialButtonBall";

const Buttons = forwardRef((props: Record<string, any>, ref: Ref<HTMLDivElement>) =>
{
	const { handleShowButtons, checkValue } = props;

	return (
		<Box
			ref={ref}
			{...props}
			sx={{
				position: "relative",
				left: "58px",
				top: "19px",
				width: "700px",
				height: "455px",
				m: { lg: "10px auto 0px", xl: "40px auto 0px" },
				backgroundImage: `url(${ Сontour })`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				transform: { lg: "scale(0.8)", xl: "scale(1)" }
			}}
		>
			<Box sx={{ position: "absolute", top: "64px", left: "-25px" }}>
				<RadialButtonBall
					borderStyle="2px solid #fff"
					// backgroundStyle='linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)'
					valueMaxWidth="331px"
					value="This is my why"
					direction="left"
					alignText="center"
					color={checkValue(4, false) ? "blue" : "#3E809D80"}
					showIcon={!!checkValue(4, false)}
					hoverColor="#fff"
					onClick={() => handleShowButtons(5)}
					// disabled={checkValue(3, false)}
					blip={checkValue(4, true)}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "122px", left: "380px" }}>
				<RadialButtonBall
					borderStyle="2px solid #fff"
					// backgroundStyle='linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)'
					valueMaxWidth="425px"
					value="This is what I stand for"
					onClick={() => handleShowButtons(4)}
					// disabled={checkValue(2, true)}
					showValue
					direction="right"
					alignText="center"
					color={checkValue(3, false) ? "blue" : "#3E809D80"}
					showIcon={!!checkValue(3, false)}
					hoverColor="#fff"
					blip={checkValue(3, true)}
				/>
			</Box>
			<Box sx={{ position: "absolute", top: "204px", left: "-15px" }}>
				<RadialButtonBall
					borderStyle="2px solid #fff"
					// backgroundStyle='linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)'
					value="My tribe is"
					onClick={() => handleShowButtons(3)}
					// disabled={checkValue(1, false)}
					direction="left"
					alignText="center"
					color={checkValue(2, false) ? "blue" : "#3E809D80"}
					showIcon={!!checkValue(2, false)}
					hoverColor="#fff"
					blip={checkValue(2, true)}
				/>
			</Box>

			<Box sx={{ position: "absolute", top: "269px", left: "400px" }}>
				<RadialButtonBall
					borderStyle="2px solid #fff"
					// backgroundStyle='linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)'
					value="Quotes I live by"
					onClick={() => handleShowButtons(2)}
					// disabled={checkValue(0, true)}
					showValue
					direction="right"
					alignText="center"
					color={checkValue(1, false) ? "blue" : "#3E809D80"}
					showIcon={!!checkValue(1, false)}
					hoverColor="#fff"
					blip={checkValue(1, true)}
				/>
			</Box>

			<Box sx={{ position: "absolute", top: "350px", left: "-50px" }}>
				<RadialButtonBall
					borderStyle="2px solid #fff"
					// backgroundStyle='linear-gradient(103.55deg, rgba(230, 229, 229, 0.79) 9.99%, rgba(239, 239, 239, 0.22) 91.61%)'
					value="My purpose"
					valueMaxWidth="296px"
					onClick={() => handleShowButtons(1)}
					color={checkValue(0, false) ? "blue" : "#3E809D80"}
					showIcon={!!checkValue(0, false)}
					hoverColor="#fff"
					direction="left"
					alignText="center"
					blip={checkValue(0, true)}
				/>
			</Box>
		</Box>
	);
});

export default Buttons;
